<template>
  <q-intersection
    transition="scale"
    once
  >
    <q-form
      class="q-mb-lg q-mt-sm"
      @submit="handleSubmit"
    >
      <div class="row q-col-gutter-md wrap">
        <div class="col-4 col-sm-6 col-xs-12">
          <currency-kik-block />
        </div>
        <div class="col-4 col-sm-6 col-xs-12">
          <credit-type-block />
        </div>
        <div class="col-4 col-sm-6 col-xs-12">
          <monthes-kik-block />
        </div>
        <div
          v-if="creditType === CreditTypeEnum.contract"
          class="col-12"
        >
          <has-state-contracts-kik-block />
        </div>
      </div>
    </q-form>
  </q-intersection>
</template>

<script setup lang="ts">
import CurrencyKikBlock from 'components/Applications/ApplicationNew/Form/CurrencyKikBlock.vue'
import CreditTypeBlock from 'components/Applications/ApplicationNew/Form/CreditTypeBlock.vue'
import MonthesKikBlock from 'components/Applications/ApplicationNew/Form/MonthesKikBlock.vue'
import HasStateContractsKikBlock from 'components/Applications/ApplicationNew/Form/HasStateContractsKikBlock.vue'
import {useApplicationsNew} from 'stores/Applications/ApplicationsNew/useApplicationsNew'
import {storeToRefs} from 'pinia'
import {CreditTypeEnum} from 'src/models/Applications/CreditTypeEnum'

const store = useApplicationsNew()
const {
  creditType,
} = storeToRefs(store)

const handleSubmit = () => {
  //
}
</script>

<style lang="scss" scoped>
.bottom {
  border-top: 1px solid $blue-grey-1;
}
</style>
