import {useGetApi} from 'src/uses/Api/useGetApi'
import {SuretyGuarantor, SuretyResponse, SuretyStatusEnum} from 'src/models/Applications/SuretyResponse'
import {computed, ref, watch} from 'vue'
import {CloneHelper} from 'src/helpers/CloneHelper'
import {ApplicationFormDocumentStatusEnum} from 'src/models/Applications/Form/ApplicationFormResponseData'
import {
  useApplicationControlCenterChangeStatusStore
} from 'src/stores/Applications/ControlCenter/ChangeStatus/useApplicationControlCenterChangeStatusStore'
import {useHeaderDataFromStore} from 'src/uses/Applications/ControlCenter/useHeaderDataFromStore'
import {ApplicationStatusEnum} from 'src/models/Applications/ApplicationStatusEnum'
import {ApplicationSubStatusEnum} from 'src/models/Applications/ApplicationSubStatusEnum'

export const useSurety = (id: string) => {
  const {
    get,
    status,
    response,
    loading,
    error,
  } = useGetApi<SuretyResponse>(true)

  const {
    getHeaderData
  } = useHeaderDataFromStore()

  const {
    requestFulfilledInitiator,
    requestFulfilledStatus,
    changeStatus,
  } = useApplicationControlCenterChangeStatusStore(id)()

  const guarantors = ref<SuretyGuarantor[]>([])
  const originalGuarantors = ref<SuretyGuarantor[]>([])
  const suretyStatus = ref<SuretyStatusEnum>()
  const requestCompleteEnabled = ref(false)

  const selectedGuarantorPercent = computed(
    () => guarantors.value
      .filter(g => g.is_selected)
      .filter(g => !!g.phone)
      .reduce(
        (sum, g) => sum + g.share_percent,
        0
      )
  )

  const prepareDocsEnabled = computed(() => {
    return selectedGuarantorPercent.value >= 51
      && JSON.stringify(originalGuarantors.value) !== JSON.stringify(guarantors.value)
  })

  const recalculateRequestCompleteEnabled = async () => {
    if (selectedGuarantorPercent.value < 51
      || JSON.stringify(originalGuarantors.value) !== JSON.stringify(guarantors.value)
    ) {
      requestCompleteEnabled.value = false

      return
    }

    const selected = guarantors.value
      .filter(g => g.is_selected)

    if (selected.find(g => !g.is_documents_received)) {
      requestCompleteEnabled.value = false

      return
    }

    if (selected
      .find(g => g.documents
        .find(doc => doc.status !== ApplicationFormDocumentStatusEnum.signed)
      )) {
      requestCompleteEnabled.value = false

      return
    }

    const headerData = await getHeaderData(id)
    if (!headerData) {
      requestCompleteEnabled.value = false

      return
    }

    requestCompleteEnabled.value = !!requestFulfilledStatus(headerData.status as ApplicationStatusEnum, headerData.sub_status as ApplicationSubStatusEnum)
  }

  const completeRequest = async () => {
    if (!requestCompleteEnabled.value) {
      return
    }

    const headerData = await getHeaderData(id)

    if (!headerData) {
      return
    }

    const applicationStatus = requestFulfilledStatus(headerData.status as ApplicationStatusEnum, headerData.sub_status as ApplicationSubStatusEnum)
    if (!applicationStatus) {
      return
    }

    await changeStatus(
      {
        id,
        applicationStatus,
        initiator: requestFulfilledInitiator(headerData.sub_status as ApplicationSubStatusEnum)
      },
      headerData.sub_status as ApplicationSubStatusEnum
    )
  }

  const load = async () => {
    await get(
      `v1/control-center/${id}/guarantors`
    )

    if (status.value === 200 && response.value) {
      guarantors.value = response.value.guarantors || []
      originalGuarantors.value = CloneHelper.clone(guarantors.value)
      suretyStatus.value = response.value?.status
    }
  }

  const isGuarantorDisabled = (guarantor: SuretyGuarantor) => {
    const originalGuarantor = originalGuarantors.value
      .find(g => g.founder_id === guarantor.founder_id)

    if (!originalGuarantor) {
      return true
    }

    return originalGuarantor.is_selected
      && !originalGuarantor.is_documents_received
  }

  watch(
    selectedGuarantorPercent,
    recalculateRequestCompleteEnabled
  )

  watch(
    guarantors,
    recalculateRequestCompleteEnabled,
    {
      deep: true
    }
  )

  return {
    status,
    response,
    loading,
    error,
    guarantors,
    originalGuarantors,
    selectedGuarantorPercent,
    prepareDocsEnabled,
    requestCompleteEnabled,
    completeRequest,
    isGuarantorDisabled,
    load,
  }
}
