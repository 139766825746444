<template>
  <q-tab
    class="ApplicationControlCenterTab"
    :name="props.name"
    :disable="loading"
  >
    {{ props.label }}
  </q-tab>
</template>

<script setup lang="ts">
import {ApplicationControlCenterSectionsEnum} from 'src/models/Enums/ApplicationControlCenterSectionsEnum'

const props = defineProps<{
  name: ApplicationControlCenterSectionsEnum,
  label: string,
  loading: boolean,
}>()

</script>

<style lang="scss">

.ApplicationControlCenterTab {
  border-radius: 6px 6px 0 0;
  margin-right: 1px;
  height: 60px;
  padding: 0 30px;
  opacity: 1;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  background-color: #F5F5F5;
  &.q-tab--active {
    background: $blue-1;
  }
  .q-tab__indicator {
    display: none;
  }
}
</style>
