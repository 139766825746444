<template>
  <div
    v-if="documents.length"
    class="ChatNewDocuments q-pa-sm rounded-borders"
  >
    <template
      v-for="group in groups"
      :key="group"
    >
      <template v-if="newDocuments[group]">
        <chat-new-document
          v-for="(document, index) in newDocuments[group]"
          :key="JSON.stringify(document)"
          :document="document"
          @remove="removeNewDocumentByIndex(index, group)"
        />
      </template>
    </template>
  </div>
</template>

<script setup lang="ts">
import ChatNewDocument from 'src/components/Chat/ChatNewDocument.vue'
import {
  useApplicationControlCenterItemStore
} from 'src/stores/Applications/ControlCenter/useApplicationControlCenterItemStore'
import {ControlCenterHeader} from 'src/models/Applications/ControlCenter/ControlCenterHeader'
import {ChatTypeEnum} from 'src/models/ChatTypeEnum'
import {storeToRefs} from 'pinia'
import {computed} from 'vue'
import {
  ApplicationFormDocument,
  ApplicationFormDocumentGroupEnum
} from 'src/models/Applications/Form/ApplicationFormResponseData'

const props = defineProps<{
  headerData: ControlCenterHeader,
  chatType: ChatTypeEnum,
}>()

const applicationItemStore = useApplicationControlCenterItemStore(props.headerData.id)()
const {
  removeNewDocumentByIndex,
} = applicationItemStore
const {
  newDocuments,
} = storeToRefs(applicationItemStore)

const documents = computed<ApplicationFormDocument[]>(() => {
  return Object.values(newDocuments.value)
    .reduce(
      (all, documents) => {
        all.push(...documents)
        return all
      },
      []
    )
})

const groups = [
  ApplicationFormDocumentGroupEnum.passports,
  ApplicationFormDocumentGroupEnum.financial,
  ApplicationFormDocumentGroupEnum.additional,
  ApplicationFormDocumentGroupEnum.offer,
  ApplicationFormDocumentGroupEnum.deal,
]
</script>

<style lang="scss">
.ChatNewDocuments {
  background: $blue-grey-1;
}
</style>
