<template>
  <q-btn
    v-if="!props.itemId"
    color="primary"
    icon-right="svguse:icons.svg?2#app-plus-1"
    label="Добавить пользователя"
    style="height: 50px;"
    no-caps
    @click="open"
  />
  <q-btn
    v-if="props.itemId"
    size="sm"
    color="blue-grey-4"
    icon="svguse:icons.svg?2#app-pencil-alt"
    style="height: 50px;"
    round
    flat
    @click="open"
  />
  <q-dialog
    v-model="dialog"
  >
    <q-card
      class="q-mt-md bg-blue-1"
      style="min-width: 400px; max-width: 800px; border-radius: 4px !important;"
    >
      <q-card-section>
        <div class="text-h5 rounded-borders q-pb-none">
          {{ itemId ? 'Редактировать учетную запись пользователя' : 'Создать учетную запись пользователя' }}
        </div>
      </q-card-section>
      <q-card-section
        v-if="showActivated"
        class="bg-blue-grey-10 text-white row items-center q-py-lg"
      >
        <div class="rounded-borders q-pa-sm bg-blue-grey-9">
          <q-icon
            name="svguse:icons.svg?2#app-information-circle"
            size="sm"
            color="orange-6"
          />
        </div>
        <div class="col q-pl-sm text-wgite">
          <div v-if="userCreateData.agent?.inn && userCreateData.agent?.title">
            Сотрудник агента {{ userCreateData.agent?.title }} ИНН {{ userCreateData.agent?.inn }}
          </div>
          {{ userCreateData.activated }}
          <div v-if="userCreateData.activated">
            Пользователь ознакомился с условиями Сублицензионного соглашения и с Политикой конфиденциальности {{ userCreateData.activated }}
          </div>
        </div>
      </q-card-section>
      <q-card-section class="white-bg-field">
        <q-form
          v-if="userCreateData"
          style="max-height: 70vh; overflow: auto; margin: 0 -16px; padding: 0 16px;"
        >
          <div class="row q-col-gutter-md">
            <div class="col-6">
              <q-input
                v-model="userCreateData.first_name"
                class="field-with-top-label"
                :loading="loading"
                :error-message="getErrorMessage('first_name')"
                :error="hasError('first_name')"
                label="Имя"
                outlined
                hide-hint
                hide-bottom-space
                dense
                @blur="validation.first_name.$validate()"
                @focus="clearError('first_name')"
              />
            </div>
            <div class="col-6">
              <q-input
                v-model="userCreateData.last_name"
                class="field-with-top-label"
                :loading="loading"
                :error-message="getErrorMessage('last_name')"
                :error="hasError('last_name')"
                label="Фамилия"
                outlined
                hide-hint
                hide-bottom-space
                dense
                @blur="validation.last_name.$validate()"
                @focus="clearError('last_name')"
              />
            </div>
            <div class="col-6">
              <q-input
                v-model="userCreateData.middle_name"
                class="field-with-top-label"
                :loading="loading"
                label="Отчество"
                outlined
                hide-hint
                hide-bottom-space
                dense
              />
            </div>
            <div class="col-6">
              <q-input
                v-model="userCreateData.phone"
                class="field-with-top-label"
                :loading="loading"
                :error-message="getErrorMessage('phone')"
                :error="hasError('phone')"
                type="tel"
                mask="+# (###) ### - ## - ##"
                hint="+# (###) ### - ## - ##"
                label="Телефон"
                unmasked-value
                fill-mask
                outlined
                hide-hint
                hide-bottom-space
                dense
                @blur="validation.phone.$validate()"
                @focus="clearError('phone')"
              />
            </div>
            <div class="col-6">
              <q-input
                v-model="userCreateData.email"
                class="field-with-top-label"
                :loading="loading"
                :error-message="getErrorMessage('email')"
                :error="hasError('email')"
                label="E-mail"
                outlined
                hide-hint
                hide-bottom-space
                dense
                @blur="validation.email.$validate()"
                @focus="clearError('email')"
              />
            </div>
            <div class="col-6">
              <q-select
                v-if="!isBlockedRole"
                v-model="userCreateData.role"
                class="field-with-top-label"
                :options="rolesForSelect"
                :loading="loading"
                style="min-width: 200px"
                map-options
                label="Роль пользователя"
                outlined
                hide-hint
                hide-bottom-space
                dense
                emit-value
              />
              <q-select
                v-else
                :model-value="userCreateData.role"
                class="field-with-top-label"
                :options="blockedRolesForSelect"
                :loading="loading"
                style="min-width: 200px"
                map-options
                label="Роль пользователя"
                disable
                outlined
                hide-hint
                hide-bottom-space
                dense
                emit-value
              />
            </div>
            <template v-if="userCreateData.role === UserRoleEnum.Operator && userCreateData.operator">
              <div class="col-6">
                <user-head-opeator
                  v-model="userCreateData.operator.head_id"
                  :name="userCreateData.operator.head_name"
                />
              </div>
            </template>
            <template v-if="userCreateData.role === UserRoleEnum.BankEmployee && userCreateData.bank_employee">
              <div class="col-6">
                <q-select
                  v-model="userCreateData.bank_employee.banks"
                  class="field-with-top-label MultipleSelect"
                  :options="mappedBanks"
                  :loading="loading"
                  style="min-width: 200px"
                  option-value="id"
                  option-label="title"
                  label="Банки"
                  multiple
                  outlined
                  hide-hint
                  label-slot
                  hide-bottom-space
                  dense
                >
                  <template
                    #option="{ itemProps, opt, selected, toggleOption }"
                  >
                    <q-item v-bind="itemProps">
                      <q-item-section side>
                        <q-checkbox
                          :model-value="selected"
                          @update:model-value="toggleOption(opt)"
                        />
                      </q-item-section>
                      <q-item-section>
                        <q-item-label v-html="opt.title" />
                      </q-item-section>
                    </q-item>
                  </template>
                </q-select>
              </div>
              <div class="col-6">
                <q-toggle
                  v-model="userCreateData.bank_employee.is_hide_personal_data"
                  label="Скрыть персональные данные площадки"
                />
              </div>
            </template>
            <template v-if="userCreateData.role === UserRoleEnum.Agent && userCreateData.agent">
              <div class="col-6">
                <q-input
                  v-model="userCreateData.agent.inn"
                  class="field-with-top-label"
                  :loading="loading"
                  label="ИНН"
                  disable
                  outlined
                  hide-hint
                  hide-bottom-space
                  dense
                />
              </div>
              <div class="col-6">
                <q-select
                  v-model="userCreateData.agent.opf"
                  class="field-with-top-label"
                  :options="opfForSelect"
                  :loading="loading"
                  style="min-width: 200px"
                  label="Тип организации"
                  map-options
                  disable
                  outlined
                  hide-hint
                  hide-bottom-space
                  dense
                  emit-value
                />
              </div>
            </template>
            <div class="col-6">
              <password-help
                :model-value="showPasswordHelp"
                :password="userCreateData.password || ''"
              >
                <q-input
                  v-model="userCreateData.password"
                  class="field-with-top-label"
                  :loading="loading"
                  :error-message="getErrorMessage('password')"
                  :error="hasError('password')"
                  autocomplete="new-password"
                  type="password"
                  label="Пароль"
                  outlined
                  hide-hint
                  hide-bottom-space
                  dense
                  @blur="validation.password.$validate(); showPasswordHelp = false"
                  @focus="showPasswordHelp = true; clearError('password')"
                />
              </password-help>
            </div>
            <div class="col-6">
              <password-help
                :model-value="showPasswordConfirmHelp"
                :password="userCreateData.password_confirm || ''"
              >
                <q-input
                  v-model="userCreateData.password_confirm"
                  class="field-with-top-label"
                  :loading="loading"
                  :error-message="getErrorMessage('password_confirm')"
                  :error="hasError('password_confirm')"
                  label="Повторите пароль"
                  autocomplete="new-password"
                  type="password"
                  outlined
                  hide-hint
                  hide-bottom-space
                  dense
                  @blur="validation.password_confirm.$validate(); showPasswordConfirmHelp = false"
                  @focus="showPasswordConfirmHelp = true; clearError('password_confirm')"
                />
              </password-help>
            </div>
            <div class="col-6">
              <q-toggle
                v-model="userCreateData.user_is_active"
                label="Пользователь активен"
              />
            </div>
            <div
              v-if="userCreateData.role === UserRoleEnum.AgentManager && userCreateData.agent_manager"
              class="col-6"
            >
              <q-toggle
                v-model="userCreateData.agent_manager.is_default"
                label="Куратор по умолчанию для новых агентов"
              />
            </div>
            <div
              v-if="userCreateData.role === UserRoleEnum.Operator && userCreateData.operator"
              class="col-6"
            >
              <q-toggle
                v-model="userCreateData.operator.is_hide_personal_data"
                label="Скрыть перс. данные агента"
              />
            </div>
          </div>
        </q-form>
      </q-card-section>
      <q-card-actions
        class="row justify-end q-py-md"
        style="background: #D5EAFA;"
      >
        <q-btn
          v-close-popup
          size="16px"
          padding="sm lg"
          label="Отмена"
          :disable="loading"
          unelevated
          no-caps
        />
        <q-btn
          padding="sm lg"
          size="16px"
          color="blue-7"
          label="Сохранить"
          :loading="loading"
          unelevated
          no-caps
          :disable="validation.$invalid"
          @click="handleSubmit"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
import {withDefaults, ref, computed, onBeforeMount} from 'vue'
import {useDialog} from 'src/uses/Dialogs/useDialog'
import {useValidation} from 'src/uses/useValidation'
import PasswordHelp from 'src/components/PasswordHelp.vue'
import {UserRoleEnum} from 'src/models/User/UserRoleEnum'
import {useUserCreateUpdate} from 'src/uses/Profile/Users/useUserCreateUpdate'
import {useBankEmployeeBankListStore} from 'stores/Banks/useBankEmployeeBankListStore'
import {storeToRefs} from 'pinia'
import UserHeadOpeator from 'components/Profiles/Users/UserHeadOpeator.vue'

const props = withDefaults(
  defineProps<{
    itemId?: number
  }>(),
  {
    itemId: undefined
  }
)

const emit = defineEmits<{
  (e: 'success'): void,
}>()

const userCreateUpdate = useUserCreateUpdate()
const {
  userCreateData,
  loading,
  rolesForSelect,
  blockedRolesForSelect,
  validation,
  errors,
  opfForSelect,
  itemId,
  status,
  isBlockedRole,
  create,
  update,
  loadUserItem
} = userCreateUpdate

const {dialog, show, hide} = useDialog()

const open = async () => {
  clearAllErrors()
  show()
  itemId.value = props.itemId
  if (itemId.value) {
    await loadUserItem(itemId.value)
  }
}

const {
  getErrorMessage,
  hasError,
  clearError,
  clearAllErrors,
} = useValidation(validation, errors)

const {
  load,
} = useBankEmployeeBankListStore()
const {
  mappedBanks,
} = storeToRefs(useBankEmployeeBankListStore())

const showPasswordHelp = ref(false)
const showPasswordConfirmHelp = ref(false)

const showActivated = computed(() => [
  UserRoleEnum.Agent,
  UserRoleEnum.Employee,
  UserRoleEnum.HeadEmployee,
].includes(userCreateData.value.role as UserRoleEnum))

const success = () => {
  emit('success')
  hide()
}

const handleSubmit = async () => {
  if (itemId.value) {
    await update()
  } else {
    await create()
  }

  if (status.value === 200 || status.value === 201) {
    success()
  }
}

onBeforeMount(async () => {
  await load()
})
</script>
