<template>
  <div
    :class="{'ProfileEmployeesGridFilters row': true, 'q-pt-sm q-col-gutter-y-md': $q.platform.is.mobile, 'q-col-gutter-md': !$q.platform.is.mobile}"
  >
    <div class="col-xs-12 col-md-7">
      <q-input
        v-model="filters.name"
        :loading="props.loading"
        label="Поиск по номеру, ФИО или E-mail"
        outlined
        hide-hint
        hide-bottom-space
        dense
        @change="onSubmit"
        @clear="onSubmit"
      >
        <template #prepend>
          <q-icon name="svguse:icons.svg?2#app-search" />
        </template>
        <template #append>
          <q-icon
            v-if="filters.name"
            class="cursor-pointer"
            name="svguse:icons.svg?2#app-x"
            @click="clear"
          />
        </template>
      </q-input>
    </div>
    <div class="col-xs-12 col-md-5">
      <q-select
        v-model="filters.role"
        :options="rolesForSelect"
        :loading="loading"
        style="min-width: 200px"
        map-options
        label="Роль"
        outlined
        hide-hint
        hide-bottom-space
        dense
        emit-value
        clearable
        @update:model-value="onSubmit"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import {storeToRefs} from 'pinia'
import {useEmployeeGridFiltersStore} from 'src/stores/Profile/Employees/useEmployeesGridFiltersStore'
import {useQuasar} from 'quasar'
const props = defineProps<{
  loading: boolean,
}>()

const emit = defineEmits<{
  (e: 'update:filters'): void,
}>()

const filtersStore = useEmployeeGridFiltersStore()
const {
  filters,
  rolesForSelect
} = storeToRefs(filtersStore)

const $q = useQuasar()

const onSubmit = () => {
  emit('update:filters')
}

const clear = () => {
  filters.value.name = null
  emit('update:filters')
}
</script>

<style lang="scss">
.ProfileEmployeesGridFilters {
  .q-field--dense .q-field__label {
    top: 16px;
  }
}
</style>
