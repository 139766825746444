import {defineStore} from 'pinia'
import {useGetApi} from 'src/uses/Api/useGetApi'
import {ref} from 'vue'
import {useGridPagination} from 'src/uses/Grid/useGridPagination'
import {BanksGridResponseData} from 'src/models/Banks/BanksGridResponseData'
import {BankItem} from 'src/models/Banks/BankItem'
import {useBanksGridFiltersStore} from 'stores/Banks/useBanksGridFiltersStore'

export const useBanksGridStore = defineStore('banks-grid', () => {
  const {
    loading,
    get,
    errors,
    error,
    status,
    response,
  } = useGetApi<BanksGridResponseData>()

  const {
    pagination,
    gridPagination,
    perPageOptions,
    getFromStorage,
    setPagination,
    onRequestPagination,
    setPage,
  } = useGridPagination('banks-grid')

  const filtersStore = useBanksGridFiltersStore()
  const {getFiltersQuery} = filtersStore

  const items = ref<BankItem[]>([])

  const load = async () => {
    await getFromStorage()
    await get(
      'admin/bank/list',
      {
        page: pagination.value?.page,
        perpage: 30,
        ...getFiltersQuery()
      }
    )

    if (status.value === 200 && response && response.value?.items) {
      items.value = response.value?.items
      setPagination(response.value?.pagination)
    }
  }

  return {
    loading,
    get,
    errors,
    error,
    items,
    pagination,
    gridPagination,
    perPageOptions,
    onRequestPagination,
    load,
    setPage,
  }
})
