import {defineStore, storeToRefs} from 'pinia'
import {useGetApi} from 'src/uses/Api/useGetApi'
import {ref} from 'vue'
import {useGridPagination} from 'src/uses/Grid/useGridPagination'
import {EmployeeGridResponseData} from 'src/models/Profile/Employees/EmployeeGridResponseData'
import {EmployeeGridItem} from 'src/models/Profile/Employees/EmployeeGridItem'
import {useEmployeeGridFiltersStore} from 'src/stores/Profile/Employees/useEmployeesGridFiltersStore'
import {useEmployeeGridSortingStore} from 'src/stores/Profile/Employees/useEmployeeGridSortStore'
import {useDialog} from 'src/uses/Dialogs/useDialog'
import {useQuasar} from 'quasar'

export const useEmployeesGridStore = defineStore('profile-employees-grid', () => {
  const AGENT_EMPLOYEES_LIST_URL = 'v1/employees'

  const {
    loading,
    get,
    errors,
    error,
    status,
    response,
  } = useGetApi<EmployeeGridResponseData>()

  const {
    pagination,
    gridPagination,
    perPageOptions,
    getFromStorage,
    noMorePages,
    setPagination,
    onRequestPagination,
    setPage,
    setNextPage,
  } = useGridPagination('profile-employees')

  const $q = useQuasar()

  const {
    dialog: filerDialog,
    show: filterDialogShow
  } = useDialog()

  const filtersStore = useEmployeeGridFiltersStore()
  const {
    getFiltersQuery,
    clear
  } = filtersStore
  const {clearable} = storeToRefs(filtersStore)

  const sortingStore = useEmployeeGridSortingStore()
  const {sorting} = storeToRefs(sortingStore)

  const items = ref(<EmployeeGridItem[]>[])

  const load = async () => {
    await getFromStorage()
    await get(
      AGENT_EMPLOYEES_LIST_URL,
      {
        page: pagination.value?.page,
        perpage: pagination.value?.per_page,
        ...sorting.value,
        ...getFiltersQuery()
      }
    )

    if (status.value === 200 && response && response.value?.items) {
      if ($q.platform.is.mobile && pagination.value?.page !== 1) {
        items.value = items.value.concat(response.value.items)
      } else {
        items.value = response.value.items
      }
      setPagination(response.value?.pagination)
    }
  }

  const getFullName = (item: EmployeeGridItem): string => {
    return `${item.name_first} ${item.name_middle} ${item.name_last}`
  }

  const updateFilters = () => {
    setPage(1)
    load()
  }

  const onClear = async () => {
    await clear()
    updateFilters()
  }

  return {
    loading,
    get,
    errors,
    error,
    items,
    pagination,
    gridPagination,
    perPageOptions,
    noMorePages,
    filerDialog,
    clearable,
    onRequestPagination,
    load,
    setPage,
    setNextPage,
    getFullName,
    onClear,
    filterDialogShow,
  }
})
