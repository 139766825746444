import {LoginGuard} from 'src/router/quards/LoginGuard'
import {Platform} from 'quasar'

const importLayout = () => {
  if (Platform.is.mobile) {
    return import(/* webpackChunkName: "LoginMobileLayout" */ '../layouts/LoginMobileLayout.vue')
  }

  return import(/* webpackChunkName: "LoginLayout" */ '../layouts/LoginLayout.vue')
}

export const loginRouter = [
  {
    path: '/',
    component: () => import('../layouts/LoginMobileLayoutEmpty.vue'),
    children: [
      {
        path: '/',
        name: 'login-main',
        component: () => import('../views/HomeMobileView.vue'),
        meta: {
          guards: [
            LoginGuard(),
          ],
        },
      },
    ],
  },
  {
    path: '/login',
    component: () => importLayout(),
    redirect: {name: 'login'},
    children: [
      {
        path: '',
        name: 'login',
        components: {
          default: () => import('../views/Login/LoginView.vue'),
          header: () => import('../components/Login/LoginHeader.vue'),
        },
        meta: {
          guards: [LoginGuard()],
        },
      },
      {
        path: 'password-recovery',
        name: 'password-recovery',
        components: {
          default: () => import('../views/Login/PasswordRecoveryView.vue'),
          header: () => import('../components/Login/PasswordRecoveryHeader.vue'),
        },
        meta: {
          guards: [LoginGuard()],
        },
      },
      {
        path: 'password-recovery-continue/:token',
        name: 'password-recovery-continue',
        components: {
          default: () => import('../views/Login/PasswordRecoveryContinue.vue'),
          header: () => import('../components/Login/PasswordRecoveryContinueHeader.vue'),
        },
        meta: {
          guards: [LoginGuard()],
        },
      },
      {
        path: 'registration',
        name: 'registration',
        components: {
          default: () => import('../views/Login/RegistrationView.vue'),
          header: () => import('../components/Login/RegistrationHeader.vue'),
        },
        meta: {
          guards: [LoginGuard()],
        },
      },
      {
        path: 'signup-confirm/:hash',
        name: 'signup-confirm',
        components: {
          default: () => import('../views/Login/SignupConfirmView.vue'),
          header: () => import('../components/Login/SignUpConfirmHeader.vue'),
        },
        meta: {
          guards: [LoginGuard()],
        },
      },
    ],
  }
]
