<template>
  <div
    v-if="isAgentManager() || isAdmin()"
    class="q-py-sm q-px-md bg-white row"
  >
    <q-input
      ref="inputRef"
      v-model="formattedValue"
      class="field-with-top-label col"
      label="Оплата по заявке"
      :disable="!(isAgentManager() || isAdmin()) || loading"
      :loading="loading"
      outlined
      hide-bottom-space
      dense
      @focus="onFocus"
      @blur="onBlur"
    />
    <div
      v-if="modified"
      style="padding-top: 40px"
    >
      <q-btn
        class="q-ml-xs"
        icon="svguse:icons.svg?2#app-check"
        size="10px"
        color="green-14"
        :disable="loading"
        round
        unelevated
        flat
        @click="confirm"
      />
      <q-btn
        class="q-ml-xs"
        icon="svguse:icons.svg?2#app-x"
        size="10px"
        color="red-12"
        :disable="loading"
        round
        unelevated
        flat
        @click="cancel"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import {ApplicationsGridItem} from 'src/models/Applications/ApplicationsGridItem'
import {watchEffect, computed, nextTick} from 'vue'
import {useProfileStore} from 'src/stores/Login/useProfileStore'
import {usePaymentUpdate} from 'src/uses/Applications/usePaymentUpdate'
import {useCurrencyInput} from 'src/uses/useCurrencyInput'

const props = defineProps<{
  item: ApplicationsGridItem,
}>()

const emit = defineEmits<{
  (e: 'update:modelValue', value: number): void,
  (e: 'change'): void,
  (e: 'changed'): void,
}>()

const {
  isAgentManager,
  isAdmin,
} = useProfileStore()

const {
  formattedValue,
  numberValue,
  onFocus,
  onBlur,
} = useCurrencyInput()

const {
  status,
  loading,
  update,
} = usePaymentUpdate()

const modified = computed(() => {
  return numberValue.value !== props.item.payment
})

const confirm = async () => {
  await update(props.item.id, numberValue.value || 0)

  if (status.value === 200) {
    emit('changed')
  }
}

const cancel = () => {
  numberValue.value = props.item.payment || 0
}

nextTick(() => {
  numberValue.value = props.item.payment || 0
})

watchEffect(() => {
  numberValue.value = props.item.payment || 0
})
</script>

<style scoped lang="scss">

</style>
