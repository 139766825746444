import {defineStore} from 'pinia'
import {ref} from 'vue'
import {useAuthStore} from 'stores/Login/useAuthStore'
import {RouteLocationNormalized, useRouter} from 'vue-router'

export const useRememberRouteForGuestStore = defineStore(
  'remember-rout-for-guest',
  () => {
    const authStore = useAuthStore()
    const location = ref<string>()
    const router = useRouter()

    const remember = (to: RouteLocationNormalized) => {
      if (authStore.isAuthed || location.value) {
        return
      }

      location.value = router.resolve(to).href
    }

    const forget = () => {
      location.value = undefined
    }

    const redirect = () => {
      if (!location.value) {
        return
      }

      document.location.href = location.value
      forget()
    }

    return {
      location,
      remember,
      redirect,
      forget,
    }
  })
