import {useApplicationControlCenterStore} from 'src/stores/Applications/ControlCenter/useApplicationControlCenterStore'

export const useHeaderDataFromStore = () => {
  const getHeaderData = async (appId: string) => {
    if (!appId) {
      return undefined
    }
    const store = useApplicationControlCenterStore(appId)()
    if (!store.headerData) {
      await store.init()
    }

    if (!store.headerData) {
      return undefined
    }

    return store.headerData
  }

  return {
    getHeaderData
  }
}
