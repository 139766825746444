import {defineStore} from 'pinia'
import {ref, watch} from 'vue'
import {
  ApplicationFormFinanceField,
  ApplicationFormBank,
  ApplicationFormClient,
  ApplicationFormDocument,
  ApplicationFormField,
} from 'src/models/Applications/Form/ApplicationFormResponseData'
import {useMainFields} from 'src/uses/Applications/Form/useMainFields'
import {useFinanceFields} from 'src/uses/Applications/Form/Finance/useFinanceFields'
import {useClientCommonFields} from 'src/uses/Applications/Form/Client/useClientCommonFields'
import {useClientHeadFields} from 'src/uses/Applications/Form/Client/useClientHeadFields'
import {useClientFoundersFields} from 'src/uses/Applications/Form/Client/useClientFoundersFields'
import {useUpdateFieldsSuccess} from 'src/uses/Applications/Form/useUpdateFieldsSuccess'
import {useDocuments} from 'src/uses/Applications/Form/Documents/useDocuments'
import {ApplicationFormUpdateResponse} from 'src/models/Applications/Form/ApplicationFormUpdateResponse'
import {ApplicationItemSectionsEnum} from 'src/models/Enums/ApplicationItemSectionsEnum'
import {useAddressDetailsSave} from 'src/uses/Applications/Form/useAddressDetailsSave'

export const useApplicationItemEbgStore = (multiOrderId: number) => defineStore(`application-item-ebg-${multiOrderId}`, () => {
  const client = ref<ApplicationFormClient | null>(null)
  const banks = ref<ApplicationFormBank[]>([])
  const openedFounderId = ref<string>()

  const tab = ref<ApplicationItemSectionsEnum>(ApplicationItemSectionsEnum.MAIN)

  const highlightField = (field: ApplicationFormFinanceField | ApplicationFormDocument | ApplicationFormField, on = true) => {
    field.highlighted = on
  }

  const {
    update: updateAddressDetails,
    response: updateAddressDetailsResponse,
  } = useAddressDetailsSave(multiOrderId)

  const {
    main,
    mainVisibleFields,
    updateMainFields,
    response: updateMainFieldsResponse,
  } = useMainFields(banks, multiOrderId)

  const {
    documents,
    visibleDocuments,
    newDocuments,
    dropZoneAvailability,
    canUploadNewDocumentsBySections,
    canUploadAllNewDocuments,
    canUploadNewDocument,
    canAddNewAddedDocument,
    uploadDocument,
    cancelUpload,
    deleteDocument,
    downloadDocument,
    downloadDocumentSign,
    addNewDocument,
    removeNewDocumentByIndex,
    clearNewDocuments,
    onNewDocumentUpload,
    updateNewDocument,
    uploadAllNewDocuments,
    uploadPassportByFounderId,
    response: uploadDocumentResponse,
  } = useDocuments(multiOrderId)

  const {
    clientCommonVisibleFields,
    updateClientCommonFields,
    response: updateClientCommonFieldsResponse,
  } = useClientCommonFields(client, banks, multiOrderId)

  const {
    clientHeadVisibleFields,
    updateClientHeadFields,
    response: updateClientHeadFieldsResponse,
  } = useClientHeadFields(client, banks, multiOrderId)

  const {
    clientFoundersVisibleFields,
    updateClientFounderFields,
    response: updateClientFounderFieldsResponse,
  } = useClientFoundersFields(client, banks, multiOrderId)

  const {
    finance,
    financeVisible,
    periods,
    tab: financePeriodTab,
    updateFinanceField,
    response: updateFinanceFieldsResponse,
  } = useFinanceFields(multiOrderId)

  const {
    updateChangesProcess,
  } = useUpdateFieldsSuccess()

  const startUpdateChangesProcess = (response: ApplicationFormUpdateResponse | undefined) => {
    updateChangesProcess(
      response,
      main.value,
      client.value,
      finance.value,
      documents.value,
      banks.value,
    )
  }

  watch(updateMainFieldsResponse, startUpdateChangesProcess)
  watch(updateClientCommonFieldsResponse, startUpdateChangesProcess)
  watch(updateClientHeadFieldsResponse, startUpdateChangesProcess)
  watch(updateClientFounderFieldsResponse, startUpdateChangesProcess)
  watch(updateFinanceFieldsResponse, startUpdateChangesProcess)
  watch(uploadDocumentResponse, startUpdateChangesProcess)
  watch(updateAddressDetailsResponse, startUpdateChangesProcess)

  return {
    banks,
    tab,
    highlightField,

    main,
    mainVisibleFields,
    updateMainFields,

    finance,
    financeVisible,
    periods,
    financePeriodTab,
    updateFinanceField,

    clientCommonVisibleFields,
    updateClientCommonFields,

    clientHeadVisibleFields,
    updateClientHeadFields,

    client,
    clientFoundersVisibleFields,
    openedFounderId,
    updateClientFounderFields,

    documents,
    visibleDocuments,
    newDocuments,
    dropZoneAvailability,
    canUploadNewDocumentsBySections,
    canUploadAllNewDocuments,
    canUploadNewDocument,
    canAddNewAddedDocument,
    uploadDocument,
    cancelUpload,
    deleteDocument,
    downloadDocument,
    downloadDocumentSign,
    addNewDocument,
    removeNewDocumentByIndex,
    clearNewDocuments,
    onNewDocumentUpload,
    updateNewDocument,
    uploadAllNewDocuments,
    uploadPassportByFounderId,

    updateAddressDetails,

    startUpdateChangesProcess,

    updateMainFieldsResponse,
    updateClientCommonFieldsResponse,
    updateClientHeadFieldsResponse,
    updateClientFounderFieldsResponse,
    updateFinanceFieldsResponse,
    uploadDocumentResponse,
    updateAddressDetailsResponse,
  }
})
