<template>
  <div class="text-h6 q-mb-md">
    Общая информация
  </div>
  <fields-block-component
    :fields="props.fields"
    :banks="banks"
    @update:value="updateValue"
    @update:address-details="updateAddressDetails"
  />
</template>

<script setup lang="ts">
import {
  ApplicationFormField,
} from 'src/models/Applications/Form/ApplicationFormResponseData'
import {ProductFields} from 'src/models/Applications/Form/ProductFields'
import FieldsBlockComponent from 'src/components/Applications/Form/FieldsBlockComponent.vue'
import {storeToRefs} from 'pinia'
import {FormElementValue} from 'src/models/Form'
import {useApplicationItemEbgStore} from 'stores/Applications/Item/useApplicationItemEbgStore'
import {useApplicationItemStore} from 'stores/Applications/Item/useApplicationItemStore'
import {ProductEnum} from 'src/models/Applications/ProductEnum'
import {useApplicationItemKikStore} from 'stores/Applications/Item/useApplicationItemKikStore'

const props = defineProps<{
  fields: ProductFields,
  multiOrderId: number,
}>()

const applicationItemStore = useApplicationItemStore(props.multiOrderId)()
const {
  productTab,
} = storeToRefs(applicationItemStore)

const applicationItemProductStore = productTab.value === ProductEnum.EBG
  ? useApplicationItemEbgStore(props.multiOrderId)()
  : useApplicationItemKikStore(props.multiOrderId)()

const {
  updateClientCommonFields,
  updateAddressDetails,
} = applicationItemProductStore

const {
  banks,
} = storeToRefs(applicationItemProductStore)

const updateValue = (value: FormElementValue, field: ApplicationFormField) => {
  if (!props.multiOrderId) {
    return
  }

  updateClientCommonFields(
    [
      {
        code: field.code,
        value,
      }],
  )
}
</script>
