import {useGetApi} from 'src/uses/Api/useGetApi'
import {ApplicationsTariffDetailsResponseData} from 'src/models/Applications/Tariff/ApplicationsTariffDetailsResponseData'
import {ref} from 'vue'
import {BgFormEnum} from 'src/models/Applications/BgFormEnum'

export const useGetTariffDetailsCalculate = () => {
  const {
    loading,
    get,
    status,
    response,
  } = useGetApi<ApplicationsTariffDetailsResponseData>()

  const tariffDetails = ref<ApplicationsTariffDetailsResponseData>()

  const calculate = async (id: string, issueForm: BgFormEnum, mailhardcopy: boolean) => {
    await get(
      `v1/control-center/${id}/tariff/details/calculate?issue_form=${issueForm}&mailhardcopy=${mailhardcopy}`,
    )

    if (status.value === 200 && response && response.value) {
      tariffDetails.value = response.value
    }
  }

  return {
    loading,
    tariffDetails,
    calculate,
  }
}
