import {defineStore, storeToRefs} from 'pinia'
import {useGetApi} from 'src/uses/Api/useGetApi'
import {ref} from 'vue'
import {useGridPagination} from 'src/uses/Grid/useGridPagination'
import {useDialog} from 'src/uses/Dialogs/useDialog'
import {ContractorsListResponseData} from 'src/models/Contractors/ContractorsListResponseData'
import {useContractorsGridFiltersStore} from 'stores/Contractors/useContractorsGridFiltersStore'
import {Contractor} from 'src/models/Contractors/Contractor'

export const useContractorsGridStore = defineStore('contractors-gid', () => {
  const {
    loading,
    get,
    errors,
    error,
    status,
    response,
  } = useGetApi<ContractorsListResponseData>()

  const {
    pagination,
    gridPagination,
    perPageOptions,
    getFromStorage,
    setPagination,
    setPage,
    onRequestPagination,
  } = useGridPagination('contractors-grid')

  const filtersStore = useContractorsGridFiltersStore()
  const {
    getFiltersQuery,
    clear
  } = filtersStore
  const {clearable} = storeToRefs(filtersStore)

  const items = ref<Contractor[]>([])

  const {
    dialog: filerDialog,
    show: filterDialogShow
  } = useDialog()

  const updateFilters = () => {
    setPage(1)
    load()
  }

  const onClear = async () => {
    await clear()
    updateFilters()
  }

  const load = async () => {
    await getFromStorage()
    await get(
      'v2/contractors',
      {
        page: pagination.value?.page,
        perpage: pagination.value?.per_page,
        ...getFiltersQuery()
      }
    )

    if (status.value === 200 && response && response.value?.items) {
      items.value = response.value.items
      setPagination(response.value?.pagination)
    }
  }

  return {
    loading,
    get,
    errors,
    error,
    items,
    pagination,
    gridPagination,
    perPageOptions,
    onRequestPagination,
    load,
    setPage,

    filerDialog,
    filterDialogShow,
    clearable,
    onClear
  }
})
