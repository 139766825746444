<template>
  <div class="q-pa-sm bg-white rounded-borders q-my-md relative-position">
    <div class="row items-center no-wrap">
      <q-icon
        name="svguse:icons.svg?2#app-document-add-bold"
        color="blue-grey-5"
        size="md"
      />
      <div
        v-if="props.file"
        class="text-blue-grey-5 col ellipsis"
      >
        {{ props.file.name }} ({{ getSizeString(props.file.size) }})
      </div>
      <q-btn
        class="delete"
        :disable="props.loading"
        dense
        unelevated
        @click="emit('remove')"
      >
        <q-icon
          name="svguse:icons.svg?2#app-x"
          color="blue-grey-4"
        />
      </q-btn>
    </div>
    <div
      v-if="props.progress"
      class="progress"
    >
      <q-linear-progress
        :value="props.progress.loaded / props.progress.total"
        color="green-14"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import {withDefaults} from 'vue'
import {useDataSize} from 'src/uses/useDataSize'

const props = withDefaults(
  defineProps<{
    file: File,
    progress?: ProgressEvent
    loading?: boolean
  }>(),
  {
    progress: undefined,
    loading: false,
  }
)

const emit = defineEmits<{
  (e: 'remove'): void,
}>()

const {
  getSizeString,
} = useDataSize()
</script>

<style lang="scss" scoped>
.progress {
  position: absolute;
  width: calc(100% - 48px);
  margin: -5px 0 0 32px;
}
</style>
