import {helpers} from '@vuelidate/validators'

export const urlHasProtocolValidator = helpers.withMessage(
  'Enter URL with protocol',
  (value: string) => {
    const reg = /^(https?:\/\/)/i

    return value.match(reg) !== null
  }
)
