import {defineStore} from 'pinia'
import {useGridFilters} from 'src/uses/Grid/useGridFilters'
import {productList as productsDescriptions} from 'src/models/Applications/ProductDescriptions'
import {ProductsFilter} from 'src/models/Products/ProductsFilter'
import {ref} from 'vue'

const defaultFilters: ProductsFilter = {
  name: '',
  type: '',
}

export const useProductsGridFiltersStore = defineStore('products-grid-filters', () => {
  const productTypes = ref(
    Object.entries(productsDescriptions).map(([product, title]) => {
      return {
        label: title,
        value: product
      }
    })
  )

  const {
    filters,
    clearable,
    getFiltersQuery,
    clear,
  } = useGridFilters(defaultFilters)

  return {
    filters,
    clearable,
    getFiltersQuery,
    clear,
    productTypes
  }
})
