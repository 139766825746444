<template>
  <page-block-wrapper>
    <div class="profile-wrapper">
      <profile-documents-grid />
    </div>
  </page-block-wrapper>
</template>
<script setup lang="ts">
import ProfileDocumentsGrid from 'src/components/Profile/Documents/ProfileDocumentsGrid.vue'
import PageBlockWrapper from 'src/components/PageBlockWrapper.vue'
import {useMetaStore} from 'src/stores/useMetaStore'

useMetaStore().setTitle('Документы')
</script>
