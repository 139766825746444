<template>
  <q-dialog
    v-if="isAgentManager()"
    :model-value="modelValue"
    @update:model-value="emit('update:model-value', $event)"
  >
    <q-card
      style="min-width: 400px; width: 100vw; max-width: 600px;"
      class="bg-blue-1"
    >
      <q-card-section class="text-h6 rounded-borders">
        Комментарий к отказу по заявке
      </q-card-section>
      <q-card-section class="q-pt-none white-bg-field">
        <q-input
          v-model="comment"
          :disable="loading"
          placeholder="Введите текст вашего комментария"
          class="field-with-top-label overflow-label-visible"
          label="Причина отказа от нашей БГ"
          style="margin-bottom: 100px;"
          maxlength="300"
          counter
          outlined
          hide-bottom-space
          dense
        />
      </q-card-section>
      <q-card-actions
        class="row justify-end q-py-md"
        style="background: #D5EAFA;"
      >
        <q-btn
          size="16px"
          padding="sm lg"
          label="Отмена"
          :disable="loading"
          unelevated
          no-caps
          @click="cancel"
        />
        <q-btn
          padding="sm lg"
          size="16px"
          color="blue-7"
          label="Сохранить"
          :loading="loading"
          :disable="!comment"
          unelevated
          no-caps
          @click="save"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
import {ref, watchEffect} from 'vue'
import {
  ApplicationsGridItem,
} from 'src/models/Applications/ApplicationsGridItem'
import {useProfileStore} from 'src/stores/Login/useProfileStore'
import {QInput} from 'quasar'
import {useApplicationComment} from 'src/uses/Applications/useApplicationComment'

const {
  isAgentManager,
} = useProfileStore()

const props = defineProps<{
  item: ApplicationsGridItem,
  modelValue: boolean,
}>()

const emit = defineEmits<{
  (e: 'update:model-value', val: boolean): void,
  (e: 'change'): void,
}>()

const {
  loading,
  status,
  update,
} = useApplicationComment()

const comment = ref('')

const save = async () => {
  if (!comment.value) {
    return
  }

  await update(props.item.id, comment.value)
  if (status.value === 200) {
    emit('change')
    emit('update:model-value', false)
  }
}

const cancel = () => {
  comment.value = props.item.additional_information.comment || ''
  emit('update:model-value', false)
}

watchEffect(() => {
  comment.value = props.item.additional_information.comment || ''
})
</script>
