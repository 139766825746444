<template>
  <div v-if="nextToFocus">
    <q-intersection
      transition="scale"
      once
    >
      <div class="applicationNewNexFieldHelper row items-center text-weight-medium q-py-md">
        <div
          class="col-12 q-px-md cursor-pointer"
          @click="focusNext"
        >
          <template v-if="nextToFocus === 'rnt'">
            Укажите реестровый номер торгов
            <span>(при наличии)</span>
          </template>
          <template v-if="nextToFocus === 'law'">
            Укажите закон
          </template>
          <template v-if="nextToFocus === 'rntNotInEISReason'">
            Укажите причину отсутствия в ЕИС
          </template>
          <template v-if="nextToFocus === 'provisionType'">
            Укажите тип обеспечения
          </template>
          <template v-if="nextToFocus === 'bgSum'">
            Укажите сумму гарантии
          </template>
          <template v-if="nextToFocus === 'advancePercent'">
            Укажите процент аванса
          </template>
          <template v-if="nextToFocus === 'dateFrom'">
            Укажите дату начала действия банковской гарантии
          </template>
          <template v-if="nextToFocus === 'dateTo'">
            Укажите дату окончания действия банковской гарантии
          </template>
          <template v-if="nextToFocus === 'bgForm'">
            Укажите форму банковской гарантии
          </template>
        </div>
      </div>
    </q-intersection>
  </div>
</template>

<script setup lang="ts">

import {useApplicationsNew} from 'src/stores/Applications/ApplicationsNew/useApplicationsNew'
import {storeToRefs} from 'pinia'

const applicationsNewStore = useApplicationsNew()
const {
  focusNext,
} = applicationsNewStore

const {
  nextToFocus,
} = storeToRefs(applicationsNewStore)
</script>

<style lang="scss">
.applicationNewNexFieldHelper {
  background: $green-1;
  color: $green-14;
  font-size: 14px;

  span {
    color: $grey-7;
  }
}
</style>
