import {ref} from 'vue'
import {RntData} from 'src/models/Applications/New/RntData'
import {usePostApi} from 'src/uses/Api/usePostApi'

export const useApplicationsNewFormRntData = () => {
  const {
    loading,
    post,
    status,
    response,
    errors,
    abort,
  } = usePostApi<RntData, {rnt: string, lot: number}>()

  const rntData = ref<RntData>()
  const rntError = ref('')

  const clearRntError = () => {
    abort()
    rntError.value = ''
  }

  const load = async (rnt: string, lot: number) => {
    await post(
      'v1/product/rnt',
      {
        lot,
        rnt
      }
    )

    if (status.value === 200 && response.value) {
      rntData.value = response.value
      clearRntError()
    }

    if (errors.value) {
      rntError.value = errors.value.rnt || ''
    }
  }

  return {
    rntData,
    loading,
    load,
    rntError,
    clearRntError,
  }
}
