<template>
  <div
    :class="classes"
    @click="clickOnStatus"
  >
    <strong class="text-no-wrap ellipsis full-width block">{{ label(item.status) }}</strong>
    <div class="row items-center no-wrap">
      <div class="col" />
      <span
        v-if="showIcon"
        class="ApplicationGridStatus--request"
      >
        <q-icon
          style="margin-right: 2px; margin-top: -3px"
          :color="isOrange(item.sub_status) || newRequest || hasChanges || isSignStatuses(item.sub_status) ? 'orange' : 'green-14'"
          size="16px"
          :name="iconName"
        />
      </span>
      <date-item
        v-if="statusDate"
        :model-value="statusDate"
        class="block"
      />
      <div class="col" />
    </div>
    <q-tooltip
      v-if="showTooltip"
      :delay="700"
      :offset="[0, 10]"
    >
      <div v-if="subStatus === ApplicationSubStatusEnum.waiting_required">
        <template v-if="(props.item.waiting_completion_orders || []).length === 0">
          Заявка находится в ожидании ссылки
        </template>
        <template v-else>
          Заявка находится в ожидании исполнения доп. запроса по связанным заявкам:<br>
          <div
            v-for="item in (props.item.waiting_completion_orders || [])"
            :key="item.number"
          >
            {{ item.number }}
          </div>
        </template>
      </div>
      <div v-if="statusComment && isRed(subStatus)">
        {{ statusComment }}
      </div>
      <div v-if="newRequest">
        По заявке есть дополнительный запрос. Перейдите в центр управления заявкой.
      </div>
      <div v-if="hasChanges">
        В заявке есть изменения. Перейдите в центр управления заявкой.
      </div>
      <div v-if="filled && isDraft(subStatus)">
        Заявка готова к отправке на проверку.
      </div>
      <div v-if="isSignStatuses(subStatus)">
        По заявке требуется подписание документов клиентом
      </div>
      <div v-if="isShowIconForBgProjectCustomerAgreement()">
        Клиент подписал форму банковской гарантии
      </div>
      <stop-factors-result
        v-if="props.item.stop_factor_results.length"
        :model-value="props.item.stop_factor_results"
      />
      <div v-if="showComment">
        {{ props.item.additional_information.comment }}
      </div>
    </q-tooltip>
  </div>
</template>

<script setup lang="ts">
import {computed} from 'vue'
import {ApplicationsGridItem} from 'src/models/Applications/ApplicationsGridItem'
import {useStatus} from 'src/uses/Applications/useStatus'
import DateItem from 'src/components/DateItem.vue'
import StopFactorsResult from 'src/components/Applications/StopFactorsResult.vue'
import {useProfileStore} from 'src/stores/Login/useProfileStore'
import {
  useApplicationControlCenterStatuses
} from 'src/uses/Applications/ControlCenter/useApplicationControlCenterStatuses'
import {ApplicationSubStatusEnum} from 'src/models/Applications/ApplicationSubStatusEnum'
import {useQuasar} from 'quasar'
import {usePosthogStore} from 'stores/Posthog/usePosthogStore'

const props = defineProps<{
  item: ApplicationsGridItem
}>()

const {
  isAgentManager,
} = useProfileStore()

const {
  isDraft,
  isClientRejected,
  isRed,
  isOrange,
  color,
  bgColor,
  label,
} = useStatus()

const $q = useQuasar()

const subStatus = computed(() => props.item.sub_status)
const newRequest = computed(() => props.item.new_request)
//сonst newNotice = computed(() => props.item.new_notice)
const filled = computed(() => props.item.filled)
const statusComment = computed(() => props.item.status_comment)
const hasChanges = computed(() => props.item.new_documents || props.item.new_messages)
const statusDate = computed(() => props.item.status_date)
const showComment = computed(() => {
  return isAgentManager()
    && isClientRejected(subStatus.value)
    && props.item.additional_information.comment
})

const showTooltip = computed(() => {
  return (statusComment.value && isRed(subStatus.value))
    || subStatus.value === ApplicationSubStatusEnum.waiting_required
    || newRequest.value
    || hasChanges.value
    || (filled.value && isDraft(subStatus.value))
    || props.item.stop_factor_results.length
    || showComment.value
    || isSignStatuses(subStatus.value)
    || isShowIconForBgProjectCustomerAgreement()
})

const showIcon = computed(() => {
  return newRequest.value
    || subStatus.value === ApplicationSubStatusEnum.waiting_required
    || hasChanges.value
    || (isDraft(subStatus.value) && filled.value)
    || isSignStatuses(subStatus.value)
    || isShowIconForBgProjectCustomerAgreement()
})
const {sendEvent} = usePosthogStore()

const isShowIconForBgProjectCustomerAgreement = () => {
  return subStatus.value === ApplicationSubStatusEnum.bg_project_customer_agreement && isBgFormHasECP()
}

const isBgFormHasECP = () => {
  const bgForm = props.item.documents
    .find(item => {
      return item.code === 'BG_FORM'
    })

  return bgForm && bgForm.status === 'signed'
}

const {
  isSignStatuses
} = useApplicationControlCenterStatuses()

const iconName = computed(() => {
  if (isDraft(props.item.sub_status) || isShowIconForBgProjectCustomerAgreement()) {
    return 'svguse:icons.svg?2#app-check-circle-bold'
  }

  if (isSignStatuses(props.item.sub_status)) {
    return 'svguse:icons.svg?2#app-pencil-circle-bold'
  }

  return 'svguse:icons.svg?2#app-exclamation-circle-bold'
})

const classes = computed(() => {
  return {
    ApplicationGridStatus: true,
    isMobile: $q.platform.is.mobile,
    'q-py-sm': true,
    'q-px-md': true,
    'text-center': true,
    [`bg-${bgColor(props.item.sub_status)}`]: !isDraft(props.item.sub_status),
    [`text-${color(props.item.sub_status)}`]: true,
    'bg-grey-2': isDraft(props.item.sub_status)
  }
})

const clickOnStatus = () => {
  sendEvent('Click_on_status_name_in_list_of_applications')
}
</script>

<style lang="scss">
.ApplicationGridStatus {
  border-radius: 6px;
  min-width: 190px !important;
  max-width: 190px !important;
  width: 190px !important;

  &.isMobile {
    min-width: auto !important;
    max-width: none !important;
    width: auto !important;
  }
}
</style>
