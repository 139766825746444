<template>
  <div class="NewsList">
    <div>
      <div class="text-h6 q-mb-md">Новости</div>
      <news-list-filters
        :loading="loading"
        @update:filters="updateFilters"
      />
    </div>
    <div class="row q-col-gutter-sm">
      <div
        v-for="(item, index) in items"
        :key="item.id"
        :class="index < 4 ? 'col-sm-6 col-xs-12' : 'col-sm-3 col-xs-6'"
      >
        <news-list-item
          :item="item"
          :is-big="index < 4"
        />
      </div>
    </div>
    <div class="q-pa-lg flex flex-center">
      <q-pagination
        v-model="paginationPage"
        :max="pagination.pages || 0"
        color="grey"
        :max-pages="6"
        active-color="primary"
        unelevated
        flat
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import {storeToRefs} from 'pinia'
import {onBeforeMount, onBeforeUnmount, watch, ref} from 'vue'
import {useNewsListStore} from 'src/stores/News/useNewsListStore'
import {useNewsListFiltersStore} from 'src/stores/News/useNewsListFiltersStore'
import NewsListFilters from 'src/components/News/NewsListFilters.vue'
import NewsListItem from 'src/components/News/NewsListItem.vue'
const paginationPage = ref(1)
const filtersStore = useNewsListFiltersStore()
const newsListStore = useNewsListStore()
const {
  pagination,
  items,
  loading,

} = storeToRefs(newsListStore)

const {
  load,
  setPage,
  setPerPage,
} = newsListStore

onBeforeMount(async () => {
  setPerPage(8)
  await load()
})

watch(
  paginationPage,
  () => {
    setPage(paginationPage.value)
    load()
  },
  {deep: true}
)

onBeforeUnmount(() => {
  newsListStore.$reset()
  newsListStore.$dispose()
  filtersStore.$reset()
  filtersStore.$dispose()
})

const updateFilters = () => {
  setPage(1)
  load()
}
</script>
