<template>
  <q-btn
    v-if="isBankEmployee() && props.headerInfo.sub_status === ApplicationKikSubStatusEnum.in_bank"
    class="requestButton float-left"
    label="Выставить запрос"
    icon-right="svguse:icons.svg?2#app-information-circle"
    :disable="loading"
    :loading="loading"
    unelevated
    no-caps
    @click="changeStatus(
      {
        id: props.headerInfo.id,
        applicationStatus: ApplicationKikSubStatusEnum.data_request
      }
    )"
  />
</template>

<script setup lang="ts">
import {ControlCenterHeader} from 'src/models/Applications/ControlCenter/ControlCenterHeader'
import {
  useApplicationControlCenterChangeStatusStore
} from 'src/stores/Applications/ControlCenter/ChangeStatus/useApplicationControlCenterChangeStatusStore'
import {useProfileStore} from 'src/stores/Login/useProfileStore'
import {storeToRefs} from 'pinia'
import {
  useApplicationKikControlCenterChangeStatusStore
} from 'stores/Applications/ControlCenter/ChangeStatus/useApplicationKikControlCenterChangeStatusStore'
import {ApplicationKikSubStatusEnum} from 'src/models/Applications/ApplicationKikSubStatusEnum'

const props = defineProps<{
  headerInfo: ControlCenterHeader
}>()

const {
  changeStatus,
} = useApplicationKikControlCenterChangeStatusStore(props.headerInfo?.id)()
const {loading} = storeToRefs(useApplicationControlCenterChangeStatusStore(props.headerInfo?.id)())

const {
  isBankEmployee,
} = useProfileStore()

</script>

<style scoped lang="scss">

</style>
