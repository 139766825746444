import {defineStore} from 'pinia'
import {computed} from 'vue'
import {email, integer, maxLength, minLength, phoneValidator, required} from 'src/utils/i18n-validators'
import {OpfEnum} from 'src/models/OpfEnum'
import {AgentIndividual} from 'src/models/Profile/Requisites/AgentIndividual'
import {useAgentEdit} from 'src/stores/Profiles/Agents/useAgentEdit'

export const useAgentIndividualEditStore = defineStore('profiles-agent-individual-edit', () => {
  const {
    agentData,
    offerOriginalFile,
    offerSigFile,
    offer,
    platform,
    loading,
    response,
    status,
    error,
    errors,
    validation,
    success,
    update,
    loadAgent,
  } = useAgentEdit<AgentIndividual>(
    OpfEnum.INDIVIDUAL,
    {
      inn: {
        required,
        integer,
        minLength: minLength(10),
        maxLength: maxLength(12),
      },
      contact_email: {
        required,
        email,
      },
      contact_phone: {
        required,
        phoneValidator,
      },
      paymentAccount: {},
    },
  )

  const fullName = computed(() => {
    if (!agentData.value) {
      return ''
    }

    return `${agentData.value.first_name} ${agentData.value.middle_name} ${agentData.value.last_name}`
  })

  return {
    agentData,
    offerOriginalFile,
    offerSigFile,
    offer,
    platform,
    loading,
    response,
    status,
    error,
    errors,
    validation,
    success,
    update,
    loadAgent,
    fullName,
  }
})
