import {defineStore} from 'pinia'
import {computed, ref, watch} from 'vue'
import {ProductEnum} from 'src/models/Applications/ProductEnum'
import {useApplicationsNewInn} from 'src/stores/Applications/ApplicationsNew/useApplicationsNewInn'
import {useApplicationNewProductList} from 'src/stores/Applications/ApplicationsNew/useApplicationNewProductList'
import {useApplicationsNewFormRntData} from 'src/stores/Applications/ApplicationsNew/useApplicationsNewFormRntData'
import {GetProductListKikPayload, GetProductListPayload} from 'src/models/Applications/New/GetProductListPayload'
import {useApplicationNewBeneficiaries} from 'src/stores/Applications/ApplicationsNew/useApplicationNewBeneficiaries'
import {useApplicationsNewForm} from 'src/stores/Applications/ApplicationsNew/useApplicationsNewForm'
import {ProvisionTypeEnum} from 'src/models/Applications/ProvosionTypeEnum'
import {useApplicationsNewSend} from 'src/stores/Applications/ApplicationsNew/useApplicationNewSend'
import {ApplicationCreatePayload} from 'src/models/Applications/New/ApplicationCreatePayload'
import {RntNotInEISReasonEnum} from 'src/models/Enums/RntNotInEISReasonEnum'
import {useRouter} from 'vue-router'
import {useApplicationsNewAgents} from 'src/stores/Applications/ApplicationsNew/useApplicationsNewAgents'
import {useAuthStore} from 'src/stores/Login/useAuthStore'
import {UserRoleEnum} from 'src/models/User/UserRoleEnum'
import {useApplicationsNewEmployee} from 'src/stores/Applications/ApplicationsNew/useApplicationsNewEmployee'
import {useApplicationsNewSetEmail} from 'src/stores/Applications/ApplicationsNew/useApplicationsNewSetEmail'
import {useLoadingStore} from 'src/stores/useLoadingStore'
import {useApplicationNewClone} from 'stores/Applications/ApplicationsNew/useApplicationNewClone'
import {ApplicationNewModeEnum} from 'src/models/Applications/New/ApplicationNewModeEnum'
import {useApplicationNewSendClone} from 'stores/Applications/ApplicationsNew/useApplicationNewSendClone'
import {ApplicationClonePayload} from 'src/models/Applications/New/ApplicationClonePayload'
import {useApplicationsNewCreditForm} from 'stores/Applications/ApplicationsNew/useApplicationsNewCreditForm'
import ApplicationNewSelectBanks from 'components/Applications/ApplicationNew/ApplicationNewSelectBanks.vue'

export const useApplicationsNew = defineStore('applications-new', () => {
  const mode = ref<ApplicationNewModeEnum>(ApplicationNewModeEnum.standard)
  const applicationNewClone = useApplicationNewClone()
  const applicationsNewFormRntData = useApplicationsNewFormRntData()
  const applicationsNewForm = useApplicationsNewForm(mode)
  const applicationsNewCreditForm = useApplicationsNewCreditForm()
  const applicationsNewAgents = useApplicationsNewAgents()
  const applicationsNewEmployee = useApplicationsNewEmployee()
  const applicationsNewInn = useApplicationsNewInn(applicationsNewAgents.selected)
  const applicationNewProductList = useApplicationNewProductList()
  const applicationNewKikProductList = useApplicationNewProductList()
  const applicationNewBeneficiaries = useApplicationNewBeneficiaries(
    applicationsNewFormRntData.rntData,
    applicationsNewForm.provisionType,
    applicationsNewForm.oneBg
  )
  const applicationsNewSetEmail = useApplicationsNewSetEmail()
  const applicationsNewSend = useApplicationsNewSend()
  const applicationsNewSendClone = useApplicationNewSendClone()
  const authStore = useAuthStore()

  const loadingStore = useLoadingStore()

  const router = useRouter()

  const tab = ref(/*ProductEnum.EBG*/)
  const selectedTabs = ref<ProductEnum[]>([])

  const productsRef = ref<typeof ApplicationNewSelectBanks>()
  const creditProductsRef = ref<typeof ApplicationNewSelectBanks>()

  const showSelectInn = computed(() => {
    return (
      authStore.profile
      && [
        UserRoleEnum.Agent,
        UserRoleEnum.HeadEmployee,
        UserRoleEnum.Employee,
      ].includes(authStore.profile.role)
    )
    || applicationsNewAgents.selected.value
  })

  const showSelectAgent = computed(() => {
    return authStore.profile
      && [
        UserRoleEnum.Operator,
        UserRoleEnum.AgentManager,
      ].includes(authStore.profile.role)
  })

  const showSelectEmployee = computed(() => {
    return showSelectAgent.value && applicationsNewAgents.selected.value
  })

  const email = ref<string>()

  const emailDialog = ref(false)

  const showTabs = computed(
    () => {
      return !applicationsNewInn.loading.value
        && !!applicationsNewInn.selected.value
        && !applicationsNewInn.loadingClientData.value
        && applicationsNewInn.clientData.value
        && (
          !showSelectAgent.value
          || applicationsNewAgents.selected.value
        )
        && (
          !showSelectEmployee.value
          || applicationsNewEmployee.selected.value
        )
        && [
          ApplicationNewModeEnum.clone,
          ApplicationNewModeEnum.standard,
        ].includes(mode.value)
    })
  const payload = computed<GetProductListPayload>(() => ({
    advance_amount: null,
    advance_needed: false,
    agentId: applicationsNewAgents.selected.value ? applicationsNewAgents.selected.value.id : '',
    beneficiaries: applicationNewBeneficiaries.beneficiariesForProducts.value,
    commercial_purchase: applicationsNewForm.commercialPurchase.value,
    customer_form_needed: applicationsNewForm.customerBgForm.value,
    decline: null,
    guarantee_days: applicationsNewForm.countDays.value,
    guarantee_from: applicationsNewForm.dateFrom.value,
    guarantee_issue_form: applicationsNewForm.bgForm.value,
    guarantee_to: applicationsNewForm.dateTo.value,
    guarantee_total: applicationsNewForm.bgSum.value || 0,
    hasDataInEis: !applicationsNewForm.rntNotInEIS.value,
    inn: applicationsNewInn.selected.value ? applicationsNewInn.selected.value.inn : '',
    is_concrete_start_date: !applicationsNewForm.fromIssueDate.value,
    link_to_purchase: applicationsNewForm.rntLink.value,
    lot: applicationsNewForm.lot.value,
    mailhardcopy: applicationsNewForm.mailhardcopy.value,
    obligation_type: applicationsNewForm.law.value,
    provision_type: applicationsNewForm.provisionType.value,
    purchase_closed: applicationsNewForm.closedPurchase.value,
    purchase_goz: applicationsNewForm.goz275Fz.value,
    re_provision: applicationsNewForm.oversupply.value,
    reason_why_purchase_not_available_in_eis: applicationsNewForm.rntNotInEISReason.value,
    rnt: applicationsNewForm.rnt.value,
    single_guarantee: applicationsNewForm.oneBg.value,
    type: ProductEnum.EBG,
  }))

  const onlyKikPayload = computed<GetProductListKikPayload>(() => {
    return {
      agentId: applicationsNewAgents.selected.value ? applicationsNewAgents.selected.value.id : '',
      inn: applicationsNewInn.selected.value ? applicationsNewInn.selected.value.inn : '',
      type: ProductEnum.KIK,
      credit_amount: applicationsNewCreditForm.creditAmount.value ? applicationsNewCreditForm.creditAmount.value.toString() : '',
      credit_months: applicationsNewCreditForm.creditMonths.value ? applicationsNewCreditForm.creditMonths.value : 0,
      credit_type: applicationsNewCreditForm.creditType.value,
      has_state_contracts: !!applicationsNewCreditForm.hasStateContracts,
    }
  })

  const disableForm = computed(() => mode.value === ApplicationNewModeEnum.clone)

  const createPayload = computed<ApplicationCreatePayload | null>(() => {
    if (!applicationsNewInn.selected.value) {
      return null
    }

    return {
      agent_id: applicationsNewAgents.selected.value ? applicationsNewAgents.selected.value.id : undefined,
      employee_id: applicationsNewEmployee.selected.value ? applicationsNewEmployee.selected.value.id : undefined,
      client_inn: applicationsNewInn.selected.value.inn,
      client_title: applicationsNewInn.selected.value.name,
      manual_selected_platform: 'tl',
      product_items: [
        ...applicationNewProductList.selected.value
          .map(product => {
            return {
              ...payload.value,
              beneficiaries: applicationNewBeneficiaries.beneficiariesForProductsCreate.value,
              product_id: product.id,
            }
          }),
        ...applicationNewKikProductList.selected.value
          .map(product => {
            return {
              ...onlyKikPayload.value,
              product_id: product.id,
            }
          })
      ]
    }
  })

  const clonePayload = computed<ApplicationClonePayload | null>(() => {
    if (!applicationsNewInn.selected.value) {
      return null
    }

    return {
      product_items: applicationNewProductList.selected.value
        .map(product => {
          return {
            product_id: product.id,
          }
        })
    }
  })

  const hasEBG = computed(() => selectedTabs.value.find(t => t === ProductEnum.EBG))
  const hasKIK = computed(() => selectedTabs.value.find(t => t === ProductEnum.KIK))

  const removeProduct = (product: ProductEnum) => {
    if (selectedTabs.value.length === 0) {
      return
    }

    const index = selectedTabs.value.findIndex(p => p === product)

    if (index === -1) {
      return
    }

    if (product === ProductEnum.KIK) {
      applicationsNewCreditForm.setDefault()
      applicationNewKikProductList.selected.value = []
    }
    if (product === ProductEnum.EBG) {
      applicationsNewForm.setDefault()
      applicationNewProductList.selected.value = []
      applicationNewBeneficiaries.selectedBeneficiaries.value = []
    }
    selectedTabs.value.splice(index, 1)

    if (tab.value === product) {
      tab.value = selectedTabs.value.length > 0 ? selectedTabs.value[0] : undefined
    }
  }

  const isBlocked = (name: string) => {
    return applicationsNewForm.isBlocked(name) || disableForm.value
  }

  const rntDataLoad = () => {
    if (!applicationsNewForm.rnt.value || !applicationsNewForm.lot.value) {
      return
    }
    if (applicationsNewForm.rntNotInEIS.value
      && applicationsNewForm.rntNotInEISReason.value
      && applicationsNewForm.rntNotInEISReason.value === RntNotInEISReasonEnum.astgoz
    ) {
      return
    }
    applicationsNewFormRntData.load(
      applicationsNewForm.rnt.value,
      applicationsNewForm.lot.value
    )
  }

  const create = async () => {
    if (!createPayload.value) {
      return
    }

    if (showSelectAgent.value && !email.value) {
      emailDialog.value = true

      return
    }

    if (mode.value !== ApplicationNewModeEnum.clone) {
      loadingStore.showLoading('Пожалуйста подождите, Заявка создается.\nЭто займет несколько секунд...')
      await applicationsNewSend.create(createPayload.value)
      loadingStore.hideLoading()
      if (!applicationsNewSend.response.value || applicationsNewSend.status.value !== 201) {
        return
      }

      if (showSelectAgent.value && email.value) {
        await applicationsNewSetEmail.putEmail(
          applicationsNewSend.response.value.order_id,
          email.value
        )
      }

      await router.push({
        name: 'application-item',
        params: {
          formId: applicationsNewSend.response.value?.order_id
        }
      })
    }

    if (mode.value === ApplicationNewModeEnum.clone
      && applicationNewClone.selectedOriginalItem.value
      && clonePayload.value
    ) {
      loadingStore.showLoading('Пожалуйста подождите, Заявка создается.\nЭто займет несколько секунд...')
      await applicationsNewSendClone.clone(
        clonePayload.value,
        applicationNewClone.selectedOriginalItem.value.id
      )
      loadingStore.hideLoading()

      await router.push({
        name: 'application-item',
        params: {
          formId: applicationsNewSendClone.response.value?.multi_order_id
        }
      })
    }
  }

  const applySumFromRnt = () => {
    if (!applicationsNewFormRntData.rntData.value) {
      return
    }

    if (ProvisionTypeEnum.Execution === applicationsNewForm.provisionType.value) {
      applicationsNewForm.bgSum.value = applicationsNewFormRntData.rntData.value.lot_contract_guarantee || undefined

      applicationsNewForm.bgSum.value = !applicationsNewForm.bgSum.value && applicationsNewFormRntData.rntData.value.beneficiaries[0]
        ? applicationsNewFormRntData.rntData.value.beneficiaries
          .reduce((a, b) => a + b.lot_contract_guarantee || 0, 0)
        : undefined
    }
    if (ProvisionTypeEnum.Participation === applicationsNewForm.provisionType.value) {
      applicationsNewForm.bgSum.value = applicationsNewFormRntData.rntData.value.lot_guarantee || undefined

      applicationsNewForm.bgSum.value = !applicationsNewForm.bgSum.value && applicationsNewFormRntData.rntData.value.beneficiaries[0]
        ? applicationsNewFormRntData.rntData.value.beneficiaries[0].lot_guarantee
        : undefined
    }
  }

  const applyFromRntData = () => {
    if (!applicationsNewFormRntData.rntData.value) {
      return
    }

    applicationsNewForm.nmcDiscount.value = false
    applicationsNewForm.law.value = applicationsNewFormRntData.rntData.value.law || null
    applicationsNewForm.block('law')
    applySumFromRnt()
  }

  watch(
    tab,
    () => {
      if (!tab.value) {
        return
      }

      selectedTabs.value.push(tab.value)
      selectedTabs.value = selectedTabs.value
        .filter((tab, index) => selectedTabs.value.findIndex(t => t === tab) === index)
    }
  )

  watch(applicationsNewForm.provisionType, applySumFromRnt, {immediate: true})
  watch(applicationsNewFormRntData.rntData, () => {
    applicationsNewForm.clearFieldError('rnt')
    applyFromRntData()
  })

  watch(
    applicationsNewForm.data,
    () => {
      if (applicationsNewForm.percentage.value !== 100) {
        return
      }

      if (mode.value === ApplicationNewModeEnum.clone) {
        return
      }

      applicationNewProductList.load(payload.value)
    },
    {
      deep: true,
    }
  )

  watch(
    applicationsNewCreditForm.data,
    () => {
      if (applicationsNewCreditForm.percentage.value !== 100) {
        return
      }

      if (mode.value === ApplicationNewModeEnum.clone) {
        return
      }

      applicationNewKikProductList.load(onlyKikPayload.value)
    },
    {
      deep: true,
    }
  )

  watch(
    applicationsNewForm.rntNotInEIS,
    () => {
      if (applicationsNewForm.rntNotInEIS.value) {
        applicationsNewFormRntData.clearRntError()

        return
      }

      rntDataLoad()
    }
  )

  watch(
    applicationsNewForm.lot,
    () => {
      rntDataLoad()
    }
  )

  watch(
    applicationNewBeneficiaries.beneficiaries,
    () => {
      if (!applicationNewBeneficiaries.enabledOneBgForAllBeneficiaries.value) {
        return
      }

      applicationsNewForm.oneBg.value = true
    }
  )

  watch(
    applicationsNewInn.selected,
    () => {
      if (!applicationsNewInn.selected.value
        || !applicationNewClone.selectedOriginalItem.value
      ) {
        mode.value = ApplicationNewModeEnum.standard
        applicationNewClone.selectedOriginalItems.value = []
      }
      if (applicationsNewInn.selected.value && applicationNewClone.selectedOriginalItem.value) {
        if (mode.value === ApplicationNewModeEnum.clone) {
          applicationNewClone.selectedOriginalItems.value = []
          mode.value = ApplicationNewModeEnum.standard
        } else {
          mode.value = ApplicationNewModeEnum.clone
        }
      }

      applicationsNewForm.setDefault()
      applicationsNewCreditForm.setDefault()
      tab.value = undefined
      applicationsNewFormRntData.clearRntError()
      applicationsNewFormRntData.rntData.value = undefined
      applicationNewProductList.selected.value = []
      applicationNewProductList.products.value = []
    }
  )

  watch(
    applicationsNewAgents.selected,
    () => {
      applicationsNewEmployee.selected.value = undefined
      if (!applicationNewClone.selectedOriginalItem.value && applicationsNewAgents.selected.value) {
        applicationsNewInn.selected.value = null
        applicationsNewInn.load()
      }
    }
  )

  watch(
    applicationNewClone.selectedOriginalItem,
    async () => {
      if (applicationNewClone.selectedOriginalItem.value && applicationNewClone.selectedOriginalItem.value.client) {
        applicationsNewInn.selected.value = {
          inn: applicationNewClone.selectedOriginalItem.value.client.inn,
          name: applicationNewClone.selectedOriginalItem.value.client.title,
        }
      }
      if (showSelectAgent.value && applicationNewClone.selectedOriginalItem.value && applicationNewClone.selectedOriginalItem.value.agent) {
        applicationsNewAgents.selected.value = {
          email: applicationNewClone.selectedOriginalItem.value.agent.email,
          id: applicationNewClone.selectedOriginalItem.value.agent.id,
          inn: applicationNewClone.selectedOriginalItem.value.agent.inn,
          title: applicationNewClone.selectedOriginalItem.value.agent.title,
        }
      }
      loadingStore.showLoading()
      const cloneData = await applicationNewClone.getData()
      loadingStore.hideLoading()
      applicationsNewForm.setDefault()
      applicationsNewCreditForm.setDefault()
      if (cloneData) {
        applicationsNewForm.customerBgForm.value = !!cloneData.customer_form_needed
        applicationsNewForm.dateFrom.value = cloneData.guarantee_from
        applicationsNewForm.dateTo.value = cloneData.guarantee_to
        applicationsNewForm.bgForm.value = cloneData.guarantee_issue_form
        applicationsNewForm.bgSum.value = cloneData.guarantee_total
        applicationsNewForm.rnt.value = cloneData.rnt
        applicationsNewForm.rntNotInEISReason.value = cloneData.reason_why_purchase_not_available_in_eis
        applicationsNewForm.rntNotInEIS.value = !!cloneData.reason_why_purchase_not_available_in_eis
        applicationsNewForm.lot.value = cloneData.lot
        applicationsNewForm.mailhardcopy.value = cloneData.mailhardcopy
        applicationsNewForm.law.value = cloneData.obligation_type
        applicationsNewForm.provisionType.value = cloneData.provision_type
        applicationsNewForm.goz275Fz.value = cloneData.purchase_goz
        applicationNewProductList.products.value = cloneData.products
        applicationNewProductList.selected.value = cloneData.products.filter(product => product.required)
      }
    },
    {
      deep: true
    }
  )

  return {
    innSelected: applicationsNewInn.selected,
    innsLoading: applicationsNewInn.loading,
    innItems: applicationsNewInn.items,
    loadingClientData: applicationsNewInn.loadingClientData,
    loadClientData: applicationsNewInn.load,
    clientData: applicationsNewInn.clientData,
    productData: applicationsNewInn.productsData,

    loadAgents: applicationsNewAgents.loadAgents,
    selectedAgent: applicationsNewAgents.selected,
    agentsLoading: applicationsNewAgents.loading,
    agents: applicationsNewAgents.items,

    loadEmployees: applicationsNewEmployee.loadEmployees,
    selectedEmployee: applicationsNewEmployee.selected,
    employeesLoading: applicationsNewEmployee.loading,
    employees: applicationsNewEmployee.items,

    loadProducts: applicationNewProductList.load,
    products: applicationNewProductList.products,
    selectedProducts: applicationNewProductList.selected,
    loadingProducts: applicationNewProductList.loading,
    productsErrors: applicationNewProductList.errors,

    creditLoadProducts: applicationNewKikProductList.load,
    creditProducts: applicationNewKikProductList.products,
    creditSelectedProducts: applicationNewKikProductList.selected,
    creditLoadingProducts: applicationNewKikProductList.loading,
    creditProductsErrors: applicationNewKikProductList.errors,

    rntData: applicationsNewFormRntData.rntData,
    rntDataLoading: applicationsNewFormRntData.loading,
    rntError: applicationsNewFormRntData.rntError,
    clearRntError: applicationsNewFormRntData.clearRntError,

    beneficiaries: applicationNewBeneficiaries.beneficiaries,
    selectedBeneficiaries: applicationNewBeneficiaries.selectedBeneficiaries,
    selectedBeneficiariesSum: applicationNewBeneficiaries.selectedBeneficiariesSum,
    enabledOneBgForAllBeneficiaries: applicationNewBeneficiaries.enabledOneBgForAllBeneficiaries,

    rntNotInEisDialog: applicationsNewForm.rntNotInEisDialog,
    show: applicationsNewForm.show,
    showRntNotInEisReasonInsteadRnt: applicationsNewForm.showRntNotInEisReasonInsteadRnt,
    rnt: applicationsNewForm.rnt,
    lot: applicationsNewForm.lot,
    law: applicationsNewForm.law,
    rntNotInEIS: applicationsNewForm.rntNotInEIS,
    rntNotInEISReason: applicationsNewForm.rntNotInEISReason,
    rntLink: applicationsNewForm.rntLink,
    oversupply: applicationsNewForm.oversupply,
    goz275Fz: applicationsNewForm.goz275Fz,
    provisionType: applicationsNewForm.provisionType,
    oneBg: applicationsNewForm.oneBg,
    bgSum: applicationsNewForm.bgSum,
    nmcDiscount: applicationsNewForm.nmcDiscount,
    dateFrom: applicationsNewForm.dateFrom,
    dateTo: applicationsNewForm.dateTo,
    countDays: applicationsNewForm.countDays,
    fromIssueDate: applicationsNewForm.fromIssueDate,
    bgForm: applicationsNewForm.bgForm,
    mailhardcopy: applicationsNewForm.mailhardcopy,
    customerBgForm: applicationsNewForm.customerBgForm,
    percentage: applicationsNewForm.percentage,
    blocked: applicationsNewForm.blocked,
    block: applicationsNewForm.block,
    unblock: applicationsNewForm.unblock,
    clearAllBlocks: applicationsNewForm.clearAllBlocks,
    lastFocused: applicationsNewForm.lastFocused,
    nextToFocus: applicationsNewForm.nextToFocus,
    emptyRequiredFields: applicationsNewForm.emptyRequiredFields,
    requiredFields: applicationsNewForm.requiredFields,
    fromDateOptions: applicationsNewForm.fromDateOptions,
    toDateOptions: applicationsNewForm.toDateOptions,
    focusNext: applicationsNewForm.focusNext,
    selectedOriginalItems: applicationNewClone.selectedOriginalItems,
    selectedOriginalItem: applicationNewClone.selectedOriginalItem,

    creditAmount: applicationsNewCreditForm.creditAmount,
    creditMonths: applicationsNewCreditForm.creditMonths,
    creditType: applicationsNewCreditForm.creditType,
    hasStateContracts: applicationsNewCreditForm.hasStateContracts,
    creditPercentage: applicationsNewCreditForm.percentage,
    creditData: applicationsNewCreditForm.data,
    creditLastFocused: applicationsNewCreditForm.lastFocused,
    creditEmptyRequiredFields: applicationsNewCreditForm.emptyRequiredFields,
    creditRequiredFields: applicationsNewCreditForm.requiredFields,
    creditNextToFocus: applicationsNewCreditForm.nextToFocus,
    creditSetDefault: applicationsNewCreditForm.setDefault,
    creditFocusNext: applicationsNewCreditForm.focusNext,

    showTabs,
    tab,
    selectedTabs,
    showSelectInn,
    showSelectAgent,
    showSelectEmployee,
    email,
    emailDialog,
    mode,
    disableForm,
    hasEBG,
    hasKIK,

    creditProductsRef,
    productsRef,

    isBlocked,
    rntDataLoad,
    create,
    removeProduct,
  }
})
