<template>
  <q-dialog
    :model-value="modelValue"
    @update:model-value="emit('update:model-value', $event)"
  >
    <q-card
      style="min-width: 400px; max-width: 800px; width: 60vw"
      class="bg-blue-1"
    >
      <div
        style="max-height: 65vh"
        class="scroll"
      >
        <q-card-section>
          <div class="text-h6 q-mb-md">
            Информация по заявке
          </div>

          <div class="ApplicationGridInfo--ListWrapper">
            <key-value-list-item>
              <template #key>№</template>
              {{ props.item.number }}
            </key-value-list-item>

            <key-value-list-item>
              <template #key>ИНН</template>
              {{ props.item.client.inn }}
            </key-value-list-item>

            <key-value-list-item>
              <template #key>Название</template>
              {{ props.item.client.title }}
            </key-value-list-item>

            <key-value-list-item>
              <template #key>РНТ</template>
              {{ props.item.rnt }}
            </key-value-list-item>

            <key-value-list-item>
              <template #key>Сумма</template>
              <money-component :model-value="props.item.guarantee_total" />
            </key-value-list-item>

            <key-value-list-item>
              <template #key>Дней</template>
              {{ props.item.guarantee_days }}
            </key-value-list-item>

            <key-value-list-item>
              <template #key>Комиссия</template>
              <money-component :model-value="props.item.tariff" />
            </key-value-list-item>

            <key-value-list-item>
              <template #key>Банк</template>
              <img
                :src="props.item.bank.logo"
                :alt="props.item.bank.title"
                style="max-height: 30px; position: absolute"
              >
            </key-value-list-item>

            <key-value-list-item>
              <template #key>Тип</template>
              {{ getLawLabel(item.obligation_type) }}
            </key-value-list-item>

            <key-value-list-item>
              <template #key>Статус</template>
              <div class="row no-wrap">
                <application-grid-status
                  class="col"
                  :item="props.item"
                />
                <application-grid-messages-action :item="props.item" />
              </div>
            </key-value-list-item>

            <edit-notification-email-component
              v-if="isAdmin() || isAgentManager()"
              :item="props.item"
              @changed="emit('change')"
            />
            <key-value-list-item v-else>
              <template #key>Почта для уведомлений по заявке</template>
              {{ props.item.additional_information?.email || 'не указана' }}
            </key-value-list-item>
            <edit-payment-component
              :item="props.item"
              @changed="emit('change')"
            />
            <edit-release-date-component
              :item="props.item"
              @changed="emit('change')"
            />
          </div>
        </q-card-section>

        <q-card-section v-if="props.item.agent.id">
          <div class="text-h6 text-no-wrap q-mb-md">
            Агент
          </div>

          <div class="ApplicationGridInfo--ListWrapper">
            <key-value-list-item>
              <template #key>ID</template>
              {{ props.item.agent.id }}
            </key-value-list-item>

            <key-value-list-item>
              <template #key>ID SIGN</template>
              {{ props.item.agent.code }}
            </key-value-list-item>

            <key-value-list-item>
              <template #key>ИНН</template>
              {{ props.item.agent.inn }}
            </key-value-list-item>

            <edit-agent-component
              v-if="isAdmin()"
              :item="props.item"
              @changed="emit('change')"
            />
            <key-value-list-item v-else>
              <template #key>Наименование</template>
              {{ props.item.agent.title }}
            </key-value-list-item>

            <key-value-list-item>
              <template #key>E-mail</template>
              {{ props.item.agent.email }}
            </key-value-list-item>

            <key-value-list-item>
              <template #key>Телефон</template>
              {{ props.item.agent.phone }}
            </key-value-list-item>

            <key-value-list-item>
              <template #key>Комиссионное вознаграждение</template>
              {{ props.item.agent.fee }} %
            </key-value-list-item>
          </div>
        </q-card-section>

        <q-card-section v-if="!isAgentManager()">
          <div class="text-h6 text-no-wrap q-mb-md">
            Агентский менеджер
          </div>

          <div class="ApplicationGridInfo--ListWrapper">
            <key-value-list-item>
              <template #key>ФИО</template>
              {{ props.item.agent_manager.fio }}
            </key-value-list-item>

            <key-value-list-item>
              <template #key>E-mail</template>
              {{ props.item.agent_manager.email }}
            </key-value-list-item>

            <key-value-list-item>
              <template #key>Телефон</template>
              {{ props.item.agent_manager.phone }}
            </key-value-list-item>
          </div>
        </q-card-section>

        <q-card-section v-if="!isOperator()">
          <div class="text-h6 text-no-wrap q-mb-md">
            Оператор
          </div>

          <div class="ApplicationGridInfo&#45;&#45;ListWrapper">
            <key-value-list-item>
              <template #key>ФИО</template>
              {{ props.item.operator?.fio }}
            </key-value-list-item>

            <key-value-list-item>
              <template #key>E-mail</template>
              {{ props.item.operator?.email }}
            </key-value-list-item>

            <key-value-list-item>
              <template #key>Телефон</template>
              {{ props.item.operator?.phone }}
            </key-value-list-item>
          </div>
        </q-card-section>

        <q-card-section>
          <div class="text-h6 text-no-wrap q-mb-md">
            Сотрудник Банка
          </div>

          <div class="ApplicationGridInfo&#45;&#45;ListWrapper">
            <edit-bank-employee-component
              v-if="isAdmin()"
              :item="props.item"
            />
            <key-value-list-item v-else>
              <template #key>ФИО</template>
              {{ props.item.bank_employee?.fio }}
            </key-value-list-item>

            <key-value-list-item>
              <template #key>E-mail</template>
              {{ props.item.bank_employee?.email }}
            </key-value-list-item>

            <key-value-list-item>
              <template #key>Телефон</template>
              {{ props.item.bank_employee?.phone }}
            </key-value-list-item>
          </div>
        </q-card-section>
      </div>

      <q-card-actions align="right">
        <q-btn
          v-close-popup
          label="Ок"
          color="primary"
          padding="10px 20px"
          no-caps
          unelevated
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
import {ApplicationsGridItem} from 'src/models/Applications/ApplicationsGridItem'
import MoneyComponent from 'src/components/MoneyComponent.vue'
import KeyValueListItem from 'src/components/KeyValueListItem.vue'
import ApplicationGridStatus from 'src/components/Applications/ApplicationsGrid/Columns/ApplicationGridStatus.vue'
import ApplicationGridMessagesAction
  from 'src/components/Applications/ApplicationsGrid/Actions/ApplicationGridMessagesAction.vue'
import {useLaw} from 'src/uses/Applications/useLaw'
import {useProfileStore} from 'src/stores/Login/useProfileStore'
import EditPaymentComponent from 'src/components/Applications/ApplicationsGrid/EditPaymentComponent.vue'
import EditNotificationEmailComponent
  from 'src/components/Applications/ApplicationsGrid/EditNotificationEmailComponent.vue'
import EditAgentComponent from 'src/components/Applications/ApplicationsGrid/EditAgentComponent.vue'
import EditBankEmployeeComponent from 'src/components/Applications/ApplicationsGrid/EditBankEmployeeComponent.vue'
import EditReleaseDateComponent from 'components/Applications/ApplicationsGrid/EditReleaseDateComponent.vue'

const {getLawLabel} = useLaw()

const {
  isOperator,
  isAgentManager,
  isAdmin,
} = useProfileStore()

const props = defineProps<{
  item: ApplicationsGridItem,
  modelValue: boolean,
}>()

const emit = defineEmits<{
  (e: 'update:model-value', val: boolean): void,
  (e: 'change'): void,
}>()
</script>

<style lang="scss">
  .ApplicationGridInfo--ListWrapper {
    border-radius: 6px;
    overflow: hidden;
    position: relative;
  }
</style>
