<template>
  <page-block-wrapper>
    <div class="profiles-users">
      <profiles-users-grid />
    </div>
  </page-block-wrapper>
</template>
<script setup lang="ts">
import ProfilesUsersGrid from 'src/components/Profiles/Users/ProfilesUsersGrid.vue'
import PageBlockWrapper from 'src/components/PageBlockWrapper.vue'
import {useMetaStore} from 'src/stores/useMetaStore'

useMetaStore().setTitle('Пользователи')
</script>
