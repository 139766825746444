import {defineStore} from 'pinia'
import {useGridFilters} from 'src/uses/Grid/useGridFilters'
import {ClientsListFilter} from 'src/models/Clients/ClientstListFilter'

const defaultFilters: ClientsListFilter = {
  name: '',
  region: [],
  agent: '',
  agent_code: '',
}

export const useClientsListFiltersStore = defineStore('clients-list-filters', () => {
  const {
    filters,
    clearable,
    getFiltersQuery,
    clear,
  } = useGridFilters(defaultFilters)

  return {
    filters,
    clearable,
    getFiltersQuery,
    clear,
  }
})
