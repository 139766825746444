<template>
  <q-form v-if="agentData">
    <div class="row q-mb-sm">
      <div class="title">Основные реквизиты</div>
    </div>
    <div class="row q-col-gutter-md">
      <div class="col-4 col-xs-12 white-bg-field q-mb-lg">
        <q-input
          v-model="agentData.inn"
          class="field-with-top-label"
          :loading="loading"
          :error-message="getErrorMessage('inn')"
          :error="hasError('inn')"
          :disable="$q.platform.is.mobile"
          autocomplete="new-password"
          label="ИНН"
          outlined
          hide-bottom-space
          dense
          @blur="validation.inn.$validate()"
          @focus="clearError('inn')"
        />
      </div>
      <div class="col-4 col-xs-12 white-bg-field">
        <q-input
          v-model="(agentData as AgentPhysical).snils"
          class="field-with-top-label"
          :loading="loading"
          :error-message="getErrorMessage('snils')"
          :error="hasError('snils')"
          :disable="$q.platform.is.mobile"
          type="text"
          label="СНИЛС"
          mask="###-###-###-##"
          hint="000-000-000-00"
          unmasked-value
          outlined
          hide-bottom-space
          dense
          @blur="validation.snils.$validate()"
          @focus="clearError('snils')"
        />
      </div>
      <div
        v-if="!$q.platform.is.mobile"
        class="col-4"
      />

      <div class="col-4 col-xs-12 white-bg-field">
        <q-input
          v-model="agentData.last_name"
          class="field-with-top-label"
          :loading="loading"
          :error-message="getErrorMessage('last_name')"
          :error="hasError('last_name')"
          :disable="$q.platform.is.mobile"
          label="Фамилия"
          outlined
          hide-bottom-space
          dense
          @blur="validation.last_name.$validate()"
          @focus="clearError('last_name')"
        />
      </div>
      <div class="col-4 col-xs-12 white-bg-field">
        <q-input
          v-model="agentData.first_name"
          class="field-with-top-label"
          :loading="loading"
          :error-message="getErrorMessage('first_name')"
          :error="hasError('first_name')"
          :disable="$q.platform.is.mobile"
          label="Имя"
          outlined
          hide-bottom-space
          dense
          @blur="validation.first_name.$validate()"
          @focus="clearError('first_name')"
        />
      </div>
      <div class="col-4 col-xs-12 white-bg-field">
        <q-input
          v-model="agentData.middle_name"
          class="field-with-top-label"
          :loading="loading"
          :error-message="getErrorMessage('middle_name')"
          :error="hasError('middle_name')"
          :disable="$q.platform.is.mobile"
          label="Отчество"
          outlined
          hide-bottom-space
          dense
          @blur="validation.middle_name.$validate()"
          @focus="clearError('middle_name')"
        />
      </div>

      <div class="col-4 col-xs-12 white-bg-field">
        <q-input
          v-model="(agentData as AgentPhysical).birthday"
          mask="##.##.####"
          placeholder="дд.мм.гггг"
          class="field-with-top-label overflow-label-visible"
          :loading="loading"
          :error-message="getErrorMessage('birthday')"
          :error="hasError('birthday')"
          :disable="$q.platform.is.mobile"
          label="Дата рождения"
          unmasked-value
          outlined
          hide-bottom-space
          dense
          @blur="validation.birthday.$validate()"
          @focus="clearError('birthday')"
        >
          <template
            v-if="!$q.platform.is.mobile"
            #append
          >
            <q-icon
              class="cursor-pointer"
              name="svguse:icons.svg?2#app-calendar"
            />
            <q-popup-proxy
              ref="dateProxy"
              transition-show="scale"
              transition-hide="scale"
            >
              <q-date
                v-model="(agentData as AgentPhysical).birthday"
                mask="DD.MM.YYYY"
                minimal
                @update:model-value="($refs['dateProxy'] as QPopupProxy).hide()"
              />
            </q-popup-proxy>
          </template>
        </q-input>
      </div>

      <div class="col-4 col-xs-12 white-bg-field">
        <q-input
          v-model="agentData.contact_phone"
          class="field-with-top-label"
          :loading="loading"
          :error-message="getErrorMessage('contact_phone')"
          :error="hasError('contact_phone')"
          :disable="$q.platform.is.mobile"
          type="tel"
          label="Телефон контактного лица"
          mask="+# (###) ### - ## - ##"
          hint="+# (###) ### - ## - ##"
          unmasked-value
          fill-mask
          outlined
          hide-bottom-space
          dense
          @blur="validation.contact_phone.$validate()"
          @focus="clearError('contact_phone')"
        />
      </div>
      <div class="col-4 col-xs-12 white-bg-field">
        <q-input
          v-model="agentData.contact_email"
          class="field-with-top-label"
          :loading="loading"
          label="E-mail контактного лица"
          :error-message="getErrorMessage('contact_email')"
          :error="hasError('contact_email')"
          :disable="$q.platform.is.mobile"
          outlined
          hide-bottom-space
          dense
          @blur="validation.contact_email.$validate()"
          @focus="clearError('contact_email')"
        />
      </div>
      <div class="col-8 col-xs-12 white-bg-field">
        <q-input
          v-model="agentData.fact_address"
          class="field-with-top-label"
          :loading="loading"
          :error-message="getErrorMessage('fact_address')"
          :error="hasError('fact_address')"
          :disable="$q.platform.is.mobile"
          label="Фактический адрес"
          outlined
          hide-bottom-space
          dense
          @blur="validation.fact_address.$validate()"
          @focus="clearError('fact_address')"
        />
      </div>
      <div class="col white-bg-field">
        <q-field
          :model-value="(agentData as AgentIndividual).tax_system"
          class="field-with-top-label no-bg q-mb-sm text-no-wrap"
          label="Система налогообложения"
          :error-message="getErrorMessage('tax_system')"
          :error="hasError('tax_system')"
          :disable="$q.platform.is.mobile"
          borderless
          hide-bottom-space
          dense
        >
          <q-btn-toggle
            v-model="(agentData as AgentIndividual).tax_system"
            class="full-width"
            toggle-color="primary"
            color="white"
            text-color="grey-9"
            toggle-text-color="white"
            :disable="$q.platform.is.mobile"
            :options="[
              {label: 'УСН', value: 'SYSTEM_USN'},
              {label: 'ОСН', value: 'SYSTEM_OSN'}
            ]"
            spread
            unelevated
            @change="validation.tax_system.$validate()"
          />
        </q-field>
      </div>
    </div>

    <payment-requisites
      :store="props.store"
      :disable-requisites-changing="props.disableRequisitesChanging"
    />
  </q-form>
</template>

<script setup lang="ts">
import {storeToRefs} from 'pinia'
import {useValidation} from 'src/uses/useValidation'
import {useProfileRequisitesPhysicalStore} from 'src/stores/Profile/Requisites/useProfileRequisitesPhysicalStore'
import {QInput, QPopupProxy} from 'quasar'
import {useAgentPhysicalEditStore} from 'src/stores/Profiles/Agents/useAgentPhysicalEditStore'
import PaymentRequisites from 'src/components/Requisites/PaymentRequisites.vue'
import {AgentPhysical} from 'src/models/Profile/Requisites/AgentPhysical'
import {AgentIndividual} from 'src/models/Profile/Requisites/AgentIndividual'

const props = defineProps<{
  store: typeof useProfileRequisitesPhysicalStore | typeof useAgentPhysicalEditStore,
  disableRequisitesChanging: boolean,
}>()

const profileRequisitesPhysicalStore = props.store()

const {
  agentData,
  validation,
  errors,
  loading,
} = storeToRefs(profileRequisitesPhysicalStore)

const {
  getErrorMessage,
  hasError,
  clearError
} = useValidation(validation, errors)
</script>
