import {useCases} from 'src/uses/useCases'
import {Ref} from 'vue-demi'
import {Validation} from '@vuelidate/core'

export const useValidation = (
  validation: Ref<Validation>,
  errors: Ref<Record<string, string> | undefined>,
) => {
  const {
    camelcase
  } = useCases()

  const clearError = (key: string) => {
    if (errors.value && errors.value[key]) {
      errors.value[key] = ''
    }
  }

  const clearAllErrors = () => {
    errors.value = {}
    validation.value.$reset()
  }

  const hasError = (key: string): boolean => {
    if (
      (validation.value[key] && validation.value[key].$error)
      || (validation.value[camelcase(key)] && validation.value[camelcase(key)].$error)
    ) {
      return true
    }

    return !!getErrorMessage(key)
  }

  const getErrorMessage = (key: string): string | undefined => {
    if (!validation.value[key] && (!errors.value || !errors.value[key])) {
      return undefined
    }

    if (errors.value
      && (errors.value[key] || errors.value[camelcase(key)])
    ) {
      return errors.value[key]
        || errors.value[camelcase(key)]
    }

    return validation.value[key].$errors[0]?.$message || undefined
  }

  return {
    clearError,
    hasError,
    getErrorMessage,
    clearAllErrors,
  }
}
