<template>
  <div
    class="bg-blue-grey-10 text-white"
    style="margin: 0 -16px 16px;"
  >
    <q-expansion-item>
      <template #header>
        <q-item-section>
          <q-item-label class="text-h6">
            История изменений
          </q-item-label>
        </q-item-section>
      </template>
      <q-card
        class="bg-blue-grey-10 text-white"
        style="max-height: 170px; overflow: auto"
      >
        <q-card-section
          v-if="items.length === 0"
          class="row items-center"
        >
          <div class="q-pr-md">
            <div class="rounded-borders q-pa-sm bg-blue-grey-9">
              <q-icon
                name="svguse:icons.svg?2#app-information-circle"
                size="sm"
                color="orange-6"
              />
            </div>
          </div>
          <div class="col">
            Изменения не зафиксированны.
          </div>
        </q-card-section>
        <template
          v-for="(item, index) in items"
          :key="index"
        >
          <q-card-section class="row">
            <div class="q-pr-md q-pt-sm">
              <div class="rounded-borders q-pa-sm bg-blue-grey-9">
                <q-icon
                  name="svguse:icons.svg?2#app-book-open"
                  size="sm"
                  color="orange-6"
                />
              </div>
            </div>
            <div class="col">
              <div class="row">
                <div class="text-h6 col">{{ agentName }}</div>
                <div class="text-subtitle2">{{ item.change_date }}</div>
              </div>

              <div>Наименование банка: <span class="text-subtitle2">{{ item.title }}</span></div>
              <div>БИК: <span class="text-subtitle2">{{ item.bik }}</span></div>
              <div>Корр. счёт: <span class="text-subtitle2">{{ item.correspondentAccount }}</span></div>
              <div>Расчётный счёт: <span class="text-subtitle2">{{ item.paymentAccount }}</span></div>
              <div>{{ item.message }}</div>
            </div>
          </q-card-section>
        </template>
      </q-card>
    </q-expansion-item>
  </div>
</template>

<script setup lang="ts">
import {onBeforeMount} from 'vue'
import {useGetAgentHistory} from 'src/uses/Profiles/Agents/useGetAgentHistory'

const props = defineProps<{
  itemId: string | undefined,
  agentName: string | undefined,
}>()

const {
  items,
  load,
} = useGetAgentHistory()

onBeforeMount(async () => {
  if (!props.itemId || !props.agentName) {
    return
  }

  await load(props.itemId, props.agentName)
})
</script>
