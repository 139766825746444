<template>
  <div class="ListingsGridFilters row q-col-gutter-md">
    <div class="col-6">
      <q-input
        v-model="filters.key"
        :loading="props.loading"
        label="Поиск по ключу"
        outlined
        hide-hint
        hide-bottom-space
        dense
        @change="onSubmit"
        @clear="clearKey"
      >
        <template #prepend>
          <q-icon name="svguse:icons.svg?2#app-search" />
        </template>
        <template #append>
          <q-icon
            v-if="filters.key"
            class="cursor-pointer"
            name="svguse:icons.svg?2#app-x"
            @click="clearKey"
          />
        </template>
      </q-input>
    </div>
    <div class="col-6">
      <q-input
        v-model="filters.value"
        :loading="props.loading"
        label="Поиск по значению"
        outlined
        hide-hint
        hide-bottom-space
        dense
        @change="onSubmit"
        @clear="clearValue"
      >
        <template #prepend>
          <q-icon name="svguse:icons.svg?2#app-search" />
        </template>
        <template #append>
          <q-icon
            v-if="filters.value"
            class="cursor-pointer"
            name="svguse:icons.svg?2#app-x"
            @click="clearValue"
          />
        </template>
      </q-input>
    </div>
  </div>
</template>

<script setup lang="ts">
import {storeToRefs} from 'pinia'
import {
  useListingsItemRecordsGridFiltersStore
} from 'stores/Listings/ListingsRecord/useListingsItemRecordsGridFiltersStore'
const props = defineProps<{
  loading: boolean,
}>()

const emit = defineEmits<{
  (e: 'update:filters'): void,
}>()

const filtersStore = useListingsItemRecordsGridFiltersStore()
const {filters} = storeToRefs(filtersStore)

const onSubmit = () => {
  emit('update:filters')
}

const clearKey = () => {
  filters.value.key = ''
  emit('update:filters')
}

const clearValue = () => {
  filters.value.value = ''
  emit('update:filters')
}
</script>

<style lang="scss">
.ListingsGridFilters {
  .q-field--dense .q-field__label {
    top: 16px;
  }
}
</style>
