import {defineStore} from 'pinia'
import {computed, Ref, ref} from 'vue'
import {required} from 'src/utils/i18n-validators'
import useVuelidate from '@vuelidate/core'
import {usePostApi} from 'src/uses/Api/usePostApi'
import {useGetNewsItemStore} from 'src/stores/Settings/News/useGetNewsItemStore'
import {NewsItem, NewsItemDocuments} from 'src/models/News/NewsItem'
import {NewsTopicEnum} from 'src/models/Enums/NewsTopicEnum'
import {NewsDescriptions} from 'src/models/News/NewsDescriptions'
import {NewsPublicationTypeEnum} from 'src/models/Enums/NewsPublicationTypeEnum'
import {FormHelper} from 'src/helpers/FormHelper'
import {DateHelper} from 'src/helpers/DateHelper'
import {useRouter} from 'vue-router'
import {useValidationRules} from 'src/uses/useValidationRules'

export const useNewsCreateStore = defineStore('settings-news-create', () => {
  const router = useRouter()

  const getNewsItemStore = useGetNewsItemStore()
  const {
    load,
    getNewsRequestData
  } = getNewsItemStore

  const {
    post,
    response,
    status,
    loading,
    error,
    errors,
  } = usePostApi<FormData, FormData>()

  const {
    getRulesFromState,
  } = useValidationRules()

  const newDocument = ref<File | null>(null)
  const newDocumentName = ref('')
  const documents = ref<NewsItemDocuments[]>([])
  const deletedDocuments = ref<string[]>([])
  const newPicture = ref<File | null>(null)

  const topicsForSelect = ref(
    Object.values(NewsTopicEnum).map(topic => {
      return {
        label: NewsDescriptions.getNewsTopicDescription((topic)),
        value: topic
      }
    })
  )

  const publicationTypeForSelect = ref(
    Object.values(NewsPublicationTypeEnum).map(publicationType => {
      return {
        label: NewsDescriptions.getNewsPublicationTypeDescription((publicationType)),
        value: publicationType
      }
    })
  )

  const publicationTimeForSelect = ref(
    DateHelper.timeArr().map(time => {
      return {
        label: time,
        value: time
      }
    })
  )

  const newsCreateData = ref<NewsItem>({
    id: '',
    title: '',
    body: '',
    created: '',
    publication_date: '',
    publication_time: '',
    publication_type: null,
    status: null,
    topic: null,
    important: false,
    documents: [],
    picture: '',
  })

  const success = ref(false)
  const date = ref('')
  const time = ref('00:00')

  const getDate = (date = '') => {
    return date.split(' ')[0]
  }

  const getTime = (date = '') => {
    return date.split(' ')[1]
  }

  const rules = computed(() => {
    return {
      ...getRulesFromState(newsCreateData.value),
      title: {
        required,
      },
      topic: {
        required,
      },
      picture: {},
    }
  })

  const addDocument = () => {
    if (!newDocument.value) {
      return
    }
    const doc = {
      title: newDocumentName.value,
      name: newDocument.value.name,
      file: newDocument.value,
      size: newDocument.value.size / 1024
    }

    documents.value.push(doc)
    newDocumentName.value = ''
    newDocument.value = null
  }

  const removeDocument = (document: NewsItemDocuments) => {
    if (!deletedDocuments.value) {
      return
    }

    const ind = documents.value.findIndex(d => d.id === document.id || d.title === document.title)
    documents.value.splice(ind, 1)
    if (document.id) {
      deletedDocuments.value.push(document.id)
    }
  }

  const removePicture = () => {
    newsCreateData.value.picture = null
    newPicture.value = null
  }

  const getPictureName = () => {
    if (newPicture.value?.name) {
      return newPicture.value?.name
    }

    return newsCreateData.value.picture
  }

  const validation = useVuelidate<Ref<NewsItem>>(rules, newsCreateData)

  const getDataToSave = () => {
    const documentsTitles = ref()
    const documentsFiles = ref()

    documentsTitles.value = {}
    documentsFiles.value = {}

    documents.value.forEach((doc, index) => {
      if (typeof doc.file === 'string') {
        return
      }
      const keyFile = `documents_files[${index}]`
      const keyTitle = `documents_titles[${index}]`
      if (documentsTitles.value) {
        documentsTitles.value[keyTitle] = doc.title
      }
      if (documentsFiles.value) {
        documentsFiles.value[keyFile] = doc.file
      }
    })

    setDateTime()

    let news: NewsItem = {
      title: newsCreateData.value.title,
      body: newsCreateData.value.body,
      publication_type: newsCreateData.value.publication_type,
      topic: newsCreateData.value.topic,
      important: newsCreateData.value.important,
      ...documentsTitles.value,
      ...documentsFiles.value
    }

    newsCreateData.value.picture = newPicture.value

    if (newsCreateData.value.picture) {
      news = {
        ...news,
        picture: newPicture.value
      }
    }

    if (newsCreateData.value.publication_date) {
      news = {
        ...news,
        publication_date: newsCreateData.value.publication_date
      }
    }

    if (newsCreateData.value.id) {
      const documentsDelete = ref()
      documentsDelete.value = {}
      deletedDocuments.value.forEach((doc, index) => {
        const keyDeleteDoc = `documents_delete[${index}]`
        documentsDelete.value[keyDeleteDoc] = doc
      })

      news = {
        ...news,
        picture_delete: !newsCreateData.value.picture,
        ...documentsDelete.value
      }
    }

    return news
  }

  const save = async () => {
    if (!await validation.value.$validate()) {
      return
    }

    const news = getDataToSave()

    if (newsCreateData.value.id) {
      await update(newsCreateData.value.id, news)

      return true
    }

    await create(news)
  }

  const create = async (news: NewsItem) => {
    await post('admin/news', FormHelper.getFormDataFromObject(news))
    if (status.value === 201 && response.value) {
      success.value = true
      router.push({name: 'settings-news'})
    }
  }

  const update = async (newsId: string, news: NewsItem) => {
    await post(`admin/news/${newsId}`, FormHelper.getFormDataFromObject(news))
    if (status.value === 200 && response.value) {
      success.value = true
      router.push({name: 'settings-news'})
    }
  }

  const setDateTime = () => {
    newsCreateData.value.publication_date = `${date.value} ${time.value}`
  }

  const loadNewsItem = async (id: string) => {
    await load(id)
    newsCreateData.value = {
      ...getNewsRequestData()
    }
    date.value = getDate(newsCreateData.value.publication_date)
    time.value = getTime(newsCreateData.value.publication_date)
    if (newsCreateData.value.documents) {
      documents.value = newsCreateData.value.documents
    }
  }

  return {
    newsCreateData,
    newDocument,
    newDocumentName,
    documents,
    newPicture,
    addDocument,
    removeDocument,
    removePicture,
    getPictureName,
    date,
    time,
    loading,
    response,
    status,
    error,
    errors,
    validation,
    success,
    save,
    loadNewsItem,
    topicsForSelect,
    publicationTypeForSelect,
    publicationTimeForSelect,
    getDataToSave,
  }
})
