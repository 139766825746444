<template>
  <q-intersection
    v-if="canBeUpload(props.documents) || props.canAddNewAddedDocument"
    transition="scale"
    once
  >
    <div class="ApplicationItemNewDocument q-my-xs">
      <div class="q-pa-md">
        <div
          v-if="!props.noActions"
          class="text-h6 q-mb-sm row"
        >
          <div class="col">
            <strong>{{ getDocumentGroupAddLabel(props.documentType) }}</strong>
          </div>
          <!--<q-btn
            class="q-mr-sm"
            label="Отмена"
            padding="8px 15px"
            :loading="fileSandedButNoResponse || props.document.loading"
            :disable="fileSandedButNoResponse || props.document.loading"
            unelevated
            no-wrap
            no-caps
            @click="emit('remove')"
          />-->
        </div>
        <application-form-new-document-file
          v-if="props.document.file"
          :file="props.document.file"
          :progress="props.document.progress"
          :loading="props.document.loading"
          @remove="emit('remove')"
        />
        <application-form-new-document-passports-parameters
          v-if="props.documentType === ApplicationFormDocumentGroupEnum.passports"
          :documents="props.documents"
          :document="props.document"
          :new-documents="props.newDocuments"
          :founders="props.founders"
          :head-fields="props.headFields"
          @select-passport="onSelectPassport"
        />
        <application-form-new-document-finencial-parameters
          v-if="props.documentType === ApplicationFormDocumentGroupEnum.financial"
          :documents="props.documents"
          :document="props.document"
          :new-documents="props.newDocuments"
          @select-finencial="onSelectFinancial"
        />
        <application-form-new-document-additional-parameters
          v-if="props.documentType === ApplicationFormDocumentGroupEnum.additional"
          label="Дополнительный документ"
          additional-option-label="Другой документ"
          :documents="props.documents"
          :document="props.document"
          :new-documents="props.newDocuments"
          :can-upload-added-document="props.canAddNewAddedDocument"
          @select-additional="onSelectAdditional"
        />
        <application-form-new-document-additional-parameters
          v-if="props.documentType === ApplicationFormDocumentGroupEnum.offer"
          label="Предложение"
          additional-option-label="Произвольный документ"
          :documents="props.documents"
          :document="props.document"
          :new-documents="props.newDocuments"
          :can-upload-added-document="props.canAddNewAddedDocument"
          @select-additional="onSelectOffer"
        />
        <application-form-new-document-additional-parameters
          v-if="props.documentType === ApplicationFormDocumentGroupEnum.deal"
          label="Сделка"
          additional-option-label="Произвольный документ"
          :documents="props.documents"
          :document="props.document"
          :new-documents="props.newDocuments"
          :can-upload-added-document="props.canAddNewAddedDocument"
          @select-additional="onSelectDeal"
        />
      </div>
    </div>
  </q-intersection>
</template>

<script setup lang="ts">
import {/*computed, */withDefaults, ref, watchEffect} from 'vue'
import {
  ApplicationFormDocumentGroupEnum,
  ApplicationFormDocument,
  ApplicationFormFounder,
  ApplicationFormField,
} from 'src/models/Applications/Form/ApplicationFormResponseData'
import {useDocumentGroup} from 'src/uses/Applications/useDocumentGroup'
import {useCanDocumentBeUpload} from 'src/uses/Applications/Form/Documents/useCanDocumentBeUpload'
import ApplicationFormNewDocumentPassportsParameters
  from 'src/components/Applications/Form/Documents/ApplicationFormNewDocumentPassportsParameters.vue'
import ApplicationFormNewDocumentFinencialParameters
  from 'src/components/Applications/Form/Documents/ApplicationFormNewDocumentFinencialParameters.vue'
import ApplicationFormNewDocumentAdditionalParameters
  from 'src/components/Applications/Form/Documents/ApplicationFormNewDocumentAdditionalParameters.vue'
import ApplicationFormNewDocumentFile from 'src/components/Applications/Form/Documents/ApplicationFormNewDocumentFile.vue'

const props = withDefaults(
  defineProps<{
    document: ApplicationFormDocument,
    documents: ApplicationFormDocument[],
    documentType: ApplicationFormDocumentGroupEnum,
    newDocuments: ApplicationFormDocument[],
    founders: ApplicationFormFounder[],
    headFields: ApplicationFormField[],
    canAddNewAddedDocument: boolean,
    noActions?: boolean,
  }>(),
  {
    noActions: false,
  }
)

const emit = defineEmits<{
  (e: 'remove'): void,
  (e: 'update', document: ApplicationFormDocument): void,
  (e: 'upload', document: ApplicationFormDocument): void,
}>()

const {
  canBeUpload,
} = useCanDocumentBeUpload()

const documentData = ref<ApplicationFormDocument>()

const {
  getDocumentGroupAddLabel,
} = useDocumentGroup()

/*const fileSandedButNoResponse = computed(() => {
  return props.document.loading
    && props.document.progress
    && props.document.progress.loaded === props.document.progress.total
})*/

const onSelectPassport = (data: ApplicationFormDocument) => {
  if (!documentData.value) {
    return
  }

  documentData.value.code = data.code
  documentData.value.founder_id = data.founder_id
  documentData.value.title = data.title
  documentData.value.sign_file_required = data.sign_file_required
  documentData.value.file_sign = data.file_sign

  emit('update', documentData.value)
}

const onSelectFinancial = (data: ApplicationFormDocument) => {
  if (!documentData.value) {
    return
  }

  documentData.value.code = data.code
  documentData.value.period_year = data.period_year
  documentData.value.period_quarter = data.period_quarter
  documentData.value.title = data.title
  documentData.value.sign_file_required = data.sign_file_required
  documentData.value.file_sign = data.file_sign

  emit('update', documentData.value)
}

const onSelectAdditional = (data: ApplicationFormDocument) => {
  if (!documentData.value) {
    return
  }

  documentData.value.code = data.code
  documentData.value.index = data.index
  documentData.value.title = data.title
  documentData.value.sign_file_required = data.sign_file_required
  documentData.value.file_sign = data.file_sign

  emit('update', documentData.value)
}

const onSelectOffer = (data: ApplicationFormDocument) => {
  if (!documentData.value) {
    return
  }

  documentData.value.code = data.code
  documentData.value.title = data.title
  documentData.value.index = data.index
  documentData.value.sign_file_required = data.sign_file_required
  documentData.value.file_sign = data.file_sign

  emit('update', documentData.value)
}

const onSelectDeal = (data: ApplicationFormDocument) => {
  if (!documentData.value) {
    return
  }

  documentData.value.code = data.code
  documentData.value.title = data.title
  documentData.value.index = data.index
  documentData.value.sign_file_required = data.sign_file_required
  documentData.value.file_sign = data.file_sign

  emit('update', documentData.value)
}

watchEffect(() => {
  documentData.value = {
    ...props.document
  }
})
</script>

<style lang="scss">
.ApplicationItemNewDocument {
  border: 1px solid #C7DFF1;
  border-radius: 6px;
}
</style>
