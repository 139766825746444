import {PushNotifications} from '@capacitor/push-notifications'
import {RouteLocationRaw, useRouter, useRoute} from 'vue-router'
import {ref, watch} from 'vue'
import {PushNotificationSchema} from '@capacitor/push-notifications/dist/esm/definitions'
import {ApplicationControlCenterSectionsEnum} from 'src/models/Enums/ApplicationControlCenterSectionsEnum'
import {PushNotificationsActionsEnum} from 'src/models/Enums/PushNotificationsActionsEnum'
import {usePushNotificationRegisterToken} from 'src/stores/Push/usePushNotificationRegisterToken'
import {PushNotificationRegisterTokenPayload} from 'src/models/Push/PushNotificationRegisterTokenPayload'
import {useQuasar} from 'quasar'
import {useLocalStorage} from 'src/uses/useLocalStorage'
import {PushNotificationData} from 'src/models/Push/PushNotificationData'
import { App } from '@capacitor/app'
import {Capacitor} from '@capacitor/core'
import {Badge} from '@capawesome/capacitor-badge'
import {useTokenStore} from 'stores/Login/useTokenStore'
import {storeToRefs} from 'pinia'
import {useClearBadge} from 'stores/Push/useClearBadge'

export const usePushNotifications = () => {
  const router = useRouter()
  const route = useRoute()
  const $q = useQuasar()
  const pushNotificationRegisterToken = usePushNotificationRegisterToken()
  const { clear } = useClearBadge()
  const {token} = storeToRefs(useTokenStore())
  const deliveredNotifications = ref<PushNotificationSchema[]>([])
  const {
    data,
    getFromStorage,
    setToStorage
  } = useLocalStorage<PushNotificationRegisterTokenPayload>('PushNotificationRegisterToken')

  const addListeners = async () => {
    await PushNotifications.removeAllListeners()
    await PushNotifications.addListener('registration', async token => {
      console.info('Registration token: ', token.value)

      const payload: PushNotificationRegisterTokenPayload = {
        push_token: token.value,
        platform_type: $q.platform.is.ios ? 'ios' : 'android',
      }

      setToStorage(payload)
    })

    await PushNotifications.addListener('registrationError', err => {
      console.error('Registration error: ', err.error)
    })

    await PushNotifications.addListener('pushNotificationReceived', notification => {
      console.log('Push notification received: ', notification)
      clearBadge()
    })

    await PushNotifications.addListener('pushNotificationActionPerformed', async (notification) => {
      console.log('Push notification action performed', notification)
      console.log('Order ID', notification.notification.data.form_id)

      if (!notification.notification.data.form_id
          || !notification.notification.data.app_id
          || !notification.notification.data.action
          || notification.actionId !== 'tap'
      ) {
        return
      }

      const data: PushNotificationData = notification.notification.data

      let redirectTo: RouteLocationRaw | undefined

      if (data.action === PushNotificationsActionsEnum.OPEN_CHAT) {
        redirectTo = {
          name: 'application-control-center',
          query: {
            chat: 'true',
          },
          params: {
            formId: data.form_id,
            appId: data.app_id,
          },
        }
      }

      if (data.action === PushNotificationsActionsEnum.OPEN_TARIFF) {
        redirectTo = {
          name: 'application-control-center',
          query: {
            tariff: 'true',
          },
          params: {
            formId: data.form_id,
            appId: data.app_id,
          },
        }
      }

      if (data.action === PushNotificationsActionsEnum.OPEN_TAB
          && data.tab
      ) {
        const tabs = Object.values<string>(ApplicationControlCenterSectionsEnum)
        if (!tabs.includes(data.tab as string)) {
          return
        }

        redirectTo = {
          name: 'application-control-center',
          query: {
            tab: data.tab,
            ...(data.documentTab ? {documentTab: data.documentTab,} : {}),
          },
          params: {
            formId: data.form_id,
            appId: data.app_id,
          },
        }
      }

      if (redirectTo) {
        const resolved = router.resolve(redirectTo)
        if (resolved.name === route.name) {
          await router.push(redirectTo)
          window.location.reload()
          return
        }

        await router.push(redirectTo)
      }
    })

    if (Capacitor.getPlatform() === 'ios') {
      App.addListener('resume', () => {
        clearBadge()
      })
    }
  }

  const clearBadge = async () => {
    if (Capacitor.getPlatform() !== 'ios') {
      return
    }

    if (!token.value) {
      return
    }

    const canUseBadges = await registerBadges()

    if (!canUseBadges) {
      Badge.clear()
      clear()
      return
    }

    if ((await Badge.get()).count === 0) {
      return
    }

    Badge.clear()
    clear()
  }

  const registerNotifications = async () => {
    let permStatus = await PushNotifications.checkPermissions()

    if (permStatus.receive === 'prompt') {
      permStatus = await PushNotifications.requestPermissions()
    }

    if (permStatus.receive !== 'granted') {
      throw new Error('User denied permissions!')
    }

    await PushNotifications.register()
  }

  const registerBadges = async () => {
    let permStatus = await Badge.checkPermissions()

    if (permStatus.display === 'prompt') {
      permStatus = await Badge.requestPermissions()
    }

    if (permStatus.display !== 'granted') {
      return false
    }

    return true
  }

  const getDeliveredNotifications = async () => {
    deliveredNotifications.value = (await PushNotifications.getDeliveredNotifications()).notifications
    console.log('delivered notifications', deliveredNotifications.value)
  }

  const init = async () => {
    getFromStorage()
    await addListeners()
    await registerNotifications()
    await getDeliveredNotifications()
    await clearBadge()
  }

  init()

  watch(token, clearBadge)

  watch(
    data,
    async () => {
      if (!data.value) {
        return
      }

      await pushNotificationRegisterToken.create(data.value)

      if (pushNotificationRegisterToken.status.value === 200) {
        setToStorage(null)
        return
      }

      console.log(
        {
          message: 'Push notification registration failed',
          status: pushNotificationRegisterToken.status.value,
          errors: pushNotificationRegisterToken.errors.value
        }
      )
    },
    {
      immediate: true,
      deep: true
    }
  )

  return {
    deliveredNotifications
  }
}
