<template>
  <div class="flex-inline text-no-wrap">
    <span>{{ item.label }}</span>
    <q-icon
      v-if="item.sortableColumn"
      v-ripple
      class="cursor-pointer"
      :name="getSortingArrow(item)"
      size="1.3em"
      @click="onSort(item)"
    />
  </div>
</template>

<script setup lang="ts">
import {computed} from 'vue'
import {storeToRefs} from 'pinia'
import {GridSortingEnum} from 'src/models/Grid/GridSortingEnum'
import {useApplicationsGridSortingStore} from 'stores/Applications/Grid/useApplicationsGridSortStore'
import {GridColumnSettings, QTableColumn} from 'src/models/Grid/GridColumnSettings'

const props = defineProps<{
  item: GridColumnSettings
}>()
const item = computed(() => props.item)

const sortingStore = useApplicationsGridSortingStore()
const {sorting} = storeToRefs(sortingStore)

const emit = defineEmits<{
  (e: 'update:sorting'): void,
}>()

const getSortingArrow = (col: QTableColumn) => {
  if (sorting.value.sort !== col.name) {
    return 'svguse:icons.svg?2#app-swap-vert'
  }
  if (sorting.value.direction === GridSortingEnum.DESC) {
    return 'svguse:icons.svg?2#app-chevron-down'
  }

  return 'svguse:icons.svg?2#app-chevron-up'
}

const onSort = (col: QTableColumn) => {
  sorting.value.sort = col.name
  sorting.value.direction = sorting.value.direction === GridSortingEnum.ASC
    ? GridSortingEnum.DESC
    : GridSortingEnum.ASC
  emit('update:sorting')
}
</script>
