import {GridColumnEditableSettings} from 'src/models/Grid/GridColumnSettings'

export const ProfileNotificationsColumns: GridColumnEditableSettings[] = [
  {
    field: '',
    name: 'title',
    required: true,
    label: 'Наименование',
    align: 'left',
    classes: 'text-no-wrap',
    visible: true,
    editable: false,
    order: 1,
  },
  {
    field: '',
    name: 'email',
    required: true,
    label: 'Почта',
    align: 'left',
    classes: 'text-no-wrap',
    visible: true,
    editable: true,
    order: 2,
  },
  {
    field: '',
    name: 'push',
    required: true,
    label: 'Push',
    align: 'left',
    classes: 'text-no-wrap',
    visible: true,
    editable: true,
    order: 3,
  },
]
