import {defineStore} from 'pinia'
import {useDeleteApi} from 'src/uses/Api/useDeleteApi'

export const useContractorDeleteStore = defineStore('contractor-delete', () => {
  const {
    deleteRequest,
    response,
    status,
    loading,
    error,
    errors,
  } = useDeleteApi<never>()

  const deleteContractor = async (newsId: string) => {
    await deleteRequest(`v2/contractors/${newsId}`)
  }

  return {
    loading,
    response,
    status,
    error,
    errors,
    deleteContractor,
  }
})
