<template>
  <div
    v-if="productTariffUpdateData"
    class="row q-mt-md"
  >
    <div class="col-12 q-pt-none">
      <div
        v-for="(condition, index) in productTariffUpdateData.conditions"
        :key="index"
      >
        <div class="row q-mb-md q-pa-md"
             style="border-radius: 6px; border: 1px solid #C7DFF1;"
        >
          <div class="col-12 q-mb-lg">
            <div class="text-bold q-mb-xs">Условие #{{ index + 1 }}</div>
            <q-form v-if="condition?.comparison">
              <div class="text-blue-grey-4 q-mb-sm">Параметр</div>
              <div
                v-for="(param , indexParam) in condition.comparison.parts"
                :key="indexParam"
                class="q-py-xs paramRow"
              >
                <div class="row q-gutter-sm white-bg-field">
                  <q-select
                    v-model="param.param"
                    :options="conditionsParamsOptions"
                    class="col field-with-top-label q-mb-sm"
                    emit-value
                    map-options
                    outlined
                    hide-bottom-space
                    dense
                    @update:model-value="onChangeParam(index, indexParam, null)"
                  />
                  <q-field
                    :model-value="param.operator"
                    class="field-with-top-label q-pt-none no-bg q-mb-sm text-no-wrap"
                    :error="false"
                    borderless
                    hide-bottom-space
                    dense
                  >
                    <q-btn-toggle
                      v-model="param.operator"
                      class="full-width"
                      toggle-color="primary"
                      color="white"
                      text-color="grey-9"
                      toggle-text-color="white"
                      :options="operatorOptions"
                      :disable="paramComparisonOptions[param.param] || false"
                      no-caps
                      spread
                      unelevated
                    />
                  </q-field>
                  <q-input
                    v-if="paramValueTypesAndOptions[param.param].type !== ProductTariffParamsItemTypeEnum.SELECT"
                    v-model="param.value as string | number | null"
                    class="col field-with-top-label q-mb-sm"
                    type="number"
                    outlined
                    hide-bottom-space
                    dense
                    @update:model-value="onChangeParam(index, indexParam, $event, paramValueTypesAndOptions[param.param].type)"
                  />
                  <form-select
                    v-if="paramValueTypesAndOptions[param.param].type === ProductTariffParamsItemTypeEnum.SELECT"
                    class="col field-with-top-label q-mb-sm"
                    :model-value="param.value"
                    :multiple="paramValueTypesAndOptions[param.param].multiple"
                    :use-chips="paramValueTypesAndOptions[param.param].multiple"
                    :options="paramValueTypesAndOptions[param.param].options"
                    label=""
                    top-actions
                    @update:model-value="onChangeParam(index, indexParam, $event as string[], paramValueTypesAndOptions[param.param].type)"
                  />
                  <div style="width: 40px; padding-top: 9px">
                    <q-btn
                      class="removeParamBtn"
                      size="sm"
                      icon="svguse:icons.svg?2#app-trash"
                      flat
                      round
                      @click="removeParam(index, indexParam)"
                    />
                  </div>
                </div>
              </div>
              <div class="row q-mb-lg q-pb-md">
                <div class="col">
                  <q-btn
                    class="btnBgGray q-mt-sm"
                    style="height: 30px"
                    label="Добавить параметр"
                    icon-right="svguse:icons.svg?2#app-plus"
                    text-color="blue-gray-5"
                    unelevated
                    no-wrap
                    no-caps
                    @click="addParam(index)"
                  />
                </div>
              </div>
              <div class="row q-gutter-sm white-bg-field q-mt-lg">
                <div class="col-4">
                  <q-select
                    v-model="condition.operation"
                    :options="conditionOperationOptions"
                    label="Значение"
                    class="col field-with-top-label q-mb-sm"
                    emit-value
                    map-options
                    outlined
                    hide-bottom-space
                    dense
                  />
                </div>
                <div class="col"
                     style="max-width: 100px;"
                >
                  <q-field
                    :model-value="condition.rateType"
                    class="field-with-top-label q-pt-none no-bg q-mb-sm text-no-wrap"
                    :error="false"
                    borderless
                    hide-bottom-space
                    dense
                  >
                    <q-btn-toggle
                      v-model="condition.rateType"
                      class="full-width"
                      toggle-color="primary"
                      color="white"
                      text-color="grey-9"
                      toggle-text-color="white"
                      :options="intervalOptions"
                      no-caps
                      spread
                      unelevated
                    />
                  </q-field>
                </div>
                <div class="col-3">
                  <q-input
                    v-model.number="condition.rate"
                    class="field-with-top-label q-mb-sm"
                    type="number"
                    outlined
                    hide-bottom-space
                    dense
                  />
                </div>
                <div class="col">
                  <q-btn
                    class="btnBgGray"
                    style="height: 50px"
                    size="md"
                    text-color="blue-gray-5"
                    label="Удалить условие"
                    unelevated
                    no-wrap
                    no-caps
                    @click="removeCondition(index)"
                  />
                </div>
              </div>
            </q-form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <q-btn
    label="Добавить условие"
    color="primary"
    icon-right="svguse:icons.svg?2#app-plus"
    style="padding: 12px 22px"
    unelevated
    no-caps
    @click="addCondition"
  />
</template>

<script setup lang="ts">
import {useProductsTariffControlStore} from 'stores/Products/Tariff/useProductsTariffControlStore'
import {storeToRefs} from 'pinia'
import {ProductTariffParamsItemTypeEnum} from 'src/models/Products/Tariff/ProductTariffParamsItem'
import FormSelect from 'components/FormElements/FormSelect.vue'
import {useProductsTariffParamsStore} from 'stores/Products/Tariff/useProductsTariffParamsStore'

const props = defineProps<{
  productId: string
}>()

const productsTariffControlStore = useProductsTariffControlStore(props.productId)()
const {
  productTariffUpdateData,
  conditionOperationOptions,
  intervalOptions,
} = storeToRefs(productsTariffControlStore)
const {
  addCondition,
  addParam,
  removeCondition,
  removeParam,
  onChangeParam,
} = productsTariffControlStore

const productTariffParamsStore = useProductsTariffParamsStore()
const {
  paramComparisonOptions,
  paramValueTypesAndOptions,
  operatorOptions,
  conditionsParamsOptions,
} = storeToRefs(productTariffParamsStore)

</script>
