<template>
  <q-dialog
    :model-value="props.modelValue"
    @update:model-value="dialog => emit('update:modelValue', dialog)"
  >
    <q-card
      style="min-width: 420px"
      class="modal-blue-background"
    >
      <q-card-section class="q-mt-md">
        <div class="text-h6">Подписать ЭЦП</div>
      </q-card-section>
      <q-card-section class="q-pr-lg q-pl-lg">
        <sign-file
          v-if="fileForSign"
          ref="signFileComponent"
          :file-for-sign="fileForSign"
          @update:signed-file="handleSign"
        />
      </q-card-section>
      <q-card-actions
        align="right"
        class="modal-actions text-primary q-pa-lg"
      >
        <q-btn
          v-close-popup
          :loading="loading"
          label="Отмена"
          class="q-pr-lg"
          style="padding: 17px 28px;"
          unelevated
          flat
          no-caps
          text-color="black"
        />
        <q-btn
          :loading="loading"
          label="Подписать"
          color="primary"
          style="padding: 17px 28px;"
          unelevated
          icon-right="svguse:icons.svg?2#app-pencil"
          no-caps
          @click="handleSubmit()"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
import {computed, onBeforeUnmount, ref, watch} from 'vue'
import {storeToRefs} from 'pinia'
import {Act} from 'src/models/Profile/Acts/Act'
import {useProfileActSignStore} from 'src/stores/Profile/Acts/useProfileActSignStore'
import SignFile from 'src/components/Sign/SignFile.vue'

const props = defineProps<{
  item: Act,
  modelValue: boolean,
}>()

const emit = defineEmits<{
  (e: 'update:modelValue', modelValue: boolean): void,
}>()

const item = computed(() => props.item)
const profileActSignStore = useProfileActSignStore()
const {
  loading,
  fileForSign,
  success,
} = storeToRefs(profileActSignStore)

const {
  sign,
  getFileForSign,
} = profileActSignStore

const abort = () => {
  emit('update:modelValue', false)
}

onBeforeUnmount(() => {
  profileActSignStore.$reset()
  profileActSignStore.$dispose()
})

const signFileComponent = ref<InstanceType<typeof SignFile>>()

const handleSubmit = async () => {
  if (!signFileComponent.value) {
    return
  }

  await signFileComponent.value.onSign()
}

const handleSign = async (signedFile: string, certName: string) => {
  await sign(item.value, signedFile, certName)
}
watch(
  props,
  async () => {
    if (props.modelValue) {
      await getFileForSign(item.value.id)
    }
  },
  {deep: true, immediate: true}
)

watch(
  success,
  async () => {
    if (success) {
      abort()
    }
  },
  {deep: true, immediate: true}
)

</script>
