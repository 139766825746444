<template>
  <rnt-not-in-eis-reason-modal
    v-model:rnt-not-in-eis-reason="rntNotInEISReason"
    v-model:rnt-link="rntLink"
    v-model="rntNotInEisDialog"
    @abort="onRntNotInEISReasonAbort"
  />
  <form-group>
    <div class="row q-mb-sm">
      <q-input
        v-if="showRntNotInEisReasonInsteadRnt && rntNotInEISReason"
        :model-value="getRntNotInEISReasonLabel(rntNotInEISReason)"
        class="field-with-top-label col-8 q-pr-sm"
        label="РНТ"
        :error="false"
        debounce="700"
        disable
        outlined
        hide-bottom-space
        dense
        clearable
        @blur="rntDataLoad"
        @keydown.enter.exact.prevent="keydownEnterLoadRnt"
      />
      <q-input
        v-else
        ref="rntInput"
        v-model="rnt"
        :disable="isBlocked('rnt')"
        :loading="rntDataLoading"
        class="field-with-top-label col-8 q-pr-sm"
        label="РНТ"
        :error="false"
        debounce="700"
        outlined
        hide-bottom-space
        dense
        clearable
        @blur="rntDataLoad"
        @keydown.enter.exact.prevent="keydownEnterLoadRnt"
      />
      <q-input
        :model-value="lot"
        :loading="rntDataLoading"
        :disable="isBlocked('lot')"
        class="field-with-top-label col-4"
        label="Лот"
        :error="false"
        debounce="700"
        outlined
        hide-bottom-space
        dense
        @update:model-value="lot = parseInt($event as string)"
      />
    </div>
    <div class="row no-wrap">
      <q-toggle
        v-model="rntNotInEIS"
        :disable="isBlocked('rntNotInEIS')"
        class="q-mr-md"
        style="margin-left: -10px;"
        color="blue-7"
      >
        <span class="ellipsis-2-lines">нет в ЕИС</span>
      </q-toggle>
      <q-toggle
        v-model="oversupply"
        :disable="isBlocked('oversupply')"
        label="Переобеспечение"
        style="margin-left: -10px;"
        color="blue-7"
      />
    </div>
  </form-group>
</template>

<script setup lang="ts">
import {storeToRefs} from 'pinia'
import RntNotInEisReasonModal from 'src/components/Applications/ApplicationNew/RntNotInEisReasonModal.vue'
import {useRntNotInEISReason} from 'src/uses/Applications/useRntNotInEISReason'
import FormGroup from 'src/components/FormGroup.vue'
import {useApplicationsNew} from 'src/stores/Applications/ApplicationsNew/useApplicationsNew'
import {ref, watch} from 'vue'
import {QInput} from 'quasar'
import {useScrollTo} from 'src/uses/useScrollTo'

const rntInput = ref<QInput>()

const {
  getRntNotInEISReasonLabel
} = useRntNotInEISReason()

const applicationsNewStore = useApplicationsNew()
const {
  rnt,
  lot,
  rntNotInEIS,
  rntNotInEISReason,
  rntLink,
  oversupply,
  rntNotInEisDialog,
  showRntNotInEisReasonInsteadRnt,
  rntDataLoading,
  lastFocused,
} = storeToRefs(applicationsNewStore)

const {
  rntDataLoad,
  isBlocked,
} = applicationsNewStore

const {
  scrollToInvisibleElement,
} = useScrollTo()

const onRntNotInEISReasonAbort = () => {
  rntNotInEIS.value = false
}

const keydownEnterLoadRnt = () => {
  rntInput.value?.blur()
}

watch(
  lastFocused,
  () => {
    if (lastFocused.value !== 'rnt' || !rntInput.value) {
      return
    }

    rntInput.value.focus()
    scrollToInvisibleElement(rntInput.value?.$el)
  }
)
</script>
