export interface CalculatorComparisonPart {
  operator: string
  param: string
  value: boolean | string | number | null
  id?: string
  type?: string
}

export interface CalculatorComparison {
  parts: CalculatorComparisonPart[]
}

export interface CalculatorConditionalOptions {
  date_of_entry_into_force_enabled: boolean
  issue_form_enabled: boolean
  bank_commission_enabled: boolean
  bank_commission_percent: number
  bank_commission_comment: string
  agent_commission_enabled: boolean
  agent_commission_percent: number
  agent_commission_comment: string
  comparison: CalculatorComparison
  calculator_enabled: boolean
}

export interface CalculatorOptionsUpdate {
  enabled: boolean
  date_of_entry_into_force_enabled: boolean
  issue_form_enabled: boolean
  bank_commission_enabled: boolean
  bank_commission_percent: number
  bank_commission_comment: string
  agent_commission_enabled: boolean
  agent_commission_percent: number
  agent_commission_comment: string
  external_discount_enabled: boolean
  commission_approval_enabled: boolean
  conditional_options: CalculatorConditionalOptions[]
}

export enum CalculatorParamTypesEnum {
  NUMBER = 'number',
  SELECT = 'select',
  DATETIME = 'datetime',
}

export enum CalculatorRelationsEnum {
  LT = 'lt',
  EQ = 'eq',
  GT = 'gt',
}

export interface CalculatorConditionalParam {
  relations: CalculatorRelationsEnum[]
  param: string
  title: string
  type: CalculatorParamTypesEnum
  multiple?: boolean
  options?: Record<string, string> | null
}

export interface CalculatorOptions extends CalculatorOptionsUpdate {
  conditional_params: CalculatorConditionalParam[]
}
