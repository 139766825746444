import {UserDocumentStateEnum} from 'src/models/User/UserDocumentStateEnum'

const messages: Record<UserDocumentStateEnum, string> = {
  [UserDocumentStateEnum.NoSign]: 'Необходимо заполнить реквизиты и подписать документы для подключения к сервису',
  [UserDocumentStateEnum.IsChecking]: 'Документы находятся на проверке у вашего куратора. В ближайшее время вы получите обратную связь',
  [UserDocumentStateEnum.Revision]: 'Необходимо ознакомиться с комментарием по результату проверки документа и внести корректировки',
  [UserDocumentStateEnum.Signed]: 'Документы подписаны, вы подключены к сервису',
  [UserDocumentStateEnum.WaitOriginal]: 'Документы проверены, вам необходимо отправить оригинал подписанного заявления',
}

export const useInfoBannerMessage = () => {
  const getMessage = (docStatus: UserDocumentStateEnum | null) => {
    if (docStatus === null) {
      return 'Необходимо заполнить реквизиты и подписать документы для подключения к сервису'
    }

    return messages[docStatus]
  }

  return {
    getMessage
  }
}
