<template>
  <applications-grid-filters-resume-dialog
    :top="150"
  >
    <div class="row items-end justify-center">
      <q-btn
        color="grey-2"
        class="text-blue-grey-4"
        icon-right="svguse:icons.svg?2#app-adjustments"
        padding="xs"
        unelevated
        no-caps
        @click="filterDialogShow"
      />
      <q-btn
        v-if="clearable"
        color="grey-2"
        class="text-blue-grey-4 q-ml-xs"
        icon-right="svguse:icons.svg?2#app-trash"
        padding="xs"
        unelevated
        no-caps
        @click="onClear"
      />
      <q-btn
        class="q-ml-sm text-primary bg-grey-2"
        label="Наверх"
        padding="xs sm"
        unelevated
        no-wrap
        no-caps
        @click="scrollTop"
      >
        <q-icon
          name="svguse:icons.svg?2#app-arrow-up"
          color="primary"
          size="15px"
        />
      </q-btn>
    </div>
  </applications-grid-filters-resume-dialog>
  <div
    class="q-mb-md"
    style="margin-left: -16px; margin-right: -16px; margin-top: -8px;"
  >
    <application-grid-toggle :loading="loading" />
  </div>
  <div style="margin-left: -8px; margin-right: -8px;">
    <application-grid-product-toggle :loading="loading" />
  </div>
  <application-grid-filters-dialog
    v-model="filerDialog"
    :loading="loading"
    :ignore-pins="ignorePins"
    @update:filters="updateFilters"
  />
  <div class="q-px-none">
    <div class="ApplicationGrid--mobile">
      <div class="row q-pb-lg q-pt-sm">
        <q-btn
          label="Все фильтры"
          color="blue-grey-0"
          class="text-blue-7 col"
          icon-right="svguse:icons.svg?2#app-adjustments"
          padding="10px"
          unelevated
          no-caps
          @click="filterDialogShow"
        />
        <q-btn
          v-if="clearable"
          color="blue-grey-0"
          class="text-blue-7 q-ml-xs"
          icon-right="svguse:icons.svg?2#app-trash"
          size="14px"
          padding="10px"
          unelevated
          no-caps
          @click="onClear"
        />
      </div>
      <application-grid-statuses
        class="q-mb-sm q-mt-sm"
        @update:filters="updateFilters"
      />
      <q-infinite-scroll
        ref="scroll"
        style="margin-bottom: 12px;"
        :offset="50"
        @load="onLoad"
      >
        <div
          v-for="item in items as ApplicationsGridItem[]"
          :key="item.id"
        >
          <div>
            <div class="ApplicationGrid--mobile--item q-pa-md rounded-borders bg-white q-mt-sm">
              <div class="row">
                <div>
                  <div class="text-grey-6">Дата, №</div>
                  <application-grid-number-mobile
                    :item="item"
                    :onlyText="ApplicationSubStatusEnum.archive === item.sub_status"
                  />
                </div>
                <div class="col"></div>
                <div class="text-grey-6 q-mt-sm">
                  <div class="row no-wrap">
                    <div v-if="getColumnByName('docs') && isVisibleByRole(getColumnByName('docs'))">
                      <application-grid-docs :item="item"/>
                    </div>
                    <div class="text-right col">
                      <application-grid-actions
                        :item="item"
                        @change="refreshById(item.id as never)"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="row no-wrap q-mt-sm">
                <div>
                  <div class="text-grey-6 q-mb-xs">Банк</div>
                  <div>
                    <img
                      :src="item.bank.logo"
                      :alt="item.bank.title_short"
                      style="max-width: 60px; max-height: 52px;"
                    >
                  </div>
                </div>
                <div class="col q-pl-sm">
                  <div class="text-grey-6 q-mb-xs">Статус</div>
                  <div class="row no-wrap">
                    <div class="q-pr-sm col">
                      <application-grid-status :item="item"/>
                    </div>
                    <application-grid-messages-action
                      :item="item"
                      mobile
                      @model-closed="refreshById(item.id as never)"
                    />
                  </div>
                </div>
              </div>

              <div class="text-grey-6 q-mt-sm">Название/ИНН</div>
              <application-grid-inn
                :item="item"
                mobile
              />

              <div class="text-grey-6 q-mt-sm">Закупка</div>
              <application-grid-rnt
                :item="item"
                mobile
              />

              <div class="text-grey-6 q-mt-sm">Срок действия</div>
              <application-grid-period
                :item="item"
                mobile
              />

              <div class="text-grey-6 q-mt-sm">Сумма гарантии</div>
              <application-grid-sum
                :item="item"
                mobile
                @tariff-refreshed="refreshById(item.id as never)"
              />

              <div v-if="getColumnByName('users') && isVisibleByRole(getColumnByName('users'))">
                <div class="text-grey-6 q-mt-sm">Инфо</div>
                <application-grid-users :item="item"/>
              </div>
            </div>
          </div>
        </div>
        <template #loading>
          <div class="text-center">
            <q-circular-progress
              class="q-mt-md"
              color="primary"
              size="md"
              indeterminate
            />
          </div>
        </template>
      </q-infinite-scroll>
    </div>
  </div>
  <div v-if="items.length === 0">
    <p class="text-center q-py-md">
      По вашему запросу ничего не найдено. <br> Попробуйте изменить фильтры
    </p>
  </div>
</template>

<script setup lang="ts">
import ApplicationGridStatuses from 'src/components/Applications/ApplicationsGrid/ApplicationGridStatuses.vue'
import ApplicationGridFiltersDialog from 'src/components/Applications/ApplicationsGrid/ApplicationGridFiltersDialog.vue'
import {useApplicationsGrid} from 'src/components/Applications/ApplicationsGrid/Grid/useApplicationsGrid'
import {ApplicationsGridItem} from 'src/models/Applications/ApplicationsGridItem'
import ApplicationGridNumberMobile
  from 'src/components/Applications/ApplicationsGrid/Columns/ApplicationGridNumberMobile.vue'
import ApplicationGridStatus from 'src/components/Applications/ApplicationsGrid/Columns/ApplicationGridStatus.vue'
import ApplicationGridMessagesAction
  from 'src/components/Applications/ApplicationsGrid/Actions/ApplicationGridMessagesAction.vue'
import ApplicationGridInn from 'src/components/Applications/ApplicationsGrid/Columns/ApplicationGridInn.vue'
import ApplicationGridRnt from 'src/components/Applications/ApplicationsGrid/Columns/ApplicationGridRnt.vue'
import ApplicationGridPeriod from 'src/components/Applications/ApplicationsGrid/Columns/ApplicationGridPeriod.vue'
import ApplicationGridSum from 'src/components/Applications/ApplicationsGrid/Columns/ApplicationGridSum.vue'
import ApplicationGridUsers from 'src/components/Applications/ApplicationsGrid/Columns/ApplicationGridUsers.vue'
import {ApplicationsGridColumns} from 'src/components/Applications/ApplicationsGrid/ApplicationsGridColumns'
import {useGridSettings} from 'src/uses/Grid/useGridSettings'
import ApplicationGridActions from 'src/components/Applications/ApplicationsGrid/Actions/ApplicationGridActions.vue'
import ApplicationGridDocs from 'src/components/Applications/ApplicationsGrid/Columns/ApplicationGridDocs.vue'
import ApplicationGridToggle from 'components/Applications/ApplicationsGrid/Grid/ApplicationGridToggle.vue'
import {ref, watch} from 'vue'
import {QInfiniteScroll} from 'quasar'
import ApplicationGridProductToggle
  from 'components/Applications/ApplicationsGrid/Grid/ApplicationGridProductToggle.vue'
import {ApplicationSubStatusEnum} from 'src/models/Applications/ApplicationSubStatusEnum'
import ApplicationsGridFiltersResumeDialog
  from 'components/Applications/ApplicationsGrid/Grid/ApplicationsGridFiltersResumeDialog.vue'

const {
  items,

  clearable,

  loading,
  ignorePins,

  noMorePages,

  filerDialog,
  filterDialogShow,

  updateFilters,
  onClear,

  refreshById,

  load,
  setNextPage
} = useApplicationsGrid()

const columns = ApplicationsGridColumns()
const {isVisibleByRole} = useGridSettings('application-grid', columns)

const getColumnByName = (name: string) => columns.find(column => column.name === name)

const scroll = ref<QInfiniteScroll>()

const onLoad = async (index: number, done: (stop?: boolean) => void) => {
  if (loading.value) {
    return
  }
  setNextPage()
  await load()
  done()
}

const scrollTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  })
}

watch(
  noMorePages,
  () => {
    if (!scroll.value) {
      return
    }

    if (noMorePages.value) {
      scroll.value.stop()

      return
    }

    scroll.value.resume()
  }
)
</script>

<style lang="scss" src="./ApplicationsGridMobile.scss"/>
