import {ChatDocument, ChatMessage} from 'src/models/Chat'
import {ref} from 'vue'
import {
  ApplicationFormDocumentGroupEnum,
  ApplicationFormDocument, ApplicationFormDocuments,
  ApplicationFormDocumentStatusEnum,
} from 'src/models/Applications/Form/ApplicationFormResponseData'
import {
  useApplicationFormDownloadDocument
} from 'src/uses/Applications/Form/Documents/useApplicationFormDownloadDocument'
import {ApplicationFormUpdateBlockEnum} from 'src/models/Applications/Form/ApplicationFormUpdateResponse'
import {useCanDocumentBeUpload} from 'src/uses/Applications/Form/Documents/useCanDocumentBeUpload'
import {
  useApplicationControlCenterItemStore
} from 'stores/Applications/ControlCenter/useApplicationControlCenterItemStore'

export interface typesForSelect {
  value: ApplicationFormDocumentGroupEnum,
  label: string,
}

export const useChatUploadDocuments = (appId: string) => {
  const applicationItemStore = useApplicationControlCenterItemStore(appId)()
  const {
    canAddNewAddedDocument,
  } = applicationItemStore

  const documentType = ref<ApplicationFormDocumentGroupEnum>()
  const documentTypes: ApplicationFormDocumentGroupEnum[] = [
    ApplicationFormDocumentGroupEnum.passports,
    ApplicationFormDocumentGroupEnum.financial,
    ApplicationFormDocumentGroupEnum.additional,
    ApplicationFormDocumentGroupEnum.offer,
    ApplicationFormDocumentGroupEnum.deal,
  ]

  const {
    canBeUpload,
  } = useCanDocumentBeUpload()

  const getDocumentTypes = (documents: ApplicationFormDocuments) => {
    return documentTypes
      .filter(type => {
        if (canBeUpload(canUploadDocuments(documents, type)) || canAddNewAddedDocument(type)) {
          return type
        }

        return false
      })
  }

  const canUploadDocuments = (
    documents: ApplicationFormDocuments,
    documentType: ApplicationFormDocumentGroupEnum,
  ): ApplicationFormDocument[] => {
    const docs = documents[documentType] || null
    if (!docs) {
      return []
    }

    return docs
  }

  const hasDocuments = (message: ChatMessage): boolean => {
    if (!message.documents) {
      return false
    }

    return Object.values(message.documents).length > 0
  }
  const getDocument = (message: ChatMessage): ChatDocument | undefined => {
    if (!message.documents) {
      return undefined
    }
    return Object.values(message.documents)[0] || undefined
  }

  const downloadDocument = async (
    id: string,
    document: ApplicationFormDocument,
    type: ApplicationFormDocumentGroupEnum,
  ) => {
    const {
      download,
    } = useApplicationFormDownloadDocument(undefined, id)

    await download(document, type)
  }

  const convertChatDocumentToApplicationDocument = (chatDocument: ChatDocument): ApplicationFormDocument => {
    return {
      location: {
        block: ApplicationFormUpdateBlockEnum.documents,
        section_type: chatDocument.type,
      },
      invisible: false,
      valid: [],
      code: chatDocument.code,
      description: null,
      file_date: null,
      file_name: null,
      file_path: null,
      file_status: ApplicationFormDocumentStatusEnum.uploaded,
      founder_id: chatDocument.founder_id,
      founder_passport_number: null,
      founder_passport_series: null,
      index: chatDocument.index,
      period_quarter: chatDocument.quarter,
      period_year: chatDocument.year,
      sign_file_name: null,
      sign_file_path: null,
      title: chatDocument.title,
      highlighted: false,
      readonly: false,
      sign_file_required: false,
    }
  }

  return {
    documentType,
    documentTypes,
    getDocumentTypes,
    hasDocuments,
    getDocument,
    downloadDocument,
    convertChatDocumentToApplicationDocument,
  }
}
