import {LawTypeEnum} from 'src/models/Applications/LawTypeEnum'
import {useOptionsFromObject} from 'src/uses/useOptionsFromObject'

const laws: Record<LawTypeEnum, string> = {
  [LawTypeEnum.FZ44]: '44-ФЗ',
  [LawTypeEnum.FZ223]: '223-ФЗ',
  [LawTypeEnum.PP615]: '615-ПП',
}

export const useLaw = () => {
  const {getOptionsFromObject} = useOptionsFromObject()
  const options = getOptionsFromObject(laws)
  const getLawLabel = (law: LawTypeEnum): string => {
    return laws[law] || ''
  }

  return {
    getLawLabel,
    options,
  }
}
