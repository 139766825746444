export enum ApplicationKikStatusEnum {
  all = 'all',
  draft = 'draft',
  in_bank = 'in_bank',
  approved = 'approved',
  released = 'released',
  bank_rejected = 'bank_rejected',
  client_rejected = 'client_rejected',
  system_rejected = 'system_rejected',
  need_sign = 'need_sign',
  favorites = 'favorites',
}
