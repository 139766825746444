import {ref} from 'vue'

export const useLocalStorage = <T>(hash: string) => {
  const data = ref<T | undefined | null>()

  const getFromStorage = () => {
    try {
      data.value = JSON.parse(localStorage.getItem(hash) || '') as T
    } catch (error) {
      data.value = undefined
    }
  }

  const setToStorage = (data: T | null) => {
    localStorage.setItem(hash, JSON.stringify(data))
  }

  return {getFromStorage, setToStorage, data}
}
