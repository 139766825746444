<template>
  <form-group class="full-width">
    <div>
      <q-field
        ref="bgFormField"
        :model-value="bgForm"
        :disable="isBlocked('bgForm')"
        class="field-with-top-label no-bg btn-group-white-bg bgFormToggle q-pr-lg"
        label-slot
        :error="false"
        borderless
        hide-bottom-space
        dense
      >
        <template #label>
          <div>
            Форма выпуска БГ
            <q-icon
              style="margin-top: -2px; position: relative;pointer-events: auto;"
              name="svguse:icons.svg?2#app-exclamation-circle-bold"
              size="xs"
              color="blue-grey-4"
            />
            <q-tooltip>
              Электронная - пришлем PDF с УКЭП <br>
              Бумажная - напечатаем БГ и отправим курьером (банки могут брать доп. плату за доставку)
            </q-tooltip>
          </div>
        </template>
        <q-btn-toggle
          v-model="bgForm"
          class="full-width"
          toggle-color="primary"
          color="blue-grey-1"
          text-color="grey-9"
          toggle-text-color="white"
          :options="bgForms"
          spread
          no-caps
          unelevated
        />
      </q-field>
      <div>
        <q-toggle
          v-model="mailhardcopy"
          :disable="isBlocked('mailhardcopy')"
          class="q-mt-sm"
          style="margin-left: -10px;"
          label="Требуется доставка оригинала БГ"
        />
      </div>
    </div>
  </form-group>
</template>

<script setup lang="ts">
import {storeToRefs} from 'pinia'
import {useBgForm} from 'src/uses/Applications/useBgForm'
import {useApplicationsNew} from 'src/stores/Applications/ApplicationsNew/useApplicationsNew'
import {ref, watch} from 'vue'
import {QField} from 'quasar'
import {useScrollTo} from 'src/uses/useScrollTo'
import FormGroup from 'src/components/FormGroup.vue'

const bgFormField = ref<QField>()

const {
  options: bgForms,
} = useBgForm()

const applicationsNewStore = useApplicationsNew()
const {
  bgForm,
  mailhardcopy,
  lastFocused,
} = storeToRefs(applicationsNewStore)

const {
  isBlocked,
} = applicationsNewStore

const {
  scrollToInvisibleElement,
} = useScrollTo()

watch(
  lastFocused,
  () => {
    if (lastFocused.value !== 'bgForm' || !bgFormField.value) {
      return
    }

    bgFormField.value.focus()
    scrollToInvisibleElement(bgFormField.value?.$el)
  }
)
</script>

<style lang="scss">
.bgFormToggle {
  .q-btn {
    min-width: 100px;
  }
}
</style>
