import { boot } from 'quasar/wrappers'
import * as Sentry from '@sentry/vue'

export default boot(({ app, router }) => {
  return Sentry.init({
    app,
    dsn: 'https://9bb572b209b1fac67cf78381fb3002b5@sentry.fenixfin.ru/3',
    integrations: [
      new Sentry.Replay(),
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        //tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0,
    environment: import.meta.env.VUE_APP_ENV as string,
    //debug: true,

    // Session Replay
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  })
})
