<template>
  <div class="q-py-sm q-px-md bg-white row">
    <q-select
      v-model="selected"
      class="col ApplicationBankEmployee field-with-top-label q-mb-md"
      :options="items"
      :loading="loading || updateLoading"
      :option-label="optionLabel"
      :option-value="optionValue"
      label="Наименование"
      hide-dropdown-icon
      outlined
      hide-bottom-space
      hide-selected
      use-input
      fill-input
      emit-value
      dense
      label-slot
      clearable
      @filter="onFilter"
    >
      <template #prepend>
        <q-icon name="svguse:icons.svg?2#app-search" />
      </template>
      <template #label>
        <div class="text-weight-medium">
          Наименование
        </div>
      </template>
      <template #option="scope">
        <q-item
          v-bind="scope.itemProps"
          class="q-py-sm q-px-md text-no-wrap ellipsis"
        >
          <div class="row items-center InnOption">
            <div class="text-grey-9 q-mr-sm">{{ scope.opt.fio }}</div>
            <div class="text-grey-6">{{ scope.opt.email }}</div>
          </div>
        </q-item>
      </template>
    </q-select>
    <div
      v-if="modified"
      style="padding-top: 40px"
    >
      <q-btn
        class="q-ml-xs"
        icon="svguse:icons.svg?2#app-check"
        size="10px"
        color="green-14"
        :disable="updateLoading"
        round
        unelevated
        flat
        @click="confirm"
      />
      <q-btn
        class="q-ml-xs"
        icon="svguse:icons.svg?2#app-x"
        size="10px"
        color="red-12"
        :disable="updateLoading"
        round
        unelevated
        flat
        @click="cancel"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import {computed, watchEffect, ref} from 'vue'
import {QSelect} from 'quasar'
import {ApplicationsGridItem, ApplicationsGridItemBankEmployee} from 'src/models/Applications/ApplicationsGridItem'
import {useBankEmployeesLoad} from 'src/uses/Applications/useBankEmployeesLoad'
import {useBankEmployeeUpdate} from 'src/uses/Applications/useBankEmployeeUpdate'

const props = defineProps<{
  item: ApplicationsGridItem,
}>()

const emit = defineEmits<{
  (e: 'update:modelValue', value: number): void,
  (e: 'change'): void,
  (e: 'changed'): void,
}>()

const bankEmployeesLoad = useBankEmployeesLoad()
const {
  getBankEmployeeByName,
  selected,
  items,
  loading,
} = bankEmployeesLoad

const {
  status,
  loading: updateLoading,
  update,
} = useBankEmployeeUpdate()

const optionValue = (opt: ApplicationsGridItemBankEmployee) => {
  return opt
}

const optionLabel = (option: ApplicationsGridItemBankEmployee) => {
  return option.fio || undefined
}

const onFilter = (
  input: string,
  update: (fn: () => void) => void
) => {
  if (!input) {
    update(() => true)
    return
  }

  update(async () => {
    await getBankEmployeeByName(props.item.id, input)
  })
}

const bankEmployeeId = ref<string>('')

const modified = computed(() => {
  return bankEmployeeId.value !== props.item.bank_employee.id
})

const confirm = async () => {
  if (!bankEmployeeId.value) {
    return
  }
  await update(props.item.id, bankEmployeeId.value)

  if (status.value === 200) {
    emit('changed')
  }
}

const cancel = () => {
  bankEmployeeId.value = props.item.bank_employee.id
  selected.value = props.item.bank_employee
}

watchEffect(() => {
  bankEmployeeId.value = props.item.bank_employee.id || ''
})

watchEffect(() => {
  selected.value = props.item.bank_employee
})

watchEffect(() => {
  if (!selected.value) {
    return
  }
  bankEmployeeId.value = selected.value.id
})

</script>

<style lang="scss">
body {
  .q-field {
    &.ApplicationBankEmployee {
      padding-top: 32px !important;
      padding-bottom: 2px;

      .q-field__label {
        top: -32px !important;
        left: -49px !important;
      }

      input {
        margin-top: 2px;
      }

      input::-webkit-input-placeholder {
        opacity: 1 !important;
        color: $grey-8 !important;
      }
    }
  }
}
</style>
