import {useGetApi} from 'src/uses/Api/useGetApi'
import {Chat} from 'src/models/Chat'
import {ref} from 'vue'
import {ChatTypeEnum} from 'src/models/ChatTypeEnum'
import {useProfileStore} from 'src/stores/Login/useProfileStore'
import {storeToRefs} from 'pinia'
import {UserRoleEnum} from 'src/models/User/UserRoleEnum'

export const useChatMessages = (id: string, type: ChatTypeEnum = ChatTypeEnum.platform) => {
  const {
    loading,
    get,
    errors,
    error,
    status,
    response,
  } = useGetApi<Chat[]>()

  const profileStore = useProfileStore()
  const {profile} = storeToRefs(profileStore)

  const AGENT_ORDERS_CONTROL_CENTER_URL = 'v1/control-center'
  const ADMIN_ORDERS_CONTROL_CENTER_URL = 'v1/admin/control-center'

  const chat = ref<Chat[]>()

  const loadMessages = async () => {
    if (!profile.value) {
      return
    }

    const url = [
      UserRoleEnum.Admin,
      UserRoleEnum.JuniorAdmin,
      UserRoleEnum.MiddleAdmin,
    ].includes(profile.value.role)
      ? ADMIN_ORDERS_CONTROL_CENTER_URL
      : AGENT_ORDERS_CONTROL_CENTER_URL

    await get(`${url}/${id}/messages${type === ChatTypeEnum.bank ? '?chat=bank' : ''}`)

    if (status.value === 200 && response.value) {
      chat.value = response.value
    }
  }

  return {
    chat,
    loading,
    errors,
    error,
    status,
    loadMessages,
  }
}
