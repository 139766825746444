import {FormHelper} from 'src/helpers/FormHelper'
import {usePostApi} from 'src/uses/Api/usePostApi'
import {
  ApplicationFormDocument,
} from 'src/models/Applications/Form/ApplicationFormResponseData'
import {ApplicationFormUpdateResponse} from 'src/models/Applications/Form/ApplicationFormUpdateResponse'
import {watchEffect} from 'vue'

export const useApplicationFormUploadDocument = (
  document: ApplicationFormDocument,
  multiOrderId?: number,
  appId?: string,
) => {
  const {
    error,
    errors,
    post,
    status,
    response,
    loading,
    progressEvent,
    abort,
  } = usePostApi<ApplicationFormUpdateResponse, unknown>(true)

  const prepareDocumentData = (document: ApplicationFormDocument, disableStatusChange = false) => {
    const data: Record<string, unknown> = {
      code: document.code,
      index: document.index,
      year: document.period_year,
      quarter: document.period_quarter,
      founder_id: document.founder_id,
      type: document.location.section_type,
      title: document.title,
    }

    if (document.source) {
      data.source = document.source
    }

    if (disableStatusChange) {
      data.status_change_disabled = disableStatusChange
    }

    return data
  }

  const upload = async (document: ApplicationFormDocument, disableStatusChange = false) => {
    if (!multiOrderId && !appId) {
      return
    }

    if (multiOrderId) {
      await post(
        `v2/orders/forms/${multiOrderId}/documents`,
        FormHelper.getFormDataFromObject({
          id: multiOrderId,
          file: document.file || document.file_sign,
          ...prepareDocumentData(document, disableStatusChange),
        })
      )

      return
    }

    await post(
      `v2/control-center/${appId}/documents`,
      FormHelper.getFormDataFromObject({
        id: appId,
        file: document.file || document.file_sign,
        ...prepareDocumentData(document, disableStatusChange),
      })
    )
  }

  watchEffect(() => {
    if (!document) {
      return
    }

    if (!progressEvent.value) {
      document.progress = undefined

      return
    }

    document.progress = progressEvent.value || undefined
  })

  return {
    error,
    errors,
    post,
    status,
    response,
    loading,
    progressEvent,
    upload,
    abort,
  }
}
