<template>
  <q-btn
    color="blue-grey-3"
    icon="svguse:icons.svg?2#app-paper-clip"
    style="margin-bottom: 5px"
    :disable="getDocumentTypes(visibleDocuments).length === 0"
    flat
    round
    @click="show"
  />
  <q-dialog
    v-model="dialog"
    persistent
  >
    <q-card
      class="modal-blue-background column no-wrap"
      style="min-width: 400px; width: 80vw !important; max-width: 80vw !important;"
    >
      <q-card-section class="col">
        <div
          class="modal-title q-nb-md"
          style="font-size: 18px;"
        >
          Прикрепление файлов
        </div>
        <div
          class="white-bg-field"
        >
          <q-scroll-area
            style="height: calc(80vh - 110px); width: 100%;"
          >
            <template
              v-for="type in getDocumentTypes(visibleDocuments)"
              :key="type"
            >
              <q-expansion-item
                class="DocumentsBlock"
                :model-value="true"
                :label="getDocumentGroupLabel(type)"
              >
                <chat-documents-block
                  :documents="visibleDocuments"
                  :document-type="type"
                  :new-documents="newDocuments"
                  :drop-zone-available="dropZoneAvailability[type]"
                  :founders="client ? (client.founders || []) : []"
                  :head-fields="client && client.head ? client.head.fields: []"
                  :can-add-new-added-document="canAddNewAddedDocument(type)"
                  @file:selected-new="onFileSelectedNew"
                  @new-documents:remove="removeNewDocumentByIndex($event, type)"
                  @new-document:update="onUpdateNewDocument"
                />
              </q-expansion-item>
            </template>
          </q-scroll-area>
        </div>
      </q-card-section>
      <q-card-actions
        align="right"
        class="modal-actions text-primary q-pa-lg"
        style="z-index: 1"
      >
        <q-btn
          v-if="canUpload"
          v-close-popup
          label="Загрузить"
          size="16px"
          padding="sm md"
          unelevated
          color="primary"
          no-caps
        />
        <q-btn
          v-close-popup
          label="Отмена"
          size="16px"
          padding="sm lg"
          unelevated
          flat
          no-caps
          text-color="black"
          @click="clearNewDocuments"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
import {computed, watch, watchEffect} from 'vue'
import {useDialog} from 'src/uses/Dialogs/useDialog'
import {
  ApplicationFormDocumentGroupEnum,
  ApplicationFormDocument
} from 'src/models/Applications/Form/ApplicationFormResponseData'
import {
  useApplicationControlCenterItemStore
} from 'src/stores/Applications/ControlCenter/useApplicationControlCenterItemStore'
import {storeToRefs} from 'pinia'
import ChatDocumentsBlock from 'src/components/Chat/ChatDocumentsBlock.vue'
import {useChatUploadDocuments} from 'src/uses/Chat/useChatUploadDocuments'
import {useDocumentGroup} from 'src/uses/Applications/useDocumentGroup'
import {ChatTypeEnum} from 'src/models/ChatTypeEnum'

const props = defineProps<{
  id: string,
  chatType: ChatTypeEnum,
}>()

const applicationItemStore = useApplicationControlCenterItemStore(props.id)()
const {
  addNewDocument,
  removeNewDocumentByIndex,
  updateNewDocument,
  clearNewDocuments,
  canAddNewAddedDocument,
} = applicationItemStore

const {
  visibleDocuments,
  newDocuments,
  canUploadNewDocumentsBySections,
  client,
  dropZoneAvailability,
} = storeToRefs(applicationItemStore)

const onFileSelectedNew = (
  file: File,
  document: ApplicationFormDocument,
  documentType: ApplicationFormDocumentGroupEnum,
) => {
  document.file = file
  addNewDocument(document, documentType)
}
const chatUploadDocuments = useChatUploadDocuments(props.id)
const {
  getDocumentTypes
} = chatUploadDocuments

const {dialog, show, hide} = useDialog()

const {
  getDocumentGroupLabel,
} = useDocumentGroup()

const countNewDocuments = computed(() => {
  return Object.values(newDocuments.value)
    .reduce((c, docs) => c + docs.length, 0)
})

const onUpdateNewDocument = (
  document: ApplicationFormDocument,
  documentType: ApplicationFormDocumentGroupEnum,
  index: number
) => {
  updateNewDocument(document, documentType, index, props.chatType)
}

const canUpload = computed(() => {
  return countNewDocuments.value !== 0
    && canUploadNewDocumentsBySections.value.passports
    && canUploadNewDocumentsBySections.value.financial
    && canUploadNewDocumentsBySections.value.additional
    && canUploadNewDocumentsBySections.value.offer
    && canUploadNewDocumentsBySections.value.deal
})

watch(
  countNewDocuments,
  () => {
    if (countNewDocuments.value === 0) {
      hide()
    }
  }
)

watchEffect(() => {
  if (!Object.keys(newDocuments.value).length) {
    hide()
  }
})

</script>
