<template>
  <q-banner class="FormItemCopyBanner items-center">
    <div class="row q-gutter-lg items-center justify-between">
      <div class="col">
        <q-icon
          name="svguse:icons.svg?2#app-exclamation-circle"
          class="q-mx-sm"
          size="sm"
          color="blue-grey-5"
          circle
        />
        {{ bannerText }}
      </div>
      <form-item-copy-data-dialog
        v-if="canCopy"
        :form-id="props.formId"
        :section-id="props.sectionId"
        :block-id="props.blockId"
        @change="change"
      />
      <form-item-delete-data-dialog
        v-else
        :form-id="props.formId"
        :section-id="props.sectionId"
        @change="change"
      />
    </div>
  </q-banner>
</template>

<script setup lang="ts">
import FormItemCopyDataDialog from 'components/FormEditor/FormItemCopyDataDialog.vue'
import FormItemDeleteDataDialog from 'components/FormEditor/FormItemDeleteDataDialog.vue'
import {computed} from 'vue'

const emit = defineEmits<{
  (e: 'change'): void,
}>()

const props = defineProps<{
  formId: string,
  sectionId: string,
  canCopy: boolean,
  blockId: string,
}>()

const bannerText = computed(() => props.canCopy
  ? 'Вы можете перенести данные из ранее заполненной анкеты'
  : 'Для того, чтобы перенести данные из другой анкеты, удалите все поля в этом разделе'
)

const change = () => {
  emit('change')
}

</script>
<style lang="scss">
.FormItemCopyBanner {
  background: #D5EAFA !important;
  color: $blue-grey-5;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  padding: 10px;
  border-radius: 6px;
  .FormItemCopyBanner--btn {
    background-color: #C7DFF1;
    padding: 10px 20px;
  }
}
</style>
