<template>
  <div class="row q-col-gutter-md q-mb-lg q-pa-none">
    <div class="col-sm-5 col-xs-12">
      <q-input
        v-model="filters.name"
        :loading="props.loading"
        class="NewsListFilters__title"
        style="min-width: 300px"
        label="Поиск по банкам"
        clearable
        outlined
        hide-hint
        hide-bottom-space
        dense
        @change="onSubmit"
        @clear="onSubmit"
      >
        <template #prepend>
          <q-icon name="svguse:icons.svg?2#app-search" />
        </template>
      </q-input>
    </div>
    <div class="col-sm-3 col-xs-12">
      <q-select
        v-model="filters.type"
        :options="productTypes"
        :loading="loading"
        label="Продукт"
        style="min-width: 200px"
        map-options
        outlined
        hide-hint
        hide-bottom-space
        dense
        emit-value
        @update:model-value="onSubmit"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import {storeToRefs} from 'pinia'
import {useProductsGridFiltersStore} from 'stores/Products/useProductsGridFiltersStore'

const props = defineProps<{
  loading: boolean,
}>()

const emit = defineEmits<{
  (e: 'update:filters'): void,
}>()

const filtersStore = useProductsGridFiltersStore()
const {
  filters,
  productTypes
} = storeToRefs(filtersStore)

const onSubmit = () => {
  emit('update:filters')
}
</script>

<style lang="scss">
.NewsListFilters__title {
  &.q-field {
    .q-field__label {
      top: 15px !important;
    }
  }
}
</style>
