<template>
  <q-intersection
    transition="scale"
    once
  >
    <div
      v-if="mode === ApplicationNewModeEnum.clone && selectedOriginalItem"
      class="text-h6 q-my-md"
    >
      Заявка №{{ selectedOriginalItem.number }}
      От:
      <date-item :model-value="selectedOriginalItem.date" />
    </div>
    <q-form
      class="q-mb-lg q-mt-sm"
      @submit="handleSubmit"
    >
      <div class="row q-col-gutter-md wrap">
        <div class="col-4 col-sm-6 col-xs-12">
          <rnt-block />
        </div>
        <div class="col-4 col-sm-6 col-xs-12">
          <law-block />
        </div>
        <div class="col-4 col-sm-6 col-xs-12">
          <provosion-type-block />
        </div>
        <div class="col-4 col-sm-6 col-xs-12">
          <currency-block />
        </div>
        <div class="col-4 col-sm-6 col-xs-12">
          <date-block />
        </div>
        <div class="col-4 col-sm-6 col-xs-12">
          <div class="row">
            <bg-form-block />
          </div>
        </div>
      </div>
    </q-form>
  </q-intersection>
</template>

<script setup lang="ts">
import RntBlock from 'src/components/Applications/ApplicationNew/Form/RntBlock.vue'
import LawBlock from 'src/components/Applications/ApplicationNew/Form/LawBlock.vue'
import ProvosionTypeBlock from 'src/components/Applications/ApplicationNew/Form/ProvosionTypeBlock.vue'
import DateBlock from 'src/components/Applications/ApplicationNew/Form/DateBlock.vue'
import BgFormBlock from 'src/components/Applications/ApplicationNew/Form/BgFormBlock.vue'
import CurrencyBlock from 'src/components/Applications/ApplicationNew/Form/CurrencyBlock.vue'
import {useApplicationsNew} from 'stores/Applications/ApplicationsNew/useApplicationsNew'
import {storeToRefs} from 'pinia'
import {ApplicationNewModeEnum} from 'src/models/Applications/New/ApplicationNewModeEnum'
import DateItem from 'components/DateItem.vue'

const applicationsNewStore = useApplicationsNew()
const {
  mode,
  selectedOriginalItem,
} = storeToRefs(applicationsNewStore)

const handleSubmit = () => {
  //
}
</script>

<style lang="scss" scoped>
.bottom {
  border-top: 1px solid $blue-grey-1;
}
</style>
