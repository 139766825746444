<template>
  <form-select
    :model-value="props.modelValue"
    :options="options"
    label="Банк"
    :loading="banksLoading || props.loading"
    top-actions
    @update:model-value="onChange"
  />
</template>

<script setup lang="ts">
import {useBankFilterStore} from 'src/stores/Banks/useBankFilterStore'
import {storeToRefs} from 'pinia'
import {computed, onBeforeMount} from 'vue'
import FormSelect from 'src/components/FormElements/FormSelect.vue'
import {ProductEnum} from 'src/models/Applications/ProductEnum'
import {FormElementValue} from 'src/models/Form'

const props = withDefaults(
  defineProps<{
    modelValue: string[] | string
    loading: boolean
    productType?: ProductEnum
  }>(),
  {
    productType: undefined
  }
)

const emit = defineEmits<{
  (e: 'update:model-value', val: string[] | string): void,
  (e: 'change'): void,
}>()

const banksStore = useBankFilterStore()
const {
  load,
  getOptionsByProduct,
} = banksStore
const {loading: banksLoading, banks} = storeToRefs(banksStore)

const options = computed(() => {
  return getOptionsByProduct(props.productType)
})

const onChange = (val: FormElementValue) => {
  emit('update:model-value', val as string[])
  emit('change')
}

onBeforeMount(() => {
  if (banks.value.length === 0) {
    load()
  }
})
</script>
