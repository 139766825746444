import {mask} from 'vue-the-mask'
import {boot} from 'quasar/wrappers'
import {Money3Component, Money3Directive} from 'v-money3'
import 'moment/dist/locale/ru'

export default boot(({app}) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore:next-line
  app.directive('mask', mask)
  /* eslint-disable vue/multi-word-component-names */
  app.component('money3', Money3Component)
  app.directive('money3', Money3Directive)
})
