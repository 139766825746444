import {defineStore} from 'pinia'
import {ref, watch} from 'vue'
import {useQuasar} from 'quasar'
import {useLocalStorage} from 'src/uses/useLocalStorage'

const breakpoints = [
  {
    width: 1440,
    name: 'xl'
  },
  {
    width: 1280,
    name: 'lg'
  },
  {
    width: 1024,
    name: 'md'
  },
  {
    width: 600,
    name: 'sm'
  }
]

let refreshTimeout: number

export const useLayoutStore = defineStore('layout-store', () => {
  const {
    data,
    getFromStorage,
    setToStorage,
  } = useLocalStorage<Record<string, boolean>>('menu')

  const currentWidth = ref(window.innerWidth)
  const currentContentWidth = ref(window.innerWidth)
  const q = useQuasar()
  //const route = useRoute()

  const miniState = ref(false)
  const drawer = ref(false)

  const rightMiniState = ref(false)
  const rightDrawer = ref(false)

  const drawerWidth = ref(0)
  const firstLoad = ref(true)

  const drawerClick = (e: Event) => {
    if (miniState.value) {
      miniState.value = false
      e.stopPropagation()
    }
  }

  const onMenu = () => {
    if (currentWidth.value >= 650) {
      miniState.value = !miniState.value
      setToStorage({miniState: miniState.value})
      return
    }

    drawer.value = !drawer.value
  }

  const onMenuRight = () => {
    if (currentWidth.value >= 650) {
      rightMiniState.value = !rightMiniState.value
      return
    }

    rightDrawer.value = !rightDrawer.value
  }

  const refreshDrawer = () => {
    drawer.value = currentWidth.value > 650
    if (firstLoad.value) {
      firstLoad.value = false

      //return
    }
    //miniState.value = currentWidth.value < 1400
    //setToStorage({miniState: miniState.value})
  }

  const rightDrawerClick = (e: Event) => {
    if (rightMiniState.value) {
      rightMiniState.value = !rightMiniState.value
      e.stopPropagation()
    }

    rightDrawer.value = !rightDrawer.value
  }

  const refreshRightDrawer = () => {
    rightDrawer.value = currentWidth.value > 650
    rightMiniState.value = currentWidth.value < 1400
  }

  const refreshDrawers = () => {
    drawerWidth.value = currentWidth.value >= 2200
      ? 350
      : currentWidth.value >= 1920
        ? 300 : 250
    refreshRightDrawer()
    refreshDrawer()
  }

  const onResize = ({width}: { width: number }) => {
    currentWidth.value = width
  }

  const onContentResize = ({width}: { width: number }) => {
    currentContentWidth.value = width
    refreshBreakpoints()
  }

  const getBreakpoints = () => {
    const width = currentContentWidth.value
    return Object.fromEntries(
      breakpoints
        .map((breakpoint, index) => {
          return [
            breakpoint.name,
            width >= breakpoint.width
              ? window.innerWidth - index
              : window.innerWidth + 1
          ]
        })
    )
  }

  const addClass = (names: string[]) => {
    const tokens = names
      .filter(name => !document.body.classList.contains(name))
    if (tokens.length === 0) {
      return
    }

    document.body.classList.add(...tokens)
  }

  const removeClass = (names: string[]) => {
    const tokens = names
      .filter(name => document.body.classList.contains(name))
    if (tokens.length === 0) {
      return
    }

    document.body.classList.remove(...tokens)
  }

  const refreshBreakpoints = () => {
    clearTimeout(refreshTimeout)
    refreshTimeout = setTimeout(realRefreshBreakpoints, 100) as unknown as number
  }

  const realRefreshBreakpoints = () => {
    q.screen.setSizes(getBreakpoints())

    if (q.screen.xs) {
      addClass(['screen--xl', 'screen--lg', 'screen--md', 'screen--sm', 'screen--xs'])
    }
    if (q.screen.sm) {
      addClass(['screen--xl', 'screen--lg', 'screen--md', 'screen--sm'])
      removeClass(['screen--xs'])
    }
    if (q.screen.md) {
      addClass(['screen--xl', 'screen--lg', 'screen--md'])
      removeClass(['screen--sm', 'screen--xs'])
    }
    if (q.screen.lg) {
      addClass(['screen--xl', 'screen--lg'])
      removeClass(['screen--md', 'screen--sm', 'screen--xs'])
    }
    if (q.screen.xl) {
      addClass(['screen--xl'])
      removeClass(['screen--lg', 'screen--md', 'screen--sm', 'screen--xs'])
    }
  }

  watch(
    miniState,
    () => {
      if (miniState.value) {
        return
      }
      if (window.innerWidth > 1024) {
        return
      }

      rightMiniState.value = true
    },
    /*{
      immediate: true,
    }*/
  )

  watch(
    rightMiniState,
    () => {
      if (rightMiniState.value) {
        return
      }
      if (window.innerWidth > 1024) {
        return
      }

      miniState.value = true
    }/*,
    {
      immediate: true,
    }*/
  )

  watch(
    currentWidth,
    () => {
      refreshDrawers()
    },
    {
      immediate: true,
    }
  )

  /*watch(
    route,
    () => {
      refreshDrawers()
    },
    {
      immediate: true,
      deep: true,
    }
  )*/

  watch(
    data,
    () => {
      if (data.value) {
        miniState.value = data.value.miniState
      }
    },
    {
      immediate: true,
      deep: true,
    }
  )

  refreshBreakpoints()
  getFromStorage()

  return {
    currentWidth,
    currentContentWidth,
    onResize,
    onContentResize,
    rightMiniState,
    rightDrawer,
    rightDrawerClick,
    refreshRightDrawer,
    onMenuRight,
    miniState,
    drawer,
    drawerClick,
    onMenu,
    refreshDrawer,
    refreshDrawers,
    drawerWidth,
  }
})
