import {defineStore, storeToRefs} from 'pinia'
import {useFormItemStore} from 'stores/FormEditor/useFormItemStore'
import {useFormItemGetGeneratedDocuments} from 'stores/FormEditor/useFormItemGetGeneratedDocuments'
import {useFormItemGeneratedDocumentsUpdate} from 'stores/FormEditor/useFormItemGeneratedDocumentsUpdate'
import {watch} from 'vue'

export const useFormItemGeneratedDocumentsStore = (formId: string) => defineStore(`form-item-generated-documents-${formId}`, () => {
  const formItemGetDocumentsStore = useFormItemGetGeneratedDocuments()
  const {
    generatedDocuments,
    loadDocs,
  } = formItemGetDocumentsStore

  const {
    generatedDocuments: formGeneratedDocuments,
  } = storeToRefs(useFormItemStore(formId)())

  const {
    update,
  } = useFormItemGeneratedDocumentsUpdate(formId)

  const load = async () => {
    await loadDocs()
    recalculateGenerated()
  }

  const recalculateGenerated = () => {
    if (!generatedDocuments.value) {
      return
    }

    generatedDocuments.value = generatedDocuments.value
      .map(doc => {
        return {
          ...doc,
          generated: !!(
            (formGeneratedDocuments.value || [])
              .find(fDoc => fDoc.code === doc.code && fDoc.generated)
          )
        }
      })
  }

  watch(
    formGeneratedDocuments,
    recalculateGenerated,
    {
      deep: true,
      immediate: true,
    }
  )

  return {
    load,
    update,
    generatedDocuments,
    formGeneratedDocuments,
  }
})
