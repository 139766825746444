import {usePutApi} from 'src/uses/Api/usePutApi'
import {useProfileStore} from 'src/stores/Login/useProfileStore'

export const usePaymentUpdate = () => {
  const {
    error,
    errors,
    put,
    status,
    response,
    loading,
  } = usePutApi<unknown, {payment: number}>()

  const ADMIN_URL = 'v1/admin/order'
  const AGENT_MANAGER_URL = 'v1/order'

  const {
    isAgentManager,
  } = useProfileStore()

  const update = async (appId: string, payment: number) => {
    await put(
      `${isAgentManager() ? AGENT_MANAGER_URL : ADMIN_URL}/${appId}/payment`,
      {
        payment
      }
    )
  }

  return {
    error,
    errors,
    put,
    status,
    response,
    loading,
    update,
  }
}
