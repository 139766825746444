import {DocumentsCodesEnum} from 'src/models/DocumentsCodesEnum'

export const useDocumentsDescription = () => {
  const docsDesc: Record<DocumentsCodesEnum, string> = {
    [DocumentsCodesEnum.ACCOUNTING_REPORTS]: 'Бух. отчетность',
    [DocumentsCodesEnum.PASSPORT_HEAD]: 'Паспорт руководителя',
    [DocumentsCodesEnum.PASSPORT_FOUNDER]: 'Паспорт учредителя',
    [DocumentsCodesEnum.ADDED_DOCUMENT]: 'Дополнительный документ',
    [DocumentsCodesEnum.BG_FORM]: 'БГ (форма)',
    [DocumentsCodesEnum.INVOICE]: 'Счет',
    [DocumentsCodesEnum.BG_SCAN]: 'БГ (Скан)',
    [DocumentsCodesEnum.EXTRACT_FROM_BG_REGISTRY]: 'Выписка из реестра БГ',
    [DocumentsCodesEnum.CUSTOMER_FORM]: 'БГ форма заказчика',
    [DocumentsCodesEnum.POWER_OF_ATTORNEY_FOR_THE_RIGHT_TO_SIGN]: 'Заявление на присоединение к правилам',
    [DocumentsCodesEnum.TAX_RETURN]: '',
    [DocumentsCodesEnum.ACCOUNT_CARD_51_FOR_LAST_FULL_YEAR]: 'Карточка 51 счета полный год',
    [DocumentsCodesEnum.ACCOUNT_CARD_51_FOR_YEAR]: 'Карточка 51 счета год',
  }

  const docsGridDownloadDesc: Record<string, string> = {
    [DocumentsCodesEnum.BG_FORM]: 'БГ форму',
    [DocumentsCodesEnum.INVOICE]: 'счет',
    [DocumentsCodesEnum.BG_SCAN]: 'скан',
    [DocumentsCodesEnum.EXTRACT_FROM_BG_REGISTRY]: 'выписку',
  }

  const docDescByCode = (code: DocumentsCodesEnum) => docsDesc[code] || code

  const docGridDownloadDesc = (code: string) => docsGridDownloadDesc[code] || code

  return {
    docDescByCode,
    docGridDownloadDesc
  }
}
