import {ref} from 'vue'
import {useGetApi} from 'src/uses/Api/useGetApi'
import {InnClientData} from 'src/models/Applications/New/InnClientData'
import {ApplicationNewProductData} from 'src/models/Applications/New/ApplicationNewProductData'
import {ApplicationNewClientDataResponse} from 'src/models/Applications/New/ApplicationNewClientDataResponse'

export const useApplicationsNewInnClientData = () => {
  const {
    loading,
    get,
    status,
    response,
  } = useGetApi<ApplicationNewClientDataResponse>()

  const clientData = ref<InnClientData>()
  const productsData = ref<ApplicationNewProductData[]>()

  const load = async (inn: string) => {
    await get(`client/${inn}/data`)

    if (status.value === 200 && response.value) {
      clientData.value = response.value.client
      productsData.value = response.value.products
    }
  }

  return {
    clientData,
    productsData,
    loading,
    load,
  }
}
