import {ApplicationStatusEnum} from 'src/models/Applications/ApplicationStatusEnum'
import {ApplicationSubStatusEnum} from 'src/models/Applications/ApplicationSubStatusEnum'

export const useApplicationControlCenterStatuses = () => {
  const isRiskAndRequestExecutedStatuses = (subStatus: ApplicationSubStatusEnum) => {
    const subStatuses = [
      ApplicationSubStatusEnum.client_signed,
      ApplicationSubStatusEnum.data_request_executed,
      ApplicationSubStatusEnum.risks_request_executed,
      ApplicationSubStatusEnum.risks_assessment,
      ApplicationSubStatusEnum.send_2_bank,
      ApplicationSubStatusEnum.in_bank,
    ]

    return subStatuses.includes(subStatus)
  }

  const isDataRequestStatuses = (subStatus: ApplicationSubStatusEnum) => {
    const subStatuses = [
      ApplicationSubStatusEnum.data_request,
      ApplicationSubStatusEnum.risks_request,
      ApplicationSubStatusEnum.bg_project_bank_agreement_request,
      ApplicationSubStatusEnum.deferred_conditions_execution_waiting,
      ApplicationSubStatusEnum.guarantee_released_request,
    ]

    return subStatuses.includes(subStatus)
  }

  const isVerificationStatuses = (subStatus: ApplicationSubStatusEnum) => {
    const subStatuses = [
      ApplicationSubStatusEnum.talend_loaded,
      ApplicationSubStatusEnum.go_loaded,
      ApplicationSubStatusEnum.forming_errors,
      ApplicationSubStatusEnum.accepted,
    ]

    return subStatuses.includes(subStatus)
  }

  const isReleaseWaitingStatuses = (subStatus: ApplicationSubStatusEnum) => {
    const subStatuses = [
      ApplicationSubStatusEnum.payment_waiting,
      ApplicationSubStatusEnum.paid,
      ApplicationSubStatusEnum.deferred_conditions_executed,
    ]

    return subStatuses.includes(subStatus)
  }

  const isSignStatuses = (subStatus: ApplicationSubStatusEnum) => {
    const subStatuses = [
      ApplicationSubStatusEnum.data_request_sign,
      ApplicationSubStatusEnum.risks_request_sign,
      ApplicationSubStatusEnum.bg_project_customer_agreement_request_sign,
      ApplicationSubStatusEnum.bg_project_bank_agreement_request_sign,
      ApplicationSubStatusEnum.deferred_conditions_execution_waiting_sign,
      ApplicationSubStatusEnum.guarantee_released_request_sign,
    ]

    return subStatuses.includes(subStatus)
  }

  const isRed = (status:ApplicationStatusEnum, subStatus: ApplicationSubStatusEnum): boolean => {
    const statuses = [
      ApplicationSubStatusEnum.client_rejected,
      ApplicationSubStatusEnum.bank_rejected,
    ]

    return statuses.includes(subStatus)
  }

  const isGreen = (status:ApplicationStatusEnum, subStatus: ApplicationSubStatusEnum): boolean => {
    const subStatuses = [
      ApplicationSubStatusEnum.guarantee_released,
    ]

    const statuses = [
      ApplicationStatusEnum.on_release,
      ApplicationStatusEnum.verification,
    ]

    return subStatuses.includes(subStatus) || statuses.includes(status)
  }

  const isGrey = (status:ApplicationStatusEnum, subStatus: ApplicationSubStatusEnum): boolean => {
    const subStatuses = [
      ApplicationSubStatusEnum.draft,
    ]

    const statuses = [
      ApplicationStatusEnum.draft,
    ]

    return subStatuses.includes(subStatus) || statuses.includes(status)
  }

  const getStatusClass = (status: ApplicationStatusEnum, subStatus: ApplicationSubStatusEnum) => {
    if (isRed(status, subStatus)) {
      return 'red-color-status'
    }

    if (isGreen(status, subStatus)) {
      return 'green-color-status'
    }

    if (isGrey(status, subStatus)) {
      return 'grey-color-status'
    }

    return 'blue-color-status'
  }

  return {
    isRiskAndRequestExecutedStatuses,
    isDataRequestStatuses,
    isVerificationStatuses,
    isReleaseWaitingStatuses,
    isSignStatuses,
    getStatusClass,
  }
}
