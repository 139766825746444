<template>
  <page-block-wrapper>
    <div class="clients">
      <contractors-grid />
    </div>
  </page-block-wrapper>
</template>
<script setup lang="ts">
import PageBlockWrapper from 'src/components/PageBlockWrapper.vue'
import {useMetaStore} from 'src/stores/useMetaStore'
import ContractorsGrid from 'components/Contractors/ContractorsGrid.vue'

useMetaStore().setTitle('контрагенты')
</script>
