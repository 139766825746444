import {AuthedGuard} from 'src/router/quards/AuthedGuard'
import {UserRoleEnum} from 'src/models/User/UserRoleEnum'
import {Platform} from 'quasar'
import {ApplicationGridTypeEnum} from 'src/models/Applications/ApplicationGridTypeEnum'
import {ApplicationGridTypeGuard} from 'src/router/quards/ApplicationGridTypeGuard'

const importGridView = () => {
  if (Platform.is.mobile) {
    return import('../views/Applications/ApplicationsGridMobileView.vue')
  }

  return import('../views/Applications/ApplicationsGridView.vue')
}

const importGridKikView = () => {
  if (Platform.is.mobile) {
    return import('../views/Applications/ApplicationsGridKikMobileView.vue')
  }

  return import('../views/Applications/ApplicationsGridKikView.vue')
}

const importMultiGridView = () => {
  if (Platform.is.mobile) {
    return import('../views/Applications/ApplicationsMultiGridMobileView.vue')
  }

  return import('../views/Applications/ApplicationsMultiGridView.vue')
}

export const applicationsRouter = {
  path: '/applications',
  name: 'applications',
  component: () => import('../layouts/MainLayout.vue'),
  children: [
    {
      path: 'grid-kik',
      name: 'applications-grid-kik',
      component: () => importGridKikView(),
      meta: {
        guards: [
          AuthedGuard([
            UserRoleEnum.Agent,
            UserRoleEnum.Admin,
            UserRoleEnum.JuniorAdmin,
            UserRoleEnum.Employee,
            UserRoleEnum.HeadEmployee,
            UserRoleEnum.Operator,
            UserRoleEnum.AgentManager,
            UserRoleEnum.BankEmployee,
          ]),
        ],
      },
    },
    {
      path: 'grid',
      name: ApplicationGridTypeEnum.grid,
      component: () => importGridView(),
      meta: {
        guards: [
          AuthedGuard([
            UserRoleEnum.Agent,
            UserRoleEnum.Admin,
            UserRoleEnum.JuniorAdmin,
            UserRoleEnum.Employee,
            UserRoleEnum.HeadEmployee,
            UserRoleEnum.Operator,
            UserRoleEnum.AgentManager,
            UserRoleEnum.BankEmployee,
          ]),
          ApplicationGridTypeGuard(),
        ],
      },
    },
    {
      path: 'multi-grid',
      name: ApplicationGridTypeEnum.multiGrid,
      component: () => importMultiGridView(),
      meta: {
        guards: [
          AuthedGuard([
            UserRoleEnum.Agent,
            UserRoleEnum.Admin,
            UserRoleEnum.JuniorAdmin,
            UserRoleEnum.Employee,
            UserRoleEnum.HeadEmployee,
            UserRoleEnum.Operator,
            UserRoleEnum.AgentManager,
            UserRoleEnum.BankEmployee,
          ]),
          ApplicationGridTypeGuard(),
        ],
      },
    },
    {
      path: 'new',
      name: 'applications-new',
      components: {
        default: () => import('../views/Applications/ApplicationsNewView.vue'),
        rightSidebar: () => import('../views/Applications/ApplicationsNewSidebarView.vue'),
      },
      meta: {
        guards: [
          AuthedGuard([
            UserRoleEnum.Agent,
            UserRoleEnum.Admin,
            UserRoleEnum.JuniorAdmin,
            UserRoleEnum.Employee,
            UserRoleEnum.HeadEmployee,
            UserRoleEnum.Operator,
            UserRoleEnum.AgentManager,
          ]),
        ],
      },
    },
    {
      path: 'item/:formId',
      name: 'application-item',
      components: {
        default: () => import('../views/Applications/ApplicationItemView.vue'),
        rightSidebar: () => import('../views/Applications/ApplicationItemSidebarView.vue'),
      },
      meta: {
        guards: [
          AuthedGuard([
            UserRoleEnum.Agent,
            UserRoleEnum.Admin,
            UserRoleEnum.JuniorAdmin,
            UserRoleEnum.Employee,
            UserRoleEnum.HeadEmployee,
            UserRoleEnum.Operator,
            UserRoleEnum.AgentManager,
            UserRoleEnum.BankEmployee,
          ]),
        ],
      },
    },
    {
      path: 'control-center/:appId/:formId',
      name: 'application-control-center',
      component: () => import('../views/Applications/ApplicationControlCenterView.vue'),
      /*components: {
        default: () => import('../views/Applications/ApplicationControlCenterView.vue'),
        rightSidebar: () => import('../views/Applications/ApplicationControlCenterSidebarView.vue'),
      },*/
      meta: {
        guards: [
          AuthedGuard([
            UserRoleEnum.Agent,
            UserRoleEnum.Admin,
            UserRoleEnum.JuniorAdmin,
            UserRoleEnum.Employee,
            UserRoleEnum.HeadEmployee,
            UserRoleEnum.Operator,
            UserRoleEnum.AgentManager,
            UserRoleEnum.BankEmployee,
          ]),
        ],
      },
    },
  ]
}
