<template>
  <div class="col-sm col-xs-12 bannerMainContent">
    Ознакомьтесь с данными и документами в заявке и при необходимости смените статус или напишите сообщение в чат
  </div>
  <div class="bannerInfoBtnWrap">
    <q-btn-dropdown
      color="primary"
      label="Сменить статус"
      padding="10px 15px"
      no-caps
      auto-close
    >
      <q-list>
        <q-item
          v-for="status in statuses"
          :key="status.payload.applicationStatus"
          :disable="loading"
          clickable
          @click="changeStatus(status.payload)"
        >
          <q-item-section>
            <q-item-label>
              {{ status.title }}
            </q-item-label>
          </q-item-section>
        </q-item>
      </q-list>
    </q-btn-dropdown>
  </div>
</template>

<script setup lang="ts">
import {computed} from 'vue'
import {ControlCenterHeader} from 'src/models/Applications/ControlCenter/ControlCenterHeader'
import {ChangeStatusPayload} from 'src/models/Status/ChangeStatusPayload'
import {storeToRefs} from 'pinia'
import {ApplicationKikSubStatusEnum} from 'src/models/Applications/ApplicationKikSubStatusEnum'
import {
  useApplicationKikControlCenterChangeStatusStore
} from 'stores/Applications/ControlCenter/ChangeStatus/useApplicationKikControlCenterChangeStatusStore'

const props = defineProps<{
  headerInfo: ControlCenterHeader
}>()

const {
  changeStatus,
} = useApplicationKikControlCenterChangeStatusStore(props.headerInfo.id)()
const {loading} = storeToRefs(useApplicationKikControlCenterChangeStatusStore(props.headerInfo.id)())

const statusesData = [
  {
    sub_status: ApplicationKikSubStatusEnum.in_bank,
    title: 'Отправлено в банк',
    initiator: ApplicationKikSubStatusEnum.in_bank,
    withoutMessage: false,
  },
  {
    sub_status: ApplicationKikSubStatusEnum.new,
    title: 'Требуется подпись',
    initiator: ApplicationKikSubStatusEnum.new,
    withoutMessage: false,
  },
  {
    sub_status: ApplicationKikSubStatusEnum.approved,
    title: 'Одобрена',
    initiator: ApplicationKikSubStatusEnum.approved,
    withoutMessage: false,
  },
  {
    sub_status: ApplicationKikSubStatusEnum.released,
    title: 'Выдана',
    initiator: ApplicationKikSubStatusEnum.released,
    withoutMessage: false,
  },
  {
    sub_status: ApplicationKikSubStatusEnum.bank_rejected,
    title: 'Отказ Банка',
    initiator: ApplicationKikSubStatusEnum.bank_rejected,
    withoutMessage: false,
  },
]

const statuses = computed<{ payload: ChangeStatusPayload, title: string}[]>(
  () => statusesData
    .filter(data => data.sub_status !== props.headerInfo.sub_status)
    .map(data => ({
      payload: {
        id: props.headerInfo.id,
        applicationStatus: data.sub_status,
        initiator: data.initiator,
        withoutMessage: data.withoutMessage,
      },
      title: data.title,
    }))
)
</script>

<style scoped lang="scss">

</style>
