import {useFilterWithErrors} from 'src/uses/Applications/Form/fill/useFilterWithErrors'
import {computed} from 'vue'
import {
  ApplicationFormDocumentGroupEnum,
  ApplicationFormDocument, ApplicationFormDocuments, ApplicationFormBank,
} from 'src/models/Applications/Form/ApplicationFormResponseData'
import {Ref} from 'vue-demi'

export const useApplicationFormFillDocuments = (visibleDocuments: Ref<ApplicationFormDocuments>, banks: Ref<ApplicationFormBank[]>) => {
  const {
    filterWithErrors,
    filterOnlyCommonOrDraftBanks,
  } = useFilterWithErrors(banks)

  const filterErrorsByGroup = (group: ApplicationFormDocumentGroupEnum) => {
    if (!visibleDocuments.value || !visibleDocuments.value[group]) {
      return []
    }

    return (visibleDocuments.value[group] || [])
      .filter(filterOnlyCommonOrDraftBanks)
  }

  const passports = computed(() => filterErrorsByGroup(ApplicationFormDocumentGroupEnum.passports))
  const financial = computed(() => filterErrorsByGroup(ApplicationFormDocumentGroupEnum.financial))
  const additional = computed(() => filterErrorsByGroup(ApplicationFormDocumentGroupEnum.additional))

  const fillDocumentsByBanks = computed(() => {
    return banks.value
      .map(bank => {
        return {
          ...bank,
          total: passports.value
            .filter(f => !f.location.bank_id || f.location.bank_id === bank.bank_id)
            .length
            + financial.value
              .filter(f => !f.location.bank_id || f.location.bank_id === bank.bank_id)
              .length
            + additional.value
              .filter(f => !f.location.bank_id || f.location.bank_id === bank.bank_id)
              .length,
          error: passportsDocumentsErrorsFields.value
            .filter(f => !f.location.bank_id || f.location.bank_id === bank.bank_id)
            .filter(f => !f.error
                && f.valid
                  .find(v => v.id === bank.bank_id && v.errors.length > 0))
            .length
            + financialDocumentsErrorsFields.value
              .filter(f => !f.location.bank_id || f.location.bank_id === bank.bank_id)
              .filter(f => !f.error
                && f.valid
                  .find(v => v.id === bank.bank_id && v.errors.length > 0))
              .length
            + additionalDocumentsErrorsFields.value
              .filter(f => !f.location.bank_id || f.location.bank_id === bank.bank_id)
              .filter(f => !f.error
                && f.valid
                  .find(v => v.id === bank.bank_id && v.errors.length > 0))
              .length,
        }
      })
  })

  const passportsDocumentsErrorsFields = computed<ApplicationFormDocument[]>(() => {
    return passports.value.filter(filterWithErrors)
  })
  const financialDocumentsErrorsFields = computed<ApplicationFormDocument[]>(() => {
    return financial.value.filter(filterWithErrors)
  })
  const additionalDocumentsErrorsFields = computed<ApplicationFormDocument[]>(() => {
    return additional.value.filter(filterWithErrors)
  })

  const documentsHasErrors = computed(() => {
    return passportsDocumentsErrorsFields.value.length
      || financialDocumentsErrorsFields.value.length
      || additionalDocumentsErrorsFields.value.length
  })

  const countAllDocumentsFields = computed(() => {
    return passports.value.length
      + financial.value.length
      + additional.value.length
  })
  const countErrorsDocumentsFields = computed(() => {
    return passportsDocumentsErrorsFields.value.length
      + financialDocumentsErrorsFields.value.length
      + additionalDocumentsErrorsFields.value.length
  })

  return {
    documentsHasErrors,
    countAllDocumentsFields,
    countErrorsDocumentsFields,
    fillDocumentsByBanks,
    passportsDocumentsErrorsFields,
    financialDocumentsErrorsFields,
    additionalDocumentsErrorsFields,
  }
}
