import {ProvisionTypeEnum} from 'src/models/Applications/ProvosionTypeEnum'
import {useOptionsFromObject} from 'src/uses/useOptionsFromObject'

const provisionTypes: Record<ProvisionTypeEnum, string> = {
  [ProvisionTypeEnum.Participation]: 'Участие',
  [ProvisionTypeEnum.Execution]: 'Исполнение',
  [ProvisionTypeEnum.Guarantee]: 'Гарантийные обязательства',
  [ProvisionTypeEnum.Refund]: 'Возврат аванса',
}

export const useProvisionType = () => {
  const {getOptionsFromObject} = useOptionsFromObject()
  const options = getOptionsFromObject(provisionTypes)

  const getProvisionTypeLabel = (provisionType: ProvisionTypeEnum | string): string => {
    return provisionTypes[provisionType as ProvisionTypeEnum] || ''
  }

  return {
    getProvisionTypeLabel,
    options,
  }
}
