<template>
  <q-btn
    v-if="(isBankEmployee() || isAdmin()) && requestStatus"
    class="requestButton float-left"
    label="Выставить запрос"
    icon-right="svguse:icons.svg?2#app-information-circle"
    :disable="loading"
    :loading="loading"
    unelevated
    no-caps
    @click="changeStatus(
      {
        id: props.headerInfo.id,
        applicationStatus: requestStatus
      },
      props.headerInfo.sub_status as ApplicationSubStatusEnum
    )"
  />
</template>

<script setup lang="ts">
import {computed} from 'vue'
import {ControlCenterHeader} from 'src/models/Applications/ControlCenter/ControlCenterHeader'
import {
  useApplicationControlCenterChangeStatusStore
} from 'src/stores/Applications/ControlCenter/ChangeStatus/useApplicationControlCenterChangeStatusStore'
import {useProfileStore} from 'src/stores/Login/useProfileStore'
import {storeToRefs} from 'pinia'
import {ApplicationSubStatusEnum} from 'src/models/Applications/ApplicationSubStatusEnum'

const props = defineProps<{
  headerInfo: ControlCenterHeader
}>()

const {
  changeStatus,
  getSendRequestApplicationStatus,
} = useApplicationControlCenterChangeStatusStore(props.headerInfo?.id)()
const {loading} = storeToRefs(useApplicationControlCenterChangeStatusStore(props.headerInfo?.id)())

const {
  isBankEmployee,
  isAdmin,
} = useProfileStore()

const requestStatus = computed(() => getSendRequestApplicationStatus(props.headerInfo))
</script>

<style scoped lang="scss">

</style>
