<template>
  <q-btn
    size="sm"
    icon="svguse:icons.svg?2#app-lock-closed"
    :color="props.product.required ? 'blue-4' : 'blue-grey-4'"
    round
    flat
    @click="open"
  />
  <q-dialog
    v-model="dialog"
  >
    <q-card
      class="modal-blue-background productRequired"
      style="min-width: 500px; max-width: 800px;"
    >
      <q-card-section
        class="modal-title row items-center q-pb-none q-pl-lg bg-white"
      >
        <div>Настройка обязательности</div>
      </q-card-section>
      <q-card-section class="q-pl-lg q-pr-lg bg-white">
        <q-form>
          <div class="row q-col-gutter-y-lg white-bg-field">
            <div class="col-12 q-mb-sm">
              <q-toggle
                v-model="requiredControlData.required"
                label="Банк обязателен для выбора в подборе"
                class="q-mr-md"
                style="margin-left: -10px;"
                color="blue-7"
              />
            </div>
          </div>
        </q-form>
      </q-card-section>
      <q-card-section class="q-pa-none">
        <div
          v-if="requiredControlData.required"
          class="row items-center q-pa-lg q-pt-none "
        >
          <div class="col text-bold">
            Условия: {{ conditionsCount  }}
          </div>
          <q-btn
            label="Добавить условие"
            icon-right="svguse:icons.svg?2#app-plus"
            color="blue-7"
            style="padding: 12px 23px;"
            unelevated
            no-wrap
            no-caps
            @click="addCondition()"
          />
        </div>
        <div
          v-if="requiredControlData.required"
          class="row"
        >
          <div
            v-if="requiredControlData && requiredControlData.conditions && requiredControlData.conditions.parts.length > 0"
            class="col-12 q-pa-lg q-pt-none">
            <div v-for="(condition, index) in requiredControlData.conditions.parts" :key="index">
              <div class="row q-mb-md q-pa-md"
                style="border-radius: 6px; border: 1px dotted #adacac;"
              >
                <div class="col-12">
                  <div class="text-bold q-mb-xs">Условие #{{ index + 1 }}</div>
                  <q-form>
                    <div
                      v-for="(param , indexParam) in condition.parts"
                      :key="indexParam"
                      class="q-py-xs"
                    >
                      <div class="text-blue-grey-4 q-mb-sm">Параметр</div>
                      <div class="row q-gutter-sm white-bg-field">
                        <q-select
                          v-model="param.param"
                          :options="requiredControlData.conditions_params"
                          class="col field-with-top-label q-mb-sm"
                          option-label="title"
                          option-value="param"
                          emit-value
                          map-options
                          outlined
                          hide-bottom-space
                          dense
                          @update:model-value="onChangeParam(index, indexParam)"
                        />
                        <q-field
                          :model-value="param.operator"
                          class="field-with-top-label q-pt-none no-bg q-mb-sm text-no-wrap"
                          :error="false"
                          borderless
                          hide-bottom-space
                          dense
                        >
                          <q-btn-toggle
                            v-model="param.operator"
                            class="full-width"
                            toggle-color="primary"
                            color="white"
                            text-color="grey-9"
                            toggle-text-color="white"
                            :options="operatorOptions"
                            :disable="paramComparisonOptions[param.param] || false"
                            no-caps
                            spread
                            unelevated
                          />
                        </q-field>
                        <q-input
                          v-model="param.value as string | number | null"
                          class="col field-with-top-label q-mb-sm"
                          type="number"
                          v-if="paramValueTypesAndOptions[param.param].type === ProductRequiredConditionsParamTypeEnum.NUMBER"
                          outlined
                          hide-bottom-space
                          dense
                        />
                        <q-select
                          v-if="paramValueTypesAndOptions[param.param].type === ProductRequiredConditionsParamTypeEnum.SELECT"
                          v-model="param.value"
                          class="col field-with-top-label q-mb-sm"
                          :multiple="paramValueTypesAndOptions[param.param].multiple"
                          :use-chips="paramValueTypesAndOptions[param.param].multiple"
                          :options="paramValueTypesAndOptions[param.param].options"
                          option-label="label"
                          option-value="value"
                          emit-value
                          map-options
                          outlined
                          hide-bottom-space
                          dense
                        />
                        <q-btn
                          size="md"
                          icon="svguse:icons.svg?2#app-trash"
                          flat
                          round
                          @click="removeParam(index, indexParam)"
                        />
                      </div>
                    </div>
                    <div>
                      <q-btn
                        class="btnBgGray q-mt-sm"
                        label="Добавить параметр"
                        icon-right="svguse:icons.svg?2#app-plus"
                        text-color="blue-gray-5"
                        unelevated
                        no-wrap
                        no-caps
                        @click="addParam(index)"
                      />
                    </div>
                  </q-form>
                </div>
                <div class="col-12 q-py-md text-right">
                  <q-btn
                    class="btnBgGray"
                    style="padding: 12px 23px;"
                    text-color="blue-gray-5"
                    label="Удалить условие"
                    unelevated
                    no-wrap
                    no-caps
                    @click="removeCondition(index)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </q-card-section>
      <q-card-actions
        align="right"
        class="modal-actions text-primary q-pa-lg"
      >
        <q-btn
          v-close-popup
          :loading="loading || putLoading"
          label="Отмена"
          class="q-pr-lg"
          style="padding: 17px 28px;"
          unelevated
          flat
          no-caps
          text-color="black"
        />
        <q-btn
          :loading="loading || putLoading"
          label="Сохранить"
          color="primary"
          style="padding: 17px 28px;"
          unelevated
          icon-right="svguse:icons.svg?2#app-check"
          no-caps
          :disable="!isValid"
          @click="handleSubmit()"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
import {useDialog} from 'src/uses/Dialogs/useDialog'
import {useProductsRequiredControl} from 'src/uses/Products/useProductsRequiredControl'
import {ProductsListItem} from 'src/models/Products/ProductsListItem'
import {ProductRequiredConditionsParamTypeEnum} from 'src/models/Products/ProductRequiredControl'

const props = defineProps<{
  product: ProductsListItem
}>()

const emit = defineEmits<{
  (e: 'updated'): void
}>()

const productsRequiredControl = useProductsRequiredControl()
const {
  loading,
  putLoading,
  putStatus,
  requiredControlData,
  loadRequiredControl,
  save,

  addCondition,
  addParam,
  removeCondition,
  removeParam,
  onChangeParam,
  paramComparisonOptions,
  paramValueTypesAndOptions,
  isValid,
  conditionsCount,
  operatorOptions,
} = productsRequiredControl

const {dialog, show, hide} = useDialog()

const open = async () => {
  show()
  await loadRequiredControl(props.product.id)
}

const handleSubmit = async () => {
  await save(props.product.id)
  emit('updated')
  if (putStatus.value === 200) {
    hide()
  }
}

</script>
<style lang="scss">
.productRequired {
  .q-field.field-with-top-label {
    padding-top: 0!important;

  }
  .btnBgGray {
    background-color: #C7DFF1 !important;
  }
}
</style>
