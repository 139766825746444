import {useProfileStore} from 'src/stores/Login/useProfileStore'
import {useDialog} from 'src/uses/Dialogs/useDialog'
import {ChangeStatusPayload} from 'src/models/Status/ChangeStatusPayload'
import {ApplicationSubStatusEnum} from 'src/models/Applications/ApplicationSubStatusEnum'
import {Ref} from 'vue'

export const useGuaranteeReleased = (payload: Ref<ChangeStatusPayload | undefined>, realChangeStatus: () => Promise<void>) => {
  const {
    dialog: guaranteeReleasedDialog,
    show: showGuaranteeReleasedDialog,
    hide: hideGuaranteeReleasedDialog,
  } = useDialog()

  const {
    isBankEmployee,
  } = useProfileStore()

  const needShowDialog = async () => {
    return isBankEmployee()
      && payload.value
      && ApplicationSubStatusEnum.guarantee_released === payload.value?.applicationStatus
  }

  const onSaveGuaranteeReleased = async () => {
    if (!payload.value) {
      return
    }

    await realChangeStatus()
    hideGuaranteeReleasedDialog()
  }

  return {
    guaranteeReleasedDialog,
    showGuaranteeReleasedDialog,
    hideGuaranteeReleasedDialog,
    needShowDialog,
    onSaveGuaranteeReleased,
  }
}
