// eslint-disable-next-line
// @ts-ignore
import camelcase from 'lodash.camelcase'
// eslint-disable-next-line
// @ts-ignore
import snakecase from 'lodash.snakecase'

export const useCases = () => {
  return {
    camelcase,
    snakecase,
  }
}
