import {defineStore} from 'pinia'
import {usePostApi} from 'src/uses/Api/usePostApi'
import {LoginResponseData} from 'src/models/User/Login/LoginResponseData'
import {useTokenStore} from 'src/stores/Login/useTokenStore'
import {ref} from 'vue'

export const useRefreshTokenStore = defineStore('refreshToken', () => {
  const {
    post,
    response,
    status,
  } = usePostApi<LoginResponseData, { refresh_token: string }>()
  const tokenStore = useTokenStore()

  const refreshInProgress = ref(false)

  const doRefreshToken = async () => {
    if (!tokenStore.tokenData || refreshInProgress.value) {
      return
    }

    refreshInProgress.value = true
    await post(
      'token/refresh',
      {
        refresh_token: tokenStore.tokenData.refresh_token || '',
      })
    refreshInProgress.value = false

    if (status.value !== 200 || !response.value) {
      await tokenStore.logout()

      return
    }

    tokenStore.setToken(response.value)
  }

  return {doRefreshToken}
})
