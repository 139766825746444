import {usePutApi} from 'src/uses/Api/usePutApi'
import {FieldUpdate} from 'src/models/Field/FieldUpdate'
import {ApplicationFormUpdateResponse} from 'src/models/Applications/Form/ApplicationFormUpdateResponse'

export const useUpdateClientCommonField = (multiOrderId?: number, appId?: string,) => {
  const {
    error,
    errors,
    put,
    status,
    response,
    loading,
  } = usePutApi<ApplicationFormUpdateResponse, {section_type: string, fields: FieldUpdate[]}>()

  const update = async (fields: FieldUpdate[]) => {
    if (!multiOrderId && !appId) {
      return
    }

    if (multiOrderId) {
      await put(
        `v2/orders/forms/${multiOrderId}/fields`,
        {
          section_type: 'common',
          fields
        }
      )

      return
    }

    await put(
      `v2/control-center/${appId}/fields`,
      {
        section_type: 'common',
        fields
      }
    )
  }

  return {
    error,
    errors,
    put,
    status,
    response,
    loading,
    update,
  }
}
