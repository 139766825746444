import {defineStore, storeToRefs} from 'pinia'
import {ref, watchEffect} from 'vue'
import {
  useApplicationControlCenterItemStore
} from 'src/stores/Applications/ControlCenter/useApplicationControlCenterItemStore'
import {useImportantFields} from 'src/uses/Applications/Form/useImportantFields'
import {RegistryTypeEnum} from 'src/models/Enums/RegistryTypeEnum'
import {ChangeStatusPayload} from 'src/models/Status/ChangeStatusPayload'

export const useRegistryFormStore = (appId: string) => {
  return defineStore(`application-registry-form-${appId}`, () => {
    const applicationControlCenterItemStore = useApplicationControlCenterItemStore(appId)()
    const {
      item,
      banks,
    } = storeToRefs(applicationControlCenterItemStore)

    const {
      rntField,
      ikzField,
      isPurchaseClosedField,
    } = useImportantFields(item)

    const registryType = ref<RegistryTypeEnum>(RegistryTypeEnum.closed)
    const rnt = ref<string>()
    const ikz = ref<string>()
    const singleSupplier = ref<boolean>(false)
    const success = ref(false)

    const registryTypeForSelect = ref([
      {
        value: RegistryTypeEnum.open,
        label: 'Открытая'
      },
      {
        value: RegistryTypeEnum.closed,
        label: 'Закрытая'
      }
    ])

    const initParams = () => {
      registryType.value = isPurchaseClosedField.value?.value ? RegistryTypeEnum.closed : RegistryTypeEnum.open
    }

    watchEffect(() => {
      if (isPurchaseClosedField.value) {
        initParams()
      }
    })

    const isNoValidateParams = () => {
      return registryType.value === RegistryTypeEnum.closed || !!singleSupplier.value
    }

    const getRequestData = (statusPayload: ChangeStatusPayload) => {
      const data: ChangeStatusPayload = {
        id: statusPayload.id,
        applicationStatus: statusPayload.applicationStatus,
        initiator: 'send_to_production',
        withoutMessage: false,
        comment: '',
        guarantee_registry_type: registryType.value || undefined,
        purchase_number: isNoValidateParams() ? undefined : rnt.value as string,
        purchase_identity_code: isNoValidateParams() ? undefined : ikz.value as string,
        is_purchase_from_single_supplier: singleSupplier.value
      }

      return data
    }

    watchEffect(() => {
      if (!rntField.value
        || parseInt(rntField.value.value as string || '').toString() !== rntField.value.value) {
        return
      }

      rnt.value = (rntField.value.value as string) || ''
    })

    watchEffect(() => {
      if (!ikzField.value) {
        return
      }

      ikz.value = (ikzField.value.value as string) || ''
    })

    return {
      registryType,
      singleSupplier,
      success,
      registryTypeForSelect,
      rntField,
      ikzField,
      isPurchaseClosedField,
      banks,
      rnt,
      ikz,
      isNoValidateParams,
      getRequestData,
    }
  })
}
