<template>
  <q-dialog
    v-model="onReleaseSendDialog"
  >
    <!-- Case Registry Only -->
    <q-card
      v-if="isOnlyRegistry()"
      class="q-mt-md bg-blue-1"
      style="min-width: 400px; max-width: 600px; border-radius: 4px !important;"
    >
      <only-registry-modal-card
        v-if="lastStatusPayload"
        :payload="lastStatusPayload"
        :loading="loading"
        @save="onSaveRegistry"
      />
    </q-card>
    <!-- Case Delivery Only -->
    <q-card
      v-if="isOnlyDelivery()"
      class="q-mt-md bg-blue-1"
      style="min-width: 400px; max-width: 600px; border-radius: 4px !important;"
    >
      <only-delivery-address-modal-card
        v-if="lastStatusPayload"
        :id="lastStatusPayload.id"
        :can-change-issue-bg-form="true"
        :loading="loading"
        @save="onSaveDelivery"
      />
    </q-card>
    <!-- Case Delivery Only -->
    <q-card
      v-if="isRegistryAndDelivery()"
      class="bg-blue-1"
      style="min-width: 400px; max-width: 600px; border-radius: 4px !important;"
    >
      <registry-and-delivery-address-modal-card
        v-if="lastStatusPayload"
        :change-status-payload="lastStatusPayload"
        :loading="loading"
        @save="onSaveRegistry"
      />
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
import {useStatusStore} from 'src/stores/Status/useStatusStore'
import {storeToRefs} from 'pinia'
import OnlyRegistryModalCard from 'src/components/Applications/onReleaseSendModal/OnlyRegistryModalCard.vue'
import OnlyDeliveryAddressModalCard
  from 'src/components/Applications/onReleaseSendModal/OnlyDeliveryAddressModalCard.vue'
import RegistryAndDeliveryAddressModalCard
  from 'src/components/Applications/onReleaseSendModal/RegistryAndDeliveryAddressModalCard.vue'
import {ChangeStatusPayload} from 'src/models/Status/ChangeStatusPayload'

const statusStore = useStatusStore()
const {
  hideOnReleaseSendDialog,
} = statusStore
const {
  onReleaseSendDialog,
  lastStatusPayload,
  loading,
  status,
} = storeToRefs(statusStore)

const {
  isOnlyDelivery,
  isOnlyRegistry,
  isRegistryAndDelivery,
} = statusStore

const onSaveRegistry = async (payload: ChangeStatusPayload) => {
  lastStatusPayload.value = payload
  await statusStore.onChangeReleaseStatus()
  if (status.value === 200) {
    hideOnReleaseSendDialog()
  }
}

const onSaveDelivery = () => {
  statusStore.onChangeReleaseStatus()
}
</script>

<style lang="scss">

</style>
