import {defineStore} from 'pinia'
import {useHeaderData} from 'src/uses/Applications/ControlCenter/useHeaderData'
import {ref} from 'vue'
import {ChatHistoryTabsEnum} from 'src/models/ChatHistoryTabsEnum'
import {useMetaStore} from 'src/stores/useMetaStore'

export const useApplicationControlCenterStore = (appId: string) => defineStore(`application-control-center-${appId}`, () => {
  const {
    loadHeaderData,
    loading: loadingHeaderData,
    headerData,
  } = useHeaderData()

  const chatHistoryTabs = ref<ChatHistoryTabsEnum>(ChatHistoryTabsEnum.chat)

  const init = async () => {
    useMetaStore().setTitle('Сделка')
    await loadHeaderData(appId)
    useMetaStore().setTitle(`Сделка ${headerData.value?.number} - ${headerData.value?.bank.title_short}`)
  }

  return {
    loadingHeaderData,
    headerData,
    chatHistoryTabs,
    init,
  }
})
