<template>
  <q-dialog
    :model-value="props.modelValue"
    persistent
    @update:model-value="dialog => emit('update:modelValue', dialog)"
  >
    <q-card style="max-width: 600px;  width: calc(90vw);">
      <q-card-section class="row items-center q-pb-sm q-pt-lg q-px-lg">
        <div class="text-h6">Выберите причину, по которой данная закупка не размещена в ЕИС:</div>
      </q-card-section>
      <q-card-section class="q-px-lg q-pt-none">
        <div
          v-for="(label, val) of rntNotInEISReasons"
          :key="val"
          style="margin-left: -10px;"
        >
          <q-radio
            v-model="reason"
            :val="val"
            color="blue-7"
            checked-icon="svguse:icons.svg?2#app-radio-checked"
            unchecked-icon="svguse:icons.svg?2#app-radio"
          >
            <span class="text-grey-7">{{ label }}</span>
          </q-radio>
        </div>
        <q-input
          v-model="link"
          class="field-with-top-label q-mt-lg"
          :label="'Ссылка на аукцион' + (reason !== RntNotInEISReasonEnum.commercial_purchase ? ' (необязательно)' : ' (необязательно на этапе подбора банков)')"
          :error="v$.link.$error"
          :error-message="v$.link.$errors[0] ? v$.link.$errors[0].$message as string : ''"
          outlined
          hide-bottom-space
          dense
          emit-value
          map-options
          options-dense
          hide-dropdown-icon
          clearable
          @blur="v$.$validate()"
        />
      </q-card-section>
      <q-card-actions class="row justify-end q-pa-lg bg-grey-2">
        <q-btn
          size="16px"
          padding="sm lg"
          label="Отмена"
          unelevated
          no-caps
          @click="abort"
        />
        <q-btn
          :disable="!reason || v$.$invalid"
          padding="sm lg"
          size="16px"
          color="blue-7"
          label="Сохранить"
          unelevated
          no-caps
          @click="save"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
import {useRntNotInEISReason} from 'src/uses/Applications/useRntNotInEISReason'
import {computed, ref, watch, withDefaults} from 'vue'
import {RntNotInEISReasonEnum} from 'src/models/Enums/RntNotInEISReasonEnum'
import {urlHasProtocolValidator, urlWithoutCyrillicValidator} from 'src/utils/i18n-validators'
import useVuelidate from '@vuelidate/core'

const emit = defineEmits<{
  (e: 'update:modelValue', modelValue: boolean): void,
  (e: 'update:rntNotInEisReason', rntNotInEISReason: RntNotInEISReasonEnum | null): void,
  (e: 'update:rntLink', rntLink: string | null): void,
  (e: 'abort'): void,
}>()

const props = withDefaults(
  defineProps<{
    modelValue?: boolean,
    rntNotInEisReason: RntNotInEISReasonEnum | null,
    rntLink: string | null,
  }>(),
  {
    modelValue: false,
  }
)

const {rntNotInEISReasons} = useRntNotInEISReason()

const reason = ref<RntNotInEISReasonEnum | null>(null)
const link = ref<string | null>('')

const rules = computed(() => {
  if (link.value) {
    return {
      link: {urlWithoutCyrillicValidator, urlHasProtocolValidator}
    }
  }

  return {
    link: {}
  }
})

const v$ = useVuelidate(rules, {link})

const abort = () => {
  emit('abort')
  emit('update:modelValue', false)
}

const save = () => {
  emit('update:modelValue', false)
  emit('update:rntNotInEisReason', reason.value)
  emit('update:rntLink', link.value)
}

watch(
  props,
  () => {
    if (props.rntNotInEisReason !== reason.value) {
      reason.value = props.rntNotInEisReason
    }
    if (props.rntLink !== link.value) {
      link.value = props.rntLink
    }
  }
)
</script>
