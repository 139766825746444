<template>
  <q-btn
    size="sm"
    icon="svguse:icons.svg?2#app-trash"
    color="blue-grey-4"
    round
    flat
    @click="open"
  />
  <q-dialog
    v-model="dialog"
  >
    <q-card class="modal-blue-background">
      <q-card-section>
        <div class="text-h6 q-mt-xs">Удаление справочника</div>
      </q-card-section>
      <q-card-section>
        <info-banner
          text="Вы уверены?"
          caption="Удаленный справочник невозможно будет восстановить."
          icon="svguse:icons.svg?2#app-exclamation"
          color="red"
        />
      </q-card-section>
      <q-card-section>
        <div class="row">
          <div class="col-12 text-caption q-mb-sm">Справочник</div>
          <div
            class="col-12 bordered q-pa-md"
            style="background: #FFFFFF; border-radius: 6px"
          >
            {{ props.title }}
          </div>
        </div>
      </q-card-section>
      <q-card-actions
        align="right"
        class="text-red q-mb-sm"
      >
        <q-btn
          v-close-popup
          :loading="loading"
          label="Отмена"
          class="q-pr-lg"
          style="padding: 13px 25px;"
          unelevated
          flat
          no-caps
          text-color="black"
        />
        <q-btn
          :loading="loading"
          label="Удалить"
          color="red"
          style="padding: 13px 25px;"
          unelevated
          icon-right="svguse:icons.svg?2#app-trash"
          no-caps
          @click="handleSubmit()"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
import {computed} from 'vue'
import {useDialog} from 'src/uses/Dialogs/useDialog'
import {storeToRefs} from 'pinia'
import {useListingDeleteStore} from 'stores/Listings/useListingDeleteStore'
import InfoBanner from 'components/InfoBanner.vue'

const emit = defineEmits<{
  (e: 'change'): void,
}>()

const props = defineProps<{
  itemId: string
  title: string
}>()
const itemId = computed(() => props.itemId)
const {dialog, show, hide} = useDialog()

const listingDeleteStore = useListingDeleteStore()

const {
  loading,
  status,
} = storeToRefs(listingDeleteStore)

const {
  deleteListings
} = listingDeleteStore

const open = async () => {
  show()
}

const handleSubmit = async () => {
  await deleteListings(itemId.value)
  if (status.value === 200) {
    hide()
    emit('change')
  }
}

</script>
