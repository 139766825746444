import {RouterGuard} from 'src/models/Router/RouterGuard'
import {useAuthStore} from 'src/stores/Login/useAuthStore'

export const LoginGuard: () => RouterGuard = () => ({
  canAccess: () => {
    const authStore = useAuthStore()

    return !authStore.isAuthed
  },
  redirectTo: () => {
    const authStore = useAuthStore()
    if (!authStore.isAuthed) {
      return undefined
    }

    return {
      name: 'applications-grid',
    }
  }
})
