import {
  ApplicationFormCreateFounderResponse,
} from 'src/models/Applications/Form/ApplicationFormUpdateResponse'
import {usePostApi} from 'src/uses/Api/usePostApi'
import {ApplicationFormFounder} from 'src/models/Applications/Form/ApplicationFormResponseData'

export const useAddFounder = () => {
  const {
    error,
    errors,
    post,
    status,
    response,
    loading,
  } = usePostApi<ApplicationFormCreateFounderResponse, {id: string}>()

  const addFounder = async (appId: string): Promise<ApplicationFormFounder | undefined> => {
    await post(
      `v2/control-center/${appId}/founders`,
      {
        id: appId
      }
    )

    if (status.value !== 201 || !response.value || !response.value?.changes.fields) {
      return undefined
    }

    return {
      id: response.value.founder_id,
      fields: response.value.changes.fields || [],
    }
  }

  return {
    addFounder,
    error,
    errors,
    status,
    response,
    loading,
  }
}
