<template>
  <q-dialog
    v-model="statusStore.clientRejectCommentDialog"
  >
    <q-card
      class="q-mt-md bg-blue-1"
      style="min-width: 400px; max-width: 600px; border-radius: 4px !important;"
    >
      <q-card-section class="text-h6 rounded-borders">
        Отказ клиента
      </q-card-section>
      <q-card-section class="bg-red-12 text-white row items-center q-py-lg">
        <div class="rounded-borders q-pa-sm bg-white">
          <q-icon
            name="svguse:icons.svg?2#app-exclamation"
            size="sm"
            color="red-12"
          />
        </div>
        <div class="col q-pl-sm text-wgite">
          Вы не сможете продолжить работу с заявкой после этого действия
        </div>
      </q-card-section>
      <q-card-section class="white-bg-field">
        <q-select
          v-model="reason"
          class="field-with-top-label"
          :options="options"
          label="Причина"
          outlined
          hide-bottom-space
          dense
          options-dense
        />
        <q-input
          v-model="additionalComment"
          class="field-with-top-label q-mt-md"
          label="Коментарий (необязательно)"
          maxlength="200"
          counter
          outlined
          hide-bottom-space
          dense
        />
      </q-card-section>
      <q-card-actions
        class="row justify-end q-py-md"
        style="background: #D5EAFA;"
      >
        <q-btn
          v-close-popup
          size="16px"
          padding="sm lg"
          label="Отмена"
          unelevated
          no-caps
        />
        <q-btn
          padding="sm lg"
          size="16px"
          color="blue-7"
          label="Применить"
          :disable="!reason"
          :loading="loading"
          unelevated
          no-caps
          @click="statusStore.clientRejectWithComment(comment)"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
import {computed, ref, watchEffect} from 'vue'
import {storeToRefs} from 'pinia'
import {useStatusKikStore} from 'stores/Status/useStatusKikStore'

const statusStore = useStatusKikStore()
const {loading} = storeToRefs(statusStore)

const reason = ref<string>()
const additionalComment = ref<string>()
const options = [
  'Взяли в другом банке',
  'Получили аванс',
  'Отказались от контракта',
  'Директор взял деньги на себя/испортил кредитную историю',
  'Перекредитовались в предыдущем банке',

]

const comment = computed(() => {
  return [
    reason.value,
    ...(additionalComment.value ? [additionalComment.value] : []),
  ].join(' ')
})

watchEffect(
  () => {
    if (!statusStore.clientRejectCommentDialog) {
      reason.value = undefined
      additionalComment.value = undefined
    }
  }
)
</script>
