<template>
  <q-btn
    dense
    unelevated
    :disable="props.loading"
    @click="props.signed ? false : emit('document:download')"
  >
    <q-icon
      name="svguse:icons.svg?2#app-download"
      color="blue-grey-4"
    >
      <q-popup-proxy v-if="props.signed">
        <q-list
          class="bg-white"
          bordered
          separator
        >
          <q-item
            v-ripple
            v-close-popup
            clickable
            class="text-no-wrap"
            @click="emit('document:download')"
          >
            <q-item-section>скачать</q-item-section>
          </q-item>
          <q-item
            v-ripple
            v-close-popup
            clickable
            class="text-no-wrap"
            @click="emit('document:download-sign')"
          >
            <q-item-section>скачать ЭЦП</q-item-section>
          </q-item>
        </q-list>
      </q-popup-proxy>
    </q-icon>
  </q-btn>
</template>

<script setup lang="ts">
import {withDefaults} from 'vue'

const props = withDefaults(
  defineProps<{
    signed?: boolean,
    loading?: boolean,
  }>(),
  {
    signed: false,
    loading: false,
  }
)

const emit = defineEmits<{
  (e: 'document:download'): void,
  (e: 'document:download-sign'): void,
}>()
</script>

<style scoped lang="scss">

</style>
