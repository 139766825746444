<template>
  <q-btn
    v-if="hasError(props.valid)"
    style="margin-top: -3px; z-index: 2;position: relative;pointer-events: auto;"
    dense
    unelevated
    tabindex="-1"
    @mousedown.capture.prevent.stop
    @click.capture.prevent.stop="proxy = true"
  >
    <q-icon
      name="svguse:icons.svg?2#app-library"
      color="red-7"
      size="xs"
    />
    <q-popup-proxy v-model="proxy">
      <q-card
        style="border-radius: 6px !important;"
        dark
      >
        <q-card-section>
          <div class="q-gutter-sm">
            <template
              v-for="bank in banks"
              :key="bank.bank_id"
            >
              <div
                v-if="hasError(props.valid, bank.bank_id)"
                class="rounded-borders"
              >
                <div class="row items-center no-wrap">
                  <img
                    :alt="bank.title"
                    :src="bank.url"
                    class="q-mr-md"
                    style="height: 50px;"
                  >
                  <div>
                    <div
                      v-for="(error, index) in getErrors(props.valid, bank.bank_id)"
                      :key="index"
                    >
                      {{ error }}
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </q-card-section>
      </q-card>
    </q-popup-proxy>
  </q-btn>
</template>

<script setup lang="ts">
import {ref} from 'vue'
import {
  ApplicationFormBank,
  ApplicationFormFieldValid
} from 'src/models/Applications/Form/ApplicationFormResponseData'
import {useValid} from 'src/uses/Applications/Form/useValid'

const props = defineProps<{
  valid: ApplicationFormFieldValid[],
  banks: ApplicationFormBank[],
}>()

const proxy = ref(false)

const {
  hasError,
  getErrors,
} = useValid()
</script>
