<template>
  <form-select
    :model-value="props.field.value"
    :options="options"
    :label="props.label || props.field.title"
    :hint="props.ignoreHint ? undefined : (props.field.help || undefined)"
    :disable="props.field.readonly && !props.ignoreReadonly"
    :error="props.field.error"
    :loading="props.field.loading"
    :use-chips="false"
    with-top-label
    @update:model-value="onUpdate($event)"
  >
    <template #labelPrepend>
      <slot name="labelPrepend" />
    </template>
    <template #labelAppend>
      <slot name="labelAppend" />
    </template>
  </form-select>
</template>

<script setup lang="ts">
import {computed} from 'vue'
import {
  ApplicationFormField,
} from 'src/models/Applications/Form/ApplicationFormResponseData'
import {FormElementValue} from 'src/models/Form'
import FormSelect from 'src/components/FormElements/FormSelect.vue'

const props = defineProps<{
  field: ApplicationFormField,
  label: string | boolean,
  ignoreReadonly: boolean,
  ignoreHint: boolean,
}>()

const emit = defineEmits<{
  (e: 'update:value', value: FormElementValue): void,
}>()

const options = computed(() => {
  if (!props.field.choices) {
    return []
  }

  const options = []
  for (const value in props.field.choices) {
    options.push({
      label: props.field.choices[value],
      value,
    })
  }

  return options
})

const onUpdate = (value: FormElementValue) => {
  emit('update:value', value)
}
</script>
