<template>
  <span v-if="val">
    {{ val.format(props.format) }}
  </span>
</template>

<script setup lang="ts">
import {withDefaults, ref, watchEffect} from 'vue'
import moment, {Moment} from 'moment'

type propModel = string | Moment

const props = withDefaults(
  defineProps<{
    modelValue: propModel,
    parseFormat?: string,
    format?: string,
  }>(),
  {
    parseFormat: 'YYYY-MM-DD HH:mm:ss',
    format: 'DD.MM.YY',
  }
)

const val = ref<Moment | undefined>()

watchEffect(() => {
  //console.log(props.modelValue, props.parseFormat, moment(props.modelValue, props.parseFormat))
  val.value = moment(props.modelValue, props.parseFormat)
})
</script>
