import {helpers} from '@vuelidate/validators'

export const validateNumbers = (value: string) => /[0-9]/g.test(value)
export const validateUpperCase = (value: string) => /[A-Z]/g.test(value)
export const validateLowerCase = (value: string) => /[a-z]/g.test(value)
export const validateSymbols = (value: string) => /[-\\.^+.,!?:;*@{}/[\]()#$%><_/)]/g.test(value)
export const validateMinLength = (value: string) => value.length >= 8
export const validateMaxLength = (value: string) => value.length <= 14

export const passwordValidator = helpers.withMessage(
  'Invalid password',
  (value: string) => {
    return validateNumbers(value)
      && validateUpperCase(value)
      && validateLowerCase(value)
      && validateSymbols(value)
      && validateMinLength(value)
      && validateMaxLength(value)
  })
