import {App, URLOpenListenerEvent} from '@capacitor/app'
import {useRouter} from 'vue-router'

export const useAppUrlOpen = () => {
  const router = useRouter()
  const addListeners = async () => {
    await App.removeAllListeners()
    App.addListener(
      'appUrlOpen',
      (event: URLOpenListenerEvent) => {
        const slug = event.url
          .replace(
            'https://lk.gosoblako.com/',
            ''
          )

        if (!slug) {
          return
        }

        router.push({
          path: '/' + slug.replace('#/', ''),
        })
      })
  }

  return {
    addListeners,
  }
}
