<template>
  <q-btn
    color="primary"
    icon-right="svguse:icons.svg?2#app-view-grid"
    text-color="blue-grey-4"
    unelevated
    no-caps
    flat
    @click="open"
  />
  <q-dialog
    v-model="dialog"
  >
    <q-card
      class="modal-blue-background"
      style="min-width: 500px;"
    >
      <q-card-section
        class="modal-title row items-center q-pb-none q-pl-lg"
      >
        Ранжирование порядка вывода банков
      </q-card-section>
      <q-card-section>
        <info-banner
          text="Измените порядок выведения банков используя стрелки"
          icon="svguse:icons.svg?2#app-exclamation-circle"
        />
      </q-card-section>
      <q-card-section class="q-pl-lg q-pr-lg">
        <div>
          <div
            v-for="(bank, index) in sortedBanks()"
            :key="bank.id + bank.sort"
            class="row items-center justify-center q-px-md"
            style="min-height: 50px; border-bottom: 1px solid #C7DFF1;"
          >
            <div>
              <q-btn
                :disable="index === 0"
                color="blue-grey-5"
                class="order-button"
                icon="svguse:icons.svg?2#app-chevron-up"
                size="12px"
                padding="6px"
                outline
                flat
                square
                @click="moveUp(bank)"
              />
            </div>
            <div
              class="col q-pa-xs"
              style="min-width: 300px;"
            >
              <img
                :src="bank.logo as string"
                :alt="bank.title_short"
                style="max-width: 50px; max-height: 30px;"
              >
              {{ bank.title_short }}
            </div>
            <div>
              <q-btn
                :disable="index === banks.length - 1"
                color="blue-grey-5"
                class="order-button"
                icon="svguse:icons.svg?2#app-chevron-down"
                size="12px"
                padding="6px"
                outline
                flat
                square
                @click="moveDown(bank)"
              />
            </div>
          </div>
        </div>
      </q-card-section>
      <q-card-actions
        align="right"
        class="modal-actions text-primary q-pa-lg"
      >
        <q-btn
          v-close-popup
          :loading="loading"
          label="Отмена"
          class="q-pr-lg"
          style="padding: 17px 28px;"
          unelevated
          flat
          no-caps
          text-color="black"
        />
        <q-btn
          :loading="loading"
          label="Сохранить"
          color="primary"
          style="padding: 17px 28px;"
          unelevated
          icon-right="svguse:icons.svg?2#app-check"
          no-caps
          @click="handleSubmit()"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
import {computed} from 'vue'
import {useDialog} from 'src/uses/Dialogs/useDialog'
import {BankItem} from 'src/models/Banks/BankItem'
import InfoBanner from 'components/InfoBanner.vue'
import {useBankRange} from 'src/uses/Bank/useBankRange'

const props = defineProps<{
  banks: BankItem[],
}>()

const emit = defineEmits<{
  (e: 'success'): void,
}>()

const banks = computed(() => props.banks)

const bankRange = useBankRange()
const {
  loading,
  status,
  range,
} = bankRange

const {dialog, show, hide} = useDialog()

const open = async () => {
  show()
}

const success = () => {
  emit('success')
  hide()
}

const handleSubmit = async () => {
  await range(banks.value)

  if (status.value === 200) {
    success()
  }
}

const sortedBanks = () => {
  return banks.value
    .sort((a, b) => a.sort - b.sort)
}

const moveUp = (bank: BankItem) => {
  const index = banks.value.findIndex(c => c.id === bank.id)
  if (index === -1) {
    return
  }

  const sort = banks.value[index].sort
  banks.value[index].sort = banks.value[index - 1].sort
  banks.value[index - 1].sort = sort
}

const moveDown = (bank: BankItem) => {
  const index = banks.value.findIndex(b => b.id === bank.id)
  if (index === -1) {
    return
  }

  const sort = banks.value[index].sort
  banks.value[index].sort = banks.value[index + 1].sort
  banks.value[index + 1].sort = sort
}
</script>
