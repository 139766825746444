import {useRequestApi} from './useRequestApi'
import {PostReturnInterface} from 'src/models/Api/PostReturnInterface'
import {ResponseType} from 'axios'

export const usePostApi: <T = never, R = never>(showError?: boolean, responseType?: ResponseType) => PostReturnInterface<R, T> = <T, R>(showErrors = false, responseType: ResponseType = 'json') => {
  const {
    response,
    error,
    errors,
    status,
    loading,
    request,
    abort,
    setHeader,
    headers,
    progressEvent,
  } = useRequestApi<T>(showErrors, responseType)

  const post = async (url: string, data?: R) => {
    await request<R>(url, 'POST', data)
  }

  return {
    response,
    error,
    errors,
    status,
    loading,
    progressEvent,
    abort,
    setHeader,
    headers,
    post,
  }
}
