import {computed, onBeforeMount, ref, watch} from 'vue'
import {GridColumnEditableSettings, GridColumnSettings} from 'src/models/Grid/GridColumnSettings'
import {useLocalStorage} from 'src/uses/useLocalStorage'
import {useProfileStore} from 'src/stores/Login/useProfileStore'
import {storeToRefs} from 'pinia'

export const useGridSettings = (hash: string, defaultValues: GridColumnEditableSettings[] = []) => {
  const profileStore = useProfileStore()
  const {profile} = storeToRefs(profileStore)

  const {
    getFromStorage,
    setToStorage,
    data,
  } = useLocalStorage<{ name: string, visible: boolean, order: number }[]>(`grid-columns-settings-${hash}`)

  const defaultColumns = computed(() => {
    return defaultValues
      .filter(val => {
        return !val.roles
          || !val.roles.length
          || val.roles.includes((profile.value?.role as string) || '')
      })
  })

  const getColumns = (): GridColumnEditableSettings[] => {
    getFromStorage()
    const fromStorage = data.value || []

    if (!fromStorage) {
      return defaultColumns.value
    }

    if (fromStorage.length === 0) {
      return defaultColumns.value
    }

    return defaultColumns.value
      .map(column => {
        const storageVal = fromStorage.find(val => val.name === column.name)

        return {
          ...column,
          visible: column.editable && typeof storageVal?.visible === 'boolean'
            ? storageVal?.visible
            : column.visible,
          order: typeof storageVal?.order === 'number'
            ? storageVal?.order
            : column.order,
        }
      })
  }
  const columns = ref<GridColumnEditableSettings[]>([])

  onBeforeMount(() => {
    columns.value = getColumns()
  })

  const visibleColumns = computed(() => {
    if (!columns.value) {
      return
    }

    return columns.value
      .filter(column => column.visible && isVisibleByRole(column))
      .sort((a, b) => a.order - b.order)
  })

  const isVisibleByRole = (col?: GridColumnSettings): boolean => {
    if (col === undefined) {
      return false
    }

    if (profile.value && !!col.roles) {
      return col.roles.includes(profile.value.role as never)
    }
    if (col.roles) {
      return true
    }

    return true
  }

  watch<GridColumnSettings[] | undefined>(
    columns,
    () => {
      if (!columns.value) {
        return
      }

      setColumns(columns.value)
    },
    {
      deep: true,
    }
  )

  const setColumns = (columns: GridColumnEditableSettings[]) => {
    const dataToSave = columns
      //.filter(c => c.editable)
      .map(c => ({
        name: c.name,
        visible: c.visible,
        order: c.order,
      }))
    setToStorage(dataToSave)
  }

  return {columns, visibleColumns, isVisibleByRole}
}
