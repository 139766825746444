<template>
  <q-list
    class="leftMenu menuList"
    @click.stop.prevent
  >
    <template
      v-for="item in menu"
      :key="item.to.name"
    >
      <template v-if="isShow(item)">
        <menu-item
          v-if="!item.children"
          :item="item"
          :mini="props.mini"
        />
        <menu-expansion-item
          v-else
          :item="item"
          :mini="props.mini"
        />
      </template>
    </template>
  </q-list>
  <q-space />
  <q-list
    v-if="isAuthed"
    class="leftMenu menuList exit"
    @click.stop.prevent
  >
    <q-item
      v-ripple
      clickable
      @click="logout"
    >
      <q-item-section avatar>
        <q-icon name="svguse:icons.svg?2#app-logout" />
        <q-tooltip
          v-if="props.mini"
          self="center left"
          anchor="center right"
          :delay="100"
          :offset="[20, 0]"
        >
          Выход
        </q-tooltip>
      </q-item-section>
      <q-item-section>
        Выход
      </q-item-section>
    </q-item>
  </q-list>
</template>

<script setup lang="ts">
import {storeToRefs} from 'pinia'
import {useMenuStore} from 'stores/useMenuStore'
import {useAuthStore} from 'stores/Login/useAuthStore'
import MenuItem from 'components/Menu/MenuItem.vue'
import MenuExpansionItem from 'components/Menu/MenuExpansionItem.vue'

const props = defineProps<{
  mini: boolean,
}>()

const menuStore = useMenuStore()
const {
  isShow,
} = menuStore
const {
  menu,
} = storeToRefs(menuStore)

const authStore = useAuthStore()
const {
  logout,
} = authStore
const {
  isAuthed,
} = storeToRefs(authStore)

</script>

<style lang="scss">
.menuList {
  &.ignoreLastChild {
    > .q-item {
      &:last-child {
        margin-top: 0;
        border-top: 0;
      }
    }
  }
  > .q-item {
    &:last-child {
      margin-top: 30px;
      border-top: 1px solid $blue-grey-9;
    }

    &:first-child {
      margin-top: 0 !important;
      border-top: none !important;
    }
  }

  .q-item {
    color: $blue-grey-1;
    cursor: pointer;

    .q-icon {
      color: $blue-grey-4;
    }
  }

  .q-item--active {
    background: $blue-grey-9;
    .q-icon {
      color: $blue-grey-1;
    }
  }

  .q-item__section--avatar {
    min-width: 0;
  }

  .q-expansion-item--expanded {
    background: $blue-grey-9;
    & > .q-expansion-item__container > .q-item {
      .q-icon {
        color: $blue-grey-1;
      }
    }
  }

  .q-expansion-item__content {
    .q-item {
      padding-left: 30px;

      &.q-item--active {
        background: $blue-grey-8;
      }

      .q-icon {
        font-size: 20px;
      }
    }

    .q-expansion-item__content {
      .q-item {
        padding-left: 40px;
      }
    }
  }
}

.leftMenu {
  margin-bottom: 65px;

  &.exit {
    position: absolute;
    bottom: 0;
    width: 100%;
    margin-bottom: 0;
  }
}
</style>
