<template>
  <div class="ApplicationGridUsers">
    <div
      v-if="!props.hidePersonalData || isOperator()"
      class="text-no-wrap"
    >
      <span>Куратор:</span>
      <template v-if="props.agentManager">
        {{ props.agentManager.fio }}
      </template>
    </div>
    <div
      v-if="!props.hidePersonalData || isOperator()"
      class="text-no-wrap"
    >
      <span>Оператор:</span>
      <template v-if="props.operator">
        {{ props.operator.fio }}
      </template>
    </div>
    <div class="text-no-wrap">
      <span>Агент:</span>
      <template v-if="props.agent">
        ({{ props.agent.code }})
        <template v-if="!props.hidePersonalData">
          {{ props.agent.title }}
        </template>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  ApplicationsGridItemAgent,
  ApplicationsGridItemAgentManager
} from 'src/models/Applications/ApplicationsGridItem'
import {useProfileStore} from 'stores/Login/useProfileStore'

const props = defineProps<{
  agentManager?: ApplicationsGridItemAgentManager
  operator?: ApplicationsGridItemAgentManager
  agent?: ApplicationsGridItemAgent
  hidePersonalData?: boolean
}>()

const {
  isOperator,
} = useProfileStore()
</script>

<style lang="scss">
.ApplicationGridUsers {
  max-width: 290px;
  position: relative;

  & > div.text-no-wrap {
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
</style>
