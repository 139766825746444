<template>
  <q-btn
    size="sm"
    icon="svguse:icons.svg?2#app-cog"
    color="blue-grey-4"
    round
    flat
    @click="open"
  />
  <q-dialog
    v-model="dialog"
  >
    <q-card
      class="modal-blue-background product"
      style="width: 100%; max-width: 1000px"
    >
      <q-card-section
        class="modal-title row items-center q-pb-none q-pl-lg bg-white"
      >
        <div>Редактировать стоп-факторы</div>
      </q-card-section>
      <q-card-section class="q-pl-lg q-pr-lg bg-white">
        <q-form>
          <div v-if="product" class="row q-col-gutter-sm white-bg-field">
            <div class="col-3 q-mb-sm">
              <q-input
                v-model="product.title"
                :loading="loading"
                class="field-with-border"
                label="Название продукта"
                outlined
                hide-hint
                hide-bottom-space
                dense
                disable
              />
            </div>
            <div
              v-if="bank"
              class="col-3 q-mb-sm"
            >
              <q-input
                v-model="bank.title_short"
                :loading="banksLoading"
                class="field-with-border"
                icon
                outlined
                hide-hint
                hide-bottom-space
                dense
                disable
              >
                <template #append>
                  <q-img :src="bank.logo as string" />
                </template>
              </q-input>
            </div>
            <div class="col-3 q-mb-sm">
              <q-toggle
                v-model="product.integration_enabled"
                label="Интеграция"
                style="margin-left: 10px; margin-top: 5px"
                color="blue-7"
              />
            </div>
            <div class="col-3">
              <q-input
                v-model="product.speed"
                :loading="loading"
                label="Скорость решения"
                class="bg-grey-field"
                outlined
                hide-hint
                hide-bottom-space
                dense
              />
            </div>
            <div>
              <form-two-value-toggle
                v-model="product.attached_sign_required"
                :first-value="{label: 'Присоединенная ЭП', value: true}"
                :second-value="{label: 'Открепленная ЭП', value: false}"
              />
            </div>
            <div>
              <q-toggle
                v-model="product.inner_signing_enabled"
                label="Подписание на платформе"
                style="margin-left: 10px;"
                color="blue-7"
                :disable="product.integration_enabled"
              />
            </div>
            <div>
              <q-toggle
                v-model="product.manual_generation_enabled"
                label="Ручная генерация"
                style="margin-left: 10px;"
                color="blue-7"
                :disable="product.integration_enabled"
              />
            </div>
          </div>
        </q-form>
      </q-card-section>
      <q-card-section class="q-pa-none">
        <div
          v-if="product"
          class="row items-center q-pa-lg q-pt-none "
        >
          <div class="col text-bold">
            Стоп-факторы: {{ stopFactorsCount  }}
          </div>
          <q-btn
            label="Добавить стоп-фактор"
            icon-right="svguse:icons.svg?2#app-plus"
            color="blue-7"
            style="padding: 12px 23px;"
            unelevated
            no-wrap
            no-caps
            @click="addStopFactor()"
          />
        </div>
        <div
          v-if="product && product.stop_factors.length"
          class="row"
        >
          <div class="col-12 q-pa-lg q-pt-none">
            <div
              v-for="(stopFactor, index) in product.stop_factors"
              :key="index"
            >
              <div class="row q-mb-md q-pa-md"
                style="border-radius: 6px; border: 1px dotted #adacac;"
              >
                <div class="col-12 q-mb-lg">
                  <div class="text-bold q-mb-xs">Стоп-фактор #{{ index + 1 }}</div>
                  <q-form v-if="stopFactor?.comparison">
                    <div
                      v-for="(param , indexParam) in stopFactor.comparison.parts"
                      :key="`param_${index}_${indexParam}`"
                      class="q-py-xs paramRow"
                    >
                      <div class="text-blue-grey-4 q-mb-sm">Параметр</div>
                      <div class="row q-gutter-sm white-bg-field">
                        <q-select
                          v-model="param.param"
                          :options="stopFactorsParamsOptions"
                          class="col field-with-top-label q-mb-sm"
                          emit-value
                          map-options
                          outlined
                          hide-bottom-space
                          dense
                          @update:model-value="onChangeParam(index, indexParam, null)"
                        />
                        <q-field
                          :model-value="param.operator"
                          class="field-with-top-label q-pt-none no-bg q-mb-sm text-no-wrap"
                          :error="false"
                          borderless
                          hide-bottom-space
                          dense
                        >
                          <q-btn-toggle
                            v-model="param.operator"
                            class="full-width"
                            toggle-color="primary"
                            color="white"
                            text-color="grey-9"
                            toggle-text-color="white"
                            :options="operatorOptions"
                            :disable="paramComparisonOptions[param.param as string] || false"
                            no-caps
                            spread
                            unelevated
                          />
                        </q-field>
                        <q-input
                          v-model="param.value as string | number | null | undefined"
                          class="col field-with-top-label q-mb-sm"
                          type="number"
                          v-if="paramValueTypesAndOptions[param.param as string].type !== ProductStopFactorParamsItemTypeEnum.SELECT"
                          outlined
                          hide-bottom-space
                          dense
                        />
                        <form-select
                          v-if="paramValueTypesAndOptions[param.param as string].type === ProductStopFactorParamsItemTypeEnum.SELECT"
                          class="col field-with-top-label q-mb-sm"
                          :model-value="param.value"
                          :multiple="paramValueTypesAndOptions[param.param as string].multiple"
                          :use-chips="paramValueTypesAndOptions[param.param as string].multiple"
                          :options="paramValueTypesAndOptions[param.param as string].options"
                          label=""
                          top-actions
                          @update:model-value="onChangeParam(index, indexParam, $event)"
                        />
                        <div class="q-pt-xs" style="width: 90px">
                          <span v-if="indexParam === 0" class="q-ml-sm">
                            <q-radio class="resultRadio" keep-color v-model="stopFactor.result" :val="10" label="" color="green" />
                            <q-radio class="resultRadio" keep-color v-model="stopFactor.result" :val="20" label="" color="orange" />
                            <q-radio class="resultRadio" keep-color v-model="stopFactor.result" :val="30" label="" color="red" />
                          </span>
                        </div>
                        <div style="width: 40px; padding-top: 9px">
                          <q-btn
                            class="removeParamBtn"
                            size="sm"
                            icon="svguse:icons.svg?2#app-trash"
                            flat
                            round
                            @click="removeParam(index, indexParam)"
                          />
                        </div>
                      </div>
                    </div>
                    <div>
                      <q-btn
                        class="btnBgGray q-mt-sm"
                        style="height: 30px"
                        label="Добавить параметр"
                        icon-right="svguse:icons.svg?2#app-plus"
                        text-color="blue-gray-5"
                        unelevated
                        no-wrap
                        no-caps
                        @click="addParam(index)"
                      />
                    </div>
                  </q-form>
                </div>
                <div class="col-12 q-py-md q-mt-lg">
                  <div class="row q-gutter-sm white-bg-field">
                    <q-input
                      v-model="stopFactor.comment"
                      class="col field-with-top-label q-mb-sm"
                      label="Комментарий (необязательно)"
                      type="text"
                      outlined
                      hide-bottom-space
                      dense
                    />
                    <q-btn
                      class="btnBgGray"
                      style="height: 50px"
                      size="md"
                      text-color="blue-gray-5"
                      label="Удалить стоп-фактор"
                      unelevated
                      no-wrap
                      no-caps
                      @click="removeStopFactor(index)"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </q-card-section>
      <q-card-actions
        align="right"
        class="modal-actions text-primary q-pa-lg"
      >
        <q-btn
          v-close-popup
          :loading="loading || putLoading"
          label="Отмена"
          class="q-pr-lg"
          style="padding: 17px 28px;"
          unelevated
          flat
          no-caps
          text-color="black"
        />
        <q-btn
          :loading="loading || putLoading"
          label="Сохранить"
          color="primary"
          style="padding: 17px 28px;"
          unelevated
          icon-right="svguse:icons.svg?2#app-check"
          no-caps
          :disable="!isStopFactorsValid"
          @click="handleSubmit()"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
import {useDialog} from 'src/uses/Dialogs/useDialog'
import {ProductsListItem} from 'src/models/Products/ProductsListItem'
import {useProductStopFactors} from 'src/uses/Products/useProductStopFactors'
import {ProductStopFactorParamsItemTypeEnum} from 'src/models/Products/StopFactorParamsItem'
import FormSelect from 'components/FormElements/FormSelect.vue'
import {useBankFilterStore} from 'stores/Banks/useBankFilterStore'
import {storeToRefs} from 'pinia'
import {computed, onBeforeMount} from 'vue'
import FormTwoValueToggle from 'components/FormElements/FormTwoValueToggle.vue'

const props = defineProps<{
  product: ProductsListItem
}>()

const emit = defineEmits<{
  (e: 'updated'): void
}>()

const productStopFactors = useProductStopFactors()
const {
  loading,
  putLoading,
  putStatus,
  product,
  loadProduct,
  save,

  isStopFactorsValid,
  stopFactorsCount,
  stopFactorsParamsOptions,
  paramComparisonOptions,
  paramValueTypesAndOptions,
  operatorOptions,
  loadProductStopFactorsParams,
  onChangeParam,
  addStopFactor,
  removeStopFactor,
  addParam,
  removeParam,
} = productStopFactors

const {dialog, show, hide} = useDialog()

const banksStore = useBankFilterStore()
const {load} = banksStore
const {loading: banksLoading, banks} = storeToRefs(banksStore)

const bank = computed(() => banks.value.find(b => b.id === product.value?.bank_id))

const open = async () => {
  show()
  await loadProductStopFactorsParams()
  await loadProduct(props.product.id)
}

const handleSubmit = async () => {
  await save(props.product.id)
  emit('updated')
  if (putStatus.value === 200) {
    hide()
  }
}

onBeforeMount(async () => {
  if (banks.value.length === 0) {
    await load()
  }
})

</script>
<style lang="scss">
.product {
  .MultipleSelect {
    overflow: hidden;
  }
  .field-with-border {
    border-radius: 6px;
    border: 1px solid $grey-3;
  }
  .bg-grey-field .q-field__control{
    background-color: $blue-grey-1!important;
    border-radius: 6px;
  }
  .resultRadio {
    padding: 0;
    margin-right: -7px;
    margin-left: -7px;
  }
  .q-field.field-with-top-label {
    padding-top: 0!important;

  }
  .btnBgGray {
    background-color: #C7DFF1 !important;
  }
  .paramRow {
    padding: 5px;
    &:hover {
      background-color: #D5EAFA;
      border-radius: 6px;
      .removeParamBtn {
        color: red;
      }
    }
  }
}
</style>
