import {defineStore} from 'pinia'
import {ref} from 'vue'
import {useGetApi} from 'src/uses/Api/useGetApi'
import {FormItem} from 'src/models/FormEditor/FormItem'

export const useFormsStore = defineStore('forms-store', () => {
  const {
    loading,
    get,
    status,
    response,
  } = useGetApi<FormItem[]>()

  const forms = ref<FormItem[]>([])

  const loadForms = async () => {
    await get(
      'v2/forms',
    )

    if (status.value === 200 && response && response.value) {
      forms.value = response.value
    }
  }

  return {
    loading,
    forms,
    loadForms,
  }
})
