<template>
  <q-field
    :model-value="model"
    disable
    :class="classes"
    hide-bottom-space
    borderless
    dense
  >
    <q-toggle
      v-model="model"
      style="color: #757575"
      :indeterminate-value="false"
      :size="size"
    >
      <template #default>
        <slot name="labelPrepend" />
        <div
          style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; max-width: 300px;"
        >
          {{ props.label || props.field.title }}</div>
        <slot name="labelAppend" />
      </template>
    </q-toggle>
  </q-field>
</template>

<script setup lang="ts">
import {computed, ref} from 'vue'
import {FormElementValue} from 'src/models/Form'
import {useQuasar} from 'quasar'
import {FormItemField} from 'src/models/FormEditor/FormItemResponseData'

const props = defineProps<{
  field: FormItemField,
  label: string | boolean,
}>()

const $q = useQuasar()

const model = ref<FormElementValue>(false)

const size = computed(() => {
  if ($q.platform.is.mobile) {
    return 'xl'
  }

  return 'md'
})

const classes = computed(() => {
  return {
    'no-bg field-wrapper': true,
    noPaddingTop: $q.platform.is.mobile,
  }
})
</script>
