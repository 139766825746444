import {defineStore} from 'pinia'
import {ref, watch} from 'vue'
import {ApplicationGridTypeEnum} from 'src/models/Applications/ApplicationGridTypeEnum'
import {useLocalStorage} from 'src/uses/useLocalStorage'
import {useRoute, useRouter} from 'vue-router'

export const useApplicationGridTypeStore = defineStore(
  'applications-grid-type',
  () => {
    const {
      data,
      setToStorage,
      getFromStorage,
    } = useLocalStorage<ApplicationGridTypeEnum>('applications-grid-type')

    const route = useRoute()
    const router = useRouter()

    const applicationGridType = ref(ApplicationGridTypeEnum.grid)

    getFromStorage()
    applicationGridType.value = data.value || ApplicationGridTypeEnum.grid

    watch(
      applicationGridType,
      () => {
        setToStorage(applicationGridType.value)
        if (route.name !== applicationGridType.value) {
          router.push({
            name: applicationGridType.value,
            query: route.query,
            params: route.params,
          })
        }
      }
    )

    return {
      applicationGridType,
    }
  }
)
