<template>
  <div class="row q-col-gutter-md">
    <div class="col-3 col-sm-6">
      <q-input
        v-model="filters.name"
        :loading="props.loading"
        label="Поиск по имени"
        clearable
        outlined
        hide-hint
        hide-bottom-space
        dense
        @change="onSubmit"
        @clear="onSubmit"
      >
        <template #prepend>
          <q-icon name="svguse:icons.svg?2#app-search" />
        </template>
      </q-input>
    </div>
    <div class="col-3 col-sm-6">
      <q-input
        v-model="filters.inn"
        :loading="props.loading"
        autocomplete="new-password"
        label="Поиск по имени и ИНН агента"
        clearable
        outlined
        hide-hint
        hide-bottom-space
        dense
        @change="onSubmit"
        @clear="onSubmit"
      >
        <template #prepend>
          <q-icon name="svguse:icons.svg?2#app-search" />
        </template>
      </q-input>
    </div>
    <div class="col-3 col-sm-6">
      <q-select
        v-model="filters.role"
        class="MultipleSelect"
        :options="roles"
        :loading="loading"
        map-options
        label="Роль"
        outlined
        hide-hint
        hide-bottom-space
        dense
        emit-value
        @update:model-value="onSubmit"
      />
    </div>
    <div class="col-3 col-sm-6">
      <q-select
        v-model="filters.status"
        class="ellipsis"
        :options="statuses"
        :loading="loading"
        map-options
        label="Статус"
        outlined
        hide-hint
        hide-bottom-space
        dense
        emit-value
        @update:model-value="onSubmit"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import {storeToRefs} from 'pinia'
import {useUsersListFiltersStore} from 'src/stores/Profiles/Users/useUsersListFiltersStore'

const props = defineProps<{
  loading: boolean,
}>()

const emit = defineEmits<{
  (e: 'update:filters'): void,
}>()

const filtersStore = useUsersListFiltersStore()
const {
  filters,
  roles,
  statuses,
} = storeToRefs(filtersStore)

const onSubmit = () => {
  emit('update:filters')
}
</script>
