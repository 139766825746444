import {GridPagination, Pagination, PaginationSettings} from 'src/models/Grid/Pagination'
import {computed, ref, watch, onBeforeMount} from 'vue'
import {useLocalStorage} from 'src/uses/useLocalStorage'
import {useRoute, useRouter} from 'vue-router'
import {useQuasar} from 'quasar'

export const useGridPagination = (hash: string, syncToRouter = false) => {
  const {
    data,
    setToStorage,
    getFromStorage,
  } = useLocalStorage<PaginationSettings>(`grid-pagination-${hash}`)

  const $q = useQuasar()
  const router = useRouter()
  const route = useRoute()

  const perPageOptions = computed(() => [
    5,
    10,
    20,
    30,
    50
  ])

  const pagination = ref<Pagination>({
    count: 1,
    page: 1,
    pages: undefined,
    per_page: 10,
    total: 1,
  })

  const noMorePages = computed(() => {
    if (pagination.value.pages === 0) {
      return true
    }

    return pagination.value.pages === pagination.value.page
  })

  const setPagination = (data: Pagination) => {
    pagination.value = data
  }

  const gridPagination = ref<GridPagination>({
    page: 1,
    rowsPerPage: 10,
    rowsNumber: 1,
    descending: false,
    sortBy: '',
  })

  const setPage = (page: number) => {
    pagination.value.page = page
  }

  const setNextPage = () => {
    if (pagination.value.page >= (pagination.value.pages || 0)) {
      return
    }

    pagination.value.page++
  }

  const setPerPage = (perPage: number) => {
    pagination.value.per_page = perPage
    setToStorage({
      per_page: perPage,
    })
  }

  const onRequestPagination = (pagination: GridPagination) => {
    gridPagination.value.descending = pagination.descending
    gridPagination.value.sortBy = pagination.sortBy
    if (pagination.page !== gridPagination.value.page) {
      setPage(pagination.page)
    }

    if (pagination.rowsPerPage !== gridPagination.value.rowsPerPage) {
      setPerPage(pagination.rowsPerPage)
      setPage(1)
    }
  }

  const setQueryToRouter = async () => {
    if (!syncToRouter) {
      return
    }

    await router.replace({
      ...route,
      query: {
        ...route.query,
        page: pagination.value.page,
      }
    })
  }

  onBeforeMount(() => {
    if (!syncToRouter) {
      return
    }

    setPagination({
      ...pagination.value,
      ...(route.query.page && !$q.platform.is.mobile ? {page: parseInt(route.query.page as string)} : {})
    })
  })

  watch(
    data,
    () => {
      if (!data.value) {
        return
      }

      pagination.value.per_page = data.value.per_page
    }
  )

  watch(
    pagination,
    () => {
      gridPagination.value = {
        page: pagination.value.page,
        rowsPerPage: pagination.value.per_page,
        rowsNumber: pagination.value.total,
        descending: gridPagination.value.descending,
        sortBy: gridPagination.value.sortBy,
      }
    },
    {deep: true}
  )

  return {
    pagination,
    gridPagination,
    perPageOptions,
    getFromStorage,
    noMorePages,
    setQueryToRouter,
    setPagination,
    onRequestPagination,
    setPage,
    setPerPage,
    setNextPage
  }
}
