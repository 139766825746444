import {defineStore} from 'pinia'
import {computed, Ref, ref} from 'vue'
import {usePostApi} from 'src/uses/Api/usePostApi'
import {PasswordRecoveryRequestData} from 'src/models/User/PasswordRecovery/passwordRecoveryRequestData'
import {email, required} from 'src/utils/i18n-validators'
import useVuelidate from '@vuelidate/core'
import {useValidationRules} from 'src/uses/useValidationRules'

export const usePasswordRecoveryStore = defineStore('password-recovery', () => {
  const {
    post,
    response,
    status,
    loading,
    error,
    errors
  } = usePostApi<never, PasswordRecoveryRequestData>()
  const passwordRecoveryRequestData = ref<PasswordRecoveryRequestData>({
    email: '',
  })

  const {
    getRulesFromState,
  } = useValidationRules()

  const success = ref(false)

  const rules = computed(() => {
    return {
      ...getRulesFromState(passwordRecoveryRequestData.value),
      email: {
        required,
        email,
      },
    }
  })

  const validation = useVuelidate<Ref<PasswordRecoveryRequestData>>(rules, passwordRecoveryRequestData)

  const doPasswordRecovery = async () => {
    if (!await validation.value.$validate()) {
      return
    }

    await post('v1/password', passwordRecoveryRequestData.value)
    if (status.value === 200 && response.value) {
      success.value = true

      return
    }

    success.value = false
  }

  return {
    passwordRecoveryRequestData,
    loading,
    response,
    status,
    error,
    errors,
    validation,
    success,
    doPasswordRecovery,
  }
})
