import {defineStore} from 'pinia'
import {useGetApi} from 'src/uses/Api/useGetApi'
import {FormItemDocumentListItem} from 'src/models/FormEditor/FormItemDocumentListItem'
import {ref} from 'vue'

export const useFormItemGetDocumentsStore = (formId: string) => defineStore(`form-item-get-documents-${formId}`, () => {
  const {
    loading,
    get,
    errors,
    error,
    status,
    response,
  } = useGetApi<FormItemDocumentListItem[]>()

  const documents = ref<FormItemDocumentListItem[]>([])

  const loadDocs = async () => {
    await get('v2/forms/documents')
    if (status.value === 200 && response && response.value) {
      documents.value = response.value
    }
  }

  return {
    loading,
    response,
    status,
    error,
    errors,
    documents,
    loadDocs,
  }
})
