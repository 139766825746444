import {usePostApi} from 'src/uses/Api/usePostApi'
import {ref} from 'vue'
import {SelectBank} from 'src/models/Applications/New/SelectBank'
import {GetProductListKikPayload, GetProductListPayload} from 'src/models/Applications/New/GetProductListPayload'
import {useStopFactorResult} from 'src/uses/Applications/useStopFactorResult'

export const useApplicationNewProductList = () => {
  const {
    loading,
    post,
    status,
    response,
    errors,
  } = usePostApi<SelectBank[], GetProductListPayload | GetProductListKikPayload>(true)

  const {
    isRequired,
  } = useStopFactorResult()

  const products = ref<SelectBank[]>([])

  const selected = ref<SelectBank[]>([])

  const load = async (payload: GetProductListPayload | GetProductListKikPayload) => {
    selected.value = []
    await post('v1/product/list', payload)

    if (status.value === 200 && response.value) {
      products.value = response.value
      setRequired()
    }
  }

  const setRequired = () => {
    products.value.forEach(item => {
      if (isRequired(item.factor_result, item.required)) {
        selected.value.push(item)
      }
    })
  }

  return {
    load,
    products,
    selected,
    loading,
    errors,
  }
}
