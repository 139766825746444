<template>
  <tr>
    <td>{{ props.field.title }}</td>
    <td>{{ props.field.code }}</td>
    <td>
      <div :class="classes">
        <span ref="fieldRef" />
        <q-circular-progress
          v-if="props.field.loading"
          v-model="formattedValue"
          class="absolute"
          style="margin-left: -16px;"
          color="grey-7"
          indeterminate
          rounded
          size="xs"
        />
        <input
          v-model="formattedValue"
          :key="JSON.stringify(props.field)"
          style="line-height: 30px; height: 30px; padding-left: 20px; padding-right: 20px; "
          class="q-field__input text-right"
          :disabled="props.field.readonly"
          @blur="blur"
          @focus="onFocus"
        >
      </div>
    </td>
  </tr>
</template>

<script setup lang="ts">
import {computed, nextTick, ref, watch, watchEffect} from 'vue'
import {ApplicationFormFinanceField} from 'src/models/Applications/Form/ApplicationFormResponseData'
import {useScrollTo} from 'src/uses/useScrollTo'
import {useCurrencyInput} from 'src/uses/useCurrencyInput'
import {useLayoutStore} from 'stores/useLayoutStore'
import {storeToRefs} from 'pinia'
import {useQuasar} from 'quasar'

const props = defineProps<{
  field: ApplicationFormFinanceField,
}>()

const emit = defineEmits<{
  (e: 'update:field', field: ApplicationFormFinanceField): void,
  (e: 'update:modelValue'): void,
  (e: 'change'): void,
}>()

const layoutStore = useLayoutStore()
const {rightDrawer, drawer} = storeToRefs(layoutStore)

const $q = useQuasar()

const {
  scrollToInvisibleElement,
} = useScrollTo()

const {
  formattedValue,
  numberValue,
  onFocus,
  onBlur,
} = useCurrencyInput(true)

const fieldRef = ref<HTMLSpanElement>()

const classes = computed(() => {
  return {
    'white-bg-field': !props.field.highlighted,
    'bg-orange-1': props.field.highlighted,
    'text-right': true,
  }
})

const blur = () => {
  onBlur()
  emit(
    'update:field',
    {
      ...props.field,
      value: numberValue.value || 0
    }
  )
}

watchEffect(() => {
  if (!props.field.highlighted) {
    return
  }

  if ($q.platform.is.mobile) {
    rightDrawer.value = false
    drawer.value = false
  }

  setTimeout(() => {
    if (fieldRef.value) {
      scrollToInvisibleElement(fieldRef.value)
    }
  }, 300)
})

watch(
  props.field,
  () => {
    nextTick(() => {
      numberValue.value = props.field.value === 0 || props.field.value
        ? props.field.value
        : undefined
    })
  },
  {
    deep: true,
    immediate: true,
  },
)
</script>
