import {AuthedGuard} from 'src/router/quards/AuthedGuard'

export const helpRouter = {
  path: '/help',
  component: () => import(/* webpackChunkName: "MainLayout" */ '../layouts/MainLayout.vue'),
  redirect: {name: 'help'},
  children: [
    {
      path: '/help',
      name: 'help',
      components: {
        default: () => import(/* webpackChunkName: "HelpView" */ '../views/Help/HelpView.vue'),
        rightSidebar: () => import(/* webpackChunkName: "HelpViewSidebar" */ '../views/Help/HelpViewSidebar.vue'),
      },
      meta: {
        guards: [
          AuthedGuard(),
        ],
      },
    },
    {
      path: 'item/:helpPageId',
      name: 'help-page',
      components: {
        default: () => import(/* webpackChunkName: "helpView" */ '../views/Help/HelpView.vue'),
        rightSidebar: () => import(/* webpackChunkName: "HelpViewSidebar" */ '../views/Help/HelpViewSidebar.vue'),
      },
      meta: {
        guards: [
          AuthedGuard(),
        ],
      },
    },
  ]
}
