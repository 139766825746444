<template>
  <div
    v-if="countErrorFields"
    class="text-orange-6 bg-orange-1 rounded-borders q-mt-sm q-pa-md"
  >
    <div class="row items-center">
      <q-icon
        size="sm"
        color="orange-6"
        name="svguse:icons.svg?2#app-information-circle"
      />
      <div class="col q-pl-sm">
        <strong>Состояние системы</strong>
      </div>
    </div>
    <div class="q-mt-md">
      Внимание! В форме остались незаполненные поля (<strong>{{ countErrorFields }}</strong>)
    </div>
    <q-btn
      class="q-mt-md full-width"
      label="Показать"
      color="orange-6"
      icon-right="svguse:icons.svg?2#app-cursor-click"
      padding="10px 16px"
      unelevated
      no-caps
      @click="showNextField"
    />
  </div>
  <div
    v-else
    class="text-green-14 bg-green-1 rounded-borders q-mt-sm q-pa-md"
  >
    <div class="row items-center">
      <q-icon
        size="sm"
        color="green-14"
        name="svguse:icons.svg?2#app-information-circle"
      />
      <div class="col q-pl-sm">
        <strong>Состояние системы</strong>
      </div>
    </div>
    <div class="q-mt-md">
      Все данные введены, ограничений нет
    </div>
  </div>
</template>

<script setup lang="ts">
import {useFillNext} from 'src/uses/Applications/Form/fill/useFillNext'

const props = defineProps<{
  multiOrderId: number,
}>()

const {
  countErrorFields,
  showNextField,
} = useFillNext(props.multiOrderId)

</script>
