<template>
  <template v-if="!loading && props.applicationItem">
    <div class="BreadCrumbs q-px-lg q-pb-md">
      <q-breadcrumbs>
        <q-breadcrumbs-el
          label="Заявки"
          :to="{name: 'applications-grid'}"
        />
        <q-breadcrumbs-el
          label="Черновики"
          :to="toDraftRoute"
        />
        <q-breadcrumbs-el
          :label="`Анкета № ${props.applicationItem.order_id}`"
        />
      </q-breadcrumbs>
    </div>
    <div class="q-px-lg q-py-md">
      <div class="row q-mb-md">
        <div class="col">
          <div class="text-h5 q-pb-xs">
            <strong>Анкета № {{ props.applicationItem.order_id }}</strong>
          </div>
          <div class="row q-pb-xs">
            <div class="q-pr-sm">
              <client-link
                :title="props.applicationItem.client_title"
                :inn="props.applicationItem.client_inn"
              />
            </div>
            <div class="col">
              ИНН {{ props.applicationItem.client_inn }}
            </div>
          </div>
        </div>
        <div class="q-pt-xs">
          <span class="text-grey-9">
            <q-icon
              style="margin-top: -4px;"
              name="svguse:icons.svg?2#app-calendar"
              size="sm"
              color="blue-grey-4"
            />
            {{ props.applicationItem.order_date }}
            <q-tooltip>Дата создания заявки</q-tooltip>
          </span>
        </div>
      </div>
    </div>
  </template>
</template>

<script setup lang="ts">
import {ApplicationFormResponseData} from 'src/models/Applications/Form/ApplicationFormResponseData'
import ClientLink from 'src/components/ClientLink.vue'
import {ApplicationStatusEnum} from 'src/models/Applications/ApplicationStatusEnum'

const props = defineProps<{
  loading: boolean,
  applicationItem: ApplicationFormResponseData,
}>()

const toDraftRoute = {
  name: 'applications-grid',
  query: {
    'filter[status]': ApplicationStatusEnum.draft,
  },
}
</script>

<style lang="scss">
.BreadCrumbs {
  border-bottom: 1px solid $blue-grey-1;
}
</style>
