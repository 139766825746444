import {ref, watch} from 'vue'

export const useCurrencyInput = (integer = false) => {
  const numberValue = ref<number>()
  const formattedValue = ref<string>()
  const focused = ref(false)

  const onFocus = () => {
    focused.value = true
    if (numberValue.value) {
      formattedValue.value = numberValue.value.toString().replaceAll('.', ',')
      if (integer) {
        formattedValue.value = formattedValue.value.split(',')[0]
      }
    }
  }

  const onBlur = () => {
    focused.value = false
    parse()
    format()
  }

  const format = () => {
    if (numberValue.value !== 0 && !numberValue.value) {
      formattedValue.value = undefined
      return
    }

    formattedValue.value = new Intl.NumberFormat(
      'ru',
      {
        minimumIntegerDigits: 1,
        minimumFractionDigits: integer ? 0 : 2,
        maximumFractionDigits: integer ? 0 : 2,
      }
    )
      .format(numberValue.value)
  }

  const parse = () => {
    if (focused.value) {
      return
    }
    if (!formattedValue.value) {
      numberValue.value = undefined
      return
    }

    let value = formattedValue.value
      .replaceAll(',', '.')

    if (integer) {
      value = value.split(',')[0]
    }

    value = value.replaceAll(/[^-?\d.]/gi, '')

    if (integer) {
      numberValue.value = parseInt(value)
    } else {
      numberValue.value = parseFloat(value)
    }
  }

  watch(numberValue, format)

  watch(formattedValue, parse)

  return {
    numberValue,
    formattedValue,
    onFocus,
    onBlur,
  }
}
