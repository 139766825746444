<template>
  <q-toggle
    v-model="value"
    style="margin-left: -10px;"
  />
</template>

<script setup lang="ts">
import {ref, watch} from 'vue'
import {ChannelsEnum, Subscription} from 'src/models/Profile/Notifications/Subscription'

const props = defineProps<{
  modelValue: Subscription
  channel: ChannelsEnum
}>()

const value = ref(props.modelValue.channels[props.channel])

const emit = defineEmits<{
  (e: 'update:modal-value', value: Subscription): void,
}>()

watch(
  value,
  () => {
    emit('update:modal-value', {
      ...props.modelValue,
      channels: {
        ...props.modelValue.channels,
        [props.channel]: value.value,
      }
    })
  },
  {deep: true}
)

</script>
