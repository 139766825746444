<template>
  <div class="white-bg-field q-my-md">
    {{search}}
    <q-input
      v-model="search"
      label="Поиск по наименованию документа"
      outlined
      hide-bottom-space
      dense
      emit-value
      map-options
      options-dense
      hide-dropdown-icon
      clearable
    >
      <template #prepend>
        <q-icon name="app-search" />
      </template>
    </q-input>
  </div>
  <form-item-generated-documents-block
    v-for="documentType in documentTypes"
    :key="documentType"
    :form-id="props.formId"
    :document-type="documentType"
    :search="search"
  />
</template>

<script setup lang="ts">
import {ApplicationFormDocumentGroupEnum} from 'src/models/Applications/Form/ApplicationFormResponseData'
import {onBeforeMount, onBeforeUnmount, ref} from 'vue'
import FormItemGeneratedDocumentsBlock from 'components/FormEditor/Documents/FormItemGeneratedDocumentsBlock.vue'
import {useFormItemGeneratedDocumentsStore} from 'stores/FormEditor/useFormItemGeneratedDocumentsStore'

const props = defineProps<{
  formId: string,
}>()

const formItemDocumentsStore = useFormItemGeneratedDocumentsStore(props.formId)()
const {
  load,
} = formItemDocumentsStore

const documentTypes: ApplicationFormDocumentGroupEnum[] = [
  ApplicationFormDocumentGroupEnum.deal,
  ApplicationFormDocumentGroupEnum.offer,
]
const search = ref('')

onBeforeMount(async () => {
  await load()
})

onBeforeUnmount(() => {
  formItemDocumentsStore.$reset()
  formItemDocumentsStore.$dispose()
})
</script>
