<template>
  <div class="col-sm col-xs-12 bannerMainContent">
    К сожалению по заявке получен отказ от банка. <br>
    Причина отказа: {{ headerInfo.status_comment }}
  </div>
  <!--<div
    class=""
    v-if="isOperator() || isAgentManager()"
  >
    <q-btn
      class="banner-btn"
      label="Изменить данные"
      icon-right="svguse:icons.svg?2#app-pencil-alt"
      color="primary"
      :disable="loading"
      :loading="loading"
      unelevated
      no-caps
      @click="changeStatus(
        {
          id: headerInfo.id,
          applicationStatus: ApplicationSubStatusEnum.completion,
          initiator: 'completion'
        },
        headerInfo.sub_status as ApplicationSubStatusEnum
      )"
    >
      <q-tooltip class="bg-blue-grey-10 StopTooltip q-pa-md">
        <div class="row items-center">
          <q-icon
            name="svguse:icons.svg?2#app-exclamation"
            size="sm"
            color="orange"
          />
          <div
            class="q-ml-md"
            style="max-width: 210px;"
          >
            Внимание!<br>
            При изменении данных заявка будет повторно отправлена на проверку
          </div>
        </div>
      </q-tooltip>
    </q-btn>
  </div>-->
</template>
<script setup lang="ts">
import {computed} from 'vue'
import {ControlCenterHeader} from 'src/models/Applications/ControlCenter/ControlCenterHeader'
/*import {
  useApplicationControlCenterChangeStatusStore
} from 'src/stores/Applications/ControlCenter/ChangeStatus/useApplicationControlCenterChangeStatusStore'*/
//import {useProfileStore} from 'src/stores/Login/useProfileStore'
//import {ApplicationSubStatusEnum} from 'src/models/Applications/ApplicationSubStatusEnum'
//import {storeToRefs} from 'pinia'

const props = defineProps<{ headerInfo: ControlCenterHeader }>()
const headerInfo = computed(() => props.headerInfo)

/*const {
  isOperator,
  isAgentManager,
} = useProfileStore()

const {
  changeStatus,
} = useApplicationControlCenterChangeStatusStore(headerInfo.value?.id)()
const {loading} = storeToRefs(useApplicationControlCenterChangeStatusStore(props.headerInfo.id)())*/
</script>
