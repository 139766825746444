import {FormElementValue} from 'src/models/Form'
import {ApplicationFormUpdateBlockEnum} from 'src/models/Applications/Form/ApplicationFormUpdateResponse'
import {ApplicationSubStatusEnum} from 'src/models/Applications/ApplicationSubStatusEnum'
import {ApplicationStatusEnum} from 'src/models/Applications/ApplicationStatusEnum'
import {ProductEnum} from 'src/models/Applications/ProductEnum'

export enum ApplicationFormDocumentGroupEnum {
  additional = 'additional',
  financial = 'financial',
  passports = 'passports',
  deal = 'deal',
  offer = 'offer',
  client = 'client',
}

export enum ApplicationFormDocumentChatSourceEnum {
  platform_chat = 'platform_chat',
  bank_chat = 'bank_chat',
}

export enum ApplicationFormDocumentStatusEnum {
  uploaded = 'uploaded',
  signed = 'signed',
  new = 'new',
}

export interface ApplicationFormFieldService {
  code: string;
  target_field: string;
}

export interface FieldLocation {
  block: ApplicationFormUpdateBlockEnum,
  section_type: string,
  section_id?: string,
  bank_id?: string,
}

export enum ApplicationFormFieldTypeEnum {
  dropdown = 'dropdown',
  boolean = 'boolean',
  date = 'date',
  period = 'period',
  amount = 'amount',
  address = 'address',
  phone = 'phone',
  email = 'email',
  string = 'string',
  btngroup = 'btngroup',
  count = 'count',
}

export interface AddressDetails {
  block: null | string
  block_type: null | string
  block_type_full: null | string
  city: null | string
  city_type: null | string
  city_type_full: null | string
  city_with_type: null | string
  country: null | string
  flat: null | string
  flat_type: null | string
  flat_type_full: null | string
  house: null | string
  house_type: null | string
  house_type_full: null | string
  okato: null | string
  oktmo: null | string
  post_code: null | string
  region: null | string
  region_code: null | string
  region_fias_id: null | string
  region_kladr_id: null | string
  region_with_type: null | string
  reign: null | string
  reign_type: null | string
  reign_type_full: null | string
  reign_with_type: null | string
  result: null | string
  settlement: null | string
  settlement_type: null | string
  settlement_type_full: null | string
  settlement_with_type: null | string
  source: null | string
  street: null | string
  street_type: null | string
  street_type_full: null | string
  street_with_type: null | string
  timezone: null | string
  unparsed_parts: null | string
}

export interface ApplicationFormFieldValid {
  id: string,
  errors: string[],
}

export enum ApplicationFormFieldRuleEnum {
  minDate = 'min_date',
  maxDate = 'max_date',
}

export interface ApplicationFormFieldRule {
  name: ApplicationFormFieldRuleEnum
  value: string
}

export interface ApplicationFormField {
  id?: string,
  choices?: Record<string, never> | null
  help?: string | null
  length: number
  mask?: string | null
  optional: boolean
  parent_field_code?: string | null
  readonly: boolean
  service?: ApplicationFormFieldService | null
  title: string
  type: ApplicationFormFieldTypeEnum
  error?: string
  loading?: boolean
  code: string
  value: FormElementValue
  invisible: boolean
  valid: ApplicationFormFieldValid[]
  location: FieldLocation
  highlighted: boolean
  rules: ApplicationFormFieldRule[]
  details?: AddressDetails
  is_new_field?: boolean
}

export interface ApplicationFormFinanceField {
  id?: string,
  code: string
  sort: number | null
  title: string
  value: number | null
  error?: string
  invisible: boolean
  loading: boolean
  valid: ApplicationFormFieldValid[]
  location: FieldLocation
  highlighted: boolean
  readonly: boolean
}

export interface ApplicationFormFinancePeriod {
  comment: string
  is_optional: boolean
  quarter: number
  year: number
}

export interface ApplicationFormFinance {
  fields: Record<number, ApplicationFormFinanceField[]>
  periods: Record<number, ApplicationFormFinancePeriod>
}

export interface ApplicationFormFounder {
  fields: ApplicationFormField[]
  id: string
}

export interface ApplicationFormClient {
  common: {
    fields: ApplicationFormField[]
  }
  head: {
    fields: ApplicationFormField[]
  }
  founders: ApplicationFormFounder[]
}

export interface ApplicationFormOrder {
  main: {
    fields: ApplicationFormField[]
  },
  customers: {
    fields: ApplicationFormField[]
    id: number
  }[]
}

export interface ApplicationFormBank {
  bank_id: string
  id: string
  inn: string
  sub_status: ApplicationSubStatusEnum
  status: ApplicationStatusEnum
  tariff: number | string
  tariff_type: string
  tariff_calculator_enabled: boolean
  commission_amount: number | null
  commission_percent: number | null
  title: string
  url: string // logo url
  product: ProductEnum
  required: boolean
}

export interface ApplicationFormDocument {
  location: FieldLocation
  invisible: boolean
  valid: ApplicationFormFieldValid[]
  code: string
  description: string | null
  file_date: string | null
  file_name: string | null
  file_path: string | null
  file_status: ApplicationFormDocumentStatusEnum
  founder_id: string | null
  founder_passport_number: string | null
  founder_passport_series: string | null
  index: number | null
  period_quarter: number | null
  period_year: number | null
  sign_file_name: string | null
  sign_file_path: string | null
  title: string
  loading?: boolean
  error?: string
  progress?: ProgressEvent
  file?: File
  file_sign?: File
  highlighted: boolean
  readonly: boolean
  source?: ApplicationFormDocumentChatSourceEnum
  sign_file_required: boolean
  id?: string
  principal_id?: string
  partner_id?: string
}

export interface ApplicationFormDocuments {
  [ApplicationFormDocumentGroupEnum.additional]?: ApplicationFormDocument[]
  [ApplicationFormDocumentGroupEnum.financial]?: ApplicationFormDocument[]
  [ApplicationFormDocumentGroupEnum.passports]?: ApplicationFormDocument[]
  [ApplicationFormDocumentGroupEnum.deal]?: ApplicationFormDocument[]
  [ApplicationFormDocumentGroupEnum.offer]?: ApplicationFormDocument[]
  [ApplicationFormDocumentGroupEnum.client]?: ApplicationFormDocument[]
}

export interface ApplicationFormProduct {
  order: ApplicationFormOrder
  client: ApplicationFormClient
  finance: ApplicationFormFinance
  banks: ApplicationFormBank[]
  documents: ApplicationFormDocuments
}

export interface ApplicationFormOptions {
  finance: {
    allow_short_reporting: boolean
    short_reporting: boolean
  }
}

export interface ApplicationFormResponseData {
  id: string
  order_id: number
  order_date: string
  client_inn: string
  client_title: string
  last_update: string
  order: ApplicationFormOrder
  client: ApplicationFormClient
  finance: ApplicationFormFinance
  banks: ApplicationFormBank[]
  documents: ApplicationFormDocuments
  products: {
    EBG?: ApplicationFormProduct
    KIK?: ApplicationFormProduct
    RKO?: ApplicationFormProduct
  }
  options: ApplicationFormOptions
}
