<template>
  <q-dialog
    :model-value="!!val && now && show"
    position="top"
    seamless
    no-refocus
    no-focus
  >
    <q-banner
      v-if="val"
      style="width: auto !important;"
      class="ApplicationLastModifiedInfo bg-blue-grey-1 text-primary"
      dense
      rounded
    >
      <span style="display: none">{{ now }}</span>
      <q-icon
        name="svguse:icons.svg?2#app-bell"
        color="primary"
        size="sm"
      />
      Данные сохранены {{ val.locale('ru').fromNow() }}
      <q-btn
        icon="svguse:icons.svg?2#app-x"
        size="sm"
        dense
        rounded
        flat
        @click="show = false"
      />
    </q-banner>
  </q-dialog>
</template>

<script setup lang="ts">
import moment from 'moment'
import {onUnmounted, ref, watchEffect, withDefaults} from 'vue'
import {Moment} from 'moment/moment'

type propModel = string | Moment

const props = withDefaults(
  defineProps<{
    modelValue: propModel,
    parseFormat?: string,
  }>(),
  {
    parseFormat: 'YYYY-MM-DD HH:mm:ss',
  }
)

const val = ref<Moment | undefined>()

const show = ref(true)

const now = ref<Date>(new Date())
const interval = setInterval(
  () => {
    now.value = new Date()
  },
  60000
)

onUnmounted(() => {
  clearInterval(interval)
})

watchEffect(() => {
  val.value = moment(props.modelValue, props.parseFormat)
  show.value = true
  setTimeout(() => {
    show.value = false
  }, 15000)
})
</script>

<style lang="scss">
.ApplicationLastModifiedInfo {
  width: min-content !important;
  white-space: nowrap;
  margin-top: 5px;
}
</style>
