import {useProfileStore} from 'src/stores/Login/useProfileStore'
import {ApplicationSubStatusEnum} from 'src/models/Applications/ApplicationSubStatusEnum'
import {ApplicationStatusEnum} from 'src/models/Applications/ApplicationStatusEnum'

export const useChangeStatusAction = () => {
  const profileStore = useProfileStore()
  const {
    isAgent,
    isOperator,
    isEmployee,
    isHeadEmployee,
    isBankEmployee,
    isAdmin,
  } = profileStore

  const isDraft = (subStatus: ApplicationSubStatusEnum) => {
    return subStatus === ApplicationSubStatusEnum.draft
  }

  const isBankRejected = (subStatus: ApplicationSubStatusEnum) => {
    return subStatus === ApplicationSubStatusEnum.bank_rejected
  }

  const isSystemRejected = (subStatus: ApplicationSubStatusEnum) => {
    return subStatus === ApplicationSubStatusEnum.system_rejected
  }

  const isArchive = (subStatus: ApplicationSubStatusEnum) => {
    return subStatus === ApplicationSubStatusEnum.archive
  }

  const isProjectBgReady = (subStatus: ApplicationSubStatusEnum) => {
    return [
      ApplicationSubStatusEnum.bg_project_customer_agreement,
      ApplicationSubStatusEnum.bg_project_customer_agreement_request_sign,
    ].includes(subStatus)
  }

  const isReleased = (subStatus: ApplicationSubStatusEnum) => {
    return [
      ApplicationSubStatusEnum.guarantee_released,
      ApplicationSubStatusEnum.guarantee_released_request,
      ApplicationSubStatusEnum.guarantee_released_request_sign,
    ].includes(subStatus)
  }

  const isClientRejected = (subStatus: ApplicationSubStatusEnum) => {
    return subStatus === ApplicationSubStatusEnum.client_rejected
  }

  const showClientReject = (subStatus: ApplicationSubStatusEnum, isIntegration: boolean) => {
    if (isIntegration
      && (
        isAgent()
        || isEmployee()
        || isHeadEmployee()
      )
    ) {
      return [
        ApplicationSubStatusEnum.draft,
        ApplicationSubStatusEnum.checking,
        ApplicationSubStatusEnum.completion,
        ApplicationSubStatusEnum.new,
      ].includes(subStatus)
    }

    return [
      ApplicationSubStatusEnum.draft,
      ApplicationSubStatusEnum.checking,
      ApplicationSubStatusEnum.completion,
      ApplicationSubStatusEnum.new,
      ApplicationSubStatusEnum.client_signed,
      ApplicationSubStatusEnum.send_2_bank,
      ApplicationSubStatusEnum.in_bank,
      ApplicationSubStatusEnum.data_request,
      ApplicationSubStatusEnum.data_request_sign,
      ApplicationSubStatusEnum.data_request_executed,
      ApplicationSubStatusEnum.risks_assessment,
      ApplicationSubStatusEnum.risks_request,
      ApplicationSubStatusEnum.risks_request_sign,
      ApplicationSubStatusEnum.risks_request_executed,
      ApplicationSubStatusEnum.bg_project_preparation,
      ApplicationSubStatusEnum.bg_project_customer_agreement,
      ApplicationSubStatusEnum.bg_project_customer_agreement_request_sign,
      //ApplicationSubStatusEnum.bg_project_bank_agreement,
      ApplicationSubStatusEnum.bg_project_bank_agreement_request,
      ApplicationSubStatusEnum.bg_project_bank_agreement_request_sign,
    ].includes(subStatus)
  }

  const showCompletion = (subStatus: ApplicationSubStatusEnum) => {
    return isOperator()
      && [
        ApplicationSubStatusEnum.talend_loaded,
        ApplicationSubStatusEnum.go_loaded,
        ApplicationSubStatusEnum.forming_errors,
        ApplicationSubStatusEnum.accepted,
        ApplicationSubStatusEnum.checking,
        ApplicationSubStatusEnum.completion,
      ].includes(subStatus)
  }

  const showSendToProduction = (subStatus: ApplicationSubStatusEnum) => {
    return isProjectBgReady(subStatus)
  }

  const showToBgProjectBankAgreement = (subStatus: ApplicationSubStatusEnum) => {
    return isProjectBgReady(subStatus)
  }

  const showToDraft = (subStatus: ApplicationSubStatusEnum, sentDate: string) => {
    return isClientRejected(subStatus)
      && !sentDate
  }

  const showSendToCheck = (subStatus: ApplicationSubStatusEnum, filled: boolean) => {
    return isDraft(subStatus)
      && filled
  }

  const getSendRequestApplicationStatus = (subStatus: ApplicationSubStatusEnum, status: ApplicationStatusEnum) => {
    if (status === ApplicationStatusEnum.verification
      && subStatus !== ApplicationSubStatusEnum.completion
    ) {
      return ApplicationSubStatusEnum.completion
    }

    if (status === ApplicationStatusEnum.abs
      && subStatus !== ApplicationSubStatusEnum.data_request
    ) {
      return ApplicationSubStatusEnum.data_request
    }

    if (status === ApplicationStatusEnum.risk_assessment
      && subStatus !== ApplicationSubStatusEnum.risks_request
    ) {
      return ApplicationSubStatusEnum.risks_request
    }

    if ((isBankEmployee() || isAdmin())
      && status === ApplicationStatusEnum.agreement
      && subStatus !== ApplicationSubStatusEnum.bg_project_bank_agreement_request
    ) {
      return ApplicationSubStatusEnum.bg_project_bank_agreement_request
    }

    if ((isBankEmployee() || isAdmin())
      && status === ApplicationStatusEnum.on_release
      && subStatus !== ApplicationSubStatusEnum.deferred_conditions_execution_waiting
    ) {
      return ApplicationSubStatusEnum.deferred_conditions_execution_waiting
    }

    if ((isBankEmployee() || isAdmin())
      && status === ApplicationStatusEnum.released
      && subStatus !== ApplicationSubStatusEnum.guarantee_released_request
    ) {
      return ApplicationSubStatusEnum.guarantee_released_request
    }

    return undefined
  }

  const showStatusChange = (subStatus: ApplicationSubStatusEnum, sentDate: string, filled: boolean, isIntegration: boolean) => {
    return showToDraft(subStatus, sentDate)
      || showToBgProjectBankAgreement(subStatus)
      || showSendToProduction(subStatus)
      || showClientReject(subStatus, isIntegration)
      || showSendToCheck(subStatus, filled)
  }

  return {
    isDraft,
    isBankRejected,
    isSystemRejected,
    isArchive,
    isClientRejected,
    isProjectBgReady,
    isReleased,
    showCompletion,
    showToDraft,
    showToBgProjectBankAgreement,
    showSendToProduction,
    showClientReject,
    showSendToCheck,
    showStatusChange,
    getSendRequestApplicationStatus,
  }
}
