import {defineStore} from 'pinia'
import {useGetApi} from 'src/uses/Api/useGetApi'
import {computed, ref} from 'vue'
import {useAddFounder} from 'src/uses/Applications/Form/Client/useAddFounder'
import {ApplicationItemSectionsEnum} from 'src/models/Enums/ApplicationItemSectionsEnum'
import {
  FormItemDocument,
  FormItemClientBlock,
  FormItemOrderBlock,
  FormItemResponseData
} from 'src/models/FormEditor/FormItemResponseData'

export const useFormItemStore = (formId: string) => defineStore(`form-item-${formId}`, () => {
  const client = ref<FormItemClientBlock | null>(null)
  const order = ref<FormItemOrderBlock | null>(null)
  const documents = ref<FormItemDocument[] | null>(null)
  const generatedDocuments = computed(() => {
    if (!item.value) {
      return []
    }

    return item.value.generated_document_block || []
  })

  const tab = ref<ApplicationItemSectionsEnum>(ApplicationItemSectionsEnum.MAIN)

  const addFounderUse = useAddFounder()

  const ITEM_URL = 'v2/forms/'

  const {
    loading,
    get,
    errors,
    error,
    status,
    response,
  } = useGetApi<FormItemResponseData>()

  const item = ref<FormItemResponseData>()

  const load = async () => {
    await get(ITEM_URL + formId)

    if (status.value === 200 && response.value) {
      item.value = response.value
      order.value = response.value.order_block || null
      client.value = response.value.client_block || null
      documents.value = response.value.document_block || []
    }
  }

  return {
    loading,
    get,
    errors,
    error,
    load,
    item,
    tab,

    client,
    order,
    documents,
    generatedDocuments,

    addFounderLoading: addFounderUse.loading,
  }
})
