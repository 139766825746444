<template>
  <q-dialog
    v-model="emailDialog"
    position="top"
  >
    <q-card
      class="q-mt-md bg-blue-1"
      style="min-width: 400px; max-width: 600px; border-radius: 4px !important;"
    >
      <q-card-section class="text-h6 rounded-borders">
        Укажите e-mail для уведомлений по заявке
      </q-card-section>
      <q-card-section class="bg-blue-grey-10 text-white row items-center q-py-lg">
        <div class="rounded-borders q-pa-sm bg-blue-grey-9">
          <q-icon
            name="svguse:icons.svg?2#app-information-circle"
            size="sm"
            color="orange-6"
          />
        </div>
        <div class="col q-pl-sm text-wgite">
          Все уведомления по созданной заявке будут отправлены на указанный ниже e-mail
        </div>
      </q-card-section>
      <q-card-section class="ApplicationItemSendModal__main q-pt-none white-bg-field">
        <q-input
          v-model="model"
          class="field-with-top-label q-mt-lg"
          label="Укажите e-mail"
          :error="v$.model.$error"
          :error-message="v$.model.$errors[0] ? v$.model.$errors[0].$message as string : ''"
          type="email"
          outlined
          hide-bottom-space
          dense
          emit-value
          map-options
          options-dense
          hide-dropdown-icon
          @blur="v$.$validate()"
        />
      </q-card-section>
      <q-card-actions
        class="row justify-end q-py-md"
        style="background: #D5EAFA;"
      >
        <q-btn
          align="left"
          class="sendButton"
          color="primary"
          style="padding: 16px"
          label="Сохранить"
          unelevated
          :disable="v$.$invalid"
          no-caps
          @click="onClick"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
import {storeToRefs} from 'pinia'
import {useApplicationsNew} from 'src/stores/Applications/ApplicationsNew/useApplicationsNew'
import useVuelidate from '@vuelidate/core'
import {email as emailValidator, required} from 'src/utils/i18n-validators'
import {ref, watchEffect} from 'vue'

const applicationsNew = useApplicationsNew()

const {
  create,
} = applicationsNew

const {
  email,
  selectedEmployee,
  emailDialog,
} = storeToRefs(applicationsNew)

const model = ref<string>()

const v$ = useVuelidate(
  {
    model: {
      email: emailValidator,
      required,
    },
  },
  {model}
)

const onClick = async () => {
  await v$.value.$validate()
  email.value = model.value

  await create()
}

watchEffect(() => {
  model.value = selectedEmployee.value?.email || ''
})
</script>
