<template>
  <page-block-wrapper>
    <div class="profile-wrapper">
      <profile-notifications />
    </div>
  </page-block-wrapper>
</template>
<script setup lang="ts">
import ProfileNotifications from 'src/components/Profile/Notifications/ProfileNotifications.vue'
import PageBlockWrapper from 'src/components/PageBlockWrapper.vue'
import {useMetaStore} from 'src/stores/useMetaStore'

useMetaStore().setTitle('Уведомления')
</script>
