import {computed, Ref} from 'vue'
import {ProductFields} from 'src/models/Applications/Form/ProductFields'
import {useProductFields} from 'src/uses/Applications/useProductFields'
import {FieldsCodesEnum} from 'src/models/FieldsCodesEnum'

export const useClientName = (fields: Ref<ProductFields>) => {
  const {
    getFieldByCode,
  } = useProductFields()

  const isUl = computed(() => (getFieldByCode(fields.value, FieldsCodesEnum.CLIENT_HF_UR_FL) || {}).value || false)
  const ulName = computed(() => (getFieldByCode(fields.value, FieldsCodesEnum.CLIENT_HF_UL_Name) || {}).value || '')
  const firstName = computed(() => (getFieldByCode(fields.value, FieldsCodesEnum.CLIENT_HF_NAME) || {}).value || '')
  const secondName = computed(() => (getFieldByCode(fields.value, FieldsCodesEnum.CLIENT_HF_SURNAME) || {}).value || '')
  const thirdName = computed(() => (getFieldByCode(fields.value, FieldsCodesEnum.CLIENT_HF_PATRONYMIC) || {}).value || '')
  const fullName = computed(() => {
    if (isUl.value) {
      return ulName.value
    }

    return `${secondName.value} ${firstName.value} ${thirdName.value}`
  })

  return {
    firstName,
    secondName,
    thirdName,
    fullName,
  }
}
