<template>
  <div class="q-pt-none ApplicationHeaderActions">
    <q-btn
      v-if="showClientReject(props.headerData)"
      class="status-button float-left"
      label="Отказ клиента"
      icon-right="svguse:icons.svg?2#app-x-circle"
      :disable="loading"
      :loading="loading"
      unelevated
      no-caps
      @click="changeStatus(
        {
          id: props.headerData.id,
          applicationStatus: ApplicationSubStatusEnum.client_rejected,
          initiator: 'client_rejected'
        },
        props.headerData.sub_status as never as ApplicationSubStatusEnum
      )"
    />
    <q-btn
      v-if="showToDraft(props.headerData)"
      class="status-button float-left"
      label="Вернуть на Черновик"
      icon-right="svguse:icons.svg?2#app-x-circle"
      :disable="loading"
      :loading="loading"
      unelevated
      no-caps
      @click="changeStatus(
        {
          id: props.headerData.id,
          applicationStatus: ApplicationSubStatusEnum.draft,
          initiator: 'align_risks'
        },
        props.headerData.sub_status as never as ApplicationSubStatusEnum
      )"
    />
    <application-control-center-additional-request-btn :header-info="props.headerData" />
    <div :class="classes">
      <div>{{ label(props.headerData.status as never as ApplicationStatusEnum) }}</div>
      <div class="flex justify-center">
        <q-icon
          v-if="props.headerData.sub_status === ApplicationSubStatusEnum.waiting_required"
          class="status-icon float-left"
          name="svguse:icons.svg?2#app-exclamation-circle-bold"
          color="orange"
        />
        <q-icon
          v-if="isFilledDraft(props.headerData)"
          class="status-icon float-left"
          name="svguse:icons.svg?2#app-check-circle-bold"
          color="green"
        />
        <q-icon
          v-if="isShowOrangeIcon()"
          class="status-icon float-left"
          name="svguse:icons.svg?2#app-exclamation-circle-bold"
          color="orange"
        />
        <q-icon
          v-if="isSignStatuses(props.headerData.sub_status as never as ApplicationSubStatusEnum)"
          class="status-icon float-left"
          name="svguse:icons.svg?2#app-pencil-circle-bold"
          color="orange"
        />
        <div class="date">{{ props.headerData.status_date }}</div>
        <q-tooltip
          v-if="props.stopFactors.length"
          class="bg-blue-grey-10 StopTooltip q-pa-md"
          :delay="700"
        >
          <stop-factors-result :model-value="props.stopFactors"/>
        </q-tooltip>
        <q-tooltip
          v-if="isSignStatuses(props.headerData.sub_status as never as ApplicationSubStatusEnum)"
          class="bg-blue-grey-10 StopTooltip q-pa-md"
          :delay="700"
        >
          <q-icon
            name="svguse:icons.svg?2#app-exclamation-circle"
            size="15px"
            color="orange"
          />
          По заявке требуется подписание документов клиентом
        </q-tooltip>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {computed} from 'vue'
import {ControlCenterHeader} from 'src/models/Applications/ControlCenter/ControlCenterHeader'
import {useStatus} from 'src/uses/Applications/useStatus'
import {
  useApplicationControlCenterChangeStatusStore
} from 'src/stores/Applications/ControlCenter/ChangeStatus/useApplicationControlCenterChangeStatusStore'
import {ApplicationFormDocuments,} from 'src/models/Applications/Form/ApplicationFormResponseData'
import {SelectBankFactor} from 'src/models/Applications/New/SelectBank'
import StopFactorsResult from 'src/components/Applications/StopFactorsResult.vue'
import {ApplicationSubStatusEnum} from 'src/models/Applications/ApplicationSubStatusEnum'
import {
  useApplicationControlCenterStatuses
} from 'src/uses/Applications/ControlCenter/useApplicationControlCenterStatuses'
import ApplicationControlCenterAdditionalRequestBtn
  from 'src/components/Applications/ApplicationControlCenter/ApplicationControlCenterAdditionalRequestBtn.vue'
import {storeToRefs} from 'pinia'
import {ApplicationStatusEnum} from 'src/models/Applications/ApplicationStatusEnum'

const props = defineProps<{
  loading: boolean,
  headerData: ControlCenterHeader,
  documents: ApplicationFormDocuments | null,
  stopFactors: SelectBankFactor[],
}>()

const {
  showClientReject,
  getSendRequestApplicationStatus,
  changeStatus,
  isFilledDraft,
  isCompletion,
  showToDraft,
} = useApplicationControlCenterChangeStatusStore(props.headerData?.id)()
const {loading} = storeToRefs(useApplicationControlCenterChangeStatusStore(props.headerData?.id)())

const {
  isSignStatuses
} = useApplicationControlCenterStatuses()

const isShowOrangeIcon = () => {
  return isCompletion(props.headerData) || props.headerData.new_request
}

const classes = computed(() => {
  return {
    [`control-center-status control-center-status__without_btn float-left bg-${bgColor(props.headerData.sub_status as never as ApplicationSubStatusEnum)}`]: !showClientReject(props.headerData)
    && !getSendRequestApplicationStatus(props.headerData)
    && !showToDraft(props.headerData),
    [`text-${color(props.headerData.sub_status as never as ApplicationSubStatusEnum)}`]: true,
    [`control-center-status float-left bg-${bgColor(props.headerData.sub_status as never as ApplicationSubStatusEnum)}`]: showClientReject(props.headerData)
    || getSendRequestApplicationStatus(props.headerData)
    || showToDraft(props.headerData)
  }
})

const {
  label,
  bgColor,
  color,
} = useStatus()
</script>

<style lang="scss">
.q-btn.status-button {
  background-color: #ECEFF1;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  color: #90A4AE;
  height: 50px;

  &:hover {
    background-color: #FF5252;
    color: #FFFFFF;
  }
}

.q-btn.requestButton {
  background-color: #ECEFF1;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  color: #90A4AE;
  height: 50px;

  &:hover {
    background-color: $orange-7;
    color: #FFFFFF;
  }
}

.control-center-status {
  min-width: 185px;
  height: 50px;
  background-color: #E3F2FD;
  color: #1E88E5;
  padding: 10px 26px;
  text-align: center;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;

  &.control-center-status__without_btn {
    border-radius: 6px;
  }

  &.green-color-status {
    background-color: #E8F5E9;
    color: #00C853;
  }

  &.red-color-status {
    background-color: #FFEBEE;
    color: #FF5252;
  }

  &.grey-color-status {
    background-color: #F5F5F5;
    color: #90A4AE;
  }

  .status-icon {
    margin-top: 3px;
    margin-right: 3px;
  }

  .date {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    margin-top: 3px;
  }
}
</style>
