<template>
  <div class="SettingsNewsCreateUpdate">
    <span class="text-h6 q-mb-md">
    {{ newsId ? 'Редактирование новостного материала' : 'Создание новостного материала' }}
  </span>
    <q-form v-if="newsCreateData">
      <div class="row q-col-gutter-lg q-mt-md white-bg-field">
        <div class="col-md-3 col-sm-4 col-xs-6">
          <q-select
            v-model="newsCreateData.publication_type"
            class="field-with-top-label"
            :options="publicationTypeForSelect"
            :loading="loading"
            map-options
            label="Публикация"
            outlined
            hide-hint
            hide-bottom-space
            dense
            emit-value
          />
        </div>
        <div class="col-md-3 col-sm-4 col-xs-6">
          <q-input
            :model-value="date"
            class="field-with-top-label"
            label="Дата публикации"
            outlined
            hide-bottom-space
            dense
            readonly
          >
            <template #append>
              <q-icon name="svguse:icons.svg?2#app-calendar" />
            </template>
          </q-input>
          <q-popup-proxy
            ref="pubDate"
            transition-show="scale"
            transition-hide="scale"
          >
            <q-date
              v-model="date"
              mask="DD.MM.YYYY"
              minimal
              @update:model-value="!!pubDate ? pubDate.hide() : undefined"
            />
          </q-popup-proxy>
        </div>
        <div class="col-md-3 col-sm-4 col-xs-6">
          <q-select
            v-model="time"
            class="field-with-top-label"
            :options="publicationTimeForSelect"
            :loading="loading"
            map-options
            label="Время публикации"
            outlined
            hide-hint
            hide-bottom-space
            dense
            emit-value
          />
        </div>
        <div class="col-md-3 col-sm-4 col-xs-6">
          <q-select
            v-model="newsCreateData.topic"
            class="field-with-top-label"
            :options="topicsForSelect"
            :loading="loading"
            map-options
            label="Тема публикации"
            outlined
            hide-hint
            hide-bottom-space
            dense
            emit-value
          />
        </div>
        <div class="col-md-12 col-sm-12 col-xs-12">
          <q-toggle
            v-model="newsCreateData.important"
          >
            <strong>Показать в модальном окне при входе</strong>
          </q-toggle>
        </div>
        <div class="col-12">
          <q-input
            v-model="newsCreateData.title"
            class="field-with-top-label"
            :loading="loading"
            :error-message="getErrorMessage('title')"
            :error="hasError('title')"
            label="Заголовок"
            outlined
            hide-hint
            hide-bottom-space
            dense
            @blur="validation.title.$validate()"
            @focus="clearError('title')"
          />
        </div>
        <div class="col-12 q-mb-md">
          <span>Текст новости</span>
          <q-editor
            v-model="newsCreateData.body"
            min-height="10rem"
            flat
            toolbar-bg="blue-7"
            toolbar-text-color="white"
            toolbar-toggle-color="yellow-8"
            :toolbar="[
              ['bold', 'italic', 'underline', 'left','center','right','justify','underline','strike','undo','redo'],
              [{
                label: $q.lang.editor.formatting,
                icon: $q.iconSet.editor.formatting,
                list: 'no-icons',
                options: ['p', 'h3', 'h4', 'h5', 'h6', 'code']
              }]
            ]"
          />
        </div>
        <div class="col-6 q-mb-md">
          <span class="text-bold">Документы</span>
          <q-list class="q-mt-sm q-mb-sm">
            <q-item
              v-for="document in documents"
              :key="document.id"
              class="news-document-item q-mt-xs"
            >
              <q-item-section avatar>
                <q-icon
                  name="svguse:icons.svg?2#app-article-bold"
                  color="blue-grey-3"
                />
              </q-item-section>
              <q-item-section class="overflow-hidden">{{ document.name }}</q-item-section>
              <q-item-section side>
                <q-icon
                  class="document-download-icon cursor-pointer"
                  name="svguse:icons.svg?2#app-trash"
                  color="blue-gray-3"
                  @click="removeDocument(document)"
                />
              </q-item-section>
            </q-item>
          </q-list>
          <settings-news-add-document-modal
            v-model="newDocument"
            v-model:document-name="newDocumentName"
            @change="addDocument()"
          />
        </div>
        <div class="col-6">
          <span class="text-bold">Изображение для обложки</span>
          <div
            v-if="hasError('picture')"
            class="text-red"
          >
            {{ getErrorMessage('picture') }}
          </div>
          <portal-sign-upload-with-preview
            :url="newsCreateData.picture as string"
            class="fit q-mt-sm"
            accept=".png, .jpg"
            help="Рекомендуемое разрешение - 955x500px."
            @file:selected="onLoadPicture"
            @change:src="newsCreateData.picture = $event"
          />
        </div>
      </div>
    </q-form>
  </div>
  <div class="row SettingsNewsCreateUpdate--footer">
    <div class="col-6">
      <q-btn
        :loading="loading"
        label="Показать изменения"
        class="text-blue-grey-5"
        color="for-blue-grey-5"
        style="padding: 12px 28px;"
        unelevated
        icon-right="svguse:icons.svg?2#app-desktop-computer"
        no-caps
        @click="open()"
      />
    </div>
    <div class="col-6 text-right">
      <q-btn
        v-close-popup
        :loading="loading"
        label="Отмена"
        class="q-pr-lg"
        style="padding: 12px 28px;"
        unelevated
        flat
        no-caps
        text-color="black"
        :to="{name: 'settings-news'}"
      />
      <q-btn
        :loading="loading"
        label="Сохранить изменения"
        color="primary"
        style="padding: 12px 28px;"
        unelevated
        icon-right="svguse:icons.svg?2#app-check"
        no-caps
        @click="handleSubmit()"
      />
    </div>

    <news-item-dialog
      v-model="dialog"
      :item="newsCreateData as NewsItemPreview"
      :documents="documents"
      preview
    />
  </div>
</template>

<script setup lang="ts">
import {useRoute} from 'vue-router'
import {computed, onBeforeMount, onBeforeUnmount, ref} from 'vue'
import {useNewsCreateStore} from 'src/stores/Settings/News/useNewsCreateStore'
import {storeToRefs} from 'pinia'
import {useValidation} from 'src/uses/useValidation'
import SettingsNewsAddDocumentModal from 'src/components/Settings/News/SettingsNewsAddDocumentModal.vue'
import PortalSignUploadWithPreview from 'components/Profile/PortalSign/PortalSignUploadWithPreview.vue'
import {useDialog} from 'src/uses/Dialogs/useDialog'
import NewsItemDialog from 'components/News/NewsItemDialog.vue'
import {QPopupProxy} from 'quasar'
import {NewsItemPreview} from 'src/models/News/NewsItemPreview'
const route = useRoute()
const newsId = computed(() => route.params.newsId)

const newsCreateStore = useNewsCreateStore()
const {
  newsCreateData,
  newDocument,
  newDocumentName,
  newPicture,
  documents,
  loading,
  topicsForSelect,
  publicationTypeForSelect,
  publicationTimeForSelect,
  validation,
  errors,
  date,
  time,
} = storeToRefs(newsCreateStore)

const {
  save,
  loadNewsItem,
  addDocument,
  removeDocument,
  getDataToSave,
} = newsCreateStore

const {
  getErrorMessage,
  hasError,
  clearError,
} = useValidation(validation, errors)

const {dialog, show} = useDialog()

const pubDate = ref<QPopupProxy>()

const open = async () => {
  show()
  newsCreateData.value = getDataToSave()
}

onBeforeMount(async () => {
  if (newsId.value) {
    await loadNewsItem(newsId.value as string)
  }
})

onBeforeUnmount(() => {
  newsCreateStore.$reset()
  newsCreateStore.$dispose()
})

const onLoadPicture = (file: File) => {
  newPicture.value = file
  clearError('picture')
}

const handleSubmit = async () => {
  await save()
}

</script>
<style lang="scss">
.SettingsNewsCreateUpdate {
  margin: -24px -24px 24px -24px;
  background: $blue-1;
  padding: 24px;
}
.SettingsNewsCreateUpdate--footer {
  background-color: #D5EAFA;
  padding: 24px;
  margin: -24px;
}
.news-document-item {
  background-color: #FFFFFF;
  border: none;
}
</style>
