import {useGetApi} from 'src/uses/Api/useGetApi'
import {computed, ref} from 'vue'

export const useLoginStatus = () => {
  const {
    loading,
    get,
    errors,
    error,
    status,
    response,
  } = useGetApi<{status: 'inactive' | string}>()

  const loginStatus = ref<{status: 'inactive' | string}>()

  const inactive = computed(() => {
    if (!loginStatus.value) {
      return false
    }

    return loginStatus.value?.status === 'inactive'
  })

  const getLoginStatus = async (email: string) => {
    await get(`v1/user/status?email=${email}`)

    if (status.value === 200 && response.value) {
      loginStatus.value = response.value
    }
  }

  return {
    loginStatus,
    loading,
    errors,
    error,
    status,
    inactive,
    getLoginStatus,
  }
}
