<template>
  <q-form class="q-pt-sm">
    <div v-if="productId" class="row q-col-gutter-sm white-bg-field">
      <div class="col-3 q-mb-sm">
        <q-input
          v-model="productTariffUpdateData.value"
          :loading="loading"
          class="field-with-top-label"
          label="Сумма тарифа (₽)"
          type="number"
          outlined
          hide-hint
          hide-bottom-space
          dense
          :disable="isFreeTariff"
        />
      </div>
      <div class="col-3">
        <q-toggle
          v-model="isFreeTariff"
          label="Бесплатно"
          class="q-mr-md"
          color="blue-7"
        />
      </div>
    </div>
  </q-form>
</template>

<script setup lang="ts">
import {useProductsTariffControlStore} from 'stores/Products/Tariff/useProductsTariffControlStore'
import {storeToRefs} from 'pinia'

const props = defineProps<{
  productId: string
}>()

const productsTariffControlStore = useProductsTariffControlStore(props.productId)()
const {
  productTariffUpdateData,
  loading,
  isFreeTariff,
} = storeToRefs(productsTariffControlStore)

</script>

<style lang="scss">
</style>
