<template>
  <div
    class="col-sm col-xs-12 bannerMainContent"
  >
    Вы можете восстановить заявку для повторной отправки в банк.
  </div>
  <div>
    <q-btn
      class="banner-btn"
      label="Восстановить"
      color="primary"
      :disabled="loading"
      :loading="loading"
      unelevated
      no-caps
      @click="onClick"
    />
  </div>
</template>

<script setup lang="ts">
import {ControlCenterHeader} from 'src/models/Applications/ControlCenter/ControlCenterHeader'
import {useDuplication} from 'src/uses/Applications/useDuplication'
import {useRouter} from 'vue-router'

const props = defineProps<{
  headerInfo: ControlCenterHeader,
}>()

const {
  loading,
  status,
  response,
  duplicate,
} = useDuplication()

const router = useRouter()

const onClick = async () => {
  await duplicate(props.headerInfo.id)

  if (status.value !== 201 || !response.value) {
    return
  }

  await router.push({
    name: 'application-item',
    params: {
      formId: response.value.multi_order_id
    }
  })
}
</script>
