<template>
  <q-tab
    :name="getId(props.period)"
    :class="classes"
  >
    {{ getLabel(props.period) }}
    <q-icon
      v-if="isOk()"
      name="svguse:icons.svg?2#app-check"
      size="sm"
    />
  </q-tab>
</template>

<script setup lang="ts">
import {computed, withDefaults} from 'vue'
import {FillStatusEnum} from 'src/models/Enums/FillStatusEnum'
import {ApplicationFormFinancePeriod} from 'src/models/Applications/Form/ApplicationFormResponseData'
import {usePeriod} from 'src/uses/Applications/Form/Finance/usePeriod'

const props = withDefaults(
  defineProps<{
    period: ApplicationFormFinancePeriod,
    periodsWithErrors?: number[]
  }>(),
  {
    periodsWithErrors: () => []
  }
)

const {
  getId,
  getLabel,
} = usePeriod()

const classes = computed(() => {
  return {
    [`ApplicationItemFinanceTab--${FillStatusEnum.OK}`]: isOk(),
    [`ApplicationItemFinanceTab--${FillStatusEnum.ERROR}`]: false,
    [`ApplicationItemFinanceTab--${FillStatusEnum.IN_PROGRESS}`]: true,
    ApplicationItemFinanceTab: true,
  }
})

const isOk = () => {
  return !props.periodsWithErrors.includes(getId(props.period))
}

</script>

<style lang="scss">

.ApplicationItemFinanceTab {
  opacity: 1;

  &.ApplicationItemFinanceTab--in_progress {
    color: $grey-9;
  }

  &.ApplicationItemFinanceTab--error {
    color: $orange;
  }

  &.ApplicationItemFinanceTab--ok {
    color: $green-14;
  }
}
</style>
