import {usePostApi} from 'src/uses/Api/usePostApi'
import {FormItemField} from 'src/models/FormEditor/FormItemResponseData'
import {FormItemAddFieldData} from 'src/models/FormEditor/FormItemAddFieldData'

export const useFormItemAddFields = () => {
  const {
    post,
    response,
    status,
    loading,
    error,
    errors,
  } = usePostApi<FormItemAddFieldData, FormItemAddFieldData>()

  const add = async (
    formId: string,
    sectionId: string,
    fields: FormItemField[],
    beforeFieldId?: string
  ) => {
    const data = {
      section_id: sectionId,
      before_field_id: beforeFieldId,
      fields: fields.map(f => {
        return {
          id: f.id
        }
      }),
    }
    await post(`v2/forms/${formId}/sections/${sectionId}/fields`, data)
  }

  return {
    loading,
    response,
    status,
    error,
    errors,
    add,
  }
}
