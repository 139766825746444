<template>
  <right-sidebar-drawer>
    <template
      #mini="{toggle}"
    >
      <div class="q-mb-xs">
        <q-btn
          align="left"
          size="14px"
          color="blue-grey-10"
          unelevated
          @click="toggle"
        >
          <q-icon
            name="svguse:icons.svg?2#app-chevron-double-left"
            color="white"
            size="14px"
          />
        </q-btn>
      </div>
      <div
        class="q-mb-xs"
      >
        <q-btn
          v-if="kikIsOk || ebgIsOk"
          align="left"
          size="14px"
          color="green-14"
          unelevated
          @click="create"
        >
          <q-icon
            name="svguse:icons.svg?2#app-arrow-right-bold"
            color="white"
            size="14px"
          />
        </q-btn>
      </div>
    </template>
    <template
      #default="{toggle}"
    >
      <div
        v-if="inn && selectedTabs.length"
        class="ApplicationsNewSidebar text-white bg-blue-grey-10 q-pb-sm"
      >
        <div class="ApplicationsNewSidebar--head row items-center q-pt-sm q-pl-sm">
          <div>
            <q-btn
              align="left"
              size="14px"
              color="blue-grey-10"
              class="q-mr-sm"
              padding="sm"
              unelevated
              @click="toggle"
            >
              <q-icon
                name="svguse:icons.svg?2#app-chevron-double-right"
                color="white"
                size="18px"
              />
            </q-btn>
          </div>
          <div
            class="col ellipsis text-no-wrap"
            style="max-width: 200px"
          >
            <div class="ellipsis text-no-wrap">
              {{ inn.name }}
              <q-tooltip
                anchor="top middle"
                self="bottom middle"
              >
                {{ inn.name }}
              </q-tooltip>
            </div>
            <div>ИНН {{ inn.inn }}</div>
          </div>
        </div>
        <template v-if="hasEBG">
          <div class="ApplicationsNewSidebar--content q-ma-sm bg-blue-grey-9">
            <div class="row items-center q-pa-sm ApplicationsNewSidebar--content--head">
              <q-icon
                size="18px"
                name="svguse:icons.svg?2#ebg-side"
              />
              <div class="col q-pl-xs">
                ЭБГ/{{ percentage }}%
              </div>
              <div class="col text-right">
                <q-btn
                  v-if="hasEBG"
                  icon="svguse:icons.svg?2#app-trash"
                  padding="xs"
                  unelevated
                  @click="removeProduct(ProductEnum.EBG)"
                >
                  <q-tooltip>Удалить продукт</q-tooltip>
                </q-btn>
              </div>
            </div>
            <div class="ApplicationsNewSidebar--content--data q-gutter-sm q-py-sm q-px-sm">
              <div
                v-if="rnt || rntNotInEISReason"
                class="row"
              >
                <div class="col-3">
                  РНТ
                </div>
                <div class="col">
                  <template v-if="rnt">
                    {{ rnt }}
                  </template>
                  <template v-else-if="rntNotInEISReason">
                    {{ getRntNotInEISReasonLabel(rntNotInEISReason) }}
                  </template>
                </div>
              </div>
              <div
                v-if="law"
                class="row"
              >
                <div class="col-3">
                  ФЗ
                </div>
                <div class="col">
                  {{ getLawLabel(law) }}
                  <template v-if="provisionType">
                    / {{ getProvisionTypeLabel(provisionType) }}
                  </template>
                </div>
              </div>
              <div
                v-if="dateFrom && dateTo"
                class="row"
              >
                <div class="col-3">
                  Срок
                </div>
                <div class="col">
                  {{ dateFrom }} - {{ dateTo }}, {{ countDays }}д.
                </div>
              </div>
              <div
                v-if="bgSum"
                class="row"
              >
                <div class="col-3">
                  Сумма
                </div>
                <div class="col">
                  <money-component :model-value="bgSum" />
                </div>
              </div>
              <div
                v-if="selectedProducts.length"
                class="row"
              >
                <div class="col-3">
                  Банки
                </div>
                <div class="col">
                  <q-chip
                    v-for="product in selectedProducts"
                    :key="product.id"
                    color="blue-grey-8"
                    class="text-white"
                    size="12px"
                  >
                    {{ product.bank_title_short }}
                  </q-chip>
                </div>
              </div>
            </div>
            <div style="height: 1px;" />
            <div
              v-if="!allProductsOk && ebgIsOk"
              class="sendButton q-pa-sm q-mt-md"
            >
              <q-btn
                class="full-width"
                align="left"
                color="green-14"
                size="14px"
                style="padding: 10px 16px;"
                unelevated
                no-caps
                @click="create"
              >
                <strong>Разместить заявку</strong>
                <span class="icon">
                  <q-icon
                    name="svguse:icons.svg?2#app-arrow-right-bold"
                    color="green-14"
                    size="18px"
                    style="padding: 5.5px;"
                  />
                </span>
              </q-btn>
            </div>
            <div
              v-if="selectedProducts.length === 0 && percentage === 100"
              class="q-px-sm q-pb-sm"
            >
              <div class="text-orange-6 bg-orange-1 rounded-borders q-mt-sm q-pa-md">
                <div class="row items-center">
                  <q-icon
                    size="sm"
                    color="orange-6"
                    name="svguse:icons.svg?2#app-information-circle"
                  />
                  <div class="col q-pl-sm">
                    <strong>Состояние системы</strong>
                  </div>
                </div>
                <div class="q-mt-md">
                  Для успешного размещения заявки необходимо выбрать хотя бы один банк
                </div>
                <q-btn
                  class="q-mt-md full-width"
                  label="Показать"
                  color="orange-6"
                  icon-right="svguse:icons.svg?2#app-cursor-click"
                  padding="10px 16px"
                  unelevated
                  no-caps
                  @click="showEbgProducts"
                />
              </div>
            </div>
          </div>
        </template>

        <template v-if="hasKIK">
          <div class="ApplicationsNewSidebar--content q-ma-sm bg-blue-grey-9">
            <div class="row items-center q-pa-sm ApplicationsNewSidebar--content--head">
              <q-icon
                size="18px"
                name="svguse:icons.svg?2#app-credit-card"
              />
              <div class="col q-pl-xs">
                Кредит/{{ creditPercentage }}%
              </div>
              <div class="text-right">
                <q-btn
                  v-if="hasKIK"
                  icon="svguse:icons.svg?2#app-trash"
                  padding="xs"
                  unelevated
                  @click="removeProduct(ProductEnum.KIK)"
                >
                  <q-tooltip>Удалить продукт</q-tooltip>
                </q-btn>
              </div>
            </div>
            <div class="ApplicationsNewSidebar--content--data q-gutter-sm q-py-sm q-px-sm">
              <div
                v-if="creditAmount"
                class="row"
              >
                <div class="col-3">
                  Сумма
                </div>
                <div class="col">
                  <template v-if="creditAmount">
                    <money-component :model-value="creditAmount" />
                  </template>
                </div>
              </div>
              <div
                v-if="creditMonths"
                class="row"
              >
                <div class="col-3">
                  Месяцев
                </div>
                <div class="col">
                  <template v-if="creditMonths">
                    {{ creditMonths }}
                  </template>
                </div>
              </div>
              <div
                v-if="creditType"
                class="row"
              >
                <div class="col-3">
                  Тип
                </div>
                <div class="col">
                  <template v-if="creditType">
                    {{ getCreditTypeLabel(creditType) }}
                  </template>
                </div>
              </div>
              <div
                v-if="creditSelectedProducts.length"
                class="row"
              >
                <div class="col-3">
                  Банки
                </div>
                <div class="col">
                  <q-chip
                    v-for="product in creditSelectedProducts"
                    :key="product.id"
                    color="blue-grey-8"
                    class="text-white"
                    size="12px"
                  >
                    {{ product.bank_title_short }}
                  </q-chip>
                </div>
              </div>
            </div>
            <div style="height: 1px;" />
            <div
              v-if="!allProductsOk && kikIsOk"
              class="sendButton q-pa-sm q-mt-md"
            >
              <q-btn
                class="full-width"
                align="left"
                color="green-14"
                size="14px"
                style="padding: 10px 16px;"
                unelevated
                no-caps
                @click="create"
              >
                <strong>Разместить заявку</strong>
                <span class="icon">
                  <q-icon
                    name="svguse:icons.svg?2#app-arrow-right-bold"
                    color="green-14"
                    size="18px"
                    style="padding: 5.5px;"
                  />
                </span>
              </q-btn>
            </div>
            <div
              v-if="creditSelectedProducts.length === 0 && creditPercentage === 100"
              class="q-px-sm q-pb-sm"
            >
              <div class="text-orange-6 bg-orange-1 rounded-borders q-mt-sm q-pa-md">
                <div class="row items-center">
                  <q-icon
                    size="sm"
                    color="orange-6"
                    name="svguse:icons.svg?2#app-information-circle"
                  />
                  <div class="col q-pl-sm">
                    <strong>Состояние системы</strong>
                  </div>
                </div>
                <div class="q-mt-md">
                  Для успешного размещения заявки необходимо выбрать хотя бы один банк
                </div>
                <q-btn
                  class="q-mt-md full-width"
                  label="Показать"
                  color="orange-6"
                  icon-right="svguse:icons.svg?2#app-cursor-click"
                  padding="10px 16px"
                  unelevated
                  no-caps
                  @click="showKikProducts"
                />
              </div>
            </div>
          </div>
        </template>
        <div class="ApplicationsNewSidebar--content q-ma-sm bg-blue-grey-9">
          <div
            v-if="allProductsOk"
            class="sendButton q-pa-sm q-mt-md"
          >
            <q-btn
              class="full-width"
              align="left"
              color="green-14"
              size="14px"
              style="padding: 10px 16px;"
              unelevated
              no-caps
              @click="create"
            >
              <strong>Разместить заявку</strong>
              <span class="icon">
                <q-icon
                  name="svguse:icons.svg?2#app-arrow-right-bold"
                  color="green-14"
                  size="18px"
                  style="padding: 5.5px;"
                />
              </span>
            </q-btn>
          </div>
        </div>
      </div>

      <div
        v-else
        class="help-menu-wrapper bg-blue-grey-10"
      >
        <div class="row justify-between q-pb-sm q-pt-sm q-pl-md q-pr-md">
          <div class="menu-title">
            <q-icon
              size="sm"
              class="q-mr-xs"
              name="svguse:icons.svg?2#app-support"
            />
            Помощь
          </div>
          <q-btn
            align="left"
            size="14px"
            color="blue-grey-10"
            style="margin-right: -5px;"
            padding="sm"
            unelevated
            @click="toggle"
          >
            <q-icon
              name="svguse:icons.svg?2#app-chevron-double-left"
              color="white"
              size="18px"
            />
          </q-btn>
        </div>
        <help-menu />
      </div>
    </template>
  </right-sidebar-drawer>
</template>

<script setup lang="ts">
import RightSidebarDrawer from 'src/components/RightSidebarDrawer.vue'
import MoneyComponent from 'src/components/MoneyComponent.vue'
import {storeToRefs} from 'pinia'
import {useRntNotInEISReason} from 'src/uses/Applications/useRntNotInEISReason'
import {useLaw} from 'src/uses/Applications/useLaw'
import {useProvisionType} from 'src/uses/Applications/useProvosoinType'
import {useApplicationsNew} from 'src/stores/Applications/ApplicationsNew/useApplicationsNew'
import HelpMenu from 'src/components/Help/HelpMenu.vue'
import {useHelpStore} from 'src/stores/Help/useHelpStore'
import {computed, onBeforeMount, onBeforeUnmount} from 'vue'
import {ProductEnum} from 'src/models/Applications/ProductEnum'
import {useCreditType} from 'src/uses/Applications/useCreditType'
import {useScrollTo} from 'src/uses/useScrollTo'

const applicationsNewStore = useApplicationsNew()
const {
  creditPercentage,
  percentage,
  selectedProducts,
  creditSelectedProducts,
  rnt,
  rntNotInEISReason,
  law,
  provisionType,
  dateFrom,
  dateTo,
  countDays,
  bgSum,
  rntError,
  selectedTabs,
  creditAmount,
  creditType,
  creditMonths,
  tab,
  creditProductsRef,
  productsRef,
  innSelected: inn,
  hasEBG,
  hasKIK,
} = storeToRefs(applicationsNewStore)

const {
  create,
  removeProduct,
} = applicationsNewStore

const {
  getRntNotInEISReasonLabel,
} = useRntNotInEISReason()

const {
  getCreditTypeLabel,
} = useCreditType()

const {
  getLawLabel,
} = useLaw()

const {
  getProvisionTypeLabel,
} = useProvisionType()

const helpStore = useHelpStore()

const {load} = helpStore

const {
  scrollToInvisibleElement,
} = useScrollTo()

const ebgIsOk = computed(() => {
  if (!hasEBG.value) {
    return true
  }

  return percentage.value === 100
    && selectedProducts.value.length > 0
    && !rntError.value
})

const kikIsOk = computed(() => {
  if (!hasKIK.value) {
    return true
  }

  return creditPercentage.value === 100
    && creditSelectedProducts.value.length > 0
})

const allProductsOk = computed(() => {
  return kikIsOk.value && ebgIsOk.value
})

const showKikProducts = () => {
  tab.value = ProductEnum.KIK
  setTimeout(() => {
    if (!creditProductsRef.value) {
      return
    }

    scrollToInvisibleElement(creditProductsRef.value.$el)
  }, 50)
}

const showEbgProducts = () => {
  tab.value = ProductEnum.EBG
  setTimeout(() => {
    if (!productsRef.value) {
      return
    }

    scrollToInvisibleElement(productsRef.value.$el)
  }, 50)
}

onBeforeMount(async () => {
  await load()
})

onBeforeUnmount(() => {
  helpStore.$reset()
  helpStore.$dispose()
})

</script>

<style lang="scss">
.ApplicationsNewSidebar {
  border-radius: 8px;
  width: 100%;
  position: relative;

  .ApplicationsNewSidebar--head {
    font-size: 12px;
    line-height: 14px;
  }

  .ApplicationsNewSidebar--content {
    border-radius: 6px;
    margin-bottom: 0;

    .ApplicationsNewSidebar--content--head {
      font-weight: 700;
      font-size: 14px;
      border-bottom: 1px solid $blue-grey-8;
    }

    .ApplicationsNewSidebar--content--data {
      line-height: 20px;

      .col-3 {
        color: $blue-grey-4;
        font-size: 12px;
        width: 20% !important;
      }
      .col {
        text-align: right;
        font-size: 14px;
        font-weight: 700;
      }
    }

    .sendButton {
      .icon {
        background: white;
        border-radius: 6px;
        position: absolute;
        right: 10px;
        line-height: 0;
      }

      .q-btn {
        border-radius: 6px;
      }
    }
  }
}
</style>
