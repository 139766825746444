<template>
  <div
    class="chat-wrap fit column"
  >
    <div class="col">
      <div
        v-if="chat.length !== 0"
        ref="chatMessages"
        class="chat-room fit"
      >
        <div ref="chatHeight">
          <div
            v-for="day in chat"
            :key="day.day"
          >
            <div class="chat-messages-day text-center">
              <q-chip>{{ getFormatDate(day.day) }}</q-chip>
            </div>
            <div
              v-for="mess in getMessagesByDay(day)"
              :key="mess.date"
              class="q-ma-md"
            >
              <q-chat-message
                v-if="!hasDocuments(mess)"
                :text="[prepareMessage(mess.text)]"
                :sent="mess.is_mine"
                :stamp="getMessageTime(mess)"
                text-html
              >
                <template #name>
                  <div class="row items-center">
                    <q-icon
                      size="14px"
                      :name="getIconByAuthorType(mess.author_type || '')"
                    />
                    <div>
                      {{ mess.author_name }}
                    </div>
                  </div>
                </template>
              </q-chat-message>
              <q-chat-message
                v-else
                :text="[prepareMessage(mess.text)]"
                :sent="mess.is_mine"
                :stamp="getMessageTime(mess)"
                text-html
              >
                <template #name>
                  <div class="row items-center">
                    <q-icon
                      size="14px"
                      :name="getIconByAuthorType(mess.author_type || '')"
                    />
                    <div>
                      {{ mess.author_name }}
                    </div>
                  </div>
                </template>
                <div
                  class="chat-message-document-download-link"
                  @click="onDownloadDocument(mess)"
                >
                  <q-icon
                    name="svguse:icons.svg?2#app-article-bold"
                    class="chat-message-document-icon"
                  />
                  {{ (getDocument(mess) || {}).title }}
                </div>
              </q-chat-message>
            </div>
          </div>
        </div>
      </div>
      <div
        v-else
        class="row fit items-center q-pa-md chat-room-no-messages"
        style="overflow-y: scroll; min-height: 100%;"
      >
        <div class="q-pa-md chat-room-no-messages full-width">
          <q-img
            src="../../assets/chat_no_mess_cloud.svg"
            class="no-chat-mess-cloud"
          />
          <div class="q-mt-lg">Тут пока нет сообщений :(</div>
        </div>
      </div>
    </div>
    <div class="chat-actions">
      <slot name="default" />
    </div>
  </div>
</template>

<script setup lang="ts">
import {computed, ref, watchEffect, nextTick} from 'vue'
import {Chat, ChatMessage} from 'src/models/Chat'
import {useChat} from 'src/uses/Chat/useChat'
import {useChatUploadDocuments} from 'src/uses/Chat/useChatUploadDocuments'

const props = defineProps<{
  chat: Chat[],
  appId: string,
}>()

const chat = computed(() => props.chat)
const chatMessages = ref<HTMLDivElement>()
const firstScroll = ref(true)
const chatHeight = ref<HTMLDivElement>()

watchEffect(async () => {
  if (chat.value && chatMessages.value && chatMessages.value?.scrollTop !== undefined) {
    await nextTick()
    if (!firstScroll.value) {
      chatMessages.value.scrollTo({
        top: chatHeight.value?.clientHeight || 0,
        left: 0,
        behavior: 'smooth',
      })

      return
    }

    chatMessages.value.scrollTop = chatHeight.value?.clientHeight || 0
    firstScroll.value = false
  }
})

const {
  getMessageTime,
  getMessagesByDay,
  getFormatDate,
  prepareMessage,
  getIconByAuthorType,
} = useChat()

const {
  hasDocuments,
  getDocument,
  downloadDocument,
  convertChatDocumentToApplicationDocument,
} = useChatUploadDocuments(props.appId)

const onDownloadDocument = (mess: ChatMessage) => {
  const document = getDocument(mess)
  if (!document) {
    return
  }

  downloadDocument(
    props.appId,
    convertChatDocumentToApplicationDocument(document),
    document.type
  )
}

</script>

<style lang="scss">
.chat-wrap {
  background-color: #FFFFFF;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.chat-actions {
}

.chat-room {
  background-color: #ffffff;
  padding: 0 10px;
  min-height: 300px !important;
  max-height: 100%;
  overflow-y: scroll;
  @media only screen and (pointer: coarse) {
    max-height: calc(85vh - 250px);
  }

  .chat-message-document-download-link {
    cursor: pointer;
  }

  .chat-message-document-icon {
    color: #B0BEC5;
    height: 16px;
    margin-top: -3px;
  }

  .chat-messages-day {
    .q-chip__content {
      font-weight: 400;
      font-size: 12px;
      line-height: 12px;
    }
  }

  .q-message-text {
    padding: 10px 15px 15px 15px;
  }

  .q-message-received {
    .q-message-name--received {
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 17px;
      color: #1E88E5;
      margin-bottom: 2px;
    }

    .q-message-text--received {
      background: #E3F2FD;
      border-radius: 14px 14px 14px 0;
    }

    .q-message-text:last-child:before {
      content: none;
    }

    .q-message-stamp {
      color: #64B5F6;
    }
  }

  .q-message-sent {
    .q-message-name--sent {
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 17px;
      color: #424242;
      margin-bottom: 3px;
    }

    .q-message-text--sent {
      background: #F5F5F5;
      border-radius: 14px 14px 0 14px;
    }

    .q-message-text:last-child:before {
      content: none;
    }

    .q-message-stamp {
      color: #90A4AE;
    }
  }

}

.no-active-chat {
  .no-active-chat-banner-info {
    background: #263238;
    color: #ffffff;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    margin: 0 -14px 0 -14px;
    padding: 10px 20px;
    border-radius: 5px;

    .icon-no-active-chat-banner-info-info {
      background: #37474F;
      border-radius: 6px;
      padding: 14px;
    }

    .icon-no-active-chat-info {
      margin-top: 5px;
    }
  }
}

.chat-room-no-messages {
  max-height: 450px;
  @media only screen and (pointer: coarse) {
    max-height: calc(85vh - 250px);
  }
  background: #FFFFFF;
  text-align: center;

  .no-chat-mess-cloud {
    max-width: 150px;
  }
}
</style>
