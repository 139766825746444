<template>
  <div
    class="col-sm col-xs-12 bannerMainContent"
  >
    <div>
      <div v-if="showWaitingRequiredMessage">
        Фактический статус заявки - "Требуется подпись". Нажмите здесь
        <q-btn
          class="text-link-button"
          label="здесь"
          size="xs"
          :loading="loading"
          @click="changeStatus(
        {
          id: headerInfo.id,
          applicationStatus: ApplicationSubStatusEnum.new,
          initiator: ApplicationSubStatusEnum.new
        },
        headerInfo.sub_status as ApplicationSubStatusEnum
      )"
        />
        для смены статуса
      </div>
      <template v-if="(headerInfo.waiting_completion_orders || []).length === 0">
        Заявка находится в ожидании ссылки
      </template>
      <template v-else>
        Заявка находится в ожидании исполнения доп. запроса по связанным заявкам:
      </template>
    </div>
    <template v-if="(headerInfo.waiting_completion_orders || []).length > 0">
      <q-btn
        v-for="item in (props.headerInfo.waiting_completion_orders || [])"
        :key="item.number"
        class="text-link-button q-mr-sm"
        :label="item.number"
        size="sm"
        :to="{name: 'application-control-center', params: {appId: item.id, formId: item.id}}"
      />
    </template>
  </div>
</template>

<script setup lang="ts">
import {ControlCenterHeader} from 'src/models/Applications/ControlCenter/ControlCenterHeader'
import {ApplicationSubStatusEnum} from 'src/models/Applications/ApplicationSubStatusEnum'
import {
  useApplicationControlCenterChangeStatusStore
} from 'stores/Applications/ControlCenter/ChangeStatus/useApplicationControlCenterChangeStatusStore'
import {storeToRefs} from 'pinia'
import {useProfileStore} from 'stores/Login/useProfileStore'
import {computed} from 'vue'

const props = defineProps<{
  headerInfo: ControlCenterHeader,
}>()

const {
  changeStatus,
} = useApplicationControlCenterChangeStatusStore(props.headerInfo.id)()
const {loading} = storeToRefs(useApplicationControlCenterChangeStatusStore(props.headerInfo.id)())

const {
  isAdmin,
  isAgentManager
} = useProfileStore()

const showWaitingRequiredMessage = computed(() => {
  return props.headerInfo.waiting_required
    && (isAdmin() || isAgentManager())
    && props.headerInfo.sub_status === ApplicationSubStatusEnum.waiting_required
    && props.headerInfo.integration.enabled
})
</script>
