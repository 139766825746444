import {ApplicationKikSubStatusEnum} from 'src/models/Applications/ApplicationKikSubStatusEnum'

export const useKikChangeStatusAction = () => {
  const isDraft = (subStatus: ApplicationKikSubStatusEnum) => {
    return subStatus === ApplicationKikSubStatusEnum.draft
  }

  const isBankRejected = (subStatus: ApplicationKikSubStatusEnum) => {
    return subStatus === ApplicationKikSubStatusEnum.bank_rejected
  }

  const isSystemRejected = (subStatus: ApplicationKikSubStatusEnum) => {
    return subStatus === ApplicationKikSubStatusEnum.system_rejected
  }

  const isArchive = (subStatus: ApplicationKikSubStatusEnum) => {
    return subStatus === ApplicationKikSubStatusEnum.archive
  }

  const isClientRejected = (subStatus: ApplicationKikSubStatusEnum) => {
    return subStatus === ApplicationKikSubStatusEnum.client_rejected
  }

  const showClientReject = (subStatus: ApplicationKikSubStatusEnum) => {
    return ![
      ApplicationKikSubStatusEnum.client_rejected,
      ApplicationKikSubStatusEnum.bank_rejected,
      ApplicationKikSubStatusEnum.system_rejected,
      ApplicationKikSubStatusEnum.archive,
      ApplicationKikSubStatusEnum.released,
    ].includes(subStatus)
  }

  const showSendToCheck = (subStatus: ApplicationKikSubStatusEnum, filled: boolean) => {
    return isDraft(subStatus)
      && filled
  }

  const showStatusChange = (subStatus: ApplicationKikSubStatusEnum, filled: boolean) => {
    return showClientReject(subStatus)
      || showSendToCheck(subStatus, filled)
  }

  return {
    isDraft,
    isBankRejected,
    isSystemRejected,
    isArchive,
    isClientRejected,
    showClientReject,
    showSendToCheck,
    showStatusChange,
  }
}
