<template>
  <div class="text-h6 q-mb-md">
    Основные параметры
  </div>
  <form-item-copy-delete-fields
    :form-id="props.formId"
    :section-id="main.id"
    :block-id="props.order.id"
    :can-copy="main.fields.length === 0"
    @change="updateFields"
  />
  <div class="q-py-lg">
    <form-item-fields-block-component
      :fields="main.fields"
      :section-id="main.id"
      :form-id="props.formId"
      @change="updateFields"
    />
  </div>
</template>

<script setup lang="ts">
import {computed} from 'vue'
import {FormItemOrderBlock} from 'src/models/FormEditor/FormItemResponseData'
import FormItemCopyDeleteFields from 'components/FormEditor/FormItemCopyDeleteFields.vue'
import FormItemFieldsBlockComponent from 'components/FormEditor/FormItemFieldsBlockComponent.vue'

const emit = defineEmits<{
  (e: 'updateFields'): void,
}>()

const props = defineProps<{
  order: FormItemOrderBlock,
  formId: string,
}>()

const main = computed(() => props.order.order_main)

const updateFields = () => {
  emit('updateFields')
}

</script>
