<template>
  <application-grid-only-rnt
    :item="props.item"
    :only-text="props.onlyText"
    :mobile="props.mobile"
  />

  <div :class="{'row q-col-gutter-sm': props.mobile, 'no-wrap': props.mobile}">
    <div>
      {{ getLawLabel(item.obligation_type) }}
    </div>
    <div>
      {{ getProvisionTypeLabel(item.provision_type) }}
    </div>
  </div>
</template>

<script setup lang="ts">
import {computed, withDefaults} from 'vue'
import {ApplicationsGridItem} from 'src/models/Applications/ApplicationsGridItem'
import {useLaw} from 'src/uses/Applications/useLaw'
import {useProvisionType} from 'src/uses/Applications/useProvosoinType'
import ApplicationGridOnlyRnt from 'components/Applications/ApplicationsGrid/Columns/ApplicationGridOnlyRnt.vue'

const {getLawLabel} = useLaw()
const {getProvisionTypeLabel} = useProvisionType()

const props = withDefaults(
  defineProps<{
    item: ApplicationsGridItem
    mobile?: boolean
    onlyText?: boolean
  }>(),
  {
    mobile: false,
    onlyText: false,
  }
)
const item = computed(() => props.item)
</script>
