<template>
  <page-block-wrapper>
    <div class="profile-wrapper">
      <profile-settings />
    </div>
  </page-block-wrapper>
</template>
<script setup lang="ts">
import ProfileSettings from 'src/components/Profile/Settings/ProfileSettings.vue'
import {useMetaStore} from 'src/stores/useMetaStore'
import PageBlockWrapper from 'components/PageBlockWrapper.vue'

useMetaStore().setTitle('Настройки')
</script>
