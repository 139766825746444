<template>
  <form-group>
    <q-field
      ref="inputRef"
      :model-value="creditMonths"
      class="field-with-top-label overflow-label-visible q-mb-sm"
      label="Месяцев"
      debounce="700"
      hint="от 1 до 100 мес."
      outlined
      hide-bottom-space
      dense
      @update:model-value="updateModelValue"
    >
      <template #control="{ id, floatingLabel, modelValue, emitValue }">
        <input
          v-show="floatingLabel"
          class="q-field__input"
          :id="id"
          :value="modelValue"
          @input="onInput"
          @change="e => emitValue((e.target as HTMLInputElement).value)"
        >
      </template>
    </q-field>
  </form-group>
</template>

<script setup lang="ts">
import FormGroup from 'src/components/FormGroup.vue'
import {storeToRefs} from 'pinia'
import {ref, watch} from 'vue'
import {useApplicationsNew} from 'src/stores/Applications/ApplicationsNew/useApplicationsNew'
import {QInput} from 'quasar'
import {useScrollTo} from 'src/uses/useScrollTo'

const applicationsNewStore = useApplicationsNew()
const {
  creditMonths,
  creditLastFocused,
} = storeToRefs(applicationsNewStore)

const {
  scrollToInvisibleElement,
} = useScrollTo()

const inputRef = ref<QInput>()
const prevValue = ref<number>()

const onInput = (event: Event) => {
  const input = event.target as HTMLInputElement

  const value = input.value
    .replace(/\D/gi, '')

  if (value === '' || value === '0') {
    input.value = ''
    return
  }

  const numberValue = parseInt(value)

  if (isNaN(numberValue) || numberValue > 100 || numberValue < 1) {
    input.value = prevValue.value !== undefined
      ? prevValue.value.toString()
      : ''

    return
  }

  input.value = value.toString()
  prevValue.value = numberValue
}

const updateModelValue = (value: string) => {
  const numberValue = parseInt(value)
  creditMonths.value = !isNaN(numberValue) ? numberValue : undefined
}

watch(
  creditLastFocused,
  () => {
    if (creditLastFocused.value !== 'creditMonths' || !inputRef.value) {
      return
    }

    scrollToInvisibleElement(inputRef.value.$el)
    inputRef.value.focus()
    setTimeout(() => inputRef.value?.focus(), 500)
  }
)
</script>
