<template>
  <q-layout
    class="LoginMobileLayout"
    view="hHh LpR lff"
  >
    <router-view name="header" />
    <q-page-container>
      <q-page class="vertical-middle">
        <div class="LoginWrapper">
          <div
            class="row bg-white full-width"
            style="flex-direction: column; min-height: 100%; position: absolute;"
          >
            <div class="LoginInner col">
              <router-view />
            </div>
            <div
              v-if="!$q.platform.is.nativeMobileWrapper"
              class="LoginFooter"
            >
              Для корректной работы на портале, рекомендуется использовать компьютеры под ОС Windows 7, 8, 10.
              Рекомендованные браузеры - Яндекс.Браузер 17+, Google Chrome 50+.
            </div>
          </div>
        </div>
      </q-page>
    </q-page-container>
  </q-layout>
</template>

<script setup lang="ts">
import {useQuasar} from 'quasar'

const $q = useQuasar()
</script>

<style lang="scss">
@function basePadding() {
  @return clamp(16px, 4vw, 72px);
}
.LoginMobileLayout {
  background: #fff;
  position: relative;

  .ToMain {
    position: fixed;
    z-index: 1;
    top: basePadding();
    left: basePadding();

    img {
      max-width: clamp(200px, 20vw,400px);
    }
  }

  .helpText {
    position: fixed;
    z-index: 1;
    bottom: basePadding();
    left: basePadding();
    font-size: clamp(32px, 4vw, 70px);
    color: white;
  }

  .LoginWrapper {
    width: 100%;
    min-height: 100%;
    position: absolute;

    .LoginInner {
      padding: basePadding() basePadding() basePadding();
      position: relative;
      z-index: 3;
    }

    .LoginFooter {
      padding: 24px basePadding();
      background: $grey-1;
      font-size: 10px;
      color: $grey;
    }
  }
}
</style>
