<template>
  <q-list
    class="rounded-borders HelpMenu"
    dark
    dense
  >
    <q-expansion-item
      v-for="block in helpBlocks"
      :key="block.id"
      :model-value="true"
      expand-separator
      :label="block.title"
      header-class="text-bold"
    >
      <q-list
        v-for="page in block.pages"
        :key="page.id"
        padding
      >
        <q-item
          v-ripple
          :to="{name: 'help-page', params: {helpPageId: page.id}}"
          clickable
        >
          <q-item-section class="q-pl-sm">
            {{ page.title }}
          </q-item-section>
        </q-item>
      </q-list>
    </q-expansion-item>
  </q-list>
</template>

<script setup lang="ts">
import {storeToRefs} from 'pinia'
import {useHelpStore} from 'src/stores/Help/useHelpStore'

const helpStore = useHelpStore()
const {helpBlocks} = storeToRefs(helpStore)
</script>

<style lang="scss">
body {
  .HelpMenu {
    a:hover {
      text-decoration: none;
    }
  }
}
</style>
