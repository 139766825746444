<template>
  <q-dialog
    :model-value="props.modelValue"
    @update:model-value="emit('update:model-value', $event)"
  >
    <q-card
      class="q-mt-md bg-blue-1"
      style="min-width: 400px; max-width: 800px; width: 100%; border-radius: 4px !important;"
    >
      <q-card-section class="text-h6 rounded-borders">
        Оформление поручительства по сделке
      </q-card-section>
      <q-card-section class="bg-blue-grey-10 text-white row items-center q-py-lg">
        <div class="rounded-borders q-pa-sm bg-blue-grey-9">
          <q-icon
            name="svguse:icons.svg?2#app-information-circle"
            size="sm"
            color="orange-6"
          />
        </div>
        <div class="col q-pl-md text-wgite">
          <strong>Требуется добавить нового поручителя?</strong><br>
          Закройте это окно и вернитесь в редактирование анкеты во вкладку “Данные по клиенту”
        </div>
      </q-card-section>
      <q-card-section
        class="white-bg-field"
        style="max-height: 60vh; overflow: auto"
      >
        <surety-guarantor
          v-for="(guarantor, index) in guarantors"
          :key="guarantor.founder_id"
          :app-id="props.appId"
          :model-value="guarantor"
          :disable="isGuarantorDisabled(guarantor)"
          @update:model-value="guarantors[index] = $event"
        />
        <orange-info
          class="q-mt-md"
          :text="`Совокупная доля выбранных учредителей: ${selectedGuarantorPercent}% (минимум 51%)`"
          small
        />
      </q-card-section>
      <q-card-actions
        class="row justify-end q-py-md"
        style="background: #D5EAFA;"
      >
        <q-btn
          v-close-popup
          size="16px"
          padding="sm lg"
          label="Отмена"
          unelevated
          no-caps
        />
        <q-btn
          v-close-popup
          :disable="!requestCompleteEnabled"
          padding="sm lg"
          style="margin-bottom: 3px"
          size="16px"
          color="blue-7"
          label="Запрос исполнен"
          icon-right="svguse:icons.svg?2#app-check-bold"
          :flat="!requestCompleteEnabled"
          unelevated
          no-caps
          @click="completeRequest"
        />
        <q-btn
          :disable="!prepareDocsEnabled"
          padding="sm lg"
          style="margin-bottom: 3px"
          size="16px"
          color="blue-7"
          label="Подготовить документы"
          icon-right="svguse:icons.svg?2#app-document-text"
          :flat="!prepareDocsEnabled"
          unelevated
          no-caps
          @click="onClick"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
  <q-dialog
    v-model="dialog"
  >
    <q-card
      class="q-mt-md bg-blue-1"
      style="min-width: 400px; max-width: 800px; width: 100%; border-radius: 4px !important;"
    >
      <q-card-section class="text-h6 rounded-borders">
        Оформление поручительства по сделке
      </q-card-section>
      <q-card-section>
        <p>
          Мы приступили к формированию Анкеты-поручителя и Оферты для каждого выбранного Вами поручителя.
        </p>
        <p>
          Откройте это окно еще раз через несколько минут (обычно процесс не занимает дольше 3 мин.), чтобы просмотреть документы и получить ссылки для их подписания ЭЦП
        </p>
      </q-card-section>
      <q-card-actions
        class="row justify-end q-py-md"
        style="background: #D5EAFA;"
      >
        <q-btn
          v-close-popup
          padding="sm lg"
          size="16px"
          color="blue-7"
          label="Отлично!"
          unelevated
          no-caps
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
import {watchEffect} from 'vue'
import {useSurety} from 'src/uses/Applications/Surety/useSurety'
import SuretyGuarantor from 'src/components/Applications/ApplicationControlCenter/Surety/SuretyGuarantor.vue'
import OrangeInfo from 'src/components/OrangeInfo.vue'
import {useSuretyUpdate} from 'src/uses/Applications/Surety/useSuretyUpdate'
import {useDialog} from 'src/uses/Dialogs/useDialog'

const props = defineProps<{
  modelValue: boolean,
  appId: string,
}>()

const emit = defineEmits<{
  (e: 'update:model-value', value: boolean): void
}>()

const {
  dialog,
  show,
} = useDialog()

const {
  guarantors,
  prepareDocsEnabled,
  selectedGuarantorPercent,
  requestCompleteEnabled,
  isGuarantorDisabled,
  completeRequest,
  load,
} = useSurety(props.appId)

const {
  prepareDocs,
  status: suretyUpdateStatus,
} = useSuretyUpdate(props.appId)

const onClick = async () => {
  await prepareDocs(guarantors.value)

  if (suretyUpdateStatus.value === 200) {
    emit('update:model-value', false)
    show()
  }
}

watchEffect(() => {
  if (!props.modelValue) {
    return
  }

  load()
})
</script>

<style scoped lang="scss">

</style>
