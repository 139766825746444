<template>
  <div class="white-bg-field">
    <q-select
      :model-value="model"
      class="field-with-top-label"
      :options="options"
      label="Период отчетности"
      option-label="title"
      outlined
      hide-bottom-space
      dense
      @update:model-value="onSelect"
    />
    <application-form-new-document-require-sign-parameter
      v-if="model"
      :key="String(model)"
      :document="model"
      @select:sign="model.file_sign = $event"
    />
  </div>
</template>

<script setup lang="ts">
import {computed, ref, watchEffect} from 'vue'
import {
  ApplicationFormDocument
} from 'src/models/Applications/Form/ApplicationFormResponseData'
import {DocumentsCodesEnum} from 'src/models/DocumentsCodesEnum'
import ApplicationFormNewDocumentRequireSignParameter
  from 'src/components/Applications/Form/Documents/ApplicationFormNewDocumentRequireSignParameter.vue'

const props = defineProps<{
  document: ApplicationFormDocument,
  documents: ApplicationFormDocument[],
  newDocuments: ApplicationFormDocument[],
}>()

const emit = defineEmits<{
  (e: 'select-finencial', data: ApplicationFormDocument): void,
}>()

const model = ref<ApplicationFormDocument>()

const options = computed<ApplicationFormDocument[]>(() => {
  return props.documents
    .filter(document => !document.readonly)
    .filter(document => {
      return [
        DocumentsCodesEnum.ACCOUNTING_REPORTS,
        DocumentsCodesEnum.TAX_RETURN,
        DocumentsCodesEnum.ACCOUNT_CARD_51_FOR_LAST_FULL_YEAR,
        DocumentsCodesEnum.ACCOUNT_CARD_51_FOR_YEAR,
      ].includes(document.code as DocumentsCodesEnum)
        && !isAlreadyLoaded(document)
    })
    .map(document => ({
      ...document,
    }))
})

const isAlreadyLoaded = (document: ApplicationFormDocument) => {
  if (props.newDocuments?.length === 0) {
    return false
  }

  return props.newDocuments?.find(doc => {
    return doc.code === document.code && doc.title === document.title
  })
}

const onSelect = (opt: ApplicationFormDocument) => {
  model.value = opt

  emit(
    'select-finencial',
    model.value
  )
}

watchEffect(() => {
  model.value = props.document
})
</script>
