<template>
  <q-select
    ref="selectRef"
    :key="props.field.value"
    :model-value="selected"
    class="field-with-top-label"
    :options="items"
    :hint="props.ignoreHint ? '' : (props.field.help || '')"
    :disable="props.field.readonly && !props.ignoreReadonly"
    :error="!!props.field.error"
    :error-message="props.field.error"
    :loading="props.field.loading || loading"
    popup-content-class="HalfWidthOptions"
    hide-dropdown-icon
    outlined
    hide-bottom-space
    hide-selected
    use-input
    fill-input
    emit-value
    :option-label="optionLabel"
    :option-value="optionValue"
    dense
    label-slot
    @filter="onFilter"
    @focus="onFocus"
    @blur="onBlur"
    @update:model-value="onSelect"
  >
    <template #label>
      <slot name="labelPrepend" />
      {{ props.label || props.field.title }}
      <slot name="labelAppend" />
    </template>
    <template #option="scope">
      <q-item
        v-bind="scope.itemProps"
        class="q-py-sm q-px-md text-no-wrap ellipsis"
      >
        <div class="row items-center InnOption">
          <div class="text-grey-9 q-mr-sm">{{ scope.opt.data.kod }}</div>
          <div class="text-grey-6">{{ scope.opt.data.name }}</div>
        </div>
      </q-item>
    </template>
  </q-select>
</template>

<script setup lang="ts">
import {computed, ref, watch} from 'vue'
import {
  ApplicationFormField,
} from 'src/models/Applications/Form/ApplicationFormResponseData'
import {FormElementValue} from 'src/models/Form'
import {QSelect} from 'quasar'
import {useDadataOkpd} from 'src/uses/Dadata/useDadataOkpd'
import {DadataOkpd} from 'src/models/Dadata/DadataOkpd'

const props = defineProps<{
  field: ApplicationFormField,
  label: string | boolean,
  ignoreReadonly: boolean,
  ignoreHint: boolean,
}>()

const emit = defineEmits<{
  (e: 'update:value', value: FormElementValue): void,
  (e: 'update:target-value', value: FormElementValue): void,
}>()

const {
  load,
  items,
  loading,
} = useDadataOkpd()

const model = ref<FormElementValue>(null)
const selected = ref<DadataOkpd>()
const blurTimeout = ref<ReturnType<typeof setTimeout>>()
const selectRef = ref<QSelect>()

const value = computed(() => props.field.value)

const optionValue = (opt: DadataOkpd) => {
  return opt
}

const optionLabel = (opt: DadataOkpd) => {
  return opt.data.kod
}

const onSelect = (item?: DadataOkpd) => {
  if (!item) {
    return
  }
  clearTimeout(blurTimeout.value)
  selected.value = item
  model.value = item.data.kod || ''
  emit('update:value', model.value)
  emit('update:target-value', item.data.name)
}

const onFocus = async () => {
  if (!selectRef.value) {
    return
  }

  const input = selectRef.value.$el.querySelector('input') as HTMLInputElement
  input.setSelectionRange(0, 0)
  await load(model.value as string)
}

const onFilter = (
  input: string,
  update: (fn: () => void) => void
) => {
  update(async () => {
    model.value = input
    setSelectedFromModel()
    await load(input)
  })
}

const onBlur = () => {
  if (!selected.value) {
    return
  }

  if (!selected.value?.value && selectRef.value) {
    selected.value.data.kod = props.field.value as string
    return
  }
  blurTimeout.value = setTimeout(() => {
    emit('update:value', model.value)
    setSelectedFromModel()
  }, 100)
}

const setSelectedFromModel = () => {
  selected.value = selected.value = {
    data: {
      idx: '',
      razdel: '',
      kod: model.value as string,
      name: '',
    },
    idx: '',
    kod: model.value as string,
    name: '',
    razdel: '',
    unrestricted_value: '',
    value: '',
  }
}

watch(
  value,
  () => {
    model.value = props.field.value
    setSelectedFromModel()
  },
  {immediate: true}
)
</script>

<style lang="scss" scoped>
.input {
  width: 100%;
  border: none;
  background: transparent;

  &:focus {
    outline: none;
  }
}
</style>
