import {defineStore} from 'pinia'
import {useGridFilters} from 'src/uses/Grid/useGridFilters'
import {BanksGridFilter} from 'src/models/Banks/BanksGridFilter'

const defaultFilters: BanksGridFilter = {
  name: '',
}

export const useBanksGridFiltersStore = defineStore('banks-grid-filters', () => {
  const {
    filters,
    clearable,
    getFiltersQuery,
    clear,
  } = useGridFilters(defaultFilters)

  return {
    filters,
    clearable,
    getFiltersQuery,
    clear,
  }
})
