import {usePostApi} from 'src/uses/Api/usePostApi'
import {FormHelper} from 'src/helpers/FormHelper'

export const useAgentProfileRequisitesUploadScan = () => {
  const {
    post,
    status,
    response,
    loading,
  } = usePostApi<unknown, unknown>(true)

  const uploadScan = async (documentId: string, file: File) => {
    await post(
      `v1/document/upload/${documentId}/scan`,
      FormHelper.getFormDataFromObject({
        file,
      })
    )
  }

  return {
    uploadScan,
    status,
    response,
    loading,
  }
}
