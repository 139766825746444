import {UserStateEnum} from 'src/models/User/UserStateEnum'

const statuses: Record<UserStateEnum, string> = {
  [UserStateEnum.Blocked]: 'Заблокирован',
  [UserStateEnum.NeedDocuments]: 'Проверка документов',
  [UserStateEnum.Connected]: 'Подключен',
  [UserStateEnum.NeedRequisites]: 'Ожидание заполнения реквизитов',
}

const colors: Record<UserStateEnum, string> = {
  [UserStateEnum.Blocked]: 'red-12',
  [UserStateEnum.NeedDocuments]: 'orange-6',
  [UserStateEnum.Connected]: 'green-14',
  [UserStateEnum.NeedRequisites]: 'orange-6',
}

export class AgentStatuses {
  public static getAgentsStatusDescription (status: UserStateEnum): string {
    return statuses[status] || status
  }

  public static getAgentsStatusColor (status: UserStateEnum): string {
    return colors[status] || ''
  }
}
