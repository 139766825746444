import {GridColumnEditableSettings} from 'src/models/Grid/GridColumnSettings'
import {UserRoleEnum} from 'src/models/User/UserRoleEnum'

/**
 * Иконка чата (все механики по сообщениям как у гарантий)
 * Кнопки действий (ниже табличка)
 * @constructor
 */
export const ApplicationsGridKikColumns = (): GridColumnEditableSettings[] => [
  {
    field: '',
    name: 'date',
    required: true,
    label: '№, дата создания',
    align: 'left',
    classes: 'text-no-wrap',
    visible: true,
    editable: false,
    order: 1,
    sortableColumn: true,
  },
  {
    field: '',
    name: 'inn',
    required: true,
    label: 'Название/ИНН',
    align: 'left',
    visible: true,
    editable: true,
    order: 2,
    sortableColumn: false,
  },
  {
    field: '',
    name: 'credit_type',
    required: true,
    label: 'Тип кредита',
    align: 'left',
    visible: true,
    editable: true,
    order: 3,
    sortableColumn: false,
  },
  {
    field: '',
    name: 'credit_amount',
    required: true,
    label: 'Сумма кредита',
    align: 'left',
    visible: true,
    editable: true,
    classes: 'text-no-wrap',
    order: 4,
  },
  {
    field: '',
    name: 'credit_months',
    required: true,
    label: 'Срок кредита',
    align: 'left',
    visible: true,
    editable: true,
    classes: 'text-no-wrap',
    order: 5,
    sortableColumn: false,
  },
  {
    field: '',
    name: 'tariff_percent',
    required: true,
    label: 'Тариф',
    align: 'left',
    visible: true,
    editable: true,
    classes: 'text-no-wrap',
    order: 6,
    sortableColumn: false,
  },
  {
    field: '',
    name: 'bank',
    label: 'Банк',
    required: true,
    align: 'left',
    visible: true,
    editable: true,
    order: 7,
  },
  {
    field: '',
    name: 'status',
    label: 'Статус',
    required: true,
    align: 'left',
    visible: true,
    editable: true,
    order: 8,
  },
  {
    field: '',
    name: 'users',
    label: 'Инфо',
    required: true,
    align: 'left',
    visible: true,
    editable: true,
    order: 9,
    roles: [
      UserRoleEnum.Admin,
      UserRoleEnum.Operator,
      UserRoleEnum.AgentManager,
      UserRoleEnum.BankEmployee,
    ],
  },
  {
    field: '',
    name: 'actions',
    label: 'Действия с заявкой',
    required: true,
    align: 'right',
    visible: true,
    editable: false,
    classes: 'text-no-wrap',
    order: 10,
  },
]
