import {ref, onBeforeMount} from 'vue'
import {GridSorting} from 'src/models/Grid/Sorting'
import {GridSortingEnum} from 'src/models/Grid/GridSortingEnum'
import {useRoute, useRouter} from 'vue-router'

export const useGridSorting = (
  sortInitValue: GridSorting = {sort: '', direction: GridSortingEnum.DESC},
  syncToRouter = false
) => {
  const router = useRouter()
  const route = useRoute()

  const sorting = ref<GridSorting>(sortInitValue)

  const setQueryToRouter = async () => {
    if (!syncToRouter) {
      return
    }

    await router.replace({
      ...route,
      query: {
        ...route.query,
        sort: sorting.value.sort,
        sortDirection: sorting.value.direction,
      }
    })
  }

  onBeforeMount(() => {
    if (!syncToRouter) {
      return
    }

    sorting.value.sort = (route.query.sort as string) || sorting.value.sort
    sorting.value.direction = (route.query.sortDirection as GridSortingEnum) || sorting.value.direction
  })

  return {
    sorting,
    setQueryToRouter,
  }
}
