<template>
  <q-select
    v-model="selected"
    class="RegionFilter"
    :options="filteredRegions"
    :loading="regionsLoading || props.loading"
    option-value="code"
    option-label="name"
    label="Регион"
    style="min-width: 200px"
    hide-dropdown-icon
    outlined
    hide-bottom-space
    use-input
    multiple
    dense
    label-slot
    clearable
    @filter="filter"
    @update:model-value="onChange"
    @clear="clear"
  >
    <template
      #before-options
    >
      <q-list class="bg-blue-grey-1">
        <q-item
          v-for="opt in selected || []"
          :key="opt.code"
          clickable
          v-ripple
          @click="remove(opt)"
        >
          <q-item-section side>
            <q-checkbox
              :model-value="selected"
              :val="opt"
              @click="remove(opt)"
            />
          </q-item-section>
          <q-item-section>
            <q-item-label>
              <div class="row items-center InnOption">
                <div class="text-grey-9 q-mr-sm">{{ opt.code }}</div>
                <div class="text-grey-6">{{ opt.name }}</div>
              </div>
            </q-item-label>
          </q-item-section>
        </q-item>
      </q-list>
    </template>
    <template
      #option="{ itemProps, opt, selected, toggleOption }"
    >
      <q-item
        v-if="!isSelected(opt)"
        v-bind="itemProps"
      >
        <q-item-section side>
          <q-checkbox
            :model-value="selected"
            @update:model-value="toggleOption(opt)"
          />
        </q-item-section>
        <q-item-section>
          <q-item-label>
            <div class="row items-center InnOption">
              <div class="text-grey-9 q-mr-sm">{{ opt.code }}</div>
              <div class="text-grey-6">{{ opt.name }}</div>
            </div>
          </q-item-label>
        </q-item-section>
      </q-item>
    </template>
  </q-select>
</template>

<script setup lang="ts">
import {storeToRefs} from 'pinia'
import {onBeforeMount, onBeforeUnmount, ref} from 'vue'
import {useRegionsStore} from 'src/stores/Clients/useRegionsStore'
import {Region} from 'src/models/Clients/Region'
import {QSelect} from 'quasar'

const props = defineProps<{
  modelValue: number[] | undefined,
  loading: boolean,
}>()

const emit = defineEmits<{
  (e: 'update:model-value', regionsCodes: string[]): void,
  (e: 'change'): void,
}>()

const regionsStore = useRegionsStore()
const {load} = regionsStore
const {loading: regionsLoading, regions} = storeToRefs(regionsStore)

const selected = ref<Region[]>([])
const filteredRegions = ref<Region[]>([])

const filter = (
  val: string,
  update: (callbackFn: () => void, afterFn?: (ref: QSelect) => void) => void
) => {
  if (!val) {
    update(() => {
      filteredRegions.value = regions.value
    })

    return
  }
  update(() => {
    filteredRegions.value = regions.value
      .filter(r => r.name.toLowerCase().includes(val.toLowerCase()))
  })
}

const isSelected = (opt: Region) => {
  return !!(selected.value || [])
    .find(item => item.code === opt.code)
}

const remove = (opt: Region) => {
  selected.value = (selected.value || [])
    .filter(item => item.code !== opt.code)
}

const onChange = (val: Region[]) => {
  emit('update:model-value', val.map(item => item.code))
  emit('change')
}

const clear = () => {
  emit('update:model-value', [])
  emit('change')
}

onBeforeMount(async () => {
  if (regions.value.length === 0) {
    await load()
  }
})

onBeforeUnmount(() => {
  regionsStore.$dispose()
  regionsStore.$reset()
})
</script>

<style lang="scss">
.RegionFilter {
  .q-field__native {
    & > span {
      max-width: 65%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}
</style>
