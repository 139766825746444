<template>
  <slot
    name="default"
    :on-show-click="onShowClick"
  />
  <q-dialog v-model="dialog">
    <q-card
      class="ApplicationTariffModal bg-blue-1"
      style="width: 65vw; min-width: 400px;"
    >
      <div class="text-h6 q-px-md q-pt-md">
        Изменение тарифа
      </div>
      <div
        style="max-height: 75vh; min-height: 300px;"
        class="scroll"
      >
        <q-card-section v-if="loading || !tariffDetails">
          <q-linear-progress indeterminate />
        </q-card-section>
        <div v-if="tariffDetails">
          <q-card-section>
            <div class="ApplicationTariff--ListWrapper">
              <key-value-list-item>
                <template #key>№</template>
                {{ props.number }}
              </key-value-list-item>
              <key-value-list-item>
                <template #key>Сумма БГ</template>
                <money-component :model-value="tariffDetails.guarantee_total" />
              </key-value-list-item>
              <key-value-list-item>
                <template #key>% ставка годовых</template>
                {{ tariffDetails.tariff_percent }}
              </key-value-list-item>
              <key-value-list-item>
                <template #key>Начало действия БГ</template>
                <template v-if="!tariffDetails.is_concrete_start_date">
                  С даты выдачи (тариф рассчитан с {{ tariffDetails.guarantee_from }})
                </template>
                <template v-else>
                  {{ tariffDetails.guarantee_from }}
                </template>
              </key-value-list-item>
              <key-value-list-item>
                <template #key>Окончание действия БГ</template>
                {{ tariffDetails.guarantee_to }}
              </key-value-list-item>
              <key-value-list-item>
                <template #key>Количество дней БГ</template>
                {{ tariffDetails.guarantee_days }}
              </key-value-list-item>
            </div>
            <div class="row q-mt-md wrap">
              <q-field
                v-model="tariffDetails.issue_form"
                class="field-with-top-label no-bg bgFormToggle q-pr-lg col"
                label="Форма выпуска БГ"
                borderless
                hide-bottom-space
                dense
              >
                <q-btn-toggle
                  :model-value="tariffDetails.issue_form"
                  class="full-width"
                  toggle-color="primary"
                  color="blue-grey-1"
                  text-color="grey-9"
                  toggle-text-color="white"
                  :options="bgForms"
                  no-caps
                  spread
                  unelevated
                  @update:model-value="onIssueFormUpdate"
                />
              </q-field>
              <div class="q-pt-lg col-sm col-xs-12">
                <q-toggle
                  v-model="tariffDetails.mailhardcopy"
                  :disable="!tariffDetails.issue_form_enabled"
                  class="q-mt-sm"
                  style="margin-left: -10px;"
                  label="Требуется отправка БГ"
                  @update:model-value="onMailhardcopyUpdate"
                />
              </div>
            </div>
          </q-card-section>
          <q-card-section style="background: #D5EAFA;">
            <orange-info
              class="q-mb-md py-md"
              small
            >
              Внимание! Минимальный тариф -
              <money-component :model-value="minimalTariff" />
            </orange-info>
            <div :class="{'ApplicationTariffDetails--tariff': true, selected: baseSelected}">
              <div class="row items-center">
                <div class="col">
                  <div>
                    <strong>Базовый тариф</strong>
                  </div>
                  Комиссия
                  <money-component :model-value="tariffDetails.tariff_amount" />
                  ({{ tariffDetails.tariff_percent }} %)
                </div>
                <div>
                  <q-btn
                    color="white"
                    :label="baseSelected ? 'Используется' : 'Применить'"
                    no-caps
                    unelevated
                    flat
                    @click="setTariff(tariffDetails.tariff_amount || 0)"
                  />
                </div>
              </div>
            </div>
            <div
              v-if="tariffDetails.bank_commission_percent"
              :class="{'ApplicationTariffDetails--tariff': true, selected: bankSelected}"
            >
              <div class="row items-center">
                <div class="col">
                  <div>
                    <strong>- {{ tariffDetails.bank_commission_percent }} % (за счет Банка)</strong>
                  </div>
                  Комиссия
                  <money-component :model-value="tariffDetails.bank_commission_amount" />
                  ({{ tariffDetails.bank_commission_percent_calculated }} %)
                </div>
                <div>
                  <q-btn
                    color="white"
                    :label="bankSelected ? 'Используется' : 'Применить'"
                    no-caps
                    unelevated
                    flat
                    @click="setTariff(tariffDetails.bank_commission_amount || 0)"
                  />
                </div>
              </div>
            </div>
            <div
              v-if="tariffDetails.agent_commission_percent"
              :class="{'ApplicationTariffDetails--tariff': true, selected: agentSelected}"
            >
              <div class="row items-center">
                <div class="col">
                  <div>
                    <strong>- {{ tariffDetails.agent_commission_percent }} % (за счет вашего КВ)</strong>
                  </div>
                  Комиссия
                  <money-component :model-value="tariffDetails.agent_commission_amount" />
                  ({{ tariffDetails.agent_commission_percent_calculated }} %)
                </div>
                <div>
                  <q-btn
                    color="white"
                    :label="agentSelected ? 'Используется' : 'Применить'"
                    no-caps
                    unelevated
                    flat
                    @click="setTariff(tariffDetails.agent_commission_amount || 0)"
                  />
                </div>
              </div>
            </div>
            <error-helper
              v-if="commissionWarning"
              class="q-my-md"
              :error="commissionWarning"
            />
            <application-tariff-comission
              ref="tariffCommissionRef"
              :id="props.id"
              v-model:commission-amount="tariffDetails.commission_amount"
              v-model:commission-percent="tariffDetails.commission_percent"
            />
          </q-card-section>
        </div>
      </div>
      <q-card-actions
        class="q-py-md"
        style="background: #BEDFF8;"
        align="right"
      >
        <q-btn
          v-close-popup
          label="Отмена"
          padding="10px 20px"
          no-caps
          flat
          unelevated
        />
        <q-btn
          :disable="!tariffDetails || minimalAmount > (tariffDetails.commission_amount || 0)"
          :loading="setTariffLoading || loadingCalculated"
          color="primary"
          label="Сохранить"
          padding="10px 20px"
          no-caps
          unelevated
          @click="save"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
import {computed, onBeforeMount, ref, watch} from 'vue'
import {useDialog} from 'src/uses/Dialogs/useDialog'
import KeyValueListItem from 'src/components/KeyValueListItem.vue'
import MoneyComponent from 'src/components/MoneyComponent.vue'
import {useGetTariffDetails} from 'src/uses/Applications/Tariff/useGetTariffDetails'
import {useBgForm} from 'src/uses/Applications/useBgForm'
import OrangeInfo from 'src/components/OrangeInfo.vue'
import ErrorHelper from 'src/components/ErrorHelper.vue'
import ApplicationTariffComission from 'src/components/Applications/Tariff/ApplicationTariffComission.vue'
import {useSetTariff} from 'src/uses/Applications/Tariff/useSetTariff'
import {ApplicationsTariffDetailsResponseData} from 'src/models/Applications/Tariff/ApplicationsTariffDetailsResponseData'
import {useRoute} from 'vue-router'
import {BgFormEnum} from 'src/models/Applications/BgFormEnum'
import {useGetTariffDetailsCalculate} from 'src/uses/Applications/Tariff/useGetTariffDetailsCalculate'
import {usePosthogStore} from 'stores/Posthog/usePosthogStore'

const props = defineProps<{
  id: string,
  number: string,
}>()

const emit = defineEmits<{
  (
    e: 'tariffRefreshed',
    details: ApplicationsTariffDetailsResponseData,
  ): void,
}>()

const {dialog, show, hide} = useDialog()
const {sendEvent} = usePosthogStore()
const {
  options: bgForms,
} = useBgForm()

const {
  load,
  tariffDetails,
  loading,
} = useGetTariffDetails()

const {
  calculate,
  tariffDetails: tariffDetailsCalculated,
  loading: loadingCalculated,
} = useGetTariffDetailsCalculate()

const {
  setTariff: realSetTariff,
  loading: setTariffLoading,
  status: setTariffStatus,
} = useSetTariff()

const route = useRoute()

const tariffCommissionRef = ref<typeof ApplicationTariffComission>()

const minimalTariff = computed(() => {
  if (!tariffDetails.value) {
    return undefined
  }

  const {
    bank_commission_amount: bankCommissionAmount,
    agent_commission_amount: agentCommissionAmount,
    minimal_tariff: minimalTariff,
    agent_commission_percent: agentCommissionPercent,
    bank_commission_percent: bankCommissionPercent
  } = tariffDetails.value

  if (!agentCommissionPercent && !bankCommissionPercent) {
    return minimalTariff
  }

  return agentCommissionPercent ? agentCommissionAmount : bankCommissionAmount
})

const commissionWarning = computed(() => {
  if (!tariffDetails.value) {
    return undefined
  }

  const {
    bank_commission_amount: bankCommissionAmount,
    agent_commission_amount: agentCommissionAmount,
    agent_commission_comment: agentCommissionComment,
    bank_commission_comment: bankCommissionComment,
    minimal_tariff: minimalTariff,
    commission_amount: commissionAmount,
    agent_commission_percent: agentCommissionPercent,
    bank_commission_percent: bankCommissionPercent,
    tariff_amount: tariffAmount,
  } = tariffDetails.value

  if (!agentCommissionPercent
    && !bankCommissionPercent
    && (minimalTariff || 0) > (commissionAmount || 0)
  ) {
    return `Сумма некорректна, значение не должно быть меньше ${minimalTariff}`
  }

  if ((commissionAmount || 0) < (tariffAmount || 0)
    && (commissionAmount || 0) >= (bankCommissionAmount || 0)
  ) {
    return bankCommissionComment || 'Для согласования скидки напишите нужную сумму в чат или обратитесь к куратору.'
  }

  if ((commissionAmount || 0) < (bankCommissionAmount || 0)
    && (commissionAmount || 0) >= (agentCommissionAmount || 0)
  ) {
    return agentCommissionComment || 'Для согласования скидки напишите нужную сумму в чат или обратитесь к куратору.'
  }

  if ((commissionAmount || 0) < (agentCommissionAmount || 0)) {
    return `Сумма некорректна, значение не должно быть меньше ${agentCommissionAmount}`
  }

  return undefined
})

const baseSelected = computed(() => {
  if (!tariffDetails.value) {
    return false
  }

  return tariffDetails.value.tariff_amount === tariffDetails.value.commission_amount
})

const bankSelected = computed(() => {
  if (!tariffDetails.value) {
    return false
  }

  return tariffDetails.value.bank_commission_amount === tariffDetails.value.commission_amount
})

const agentSelected = computed(() => {
  if (!tariffDetails.value) {
    return false
  }

  return tariffDetails.value.agent_commission_amount === tariffDetails.value.commission_amount
})

const minimalAmount = computed(() => {
  if (!tariffDetails.value) {
    return 0
  }

  const amounts = []
  if (tariffDetails.value.tariff_amount) {
    amounts.push(tariffDetails.value.tariff_amount)
  }
  if (tariffDetails.value.agent_commission_amount) {
    amounts.push(tariffDetails.value.agent_commission_amount)
  }
  if (tariffDetails.value.bank_commission_amount) {
    amounts.push(tariffDetails.value.bank_commission_amount)
  }

  if (amounts.length === 0) {
    return 0
  }

  return Math.min(...amounts) || 0
})

const onMailhardcopyUpdate = async () => {
  if (!tariffDetails.value) {
    return
  }

  await calculate(props.id, tariffDetails.value.issue_form, tariffDetails.value.mailhardcopy)
}

const onIssueFormUpdate = async (value: BgFormEnum) => {
  if (!tariffDetails.value) {
    return
  }

  tariffDetails.value.issue_form = value
  tariffDetails.value.mailhardcopy = value === BgFormEnum.paper
  await calculate(props.id, tariffDetails.value.issue_form, tariffDetails.value.mailhardcopy)
}

const setTariff = (tariff: number) => {
  if (!tariffDetails.value) {
    return false
  }

  tariffDetails.value.commission_amount = tariff
}

const realSave = async () => {
  if (!tariffDetails.value) {
    return false
  }

  await realSetTariff(
    props.id,
    {
      amount: tariffDetails.value.commission_amount || 0,
      is_concrete_start_date: tariffDetails.value.is_concrete_start_date,
      issue_form: tariffDetails.value.issue_form,
      mailhardcopy: tariffDetails.value.mailhardcopy,
      percent: tariffDetails.value.commission_percent || 0,
    }
  )

  if (setTariffStatus.value !== 200) {
    await load(props.id)

    return
  }

  if (tariffDetails.value) {
    emit('tariffRefreshed', tariffDetails.value)
  }

  hide()
}

const save = async () => {
  if (tariffCommissionRef.value && tariffCommissionRef.value.loading) {
    setTimeout(save, 200)
    return
  }

  await realSave()
}

onBeforeMount(() => {
  if (route.query.tariff !== 'true' || route.name !== 'application-control-center') {
    return
  }

  onShowClick()
})

const onShowClick = async () => {
  show()
  await load(props.id)
  const posthogEventName = route.name === 'application-control-center'
    ? 'Open_tariff_settings_via_control_center'
    : 'Open_tariff_settings_via_list_of_applications'
  sendEvent(posthogEventName)
}

watch(
  tariffDetailsCalculated,
  () => {
    tariffDetails.value = tariffDetailsCalculated.value
  },
  {
    deep: true,
  }
)
</script>

<style lang="scss">
.ApplicationTariff--ListWrapper {
  border-radius: 6px;
  overflow: hidden;
  position: relative;

}

.ApplicationTariffDetails--tariff {
  background: $blue-7;
  color: white;
  padding: 10px 20px;
  border-radius: 6px;
  margin: 5px 0;

  &.selected {
    background: $blue-grey-10;
  }

  strong {
    font-size: 16px;
  }
}
</style>
