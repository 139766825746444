<template>
  <page-block-wrapper>
    <div class="clients">
      <clients-grid />
    </div>
  </page-block-wrapper>
</template>
<script setup lang="ts">
import PageBlockWrapper from 'src/components/PageBlockWrapper.vue'
import ClientsGrid from 'src/components/Clients/ClientsGrid.vue'
import {useMetaStore} from 'src/stores/useMetaStore'

useMetaStore().setTitle('Клиенты')
</script>
