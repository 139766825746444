import {DataSizesEnum} from 'src/models/DataSizes'

export const useDataSize = () => {
  const sizeTypes = [
    {
      name: DataSizesEnum.b,
      iterations: 0,
    },
    {
      name: DataSizesEnum.kb,
      iterations: 1,
    },
    {
      name: DataSizesEnum.mb,
      iterations: 2,
    },
    {
      name: DataSizesEnum.gb,
      iterations: 3,
    },
    {
      name: DataSizesEnum.tb,
      iterations: 4,
    },
  ]

  const getSizeIn = (sizeInBytes: number, sizeType: DataSizesEnum = DataSizesEnum.b) => {
    const st = sizeTypes
      .find(st => st.name === sizeType)
    if (!st) {
      return sizeInBytes
    }

    return (new Array(st.iterations))
      .fill(0)
      .reduce((result) => result / 1024, sizeInBytes)
  }

  const detectSizeType = (sizeInBytes: number) => {
    return sizeTypes.find(st => {
      const sizeIn = Math.floor(getSizeIn(sizeInBytes, st.name))

      return sizeIn.toString().length >= 1
        && sizeIn.toString().length < 4
    })
      || sizeTypes[sizeTypes.length - 1]
  }

  const getSizeString = (sizeInBytes: number) => {
    const st = detectSizeType(sizeInBytes)

    return `${getSizeIn(sizeInBytes, st.name).toFixed(2)}${st.name}`
  }

  return {
    getSizeString,
  }
}
