<template>
  <div>
    <div
      v-if="hasForm1(props.fields)"
      class="text-h6 q-my-md"
    >
      форма 1
    </div>
    <div
      v-if="hasForm1(props.fields)"
      class="bg-white q-mb-md rounded-borders"
    >
      <div class="q-pa-md">
        <strong>Актив</strong>
      </div>
      <finance-table-wrapper :period-label="getLabel(props.period)">
        <finance-form1-block
          v-for="(block, index) in form1ActiveBlocks"
          :key="index"
          :period="props.period"
          :block="block"
          :fields="props.fields"
          @update:field="updateFinanceField"
        />
        <finance-sum
          v-if="form1ActiveSumField"
          :key="form1ActiveSumField.code + form1ActiveSumField.value"
          :field="form1ActiveSumField"
        />
      </finance-table-wrapper>
    </div>
    <div
      v-if="hasForm1(props.fields) && form1PassiveBlocks.length"
      class="bg-white q-mb-md rounded-borders"
    >
      <div class="q-pa-md">
        <strong>Пассив</strong>
      </div>
      <finance-table-wrapper :period-label="getLabel(props.period)">
        <finance-form1-block
          v-for="(block, index) in form1PassiveBlocks"
          :key="index"
          :period="props.period"
          :block="block"
          :fields="props.fields"
          @update:field="updateFinanceField"
        />
        <finance-sum
          v-if="form1PassiveSumField"
          :key="form1PassiveSumField.code + form1PassiveSumField.value"
          :field="form1PassiveSumField"
        />
      </finance-table-wrapper>
    </div>

    <div
      v-if="hasForm2(props.fields) && form2Blocks.length"
      class="text-h6 q-my-md"
    >
      форма 2
    </div>
    <div class="bg-white q-mb-md rounded-borders">
      <finance-table-wrapper :period-label="getLabel(props.period)">
        <finance-form2-block
          v-for="(block, index) in form2Blocks"
          :key="index"
          :period="props.period"
          :block="block"
          :fields="props.fields"
          @update:field="updateFinanceField"
        />
      </finance-table-wrapper>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  ApplicationFormFinanceField,
  ApplicationFormFinancePeriod,
} from 'src/models/Applications/Form/ApplicationFormResponseData'
import FinanceSum from 'src/components/Applications/Form/Finance/FinanceSum.vue'
import {usePeriod} from 'src/uses/Applications/Form/Finance/usePeriod'
import {useFinanceFieldsFilter} from 'src/uses/Applications/Form/Finance/useFinanceFieldsFilter'
import FinanceTableWrapper from 'src/components/Applications/Form/Finance/FinanceTableWrapper.vue'
import {useForms} from 'src/uses/Applications/Form/Finance/useForms'
import {computed} from 'vue'
import FinanceForm1Block from 'components/Applications/Form/Finance/FinanceForm1Block.vue'
import FinanceForm2Block from 'components/Applications/Form/Finance/FinanceForm2Block.vue'

const props = defineProps<{
  period: ApplicationFormFinancePeriod,
  fields: ApplicationFormFinanceField[],
}>()

const {
  getLabel,
  hasForm1,
  hasForm2,
} = usePeriod()

const {
  getFieldByCode,
} = useFinanceFieldsFilter()

const {
  form1ActiveBlocks,
  form1ActiveSumCode,
  form1PassiveBlocks,
  form1PassiveSumCode,
  form2Blocks,
} = useForms()

const emit = defineEmits<{
  (e: 'update:field', field: ApplicationFormFinanceField): void,
}>()

const form1ActiveSumField = computed(() => {
  return getFieldByCode(props.fields, form1ActiveSumCode)
})

const form1PassiveSumField = computed(() => {
  return getFieldByCode(props.fields, form1PassiveSumCode)
})

const updateFinanceField = (field: ApplicationFormFinanceField) => {
  emit(
    'update:field',
    field,
  )
}
</script>
