import {ActStatusEnum} from 'src/models/Enums/ActStatusEnum'

const statuses: Record<ActStatusEnum, string> = {
  [ActStatusEnum.approval]: 'На согласовании',
  [ActStatusEnum.formed]: 'Сформирован',
  [ActStatusEnum.signed]: 'Подписан',
  [ActStatusEnum.paid]: 'Оплачен',
}

export class ActStatuses {
  public static getActStatusDescription (status: ActStatusEnum): string {
    return statuses[status] || status
  }

  public static isApprovalStatus (status: ActStatusEnum): boolean {
    return status === ActStatusEnum.approval
  }

  public static isFormedStatus (status: ActStatusEnum): boolean {
    return status === ActStatusEnum.formed
  }

  public static isSignedStatus (status: ActStatusEnum): boolean {
    return status === ActStatusEnum.signed
  }
}
