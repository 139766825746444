import {RouterGuard} from 'src/models/Router/RouterGuard'
import {useAuthStore} from 'src/stores/Login/useAuthStore'
import {UserRoleEnum} from 'src/models/User/UserRoleEnum'

const canAccess = () => {
  const authStore = useAuthStore()

  return !!authStore.profile
    && [
      UserRoleEnum.Admin,
      UserRoleEnum.JuniorAdmin,
    ].includes(authStore.profile.role)
}

const redirectTo = () => {
  if (canAccess()) {
    return undefined
  }

  return {
    name: 'main',
  }
}

export const ListingsGuard: RouterGuard = {
  canAccess,
  redirectTo,
}
