import {GridColumnEditableSettings} from 'src/models/Grid/GridColumnSettings'

export const BanksGridColumns: GridColumnEditableSettings[] = [
  {
    field: '',
    name: 'number',
    required: true,
    label: 'Номер',
    align: 'left',
    classes: 'text-no-wrap',
    visible: true,
    editable: true,
    order: 1,
    sortable: false,
  },
  {
    field: '',
    name: 'title',
    required: true,
    label: 'Наименование',
    align: 'left',
    classes: 'text-no-wrap',
    visible: true,
    editable: true,
    order: 2,
    sortable: false,
  },
  {
    field: '',
    name: 'logo',
    required: true,
    label: 'Логотип',
    align: 'left',
    classes: 'text-no-wrap',
    visible: true,
    editable: true,
    order: 3,
    sortable: false,
  },
  {
    field: '',
    name: 'products',
    required: true,
    label: 'Продукты',
    align: 'left',
    visible: true,
    editable: true,
    order: 4,
    sortable: false,
  },
  {
    field: '',
    name: 'actions',
    label: '',
    required: true,
    align: 'right',
    visible: true,
    editable: false,
    order: 5,
  },
]
