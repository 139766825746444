import {defineStore} from 'pinia'
import {useGridFilters} from 'src/uses/Grid/useGridFilters'
import {ContractorsListFilter} from 'src/models/Contractors/ContractorsListFilter'

const defaultFilters: ContractorsListFilter = {
  query: '',
}

export const useContractorsGridFiltersStore = defineStore('contractors-list-filters', () => {
  const {
    filters,
    clearable,
    getFiltersQuery,
    clear,
  } = useGridFilters(defaultFilters)

  return {
    filters,
    clearable,
    getFiltersQuery,
    clear,
  }
})
