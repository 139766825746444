<template>
  <div class="text-h6 q-mb-md">
    Финансовый блок
  </div>
  <div v-if="item && item.finance_block">
    <div class="row q-gutter-lg">
      <div class="col">
        <q-toggle
          v-model="periodsOff"
          label="Банк требует бухгалтерскую отчетность"
        />
      </div>
      <div class="col white-bg-field">
        <q-checkbox
          v-model="item.finance_block.allow_short_reporting"
          label="Возможность рассмотрения с частичной БО"
          @click="changeAllowShortReporting"
        />
      </div>
    </div>
    <form-item-financial-periods
      :form-id="props.formId"
    ></form-item-financial-periods>
  </div>
</template>

<script setup lang="ts">
import FormItemFinancialPeriods from 'components/FormEditor/Finance/FormItemFinancialPeriods.vue'
import {useFormItemFinancialStore} from 'stores/FormEditor/useFormItemFinancialStore'
import {storeToRefs} from 'pinia'
import {onBeforeMount} from 'vue'

const props = defineProps<{
  formId: string,
}>()

const formItemFinancialStore = useFormItemFinancialStore(props.formId)()
const {
  periodsOff,
  item,
} = storeToRefs(formItemFinancialStore)

const {
  save,
  init,
  loadReports
} = formItemFinancialStore

const changeAllowShortReporting = async () => {
  await save()
}

onBeforeMount(async () => {
  await loadReports()
  init()
})

</script>
