<template>
  <div class="ApplicationControlCenterItemHeadAndFounders q-px-md q-py-lg bg-white q-mt-lg">
    <div
      v-if="$q.platform.is.mobile"
      class="row items-center"
    >
      <div class="col-12 text-h6">Руководители и учредители</div>
      <div class="col-12 text-right">
        <q-btn
          class="full-width q-my-md"
          label="Добавить учредителя"
          icon-right="svguse:icons.svg?2#app-plus"
          color="blue-7"
          padding="13px 20px"
          :loading="addFounderLoading"
          :disable="addFounderLoading"
          unelevated
          no-wrap
          no-caps
          @click="addFounder()"
        />
      </div>
    </div>
    <div
      v-else
      class="row items-center"
    >
      <div class="text-h6">Руководители и учредители</div>
      <div class="col text-right">
        <q-btn
          label="Добавить учредителя"
          icon-right="svguse:icons.svg?2#app-plus"
          color="blue-7"
          padding="13px 20px"
          :loading="addFounderLoading"
          :disable="addFounderLoading || !canAddFounder"
          unelevated
          no-wrap
          no-caps
          @click="addFounder()"
        />
      </div>
    </div>
    <div class="blue-bg-field q-my-md">
      <q-input
        v-model="search"
        class="field-with-top-label"
        label="Поиск по имени"
        outlined
        hide-bottom-space
        dense
        emit-value
        map-options
        options-dense
        hide-dropdown-icon
        clearable
      >
        <template #prepend>
          <q-icon name="svguse:icons.svg?2#app-search" />
        </template>
      </q-input>
    </div>
    <application-form-founder
      v-if="showHead"
      v-model:opened-founder-id="openedFounderId"
      :fields="props.headFields"
      :is-ok="isOk('head')"
      :loading="false"
      :banks="banks"
      founder-id="head"
      @update:field-value="onUpdateFieldValue"
      @update:address-details="updateAddressDetails"
      @passport-recognized="onPassportRecognized($event, undefined)"
    />
    <template v-if="client">
      <template
        v-for="(founder, index) in props.foundersFields"
        :key="index"
      >
        <application-form-founder
          v-if="showFounder(founder)"
          v-model:opened-founder-id="openedFounderId"
          :fields="founder"
          :is-head="false"
          :loading="loadings[client.founders[index].id] || false"
          :banks="banks"
          :is-ok="isOk(client.founders[index].id)"
          :founder-id="client.founders[index].id"
          @delete="deleteHandler"
          @update:field-value="onUpdateFieldValue"
          @update:address-details="updateAddressDetails"
          @passport-recognized="onPassportRecognized($event, client.founders[index].id)"
        >
          <template #type>
            Учредитель {{ index + 1 }}
          </template>
        </application-form-founder>
      </template>
    </template>
  </div>
</template>

<script setup lang="ts">
import {computed, ref} from 'vue'
import {ProductFields} from 'src/models/Applications/Form/ProductFields'
import {storeToRefs} from 'pinia'
import {useClientHeadAndFounders} from 'src/uses/Applications/Form/Client/useClientHeadAndFounders'
import {
  useApplicationControlCenterItemStore
} from 'src/stores/Applications/ControlCenter/useApplicationControlCenterItemStore'
import {
  useApplicationControlCenterItemFillStore
} from 'src/stores/Applications/ControlCenter/useApplicationControlCenterItemFillStore'
import {FieldUpdate} from 'src/models/Field/FieldUpdate'
import ApplicationFormFounder from 'src/components/Applications/Form/Client/ApplicationFormFounder.vue'
import {useQuasar} from 'quasar'
import {ApplicationSubStatusEnum} from 'src/models/Applications/ApplicationSubStatusEnum'

const props = defineProps<{
  headFields: ProductFields,
  foundersFields: ProductFields[],
  appId: string,
}>()

const applicationControlCenterItemStore = useApplicationControlCenterItemStore(props.appId)()

const {
  client,
  openedFounderId,
  addFounderLoading,
  banks,
} = storeToRefs(applicationControlCenterItemStore)

const {
  addFounder,
  deleteFounder,
  updateClientHeadFields,
  updateClientFounderFields,
  updateAddressDetails,
  uploadPassportByFounderId,
} = applicationControlCenterItemStore

const fillStore = useApplicationControlCenterItemFillStore(props.appId)()

const {
  founderHasError,
} = fillStore

const {
  clientHeadFieldsWithErrors,
} = storeToRefs(fillStore)

const {
  search,
  showFounder,
} = useClientHeadAndFounders()

const $q = useQuasar()

const loadings = ref<Record<string, boolean>>({})

const canAddFounder = computed(() => {
  const bank = banks.value[0]
  if (!bank) {
    return false
  }

  return [
    ApplicationSubStatusEnum.completion,
    ApplicationSubStatusEnum.data_request,
    ApplicationSubStatusEnum.risks_request,
    ApplicationSubStatusEnum.bg_project_customer_agreement,
    ApplicationSubStatusEnum.bg_project_bank_agreement_request,
    ApplicationSubStatusEnum.bg_project_bank_agreement_request,
    ApplicationSubStatusEnum.deferred_conditions_execution_waiting,
    ApplicationSubStatusEnum.guarantee_released_request,
  ].includes(bank.sub_status)
})

const onUpdateFieldValue = async (
  data: FieldUpdate[],
  founderId: string,
  file?: File,
) => {
  if (!props.appId) {
    return
  }

  if (founderId === 'head') {
    await updateClientHeadFields(data)
    onPassportRecognized(file)

    return
  }

  await updateClientFounderFields(data, founderId)
  onPassportRecognized(file, founderId)
}

const onPassportRecognized = (file?: File, founderId?: string) => {
  if (!file) {
    return
  }
  uploadPassportByFounderId(file, founderId)
}

const deleteHandler = async (founderId: string) => {
  loadings.value[founderId] = true
  await deleteFounder(founderId)
  loadings.value[founderId] = false
}

const isOk = (founderId: string) => {
  if (!founderId || founderId === 'head') {
    return clientHeadFieldsWithErrors.value.length === 0
  }

  return !founderHasError(founderId)
}

const showHead = computed(() => {
  return showFounder(props.headFields)
})
</script>

<style lang="scss">
body{
  .ApplicationControlCenterItemHeadAndFounders {
    border-radius: 6px;

    .q-field {
      .q-field__label {
        top: -32px !important;
        left: -49px !important;
      }
    }
  }
}
</style>
