<template>
  <div class="q-mb-xs">
    <q-btn
      align="left"
      size="14px"
      color="blue-grey-10"
      unelevated
      @click="emit('toggle')"
    >
      <q-icon
        name="svguse:icons.svg?2#app-chevron-double-left"
        color="white"
        size="14px"
      />
    </q-btn>
  </div>
  <div
    v-if="atLeastOneDraftBankIsOkEbg || allDraftBankIsOkEbg || atLeastOneDraftBankIsOkKik || allDraftBankIsOkKik"
    class="q-mb-xs"
  >
    <q-btn
      align="left"
      size="14px"
      color="green-14"
      unelevated
      @click="sendDialogShow"
    >
      <q-icon
        name="svguse:icons.svg?2#app-arrow-right-bold"
        color="white"
        size="14px"
      />
    </q-btn>
  </div>
  <div
    v-if="countErrorFields"
    class="q-mb-xs"
  >
    <q-btn
      align="left"
      size="14px"
      color="orange-6"
      unelevated
      @click="showNextField"
    >
      <q-icon
        name="svguse:icons.svg?2#app-cursor-click"
        color="white"
        size="14px"
      />
    </q-btn>
  </div>
</template>

<script setup lang="ts">
import {computed} from 'vue'
import {storeToRefs} from 'pinia'
import {useRoute} from 'vue-router'
import {useFillNext} from 'src/uses/Applications/Form/fill/useFillNext'
import {useApplicationItemSendStore} from 'src/stores/Applications/Item/useApplicationItemSendStore'
import {useApplicationItemEbgFillStore} from 'stores/Applications/Item/useApplicationItemEbgFillStore'
import {useApplicationItemKikFillStore} from 'stores/Applications/Item/useApplicationItemKikFillStore'

const emit = defineEmits<{
  (e: 'toggle'): void,
}>()

const route = useRoute()
const multiOrderId = computed(() => parseInt(route.params.formId as string))

const applicationItemSendStore = useApplicationItemSendStore(multiOrderId.value)()

const {
  sendDialogShow,
} = applicationItemSendStore

const fillEbgStore = useApplicationItemEbgFillStore(multiOrderId.value)()
const {
  atLeastOneDraftBankIsOk: atLeastOneDraftBankIsOkEbg,
  allDraftBankIsOk: allDraftBankIsOkEbg,
} = storeToRefs(fillEbgStore)

const fillKikStore = useApplicationItemKikFillStore(multiOrderId.value)()
const {
  atLeastOneDraftBankIsOk: atLeastOneDraftBankIsOkKik,
  allDraftBankIsOk: allDraftBankIsOkKik,
} = storeToRefs(fillKikStore)

const {
  countErrorFields,
  showNextField,
} = useFillNext(multiOrderId.value)
</script>
