import {useOptionsFromObject} from 'src/uses/useOptionsFromObject'
import {ReportTypeEnum} from 'src/models/Profile/Reports/ReportTypeEnum'

const reportTypes: Record<ReportTypeEnum, string> = {
  [ReportTypeEnum.OrdersReport]: 'Отчет по сделкам',
  [ReportTypeEnum.ReleaseReport]: 'Отчет по выпускам',
}

export const useReportType = () => {
  const {getOptionsFromObject} = useOptionsFromObject()
  const options = getOptionsFromObject(reportTypes)
  const getReportTypeLabel = (reportType: ReportTypeEnum): string => {
    return reportTypes[reportType] || ''
  }

  return {
    getReportTypeLabel,
    options,
  }
}
