import {usePutApi} from 'src/uses/Api/usePutApi'
import {ApplicationFormUpdateResponse} from 'src/models/Applications/Form/ApplicationFormUpdateResponse'

export const useLoadBO = () => {
  const {
    error,
    errors,
    put,
    status,
    response,
    loading,
  } = usePutApi<ApplicationFormUpdateResponse, {years: number[]}>()

  const loadBO = async (years: number[], multiOrderId?: number, appId?: string) => {
    if (!multiOrderId && !appId) {
      return
    }

    if (multiOrderId) {
      await put(
        `v2/orders/forms/${multiOrderId}/finance/load`,
        {
          years
        }
      )

      return
    }

    await put(
      `v2/control-center/${appId}/finance/load`,
      {
        years
      }
    )
  }

  return {
    error,
    errors,
    put,
    status,
    response,
    loading,
    loadBO,
  }
}
