import {
  ApplicationFormResponseData
} from 'src/models/Applications/Form/ApplicationFormResponseData'
import {Ref} from 'vue'
import {FieldsCodesEnum} from 'src/models/FieldsCodesEnum'

export const useGetFieldByCode = (item: Ref<ApplicationFormResponseData | undefined>) => {
  const getFieldByCode = (code: FieldsCodesEnum) => {
    if (!item.value) {
      return undefined
    }

    if (!item.value.products.EBG && !item.value.products.KIK) {
      return undefined
    }

    const result = item.value?.products.EBG?.order.main.fields
      .find(f => f.code === code)

    if (result) {
      return result
    }

    return item.value?.products.KIK?.order.main.fields
      .find(f => f.code === code)
  }

  return {
    getFieldByCode,
  }
}
