import {RouterGuard} from 'src/models/Router/RouterGuard'
import {useApplicationGridTypeStore} from 'stores/Applications/Grid/useApplicationGridTypeStore'
/*import { Platform } from 'quasar'
import {ApplicationGridTypeEnum} from 'src/models/Applications/ApplicationGridTypeEnum'*/

export const ApplicationGridTypeGuard: () => RouterGuard = () => ({
  canAccess: (from, to) => {
    if (!to) {
      return true
    }

    /*if (Platform.is.mobile && useApplicationGridTypeStore().applicationGridType === ApplicationGridTypeEnum.multiGrid) {
      return false
    }*/

    return useApplicationGridTypeStore().applicationGridType === to.name
  },
  redirectTo: (from, to) => {
    /*if (Platform.is.mobile && useApplicationGridTypeStore().applicationGridType === ApplicationGridTypeEnum.multiGrid) {
      useApplicationGridTypeStore().applicationGridType = ApplicationGridTypeEnum.grid
    }*/

    return {
      name: useApplicationGridTypeStore().applicationGridType,
      ...(to ? {params: to.params, query: to.query} : {})
    }
  }
})
