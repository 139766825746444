<template>
  <q-dialog
    v-model="agreementConfirmDialog"
  >
    <q-card
      class="q-mt-md bg-blue-1"
      style="min-width: 400px; max-width: 800px; border-radius: 4px !important;"
    >
      <q-card-section class="text-h6 rounded-borders">
        <strong>Подтвердите данные</strong>
      </q-card-section>
      <q-card-section class="bg-blue-grey-10 text-white row items-center q-py-lg">
        <div class="rounded-borders q-pa-sm bg-blue-grey-9">
          <q-icon
            name="svguse:icons.svg?2#app-information-circle"
            size="sm"
            color="orange-6"
          />
        </div>
        <div class="col q-pl-sm text-wgite">
          Мы заметили, что вы внесли корректировки в параметры заявки.
          Для корректного согласования правок необходимо убедиться,
          что данные в проекте БГ и в системе совпадают.
          <div class="q-mt-sm">При необходимости скорректируйте данные и обновите проект.</div>
        </div>
      </q-card-section>
      <q-card-section class="white-bg-field">
        <agreement-confirm-editable-fields
          v-if="lastStatusPayload"
          ref="agreementConfirmEditableFieldsRef"
          :app-id="lastStatusPayload.id"
        />
      </q-card-section>
      <q-card-actions
        class="row justify-end q-py-md"
        style="background: #D5EAFA;"
      >
        <q-btn
          v-close-popup
          size="16px"
          padding="sm lg"
          label="Отмена"
          unelevated
          no-caps
        />
        <q-btn
          :disable="loading"
          :loading="loading"
          padding="sm lg"
          size="16px"
          color="blue-7"
          label="Данные совпадают"
          unelevated
          no-caps
          @click="statusStore.onAgreementConfirm()"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
import {useStatusStore} from 'src/stores/Status/useStatusStore'
import {storeToRefs} from 'pinia'
import AgreementConfirmEditableFields from 'src/components/Applications/AgreementConfirmEditableFields.vue'
import {computed, ref} from 'vue'

const statusStore = useStatusStore()
const {
  agreementConfirmDialog,
  lastStatusPayload,
} = storeToRefs(statusStore)

const agreementConfirmEditableFieldsRef = ref<typeof AgreementConfirmEditableFields>()

const loading = computed(() => {
  if (!agreementConfirmEditableFieldsRef.value) {
    return true
  }

  return agreementConfirmEditableFieldsRef.value.loading
})
</script>

<style lang="scss">

</style>
