<template>
  <form-select
    :model-value="props.modelValue"
    :options="options"
    label="Выберите сотрудника"
    :loading="employeesLoading || props.loading"
    top-actions
    @update:model-value="onChange"
  />
</template>

<script setup lang="ts">
import {storeToRefs} from 'pinia'
import {onBeforeMount, onBeforeUnmount, computed} from 'vue'
import {useEmployeeFilterSelectStore} from 'src/stores/Profile/Reports/useEmployeeFilterStore'
import FormSelect from 'src/components/FormElements/FormSelect.vue'
import {FormElementValue} from 'src/models/Form'

const props = defineProps<{
  modelValue: string[],
  loading: boolean,
}>()

const emit = defineEmits<{
  (e: 'update:model-value', employees: string[]): void,
  (e: 'change'): void,
}>()

const reportEmployeesStore = useEmployeeFilterSelectStore()
const {
  load,
} = reportEmployeesStore
const {
  loading: employeesLoading,
  employees
} = storeToRefs(reportEmployeesStore)

const options = computed(() => {
  return employees?.value
    .map(employee => ({
      label: `${employee.name_first} ${employee.name_last}`,
      value: employee.id,
    }))
})

onBeforeMount(() => {
  if (employees.value.length === 0) {
    load()
  }
})

onBeforeUnmount(() => {
  reportEmployeesStore.$dispose()
})

const onChange = (val: FormElementValue) => {
  emit('update:model-value', val as string[])
  emit('change')
}
</script>
