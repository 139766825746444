<template>
  <page-block-wrapper>
    <div class="banks">
      <banks-grid />
    </div>
  </page-block-wrapper>
</template>
<script setup lang="ts">
import PageBlockWrapper from 'src/components/PageBlockWrapper.vue'
import {useMetaStore} from 'src/stores/useMetaStore'
import BanksGrid from 'components/Banks/BanksGrid.vue'

useMetaStore().setTitle('Банки')
</script>
