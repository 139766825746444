<template>
  <form-group>
    <q-select
      v-model="provisionType"
      :disable="isBlocked('provisionType')"
      class="field-with-top-label q-mb-sm"
      :options="provisionTypes"
      option-value="value"
      label="Тип обеспечения"
      :error="false"
      emit-value
      map-options
      outlined
      hide-bottom-space
      dense
    />
    <div style="min-height: 40px;">
      <div
        v-if="enabledOneBgForAllBeneficiaries"
        class="q-mr-md"
        style="margin-left: -10px;"
      >
        <q-toggle
          v-model="oneBg"
          :disable="isBlocked('oneBg')"
          label="Одна БГ на всех бенефициаров"
          color="blue-7"
        />
      </div>
      <div>
        <q-toggle
          v-model="customerBgForm"
          :disable="isBlocked('customerBgForm')"
          style="margin-left: -10px;"
          label="Предусмотрена форма заказчика"
        />
      </div>
    </div>
  </form-group>
</template>

<script setup lang="ts">
import FormGroup from 'src/components/FormGroup.vue'
import {useProvisionType} from 'src/uses/Applications/useProvosoinType'
import {storeToRefs} from 'pinia'
import {useApplicationsNew} from 'src/stores/Applications/ApplicationsNew/useApplicationsNew'
import {ref, watch} from 'vue'
import {QSelect} from 'quasar'
import {useScrollTo} from 'src/uses/useScrollTo'

const provisionTypeSelect = ref<QSelect>()

const {
  options: provisionTypes,
} = useProvisionType()

const applicationsNewStore = useApplicationsNew()
const {
  provisionType,
  oneBg,
  enabledOneBgForAllBeneficiaries,
  lastFocused,
  customerBgForm,
} = storeToRefs(applicationsNewStore)

const {
  scrollToInvisibleElement,
} = useScrollTo()

const {
  isBlocked,
} = applicationsNewStore

watch(
  lastFocused,
  () => {
    if (lastFocused.value !== 'provisionType' || !provisionTypeSelect.value) {
      return
    }

    provisionTypeSelect.value?.focus()
    scrollToInvisibleElement(provisionTypeSelect.value?.$el)
  }
)
</script>
