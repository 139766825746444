import {computed, onBeforeMount, ref, Ref, watchEffect} from 'vue'
import useVuelidate, {ValidationArgs} from '@vuelidate/core'
import {usePutApi} from 'src/uses/Api/usePutApi'
import {useValidationRules} from 'src/uses/useValidationRules'
import {OpfEnum} from 'src/models/OpfEnum'
import {useGetProfileRequisites} from 'src/stores/Profile/Requisites/useGetProfileRequisites'
import {CloneHelper} from 'src/helpers/CloneHelper'

export const useEditProfileRequisites = <AgentRequisitesResponseInterface = Record<string, unknown>>(opf: OpfEnum, rulesInit: ValidationArgs) => {
  const {
    load,
    AgentRequisitesResponse,
  } = useGetProfileRequisites<AgentRequisitesResponseInterface>(opf)

  const {
    put,
    response,
    status,
    loading,
    error,
    errors,
  } = usePutApi<never, AgentRequisitesResponseInterface>()

  const {
    getRulesFromState,
  } = useValidationRules()

  const agentData = ref<AgentRequisitesResponseInterface>()
  const success = ref(false)
  const rules = computed<ValidationArgs>(() => {
    return {
      ...getRulesFromState(agentData.value || {}),
      ...rulesInit,
    }
  })
  const validation = useVuelidate<Ref<AgentRequisitesResponseInterface | undefined>>(rules, agentData)

  const save = async () => {
    success.value = false
    if (!agentData.value
      || !await validation.value.$validate()
    ) {
      return
    }

    await put(`v1/profile/requisites/${opf.toLowerCase()}`, agentData.value)
    loading.value = true
    await awaitTimeout()
    loading.value = false
    if (status.value === 200 && response.value) {
      success.value = true
    }
  }

  const awaitTimeout = async () => {
    return new Promise(resolve => setTimeout(resolve, 1500))
  }

  onBeforeMount(async () => {
    await load()
  })

  watchEffect(() => {
    if (!AgentRequisitesResponse.value) {
      return
    }

    agentData.value = CloneHelper.clone(AgentRequisitesResponse.value)
  })

  return {
    agentData,
    loading,
    response,
    status,
    error,
    errors,
    validation,
    success,
    save,
  }
}
