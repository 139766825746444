<template>
  <q-dialog
    :model-value="props.modelValue"
    :full-width="$q.platform.is.mobile"
  >
    <q-card
      :class="{'q-ma-md': true, 'full-width': $q.platform.is.mobile}"
    >
      <q-card-section>
        <div class="text-h5 text-center">
          <strong>Письмо отправлено!</strong>
        </div>
      </q-card-section>
      <q-card-section>
        Проверьте ваш электронный адес, на него отправлено письмо с инструкциями по изменению пароля
      </q-card-section>
      <q-card-actions class="row justify-center">
        <q-btn
          label="Ок"
          color="primary"
          class="full-width"
          style="height: 50px;"
          unelevated
          no-caps
          @click="emit('update:model-value')"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
import {useQuasar} from 'quasar'

const props = defineProps<{
  modelValue: boolean
}>()
const emit = defineEmits<{
  (e: 'update:model-value'): void
}>()

const $q = useQuasar()
</script>

<style scoped lang="scss">

</style>
