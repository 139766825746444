<template>
  <div class="text-h6 q-mb-md">
    Основные параметры
  </div>
  <fields-block-component
    :fields="props.fields"
    :banks="banks"
    @update:value="updateValue"
    @update:address-details="updateAddressDetails"
  />
</template>

<script setup lang="ts">
import {
  ApplicationFormField,
} from 'src/models/Applications/Form/ApplicationFormResponseData'
import {FormElementValue} from 'src/models/Form'
import {ProductFields} from 'src/models/Applications/Form/ProductFields'
import FieldsBlockComponent from 'src/components/Applications/Form/FieldsBlockComponent.vue'
import {storeToRefs} from 'pinia'
import {
  useApplicationControlCenterItemStore
} from 'src/stores/Applications/ControlCenter/useApplicationControlCenterItemStore'

const props = defineProps<{
  fields: ProductFields,
  appId: string
}>()

const applicationItemStore = useApplicationControlCenterItemStore(props.appId)()
const {
  updateMainFields,
  updateAddressDetails,
} = applicationItemStore

const {
  banks,
} = storeToRefs(applicationItemStore)

const updateValue = (value: FormElementValue, field: ApplicationFormField) => {
  if (!props.appId) {
    return
  }

  updateMainFields(
    [
      {
        code: field.code,
        value,
      }
    ]
  )
}
</script>
