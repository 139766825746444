<template>
  <pagination-component
    class="q-mb-md"
    :pagination="gridPagination"
    :per-page-options="perPageOptions"
    @request="onRequest"
    @refresh="load(listingId)"
  />
  <div class="row ListingsItemRecordsHeader items-center">
    <q-btn
      class="back-btn text-blue-grey-4"
      icon="svguse:icons.svg?2#app-arrow-left"
      label="Назад"
      style="background-color: #F5F5F5"
      padding="8px 10px"
      unelevated
      no-caps
      @click="backClick"
    />
    <div
      v-if="!listingsItemLoading"
      class="text-left col ListingsItemRecordsHeader--title"
    >
      {{listingsItemTitle}}
    </div>
  </div>
  <div class="row q-mb-md q-mt-md">
    <div class="col-8">
      <listings-item-records-grid-filters
        :loading="loading"
        @update:filters="updateFilters"
      />
    </div>
    <div class="col-4 text-right">
      <listings-item-record-create-update-modal
        :listings-id="listingsItem.id"
        @success="updateFilters"
      />
    </div>
  </div>
  <div class="ListingsGrid--table">
    <q-table
      v-model:pagination="gridPagination"
      class="sticky-header-table"
      :rows="items"
      :columns="columns"
      :loading="loading"
      :rows-per-page-options="perPageOptions"
      separator="none"
      row-key="inn"
      hide-bottom
      wrap-cells
      @request="onRequest"
    >
      <template #body-cell-id="props">
        <q-td :props="props">
          {{ props.row.id }}
        </q-td>
      </template>
      <template #body-cell-key="props">
        <q-td :props="props">
          {{ props.row.key }}
        </q-td>
      </template>
      <template #body-cell-value="props">
        <q-td :props="props">
          {{ props.row.value }}
        </q-td>
      </template>
      <template #body-cell-actions="props">
        <q-td :props="props">
          <listings-item-record-enabled-modal
            :listings-id="listingId"
            :record-id="props.row.id"
            v-model="props.row.enabled"
          />
          <listings-item-record-create-update-modal
            :item="props.row"
            :listings-id="listingsItem.id"
          />
          <listings-item-record-delete-modal
            :record-id="props.row.id"
            :item-id="listingsItem.id"
            @change="updateFilters"
          />
        </q-td>
      </template>
    </q-table>
  </div>
</template>

<script setup lang="ts">
import {storeToRefs} from 'pinia'
import {computed, onBeforeMount, onBeforeUnmount} from 'vue'
import {useGridSettings} from 'src/uses/Grid/useGridSettings'
import {GridSortingEnum} from 'src/models/Grid/GridSortingEnum'
import {GridPagination, RequestProp} from 'src/models/Grid/Pagination'
import {useListingsItemRecordsGridStore} from 'stores/Listings/ListingsRecord/useListingsItemRecordsGridStore'
import {useListingsItemRecordsGridSortStore} from 'stores/Listings/ListingsRecord/useListingsItemRecordsGridSortStore'
import {useRoute, useRouter} from 'vue-router'
import {ListingsItemRecordsGridColumns} from 'components/Listings/ListingRecords/ListingsItemRecordsGridColumns'
import {
  useListingsItemRecordsGridFiltersStore
} from 'stores/Listings/ListingsRecord/useListingsItemRecordsGridFiltersStore'
import ListingsItemRecordsGridFilters from 'components/Listings/ListingRecords/ListingsItemRecordsGridFilters.vue'
import ListingsItemRecordCreateUpdateModal
  from 'components/Listings/ListingRecords/ListingsItemRecordCreateUpdateModal.vue'
import ListingsItemRecordDeleteModal from 'components/Listings/ListingRecords/ListingsItemRecordDeleteModal.vue'
import ListingsItemRecordEnabledModal from 'components/Listings/ListingRecords/ListingsItemRecordEnabledModal.vue'
import PaginationComponent from 'components/PaginationComponent.vue'
import {useListingsItem} from 'src/uses/Listings/useListingsItem'

const filtersStore = useListingsItemRecordsGridFiltersStore()
const listingsItemRecordsGridStore = useListingsItemRecordsGridStore()
const {
  items,
  gridPagination,
  perPageOptions,
  loading,
} = storeToRefs(listingsItemRecordsGridStore)

const {
  load,
  onRequestPagination,
  setPage,
} = listingsItemRecordsGridStore

const listingsItemRecordsGridSortStore = useListingsItemRecordsGridSortStore()
const {
  sorting,
} = storeToRefs(listingsItemRecordsGridSortStore)

const {
  loadListingsItem,
  listingsItem,
  loading: listingsItemLoading,
} = useListingsItem()

const {columns} = useGridSettings('listing-records-grid', ListingsItemRecordsGridColumns)
const route = useRoute()
const router = useRouter()
const listingId = computed(() => route.params.listingId as string)

onBeforeMount(async () => {
  await loadListingsItem(listingId.value)
  await load(listingId.value)
})

onBeforeUnmount(() => {
  listingsItemRecordsGridStore.$reset()
  listingsItemRecordsGridStore.$dispose()
  filtersStore.$reset()
  filtersStore.$dispose()
})

const listingsItemTitle = computed(() => listingsItem.value.title || '')

const onRequest = ({pagination}: RequestProp) => {
  sorting.value.direction = pagination.descending ? GridSortingEnum.DESC : GridSortingEnum.ASC
  sorting.value.sort = pagination.sortBy || ''
  onRequestPagination(pagination as GridPagination)
  load(listingId.value)
}

const updateFilters = () => {
  setPage(1)
  load(listingId.value)
}

const backClick = () => {
  try {
    router.resolve(window.history.state.back)
    router.back()
  } catch (e) {
    console.log(e)
    router.push({name: 'applications-grid'})
  }
}

</script>

<style lang="scss">
.ListingsItemRecordsHeader {
  border-bottom: 1px solid #ECEFF1;
  margin: 0 -24px;
  padding: 0 24px 18px;
  .ListingsItemRecordsHeader--title {
    font-size: 22px;
    font-weight: 700;
    line-height: 21px;
    letter-spacing: 0;
    text-align: left;
    color: #424242;
    margin-left: 20px;
  }
}
.ListingsGrid--table {
  margin: 0 -24px -24px;
  background: $blue-1;
  padding: 0 24px 24px;

  table {
    position: relative;

    tr {
      td {
        width: 150px;

        &:first-child {
          width: auto;
          white-space: normal;
        }
      }
    }
  }

  .createButton {
    .icon {
      background: white;
      border-radius: 6px;
      position: absolute;
      right: 10px;
      line-height: 0;
    }

    .q-btn {
      border-radius: 6px;
    }
  }
}
</style>
