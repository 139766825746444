<template>
  <div class="column fit">
    <div class="row q-pa-md control-center-chats-history">
      <div class="col">
        <q-btn-toggle
          v-model="chatHistoryTabs"
          :style="buttonStyles"
          toggle-color="primary"
          color="white"
          text-color="grey-9"
          toggle-text-color="white"
          :options="chatHistoryTabsOptions"
          spread
          unelevated
          no-caps
        />
      </div>
      <div class="mobile-hide">
        <q-btn
          v-if="props.dialog"
          unelevated
          icon="svguse:/icons.svg#chat-close"
          @click="closeModal()"
        />
        <q-btn
          v-else
          unelevated
          icon="svguse:icons.svg?2#app-arrows-expand"
          @click="openModal()"
        />
      </div>
    </div>
    <div class="col">
      <q-tab-panels
        v-model="chatHistoryTabs"
        class="bg-transparent fit"
      >
        <q-tab-panel
          :name="ChatHistoryTabsEnum.chat"
          class="q-pa-none fit"
        >
          <application-control-center-chat
            v-if="props.headerData"
            :header-data="props.headerData"
            :original="props.original"
          />
        </q-tab-panel>
        <q-tab-panel
          :name="ChatHistoryTabsEnum.history"
          class="q-pa-none history-tab-panel fit"
        >
          <application-control-center-history
            v-if="props.headerData"
            :header-data="props.headerData"
            :original="props.original"
          />
        </q-tab-panel>
      </q-tab-panels>
    </div>
  </div>
</template>

<script setup lang="ts">
import {ControlCenterHeader} from 'src/models/Applications/ControlCenter/ControlCenterHeader'
import ApplicationControlCenterChat
  from 'src/components/Applications/ApplicationControlCenter/ApplicationControlCenterChat.vue'
import {ChatHistoryTabsEnum} from 'src/models/ChatHistoryTabsEnum'
import {useControlCenterMain} from 'src/uses/Applications/ControlCenter/useControlCenterMain'
import ApplicationControlCenterHistory
  from 'src/components/Applications/ApplicationControlCenter/History/ApplicationControlCenterHistory.vue'
import {useApplicationControlCenterStore} from 'src/stores/Applications/ControlCenter/useApplicationControlCenterStore'
import {storeToRefs} from 'pinia'
import {computed, onBeforeMount} from 'vue'
import {useRoute} from 'vue-router'
import {Platform} from 'quasar'

const emit = defineEmits<{
  (e: 'update:dialog', dialog: boolean): void,
}>()

const closeModal = () => {
  emit('update:dialog', false)
}

const openModal = () => {
  emit('update:dialog', true)
}

const props = defineProps<{
  headerData: ControlCenterHeader,
  dialog: boolean,
  original: boolean,
}>()

const route = useRoute()

const applicationControlCenterStore = useApplicationControlCenterStore(props.headerData.id)()

const {
  chatHistoryTabs,
} = storeToRefs(applicationControlCenterStore)

const {
  chatHistoryTabsOptions
} = useControlCenterMain()

const buttonStyles = computed(() => {
  return !Platform.is.mobile ? 'max-width: 300px; max-height: 1px' : ''
})

onBeforeMount(() => {
  if (route.query.chat !== 'true' || route.name !== 'application-control-center') {
    return
  }

  openModal()
})
</script>
<style lang="scss">
.control-center-chats-history {
  background-color: #D5EAFA;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}
</style>
