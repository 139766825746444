<template>
  <div class="FounderPassportScanRecognize row items-center q-px-lg q-pt-lg q-pb-md q-mb-lg">
    <q-file
      v-show="false"
      ref="filePickerRef"
      v-model="file"
      accept=".jpg, .jpeg, .pdf"
    />
    <div class="col col-xs-12 q-mb-md">
      <div>Заполнить данными из скана паспорта руководителя</div>
      (Распознаются только файлы .pdf и .jpeg)
    </div>
    <q-btn
      class="col-xs-12 col-md-auto q-mb-md"
      label="Загрузить скан паспорта"
      icon-right="svguse:icons.svg?2#app-download"
      color="blue-7"
      padding="13px 20px"
      :loading="loading"
      :disable="loading"
      unelevated
      no-wrap
      no-caps
      @click="startSelectFile"
    />
  </div>
</template>

<script setup lang="ts">
import {ref, watchEffect} from 'vue'
import {usePassportRecognize} from 'src/uses/usePassportRecognize'
import {QFile} from 'quasar'
import {PassportRecognizeResponse} from 'src/models/PassportRecognizeResponse'

const emit = defineEmits<{
  (e: 'recognized:data', modelValue: PassportRecognizeResponse, file: File): void,
}>()

const {
  recognize,
  response,
  status,
  loading,
} = usePassportRecognize()

const file = ref<File>()
const filePickerRef = ref<QFile>()

const startSelectFile = (event: Event) => {
  if (!filePickerRef.value) {
    return
  }

  filePickerRef.value.pickFiles(event)
}

watchEffect(async () => {
  if (!file.value) {
    return
  }

  await recognize(file.value)

  if (status.value !== 200 || !response.value) {
    return
  }

  emit('recognized:data', response.value, file.value)
})
</script>

<style lang="scss">
.FounderPassportScanRecognize {
  border-radius: 6px;
  border: 1px solid $blue-grey-2;
  color: $grey;

  & > div > div {
    color:$grey-7;
  }
}

.PageBlockWrapper__mobile {

}
</style>
