<template>
  <div>
    <date-item :model-value="item.date" />
  </div>
  <div
    style="width: 125px;"
    class="ellipsis-2-lines"
  >
    Заявка:
    <template v-if="props.onlyText">
      {{ item.number }}
    </template>
    <router-link
      v-else
      :to="to"
      style="white-space: normal"
      @click="sendEvent('Open_control_center_via_applications_number')"
    >
      {{ item.number }}
      <q-tooltip
        :delay="1000"
        max-width="300px"
      >
        {{ item.number }}
      </q-tooltip>
    </router-link>
  </div>
  <div v-if="item.guarantee_number">
    БГ: {{ item.guarantee_number }}
  </div>
</template>

<script setup lang="ts">
import DateItem from 'src/components/DateItem.vue'
import {computed} from 'vue'
import {ApplicationsGridItem} from 'src/models/Applications/ApplicationsGridItem'
import {useGridChangeStatusAction} from 'src/uses/Grid/useGridChangeStatusAction'
import {usePosthogStore} from 'stores/Posthog/usePosthogStore'

const props = withDefaults(
  defineProps<{
    item: ApplicationsGridItem
    onlyText?: boolean
  }>(),
  {
    onlyText: false,
  }
)

const {sendEvent} = usePosthogStore()
const gridActions = useGridChangeStatusAction()
const {
  isDraft,
  isSystemRejected,
} = gridActions

const item = computed(() => props.item)

const to = computed(() => {
  return isDraft(item.value) || isSystemRejected(item.value)
    ? {
        name: 'application-item',
        params: {
          formId: item.value.multi_order_id,
        }
      }
    : {
        name: 'application-control-center',
        params: {
          appId: item.value.id,
          formId: item.value.form_id,
        }
      }
})
</script>
