export default {
  message: 'hello i18n !!',
  'Columns settings': 'Columns settings',
  validations: {
    required: 'Значение должно быть заполнено',
    email: 'Необходимо указать E-mail',
    integer: 'Необходимо указать целое число',
    minLength: 'Минимальное количество символов - {min}',
    maxLength: 'Максимальное количество символов - {max}',
    phoneValidator: 'Укажите действующий номер телефона',
    passwordValidator: 'Ненадежный пароль',
    nameUpperLowerCaseValidator: 'Допускаются только прописные и строчные буквы русского алфавита',
    urlValidator: 'Необходимо указать ссылку',
  },
}
