<template>
  <filter-pin
    v-if="!onlyPinned || isPinned('number')"
    v-model="pins.number"
    :no-pin="props.onlyPinned || props.ignorePins"
  >
    <q-input
      v-model="filters.number"
      :loading="props.loading"
      autocomplete="new-password"
      label="Поиск по номеру заявки"
      clearable
      outlined
      hide-hint
      hide-bottom-space
      dense
      @change="onSubmit"
      @clear="onSubmit"
    >
      <template #prepend>
        <q-icon name="svguse:icons.svg?2#app-search"/>
      </template>
    </q-input>
  </filter-pin>

  <filter-pin
    v-if="!onlyPinned || isPinned('client')"
    v-model="pins.client"
    :no-pin="props.onlyPinned || props.ignorePins"
  >
    <q-input
      v-model="filters.client"
      :loading="props.loading"
      autocomplete="new-password"
      label="Поиск по ИНН или наименованию"
      clearable
      outlined
      hide-hint
      hide-bottom-space
      dense
      @change="onSubmit"
      @clear="onSubmit"
    >
      <template #prepend>
        <q-icon name="svguse:icons.svg?2#app-search" />
      </template>
    </q-input>
  </filter-pin>

  <filter-pin
    v-if="isVisibleFilterByAgent && (!onlyPinned || isPinned('agent'))"
    v-model="pins.agent"
    :no-pin="props.onlyPinned || props.ignorePins"
  >
    <q-input
      v-model="filters.agent"
      :loading="props.loading"
      autocomplete="new-password"
      label="Поиск по ИНН / названию агента"
      clearable
      outlined
      hide-hint
      hide-bottom-space
      dense
      @change="onSubmit"
      @clear="onSubmit"
    >
      <template #prepend>
        <q-icon name="svguse:icons.svg?2#app-search" />
      </template>
    </q-input>
  </filter-pin>

  <filter-pin
    v-if="!onlyPinned || isPinned('rnt')"
    v-model="pins.rnt"
    :no-pin="props.onlyPinned || props.ignorePins"
  >
    <q-input
      v-model="filters.rnt"
      :loading="props.loading"
      autocomplete="new-password"
      label="Поиск по РНТ"
      clearable
      outlined
      hide-hint
      hide-bottom-space
      dense
      @change="onSubmit"
      @clear="onSubmit"
    >
      <template #prepend>
        <q-icon name="svguse:icons.svg?2#app-search" />
      </template>
    </q-input>
  </filter-pin>

  <filter-pin
    v-if="!onlyPinned || isPinned('banks')"
    v-model="pins.banks"
    :no-pin="props.onlyPinned || props.ignorePins"
  >
    <bank-filter
      v-model="filters.banks"
      :loading="loading"
      :product-type="ProductEnum.EBG"
      @change="onSubmit"
    />
  </filter-pin>

  <filter-pin
    v-if="(isAdmin() || isAgentManager()) && (!onlyPinned || isPinned('guarantee_total_from'))"
    v-model="pins.guarantee_total_from"
    :no-pin="props.onlyPinned || props.ignorePins"
  >
    <q-input
      v-model="filters.guarantee_total_from"
      class="filterSumInput"
      :loading="props.loading"
      label="Сумма банковской гарантии с"
      clearable
      outlined
      hide-hint
      hide-bottom-space
      dense
      @change="onSubmit"
      @clear="onSubmit"
    />
  </filter-pin>

  <filter-pin
    v-if="(isAdmin() || isAgentManager()) && (!onlyPinned || isPinned('guarantee_total_to'))"
    v-model="pins.guarantee_total_to"
    :no-pin="props.onlyPinned || props.ignorePins"
  >
    <q-input
      v-model="filters.guarantee_total_to"
      class="filterSumInput"
      :loading="props.loading"
      label="Сумма банковской гарантии по"
      clearable
      outlined
      hide-hint
      hide-bottom-space
      dense
      @change="onSubmit"
      @clear="onSubmit"
    />
  </filter-pin>

  <filter-pin
    v-if="(isAdmin() || isAgentManager()) && (!onlyPinned || isPinned('release_date_from'))"
    v-model="pins.release_date_from"
    :no-pin="props.onlyPinned || props.ignorePins"
  >
    <q-input
      v-model="filters.release_date_from"
      class="FilterDateInput"
      :loading="props.loading"
      type="date"
      label="Плановая дата выпуска с"
      clearable
      outlined
      hide-hint
      hide-bottom-space
      dense
      @change="onSubmit"
      @clear="onSubmit"
    />
  </filter-pin>

  <filter-pin
    v-if="(isAdmin() || isAgentManager()) && (!onlyPinned || isPinned('release_date_to'))"
    v-model="pins.release_date_to"
    :no-pin="props.onlyPinned || props.ignorePins"
  >
    <q-input
      v-model="filters.release_date_to"
      class="FilterDateInput"
      :loading="props.loading"
      type="date"
      label="Плановая дата выпуска по"
      clearable
      outlined
      hide-hint
      hide-bottom-space
      dense
      @change="onSubmit"
      @clear="onSubmit"
    />
  </filter-pin>

  <filter-pin
    v-if="(isAgent() || isHeadEmployee()) && (!onlyPinned || isPinned('employees'))"
    v-model="pins.employees"
    :no-pin="props.onlyPinned || props.ignorePins"
  >
    <employee-filter
      v-model="filters.employees"
      :loading="loading"
      @change="onSubmit"
    />
  </filter-pin>

  <filter-pin
    v-if="(!onlyPinned || isPinned('new_request'))"
    v-model="pins.new_request"
    :no-pin="props.onlyPinned || props.ignorePins"
  >
    <q-toggle
      v-model="filters.new_request"
      label="Только сделки с допзапросом"
      @click="onSubmit"
    />
  </filter-pin>
</template>

<script setup lang="ts">
import {storeToRefs} from 'pinia'
import BankFilter from 'src/components/Applications/ApplicationsGrid/Filters/BankFilter.vue'
import FilterPin from 'src/components/Grid/FilterPin.vue'
import {useProfileStore} from 'src/stores/Login/useProfileStore'
import {computed} from 'vue'
import EmployeeFilter from 'components/Applications/ApplicationsGrid/Filters/EmployeeFilter.vue'
import {useApplicationsGridFiltersStore} from 'stores/Applications/Grid/useApplicationsGridFiltersStore'
import {ProductEnum} from 'src/models/Applications/ProductEnum'

const props = defineProps<{
  loading: boolean,
  onlyPinned: boolean,
  ignorePins: boolean,
}>()

const emit = defineEmits<{
  (e: 'update:filters'): void,
}>()

const filtersStore = useApplicationsGridFiltersStore()
const {isPinned} = filtersStore
const {filters, pins} = storeToRefs(filtersStore)
const profileStore = useProfileStore()
const {
  isAdmin,
  isAgentManager,
  isJuniorAdmin,
  isBankEmployee,
  isOperator,
  isHeadEmployee,
  isAgent,
} = profileStore
const {profile} = storeToRefs(profileStore)

const isVisibleFilterByAgent = computed(() => {
  return isAdmin()
    || isJuniorAdmin()
    || isHeadEmployee()
    || isOperator()
    || (isBankEmployee() && !profile.value?.is_hide_personal_data)
    || isAgentManager()
})

const onSubmit = () => {
  emit('update:filters')
}
</script>

<style>
.filterSumInput .q-field__label {
  top: 13px!important;
}

</style>
