import {AuthedGuard} from 'src/router/quards/AuthedGuard'

export const productsRouter = {
  path: '/products',
  component: () => import(/* webpackChunkName: "MainLayout" */ '../layouts/MainLayout.vue'),
  children: [
    {
      path: '',
      name: 'products-list',
      component: () => import(/* webpackChunkName: "ProductsView" */ '../views/Products/ProductsView.vue'),
      meta: {
        guards: [
          AuthedGuard(),
        ],
      },
    }
  ]
}
