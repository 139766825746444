<template>
  <div class="col-sm col-xs-12 bannerMainContent">
    <div>
      Ознакомьтесь с дополнительным запросом в чате, при необходимости прикрепите <br>
      документы или измените параметры заявки
    </div>
  </div>
  <div class="bannerInfoBtnWrap">
    <q-btn
      v-if="changeStatusPayload"
      class="banner-btn"
      label="Запрос исполнен"
      icon-right="svguse:icons.svg?2#app-check"
      color="primary"
      :disable="loading"
      :loading="loading"
      unelevated
      no-caps
      @click="changeStatus(changeStatusPayload, props.headerInfo.sub_status as ApplicationSubStatusEnum)"
    />
  </div>
</template>
<script setup lang="ts">
import {computed} from 'vue'
import {ControlCenterHeader} from 'src/models/Applications/ControlCenter/ControlCenterHeader'
import {
  useApplicationControlCenterChangeStatusStore
} from 'src/stores/Applications/ControlCenter/ChangeStatus/useApplicationControlCenterChangeStatusStore'
import {storeToRefs} from 'pinia'
import {ApplicationSubStatusEnum} from 'src/models/Applications/ApplicationSubStatusEnum'
import {ApplicationStatusEnum} from 'src/models/Applications/ApplicationStatusEnum'

const props = defineProps<{ headerInfo: ControlCenterHeader }>()
const headerInfo = computed(() => props.headerInfo)

const {
  requestFulfilledInitiator,
  requestFulfilledStatus,
  changeStatus,
} = useApplicationControlCenterChangeStatusStore(headerInfo.value?.id)()
const {loading} = storeToRefs(useApplicationControlCenterChangeStatusStore(props.headerInfo.id)())

const changeStatusPayload = computed(() => {
  const newStatus = requestFulfilledStatus(props.headerInfo.status as ApplicationStatusEnum, props.headerInfo.sub_status as ApplicationSubStatusEnum)
  if (!newStatus) {
    return undefined
  }

  return {
    id: props.headerInfo.id,
    applicationStatus: newStatus,
    initiator: requestFulfilledInitiator(props.headerInfo.sub_status as ApplicationSubStatusEnum)
  }
})
</script>
