<template>
  <q-intersection
    transition="scale"
    once
  >
    <div
      v-if="$q.platform.is.mobile"
      class="ApplicationNewInn-label"
    >
      Для создания новой заявки укажите ИНН клиента
    </div>
    <q-select
      :model-value="selected"
      class="field-with-top-label ApplicationNewInn"
      :options="items"
      :loading="loading || loadingClientData"
      :placeholder="selected ? '' : '000000000000'"
      label="Для создания новой заявки укажите ИНН клиента"
      :use-input="!selected"
      input-debounce="300"
      outlined
      hide-bottom-space
      dense
      emit-value
      map-options
      options-dense
      hide-dropdown-icon
      clearable
      @input-value="onInput"
      @keydown.enter="onEnter"
      @update:model-value="onUpdate"
    >
      <template #label>
        <div
          class="text-weight-medium"
          :style="$q.platform.is.mobile ? 'display:none' : ''"
        >
          Для создания новой заявки укажите ИНН клиента
        </div>
      </template>
      <template #prepend>
        <q-icon name="svguse:icons.svg?2#app-search" />
      </template>
      <template #selected-item="{opt}">
        <div class="row no-wrap full-width">
          <div>{{ opt.inn }}</div>
          <div class="text-caption q-ml-sm text-grey-7 ellipsis">{{ opt.name }}</div>
        </div>
      </template>
      <template #option="scope">
        <q-item
          v-bind="scope.itemProps"
          class="q-py-sm q-px-md text-no-wrap ellipsis"
        >
          <div class="row items-center InnOption">
            <div class="text-grey-9 q-mr-sm">{{ scope.opt.inn }}</div>
            <div class="text-grey-6">{{ scope.opt.name }}</div>
          </div>
        </q-item>
      </template>
    </q-select>
  </q-intersection>
</template>

<script setup lang="ts">
import {onBeforeMount, ref} from 'vue'
import {storeToRefs} from 'pinia'
import {useApplicationsNew} from 'src/stores/Applications/ApplicationsNew/useApplicationsNew'
import {ApplicationNewInnResponse} from 'src/models/Applications/ApplicationNewInnResponse'
import {QSelect, useQuasar} from 'quasar'

const applicationsNewStore = useApplicationsNew()
const {
  loadClientData: load,
} = applicationsNewStore

const {
  innItems: items,
  innsLoading: loading,
  innSelected: selected,
  loadingClientData,
} = storeToRefs(applicationsNewStore)

const $q = useQuasar()

const input = ref<string>()

let timeoutInput: number
let timeoutEnter: number

const onInput = (payload: string) => {
  input.value = payload
  if (payload === '') {
    return
  }

  clearTimeout(timeoutInput)
  timeoutInput = setTimeout(() => {
    load(payload)
  }, 500) as unknown as number
}

const onEnter = () => {
  timeoutEnter = setTimeout(() => {
    const inn = (input.value || '').replaceAll(/[^0-9]*/ig, '')
    if (!inn) {
      return
    }

    selected.value = {
      inn,
      name: '',
    }
  }, 0) as unknown as number
}

const onUpdate = (value: ApplicationNewInnResponse) => {
  clearTimeout(timeoutEnter)
  //console.log('onUpdate')
  selected.value = value
}
onBeforeMount(() => {
  if (loading.value) {
    return
  }
  load()
})
</script>

<style lang="scss">
body {
  .q-field {
    &.ApplicationNewInn {
      padding-top: 32px !important;
      padding-bottom: 2px !important;

      .q-field__label {
        top: -32px !important;
        left: -49px !important;
        font-size: 16px !important;
        color: $grey-9;
      }

      input {
        margin-top: 2px;
      }

      input::-webkit-input-placeholder {
        opacity: 1 !important;
        color: $grey-8 !important;
      }
    }
  }
  &.mobile {
    .q-field {
      &.ApplicationNewInn {
        padding-top: 12px !important;
      }
    }
    .ApplicationNewInn-label {
      display: block;
      font-size: 16px !important;
      font-weight: bold;
    }
  }
}
</style>
