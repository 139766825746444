<template>
  <form-group>
    <q-field
      ref="lawField"
      :model-value="law"
      :disable="isBlocked('law')"
      class="field-with-top-label no-bg btn-group-white-bg q-mb-sm text-no-wrap"
      label="ФЗ"
      :error="false"
      borderless
      hide-bottom-space
      dense
    >
      <q-btn-toggle
        v-model="law"
        class="full-width"
        toggle-color="primary"
        color="blue-grey-1"
        text-color="grey-9"
        toggle-text-color="white"
        :disable="isBlocked('law')"
        :options="laws"
        spread
        unelevated
      />
    </q-field>
    <q-toggle
      v-model="goz275Fz"
      :disable="isBlocked('goz275Fz')"
      style="margin-left: -10px;"
      label="Закупка в рамках 275ФЗ (ГОЗ)"
    />
  </form-group>
</template>

<script setup lang="ts">
import FormGroup from 'src/components/FormGroup.vue'
import {storeToRefs} from 'pinia'
import {useLaw} from 'src/uses/Applications/useLaw'
import {useApplicationsNew} from 'src/stores/Applications/ApplicationsNew/useApplicationsNew'
import {ref, watch} from 'vue'
import {QField} from 'quasar'
import {useScrollTo} from 'src/uses/useScrollTo'

const lawField = ref<QField>()

const {
  options: laws,
} = useLaw()

const applicationsNewStore = useApplicationsNew()
const {
  law,
  goz275Fz,
  lastFocused,
} = storeToRefs(applicationsNewStore)

const {
  isBlocked,
} = applicationsNewStore

const {
  scrollToInvisibleElement,
} = useScrollTo()

watch(
  lastFocused,
  () => {
    if (lastFocused.value !== 'law' || !lawField.value) {
      return
    }

    lawField.value.focus()
    scrollToInvisibleElement(lawField.value?.$el)
  }
)
</script>

<style scoped>

</style>
