<template>
  <div ref="el">
    <q-intersection
      transition="scale"
      once
    >
      <div
        :class="classes"
      >
        <div class="ApplicationNewSelectBanks--head q-pa-lg">
          Выберите подходящие вам банки для создания заявки
        </div>
        <q-table
          v-model:selected="selectedProducts"
          class="full-width"
          style="max-height: 416px; margin-top: -3px"
          :rows="products"
          :loading="loadingProducts"
          :columns="columns"
          :pagination="{rowsPerPage: 0}"
          separator="horizontal"
          row-key="id"
          selection="multiple"
          hide-pagination
          virtual-scroll
          flat
          :grid="Platform.is.mobile"
          @row-click="onRowClick"
        >
          <template #header-selection="scope">
            <q-checkbox
              class="BankSelectCheckbox"
              :model-value="scope.selected"
              @update:model-value="onAllSelect"
            />
          </template>
          <template #body-selection="scope">
            <q-checkbox
              :model-value="scope.selected"
              :disable="isRed(scope.row.factor_result) || isRequired(scope.row.factor_result, scope.row.required)"
              :checked-icon="isRequired(scope.row.factor_result, scope.row.required) ? 'svguse:icons.svg#app-lock-closed' : ''"
              @update:model-value="onCheckboxUpdate($event, scope)"
            >
              <q-tooltip
                v-if="isRequired(scope.row.factor_result, scope.row.required)"
                class="bg-blue-grey-10 StopTooltip q-pa-md"
                :delay="700"
              >
                <div class="row">
                  <div class="col q-mr-xs">
                    <q-icon
                      name="svguse:icons.svg?2#app-exclamation-circle"
                      class="float-left"
                      size="15px"
                      color="orange"
                    />
                  </div>
                  <div>
                    Заявка в данный банк создается в приоритетном порядке <br>
                    основываясь на предварительном анализе клиента и 100% совпадении анкетных данных группы банков
                  </div>
                </div>
              </q-tooltip>
            </q-checkbox>
          </template>
          <template #body-cell-tariff="props">
            <q-td :props="props">
              <template v-if="props.row.tariff_error">
                {{ props.row.tariff_error }}
              </template>
              <template v-else>
                {{ props.row.tariff_type }} % /
                <money-component :model-value="props.row.tariff" />
              </template>
            </q-td>
          </template>
          <template #body-cell-speed="props">
            <q-td :props="props">
              {{ props.row.speed }}
            </q-td>
          </template>
          <template #body-cell-status="props">
            <q-td :props="props">
              <application-new-select-banks-stops :model-value="props.row" />
            </q-td>
          </template>

          <!--<template v-slot:top="props">
            <div class="topChecker">
              <q-checkbox
                :model-value="props.selected"
                @update:model-value="onAllSelect"
                label="Выбрать все"
              />
            </div>
          </template>-->
          <template v-slot:item="props">
            <div class="col-lg-12">
              <div class="row ApplicationNewSelectBanksMobile--item">
                <q-checkbox
                  :model-value="props.selected"
                  :disable="isRed(props.row.factor_result) || isRequired(props.row.factor_result, props.row.required)"
                  :checked-icon="isRequired(props.row.factor_result, props.row.required) ? 'svguse:icons.svg#app-lock-closed' : ''"
                  @update:model-value="onCheckboxUpdate($event, props)"
                />
                <div class="col items-start itemInfo">
                  <div class="q-mb-md">
                    <div class="text-caption">Название банка</div>
                    {{ props.row.bank_title_short }}
                  </div>
                  <div class="q-mb-md">
                    <div class="text-caption">Тариф годовых</div>
                    <template v-if="props.row.tariff_error">
                      {{ props.row.tariff_error }}
                    </template>
                    <template v-else>
                      {{ props.row.tariff_type }} % /
                      <money-component :model-value="props.row.tariff" />
                    </template>
                  </div>
                  <div class="q-mb-md">
                    <div class="text-caption">Срок рассмотрения</div>
                    {{ props.row.speed }}
                  </div>
                  <div class="q-mb-md">
                    <div class="text-caption">Статус</div>
                    <application-new-select-banks-stops :model-value="props.row" />
                  </div>
                </div>
              </div>
            </div>
          </template>
        </q-table>
      </div>
    </q-intersection>
  </div>
</template>

<script setup lang="ts">
import {computed, onMounted, ref} from 'vue'
import MoneyComponent from 'src/components/MoneyComponent.vue'
import ApplicationNewSelectBanksStops from 'src/components/Applications/ApplicationNew/ApplicationNewSelectBanksStops.vue'
import {useStopFactorResult} from 'src/uses/Applications/useStopFactorResult'

const props = withDefaults(
  defineProps<{
    kik?: boolean
  }>(),
  {
    kik: false
  }
)

const el = ref<HTMLDivElement>()

const {
  isRed,
  isRequired,
} = useStopFactorResult()

import {storeToRefs} from 'pinia'
import {useApplicationsNew} from 'src/stores/Applications/ApplicationsNew/useApplicationsNew'
import {useScrollTo} from 'src/uses/useScrollTo'
import {SelectBank, SelectBankFactor, StopFactorResultEnum} from 'src/models/Applications/New/SelectBank'
import {Platform} from 'quasar'
import {QTableColumn} from 'src/models/Grid/GridColumnSettings'

const applicationsNewStore = useApplicationsNew()
const {
  products: ebgProducts,
  selectedProducts: ebgSelectedProducts,
  loadingProducts: ebgLoadingProducts,
  creditProducts,
  creditSelectedProducts,
  creditLoadingProducts,
} = storeToRefs(applicationsNewStore)

const products = computed(() => props.kik ? creditProducts.value : ebgProducts.value)
const selectedProducts = computed({
  get: () => {
    return props.kik ? creditSelectedProducts.value : ebgSelectedProducts.value
  },
  set: (value: SelectBank[]) => {
    if (props.kik) {
      creditSelectedProducts.value = value

      return
    }

    ebgSelectedProducts.value = value
  }
})
const loadingProducts = computed(() => props.kik ? creditLoadingProducts.value : ebgLoadingProducts.value)

const {
  scrollToInvisibleElement,
} = useScrollTo()

const stringSorter = (a: string, b: string) => {
  if (a < b) {
    return -1
  }
  if (a > b) {
    return 1
  }

  return 0
}

const classes = computed(() => {
  return {
    ApplicationNewSelectBanks: !Platform.is.mobile,
    ApplicationNewSelectBanksMobile: Platform.is.mobile
  }
})

const columns = ref<QTableColumn[]>([
  {
    name: 'name',
    field: 'bank_title_short',
    required: true,
    label: 'Название банка',
    align: 'left',
    classes: 'text-no-wrap',
    sortable: true,
    sort: stringSorter,
  },
  {
    name: 'tariff',
    field: 'tariff',
    required: true,
    label: 'Тариф, годовых',
    align: 'left',
    classes: 'text-no-wrap',
    sortable: true,
    sort: (a: number, b: number) => a - b,
  },
  {
    name: 'speed',
    field: 'speed',
    required: true,
    label: 'Скорость решения',
    align: 'left',
    classes: 'text-no-wrap',
    sortable: true,
    sort: stringSorter,
  },
  /*{
    name: 'check',
    //field: 'lot_contract_guarantee',
    required: true,
    label: 'Срок рассмотрения',
    align: 'left',
    classes: 'text-no-wrap',
  },*/
  {
    name: 'status',
    field: 'factors',
    required: true,
    label: 'Статус',
    align: 'left',
    classes: 'text-no-wrap',
    sortable: true,
    sort: (a: SelectBankFactor[], b: SelectBankFactor[]) => {
      const aResult = a.reduce((result, current) => {
        return current.result > result ? current.result : result
      }, StopFactorResultEnum.ok)

      const bResult = b.reduce((result, current) => {
        return current.result > result ? current.result : result
      }, StopFactorResultEnum.ok)

      return aResult - bResult
    }
  },
])

const onRowClick = (event: Event, row: SelectBank) => {
  if (row.required) {
    return
  }

  if (isRed(row.factor_result) || isRequired(row.factor_result, row.required)) {
    return
  }

  const index = selectedProducts.value
    .findIndex(b => b.bank_id === row.bank_id)

  if (index === -1) {
    selectedProducts.value.push(row)

    return
  }

  selectedProducts.value.splice(index, 1)
}

const onAllSelect = () => {
  const selected = selectedProducts.value.filter(item => !isRequired(item.factor_result, item.required))
  if (selected.length) {
    selectedProducts.value = [
      ...selectedProducts.value.filter(item => isRequired(item.factor_result, item.required)),
    ]

    return
  }

  selectedProducts.value = products.value.filter(item => !isRed(item.factor_result))
}

const scroll = () => {
  if (!el.value) {
    return
  }

  scrollToInvisibleElement(el.value)
}

const onCheckboxUpdate = (value: unknown, scope: {selected?: boolean}) => {
  const propertyDescriptor = Object.getOwnPropertyDescriptor(scope, 'selected')
  if (!propertyDescriptor || !propertyDescriptor.set) {
    return
  }

  propertyDescriptor.set(value)
}

onMounted(() => {
  scroll()
})
</script>

<style lang="scss">
.ApplicationNewSelectBanks {
  background: #ffffff;
  border-radius: 8px;

  .q-table {
    border-spacing: 0 !important;

    td {
      border-radius: 0 !important;
    }
  }

  .q-checkbox__inner--falsy .q-checkbox__bg {
    background: #eceff1 !important;
    border-color: #cfd8dc!important;
  }
  .ApplicationNewSelectBanks--head {
    color: $grey-7;
    font-size: 12px;
  }

  thead {
    tr:first-child th {
      background: $grey-3 !important;
      top: 3px;
      position: sticky;
      z-index: 1;

      &:before {
        content: '';
        width: 100%;
        height: 4px;
        margin-top: -4px;
        background: #ffffff;
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
      }
    }
  }

  .q-table__bottom {
    display: none;
  }
}
.ApplicationNewSelectBanksMobile {
  border-radius: 10px;
  border: 1px solid #EEEEEE;
  .q-table__top {
    padding: 0!important;
    background-color: #EEEEEE;
  }
  .q-table__container {
    max-height: 100%!important;
  }
  .ApplicationNewSelectBanks--head {
    padding-left: 10px;
  }
  .ApplicationNewSelectBanksMobile--item {
    border-bottom: 1px solid #EEEEEE;
    display: flex;
    align-items: flex-start;
    padding-top: 5px;
    .itemInfo {
      padding-top: 10px;
      padding-left: 10px;
      font-size: 16px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0;
      text-align: left;
      color: #424242;
      .text-caption {
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0;
        text-align: left;
        color: #9E9E9E;
        margin-bottom: 3px;
      }
    }
  }
}
</style>
