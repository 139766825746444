<template>
  <div class="row q-mb-md">
    <div class="col-12">
      <clients-grid-filters
        :loading="loading"
        @update:filters="updateFilters"
      />
    </div>
  </div>
  <div class="ClientsGrid--table">
    <q-table
      v-model:pagination="gridPagination"
      class="sticky-header-table"
      :rows="items"
      :columns="columns"
      :loading="loading"
      :rows-per-page-options="perPageOptions"
      separator="none"
      row-key="id"
      hide-bottom
      wrap-cells
      @request="onRequest"
    >
      <template #body-cell-title="props">
        <q-td :props="props">
          <div>
            <router-link
              :to="{
                name: 'applications-grid',
                query: { 'filter[client]': props.row.inn}
              }"
            >
              {{ props.row.title }}
            </router-link>
          </div>
        </q-td>
      </template>
      <template #body-cell-inn="props">
        <q-td :props="props">
          {{ props.row.inn }}
        </q-td>
      </template>
      <template #body-cell-agent="props">
        <q-td :props="props">
          <span class="text-no-wrap">{{ getAgentNameParts(props.row.agent.name).name }}</span>
          /
          <span class="text-no-wrap">{{ getAgentNameParts(props.row.agent.name).inn }}</span>
        </q-td>
      </template>
      <template #body-cell-region="props">
        <q-td :props="props">
          {{ props.row.region?.name }}
        </q-td>
      </template>
      <template #body-cell-timezone="props">
        <q-td :props="props">
          {{ props.row.localtime }} {{ props.row.timezone }}
        </q-td>
      </template>
      <template #body-cell-agentCode="props">
        <q-td :props="props">
          {{ props.row.agent.code }}
        </q-td>
      </template>
      <template #body-cell-actions="props">
        <q-td :props="props">
          <div class="createButton">
            <q-btn
              :to="{
                name: 'applications-new',
                query: {
                  inn: props.row.inn,
                  agent_id: isOperator() ? props.row.agent.id : undefined,
                  agent_inn: isOperator() ? props.row.agent.inn : undefined,
                }
              }"
              class="full-width text-no-wrap"
              align="left"
              color="primary"
              size="12px"
              style="padding: 10px 36px 10px 16px;"
              unelevated
              no-caps
            >
              <strong>Создать заявку</strong>
              <span class="icon">
                <q-icon
                  name="svguse:icons.svg?2#app-plus-1"
                  color="primary"
                  size="12px"
                  style="padding: 5.5px;"
                />
              </span>
            </q-btn>
          </div>
        </q-td>
      </template>
    </q-table>
    <pagination-component
      class="q-mb-md"
      :pagination="gridPagination"
      :per-page-options="perPageOptions"
      @request="onRequest"
      @refresh="load"
    />
  </div>
</template>

<script setup lang="ts">
import {storeToRefs} from 'pinia'
import {onBeforeMount, onBeforeUnmount} from 'vue'
import {useGridSettings} from 'src/uses/Grid/useGridSettings'
import {ClientsGridColumns} from 'src/components/Clients/ClientsGridColumns'
import {useClientsListFiltersStore} from 'src/stores/Clients/useClientsListFiltersStore'
import {useClientsListStore} from 'src/stores/Clients/useClientsListStore'
import ClientsGridFilters from 'src/components/Clients/ClientsGridFilters.vue'
import PaginationComponent from 'src/components/PaginationComponent.vue'
import {useClientsGridSortStore} from 'src/stores/Clients/useClientsGridSortStore'
import {GridSortingEnum} from 'src/models/Grid/GridSortingEnum'
import {useProfileStore} from 'src/stores/Login/useProfileStore'
import {RequestProp} from 'src/models/Grid/Pagination'

const filtersStore = useClientsListFiltersStore()
const clientsListStore = useClientsListStore()
const {
  items,
  gridPagination,
  perPageOptions,
  loading,
} = storeToRefs(clientsListStore)

const {
  load,
  onRequestPagination,
  setPage,
} = clientsListStore

const {
  isOperator,
} = useProfileStore()

const clientsGridSortStore = useClientsGridSortStore()
const {
  sorting,
} = storeToRefs(clientsGridSortStore)

const {columns} = useGridSettings('clients-grid', ClientsGridColumns)

const getAgentNameParts = (name: string) => {
  const parts = name.split(' / ')
  return {
    name: parts[1] || '',
    inn: parts[0] || '',
  }
}

onBeforeMount(async () => {
  await load()
})

onBeforeUnmount(() => {
  clientsListStore.$reset()
  clientsListStore.$dispose()
  filtersStore.$reset()
  filtersStore.$dispose()
})

const onRequest = ({pagination}: RequestProp) => {
  sorting.value.direction = pagination.descending ? GridSortingEnum.DESC : GridSortingEnum.ASC
  sorting.value.sort = pagination.sortBy || ''
  onRequestPagination({
    page: pagination.page,
    rowsPerPage: pagination.rowsPerPage,
    rowsNumber: 0,
    sortBy: pagination.sortBy,
    descending: pagination.descending,
  })
  load()
}

const updateFilters = () => {
  setPage(1)
  load()
}

</script>

<style lang="scss">
.ClientsGrid--table {
  margin: 0 -24px -24px;
  background: $blue-1;
  padding: 0 24px 24px;

  table {
    position: relative;

    tr {
      td {
        width: 150px;

        &:first-child {
          width: auto;
          white-space: normal;
        }
      }
    }
  }

  .createButton {
    .icon {
      background: white;
      border-radius: 6px;
      position: absolute;
      right: 10px;
      line-height: 0;
    }

    .q-btn {
      border-radius: 6px;
    }
  }
}
</style>
