import {usePutApi} from 'src/uses/Api/usePutApi'

export const useApplicationsNewSetEmail = () => {
  const {
    loading,
    put,
    status,
    response,
  } = usePutApi<unknown, {email: string}>()

  const putEmail = async (multiOrderId: number, email: string) => {
    await put(
      `v1/multiorder/${multiOrderId}/additional-information/email`,
      {
        email,
      })
  }

  return {
    loading,
    putEmail,
    status,
    response,
  }
}
