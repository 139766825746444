import {defineStore} from 'pinia'
import {useGetApi} from 'src/uses/Api/useGetApi'
import {ref} from 'vue'
import {AgentManagerListItem} from 'src/models/AgentManagers/AgentManagerListItem'
import {AgentManagerListResponseData} from 'src/models/AgentManagers/AgentManagerListResponseData'

export const useAgentManagerListStore = (hash: string) => defineStore(`agent-manager-list-${hash}`, () => {
  const {
    loading,
    get,
    status,
    response,
  } = useGetApi<AgentManagerListResponseData>()

  const agentManagers = ref<AgentManagerListItem[]>([])

  const load = async () => {
    await get(
      'v1/admin/managers',
    )

    if (status.value === 200 && response && response.value) {
      agentManagers.value = response.value?.items
    }
  }

  return {
    loading,
    agentManagers,
    load,
  }
})
