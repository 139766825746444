<template>
  <div class="white-bg-field">
    <q-select
      :model-value="model"
      class="field-with-top-label"
      :options="options"
      :option-label="optionLabel"
      :label="props.label"
      outlined
      hide-bottom-space
      dense
      @update:model-value="onSelect"
    />
    <q-input
      v-if="model && isNewAddedDocument(model)"
      :model-value="model.title"
      class="field-with-top-label q-mt-sm"
      label="Название документа"
      outlined
      hide-bottom-space
      dense
      @update:model-value="onInput"
    />
    <application-form-new-document-require-sign-parameter
      v-if="model"
      :key="JSON.stringify(model)"
      :document="model"
      @select:sign="model.file_sign = $event; emitSelect()"
    />
  </div>
</template>

<script setup lang="ts">
import {computed, ref, watchEffect} from 'vue'
import {
  ApplicationFormDocument
} from 'src/models/Applications/Form/ApplicationFormResponseData'
import {DocumentsCodesEnum} from 'src/models/DocumentsCodesEnum'
import ApplicationFormNewDocumentRequireSignParameter
  from 'src/components/Applications/Form/Documents/ApplicationFormNewDocumentRequireSignParameter.vue'

const props = defineProps<{
  document: ApplicationFormDocument
  documents: ApplicationFormDocument[]
  newDocuments: ApplicationFormDocument[]
  additionalOptionLabel: string
  label: string
  canUploadAddedDocument: boolean
}>()

const emit = defineEmits<{
  (e: 'select-additional', data: ApplicationFormDocument): void,
}>()

const model = ref<ApplicationFormDocument>()

const isNewAddedDocument = (doc: ApplicationFormDocument) => {
  return doc.code === DocumentsCodesEnum.ADDED_DOCUMENT
    && !doc.index
}

const isAlreadyLoaded = (document: ApplicationFormDocument) => {
  if (props.newDocuments?.length === 0) {
    return false
  }

  return props.newDocuments?.find(doc => {
    return doc.code === document.code && doc.title === document.title
  })
}

const options = computed<unknown[]>(() => {
  const options = props.documents
    .filter(document => !document.readonly && !isAlreadyLoaded(document))
    .map(document => ({
      ...document,
    }))

  return [
    ...options,
    ...(props.canUploadAddedDocument ? [{
      code: DocumentsCodesEnum.ADDED_DOCUMENT,
      index: null,
      title: '',
    }] : [])
  ]
})

const optionLabel = (opt: ApplicationFormDocument) => {
  if (isNewAddedDocument(opt)) {
    return props.additionalOptionLabel
  }

  return opt.title || props.additionalOptionLabel
}

const emitSelect = () => {
  if (!model.value) {
    return
  }

  emit(
    'select-additional',
    {
      ...model.value,
    }
  )
}

const onSelect = (value: ApplicationFormDocument) => {
  model.value = value
  emitSelect()
}

const onInput = (value: string | number | null) => {
  if (!model.value) {
    return
  }

  model.value.title = value as string

  emitSelect()
}

watchEffect(() => {
  if (!props.document.code) {
    return
  }

  model.value = props.document
})
</script>
