import {computed} from 'vue'
import {ApplicationKikStatusEnum} from 'src/models/Applications/ApplicationKikStatusEnum'
import {useProfileStore} from 'stores/Login/useProfileStore'

const filterStatusesDesc: Record<ApplicationKikStatusEnum, string> = {
  [ApplicationKikStatusEnum.all]: 'Все',
  [ApplicationKikStatusEnum.draft]: 'Черновики',
  [ApplicationKikStatusEnum.approved]: 'Выставлено предложение',
  [ApplicationKikStatusEnum.released]: 'Выданные кредиты',
  [ApplicationKikStatusEnum.bank_rejected]: 'Отказ банка',
  [ApplicationKikStatusEnum.client_rejected]: 'Отказ клиента',
  [ApplicationKikStatusEnum.system_rejected]: 'Отказ системы',
  [ApplicationKikStatusEnum.in_bank]: 'Отправлено в банк',
  [ApplicationKikStatusEnum.need_sign]: 'Требуют подпись',
  [ApplicationKikStatusEnum.favorites]: 'Избранное',
}

const icons: Record<ApplicationKikStatusEnum, string> = {
  [ApplicationKikStatusEnum.all]: 'svguse:icons.svg?2#app-collection-bold',
  [ApplicationKikStatusEnum.draft]: 'svguse:icons.svg?2#app-clipboard-list-bold',
  [ApplicationKikStatusEnum.approved]: 'svguse:icons.svg?2#app-collection-bold',
  [ApplicationKikStatusEnum.released]: 'svguse:icons.svg?2#app-warranty-issued-bold',
  [ApplicationKikStatusEnum.bank_rejected]: 'svguse:icons.svg?2#app-bank-refusal-bold',
  [ApplicationKikStatusEnum.client_rejected]: 'svguse:icons.svg?2#app-x-circle-bold',
  [ApplicationKikStatusEnum.system_rejected]: 'svguse:icons.svg?2#app-minus-circle-bold',
  [ApplicationKikStatusEnum.in_bank]: 'svguse:icons.svg?2#app-collection-bold',
  [ApplicationKikStatusEnum.need_sign]: 'svguse:icons.svg?2#app-need-signature-bold',
  [ApplicationKikStatusEnum.favorites]: 'svguse:icons.svg?2#app-heart-bold',
}

const redStatuses: ApplicationKikStatusEnum[] = [
  ApplicationKikStatusEnum.bank_rejected,
  ApplicationKikStatusEnum.client_rejected,
  ApplicationKikStatusEnum.system_rejected,
]

const blueStatuses: ApplicationKikStatusEnum[] = [
  ApplicationKikStatusEnum.in_bank,
]

const greenStatuses: ApplicationKikStatusEnum[] = [
  ApplicationKikStatusEnum.released,
  ApplicationKikStatusEnum.approved,
]

const greyStatuses: ApplicationKikStatusEnum[] = [
  ApplicationKikStatusEnum.draft,
  ApplicationKikStatusEnum.all,
]

export const useFilterKikStatus = () => {
  const {
    isOperator,
    isAgentManager,
  } = useProfileStore()

  const filterLabel = (status: ApplicationKikStatusEnum) => filterStatusesDesc[status] || status

  const icon = (status: ApplicationKikStatusEnum) => icons[status] || status

  const isDraft = (status: ApplicationKikStatusEnum) => status === ApplicationKikStatusEnum.draft

  const isRed = (status: ApplicationKikStatusEnum) => redStatuses.includes(status)
  const isBlue = (status: ApplicationKikStatusEnum) => blueStatuses.includes(status)
  const isGreen = (status: ApplicationKikStatusEnum) => greenStatuses.includes(status)
  const isGrey = (status: ApplicationKikStatusEnum) => greyStatuses.includes(status)

  const color = (status: ApplicationKikStatusEnum) => {
    if (isRed(status)) {
      return 'red-12'
    }

    if (isBlue(status)) {
      return 'blue-7'
    }

    if (isGreen(status)) {
      return 'green-14'
    }

    if (isGrey(status)) {
      return 'blue-grey-4'
    }

    return 'green-14'
  }

  const bgColor = (status: ApplicationKikStatusEnum) => {
    if (isRed(status)) {
      return 'white'//'red-1'
    }

    if (isBlue(status)) {
      return 'white'//'blue-1'
    }

    if (isGreen(status)) {
      return 'white'//'green-1'
    }

    if (isGrey(status)) {
      return 'white'//'grey-4'
    }

    return 'white'//'green-1'
  }

  const filterStatuses = computed(() => {
    return [
      ApplicationKikStatusEnum.all,
      ApplicationKikStatusEnum.draft,
      ApplicationKikStatusEnum.in_bank,
      ApplicationKikStatusEnum.need_sign,
      ApplicationKikStatusEnum.approved,
      ApplicationKikStatusEnum.released,
      ApplicationKikStatusEnum.bank_rejected,
      ApplicationKikStatusEnum.client_rejected,
      ApplicationKikStatusEnum.system_rejected,
      ...(isOperator() || isAgentManager()) ? [ApplicationKikStatusEnum.favorites] : [],
    ]
  })

  return {
    filterLabel,
    isDraft,
    isRed,
    isBlue,
    isGreen,
    isGrey,
    color,
    bgColor,
    icon,
    filterStatuses,
  }
}
