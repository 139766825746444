<template>
  <q-dialog
    v-if="isAgentManager()"
    :model-value="modelValue"
    @update:model-value="emit('update:model-value', $event)"
  >
    <q-card
      style="min-width: 400px; width: 100vw; max-width: 600px;"
      class="bg-blue-1"
    >
      <q-card-section class="text-h6 rounded-borders">
        Установить плановую дату выпуска
      </q-card-section>
      <q-card-section class="q-pt-none white-bg-field">
        <div
          class="row"
          style="width: 410px; max-width: 100%; margin-bottom: 100px;"
        >
          <q-input
            v-if="releaseDate"
            v-model="releaseDate.date"
            :disable="loading"
            mask="##.##.####"
            placeholder="дд.мм.гггг"
            class="field-with-top-label overflow-label-visible col"
            label="Запланированная дата выпуска гарантии"
            outlined
            hide-bottom-space
            dense
          >
            <template #append>
              <q-icon
                class="cursor-pointer"
                name="svguse:icons.svg?2#app-calendar"
              />
              <q-popup-proxy
                ref="dateProxy"
                transition-show="scale"
                transition-hide="scale"
              >
                <q-date
                  :model-value="releaseDate.date"
                  mask="DD.MM.YYYY"
                  :options="optionsFn"
                  minimal
                  @update:model-value="releaseDate.date = $event; ($refs['dateProxy'] as QPopupProxy).hide();"
                />
              </q-popup-proxy>
            </template>
          </q-input>
          <q-toggle
            v-if="releaseDate"
            v-model="releaseDate.is_exact"
            :disable="loading"
            style="margin-top: 30px;"
            :indeterminate-value="null"
            label="точная дата"
          />
        </div>
      </q-card-section>
      <q-card-actions
        class="row justify-end q-py-md"
        style="background: #D5EAFA;"
      >
        <q-btn
          size="16px"
          padding="sm lg"
          label="Отмена"
          :disable="loading"
          unelevated
          no-caps
          @click="cancel"
        />
        <q-btn
          padding="sm lg"
          size="16px"
          color="blue-7"
          label="Сохранить"
          :loading="loading"
          :disable="!releaseDate || !releaseDate.date"
          unelevated
          no-caps
          @click="save"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
import {ref, watchEffect, computed, watch} from 'vue'
import {
  ApplicationsGridItem,
  ApplicationsGridItemAdditionalInformationReleaseDate
} from 'src/models/Applications/ApplicationsGridItem'
import {useProfileStore} from 'src/stores/Login/useProfileStore'
import {QInput, QPopupProxy} from 'quasar'
import moment from 'moment/moment'
import {CloneHelper} from 'src/helpers/CloneHelper'
import {useApplicationReleaseDate} from 'src/uses/Applications/useApplicationReleaseDate'

const {
  isAgentManager,
} = useProfileStore()

const props = defineProps<{
  item: ApplicationsGridItem,
  modelValue: boolean,
}>()

const emit = defineEmits<{
  (e: 'update:model-value', val: boolean): void,
  (e: 'change'): void,
}>()

const {
  loading,
  status,
  update,
} = useApplicationReleaseDate()

const releaseDate = ref<ApplicationsGridItemAdditionalInformationReleaseDate>()
const dateProxy = ref<QPopupProxy>()

const optionsFn = (date: string) => {
  return moment(date, 'YYYY/MM/DD').isSameOrAfter(moment().subtract(1, 'day'))
}

const save = async () => {
  if (!releaseDate.value) {
    return
  }

  await update(props.item.id, releaseDate.value)
  if (status.value === 200) {
    emit('change')
    emit('update:model-value', false)
  }
}

const cancel = () => {
  releaseDate.value = props.item.additional_information.release_date
  emit('update:model-value', false)
}

const isExact = computed(() => {
  if (!releaseDate.value) {
    return false
  }

  return !!releaseDate.value?.is_exact
})

watchEffect(() => {
  releaseDate.value = CloneHelper.clone(props.item.additional_information.release_date)
})
watch(
  isExact,
  () => {
    if (!releaseDate.value) {
      return
    }
    releaseDate.value.is_exact = isExact.value
  },
  {immediate: true, deep: true}
)
</script>
