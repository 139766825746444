import {defineStore, storeToRefs} from 'pinia'
import {useGetApi} from 'src/uses/Api/useGetApi'
import {ref} from 'vue'
import {useGridPagination} from 'src/uses/Grid/useGridPagination'
import {ReportsGridResponseData} from 'src/models/Profile/Reports/ReportsGridResponseData'
import {ReportsGridItem} from 'src/models/Profile/Reports/ReportsGridItem'
import {useReportsGridFiltersStore} from 'src/stores/Profile/Reports/useReportsGridFiltersStore'
import {ReportStatusEnum} from 'src/models/Enums/ReportStatusEnum'
import {usePostApi} from 'src/uses/Api/usePostApi'
import {ReportsGridFilters} from 'src/models/Profile/Reports/ReportsGridFilters'
import moment from 'moment'
import {useDialog} from 'src/uses/Dialogs/useDialog'
import {useQuasar} from 'quasar'
import {useProfileStore} from 'stores/Login/useProfileStore'

export const useReportsGridStore = defineStore('profile-reports-grid', () => {
  const ADMIN_REPORTS_LIST_URL = 'v1/admin/reports'
  const AGENT_REPORTS_LIST_URL = 'v1/reports'

  const {
    loading: postLoading,
    post,
    status: postStatus,
    response: postResponse,
  } = usePostApi<ReportsGridFilters, ReportsGridFilters>(true)

  const {
    loading,
    get,
    errors,
    error,
    status,
    response,
  } = useGetApi<ReportsGridResponseData>()

  const profileStore = useProfileStore()
  const {isAdmin} = profileStore

  const {
    pagination,
    gridPagination,
    perPageOptions,
    getFromStorage,
    noMorePages,
    setPagination,
    onRequestPagination,
    setPage,
  } = useGridPagination('profile-reports')

  const filtersStore = useReportsGridFiltersStore()
  const {
    clear
  } = filtersStore
  const {clearable, filters} = storeToRefs(filtersStore)

  const {
    dialog: filerDialog,
    show: filterDialogShow
  } = useDialog()

  const $q = useQuasar()
  const items = ref(<ReportsGridItem[]>[])
  const successGenerate = ref(false)

  const load = async () => {
    await getFromStorage()
    await get(
      isAdmin() ? ADMIN_REPORTS_LIST_URL : AGENT_REPORTS_LIST_URL,
      {
        page: pagination.value?.page,
        perpage: pagination.value?.per_page,
      }
    )

    if (status.value === 200 && response && response.value?.items) {
      if ($q.platform.is.mobile && pagination.value?.page !== 1) {
        items.value = items.value.concat(response.value.items)
      } else {
        items.value = response.value?.items
      }
      setPagination(response.value?.pagination)
    }
  }

  const updateFilters = () => {
    setPage(1)
    load()
  }

  const onClear = async () => {
    await clear()
    updateFilters()
  }

  const generate = async () => {
    const data = {
      ...filters.value,
    }

    if (filters.value.date_from) {
      data.date_from = moment(filters.value.date_from, 'DD.MM.YYYY').format('DD.MM.YYYY')
    }
    if (filters.value.date_to) {
      data.date_to = moment(filters.value.date_to, 'DD.MM.YYYY').format('DD.MM.YYYY')
    }

    await post(isAdmin() ? ADMIN_REPORTS_LIST_URL : AGENT_REPORTS_LIST_URL, data)
    if (postStatus.value === 200 && postResponse.value) {
      successGenerate.value = true
      await load()
    }
  }

  const setNextPage = () => {
    if (pagination.value.page >= (pagination.value.pages || 0)) {
      return
    }

    pagination.value.page++
  }

  const statusClass = (status: ReportStatusEnum) => {
    return status === ReportStatusEnum.failed ? 'text-color-red' : ''
  }
  return {
    loading,
    get,
    errors,
    error,
    items,
    pagination,
    gridPagination,
    perPageOptions,
    noMorePages,
    postLoading,
    onRequestPagination,
    load,
    setPage,
    statusClass,
    generate,

    filerDialog,
    clearable,
    filterDialogShow,
    onClear,
    setNextPage,
  }
})
