import {usePutApi} from 'src/uses/Api/usePutApi'

export const useNotificationEmailUpdate = () => {
  const {
    error,
    errors,
    put,
    status,
    response,
    loading,
  } = usePutApi<unknown, {email: string}>()

  const update = async (appId: string, email: string) => {
    await put(
      `v1/admin/order/${appId}/additional-information/email`,
      {
        email
      }
    )
  }

  return {
    error,
    errors,
    put,
    status,
    response,
    loading,
    update,
  }
}
