import {ApplicationStatusEnum} from 'src/models/Applications/ApplicationStatusEnum'
import {ApplicationSubStatusEnum} from 'src/models/Applications/ApplicationSubStatusEnum'

const statuses: Record<ApplicationStatusEnum, string> = {
  [ApplicationStatusEnum.all]: 'Все',
  [ApplicationStatusEnum.active]: 'Активная',
  [ApplicationStatusEnum.favorites]: 'Избранное',
  [ApplicationStatusEnum.draft]: 'Черновик',
  [ApplicationStatusEnum.verification]: 'Проверка Go',
  [ApplicationStatusEnum.on_check]: 'На проверке',
  [ApplicationStatusEnum.need_sign]: 'Требуется подпись',
  [ApplicationStatusEnum.abs]: 'Отправлено в банк',
  [ApplicationStatusEnum.in_bank]: 'Отправлено в банк',
  [ApplicationStatusEnum.risk_assessment]: 'Оценка рисками',
  [ApplicationStatusEnum.approved]: 'Одобрена',
  [ApplicationStatusEnum.project_bg_ready]: 'Выставлено предложение',
  [ApplicationStatusEnum.agreement]: 'На согласовании с банком',
  [ApplicationStatusEnum.on_release]: 'Ожидает выпуска',
  [ApplicationStatusEnum.released]: 'Выдана',
  [ApplicationStatusEnum.bank_rejected]: 'Отказ банка',
  [ApplicationStatusEnum.client_rejected]: 'Отказ клиента',
  [ApplicationStatusEnum.system_rejected]: 'Отказ системы',
  [ApplicationStatusEnum.bank_consideration]: 'На рассмотрении в Банке',
  [ApplicationStatusEnum.approved_orders]: 'Выставлено предложение',
  [ApplicationStatusEnum.guarantee_released_request]: 'Выставлено предложение',
  [ApplicationStatusEnum.bg_project_bank_agreement_request]: 'Выставлено предложение',
  [ApplicationStatusEnum.release_today]: 'Выпуск сегодня',
  [ApplicationStatusEnum.release_expired]: 'Выпуск просрочен',
  [ApplicationStatusEnum.release_without_date]: 'Выпуск без даты',
  [ApplicationStatusEnum.waiting]: 'В ожидании',
}

const icons: Record<ApplicationStatusEnum, string> = {
  [ApplicationStatusEnum.all]: 'svguse:icons.svg#app-collection-bold',
  [ApplicationStatusEnum.active]: 'svguse:icons.svg#app-collection-bold',
  [ApplicationStatusEnum.favorites]: 'svguse:icons.svg#app-heart-bold',
  [ApplicationStatusEnum.draft]: 'svguse:icons.svg#svguse:icons.svg#app-clipboard-list-bold',
  [ApplicationStatusEnum.verification]: 'svguse:icons.svg#app-collection-bold',
  [ApplicationStatusEnum.on_check]: 'svguse:icons.svg#app-collection-bold',
  [ApplicationStatusEnum.need_sign]: 'svguse:icons.svg#app-collection-bold',
  [ApplicationStatusEnum.abs]: 'svguse:icons.svg#app-collection-bold',
  [ApplicationStatusEnum.risk_assessment]: 'svguse:icons.svg#app-collection-bold',
  [ApplicationStatusEnum.approved]: 'svguse:icons.svg#app-collection-bold',
  [ApplicationStatusEnum.project_bg_ready]: 'svguse:icons.svg#app-collection-bold',
  [ApplicationStatusEnum.agreement]: 'svguse:icons.svg#app-collection-bold',
  [ApplicationStatusEnum.on_release]: 'svguse:icons.svg#app-collection-bold',
  [ApplicationStatusEnum.released]: 'svguse:icons.svg#app-collection-bold',
  [ApplicationStatusEnum.bank_rejected]: 'svguse:icons.svg#app-collection-bold',
  [ApplicationStatusEnum.client_rejected]: 'svguse:icons.svg#app-collection-bold',
  [ApplicationStatusEnum.system_rejected]: 'svguse:icons.svg#app-collection-bold',
  [ApplicationStatusEnum.bank_consideration]: 'svguse:icons.svg#app-collection-bold',
  [ApplicationStatusEnum.approved_orders]: 'svguse:icons.svg#app-collection-bold',
  [ApplicationStatusEnum.guarantee_released_request]: 'svguse:icons.svg#app-collection-bold',
  [ApplicationStatusEnum.bg_project_bank_agreement_request]: 'svguse:icons.svg#app-collection-bold',
  [ApplicationStatusEnum.release_today]: 'svguse:icons.svg#app-calendar-bold',
  [ApplicationStatusEnum.release_expired]: 'svguse:icons.svg#app-calendar-bold',
  [ApplicationStatusEnum.release_without_date]: 'svguse:icons.svg#app-calendar-bold',
  [ApplicationStatusEnum.waiting]: 'svguse:icons.svg#app-collection-bold',
  [ApplicationStatusEnum.in_bank]: 'svguse:icons.svg#app-collection-bold',
}

const redStatuses: ApplicationSubStatusEnum[] = [
  ApplicationSubStatusEnum.bank_rejected,
  ApplicationSubStatusEnum.client_rejected,
  ApplicationSubStatusEnum.system_rejected,
  ApplicationSubStatusEnum.archive,
]

const blueStatuses: ApplicationSubStatusEnum[] = [
  ApplicationSubStatusEnum.client_signed,
  ApplicationSubStatusEnum.send_2_bank,
  ApplicationSubStatusEnum.in_bank,
  ApplicationSubStatusEnum.data_request,
  ApplicationSubStatusEnum.data_request_sign,
  ApplicationSubStatusEnum.data_request_executed,
  ApplicationSubStatusEnum.risks_assessment,
  ApplicationSubStatusEnum.risks_request,
  ApplicationSubStatusEnum.risks_request_sign,
  ApplicationSubStatusEnum.risks_request_executed,
  ApplicationSubStatusEnum.bg_project_bank_agreement,
  ApplicationSubStatusEnum.bg_project_bank_agreement_request,
  ApplicationSubStatusEnum.bg_project_bank_agreement_request_sign,
  ApplicationSubStatusEnum.payment_waiting,
  ApplicationSubStatusEnum.deferred_conditions_execution_waiting,
  ApplicationSubStatusEnum.deferred_conditions_execution_waiting_sign,
  ApplicationSubStatusEnum.deferred_conditions_executed,
  ApplicationSubStatusEnum.paid,
]

const greenStatuses: ApplicationSubStatusEnum[] = [
  ApplicationSubStatusEnum.guarantee_released_request_sign,
  ApplicationSubStatusEnum.guarantee_released_request,
  ApplicationSubStatusEnum.guarantee_released,
  ApplicationSubStatusEnum.bg_project_preparation,
  ApplicationSubStatusEnum.new,
  ApplicationSubStatusEnum.completion,
  ApplicationSubStatusEnum.checking,
]

const greyStatuses: ApplicationSubStatusEnum[] = [
  ApplicationSubStatusEnum.draft,
]

const orangeStatuses: ApplicationSubStatusEnum[] = [
  ApplicationSubStatusEnum.waiting_required,
]

export const useStatus = () => {
  const label = (status: ApplicationStatusEnum) => statuses[status] || status

  const icon = (status: ApplicationStatusEnum) => icons[status] || status

  const isDraft = (status: ApplicationSubStatusEnum) => status === ApplicationSubStatusEnum.draft
  const isClientRejected = (status: ApplicationSubStatusEnum) => status === ApplicationSubStatusEnum.client_rejected

  const isRed = (status: ApplicationSubStatusEnum) => redStatuses.includes(status)
  const isBlue = (status: ApplicationSubStatusEnum) => blueStatuses.includes(status)
  const isGreen = (status: ApplicationSubStatusEnum) => greenStatuses.includes(status)
  const isGrey = (status: ApplicationSubStatusEnum) => greyStatuses.includes(status)
  const isOrange = (status: ApplicationSubStatusEnum) => orangeStatuses.includes(status)

  const color = (status: ApplicationSubStatusEnum) => {
    if (isRed(status)) {
      return 'red-12'
    }

    if (isOrange(status)) {
      return 'orange-6'
    }

    if (isBlue(status)) {
      return 'blue-7'
    }

    if (isGreen(status)) {
      return 'green-14'
    }

    if (isGrey(status)) {
      return 'blue-grey-4'
    }

    return 'green-14'
  }

  const bgColor = (status: ApplicationSubStatusEnum) => {
    if (isRed(status)) {
      return 'red-1'
    }

    if (isOrange(status)) {
      return 'orange-1'
    }

    if (isBlue(status)) {
      return 'blue-1'
    }

    if (isGreen(status)) {
      return 'green-1'
    }

    if (isGrey(status)) {
      return 'grey-4'
    }

    return 'green-1'
  }

  return {
    label,
    isDraft,
    isClientRejected,
    isRed,
    isBlue,
    isGreen,
    isGrey,
    isOrange,
    color,
    bgColor,
    icon,
    statuses,
  }
}
