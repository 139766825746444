<template>
  <div class="row q-col-gutter-md">
    <field-component
      v-for="(field, index) in props.fields.all"
      :key="index"
      :field="field"
      :banks="props.banks"
      :scroll-root="props.scrollRoot"
      :show-animation-in-progress="showAnimationInProgress"
      @update:value="updateValue"
      @update:target-value="updateTargetValue"
      @update:address-details="updateAddressDetails"
    />
  </div>

  <div
    v-if="props.fields.banks.length > 0"
    class="text-h6 q-mb-md q-mt-md"
  >
    Дополнительные требования
  </div>
  <div
    v-for="bank in props.fields.banks"
    :key="bank.id"
    style="border: 1px solid #C7DFF1; border-radius: 10px;"
  >
    <div class="q-pa-md">
      <img
        :src="bank.url"
        :alt="bank.title"
        style="height: 64px;"
      >
    </div>
    <div class="row q-col-gutter-md q-ml-none q-mr-md q-mb-md">
      <field-component
        v-for="(field, index) in bank.fields"
        :key="index"
        :field="field"
        :banks="props.banks"
        :scroll-root="props.scrollRoot"
        :show-animation-in-progress="showAnimationInProgress"
        @update:value="updateValue"
        @update:target-value="updateTargetValue"
        @update:address-details="updateAddressDetails"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import FieldComponent from 'src/components/Fields/FieldComponent.vue'
import {withDefaults} from 'vue'
import {ProductFields} from 'src/models/Applications/Form/ProductFields'
import {FormElementValue} from 'src/models/Form'
import {
  AddressDetails,
  ApplicationFormBank,
  ApplicationFormField
} from 'src/models/Applications/Form/ApplicationFormResponseData'
import {useProductFields} from 'src/uses/Applications/useProductFields'

const props = withDefaults(
  defineProps<{
    fields: ProductFields,
    banks: ApplicationFormBank[],
    scrollRoot?: Element | null,
    showAnimationInProgress?: boolean
  }>(),
  {
    scrollRoot: null,
    showAnimationInProgress: false,
  }
)

const emit = defineEmits<{
  (e: 'update:value', value: FormElementValue, field: ApplicationFormField): void,
  (e: 'update:address-details', field: ApplicationFormField, details: AddressDetails): void,
}>()

const {
  getFieldByCode,
} = useProductFields()

const updateValue = (value: FormElementValue, field: ApplicationFormField) => {
  emit('update:value', value, field)
}

const updateAddressDetails = (field: ApplicationFormField, details: AddressDetails) => {
  emit('update:address-details', field, details)
}

const updateTargetValue = (value: FormElementValue, field: ApplicationFormField) => {
  const parentField = getFieldByCode(props.fields, field.service?.target_field || '')
  if (!parentField) {
    return
  }
  emit('update:value', value, parentField)
}
</script>
