<template>
  <div
    v-if="props.document.file"
    class="row items-center no-wrap bg-white rounded-borders q-mb-xs q-pa-xs relative-position"
  >
    <q-icon
      name="svguse:icons.svg?2#app-document-text-bold"
      color="blue-grey-3"
      size="md"
    />
    <div class="ellipsis">
      {{ props.document.title }}
    </div>
    <div class="q-pl-sm q-pr-sm text-grey-6">
      {{ getSizeString(props.document.file.size) }}
    </div>
    <div class="text-right">
      <q-btn
        class="delete"
        :disable="props.document.loading"
        :loading="props.document.loading"
        dense
        unelevated
        @click="emit('remove')"
      >
        <q-icon
          name="svguse:icons.svg?2#app-x"
          color="blue-grey-4"
        />
      </q-btn>
    </div>
    <div
      v-if="props.document.progress"
      class="progress q-px-sm"
    >
      <q-linear-progress
        :value="props.document.progress.loaded / props.document.progress.total"
        color="green-14"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import {ApplicationFormDocument} from 'src/models/Applications/Form/ApplicationFormResponseData'
import {useDataSize} from 'src/uses/useDataSize'

const emit = defineEmits<{
  (e: 'remove'): void,
}>()

const props = defineProps<{
  document: ApplicationFormDocument,
}>()

const {
  getSizeString,
} = useDataSize()
</script>

<style scoped lang="scss">
.progress {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
</style>
