<template>
  <filters-expansion
    :clearable="clearable"
    :is-show-filters="isShowFilters"
    @clear-pins="onClearPins"
    @set-default-pins="onSetDefaultPins"
  >
    <template #header>
      <div v-if="filters.number && !isPinned('number')">
        <q-chip
          :label="`Номер ${filters.number}`"
          color="primary"
          text-color="white"
        />
      </div>
      <div v-if="filters.client && !isPinned('client')">
        <q-chip
          :label="`ИНН ${filters.client}`"
          color="primary"
          text-color="white"
        />
      </div>
      <div v-if="filters.guarantee_total_from && !isPinned('guarantee_total_from')">
        <q-chip
          :label="`Сумма от`"
          color="primary"
          text-color="white"
        >
          <money-component
            :model-value="filters.guarantee_total_from"
            class="q-ml-sm"
          />
        </q-chip>
      </div>
      <div v-if="filters.guarantee_total_to && !isPinned('guarantee_total_to')">
        <q-chip
          :label="`Сумма до`"
          color="primary"
          text-color="white"
        >
          <money-component
            :model-value="filters.guarantee_total_to"
            class="q-ml-sm"
          />
        </q-chip>
      </div>
      <div v-if="filters.release_date_from && !isPinned('release_date_from')">
        <q-chip
          :label="`Дата выпуска с`"
          color="primary"
          text-color="white"
        >
          <date-item
            :model-value="filters.release_date_from"
            class="q-ml-sm"
            parse-format="YYYY-MM-DD"
            format="DD.MM.YYYY"
          />
        </q-chip>
      </div>
      <div v-if="filters.release_date_to && !isPinned('release_date_to')">
        <q-chip
          :label="`Дата выпуска по`"
          color="primary"
          text-color="white"
        >
          <date-item
            :model-value="filters.release_date_to"
            class="q-ml-sm"
            parse-format="YYYY-MM-DD"
            format="DD.MM.YYYY"
          />
        </q-chip>
      </div>
      <div v-if="filters.agent && !isPinned('agent')">
        <q-chip
          :label="`Агент ${filters.agent}`"
          color="primary"
          text-color="white"
        />
      </div>
      <div v-if="filters.rnt && !isPinned('rnt')">
        <q-chip
          :label="`РНТ ${filters.rnt}`"
          color="primary"
          text-color="white"
        />
      </div>
      <div v-if="filters.banks.length > 0 && !isPinned('banks')">
        <q-chip
          label="Банки"
          color="primary"
          text-color="white"
        >
          <div
            class="q-ml-sm"
            style="margin-top: 6px;"
          >
            <template
              v-for="bank in banks"
              :key="bank.id"
            >
              <img
                v-if="filters.banks.includes(bank.id)"
                :alt="bank.title_short"
                :src="bank.logo as string"
                style="max-height: 20px;line-height: 20px"
                class="q-mr-xs"
              >
            </template>
          </div>
        </q-chip>
      </div>
      <div v-if="(isAgent() || isHeadEmployee())  && filteredEmployees.length > 0 && !isPinned('employees')">
        <q-chip
          color="primary"
          class="text-white"
        >
          Сотрудники:
          <div class=" q-pl-xs row q-col-gutter-x-xs">
            <div v-if="filteredEmployees.length > 0 && employees.length === filteredEmployees.length">
              Все
            </div>
            <div v-else-if="filteredEmployees.length > 3 && employees.length !== filteredEmployees.length">
              {{ filteredEmployees.length }} из {{ employees.length }}
            </div>
            <div
              v-else
              v-for="employee in filteredEmployees"
              :key="employee.id"
              class="q-gutter-x-xs"
            >
              <span v-if="employee.name_last">
                {{ employee.name_last }}
              </span>
              <span v-if="employee.name_first">
                {{ employee.name_first[0] }}.
              </span>
              <span v-if="employee.name_middle">
                {{ employee.name_middle[0] }},
              </span>
            </div>
          </div>
        </q-chip>
      </div>
      <div v-if="filters.new_request && !isPinned('new_request')">
        <q-chip
          label="С доп. запросом"
          color="primary"
          text-color="white"
        />
      </div>
    </template>

    <application-grid-filters
      :loading="props.loading"
      :only-pinned="false"
      :ignore-pins="props.ignorePins"
      @update:filters="onSubmit"
    />
  </filters-expansion>
</template>

<script setup lang="ts">
import {computed} from 'vue'
import {storeToRefs} from 'pinia'
import FiltersExpansion from 'src/components/Grid/FiltersExpansion.vue'
import ApplicationGridFilters from 'src/components/Applications/ApplicationsGrid/ApplicationGridFilters.vue'
import {useBankFilterStore} from 'src/stores/Banks/useBankFilterStore'
import {useProfileStore} from 'src/stores/Login/useProfileStore'
import {
  applicationGridDefaultPins,
} from 'src/components/Applications/ApplicationsGrid/ApplicationGridDefaultFilters'
import {useApplicationsGridFiltersStore} from 'stores/Applications/Grid/useApplicationsGridFiltersStore'
import MoneyComponent from 'components/MoneyComponent.vue'
import DateItem from 'components/DateItem.vue'
import {useEmployeeFilterStore} from 'stores/Employees/useEmployeeFilterStore'

const props = defineProps<{
  loading: boolean
  ignorePins: boolean
}>()

const emit = defineEmits<{
  (e: 'update:filters'): void,
}>()

const bankFilterStore = useBankFilterStore()
const {banks} = storeToRefs(bankFilterStore)
const employeeFilterStore = useEmployeeFilterStore()
const {employees} = storeToRefs(employeeFilterStore)
const filtersStore = useApplicationsGridFiltersStore()
const {isPinned: isRealPined} = filtersStore
const {filters, clearable, pins} = storeToRefs(filtersStore)
const profileStore = useProfileStore()
const {
  isAdmin,
  isAgent,
  isHeadEmployee,
} = profileStore

const onSubmit = () => {
  emit('update:filters')
}

const isPinned = (key: string) => {
  return isRealPined(key) && !props.ignorePins
}

const onClearPins = () => {
  Object.keys(pins.value)
    .forEach(key => {
      pins.value[key] = false
    })
}

const isShowFilters = computed(() => {
  return (filters.value.number && !isPinned('number'))
    || (filters.value.client && !isPinned('client'))
    || (filters.value.agent && !isPinned('agent'))
    || (filters.value.guarantee_total_from && !isPinned('guarantee_total_from'))
    || (filters.value.guarantee_total_to && !isPinned('guarantee_total_to'))
    || (filters.value.release_date_to && !isPinned('release_date_to'))
    || (filters.value.release_date_from && !isPinned('release_date_from'))
    || (filters.value.rnt && !isPinned('rnt'))
    || (filters.value.banks.length > 0 && !isPinned('banks'))
    || (filters.value.rnt && !isPinned('rnt'))
    || (isAdmin() && filters.value.new_request && !isPinned('new_request'))
    || ((isAgent() || isHeadEmployee()) && filters.value.employees && !isPinned('employees'))
})

const filteredEmployees = computed(() => {
  return employees.value
    .filter(e => filters.value.employees.includes(e.id))
})

const onSetDefaultPins = () => {
  pins.value = JSON.parse(JSON.stringify(applicationGridDefaultPins))
}

defineExpose({
  isShowFilters,
})
</script>
