import {OpfEnum} from 'src/models/OpfEnum'

const opfList: Record<OpfEnum, string> = {
  [OpfEnum.INDIVIDUAL]: 'ИП',
  [OpfEnum.PHYSICAL]: 'Физ. лицо',
  [OpfEnum.COMPANY]: 'Юр. лицо',
}

export class Opf {
  public static getOpfDescription (opf: OpfEnum): string {
    return opfList[opf] || opf
  }
}
