<template>
  <q-btn
    size="sm"
    color="blue-grey-4"
    icon="svguse:icons.svg?2#app-trash"
    round
    flat
    @click="open"
  />
  <q-dialog
    v-model="dialog"
    class="ContractorsDeleteModal"
  >
    <q-card
      style="min-width: 500px"
      class="modal-blue-background"
    >
      <q-card-section
        class="modal-title row items-center q-pb-none"
      >
        <div>Удаление контрагента</div>
      </q-card-section>
      <q-card-section>
        <info-banner
          text="Вы уверены?"
          icon="svguse:icons.svg?2#app-exclamation"
          color="red"
        />
      </q-card-section>
      <q-card-section>
        <div class="row">
          <div class="col-12 q-pb-sm">
            Контрагент "{{ props.name }}" будет удален
          </div>
        </div>
      </q-card-section>
      <q-card-actions
        align="right"
        class="modal-actions text-primary q-pa-md"
      >
        <q-btn
          v-close-popup
          :loading="loading"
          label="Отмена"
          class="q-pr-lg"
          style="padding: 17px 28px;"
          unelevated
          flat
          no-caps
          text-color="black"
        />
        <q-btn
          :loading="loading"
          label="Удалить"
          color="red"
          style="padding: 17px 28px;"
          unelevated
          icon-right="svguse:icons.svg?2#app-trash"
          no-caps
          @click="handleSubmit()"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
import {computed, onBeforeUnmount} from 'vue'
import {useDialog} from 'src/uses/Dialogs/useDialog'
import {storeToRefs} from 'pinia'
import InfoBanner from 'src/components/InfoBanner.vue'
import {useContractorDeleteStore} from 'stores/Contractors/useContractorDeleteStore'

const emit = defineEmits<{
  (e: 'change'): void,
}>()

const props = defineProps<{
  itemId: string,
  name: string,
}>()
const itemId = computed(() => props.itemId)
const {dialog, show, hide} = useDialog()

const deleteNewsStore = useContractorDeleteStore()

const {
  loading,
  status,
} = storeToRefs(deleteNewsStore)

const {
  deleteContractor
} = deleteNewsStore

const open = async () => {
  show()
}

onBeforeUnmount(() => {
  deleteNewsStore.$dispose()
})

const handleSubmit = async () => {
  await deleteContractor(itemId.value)
  if (status.value !== 200) {
    return
  }
  hide()
  emit('change')
}
</script>
<style lang="scss">
.ContractorsDeleteModal {
  .application-banner-info {
    margin: 0 -16px 0 -16px;
  }
  .q-dialog__inner > div {
    border-radius: 6px!important;
  }
}
</style>
