<template>
  <div class="row q-mb-md">
    <div class="col">
      <products-grid-filters
        :loading="loading"
        @update:filters="updateFilters"
      />
    </div>
  </div>
  <div class="ProductsGrid--table">
    <q-table
      v-model:pagination="gridPagination"
      class="sticky-header-table"
      :rows="items"
      :columns="columns"
      :loading="loading"
      :rows-per-page-options="perPageOptions"
      row-key="index"
      hide-pagination
      @request="onRequest"
    >
      <template #body-cell-title="props">
        <q-td :props="props">
          {{ props.row.title }}
        </q-td>
      </template>
      <template #body-cell-bank="props">
        <q-td :props="props">
          <img
            :src="props.row.bank.logo"
            alt=""
            style="max-width: 100px; max-height: 50px;"
          >
          {{ props.row.bank?.title_short }}
        </q-td>
      </template>
      <template #body-cell-tariff="props">
        <q-td :props="props">
          <product-tariff-control-modal
            :product="props.row"
            :key="props.row.id"
            @updated="updateFilters"
          />
          {{ getTariffValue(props.row) }}
        </q-td>
      </template>
      <template #body-cell-actions="props">
        <q-td :props="props">
          <product-calculator-options-modal
            :product="props.row"
          />
          <product-edit-contractors-modal :product="props.row" />
          <product-required-control-modal
            :product="props.row"
            @updated="load"
          />
          <product-stop-factors-modal
            :product="props.row"
          />
          <q-toggle
            v-model="props.row.hidden"
            :false-value="true"
            :true-value="false"
            @update:model-value="changeHidden($event, props.row.id)"
          />
        </q-td>
      </template>
    </q-table>
    <pagination-component
      class="q-mb-md"
      :pagination="gridPagination"
      :per-page-options="perPageOptions"
      @request="onRequest"
      @refresh="load"
    />
  </div>
</template>

<script setup lang="ts">

import {storeToRefs} from 'pinia'
import {onBeforeMount, onBeforeUnmount, watch, ref} from 'vue'
import {useGridSettings} from 'src/uses/Grid/useGridSettings'
import {ProductsGridColumns} from 'components/Products/ProductsGridColumns'
import {useProductsListStore} from 'stores/Products/useProductsListStore'
import PaginationComponent from 'components/PaginationComponent.vue'
import ProductRequiredControlModal from 'components/Products/ProductRequiredControlModal.vue'
import ProductsGridFilters from 'components/Products/ProductsGridFilters.vue'
import {useProductsGridFiltersStore} from 'stores/Products/useProductsGridFiltersStore'
import ProductStopFactorsModal from 'components/Products/ProductStopFactorsModal.vue'
import ProductTariffControlModal from 'components/Products/Tariff/ProductTariffControlModal.vue'
import {useProductHidden} from 'src/uses/Products/useProductHidden'
import ProductCalculatorOptionsModal from 'components/Products/Calculator/ProductCalculatorOptionsModal.vue'
import {GridPagination, RequestProp} from 'src/models/Grid/Pagination'
import ProductEditContractorsModal from 'components/Products/ProductEditContractorsModal.vue'

const paginationPage = ref(1)
const filtersStore = useProductsGridFiltersStore()
const productsListStore = useProductsListStore()
const {
  items,
  loading,
  gridPagination,
  perPageOptions,
} = storeToRefs(productsListStore)

const {
  load,
  setPage,
  onRequestPagination,
  getTariffValue,
} = productsListStore

const {
  changeHidden,
} = useProductHidden()

onBeforeMount(async () => {
  await load()
})

const onRequest = ({pagination}: RequestProp) => {
  onRequestPagination(pagination as GridPagination)
  load()
}

const updateFilters = () => {
  setPage(1)
  load()
}

watch(
  paginationPage,
  () => {
    setPage(paginationPage.value)
    load()
  },
  {deep: true}
)

const {columns} = useGridSettings('products-grid', ProductsGridColumns)

onBeforeUnmount(() => {
  productsListStore.$reset()
  productsListStore.$dispose()
  filtersStore.$reset()
  filtersStore.$dispose()
})
</script>

<style lang="scss">
.ProductsGrid--table {
  margin: 0 -24px -24px;
  background: $blue-1;
  padding: 0 24px 24px;

  table {
    position: relative;

    tr {
      td {
        width: 150px;

        &:first-child {
          width: auto;
          white-space: normal;
        }
      }
    }
  }
}
</style>
