import {defineStore, storeToRefs} from 'pinia'
import {ref, watch, watchEffect} from 'vue'
import {useWebSocket} from 'src/stores/WebSocket/useWebSocketStore'
import {PublicationContext} from 'centrifuge/build/types'
import {SocketEventTypeEnum} from 'src/models/Enums/SocketEventTypeEnum'
import {ChatTypeEnum} from 'src/models/ChatTypeEnum'
import {ControlCenterHeader} from 'src/models/Applications/ControlCenter/ControlCenterHeader'
import {UserRoleEnum} from 'src/models/User/UserRoleEnum'
import {ApplicationSubStatusEnum} from 'src/models/Applications/ApplicationSubStatusEnum'
import {useProfileStore} from 'src/stores/Login/useProfileStore'
import {useChatMessages} from 'src/uses/Chat/useChatMessages'
import {useApplicationControlCenterStore} from 'stores/Applications/ControlCenter/useApplicationControlCenterStore'

export const useControlCenterChatStore = (id: string) => defineStore(`control-center-chat-${id}`, () => {
  const webSocketStore = useWebSocket()
  const {
    message,
  } = storeToRefs(webSocketStore)

  const profileStore = useProfileStore()
  const {
    profile,
  } = storeToRefs(profileStore)

  const {
    headerData,
  } = storeToRefs(useApplicationControlCenterStore(id)())

  const isBankChatDisabled = ref(false)
  const isPlatformChatDisabled = ref(false)
  const activeChat = ref<ChatTypeEnum>()

  const {
    chat,
    loadMessages,
  } = useChatMessages(id)
  const {
    chat: chatBank,
    loadMessages: loadBankMessages,
  } = useChatMessages(id, ChatTypeEnum.bank)

  const init = async (headerData: ControlCenterHeader) => {
    isPlatformChatDisabled.value = !headerData?.chats?.platform?.enabled
    isBankChatDisabled.value = !headerData?.chats?.bank?.enabled
    if (unblockBankChat(headerData)) {
      isBankChatDisabled.value = false
    }

    if (isPlatformChatDisabled.value) {
      activeChat.value = ChatTypeEnum.bank
    }
  }

  const unblockBankChat = (headerData: ControlCenterHeader) => {
    const roles = [UserRoleEnum.Agent, UserRoleEnum.Employee, UserRoleEnum.Operator]
    const statuses = [
      ApplicationSubStatusEnum.completion,
      ApplicationSubStatusEnum.data_request,
      ApplicationSubStatusEnum.risks_request,
      ApplicationSubStatusEnum.bg_project_bank_agreement_request,
      ApplicationSubStatusEnum.deferred_conditions_execution_waiting,
      ApplicationSubStatusEnum.guarantee_released_request,
    ]

    if (!profile.value) {
      return
    }

    return roles.includes(profile.value.role)
      && statuses.includes(headerData.sub_status as ApplicationSubStatusEnum)
      && !headerData?.integration?.enabled
  }

  const messageWasAdded = async (ctx: PublicationContext) => {
    const messData = ctx.data
    const payload = messData?.payload

    if (!(messData && messData.event_type && payload && payload.order_id === id)) {
      return
    }

    if (messData.event_type !== SocketEventTypeEnum.order_chat_message_added) {
      return
    }

    ctx.data.payload.chat === ChatTypeEnum.platform
      ? await loadMessages()
      : await loadBankMessages()
  }

  watchEffect(async () => {
    if (!activeChat.value) {
      return
    }

    if (activeChat.value === ChatTypeEnum.platform && !chat.value) {
      await loadMessages()

      return
    }

    if (activeChat.value === ChatTypeEnum.bank && !chatBank.value) {
      await loadBankMessages()
    }
  })

  watch(
    message,
    () => {
      if (!message.value) {
        return
      }

      messageWasAdded(message.value)
    })

  watch(
    headerData,
    () => {
      if (!headerData.value || activeChat.value) {
        return
      }

      if (profileStore.isBankEmployee()) {
        activeChat.value = ChatTypeEnum.bank
        return
      }

      if (headerData.value.chats.bank.new_messages > 0
        || (
          headerData.value.new_request
          && headerData.value.sub_status !== ApplicationSubStatusEnum.completion
        )
        || headerData.value.sub_status === ApplicationSubStatusEnum.new
      ) {
        activeChat.value = ChatTypeEnum.bank

        return
      }

      activeChat.value = ChatTypeEnum.platform
    },
    {
      deep: true,
      immediate: true,
    }
  )

  return {
    chat,
    chatBank,
    isBankChatDisabled,
    isPlatformChatDisabled,
    activeChat,
    loadMessages,
    loadBankMessages,
    init,
  }
})
