import {defineStore} from 'pinia'
import {computed, watch} from 'vue'
import {useGetIntegratorData} from 'src/uses/useGetIntegratorData'

export const useIntegratorStore = defineStore(
  'integrator',
  () => {
    const {
      loading,
      data,
      load,
    } = useGetIntegratorData()

    const loaded = computed(() => {
      return !!data.value
    })

    const setIcon = () => {
      let link = document.querySelector<HTMLLinkElement>('link[rel~=\'icon\']')
      if (!link) {
        link = document.createElement('link')
        document.head.appendChild(link)
        link.rel = 'icon'
      }

      if (!data.value) {
        return
      }

      link.href = data.value.favicon
    }

    watch(
      data,
      () => {
        setIcon()
      },
      {
        deep: true,
      }
    )

    return {
      loading,
      loaded,
      data,
      load,
    }
  })
