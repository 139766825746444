import {defineStore} from 'pinia'
import {useGetApi} from 'src/uses/Api/useGetApi'
import {ref} from 'vue'
import {Region} from 'src/models/Clients/Region'

export const useRegionsStore = defineStore('regions-store', () => {
  const {
    loading,
    get,
    status,
    response,
  } = useGetApi<Region[]>()

  const regions = ref<Region[]>([])

  const load = async () => {
    await get('v1/client/regions')

    if (status.value === 200 && response && response.value) {
      regions.value = response.value
    }
  }

  return {
    loading,
    regions,
    load,
  }
})
