export class FormHelper {
  public static getFormDataFromObject (object: object): FormData {
    const formData = new FormData()
    for (const key of Object.keys(object)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      formData.append(key, object[key])
    }

    return formData
  }
}
