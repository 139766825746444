import {defineStore} from 'pinia'
import {useGridFilters} from 'src/uses/Grid/useGridFilters'
import {ReportsGridFilters} from 'src/models/Profile/Reports/ReportsGridFilters'
import {computed, ref, watch} from 'vue'
import {ProductEnum} from 'src/models/Applications/ProductEnum'
import {ProductDescriptions} from 'src/models/Applications/ProductDescriptions'
import {useFilterStatus} from 'src/uses/Applications/useFilterStatus'
import {ApplicationStatusEnum} from 'src/models/Applications/ApplicationStatusEnum'
import moment from 'moment'
import {ApplicationKikStatusEnum} from 'src/models/Applications/ApplicationKikStatusEnum'
import {useFilterKikStatus} from 'src/uses/Applications/useFilterKikStatus'
import {useProfileStore} from 'stores/Login/useProfileStore'
import {ReportTypeEnum} from 'src/models/Profile/Reports/ReportTypeEnum'

export const useReportsGridFiltersStore = defineStore('profile-reports-grid-filters', () => {
  const {
    isAdmin,
  } = useProfileStore()
  const defaultFilters = computed<ReportsGridFilters>(() => {
    return {
      banks: [],
      statuses: [],
      employees: [],
      product_type: ProductEnum.EBG,
      date_from: moment().subtract(1, 'month').format('DD.MM.YYYY'),
      date_to: moment().format('DD.MM.YYYY'),
      ...(
        isAdmin()
          ? {
              report_type: ReportTypeEnum.OrdersReport,
              clients: [],
              agents: [],
              release_date_from: moment().subtract(1, 'month').format('DD.MM.YYYY'),
              release_date_to: moment().format('DD.MM.YYYY'),
              status_date_from: null,
              status_date_to: null,
              guarantee_amount_min: null,
              guarantee_amount_max: null,
            }
          : {}
      )
    }
  })

  const {
    filters,
    clearable,
    getFiltersQuery,
    clear,
    isDefaultFilterValue,
  } = useGridFilters(defaultFilters.value)

  const {
    filterLabel,
    filterStatuses
  } = useFilterStatus()

  const {
    filterLabel: filterKikLabel,
    filterStatuses: filterKikStatuses
  } = useFilterKikStatus()

  const productType = computed(() => filters.value.product_type)

  const statusesEbgForSelect = ref(
    filterStatuses.value
      .filter(status => ![
        ApplicationStatusEnum.all,
        ApplicationStatusEnum.active,
        ApplicationStatusEnum.favorites,
        ApplicationStatusEnum.release_expired,
        ApplicationStatusEnum.release_today,
        ApplicationStatusEnum.release_without_date,
      ].includes(status))
      .map(status => {
        return {
          label: filterLabel(status),
          value: status
        }
      })
  )

  const statusesKikForSelect = ref(
    filterKikStatuses.value
      .filter(status => [
        ApplicationKikStatusEnum.draft,
        ApplicationKikStatusEnum.need_sign,
        ApplicationKikStatusEnum.in_bank,
        ApplicationKikStatusEnum.approved,
        ApplicationKikStatusEnum.released,
        ApplicationKikStatusEnum.bank_rejected,
        ApplicationKikStatusEnum.client_rejected,
        ApplicationKikStatusEnum.system_rejected,
      ].includes(status))
      .map(status => {
        return {
          label: filterKikLabel(status),
          value: status
        }
      })
  )

  const productsForSelect = ref(
    Object.values(ProductEnum)
      .filter(type => type !== ProductEnum.RKO)
      .map(product => {
        return {
          label: ProductDescriptions.getProductDescription(product),
          value: product
        }
      })
  )

  watch(
    productType,
    () => {
      filters.value.statuses = []
      filters.value.banks = []
    },
    {
      deep: true,
      immediate: true,
    }
  )

  return {
    filters,
    clearable,
    getFiltersQuery,
    clear,
    statusesEbgForSelect,
    statusesKikForSelect,
    productsForSelect,
    isDefaultFilterValue,
  }
})
