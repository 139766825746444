<template>
  <table class="FinanceTable">
    <thead>
      <tr>
        <th>Название</th>
        <th>Код</th>
        <th>{{ props.periodLabel }} (тыс. ₽)</th>
      </tr>
    </thead>
    <tbody>
      <slot name="default" />
    </tbody>
  </table>
</template>

<script setup lang="ts">
const props = defineProps<{
  periodLabel: string
}>()
</script>

<style lang="scss">
.FinanceTable {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;

  th {
    text-align: left;
    background: $grey-3;
    color: $grey-7;
    height: 43px;
    padding: 0 16px;
    font-weight: normal;

    &:last-child {
      text-align: right;
      width: 170px;
    }

    &:nth-child(2) {
      width: 70px;
    }
  }

  tr {
    &:hover {
      td {
        background: $blue-2;
      }
    }
  }

  td {
    color: $grey-9;
    padding: 5px 16px;
    border-bottom: 1px solid $blue-grey-1;

    &:first-child {
      padding-left: 36px;

      &.FinanceTableBlockHeader {
        padding-left: 16px;
        background: $grey-1;
        height: 40px;
        font-size: 17px;
      }
    }

    .q-field__control, .q-field__native {
      height: 30px !important;
      min-height: 30px !important;

      input {
        margin-top: -3px !important;
      }
    }

    .q-field--disabled {
      .q-field__control {
        & > div {
          opacity: 1 !important;
          font-weight: 700;
        }
      }

      .text-red-13 {
        input {
          color: $red-13;
        }
      }

      .text-green-10 {
        input {
          color: $green-10;
        }
      }
    }
  }
}
</style>
