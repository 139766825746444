import {AuthedGuard} from 'src/router/quards/AuthedGuard'
import {UserRoleEnum} from 'src/models/User/UserRoleEnum'

export const banksRouter = {
  path: '/banks',
  component: () => import(/* webpackChunkName: "MainLayout" */ '../layouts/MainLayout.vue'),
  children: [
    {
      path: '',
      name: 'banks-grid',
      component: () => import(/* webpackChunkName: "NewsView" */ '../views/Banks/BanksView.vue'),
      meta: {
        guards: [
          AuthedGuard([
            UserRoleEnum.Admin,
          ]),
        ],
      },
    },
  ]
}
