import {GridColumnEditableSettings} from 'src/models/Grid/GridColumnSettings'

export const NewsGridColumns: GridColumnEditableSettings[] = [
  {
    field: '',
    name: 'title',
    required: true,
    label: 'Заголовок',
    align: 'left',
    classes: 'text-no-wrap',
    visible: true,
    editable: false,
    order: 1,
  },
  {
    field: '',
    name: 'topic',
    required: true,
    label: 'Тема новости',
    align: 'left',
    classes: 'text-no-wrap',
    visible: true,
    editable: true,
    order: 2,
  },
  {
    field: '',
    name: 'publication-date',
    required: true,
    label: 'Дата создания/публикации',
    align: 'left',
    classes: 'text-no-wrap',
    visible: true,
    editable: true,
    order: 3,
  },
  {
    field: '',
    name: 'status',
    required: true,
    label: 'Статус',
    align: 'left',
    visible: true,
    editable: true,
    order: 4,
  },
  {
    field: '',
    name: 'actions',
    label: '',
    required: true,
    align: 'center',
    visible: true,
    editable: false,
    order: 5,
  },
]
