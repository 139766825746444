import {defineStore, storeToRefs} from 'pinia'
import {useFormItemFinancialReportsStore} from 'stores/FormEditor/useFormItemFinancialReportsStore'
import {ref, computed, watchEffect, watch} from 'vue'
import {
  FormFinanceBlock, FormFinanceBlockPeriod, FormItemFinancePeriodCondition, FormItemFinancePeriodConditionsPart,
} from 'src/models/FormEditor/FormItemResponseData'
import {usePutApi} from 'src/uses/Api/usePutApi'
import {useFormItemStore} from 'stores/FormEditor/useFormItemStore'
import {useFormItemFinancePeriod} from 'src/uses/FormEditor/Finance/useFormItemFinancePeriod'
import {useFormItemConditions} from 'src/uses/FormEditor/Finance/useFormItemConditions'
import useVuelidate from '@vuelidate/core'
import {Ref} from 'vue/dist/vue'

export const useFormItemFinancialStore = (formId: string) => defineStore(`form-item-financial-${formId}`, () => {
  const formItemFinancialReportsStore = useFormItemFinancialReportsStore()
  const {
    loadReports
  } = formItemFinancialReportsStore
  const {
    reports,
  } = storeToRefs(formItemFinancialReportsStore)

  const {
    item
  } = storeToRefs(useFormItemStore(formId)())

  const {
    put,
    status,
    response,
    errors,
  } = usePutApi<FormFinanceBlock, FormFinanceBlock>()

  const {
    getYears,
    getQuarters
  } = useFormItemFinancePeriod()

  const periodsOff = ref<boolean>(false)

  const periods = computed(() => {
    if (!item.value || !item.value.finance_block) {
      return []
    }

    return item.value.finance_block.periods || []
  })

  const reportsOptions = computed(() => {
    return reports.value
      .map(report => ({
        label: report.title,
        value: report.id,
      }))
  })

  const addPeriod = async () => {
    if (!item.value || !item.value.finance_block) {
      return
    }
    item.value.finance_block.periods.push({
      year: null,
      quarter: null,
      comment: '',
      reports: []
    })
  }

  const deletePeriod = async (periodId: string | undefined, key: number) => {
    if (!item.value || !item.value.finance_block) {
      return
    }

    item.value.finance_block.periods.splice(key, 1)
  }

  const getFieldsOptions = (reportId: string) => {
    const reportInd = reports.value.findIndex(r => r.id === reportId)
    if (reportInd === -1) {
      return []
    }

    return reports.value[reportInd].fields
      .map(f => {
        return {
          label: `${f.code} ${f.title}`,
          value: f.id,
        }
      })
  }

  const updateReports = async (periodId: string | undefined, reportsIds: string[]) => {
    if (!item.value || !item.value?.finance_block) {
      return
    }
    const periodInd = periods.value.findIndex(period => period.id === periodId)
    if (periodInd === -1) {
      return
    }

    item.value.finance_block.periods[periodInd].reports = reports.value
      .filter(r => reportsIds.includes(r.id))
      .map(r => {
        const existingReport = (periods.value[periodInd].reports || []).find(report => report.id === r.id)
        return {
          id: r.id,
          title: r.title,
          fields: existingReport ? existingReport.fields : []
        }
      })

    await save()
  }

  const updateReportFields = async (periodId: string | undefined, reportId: string, fieldsIds: string[]) => {
    if (!item.value || !item.value?.finance_block) {
      return
    }
    const periodInd = periods.value.findIndex(period => period.id === periodId)
    if (periodInd === -1) {
      return
    }

    const reportInd = (periods.value[periodInd].reports || []).findIndex(report => report.id === reportId)

    if (reportInd === -1) {
      return
    }

    if (!item.value.finance_block.periods[periodInd].reports) {
      item.value.finance_block.periods[periodInd].reports = []
    }

    const report = reports.value.find(r => r.id === reportId)
    if (!report) {
      return
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    item.value.finance_block.periods[periodInd].reports[reportInd].fields = report.fields
      .filter(f => fieldsIds.includes(f.id))

    await save()
  }

  const init = () => {
    if (!item.value || !item.value?.finance_block) {
      return
    }

    item.value.finance_block.periods.forEach((periodCondition: FormFinanceBlockPeriod, periodKey) => {
      periodCondition.conditions?.parts.forEach((condition: FormItemFinancePeriodCondition, conditionKey) => {
        condition.parts.forEach((param: FormItemFinancePeriodConditionsPart, paramKey) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          if ((param.value === false || param.value === true) && item.value && item.value.finance_block.periods[periodKey].conditions) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            item.value.finance_block.periods[periodKey].conditions.parts[conditionKey].parts[paramKey].value = param.value ? 'yes' : 'no'
          }
        })
      })
    })
  }

  const save = async () => {
    const periodsWithoutIds: FormFinanceBlockPeriod[] = periods.value.filter(p => !(
      p.quarter
      && p.year
      && p.reports
      && p.reports.length > 0
      && !p.reports.find(r => r.fields.length === 0))
    ) || []

    await put(`v2/forms/${formId}/finance`, {
      allow_short_reporting: item.value?.finance_block.allow_short_reporting || false,
      periods: !periodsOff.value ? [] : periods.value.filter(p => p.quarter
        && p.year
        && p.reports
        && p.reports.length > 0
      )
    })

    if (status.value === 200 && response.value && item.value && item.value.finance_block) {
      item.value.finance_block = response.value
      if (periodsWithoutIds.length > 0 && !item.value.finance_block.periods) {
        item.value.finance_block.periods = []
      }
      item.value.finance_block.periods.push(...periodsWithoutIds)
      init()
    }
  }

  const {
    addCondition,
    removeCondition,
    addParam,
    removeParam,
    onChangeParam,
    changeMultiSelectParamValue,
    changeParamValueOrOperator,
    operatorOptions,
    paramComparisonOptions,
    conditionParams,
    paramValueTypesAndOptions,
  } = useFormItemConditions(formId, save)

  const finBlock = computed(() => item.value ? item.value.finance_block : { allow_short_reporting: false, periods: []})
  const validation = useVuelidate<Ref<FormFinanceBlock>>([], finBlock)

  watchEffect(() => {
    if (periods.value && periods.value.length > 0) {
      periodsOff.value = true
    }
  })

  watch(
    periods,
    () => {
      if (periods.value.length === 0) {
        periodsOff.value = false
      }
    },
    {
      deep: true,
    }
  )

  watchEffect(() => {
    if (item.value && item.value.finance_block === null) {
      item.value.finance_block = {
        allow_short_reporting: false,
        periods: []
      }
    }

    if (item.value && item.value.finance_block && !item.value.finance_block.periods) {
      item.value.finance_block.periods = []
    }
  })

  return {
    item,
    loadReports,
    addPeriod,
    deletePeriod,
    reports,
    periods,
    reportsOptions,
    periodsOff,
    status,
    errors,
    conditionParams,
    operatorOptions,
    paramComparisonOptions,
    paramValueTypesAndOptions,
    validation,

    getYears,
    getQuarters,
    getFieldsOptions,

    updateReports,
    updateReportFields,
    save,
    init,

    addCondition,
    removeCondition,
    addParam,
    removeParam,
    onChangeParam,
    changeMultiSelectParamValue,
    changeParamValueOrOperator,
  }
})
