import {defineStore, storeToRefs} from 'pinia'
import {useDialog} from 'src/uses/Dialogs/useDialog'
import {computed, ref, watchEffect} from 'vue'
import {ApplicationFormBank} from 'src/models/Applications/Form/ApplicationFormResponseData'
import {ApplicationSubStatusEnum} from 'src/models/Applications/ApplicationSubStatusEnum'
import {useSendToCheck} from 'src/stores/Applications/useSendToCheck'
import {useApplicationItemEbgStore} from 'stores/Applications/Item/useApplicationItemEbgStore'
import {useApplicationItemEbgFillStore} from 'stores/Applications/Item/useApplicationItemEbgFillStore'
import {useApplicationItemStore} from 'stores/Applications/Item/useApplicationItemStore'
import {useApplicationItemKikStore} from 'stores/Applications/Item/useApplicationItemKikStore'
import {useApplicationItemKikFillStore} from 'stores/Applications/Item/useApplicationItemKikFillStore'

export const useApplicationItemSendStore = (multiOrderId: number) => defineStore(`application-item-send-${multiOrderId}`, () => {
  const applicationItemProductStore = useApplicationItemStore(multiOrderId)()
  const {
    startUpdateChangesProcess,
  } = applicationItemProductStore
  const {
    hasEbg,
    hasKik,
  } = storeToRefs(applicationItemProductStore)

  const applicationItemEbgStore = useApplicationItemEbgStore(multiOrderId)()
  const {
    banks: banksEbg,
  } = storeToRefs(applicationItemEbgStore)
  const fillEbgStore = useApplicationItemEbgFillStore(multiOrderId)()
  const {
    fillByBanks: fillByBanksEbg,
  } = storeToRefs(fillEbgStore)

  const applicationItemKikStore = useApplicationItemKikStore(multiOrderId)()
  const {
    banks: banksKik,
  } = storeToRefs(applicationItemKikStore)
  const fillKikStore = useApplicationItemKikFillStore(multiOrderId)()
  const {
    fillByBanks: fillByBanksKik,
  } = storeToRefs(fillKikStore)

  const {
    dialog: sendDialog,
    show: sendDialogShow,
    hide: sendDialogHide,
  } = useDialog()

  const sendToCheckStore = useSendToCheck()
  const {send} = sendToCheckStore
  const {
    loading,
    status,
    response,
  } = storeToRefs(sendToCheckStore)

  const selectedBanks = ref<ApplicationFormBank[]>([])

  const banks = computed(() => {
    return [
      ...(banksEbg.value || []),
      ...(banksKik.value || []),
    ]
  })

  const fillByBanks = computed(() => {
    return [
      ...(fillByBanksEbg.value || []),
      ...(fillByBanksKik.value || []),
    ]
  })

  const realSend = async () => {
    return send(selectedBanks.value.map(b => b.id), multiOrderId)
  }

  const selectAllAvailableBanks = () => {
    selectedBanks.value = [
      ...(banks.value || [])
        .filter(availableToSelect),
    ]
  }

  const availableToSelect = (bank: ApplicationFormBank) => {
    return !!fillByBanks.value
      .filter(b => b.sub_status === ApplicationSubStatusEnum.draft)
      .filter(b => b.error === 0)
      .find(b => b.id === bank.id)
  }

  watchEffect(() => {
    if (!sendDialog.value) {
      return
    }

    selectAllAvailableBanks()
  })

  watchEffect(() => {
    if (!banks.value) {
      return
    }

    selectedBanks.value = selectedBanks.value
      .filter(availableToSelect)
  })

  watchEffect(() => {
    if (!response.value) {
      return
    }

    startUpdateChangesProcess(response.value)
  })

  return {
    selectedBanks,
    banks,
    availableToSelect,
    selectAllAvailableBanks,
    send: realSend,
    loading,
    status,
    response,
    hasEbg,
    hasKik,

    sendDialog,
    sendDialogShow,
    sendDialogHide,
  }
})
