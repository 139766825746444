import {useDialog} from 'src/uses/Dialogs/useDialog'
import {ApplicationSubStatusEnum} from 'src/models/Applications/ApplicationSubStatusEnum'
import {useProfileStore} from 'src/stores/Login/useProfileStore'
import {Ref} from 'vue'
import {ChangeStatusPayload} from 'src/models/Status/ChangeStatusPayload'
import {useHeaderDataFromStore} from 'src/uses/Applications/ControlCenter/useHeaderDataFromStore'

const bankRequestStatusMapping = [
  {
    original: ApplicationSubStatusEnum.data_request,
    new: ApplicationSubStatusEnum.data_request_sign
  },
  {
    original: ApplicationSubStatusEnum.risks_request,
    new: ApplicationSubStatusEnum.risks_request_sign
  },
  {
    original: ApplicationSubStatusEnum.bg_project_bank_agreement_request,
    new: ApplicationSubStatusEnum.bg_project_bank_agreement_request_sign
  },
  {
    original: ApplicationSubStatusEnum.deferred_conditions_execution_waiting,
    new: ApplicationSubStatusEnum.deferred_conditions_execution_waiting_sign
  },
  {
    original: ApplicationSubStatusEnum.guarantee_released_request,
    new: ApplicationSubStatusEnum.guarantee_released_request_sign
  },
  {
    original: ApplicationSubStatusEnum.bg_project_customer_agreement,
    new: ApplicationSubStatusEnum.bg_project_customer_agreement_request_sign
  },
]

export const useBankRequestSign = (payload: Ref<ChangeStatusPayload | undefined>, realChangeStatus: () => Promise<void>) => {
  const {
    dialog: bankRequestSignDialog,
    show: showBankRequestSignDialog,
    hide: hideBankRequestSignDialog,
  } = useDialog()

  const {
    isOperator,
    isAgent,
    isEmployee,
    isHeadEmployee,
    isAgentManager,
  } = useProfileStore()

  const {
    getHeaderData
  } = useHeaderDataFromStore()

  const needShowDialog = async (currentSubStatus: ApplicationSubStatusEnum) => {
    if (!payload.value) {
      return false
    }
    const headerData = await getHeaderData(payload.value.id)
    if (!headerData) {
      return false
    }

    const validRoles = isOperator()
      || isAgent()
      || isEmployee()
      || isHeadEmployee()
      || isAgentManager()

    return validRoles
      && [
        ApplicationSubStatusEnum.data_request,
        ApplicationSubStatusEnum.risks_request,
        ApplicationSubStatusEnum.bg_project_bank_agreement_request,
        ApplicationSubStatusEnum.deferred_conditions_execution_waiting,
        ApplicationSubStatusEnum.guarantee_released_request,
        ApplicationSubStatusEnum.bg_project_customer_agreement,
      ].includes(currentSubStatus)
      && [
        ApplicationSubStatusEnum.data_request_executed,
        ApplicationSubStatusEnum.risks_request_executed,
        ApplicationSubStatusEnum.bg_project_bank_agreement,
        ApplicationSubStatusEnum.deferred_conditions_executed,
        ApplicationSubStatusEnum.guarantee_released,
      ].includes(payload.value.applicationStatus as ApplicationSubStatusEnum)
      && headerData.not_signed_documents.length > 0
  }

  const onSaveBankRequestSign = async (signWasRequested: boolean) => {
    if (!payload.value) {
      return
    }

    if (!signWasRequested) {
      payload.value.bank_not_need_signed_documents_check = true
    }

    if (signWasRequested) {
      const data = await getHeaderData(payload.value.id)
      const mapping = bankRequestStatusMapping
        .find(m => data && m.original === data.sub_status)

      payload.value.applicationStatus = mapping
        ? mapping.new
        : payload.value.applicationStatus
    }

    await realChangeStatus()
    hideBankRequestSignDialog()
  }

  return {
    bankRequestSignDialog,
    showBankRequestSignDialog,
    hideBankRequestSignDialog,
    getHeaderData,
    needShowDialog,
    onSaveBankRequestSign,
  }
}
