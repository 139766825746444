import {ref} from 'vue'
import {
  ApplicationFormOptions,
} from 'src/models/Applications/Form/ApplicationFormResponseData'

export const useOptions = () => {
  const options = ref<ApplicationFormOptions | null>(null)

  return {
    options
  }
}
