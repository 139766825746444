import {useDeleteApi} from 'src/uses/Api/useDeleteApi'

export const useDeleteHelpBlock = () => {
  const {
    deleteRequest,
    response,
    status,
    loading,
    error,
    errors,
  } = useDeleteApi<never>()

  const deleteBlock = async (pageId: string) => {
    await deleteRequest(`admin/help/blocks/${pageId}`)
  }

  return {
    loading,
    response,
    status,
    error,
    errors,
    deleteBlock,
  }
}
