import {usePatchApi} from 'src/uses/Api/usePatchApi'

export const useFormItemUpdateDocumentDescription = () => {
  const {
    patch,
    response,
    status,
    loading,
    error,
    errors,
  } = usePatchApi<never, {description: string}>()

  const updateDocumentDescription = async (
    docId: string,
    description: string,
  ) => {
    await patch(`v2/forms/documents/${docId}/description`, {description})
  }

  return {
    loading,
    response,
    status,
    error,
    errors,
    updateDocumentDescription,
  }
}
