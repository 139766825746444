<template>
  <div
    v-if="data"
    :class="{'row items-center justify-center PaginationComponent text-grey-7': true, 'justify-end': !props.mobile, PaginationComponentMobile: props.mobile}"
  >
    <template v-if="!props.mobile">
      <div class="col">
        <q-btn
          v-if="props.canRefresh"
          color="blue-grey-4"
          icon="svguse:icons.svg?1#app-refresh"
          class="q-mr-sm"
          flat
          square
          dense
          @click="refresh"
        />
        <slot name="buttons" />
      </div>
      <div class="no-wrap">
        Строк на странице:
      </div>
      <div class="q-px-sm">
        <q-select
          v-model="data.rowsPerPage"
          class="field-with-top-label"
          :options="props.perPageOptions"
          label=""
          outlined
          hide-bottom-space
          dense
        />
      </div>
    </template>
    <div class="q-px-md">
      {{ from }} - {{ to }} из {{ data.rowsNumber }}
    </div>
    <div>
      <q-btn
        :disable="data.page === 1"
        color="blue-grey-4"
        icon="svguse:icons.svg?2#app-chevron-double-left"
        flat
        square
        dense
        @click="setFirst"
      />
      <q-btn
        :disable="data.page === 1"
        color="blue-grey-4"
        icon="svguse:icons.svg?2#app-chevron-left"
        flat
        square
        dense
        @click="setPrev"
      />
      <q-btn
        :disable="data.page === countPages || data.rowsNumber === 0"
        color="blue-grey-4"
        icon="svguse:icons.svg?2#app-chevron-right"
        flat
        square
        dense
        @click="setNext"
      />
      <q-btn
        :disable="data.page === countPages || data.rowsNumber === 0"
        color="blue-grey-4"
        icon="svguse:icons.svg?2#app-chevron-double-right"
        flat
        square
        dense
        @click="setLast"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import {computed, withDefaults, ref, watch} from 'vue'
import {GridPagination, RequestProp} from 'src/models/Grid/Pagination'
import {QSelect} from 'quasar'

const props = withDefaults(
  defineProps<{
    pagination: GridPagination
    perPageOptions: number[]
    mobile?: boolean
    canRefresh?: boolean
  }>(),
  {
    mobile: false,
    canRefresh: true,
  }
)

const data = ref<GridPagination>()

const emit = defineEmits<{
  (
    e: 'request',
    value: RequestProp
  ): void,
  (
    e: 'refresh',
  ): void,
}>()

const setFirst = () => {
  if (!data.value) {
    return
  }
  data.value.page = 1
}

const refresh = () => {
  emit('refresh')
}

const countPages = computed(() => {
  if (!data.value) {
    return 0
  }

  return Math.ceil(data.value.rowsNumber / data.value.rowsPerPage)
})

const from = computed(() => {
  if (!data.value) {
    return 0
  }

  if (data.value?.rowsNumber === 0) {
    return 0
  }

  return data.value.rowsPerPage * (data.value.page - 1) + 1
})

const to = computed(() => {
  if (!data.value) {
    return 0
  }
  const to = data.value.rowsPerPage * data.value.page

  return to > data.value.rowsNumber ? data.value?.rowsNumber : to
})

const setNext = () => {
  if (!data.value) {
    return
  }
  data.value.page = data.value.page + 1
}

const setPrev = () => {
  if (!data.value) {
    return
  }
  data.value.page = data.value.page - 1
}

const setLast = () => {
  if (!data.value) {
    return
  }
  data.value.page = countPages.value
}

watch(
  props,
  () => {
    data.value = { ...props.pagination }
  },
  {
    deep: true,
  }
)

watch(
  data,
  () => {
    if (!data.value || JSON.stringify(data.value) === JSON.stringify(props.pagination)) {
      return
    }

    emit(
      'request',
      {
        pagination: data.value,
        getCellValue: () => {}
      }
    )
  },
  {
    deep: true,
  }
)
</script>

<style lang="scss">
body {
  .PaginationComponent {
    border-bottom: 1px solid $blue-grey-1;
    margin: 0 -24px;
    padding: 16px 24px 8px;

    .q-field.q-field--dense .q-field__control,
    .q-field.q-field--dense .q-field__marginal {
      height: 40px !important;
    }

    .q-field .q-field__inner .q-field__control {
      background: #C7DFF1;
      padding: 0 10px 0 14px;
      border-radius: 6px;

      &::before {
        border-color: transparent !important;
      }
    }

    &.PaginationComponentMobile {
      margin: 0;
      border: none;
      padding: 16px 4px 38px;
    }

    .q-field.field-with-top-label {
      padding-top: 0 !important;
    }
  }
}
</style>
