<template>
  <q-btn
    size="sm"
    color="red"
    icon="svguse:icons.svg?2#app-trash"
    round
    flat
    @click="open"
  />
  <q-dialog
    v-model="dialog"
  >
    <q-card>
      <q-card-section>
        <div class="text-h6 text-red">Удаление учетной записи пользователя</div>
      </q-card-section>
      <q-card-section>
        Вы уверены что хотите удалить учетную запись пользователя?
        Отменить данное действие будет невозможно.
      </q-card-section>
      <q-card-actions
        align="right"
        class="text-red"
      >
        <q-btn
          v-close-popup
          :loading="loading"
          label="Отмена"
          class="q-pr-md"
          color="white"
          text-color="black"
        />
        <q-btn
          :loading="loading"
          label="Удалить"
          color="red"
          @click="handleSubmit()"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
import {computed, watch} from 'vue'
import {useDialog} from 'src/uses/Dialogs/useDialog'
import {storeToRefs} from 'pinia'
import {useUserDeleteStore} from 'src/stores/Profiles/Users/useUserDeleteStore'

const emit = defineEmits<{
  (e: 'change'): void,
}>()

const props = defineProps<{
  itemId: number
}>()
const itemId = computed(() => props.itemId)
const {dialog, show, hide} = useDialog()

const deleteUserStore = useUserDeleteStore()

const {
  success,
  loading
} = storeToRefs(deleteUserStore)

const {
  deleteUser
} = deleteUserStore

const open = async () => {
  show()
}

const handleSubmit = async () => {
  await deleteUser(itemId.value)
  hide()
}

watch(
  success,
  () => {
    if (success.value) {
      emit('change')
    }
  },
  {
    deep: true,
  },
)

</script>
