<template>
  <div
    v-if="bgSumField && !bgSumField.invisible"
    class="row"
  >
    <field-component
      :key="String(bgSumField.value)"
      :field="bgSumField"
      :banks="banks"
      class="col-12"
      ignore-width
      @update:value="onUpdate"
    />
    <field-component
      v-if="nmcField && !nmcField.invisible"
      :key="String(nmcField.value)"
      :field="nmcField"
      :banks="banks"
      class="col-12"
      :style="!$q.platform.is.mobile ? 'margin-top: -45px;' : ''"
      ignore-width
      @update:value="onUpdate"
    />
  </div>
  <div class="row">
    <div
      v-if="startDateField && !startDateField.invisible"
      :key="String(startDateField.value)"
      class="col q-mr-sm"
    >
      <field-component
        :field="startDateField"
        :banks="banks"
        label="Срок действия банковской гарантии"
        ignore-width
        @update:value="onUpdate"
      />
    </div>
    <div
      v-if="endDateField && !endDateField.invisible"
      :key="String(endDateField.value)"
      class="col q-mr-sm"
    >
      <field-component
        :field="endDateField"
        :banks="banks"
        label=" "
        ignore-width
        @update:value="onUpdate"
      />
    </div>
    <div
      v-if="endDateField && startDateField"
      class="white-bg-field"
      style="width: 90px;"
    >
      <q-input
        :model-value="countDays"
        class="field-with-top-label"
        disable
        label="Дней"
        outlined
        hide-bottom-space
        dense
      />
    </div>
  </div>
  <div class="row">
    <div
      v-if="startFromReleaseDateField && !startFromReleaseDateField.invisible"
      :key="String(startFromReleaseDateField.value)"
      class="col q-mr-sm"
    >
      <field-component
        :field="startFromReleaseDateField"
        :banks="banks"
        :style="!$q.platform.is.mobile ? 'margin-top: -30px;' : ''"
        ignore-width
        @update:value="onUpdate"
      />
    </div>
  </div>
  <application-form-document-component
    v-if="customerBgForm"
    :document="customerBgForm"
    :banks="banks"
    @cancel="cancelUpload(customerBgForm)"
    @document:delete="deleteDocument(customerBgForm)"
    @document:download-sign="downloadDocumentSign(customerBgForm, ApplicationFormDocumentGroupEnum.offer)"
    @document:download="downloadDocument(customerBgForm, ApplicationFormDocumentGroupEnum.offer)"
    @file:selected="onFileSelected($event, customerBgForm)"
    @file:selected-with-sign="(original, sign) => onFileSelectedWithSign(original, sign, customerBgForm)"
  />
</template>

<script setup lang="ts">
import {
  useApplicationControlCenterItemStore
} from 'src/stores/Applications/ControlCenter/useApplicationControlCenterItemStore'
import {storeToRefs} from 'pinia'
import {useImportantFields} from 'src/uses/Applications/Form/useImportantFields'
import {computed} from 'vue'
import FieldComponent from 'src/components/Fields/FieldComponent.vue'
import {FormElementValue} from 'src/models/Form'
import {
  ApplicationFormDocument,
  ApplicationFormDocumentGroupEnum,
  ApplicationFormField
} from 'src/models/Applications/Form/ApplicationFormResponseData'
import ApplicationFormDocumentComponent
  from 'src/components/Applications/Form/Documents/ApplicationFormDocumentComponent.vue'
import {DocumentsCodesEnum} from 'src/models/DocumentsCodesEnum'

const props = defineProps<{
  appId: string,
}>()

const applicationControlCenterItemStore = useApplicationControlCenterItemStore(props.appId)()
const {
  updateMainFields,
  load,
  cancelUpload,
  deleteDocument,
  downloadDocument,
  downloadDocumentSign,
  uploadDocument,
} = applicationControlCenterItemStore
const {
  loading: loadingForm,
  item,
  banks,
  documents,
} = storeToRefs(applicationControlCenterItemStore)

const {
  bgSumField,
  startDateField,
  endDateField,
  countDays,
  nmcField,
  startFromReleaseDateField,
} = useImportantFields(item)

const customerBgForm = computed(() => {
  if (!documents.value) {
    return undefined
  }

  const offerDocuments = documents.value[ApplicationFormDocumentGroupEnum.offer]
  if (!offerDocuments) {
    return undefined
  }

  return offerDocuments
    .find(doc => doc.code === DocumentsCodesEnum.CUSTOMER_FORM)
})

const loading = computed(() => {
  if (loadingForm.value) {
    return true
  }

  if (bgSumField.value && bgSumField.value.loading) {
    return true
  }
  if (startDateField.value && startDateField.value.loading) {
    return true
  }
  if (endDateField.value && endDateField.value.loading) {
    return true
  }
  if (nmcField.value && nmcField.value.loading) {
    return true
  }
  if (startFromReleaseDateField.value && startFromReleaseDateField.value.loading) {
    return true
  }
  if (customerBgForm.value && customerBgForm.value.loading) {
    return true
  }

  return false
})

const onFileSelected = (file: File, document: ApplicationFormDocument) => {
  document.file = file
  uploadDocument(document, true)
}

const onFileSelectedWithSign = (original: File, sign: File, document?: ApplicationFormDocument) => {
  if (!document) {
    return
  }
  document.file_sign = sign
  document.file = original
  uploadDocument(document, true)
}

const onUpdate = (
  value: FormElementValue,
  field: ApplicationFormField,
) => {
  updateMainFields([{
    value,
    code: field.code,
  }])
}

if (!item.value && !loadingForm.value) {
  load()
}

defineExpose({
  loading,
})
</script>

<style lang="scss">

</style>
