<template>
  <div v-if="$q.platform.is.mobile && !!allDraftBankIsOk">
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
  </div>
  <q-dialog
    v-if="$q.platform.is.mobile"
    :model-value="!!allDraftBankIsOk"
    position="bottom"
    seamless
  >
    <div
      class="row q-ma-sm bg-blue-grey-9 text-white q-pa-md"
      style="margin-bottom: 100px; border-radius: 10px;"
    >
      <div class="q-pr-sm col-sm-12 col-md q-mb-sm">
        Все данные введены, ограничений нет. Заявка может быть размещена
      </div>
      <q-btn
        class="full-width"
        align="left"
        color="green-14"
        size="14px"
        style="padding: 10px 16px;"
        unelevated
        no-caps
        @click="sendDialogShow"
      >
        <strong>Разместить заявку</strong>
        <span class="icon">
            <q-icon
              name="svguse:icons.svg?2#app-arrow-right-bold"
              color="green-14"
              size="18px"
              style="padding: 5.5px;"
            />
          </span>
      </q-btn>
    </div>
  </q-dialog>
</template>

<script setup lang="ts">
import {storeToRefs} from 'pinia'
import {useApplicationItemSendStore} from 'stores/Applications/Item/useApplicationItemSendStore'
import {useQuasar} from 'quasar'
import {ProductEnum} from 'src/models/Applications/ProductEnum'
import {useApplicationItemEbgFillStore} from 'stores/Applications/Item/useApplicationItemEbgFillStore'
import {useApplicationItemKikFillStore} from 'stores/Applications/Item/useApplicationItemKikFillStore'
import {computed} from 'vue'

const props = defineProps<{
  multiOrderId: number
  product: ProductEnum
}>()

const ebgFillStore = useApplicationItemEbgFillStore(props.multiOrderId)()
const {
  allDraftBankIsOk: ebgAllDraftBankIsOk,
} = storeToRefs(ebgFillStore)
const kikFillStore = useApplicationItemKikFillStore(props.multiOrderId)()
const {
  allDraftBankIsOk: kikAllDraftBankIsOk,
} = storeToRefs(kikFillStore)

const applicationItemSendStore = useApplicationItemSendStore(props.multiOrderId)()

const {
  sendDialogShow,
} = applicationItemSendStore

const allDraftBankIsOk = computed(() => {
  return props.product === ProductEnum.EBG
    ? ebgAllDraftBankIsOk.value
    : kikAllDraftBankIsOk.value
})

const $q = useQuasar()
</script>

<style scoped lang="scss">

</style>
