import {useGetApi} from 'src/uses/Api/useGetApi'
import {computed, ref, watch} from 'vue'
import {
  useApplicationManualGenerateDocumentsStart
} from 'src/uses/Applications/ManualGenerateDocuments/useApplicationManualGenerateDocumentsStart'
import {useApplicationControlCenterStore} from 'stores/Applications/ControlCenter/useApplicationControlCenterStore'
import {storeToRefs} from 'pinia'
import {
  useApplicationControlCenterItemStore
} from 'stores/Applications/ControlCenter/useApplicationControlCenterItemStore'
import {useProfileStore} from 'stores/Login/useProfileStore'

export const useApplicationManualGenerateDocuments = (id: string) => {
  const {
    get,
    response,
  } = useGetApi<{is_request_exists: boolean}>(true)

  const {isBankEmployee} = useProfileStore()

  const applicationControlCenterStore = useApplicationControlCenterStore(id)()
  const {
    init,
  } = applicationControlCenterStore
  const {
    headerData,
  } = storeToRefs(applicationControlCenterStore)

  const applicationControlCenterItemStore = useApplicationControlCenterItemStore(id)()
  const {
    load,
  } = applicationControlCenterItemStore

  const {
    generate,
  } = useApplicationManualGenerateDocumentsStart()

  const inDocumentsManualGeneratingProcess = ref(false)

  const headerGeneratedDocumentsIsRequestExists = computed(() => {
    if (!headerData.value) {
      return false
    }

    return headerData.value.generated_documents.is_request_exists
  })

  const manualGeneratingDocumentsAvailable = computed(() => {
    if (!headerData.value) {
      return false
    }

    return isBankEmployee()
      && headerData.value.product.manual_generation_enabled
  })

  const check = async () => {
    if (!headerData.value) {
      setTimeout(check, 100)
      return
    }

    await get(`v2/control-center/${headerData.value.id}/documents/generate/status`)

    if (response.value && response.value.is_request_exists === false) {
      inDocumentsManualGeneratingProcess.value = false
      refresh()

      return
    }

    setTimeout(check, 5000)
  }

  const manualGenerateDocuments = async () => {
    inDocumentsManualGeneratingProcess.value = true
    await generate(id)
    setTimeout(check, 1000)
  }

  const refresh = () => {
    init()
    load()
  }

  watch(
    headerGeneratedDocumentsIsRequestExists,
    () => {
      if (!headerGeneratedDocumentsIsRequestExists.value) {
        return
      }

      inDocumentsManualGeneratingProcess.value = true
      check()
    },
    {
      immediate: true,
    }
  )

  return {
    inDocumentsManualGeneratingProcess,
    headerGeneratedDocumentsIsRequestExists,
    manualGeneratingDocumentsAvailable,
    manualGenerateDocuments,
  }
}
