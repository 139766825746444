import {defineStore, storeToRefs} from 'pinia'
import {useGetApi} from 'src/uses/Api/useGetApi'
import {ApplicationsGridItem} from 'src/models/Applications/ApplicationsGridItem'
import {ApplicationsGridResponseData} from 'src/models/Applications/ApplicationsGridResponseData'
import {onBeforeMount, ref, watch} from 'vue'
import {useGridPagination} from 'src/uses/Grid/useGridPagination'
import {useProfileStore} from 'src/stores/Login/useProfileStore'
import {useBankFilterStore} from 'src/stores/Banks/useBankFilterStore'
import {UserRoleEnum} from 'src/models/User/UserRoleEnum'
import {useStatusStore} from 'src/stores/Status/useStatusStore'
import {applicationGridDefaultFilters} from 'src/components/Applications/ApplicationsGrid/ApplicationGridDefaultFilters'
import {CloneHelper} from 'src/helpers/CloneHelper'
import {useSendToCheck} from 'src/stores/Applications/useSendToCheck'
import {GridSorting} from 'src/models/Grid/Sorting'
import {useRoute} from 'vue-router'
import {useMetaStore} from 'src/stores/useMetaStore'
import {useQuasar} from 'quasar'
import {useApplicationsGridFiltersStore} from 'stores/Applications/Grid/useApplicationsGridFiltersStore'
import {useApplicationsGridSortingStore} from 'stores/Applications/Grid/useApplicationsGridSortStore'
import {useEmployeeFilterStore} from 'stores/Employees/useEmployeeFilterStore'

export const useApplicationsGridStore = defineStore('applications-grid', () => {
  const banksStore = useBankFilterStore()
  const {load: loadBanks} = banksStore
  const {banks} = storeToRefs(banksStore)

  const employeesStore = useEmployeeFilterStore()
  const {employees} = storeToRefs(employeesStore)
  const {load: loadEmployees} = employeesStore

  const profileStore = useProfileStore()
  const {profile} = storeToRefs(profileStore)

  const statusStore = useStatusStore()
  const {lastAffectedStatusPayload} = storeToRefs(statusStore)
  const sendToCheckStore = useSendToCheck()
  const {lastAffectedSendToCheckPayload} = storeToRefs(sendToCheckStore)

  const route = useRoute()

  const AGENT_ORDERS_LIST_URL = 'v2/orders/ebgs/grid'
  const ADMIN_ORDERS_LIST_URL = 'v2/orders/ebgs/grid'

  const {
    loading,
    get,
    errors,
    error,
    status,
    response,
  } = useGetApi<ApplicationsGridResponseData>()

  const {
    pagination,
    gridPagination,
    perPageOptions,
    getFromStorage,
    noMorePages,
    setPagination,
    onRequestPagination,
    setPage,
    setNextPage,
    setPerPage,
    setQueryToRouter: setPaginationQueryToRouter,
  } = useGridPagination('applications', route.name === 'applications-grid')

  const $q = useQuasar()
  const filtersStore = useApplicationsGridFiltersStore()
  const {filters} = storeToRefs(filtersStore)

  const sortingStore = useApplicationsGridSortingStore()

  const items = ref(<ApplicationsGridItem[]>[])

  const load = async () => {
    getFromStorage()
    await filtersStore.setQueryToRouter()
    await setPaginationQueryToRouter()
    await sortingStore.setQueryToRouter()

    const response = await realLoad(
      pagination.value?.page,
      pagination.value?.per_page,
      sortingStore.sorting,
      filtersStore.getFiltersQuery(),
    )

    if (!response) {
      return
    }

    if ($q.platform.is.mobile) {
      items.value = items.value.concat(response.items)
    } else {
      items.value = response.items
    }

    setPagination(response.pagination)
  }

  const realLoad = async (
    page: number,
    perPage: number,
    sorting: GridSorting,
    filters: Record<string, unknown>,
  ) => {
    if (!profile.value) {
      return
    }

    useMetaStore().setTitle('Сделки')

    const url = [
      UserRoleEnum.Admin,
      UserRoleEnum.JuniorAdmin,
      UserRoleEnum.MiddleAdmin,
    ].includes(profile.value.role)
      ? ADMIN_ORDERS_LIST_URL
      : AGENT_ORDERS_LIST_URL

    await get(
      url,
      {
        page,
        perpage: perPage,
        ...sorting,
        ...filters,
        'filter[new_request]': filters['filter[new_request]'] ? 1 : 0
      }
    )

    if (status.value === 200 && response && response.value?.items) {
      return response.value
    }

    return undefined
  }

  const updateItem = (item: ApplicationsGridItem) => {
    const index = items.value.findIndex(i => i.id === item.id)
    if (index === -1) {
      return
    }

    items.value[index] = {...item}
  }

  onBeforeMount(async () => {
    if (banks.value.length === 0) {
      await loadBanks()
    }
    if ((profileStore.isAgent() || profileStore.isHeadEmployee()) && employees.value.length === 0) {
      await loadEmployees()
    }
  })

  const refreshById = async (id: string) => {
    const itemIndex = items.value
      .findIndex(item => item.id === id)

    if (itemIndex === -1) {
      return
    }

    const response = await realLoad(
      1,
      1,
      sortingStore.sorting,
      {
        ...CloneHelper.clone(applicationGridDefaultFilters),
        'filter[number]': items.value[itemIndex].number.toString()
      }
    )

    if (!response) {
      return
    }

    const itemExists = items.value
      .findIndex(item => item.id === id)
      !== -1

    if (itemExists && response.items[0]) {
      items.value[itemIndex] = response.items[0]
    }
  }

  watch(
    filters,
    () => {
      if (!$q.platform.is.mobile) {
        return
      }

      items.value = []
    },
    {
      deep: true
    }
  )

  watch(
    lastAffectedSendToCheckPayload,
    () => {
      if (items.value.length === 0) {
        return
      }
      if (!lastAffectedSendToCheckPayload.value) {
        return
      }

      lastAffectedSendToCheckPayload.value.applicationIds
        .forEach(async (id) => {
          await refreshById(id)
        })
    }
  )

  watch(
    lastAffectedStatusPayload,
    async () => {
      if (items.value.length === 0) {
        return
      }
      if (!lastAffectedStatusPayload.value) {
        return
      }

      await refreshById(lastAffectedStatusPayload.value.id)
    },
  )

  watch(
    route,
    async () => {
      if (route.name === 'applications-grid' && Object.keys(route.query).length === 0) {
        await load()
      }
    }
  )

  return {
    loading,
    errors,
    error,
    items,
    pagination,
    gridPagination,
    perPageOptions,
    noMorePages,
    setPerPage,
    onRequestPagination,
    load,
    setPage,
    setNextPage,
    updateItem,
    refreshById,
  }
})
