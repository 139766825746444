<template>
  <filters-dialog
    :model-value="props.modelValue"
    @update:model-value="emit('update:model-value', $event)"
    @set-default-pins="onSetDefaultPins"
    @clear-pins="onClearPins"
    @update:modelValue="!$event ? emit('update:filters') : ''"
  >
    <application-kik-grid-filters
      :loading="loading"
      :only-pinned="false"
      :ignore-pins="props.ignorePins"
    />
  </filters-dialog>
</template>

<script setup lang="ts">
import {storeToRefs} from 'pinia'
import FiltersDialog from 'src/components/Grid/FiltersDialog.vue'
import {useApplicationsGridKikFiltersStore} from 'stores/Applications/Grid/useApplicationsGridKikFiltersStore'
import {
  applicationGridKikDefaultPins
} from 'components/Applications/ApplicationsGrid/Grid/ApplicationGridKikDefaultFilters'
import ApplicationKikGridFilters from 'components/Applications/ApplicationsGrid/ApplicationKikGridFilters.vue'

const props = defineProps<{
  loading: boolean
  modelValue: boolean
  ignorePins: boolean
}>()

const emit = defineEmits<{
  (e: 'update:filters'): void,
  (e: 'update:model-value', value: boolean): void,
}>()

const filtersStore = useApplicationsGridKikFiltersStore()
const {pins} = storeToRefs(filtersStore)

const onClearPins = () => {
  Object.keys(pins.value)
    .forEach(key => {
      pins.value[key] = false
    })
}

const onSetDefaultPins = () => {
  pins.value = JSON.parse(JSON.stringify(applicationGridKikDefaultPins))
}
</script>
