<template>
  <q-select
    :key="JSON.stringify(props.modelValue)"
    v-model="model"
    :class="classes"
    :options="props.options"
    :hint="props.hint"
    :disable="props.disable"
    :error="!!props.error"
    :error-message="props.error"
    :multiple="multiple"
    :loading="props.loading"
    :use-chips="useChips"
    label-slot
    outlined
    hide-bottom-space
    dense
    emit-value
    map-options
    options-dense
    @popup-hide="onUpdate"
  >
    <template #label>
      <slot name="labelPrepend" />
      {{ props.label }}
      <slot name="labelAppend" />
    </template>
    <template
      v-if="multiple"
      #option="{ itemProps, opt, selected, toggleOption }"
    >
      <q-item v-bind="itemProps">
        <q-item-section side>
          <q-checkbox
            :model-value="selected"
            @update:model-value="toggleOption(opt)"
          />
        </q-item-section>
        <q-item-section>
          <q-item-label v-html="opt.label" />
        </q-item-section>
      </q-item>
    </template>
    <template
      v-if="multiple && !props.topActions"
      #after-options
    >
      <div class="q-pa-sm">
        <q-btn
          class="q-mr-sm"
          label="Выбрать все"
          no-caps
          flat
          @click="selectAll"
        />
        <q-btn
          label="Очистить все"
          no-caps
          flat
          @click="clearAll"
        />
      </div>
    </template>
    <template
      v-if="multiple && props.topActions"
      #before-options
    >
      <div class="q-pa-sm">
        <q-btn
          class="q-mr-sm"
          label="Выбрать все"
          no-caps
          flat
          @click="selectAll"
        />
        <q-btn
          label="Очистить все"
          no-caps
          flat
          @click="clearAll"
        />
      </div>
    </template>
  </q-select>
</template>

<script setup lang="ts">
import {computed, ref, watchEffect, withDefaults} from 'vue'
import {FormElementValue} from 'src/models/Form'

const props = withDefaults(
  defineProps<{
    modelValue: FormElementValue
    disable?: boolean
    error?: string
    loading?: boolean
    label: string | boolean
    withTopLabel?: boolean
    options: {label: string, value: string | number}[]
    topActions?: boolean
    useChips?: boolean
  }>(),
  {
    hint: undefined,
    disable: false,
    error: undefined,
    loading: false,
    withTopLabel: false,
    topActions: false,
    useChips: false,
  }
)

const emit = defineEmits<{
  (e: 'update:model-value', value: FormElementValue): void,
}>()

const model = ref<FormElementValue>(null)

const classes = computed(() => {
  return {
    'field-with-top-label': props.withTopLabel,
    MultipleSelect: true,
    MultipleSelectWithChips: props.useChips,
  }
})

const multiple = computed(() => Array.isArray(model.value))

const selectAll = () => {
  if (!multiple.value) {
    return
  }

  model.value = props.options
    .map(o => o.value)
}

const clearAll = () => {
  if (!multiple.value) {
    return
  }

  model.value = []
}

const onUpdate = () => {
  emit('update:model-value', model.value)
}

watchEffect(() => {
  model.value = props.modelValue
})
</script>
