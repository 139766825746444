import {defineStore} from 'pinia'
import {ref} from 'vue'
import {Act} from 'src/models/Profile/Acts/Act'
import {usePutApi} from 'src/uses/Api/usePutApi'

export const useProfileActSendMessageStore = defineStore('act-send-message', () => {
  const {
    put,
    response,
    status,
    loading,
    error,
    errors,
  } = usePutApi<{text: string}, {text: string}>()

  const success = ref(false)
  const message = ref('')

  const sendMessage = async (act: Act) => {
    await put(`v1/acts/${act.id}/approval`, {text: message.value})
    if (status.value === 200 && response.value) {
      success.value = true
    }
  }

  return {
    loading,
    response,
    status,
    error,
    errors,
    success,
    message,
    sendMessage
  }
})
