import {defineStore, storeToRefs} from 'pinia'
import {computed, ref, watch} from 'vue'
import { useMeta } from 'quasar'
import {useIntegratorStore} from 'stores/useIntegratorStore'

export const useMetaStore = defineStore('meta-store', () => {
  const {data} = storeToRefs(useIntegratorStore())
  const name = computed(() => {
    if (!data.value) {
      return ''
    }

    return data.value.title_short
  })
  const title = ref(name.value)

  const reset = () => {
    setTitle(name.value)
  }

  const setTitle = (str: string) => {
    if (!str) {
      title.value = name.value
      return
    }

    title.value = `${str} - ${name.value}`
  }

  useMeta(() => {
    return {
      title: title.value
    }
  })

  watch(
    data,
    () => {
      setTitle('')
    },
    {
      deep: true,
    }
  )

  return {
    title,
    reset,
    setTitle,
  }
})
