<template>
  <div class="history-wrap column fit">
    <div
      v-if="history.length !== 0"
      ref="historyRef"
      class="history-block fit"
    >
      <div ref="heightRef">
        <div
          v-for="day in history"
          :key="day.day"
        >
          <div class="chat-messages-day text-center">
            <q-chip>{{ getFormatDate(day.day) }}</q-chip>
          </div>
          <div
            v-for="change in getChangesByDay(day)"
            :key="change.date"
            class="q-ma-md"
          >
            <div class="row change-info">
              <div class="col">
                <div
                  v-if="change.actor_type === 'GOSOBLAKO'"
                  class="gosoblako"
                >
                  {{ change.agent_name || change.actor_type }}
                </div>
                <div v-else>
                  <q-icon
                    name="svguse:icons.svg?2#app-user-circle-bold"
                    class="actor-icon"
                  />
                  <span>{{ change.agent_name || change.actor_type }}</span>
                </div>
              </div>
              <span class="history-type">
                <q-icon
                  :name="getChangeTypeIcon(change.action_type)"
                  color="green"
                  class="history-action-type-icon"
                />
                {{ change.action_type }}
              </span>
            </div>
            <q-chat-message
              :text="[change.action_title]"
              :stamp="getChangeTime(change)"
              size="12"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      v-else
      class="row fit items-center q-pa-md chat-room-no-messages"
      style="overflow-y: scroll"
    >
      <div class="q-pa-md chat-room-no-messages full-width">
        <q-img
          :src="'../../../../assets/chat_no_mess_cloud.svg'"
          class="no-chat-mess-cloud"
        />
        <div class="q-mt-lg">История пуста</div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {computed, onMounted, ref} from 'vue'
import {ApplicationHistory} from 'src/models/Applications/ControlCenter/ApplicationHistory'
import {useHistory} from 'src/uses/History/useHistory'

const props = defineProps<{
  history: ApplicationHistory[]
}>()

const history = computed(() => props.history)

const historyRef = ref<HTMLDivElement>()
const heightRef = ref<HTMLDivElement>()

onMounted(() => {
  if (!historyRef.value || !heightRef.value) {
    return
  }

  historyRef.value.scrollTop = heightRef.value?.clientHeight || 0
  /*historyRef.value.scrollTo({
    top: heightRef.value?.clientHeight || 0,
    left: 0,
    behavior: 'smooth',
  })*/
})

const {
  getChangesByDay,
  getFormatDate,
  getChangeTime,
  getChangeTypeIcon,
} = useHistory()

</script>
<style lang="scss">
.history-wrap {
  background-color: #FFFFFF;
  border-bottom-left-radius: 8px!important;
  border-bottom-right-radius: 8px!important;
  padding-bottom: 20px;
}
.history-block {
  background-color: #ffffff;
  padding: 10px 10px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  min-height: 300px;
  max-height: 100%;
  overflow-y: scroll;
  @media only screen and (pointer: coarse) {
    max-height: calc(85vh - 159px);
  }

  .chat-messages-day {
    .q-chip__content {
      font-weight: 400;
      font-size: 12px;
      line-height: 12px;
    }
  }
  .q-message-text {
    padding: 15px;
    color: #424242;
  }

  .change-info {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 17px;
    color: #757575;
    margin-bottom: 2px;
    .actor-icon {
      margin-bottom: 1px;
      height: 14px;
      width: 15px;
      margin-right: 2px;
    }
    .gosoblako {
      color: #1E88E5;
      &:before {
        display: inline-flex;
        content: '';
        background-image: url('../../../../assets/chat_gosoblako_icon.svg');
        background-size: 14px 14px;
        height: 12px;
        width: 15px;
        margin-right: 3px;
      }
    }
  }

  .history-type {
    color: #00C853;
    .history-action-type-icon {
      height: 13px;
      width: 13px;
      margin-bottom: 1px;
    }
  }

  .q-message-received {
    .q-message-name--received {
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 17px;
      color: #757575;
      margin-bottom: 2px;
    }

    .q-message-text--received {
      background: #E3F2FD;
      border-radius: 14px 14px 14px 0;
    }
    .q-message-text:last-child:before {
      content: none;
    }
    .q-message-stamp {
      color: #64B5F6;
    }
  }
}
.no-active-chat {
  .no-active-chat-banner-info {
    background: #263238;
    color: #ffffff;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    margin: 0 -14px 0 -14px;
    padding: 10px 20px;
    border-radius: 5px;
    .icon-no-active-chat-banner-info-info {
      background: #37474F;
      border-radius: 6px;
      padding: 14px;
    }
    .icon-no-active-chat-info{
      margin-top: 5px;
    }
  }
}

.chat-room-no-messages {
  max-height: 450px;
  @media only screen and (pointer: coarse) {
    max-height: calc(85vh - 159px);
  }
  background: #FFFFFF;
  text-align: center;

  .no-chat-mess-cloud {
    max-width: 150px;
  }
}
</style>
