<template>
  <page-block-wrapper>
    <div class="help">
      <help-page
        v-if="helpPageId"
        :page-id="helpPageId"
      />
    </div>
  </page-block-wrapper>
</template>
<script setup lang="ts">
import {useRoute, useRouter} from 'vue-router'
import HelpPage from 'src/components/Help/HelpPage.vue'
import {computed, onBeforeMount} from 'vue'
import PageBlockWrapper from 'src/components/PageBlockWrapper.vue'
import {useHelpStore} from 'src/stores/Help/useHelpStore'
import {storeToRefs} from 'pinia'
import {useMetaStore} from 'src/stores/useMetaStore'

const route = useRoute()
const helpPageId = computed(() => route.params.helpPageId as never as string)

const helpStore = useHelpStore()

const {load} = helpStore

const router = useRouter()

const {
  helpBlocks,
} = storeToRefs(helpStore)

const redirectToFirst = async () => {
  if (helpPageId.value || helpBlocks.value.length === 0) {
    return
  }

  const helpBlock = helpBlocks.value[0]
  if (!helpBlock.pages || helpBlock.pages.length === 0) {
    return
  }

  await router.replace({
    name: 'help-page',
    params: {
      helpPageId: helpBlock.pages[0].id,
    },
  })
  useMetaStore().setTitle('Помощь')
}

onBeforeMount(async () => {
  useMetaStore().setTitle('Помощь')
  await load()
  await redirectToFirst()
})
</script>
