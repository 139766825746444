<template>
  <div class="row no-wrap items-center">
    <div :class="{col: props.mobile}">
      <a
        v-if="!props.onlyText && isRntLink(item.rnt, item.rnt_link)"
        target="_blank"
        :href="getRntLink(item.rnt, item.rnt_link)"
      >
        {{ item.rnt }}
      </a>
      <template v-else>
        {{ item.rnt }}
      </template>
    </div>
    <div
      v-if="item.rnt && !props.onlyText"
      style="position: relative; min-width: 35px"
    >
      <copy-to-clipboard
        style="top: -15px; position: absolute;"
        :text="item.rnt"
      >
        <q-btn
          class="q-ml-xs"
          icon="svguse:icons.svg?2#app-document-duplicate"
          :size="!props.mobile ? '10px' : '14px'"
          color="blue-grey-4"
          round
          unelevated
          flat
        >
          <q-tooltip
            anchor="top middle"
            self="bottom middle"
          >Копировать</q-tooltip>
        </q-btn>
      </copy-to-clipboard>
    </div>
  </div>
</template>

<script setup lang="ts">
import {computed, withDefaults} from 'vue'
import {ApplicationsGridItem} from 'src/models/Applications/ApplicationsGridItem'
import {useRnt} from 'src/uses/Applications/useRnt'
import CopyToClipboard from 'src/components/CopyToClipboard.vue'

const {
  isRntLink,
  getRntLink,
} = useRnt()

const props = withDefaults(
  defineProps<{
    item: ApplicationsGridItem
    mobile?: boolean
    onlyText?: boolean
  }>(),
  {
    mobile: false,
    onlyText: false,
  }
)
const item = computed(() => props.item)
</script>
