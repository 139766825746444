<template>
  <template v-if="$q.platform.is.mobile">
    <info-banner
      text="Для работы с реквизитами и документами зайдите в кабинет используя персональный компьютер"
    />
    <div class="ProfileRequisites q-ma-none">
      <div class="RequisitesStepperWrapper">
        <div v-if="profile">
          <profile-requisites-physical
            v-if="profile.opf === OpfEnum.PHYSICAL"
            @update:requisites="goToDocuments()"
          />
          <profile-requisites-individual
            v-if="profile.opf === OpfEnum.INDIVIDUAL"
            @update:requisites="goToDocuments()"
          />
          <profile-requisites-company
            v-if="profile.opf === OpfEnum.COMPANY"
            @update:requisites="goToDocuments()"
          />
        </div>
      </div>
    </div>
  </template>
  <template v-else>
    <bread-crumbs
      parent-label="Профиль"
      current-label="Реквизиты"
      :parent-to="{name: 'profile-requisites'}"
    />
    <info-banner
      :text="getMessage(profile?.agent_offer_status || null)"
    />
    <div class="ProfileRequisites">
      <div class="RequisitesStepperWrapper">
        <q-stepper
          v-model="step"
          class="RequisitesStepper"
          header-nav
          flat
          color="primary"
          animated
        >
          <q-step
            :name="1"
            title="Заполнение реквизитов"
            icon="svguse:icons.svg?2#app-document-text"
            :done="requisites"
          >
            <q-separator />
            <div class="q-pa-lg">
              <div v-if="profile">
                <div
                  v-if="showAlertMessage(profile.agent_status)"
                  class="col-8"
                >
                  <div class="alert-msg">
                    Необходимо заполнить реквизиты и подписать документы для подключения к сервису
                  </div>
                </div>
              </div>

              <div v-if="profile">
                <profile-requisites-physical
                  v-if="profile.opf === OpfEnum.PHYSICAL"
                  @update:requisites="goToDocuments()"
                />
                <profile-requisites-individual
                  v-if="profile.opf === OpfEnum.INDIVIDUAL"
                  @update:requisites="goToDocuments()"
                />
                <profile-requisites-company
                  v-if="profile.opf === OpfEnum.COMPANY"
                  @update:requisites="goToDocuments()"
                />
              </div>
            </div>
          </q-step>

          <q-step
            :name="2"
            title="Подписание документов"
            icon="svguse:icons.svg?2#app-pencil"
            :done="documents"
          >
            <div class="DocumentsWrapper">
              <div class="title">Документы</div>
              <profile-documents-grid />
            </div>
          </q-step>
        </q-stepper>
      </div>
    </div>
  </template>
</template>

<script setup lang="ts">

import {useAuthStore} from 'src/stores/Login/useAuthStore'
import {storeToRefs} from 'pinia'
import {onBeforeMount, ref} from 'vue'
import {useProfileRequisites} from 'src/uses/Profile/Requisites/useProfileRequisites'
import ProfileRequisitesPhysical from 'src/components/Profile/Requisites/ProfileRequisitesPhysical.vue'
import ProfileRequisitesIndividual from 'src/components/Profile/Requisites/ProfileRequisitesIndividual.vue'
import ProfileRequisitesCompany from 'src/components/Profile/Requisites/ProfileRequisitesCompany.vue'
import {OpfEnum} from 'src/models/OpfEnum'
import InfoBanner from 'src/components/InfoBanner.vue'
import BreadCrumbs from 'src/components/BreadCrumbs.vue'
import ProfileDocumentsGrid from 'src/components/Profile/Documents/ProfileDocumentsGrid.vue'
import {useQuasar} from 'quasar'
import {useInfoBannerMessage} from 'src/uses/Profile/Requisites/useInfoBannerMessage'

const profileRequisites = useProfileRequisites()
const {showAlertMessage} = profileRequisites

const {getMessage} = useInfoBannerMessage()

const $q = useQuasar()

const authStore = useAuthStore()
const {profile} = storeToRefs(authStore)
const step = ref()
const requisites = ref()
const documents = ref()

const goToDocuments = () => {
  documents.value = true
  step.value = 2
}

onBeforeMount(() => {
  step.value = 1
  requisites.value = true
})
</script>

<style lang="scss">
.ProfileRequisites {
  margin: 0 -24px -24px;
  background: $blue-1;
  padding: 0 24px 24px;

  &.q-ma-none {
    margin: 0 0 -12px 0;
    padding: 24px 32px;
  }
}
.RequisitesStepper {
  background: $blue-1;
  .q-stepper__tab.col-grow {
    //flex: 0 0 ;
  }
  .q-stepper__step-inner {
    padding: 0;
  }
  .q-stepper__header {
    max-width: 600px;
  }
}
.RequisitesStepperWrapper {
  margin: 0 -24px;

  .title {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    padding-bottom: 30px;
    padding-top: 10px;
  }
}
.DocumentsWrapper {
  padding: 0 24px 24px;
}
</style>
