<template>
  <q-btn
    v-if="ActStatuses.isFormedStatus(item.status)"
    class="GridActions"
    color="blue-7"
    size="12px"
    icon="svguse:icons.svg?2#app-pencil"
    padding="6px"
    flat
    unelevated
    rounded
    square
    @click="show"
  />
  <profile-act-chat-modal
    :is-chat="false"
    :item="item"
  />
  <q-btn-dropdown
    class="GridActions q-ml-sm"
    color="blue-grey-4"
    size="12px"
    icon="svguse:icons.svg?2#app-download"
    dropdown-icon="none"
    padding="6px"
    flat
    unelevated
    rounded
    square
  >
    <q-list class="GridActions--list">
      <q-item
        v-ripple
        class="text-no-wrap"
        clickable
      >
        <q-item-section>
          <q-item-label @click="download(downloadFileUrl)">
            Без ЭЦП
          </q-item-label>
        </q-item-section>
      </q-item>
      <q-item
        v-if="!(ActStatuses.isFormedStatus(item.status) || ActStatuses.isApprovalStatus(item.status))"
        v-ripple
        class="text-no-wrap"
        clickable
      >
        <q-item-section>
          <q-item-label @click="download(downloadSignFileUrl)">
            С ЭЦП
          </q-item-label>
        </q-item-section>
      </q-item>
    </q-list>
  </q-btn-dropdown>

  <profile-act-chat-modal
    is-chat
    :item="item"
  />

  <profile-act-sign-modal
    v-model="dialog"
    :item="item"
  />
</template>

<script setup lang="ts">
import {computed} from 'vue'
import {Act} from 'src/models/Profile/Acts/Act'
import ProfileActChatModal from 'src/components/Profile/Acts/ProfileActChatModal.vue'
import ProfileActSignModal from 'src/components/Profile/Acts/ProfileActSignModal.vue'
import {useDialog} from 'src/uses/Dialogs/useDialog'
import {useFileDownload} from 'src/uses/File/useFileDownload'
import {ActStatuses} from 'src/models/Profile/Acts/ActStatusDescriptions'

const props = defineProps<{
  item: Act
}>()
const item = computed(() => props.item)
const downloadFileUrl = `v1/acts/${item.value.id}/file`
const downloadSignFileUrl = `v1/acts/${item.value.id}/sign`
const {
  download
} = useFileDownload()

const {
  dialog,
  show,
} = useDialog()
</script>
