import {useOptionsFromObject} from 'src/uses/useOptionsFromObject'
import {ProductEnum} from 'src/models/Applications/ProductEnum'

const products: Record<ProductEnum, string> = {
  [ProductEnum.EBG]: 'ЭБГ',
  [ProductEnum.KIK]: 'Кредит',
  [ProductEnum.RKO]: 'РКО',
}

export const useProduct = () => {
  const {getOptionsFromObject} = useOptionsFromObject()
  const options = getOptionsFromObject(products)
  const getProductLabel = (product: ProductEnum): string => {
    return products[product] || ''
  }

  return {
    getProductLabel,
    options,
  }
}
