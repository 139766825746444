<template>
  <help-bread-crumbs
    :help-blocks="helpBlocks"
    :help-page-id="helpPageId"
  />
  <q-separator style="margin: 0 -24px"/>
  <div
    ref="content"
    class="q-ma-sm help-page"
  >
    <div v-html="getPageBodyById()"/>
  </div>
</template>

<script setup lang="ts">
import {storeToRefs} from 'pinia'
import {computed, nextTick, onMounted, ref, watch} from 'vue'
import {useHelpStore} from 'src/stores/Help/useHelpStore'
import {HelpBlock} from 'src/models/Help/HelpBlock'
import HelpBreadCrumbs from 'src/components/Help/HelpBreadCrumbs.vue'

const props = defineProps<{
  pageId: string,
}>()

const helpPageId = computed(() => props.pageId)

const helpStore = useHelpStore()
const {helpBlocks} = storeToRefs(helpStore)

const content = ref<HTMLDivElement>()

const getPageBodyById = () => {
  for (const block of helpBlocks.value as HelpBlock[]) {
    if (!block.pages) {
      continue
    }
    const page = block.pages
      .find((page) => page.id === helpPageId.value)
    if (!page) {
      continue
    }

    return page.body
  }

  return null
}

const addImgClick = () => {
  nextTick(() => {
    if (!content.value) {
      return
    }

    (content.value as HTMLDivElement)
      .querySelectorAll('img')
      .forEach(img => img.addEventListener(
        'click',
        (event) => {
          const src = (event.target as HTMLImageElement).src
          if (!src) {
            return
          }

          document.location.href = src
        }
      ))
  })
}

onMounted(addImgClick)

watch(
  helpBlocks,
  addImgClick,
  {
    deep: true,
    immediate: true,
  }
)
</script>

<style lang="scss">
.help-page {
  overflow: hidden;

  h3 {
    color: #424242;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
  }

  img {
    cursor: pointer;
    max-width: 100%;
    height: auto !important;
  }
}
</style>
