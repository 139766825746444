<template>
  <div
    class="q-mb-md"
    style="margin-left: -16px; margin-right: -16px; margin-top: -8px;"
  >
    <application-grid-toggle :loading="loading" />
  </div>
  <div style="margin-left: -8px; margin-right: -8px;">
    <application-grid-product-toggle :loading="loading" />
  </div>
  <application-grid-filters-dialog
    v-model="filerDialog"
    :loading="loading"
    :ignore-pins="ignorePins"
    @update:filters="updateFilters"
  />
  <div class="q-px-none">
    <div class="ApplicationMultiGrid--mobile">
      <div class="row q-pb-lg q-pt-sm">
        <q-btn
          label="Все фильтры"
          color="blue-grey-0"
          class="text-blue-7 col"
          icon-right="svguse:icons.svg?2#app-adjustments"
          padding="10px"
          unelevated
          no-caps
          @click="filterDialogShow"
        />
        <q-btn
          v-if="clearable"
          color="blue-grey-0"
          class="text-blue-7 q-ml-xs"
          icon-right="svguse:icons.svg?2#app-trash"
          size="14px"
          padding="10px"
          unelevated
          no-caps
          @click="onClear"
        />
      </div>
      <application-grid-statuses
        class="q-mb-sm"
        @update:filters="updateFilters"
      />
      <q-infinite-scroll
        ref="scroll"
        style="margin-bottom: 12px"
        :offset="50"
        @load="onLoad"
      >
        <div
          v-for="item in items as ApplicationsMultiGridItem[]"
          :key="item.id"
        >
          <div>
            <div :class="{'ApplicationMultiGrid--mobile--item rounded-borders q-mt-sm': true, 'ApplicationMultiGrid--mobile--item__expanded': isExpanded(item.id)}">
              <div class="row no-wrap justify-between ApplicationMultiGrid--mobile--expand">
                <div class="row items-center q-pa-sm">
                  <q-icon
                    class="q-mr-md"
                    name="svguse:icons.svg?2#app-exclamation-circle"
                    size="18px"
                    color="orange"
                  />
                  <template v-if="isExpanded(item.id)">
                    Нажмите, чтобы свернуть заявку
                  </template>
                  <template v-else>
                    Нажмите, чтобы раскрыть заявку
                  </template>
                  <q-icon
                    class="q-ml-xs"
                    name="svguse:icons.svg?2#app-arrow-narrow-right"
                    size="sm"
                    color="white"
                  />
                </div>
                <div class="q-pr-xs ApplicationMultiGrid--mobile--expand--toggle">
                  <q-icon
                    class="q-mr-sm q-mt-sm"
                    :name="isExpanded(item.id) ? 'svguse:icons.svg?2#app-chevron-double-up': 'svguse:icons.svg?2#app-chevron-double-down'"
                    size="sm"
                    :color="isExpanded(item.id) ? 'blue-grey-4': 'primary'"
                    @click="toggleExpand(item.id)"
                  />
                </div>
              </div>
              <div class="ApplicationMultiGrid--mobile--item--inner q-pa-md">
                <div class="row">
                  <div>
                    <div class="text-grey-6">Дата, №</div>
                    <application-multi-grid-number-mobile :item="item" />
                  </div>
                </div>

                <div class="text-grey-6 q-mt-sm">Название/ИНН</div>
                <application-grid-inn
                  :item="item as never as ApplicationsGridItem"
                  mobile
                />

                <div class="text-grey-6 q-mt-sm">Сумма гарантии</div>
                <money-component :model-value="item.guarantee_total" />

                <div class="text-grey-6 q-mt-sm">Закупка</div>
                <application-grid-rnt
                  :item="item as never as ApplicationsGridItem"
                  mobile
                />

                <div class="text-grey-6 q-mt-sm">Закупка</div>
                <application-multi-grid-status :item="item" />
              </div>
              <div
                v-show="isExpanded(item.id)"
                class="bg-grey-1 q-pa-md"
                style="box-shadow: inset 0 7px 12px -7px rgba(0,0,0,0.2);"
              >
                <div
                  v-for="order in item.orders"
                  :key="order.id"
                  class="ApplicationMultiGrid--mobile--item--inner--order"
                >
                  <div class="row">
                    <div>
                      <div class="text-grey-6">Дата, №</div>
                      <application-grid-number-mobile :item="order"/>
                    </div>
                    <div class="col"></div>
                    <div class="text-grey-6 q-mt-sm">
                      <div class="row no-wrap items-center">
                        <div v-if="getColumnByName('docs') && isVisibleByRole(getColumnByName('docs'))">
                          <application-grid-docs :item="order"/>
                        </div>
                        <div class="text-right col">
                          <application-grid-actions
                            :item="order"
                            @change="refreshById(item.id as never)"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row no-wrap q-mt-sm">
                    <div>
                      <div class="text-grey-6">Банк</div>
                      <div>
                        <img
                          :src="order.bank.logo"
                          :alt="order.bank.title_short"
                          style="max-width: 60px; max-height: 50px;"
                        >
                      </div>
                    </div>
                    <div class="col q-pl-sm">
                      <div class="text-grey-6">Статус</div>
                      <div class="row no-wrap">
                        <div class="q-pr-sm col">
                          <application-grid-status :item="order"/>
                        </div>
                        <application-grid-messages-action
                          :item="order"
                          mobile
                          @model-closed="refreshById(item.id as never)"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="text-grey-6 q-mt-sm">Срок действия</div>
                  <application-grid-period
                    :item="order"
                    mobile
                  />

                  <div class="text-grey-6 q-mt-sm">Сумма гарантии</div>
                  <application-grid-sum
                    :item="order"
                    mobile
                    @tariff-refreshed="refreshById(item.id as never)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <template #loading>
          <div class="text-center">
            <q-circular-progress
              class="q-mt-sm"
              color="primary"
              size="md"
              indeterminate
            />
          </div>
        </template>
      </q-infinite-scroll>
    </div>
  </div>
</template>

<script setup lang="ts">
import ApplicationGridFiltersDialog from 'components/Applications/ApplicationsGrid/ApplicationGridFiltersDialog.vue'
import {onBeforeMount, ref, watch, watchEffect} from 'vue'
import {useApplicationsGrid} from 'components/Applications/ApplicationsGrid/Grid/useApplicationsGrid'
import {useLocalStorage} from 'src/uses/useLocalStorage'
import ApplicationGridStatuses from 'components/Applications/ApplicationsGrid/ApplicationGridStatuses.vue'
import ApplicationGridToggle from 'components/Applications/ApplicationsGrid/Grid/ApplicationGridToggle.vue'
import {ApplicationsMultiGridItem} from 'src/models/Applications/ApplicationsMultiGridItem'
import ApplicationMultiGridNumberMobile
  from 'components/Applications/ApplicationsGrid/Columns/ApplicationMultiGridNumberMobile.vue'
import ApplicationGridInn from 'components/Applications/ApplicationsGrid/Columns/ApplicationGridInn.vue'
import ApplicationGridSum from 'components/Applications/ApplicationsGrid/Columns/ApplicationGridSum.vue'
import MoneyComponent from 'components/MoneyComponent.vue'
import ApplicationGridRnt from 'components/Applications/ApplicationsGrid/Columns/ApplicationGridRnt.vue'
import ApplicationMultiGridStatus from 'components/Applications/ApplicationsGrid/Columns/ApplicationMultiGridStatus.vue'
import ApplicationGridStatus from 'components/Applications/ApplicationsGrid/Columns/ApplicationGridStatus.vue'
import ApplicationGridMessagesAction
  from 'components/Applications/ApplicationsGrid/Actions/ApplicationGridMessagesAction.vue'
import ApplicationGridPeriod from 'components/Applications/ApplicationsGrid/Columns/ApplicationGridPeriod.vue'
import ApplicationGridDocs from 'components/Applications/ApplicationsGrid/Columns/ApplicationGridDocs.vue'
import ApplicationGridNumberMobile
  from 'components/Applications/ApplicationsGrid/Columns/ApplicationGridNumberMobile.vue'
import ApplicationGridActions from 'components/Applications/ApplicationsGrid/Actions/ApplicationGridActions.vue'
import {ApplicationsGridColumns} from 'components/Applications/ApplicationsGrid/ApplicationsGridColumns'
import {useGridSettings} from 'src/uses/Grid/useGridSettings'
import {QInfiniteScroll} from 'quasar'
import ApplicationGridProductToggle
  from 'components/Applications/ApplicationsGrid/Grid/ApplicationGridProductToggle.vue'
import {ApplicationsGridItem} from 'src/models/Applications/ApplicationsGridItem'

const {
  clearable,

  items,
  loading,

  noMorePages,
  setNextPage,

  load,
  refreshById,

  ignorePins,
  filerDialog,
  filterDialogShow,

  updateFilters,
  onClear,
} = useApplicationsGrid(true)

const columns = ApplicationsGridColumns()
const {isVisibleByRole} = useGridSettings('application-grid', columns)

const getColumnByName = (name: string) => columns.find(column => column.name === name)

const {
  data,
  setToStorage,
  getFromStorage,
} = useLocalStorage<number[]>('multi-grid-expand')

const expanded = ref<number[]>([])

const scroll = ref<QInfiniteScroll>()

const isExpanded = (id: number) => expanded.value.includes(id)

const toggleExpand = (id: number) => {
  if (isExpanded(id)) {
    expanded.value = expanded.value
      .filter(expandedId => id !== expandedId)
  } else {
    expanded.value.push(id)
  }

  setToStorage(expanded.value)
}

const onLoad = async (index: number, done: (stop?: boolean) => void) => {
  if (loading.value) {
    return
  }

  setNextPage()
  await load()
  done()
}

onBeforeMount(async () => {
  getFromStorage()
  //await load()
})

watchEffect(() => {
  expanded.value = data.value || []
})

watch(
  noMorePages,
  () => {
    if (!scroll.value) {
      return
    }

    if (noMorePages.value) {
      scroll.value.stop()

      return
    }

    scroll.value.resume()
  }
)
</script>

<style lang="scss" src="./ApplicationsMultiGridMobile.scss" />
