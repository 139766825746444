import {useProfileStore} from 'src/stores/Login/useProfileStore'
import {useDialog} from 'src/uses/Dialogs/useDialog'
import {storeToRefs} from 'pinia'
import {useGridSettings} from 'src/uses/Grid/useGridSettings'
import {computed, onBeforeUnmount, ref} from 'vue'
import {useElementSize} from '@vueuse/core'
import {GridPagination, RequestProp} from 'src/models/Grid/Pagination'
import {useLayoutStore} from 'stores/useLayoutStore'
import {useApplicationsGridKikFiltersStore} from 'stores/Applications/Grid/useApplicationsGridKikFiltersStore'
import {useApplicationsGridKikSortingStore} from 'stores/Applications/Grid/useApplicationsGridKikSortStore'
import {useApplicationsGridKikStore} from 'stores/Applications/Grid/useApplicationsGridKikStore'
import {ApplicationsGridKikColumns} from 'components/Applications/ApplicationsGrid/ApplicationsGridKikColumns'
import {usePosthogStore} from 'stores/Posthog/usePosthogStore'

export const useApplicationsKikGrid = () => {
  const {
    profile,
    isBankEmployee,
  } = useProfileStore()

  const {
    dialog: dialogGridColumnsSettings,
    show: showGridColumnsSettingsDialog
  } = useDialog()
  const {sendEvent} = usePosthogStore()

  const showGridColumnsSettings = () => {
    sendEvent('Edit_colomns_order_via_setting_icon')
    showGridColumnsSettingsDialog()
  }

  const filtersStore = useApplicationsGridKikFiltersStore()
  const {
    isDefaultFilterValue,
  } = storeToRefs(filtersStore)

  const {clear} = filtersStore
  const {hasPins, clearable} = storeToRefs(filtersStore)

  const {currentWidth} = storeToRefs(useLayoutStore())

  const ignorePins = computed(() => {
    return currentWidth.value <= 700
  })

  const sortingStore = useApplicationsGridKikSortingStore()

  const applicationsGridStore = useApplicationsGridKikStore()
  const {
    items,
    gridPagination,
    perPageOptions,
    loading,
    noMorePages,
  } = storeToRefs(applicationsGridStore)

  const {
    load,
    refreshById,
    onRequestPagination,
    setPage,
    setNextPage,
    setPerPage,
  } = applicationsGridStore

  const {
    columns,
    visibleColumns
  } = useGridSettings(
    'application-kik-grid',
    ApplicationsGridKikColumns()
  )

  const {
    dialog: filerDialog,
    show: filterDialogShow
  } = useDialog()

  const controlRef = ref<HTMLDivElement>()
  const { height: controlHeight } = useElementSize(controlRef)

  const tableHeightStyle = computed(() => {
    if (!controlHeight.value) {
      return ''
    }

    return `max-height: calc(100vh - ${(controlHeight.value || 0) + 100}px)`
  })

  const filteredVisibleColumns = computed(() => {
    if (!isBankEmployee() || !visibleColumns.value) {
      return visibleColumns.value
    }

    return visibleColumns.value
      .filter(column => column.name !== 'users' || !profile?.is_hide_personal_data)
  })

  const onRequest = ({pagination}: RequestProp) => {
    onRequestPagination(pagination as GridPagination)
    load()
  }

  const updateFilters = async () => {
    setPage(1)
    await load()
  }

  const updateSorting = async () => {
    await load()
  }

  const onClear = async () => {
    await clear()
    await updateFilters()
  }

  onBeforeUnmount(() => {
    applicationsGridStore.$reset()
    applicationsGridStore.$dispose()
    filtersStore.$reset()
    filtersStore.$dispose()
    sortingStore.$reset()
    sortingStore.$dispose()
  })

  return {
    profile,
    dialogGridColumnsSettings,
    isBankEmployee,
    showGridColumnsSettings,

    isDefaultFilterValue,
    clear,
    hasPins,
    ignorePins,
    clearable,

    items,
    gridPagination,
    perPageOptions,
    loading,

    noMorePages,
    load,
    refreshById,
    onRequestPagination,
    setPage,
    setNextPage,
    setPerPage,

    columns,
    visibleColumns,

    filerDialog,
    filterDialogShow,

    controlHeight,
    controlRef,
    tableHeightStyle,
    filteredVisibleColumns,

    onRequest,
    updateFilters,
    updateSorting,
    onClear,
  }
}
