import {ApplicationFormFinanceField} from 'src/models/Applications/Form/ApplicationFormResponseData'

export const useFinanceFieldsFilter = () => {
  const filterFieldsByBlock = (fields: ApplicationFormFinanceField[], block: string) => {
    return fields.filter(f => f.code.startsWith(block) && !f.code.endsWith('00'))
  }

  const getFieldByCode = (fields: ApplicationFormFinanceField[], code: string) => {
    return fields.find(f => f.code === code)
  }

  return {
    filterFieldsByBlock,
    getFieldByCode,
  }
}
