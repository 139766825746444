<template>
  <div style="padding-top: 80px;">
    <div
      v-if="!error && !success"
      class="q-gutter-lg"
    >
      <div>Подождите</div>
      <div class="q-pa-lg bg-blue-1 text-blue-7">
        Выполняется активация аккаунта...
      </div>
    </div>
    <div
      v-if="error"
    >
      <orange-info text="Ссылка на активацию аккаунта устарела или уже использовалась" />
      <div class="q-mt-lg">
        Для входа в аккаунт перейдите по <router-link :to="{name: 'login'}">ссылке</router-link>.
      </div>
    </div>
    <div
      v-if="success"
      class="q-gutter-lg"
    >
      <div>Аккаунт успешно активирован.</div>
      <div>Для входа в аккаунт перейдите по <router-link :to="{name: 'login'}">ссылке</router-link>.</div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {onBeforeUnmount, onMounted} from 'vue'
import {useSignupConfirmStore} from 'src/stores/Login/useSignupConfirmStore'
import {storeToRefs} from 'pinia'
import OrangeInfo from 'src/components/OrangeInfo.vue'

const signupConfirmStore = useSignupConfirmStore()

const {doSignupConfirm} = signupConfirmStore
const {
  error,
  success,
} = storeToRefs(signupConfirmStore)

onMounted(() => {
  doSignupConfirm()
})

onBeforeUnmount(() => {
  signupConfirmStore.$reset()
  signupConfirmStore.$dispose()
})
</script>
