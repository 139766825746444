<template>
  <div class="HomeMobile">
    <div class="HomeMobile--blur" />
    <div class="column relative-position fit">
      <div class="col self-center content-center">
        <router-link :to="{name: 'login'}">
          <img
            src="../assets/logo-login.svg"
            alt=""
          >
        </router-link>
      </div>
      <div class="HomeMobile--footer">
        <q-btn
          label="Вход"
          color="primary"
          class="full-width"
          style="height: 70px;"
          size="20px"
          unelevated
          no-caps
          :to="{name: 'login'}"
        />
        <template v-if="!$q.platform.is.nativeMobileWrapper">
          <q-btn
            label="Регистрация"
            class="full-width text-primary bg-white"
            style="height: 70px;"
            size="20px"
            unelevated
            no-caps
            :to="{name: 'registration'}"
          />
        </template>
        <div class="row items-center justify-around q-mt-lg">
          <q-space />
          <div class="q-px-md q-py-xs icon">
            <q-icon
              name="svguse:icons.svg?1#app-support-head"
              color="blue-grey-4"
              size="24px"
            />
          </div>
          <div class="text-no-wrap q-px-md text-white">
            <a href="tel:+74991101642">+7 (499) 110 16 42</a>
            <div class="text-grey-6">
              <a href="mailto:support@gosoblako.com">support@gosoblako.com</a>
            </div>
          </div>
          <q-space />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {useQuasar} from 'quasar'

const $q = useQuasar()
</script>

<style lang="scss">
.HomeMobile {
  background: url(../assets/login-mobile-bg.png);
  background-size: cover;
  position: relative;
  width: 100vw;
  height: 100vh;

  .HomeMobile--blur {
    background: #FFFFFF05;
    backdrop-filter: blur(10px);
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .HomeMobile--footer {
    padding: 20px;

    a {
      color: white;
      text-decoration: none;
    }

    & > * {
      margin-bottom: 20px;
    }
  }
}
</style>
