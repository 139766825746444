<template>
  <page-block-wrapper>
    <div class="listings">
      <listings-grid />
    </div>
  </page-block-wrapper>
</template>
<script setup lang="ts">
import PageBlockWrapper from 'src/components/PageBlockWrapper.vue'
import {useMetaStore} from 'src/stores/useMetaStore'
import ListingsGrid from 'components/Listings/ListingsGrid.vue'

useMetaStore().setTitle('Справочники')
</script>
