import {defineStore} from 'pinia'
import {useGridFilters} from 'src/uses/Grid/useGridFilters'
import {useGridFiltersPins} from 'src/uses/Grid/useGridFiltersPins'
import {
  applicationGridKikDefaultFilters,
  applicationGridKikDefaultPins,
} from 'components/Applications/ApplicationsGrid/Grid/ApplicationGridKikDefaultFilters'

export const useApplicationsGridKikFiltersStore = defineStore(
  'applications-grid-kik-filters',
  () => {
    const {
      filters,
      isDefaultFilterValue,
      clearable,
      getFiltersQuery,
      clear,
      setQueryToRouter,
    } = useGridFilters(applicationGridKikDefaultFilters, true)

    const {
      pins,
      hasPins,
      isPinned,
    } = useGridFiltersPins('applications-kik', Object.keys(applicationGridKikDefaultFilters), applicationGridKikDefaultPins)

    return {
      filters,
      clearable,
      getFiltersQuery,
      clear,
      pins,
      hasPins,
      isPinned,
      setQueryToRouter,
      isDefaultFilterValue,
    }
  })
