import {defineStore} from 'pinia'
import {useGetApi} from 'src/uses/Api/useGetApi'
import {computed, ref} from 'vue'
import {Subscription} from 'src/models/Profile/Notifications/Subscription'
import {useGridPagination} from 'src/uses/Grid/useGridPagination'
import {ProductEnum} from 'src/models/Applications/ProductEnum'

export const useProfileNotificationsGridStore = defineStore('profile-notifications-grid', () => {
  const AGENT_PROFILE_SUBSCRIPTIONS_LIST_URL = 'v2/profile/subscriptions'

  const {
    loading,
    get,
    errors,
    error,
    status,
    response,
  } = useGetApi<Subscription[]>()

  const productType = ref<ProductEnum>(ProductEnum.EBG)

  const {
    gridPagination,
  } = useGridPagination('profile-notifications')

  const items = ref<Subscription[]>([])

  const subscriptions = computed(() => {
    return items.value.filter(item => item.product_type === productType.value)
  })

  const getItemIndex = (code: string, productType: ProductEnum) => {
    return items.value
      .findIndex(item => item.code === code && item.product_type === productType)
  }

  const load = async () => {
    await get(
      AGENT_PROFILE_SUBSCRIPTIONS_LIST_URL
    )

    if (status.value === 200 && response.value) {
      items.value = response.value
    }
  }

  return {
    loading,
    get,
    errors,
    error,
    items,
    subscriptions,
    getItemIndex,
    load,
    gridPagination,
    productType,
  }
})
