<template>
  <div class="cursor-pointer">
    <q-input
      ref="dateFromInput"
      :key="props.modelValue"
      v-model="model"
      mask="##.##.####"
      placeholder="дд.мм.гггг"
      :class="classes"
      :hint="hint"
      :disable="disable"
      :error="!!props.error"
      :error-message="props.error"
      :loading="loading"
      :clearable="props.clearable"
      label-slot
      outlined
      hide-bottom-space
      dense
      @clear="onClear"
      @change="onDateChange"
    >
      <template #label>
        <slot name="labelPrepend" />
        {{ props.label }}
        <slot name="labelAppend" />
      </template>
      <template #append>
        <q-icon
          class="cursor-pointer"
          name="svguse:icons.svg?2#app-calendar"
        />
        <q-popup-proxy
          v-if="!disable"
          ref="dateProxy"
          transition-show="scale"
          transition-hide="scale"
        >
          <q-date
            v-model="model"
            mask="DD.MM.YYYY"
            :options="optionsFn"
            minimal
            @update:model-value="($refs['dateProxy'] as QPopupProxy).hide(); update()"
          />
        </q-popup-proxy>
      </template>
    </q-input>
  </div>
</template>

<script setup lang="ts">
import {withDefaults, ref, watchEffect, computed} from 'vue'
import {FormElementValue} from 'src/models/Form'
import {QInput, QPopupProxy} from 'quasar'
import moment from 'moment'

const props = withDefaults(
  defineProps<{
    modelValue: string | null | undefined,
    hint?: string,
    disable?: boolean,
    error?: string,
    loading?: boolean,
    label: string | boolean,
    minDate?: string,
    maxDate?: string,
    withTopLabel?: boolean,
    clearable?: boolean,
  }>(),
  {
    hint: undefined,
    disable: false,
    error: undefined,
    loading: false,
    minDate: undefined,
    maxDate: undefined,
    withTopLabel: false,
    clearable: false,
  }
)

const emit = defineEmits<{
  (e: 'update:model-value', value: FormElementValue): void,
}>()

const model = ref<string>('')
const dateProxy = ref<QPopupProxy>()

const classes = computed(() => {
  return {
    'field-with-top-label': props.withTopLabel,
    'overflow-label-visible': true,
  }
})

const onDateChange = () => {
  const parts = model.value.split('.')
  if ((parts[2] || '').length === 2) {
    parts[2] = `20${parts[2]}`
    model.value = parts.join('.')
  }

  update()
}

const onClear = () => {
  update()
}

const update = () => {
  emit('update:model-value', model.value)
}

const optionsFn = (date: string) => {
  let rulesResult = true
  const dateMoment = moment(date, 'YYYY/MM/DD')
  if (props.minDate) {
    rulesResult = rulesResult && dateMoment.isSameOrAfter(
      moment(props.minDate, 'DD.MM.YYYY')
    )
  }
  if (props.maxDate) {
    rulesResult = rulesResult && dateMoment.isSameOrBefore(
      moment(props.maxDate, 'DD.MM.YYYY')
    )
  }

  return rulesResult
}

watchEffect(() => {
  model.value = props.modelValue as never as string
})
</script>
