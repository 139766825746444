<template>
  <q-form
    @submit="handleSubmit"
  >
    <div style="font-size: 16px; padding-top: 80px;">
      <q-icon
        name="svguse:icons.svg?2#app-identification"
        color="blue-grey-4"
        size="sm"
      />
      Если у Вас есть аккаунт, выполните
      <router-link :to="{name: 'login'}">
        вход
      </router-link>
    </div>
    <div class="q-gutter-lg q-mt-xl">
      <q-input
        v-model="registrationRequestData.inn"
        class="field-with-top-label"
        :loading="loading"
        :error-message="getErrorMessage('inn')"
        :error="hasError('inn')"
        autocomplete="new-password"
        label="ИНН"
        counter
        outlined
        hide-hint
        hide-bottom-space
        dense
        @blur="validation.inn.$validate()"
        @focus="clearError('inn')"
      />

      <q-input
        v-model="registrationRequestData.email"
        class="field-with-top-label"
        :loading="loading"
        autocomplete="new-password"
        label="E-mail"
        :error-message="getErrorMessage('email')"
        :error="hasError('email')"
        outlined
        hide-hint
        hide-bottom-space
        dense
        @blur="validation.email.$validate()"
        @focus="clearError('email')"
      />

      <q-input
        v-model="registrationRequestData.phone"
        class="field-with-top-label"
        :loading="loading"
        :error-message="getErrorMessage('phone')"
        :error="hasError('phone')"
        type="tel"
        autocomplete="new-password"
        label="Телефон"
        mask="+# (###) ### - ## - ##"
        hint="+# (###) ### - ## - ##"
        unmasked-value
        fill-mask
        outlined
        hide-hint
        hide-bottom-space
        dense
        @blur="validation.phone.$validate()"
        @focus="clearError('phone')"
      />
      <password-help
        :model-value="showPasswordHelp"
        :password="registrationRequestData.password"
      >
        <q-input
          v-model="registrationRequestData.password"
          class="field-with-top-label"
          :loading="loading"
          :error-message="getErrorMessage('password')"
          :error="hasError('password')"
          autocomplete="new-password"
          :type="passwordType ? 'password' : 'text'"
          label="Пароль"
          outlined
          hide-hint
          hide-bottom-space
          dense
          @blur="validation.password.$validate(); showPasswordHelp = false"
          @focus="showPasswordHelp = true; clearError('password')"
        >
          <template #append>
            <q-icon
              class="cursor-pointer"
              :name="passwordType ? 'svguse:icons.svg?2#app-eye-off' : 'svguse:icons.svg?2#app-eye'"
              @click="passwordType = !passwordType"
            />
          </template>
        </q-input>
      </password-help>
      <div style="margin-left: 14px;">
        <div>
          <q-toggle
            v-model="registrationRequestData.sublicense_agreement"
            :class="{error: validation.sublicense_agreement.$error}"
            @change="validation.sublicense_agreement.$validate()"
          >
            С условиями
            <a
              target="_blank"
              href="https://gosoblako.com/sublicense"
              @click.capture.stop
            >
              Сублицензионного соглашения
            </a>
            ознакомлен
          </q-toggle>
        </div>

        <div>
          <q-toggle
            v-model="registrationRequestData.privacy_policy"
            :class="{error: validation.privacy_policy.$error}"
            @change="validation.privacy_policy.$validate()"
          >
            С
            <a
              target="_blank"
              href="https://gosoblako.com/policy"
              @click.capture.stop
            >
              Политикой конфиденциальности
            </a>
            ознакомлен
          </q-toggle>
        </div>
      </div>
    </div>
    <q-btn
      class="q-mt-xl"
      :class="{'full-width': $q.platform.is.mobile}"
      :loading="loading"
      label="Регистрация"
      color="primary"
      type="submit"
      icon-right="svguse:icons.svg?2#app-arrow-narrow-right"
      style="height: 50px;"
      unelevated
      no-caps
    />
  </q-form>
  <q-dialog
    v-model="successModal"
    persistent
  >
    <q-card class="q-ma-md">
      <q-card-section>
        <div class="text-h5">
          <strong>Еще один шаг!</strong>
        </div>
      </q-card-section>
      <q-card-section>
        Мы отправили на указанный вами почтовый ящик письмо для подтверждения и активации учетной записи
      </q-card-section>
      <q-card-actions class="row justify-center">
        <q-btn
          label="Ок"
          color="primary"
          class="full-width"
          style="height: 50px;"
          unelevated
          no-caps
          @click="successModal = false"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script lang="ts" setup>
import {useRegistrationStore} from 'src/stores/Login/useRegistrationStore'
import {storeToRefs} from 'pinia'
import PasswordHelp from 'src/components/PasswordHelp.vue'
import {onBeforeUnmount, ref, watch} from 'vue'
import {useValidation} from 'src/uses/useValidation'

const successModal = ref(false)
const passwordType = ref(true)

const registrationStore = useRegistrationStore()

const {
  registrationRequestData,
  validation,
  errors,
  loading,
  success
} = storeToRefs(registrationStore)

const {
  doRegistration,
} = registrationStore

const {
  getErrorMessage,
  hasError,
  clearError
} = useValidation(validation, errors)

const showPasswordHelp = ref(false)

onBeforeUnmount(() => {
  registrationStore.$reset()
  registrationStore.$dispose()
})

const handleSubmit = async () => {
  await doRegistration()
}

watch(
  success,
  () => {
    if (!success.value) {
      return
    }

    successModal.value = true
  }
)
</script>
