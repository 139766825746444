import {ApplicationSubStatusEnum} from 'src/models/Applications/ApplicationSubStatusEnum'
import {ApplicationStatusEnum} from 'src/models/Applications/ApplicationStatusEnum'

export const useRequestFulfilledStatus = () => {
  const getStatus = (status: ApplicationStatusEnum): ApplicationSubStatusEnum | undefined => {
    switch (status) {
      case ApplicationStatusEnum.verification:
        return ApplicationSubStatusEnum.checking
      case ApplicationStatusEnum.abs:
      case ApplicationStatusEnum.in_bank:
        return ApplicationSubStatusEnum.data_request_executed
      case ApplicationStatusEnum.risk_assessment:
        return ApplicationSubStatusEnum.risks_request_executed
      case ApplicationStatusEnum.on_release:
        return ApplicationSubStatusEnum.deferred_conditions_executed
      case ApplicationStatusEnum.guarantee_released_request:
        return ApplicationSubStatusEnum.guarantee_released
      case ApplicationStatusEnum.bg_project_bank_agreement_request:
        return ApplicationSubStatusEnum.bg_project_bank_agreement
      default:
        return undefined
    }
  }

  const getInitiator = (subStatus: ApplicationSubStatusEnum): string => {
    switch (subStatus) {
      case ApplicationSubStatusEnum.guarantee_released_request:
        return 'clarifications'
      case ApplicationSubStatusEnum.bg_project_bank_agreement_request:
        return 'documents_updated'
      default:
        return 'request_fulfilled'
    }
  }

  return {
    getStatus,
    getInitiator,
  }
}
