<template>
  <info-banner
    text="Для формирования отчета, введите необходимые данные и нажмите кнопку “Сформировать отчет”"
    icon="svguse:icons.svg?2#app-exclamation-circle"
  />
  <div class="row q-mb-md q-mt-md q-col-gutter-md">
    <div class="col-12">
      <reports-grid-filters
        :loading="loading"
        @update:filters="updateFilters"
      >
        <template #append>
          <q-btn
            color="primary"
            icon-right="svguse:icons.svg?2#app-document-text"
            label="Сформировать отчет"
            class="full-width q-ml-md"
            style="padding: 13px 24px;"
            unelevated
            no-caps
            @click="generate"
          />
        </template>
      </reports-grid-filters>
    </div>
  </div>
  <div class="ReportsGrid--table">
    <div class="text-h6 q-pt-lg q-pl-sm"><strong>История</strong></div>
    <q-table
      v-model:pagination="gridPagination"
      class="sticky-header-table q-ma-sm"
      :rows="items"
      :columns="columns"
      :loading="postLoading"
      :rows-per-page-options="perPageOptions"
      separator="none"
      row-key="index"
      wrap-cells
      hide-pagination
      @request="onRequest"
    >
      <template
        v-if="!loading"
        #no-data
      >
        Вы пока не сформировали отчеты. Нажмите кнопку "Сформировать отчет", чтобы получить выгрузку по вашим заявкам
      </template>
      <template #header="props">
        <q-th auto-width />
        <q-th
          v-for="col in props.cols"
          :key="col.name"
          :props="props"
        >
          {{ col.label }}
        </q-th>
      </template>

      <template #body="props">
        <q-tr :props="props">
          <q-td
            auto-width
          >
            <q-btn
              :icon="isExpanded(props.row.id) ? 'svguse:icons.svg?2#app-chevron-up' : 'svguse:icons.svg?2#app-chevron-down'"
              size="sm"
              padding="7px"
              unelevated
              @click="toggleExpand(props.row.id)"
            />
          </q-td>
          <q-td
            :props="props"
            key="type"
          >
            Отчет по продукту {{ ReportTypeDescriptions.getReportTypeDescription(props.row.filter.product_type) }}
          </q-td>
          <q-td
            :props="props"
            key="status"
          >
            <span :class="statusClass(props.row.status)">
            {{ ReportStatusDescriptions.getReportStatusDescription(props.row.status) }}
          </span>
          </q-td>
          <q-td
            :props="props"
            key="created"
          >
            {{ props.row.created }}
          </q-td>
          <q-td
            :props="props"
            key="actions"
          >
            <q-btn
              size="sm"
              color="primary"
              icon="svguse:icons.svg?2#app-download"
              round
              flat
              @click="download(downloadUrl(props.row.id), false)"
            />
          </q-td>
        </q-tr>
        <q-tr
          v-show="isExpanded(props.row.id)"
          class="expanded"
          :props="props"
        >
          <q-td colspan="100%">
            <div class="q-mb-xs">
              <span class="text-bold q-mr-xs">Банки:</span>
              <template v-if="!props.row.filter.banks || props.row.filter.banks.length === 0 || props.row.filter.banks.length === getOptionsByProduct(props.row.filter.product_type).length">
                Все
              </template>
              <template v-else>
                <span
                  v-for="bank in props.row.filter.banks"
                  :key="bank"
                >
                  <img
                    :alt="bank.title_short"
                    :src="bank.logo"
                    style="max-height: 26px;line-height: 12px"
                    class="q-mr-xs"
                  >
                </span>
              </template>
            </div>
            <div
              v-if="!isAdmin()"
              class="q-mb-xs"
            >
              <span class="text-bold q-mr-xs">Сотрудники:</span>
              <template v-if="!props.row.filter.employees || props.row.filter.employees.length === 0">
                Все
              </template>
              <template v-else>
                <span
                  v-for="(employee, index) in props.row.filter.employees"
                  :key="employee.name"
                >
                {{ employee.name_full }}<i v-if="props.row.filter.employees.length > index + 1">, </i>
              </span>
              </template>
            </div>
            <div class="q-mb-xs">
              <span class="text-bold q-mr-xs">Статусы:</span>
              <template v-if="isAllStatuses(props.row.filter)">
                Все
              </template>
              <template v-else>
                <span
                  v-for="(status, key, index) in props.row.filter.statuses"
                  :key="key"
                >
                  {{ label(status) }}<i v-if="index !== Object.keys(props.row.filter.statuses).length - 1">, </i>
                </span>
              </template>
            </div>
            <div
              v-if="isAdmin()"
              class="q-mb-xs"
            >
              <span class="text-bold q-mr-xs">Агент:</span>
              <template v-if="!props.row.filter.agents || props.row.filter.agents.length === 0">
                Все
              </template>
              <template v-else>
                <span
                  v-for="(agent, index) in props.row.filter.agents"
                  :key="index"
                >
                  {{ agent.title }} ({{ agent.inn }})
                  <i v-if="props.row.filter.agents.length > index + 1">, </i>
                </span>
              </template>
            </div>
            <div
              v-if="isAdmin()"
              class="q-mb-xs"
            >
              <span class="text-bold q-mr-xs">Клиент:</span>
              <template v-if="!props.row.filter.clients || props.row.filter.clients.length === 0">
                Все
              </template>
              <template v-else>
                <span
                  v-for="(client, index) in props.row.filter.clients"
                  :key="index"
                >
                  {{ client.title }} ({{ client.inn }})
                  <i v-if="props.row.filter.clients.length > index + 1">, </i>
                </span>
              </template>
            </div>
            <div
              v-if="isAdmin() && props.row.filter.status_date_from"
              class="q-mb-xs"
            >
              <span class="text-bold q-mr-xs">Период установки статуса с:</span>
              {{ props.row.filter.status_date_from }}
            </div>
            <div
              v-if="isAdmin() && props.row.filter.status_date_to"
              class="q-mb-xs"
            >
              <span class="text-bold q-mr-xs">Период установки статуса по:</span>
              {{ props.row.filter.status_date_to }}
            </div>
            <div
              v-if="isAdmin() && props.row.filter.release_date_from"
              class="q-mb-xs"
            >
              <span class="text-bold q-mr-xs">Дата выдачи с:</span>
              {{ props.row.filter.release_date_from }}
            </div>
            <div
              v-if="isAdmin() && props.row.filter.release_date_to"
              class="q-mb-xs"
            >
              <span class="text-bold q-mr-xs">Дата выдачи по:</span>
              {{ props.row.filter.release_date_to }}
            </div>
            <div
              v-if="isAdmin()"
              class="q-mb-xs"
            >
              <span class="text-bold q-mr-xs">Сумма БГ от:</span>
              <money-component
                v-if="props.row.filter.guarantee_amount_min"
                :model-value="props.row.filter.guarantee_amount_min"
              />
              <template v-else>Любая</template>
            </div>
            <div
              v-if="isAdmin()"
              class="q-mb-xs"
            >
              <span class="text-bold q-mr-xs">Сумма БГ до:</span>
              <money-component
                v-if="props.row.filter.guarantee_amount_max"
                :model-value="props.row.filter.guarantee_amount_max"
              />
              <template v-else>Любая</template>
            </div>
            <div class="q-mb-xs">
              <span class="text-bold q-mr-xs">Дата создания заявок с:</span>
              {{ props.row.filter.date_from }}
            </div>
            <div>
              <span class="text-bold q-mr-xs">Дата создания заявок по:</span>
              {{ props.row.filter.date_to }}
            </div>
          </q-td>
        </q-tr>
      </template>
    </q-table>
    <pagination-component
      :pagination="gridPagination"
      style="margin-bottom: 0"
      :per-page-options="perPageOptions"
      @request="onRequest"
      @refresh="load"
    />
  </div>
</template>

<script setup lang="ts">
import {storeToRefs} from 'pinia'
import {onBeforeMount, onBeforeUnmount, ref} from 'vue'
import {useGridSettings} from 'src/uses/Grid/useGridSettings'
import {ReportsGridColumns} from 'src/components/Profile/Reports/ReportsGridColumns'
import {useReportsGridFiltersStore} from 'src/stores/Profile/Reports/useReportsGridFiltersStore'
import {useReportsGridStore} from 'src/stores/Profile/Reports/useReportsGridStore'
import PaginationComponent from 'src/components/PaginationComponent.vue'
import InfoBanner from 'src/components/InfoBanner.vue'
import {ReportStatusDescriptions} from 'src/models/Profile/Reports/ReportStatusDescriptions'
import ReportsGridFilters from 'src/components/Profile/Reports/ReportsGridFilters.vue'
import {useFileDownload} from 'src/uses/File/useFileDownload'
import {ReportTypeDescriptions} from 'src/models/Profile/Reports/ReportTypeDescriptions'
import {useStatus} from 'src/uses/Applications/useStatus'
import {useProfileStore} from 'stores/Login/useProfileStore'
import {GridPagination, RequestProp} from 'src/models/Grid/Pagination'
import {useBankFilterStore} from 'stores/Banks/useBankFilterStore'
import {ReportsGridItemFilters} from 'src/models/Profile/Reports/ReportsGridItem'
import MoneyComponent from 'components/MoneyComponent.vue'

const filtersStore = useReportsGridFiltersStore()
const reportsGridStore = useReportsGridStore()
const {
  items,
  gridPagination,
  perPageOptions,
  loading,
  postLoading,
} = storeToRefs(reportsGridStore)

const {
  load,
  onRequestPagination,
  setPage,
  statusClass,
  generate,
} = reportsGridStore

const banksStore = useBankFilterStore()
const {
  getOptionsByProduct,
} = banksStore

const {isAdmin} = useProfileStore()

const {
  label,
} = useStatus()

const {columns} = useGridSettings('profile-reports-grid', ReportsGridColumns)

const {
  download
} = useFileDownload()

onBeforeMount(async () => {
  await load()
})

onBeforeUnmount(() => {
  reportsGridStore.$reset()
  reportsGridStore.$dispose()
  filtersStore.$reset()
  filtersStore.$dispose()
})

const onRequest = ({pagination}: RequestProp) => {
  onRequestPagination(pagination as GridPagination)
  load()
}

const updateFilters = () => {
  setPage(1)
  load()
}

const expanded = ref<number[]>([])

const isExpanded = (id: number) => expanded.value.includes(id)
const downloadUrl = (id: string) => {
  const baseUrl = isAdmin() ? 'v1/admin/reports' : 'v1/reports'
  return `${baseUrl}/${id}/download`
}

const isAllStatuses = (filter: ReportsGridItemFilters) => {
  return !filter.statuses || filter.statuses.length === 0
}

const toggleExpand = (id: number) => {
  if (isExpanded(id)) {
    expanded.value = expanded.value
      .filter(expandedId => id !== expandedId)
  } else {
    expanded.value.push(id)
  }
}

</script>
<style lang="scss">
.ReportsGrid--table {
  margin: 0 -24px -24px;
  background: $blue-1;
  padding: 0 24px 24px;

  table {
    position: relative;

  }

  .expanded {
    td {
      background-color: #FAFAFA!important;
    }
  }

  .createButton {
    .icon {
      background: white;
      border-radius: 6px;
      position: absolute;
      right: 10px;
      line-height: 0;
    }

    .q-btn {
      border-radius: 6px;
    }
  }
}
</style>
