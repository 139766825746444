<template>
  <application-grid-info-action
    v-if="canShowInfo"
    @show-dialog="infoDialog = true"
  />
  <application-grid-to-control-center-action :item="props.item" />
  <application-grid-to-application-item-action :item="props.item" />
  <application-grid-change-status-action :item="props.item" />
  <application-grid-fivorite-action
    :item="props.item"
    @updated="emit('change')"
  />
  <q-btn-dropdown
    v-if="!isBankEmployee()"
    v-model="state"
    class="ApplicationGridActions"
    :disable="!showDots"
    color="blue-grey-4"
    size="12px"
    dropdown-icon="none"
    padding="6px 0 6px 6px"
    flat
    unelevated
    rounded
    square
    auto-close
    @click="clickBtn"
  >
    <template #label>
      <q-icon name="svguse:icons.svg?2#app-dots-vertical">
        <q-tooltip
          anchor="top middle"
          self="bottom middle"
        >Изменить статус</q-tooltip>
      </q-icon>
    </template>
    <q-list class="ApplicationGridActions--list">
      <!--<application-grid-info-action
         v-if="profile && profile.role === UserRoleEnum.Operator"
         list
         @show-dialog="infoDialog = true"
       />
       <application-grid-to-control-center-action
         :item="props.item"
         list
       />
       <application-grid-to-application-item-action
         :item="props.item"
         list
       />-->
      <application-grid-change-status-action
        v-if="showGridStatusChange(props.item)"
        :item="props.item"
        list
      />
      <application-grid-release-date-action
        v-if="showReleaseDate"
        @show-dialog="releaseDateDialog = true"
      />
      <application-grid-comment-action
        v-if="showComment"
        @show-dialog="commentDialog = true"
      />
    </q-list>
  </q-btn-dropdown>
  <application-grid-info-action-dialog
    v-model="infoDialog"
    :item="props.item"
    @change="emit('change')"
  />
  <application-grid-release-date-dialog
    v-if="showReleaseDate"
    v-model="releaseDateDialog"
    :item="props.item"
    @change="emit('change')"
  />
  <application-grid-comment-dialog
    v-if="showComment"
    v-model="commentDialog"
    :item="props.item"
    @change="emit('change')"
  />
</template>

<script lang="ts" setup>
import {computed, ref} from 'vue'
import {ApplicationsGridItem} from 'src/models/Applications/ApplicationsGridItem'
import ApplicationGridInfoAction from 'src/components/Applications/ApplicationsGrid/Actions/ApplicationGridInfoAction.vue'
import ApplicationGridToControlCenterAction
  from 'src/components/Applications/ApplicationsGrid/Actions/ApplicationGridToControlCenterAction.vue'
import ApplicationGridToApplicationItemAction
  from 'src/components/Applications/ApplicationsGrid/Actions/ApplicationGridToApplicationItemAction.vue'
import ApplicationGridChangeStatusAction
  from 'src/components/Applications/ApplicationsGrid/Actions/ApplicationGridChangeStatusAction.vue'
import ApplicationGridInfoActionDialog
  from 'src/components/Applications/ApplicationsGrid/Actions/ApplicationGridInfoActionDialog.vue'
import {useAuthStore} from 'src/stores/Login/useAuthStore'
import {storeToRefs} from 'pinia'
import {UserRoleEnum} from 'src/models/User/UserRoleEnum'
import ApplicationGridFivoriteAction
  from 'src/components/Applications/ApplicationsGrid/Actions/ApplicationGridFivoriteAction.vue'
import {useGridChangeStatusAction} from 'src/uses/Grid/useGridChangeStatusAction'
import ApplicationGridReleaseDateAction
  from 'src/components/Applications/ApplicationsGrid/Actions/ApplicationGridReleaseDateAction.vue'
import {useProfileStore} from 'src/stores/Login/useProfileStore'
import ApplicationGridReleaseDateDialog
  from 'src/components/Applications/ApplicationsGrid/Actions/ApplicationGridReleaseDateDialog.vue'
import ApplicationGridCommentDialog
  from 'src/components/Applications/ApplicationsGrid/Actions/ApplicationGridCommentDialog.vue'
import ApplicationGridCommentAction
  from 'src/components/Applications/ApplicationsGrid/Actions/ApplicationGridCommentAction.vue'
import {usePosthogStore} from 'stores/Posthog/usePosthogStore'

const props = defineProps<{
  item: ApplicationsGridItem
}>()

const emit = defineEmits<{
  (e: 'change'): void,
}>()

const authStore = useAuthStore()
const {isBankEmployee} = authStore
const {
  profile,
} = storeToRefs(authStore)
const {sendEvent} = usePosthogStore()

const {
  isAgentManager,
} = useProfileStore()

const {
  showGridStatusChange,
  isBankRejected,
  isSystemRejected,
  isClientRejected,
} = useGridChangeStatusAction()

const state = ref(false)

const infoDialog = ref(false)
const releaseDateDialog = ref(false)
const commentDialog = ref(false)

const showReleaseDate = computed(() => {
  return isAgentManager()
    && !isBankRejected(props.item)
    && !isSystemRejected(props.item)
    && !isClientRejected(props.item)
})

const showComment = computed(() => {
  return isAgentManager()
    && isClientRejected(props.item)
})

const showDots = computed(() => {
  return showGridStatusChange(props.item)
    || showReleaseDate.value
    || showComment.value
})

const canShowInfo = computed(() => {
  if (!profile.value) {
    return false
  }

  if (
    [
      UserRoleEnum.Operator,
      UserRoleEnum.AgentManager,
      UserRoleEnum.Admin,
    ].includes(profile.value.role)
  ) {
    return true
  }

  return UserRoleEnum.BankEmployee === profile.value.role
    && !profile.value.is_hide_personal_data
})

const clickBtn = () => {
  sendEvent('Change_applications_status_via_list_of_applications')
}
</script>

<style lang="scss">
.ApplicationGridActions {
  .q-btn-dropdown__arrow {
    display: none;
  }
}

.ApplicationGridActions--list {
  color: $grey-7 !important;

  .q-item {
    border-bottom: 1px solid $blue-grey-1;

    .q-icon {
      margin-top: -4px;
    }
  }
}
</style>
