import {computed, Ref, ref, watch} from 'vue'
import {LawTypeEnum} from 'src/models/Applications/LawTypeEnum'
import {RntNotInEISReasonEnum} from 'src/models/Enums/RntNotInEISReasonEnum'
import {ProvisionTypeEnum} from 'src/models/Applications/ProvosionTypeEnum'
import moment from 'moment'
import {BgFormEnum} from 'src/models/Applications/BgFormEnum'
import {useBlock} from 'src/uses/useBlock'
import {useDialog} from 'src/uses/Dialogs/useDialog'
import {ApplicationNewModeEnum} from 'src/models/Applications/New/ApplicationNewModeEnum'

export const useApplicationsNewForm = (mode: Ref<ApplicationNewModeEnum>) => {
  const applicationBlock = useBlock()
  const dialog = useDialog()

  const rnt = ref('')
  const lot = ref(1)
  const law = ref<LawTypeEnum | null>(null)
  const rntNotInEIS = ref(false)
  const rntNotInEISReason = ref<RntNotInEISReasonEnum | null>(null)
  const rntLink = ref<string | null>(null)
  const oversupply = ref(false)
  const goz275Fz = ref(false)
  const provisionType = ref<ProvisionTypeEnum>(ProvisionTypeEnum.Execution)
  const oneBg = ref(false)
  const bgSum = ref<number>()
  const nmcDiscount = ref(false)
  const dateFrom = ref(moment().format('DD.MM.YYYY'))
  const dateTo = ref('')
  const countDays = ref(0)
  const fromIssueDate = ref(true)
  const bgForm = ref<BgFormEnum | null>(null)
  const customerBgForm = ref(false)
  const mailhardcopy = ref(false)

  const fieldsWithErrors = ref<string[]>([])

  const setDefault = () => {
    rnt.value = ''
    lot.value = 1
    law.value = null
    rntNotInEIS.value = false
    rntNotInEISReason.value = null
    rntLink.value = null
    oversupply.value = false
    goz275Fz.value = false
    provisionType.value = ProvisionTypeEnum.Execution
    oneBg.value = false
    bgSum.value = undefined
    nmcDiscount.value = false
    dateFrom.value = moment().format('DD.MM.YYYY')
    dateTo.value = ''
    countDays.value = 0
    fromIssueDate.value = true
    bgForm.value = null
    customerBgForm.value = false
    mailhardcopy.value = false

    applicationBlock.blocked.value = ['rntNotInEISReason', 'mailhardcopy']
  }

  setDefault()

  const fieldsSet = [
    'rnt',
    'lot',
    'law',
    'rntNotInEISReason',
    'provisionType',
    'bgSum',
    'dateFrom',
    'dateTo',
    'bgForm',
    'countDays',
  ]

  const lastFocused = ref('')

  const data = computed<Record<string, Ref>>(() => ({
    rnt,
    lot,
    law,
    rntNotInEIS,
    rntNotInEISReason,
    rntLink,
    oversupply,
    goz275Fz,
    provisionType,
    oneBg,
    bgSum,
    nmcDiscount,
    dateFrom,
    dateTo,
    countDays,
    fromIssueDate,
    bgForm,
    customerBgForm,
    mailhardcopy,
  }))

  const ignoreCountDaysWatch = ref(false)

  const clearFieldError = (field: string) => {
    if (!fieldsWithErrors.value.includes(field)) {
      return
    }

    fieldsWithErrors.value = fieldsWithErrors.value.filter(f => f !== field)
  }

  const closedPurchase = computed(() => !!rntNotInEIS.value
    && (
      RntNotInEISReasonEnum.closed_purchase === rntNotInEISReason.value
      || RntNotInEISReasonEnum.astgoz === rntNotInEISReason.value
    )
  )
  const commercialPurchase = computed(() => !!rntNotInEIS.value && RntNotInEISReasonEnum.commercial_purchase === rntNotInEISReason.value)
  const astGoz = computed(() => !!rntNotInEIS.value && RntNotInEISReasonEnum.astgoz === rntNotInEISReason.value)

  const showRntNotInEisReasonInsteadRnt = computed<boolean>(() => {
    return !!rntNotInEIS.value
      && !!rntNotInEISReason.value
      && !astGoz.value
  })

  const requiredFields = computed(() => {
    return fieldsSet.filter(f => !applicationBlock.isBlocked(f))
  })

  const emptyRequiredFields = computed(() => {
    return requiredFields.value.filter(
      f => {
        if (!data.value || !data.value[f]) {
          return true
        }

        if (data.value[f].value === undefined
          || data.value[f].value === null
          || data.value[f].value === ''
          || fieldsWithErrors.value.includes(f)
        ) {
          return true
        }

        if (f === 'bgSum') {
          return data.value[f].value === 0
        }

        if (f === 'dateFrom') {
          return !fromDateOptions(data.value[f].value)
        }

        if (f === 'dateTo') {
          return !toDateOptions(data.value[f].value)
        }

        return false
      }
    )
  })

  const percentage = computed(() => {
    const total = requiredFields.value
    const empty = emptyRequiredFields.value

    return Math.ceil((total.length - empty.length) / total.length * 100)
  })

  const nextToFocus = computed(() => {
    if (!emptyRequiredFields.value.length) {
      return null
    }

    return emptyRequiredFields.value[0]
  })

  const focusNext = () => {
    const fieldName = nextToFocus.value
    if (!fieldName || !data.value || !data.value[fieldName]) {
      return
    }

    lastFocused.value = fieldName
    setTimeout(() => lastFocused.value = '', 0)
  }

  const dateFromString = (date: string): Date | null => {
    const parts = date.split('.')
    if (parts.length < 3) {
      return null
    }

    return new Date(`${parts[2]}/${parts[1]}/${parts[0]}`)
  }

  const calculateDays = () => {
    const from = dateFromString(dateFrom.value)
    const to = dateFromString(dateTo.value)
    if (!from || !to) {
      countDays.value = 0

      return
    }

    ignoreCountDaysWatch.value = true
    let contDaysValue = (to.getTime() - from.getTime()) / 60 / 60 / 24 / 1000
    contDaysValue = contDaysValue > 0 ? contDaysValue : 0

    countDays.value = parseInt(contDaysValue.toString())
  }

  const fromDateOptions = (dateString: string) => {
    const date = moment(dateString, dateString.includes('/') ? 'YYYY/MM/DD' : 'DD.MM.YYYY')
    const now = moment()
    const to = moment(dateTo.value, 'DD.MM.YYYY')

    if (!date.isValid()) {
      return false
    }

    if (mode.value === ApplicationNewModeEnum.clone) {
      return true
    }

    if (to.isValid() && to.startOf('day').isSameOrAfter(now.startOf('day'))) {
      return date.startOf('day').isSameOrAfter(now.startOf('day'))
        && date.startOf('day').isBefore(to.startOf('day'))
    }

    return date.startOf('day').isSameOrAfter(now.startOf('day'))
  }

  const toDateOptions = (dateString: string) => {
    const date = moment(dateString, dateString.includes('/') ? 'YYYY/MM/DD' : 'DD.MM.YYYY')
    const now = moment()
    const from = moment(dateFrom.value, 'DD.MM.YYYY')

    if (!date.isValid()) {
      return false
    }

    if (mode.value === ApplicationNewModeEnum.clone) {
      return true
    }

    if (from.isValid()) {
      return date.startOf('day').isSameOrAfter(now.startOf('day'))
        && date.startOf('day').isAfter(from.startOf('day'))
    }

    return date.startOf('day').isSameOrAfter(now.startOf('day'))
  }

  const refreshSumByLawAndNmc = (law: LawTypeEnum, nmc: boolean) => {
    if (!bgSum.value) {
      return
    }
    if (nmc && law === LawTypeEnum.PP615) {
      bgSum.value = bgSum.value * 2
    }
    if (nmc && law === LawTypeEnum.FZ44) {
      bgSum.value = bgSum.value * 1.5
    }

    if (!nmc && law === LawTypeEnum.PP615) {
      bgSum.value = bgSum.value / 2
    }
    if (!nmc && law === LawTypeEnum.FZ44) {
      bgSum.value = bgSum.value / 1.5
    }
  }

  watch(dateFrom, calculateDays)
  watch(dateTo, calculateDays)
  watch(
    countDays,
    () => {
      if (ignoreCountDaysWatch.value) {
        ignoreCountDaysWatch.value = false

        return
      }
      const from = dateFromString(dateFrom.value)
      if (!from) {
        return
      }

      if (!countDays.value) {
        return
      }

      dateTo.value = moment(from).add(countDays.value, 'days').format('DD.MM.YYYY')
    }
  )

  watch(
    bgForm,
    () => {
      if (bgForm.value === BgFormEnum.paper) {
        applicationBlock.unblock('mailhardcopy')
        mailhardcopy.value = true

        return
      }

      applicationBlock.block('mailhardcopy')
      mailhardcopy.value = false
    },
    {immediate: true}
  )

  watch(
    provisionType,
    () => {
      if (provisionType.value === ProvisionTypeEnum.Execution) {
        applicationBlock.unblock('nmcDiscount')
      }
      if (provisionType.value === ProvisionTypeEnum.Participation) {
        applicationBlock.block('nmcDiscount')
      }
      if (provisionType.value === ProvisionTypeEnum.Refund) {
        applicationBlock.block('nmcDiscount')
      }
      if (provisionType.value === ProvisionTypeEnum.Guarantee) {
        applicationBlock.block('nmcDiscount')
      }
    },
    {
      immediate: true,
    }
  )

  watch(
    law,
    () => {
      if (law.value === LawTypeEnum.FZ223) {
        applicationBlock.block('nmcDiscount')
        nmcDiscount.value = false
      } else {
        applicationBlock.unblock('nmcDiscount')
      }

      /*if (!law.value) {
        return
      }*/
    }
  )

  watch(
    nmcDiscount,
    () => {
      if (!law.value) {
        return
      }

      refreshSumByLawAndNmc(law.value, nmcDiscount.value)
    }
  )

  watch(
    rntNotInEIS,
    () => {
      if (mode.value === ApplicationNewModeEnum.clone) {
        return
      }
      if (!rntNotInEIS.value) {
        if (rntNotInEISReason.value === RntNotInEISReasonEnum.astgoz) {
          fieldsWithErrors.value.push('rnt')
        }
        rntNotInEISReason.value = null
        rntLink.value = ''
        applicationBlock.block('rntNotInEISReason')
        applicationBlock.unblock('rnt')

        return
      }

      clearFieldError('rnt')
      applicationBlock.unblock('rntNotInEISReason')
      dialog.show()
    },
    {
      immediate: true
    }
  )

  watch(
    rntNotInEISReason,
    () => {
      if (!rntNotInEIS.value) {
        return
      }
      if (rntNotInEISReason.value !== RntNotInEISReasonEnum.astgoz) {
        applicationBlock.block('rnt')
        rnt.value = ''
      }
      if (rntNotInEISReason.value === RntNotInEISReasonEnum.commercial_purchase) {
        applicationBlock.block('law')
        applicationBlock.block('goz275Fz')
        law.value = null

        return
      }

      applicationBlock.unblock('law')
      applicationBlock.unblock('goz275Fz')
    },
  )

  watch(
    rnt,
    () => {
      applicationBlock.unblock('law')
      applicationBlock.unblock('goz275Fz')
    }
  )

  return {
    isBlocked: applicationBlock.isBlocked,
    block: applicationBlock.block,
    unblock: applicationBlock.unblock,
    clearAllBlocks: applicationBlock.clearAllBlocks,
    blocked: applicationBlock.blocked,

    rntNotInEisDialog: dialog.dialog,
    show: dialog.show,

    rnt,
    lot,
    law,
    rntNotInEIS,
    rntNotInEISReason,
    rntLink,
    oversupply,
    goz275Fz,
    provisionType,
    oneBg,
    bgSum,
    nmcDiscount,
    dateFrom,
    dateTo,
    countDays,
    fromIssueDate,
    bgForm,
    customerBgForm,
    showRntNotInEisReasonInsteadRnt,
    percentage,
    data,
    lastFocused,
    emptyRequiredFields,
    requiredFields,
    nextToFocus,
    mailhardcopy,
    closedPurchase,
    commercialPurchase,
    astGoz,
    setDefault,
    fromDateOptions,
    toDateOptions,
    focusNext,
    clearFieldError,
  }
}
