import {ReportStatusEnum} from 'src/models/Enums/ReportStatusEnum'

const statuses: Record<ReportStatusEnum, string> = {
  [ReportStatusEnum.new]: 'Формируется',
  [ReportStatusEnum.ready]: 'Сформирован',
  [ReportStatusEnum.failed]: 'Ошибка формирования',
}

export class ReportStatusDescriptions {
  public static getReportStatusDescription (status: ReportStatusEnum): string {
    return statuses[status] || status
  }
}
