<template>
  <template v-if="props.list">
    <q-item
      v-if="showSendToCheck(props.item)"
      v-ripple
      clickable
      @click="send([props.item.id], props.item.multi_order_id)"
    >
      <q-item-section>
        Отправить на проверку
      </q-item-section>
    </q-item>

    <q-item
      v-if="showClientReject(props.item)"
      v-ripple
      clickable
      @click="changeStatus(
        {
          id: props.item.id,
          applicationStatus: ApplicationSubStatusEnum.client_rejected,
        }
      )"
    >
      <q-item-section>
        Отказ клиента
      </q-item-section>
    </q-item>
  </template>
</template>

<script setup lang="ts">
import {withDefaults} from 'vue'
import {ApplicationSubStatusEnum} from 'src/models/Applications/ApplicationSubStatusEnum'
import {useSendToCheck} from 'src/stores/Applications/useSendToCheck'
import {useGridKikChangeStatusAction} from 'src/uses/Grid/useGridKikChangeStatusAction'
import {ApplicationsGridKikItem} from 'src/models/Applications/ApplicationsGridKikItem'

const props = withDefaults(
  defineProps<{
    item: ApplicationsGridKikItem
    list?: boolean,
  }>(),
  {
    list: false,
  }
)

const {
  send,
} = useSendToCheck()

const {
  showClientReject,
  showSendToCheck,
  changeStatus,
} = useGridKikChangeStatusAction()
</script>
