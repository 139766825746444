<template>
  <q-input
    :key="String(props.field.value)"
    v-model="model"
    class="field-with-top-label"
    :hint="props.ignoreHint ? '' : (props.field.help || '')"
    :disable="props.field.readonly && !props.ignoreReadonly"
    :error="!!props.field.error"
    :error-message="props.field.error"
    :loading="props.field.loading"
    :mask="props.field.mask ? props.field.mask.replaceAll('0', '#') : undefined"
    label-slot
    outlined
    hide-bottom-space
    dense
    @change="change"
  >
    <template #label>
      <slot name="labelPrepend" />
      {{ props.label || props.field.title }}
      <slot name="labelAppend" />
    </template>
  </q-input>
</template>

<script setup lang="ts">
import {ref, watchEffect} from 'vue'
import {
  ApplicationFormField,
} from 'src/models/Applications/Form/ApplicationFormResponseData'
import {FormElementValue} from 'src/models/Form'

const props = defineProps<{
  field: ApplicationFormField,
  label: string | boolean,
  ignoreReadonly: boolean,
  ignoreHint: boolean,
}>()

const emit = defineEmits<{
  (e: 'update:value', value: FormElementValue): void,
}>()

const model = ref<string | null>(null)

watchEffect(() => {
  model.value = props.field.value as string | null
})

const change = () => {
  emit('update:value', model.value)
}
</script>
