<template>
  <q-tabs
    ref="tabsRef"
    v-model="documentTab"
    class="ApplicationItemFinanceTabs q-px-lg"
    style="margin: 0 -24px;"
    align="left"
    indicator-color="blue-7"
    inline-label
    no-caps
  >
    <q-tab
      v-for="t in tabs"
      :key="t"
      :name="t"
    >
      {{ getDocumentGroupLabel(t) }}
    </q-tab>
  </q-tabs>
  <q-tab-panels
    v-model="documentTab"
    class="bg-transparent"
    style="margin-left: -16px; margin-right: -16px;"
    animated
  >
    <q-tab-panel
      v-for="t in tabs"
      :key="t"
      :name="t"
    >
      <template v-if="t === ApplicationFormDocumentGroupEnum.client">
        <div
          v-if="isBankEmployee()"
          class="q-gutter-x-xs"
        >
          <q-btn
            color="grey-2"
            class="text-blue-grey-5"
            icon="svguse:icons.svg?2#app-download"
            :loading="documentsLoading"
            dense
            unelevated
            @click="download(inputProps.appId, clientDocumentsCodes)"
          >
            <q-tooltip :delay="500">
              Скачать документы без КЭП
            </q-tooltip>
          </q-btn>
          <q-btn
            color="grey-2"
            class="text-blue-grey-5"
            icon="svguse:icons.svg?2#app-download-ecp"
            :loading="signedDocumentsLoading"
            dense
            unelevated
            @click="downloadSigned(inputProps.appId, clientDocumentsCodes, true)"
          >
            <q-tooltip :delay="500">
              Скачать документы с КЭП
            </q-tooltip>
          </q-btn>
        </div>
        <application-form-documents-block
          v-for="documentType in documentTypes"
          :key="documentType"
          :documents="visibleDocuments"
          :new-documents="newDocuments"
          :document-type="documentType"
          :banks="banks"
          :can-upload-all-new-documents="canUploadAllNewDocuments"
          :founders="client && client.founders ? client.founders : []"
          :head-fields="client && client.head ? client.head.fields : []"
          :dropzone-available="dropZoneAvailability[documentType]"
          :can-add-new-added-document="canAddNewAddedDocument(documentType)"
          @cancel="onCancel"
          @file:selected="onFileSelected"
          @file:selected-new="onFileSelectedNew"
          @file:selected-with-sign="onFileSelectedWithSign"
          @document:delete="deleteDocument($event)"
          @document:download="downloadDocument($event, documentType)"
          @document:download-sign="downloadDocumentSign($event, documentType)"
          @new-documents:remove="removeNewDocumentByIndex($event, documentType)"
          @new-documents:upload="onNewDocumentUploadHandler"
          @new-document:update="updateNewDocument"
          @upload-all-new-documents="uploadAllNewDocuments(false, true)"
        />
      </template>
      <template v-else>
        <div
          v-if="manualGeneratingDocumentsAvailable"
          class="q-gutter-x-xs"
        >
          <q-btn
            color="grey-2"
            class="text-blue-grey-5"
            label="Сформировать"
            padding="sm lg"
            :loading="documentsLoading || inDocumentsManualGeneratingProcess"
            dense
            unelevated
            no-caps
            @click="manualGenerateDocuments"
          />
        </div>
        <application-form-documents-block
          :key="t"
          :documents="visibleDocuments"
          :new-documents="newDocuments"
          :document-type="t"
          :banks="banks"
          :founders="[]"
          :head-fields="[]"
          :can-upload-all-new-documents="canUploadAllNewDocuments"
          :dropzone-available="dropZoneAvailability[t]"
          :can-add-new-added-document="canAddNewAddedDocument(t)"
          @cancel="onCancel"
          @file:selected="onFileSelected"
          @file:selected-new="onFileSelectedNew"
          @file:selected-with-sign="onFileSelectedWithSign"
          @document:delete="deleteDocument($event)"
          @document:download="downloadDocument($event, t)"
          @document:download-sign="downloadDocumentSign($event, t)"
          @new-documents:remove="removeNewDocumentByIndex($event, t)"
          @new-documents:upload="onNewDocumentUploadHandler"
          @new-document:update="updateNewDocument"
          @upload-all-new-documents="uploadAllNewDocuments(false, true)"
        />
      </template>
    </q-tab-panel>
  </q-tab-panels>
</template>

<script setup lang="ts">
import {computed} from 'vue'
import {storeToRefs} from 'pinia'
import {
  ApplicationFormDocument,
  ApplicationFormDocumentGroupEnum,
} from 'src/models/Applications/Form/ApplicationFormResponseData'
import {
  useApplicationControlCenterItemStore
} from 'src/stores/Applications/ControlCenter/useApplicationControlCenterItemStore'
import {QTabs} from 'quasar'
import {useDocumentGroup} from 'src/uses/Applications/useDocumentGroup'
import ApplicationFormDocumentsBlock from 'src/components/Applications/Form/Documents/ApplicationFormDocumentsBlock.vue'
import {useProfileStore} from 'stores/Login/useProfileStore'
import {useDocumentsDownload} from 'src/uses/Applications/ControlCenter/useDocumentsDownload'
import {
  useApplicationManualGenerateDocuments
} from 'src/uses/Applications/ManualGenerateDocuments/useApplicationManualGenerateDocuments'

const inputProps = defineProps<{
  appId: string,
}>()

const applicationItemStore = useApplicationControlCenterItemStore(inputProps.appId)()
const {
  uploadDocument,
  cancelUpload,
  deleteDocument,
  downloadDocument,
  downloadDocumentSign,
  addNewDocument,
  removeNewDocumentByIndex,
  onNewDocumentUpload,
  updateNewDocument,
  uploadAllNewDocuments,
  canAddNewAddedDocument,
} = applicationItemStore
const {
  visibleDocuments,
  newDocuments,
  dropZoneAvailability,
  banks,
  client,
  documentTab,
  canUploadAllNewDocuments,
} = storeToRefs(applicationItemStore)

const {isBankEmployee} = useProfileStore()

const {
  inDocumentsManualGeneratingProcess,
  manualGeneratingDocumentsAvailable,
  manualGenerateDocuments,
} = useApplicationManualGenerateDocuments(inputProps.appId)

const {
  download,
  loading: documentsLoading,
} = useDocumentsDownload()

const {
  download: downloadSigned,
  loading: signedDocumentsLoading,
} = useDocumentsDownload()

const {
  getDocumentGroupLabel,
} = useDocumentGroup()

const documentTypes: ApplicationFormDocumentGroupEnum[] = [
  ApplicationFormDocumentGroupEnum.passports,
  ApplicationFormDocumentGroupEnum.financial,
  ApplicationFormDocumentGroupEnum.additional,
]

const tabs: ApplicationFormDocumentGroupEnum[] = [
  ApplicationFormDocumentGroupEnum.client,
  ApplicationFormDocumentGroupEnum.deal,
  ApplicationFormDocumentGroupEnum.offer,
]

const clientDocumentsCodes = computed(() => {
  const docs: ApplicationFormDocument[] = []
  documentTypes
    .forEach(type => {
      docs.push(...(visibleDocuments.value[type] || []))
    })

  return docs
    .map(doc => doc.code)
    .filter((code, index, codes) => codes.indexOf(code) === index)
})

const onFileSelected = (file: File, document: ApplicationFormDocument) => {
  document.file = file
  uploadDocument(document)
}

const onFileSelectedNew = (
  file: File,
  document: ApplicationFormDocument,
  documentType: ApplicationFormDocumentGroupEnum,
) => {
  document.file = file
  addNewDocument(document, documentType)
}

const onCancel = (document: ApplicationFormDocument) => {
  cancelUpload(document)
}

const onNewDocumentUploadHandler = (
  document: ApplicationFormDocument,
  documentType: ApplicationFormDocumentGroupEnum,
  index: number
) => {
  onNewDocumentUpload(document, documentType, index)
}

const onFileSelectedWithSign = (original: File, sign: File, document: ApplicationFormDocument) => {
  document.file_sign = sign
  document.file = original
  uploadDocument(document)
}
</script>
