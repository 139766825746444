import {defineStore} from 'pinia'
import {useProfileStore} from 'stores/Login/useProfileStore'
import postHog from 'posthog-js'

export const usePosthogStore = defineStore('posthog-store', () => {
  const profileStore = useProfileStore()

  const sendEvent = (eventName: string) => {
    if (!profileStore.profile
      || !(profileStore.isAgent() || profileStore.isEmployee() || profileStore.isHeadEmployee())
    ) {
      return
    }

    postHog.capture(eventName, {
      $set: {
        name: profileStore.profile.name.full,
        user_id: profileStore.profile.user_id,
        email: profileStore.profile.email,
        phone: profileStore.profile.phone,
        role: profileStore.profile.role,
      },
    })
  }

  return {
    sendEvent,
  }
})
