import {RouterGuard} from 'src/models/Router/RouterGuard'
import {useAuthStore} from 'src/stores/Login/useAuthStore'
import {UserRoleEnum} from 'src/models/User/UserRoleEnum'
import {Platform} from 'quasar'

export const AuthedGuard: (roles?: UserRoleEnum[]) => RouterGuard = (roles?: UserRoleEnum[]) => ({
  canAccess: () => {
    const authStore = useAuthStore()

    if (!authStore.isAuthed) {
      return false
    }

    return !roles || !authStore.profile
      ? true
      : roles.includes(authStore.profile.role)
  },
  redirectTo: () => {
    const authStore = useAuthStore()
    if (!authStore.isAuthed) {
      return {
        name: Platform.is.mobile ? 'login-main' : 'login',
      }
    }

    if (!!roles && authStore.profile) {
      return {
        name: 'main',
      }
    }

    return undefined
  }
})
