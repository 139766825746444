<template>
  <q-intersection
    v-if="!$q.platform.is.mobile"
    transition="scale"
    once
  >
    <div class="ApplicationNewSend bg-blue-grey-10 q-pa-md text-white">
      <div class="row items-center justify-between q-pa-md">
        <div class="q-pr-sm col-sm-12 col-md">
          Все данные введены, ограничений нет. Заявка может быть размещена
        </div>
        <div :class="senBtnClasses">
          <q-btn
            class="full-width"
            align="left"
            color="green-14"
            size="14px"
            style="padding: 10px 16px;"
            unelevated
            no-caps
            @click="create"
          >
            <strong>Разместить заявку</strong>
            <span class="icon">
              <q-icon
                name="svguse:icons.svg?2#app-arrow-right-bold"
                color="green-14"
                size="18px"
                style="padding: 5.5px;"
              />
            </span>
          </q-btn>
        </div>
      </div>
    </div>
  </q-intersection>
  <q-dialog
    v-else
    :model-value="true"
    position="bottom"
    seamless
  >
    <div
      class="q-ma-sm bg-blue-grey-9 text-white q-pa-md ApplicationNewSend"
      style="border-radius: 10px; margin: 0 10px 100px"
    >
      <div class="q-pr-sm col-sm-12 col-md q-mb-sm">
        Все данные введены, ограничений нет. Заявка может быть размещена
      </div>
      <div :class="senBtnClasses">
        <q-btn
          class="full-width"
          align="left"
          color="green-14"
          size="14px"
          style="padding: 10px 16px;"
          unelevated
          no-caps
          @click="create"
        >
          <strong>Разместить заявку</strong>
          <span class="icon">
            <q-icon
              name="svguse:icons.svg?2#app-arrow-right-bold"
              color="green-14"
              size="18px"
              style="padding: 5.5px;"
            />
          </span>
        </q-btn>
      </div>
    </div>
  </q-dialog>
</template>

<script setup lang="ts">
import {useApplicationsNew} from 'src/stores/Applications/ApplicationsNew/useApplicationsNew'
import {computed} from 'vue'
import {useQuasar} from 'quasar'

const applicationsNew = useApplicationsNew()
const {
  create,
} = applicationsNew

const $q = useQuasar()

const senBtnClasses = computed(() => {
  return {
    sendButtonMobile: $q.platform.is.mobile,
    sendButton: true,
  }
})
</script>

<style lang="scss">
.ApplicationNewSend {
  margin: 0 -24px -24px;
  border-radius: 0 0 10px 10px;

  .sendButton {
    min-width: 220px;

    .icon {
      background: white;
      border-radius: 6px;
      position: absolute;
      right: 10px;
      line-height: 0;
    }

    .q-btn {
      border-radius: 6px;
    }
    &.sendButtonMobile {
      width: 100%!important;
      margin-top: 10px;
    }
  }
}
</style>
