import {useGetApi} from 'src/uses/Api/useGetApi'
import {ref} from 'vue'
import {FormItemGeneratedDocumentListItem} from 'src/models/FormEditor/FormItemGeneratedDocumentListItem'

export const useFormItemGetGeneratedDocuments = () => {
  const {
    loading,
    get,
    errors,
    error,
    status,
    response,
  } = useGetApi<FormItemGeneratedDocumentListItem[]>()

  const generatedDocuments = ref<FormItemGeneratedDocumentListItem[]>([])

  const loadDocs = async () => {
    await get('v2/forms/generated-documents')
    if (status.value === 200 && response && response.value) {
      generatedDocuments.value = response.value
    }
  }

  return {
    loading,
    response,
    status,
    error,
    errors,
    generatedDocuments,
    loadDocs,
  }
}
