import {defineStore} from 'pinia'
import {ref} from 'vue'
import {usePutApi} from 'src/uses/Api/usePutApi'
import {AgentManagerListItem} from 'src/models/AgentManagers/AgentManagerListItem'

export const useAttachAgentToAgentManagerStore = defineStore('attach-agents-to-agent-manager', () => {
  const {
    put,
    response,
    status,
    loading,
    error,
    errors,
  } = usePutApi<string[], string[]>()

  const agentManager = ref<AgentManagerListItem>({
    id: '',
    name: ''
  })

  const success = ref(false)

  const attach = async (agentIds: string[]) => {
    if (!agentManager.value) {
      return false
    }
    await put(`v1/admin/managers/${agentManager.value?.id}/agents/attach`, agentIds)
    if (status.value === 200 && response.value) {
      success.value = true
    }
  }

  return {
    agentManager,
    loading,
    response,
    status,
    error,
    errors,
    success,
    attach,
  }
})
