<template>
  <div
    ref="el"
    v-if="props.modelValue"
  >
    <q-intersection
      transition="scale"
      once
    >
      <div
        class="FormFieldList"
      >
        <q-table
          v-model:selected="selected"
          class="full-width"
          style="max-height: 40vh; margin-top: -3px"
          :rows="fields"
          :loading="loading"
          :columns="columns"
          :pagination="{rowsPerPage: 0}"
          separator="horizontal"
          row-key="id"
          selection="multiple"
          virtual-scroll
          no-data-label="нет данных"
          hide-bottom
          @row-click="onRowClick"
        >
          <template #body-selection="scope">
            <q-checkbox
              v-model="selected"
              :val="scope.row"
            />
          </template>
          <template #body-cell-title="props">
            <q-td :props="props">
              {{ props.row.title }}
            </q-td>
          </template>
          <template #body-cell-code="props">
            <q-td :props="props">
              {{ props.row.code }}
            </q-td>
          </template>
          <template #body-cell-type="props">
            <q-td :props="props">
              {{ props.row.type }}
            </q-td>
          </template>
          <template #body-cell-length="props">
            <q-td :props="props">
              {{ props.row.length }}
            </q-td>
          </template>
        </q-table>
        <div class="row justify-between bg-white q-pa-sm q-gutter-col-md">
          <div class="col">
            <q-input
              v-model="name"
              :loading="loading"
              style="min-width: 300px"
              label="Поиск по названию"
              clearable
              outlined
              hide-hint
              hide-bottom-space
              dense
              @change="updateFilter"
              @clear="updateFilter"
            >
              <template #prepend>
                <q-icon name="svguse:icons.svg?2#app-search" />
              </template>
            </q-input>
          </div>
          <div class="action-buttons  q-ml-md">
            <q-btn
              class="q-mr-md"
              size="16px"
              padding="sm lg"
              label="Отмена"
              unelevated
              no-caps
              :disable="loading"
              @click="cancel"
            />
            <q-btn
              padding="sm lg"
              size="16px"
              color="blue-7"
              label="Добавить"
              icon-right="svguse:icons.svg?2#app-plus"
              :loading="loading"
              unelevated
              no-caps
              @click="onSave"
            />
          </div>
        </div>
      </div>
    </q-intersection>
  </div>
</template>

<script setup lang="ts">
import {computed, onMounted, ref, watch} from 'vue'
import {withDefaults} from 'vue/dist/vue'
import {useFormItemAddFieldsList} from 'src/uses/FormEditor/useFormItemAddFieldsList'
import {FormItemField} from 'src/models/FormEditor/FormItemResponseData'
import {useFormItemAddFields} from 'src/uses/FormEditor/useFormItemAddFields'
import {QTableColumn} from 'src/models/Grid/GridColumnSettings'

const props = withDefaults(
  defineProps<{
    modelValue: boolean,
    sectionId: string,
    formId: string,
    beforeFieldId: string | undefined,
  }>(),
  {
    beforeFieldId: undefined,
  }
)
const emit = defineEmits<{
  (e: 'addFields', fields: FormItemField[]): void,
  (e: 'update:model-value', value: boolean): void,
  (e: 'change'): void,
}>()

const el = ref<HTMLDivElement>()

const formItemAddFieldsList = useFormItemAddFieldsList()
const {
  fields,
  loading,
  load
} = formItemAddFieldsList

const {
  add,
  status,
} = useFormItemAddFields()

const name = ref<string>('')
const realSelected = ref<FormItemField[]>([])
const selectedIds = computed(() => realSelected.value.map(item => item.id))

const selected = computed({
  get: () => {
    return fields.value
      .filter(field => selectedIds.value.includes(field.id || ''))
  },
  set: (items: FormItemField[]) => {
    const itemsIds = items
      .map(item => item.id)
    const idToDelete = fields.value
      .filter(field => !itemsIds.includes(field.id))
      .map(item => item.id)

    realSelected.value = [
      ...realSelected.value,
      ...items
    ]
      .filter((item, index, arr) => arr.map(item => item.id).indexOf(item.id) === index)
      .filter(item => !idToDelete.includes(item.id))
  }
})

const columns = ref<QTableColumn[]>([
  {
    name: 'title',
    field: 'title',
    required: true,
    label: 'Наименование',
    align: 'left',
    classes: 'text-no-wrap',
    sortable: false,
  },
  {
    name: 'code',
    field: 'code',
    required: true,
    label: 'Код поля',
    align: 'left',
    classes: 'text-no-wrap',
    sortable: false,
  },
  {
    name: 'type',
    field: 'type',
    required: true,
    label: 'Тип поля',
    align: 'left',
    classes: 'text-no-wrap',
    sortable: false,
  },
  {
    name: 'length',
    field: 'length',
    required: true,
    label: 'Длина',
    align: 'left',
    classes: 'text-no-wrap',
    sortable: false,
  },
])

const onRowClick = (event: Event, row: FormItemField) => {
  const index = selected.value.findIndex(f => f.id === row.id)

  if (index === -1) {
    row.is_new_field = true
    selected.value.push(row)

    return
  }

  selected.value.splice(index, 1)
}

const updateFilter = async () => {
  await load(props.sectionId, name.value || '')
}

const onSave = async () => {
  await add(props.formId, props.sectionId, realSelected.value, props.beforeFieldId)
  if (status.value === 200) {
    realSelected.value = []
    emit('change')
  }
}

const cancel = () => {
  realSelected.value = []
  emit('addFields', [])
  emit('update:model-value', false)
}

onMounted(async () => {
  realSelected.value = []
  await load(props.sectionId, '')
})

watch(
  selected,
  () => {
    emit('addFields', realSelected.value)
  },
  {
    immediate: true,
  }
)
</script>

<style lang="scss">
.FormFieldList {
  box-shadow: 1px 4px 8px 0px #2632384D;
  .q-table {
    border-spacing: 0!important;
  }

  .q-table__container {
    min-height: 300px;
    background: #EEEEEE !important;
  }
  .q-table th, .q-table td {
    padding: 10px 3px !important;
  }
  thead {
    tr:first-child th {
      background: #EEEEEE !important;
      top: 0;
      position: sticky;
      z-index: 1;
    }

    td {
      border-radius: 0;
    }

  }

  .q-table__bottom {
    display: none;
  }
}
</style>
