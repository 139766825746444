<template>
  <q-header
    v-if="$q.platform.is.mobile"
    class="text-grey-10 LoginHeader"
    style="background: #FAFAFA; border-bottom: 0 !important;"
  >
    <q-toolbar>
      <div class="full-width">
        <slot name="default" />
      </div>
    </q-toolbar>
  </q-header>
  <slot
    v-else
    name="default"
  />
</template>

<script setup lang="ts">
import {useQuasar} from 'quasar'

const $q = useQuasar()
</script>
