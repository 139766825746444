import {usePostApi} from 'src/uses/Api/usePostApi'
import {PushNotificationRegisterTokenPayload} from 'src/models/Push/PushNotificationRegisterTokenPayload'

export const useClearBadge = () => {
  const {
    loading,
    post,
    status,
    response,
    errors,
  } = usePostApi<unknown, PushNotificationRegisterTokenPayload>()

  const clear = async () => {
    await post('application/v1/clear-badge')
  }

  return {
    errors,
    response,
    status,
    loading,
    clear,
  }
}
