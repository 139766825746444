import {usePutApi} from 'src/uses/Api/usePutApi'

export const useApplicationFavorite = () => {
  const {
    put,
    response,
    status,
    loading,
    error,
    errors,
  } = usePutApi<unknown, unknown>()

  const favorite = async (
    id: string,
    add = true,
  ) => {
    await put(
      `v1/order/${id}/favorite/${add ? 'add' : 'delete'}`,
    )
  }

  return {
    put,
    response,
    status,
    loading,
    error,
    errors,
    favorite
  }
}
