import {useDialog} from 'src/uses/Dialogs/useDialog'
import {ApplicationSubStatusEnum} from 'src/models/Applications/ApplicationSubStatusEnum'
import {Ref, ref} from 'vue'
import {ChangeStatusPayload} from 'src/models/Status/ChangeStatusPayload'
import {useBankRequestSign} from 'src/stores/Status/useBankRequestSign'
import {useHeaderDataFromStore} from 'src/uses/Applications/ControlCenter/useHeaderDataFromStore'
import {ControlCenterHeader} from 'src/models/Applications/ControlCenter/ControlCenterHeader'
import {useOnReleaseSend} from 'stores/Status/useOnReleaseSend'

export const useBankRequestSignAndOnReleaseSend = (payload: Ref<ChangeStatusPayload | undefined>, realChangeStatus: () => Promise<void>) => {
  const {
    dialog: bankRequestSignAndOnReleaseSendDialog,
    show: showBankRequestSignAndOnReleaseSendDialog,
    hide: hideBankRequestSignAndOnReleaseSendDialog,
  } = useDialog()

  const bankRequestSign = useBankRequestSign(payload, realChangeStatus)
  const onReleaseSend = useOnReleaseSend(payload, realChangeStatus)
  const {
    getHeaderData
  } = useHeaderDataFromStore()

  const headerData = ref<ControlCenterHeader | undefined>()

  const needShowDialog = async (currentSubStatus: ApplicationSubStatusEnum) => {
    if (!payload.value) {
      return false
    }

    headerData.value = await getHeaderData(payload.value.id)
    if (!headerData.value) {
      return false
    }

    return await bankRequestSign.needShowDialog(currentSubStatus)
      && onReleaseSend.needShowDialog()
  }

  const onBankRequestSignAndOnReleaseSend = async (signWasRequested: boolean) => {
    if (!payload.value) {
      return
    }

    await bankRequestSign.onSaveBankRequestSign(signWasRequested)
    hideBankRequestSignAndOnReleaseSendDialog()
  }

  return {
    bankRequestSignAndOnReleaseSendDialog,
    showBankRequestSignAndOnReleaseSendDialog,
    hideBankRequestSignAndOnReleaseSendDialog,
    needShowDialog,
    onBankRequestSignAndOnReleaseSend,
    isOnlyRegistry: onReleaseSend.isOnlyRegistry,
    isOnlyDelivery: onReleaseSend.isOnlyDelivery,
    isRegistryAndDelivery: onReleaseSend.isRegistryAndDelivery,
  }
}
