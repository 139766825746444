<template>
  <q-dialog
    v-model="bankRequestSignAndOnReleaseSendDialog"
  >
    <q-card
      class="q-mt-md bg-blue-1"
      style="min-width: 400px; max-width: 800px; border-radius: 4px !important;"
    >
      <q-card-section class="bg-blue-grey-10 text-white row items-center q-py-lg">
        <div class="rounded-borders q-pa-sm bg-blue-grey-9">
          <q-icon
            name="svguse:icons.svg?2#app-information-circle"
            size="sm"
            color="orange-6"
          />
        </div>
        <div
          v-if="step === 1"
          class="col q-pl-sm text-white"
        >
          Для размещения гарантии необходимо указать адрес
        </div>
        <div
          v-if="step === 2"
          class="col q-pl-sm text-white"
        >
          Для размещения гарантии необходимо указать реестр
        </div>
        <div
          v-if="step === 3"
          class="col q-pl-sm text-white"
        >
          Мы заметили, что вы загрузили в заявку неподписанные документы
        </div>
      </q-card-section>
      <q-stepper
        v-model="step"
        header-nav
        class="modal-stepper"
        color="primary"
        animated
      >
        <q-step
          v-if="isRegistryAndDelivery() || isOnlyDelivery()"
          :name="1"
          title="Укажите адрес доставки"
          icon="svguse:icons.svg?2#app-cog"
          :done="step > 1"
        >
          <q-card-section>
            <delivery-address-modal-form
              ref="deliveryAddressRef"
              v-if="lastStatusPayload"
              :id="lastStatusPayload.id"
              :is-only-delivery-modal="false"
              :can-change-issue-bg-form="true"
            />
          </q-card-section>
        </q-step>
        <q-step
          v-if="isRegistryAndDelivery() || isOnlyRegistry()"
          :name="2"
          title="Укажите реестр"
          icon="svguse:icons.svg?2#app-cog"
          :disable="step < 2"
          :done="step > 2"
        >
          <q-card-section>
            <registry-modal-form
              v-if="lastStatusPayload"
              :id="lastStatusPayload.id"
              :loading="false"
            />
          </q-card-section>
        </q-step>
        <q-step
          :name="3"
          title="Подпишите документы"
          icon="svguse:icons.svg?2#app-cog"
          :disable="step < 3"
          :done="step > 3"
        >
          <q-card-section>
            <bank-request-sign-form
              v-if="lastStatusPayload"
              ref="bankRequestSignRef"
              :app-id="lastStatusPayload.id"
            />
          </q-card-section>
        </q-step>
      </q-stepper>
      <q-card-actions
        class="row justify-end q-py-md"
        style="background: #D5EAFA;"
      >
        <q-btn
          v-close-popup
          size="16px"
          padding="sm lg"
          label="Отмена"
          unelevated
          no-caps
        />
        <q-btn
          v-if="step === 1"
          label="Сохранить"
          color="primary"
          unelevated
          no-caps
          :loading="deliveryAddressForm.loading "
          :disable="deliveryAddressForm.validation.$invalid"
          @click="onAddressSave"
        />

        <q-btn
          v-if="step === 2"
          label="Сохранить"
          color="primary"
          unelevated
          no-caps
          @click="onRegistrySave"
        />

        <q-btn
          v-if="step === 3 && bankRequestSignRef"
          :disable="needSign === undefined"
          padding="sm lg"
          size="16px"
          color="blue-7"
          label="Сохранить"
          unelevated
          no-caps
          @click="statusStore.onBankRequestSignAndOnReleaseSend(needSign)"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
import {useStatusStore} from 'src/stores/Status/useStatusStore'
import {storeToRefs} from 'pinia'
import AgreementConfirmEditableFields from 'src/components/Applications/AgreementConfirmEditableFields.vue'
import {computed, ref, watch} from 'vue'
import BankRequestSignForm from 'src/components/Applications/BankRequestSignForm.vue'
import DeliveryAddressModalForm from 'components/Applications/onReleaseSendModal/DeliveryAddressModalForm.vue'
import {useDeliveryAddressFormStore} from 'stores/Applications/Forms/useDeliveryAddressFormStore'
import {useImportantFields} from 'src/uses/Applications/Form/useImportantFields'
import {
  useApplicationControlCenterItemStore
} from 'stores/Applications/ControlCenter/useApplicationControlCenterItemStore'
import RegistryModalForm from 'components/Applications/onReleaseSendModal/RegistryModalForm.vue'
import {useRegistryFormStore} from 'stores/Applications/Forms/useRegistryFormStore'

const statusStore = useStatusStore()
const {
  bankRequestAndIsOnlyDelivery: isOnlyDelivery,
  bankRequestAndIsOnlyRegistry: isOnlyRegistry,
  bankRequestAndIsRegistryAndDelivery: isRegistryAndDelivery,
} = statusStore
const {
  bankRequestSignAndOnReleaseSendDialog,
  lastStatusPayload,
} = storeToRefs(statusStore)

const deliveryAddressForm = useDeliveryAddressFormStore('')()

const deliveryAddressRef = ref<typeof AgreementConfirmEditableFields>()
const bankRequestSignRef = ref<typeof BankRequestSignForm>()

const needSign = computed(() => !bankRequestSignRef.value ? undefined : bankRequestSignRef.value.needSign)

const step = ref(1)

const onRegistrySave = () => {
  if (!lastStatusPayload.value) {
    step.value = 3
    return
  }
  const registryForm = useRegistryFormStore(lastStatusPayload.value?.id || '')()

  lastStatusPayload.value = registryForm.getRequestData(lastStatusPayload.value)
  registryForm.$dispose()
  registryForm.$reset()
  step.value = 3
}

const onAddressSave = async () => {
  const deliveryAddressForm = useDeliveryAddressFormStore(lastStatusPayload.value?.id || '')()
  const applicationControlCenterItemStore = useApplicationControlCenterItemStore(lastStatusPayload.value?.id || '')()
  const {
    item,
  } = storeToRefs(applicationControlCenterItemStore)
  const {
    issueMailHardCopyField,
  } = useImportantFields(item)

  if (!!issueMailHardCopyField.value && issueMailHardCopyField.value.value) {
    await deliveryAddressForm.sendDeliveryAddress()

    if (deliveryAddressForm.success) {
      deliveryAddressForm.$dispose()
      deliveryAddressForm.$reset()
      step.value = isRegistryAndDelivery() ? 2 : 3
    }

    return
  }

  step.value = isRegistryAndDelivery() ? 2 : 3
}

const initStep = () => {
  step.value = isRegistryAndDelivery() || isOnlyDelivery() ? 1 : 2
}

watch(
  bankRequestSignAndOnReleaseSendDialog,
  () => {
    initStep()
  }
)
</script>

<style lang="scss">
.modal-stepper {
  &.q-stepper {
    background: $blue-1;
    .q-stepper__tab.col-grow {
      //flex: 0 0 ;
    }
    .q-stepper__step-inner {
      padding: 0;
    }
    .title {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 16px;
      padding-bottom: 30px;
      padding-top: 10px;
    }
    .q-stepper__header {
      max-width: 100%;
    }
  }
}
</style>
