import {defineStore, storeToRefs} from 'pinia'
import {computed, ref} from 'vue'
import {LoginResponseData} from 'src/models/User/Login/LoginResponseData'
import {useLocalStorage} from 'src/uses/useLocalStorage'
import moment from 'moment'
import {useRefreshTokenStore} from 'src/stores/Login/useRefreshTokenStore'
import {useImpersonateStore} from 'src/stores/useImpersonateStore'
import {usePostApi} from 'src/uses/Api/usePostApi'
import {useLoadingStore} from 'stores/useLoadingStore'
import {useRouter} from 'vue-router'
import {useBankFilterStore} from 'stores/Banks/useBankFilterStore'

export const useTokenStore = defineStore('token', () => {
  const {
    data,
    getFromStorage,
    setToStorage,
  } = useLocalStorage<LoginResponseData>('token')

  const {
    post,
  } = usePostApi(true)

  const {showLoading, hideLoading} = useLoadingStore()

  const tokenData = ref<LoginResponseData | null>(null)
  const refreshTokenStore = useRefreshTokenStore()
  const refreshTimeout = ref()
  const impersonateStore = useImpersonateStore()

  const banksStore = useBankFilterStore()
  const {banks} = storeToRefs(banksStore)

  const router = useRouter()

  const logout = async () => {
    showLoading()

    if (impersonateStore.impersonateEmail) {
      impersonateStore.clearImpersonate()
      hideLoading()
      document.location.reload()
      return
    }

    await post('logout')

    banks.value = []

    tokenData.value = null
    setToStorage(tokenData.value)

    await router.push({name: 'login'})
    hideLoading()
  }

  const setToken = (data: LoginResponseData) => {
    const dataToSet = {
      ...data,
      expired: Number(data?.expired),
    }

    if (!dataToSet?.dateTime) {
      dataToSet.dateTime = moment()
    }

    tokenData.value = dataToSet
    setToStorage(tokenData.value)
    setRefreshTimeout()
  }

  const setRefreshTimeout = () => {
    if (!tokenData.value?.dateTime || !tokenData.value?.expired) {
      return
    }

    const duration = tokenData.value.dateTime
      .add(tokenData.value.expired, 'seconds')
      .diff(moment())
      - 50000

    clearTimeout(refreshTimeout.value)
    refreshTimeout.value = setTimeout(
      () => {
        refreshTokenStore.doRefreshToken()
      },
      duration > 0 ? duration : 0
    )
  }

  const token = computed(() => tokenData.value?.token)

  const updateFromStorage = () => {
    getFromStorage()
    if (!data.value) {
      return
    }

    setToken({
      ...data.value,
      dateTime: moment(data.value?.dateTime || undefined)
    })
  }

  updateFromStorage()
  setTimeout(updateFromStorage, 1)

  return {
    tokenData,
    token,
    logout,
    setToken,
  }
})
