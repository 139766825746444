<template>
  <filter-pin
    v-if="!onlyPinned || isPinned('number')"
    v-model="pins.number"
    :no-pin="props.onlyPinned || props.ignorePins"
  >
    <q-input
      v-model="filters.number"
      :loading="props.loading"
      autocomplete="new-password"
      label="Поиск по номеру заявки"
      clearable
      outlined
      hide-hint
      hide-bottom-space
      dense
      @change="onSubmit"
      @clear="onSubmit"
    >
      <template #prepend>
        <q-icon name="svguse:icons.svg?2#app-search"/>
      </template>
    </q-input>
  </filter-pin>

  <filter-pin
    v-if="!onlyPinned || isPinned('banks')"
    v-model="pins.banks"
    :no-pin="props.onlyPinned || props.ignorePins"
  >
    <bank-filter
      v-model="filters.banks"
      :loading="loading"
      :product-type="ProductEnum.KIK"
      @change="onSubmit"
    />
  </filter-pin>

  <filter-pin
    v-if="!onlyPinned || isPinned('client')"
    v-model="pins.client"
    :no-pin="props.onlyPinned || props.ignorePins"
  >
    <q-input
      v-model="filters.client"
      :loading="props.loading"
      autocomplete="new-password"
      label="Поиск по ИНН или наименованию"
      clearable
      outlined
      hide-hint
      hide-bottom-space
      dense
      @change="onSubmit"
      @clear="onSubmit"
    >
      <template #prepend>
        <q-icon name="svguse:icons.svg?2#app-search" />
      </template>
    </q-input>
  </filter-pin>

  <filter-pin
    v-if="isVisibleFilterByAgent && (!onlyPinned || isPinned('agent'))"
    v-model="pins.agent"
    :no-pin="props.onlyPinned || props.ignorePins"
  >
    <q-input
      v-model="filters.agent"
      :loading="props.loading"
      autocomplete="new-password"
      label="Поиск по ИНН / названию агента"
      clearable
      outlined
      hide-hint
      hide-bottom-space
      dense
      @change="onSubmit"
      @clear="onSubmit"
    >
      <template #prepend>
        <q-icon name="svguse:icons.svg?2#app-search" />
      </template>
    </q-input>
  </filter-pin>

  <filter-pin
    v-if="(!onlyPinned || isPinned('new_request'))"
    v-model="pins.new_request"
    :no-pin="props.onlyPinned || props.ignorePins"
  >
    <q-toggle
      v-model="filters.new_request"
      label="Только сделки с допзапросом"
      @click="onSubmit"
    />
  </filter-pin>
</template>

<script setup lang="ts">
import {storeToRefs} from 'pinia'
import FilterPin from 'src/components/Grid/FilterPin.vue'
import {useApplicationsGridKikFiltersStore} from 'stores/Applications/Grid/useApplicationsGridKikFiltersStore'
import BankFilter from 'components/Applications/ApplicationsGrid/Filters/BankFilter.vue'
import {ProductEnum} from 'src/models/Applications/ProductEnum'
import {computed} from 'vue'
import {useProfileStore} from 'stores/Login/useProfileStore'

const props = defineProps<{
  loading: boolean,
  onlyPinned: boolean,
  ignorePins: boolean,
}>()

const emit = defineEmits<{
  (e: 'update:filters'): void,
}>()

const profileStore = useProfileStore()
const {
  isAdmin,
  isAgentManager,
  isJuniorAdmin,
  isBankEmployee,
} = profileStore
const {profile} = storeToRefs(profileStore)

const filtersStore = useApplicationsGridKikFiltersStore()
const {isPinned} = filtersStore
const {filters, pins} = storeToRefs(filtersStore)

const isVisibleFilterByAgent = computed(() => {
  return isAdmin()
    || isJuniorAdmin()
    || isAgentManager()
    || (isBankEmployee() && !profile.value?.is_hide_personal_data)
})

const onSubmit = () => {
  emit('update:filters')
}
</script>

<style>
.filterSumInput .q-field__label {
  top: 13px!important;
}

</style>
