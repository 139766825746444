<template>
  <q-btn
    size="sm"
    icon="svguse:icons.svg?2#app-trash"
    color="blue-grey-4"
    round
    flat
    @click="open"
  />
  <q-dialog
    v-model="dialog"
  >
    <q-card class="modal-blue-background">
      <q-card-section>
        <div class="text-h6 q-mt-xs">Удаление записи {{ recordId }}</div>
      </q-card-section>
      <q-card-section>
        <info-banner
          text="Вы уверены?"
          caption="Удаленную запись невозможно будет восстановить."
          icon="svguse:icons.svg?2#app-exclamation"
          color="red"
        />
      </q-card-section>
      <q-card-actions
        align="right"
        class="text-red q-mb-sm"
      >
        <q-btn
          v-close-popup
          :loading="loading"
          label="Отмена"
          class="q-pr-lg"
          style="padding: 13px 25px;"
          unelevated
          flat
          no-caps
          text-color="black"
        />
        <q-btn
          :loading="loading"
          label="Удалить"
          color="red"
          style="padding: 13px 25px;"
          unelevated
          icon-right="svguse:icons.svg?2#app-trash"
          no-caps
          @click="handleSubmit()"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
import {computed} from 'vue'
import {useDialog} from 'src/uses/Dialogs/useDialog'
import {storeToRefs} from 'pinia'
import InfoBanner from 'components/InfoBanner.vue'
import {useListingsItemRecordDeleteStore} from 'stores/Listings/ListingsRecord/useListingsItemRecordDeleteStore'

const emit = defineEmits<{
  (e: 'change'): void,
}>()

const props = defineProps<{
  itemId: string
  recordId: number
}>()
const itemId = computed(() => props.itemId)
const recordId = computed(() => props.recordId)
const {dialog, show, hide} = useDialog()

const listingsItemRecordDeleteStore = useListingsItemRecordDeleteStore()

const {
  loading,
  status,
} = storeToRefs(listingsItemRecordDeleteStore)

const {
  deleteListingsItemRecord
} = listingsItemRecordDeleteStore

const open = async () => {
  show()
}

const handleSubmit = async () => {
  await deleteListingsItemRecord(itemId.value, recordId.value)
  if (status.value === 200) {
    hide()
    emit('change')
  }
}

</script>
