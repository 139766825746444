<template>
  <pagination-component
    class="q-mb-md"
    :pagination="gridPagination"
    :per-page-options="perPageOptions"
    @request="onRequest"
    @refresh="load"
  />
  <div class="row q-mb-md">
    <div class="col-8">
      <listings-grid-filters
        :loading="loading"
        @update:filters="updateFilters"
      />
    </div>
    <div class="col-4 text-right">
      <listings-create-update-modal
        @success="updateFilters"
      />
    </div>
  </div>
  <div class="ListingsGrid--table">
    <q-table
      v-model:pagination="gridPagination"
      class="sticky-header-table"
      :rows="items"
      :columns="columns"
      :loading="loading"
      :rows-per-page-options="perPageOptions"
      separator="none"
      row-key="inn"
      hide-bottom
      wrap-cells
      @request="onRequest"
    >
      <template #body-cell-title="props">
        <q-td :props="props">
          <router-link
            :to="`/listings/${props.row.id}/records`"
            style="white-space: normal"
          >
            {{ props.row.title }}
          </router-link>
        </q-td>
      </template>
      <template #body-cell-count="props">
        <q-td :props="props">
          {{ props.row.count }}
        </q-td>
      </template>
      <template #body-cell-updated="props">
        <q-td :props="props">
          {{ props.row.updated }}
        </q-td>
      </template>
      <template #body-cell-actions="props">
        <q-td style="width: 160px" :props="props">
          <listings-preview-file-modal :id="props.row.id" />
          <listings-create-update-modal
            :item="props.row"
            @success="updateFilters"
          />
          <listing-delete-modal
            :title="props.row.title"
            :item-id="props.row.id"
            @change="updateFilters"
          />
          <q-btn
            size="sm"
            color="blue-grey-4"
            icon="svguse:icons.svg?2#app-download"
            round
            flat
            @click="downloadListingsFile(props.row.id)"
          />
        </q-td>
      </template>
    </q-table>
  </div>
</template>

<script setup lang="ts">
import {storeToRefs} from 'pinia'
import {onBeforeMount, onBeforeUnmount} from 'vue'
import {useGridSettings} from 'src/uses/Grid/useGridSettings'
import {ListingsGridColumns} from 'src/components/Listings/ListingsGridColumns'
import PaginationComponent from 'src/components/PaginationComponent.vue'
import {GridSortingEnum} from 'src/models/Grid/GridSortingEnum'
import {GridPagination, RequestProp} from 'src/models/Grid/Pagination'
import ListingsGridFilters from 'components/Listings/ListingsGridFilters.vue'
import {useListingsGridFiltersStore} from 'stores/Listings/useListingsGridFiltersStore'
import {useListingsGridStore} from 'stores/Listings/useListingsGridStore'
import {useListingsGridSortStore} from 'stores/Listings/useListingsGridSortStore'
import ListingsCreateUpdateModal from 'components/Listings/ListingsCreateUpdateModal.vue'
import ListingDeleteModal from 'components/Listings/ListingDeleteModal.vue'
import {useListingsDownloadFile} from 'src/uses/Listings/useListingsDownloadFile'
import ListingsPreviewFileModal from 'components/Listings/ListingsPreviewFileModal.vue'

const filtersStore = useListingsGridFiltersStore()
const listingsGridStore = useListingsGridStore()
const {
  items,
  gridPagination,
  perPageOptions,
  loading,
} = storeToRefs(listingsGridStore)

const {
  load,
  onRequestPagination,
  setPage,
} = listingsGridStore

const listingsGridSortStore = useListingsGridSortStore()
const {
  sorting,
} = storeToRefs(listingsGridSortStore)

const {columns} = useGridSettings('listings-grid', ListingsGridColumns)

const {
  downloadListingsFile
} = useListingsDownloadFile()

onBeforeMount(async () => {
  await load()
})

onBeforeUnmount(() => {
  listingsGridStore.$reset()
  listingsGridStore.$dispose()
  filtersStore.$reset()
  filtersStore.$dispose()
})

const onRequest = ({pagination}: RequestProp) => {
  sorting.value.direction = pagination.descending ? GridSortingEnum.DESC : GridSortingEnum.ASC
  sorting.value.sort = pagination.sortBy || ''
  onRequestPagination(pagination as GridPagination)
  load()
}

const updateFilters = () => {
  setPage(1)
  load()
}

</script>

<style lang="scss">
.ListingsGrid--table {
  margin: 0 -24px -24px;
  background: $blue-1;
  padding: 0 24px 24px;

  table {
    position: relative;

    tr {
      td {
        width: 150px;

        &:first-child {
          width: auto;
          white-space: normal;
        }
      }
    }
  }

  .createButton {
    .icon {
      background: white;
      border-radius: 6px;
      position: absolute;
      right: 10px;
      line-height: 0;
    }

    .q-btn {
      border-radius: 6px;
    }
  }
}
</style>
