import {GridColumnEditableSettings} from 'src/models/Grid/GridColumnSettings'
import {UserRoleEnum} from 'src/models/User/UserRoleEnum'

export const ApplicationsGridColumns = (): GridColumnEditableSettings[] => [
  {
    name: 'date',
    field: '',
    required: true,
    label: '№, дата создания',
    align: 'left',
    classes: 'text-no-wrap',
    visible: true,
    editable: false,
    order: 1,
    sortableColumn: true,
  },
  {
    field: '',
    name: 'inn',
    required: true,
    label: 'Название/ИНН',
    align: 'left',
    visible: true,
    editable: true,
    order: 2,
    sortableColumn: true,
  },
  {
    field: '',
    name: 'rnt',
    required: true,
    label: 'Закупка',
    align: 'left',
    visible: true,
    editable: true,
    order: 3,
    sortableColumn: true,
  },
  {
    field: '',
    name: 'period',
    required: true,
    label: 'Срок действия',
    align: 'left',
    visible: true,
    editable: true,
    classes: 'text-no-wrap',
    order: 4,
  },
  {
    field: '',
    name: 'guarantee_total',
    required: true,
    label: 'Сумма гарантии',
    align: 'left',
    visible: true,
    editable: true,
    classes: 'text-no-wrap',
    order: 5,
    sortableColumn: true,
  },
  {
    field: '',
    name: 'release_date',
    required: true,
    label: 'Выпуск',
    align: 'left',
    visible: true,
    editable: true,
    classes: 'text-no-wrap',
    order: 6,
    sortableColumn: true,
    roles: [UserRoleEnum.AgentManager]
  },
  {
    field: '',
    name: 'bank',
    label: 'Банк',
    required: true,
    align: 'left',
    visible: true,
    editable: true,
    order: 7,
  },
  {
    field: '',
    name: 'status',
    label: 'Статус',
    required: true,
    align: 'left',
    visible: true,
    editable: true,
    order: 8,
  },
  {
    field: '',
    name: 'users',
    label: 'Инфо',
    required: true,
    align: 'left',
    visible: true,
    editable: true,
    order: 9,
    roles: [
      UserRoleEnum.Admin,
      UserRoleEnum.JuniorAdmin,
      UserRoleEnum.Operator,
      UserRoleEnum.AgentManager,
      UserRoleEnum.BankEmployee,
    ],
  },
  {
    field: '',
    name: 'docs',
    label: 'Файлы',
    required: true,
    align: 'left',
    visible: true,
    editable: true,
    order: 10,
    roles: [
      UserRoleEnum.Admin,
      UserRoleEnum.Operator,
      UserRoleEnum.AgentManager,
      UserRoleEnum.Employee,
      UserRoleEnum.HeadEmployee,
      UserRoleEnum.Agent,
    ],
  },
  {
    field: '',
    name: 'employee',
    label: 'Сотрудник',
    required: true,
    align: 'left',
    visible: false,
    editable: true,
    order: 11,
    roles: [
      UserRoleEnum.HeadEmployee,
      UserRoleEnum.Agent,
    ],
  },
  {
    field: '',
    name: 'actions',
    label: 'Действия с заявкой',
    required: true,
    align: 'right',
    visible: true,
    editable: false,
    classes: 'text-no-wrap',
    order: 12,
  },
]
