<template>
  <tr v-if="props.title">
    <td
      colspan="3"
      class="FinanceTableBlockHeader"
    >
      {{ props.title }}
    </td>
  </tr>
</template>

<script setup lang="ts">
const props = defineProps<{
  title: string,
}>()
</script>
