<template>
  <div>
    <q-banner :class="{'setting-banner-info q-pa-lg': true,  'setting-banner-info-mobile': $q.platform.is.mobile}">
      <template #avatar>
        <div class="icon-application-info">
          <q-icon
            name="svguse:icons.svg?2#app-light-bulb"
            size="sm"
            color="orange"
          />
        </div>
      </template>
      <div>Вы можете отредактировать данные вашего профиля и сменить пароль</div>
    </q-banner>
    <div class="q-pa-lg profile-setting">
      <q-form
        v-if="!!profile"
        class="white-bg-field"
        @submit="handleSubmit"
      >
        <div class="row q-col-gutter-md">
          <div class="col-4 col-xs-12">
            <q-input
              v-model="profileSettingsData.fio"
              class="field-with-top-label"
              :loading="loading"
              label="ФИО"
              :error-message="getErrorMessage('fio')"
              :error="hasError('fio')"
              outlined
              hide-hint
              hide-bottom-space
              dense
              @focus="clearError('fio')"
            />
          </div>
          <div class="col-4 col-xs-12 relative-position">
            <q-input
              v-model="profile.email"
              class="field-with-top-label"
              :loading="loading"
              label="E-mail"
              :error-message="getErrorMessage('email')"
              :error="hasError('email')"
              outlined
              hide-bottom-space
              dense
              disable
              @focus="clearError('email')"
            />
            <q-icon
              class="field-tooltip-icon"
              size="sm"
              color="orange"
              name="svguse:icons.svg?2#app-exclamation-circle-bold"
            >
              <q-tooltip>Для смены электронной почты <br> обратитесь в службу поддержки</q-tooltip>
            </q-icon>
          </div>
          <div class="col-4 col-xs-12 relative-position">
            <q-input
              v-model="profile.phone"
              class="field-with-top-label"
              :loading="loading"
              type="tel"
              label="Телефон"
              mask="+# (###) ### - ## - ##"
              :error-message="getErrorMessage('phone')"
              :error="hasError('phone')"
              unmasked-value
              fill-mask
              outlined
              hide-bottom-space
              dense
              disable
              @focus="clearError('phone')"
            />

            <q-icon
              class="field-tooltip-icon"
              size="sm"
              color="orange"
              name="svguse:icons.svg?2#app-exclamation-circle-bold"
            >
              <q-tooltip>Для смены телефона обратитесь <br> в службу поддержки</q-tooltip>
            </q-icon>
          </div>
          <div class="col-4 col-xs-12">
            <q-input
              v-model="profileSettingsData.old_password"
              class="field-with-top-label"
              :loading="loading"
              label="Старый пароль"
              :error-message="getErrorMessage('oldPassword')"
              :error="hasError('oldPassword')"
              :type="passwordType ? 'password' : 'text'"
              outlined
              hide-hint
              hide-bottom-space
              dense
              @focus="clearError('oldPassword')"
            >
              <template #append>
                <q-icon
                  class="cursor-pointer"
                  :name="passwordType ? 'svguse:icons.svg?2#app-eye-off' : 'svguse:icons.svg?2#app-eye'"
                  @click="passwordType = !passwordType"
                />
              </template>
            </q-input>
          </div>
          <div class="col-4 col-xs-12">
            <q-input
              v-model="profileSettingsData.new_password"
              class="field-with-top-label"
              :loading="loading"
              label="Новый пароль"
              :error-message="getErrorMessage('newPassword')"
              :error="hasError('newPassword')"
              :type="newPasswordType ? 'password' : 'text'"
              outlined
              hide-hint
              hide-bottom-space
              dense
              @focus="clearError('newPassword')"
            >
              <template #append>
                <q-icon
                  class="cursor-pointer"
                  :name="newPasswordType ? 'svguse:icons.svg?2#app-eye-off' : 'svguse:icons.svg?2#app-eye'"
                  @click="newPasswordType = !newPasswordType"
                />
              </template>
            </q-input>
          </div>
          <div class="col-4 col-xs-12">
            <q-input
              v-model="profileSettingsData.confirm_password"
              class="field-with-top-label"
              :loading="loading"
              label="Повторите новый пароль"
              :error-message="getErrorMessage('confirmPassword')"
              :error="hasError('confirmPassword')"
              :type="confirmPasswordType ? 'password' : 'text'"
              outlined
              hide-hint
              hide-bottom-space
              dense
              @focus="clearError('confirmPassword')"
            >
              <template #append>
                <q-icon
                  class="cursor-pointer"
                  :name="confirmPasswordType ? 'svguse:icons.svg?2#app-eye-off' : 'svguse:icons.svg?2#app-eye'"
                  @click="confirmPasswordType = !confirmPasswordType"
                />
              </template>
            </q-input>
          </div>
        </div>

        <div class="row q-pt-lg">
          <div class="col-12">
            <q-card-actions class="row justify-end">
              <q-btn
                :class="{'full-width': $q.platform.is.mobile}"
                :loading="loading"
                label="Сохранить изменения"
                color="primary"
                icon-right="svguse:icons.svg?2#app-check"
                padding="13px 24px"
                unelevated
                no-caps
                @click="handleSubmit"
              />
            </q-card-actions>
          </div>
        </div>
        <div
          v-if="success"
          class="col-12 q-mt-sm"
        >
          <span class="text-green-7">Данные успешно сохранены</span>
        </div>
      </q-form>
    </div>
  </div>
</template>

<script setup lang="ts">

import {storeToRefs} from 'pinia'
import {onBeforeUnmount, ref} from 'vue'
import {useProfileSettingsStore} from 'src/stores/Profile/Settings/useProfileSettingsStore'
import {useQuasar} from 'quasar'

const $q = useQuasar()

const profileSettingsStore = useProfileSettingsStore()

const {
  profileSettingsData,
  loading,
  success,
  profile,
  errors,
} = storeToRefs(profileSettingsStore)

const {save} = profileSettingsStore

const confirmPasswordType = ref(true)
const newPasswordType = ref(true)
const passwordType = ref(true)

const hasError = (key: string) => {
  return !!errors.value && !!errors.value[key]
}

const getErrorMessage = (key: string) => {
  return hasError(key) && errors.value
    ? errors.value[key]
    : undefined
}

const clearError = (key: string) => {
  if (!hasError(key) || !errors.value || !errors.value[key]) {
    return
  }

  errors.value[key] = ''
}

onBeforeUnmount(() => {
  profileSettingsStore.$reset()
  profileSettingsStore.$dispose()
})

const handleSubmit = async () => {
  await save()
}
</script>

<style lang="scss">
.profile-setting {
  background: $blue-1;
  .q-card__actions {
    padding: 0;
  }
  .q-field__messages {
    color: orange;
  }
}
.setting-banner-info {
  width: 100%;
  background: #263238;
  color: #ffffff;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  &.setting-banner-info-mobile {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .icon-application-info {
    background: #37474F;
    border-radius: 5px;
    padding: 10px;
  }
}
</style>
