import {ProductFields} from 'src/models/Applications/Form/ProductFields'
import {FieldsCodesEnum} from 'src/models/FieldsCodesEnum'

export const useProductFields = () => {
  const getFieldByCode = (fields: ProductFields, code: FieldsCodesEnum | string) => {
    const all = []
    all.push(...fields.all)
    fields.banks.forEach(b => all.push(...b.fields))

    return all.find(f => f.code === code)
  }

  return {
    getFieldByCode,
  }
}
