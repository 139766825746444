<template>
  <div class="col-12">
    <div class="row">
      <div
        v-if="headerInfo && documents && item"
        class="col-sm col-xs-12 bannerMainContent"
      >
        <div v-if="!headerInfo.payment && !isBgFormHasEcp">
          Подготовлен
          <q-btn
            class="text-link-button"
            label="проект"
            icon-right="svguse:icons.svg?2#app-download"
            size="xs"
            @click="downloadDocument(getDocument('BG_FORM'), ApplicationFormDocumentGroupEnum.offer)"
          />
          и
          <q-btn
            class="text-link-button"
            label="счет"
            icon-right="svguse:icons.svg?2#app-download"
            size="xs"
            @click="downloadDocument(getDocument('INVOICE'), ApplicationFormDocumentGroupEnum.offer)"
          />
          <div v-if="!headerInfo.integration.enabled">
            Для отправки заявки на выпуск необходимо согласовать проект банковской гарантии
          </div>
          <div v-if="headerInfo.integration.enabled">
            Для отправки заявки на выпуск необходимо согласовать и подписать проект банковской гарантии
            <template v-if="isGpbExpressById(props.headerInfo.bank.id)">
              и согласие на присоединение к правилам
            </template><br>
            Для подписания проекта направьте
            <copy-to-clipboard-component
              :text="headerInfo.client?.sign_url || ''"
            >
              <q-btn
                class="text-link-button"
                label="ссылку"
                icon="svguse:icons.svg?2#app-document-duplicate"
                size="xs"
              />
            </copy-to-clipboard-component>
            клиенту
          </div>
        </div>
        <div v-if="!headerInfo.payment && isBgFormHasEcp">
          Клиент подписал форму БГ
          <template v-if="isGpbExpressById(props.headerInfo.bank.id) && props.headerInfo.can_send_to_release">
            и согласие на присоединение к правилам
          </template>
          по заявке.
          <br>
          <template v-if="isGpbExpressById(props.headerInfo.bank.id) && !props.headerInfo.can_send_to_release">
            Необходимо подписать согласие на присоединение к правилам для отправки на выпуск
          </template>
          <template v-else>
            Заявку можно отправить на выпуск
          </template>
          , гарантия будет выпущена после отправки на выпуск и поступления оплаты от клиента
          <q-btn
            class="text-link-button"
            label="счет"
            icon-right="svguse:icons.svg?2#app-download"
            size="xs"
            @click="downloadDocument(getDocument('INVOICE'), ApplicationFormDocumentGroupEnum.offer)"
          />
        </div>
        <div v-if="headerInfo.payment && isBgFormHasEcp && !incorrectPayment">
          Клиент подписал БГ форму и оплатил комиссию. Вы можете направить заявку на выпуск
        </div>
        <div v-if="isBg1397WithoutCustomerForm && headerInfo.payment && !isBgFormHasEcp && !incorrectPayment">
          Клиент оплатил комиссию вы можете отправить заявку на выпуск
        </div>
        <div v-if="!isBg1397WithoutCustomerForm && headerInfo.payment && !isBgFormHasEcp && !incorrectPayment">
          Клиент оплатил счет. Для выпуска необходимо подписать проект банковской гарантии по
          <q-btn
            class="text-link-button"
            label="ссылке"
            icon="svguse:icons.svg?2#app-document-duplicate"
            size="xs"
            @click="copyToClipboard(headerInfo.client?.sign_url || '')"
          />
        </div>
        <div v-if="headerInfo.payment && !isBgFormHasEcp && incorrectPayment">
          По заявке поступила оплата не соответствующая выставленному счету.<br>
          Для отправки заявки на выпуск необходимо:<br>
          <span v-if="!isBg1397WithoutCustomerForm">
            1. Подписать согласованную форму гарантии по
            <q-btn
              class="text-link-button"
              label="ссылке"
              icon="svguse:icons.svg?2#app-document-duplicate"
              size="xs"
              @click="copyToClipboard(headerInfo.client?.sign_url || '')"
            />
            <br>
            2.
          </span>
          Отредактировать тариф по заявке с учётом суммы оплаты клиентом или написать
          письмо о возврате разницы комиссии в банк
          <q-btn
            class="text-link-button"
            label="письмо о возврате разницы комиссии в банк"
            icon-right="svguse:icons.svg?2#app-download"
            size="xs"
            target="_blank"
            href="docs/template_mail_return_commission.docx"
          />
          письмо должно быть подписано ЭЦП клиента и направлено вашему куратору
        </div>
        <div v-if="headerInfo.payment && isBgFormHasEcp && incorrectPayment">
          По заявке поступила оплата не соответствующая выставленному счету.<br>
          Для отправки на выпуск необходимо отредактировать тариф по заявке с учётом суммы оплаты клиентом
          или написать
          <q-btn
            class="text-link-button"
            label="письмо о возврате разницы комиссии в банк"
            icon-right="svguse:icons.svg?2#app-download"
            size="xs"
            target="_blank"
            href="docs/template_mail_return_commission.docx"
          />
          письмо должно быть подписано ЭЦП клиента
          и направлено вашему куратору
        </div>
      </div>
      <div
        v-if="headerInfo && headerInfo.can_send_to_release"
        class="bannerInfoBtnWrap"
      >
        <application-control-center-send-on-release-action :header-data="props.headerInfo" />
      </div>
    </div>
    <div class="row">
      <div
        v-if="headerInfo && documents && (!headerInfo.payment || (headerInfo.payment && !incorrectPayment)) && !isBgFormHasEcp"
        class="col-12 q-mt-md"
      >
        <application-form-upload
          :document-type="ApplicationFormDocumentGroupEnum.offer"
          :documents="documents[ApplicationFormDocumentGroupEnum.offer] || []"
          input-text="или перетащите файлы БГ формы с правками сюда для отправки на согласование"
          ignore-uploaded-check
          @file:selected="onFileSelected"
        />
        <q-linear-progress
          v-if="document && document.progress"
          class="q-mt-xs"
          :value="document.progress.loaded / document.progress.total"
          color="green-14"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {computed, ref, watchEffect} from 'vue'
import {ControlCenterHeader} from 'src/models/Applications/ControlCenter/ControlCenterHeader'
import ApplicationControlCenterSendOnReleaseAction
  from 'src/components/Applications/ApplicationControlCenter/ApplicationControlCenterSendOnReleaseAction.vue'
import {
  ApplicationFormDocument,
  ApplicationFormDocumentGroupEnum,
  ApplicationFormDocuments
} from 'src/models/Applications/Form/ApplicationFormResponseData'
import ApplicationFormUpload from 'src/components/Applications/Form/Documents/ApplicationFormUpload.vue'
import { copyToClipboard } from 'quasar'
import CopyToClipboardComponent from 'src/components/CopyToClipboard.vue'
import {
  useApplicationControlCenterItemStore
} from 'src/stores/Applications/ControlCenter/useApplicationControlCenterItemStore'
import {storeToRefs} from 'pinia'
import {DocumentsCodesEnum} from 'src/models/DocumentsCodesEnum'
import {useImportantFields} from 'src/uses/Applications/Form/useImportantFields'
import {useBank} from 'src/uses/useBank'

const props = defineProps<{
  headerInfo: ControlCenterHeader,
  documents: ApplicationFormDocuments | null,
}>()

const {
  isGpbExpressById,
} = useBank()

const document = ref<ApplicationFormDocument>()
const isBgFormHasEcp = ref(false)
const isCustomerForm = ref(false)
const headerInfo = computed(() => props.headerInfo)
const documents = computed(() => props.documents)
const incorrectPayment = computed(() => {
  return props.headerInfo.payment !== bank.value?.commission_amount
})

const offerDocuments = computed<ApplicationFormDocument[]>(() => {
  if (!props.documents) {
    return []
  }
  const documents = props.documents[ApplicationFormDocumentGroupEnum.offer]
  if (!documents) {
    return []
  }

  return documents
})

const isBg1397WithoutCustomerForm = computed(() => {
  const bgSum = bgSumField.value?.value as number | 0

  return bg1397Field.value?.value && bgSum < 3000000 && !isCustomerForm.value
})

const applicationControlCenterItemStore = useApplicationControlCenterItemStore(headerInfo.value.id)()
const {
  uploadDocument,
  downloadDocument,
} = applicationControlCenterItemStore
const {
  banks,
  item,
} = storeToRefs(applicationControlCenterItemStore)
const {
  bg1397Field,
  bgSumField,
} = useImportantFields(item)

const bank = computed(() => {
  if (!banks.value.length) {
    return undefined
  }

  return banks.value[0]
})

const onFileSelected = async (file: File, newDocument: ApplicationFormDocument) => {
  document.value = newDocument
  document.value.code = DocumentsCodesEnum.CUSTOMER_FORM
  document.value.file = file
  await uploadDocument(document.value)
  document.value = undefined
}

const getDocument = (docCode: string) => {
  return offerDocuments.value.find(doc => {
    return doc.code === docCode
  })
}

const checkBgFormHasEcp = () => {
  if (!documents.value?.offer) {
    return false
  }
  const bgForm = documents.value?.offer
    .find(item => {
      return item.code === DocumentsCodesEnum.BG_FORM
    })

  if (!bgForm) {
    return false
  }

  return !!bgForm.sign_file_name
}

const checkCustomerForm = () => {
  if (!documents.value?.offer) {
    return false
  }
  const customerForm = documents.value?.offer
    .find(item => {
      return item.code === DocumentsCodesEnum.CUSTOMER_FORM && item.id
    })

  return !!customerForm
}

watchEffect(() => {
  if (props.documents) {
    isBgFormHasEcp.value = checkBgFormHasEcp()
    isCustomerForm.value = checkCustomerForm()
  }
})
</script>
