<template>
  <applications-grid-filters-resume-dialog
    :top="controlHeight"
  >
    <div class="row items-end justify-center">
      <q-btn
        color="grey-2"
        class="text-blue-grey-4"
        icon-right="svguse:icons.svg?2#app-adjustments"
        padding="xs"
        unelevated
        no-caps
        @click="filterDialogShow"
      />
      <q-btn
        v-if="clearable"
        color="grey-2"
        class="text-blue-grey-4 q-ml-xs"
        icon-right="svguse:icons.svg?2#app-trash"
        size="14px"
        padding="5px 7px"
        unelevated
        square
        no-caps
        @click="onClear"
      />
      <div>
        <application-grid-filters-expansion
          :loading="loading"
          :ignore-pins="true"
          @update:filters="updateFilters"
        />
      </div>
      <q-btn
        class="q-ml-sm text-primary bg-grey-2"
        label="Наверх"
        padding="xs sm"
        unelevated
        no-wrap
        no-caps
        @click="scrollTop"
      >
        <q-icon
          name="svguse:icons.svg?2#app-arrow-up"
          color="primary"
          size="15px"
        />
      </q-btn>
    </div>
  </applications-grid-filters-resume-dialog>
  <application-grid-filters-dialog
    v-model="filerDialog"
    :loading="loading"
    :ignore-pins="ignorePins"
    @update:filters="updateFilters"
  />
  <div
    class="q-px-none"
    style="margin-top: -20px;"
  >
    <div ref="controlRef">
      <div class="row">
        <div class="col">
          <q-btn
            color="blue-grey-4"
            icon="svguse:icons.svg?2#app-refresh"
            class="q-mr-sm"
            flat
            square
            dense
            @click="load"
          />
          <application-grid-product-toggle :loading="loading" />
        </div>
        <application-grid-toggle :loading="loading" />
      </div>
      <div class="q-px-lg q-py-md ApplicationsMultiGrid--control">
        <div class="q-gutter-y-sm">
          <div class="row no-wrap q-col-gutter-x-sm">
            <div v-if="filtersBtnNotInStatuses">
              <q-btn
                color="grey-2"
                class="text-blue-grey-4"
                icon-right="svguse:icons.svg?2#app-adjustments"
                padding="10px"
                unelevated
                no-caps
                flat
                @click="filterDialogShow"
              />
            </div>
            <div class="col white-bg-field">
              <application-grid-filters-expansion
                ref="filterExpansion"
                v-model="filerDialog"
                :class="{'q-mt-sm': true, 'q-mb-sm': filterExpansion && filterExpansion.isShowFilters}"
                :ignore-pins="ignorePins"
                :loading="loading"
                @update:filters="updateFilters"
              />
              <div
                v-if="hasPins && !ignorePins"
                class="row q-gutter-sm q-mb-md"
              >
                <application-grid-filters
                  :loading="loading"
                  :ignore-pins="ignorePins"
                  only-pinned
                  @update:filters="updateFilters"
                />
              </div>
            </div>
            <div v-if="filtersBtnNotInStatuses">
              <q-btn
                v-if="clearable"
                color="grey-2"
                class="text-blue-grey-4 q-ml-xs"
                icon-right="svguse:icons.svg?2#app-trash"
                padding="7px"
                unelevated
                no-caps
                flat
                @click="onClear"
              />
            </div>
          </div>
          <div class="row no-wrap q-col-gutter-x-sm">
            <div v-if="filtersBtnInStatuses">
              <q-btn
                color="grey-2"
                class="text-blue-grey-4"
                icon-right="svguse:icons.svg?2#app-adjustments"
                padding="7px"
                unelevated
                no-caps
                flat
                @click="filterDialogShow"
              />
            </div>
            <div class="col">
              <application-grid-statuses @update:filters="updateFilters" />
            </div>
            <div v-if="filtersBtnInStatuses">
              <q-btn
                v-if="clearable"
                color="grey-2"
                class="text-blue-grey-4 q-ml-xs"
                icon-right="svguse:icons.svg?2#app-trash"
                padding="7px"
                unelevated
                no-caps
                flat
                @click="onClear"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="ApplicationsMultiGrid--table">
      <div class="ApplicationsMultiGrid--table--expandControl row no-wrap items-center">
        <q-btn
          icon="svguse:icons.svg?2#folder-close"
          size="md"
          color="primary"
          padding="3px"
          flat
          @click="expandOff"
        >
          <q-tooltip>
            Свернуть все
          </q-tooltip>
        </q-btn>
        <div class="line" />
        <q-btn
          icon="svguse:icons.svg?2#folder-open"
          size="md"
          color="primary"
          padding="3px"
          flat
          @click="expandOn"
        >
          <q-tooltip>
            Раскрыть все
          </q-tooltip>
        </q-btn>
      </div>
      <q-table
        v-model:pagination="gridPagination"
        class="sticky-header-table"
        :rows="items"
        :columns="filteredVisibleColumns"
        :loading="loading"
        :rows-per-page-options="perPageOptions"
        separator="none"
        row-key="id"
        hide-pagination
        wrap-cells
        @request="onRequest"
      >
        <template
          v-if="!loading"
          #no-data
        >
          <template v-if="!isDefaultFilterValue">
            По вашему запросу ничего не найдено. Попробуйте изменить фильтры
          </template>
          <template v-else>
            У вас пока нет ни одной заявки
          </template>
        </template>
        <template #header-cell="props">
          <q-th
            :props="props"
            class="grid-column-header"
          >
            <applications-grid-th
              :item="props.col"
              @update:sorting="updateSorting"
            />
          </q-th>
        </template>
        <template #body="props">
          <q-tr
            :class="{'preExpanded': isExpanded(props.row.id)}"
            :props="props"
          >
            <q-td
              auto-width
            >
              <div style="width: 110px;" />
              <div
                :class="{'q-px-md q-py-sm bg-white cursor-pointer expandBtn': true, 'text-blue-7': isExpanded(props.row.id), 'text-blue-grey-4': !isExpanded(props.row.id)}"
                @click="toggleExpand(props.row.id)"
              >
                {{ isExpanded(props.row.id) ? 'Свернуть' : 'Раскрыть'}}
                <q-icon
                  :name="isExpanded(props.row.id) ? 'svguse:icons.svg?2#app-chevron-double-up' : 'svguse:icons.svg?2#app-chevron-double-down'"
                  size="xs"
                />
              </div>
            </q-td>
            <q-td
              :props="props"
              key="date"
            >
              <application-multi-grid-number
                :item="props.row"
                :only-text="ApplicationSubStatusEnum.archive === props.row.sub_status"
              />
            </q-td>
            <q-td
              :props="props"
              key="inn"
            >
              <application-grid-inn :item="props.row.orders[0]" />
            </q-td>
            <q-td
              :props="props"
              key="sum"
            >
              <money-component :model-value="props.row.guarantee_total" />
            </q-td>
            <q-td
              :props="props"
              key="rnt"
            >
              <application-grid-only-rnt :item="props.row.orders[0]" />
            </q-td>
            <q-td
              :props="props"
              key="law"
            >
              {{ getLawLabel(props.row.orders[0].obligation_type) }}
            </q-td>
            <q-td
              :props="props"
              key="provisionType"
            >
              {{ getProvisionTypeLabel(props.row.orders[0].provision_type) }}
            </q-td>
            <q-td
              :props="props"
              key="banks"
            >
              <div
                class="row no-wrap"
                style="max-width: 200px; height: 50px;"
              >
                <div
                  v-for="(order, index) in props.row.orders"
                  :key="index"
                  :style="`min-width: ${getBankWidth(props.row.orders.length)}px; z-index: ${props.row.orders.length - index};`"
                >
                  <img
                    :src="order.bank.logo"
                    :alt="order.bank.title_short"
                    style="max-width: 50px; max-height: 50px; position: absolute;"
                  >
                </div>
              </div>
            </q-td>
            <q-td
              :props="props"
              key="status"
            >
              <application-multi-grid-status :item="props.row" />
            </q-td>
            <q-td
              :props="props"
              key="users"
            >
              <application-grid-users
                :operator="props.row.operator"
                :agent-manager="props.row.agent_manager"
                :agent="props.row.agent"
              />
            </q-td>
          </q-tr>
          <q-tr
            v-show="isExpanded(props.row.id)"
            class="expanded"
            :props="props"
          >
            <q-td
              colspan="100%"
              style="padding: 0;"
            >
              <div class="expandedTableFixTopBorder">
                <q-table
                  class="full-width MultiGridInnerTable"
                  :rows="props.row.orders"
                  :columns="visibleOrdersColumns"
                  :pagination="{rowsPerPage: 0}"
                  separator="none"
                  row-key="id"
                  hide-pagination
                >
                  <template #body-cell-bank="innerProps">
                    <q-td :props="innerProps">
                      <img
                        :src="innerProps.row.bank.logo"
                        :alt="innerProps.row.bank.title_short"
                        style="max-width: 100px; max-height: 50px;"
                      >
                    </q-td>
                  </template>
                  <template #body-cell-date="innerProps">
                    <q-td :props="innerProps">
                      <application-grid-number
                        :item="innerProps.row"
                        :onlyText="ApplicationSubStatusEnum.archive === innerProps.row.sub_status"
                      />
                    </q-td>
                  </template>
                  <template #body-cell-period="innerProps">
                    <q-td :props="innerProps">
                      <application-grid-period :item="innerProps.row" />
                    </q-td>
                  </template>
                  <template #body-cell-sum="innerProps">
                    <q-td :props="innerProps">
                      <application-grid-sum
                        :item="innerProps.row"
                        @tariff-refreshed="refreshById(props.row.id as never)"
                      />
                    </q-td>
                  </template>
                  <template #body-cell-status="innerProps">
                    <q-td :props="innerProps">
                      <div class="row no-wrap">
                        <application-grid-status
                          class="col"
                          :item="innerProps.row"
                        />
                        <application-grid-messages-action
                          :item="innerProps.row"
                          @model-closed="refreshById(props.row.id as never)"
                        />
                      </div>
                    </q-td>
                  </template>
                  <template #body-cell-users="innerProps">
                    <q-td :props="innerProps">
                      <application-grid-users :item="innerProps.row" />
                    </q-td>
                  </template>
                  <template #body-cell-docs="innerProps">
                    <q-td :props="innerProps">
                      <application-grid-docs :item="innerProps.row" />
                    </q-td>
                  </template>
                  <template #body-cell-release_date="innerProps">
                    <q-td :props="innerProps">
                      <application-grid-release-date :item="innerProps.row" />
                    </q-td>
                  </template>
                  <template #body-cell-actions="innerProps">
                    <q-td :props="innerProps">
                      <application-grid-actions
                        :item="innerProps.row"
                        @change="refreshById(props.row.id as never)"
                      />
                    </q-td>
                  </template>
                  <template #body-cell-padding="innerProps">
                    <q-td
                      :props="innerProps"
                      style="width: 142px;min-width: 142px;overflow: hidden;white-space: nowrap;"
                    />
                  </template>
                </q-table>
              </div>
            </q-td>
          </q-tr>
        </template>
      </q-table>
      <pagination-component
        class="q-mb-md"
        :pagination="gridPagination as GridPagination"
        :per-page-options="perPageOptions"
        :can-refresh="false"
        @request="onRequest"
        @refresh="load"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import ApplicationGridFilters from 'components/Applications/ApplicationsGrid/ApplicationGridFilters.vue'
import ApplicationGridFiltersExpansion from 'components/Applications/ApplicationsGrid/ApplicationGridFiltersExpansion.vue'
import ApplicationGridStatuses from 'components/Applications/ApplicationsGrid/ApplicationGridStatuses.vue'
import PaginationComponent from 'components/PaginationComponent.vue'
import {GridPagination} from 'src/models/Grid/Pagination'
import ApplicationsGridTh from 'components/Applications/ApplicationsGrid/ApplicationsGridTh.vue'
import ApplicationGridFiltersDialog from 'components/Applications/ApplicationsGrid/ApplicationGridFiltersDialog.vue'
import {computed, onBeforeMount, ref, watchEffect} from 'vue'
import {useApplicationsGrid} from 'components/Applications/ApplicationsGrid/Grid/useApplicationsGrid'
import {ApplicationSubStatusEnum} from 'src/models/Applications/ApplicationSubStatusEnum'
import ApplicationMultiGridNumber from 'components/Applications/ApplicationsGrid/Columns/ApplicationMultiGridNumber.vue'
import ApplicationGridInn from 'components/Applications/ApplicationsGrid/Columns/ApplicationGridInn.vue'
import ApplicationGridOnlyRnt from 'components/Applications/ApplicationsGrid/Columns/ApplicationGridOnlyRnt.vue'
import MoneyComponent from 'components/MoneyComponent.vue'
import {useLaw} from 'src/uses/Applications/useLaw'
import {useProvisionType} from 'src/uses/Applications/useProvosoinType'
import ApplicationMultiGridStatus from 'components/Applications/ApplicationsGrid/Columns/ApplicationMultiGridStatus.vue'
import {UserRoleEnum} from 'src/models/User/UserRoleEnum'
import ApplicationGridNumber from 'components/Applications/ApplicationsGrid/Columns/ApplicationGridNumber.vue'
import ApplicationGridPeriod from 'components/Applications/ApplicationsGrid/Columns/ApplicationGridPeriod.vue'
import ApplicationGridSum from 'components/Applications/ApplicationsGrid/Columns/ApplicationGridSum.vue'
import ApplicationGridMessagesAction
  from 'components/Applications/ApplicationsGrid/Actions/ApplicationGridMessagesAction.vue'
import ApplicationGridStatus from 'components/Applications/ApplicationsGrid/Columns/ApplicationGridStatus.vue'
import ApplicationGridUsers from 'components/Applications/ApplicationsGrid/Columns/ApplicationGridUsers.vue'
import ApplicationGridActions from 'components/Applications/ApplicationsGrid/Actions/ApplicationGridActions.vue'
import ApplicationGridDocs from 'components/Applications/ApplicationsGrid/Columns/ApplicationGridDocs.vue'
import {useLocalStorage} from 'src/uses/useLocalStorage'
import ApplicationGridToggle from 'components/Applications/ApplicationsGrid/Grid/ApplicationGridToggle.vue'
import {useProfileStore} from 'stores/Login/useProfileStore'
import {storeToRefs} from 'pinia'
import ApplicationGridReleaseDate from 'components/Applications/ApplicationsGrid/Columns/ApplicationGridReleaseDate.vue'
import ApplicationsGridFiltersResumeDialog
  from 'components/Applications/ApplicationsGrid/Grid/ApplicationsGridFiltersResumeDialog.vue'
import {GridColumnEditableSettings} from 'src/models/Grid/GridColumnSettings'
import ApplicationGridProductToggle
  from 'components/Applications/ApplicationsGrid/Grid/ApplicationGridProductToggle.vue'

const {
  isDefaultFilterValue,
  hasPins,
  clearable,
  ignorePins,

  items,
  gridPagination,
  perPageOptions,
  loading,

  load,
  refreshById,

  filerDialog,
  filterDialogShow,

  controlRef,
  controlHeight,
  filteredVisibleColumns,

  onRequest,
  updateFilters,
  updateSorting,
  onClear,
} = useApplicationsGrid(true)

const {getLawLabel} = useLaw()
const {getProvisionTypeLabel} = useProvisionType()

const {profile} = storeToRefs(useProfileStore())

const {
  data,
  setToStorage,
  getFromStorage,
} = useLocalStorage<number[]>('multi-grid-expand')

const filterExpansion = ref<typeof ApplicationGridFiltersExpansion>()

const filtersBtnInStatuses = computed(() => {
  return filterExpansion.value
    && !filterExpansion.value.isShowFilters
    && !(hasPins.value && !ignorePins.value)
})

const filtersBtnNotInStatuses = computed(() => {
  return filterExpansion.value
    && (
      filterExpansion.value.isShowFilters
      ||
      (hasPins.value && !ignorePins.value)
    )
})

const ordersColumns = computed<GridColumnEditableSettings[]>(() => {
  return [
    {
      field: '',
      name: 'padding',
      label: '',
      required: true,
      align: 'left',
      visible: true,
      editable: true,
      order: 0,
    },
    {
      field: '',
      name: 'bank',
      label: 'Банк',
      required: true,
      align: 'left',
      visible: true,
      editable: true,
      order: 1,
    },
    {
      field: '',
      name: 'date',
      required: true,
      label: '№, дата создания',
      align: 'left',
      classes: 'text-no-wrap',
      visible: true,
      editable: false,
      order: 2,
      sortableColumn: true,
    },
    {
      field: '',
      name: 'period',
      required: true,
      label: 'Срок действия',
      align: 'left',
      visible: true,
      editable: true,
      classes: 'text-no-wrap',
      order: 3,
    },
    {
      field: '',
      name: 'sum',
      required: true,
      label: 'Сумма гарантии',
      align: 'left',
      visible: true,
      editable: true,
      classes: 'text-no-wrap',
      order: 4,
    },
    {
      field: '',
      name: 'release_date',
      required: true,
      label: 'Выпуск',
      align: 'left',
      visible: true,
      editable: true,
      classes: 'text-no-wrap',
      order: 5,
      sortableColumn: true,
      roles: [UserRoleEnum.AgentManager]
    },
    {
      field: '',
      name: 'status',
      label: 'Статус',
      required: true,
      align: 'left',
      visible: true,
      editable: true,
      order: 6,
    },
    {
      field: '',
      name: 'docs',
      label: 'Файлы',
      required: true,
      align: 'left',
      visible: true,
      editable: true,
      order: 7,
      roles: [
        UserRoleEnum.Admin,
        UserRoleEnum.Operator,
        UserRoleEnum.AgentManager,
        UserRoleEnum.Employee,
        UserRoleEnum.HeadEmployee,
        UserRoleEnum.Agent,
      ],
    },
    {
      field: '',
      name: 'actions',
      label: 'Действия с заявкой',
      required: true,
      align: 'right',
      visible: true,
      editable: false,
      classes: 'text-no-wrap',
      order: 8,
    },
  ]
})
const visibleOrdersColumns = computed(() => {
  if (!profile.value) {
    return []
  }

  return ordersColumns.value
    .filter(column => {
      return !column.roles
        || !column.roles.length
        || column.roles.includes((profile.value?.role as UserRoleEnum) || '')
    })
})

const expanded = ref<number[]>([])

const isExpanded = (id: number) => expanded.value.includes(id)

const getBankWidth = (count: number) => {
  const width = 200 / count

  return width > 50 ? 50 : width
}

const toggleExpand = (id: number) => {
  if (isExpanded(id)) {
    expanded.value = expanded.value
      .filter(expandedId => id !== expandedId)
  } else {
    expanded.value.push(id)
  }

  setToStorage(expanded.value)
}

const expandOff = () => {
  items.value
    .forEach(item => {
      expanded.value = expanded.value
        .filter(expandedId => item.id !== expandedId)
    })

  setToStorage(expanded.value)
}

const expandOn = () => {
  items.value
    .forEach(item => {
      if (isExpanded(item.id as number)) {
        return
      }
      expanded.value.push(item.id as number)
    })

  setToStorage(expanded.value)
}

const scrollTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  })
}

onBeforeMount(async () => {
  getFromStorage()
  await load()
})

watchEffect(() => {
  expanded.value = data.value || []
})
</script>

<style lang="scss" src="./ApplicationsMultiGrid.scss" />
