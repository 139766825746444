<template>
  <q-drawer
    v-model="rightDrawer"
    class="bg-transparent"
    side="right"
    :mini="rightMiniState"
    :width="drawerWidth"
    :breakpoint="650"
    show-if-above
    no-swipe-open
    no-swipe-close
    no-swipe-backdrop
    @click="onMenuRight"
  >
    <template #mini>
      <q-scroll-area class="fit">
        <div
          class="q-mt-sm relative-position"
          @click.stop
        >
          <slot
            name="mini"
            :mini-state="rightMiniState"
            :toggle="onMenuRight"
          />
        </div>
      </q-scroll-area>
    </template>

    <q-scroll-area class="fit">
      <div
        class="q-mt-sm q-mr-sm relative-position"
        @click.stop
      >
        <slot
          :mini-state="rightMiniState"
          :toggle="onMenuRight"
        />
      </div>
    </q-scroll-area>
  </q-drawer>
</template>

<script setup lang="ts">
import {useLayoutStore} from 'src/stores/useLayoutStore'
import {storeToRefs} from 'pinia'

const layoutStore = useLayoutStore()
const {
  rightMiniState,
  rightDrawer,
  drawerWidth,
} = storeToRefs(layoutStore)
const {
  onMenuRight,
} = layoutStore

</script>

<style lang="scss">
.q-drawer {
  background: transparent;
}
</style>
