<template>
  <delivery-address-info-banner
    v-if="props.canChangeIssueBgForm"
    :issue-form-field="issueFormField"
    :issue-mail-hard-copy-field="issueMailHardCopyField"
    :is-only-delivery-modal="props.isOnlyDeliveryModal"
    class="q-mb-lg"
  />
  <q-form>
    <div class="row q-px-lg q-col-gutter-lg white-bg-field">
      <field-component
        v-if="issueFormField && props.canChangeIssueBgForm"
        :key="String(issueFormField.value)"
        :field="issueFormField"
        :banks="banks"
        class="col-xs-12 col-sm-6"
        :ignore-readonly="issueMailHardCopyField?.value === false"
        ignore-width
        @update:value="onUpdate"
      />
      <field-component
        v-if="issueFormField && issueFormField.value === BgFormEnum.paper && issueMailHardCopyField && props.canChangeIssueBgForm"
        :key="String(issueMailHardCopyField.value)"
        :field="issueMailHardCopyField"
        :banks="banks"
        class="col-xs-12 col-sm-6"
        label="Требуется отправка БГ"
        :ignore-readonly="issueMailHardCopyField?.value === false"
        ignore-width
        @update:value="onUpdate"
      />
      <div class="col-12 q-mb-sm">
        <q-input
          v-model="deliveryAddressData.address"
          class="field-with-top-label"
          :loading="loading"
          :error-message="getErrorMessage('address')"
          :error="hasError('address')"
          :disable="issueMailHardCopyField?.value === false"
          label="Адрес доставки"
          outlined
          hide-hint
          hide-bottom-space
          dense
          @blur="validation.address.$validate()"
          @focus="clearError('address')"
        />
      </div>
      <div class="col-12">
        <div
          v-for="receiver of receiverOptions"
          :key="receiver.value"
          style="margin-left: -10px;"
        >
          <q-radio
            v-model="deliveryAddressData.receiver"
            :val="receiver.value"
            color="blue-7"
            :disable="issueMailHardCopyField?.value === false"
            checked-icon="svguse:icons.svg?2#app-radio-checked"
            unchecked-icon="svguse:icons.svg?2#app-radio"
          >
            <span class="text-grey-7">{{ receiver.label }}</span>
          </q-radio>
        </div>
        <div
          v-if="hasError('receiver')"
          class="receiver-validation-error"
        >
          {{ getErrorMessage('receiver') }}
        </div>
      </div>
      <div class="col-6 q-mb-sm">
        <q-input
          v-model="deliveryAddressData.name"
          class="field-with-top-label"
          :loading="loading"
          :error-message="getErrorMessage('name')"
          :error="hasError('name')"
          :disable="issueMailHardCopyField?.value === false"
          label="ФИО контактного лица"
          maxlength="300"
          outlined
          hide-hint
          hide-bottom-space
          dense
          @blur="validation.name.$validate()"
          @focus="clearError('name')"
        />
      </div>
      <div class="col-6 q-mb-sm">
        <q-input
          v-model="deliveryAddressData.phone"
          v-mask="'+7(9##) ###-##-##'"
          placeholder="+7(9__) ___-__-__"
          class="field-with-top-label"
          :loading="loading"
          :error-message="getErrorMessage('phone')"
          :error="hasError('phone')"
          :disable="issueMailHardCopyField?.value === false"
          label="Моб. телефон контактного лица"
          outlined
          hide-hint
          hide-bottom-space
          dense
          @blur="validation.phone.$validate()"
          @focus="clearError('phone')"
        />
      </div>
      <div class="col-12 q-mb-sm">
        <q-input
          v-model="deliveryAddressData.comment"
          class="field-with-top-label"
          :loading="loading"
          :disable="issueMailHardCopyField?.value === false"
          label="Комментарий (необязательно)"
          outlined
          hide-hint
          hide-bottom-space
          dense
        />
      </div>
    </div>
  </q-form>
</template>

<script setup lang="ts">
import {onBeforeMount} from 'vue'
import {useDeliveryAddressFormStore} from 'src/stores/Applications/Forms/useDeliveryAddressFormStore'
import {useValidation} from 'src/uses/useValidation'
import {storeToRefs} from 'pinia'
import {FormElementValue} from 'src/models/Form'
import {ApplicationFormField} from 'src/models/Applications/Form/ApplicationFormResponseData'
import FieldComponent from 'src/components/Fields/FieldComponent.vue'
import DeliveryAddressInfoBanner from 'src/components/Applications/onReleaseSendModal/DeliveryAddressInfoBanner.vue'
import {BgFormEnum} from 'src/models/Applications/BgFormEnum'

const props = defineProps<{
  id: string,
  isOnlyDeliveryModal?: boolean,
  canChangeIssueBgForm?: boolean
}>()

const deliveryAddressFormStore = useDeliveryAddressFormStore(props.id)()
const { updateMainFields } = deliveryAddressFormStore
const {
  deliveryAddressData,
  loading,
  validation,
  errors,
  banks,
  issueFormField,
  issueMailHardCopyField,
  receiverOptions
} = storeToRefs(deliveryAddressFormStore)

const {
  init,
} = deliveryAddressFormStore

const {
  getErrorMessage,
  hasError,
  clearError,
  clearAllErrors,
} = useValidation(validation, errors)

const onUpdate = (
  value: FormElementValue,
  field: ApplicationFormField,
) => {
  if (!issueMailHardCopyField.value) {
    clearAllErrors()
  }
  updateMainFields([{
    value,
    code: field.code,
  }])
}

defineExpose({
  issueMailHardCopyField,
})

onBeforeMount(async () => {
  await init()
})

</script>

<style lang="scss">
.receiver-validation-error {
  color: var(--q-negative);
  font-size: 11px;
}
</style>
