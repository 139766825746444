import {defineStore} from 'pinia'
import {useGridFilters} from 'src/uses/Grid/useGridFilters'
import {ref} from 'vue'
import {UserRoleEnum} from 'src/models/User/UserRoleEnum'
import {UserRoles} from 'src/models/User/UserRoleDescriptions'
import {UsersListFilter} from 'src/models/Profiles/Users/UsersListFilter'
import {UserStatuses} from 'src/models/User/UserStatusDescriptions'
import {UserStatusEnum} from 'src/models/User/UserStatusEnum'

const defaultFilters: UsersListFilter = {
  role: undefined,
  name: undefined,
  inn: undefined,
  status: undefined
}

export const useUsersListFiltersStore = defineStore('profiles-users-list-filters', () => {
  const roles = ref([
    {
      label: 'Все роли',
      value: undefined,
    },
    ...Object.values(UserRoleEnum)
      .map(role => {
        return {
          label: UserRoles.getUserRoleDescription(role),
          value: role,
        }
      }),
  ])

  const statuses = ref([
    {
      label: 'Все статусы',
      value: undefined,
    },
    ...Object.values(UserStatusEnum)
      .map(status => {
        return {
          label: UserStatuses.getUserStatusDescription(status),
          value: status,
        }
      }),
  ])

  const {
    filters,
    clearable,
    getFiltersQuery,
    clear,
  } = useGridFilters(defaultFilters)

  return {
    filters,
    clearable,
    getFiltersQuery,
    clear,
    roles,
    statuses,
  }
})
