import {defineStore} from 'pinia'
import {computed, Ref, ref, watch} from 'vue'
import useVuelidate from '@vuelidate/core'
import {usePutApi} from 'src/uses/Api/usePutApi'
import {useProfileStore} from 'src/stores/Login/useProfileStore'
import {ProfileSettings} from 'src/models/Profile/Settings/ProfileSettings'
import {useValidationRules} from 'src/uses/useValidationRules'

export const useProfileSettingsStore = defineStore('profile-Settings', () => {
  const profileStore = useProfileStore()
  const {profile} = profileStore

  const {
    put,
    response,
    status,
    loading,
    error,
    errors,
  } = usePutApi<never, ProfileSettings>()

  const {
    getRulesFromState,
  } = useValidationRules()

  const getFullName = () => {
    return (profile) ? `${profile.name?.first} ${profile.name?.middle} ${profile.name?.last}` : ''
  }

  const profileSettingsData = ref<ProfileSettings>({
    fio: getFullName(),
    confirm_password: '',
    new_password: '',
    old_password: '',
  })

  const success = ref(false)

  const rules = computed(() => {
    return {
      ...getRulesFromState(profileSettingsData.value)
    }
  })

  const validation = useVuelidate<Ref<ProfileSettings>>(rules, profileSettingsData)

  const save = async () => {
    success.value = false

    await put('v1/profile', profileSettingsData.value)
    if (status.value === 200 && response.value) {
      success.value = true
    }
  }

  watch(
    profileSettingsData,
    () => {
      success.value = false
    },
    {deep: true}
  )

  return {
    profileSettingsData,
    loading,
    response,
    status,
    error,
    errors,
    validation,
    success,
    profile,
    save,
  }
})
