<template>
  <q-dialog
    :model-value="dialog"
    @update:model-value="emit('update:dialog', $event)"
  >
    <q-card
      class="bg-blue-1"
      style="border-radius: 6px !important;"
    >
      <div class="text-h6 bg-white">
        <q-card-section class="row">
          <div class="col">
            {{ $t('Columns settings') }}
          </div>
          <q-btn
            color="grey-2"
            class="text-blue-grey-4"
            padding="8px 15px"
            unelevated
            no-wrap
            no-caps
            @click="setDefault"
          >
            По умолчанию
            <q-icon
              class="q-ml-sm"
              name="svguse:icons.svg?2#app-book-open"
              color="blue-grey-4"
            />
          </q-btn>
        </q-card-section>
      </div>
      <q-card-section class="q-pa-none">
        <div>
          <div
            v-for="(column, index) in sortedColumns()"
            :key="column.name + column.order"
            class="row items-center justify-center q-px-md"
            style="min-height: 50px; border-bottom: 1px solid #C7DFF1;"
          >
            <div>
              <q-btn
                :disable="index === 0"
                color="blue-grey-5"
                class="order-button"
                icon="svguse:icons.svg?2#app-chevron-up"
                size="12px"
                padding="6px"
                outline
                flat
                square
                @click="moveUp(column)"
              />
            </div>
            <div
              class="col"
              style="min-width: 300px;"
            >
              <q-toggle
                v-model="column.visible"
                :label="column.label || column.name"
                :disable="!column.editable"
              />
            </div>
            <div>
              <q-btn
                :disable="index === columns.length - 1"
                color="blue-grey-5"
                class="order-button"
                icon="svguse:icons.svg?2#app-chevron-down"
                size="12px"
                padding="6px"
                outline
                flat
                square
                @click="moveDown(column)"
              />
            </div>
          </div>
        </div>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
import {ref, watch, withDefaults} from 'vue'
import {GridColumnEditableSettings} from 'src/models/Grid/GridColumnSettings'

const props = withDefaults(
  defineProps<{
    modelValue: GridColumnEditableSettings[],
    dialog: boolean,
    default: GridColumnEditableSettings[],
  }>(), {
    dialog: false,
  }
)

const emit = defineEmits<{
  (e: 'update:model-value', columns: GridColumnEditableSettings[]): void,
  (e: 'update:dialog', value: boolean): void,
}>()

const columns = ref<GridColumnEditableSettings[]>([])

const sortedColumns = () => {
  return columns.value
    .sort((a, b) => a.order - b.order)
}

const setDefault = () => {
  columns.value = props.default
}

const moveUp = (column: GridColumnEditableSettings) => {
  const index = columns.value.findIndex(c => c.name === column.name)
  if (index === -1) {
    return
  }

  const order = columns.value[index].order
  columns.value[index].order = columns.value[index - 1].order
  columns.value[index - 1].order = order
}

const moveDown = (column: GridColumnEditableSettings) => {
  const index = columns.value.findIndex(c => c.name === column.name)
  if (index === -1) {
    return
  }

  const order = columns.value[index].order
  columns.value[index].order = columns.value[index + 1].order
  columns.value[index + 1].order = order
}

watch<GridColumnEditableSettings[], boolean>(
  props.modelValue,
  () => {
    columns.value = JSON.parse(JSON.stringify(props.modelValue))
  },
  {
    immediate: true,
    deep: true,
  }
)

watch<GridColumnEditableSettings[]>(
  columns,
  () => {
    emit('update:model-value', JSON.parse(JSON.stringify(columns.value)))
  },
  {
    deep: true,
  }
)
</script>
