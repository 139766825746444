<template>
  <div class="q-mb-lg">
    <div class="text-h6 q-mb-md">
      Основные параметры
    </div>
    <form-item-copy-delete-fields
      :form-id="props.formId"
      :section-id="clientCommon.id"
      :block-id="props.client.id"
      :can-copy="clientCommon.fields.length === 0"
      @change="updateFields"
    />
    <div class="q-py-lg">
      <form-item-fields-block-component
        :fields="clientCommon.fields"
        :section-id="clientCommon.id"
        :form-id="props.formId"
        @change="updateFields"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import {FormItemClientBlock} from 'src/models/FormEditor/FormItemResponseData'
import FormItemCopyDeleteFields from 'components/FormEditor/FormItemCopyDeleteFields.vue'
import {computed} from 'vue'
import FormItemFieldsBlockComponent from 'components/FormEditor/FormItemFieldsBlockComponent.vue'

const emit = defineEmits<{
  (e: 'updateFields'): void,
}>()

const props = defineProps<{
  client: FormItemClientBlock,
  formId: string,
}>()

const clientCommon = computed(() => props.client.client_common)

const updateFields = () => {
  emit('updateFields')
}

</script>
