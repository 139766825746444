import {computed, Ref} from 'vue'
import {ApplicationFormResponseData} from 'src/models/Applications/Form/ApplicationFormResponseData'
import {useImportantFields} from 'src/uses/Applications/Form/useImportantFields'

export const useApplicationFormSidebar = (item: Ref<ApplicationFormResponseData | undefined>) => {
  const {
    rntField,
    bgSumField,
    law,
    provisionType,
    startDate,
    endDate,
    countDays,
    kikSumField,
    kikMonthsField,
    kikType,
  } = useImportantFields(item)

  const rnt = computed(() => {
    return rntField.value ? rntField.value.value : undefined
  })

  const bgSum = computed(() => {
    return bgSumField.value ? bgSumField.value.value : undefined
  })

  const kikSum = computed(() => {
    return kikSumField.value ? kikSumField.value.value : undefined
  })

  const kikMonths = computed(() => {
    return kikMonthsField.value ? kikMonthsField.value.value : undefined
  })

  return {
    rnt,
    law,
    provisionType,
    startDate,
    endDate,
    countDays,
    bgSum,
    kikSum,
    kikMonths,
    kikType,
  }
}
