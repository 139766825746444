import {FormHelper} from 'src/helpers/FormHelper'
import {usePostApi} from 'src/uses/Api/usePostApi'
import {PassportRecognizeResponse} from 'src/models/PassportRecognizeResponse'

export const usePassportRecognize = () => {
  const {
    error,
    errors,
    post,
    status,
    response,
    loading,
    progressEvent,
    abort,
  } = usePostApi<PassportRecognizeResponse, unknown>()

  const recognize = async (file: File) => {
    await post(
      'v1/document/passport',
      FormHelper.getFormDataFromObject({
        file,
      })
    )
  }

  return {
    error,
    errors,
    post,
    status,
    response,
    loading,
    progressEvent,
    recognize,
    abort,
  }
}
