import {ApplicationGridFilters} from 'src/models/Applications/ApplicationGridFilters'
import {ProductEnum} from 'src/models/Applications/ProductEnum'

export const applicationGridDefaultFilters: ApplicationGridFilters = {
  agent: null,
  new_request: false,
  number: null,
  client: null,
  rnt: null,
  product: ProductEnum.EBG,
  banks: [],
  release_date_from: null,
  release_date_to: null,
  guarantee_total_from: null,
  guarantee_total_to: null,
  status: null,
  employees: []
}

export const applicationGridDefaultPins: Record<string, boolean> = {
  agent: false,
  new_request: true,
  number: true,
  client: true,
  rnt: true,
  product: true,
  banks: true,
  release_date_from: true,
  release_date_to: true,
  guarantee_total_from: true,
  guarantee_total_to: true,
  status: true,
  employees: true,
}
