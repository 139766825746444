<template>
  <q-btn
    size="md"
    label="Пример"
    no-caps
    style="min-width: 125px; height: 40px; background-color: #C7DFF1"
    @click="open"
  />
  <q-dialog
    v-model="dialog"
    full-width
  >
    <q-card
      style="max-width: 1200px!important;"
    >
      <q-card-section
        class="modal-title row q-py-md q-pl-lg q-mt-xs"
      >
        <div>Пример оформления рекламного блока</div>
      </q-card-section>
      <q-card-section class="q-pl-lg q-pr-lg">
        <div class="row q-col-gutter-x-lg">
          <div class="col-12 text-bold q-mb-md">
            Расположение блоков
          </div>
          <div style="width: 56%;">
            <q-img
              :ratio="16/11.7"
              src="images/portal_sign_right_position_banner_example.jpg"
              style="border-radius: 6px"
            />
          </div>
          <div style="width: 44%">
            <q-img
              :ratio="16/15.1"
              src="images/portal_sign_top_position_banner_example.jpg"
              style="border-radius: 6px"
            />
          </div>
        </div>
        <div class="row q-mt-lg">
          <div class="col-12 text-bold q-mb-md">
            Вертикальный баннер #1 распологается на странице справа
          </div>
          <div class="col-6">
            <q-img
              src="images/portal_sign_banner_params_view.png"
              style="border-radius: 6px"
            />
          </div>
        </div>
        <div class="row q-mt-lg">
          <div class="col-12 text-bold q-mb-md">
            Горизонтальный баннер #2 расположен в верхней части страницы
          </div>
          <div class="col-12">
            <q-img
              src="images/portal_sign_gorizontal_banner.jpg"
              style="border-radius: 6px; max-width: 1000px"
            />
          </div>
        </div>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
import {useDialog} from 'src/uses/Dialogs/useDialog'

const {dialog, show} = useDialog()

const open = async () => {
  show()
}

</script>
