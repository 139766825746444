<template>
  <q-btn
    v-if="!props.item"
    class="q-ml-md"
    style="padding: 12px 23px;"
    color="primary"
    icon-right="svguse:icons.svg?2#app-plus-1"
    label="Добавить справочник"
    no-caps
    @click="open"
  />
  <q-btn
    v-if="props.item"
    size="sm"
    color="blue-grey-4"
    icon="svguse:icons.svg?2#app-pencil-alt"
    round
    flat
    @click="open"
  />
  <q-dialog
    v-model="dialog"
  >
    <q-card
      class="q-mt-md bg-blue-1"
      style="min-width: 400px; max-width: 600px; border-radius: 4px !important;"
    >
      <q-card-section>
        <div class="modal-title q-pb-none">
          {{ item?.id ? 'Редактирование справочника' : 'Добавление справочника' }}
        </div>
      </q-card-section>
      <q-card-section class="white-bg-field">
        <q-form
          v-if="listingCreateData"
          style="max-height: 70vh; overflow: auto; margin: 0 -16px; padding: 0 16px;"
        >
          <div class="row q-col-gutter-md">
            <div class="col-12">
              <q-input
                v-model="listingCreateData.title"
                class="field-with-top-label"
                :loading="loading"
                :error-message="getErrorMessage('title')"
                :error="hasError('title')"
                label="Название справочника"
                outlined
                hide-hint
                hide-bottom-space
                dense
                @blur="validation.title.$validate()"
                @focus="clearError('title')"
              />
            </div>
            <div class="row q-col-gutter-md q-mt-sm">
              <div class="form-title col-12">Дополнительные настройки</div>
              <div class="text-caption col-12">Где участвует справочник?</div>
              <div class="col-xs-12 col-sm-6">
                <q-toggle
                  v-model="listingCreateData.stop_factors_enabled"
                  label="Стоп-факторы"
                />
              </div>
              <div class="col-xs-12 col-sm-6">
                <q-toggle
                  v-model="listingCreateData.calculator_enabled"
                  label="Калькулятор"
                />
              </div>
              <div class="col-xs-12 col-sm-6">
                <q-toggle
                  v-model="listingCreateData.tariff_enabled"
                  label="Тарифы"
                />
              </div>
              <div class="text-caption col-12">Свойства справочника</div>
              <div class="col-xs-12 col-sm-6">
                <q-toggle
                  v-model="listingCreateData.read_enabled"
                  label="Просмотр"
                />
              </div>
              <div class="col-xs-12 col-sm-6">
                <q-toggle
                  v-model="listingCreateData.edit_enabled"
                  label="Редактирование"
                />
              </div>
              <div class="col-xs-12 col-sm-6">
                <q-toggle
                  v-model="listingCreateData.delete_enabled"
                  label="Удаление"
                />
              </div>
            </div>
            <div class="row q-col-gutter-md q-mt-sm">
              <div class="form-title col-12">Загрузка файла</div>
              <div class="col-xs-12 col-sm-12">
                <div class="col-12">
                  <q-list
                    v-if="listingCreateData.file"
                    class="q-mt-sm q-mb-sm"
                  >
                    <q-item
                      class="news-document-item q-mt-xs"
                    >
                      <q-item-section avatar>
                        <q-icon
                          name="svguse:icons.svg?2#app-article-bold"
                          color="blue-grey-3"
                        />
                      </q-item-section>
                      <q-item-section class="overflow-hidden">{{ listingCreateData.file.name }}</q-item-section>
                      <q-item-section side>
                        <q-icon
                          class="document-download-icon cursor-pointer"
                          name="svguse:icons.svg?2#app-trash"
                          color="blue-gray-3"
                          @click="removeDocument()"
                        />
                      </q-item-section>
                    </q-item>
                  </q-list>
                  <file-select-component
                    v-else
                    class="fit"
                    accept=".csv"
                    :max-file-size="26214400"
                    @file:selected="onSelected"
                  />
                </div>
              </div>
            </div>
          </div>
        </q-form>
      </q-card-section>
      <q-card-actions
        class="row justify-end q-py-md"
        style="background: #D5EAFA;"
      >
        <q-btn
          v-close-popup
          size="16px"
          padding="sm lg"
          label="Отмена"
          :disable="loading"
          unelevated
          no-caps
        />
        <q-btn
          padding="sm lg"
          size="16px"
          color="blue-7"
          label="Сохранить"
          :loading="loading"
          unelevated
          no-caps
          @click="handleSubmit"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
import {withDefaults} from 'vue'
import {useDialog} from 'src/uses/Dialogs/useDialog'
import {useValidation} from 'src/uses/useValidation'
import {ListingsItem} from 'src/models/Listings/ListingsItem'
import FileSelectComponent from 'components/FileSelectComponent.vue'
import {useListingsCreateUpdate} from 'src/uses/Listings/useListingsCreateUpdate'

const props = withDefaults(
  defineProps<{
    item?: ListingsItem
  }>(),
  {
    item: undefined
  }
)

const emit = defineEmits<{
  (e: 'success'): void,
}>()

const listingCreateStore = useListingsCreateUpdate()

const {
  listingCreateData,
  loading,
  validation,
  errors,
  status,
  create,
  update,
  initItem,
  clearState,
} = listingCreateStore

const {dialog, show, hide} = useDialog()

const open = async () => {
  show()
  if (props.item) {
    initItem(props.item)
  }
  clearAllErrors()
}

const {
  getErrorMessage,
  hasError,
  clearError,
  clearAllErrors,
} = useValidation(validation, errors)

const success = () => {
  emit('success')
  hide()
  clearState()
}

const handleSubmit = async () => {
  if (listingCreateData.value?.id) {
    await update()
  } else {
    await create()
  }

  if (status.value === 201 || status.value === 200) {
    success()
  }
}

const onSelected = (payload: File) => {
  listingCreateData.value.file = payload
}

const removeDocument = () => {
  listingCreateData.value.file = undefined
}
</script>
<style lang="scss">
.form-title {
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0;
  text-align: left;
  color: #424242;
}
</style>
