<template>
  <form-select
    v-model="value"
    :options="[]"
    :label="props.label || props.field.title"
    disable
  />
</template>

<script setup lang="ts">
import {ref} from 'vue'
import FormSelect from 'src/components/FormElements/FormSelect.vue'
import {FormItemField} from 'src/models/FormEditor/FormItemResponseData'

const props = defineProps<{
  field: FormItemField,
  label: string | boolean,
}>()

const value = ref<string>('')

</script>
