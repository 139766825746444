import {usePatchApi} from 'src/uses/Api/usePatchApi'

export const useFormItemUpdateClientDocument = () => {
  const {
    patch,
    response,
    status,
    loading,
    error,
    errors,
  } = usePatchApi<never, {client_document: boolean}>()

  const updateClientDocument = async (
    docId: string,
    clientDocument: boolean,
  ) => {
    await patch(`v2/forms/documents/${docId}/client_document`, {client_document: clientDocument})
  }

  return {
    loading,
    response,
    status,
    error,
    errors,
    updateClientDocument,
  }
}
