import {useDialog} from 'src/uses/Dialogs/useDialog'
import {ApplicationSubStatusEnum} from 'src/models/Applications/ApplicationSubStatusEnum'
import {Ref} from 'vue'
import {ChangeStatusPayload} from 'src/models/Status/ChangeStatusPayload'

export const useClientRejectComment = (payload: Ref<ChangeStatusPayload | undefined>, realChangeStatus: () => Promise<void>) => {
  const {
    dialog: clientRejectCommentDialog,
    show: showClientRejectCommentDialog,
    hide: hideClientRejectCommentDialog,
  } = useDialog()

  const needShowDialog = async () => {
    if (!payload.value) {
      return false
    }

    return payload.value.applicationStatus === ApplicationSubStatusEnum.client_rejected
      && !payload.value.comment
  }

  const clientRejectWithComment = async (comment: string) => {
    if (!comment || !payload.value) {
      return
    }

    payload.value.comment = comment

    await realChangeStatus()
    hideClientRejectCommentDialog()
  }

  return {
    clientRejectCommentDialog,
    showClientRejectCommentDialog,
    hideClientRejectCommentDialog,
    needShowDialog,
    clientRejectWithComment,
  }
}
