<template>
  <div class="text-h5">
    <strong>
      Заявка №
      <template v-if="!isBankEmployee()">
        {{ props.headerData.number }}
      </template>
      <a
        v-else
        class="cursor-pointer"
        @click="show"
      >
        {{ props.headerData.number }}
      </a>
    </strong>
  </div>
  <q-dialog
    v-model="dialog"
  >
    <q-card
      class="q-mt-md bg-blue-1"
      style="min-width: 400px; max-width: 600px; width: 100%; border-radius: 4px !important;"
    >
      <q-card-section class="text-h6 rounded-borders">
        Номер заявки
      </q-card-section>
      <q-card-section class="white-bg-field">
        <q-input
          v-model="number"
          class="field-with-top-label q-mb-xl"
          label="Укажите номер заявки, который был присвоен ей в системе банка"
          maxlength="300"
          counter
          outlined
          hide-bottom-space
          dense
          @update:model-value="changeNumber"
        />
      </q-card-section>
      <q-card-actions
        class="row justify-end q-py-md"
        style="background: #D5EAFA;"
      >
        <q-btn
          v-close-popup
          size="16px"
          padding="sm lg"
          label="Отмена"
          unelevated
          no-caps
          :disable="loading"
        />
        <q-btn
          padding="sm lg"
          size="16px"
          color="blue-7"
          label="Сохранить"
          :disable="!number"
          :loading="loading"
          unelevated
          no-caps
          @click="onClick"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
import {ref, watch, watchEffect} from 'vue'
import {ControlCenterHeader} from 'src/models/Applications/ControlCenter/ControlCenterHeader'
import {useProfileStore} from 'src/stores/Login/useProfileStore'
import {useDialog} from 'src/uses/Dialogs/useDialog'
import {useChangeNumber} from 'src/uses/Applications/useChangeNumber'

const props = defineProps<{
  headerData: ControlCenterHeader,
}>()

const emit = defineEmits<{
  (e: 'changed'): void,
}>()

const {
  status,
  loading,
  save,
} = useChangeNumber()

const {
  dialog,
  show,
  hide,
} = useDialog()

const {
  isBankEmployee,
} = useProfileStore()

const number = ref<string>()

const onClick = async () => {
  if (!number.value) {
    return
  }

  await save(props.headerData.id, number.value)

  if (status.value === 200) {
    emit('changed')
    hide()
  }
}

const changeNumber = () => {
  if (!number.value) {
    return
  }
  number.value = number.value.replace(/\s+/g, '')
}

watchEffect(
  () => {
    number.value = props.headerData.number
  }
)

watch(
  dialog,
  () => {
    number.value = props.headerData.number
  }
)
</script>

<style scoped lang="scss">

</style>
