import {usePutApi} from 'src/uses/Api/usePutApi'

export const useBankEmployeeUpdate = () => {
  const {
    error,
    errors,
    put,
    status,
    response,
    loading,
  } = usePutApi<unknown, {bank_employee_id: string}>()

  const update = async (appId: string, BankEmployeeId: string) => {
    await put(
      `v1/admin/order/${appId}/bank-employee/attach`,
      {
        bank_employee_id: BankEmployeeId
      }
    )
  }

  return {
    error,
    errors,
    put,
    status,
    response,
    loading,
    update,
  }
}
