<template>
  <q-btn
    v-if="helpBlock"
    label="Редактировать раздел"
    class="BgGreyBtn"
    icon-right="svguse:icons.svg?2#app-pencil"
    unelevated
    no-caps
    @click="open"
  />
  <q-btn
    v-else
    label="Добавить раздел"
    color="primary"
    icon-right="svguse:icons.svg?2#app-plus"
    unelevated
    no-caps
    @click="open"
  />
  <q-dialog
    v-model="dialog"
    full-width
  >
    <q-card class="modal-blue-background">
      <q-card-section
        class="modal-title row items-center q-pb-none q-pl-lg"
      >
        <div class="col">{{ helpBlockCreateUpdateData.id ? 'Редактирование' : 'Добавление нового' }} раздела</div>
      </q-card-section>
      <q-card-section class="q-pl-lg q-pr-lg">
        <q-form>
          <div class="row q-col-gutter-lg white-bg-field">
            <div class="col-12 q-mb-sm">
              <q-input
                v-model="helpBlockCreateUpdateData.title"
                class="field-with-top-label"
                :loading="loading"
                label="Название"
                outlined
                hide-hint
                hide-bottom-space
                dense
              />
            </div>
          </div>
        </q-form>
      </q-card-section>
      <q-card-actions
        align="right"
        class="modal-actions text-primary q-pa-lg"
      >
        <q-btn
          v-close-popup
          :loading="loading"
          label="Отмена"
          class="q-pr-lg"
          style="padding: 17px 28px;"
          unelevated
          flat
          no-caps
          text-color="black"
        />
        <q-btn
          :loading="loading"
          label="Сохранить"
          color="primary"
          style="padding: 17px 28px;"
          unelevated
          icon-right="svguse:icons.svg?2#app-check"
          no-caps
          :disable="!helpBlockCreateUpdateData.title"
          @click="handleSubmit()"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
import {useDialog} from 'src/uses/Dialogs/useDialog'
import {onBeforeMount} from 'vue'
import {withDefaults} from 'vue/dist/vue'
import {useCreateUpdateHelpBlock} from 'src/uses/Settings/Help/useCreateUpdateHelpBlock'
import {HelpBlock} from 'src/models/Help/HelpBlock'

const props = withDefaults(
  defineProps<{
    helpBlock?: HelpBlock | null,
  }>(),
  {
    helpBlock: null
  }
)

const emit = defineEmits<{
  (e: 'success'): void,
}>()

const createUpdateHelpBlock = useCreateUpdateHelpBlock()
const {
  helpBlockCreateUpdateData,
  status,
  loading,
  create,
  update,
} = createUpdateHelpBlock

const {dialog, show, hide} = useDialog()

const open = async () => {
  show()
}
const success = () => {
  emit('success')
  hide()
}

const handleSubmit = async () => {
  if (helpBlockCreateUpdateData.value.id) {
    await update()
  } else {
    await create()
  }

  if (status.value === 200 || status.value === 201) {
    success()
  }
}

onBeforeMount(() => {
  if (!props.helpBlock) {
    return
  }

  helpBlockCreateUpdateData.value = props.helpBlock
})

</script>
