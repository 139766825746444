<template>
  <q-btn
    size="sm"
    icon="svguse:icons.svg?2#app-lock-closed"
    color="blue-grey-4"
    round
    flat
    @click="open"
  />
  <q-dialog
    v-model="dialog"
  >
    <q-card
      class="modal-blue-background"
      style="min-width: 500px; max-width: 800px;"
    >
      <q-card-section
        class="modal-title row items-center q-pb-none q-pl-lg"
      >
        <div>Настройка обязательных продуктов</div>
      </q-card-section>
      <q-card-section class="q-pl-lg q-pr-lg">
        <q-form>
          <div class="row q-col-gutter-lg white-bg-field">
            <div class="col-12 q-mb-sm">
              <div
                v-for="product in requiredProducts"
                :key="product.id"
              >
                <q-checkbox
                  :label="product.bank_title"
                  :val="product.id"
                  v-model="selectedBanks"
                />
              </div>
            </div>
          </div>
        </q-form>
      </q-card-section>
      <q-card-actions
        align="right"
        class="modal-actions text-primary q-pa-lg"
      >
        <q-btn
          v-close-popup
          :loading="loading || putLoading"
          label="Отмена"
          class="q-pr-lg"
          style="padding: 17px 28px;"
          unelevated
          flat
          no-caps
          text-color="black"
        />
        <q-btn
          :loading="loading || putLoading"
          label="Сохранить"
          color="primary"
          style="padding: 17px 28px;"
          unelevated
          icon-right="svguse:icons.svg?2#app-check"
          no-caps
          @click="handleSubmit()"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
import {useDialog} from 'src/uses/Dialogs/useDialog'
import {useRequiredProductsForAgent} from 'src/uses/Products/useRequiredProductsForAgent'

const props = defineProps<{
  id: string
}>()

const requiredProductsForAgent = useRequiredProductsForAgent()
const {
  loading,
  putLoading,
  putStatus,
  requiredProducts,
  selectedBanks,
  loadRequiredProducts,
  save,
} = requiredProductsForAgent

const {dialog, show, hide} = useDialog()

const open = async () => {
  show()
  await loadRequiredProducts(props.id)
}

const handleSubmit = async () => {
  await save(props.id)
  if (putStatus.value === 200) {
    hide()
  }
}

</script>
