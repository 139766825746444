<template>
  <page-block-wrapper>
    <news-list />
  </page-block-wrapper>
</template>

<script setup lang="ts">
import NewsList from 'src/components/News/NewsList.vue'
import PageBlockWrapper from 'src/components/PageBlockWrapper.vue'
import {useMetaStore} from 'src/stores/useMetaStore'

useMetaStore().setTitle('Новости')
</script>
