import {computed, ref, Ref} from 'vue'
import {FieldUpdate} from 'src/models/Field/FieldUpdate'
import {
  ApplicationFormBank,
  ApplicationFormClient,
} from 'src/models/Applications/Form/ApplicationFormResponseData'
import {useUpdateClientCommonField} from 'src/uses/Applications/Form/Client/useUpdateClientCommonField'
import {ApplicationFormUpdateResponse} from 'src/models/Applications/Form/ApplicationFormUpdateResponse'
import {useUpdateSectionFields} from 'src/uses/Applications/Form/useUpdateSectionFields'
import {ProductFields} from 'src/models/Applications/Form/ProductFields'

export const useClientCommonFields = (
  client: Ref<ApplicationFormClient | null>,
  banks: Ref<ApplicationFormBank[]>,
  multiOrderId?: number,
  appId?: string,
) => {
  const response = ref<ApplicationFormUpdateResponse>()

  const {
    updateValues,
    updateErrorProcess,
    updateSuccessProcess,
    filterVisible,
    getParentFieldsUpdate,
  } = useUpdateSectionFields()

  const clientCommonVisibleFields = computed<ProductFields>(() => {
    return filterVisible(!client.value ? [] : client.value.common.fields, banks.value)
  })

  // client.value.common.all

  const updateClientCommonFields = async (fields: FieldUpdate[]) => {
    if (!client.value) {
      return
    }

    fields.push(...getParentFieldsUpdate(fields, clientCommonVisibleFields.value))

    const {
      status,
      errors,
      response: updateResponse,
      update,
    } = useUpdateClientCommonField(multiOrderId, appId)

    updateValues(fields, client.value.common.fields)
    await update(fields)

    if (status.value !== 200 && errors.value) {
      updateErrorProcess(fields, client.value.common.fields, errors.value)
      response.value = undefined

      return
    }

    updateSuccessProcess(fields, client.value.common.fields)
    response.value = updateResponse.value
  }

  return {
    clientCommonVisibleFields,
    response,
    updateClientCommonFields,
  }
}
