<template>
  <page-block-wrapper class="PortalSignView">
    <portal-sign />
  </page-block-wrapper>
</template>
<script setup lang="ts">
import PageBlockWrapper from 'src/components/PageBlockWrapper.vue'
import {useMetaStore} from 'src/stores/useMetaStore'
import PortalSign from 'components/Profile/PortalSign/PortalSign.vue'

useMetaStore().setTitle('Партнерский портал - настройки')
</script>

<style lang="scss">
.PortalSignView {
  .PageBlockWrapper {
    background: $blue-1 !important;
  }
}
</style>
