<template>
  <div
    v-if="!props.field.invisible"
    :key="JSON.stringify(props.field.value)"
    :class="classes"
  >
    <div class="row relative-position">
      <span ref="fieldRef" />
      <component
        :is="component"
        :field="props.field"
        :label="props.label"
        class="col"
      />
      <div
        class="absolute flex justify-between form-field-action-buttons"
        style="right: 10px; top:10px;"
      >
        <q-btn
          v-if="!props.field.is_new_field"
          icon-right="svguse:icons.svg?2#app-plus"
          color="blue-7"
          flat
          dense
          no-wrap
          no-caps
          @click="addBeforeFields"
        />
        <q-btn
          icon="svguse:icons.svg?2#app-trash"
          flat
          dense
          no-wrap
          no-caps
          color="red"
          @click="props.field.is_new_field ? deleteNewField() :deleteFieldHandler()"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {withDefaults, computed, ref} from 'vue'
import { ApplicationFormFieldTypeEnum } from 'src/models/Applications/Form/ApplicationFormResponseData'
import FormFieldSelect from 'src/components/FormEditor/FormFields/FormFieldSelect.vue'
import FormFieldString from 'src/components/FormEditor/FormFields/FormFieldString.vue'
import FormFieldBoolean from 'src/components/FormEditor/FormFields/FormFieldBoolean.vue'
import {useQuasar} from 'quasar'
import {FormItemField} from 'src/models/FormEditor/FormItemResponseData'
import {useFormItemDeleteField} from 'src/uses/FormEditor/useFormItemDeleteField'

const emit = defineEmits<{
  (e: 'change'): void,
  (e: 'addBeforeFields', beforeFieldId: string): void,
  (e: 'deleteNewField', fieldId: string): void,
}>()

const props = withDefaults(
  defineProps<{
    field: FormItemField,
    sectionId: string,
    formId: string,
    label?: string | boolean,
  }>(),
  {
    label: false,
  }
)

const {
  status,
  deleteField
} = useFormItemDeleteField()

const deleteFieldHandler = async () => {
  await deleteField(props.formId, props.sectionId, props.field.id || '')
  if (status.value === 200) {
    changeField()
  }
}

const deleteNewField = async () => {
  emit('deleteNewField', props.field.id || '')
}

const fieldRef = ref<HTMLSpanElement>()

const $q = useQuasar()

const isDropdown = computed(() => props.field.type === ApplicationFormFieldTypeEnum.dropdown)
const isBoolean = computed(() => props.field.type === ApplicationFormFieldTypeEnum.boolean)

const component = computed(() => {
  if (isDropdown.value) return FormFieldSelect
  if (isBoolean.value) return FormFieldBoolean

  return FormFieldString
})

const classes = computed(() => {
  const baseMultiplier = $q.screen.xs
    ? 6 : $q.screen.sm
      ? 6 : $q.screen.md
        ? 4 : $q.screen.lg
          ? 4 : $q.screen.xl
            ? 4 : 4

  let size = (props.field.length || 1) * baseMultiplier
  size = size > 12 ? 12 : size

  if ($q.platform.is.mobile) {
    size = 12
  }

  return {
    [`col-${size} form-editor-field`]: true,
    'white-bg-field': !props.field.highlighted,
    'warning-bg-field': props.field.highlighted,
  }
})

const addBeforeFields = () => {
  emit('addBeforeFields', props.field.id || '')
}

const changeField = () => {
  emit('change')
}
</script>
<style lang="scss">
.form-editor-field {
  &:hover {
    .form-field-action-buttons {
      display: flex!important;
    }
  }

  .form-field-action-buttons {
    display: none!important;
  }
}
</style>
