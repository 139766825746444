<template>
  <q-btn-dropdown
    v-if="canDownload"
    class="ApplicationGridDocs"
    color="blue-grey-4"
    size="12px"
    dropdown-icon="none"
    padding="6px"
    flat
    unelevated
    rounded
    square
    @click="clickBtn"
  >
    <template #label>
      <q-icon
        name="svguse:icons.svg?2#app-download"
        size="sm"
        padding="6px"
      >
        <q-tooltip
          anchor="top middle"
          self="bottom middle"
        >Скачать файлы</q-tooltip>
      </q-icon>
    </template>
    <q-list separator>
      <template
        v-for="doc in filteredDocuments()"
        :key="doc.code"
      >
        <q-item
          v-close-popup
          clickable
        >
          <q-item-section @click="download(doc, props.item.id)">
            <q-item-label>Скачать {{ docGridDownloadDesc(doc.code) }}</q-item-label>
          </q-item-section>
        </q-item>
        <q-item
          v-if="canDownloadSign(doc)"
          v-close-popup
          clickable
        >
          <q-item-section @click="download(doc, props.item.id, true)">
            <q-item-label>
              Скачать {{ docGridDownloadDesc(doc.code) }}
              <q-icon
                color="green-14"
                name="svguse:icons.svg?2#app-lock-closed"
              />
            </q-item-label>
          </q-item-section>
        </q-item>
      </template>
    </q-list>
  </q-btn-dropdown>
</template>

<script setup lang="ts">
import {computed} from 'vue'
import {ApplicationsGridItem, ApplicationsGridItemDocument} from 'src/models/Applications/ApplicationsGridItem'
import {ApplicationStatusEnum} from 'src/models/Applications/ApplicationStatusEnum'
import {useDocumentsDescription} from 'src/uses/Applications/Form/Documents/useDocumentDescription'
import {useApplicationGridDownloadDocument} from 'src/uses/Applications/ControlCenter/useApplicationGridDownloadDocument'
import {DocumentsCodesEnum} from 'src/models/DocumentsCodesEnum'
import {ApplicationSubStatusEnum} from 'src/models/Applications/ApplicationSubStatusEnum'
import {usePosthogStore} from 'stores/Posthog/usePosthogStore'

const {sendEvent} = usePosthogStore()

const props = defineProps<{
  item: ApplicationsGridItem
}>()
const item = computed(() => {
  return props.item
})

const filteredDocuments = () => {
  const docsCodes = [
    DocumentsCodesEnum.BG_FORM,
    DocumentsCodesEnum.INVOICE,
  ]

  const docsForReleaseStatusCodes = [
    DocumentsCodesEnum.BG_SCAN,
    DocumentsCodesEnum.EXTRACT_FROM_BG_REGISTRY,
  ]

  return item.value.documents.filter(doc => {
    if (item.value.status === ApplicationStatusEnum.released) {
      return docsForReleaseStatusCodes.includes(doc.code as DocumentsCodesEnum)
    }

    return docsCodes.includes(doc.code as DocumentsCodesEnum)
  })
}

const {
  docGridDownloadDesc
} = useDocumentsDescription()

const {
  download,
} = useApplicationGridDownloadDocument()

const canDownload = computed(() => {
  const statuses = [
    ApplicationSubStatusEnum.bg_project_preparation,
    ApplicationSubStatusEnum.bg_project_customer_agreement,
    ApplicationSubStatusEnum.bg_project_customer_agreement_request_sign,
    ApplicationSubStatusEnum.bg_project_bank_agreement,
    ApplicationSubStatusEnum.bg_project_bank_agreement_request,
    ApplicationSubStatusEnum.bg_project_bank_agreement_request_sign,
    ApplicationSubStatusEnum.payment_waiting,
    ApplicationSubStatusEnum.deferred_conditions_execution_waiting,
    ApplicationSubStatusEnum.deferred_conditions_execution_waiting_sign,
    ApplicationSubStatusEnum.deferred_conditions_executed,
    ApplicationSubStatusEnum.paid,
    ApplicationSubStatusEnum.guarantee_released,
    ApplicationSubStatusEnum.guarantee_released_request,
    ApplicationSubStatusEnum.guarantee_released_request_sign,
  ]

  return statuses.includes(props.item.sub_status)
    && props.item.documents
    && props.item.documents.length
    && filteredDocuments().length > 0
})

const canDownloadSign = (doc: ApplicationsGridItemDocument) => {
  const releasedStatuses = [
    ApplicationSubStatusEnum.guarantee_released,
    ApplicationSubStatusEnum.guarantee_released_request,
    ApplicationSubStatusEnum.guarantee_released_request_sign,
  ]

  return doc.status === 'signed' && releasedStatuses.includes(props.item.sub_status)
}

const clickBtn = () => {
  sendEvent('Download_doc_via_list_of_applications')
}
</script>

<style lang="scss">
.ApplicationGridDocs {
  .q-btn-dropdown__arrow {
    display: none;
  }
}
</style>
