import {useDialog} from 'src/uses/Dialogs/useDialog'
import {ApplicationSubStatusEnum} from 'src/models/Applications/ApplicationSubStatusEnum'
import {Ref} from 'vue'
import {ChangeStatusPayload} from 'src/models/Status/ChangeStatusPayload'
import {useBankRequestSign} from 'src/stores/Status/useBankRequestSign'
import {useAgreementConfirm} from 'src/stores/Status/useAgreementConfirm'

export const useBankRequestSignAndAgreementConfirm = (payload: Ref<ChangeStatusPayload | undefined>, realChangeStatus: () => Promise<void>) => {
  const {
    dialog: bankRequestSignAndAgreementConfirmDialog,
    show: showBankRequestSignAndAgreementConfirmDialog,
    hide: hideBankRequestSignAndAgreementConfirmDialog,
  } = useDialog()

  const bankRequestSign = useBankRequestSign(payload, realChangeStatus)
  const agreementConfirm = useAgreementConfirm(payload, realChangeStatus)

  const needShowDialog = async (currentSubStatus: ApplicationSubStatusEnum) => {
    return await bankRequestSign.needShowDialog(currentSubStatus)
      && await agreementConfirm.needShowDialog(currentSubStatus)
  }

  const onBankRequestSignAndAgreementConfirm = async (signWasRequested: boolean) => {
    if (!payload.value) {
      return
    }

    await bankRequestSign.onSaveBankRequestSign(signWasRequested)
    hideBankRequestSignAndAgreementConfirmDialog()
  }

  return {
    bankRequestSignAndAgreementConfirmDialog,
    showBankRequestSignAndAgreementConfirmDialog,
    hideBankRequestSignAndAgreementConfirmDialog,
    needShowDialog,
    onBankRequestSignAndAgreementConfirm,
  }
}
