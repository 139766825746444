import {defineStore} from 'pinia'
import {ref} from 'vue'
import {usePutApi} from 'src/uses/Api/usePutApi'
import {ChangeStatusRequestData} from 'src/models/Status/ChangeStatusRequestData'
import {ApplicationSubStatusEnum} from 'src/models/Applications/ApplicationSubStatusEnum'
import {useBankRequestSign} from 'src/stores/Status/useBankRequestSign'
import {ChangeStatusPayload} from 'src/models/Status/ChangeStatusPayload'
import {useOnReleaseSend} from 'src/stores/Status/useOnReleaseSend'
import {useClientRejectComment} from 'src/stores/Status/useClientRejectComment'
import {useRequest} from 'src/stores/Status/useRequest'
import {useToNew} from 'src/stores/Status/useToNew'
import {useBankReject} from 'src/stores/Status/useBankReject'
import {ApplicationFormUpdateResponse} from 'src/models/Applications/Form/ApplicationFormUpdateResponse'
import {useAgreementConfirm} from 'src/stores/Status/useAgreementConfirm'
import {useBankRequestSignAndAgreementConfirm} from 'src/stores/Status/useBankRequestSignAndAgreementConfirm'
import {useGuaranteeReleased} from 'stores/Status/useGuaranteeReleased'
import {
  useBankRequestSignAndOnReleaseSend
} from 'stores/Status/useBankRequestSignAndOnReleaseSend'

export const useStatusStore = defineStore('applications-change-status', () => {
  const {
    put,
    response,
    status,
    loading,
    error,
    errors,
  } = usePutApi<ApplicationFormUpdateResponse, ChangeStatusRequestData>(true)

  const lastAffectedStatusPayload = ref<ChangeStatusPayload>()
  const lastStatusPayload = ref<ChangeStatusPayload>()

  const changeStatus = async (
    payload: ChangeStatusPayload,
    currentSubStatus: ApplicationSubStatusEnum,
  ) => {
    lastStatusPayload.value = payload

    if (await bankRequestSignAndOnReleaseSend.needShowDialog(currentSubStatus)) {
      bankRequestSignAndOnReleaseSend.showBankRequestSignAndOnReleaseSendDialog()
      return
    }

    if (await onClientRejectComment.needShowDialog()) {
      onClientRejectComment.showClientRejectCommentDialog()
      return
    }

    if (await bankRequestSignAndAgreementConfirm.needShowDialog(currentSubStatus)) {
      bankRequestSignAndAgreementConfirm.showBankRequestSignAndAgreementConfirmDialog()
      return
    }

    if (await agreementConfirm.needShowDialog(currentSubStatus)) {
      agreementConfirm.showAgreementConfirmDialog()
      return
    }

    if (await bankRequestSign.needShowDialog(currentSubStatus)) {
      bankRequestSign.showBankRequestSignDialog()
      return
    }

    if (await request.needShowDialog()) {
      request.showRequestDialog()
      return
    }

    if (await toNew.needShowDialog()) {
      toNew.showNewDialog()
      return
    }

    if (await bankReject.needShowDialog()) {
      bankReject.showBankRejectDialog()
      return
    }

    if (await guaranteeReleased.needShowDialog()) {
      guaranteeReleased.showGuaranteeReleasedDialog()
      return
    }

    if (await onReleaseSend.needShowDialog()) {
      onReleaseSend.showOnReleaseSendDialog()
      return
    }

    await realChangeStatus()
  }

  const realChangeStatus = async () => {
    if (!lastStatusPayload.value) {
      return
    }

    await put(
      `v2/control-center/${lastStatusPayload.value.id}/status/${lastStatusPayload.value.applicationStatus}`,
      {
        initiator: lastStatusPayload.value.initiator || '',
        withoutMessage: lastStatusPayload.value.withoutMessage || false,
        ...(lastStatusPayload.value.bank_not_need_signed_documents_check
          ? {bank_not_need_signed_documents_check: true}
          : {}
        ),
        ...(lastStatusPayload.value.guarantee_registry_type
          ? {guarantee_registry_type: lastStatusPayload.value.guarantee_registry_type}
          : {}
        ),
        ...(lastStatusPayload.value.comment ? {comment: lastStatusPayload.value.comment} : {}),
        ...(lastStatusPayload.value?.sign_url ? {sign_url: lastStatusPayload.value.sign_url} : {}),
        ...(lastStatusPayload.value?.purchase_number ? {purchase_number: lastStatusPayload.value.purchase_number} : {}),
        ...(lastStatusPayload.value?.purchase_identity_code
          ? {purchase_identity_code: lastStatusPayload.value.purchase_identity_code}
          : {}
        ),
        ...(lastStatusPayload.value?.is_purchase_from_single_supplier
          ? {is_purchase_from_single_supplier: lastStatusPayload.value.is_purchase_from_single_supplier}
          : {}
        ),
        ...(lastStatusPayload.value.inner_signing_enabled
          ? {inner_signing_enabled: lastStatusPayload.value.inner_signing_enabled}
          : {}
        ),
      }
    )

    if (status.value === 200 && response && response.value) {
      lastAffectedStatusPayload.value = lastStatusPayload.value
      lastStatusPayload.value = undefined
    }
  }

  const bankRequestSignAndOnReleaseSend = useBankRequestSignAndOnReleaseSend(lastStatusPayload, realChangeStatus)
  const bankRequestSignAndAgreementConfirm = useBankRequestSignAndAgreementConfirm(lastStatusPayload, realChangeStatus)
  const agreementConfirm = useAgreementConfirm(lastStatusPayload, realChangeStatus)
  const bankRequestSign = useBankRequestSign(lastStatusPayload, realChangeStatus)
  const onReleaseSend = useOnReleaseSend(lastStatusPayload, realChangeStatus)
  const onClientRejectComment = useClientRejectComment(lastStatusPayload, realChangeStatus)
  const request = useRequest(lastStatusPayload, realChangeStatus)
  const toNew = useToNew(lastStatusPayload, realChangeStatus)
  const bankReject = useBankReject(lastStatusPayload, realChangeStatus)
  const guaranteeReleased = useGuaranteeReleased(lastStatusPayload, realChangeStatus)

  return {
    put,
    response,
    status,
    loading,
    error,
    errors,
    lastAffectedStatusPayload,
    lastStatusPayload,

    clientRejectCommentDialog: onClientRejectComment.clientRejectCommentDialog,
    showClientRejectCommentDialog: onClientRejectComment.showClientRejectCommentDialog,
    hideClientRejectCommentDialog: onClientRejectComment.hideClientRejectCommentDialog,
    clientRejectWithComment: onClientRejectComment.clientRejectWithComment,

    requestDialog: request.requestDialog,
    showRequestDialog: request.showRequestDialog,
    hideRequestDialog: request.hideRequestDialog,
    onSaveRequest: request.onSaveRequest,

    newDialog: toNew.newDialog,
    showNewDialog: toNew.showNewDialog,
    hideNewDialog: toNew.hideNewDialog,
    onSaveNew: toNew.onSaveNew,

    bankRequestSignAndOnReleaseSendDialog: bankRequestSignAndOnReleaseSend.bankRequestSignAndOnReleaseSendDialog,
    showBankRequestSignAndOnReleaseSendDialog: bankRequestSignAndOnReleaseSend.showBankRequestSignAndOnReleaseSendDialog,
    hideBankRequestSignAndOnReleaseSendDialog: bankRequestSignAndOnReleaseSend.hideBankRequestSignAndOnReleaseSendDialog,
    onBankRequestSignAndOnReleaseSend: bankRequestSignAndOnReleaseSend.onBankRequestSignAndOnReleaseSend,
    bankRequestAndIsOnlyDelivery: bankRequestSignAndOnReleaseSend.isOnlyDelivery,
    bankRequestAndIsOnlyRegistry: bankRequestSignAndOnReleaseSend.isOnlyRegistry,
    bankRequestAndIsRegistryAndDelivery: bankRequestSignAndOnReleaseSend.isRegistryAndDelivery,

    bankRejectDialog: bankReject.bankRejectDialog,
    showBankRejectDialog: bankReject.showBankRejectDialog,
    hideBankRejectDialog: bankReject.hideBankRejectDialog,
    onSaveBankReject: bankReject.onSaveBankReject,

    guaranteeReleasedDialog: guaranteeReleased.guaranteeReleasedDialog,
    showGuaranteeReleasedDialog: guaranteeReleased.showGuaranteeReleasedDialog,
    hideGuaranteeReleasedDialog: guaranteeReleased.hideGuaranteeReleasedDialog,
    onSaveGuaranteeReleased: guaranteeReleased.onSaveGuaranteeReleased,

    bankRequestSignDialog: bankRequestSign.bankRequestSignDialog,
    showBankRequestSignDialog: bankRequestSign.showBankRequestSignDialog,
    hideBankRequestSignDialog: bankRequestSign.hideBankRequestSignDialog,
    onSaveBankRequestSign: bankRequestSign.onSaveBankRequestSign,

    agreementConfirmDialog: agreementConfirm.agreementConfirmDialog,
    showAgreementConfirmDialog: agreementConfirm.showAgreementConfirmDialog,
    hideAgreementConfirmDialog: agreementConfirm.hideAgreementConfirmDialog,
    onAgreementConfirm: agreementConfirm.onAgreementConfirm,

    bankRequestSignAndAgreementConfirmDialog: bankRequestSignAndAgreementConfirm.bankRequestSignAndAgreementConfirmDialog,
    showBankRequestSignAndAgreementConfirmDialog: bankRequestSignAndAgreementConfirm.showBankRequestSignAndAgreementConfirmDialog,
    hideBankRequestSignAndAgreementConfirmDialog: bankRequestSignAndAgreementConfirm.hideBankRequestSignAndAgreementConfirmDialog,
    onBankRequestSignAndAgreementConfirm: bankRequestSignAndAgreementConfirm.onBankRequestSignAndAgreementConfirm,

    onReleaseSendDialog: onReleaseSend.onReleaseSendDialog,
    showOnReleaseSendDialog: onReleaseSend.showOnReleaseSendDialog,
    hideOnReleaseSendDialog: onReleaseSend.hideOnReleaseSendDialog,
    onChangeReleaseStatus: onReleaseSend.onChangeReleaseStatus,
    isOnlyRegistry: onReleaseSend.isOnlyRegistry,
    isOnlyDelivery: onReleaseSend.isOnlyDelivery,
    isRegistryAndDelivery: onReleaseSend.isRegistryAndDelivery,
    changeStatus,
  }
})
