import {computed, ref, Ref} from 'vue'
import {FieldUpdate} from 'src/models/Field/FieldUpdate'
import {
  ApplicationFormBank,
  ApplicationFormClient,
} from 'src/models/Applications/Form/ApplicationFormResponseData'
import {useUpdateClientHeadField} from 'src/uses/Applications/Form/Client/useUpdateClientHeadField'
import {ApplicationFormUpdateResponse} from 'src/models/Applications/Form/ApplicationFormUpdateResponse'
import {useUpdateSectionFields} from 'src/uses/Applications/Form/useUpdateSectionFields'
import {ProductFields} from 'src/models/Applications/Form/ProductFields'

export const useClientHeadFields = (
  client: Ref<ApplicationFormClient | null>,
  banks: Ref<ApplicationFormBank[]>,
  multiOrderId?: number,
  appId?: string,
) => {
  const response = ref<ApplicationFormUpdateResponse>()

  const {
    updateValues,
    updateErrorProcess,
    updateSuccessProcess,
    filterVisible,
    getParentFieldsUpdate,
  } = useUpdateSectionFields()

  const clientHeadVisibleFields = computed<ProductFields>(() => {
    return filterVisible(!client.value ? [] : client.value?.head.fields, banks.value)
  })

  const updateClientHeadFields = async (fields: FieldUpdate[]) => {
    if (!client.value) {
      return
    }

    fields.push(...getParentFieldsUpdate(fields, clientHeadVisibleFields.value))

    const {
      status,
      errors,
      update,
      response: updateResponse,
    } = useUpdateClientHeadField(multiOrderId, appId)

    updateValues(fields, client.value?.head.fields)
    await update(fields)

    if (status.value !== 200 && errors.value) {
      updateErrorProcess(fields, client.value?.head.fields, errors.value)
      response.value = undefined

      return
    }

    updateSuccessProcess(fields, client.value?.head.fields)
    response.value = updateResponse.value
  }

  return {
    clientHeadVisibleFields,
    response,
    updateClientHeadFields,
  }
}
