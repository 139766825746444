<template>
  <div
    v-if="item"
    class="ApplicationItemSidebar text-white bg-blue-grey-10 q-pb-sm"
  >
    <div class="ApplicationItemSidebar--head row q-pl-sm items-center q-pt-sm">
      <div>
        <q-btn
          align="left"
          size="14px"
          color="blue-grey-10"
          class="q-mr-sm"
          padding="sm"
          unelevated
          @click="emit('toggle')"
        >
          <q-icon
            name="svguse:icons.svg?2#app-chevron-double-left"
            color="white"
            size="18px"
          />
        </q-btn>
      </div>
    </div>
    <div
      v-if="hasEbg"
      class="ApplicationItemSidebar--content q-ma-sm bg-blue-grey-9"
    >
      <div class="row items-center q-pa-sm q-mb-md ApplicationItemSidebar--content--head">
        <q-icon
          size="18px"
          name="svguse:/icons.svg#ebg-side"
        />
        <div class="col q-pl-xs">
          ЭБГ
        </div>
        <div class="col text-right">
          {{ fillPercentEbg }}%
        </div>
      </div>
      <div class="ApplicationItemSidebar--content--data q-gutter-sm q-px-sm q-pb-md">
        <div
          v-if="rnt"
          class="row"
        >
          <div class="col-3">
            РНТ
          </div>
          <div class="col">
            {{ rnt }}
          </div>
        </div>
        <div
          v-if="law"
          class="row"
        >
          <div class="col-3">
            ФЗ
          </div>
          <div class="col">
            {{ getLawLabel(law) }}
            <template v-if="provisionType">
              / {{ getProvisionTypeLabel(provisionType) }}
            </template>
          </div>
        </div>
        <div
          v-if="startDate"
          class="row"
        >
          <div class="col-3">
            Срок
          </div>
          <div class="col">
            <date-item :model-value="startDate" />
            <template v-if="endDate">
              -
              <date-item :model-value="endDate" />
            </template>
            <template v-if="countDays">
              , <span class="text-no-wrap">{{ countDays }} д.</span>
            </template>
          </div>
        </div>
        <div
          v-if="bgSum"
          class="row"
        >
          <div class="col-3">
            Сумма
          </div>
          <div class="col">
            <money-component :model-value="bgSum as number" />
          </div>
        </div>
      </div>
      <application-item-sidebar-banks
        :multi-order-id="multiOrderId"
        :type="ProductEnum.EBG"
      >
        <template #button>
          <div
            v-if="atLeastOneDraftBankIsOkEbg"
            class="sendButton q-py-sm q-mb-md"
          >
            <q-btn
              class="full-width"
              align="left"
              color="green-14"
              size="14px"
              style="padding: 10px 16px;"
              unelevated
              no-caps
              @click="sendDialogShow"
            >
              <strong>Отправить</strong>
              <span class="icon">
                <q-icon
                  name="svguse:icons.svg?2#app-arrow-right-bold"
                  color="green-14"
                  size="18px"
                  style="padding: 5.5px;"
                />
              </span>
            </q-btn>
          </div>
        </template>
      </application-item-sidebar-banks>
      <div style="height: 1px;" />
      <div
        v-if="allDraftBankIsOkEbg && (!hasKik || !allDraftBankIsOkKik)"
        class="sendButton q-pa-sm q-mt-md"
      >
        <q-btn
          class="full-width"
          align="left"
          color="green-14"
          size="14px"
          style="padding: 10px 16px;"
          unelevated
          no-caps
          @click="sendDialogShow"
        >
          <strong>Отправить</strong>
          <span class="icon">
            <q-icon
              name="svguse:icons.svg?2#app-arrow-right-bold"
              color="green-14"
              size="18px"
              style="padding: 5.5px;"
            />
          </span>
        </q-btn>
      </div>
    </div>
    <div
      v-if="hasKik"
      class="ApplicationItemSidebar--content q-ma-sm bg-blue-grey-9"
    >
      <div class="row items-center q-pa-sm q-mb-md ApplicationItemSidebar--content--head">
        <q-icon
          size="18px"
          name="svguse:icons.svg?2#app-credit-card"
        />
        <div class="col q-pl-xs">
          Кредит
        </div>
        <div class="col text-right">
          {{ fillPercentKik }}%
        </div>
      </div>
      <div class="ApplicationItemSidebar--content--data q-gutter-sm q-px-sm q-pb-md">
        <div
          v-if="kikSum"
          class="row"
        >
          <div class="col-3">
            Сумма
          </div>
          <div class="col">
            <money-component :model-value="kikSum as FormElementPrimitiveValue" />
          </div>
        </div>
        <div
          v-if="kikMonths"
          class="row"
        >
          <div class="col-3">
            Месяцев
          </div>
          <div class="col">
            {{ kikMonths }}
          </div>
        </div>
        <div
          v-if="kikType"
          class="row"
        >
          <div class="col-3">
            Тип
          </div>
          <div class="col">
            {{ getCreditTypeLabel(kikType) }}
          </div>
        </div>
      </div>
      <application-item-sidebar-banks
        :multi-order-id="multiOrderId"
        :type="ProductEnum.KIK"
      >
        <template #button>
          <div
            v-if="atLeastOneDraftBankIsOkKik"
            class="sendButton q-py-sm q-mb-md"
          >
            <q-btn
              class="full-width"
              align="left"
              color="green-14"
              size="14px"
              style="padding: 10px 16px;"
              unelevated
              no-caps
              @click="sendDialogShow"
            >
              <strong>Отправить</strong>
              <span class="icon">
                <q-icon
                  name="svguse:icons.svg?2#app-arrow-right-bold"
                  color="green-14"
                  size="18px"
                  style="padding: 5.5px;"
                />
              </span>
            </q-btn>
          </div>
        </template>
      </application-item-sidebar-banks>
      <div style="height: 1px;" />
      <div
        v-if="allDraftBankIsOkKik && (!hasEbg || !allDraftBankIsOkEbg)"
        class="sendButton q-pa-sm q-mt-md"
      >
        <q-btn
          class="full-width"
          align="left"
          color="green-14"
          size="14px"
          style="padding: 10px 16px;"
          unelevated
          no-caps
          @click="sendDialogShow"
        >
          <strong>Отправить</strong>
          <span class="icon">
            <q-icon
              name="svguse:icons.svg?2#app-arrow-right-bold"
              color="green-14"
              size="18px"
              style="padding: 5.5px;"
            />
          </span>
        </q-btn>
      </div>
    </div>
    <div
      v-if="hasKik && hasEbg && allDraftBankIsOkEbg && allDraftBankIsOkKik"
      class="ApplicationItemSidebar--content q-ma-sm bg-blue-grey-9"
    >
      <q-btn
        class="full-width"
        align="left"
        color="green-14"
        size="14px"
        style="padding: 10px 16px;"
        unelevated
        no-caps
        @click="sendDialogShow"
      >
        <strong>Отправить</strong>
        <span class="icon">
            <q-icon
              name="svguse:icons.svg?2#app-arrow-right-bold"
              color="green-14"
              size="18px"
              style="padding: 5.5px;"
            />
          </span>
      </q-btn>
    </div>
  </div>
  <application-item-sidebar-next-field-helper
    v-if="item"
    :multi-order-id="multiOrderId"
  />
</template>

<script setup lang="ts">
import DateItem from 'src/components/DateItem.vue'
import MoneyComponent from 'src/components/MoneyComponent.vue'
import {storeToRefs} from 'pinia'
import {useApplicationFormSidebar} from 'src/uses/Applications/Form/useApplicationFormSidebar'
import {useLaw} from 'src/uses/Applications/useLaw'
import {useProvisionType} from 'src/uses/Applications/useProvosoinType'
import {useRoute} from 'vue-router'
import {computed} from 'vue'
import {useApplicationItemStore} from 'src/stores/Applications/Item/useApplicationItemStore'
import ApplicationItemSidebarBanks
  from 'src/components/Applications/ApplicationItem/Sidebar/ApplicationItemSidebarBanks.vue'
import ApplicationItemSidebarNextFieldHelper
  from 'src/components/Applications/ApplicationItem/Sidebar/ApplicationItemSidebarNextFieldHelper.vue'
import {useApplicationItemSendStore} from 'src/stores/Applications/Item/useApplicationItemSendStore'
import {useApplicationItemEbgFillStore} from 'stores/Applications/Item/useApplicationItemEbgFillStore'
import {useCreditType} from 'src/uses/Applications/useCreditType'
import {useApplicationItemKikFillStore} from 'stores/Applications/Item/useApplicationItemKikFillStore'
import {ProductEnum} from 'src/models/Applications/ProductEnum'
import {FormElementPrimitiveValue} from 'src/models/Form'

const emit = defineEmits<{
  (e: 'toggle'): void,
}>()

const route = useRoute()
const multiOrderId = computed(() => parseInt(route.params.formId as string))
const applicationItemStore = useApplicationItemStore(multiOrderId.value)()
const applicationItemSendStore = useApplicationItemSendStore(multiOrderId.value)()

const {
  sendDialogShow,
} = applicationItemSendStore

const {
  item,
  hasEbg,
  hasKik,
} = storeToRefs(applicationItemStore)

const fillEbgStore = useApplicationItemEbgFillStore(multiOrderId.value)()
const {
  fillPercent: fillPercentEbg,
  atLeastOneDraftBankIsOk: atLeastOneDraftBankIsOkEbg,
  allDraftBankIsOk: allDraftBankIsOkEbg,
} = storeToRefs(fillEbgStore)

const fillKikStore = useApplicationItemKikFillStore(multiOrderId.value)()
const {
  fillPercent: fillPercentKik,
  atLeastOneDraftBankIsOk: atLeastOneDraftBankIsOkKik,
  allDraftBankIsOk: allDraftBankIsOkKik,
} = storeToRefs(fillKikStore)

const {
  rnt,
  law,
  provisionType,
  startDate,
  endDate,
  countDays,
  bgSum,
  kikSum,
  kikMonths,
  kikType,
} = useApplicationFormSidebar(item)

const {
  getLawLabel,
} = useLaw()

const {
  getCreditTypeLabel,
} = useCreditType()

const {
  getProvisionTypeLabel,
} = useProvisionType()
</script>

<style lang="scss">
.ApplicationItemSidebar {
  border-radius: 8px;
  width: 100%;
  position: relative;

  .ApplicationItemSidebar--head {
    font-size: 12px;
    line-height: 14px;
  }

  .ApplicationItemSidebar--content {
    border-radius: 6px;
    margin-bottom: 0;

    .ApplicationItemSidebar--content--head {
      font-weight: 700;
      font-size: 14px;
      border-bottom: 1px solid $blue-grey-8;
    }

    .ApplicationItemSidebar--content--data {
      line-height: 20px;

      .col-3 {
        color: $blue-grey-4;
        font-size: 12px;
        width: 20% !important;
      }
      .col {
        text-align: right;
        font-size: 14px;
        font-weight: 700;
      }
    }

    .sendButton {
      .icon {
        background: white;
        border-radius: 6px;
        position: absolute;
        right: 10px;
        line-height: 0;
      }

      .q-btn {
        border-radius: 6px;
      }
    }
  }
}
</style>
