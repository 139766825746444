<template>
  <form-two-value-toggle
    v-model="applicationGridType"
    :loading="props.loading"
    :first-value="{label: 'Отдельные заявки', value: ApplicationGridTypeEnum.grid}"
    :second-value="{label: 'Мультизаявки', value: ApplicationGridTypeEnum.multiGrid}"
  />
</template>

<script setup lang="ts">
import {ApplicationGridTypeEnum} from 'src/models/Applications/ApplicationGridTypeEnum'
import {storeToRefs} from 'pinia'
import {useApplicationGridTypeStore} from 'stores/Applications/Grid/useApplicationGridTypeStore'
import FormTwoValueToggle from 'components/FormElements/FormTwoValueToggle.vue'

const props = defineProps<{
  loading: boolean,
}>()

const {
  applicationGridType
} = storeToRefs(useApplicationGridTypeStore())

</script>

<style lang="scss">

</style>
