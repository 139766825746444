import {usePostApi} from 'src/uses/Api/usePostApi'
import {useGetBankItem} from 'src/uses/Bank/useGetBankItem'
import {computed, Ref, ref} from 'vue'
import {BankItem} from 'src/models/Banks/BankItem'
import {
  required
} from 'src/utils/i18n-validators'
import useVuelidate from '@vuelidate/core'
import {FormHelper} from 'src/helpers/FormHelper'
import {useGetBankInfo} from 'src/uses/Bank/useGetBankInfo'

export const useBankCreateEdit = () => {
  const getBankItem = useGetBankItem()
  const {
    load,
    getBankRequestData
  } = getBankItem

  const getBankInfo = useGetBankInfo()
  const {
    getBankInfoByInn,
    bankInfo,
    status: bankInfoStatus
  } = getBankInfo

  const {
    post,
    loading,
    status,
    errors,
  } = usePostApi<BankItem, FormData>()

  const itemId = ref<string>()
  const newLogo = ref<File | null>(null)
  const bankCreateData = ref<BankItem>({
    id: '',
    hidden: false,
    inn: '',
    logo: '',
    sort: 0,
    title_full: '',
    title_short: '',
    product_types: [],
  })

  const rules = computed(() => {
    return {
      inn: {
        required,
      },
      title_full: {
        required,
      },
      title_short: {
        required,
      },
    }
  })

  const validation = useVuelidate<Ref<BankItem>>(rules, bankCreateData)

  const create = async () => {
    if (!await validation.value.$validate()) {
      return
    }

    if (newLogo.value) {
      bankCreateData.value.logo = newLogo.value
    }

    await post('admin/bank', FormHelper.getFormDataFromObject(bankCreateData.value))
  }

  const update = async () => {
    if (!itemId.value || !await validation.value.$validate()) {
      return
    }

    if (newLogo.value) {
      bankCreateData.value.logo = newLogo.value
    }

    await post(`admin/bank/${itemId.value}`, FormHelper.getFormDataFromObject(bankCreateData.value))
  }

  const loadUserItem = async (id: string) => {
    await load(id)
    bankCreateData.value = {
      ...getBankRequestData()
    }
  }

  const getFileName = () => {
    if (newLogo.value?.name) {
      return newLogo.value?.name
    }

    return ''
  }

  const removeLogo = () => {
    bankCreateData.value.logo = ''
    newLogo.value = null
  }

  const onChangeInn = async (inn: string) => {
    await getBankInfoByInn(inn)
    if (bankInfoStatus.value === 200) {
      bankCreateData.value.title_full = bankInfo.value.title_full
    }
  }

  return {
    bankCreateData,
    validation,
    status,
    errors,
    loading,
    itemId,
    rules,
    newLogo,
    create,
    update,
    loadUserItem,
    getFileName,
    removeLogo,
    onChangeInn,
  }
}
