import {defineStore} from 'pinia'
import {useGetApi} from 'src/uses/Api/useGetApi'
import {ref} from 'vue'
import {EmployeeFilterItem} from 'src/models/Employees/EmployeeFilterItem'

export const useEmployeeFilterStore = defineStore('employee-filter-list', () => {
  const {
    loading,
    get,
    status,
    response,
  } = useGetApi<{items: EmployeeFilterItem[]}>()

  const employees = ref<EmployeeFilterItem[]>([])

  const load = async () => {
    if (loading.value) {
      return
    }

    await get(
      'v1/employees/all',
    )

    if (status.value === 200 && response && response.value) {
      employees.value = response.value.items
    }
  }

  return {
    loading,
    employees,
    load,
  }
})
