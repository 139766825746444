<template>
  <div class="q-mx-md row items-center FormTwoValueToggle">
    <div
      :class="{active: isFirst, loading: props.loading}"
      @click="changeType(props.firstValue.value)"
    >
      {{ props.firstValue.label }}
    </div>
    <q-toggle
      :model-value="model"
      :true-value="props.secondValue.value"
      :false-value="props.firstValue.value"
      :disable="props.loading"
      color="primary"
      keep-color
      @update:model-value="emit('update:model-value', $event)"
    />
    <div
      :class="{active: isSecond, loading: props.loading}"
      @click="changeType(props.secondValue.value)"
    >
      {{ props.secondValue.label }}
    </div>
  </div>
</template>

<script setup lang="ts">
import {computed} from 'vue'

const props = withDefaults(
  defineProps<{
    modelValue: unknown
    firstValue: {label: string, value: unknown}
    secondValue: {label: string, value: unknown}
    loading?: boolean
  }>(),
  {
    loading: false,
  }
)

const emit = defineEmits<{
  (e: 'update:model-value', value: unknown): void
}>()

const isFirst = computed(() => props.modelValue === props.firstValue.value)
const isSecond = computed(() => props.modelValue === props.secondValue.value)

const model = computed<unknown>(() => {
  return isFirst.value
    ? props.firstValue.value
    : props.secondValue.value
})

const changeType = (value: unknown) => {
  emit('update:model-value', value)
}
</script>

<style lang="scss">
.FormTwoValueToggle {
  & > div:last-child, & > div:first-child {
    color: $blue-grey-4;
    font-weight: bold;
    cursor: pointer;

    &.loading {
      cursor: not-allowed;
      opacity: 0.8;
    }

    &.active {
      color: $primary;
    }
  }
  .q-toggle__thumb:after {
    background: $primary;
  }
}
</style>
