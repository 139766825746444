import {defineStore} from 'pinia'
import {ref} from 'vue'
import {usePostApi} from 'src/uses/Api/usePostApi'
import {useGetApi} from 'src/uses/Api/useGetApi'
import {FormHelper} from 'src/helpers/FormHelper'
import {useFile} from 'src/uses/useFile'
import {Act} from 'src/models/Profile/Acts/Act'

export const useProfileActSignStore = defineStore('act-sign', () => {
  const fileUse = useFile()
  const {
    base64ToSigFile
  } = fileUse

  const {
    post,
    response,
    status,
    loading,
    error,
    errors,
  } = usePostApi<FormData, FormData>()

  const {
    get,
    status: getStatus,
    response: getResponse,
  } = useGetApi<{base64: string} | undefined>()

  const success = ref(false)
  const fileForSign = ref<string | undefined>('')

  const sign = async (act: Act, signedFile: string, certName: string) => {
    certName = certName.replace(' ', '_')
    const filename = `${act.month}_${act.year}_${certName}.pdf.sig`
    const data = FormHelper.getFormDataFromObject({
      file: base64ToSigFile(signedFile, filename)
    })
    await post(`v1/acts/${act.id}/sign`, data)
    if (status.value === 200 && response.value) {
      success.value = true
    }
  }

  const getFileForSign = async (id: string) => {
    await get(
      `v1/acts/${id}/file/base64`,
    )

    if (getStatus.value === 200 && getResponse) {
      fileForSign.value = getResponse.value?.base64
    }
  }

  return {
    loading,
    response,
    status,
    error,
    errors,
    success,
    fileForSign,
    getFileForSign,
    sign,
  }
})
