export enum ApplicationSubStatusEnum {
  draft = 'draft',
  talend_loaded = 'talend_loaded',
  go_loaded = 'go_loaded',
  forming_errors = 'forming_errors',
  accepted = 'accepted',
  checking = 'checking',
  completion = 'completion',
  new = 'new',
  client_signed = 'client_signed',
  data_request = 'data_request',
  data_request_executed = 'data_request_executed',
  risks_assessment = 'risks_assessment',
  risks_request = 'risks_request',
  risks_request_executed = 'risks_request_executed',
  bg_project_preparation = 'bg_project_preparation',
  bg_project_customer_agreement = 'bg_project_customer_agreement',
  bg_project_bank_agreement = 'bg_project_bank_agreement',
  payment_waiting = 'payment_waiting',
  deferred_conditions_execution_waiting = 'deferred_conditions_execution_waiting',
  deferred_conditions_executed = 'deferred_conditions_executed',
  paid = 'paid',
  guarantee_released = 'guarantee_released',
  client_rejected = 'client_rejected',
  system_rejected = 'system_rejected',
  bank_rejected = 'bank_rejected',
  bg_project_customer_agreement_request = 'bg_project_customer_agreement_request',
  bg_project_bank_agreement_request = 'bg_project_bank_agreement_request',
  guarantee_released_request = 'guarantee_released_request',
  send_2_bank = 'send_2_bank',
  in_bank = 'in_bank',
  data_request_sign = 'data_request_sign',
  risks_request_sign = 'risks_request_sign',
  bg_project_bank_agreement_request_sign = 'bg_project_bank_agreement_request_sign',
  deferred_conditions_execution_waiting_sign = 'deferred_conditions_execution_waiting_sign',
  guarantee_released_request_sign = 'guarantee_released_request_sign',
  bg_project_customer_agreement_request_sign = 'bg_project_customer_agreement_request_sign',
  //bg_project_customer_agreement_sign = 'bg_project_customer_agreement_sign',
  archive = 'archive',
  commission_approval = 'commission_approval',
  waiting_required = 'waiting_required',
}
