import {computed, ref} from 'vue'
import {ApplicationsGridItem} from 'src/models/Applications/ApplicationsGridItem'
import {useGetApi} from 'src/uses/Api/useGetApi'
import {ApplicationNewCloneProducts} from 'src/models/Applications/New/ApplicationNewCloneProducts'

export const useApplicationNewClone = () => {
  const {
    loading,
    get,
    status,
    response,
  } = useGetApi<ApplicationNewCloneProducts>()

  const selectedOriginalItems = ref<ApplicationsGridItem[]>([])
  const selectedOriginalItem = computed(() => {
    if (!selectedOriginalItems.value.length) {
      return undefined
    }

    return selectedOriginalItems.value[0]
  })

  const getData = async () => {
    if (!selectedOriginalItem.value) {
      return undefined
    }

    await get(
      `v2/orders/${selectedOriginalItem.value.id}/selection/products`)

    if (status.value === 200 && response.value) {
      return response.value
    }

    return undefined
  }

  return {
    loading,
    status,
    selectedOriginalItems,
    selectedOriginalItem,
    getData,
  }
}
