<template>
  <div class="row q-mb-md q-col-gutter-md">
    <div class="col-8">
      <news-list-filters
        :loading="loading"
        @update:filters="updateFilters"
      />
    </div>
    <div class="col-4 text-right">
      <q-btn
        color="primary"
        style="padding: 13px 23px;"
        icon-right="svguse:icons.svg?2#app-plus-1"
        size="md"
        label="Добавить новость"
        no-caps
        :to="{name: 'settings-news-create'}"
      />
    </div>
  </div>
  <div class="SettingsNewsGrid--table">
    <q-table
      v-model:pagination="gridPagination"
      class="sticky-header-table q-ma-sm"
      :rows="items"
      :columns="columns"
      :loading="loading"
      :rows-per-page-options="perPageOptions"
      row-key="index"
      wrap-cells
      hide-bottom
      @request="onRequest"
    >
      <template #body-cell-title="props">
        <q-td :props="props">
          <div
            class="ellipsis"
            style="max-width: 300px"
          >
            {{ props.row.title }}
          </div>
        </q-td>
      </template>
      <template #body-cell-topic="props">
        <q-td :props="props">
          {{ NewsDescriptions.getNewsTopicDescription(props.row.topic) }}
        </q-td>
      </template>
      <template #body-cell-publication-date="props">
        <q-td :props="props">
          {{ props.row.publication_date }}
        </q-td>
      </template>
      <template #body-cell-status="props">
        <q-td :props="props">
          {{ NewsDescriptions.getNewsPublicationTypeDescription((props.row.status)) }}
        </q-td>
      </template>
      <template #body-cell-actions="props">
        <q-td :props="props">
          <q-btn
            size="sm"
            color="primary"
            icon="svguse:icons.svg?2#app-pencil-alt-bold"
            round
            :to="{name: 'settings-news-update', params: {newsId: props.row.id}}"
            flat
          />
          <settings-news-delete-modal
            :title="props.row.title"
            :item-id="props.row.id"
            @change="updateFilters"
          />
        </q-td>
      </template>
    </q-table>
    <pagination-component
      class="q-mb-md"
      :pagination="gridPagination"
      :per-page-options="perPageOptions"
      @request="onRequest"
      @refresh="load"
    />
  </div>
</template>

<script setup lang="ts">

import {storeToRefs} from 'pinia'
import {onBeforeMount, onBeforeUnmount, watch, ref} from 'vue'
import {useNewsListStore} from 'src/stores/News/useNewsListStore'
import {useNewsListFiltersStore} from 'src/stores/News/useNewsListFiltersStore'
import NewsListFilters from 'src/components/News/NewsListFilters.vue'
import {NewsDescriptions} from 'src/models/News/NewsDescriptions'
import {useGridSettings} from 'src/uses/Grid/useGridSettings'
import {NewsGridColumns} from 'src/components/Settings/News/NewsGridColumns'
import PaginationComponent from 'components/PaginationComponent.vue'
import SettingsNewsDeleteModal from 'components/Settings/News/SettingsNewsDeleteModal.vue'
import {GridPagination, RequestProp} from 'src/models/Grid/Pagination'

const paginationPage = ref(1)
const filtersStore = useNewsListFiltersStore()
const newsListStore = useNewsListStore()
const {
  items,
  loading,
  gridPagination,
  perPageOptions,
} = storeToRefs(newsListStore)

const {
  load,
  setPage,
  onRequestPagination,
} = newsListStore

onBeforeMount(async () => {
  await load()
})

const onRequest = ({pagination}: RequestProp) => {
  onRequestPagination(pagination as GridPagination)
  load()
}

watch(
  paginationPage,
  () => {
    setPage(paginationPage.value)
    load()
  },
  {deep: true}
)

const {columns} = useGridSettings('settings-news-grid', NewsGridColumns)

onBeforeUnmount(() => {
  newsListStore.$reset()
  newsListStore.$dispose()
  filtersStore.$reset()
  filtersStore.$dispose()
})

const updateFilters = () => {
  setPage(1)
  load()
}

</script>
<style lang="scss">
.SettingsNewsGrid--table {
  margin: 0 -24px -24px;
  background: $blue-1;
  padding: 0 24px 24px;

  table {
    position: relative;

    tr {
      td {
        width: 150px;

        &:first-child {
          width: auto;
          white-space: normal;
        }
      }
    }
  }

  .createButton {
    .icon {
      background: white;
      border-radius: 6px;
      position: absolute;
      right: 10px;
      line-height: 0;
    }

    .q-btn {
      border-radius: 6px;
    }
  }
}
</style>
