import {useGetApi} from 'src/uses/Api/useGetApi'
import {useFile} from 'src/uses/useFile'
import {ApplicationsGridItemDocument} from 'src/models/Applications/ApplicationsGridItem'

export const useApplicationGridDownloadDocument = () => {
  const {
    error,
    errors,
    get,
    status,
    response,
    loading,
    headers,
  } = useGetApi<Blob>(true, 'blob')

  const {
    getFilenameFromHttpResponseBlob,
    downloadFile,
  } = useFile()

  const download = async (
    document: ApplicationsGridItemDocument,
    appId: string,
    isSign = false,
  ) => {
    if (!appId) {
      return
    }

    const docType = isSign ? 'sign' : 'file'

    await get(
      `v1/control-center/${appId}/documents/${docType}/${document.type}/${document.code}`
    )

    if (status.value === 200 && response.value) {
      const fileName = getFilenameFromHttpResponseBlob(response.value, headers.value, true) || 'file'
      await downloadFile(response.value, fileName)
    }
  }

  return {
    error,
    errors,
    status,
    response,
    loading,
    download,
  }
}
