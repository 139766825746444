import {useGetApi} from 'src/uses/Api/useGetApi'
import {CalculatePercentResponseData} from 'src/models/Applications/Tariff/CalculatePercentResponseData'

export const useCalculatePercent = () => {
  const {
    loading,
    get,
    status,
    response,
  } = useGetApi<CalculatePercentResponseData>()

  const calculate = async (id :string, amount: number) => {
    await get(
      `v1/control-center/${id}/tariff/percent?amount=${amount}`,
    )

    if (status.value === 200 && response && response.value) {
      return response.value?.percent || 0
    }

    return 0
  }

  return {
    calculate,
    loading,
  }
}
