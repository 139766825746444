import {useDialog} from 'src/uses/Dialogs/useDialog'
import {ApplicationSubStatusEnum} from 'src/models/Applications/ApplicationSubStatusEnum'
import {useProfileStore} from 'src/stores/Login/useProfileStore'
import {Ref} from 'vue'
import {ChangeStatusPayload} from 'src/models/Status/ChangeStatusPayload'
import {useHeaderDataFromStore} from 'src/uses/Applications/ControlCenter/useHeaderDataFromStore'

export const useAgreementConfirm = (payload: Ref<ChangeStatusPayload | undefined>, realChangeStatus: () => Promise<void>) => {
  const {
    dialog: agreementConfirmDialog,
    show: showAgreementConfirmDialog,
    hide: hideAgreementConfirmDialog,
  } = useDialog()

  const {
    isOperator,
    isAgent,
    isEmployee,
    isHeadEmployee,
    isAgentManager,
    isAdmin,
  } = useProfileStore()

  const {
    getHeaderData
  } = useHeaderDataFromStore()

  const needShowDialog = async (currentSubStatus: ApplicationSubStatusEnum) => {
    if (!payload.value) {
      return false
    }
    const headerData = await getHeaderData(payload.value.id)
    if (!headerData) {
      return false
    }

    const validRole = isOperator()
      || isAgent()
      || isEmployee()
      || isHeadEmployee()
      || isAgentManager()
      || isAdmin()

    return validRole
      && !!payload.value?.from_chat
      && ApplicationSubStatusEnum.bg_project_customer_agreement === currentSubStatus
      && !!headerData.is_guarantee_data_changed
  }

  const onAgreementConfirm = async () => {
    if (!payload.value) {
      return
    }

    await realChangeStatus()
    hideAgreementConfirmDialog()
  }

  return {
    agreementConfirmDialog,
    showAgreementConfirmDialog,
    hideAgreementConfirmDialog,
    needShowDialog,
    onAgreementConfirm,
  }
}
