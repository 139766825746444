<template>
  <q-intersection
    transition="scale"
    once
  >
    <q-select
      v-model="selected"
      class="field-with-top-label ApplicationNewEmployees q-mb-md"
      :options="filteredItems"
      :loading="loading"
      :option-label="optionLabel"
      :option-value="optionValue"
      label="Сотрудник агента"
      hide-dropdown-icon
      outlined
      hide-bottom-space
      hide-selected
      use-input
      fill-input
      emit-value
      dense
      label-slot
      @filter="onFilter"
    >
      <template #prepend>
        <q-icon name="svguse:icons.svg?2#app-search" />
      </template>
      <template #label>
        <div class="text-weight-medium">
          Сотрудник агента
        </div>
      </template>
      <template #option="scope">
        <q-item
          v-bind="scope.itemProps"
          class="q-py-sm q-px-md text-no-wrap ellipsis"
        >
          <div class="row items-center InnOption">
            <div class="text-grey-9 q-mr-sm">{{ scope.opt.inn }}</div>
            <div class="text-grey-6">{{ scope.opt.fio }}</div>
          </div>
        </q-item>
      </template>
    </q-select>
  </q-intersection>
</template>

<script setup lang="ts">
import {computed, ref, watchEffect} from 'vue'
import {storeToRefs} from 'pinia'
import {useApplicationsNew} from 'src/stores/Applications/ApplicationsNew/useApplicationsNew'
import {QSelect} from 'quasar'
import {ApplicationNewEmployee} from 'src/models/Applications/ApplicationNewEmployeeResponse'
const applicationsNewStore = useApplicationsNew()
const {
  loadEmployees: load,
} = applicationsNewStore

const {
  selectedEmployee: selected,
  employees: items,
  employeesLoading: loading,
  selectedAgent,
} = storeToRefs(applicationsNewStore)

const search = ref()

const filteredItems = computed(() => {
  if (!search.value) {
    return items.value
  }

  return items.value
    .filter(item => item.fio.toLowerCase().includes(search.value.toLowerCase()))
})

const optionValue = (opt: ApplicationNewEmployee) => {
  return opt
}

const optionLabel = (opt: ApplicationNewEmployee) => {
  return opt.fio
}

const onFilter = (
  input: string,
  update: (fn: () => void) => void
) => {
  update(async () => {
    search.value = input
  })
}

watchEffect(() => {
  if (!selectedAgent.value) {
    return
  }

  load(selectedAgent.value.id)
})
</script>

<style lang="scss">
body {
  .q-field {
    &.ApplicationNewEmployees {
      padding-top: 32px !important;
      padding-bottom: 2px;

      .q-field__label {
        top: -32px !important;
        left: -49px !important;
        font-size: 16px !important;
        color: $grey-9;
      }

      input {
        margin-top: 2px;
      }

      input::-webkit-input-placeholder {
        opacity: 1 !important;
        color: $grey-8 !important;
      }
    }
  }
}
</style>
