<template>
  <div
    :style="!props.mobile ? 'width: 105px' : ''"
    :class="{row: props.mobile, 'no-wrap': props.mobile}"
  >
    <div :class="{'ellipsis-2-lines col': true, 'q-pr-sm': props.mobile}">
      {{ item.client.title }}
      <q-tooltip
        v-if="!props.onlyText"
        :delay="1000"
        max-width="300px"
      >
        {{ item.client.title }}
      </q-tooltip>
    </div>
    <div>
      <div class="row no-wrap">
        <div>{{ item.client.inn }}</div>
        <div
          v-if="!props.onlyText"
          style="position: relative; min-width: 35px"
        >
          <copy-to-clipboard
            v-if="item.client.inn"
            style="top: -8px; position: absolute;"
            :text="item.client.inn"
          >
            <q-btn
              class="q-ml-xs"
              icon="svguse:icons.svg?2#app-document-duplicate"
              :size="!props.mobile ? '10px' : '14px'"
              color="blue-grey-4"
              round
              unelevated
              flat
            >
              <q-tooltip
                anchor="top middle"
                self="bottom middle"
              >Копировать</q-tooltip>
            </q-btn>
          </copy-to-clipboard>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {computed, withDefaults} from 'vue'
import {ApplicationsGridItem} from 'src/models/Applications/ApplicationsGridItem'
import CopyToClipboard from 'components/CopyToClipboard.vue'
import {ApplicationsGridKikItem} from 'src/models/Applications/ApplicationsGridKikItem'

const props = withDefaults(
  defineProps<{
    item: ApplicationsGridItem | ApplicationsGridKikItem
    mobile?: boolean
    onlyText?: boolean
  }>(),
  {
    mobile: false,
    onlyText: false,
  }
)
const item = computed(() => props.item)
</script>
