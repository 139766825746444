import {usePostApi} from 'src/uses/Api/usePostApi'
import {ref} from 'vue'
import {EnvCodeEnum, useEnvStore} from 'src/stores/useEnvStore'
import {DadataBank} from 'src/models/Dadata/DadataBank'

export interface DadataBankItems {
  data: DadataBank
}

export interface DadataBikResponse {
  suggestions: DadataBankItems[]
}

export interface DadataBikRequest {
  query: string,
}

export const useDadataBik = () => {
  const {
    error,
    errors,
    status,
    post,
    response,
    loading,
    setHeader,
  } = usePostApi<DadataBikResponse, DadataBikRequest>()

  const {getEnvValue} = useEnvStore()

  const dadataBank = ref<DadataBank | null>()

  const load = async (bik: string) => {
    if (!bik) {
      return
    }

    setHeader('Authorization', `Token ${getEnvValue(EnvCodeEnum.VUE_APP_DADATA_KEY)}`)
    const url = `${getEnvValue(EnvCodeEnum.VUE_APP_DADATA_URL)}/suggestions/api/4_1/rs/findById/bank`
    await post(url, {query: bik})

    if (status.value !== 200 || !response.value?.suggestions) {
      dadataBank.value = null
      return
    }

    dadataBank.value = response?.value?.suggestions[0]?.data
  }

  return {
    error,
    errors,
    status,
    response,
    loading,
    dadataBank,
    load,
  }
}
