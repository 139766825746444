<template>
  <q-form
    @submit="handleSubmit"
  >
    <div style="font-size: 16px; padding-top: 80px;">
      <q-icon
        name="svguse:icons.svg?2#app-identification"
        color="blue-grey-4"
        size="sm"
      />
      Войдите в аккаунт
      <template v-if="!$q.platform.is.nativeMobileWrapper">
        или
        <router-link :to="{name: 'registration'}">
          зарегистрируйтесь
        </router-link>
      </template>
    </div>
    <div class="row no-wrap items-end q-mt-xl">
      <div class="q-gutter-y-lg col">
        <q-input
          v-model="loginRequestData.username"
          class="field-with-top-label noStylesForAutoCompleteCredentials"
          :loading="loading"
          :error="isFirstUsernameBlurRaised && hasError('username')"
          :error-message="getErrorMessage('username')"
          label="E-mail или логин"
          debounce="700"
          autocomplete="username"
          outlined
          hide-hint
          clearable
          hide-bottom-space
          dense
          @focus="clearError('username')"
          @blur="isFirstUsernameBlurRaised = true; validation.username.$validate()"
        />
        <q-input
          v-model="loginRequestData.password"
          class="field-with-top-label noStylesForAutoCompleteCredentials"
          :loading="loading"
          :error="hasError('password')"
          :error-message="getErrorMessage('password')"
          :type="passwordType ? 'password' : 'text'"
          label="Пароль"
          autocomplete="current-password"
          outlined
          hide-hint
          clearable
          hide-bottom-space
          dense
          @focus="clearError('password')"
          @blur="validation.password.$validate()"
        >
          <template #append>
            <q-icon
              class="cursor-pointer"
              :name="passwordType ? 'svguse:icons.svg?2#app-eye-off' : 'svguse:icons.svg?2#app-eye'"
              @click="passwordType = !passwordType"
            />
          </template>
        </q-input>
        <template v-if="inactive">
          <q-field
            :model-value="loginRequestData.sublicense_agreement"
            class="field-with-top-label no-bg field-wrapper q-pt-none q-mt-none"
            style="padding-top: 0 !important;"
            :error="hasError('sublicense_agreement')"
            :error-message="getErrorMessage('sublicense_agreement')"
            hide-bottom-space
            borderless
            dense
          >
            <q-checkbox
              v-model="loginRequestData.sublicense_agreement"
              class="text-grey-9"
              @change="clearError('privacy_policy'); validation.sublicense_agreement.$validate()"
            >
              С условиями
              <a
                target="_blank"
                href="https://gosoblako.com/sublicense"
                @click.capture.stop
              >
                Сублицензионного соглашения
              </a>
              ознакомлен
            </q-checkbox>
          </q-field>
          <q-field
            :model-value="loginRequestData.privacy_policy"
            class="field-with-top-label no-bg field-wrapper q-pt-none q-mt-none"
            style="padding-top: 0 !important;"
            :error="hasError('privacy_policy')"
            :error-message="getErrorMessage('privacy_policy')"
            hide-bottom-space
            borderless
            dense
          >
            <q-checkbox
              v-model="loginRequestData.privacy_policy"
              class="text-grey-9"
              @change="clearError('privacy_policy'); validation.privacy_policy.$validate()"
            >
              С
              <a
                target="_blank"
                href="https://gosoblako.com/policy"
                @click.capture.stop
              >
                Политикой конфиденциальности
              </a>
              ознакомлен
            </q-checkbox>
          </q-field>
        </template>
        <div class="q-pl-none q-pb-md">
          <router-link
            v-if="!$q.platform.is.mobile"
            class="text-grey-7"
            :to="{name: 'password-recovery'}"
            style="text-decoration: underline"
          >
            Забыли пароль?
          </router-link>
          <a
            v-else
            class="text-grey-7"
            style="text-decoration: underline"
            @click="recoveryModal = true"
          >
            Забыли пароль?
          </a>
        </div>
      </div>
    </div>
    <div
      v-if="error"
      class="q-field--error text-red q-mt-lg"
    >
      {{ error }}
    </div>
    <div class="q-mt-xl">
      <q-btn
        :loading="loading"
        :disable="validation.$invalid"
        label="Войти"
        :class="{'full-width': $q.platform.is.mobile}"
        style="height: 50px;"
        color="primary"
        type="submit"
        icon-right="svguse:icons.svg?2#app-arrow-narrow-right"
        padding="13px 30px"
        unelevated
        no-caps
      />
    </div>
  </q-form>
  <q-dialog
    v-model="recoveryModal"
    position="bottom"
    full-width
  >
    <q-card class="q-ma-md">
      <q-card-section>
        <div class="text-h5">
          <strong>Восстановление пароля</strong>
        </div>
      </q-card-section>
      <q-card-section>
        <password-recovery
          :has-back-btn="false"
          @close="recoveryModal = false"
          @success="recoveryModal = false; recoverySuccessModal = true"
        />
      </q-card-section>
    </q-card>
  </q-dialog>
  <password-recovery-success-message v-model="recoverySuccessModal" />
</template>

<script lang="ts" setup>
import {useLoginStore} from 'src/stores/Login/useLoginStore'
import {storeToRefs} from 'pinia'
import {onBeforeUnmount, ref} from 'vue'
import {useValidation} from 'src/uses/useValidation'
import {useQuasar} from 'quasar'
import {useDialog} from 'src/uses/Dialogs/useDialog'
import PasswordRecovery from 'components/Login/PasswordRecovery.vue'
import PasswordRecoverySuccessMessage from 'components/Login/PasswordRecoverySuccessMessage.vue'

const loginStore = useLoginStore()
const {
  loginRequestData,
  error,
  loading,
  validation,
  errors,
  inactive,
} = storeToRefs(loginStore)
const {doLogin} = loginStore

const {
  hasError,
  getErrorMessage,
  clearError,
} = useValidation(validation, errors)

const {
  dialog: recoveryModal,
} = useDialog()

const {
  dialog: recoverySuccessModal,
} = useDialog()

const $q = useQuasar()

const isFirstUsernameBlurRaised = ref(false)

onBeforeUnmount(() => {
  loginStore.$reset()
  loginStore.$dispose()
})

const passwordType = ref(true)

const handleSubmit = async () => {
  await validation.value.$validate()
  if (validation.value.$invalid) {
    return
  }

  await doLogin()
}
</script>
