import {defineStore} from 'pinia'
import {useDeleteApi} from 'src/uses/Api/useDeleteApi'

export const useNewsDeleteStore = defineStore('news-delete', () => {
  const {
    deleteRequest,
    response,
    status,
    loading,
    error,
    errors,
  } = useDeleteApi<never>()

  const deleteNews = async (newsId: string) => {
    await deleteRequest(`admin/news/${newsId}`)
  }

  return {
    loading,
    response,
    status,
    error,
    errors,
    deleteNews,
  }
})
