import {GridColumnEditableSettings} from 'src/models/Grid/GridColumnSettings'

export const ContractorsGridColumns: GridColumnEditableSettings[] = [
  {
    field: '',
    name: 'title',
    required: true,
    label: 'Название',
    align: 'left',
    classes: 'text-no-wrap',
    visible: true,
    editable: true,
    order: 1,
    sortable: true,
  },
  {
    field: '',
    name: 'inn',
    required: true,
    label: 'ИНН',
    align: 'left',
    classes: 'text-no-wrap',
    visible: true,
    editable: true,
    order: 2,
    sortable: true,
  },
  {
    field: '',
    name: 'address',
    required: true,
    label: 'Адрес',
    align: 'left',
    classes: 'text-no-wrap',
    visible: true,
    editable: true,
    order: 3,
    sortable: true,
  },
  {
    field: '',
    name: 'manager_fio',
    required: true,
    label: 'ФИО менеджера',
    align: 'left',
    classes: 'text-no-wrap',
    visible: true,
    editable: true,
    order: 4,
    sortable: true,
  },
  {
    field: '',
    name: 'actions',
    label: '',
    required: true,
    align: 'center',
    visible: true,
    editable: false,
    order: 6,
  },
]
