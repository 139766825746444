import {useDialog} from 'src/uses/Dialogs/useDialog'
import {useProfileStore} from 'src/stores/Login/useProfileStore'
import {Ref} from 'vue'
import {ChangeStatusPayload} from 'src/models/Status/ChangeStatusPayload'
import {ApplicationKikSubStatusEnum} from 'src/models/Applications/ApplicationKikSubStatusEnum'

export const useBankRejectKik = (payload: Ref<ChangeStatusPayload | undefined>, realChangeStatus: () => Promise<void>) => {
  const {
    dialog: bankRejectDialog,
    show: showBankRejectDialog,
    hide: hideBankRejectDialog,
  } = useDialog()

  const {
    isBankEmployee,
  } = useProfileStore()

  const needShowDialog = async () => {
    return isBankEmployee()
      && payload.value
      && ApplicationKikSubStatusEnum.bank_rejected === payload.value?.applicationStatus
  }

  const onSaveBankReject = async (comment: string) => {
    if (!payload.value) {
      return
    }

    payload.value.comment = comment

    await realChangeStatus()
    hideBankRejectDialog()
  }

  return {
    bankRejectDialog,
    showBankRejectDialog,
    hideBankRejectDialog,
    needShowDialog,
    onSaveBankReject,
  }
}
