<template>
  <div class="row q-col-gutter-sm">
    <div class="col-4">
      <q-select
        v-model="filters.status"
        label="Статус"
        :options="statuses"
        style="min-width: 100px"
        map-options
        option-value="id"
        option-label="title"
        outlined
        hide-hint
        hide-bottom-space
        dense
        emit-value
        @update:model-value="onSubmit"
      />
    </div>

    <div class="col-4">
      <q-select
        v-model="filters.year"
        label="Год"
        :options="years"
        map-options
        option-value="id"
        option-label="title"
        outlined
        hide-hint
        hide-bottom-space
        dense
        emit-value
        @update:model-value="onSubmit"
      />
    </div>

    <div class="col-4">
      <q-select
        v-model="filters.month"
        label="Месяц"
        :options="months"
        map-options
        option-value="id"
        option-label="title"
        outlined
        hide-hint
        hide-bottom-space
        dense
        emit-value
        @update:model-value="onSubmit"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import {onBeforeMount} from 'vue'
import {storeToRefs} from 'pinia'
import {useProfileActsGridFilters} from 'src/stores/Profile/Acts/useProfileActsGridFiltersStore'

const emit = defineEmits<{
  (e: 'update:filters'): void,
}>()

const filtersStore = useProfileActsGridFilters()
const {
  filters,
  statuses,
  years,
  months,
} = storeToRefs(filtersStore)

const {
  generateStatuses,
  generateYears,
  generateMonths,
} = filtersStore
onBeforeMount(() => {
  generateStatuses()
  generateYears()
  generateMonths()
})

const onSubmit = () => {
  emit('update:filters')
}
</script>
