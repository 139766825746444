<template>
  <div class="relative-position">
    <div
      v-if="props.modelValue"
      ref="wrapper"
      class="z-top absolute"
      :style="`margin-top: -${bottom}px`"
    >
      <q-card
        class="bg-blue-grey-10 PasswordHelp"
        dark
      >
        <q-card-section>
          <div class="q-mb-sm">
            <strong>Пароль должен содержать:</strong>
          </div>
          <div
            v-for="(row, key) in rows"
            :key="key"
          >
            <q-icon
              :style="{opacity: row.validator(props.password) ? 1 : 0}"
              size="20px"
              name="svguse:icons.svg?2#app-check-circle-bold"
              color="green"
            />
            {{ row.message }}
          </div>
        </q-card-section>
        <q-linear-progress
          :value="progress"
          color="green"
        />
      </q-card>
    </div>
    <div>
      <slot/>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {computed, ref} from 'vue'
import {
  validateMaxLength,
  validateMinLength,
  validateNumbers,
  validateSymbols,
  validateLowerCase,
  validateUpperCase
} from 'src/validators/passwordValidator'

const props = defineProps<{
  modelValue: boolean,
  password: string,
}>()

const rows = ref<{validator: (value: string) => boolean, message: string}[]>([
  {
    validator: (value: string) => validateMinLength(value) && validateMaxLength(value),
    message: 'Длина 8-14 символов',
  },
  {
    validator: validateNumbers,
    message: 'Хотя бы одна цифра',
  },
  {
    validator: validateLowerCase,
    message: 'Строчные буквы',
  },
  {
    validator: validateUpperCase,
    message: 'Заглавные буквы',
  },
  {
    validator: validateSymbols,
    message: 'Хотя бы один символ (.,:;?!*+%-&lt;&gt;@[]/\\_{}$#)',
  },
])

const wrapper = ref<Element>()

const bottom = computed(() => (wrapper.value ? wrapper.value?.clientHeight : 0) - 25)

const progress = computed(() => {
  return rows.value.filter(row => row.validator(props.password)).length / rows.value.length
})
</script>

<style lang="scss">
.PasswordHelp {
  border-radius: 6px;
}
</style>
