<template>
  <page-block-wrapper class="ApplicationNew">
    <q-intersection
      transition="scale"
      once
    >
      <div class="text-h5 q-mb-md">
        <strong>Здравствуйте!</strong>
      </div>
    </q-intersection>
    <application-new-email-dialog v-if="showSelectAgent" />
    <application-new-agents v-if="showSelectAgent" />
    <application-new-employees v-if="showSelectEmployee" />
    <application-new-inn v-if="showSelectInn" />
    <application-new-clone v-if="mode === ApplicationNewModeEnum.standard && showSelectInn" />
    <application-new-clone-grid v-if="mode === ApplicationNewModeEnum.select && showSelectInn" />
    <template v-if="showTabs">
      <q-intersection
        transition="scale"
        once
      >
        <div class="text-subtitle1 text-weight-medium q-mt-md">
          Выберите необходимый банковский продукт
        </div>
        <application-new-products-tabs
          v-if="productData"
          v-model="tab"
          :product-data="productData"
        />
      </q-intersection>
      <div
        v-if="tab"
        class="TabPanelsWrapper white-bg-field"
      >
        <q-tab-panels v-model="tab">
          <q-tab-panel
            class="q-pa-none"
            :name="productEnum.EBG"
          >
            <div class="q-mx-lg q-pb-lg">
              <filled-progres :model-value="percentage" />
              <application-new-next-field-helper />
              <error-helper
                class="q-mb-sm"
                :error="rntError"
              />
              <application-new-ebg-form />
              <application-new-beneficiaries-list v-if="!oneBg && beneficiaries.length > 1 && provisionType !== ProvisionTypeEnum.Participation" />
              <application-new-select-banks
                ref="productsRef"
                v-if="percentage === 100 && !rntError"
                class="q-mb-md"
              />
              <application-new-send v-if="percentage === 100 && selectedProducts.length > 0 && !rntError" />
            </div>
          </q-tab-panel>
          <q-tab-panel
            class="q-pa-none"
            :name="productEnum.KIK"
          >
            <div class="q-mx-lg q-pb-lg">
              <filled-progres :model-value="creditPercentage" />
              <application-new-kik-next-field-helper />
              <application-new-kik-form />
              <application-new-select-banks
                ref="creditProductsRef"
                v-if="creditPercentage === 100"
                class="q-mb-md"
                kik
              />
              <application-new-send v-if="creditPercentage === 100 && creditSelectedProducts.length > 0 && !rntError" />
            </div>
          </q-tab-panel>
        </q-tab-panels>
      </div>
    </template>
  </page-block-wrapper>
  <page-block-wrapper class="MainPageNews">
    <news-list-widget
      :count-of-big="1"
      :count="3"
    />
  </page-block-wrapper>
</template>

<script setup lang="ts">
import ApplicationNewInn from 'src/components/Applications/ApplicationNew/ApplicationNewInn.vue'
import {onUnmounted} from 'vue'
import {ProductEnum} from 'src/models/Applications/ProductEnum'
import ApplicationNewProductsTabs from 'src/components/Applications/ApplicationNew/ApplicationNewProductsTabs.vue'
import FilledProgres from 'src/components/FilledProgres.vue'
//import ApplicationNewNextFieldHelper from 'src/components/Applications/ApplicationNew/ApplicationNewNextFieldHelper.vue'
import PageBlockWrapper from 'src/components/PageBlockWrapper.vue'
import ErrorHelper from 'src/components/ErrorHelper.vue'
import ApplicationNewEbgForm from 'src/components/Applications/ApplicationNew/ApplicationNewEbgForm.vue'
import NewsListWidget from 'src/components/News/NewsListWidget.vue'
import ApplicationNewSelectBanks from 'src/components/Applications/ApplicationNew/ApplicationNewSelectBanks.vue'
import ApplicationNewSend from 'src/components/Applications/ApplicationNew/ApplicationNewSend.vue'
import {useApplicationsNew} from 'src/stores/Applications/ApplicationsNew/useApplicationsNew'
import {storeToRefs} from 'pinia'
import ApplicationNewBeneficiariesList
  from 'src/components/Applications/ApplicationNew/ApplicationNewBeneficiariesList.vue'
import ApplicationNewNextFieldHelper from 'src/components/Applications/ApplicationNew/ApplicationNewNextFieldHelper.vue'
import ApplicationNewAgents from 'src/components/Applications/ApplicationNew/ApplicationNewAgents.vue'
import ApplicationNewEmployees from 'src/components/Applications/ApplicationNew/ApplicationNewEmployees.vue'
import ApplicationNewEmailDialog from 'src/components/Applications/ApplicationNew/ApplicationNewEmailDialog.vue'
import {ProvisionTypeEnum} from 'src/models/Applications/ProvosionTypeEnum'
import ApplicationNewClone from 'components/Applications/ApplicationNew/ApplicationNewClone.vue'
import ApplicationNewCloneGrid from 'components/Applications/ApplicationNew/ApplicationNewCloneGrid.vue'
import {ApplicationNewModeEnum} from 'src/models/Applications/New/ApplicationNewModeEnum'
import ApplicationNewKikForm from 'components/Applications/ApplicationNew/ApplicationNewKikForm.vue'
import ApplicationNewKikNextFieldHelper
  from 'components/Applications/ApplicationNew/ApplicationNewKikNextFieldHelper.vue'

const productEnum = ProductEnum

const store = useApplicationsNew()

const {
  tab,
  showTabs,
  rntError,
  oneBg,
  beneficiaries,
  percentage,
  creditPercentage,
  selectedProducts,
  creditSelectedProducts,
  showSelectInn,
  showSelectAgent,
  showSelectEmployee,
  provisionType,
  mode,
  productData,
  creditProductsRef,
  productsRef,
} = storeToRefs(store)

onUnmounted(() => {
  store.$reset()
  store.$dispose()
})
</script>

<style lang="scss">
.ApplicationNew {
  .TabPanelsWrapper {
    background: $blue-1;
    margin: -20px -24px -24px -24px;
    padding-top: 24px;

    .q-tab-panels {
      background: transparent;
    }
  }
}
</style>
