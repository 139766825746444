import {App} from '@capacitor/app'
import {Capacitor} from '@capacitor/core'

export const useAndroidListeners = () => {
  const addListeners = async () => {
    if (Capacitor.getPlatform() === 'android') {
      await App.removeAllListeners()
      await App.addListener('backButton', (data) => {
        if (data.canGoBack) {
          window.history.back()
        } else {
          App.exitApp()
        }
      })
    }
  }

  addListeners()

  return {}
}
