import {usePostApi} from 'src/uses/Api/usePostApi'
import {ApplicationFormUpdateResponse} from 'src/models/Applications/Form/ApplicationFormUpdateResponse'
import {defineStore} from 'pinia'
import {ref} from 'vue'

export const useSendToCheck = defineStore(
  'applications-send-to-check',
  () => {
    const {
      error,
      errors,
      post,
      status,
      response,
      loading,
    } = usePostApi<ApplicationFormUpdateResponse, { orders: { id: string }[] }>()

    const lastAffectedSendToCheckPayload = ref<{
      applicationIds: string[],
    }>()

    const send = async (applicationIds: string[], multiOrderId: number) => {
      await post(
        `v2/orders/forms/${multiOrderId}/send`,
        {
          orders: applicationIds.map(id => ({id})),
        }
      )

      if (status.value === 200) {
        lastAffectedSendToCheckPayload.value = {
          applicationIds,
        }
      }
    }

    return {
      error,
      errors,
      status,
      response,
      loading,
      lastAffectedSendToCheckPayload,
      send,
    }
  })
