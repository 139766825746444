<template>
  <div
    v-if="props.canUpload"
    :class="classes"
    @dragover.prevent="onDragenter"
    @dragenter.prevent="onDragenter"
    @dragleave.prevent="onDragleave"
    @drop.prevent="onDrop"
  >
    <q-file
      ref="filePickerRef"
      v-model="selected"
      class="FilePicker"
      label="Standard"
      :accept="props.accept"
      :multiple="props.multiple"
      :max-file-size="props.maxFileSize"
      @rejected="onRejected"
    />
    <template v-if="$q.platform.is.mobile">
      <div class="text-grey-7 q-pb-sm">
        Поддерживаемые форматы: <strong>{{ computedAcceptHelp }} ({{ props.maxSize }})</strong>
      </div>
      <q-btn
        class="ApplicationItemUpload--button"
        :label="props.label"
        size="md"
        color="primary"
        :icon-right="props.iconRight"
        unelevated
        no-caps
        @click="selectFile"
      />
    </template>
    <div
      v-if="!$q.platform.is.mobile"
      class="ApplicationItemUpload--upload row q-pa-md cursor-pointer items-center"
      @click="selectFile"
    >
      <q-icon
        name="svguse:icons.svg?2#app-document-add-bold"
        color="blue-grey-5"
        size="md"
      />
      <div class="text-blue-grey-5">
        <strong>Нажмите здесь</strong> {{ props.inputText }}
      </div>
      <div class="text-blue-grey-3 q-pl-sm">{{ computedAcceptHelp }} ({{ props.maxSize }})</div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {computed, ref, withDefaults, watchEffect} from 'vue'
import {QFile, useQuasar} from 'quasar'
import {useNotifyErrorsStore} from 'src/stores/useNotifyErrorsStore'
import {useDataSize} from 'src/uses/useDataSize'

const props = withDefaults(
  defineProps<{
    accept?: string
    canUpload?: boolean
    inputText?: string
    multiple?: boolean
    maxSize?: string
    maxFileSize?: number
    label?: string
    iconRight?: string
  }>(),
  {
    accept: '.xls, .xlsx, .doc, .docx, .pdf, .jpeg, .jpg, .png, .txt, .sig, .sgn, .p7s, .zip, .rar, .xml',
    multiple: false,
    canUpload: true,
    inputText: 'или перетащите файлы в эту область для загрузки',
    maxSize: 'макс.25Mb',
    maxFileSize: 26214400,
    label: 'Загрузить документ',
    iconRight: 'svguse:icons.svg?2#app-document-add-bold',
  }
)

const emit = defineEmits<{
  (e: 'file:selected', file: File): void,
}>()

const {
  addErrorMessage,
} = useNotifyErrorsStore()

const {getSizeString} = useDataSize()

const filePickerRef = ref<QFile>()
const selected = ref<File | FileList>()
const readyToDrag = ref(false)
const $q = useQuasar()

const computedAcceptHelp = computed(() => props.accept
  .replaceAll('.', '')
  .toUpperCase()
)

const classes = computed(() => {
  return {
    FileSelectComponent: true,
    readyToDrag: readyToDrag.value,
  }
})

const onRejected = (rejectedEntries: {failedPropValidation: string, file: File}[]) => {
  const accept = rejectedEntries
    .filter(e => e.failedPropValidation === 'accept')

  const maxSize = rejectedEntries
    .filter(e => e.failedPropValidation === 'max-file-size')

  if (maxSize.length) {
    addErrorMessage(`Превышен максимально допустимый размер файла (${getSizeString(props.maxFileSize)}).`)
  }

  if (accept.length) {
    addErrorMessage('Недопустимый формат файла.')
  }
}

const onDragenter = () => {
  readyToDrag.value = true
}

const onDragleave = () => {
  readyToDrag.value = false
}

const onDrop = (event: DragEvent) => {
  if (!event.dataTransfer || !event.dataTransfer.files) {
    return
  }

  filePickerRef.value?.addFiles([...Array.from(event.dataTransfer.files)])
}

const selectFile = (event: Event) => {
  filePickerRef.value?.pickFiles(event)
}

watchEffect(() => {
  if (!selected.value) {
    return
  }

  if (props.multiple) {
    Array.from((selected.value as FileList))
      .forEach(file => {
        emit('file:selected', file)
      })
  } else {
    emit('file:selected', selected.value as File)
  }

  selected.value = undefined
})
</script>

<style lang="scss">
.FileSelectComponent {
  border-radius: 6px;
  position: relative;
  overflow: hidden;

  .ApplicationItemUpload--upload {
    border: 1px dashed $blue-grey-3;
    border-radius: 10px;
  }

  &.readyToDrag {
    .ApplicationItemUpload--upload {
      opacity: 0.9;
      border: 1px dashed $blue-grey-5;
    }
  }
}
</style>
