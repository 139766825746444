<template>
  <login-header-wrapper v-if="data">
    <div class="row items-center no-wrap">
      <div class="col">
        <div class="text-h6 LoginHeader--header ellipsis">
          <strong>{{ props.label }}</strong>
        </div>
      </div>
      <div>
        <div class="row items-center">
          <div class="q-px-md q-py-xs icon">
            <q-icon
              name="svguse:icons.svg?2#app-support-head"
              color="blue-grey-4"
              size="24px"
            />
          </div>
          <div class="col text-no-wrap q-px-md supportData">
            <a href="tel:+74991101642">
              {{ data.phone }}
            </a>
            <br>
            <a href="mailto:support@gosoblako.com">
              <span class="text-grey">{{ data.email }}</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </login-header-wrapper>
</template>

<script setup lang="ts">
import LoginHeaderWrapper from 'components/Login/LoginHeaderWrapper.vue'
import {storeToRefs} from 'pinia'
import {useIntegratorStore} from 'stores/useIntegratorStore'

const props = defineProps<{
  label: string,
}>()

const {data} = storeToRefs(useIntegratorStore())
</script>

<style lang="scss" scoped>
@function basePadding() {
  @return clamp(16px, 4vw, 72px);
}

.icon {
  border-right: 1px solid $grey-5;
}

.LoginHeader {
  padding: basePadding();

  .LoginHeader--header {
    line-height: 1.2;
  }
}

.supportData {
  a {
    text-decoration: none !important;
    color: $grey-9;
  }
}
</style>
