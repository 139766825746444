import {SelectBankFactor, StopFactorResultEnum} from 'src/models/Applications/New/SelectBank'

const factorResults: Record<StopFactorResultEnum, string> = {
  [StopFactorResultEnum.ok]: 'Нет стоп-факторов',
  [StopFactorResultEnum.warning]: 'Индивидуальное рассмотрение',
  [StopFactorResultEnum.error]: 'Отказ по стоп-факторам',
}

const factorResultsClasses: Record<StopFactorResultEnum, string> = {
  [StopFactorResultEnum.ok]: 'text-green-5',
  [StopFactorResultEnum.warning]: 'text-orange-6',
  [StopFactorResultEnum.error]: 'text-red-12',
}

const factorResultsColors: Record<StopFactorResultEnum, string> = {
  [StopFactorResultEnum.ok]: 'green-5',
  [StopFactorResultEnum.warning]: 'orange-6',
  [StopFactorResultEnum.error]: 'red-12',
}

export const useStopFactorResult = () => {
  const getLabel = (result: StopFactorResultEnum) => factorResults[result] || ''
  const isGreen = (result: StopFactorResultEnum) => result === StopFactorResultEnum.ok
  const isWarning = (result: StopFactorResultEnum) => result === StopFactorResultEnum.warning
  const isRed = (result: StopFactorResultEnum) => result === StopFactorResultEnum.error
  const getClass = (result: StopFactorResultEnum) => factorResultsClasses[result] || ''
  const getColor = (result: StopFactorResultEnum) => factorResultsColors[result] || ''
  const isRequired = (result: StopFactorResultEnum, required: boolean) => {
    return !isRed(result) && required
  }

  const hasRed = (bankFactors: SelectBankFactor[]) => {
    let hasRed = false
    bankFactors.forEach((item) => {
      if (item.result === StopFactorResultEnum.error) {
        hasRed = true
      }
    })

    return hasRed
  }

  const showStopFactor = (bankFactors: SelectBankFactor[], item: SelectBankFactor) => {
    return (isRed(item.result) || (isWarning(item.result) && !hasRed(bankFactors))) && !!item.comment
  }

  return {
    getLabel,
    isGreen,
    isWarning,
    isRed,
    showStopFactor,
    isRequired,
    getClass,
    getColor,
  }
}
