<template>
  <q-card-section class="q-pt-none q-px-lg">
    <info-banner
      text="Для размещения гарантии необходимо указать адрес и реестр"
    />
  </q-card-section>
  <q-stepper
    v-model="step"
    header-nav
    class="on-release-modal-stepper"
    color="primary"
    animated
  >
    <q-step
      :name="1"
      title="Укажите адрес"
      icon="svguse:icons.svg?2#app-cog"
      :done="step > 1"
    >
      <delivery-address-modal-form
        ref="deliveryAddressFormRef"
        :id="id"
        :can-change-issue-bg-form="true"
      />
    </q-step>
    <q-step
      :name="2"
      title="Укажите реестр"
      icon="svguse:icons.svg?2#app-cog"
      :done="step > 2"
    >
      <registry-modal-form
        :id="id"
        :loading="props.loading"
        class="q-pa-lg"
      />
    </q-step>
  </q-stepper>
  <q-card-actions
    align="right"
    class="modal-actions text-primary q-pa-lg"
  >
    <q-btn
      v-if="step === 1"
      label="Продолжить"
      color="primary"
      style="padding: 12px 23px;"
      unelevated
      no-caps
      :loading="deliveryAddressForm.loading"
      :disable="deliveryAddressForm.validation.$invalid"
      @click="canSendDeliveryAddress ? deliveryAddressForm.sendDeliveryAddress() : step = 2"
    />
    <q-btn
      v-if="step === 2"
      label="Сохранить"
      color="primary"
      style="padding: 12px 23px;"
      unelevated
      no-caps
      :loading="props.loading"
      @click="save()"
    />
  </q-card-actions>
</template>

<script setup lang="ts">
import {useRegistryFormStore} from 'src/stores/Applications/Forms/useRegistryFormStore'
import RegistryModalForm from 'src/components/Applications/onReleaseSendModal/RegistryModalForm.vue'
import InfoBanner from 'src/components/InfoBanner.vue'
import DeliveryAddressModalForm from 'src/components/Applications/onReleaseSendModal/DeliveryAddressModalForm.vue'
import {computed, ref, watchEffect} from 'vue'
import {useDeliveryAddressFormStore} from 'src/stores/Applications/Forms/useDeliveryAddressFormStore'
import {ChangeStatusPayload} from 'src/models/Status/ChangeStatusPayload'
const props = defineProps<{
  changeStatusPayload: ChangeStatusPayload,
  loading: boolean,
}>()

const emit = defineEmits<{
  (e: 'save', payload: ChangeStatusPayload): void,
}>()

const deliveryAddressFormRef = ref<typeof DeliveryAddressModalForm>()

const canSendDeliveryAddress = computed(() => {
  if (!deliveryAddressFormRef.value) {
    return false
  }

  if (!deliveryAddressFormRef.value.issueMailHardCopyField) {
    return false
  }

  return deliveryAddressFormRef.value.issueMailHardCopyField.value
})

const id = computed(() => props.changeStatusPayload.id)
const changeStatusPayload = computed(() => props.changeStatusPayload)

const registryForm = useRegistryFormStore(id.value)()
const deliveryAddressForm = useDeliveryAddressFormStore(id.value)()
const step = ref(1)

const save = () => {
  emit('save', registryForm.getRequestData(changeStatusPayload.value))
}

watchEffect(() => {
  if (deliveryAddressForm.success) {
    step.value = 2
  }
})

</script>

<style lang="scss">
.DoubleModal {
  margin: 0 -24px -24px;
  background: $blue-1;
  padding: 0 24px 24px;
}
.on-release-modal-stepper {
  &.q-stepper {
    background: $blue-1;
    .q-stepper__tab.col-grow {
      //flex: 0 0 ;
    }
    .q-stepper__step-inner {
      padding: 0;
    }
    .title {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 16px;
      padding-bottom: 30px;
      padding-top: 10px;
    }
    .q-stepper__header {
      max-width: 600px;
    }
  }
}
</style>
