<template>
  <div class="col-sm col-xs-12 bannerMainContent">
    <div v-if="scanDocument && scanFileExists">
      В заявке размещен
      <q-btn
        class="text-link-button"
        label="скан"
        icon-right="svguse:icons.svg?2#app-download-bold"
        size="xs"
        @click="downloadDocument(scanDocument, ApplicationFormDocumentGroupEnum.deal)"
      />
      гарантии
      <span v-if="registryDocument && registryFileExists">
        и
        <q-btn
          class="text-link-button"
          label="выписка из реестра"
          icon-right="svguse:icons.svg?2#app-download-bold"
          size="xs"
          @click="downloadDocument(registryDocument, ApplicationFormDocumentGroupEnum.deal)"
        />
      </span>
      <br>
      <span v-if="headerInfo.delivery_data_filling_enabled">
        При необходимости вы можете изменить адрес для отправки гарантии
      </span>
      <div v-if="headerInfo.track">Гарантия отправлена. Трек-номер для отслеживания:
        <q-btn
          class="text-link-button"
          :label="headerInfo.track"
          :href="`https://1track.ru/tracking/${headerInfo.track}`"
          target="_blank"
          icon-right="svguse:icons.svg?2#app-arrow-right"
          size="xs"
        />
      </div>
    </div>
  </div>
  <div class="bannerInfoBtnWrap">
    <q-btn
      v-if="headerInfo.delivery_data_filling_enabled"
      class="banner-btn"
      label="Доставка оригинала"
      icon-right="svguse:icons.svg?2#app-document"
      color="primary"
      unelevated
      no-caps
      @click="show"
    />
  </div>
  <delivery-address-dialog
    v-if="headerInfo.delivery_data_filling_enabled"
    v-model="dialog"
    :header-info="headerInfo"
  />
</template>

<script setup lang="ts">
import {computed} from 'vue'
import {ControlCenterHeader} from 'src/models/Applications/ControlCenter/ControlCenterHeader'
import DeliveryAddressDialog from 'src/components/Applications/onReleaseSendModal/DeliveryAddressDialog.vue'
import {useDialog} from 'src/uses/Dialogs/useDialog'
import {
  useApplicationControlCenterItemStore
} from 'stores/Applications/ControlCenter/useApplicationControlCenterItemStore'
import {storeToRefs} from 'pinia'
import {ApplicationFormDocumentGroupEnum} from 'src/models/Applications/Form/ApplicationFormResponseData'
import {DocumentsCodesEnum} from 'src/models/DocumentsCodesEnum'

const props = defineProps<{ headerInfo: ControlCenterHeader }>()
const headerInfo = computed(() => props.headerInfo)

const applicationControlCenterItemStore = useApplicationControlCenterItemStore(props.headerInfo.id)()
const {downloadDocument} = applicationControlCenterItemStore
const {
  documents,
} = storeToRefs(applicationControlCenterItemStore)

const scanDocument = computed(() => {
  if (!documents.value) {
    return undefined
  }

  const dealDocuments = documents.value[ApplicationFormDocumentGroupEnum.deal]
  if (!dealDocuments) {
    return undefined
  }

  return dealDocuments
    .find(document => document.code === DocumentsCodesEnum.BG_SCAN)
})

const scanFileExists = computed(() => scanDocument.value && scanDocument.value.file_name)

const registryDocument = computed(() => {
  if (!documents.value) {
    return undefined
  }

  const dealDocuments = documents.value[ApplicationFormDocumentGroupEnum.deal]
  if (!dealDocuments) {
    return undefined
  }

  return dealDocuments
    .find(document => document.code === DocumentsCodesEnum.EXTRACT_FROM_BG_REGISTRY)
})

const registryFileExists = computed(() => registryDocument.value && registryDocument.value.file_name)

const {dialog, show} = useDialog()
</script>
