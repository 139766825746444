<template>
  <q-dialog
    :model-value="dialog"
    no-route-dismiss
    @hide="setAwareDateTime"
  >
    <q-card
      class="modal"
    >
      <div class="row">
        <div class="col q-pa-lg">
          <div class="text-h5 text-bold">
            Присоединитесь к правилам для выплаты вознаграждения
          </div>
          <p class="q-mt-md q-mb-xl">
            Для получения оплаты необходимо заполнить реквизиты вашей компании и подписать заявление на присоединение к правилам
          </p>
          <q-btn
            :to="{name: 'profile-requisites'}"
            class="text-white"
            label="Перейти в реквизиты"
            color="blue-7"
            padding="15px 21px"
            icon-right="svguse:icons.svg?2#app-finger-print"
            unelevated
            no-caps
            @click="setAwareDateTime"
          />
        </div>
        <div class="col q-pt-xl">
          <img src="/images/agent-aware.png" />
        </div>
      </div>
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
import {useAgentEnterAwareStore} from 'stores/Login/useAgentEnterAwareStore'
import {storeToRefs} from 'pinia'

const {
  dialog,
} = storeToRefs(useAgentEnterAwareStore())

const {setAwareDateTime} = useAgentEnterAwareStore()
</script>

<style scoped lang="scss">
.modal {
  width: 100%;
  max-width: 800px;
  min-width: 800px;

  .row {
    background: #fff;
    background: radial-gradient(at 100% 50%, rgba(0,152,204,0.4) 0%, rgba(255,255,255,0) 100%);
  }
}
</style>
