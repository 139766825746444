import * as validators from '@vuelidate/validators'
import * as customValidators from 'src/validators/index'
import i18n from 'src/i18n'
import {createI18nMessage} from '@vuelidate/validators'

const {t} = i18n.global || i18n

const withI18nMessage = createI18nMessage({t})

export const required = withI18nMessage(validators.required)
export const email = withI18nMessage(validators.email)
export const integer = withI18nMessage(validators.integer)
export const minLength = withI18nMessage(validators.minLength, {withArguments: true})
export const maxLength = withI18nMessage(validators.maxLength, {withArguments: true})
export const phoneValidator = withI18nMessage(customValidators.phoneValidator)
export const passwordValidator = withI18nMessage(customValidators.passwordValidator)
export const nameUpperLowerCaseValidator = withI18nMessage(customValidators.nameUpperLowerCaseValidator)
export const urlValidator = withI18nMessage(customValidators.urlValidator)
export const urlWithoutCyrillicValidator = withI18nMessage(customValidators.urlWithoutCyrillicValidator)
export const urlHasProtocolValidator = withI18nMessage(customValidators.urlHasProtocolValidator)
