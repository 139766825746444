import {defineStore} from 'pinia'
import {ref} from 'vue'
import {useDeleteApi} from 'src/uses/Api/useDeleteApi'

export const useUserDeleteStore = defineStore('profiles-user-delete', () => {
  const {
    deleteRequest,
    response,
    status,
    loading,
    error,
    errors,
  } = useDeleteApi<never>()

  const success = ref(false)

  const deleteUser = async (id: number, successorId = '') => {
    await deleteRequest(`admin/user/${id}`, {successorId})
    if (status.value === 200 && response.value) {
      success.value = true
    }
  }

  return {
    loading,
    response,
    status,
    error,
    errors,
    success,
    deleteUser,
  }
})
