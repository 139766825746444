import {useDialog} from 'src/uses/Dialogs/useDialog'
import {useProfileStore} from 'src/stores/Login/useProfileStore'
import {Ref} from 'vue'
import {ChangeStatusPayload} from 'src/models/Status/ChangeStatusPayload'
import {ApplicationKikSubStatusEnum} from 'src/models/Applications/ApplicationKikSubStatusEnum'

export const useToNewKik = (payload: Ref<ChangeStatusPayload | undefined>, realChangeStatus: () => Promise<void>) => {
  const {
    dialog: newDialog,
    show: showNewDialog,
    hide: hideNewDialog,
  } = useDialog()

  const {
    isBankEmployee,
  } = useProfileStore()

  const needShowDialog = async () => {
    return isBankEmployee()
      && payload.value
      && ApplicationKikSubStatusEnum.new === payload.value?.applicationStatus
  }

  const onSaveNew = async (link: string | undefined, innerSignValue: boolean | undefined) => {
    if (!payload.value) {
      return
    }

    payload.value.sign_url = link
    payload.value.inner_signing_enabled = innerSignValue

    await realChangeStatus()
  }

  return {
    newDialog,
    showNewDialog,
    hideNewDialog,
    needShowDialog,
    onSaveNew,
  }
}
