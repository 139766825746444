import {
  ApplicationFormDocument,
} from 'src/models/Applications/Form/ApplicationFormResponseData'
import {ApplicationFormUpdateResponse} from 'src/models/Applications/Form/ApplicationFormUpdateResponse'
import {useDeleteApi} from 'src/uses/Api/useDeleteApi'

export const useApplicationFormDeleteDocument = (multiOrderId?: number, appId?: string) => {
  const {
    error,
    errors,
    deleteRequest,
    status,
    response,
    loading,
  } = useDeleteApi<ApplicationFormUpdateResponse>()

  const prepareDocumentDataQueryString = (document: ApplicationFormDocument) => {
    const data = {
      code: document.code,
      index: document.index,
      year: document.period_year,
      quarter: document.period_quarter,
      founder_id: document.founder_id,
      type: document.location.section_type,
      //title: document.title,
    }

    return encodeURI(
      Object.entries(data)
        .filter(e => e[1] !== null && e[1] !== undefined)
        .map(e => `${e[0]}=${e[1]}`)
        .join('&')
    )
  }

  const deleteDocument = async (document: ApplicationFormDocument) => {
    if (!multiOrderId && !appId) {
      return
    }

    if (multiOrderId) {
      await deleteRequest(
        `v2/orders/forms/${multiOrderId}/documents?${prepareDocumentDataQueryString(document)}`
      )

      return
    }

    await deleteRequest(
      `v2/control-center/${appId}/documents?${prepareDocumentDataQueryString(document)}`
    )
  }

  return {
    error,
    errors,
    status,
    response,
    loading,
    deleteDocument,
  }
}
