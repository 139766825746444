export enum StopFactorResultEnum {
  ok = 10,
  warning = 20,
  error = 30,
}

export interface SelectBankFactor {
  comment: null | string,
  result: StopFactorResultEnum
}

export interface SelectBank {
  bank_id: string,
  bank_title_short: string,
  factor_result: StopFactorResultEnum,
  id: string,
  tariff: number,
  tariff_error: null | string,
  tariff_percent: number,
  tariff_type: string,
  title: string,
  factors: SelectBankFactor[],
  required: boolean,
}
