import {defineStore} from 'pinia'
import {ref} from 'vue'
import {useGetApi} from 'src/uses/Api/useGetApi'
import {NewsItem} from 'src/models/News/NewsItem'

export const useGetNewsItemStore = defineStore('get-news-item', () => {
  const {
    get,
    response,
    status,
    loading,
    error,
    errors,
  } = useGetApi<NewsItem>()

  const newsResponseData = ref<NewsItem>({
    id: '',
    title: '',
    body: '',
    created: '',
    publication_date: '',
    publication_time: '',
    publication_type: null,
    status: null,
    topic: null,
    documents: [],
    delete: false,
  })

  const load = async (id: string) => {
    await get(`admin/news/${id}`)
    if (status.value === 200 && response.value) {
      newsResponseData.value = response.value
    }
  }

  const getNewsRequestData = () => {
    return newsResponseData.value
  }

  return {
    newsResponseData,
    loading,
    response,
    status,
    error,
    errors,
    load,
    getNewsRequestData
  }
})
