import {usePostApi} from 'src/uses/Api/usePostApi'
import {PushNotificationRegisterTokenPayload} from 'src/models/Push/PushNotificationRegisterTokenPayload'

export const usePushNotificationRegisterToken = () => {
  const {
    loading,
    post,
    status,
    response,
    errors,
  } = usePostApi<unknown, PushNotificationRegisterTokenPayload>()

  const create = async (payload: PushNotificationRegisterTokenPayload) => {
    await post('application/v1/add/device-token', payload)
  }

  return {
    errors,
    response,
    status,
    loading,
    create,
  }
}
