<template>
  <page-block-wrapper>
    <div class="profile-wrapper">
      <profile-employees-mobile-grid />
    </div>
  </page-block-wrapper>
</template>
<script setup lang="ts">
import PageBlockWrapper from 'src/components/PageBlockWrapper.vue'
import {useMetaStore} from 'src/stores/useMetaStore'
import ProfileEmployeesMobileGrid from 'components/Profile/Employees/ProfileEmployeesMobileGrid.vue'

useMetaStore().setTitle('Сотрудники')
</script>
