<template>
  <q-banner :class="{'application-banner-info': true, 'application-banner-info-mobile': $q.platform.is.mobile}">
    <template #avatar>
      <div class="icon-application-info">
        <q-icon
          :name="icon ? icon : 'svguse:icons.svg?2#app-light-bulb'"
          size="sm"
          :color="color ? color : 'orange'"
        />
      </div>
    </template>
    <div>{{ text }}</div>
    <div
      v-if="caption"
      class="text-caption"
    >
      {{ caption }}
    </div>
  </q-banner>
</template>

<script setup lang="ts">
import {computed} from 'vue'
import {useQuasar} from 'quasar'

const props = defineProps<{
  text: string,
  icon?: string,
  caption?: string,
  color?: string
}>()

const $q = useQuasar()

const icon = computed(() => props.icon)
const text = computed(() => props.text)
const caption = computed(() => props.caption)
const color = computed(() => props.color)
</script>

<style lang="scss">
.application-banner-info {
  background: #263238 !important;
  color: #ffffff;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  margin: 0 -24px 0 -24px;
  padding: 20px 30px;

  &.application-banner-info-mobile {
    margin: 0 -16px 0 -16px !important;
  }

  .icon-application-info {
    background: #37474F;
    border-radius: 5px;
    padding: 12px;
  }

  .text-caption {
    font-size: 14px;
  }
}
</style>
