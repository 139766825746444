import {
  ApplicationFormDocument
} from 'src/models/Applications/Form/ApplicationFormResponseData'

export const useCanDocumentBeUpload = () => {
  const canBeUpload = (documents: ApplicationFormDocument[]) => {
    return documents.filter(d => !d.readonly).length > 0
  }

  return {
    canBeUpload
  }
}
