import moment from 'moment/moment'
import {
  ApplicationHistory,
  ApplicationHistoryChanges
} from 'src/models/Applications/ControlCenter/ApplicationHistory'
import {ApplicationHistoryTypeEnum} from 'src/models/Applications/ControlCenter/ApplicationHistoryTypeEnum'

const historyTypeIcons: Record<string, string> = {
  [ApplicationHistoryTypeEnum.order_created]: 'svguse:icons.svg?2#app-clipboard-list-bold',
  [ApplicationHistoryTypeEnum.order]: 'svguse:icons.svg?2#app-clipboard-list-bold',
  [ApplicationHistoryTypeEnum.status]: 'svguse:icons.svg?2#app-clipboard-check-bold',
  [ApplicationHistoryTypeEnum.message]: 'svguse:icons.svg?2#app-mail-bold',
  [ApplicationHistoryTypeEnum.tariff]: 'svguse:icons.svg?2#app-scale',
  [ApplicationHistoryTypeEnum.bank_guarantee]: 'svguse:icons.svg?2#app-document-download-bold',
  [ApplicationHistoryTypeEnum.document]: 'svguse:icons.svg?2#app-pencil-bold',
}
export const useHistory = () => {
  const getChangesByDay = (history: ApplicationHistory) => {
    return history.changes
  }

  const getChangeTime = (change: ApplicationHistoryChanges) => {
    return moment(change.date, 'dd.MM.YYYY, HH:mm:ss').format('HH:mm')
  }

  const getChangeTypeIcon = (changeActionType: string) => {
    return historyTypeIcons[changeActionType]
  }

  const getFormatDate = (day: string) => {
    const monthList = [
      'января',
      'февраля',
      'марта',
      'апреля',
      'мая',
      'июня',
      'июля',
      'августа',
      'сентбря',
      'октября',
      'ноября',
      'декабря',
    ]
    const chatDay = moment(day).get('date')
    const month = moment(day).month()
    return `${chatDay} ${monthList[month]}`
  }

  return {
    getChangesByDay,
    getChangeTime,
    getFormatDate,
    getChangeTypeIcon,
  }
}
