import {RntNotInEISReasonEnum} from 'src/models/Enums/RntNotInEISReasonEnum'
import {useOptionsFromObject} from 'src/uses/useOptionsFromObject'

const rntNotInEISReasons: Record<RntNotInEISReasonEnum, string> = {
  [RntNotInEISReasonEnum.commercial_purchase]: 'Коммерческая закупка',
  [RntNotInEISReasonEnum.closed_purchase]: 'Закрытая закупка',
  [RntNotInEISReasonEnum.purchase_from_single_supplier]: 'Закупка у единственного поставщика',
  [RntNotInEISReasonEnum.astgoz]: 'Закупка с АСТ ГОЗ (astgoz.ru)',
  [RntNotInEISReasonEnum.other]: 'Иное',
}

export const useRntNotInEISReason = () => {
  const {getOptionsFromObject} = useOptionsFromObject()
  const options = getOptionsFromObject(rntNotInEISReasons)
  const getRntNotInEISReasonLabel = (reason: RntNotInEISReasonEnum): string => {
    return rntNotInEISReasons[reason] || ''
  }

  return {
    getRntNotInEISReasonLabel,
    rntNotInEISReasons,
    options,
  }
}
