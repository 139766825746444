import {ref, watch} from 'vue'
import {useGetApi} from 'src/uses/Api/useGetApi'
import {ApplicationNewAgent, ApplicationNewAgentsResponse} from 'src/models/Applications/ApplicationNewAgentsResponse'
import {useRoute} from 'vue-router'

export const useApplicationsNewAgents = () => {
  const {
    loading,
    get,
    status,
    response,
  } = useGetApi<ApplicationNewAgentsResponse>()

  const route = useRoute()

  const items = ref<ApplicationNewAgent[]>([])
  const selected = ref<ApplicationNewAgent>()

  const loadAgents = async (query = '') => {
    await get(
      'v1/product/selection/agents',
      {
        'filter[name]': query,
      })

    if (status.value === 200 && response.value) {
      items.value = response.value.items
    }
  }

  watch(
    items,
    () => {
      if (!route.query?.agent_id || items.value.length === 0) {
        return
      }

      const founded = items.value
        .find(i => i.id === route.query.agent_id)

      if (!founded) {
        return
      }

      selected.value = founded
    }
  )

  return {
    items,
    loading,
    loadAgents,
    selected,
  }
}
