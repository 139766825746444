<template>
  <div :class="{row: props.mobile, 'q-col-gutter-x-sm': props.mobile}">
    <div>
      Дней: {{ item.guarantee_days }}
    </div>
    <div>
      От:
      <template v-if="!item.is_concrete_start_date">
        с даты выдачи
      </template>
      <date-item
        v-else
        :model-value="item.guarantee_from || ''"
      />
    </div>
    <div>
      До:
      <date-item :model-value="item.guarantee_to || ''" />
    </div>
  </div>
</template>

<script setup lang="ts">
import {computed, withDefaults} from 'vue'
import {ApplicationsGridItem} from 'src/models/Applications/ApplicationsGridItem'
import DateItem from 'src/components/DateItem.vue'

const props = withDefaults(
  defineProps<{
    item: ApplicationsGridItem,
    mobile?: boolean,
  }>(),
  {
    mobile: false,
  }
)
const item = computed(() => props.item)
</script>
