<template>
  <span>
    <span
      @click="onClick"
    >
      <slot name="default" />
    </span>
    <textarea
      ref="copy"
      :value="props.text"
    />
  </span>
</template>

<script setup lang="ts">
import {ref, withDefaults} from 'vue'
import {Notify} from 'quasar'

const props = withDefaults(
  defineProps<{
    text: string,
    disable?: boolean
  }>(),
  {
    disable: false,
  }
)

const copy = ref()

const onClick = () => {
  if (props.disable) {
    return
  }

  copy.value.focus()
  copy.value.select()

  try {
    document.execCommand('copy')

    Notify.create({
      type: 'positive',
      message: 'Скопированно в буфер',
      position: 'top',
      timeout: 2000,
      textColor: 'white',
    })
  } catch (e) {
    //
  }

  copy.value.blur()
}
</script>

<style scoped>
textarea {
  z-index: -99999;
  width: 0;
  height: 0;
  position: fixed;
  opacity: 0;
  top: 0;
  left: 0;
  overflow: hidden;
}
</style>
