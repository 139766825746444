export const demoRouter = {
  path: '/demo',
  component: () => import(/* webpackChunkName: "MainLayout" */ '../layouts/MainLayout.vue'),
  children: [
    {
      path: '',
      name: 'demo-icons',
      component: () => import(/* webpackChunkName: "DemoIconsView" */ '../views/Demo/DemoIconsView.vue'),
    },
  ]
}
