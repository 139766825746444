<template>
  <q-breadcrumbs class="Breadcrumbs">
    <q-breadcrumbs-el label="Помощь" />
    <q-breadcrumbs-el :label="getBlockTitle() || ''" />
    <q-breadcrumbs-el :label="getPageTitle() || ''" />
  </q-breadcrumbs>
</template>

<script setup lang="ts">
import {computed} from 'vue'
import {HelpBlock} from 'src/models/Help/HelpBlock'

const props = defineProps<{
  helpBlocks: HelpBlock[],
  helpPageId: string,
}>()

const helpBlocks = computed(() => props.helpBlocks)
const helpPageId = computed(() => props.helpPageId)

const getBlockTitle = () => {
  for (const block of helpBlocks.value as HelpBlock[]) {
    if (!block.pages) {
      continue
    }
    const page = block.pages
      .find((page) => page.id === helpPageId.value)
    if (!page) {
      continue
    }

    return block.title
  }

  return null
}

const getPageTitle = () => {
  for (const block of helpBlocks.value as HelpBlock[]) {
    if (!block.pages) {
      continue
    }
    const page = block.pages
      .find((page) => page.id === helpPageId.value)
    if (!page) {
      continue
    }

    return page.title
  }

  return null
}
</script>

<style lang="scss">
.Breadcrumbs {
  padding-bottom: 20px;
  padding-left: 10px;

  .text-primary {
    color: $grey-9 !important;
  }
}
</style>
