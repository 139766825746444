<template>
  <div
    :class="{'ClientsGridFilters row': true, 'q-pt-md q-col-gutter-y-md': $q.platform.is.mobile, 'q-col-gutter-md': !$q.platform.is.mobile}"
  >
    <div class="col-sm-6 col-xs-12">
      <q-input
        v-model="filters.name"
        :loading="props.loading"
        label="Поиск по ИНН или наименованию"
        outlined
        hide-hint
        hide-bottom-space
        dense
        @change="onSubmit"
        @clear="onSubmit"
      >
        <template #prepend>
          <q-icon name="svguse:icons.svg?2#app-search" />
        </template>
        <template #append>
          <q-icon
            v-if="filters.name"
            class="cursor-pointer"
            name="svguse:icons.svg?2#app-x"
            @click="clear"
          />
        </template>
      </q-input>
    </div>
    <div
      v-if="isAgent() || isHeadEmployee() || isAdmin() || isJuniorAdmin()"
      class="col-sm-6 col-xs-12"
    >
      <region-filter
        v-model="filters.region"
        :loading="loading"
        @change="onSubmit"
      />
    </div>
    <div
      v-if="isOperator() || isAdmin() || isJuniorAdmin()"
      class="col-xs-12 col-sm-6"
    >
      <q-input
        v-model="filters.agent"
        :loading="props.loading"
        label="Название агента"
        outlined
        hide-hint
        hide-bottom-space
        dense
        clearable
        @change="onSubmit"
        @clear="onSubmit"
      />
    </div>
    <div
      v-if="isAdmin() || isJuniorAdmin()"
      class="col-xs-12 col-sm-6"
    >
      <q-input
        v-model="filters.agent_code"
        :loading="props.loading"
        label="Код агента"
        outlined
        hide-hint
        hide-bottom-space
        dense
        clearable
        @change="onSubmit"
        @clear="onSubmit"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import {storeToRefs} from 'pinia'
import {useClientsListFiltersStore} from 'src/stores/Clients/useClientsListFiltersStore'
import RegionFilter from 'src/components/Clients/Filters/RegionFilter.vue'
import {useProfileStore} from 'src/stores/Login/useProfileStore'
import {useQuasar} from 'quasar'
const props = defineProps<{
  loading: boolean,
}>()

const emit = defineEmits<{
  (e: 'update:filters'): void,
}>()

const {
  isAgent,
  isHeadEmployee,
  isOperator,
  isAdmin,
  isJuniorAdmin,
} = useProfileStore()

const filtersStore = useClientsListFiltersStore()
const {filters} = storeToRefs(filtersStore)

const $q = useQuasar()

const onSubmit = () => {
  emit('update:filters')
}

const clear = () => {
  filters.value.name = ''
  emit('update:filters')
}
</script>

<style lang="scss">
.ClientsGridFilters {
  .q-field--dense .q-field__label {
    top: 16px;
  }
}
</style>
