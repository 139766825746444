import {usePutApi} from 'src/uses/Api/usePutApi'

export const useListingsItemRecordEnabledUpdate = () => {
  const {
    put,
    loading,
    status,
    errors,
  } = usePutApi<{enabled: boolean}, {enabled: boolean}>()

  const updateEnabled = async (id: string, recordId: number, enabled: boolean) => {
    await put(`v2/listings/${id}/records/${recordId}/enable`, {enabled})
  }

  return {
    loading,
    errors,
    status,
    updateEnabled,
  }
}
