import {ChatHistoryTabsEnum} from 'src/models/ChatHistoryTabsEnum'
import {useOptionsFromObject} from 'src/uses/useOptionsFromObject'

const chatHistoryTabs: Record<ChatHistoryTabsEnum, string> = {
  [ChatHistoryTabsEnum.chat]: 'Чат',
  [ChatHistoryTabsEnum.history]: 'История заявки',
}
export const useControlCenterMain = () => {
  const {getOptionsFromObject} = useOptionsFromObject()
  const chatHistoryTabsOptions = getOptionsFromObject(chatHistoryTabs)
  return {
    chatHistoryTabsOptions
  }
}
