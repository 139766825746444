export enum BackendErrorTypeEnum {
  exception = 'exception',
  validation = 'validation',
}

export interface BackendError {
  error_type: BackendErrorTypeEnum,
  message: string,
  errors?: Record<string, string>,
}
