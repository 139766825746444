<template>
  <requisites-company
    :store="useProfileRequisitesCompanyStore"
    :disable-requisites-changing="!!profile && profile.agent_offer_status === UserDocumentStateEnum.Signed"
  />
  <div
    v-if="!$q.platform.is.mobile"
    class="row q-pt-lg"
  >
    <div class="col-12">
      <q-btn
        :loading="loading"
        label="Сохранить и сформировать документ"
        color="primary"
        icon-right="svguse:icons.svg?2#app-document-text"
        no-caps
        style="padding: 14px 26px"
        @click="handleSubmit"
      />
    </div>
    <div
      v-if="success"
      class="col-12 q-mt-sm"
    >
      <span class="text-green-7">Данные успешно сохранены</span>
    </div>
  </div>
</template>

<script setup lang="ts">

import {storeToRefs} from 'pinia'
import {onBeforeUnmount} from 'vue'
import {useProfileRequisitesCompanyStore} from 'src/stores/Profile/Requisites/useProfileRequisitesCompanyStore'
import {useProfileStore} from 'src/stores/Login/useProfileStore'
import {UserDocumentStateEnum} from 'src/models/User/UserDocumentStateEnum'
import RequisitesCompany from 'src/components/Requisites/RequisitesCompany.vue'
import {useQuasar} from 'quasar'

const emit = defineEmits<{
  (e: 'update:requisites'): void,
}>()

const $q = useQuasar()

const profileRequisitesCompanyStore = useProfileRequisitesCompanyStore()

const {
  loading,
  success,
  status,
} = storeToRefs(profileRequisitesCompanyStore)

const {save} = profileRequisitesCompanyStore

const profileStore = useProfileStore()
const {
  profile,
} = storeToRefs(profileStore)

const handleSubmit = async () => {
  await save()
  if (status.value === 200) {
    emit('update:requisites')
  }
}

onBeforeUnmount(() => {
  profileRequisitesCompanyStore.$reset()
  profileRequisitesCompanyStore.$dispose()
})
</script>
