import {GridColumnEditableSettings} from 'src/models/Grid/GridColumnSettings'

export const ProductsGridColumns: GridColumnEditableSettings[] = [
  {
    field: '',
    name: 'title',
    required: true,
    label: 'Продукт',
    align: 'left',
    classes: 'text-no-wrap',
    visible: true,
    editable: false,
    order: 1,
  },
  {
    field: '',
    name: 'bank',
    required: true,
    label: 'Банк',
    align: 'left',
    classes: 'text-no-wrap',
    visible: true,
    editable: true,
    order: 2,
  },
  {
    field: '',
    name: 'tariff',
    required: true,
    label: 'Минимальный тариф, ₽',
    align: 'left',
    classes: 'text-no-wrap',
    visible: true,
    editable: true,
    order: 3,
  },
  {
    field: '',
    name: 'actions',
    label: '',
    required: true,
    align: 'center',
    visible: true,
    editable: false,
    order: 4,
  },
]
