<template>
  <profiles-agents-grid-filters
    class="q-mt-sm q-mb-md"
    :loading="loading"
    @update:filters="updateFilters"
  />
  <attach-agents-to-agent-manager-modal
    v-if="selected.length > 0"
    :agents="selected"
    :loading="loading"
    @change="updateFilters"
  />
  <profiles-agent-edit-individual-modal
    :item="editedItem"
    @close="editedItem = undefined"
  />
  <profiles-agent-edit-company-modal
    :item="editedItem"
    @close="editedItem = undefined"
  />
  <profiles-agent-edit-physical-modal
    :item="editedItem"
    @close="editedItem = undefined"
  />
  <profiles-agent-portal-dialog
    :item="editedProfileItem"
    @close="editedProfileItem = undefined"
  />
  <div class="ProfileAgentsGrid--table q-pa-lg">
    <q-table
      v-model:pagination="gridPagination"
      v-model:selected="selected"
      class="sticky-header-table"
      :selection="isAdmin() ? 'multiple' : 'none'"
      :rows="items"
      :columns="columns"
      :loading="loading"
      :rows-per-page-options="perPageOptions"
      separator="none"
      row-key="inn"
      hide-bottom
      wrap-cells
      @request="onRequest"
    >
      <template #body-cell-title="props">
        <q-td :props="props">
          {{ props.row.title }}
        </q-td>
      </template>
      <template #body-cell-inn="props">
        <q-td :props="props">
          {{ props.row.inn }}
        </q-td>
      </template>
      <template #body-cell-date="props">
        <q-td :props="props">
          {{ props.row.date }}
        </q-td>
      </template>
      <template #body-cell-status="props">
        <q-td
          :props="props"
          :class="`text-${AgentStatuses.getAgentsStatusColor(props.row.status)}`"
        >
          {{ AgentStatuses.getAgentsStatusDescription(props.row.status) }}
        </q-td>
      </template>
      <template #body-cell-offer_status="props">
        <q-td
          :props="props"
          :class="`text-${DocumentStatuses.getDocumentStatusColor(props.row.offer_status)}`"
        >
          {{ DocumentStatuses.getDocumentStatusDescription(props.row.offer_status) }}
          <q-icon
            :name="DocumentStatuses.getDocumentStatusIcon(props.row.offer_status)"
          />
        </q-td>
      </template>
      <template #body-cell-actions="props">
        <q-td :props="props">
          <profiles-agent-portal-btn
            :enabled="props.row.sign_portal.enabled"
            @click="editedProfileItem = props.row"
          />
          <profiles-agent-edit-btn
            :item-id="props.row.id"
            @click="startEdit(props.row)"
          />
          <agent-required-banks-modal :id="props.row.id" />
        </q-td>
      </template>
    </q-table>
    <pagination-component
      class="q-mb-md"
      :pagination="gridPagination as GridPagination"
      :per-page-options="perPageOptions"
      @request="onRequest"
      @refresh="load"
    />
  </div>
</template>

<script setup lang="ts">
import {storeToRefs} from 'pinia'
import {onBeforeMount, onBeforeUnmount, ref} from 'vue'
import {useGridSettings} from 'src/uses/Grid/useGridSettings'
import ProfilesAgentsGridFilters from 'src/components/Profiles/Agents/ProfilesAgentsGridFilters.vue'
import {AgentsGridColumns} from 'src/components/Profiles/Agents/AgentsGridColumns'
import {useAgentsListFiltersStore} from 'src/stores/Profiles/Agents/useAgentsListFiltersStore'
import {useAgentsListStore} from 'src/stores/Profiles/Agents/useAgentsListStore'
import {AgentStatuses} from 'src/models/Profiles/Agents/AgentStatusDescriptions'
import AttachAgentsToAgentManagerModal from 'src/components/Profiles/Agents/AttachAgentsToAgentManagerModal.vue'
import {GridPagination, RequestProp} from 'src/models/Grid/Pagination'
import PaginationComponent from 'src/components/PaginationComponent.vue'
import {useProfileStore} from 'src/stores/Login/useProfileStore'
import {useProfilesAgentsGridSortStore} from 'src/stores/Profiles/Agents/useProfilesAgentsGridSortStore'
import {GridSortingEnum} from 'src/models/Grid/GridSortingEnum'
import ProfilesAgentEditBtn from 'src/components/Profiles/Agents/ProfilesAgentEditBtn.vue'
import ProfilesAgentEditIndividualModal from 'src/components/Profiles/Agents/ProfilesAgentEditIndividualModal.vue'
import ProfilesAgentEditCompanyModal from 'src/components/Profiles/Agents/ProfilesAgentEditCompanyModal.vue'
import {ProfilesAgentList} from 'src/models/Profiles/Agents/ProfilesAgentList'
import ProfilesAgentEditPhysicalModal from 'src/components/Profiles/Agents/ProfilesAgentEditPhysicalModal.vue'
import ProfilesAgentPortalBtn from 'components/Profiles/Agents/ProfilesAgentPortalBtn.vue'
import ProfilesAgentPortalDialog from 'components/Profiles/Agents/ProfilesAgentPortalDialog.vue'
import AgentRequiredBanksModal from 'components/Profiles/Agents/AgentRequiredProductsModal.vue'
import {DocumentStatuses} from 'src/models/Profile/Documents/DocumentStatusDescriptions'

const {
  isAdmin,
} = useProfileStore()

const filtersStore = useAgentsListFiltersStore()
const agentsListStore = useAgentsListStore()
const {
  items,
  gridPagination,
  perPageOptions,
  loading,
  selected,
} = storeToRefs(agentsListStore)

const {
  load,
  onRequestPagination,
  setPage,
} = agentsListStore

const clientsGridSortStore = useProfilesAgentsGridSortStore()
const {
  sorting,
} = storeToRefs(clientsGridSortStore)

const {columns} = useGridSettings('profiles-agents-grid', AgentsGridColumns)

const editedItem = ref<ProfilesAgentList>()
const editedProfileItem = ref<ProfilesAgentList>()

const onRequest = ({pagination}: RequestProp) => {
  sorting.value.direction = pagination.descending ? GridSortingEnum.DESC : GridSortingEnum.ASC
  sorting.value.sort = pagination.sortBy || ''
  onRequestPagination(pagination as GridPagination)
  load()
}

const startEdit = (item: ProfilesAgentList) => {
  editedItem.value = item
}

const updateFilters = () => {
  setPage(1)
  load()
}

onBeforeMount(async () => {
  await load()
})

onBeforeUnmount(() => {
  agentsListStore.$reset()
  agentsListStore.$dispose()

  filtersStore.$reset()
  filtersStore.$dispose()

  clientsGridSortStore.$reset()
  clientsGridSortStore.$dispose()
})

</script>

<style lang="scss">
.ProfileAgentsGrid--table {
  margin: 0 -24px -24px;
  background: $blue-1;
  padding: 0 24px 24px;

  .sticky-header-table {
    //max-height: calc(100vh - 400px);
    .q-table__top, .q-table__bottom, .q-table:first-child tr th {
      background-color: #E3F2FD
    }
    .q-table__progress {
      .q-linear-progress {
        position: static !important;
      }
    }
    thead tr th {
      position: sticky;
      z-index: 1;
      top: 3px;

      &:before {
        content: '';
        width: 100%;
        height: 4px;
        margin-top: -4px;
        background-color: #E3F2FD;
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
      }
    }
    &.q-table--loading thead tr:last-child th {
      top: 48px;
    }
    tbody {
      scroll-margin-top: 48px;
    }
  }
}
</style>
