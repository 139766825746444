<template>
  <!--@TODO заменить на уникальные экшены для КИК-->
  <application-grid-to-control-center-action :item="props.item as unknown as ApplicationsGridItem" />
  <application-grid-to-application-item-action :item="props.item as unknown as ApplicationsGridItem" />
  <!--@TODO /-->
  <application-kik-grid-change-status-action :item="props.item" />
  <application-grid-fivorite-action
    :item="props.item as unknown as ApplicationsGridItem"
    @updated="emit('change')"
  />
  <q-btn-dropdown
    v-if="!isBankEmployee()"
    v-model="state"
    class="ApplicationGridActions"
    :disable="!showDots"
    color="blue-grey-4"
    size="12px"
    dropdown-icon="none"
    padding="6px"
    flat
    unelevated
    rounded
    square
    auto-close
    @click="clickBtn"
  >
    <template #label>
      <q-icon name="svguse:icons.svg?2#app-dots-vertical">
        <q-tooltip
          anchor="top middle"
          self="bottom middle"
        >Изменить статус</q-tooltip>
      </q-icon>
    </template>
    <q-list class="ApplicationGridActions--list">
      <application-kik-grid-change-status-action
        v-if="showGridStatusChange(props.item)"
        :item="props.item"
        list
      />
    </q-list>
  </q-btn-dropdown>
</template>

<script lang="ts" setup>
import {computed, ref} from 'vue'
import ApplicationGridToControlCenterAction
  from 'src/components/Applications/ApplicationsGrid/Actions/ApplicationGridToControlCenterAction.vue'
import ApplicationGridToApplicationItemAction
  from 'src/components/Applications/ApplicationsGrid/Actions/ApplicationGridToApplicationItemAction.vue'
import {useAuthStore} from 'src/stores/Login/useAuthStore'
import ApplicationGridFivoriteAction
  from 'src/components/Applications/ApplicationsGrid/Actions/ApplicationGridFivoriteAction.vue'
import ApplicationKikGridChangeStatusAction
  from 'components/Applications/ApplicationsGrid/Actions/ApplicationKikGridChangeStatusAction.vue'
import {ApplicationsGridKikItem} from 'src/models/Applications/ApplicationsGridKikItem'
import {useGridKikChangeStatusAction} from 'src/uses/Grid/useGridKikChangeStatusAction'
import {ApplicationsGridItem} from 'src/models/Applications/ApplicationsGridItem'
import {usePosthogStore} from 'stores/Posthog/usePosthogStore'

const props = defineProps<{
  item: ApplicationsGridKikItem
}>()

const emit = defineEmits<{
  (e: 'change'): void,
}>()

const authStore = useAuthStore()
const {isBankEmployee} = authStore
const {sendEvent} = usePosthogStore()

const {
  showGridStatusChange,
} = useGridKikChangeStatusAction()

const state = ref(false)

const showDots = computed(() => {
  return showGridStatusChange(props.item)
})
const clickBtn = () => {
  sendEvent('Change_applications_status_via_list_of_applications')
}
</script>

<style lang="scss">
.ApplicationGridActions {
  .q-btn-dropdown__arrow {
    display: none;
  }
}

.ApplicationGridActions--list {
  color: $grey-7 !important;

  .q-item {
    border-bottom: 1px solid $blue-grey-1;

    .q-icon {
      margin-top: -4px;
    }
  }
}
</style>
