<template>
  <application-not-signed-documents
    :not-signed-documents="notSignedDocuments"
  />
  <div class="q-mt-lg q-mb-sm">
    Поступал ли запрос о подписании со стороны банка?
  </div>
  <div style="margin-left: -10px;">
    <q-radio
      v-model="needSignValue"
      :val="'true'"
      color="blue-7"
      checked-icon="svguse:icons.svg?2#app-radio-checked"
      unchecked-icon="svguse:icons.svg?2#app-radio"
    >
      <span class="text-grey-7">Да, требуется подписание клиентом</span>
    </q-radio>
  </div>
  <div style="margin-left: -10px;">
    <q-radio
      v-model="needSignValue"
      :val="'false'"
      color="blue-7"
      checked-icon="svguse:icons.svg?2#app-radio-checked"
      unchecked-icon="svguse:icons.svg?2#app-radio"
    >
      <span class="text-grey-7">Нет, подписание не требуется</span>
    </q-radio>
  </div>

  <q-intersection
    v-if="needSign"
    transition="scale"
    once
  >
    <div class="bg-blue-grey-10 text-white row items-center q-pa-md rounded-borders q-mt-md">
      <q-icon
        name="svguse:icons.svg?2#app-information-circle"
        size="sm"
        color="orange-6"
      />
      <div class="col q-pl-sm text-wgite">
        Для подписания документов перейдите по
        <q-btn
          :href="signLink"
          target="_blank"
          color="primary"
          class="text-link-button"
          label="ссылке"
          icon="svguse:icons.svg?2#app-document-duplicate"
          size="xs"
          square
        />
      </div>
    </div>
  </q-intersection>
</template>

<script setup lang="ts">
import {watchEffect, ref, computed} from 'vue'
import {
  ControlCenterHeaderNotSignedDocument
} from 'src/models/Applications/ControlCenter/ControlCenterHeader'
import {useHeaderDataFromStore} from 'src/uses/Applications/ControlCenter/useHeaderDataFromStore'
import ApplicationNotSignedDocuments from 'components/Applications/ApplicationNotSignedDocuments.vue'

const props = defineProps<{
  appId: string
}>()

const {
  getHeaderData,
} = useHeaderDataFromStore()

const notSignedDocuments = ref<ControlCenterHeaderNotSignedDocument[]>([])
const needSignValue = ref<'true' | 'false'>()
const signLink = ref('')

const needSign = computed(() => {
  return needSignValue.value === 'true'
})

watchEffect(async () => {
  if (!props.appId) {
    return
  }

  needSignValue.value = undefined
  const data = await getHeaderData(props.appId)
  if (!data) {
    return
  }

  notSignedDocuments.value = data.not_signed_documents
  signLink.value = data.client.sign_url
})

defineExpose({
  needSign,
})
</script>

<style lang="scss">
.BankRequestSignModal {
  .sendButton {
    .icon {
      background: white;
      border-radius: 3px;
      position: absolute;
      right: 8px;
      line-height: 0;
    }

    .q-btn {
      border-radius: 6px;
    }
  }

  .q-table__top, .q-table__bottom, thead th {
    background: $grey-3 !important;
  }
}
</style>
