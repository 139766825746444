<template>
  <page-block-wrapper>
    <div class="profile-wrapper">
      <profile-acts-grid />
    </div>
  </page-block-wrapper>
</template>
<script setup lang="ts">
import PageBlockWrapper from 'src/components/PageBlockWrapper.vue'
import ProfileActsGrid from 'src/components/Profile/Acts/ProfileActsGrid.vue'
import {useMetaStore} from 'src/stores/useMetaStore'

useMetaStore().setTitle('Акты')
</script>
