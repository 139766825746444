<template>
  <div class="ListingsGridFilters row q-col-gutter-md">
    <div class="col-7">
      <q-input
        v-model="filters.title"
        :loading="props.loading"
        label="Поиск по наименованию"
        outlined
        hide-hint
        hide-bottom-space
        dense
        clearable
        @change="onSubmit"
        @clear="clear"
      >
        <template #prepend>
          <q-icon name="svguse:icons.svg?2#app-search" />
        </template>
        <template #append>
          <q-icon
            v-if="filters.title"
            class="cursor-pointer"
            name="svguse:icons.svg?2#app-x"
            @click="clear"
          />
        </template>
      </q-input>
    </div>
  </div>
</template>

<script setup lang="ts">
import {storeToRefs} from 'pinia'
import {useListingsGridFiltersStore} from 'stores/Listings/useListingsGridFiltersStore'
const props = defineProps<{
  loading: boolean,
}>()

const emit = defineEmits<{
  (e: 'update:filters'): void,
}>()

const filtersStore = useListingsGridFiltersStore()
const {filters} = storeToRefs(filtersStore)

const onSubmit = () => {
  emit('update:filters')
}

const clear = () => {
  filters.value.title = ''
  emit('update:filters')
}
</script>

<style lang="scss">
.ListingsGridFilters {
  .q-field--dense .q-field__label {
    top: 16px;
  }
}
</style>
