<template>
  <div v-if="props.document.sign_file_required">
    <div class="q-mt-sm q-mb-xs">
      Открепленная подпись
    </div>
    <application-form-new-document-file
      v-if="props.document.file_sign"
      :file="props.document.file_sign"
      :progress="props.document.file_sign && !props.document.file ? props.document.progress: undefined"
      :loading="props.document.loading"
      @remove="emit('select:sign', undefined)"
    />
    <file-select-component
      v-else
      class="q-mb-sm"
      accept=".sig, .sgn, .p7s"
      @file:selected="onSelect"
    />
  </div>
</template>

<script setup lang="ts">
import {
  ApplicationFormDocument
} from 'src/models/Applications/Form/ApplicationFormResponseData'
import ApplicationFormNewDocumentFile from 'src/components/Applications/Form/Documents/ApplicationFormNewDocumentFile.vue'
import FileSelectComponent from 'src/components/FileSelectComponent.vue'

const props = defineProps<{
  document: ApplicationFormDocument,
}>()

const emit = defineEmits<{
  (e: 'select:sign', sign?: File): void,
}>()

const onSelect = (file: File) => {
  emit('select:sign', file)
}
</script>
