<template>
  <q-btn
    label="Копировать данные"
    icon-right="svguse:icons.svg?2#app-document-duplicate"
    class="FormItemCopyData--btn"
    no-caps
    unelevated
    flat
    dense
    @click="showDialog"
  />
  <q-dialog
    v-model="dialog"
  >
    <q-card
      style="min-width: 400px; width: 100vw; max-width: 600px;"
      class="bg-blue-1"
    >
      <q-card-section class="text-h6 rounded-borders">
        Копирование данных
      </q-card-section>
      <q-card-section class="q-pt-none white-bg-field">
        <q-select
          v-model="formForCopy"
          class="field-with-top-label q-mb-sm"
          :loading="loadingForms"
          :options="forms"
          option-value="form_id"
          option-label="bank_title"
          label="Анкета для банка"
          aria-placeholder="Выберите банк"
          :error="false"
          outlined
          hide-bottom-space
          dense
        />
      </q-card-section>
      <q-card-actions
        class="row justify-end q-py-md"
        style="background: #D5EAFA;"
      >
        <q-btn
          size="16px"
          padding="sm lg"
          label="Отмена"
          :disable="loading"
          style="padding: 17px 28px;"
          unelevated
          no-caps
          v-close-popup
        />
        <q-btn
          padding="sm lg"
          size="16px"
          color="blue-7"
          label="Скопировать"
          icon-right="svguse:icons.svg?2#app-document-duplicate"
          :loading="loading"
          :disable="!formIdForCopy"
          style="padding: 17px 28px;"
          unelevated
          no-caps
          @click="save"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
import {computed, ref} from 'vue'
import {useDialog} from 'src/uses/Dialogs/useDialog'
import {useFormsStore} from 'stores/Forms/useFormsStore'
import {storeToRefs} from 'pinia'
import {useFormItemDataCopyStore} from 'stores/FormEditor/useFormItemDataCopyStore'
import {FormItem} from 'src/models/FormEditor/FormItem'

const props = defineProps<{
  formId: string,
  sectionId: string,
  blockId: string,
}>()

const emit = defineEmits<{
  (e: 'change'): void,
}>()

const formsStore = useFormsStore()
const {
  loadForms
} = formsStore
const {
  forms,
  loading: loadingForms,
} = storeToRefs(formsStore)

const formItemDataCopyStore = useFormItemDataCopyStore()
const {
  copy
} = formItemDataCopyStore
const {
  status,
  loading,
} = storeToRefs(formItemDataCopyStore)

const formForCopy = ref<FormItem>()
const formIdForCopy = computed(() => formForCopy.value ? formForCopy.value.form_id : undefined)

const {
  dialog,
  show,
  hide,
} = useDialog()

const save = async () => {
  if (!formIdForCopy.value) {
    return
  }

  await copy(formIdForCopy.value, props.blockId, props.sectionId)
  if (status.value === 200) {
    emit('change')
    hide()
  }
}

const showDialog = async () => {
  if (forms.value.length === 0) {
    await loadForms()
  }
  show()
}

</script>
<style lang="scss">
.FormItemCopyData--btn {
  background-color: #C7DFF1;
  padding: 10px 20px;
}
</style>
