import {computed, Ref, ref} from 'vue'
import {OpfEnum} from 'src/models/OpfEnum'
import useVuelidate, {ValidationArgs} from '@vuelidate/core'
import {Document} from 'src/models/Profile/Documents/Document'
import {useValidationRules} from 'src/uses/useValidationRules'
import {usePostApi} from 'src/uses/Api/usePostApi'
import {FormHelper} from 'src/helpers/FormHelper'
import {AgentCompany} from 'src/models/Profile/Requisites/AgentCompany'
import {useGetAgent} from 'src/stores/Profiles/Agents/useGetAgent'
import {AgentPhysical} from 'src/models/Profile/Requisites/AgentPhysical'
import {AgentIndividual} from 'src/models/Profile/Requisites/AgentIndividual'
import {ProfilesAgent} from 'src/models/Profiles/Agents/ProfilesAgent'

export const useAgentEdit = <
  AgentRequisitesResponse = AgentCompany | AgentPhysical | AgentIndividual
>(opf: OpfEnum, rulesInit: ValidationArgs) => {
  const {
    agentResponseData,
    loadAgentCompany
  } = useGetAgent<ProfilesAgent<AgentRequisitesResponse>>(opf)

  const {
    post,
    response,
    status,
    loading,
    error,
    errors,
  } = usePostApi<AgentRequisitesResponse, FormData>()

  const {
    getRulesFromState,
  } = useValidationRules()

  const lastId = ref<string>()
  const agentData = ref<AgentRequisitesResponse | undefined>()
  const isDirect = ref<boolean>()
  const offer = ref<Document>()
  const offerOriginalFile = ref<File>()
  const offerSigFile = ref<File>()
  const platform = ref<string>('')
  const success = ref(false)
  const rules = computed(() => {
    return {
      ...getRulesFromState(agentData.value || {}),
      ...rulesInit,
    }
  })
  const validation = useVuelidate<Ref<AgentRequisitesResponse | undefined>>(rules, agentData)

  const prepareData = () => {
    return FormHelper.getFormDataFromObject({
      ...agentData.value,
      opf,
      platform: platform.value,
      is_direct: isDirect.value,
      ...(offer.value
        ? {
            offer_status: offer.value.status,
            offer_comment: offer.value.comment,
          }
        : {}
      ),
      ...(offerOriginalFile.value
        ? {offer: offerOriginalFile.value}
        : {}
      ),
      ...(offerSigFile.value
        ? {offer_sign: offerSigFile.value}
        : {}
      )
    })
  }

  const update = async () => {
    if (!agentData.value) {
      return
    }

    await post(
      `admin/agent/${lastId.value}/${opf.toLowerCase()}`,
      prepareData(),
    )
    if (status.value === 200 && response.value) {
      success.value = true
    }
  }

  const loadAgent = async (id: string) => {
    offerOriginalFile.value = undefined
    offerSigFile.value = undefined
    lastId.value = id
    await loadAgentCompany(lastId.value)
    if (!agentResponseData.value) {
      return
    }
    agentData.value = {
      ...agentResponseData.value.requisites
    }
    offer.value = agentResponseData.value.documents
      .find(doc => doc.type === 'offer')
    platform.value = agentResponseData.value.platform
    isDirect.value = agentResponseData.value.is_direct
  }

  return {
    agentData,
    offerOriginalFile,
    offerSigFile,
    offer,
    platform,
    loading,
    response,
    status,
    error,
    errors,
    validation,
    success,
    update,
    loadAgent,
  }
}
