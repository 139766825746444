import {defineStore} from 'pinia'
import {useGridFilters} from 'src/uses/Grid/useGridFilters'
import {ref} from 'vue'
import {AgentStatuses} from 'src/models/Profiles/Agents/AgentStatusDescriptions'
import {ProfilesAgentListFilter} from 'src/models/Profiles/Agents/ProfilesAgentListFilter'
import {DocumentStatusEnum} from 'src/models/Enums/DocumentStatusEnum'
import {DocumentStatuses} from 'src/models/Profile/Documents/DocumentStatusDescriptions'
import {UserStateEnum} from 'src/models/User/UserStateEnum'

const defaultFilters: ProfilesAgentListFilter = {
  status: '',
  name: '',
  agentId: '',
  managerId: '',
  offer_status: '',
}

export const useAgentsListFiltersStore = defineStore('profiles-agents-list-filters', () => {
  const statuses = ref(
    Object.values(UserStateEnum).map(status => {
      return {
        label: AgentStatuses.getAgentsStatusDescription(status),
        value: status
      }
    })
  )

  const offerStatuses = ref(
    Object.values(DocumentStatusEnum).map(status => {
      return {
        label: DocumentStatuses.getDocumentStatusDescription(status),
        value: status
      }
    })
  )

  const {
    filters,
    clearable,
    getFiltersQuery,
    clear,
  } = useGridFilters(defaultFilters)

  return {
    filters,
    clearable,
    getFiltersQuery,
    clear,
    statuses,
    offerStatuses,
  }
})
