<template>
  <div
    v-if="offer"
    class="q-mt-lg q-pa-md rounded-borders"
    style="border: 1px solid #C7DFF1"
  >
    <q-file
      ref="filePickerOriginalRef"
      v-model="fileOriginal"
      class="FilePicker"
      label="Standard"
      accept=".pdf, .jpeg, .jpg, .png, .gif"
    />
    <q-file
      ref="filePickerSigRef"
      v-model="fileSig"
      class="FilePicker"
      label="Standard"
      accept=".pdf, .jpeg, .jpg, .png, .gif, .sig, .sgn, .p7s"
    />
    <div class="row">
      <div class="col q-pr-xl text-grey-6">
        Вам необходимо скачать и проверить подписанные документы. Если Агент подписывал документы ранее - загрузите их в ячейки для загрузки<br>
        <div class="text-grey-7">
          Поддерживаемые форматы: jpeg, gif, png, pdf
        </div>
      </div>
      <div style="max-width: 400px;">
        <q-btn
          v-if="offerOriginal"
          size="16px"
          class="q-mb-sm full-width"
          padding="sm lg"
          color="blue-7"
          label="Заявление на присоединение к правилам"
          icon-right="svguse:icons.svg?2#app-download"
          unelevated
          no-caps
          no-wrap
          @click="download(`document/download/${offerOriginal.file_id}`)"
        />
        <template v-else>
          <q-btn
            size="16px"
            class="q-mb-sm full-width"
            padding="sm lg"
            color="blue-7"
            label="Заявление на присоединение к правилам"
            icon-right="svguse:icons.svg?2#app-upload"
            unelevated
            no-caps
            no-wrap
            @click="pickOriginal($event)"
          />
          <div
            v-if="fileOriginal"
            class="row items-center no-wrap bg-white rounded-borders q-mb-xs q-pa-xs relative-position"
          >
            <q-icon
              name="svguse:icons.svg?2#app-document-text-bold"
              color="blue-grey-3"
              size="md"
            />
            <div class="ellipsis">
              {{ fileOriginal.name }}
            </div>
            <div class="q-pl-sm q-pr-sm text-grey-6">
              {{ getSizeString(fileOriginal.size) }}
            </div>
            <div class="text-right">
              <q-btn
                class="delete"
                dense
                unelevated
                @click="fileOriginal = undefined"
              >
                <q-icon
                  name="svguse:icons.svg?2#app-x"
                  color="blue-grey-4"
                />
              </q-btn>
            </div>
          </div>
        </template>
        <br>
        <q-btn
          v-if="offerSig"
          size="16px"
          class="full-width"
          padding="sm lg"
          color="blue-7"
          label="Подписанное заявление"
          icon-right="svguse:icons.svg?2#app-download"
          unelevated
          no-caps
          no-wrap
          @click="download(`document/download/${offerSig.file_id}`)"
        />
        <template v-else>
          <q-btn
            size="16px"
            class="full-width"
            padding="sm lg"
            color="blue-7"
            label="SIG-файл к заявлению"
            icon-right="svguse:icons.svg?2#app-upload"
            unelevated
            no-caps
            no-wrap
            @click="pickSig($event)"
          />
          <div
            v-if="fileSig"
            class="row items-center no-wrap bg-white rounded-borders q-mb-xs q-pa-xs relative-position"
          >
            <q-icon
              name="svguse:icons.svg?2#app-document-text-bold"
              color="blue-grey-3"
              size="md"
            />
            <div class="ellipsis">
              {{ fileSig.name }}
            </div>
            <div class="q-pl-sm q-pr-sm text-grey-6">
              {{ getSizeString(fileSig.size) }}
            </div>
            <div class="text-right">
              <q-btn
                class="delete"
                dense
                unelevated
                @click="fileSig = undefined"
              >
                <q-icon
                  name="svguse:icons.svg?2#app-x"
                  color="blue-grey-4"
                />
              </q-btn>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div class="row white-bg-field q-col-gutter-x-md q-mt-lg">
      <div class="col-4">
        <q-select
          v-model="offer.status"
          class="field-with-top-label"
          :options="options"
          label="Статус документа"
          outlined
          hide-bottom-space
          dense
          emit-value
          map-options
          options-dense
        />
      </div>
      <div class="col-8">
        <q-input
          v-model="offer.comment"
          class="field-with-top-label"
          label="Комментарий"
          outlined
          hide-bottom-space
          dense
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {Document} from 'src/models/Profile/Documents/Document'
import {computed, ref, watchEffect} from 'vue'
import {useAgentDocumentStatus} from 'src/uses/Profile/Requisites/useAgentDocumentStatus'
import {useFileDownload} from 'src/uses/File/useFileDownload'
import {QFile} from 'quasar'
import {CloneHelper} from 'src/helpers/CloneHelper'
import {useDataSize} from 'src/uses/useDataSize'

const props = defineProps<{
  offer: Document,
}>()

const emit = defineEmits<{
  (e: 'update:offer', offer: Document): void,
  (e: 'select-original', file: File): void,
  (e: 'select-sig', file: File): void,
}>()

const {
  options,
} = useAgentDocumentStatus()

const {
  getSizeString,
} = useDataSize()

const {
  download
} = useFileDownload()

const offer = ref<Document>()
const filePickerOriginalRef = ref<QFile>()
const filePickerSigRef = ref<QFile>()
const fileOriginal = ref<File>()
const fileSig = ref<File>()

const offerOriginal = computed(() => {
  if (!offer.value) {
    return undefined
  }

  return offer.value.attachments.find(a => a.type === 'original')
})
const offerSig = computed(() => {
  if (!offer.value) {
    return undefined
  }

  return offer.value.attachments.find(a => a.type !== 'original')
})

const pickOriginal = (event: Event) => {
  if (!filePickerOriginalRef.value) {
    return
  }
  filePickerOriginalRef.value.pickFiles(event)
}
const pickSig = (event: Event) => {
  if (!filePickerSigRef.value) {
    return
  }
  filePickerSigRef.value.pickFiles(event)
}

watchEffect(() => {
  if (JSON.stringify(offer.value) === JSON.stringify(props.offer)) {
    return
  }

  offer.value = CloneHelper.clone(props.offer)
})
watchEffect(() => {
  if (!offer.value) {
    return
  }

  emit('update:offer', offer.value)
})
watchEffect(() => {
  if (!fileOriginal.value) {
    return
  }
  emit('select-original', fileOriginal.value)
})
watchEffect(() => {
  if (!fileSig.value) {
    return
  }
  emit('select-sig', fileSig.value)
})
</script>
