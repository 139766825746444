import {AuthedGuard} from 'src/router/quards/AuthedGuard'
import {UserRoleEnum} from 'src/models/User/UserRoleEnum'
import {Platform} from 'quasar'

const importEmployeesGridview = () => {
  if (Platform.is.mobile) {
    return import(/* webpackChunkName: "ClientsView" */ '../views/Profile/Mobile/EmployeesMobileView.vue')
  }

  return import(/* webpackChunkName: "ClientsView" */ '../views/Profile/EmployeesView.vue')
}

const importReportsGridview = () => {
  if (Platform.is.mobile) {
    return import(/* webpackChunkName: "EmployeesView" */ '../views/Profile/Mobile/ReportsMobileView.vue')
  }

  return import(/* webpackChunkName: "EmployeesView" */ '../views/Profile/ReportsView.vue')
}

export const profileRouter = {
  path: '/profile',
  name: 'profile',
  component: () => import(/* webpackChunkName: "MainLayout" */ '../layouts/MainLayout.vue'),
  children: [
    {
      path: 'requisites',
      name: 'profile-requisites',
      component: () => import(/* webpackChunkName: "RequisitesView" */ '../views/Profile/RequisitesView.vue'),
      meta: {
        guards: [
          AuthedGuard([
            UserRoleEnum.Agent,
          ]),
        ],
      },
    },
    {
      path: 'documents',
      name: 'profile-documents',
      component: () => import(/* webpackChunkName: "DocumentsView" */ '../views/Profile/DocumentsView.vue'),
      meta: {
        guards: [
          AuthedGuard([
            UserRoleEnum.Agent,
          ]),
        ],
      },
    },
    {
      path: 'employees',
      name: 'profile-employees',
      component: () => importEmployeesGridview(),
      meta: {
        guards: [
          AuthedGuard([
            UserRoleEnum.Agent,
          ]),
        ],
      },
    },
    {
      path: 'reports',
      name: 'profile-reports',
      component: () => importReportsGridview(),
      meta: {
        guards: [
          AuthedGuard([
            UserRoleEnum.Agent,
            UserRoleEnum.HeadEmployee,
            UserRoleEnum.Employee,
          ]),
        ],
      },
    },
    {
      path: 'settings',
      name: 'profile-Settings',
      component: () => import(/* webpackChunkName: "settingsView" */ '../views/Profile/SettingsView.vue'),
      meta: {
        guards: [
          AuthedGuard(),
        ],
      },
    },
    {
      path: 'notifications',
      name: 'profile-notifications',
      component: () => import(/* webpackChunkName: "notificationsView" */ '../views/Profile/NotificationsView.vue'),
      meta: {
        guards: [
          AuthedGuard([
            UserRoleEnum.Agent,
          ]),
        ],
      },
    },
    {
      path: 'acts',
      name: 'profile-acts',
      component: () => import(/* webpackChunkName: "ActsView" */ '../views/Profile/ActsView.vue'),
      meta: {
        guards: [
          AuthedGuard([
            UserRoleEnum.Agent,
          ]),
        ],
      },
    },
    {
      path: 'portal-sign',
      name: 'profile-portal-sign',
      component: () => import(/* webpackChunkName: "ActsView" */ '../views/Profile/PortalSignView.vue'),
      meta: {
        guards: [
          AuthedGuard([
            UserRoleEnum.Agent,
          ]),
        ],
      },
    },
  ]
}
