<template>
  <q-banner :class="classes">
    <template #avatar>
      <div class="icon-delivery-address-info">
        <q-icon
          name="svguse:icons.svg?2#app-exclamation-circle"
          size="sm"
          color="orange"
        />
      </div>
    </template>
    <div v-if="isElectronic()">
      <div>
        <strong>
          Пожалуйста, проверьте корректность формы выдачи БГ. <br>
          На данный момент у вас выбран электронный формат гарантии
        </strong>
      </div>
      <div class="text-caption">
        Если вам нужен бумажный вариант гарантии - измените форму выпуска на "Бумажная". <br>
        Если вам необходима доставка - нажмите на одноименный переключатель.
      </div>
    </div>
    <div v-if="isPaper()">
      <div>
        <strong>
          Пожалуйста, проверьте корректность формы выдачи БГ. <br>
          На данный момент доставка оригинала не предусмотрена.
        </strong>
      </div>
      <div class="text-caption">
        Если вам необходима доставка - нажмите на одноименный переключатель. <br>
        Если вам не нужен бумажный вариант гарантии - измените форму выпуска на "Электронная"
      </div>
    </div>
    <div v-if="isPaperAndMailHardcopy()">
      <div>
        <strong>
          Пожалуйста, укажите адрес отправки банковской гарантии.
        </strong>
      </div>
      <div class="text-caption">
        Если доставка не требуется - нажмите на одноименный переключатель или измените
        форму выпуска на "Электронная"
      </div>
    </div>
  </q-banner>
</template>

<script setup lang="ts">
import {computed} from 'vue'
import {ApplicationFormField} from 'src/models/Applications/Form/ApplicationFormResponseData'
import {BgFormEnum} from 'src/models/Applications/BgFormEnum'

const props = defineProps<{
  issueFormField?: ApplicationFormField,
  issueMailHardCopyField?: ApplicationFormField,
  isOnlyDeliveryModal?: boolean
}>()

const isElectronic = () => {
  return props.issueFormField?.value === BgFormEnum.electronic
}

const isPaper = () => {
  return props.issueFormField?.value === BgFormEnum.paper && !props.issueMailHardCopyField?.value
}

const isPaperAndMailHardcopy = () => {
  return props.issueFormField?.value === BgFormEnum.paper && props.issueMailHardCopyField?.value
}

const classes = computed(() => {
  return {
    'bg-orange': !props.isOnlyDeliveryModal,
    'delivery-address-banner-info q-pa-lg': true,
  }
})

</script>

<style lang="scss">
.delivery-address-banner-info {
  &.bg-orange {
    background: #FFF3E0 !important;
    color: #FF9800!important;
    margin: 20px 24px;
    .icon-delivery-address-info {
      background: none;
      border-radius: 5px;
      padding: 0;
    }
  }
  background: #263238 !important;
  color: #ffffff;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  .icon-delivery-address-info {
    background: #37474F;
    border-radius: 5px;
    padding: 12px;
  }
}
</style>
