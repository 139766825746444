import {useDeleteApi} from 'src/uses/Api/useDeleteApi'

export const useDeleteBankProduct = () => {
  const {
    deleteRequest,
    response,
    status,
    loading,
    error,
    errors,
  } = useDeleteApi<unknown>(true)

  const deleteProduct = async (productId: string) => {
    await deleteRequest(`admin/product/${productId}`)
  }

  return {
    loading,
    response,
    status,
    error,
    errors,
    deleteProduct,
  }
}
