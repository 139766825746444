<template>
  <history-changes
    v-if="history"
    :history="history"
  />
</template>

<script setup lang="ts">
import {onBeforeMount, onBeforeUnmount} from 'vue'
import {ControlCenterHeader} from 'src/models/Applications/ControlCenter/ControlCenterHeader'
import {storeToRefs} from 'pinia'
import {useControlCenterHistoryStore} from 'src/stores/Applications/ControlCenter/useControlCenterHistorytStore'
import HistoryChanges from 'src/components/Applications/ApplicationControlCenter/History/HistoryChanges.vue'

const props = defineProps<{
  headerData: ControlCenterHeader,
  original: boolean,
}>()

const controlCenterHistoryStore = useControlCenterHistoryStore(props.headerData.id)()
const {
  history
} = storeToRefs(controlCenterHistoryStore)

const {
  loadHistory
} = controlCenterHistoryStore

onBeforeMount(async () => {
  const id = props.headerData?.id
  if (id) {
    await loadHistory()
  }
})

onBeforeUnmount(() => {
  if (!props.original) {
    return
  }
  controlCenterHistoryStore.$reset()
  controlCenterHistoryStore.$dispose()
})

</script>
<style lang="scss">
.control-center-chats-tabs {
  background-color: #D5EAFA;
  font-style: normal;
  font-weight: 550;
  font-size: 14px;
  line-height: 14px;
  color: #424242;
  .q-focus-helper {
    border-radius: 5px;
    display: none;
  }
  .q-tab__label {
    padding: 3px 5px;
    margin-bottom: 2px;
    &:hover {
      cursor: pointer;
      border-radius: 3px;
      background-color: #C7DFF1;
    }
  }
  .q-tab__indicator {
    background-color: #1E88E5;
  }
}
</style>
