<template>
  <div class="row q-col-gutter-lg justify-end relative-position">
    <div class="col-md-6 col-sm-12 chatHistoryColumn">
      <application-control-center-chat-history
        :header-data="props.headerData"
        :dialog="dialog"
        :original="true"
        @update:dialog="changeDialog"
      />
    </div>
    <div class="col-md-6 col-sm-12">
      <div class="main-params-block full-height q-pa-md">
        <application-control-center-editable-fields
          v-if="props.headerData.type === ProductEnum.EBG"
          :app-id="props.headerData.id"
          :number="props.headerData.number"
        />
        <application-kik-control-center-editable-fields
          v-if="props.headerData.type === ProductEnum.KIK"
          :app-id="props.headerData.id"
          :number="props.headerData.number"
        />
      </div>
    </div>
  </div>
  <q-dialog
    v-model="dialog"
    full-width
    full-height
  >
    <q-card style="min-width: 400px; max-width: 800px !important; max-height: 650px !important; border-radius: 8px">
      <q-card-section
        class="q-pa-none"
        style="height: 100%"
      >
        <application-control-center-chat-history
          :header-data="props.headerData"
          :dialog="dialog"
          :original="false"
          @update:dialog="changeDialog"
        />
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
import {ControlCenterHeader} from 'src/models/Applications/ControlCenter/ControlCenterHeader'
import {useDialog} from 'src/uses/Dialogs/useDialog'
import ApplicationControlCenterChatHistory
  from 'src/components/Applications/ApplicationControlCenter/ApplicationControlCenterChatHistory.vue'
import ApplicationControlCenterEditableFields
  from 'src/components/Applications/ApplicationControlCenter/ApplicationControlCenterEditableFields.vue'
import {ProductEnum} from 'src/models/Applications/ProductEnum'
import ApplicationKikControlCenterEditableFields
  from 'components/Applications/ApplicationControlCenter/ApplicationKikControlCenterEditableFields.vue'

const props = defineProps<{
  headerData: ControlCenterHeader,
}>()

const {dialog} = useDialog()

const changeDialog = (value: boolean) => {
  dialog.value = value
}
</script>
<style lang="scss">
.main-params-block {
  border: 1px solid #C7DFF1;
  border-radius: 10px;
}

body {
  .chatHistoryColumn {
    max-height: calc(100%);
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
  }

  &.screen--sm {
    .chatHistoryColumn {
      position: static;
      max-height: 650px;
    }
  }
}
</style>
