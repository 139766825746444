import {usePostApi} from 'src/uses/Api/usePostApi'
import {useFile} from 'src/uses/useFile'

export const useDocumentsDownload = () => {
  const {
    post,
    response,
    status,
    loading,
    headers,
  } = usePostApi<Blob, {codes: string[]}>(true, 'blob')

  const {
    getFilenameFromHttpResponseBlob,
    downloadFile,
  } = useFile()

  const download = async (
    appId: string,
    codes: string[] = [],
    signed = false,
  ) => {
    await post(
      `v2/control-center/${appId}/documents/${signed ? 'signed-' : ''}files`,
      {
        codes,
      }
    )

    if (!response.value || status.value !== 200) {
      return
    }

    const fileName = getFilenameFromHttpResponseBlob(response.value, headers.value, true) || 'file'

    await downloadFile(response.value, fileName)
  }

  return {
    status,
    loading,
    download,
  }
}
