<template>
  <q-select
    :model-value="selected"
    :options="agentManagers"
    :loading="agentManagersLoading || props.loading"
    style="min-width: 200px"
    option-value="id"
    option-label="name"
    label="Агентский менеджер"
    outlined
    hide-hint
    hide-bottom-space
    dense
    @update:model-value="onChange"
  />
</template>

<script setup lang="ts">
import {storeToRefs} from 'pinia'
import {onBeforeMount, onBeforeUnmount, computed} from 'vue'
import {useAgentManagerListStore} from 'src/stores/AgentManagers/useAgentManagerListStore'
import {AgentManagerListItem} from 'src/models/AgentManagers/AgentManagerListItem'

const props = defineProps<{
  modelValue: string | undefined,
  loading: boolean,
}>()

const emit = defineEmits<{
  (e: 'update:model-value', value: string): void,
  (e: 'change'): void,
}>()

const agentManagersStore = useAgentManagerListStore('grid-filter')()
const {load} = agentManagersStore
const {loading: agentManagersLoading, agentManagers} = storeToRefs(agentManagersStore)

onBeforeMount(() => {
  if (agentManagers.value.length === 0) {
    load()
  }
})

const selected = computed(() => agentManagers.value.find(am => (props.modelValue || '').includes(am.id)))

onBeforeUnmount(() => {
  agentManagersStore.$dispose()
})

const onChange = (val: AgentManagerListItem) => {
  emit('update:model-value', val.id)
  emit('change')
}
</script>
