<template>
  <div class="q-ma-md">
    <profile-acts-grid-filters
      :loading="loading"
      @update:filters="updateFilters"
    />
  </div>
  <div class="ProfileActsGrid--table">
    <q-table
      v-model:pagination="gridPagination"
      class="sticky-header-table q-ma-sm"
      :rows="items"
      :columns="columns"
      :loading="loading"
      :rows-per-page-options="perPageOptions"
      row-key="index"
      hide-bottom
      no-data-label="У вас пока нет сформированных актов по выпущенным продуктам"
      wrap-cells
    >
      <template #body-cell-period="props">
        <q-td :props="props">
          {{ props.row.month }}.{{ props.row.year }}
        </q-td>
      </template>
      <template #body-cell-title="props">
        <q-td :props="props">
          {{ props.row.title || '-' }}
        </q-td>
      </template>
      <template #body-cell-date="props">
        <q-td :props="props">
          {{ props.row.date || '-' }}
        </q-td>
      </template>
      <template #body-cell-status="props">
        <q-td :props="props">
          {{ ActStatuses.getActStatusDescription(props.row.status) }}
          <q-icon
            name="svguse:icons.svg?2#app-question-mark-circle-bold"
            size="1.3em"
          >
            <q-tooltip
              anchor="bottom middle"
              self="top middle"
            >
              <div v-if="ActStatuses.isApprovalStatus(props.row.status)">
                <div>Мы проверяем информацию по вашим замечаниям.</div>
                <div>Как только мы все перепроверим - вы получите уведомление о новом акте</div>
              </div>
              <div v-if="ActStatuses.isFormedStatus(props.row.status)">
                <div>Вам необходимо скачать акт и проверить расчет вашего вознаграждения.</div>
                <div>Если вы согласны с расчетом - необходимо подписать акт ЭЦП.</div>
                <div>Если у вас есть замечания - вы можете оспорить акт, нажав одноименную кнопку</div>
              </div>
              <div v-if="ActStatuses.isSignedStatus(props.row.status)">
                Акт передан в оплату, ожидайте поступление вашего вознаграждения
              </div>
            </q-tooltip>
          </q-icon>
        </q-td>
      </template>
      <template #body-cell-actions="props">
        <q-td
          class="text-right"
          :props="props"
        >
          <profile-acts-actions :item="props.row" />
        </q-td>
      </template>
    </q-table>
    <pagination-component
      :pagination="gridPagination"
      style="margin-bottom: 0"
      :per-page-options="perPageOptions"
      @request="onRequest"
      @refresh="load"
    />
  </div>
</template>

<script setup lang="ts">
import {storeToRefs} from 'pinia'
import {onBeforeMount, onBeforeUnmount} from 'vue'
import {useGridSettings} from 'src/uses/Grid/useGridSettings'
import {ActStatuses} from 'src/models/Profile/Acts/ActStatusDescriptions'
import {useProfileActsGridStore} from 'src/stores/Profile/Acts/useProfileActsGridStore'
import {ProfileActsColumns} from 'src/components/Profile/Acts/ProfileActsColumns'
import {useProfileActsGridFilters} from 'src/stores/Profile/Acts/useProfileActsGridFiltersStore'
import ProfileActsActions from 'src/components/Profile/Acts/ProfileActsActions.vue'
import ProfileActsGridFilters from 'src/components/Profile/Acts/ProfileActsGridFilters.vue'
import PaginationComponent from 'src/components/PaginationComponent.vue'
import {GridPagination, RequestProp} from 'src/models/Grid/Pagination'

const filtersStore = useProfileActsGridFilters()
const profileActsGridStore = useProfileActsGridStore()
const {
  items,
  loading,
  gridPagination,
  perPageOptions,
} = storeToRefs(profileActsGridStore)

const {
  load,
  setPage,
  onRequestPagination,
} = profileActsGridStore

const {columns} = useGridSettings('profile-acts-grid', ProfileActsColumns)

onBeforeMount(async () => {
  await load()
})

onBeforeUnmount(() => {
  profileActsGridStore.$reset()
  profileActsGridStore.$dispose()
  filtersStore.$reset()
  filtersStore.$dispose()
})

const updateFilters = () => {
  setPage(1)
  load()
}

const onRequest = ({pagination}: RequestProp) => {
  onRequestPagination(pagination as GridPagination)
  load()
}

</script>
<style lang="scss">
.ProfileActsGrid--table {
  margin: 0 -24px -24px;
  background: $blue-1;
  padding: 0 24px 24px;

  table {
    position: relative;

  }
}
</style>
