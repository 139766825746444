import {useGetApi} from 'src/uses/Api/useGetApi'
import {useFile} from 'src/uses/useFile'

export const useFileDownload = () => {
  const fileUse = useFile()
  const {
    getFilenameFromHttpResponseBlob,
    downloadFile,
  } = fileUse

  const {
    get,
    response,
    status,
    loading,
    error,
    errors,
    headers,
  } = useGetApi<Blob>(true, 'blob')

  const download = async (url: string, utf = true) => {
    await get(url)

    if (status.value === 200 && response) {
      const fileName = getFilenameFromHttpResponseBlob(response.value, headers.value, utf) || 'act_signed_file'
      downloadFile(response.value, fileName)
    }
  }
  return {
    loading,
    response,
    status,
    error,
    errors,
    download,
  }
}
