<template>
  <q-card-section class="text-h6 rounded-borders">
    Укажите реестр для размещения гарантии
  </q-card-section>
  <q-card-section>
    <registry-modal-form
      :id="id"
      :loading="props.loading"
    />
  </q-card-section>
  <q-card-actions
    align="right"
    class="modal-actions text-primary q-pa-lg"
  >
    <q-btn
      :loading="loading"
      label="Сохранить"
      color="primary"
      style="padding: 17px 28px;"
      unelevated
      no-caps
      @click="save()"
    />
  </q-card-actions>
</template>

<script setup lang="ts">
import {useRegistryFormStore} from 'src/stores/Applications/Forms/useRegistryFormStore'
import RegistryModalForm from 'src/components/Applications/onReleaseSendModal/RegistryModalForm.vue'
import {ChangeStatusPayload} from 'src/models/Status/ChangeStatusPayload'
import {computed} from 'vue'
const props = defineProps<{
  payload: ChangeStatusPayload,
  loading: boolean,
}>()

const emit = defineEmits<{
  (e: 'save', payload: ChangeStatusPayload): void,
}>()

const id = computed(() => props.payload.id)
const changeStatusPayload = computed(() => props.payload)
const registryForm = useRegistryFormStore(id.value)()

const save = () => {
  emit('save', registryForm.getRequestData(changeStatusPayload.value))
}

</script>

<style lang="scss">

</style>
