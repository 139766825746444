<template>
  <div
    :class="classes"
    style="width: 180px;"
    @click="clickOnStatus"
  >
    <strong class="text-no-wrap">{{ label(props.item.status) }}</strong>
    <div class="row items-center no-wrap">
      <div class="col" />
      <span
        v-if="ApplicationMultiStatusEnum.need_attention === props.item.status"
        class="ApplicationGridStatus--request"
      >
        <q-icon
          style="margin-right: 2px; margin-top: -3px"
          color="orange"
          size="16px"
          name="svguse:icons.svg?2#app-exclamation-circle-bold"
        />
      </span>

      <date-item
        v-if="statusDate"
        :model-value="statusDate"
        class="block"
      />
      <span
        v-if="props.item.chat && props.item.chat.new_messages && props.item.chat.new_messages.all > 0"
        class="ApplicationGridStatus--request"
      >
        <q-icon
          style="margin-right: 2px; margin-top: -3px"
          color="orange"
          size="16px"
          name="svguse:icons.svg?2#app-chat-bold"
        />
      </span>
      <div class="col" />
    </div>
    <q-tooltip
      v-if="ApplicationMultiStatusEnum.need_attention === props.item.status"
      :delay="700"
      :offset="[0, 10]"
    >
      <div style="max-width: 300px;">
        По одной из созданных заявок выставлен запрос. Раскройте заявку и перейдите в центр управления
      </div>
    </q-tooltip>
  </div>
</template>

<script setup lang="ts">
import {computed, withDefaults} from 'vue'
import {ApplicationsMultiGridItem} from 'src/models/Applications/ApplicationsMultiGridItem'
import {useMultiStatus} from 'src/uses/Applications/useMultiStatus'
import DateItem from 'components/DateItem.vue'
import {ApplicationMultiStatusEnum} from 'src/models/Applications/ApplicationMultiStatusEnum'
import {useQuasar} from 'quasar'
import {usePosthogStore} from 'stores/Posthog/usePosthogStore'

const props = withDefaults(
  defineProps<{
    item: ApplicationsMultiGridItem
  }>(),
  {}
)

const {
  color,
  label,
  bgColor,
} = useMultiStatus()
const {sendEvent} = usePosthogStore()

const $q = useQuasar()

const classes = computed(() => {
  return {
    ApplicationGridStatus: true,
    isMobile: $q.platform.is.mobile,
    'q-py-sm': true,
    'q-px-md': true,
    'text-center': true,
    [`bg-${bgColor(props.item.status)}`]: true,
    [`text-${color(props.item.status)}`]: true,
  }
})

const statusDate = computed(() => props.item.status_date)
const clickOnStatus = () => {
  sendEvent('Click_on_status_name_in_list_of_applications')
}
</script>

<style lang="scss">
.ApplicationGridStatus {
  border-radius: 6px;
  min-width: 190px !important;
  max-width: 190px !important;
  width: 190px !important;

  &.isMobile {
    min-width: auto !important;
    max-width: none !important;
    width: auto !important;
  }
}
</style>
