<template>
  <q-expansion-item
    class="DocumentsBlock"
    :model-value="true"
    :label="getDocumentGroupLabel(props.documentType)"
  >
    <template v-if="existingDocuments.length">
      <application-form-document-component
        v-for="(document, index) in existingDocuments"
        :key="index"
        :document="document"
        :banks="props.banks"
        @cancel="onCancel(document)"
        @document:delete="emit('document:delete', document)"
        @document:download-sign="emit('document:download-sign', document)"
        @document:download="emit('document:download', document)"
        @file:selected="onFileSelected($event, document)"
        @file:selected-with-sign="(original, sign) => onFileSelectedWithSing(original, sign, document)"
      />
    </template>
    <template v-if="newDocuments.length">
      <application-form-new-document
        v-for="(document, index) in newDocuments"
        :key="index"
        :document="document"
        :document-type="documentType"
        :documents="existingDocuments"
        :new-documents="newDocuments"
        :founders="props.founders"
        :head-fields="props.headFields"
        :can-add-new-added-document="props.canAddNewAddedDocument"
        @remove="emit('new-documents:remove', index)"
        @upload="emit('new-documents:upload', $event, props.documentType, index)"
        @update="emit('new-document:update', $event, documentType, index)"
      >
        <template v-if="document.file">
          {{ document.file.name }}
        </template>
      </application-form-new-document>
      <div
        class="row q-pa-md justify-end q-mb-xs"
        :style="`background: ${fillStatus !== FillStatusEnum.OK ? '#D5EAFA' : '#d1f6d3'}; border-radius: 6px;`"
      >
        <q-btn
          :disable="!props.canUploadAllNewDocuments"
          class="q-mr-sm"
          label="Загрузить все"
          padding="8px 15px"
          color="primary"
          unelevated
          no-wrap
          no-caps
          @click="emit('upload-all-new-documents')"
        />
      </div>
    </template>
  </q-expansion-item>
  <application-form-upload
    v-if="props.canUpload && props.dropzoneAvailable"
    :document-type="props.documentType"
    :documents="existingDocuments"
    ignore-uploaded-check
    @file:selected="onNewDocumentFileSelected"
  />
</template>

<script setup lang="ts">
import {computed, ref, watch, withDefaults} from 'vue'
import {
  ApplicationFormBank,
  ApplicationFormDocument,
  ApplicationFormDocumentGroupEnum,
  ApplicationFormDocuments,
  ApplicationFormField,
  ApplicationFormFounder,
} from 'src/models/Applications/Form/ApplicationFormResponseData'
import {useDocumentGroup} from 'src/uses/Applications/useDocumentGroup'
import ApplicationFormNewDocument from 'src/components/Applications/Form/Documents/ApplicationFormNewDocument.vue'
import ApplicationFormDocumentComponent
  from 'src/components/Applications/Form/Documents/ApplicationFormDocumentComponent.vue'
import ApplicationFormUpload from 'src/components/Applications/Form/Documents/ApplicationFormUpload.vue'
import {FillStatusEnum} from 'src/models/Enums/FillStatusEnum'

const props = withDefaults(
  defineProps<{
    documents: ApplicationFormDocuments,
    newDocuments: ApplicationFormDocuments,
    documentType: ApplicationFormDocumentGroupEnum,
    banks: ApplicationFormBank[],
    founders: ApplicationFormFounder[],
    headFields: ApplicationFormField[],
    dropzoneAvailable: boolean,
    canAddNewAddedDocument: boolean,
    canUploadAllNewDocuments?: boolean,
    canUpload?: boolean,
    fillStatus?: Promise<FillStatusEnum> | undefined,
  }>(),
  {
    canUpload: true,
    canUploadAllNewDocuments: false,
    fillStatus: undefined,
  }
)

const emit = defineEmits<{
  (e: 'file:selected', file: File, document: ApplicationFormDocument): void,
  (
    e: 'file:selected-new',
    file: File,
    document: ApplicationFormDocument,
    documentType: ApplicationFormDocumentGroupEnum,
  ): void,
  (e: 'document:delete', document: ApplicationFormDocument): void,
  (e: 'document:download', document: ApplicationFormDocument): void,
  (e: 'document:download-sign', document: ApplicationFormDocument): void,
  (e: 'cancel', document: ApplicationFormDocument): void,
  (e: 'new-documents:remove', index: number): void,
  (
    e: 'new-documents:upload',
    document: ApplicationFormDocument,
    documentType: ApplicationFormDocumentGroupEnum,
    index: number
  ): void,
  (e: 'upload-all-new-documents'): void,
  (
    e: 'new-document:update',
    document: ApplicationFormDocument,
    documentType: ApplicationFormDocumentGroupEnum,
    index: number
  ): void,
  (
    e: 'file:selected-with-sign',
    original: File,
    sign: File,
    document: ApplicationFormDocument,
  ): void,
}>()

const {
  getDocumentGroupLabel,
} = useDocumentGroup()

const fillStatus = ref<FillStatusEnum>()
const fillStatusProp = computed(() => props.fillStatus)

const existingDocuments = computed<ApplicationFormDocument[]>(() => {
  const documents = props.documents[props.documentType]
  if (!documents) {
    return []
  }

  return documents
})

const newDocuments = computed<ApplicationFormDocument[]>(() => {
  const documents = props.newDocuments[props.documentType]
  if (!documents) {
    return []
  }

  return documents
})

const onFileSelectedWithSing = (original: File, sign: File, document: ApplicationFormDocument) => {
  emit('file:selected-with-sign', original, sign, document)
}

const onFileSelected = (file: File, document: ApplicationFormDocument) => {
  emit('file:selected', file, document)
}

const onCancel = (document: ApplicationFormDocument) => {
  emit('cancel', document)
}

const onNewDocumentFileSelected = (file: File, document: ApplicationFormDocument) => {
  emit('file:selected-new', file, document, props.documentType)
}

watch(
  fillStatusProp,
  async () => {
    if (fillStatusProp.value === undefined) {
      fillStatus.value = undefined

      return
    }

    fillStatus.value = await fillStatusProp.value
  }
)
</script>

<style lang="scss">
.DocumentsBlock {
  &:hover {
    background: transparent !important;
  }

  .q-focus-helper {
    background: transparent !important;
  }

  .q-item {
    padding-left: 0 !important;
    padding-right: 0 !important;

    font-weight: 700;
    font-size: 16px;
  }
}
</style>
