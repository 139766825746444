<template>
  <div
    :class="{'ReportsGridFilters row': true, 'q-pt-sm q-col-gutter-y-md': $q.platform.is.mobile, 'q-col-gutter-md': !$q.platform.is.mobile}"
  >
    <div
      v-if="isAdmin()"
      class="col-xs-12 col-sm-4"
    >
      <q-select
        v-model="filters.report_type"
        :options="reportTypeOptions"
        map-options
        label="Тип отчета"
        outlined
        hide-hint
        hide-bottom-space
        dense
        emit-value
        @update:model-value="onSubmit"
      />
    </div>
    <div
      v-if="isAdmin()"
      class="col-xs-12 col-sm-4"
    >
      <report-client-filter
        @update:model-value="filters.clients = $event"
      />
    </div>
    <div
      v-if="isAdmin()"
      class="col-xs-12 col-sm-4"
    >
      <report-agent-filter
        @update:model-value="filters.agents = $event"
      />
    </div>
    <div
      v-if="isAdmin()"
      class="col-xs-12 col-sm-3"
    >
      <form-date
        v-model="filters.release_date_from"
        label="Дата выдачи с"
        :loading="props.loading"
        :min-date="minFromDate"
        :max-date="maxFromDate(filters.release_date_to)"
      />
    </div>
    <div
      v-if="isAdmin()"
      class="col-xs-12 col-sm-3"
    >
      <form-date
        v-model="filters.release_date_to"
        label="Дата выдачи по"
        :loading="props.loading"
        :min-date="minToDate(filters.release_date_from)"
        :max-date="maxToDate"
      />
    </div>
    <div class="col-xs-12 col-sm-2">
      <q-select
        v-model="filters.product_type"
        :options="productsForSelect"
        :loading="loading"
        map-options
        label="Выберите продукт"
        outlined
        hide-hint
        hide-bottom-space
        dense
        emit-value
        @update:model-value="onSubmit"
      />
    </div>
    <div class="col-xs-12 col-sm-4">
      <bank-filter
        v-model="filters.banks"
        :loading="loading"
        :product-type="filters.product_type || undefined "
        @change="onSubmit"
      />
    </div>
    <div
      v-if="!(isEmployee() || isAdmin())"
      class="col-xs-12 col-sm-5"
    >
      <report-employee-filter
        v-model="filters.employees"
        :loading="loading"
        @change="onSubmit"
      />
    </div>
    <div class="col-xs-12 col-sm-3">
      <form-date
        v-model="filters.date_from"
        :label="!isAdmin() ? 'Период с' : 'Период создания заявки с'"
        :loading="props.loading"
        :min-date="minFromDate"
        :max-date="maxFromDate(filters.date_to)"
      />
    </div>
    <div class="col-xs-12 col-sm-3">
      <form-date
        v-model="filters.date_to"
        :label="!isAdmin() ? 'Период по' : 'Период создания заявки по'"
        :loading="props.loading"
        :min-date="minToDate(filters.date_from)"
        :max-date="maxToDate"
      />
    </div>
    <div
      v-if="isAdmin()"
      class="col-xs-12 col-sm-3"
    >
      <q-input
        :model-value="filters.guarantee_amount_min"
        :loading="loading"
        label="Сумма БГ от"
        type="number"
        outlined
        hide-bottom-space
        dense
        clearable
        @update:model-value="filters.guarantee_amount_min = $event ? parseFloat($event.toString()) : null"
      />
    </div>
    <div
      v-if="isAdmin()"
      class="col-xs-12 col-sm-3"
    >
      <q-input
        :model-value="filters.guarantee_amount_max"
        :loading="loading"
        label="Сумма БГ до"
        type="number"
        outlined
        hide-bottom-space
        dense
        clearable
        @update:model-value="filters.guarantee_amount_max = $event ? parseFloat($event.toString()) : null"
      />
    </div>
    <div
      v-if="isAdmin()"
      class="col-xs-12 col-sm-3"
    >
      <form-date
        v-model="filters.status_date_from"
        label="Период установки статуса с"
        :loading="props.loading"
        :min-date="minFromDate"
        :max-date="maxFromDate(filters.status_date_to)"
        clearable
      />
    </div>
    <div
      v-if="isAdmin()"
      class="col-xs-12 col-sm-3"
    >
      <form-date
        v-model="filters.status_date_to"
        label="Период установки статуса по"
        :loading="props.loading"
        :min-date="minToDate(filters.status_date_from)"
        :max-date="maxToDate"
        clearable
      />
    </div>
    <div
      v-if="filters.product_type === ProductEnum.EBG"
      class="col-xs-12 col-sm"
    >
      <form-select
        v-model="filters.statuses"
        :options="statusesEbgForSelect"
        label="Выберите статус"
        :loading="loading"
        top-actions
        @update:model-value="onSubmit"
      />
    </div>
    <div
      v-if="filters.product_type === ProductEnum.KIK"
      class="col-xs-12 col-sm"
    >
      <form-select
        v-model="filters.statuses"
        :options="statusesKikForSelect"
        label="Выберите статус"
        :loading="loading"
        top-actions
        @update:model-value="onSubmit"
      />
    </div>
    <div>
      <slot name="append" />
    </div>
  </div>
</template>

<script setup lang="ts">
import {computed} from 'vue'
import {storeToRefs} from 'pinia'
import {useReportsGridFiltersStore} from 'src/stores/Profile/Reports/useReportsGridFiltersStore'
import BankFilter from 'src/components/Applications/ApplicationsGrid/Filters/BankFilter.vue'
import ReportEmployeeFilter from 'src/components/Profile/Reports/Filters/ReportEmployeeFilter.vue'
import {useProfileStore} from 'src/stores/Login/useProfileStore'
import FormDate from 'src/components/FormElements/FormDate.vue'
import moment from 'moment/moment'
import FormSelect from 'src/components/FormElements/FormSelect.vue'
import {ProductEnum} from 'src/models/Applications/ProductEnum'
import {useQuasar} from 'quasar'
import {useReportType} from 'src/uses/useReportType'
import ReportClientFilter from 'components/Profile/Reports/Filters/ReportClientFilter.vue'
import ReportAgentFilter from 'components/Profile/Reports/Filters/ReportAgentFilter.vue'

const props = defineProps<{
  loading: boolean,
}>()

const emit = defineEmits<{
  (e: 'update:filters'): void,
}>()

const {isEmployee, isAdmin} = useProfileStore()

const filtersStore = useReportsGridFiltersStore()
const {
  filters,
  statusesEbgForSelect,
  statusesKikForSelect,
  productsForSelect,
} = storeToRefs(filtersStore)

const {
  options: reportTypeOptions,
} = useReportType()

const $q = useQuasar()

const minToDate = (value: string | null | undefined) => {
  if (!value) {
    return undefined
  }

  return moment(value, 'DD.MM.YYYY')
    .add(1, 'day')
    .format('DD.MM.YYYY')
}

const maxToDate = computed(() => {
  return moment()
    .format('DD.MM.YYYY')
})

const minFromDate = computed(() => {
  return undefined
})

const maxFromDate = (value: string | null | undefined) => {
  if (!value) {
    return moment()
      .subtract(1, 'day')
      .format('DD.MM.YYYY')
  }

  return moment(value, 'DD.MM.YYYY')
    .subtract(1, 'day')
    .format('DD.MM.YYYY')
}

const onSubmit = () => {
  emit('update:filters')
}

/*const clear = () => {
  emit('update:filters')
}*/
</script>

<style lang="scss">
.ProfileEmployeesGridFilters {
  .q-field--dense .q-field__label {
    top: 16px;
  }
}
</style>
