<template>
  <q-form
    @submit="handleSubmit"
  >
    <div style="padding-top: 80px;">
      <orange-info text="Укажите новый пароль" />
      <div class="q-gutter-lg q-mt-xl">
        <password-help
          :model-value="showPasswordHelp"
          :password="passwordRecoveryContinueRequestData.password"
        >
          <q-input
            v-model="passwordRecoveryContinueRequestData.password"
            class="field-with-top-label"
            :loading="loading"
            :error-message="getErrorMessage('password')"
            :error="hasError('password')"
            autocomplete="new-password"
            :type="passwordType ? 'password' : 'text'"
            label="Пароль"
            outlined
            hide-hint
            hide-bottom-space
            dense
            @blur="validation.password.$validate(); showPasswordHelp = false"
            @focus="showPasswordHelp = true; clearError('password')"
          >
            <template #append>
              <q-icon
                class="cursor-pointer"
                :name="passwordType ? 'svguse:icons.svg?2#app-eye-off' : 'svguse:icons.svg?2#app-eye'"
                @click="passwordType = !passwordType"
              />
            </template>
          </q-input>
        </password-help>
        <div class="row q-mt-xl">
          <div class="q-pr-md">
            <q-btn
              style="text-decoration: none;"
              label="Отмена"
              :to="{name: 'login'}"
              padding="13px 30px"
              unelevated
              no-caps
            />
          </div>
          <div>
            <q-btn
              :loading="loading"
              label="Установить"
              icon-right="svguse:icons.svg?2#app-arrow-narrow-right"
              color="primary"
              type="submit"
              class="full-width"
              style="height: 50px;"
              unelevated
              no-caps
            />
          </div>
        </div>
      </div>
    </div>
  </q-form>
  <q-dialog
    v-model="successModal"
    persistent
  >
    <q-card class="q-ma-md">
      <q-card-section>
        <div class="text-h5">
          <strong>Пароль изменен!</strong>
        </div>
      </q-card-section>
      <q-card-section>
        Пароль успешно изменен! Доступ в личный кабинет возможен лишь с использованием нового пароля.
      </q-card-section>
      <q-card-actions class="row justify-center">
        <q-btn
          label="Ок"
          color="primary"
          class="full-width"
          style="height: 50px;"
          unelevated
          no-caps
          @click="successModal = false; toLogin()"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
import {storeToRefs} from 'pinia'
import {useValidation} from 'src/uses/useValidation'
import {onBeforeUnmount, ref, watch} from 'vue'
import {usePasswordRecoveryContinueStore} from 'src/stores/Login/usePasswordRecoveryContinueStore'
import PasswordHelp from 'src/components/PasswordHelp.vue'
import OrangeInfo from 'src/components/OrangeInfo.vue'
import {useRouter} from 'vue-router'

const router = useRouter()
const successModal = ref(false)

const passwordRecoveryContinueStore = usePasswordRecoveryContinueStore()

const {
  passwordRecoveryContinueRequestData,
  loading,
  validation,
  errors,
  success,
} = storeToRefs(passwordRecoveryContinueStore)

const {doPasswordRecoveryContinue} = passwordRecoveryContinueStore

const {
  getErrorMessage,
  hasError,
  clearError
} = useValidation(validation, errors)

onBeforeUnmount(() => {
  passwordRecoveryContinueStore.$reset()
  passwordRecoveryContinueStore.$dispose()
})

const showPasswordHelp = ref(false)
const passwordType = ref(true)

const handleSubmit = async () => {
  await doPasswordRecoveryContinue()
}

const toLogin = async () => {
  await router.push({name: 'login'})
}

watch(
  success,
  async () => {
    successModal.value = true
  }
)
</script>
