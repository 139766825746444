<template>
  <div
    v-if="productTariffUpdateData"
    class="ProductTariffIntervals"
  >
    <div
      v-if="productTariffUpdateData.intervals && productTariffUpdateData.intervals.length > 0"
      class="row q-pa-md white-bg-field q-my-md"
      style="border-radius: 6px; border: 1px solid #C7DFF1;"
    >
      <div
        v-for="(interval, index) in productTariffUpdateData.intervals"
        :key="index"
        class="col-12"
      >
        <div
          v-if="index !== 0"
          class="topLine"
        />
        <q-form>
          <div class="row q-col-gutter-md q-mb-lg">
            <div class="col-12">
              <div class="text-bold q-mb-lg">
                Интервал #{{ index + 1 }}
                <q-btn
                  icon-right="svguse:icons.svg?2#app-trash"
                  style="margin-top: -3px"
                  size="sm"
                  color="red"
                  unelevated
                  no-wrap
                  no-caps
                  dense
                  flat
                  @click="removeInterval(index)"
                />
              </div>
            </div>
            <div class="col-4">
              <q-input
                v-model="interval.sum_from"
                class="field-with-top-label"
                label="От (₽)"
                outlined
                hide-bottom-space
                dense
              />
            </div>
            <div class="col-4">
              <q-input
                v-model="interval.sum_to"
                class="col field-with-top-label"
                label="До (₽)"
                outlined
                hide-bottom-space
                dense
              />
            </div>
            <div class="col"
                 style="max-width: 100px;"
            >
              <q-field
                :model-value="interval.type"
                class="field-with-top-label q-pt-none no-bg q-mb-sm text-no-wrap"
                :error="false"
                borderless
                hide-bottom-space
                dense
              >
                <q-btn-toggle
                  v-model="interval.type"
                  class="full-width"
                  toggle-color="primary"
                  color="white"
                  text-color="grey-9"
                  toggle-text-color="white"
                  :options="intervalOptions"
                  no-caps
                  spread
                  unelevated
                />
              </q-field>
            </div>
            <div class="col">
              <q-input
                v-model="interval.rate"
                class="col field-with-top-label q-mb-sm"
                type="number"
                outlined
                hide-bottom-space
                dense
              />
            </div>
          </div>
          <div class="row q-col-gutter-md q-mt-md">
            <div class="col-4">
              <q-input
                v-model="interval.days_from"
                class="col field-with-top-label q-mb-sm"
                label="Срок от (дней)"
                outlined
                hide-bottom-space
                dense
              />
            </div>
            <div class="col-4">
              <q-input
                v-model="interval.days_to"
                class="col field-with-top-label q-mb-sm"
                label="Срок до (дней)"
                outlined
                hide-bottom-space
                dense
              />
            </div>
          </div>
        </q-form>
      </div>
    </div>
    <q-btn
      label="Добавить интервал"
      color="primary"
      icon-right="svguse:icons.svg?2#app-plus"
      style="padding: 12px 22px"
      unelevated
      no-caps
      @click="addInterval"
    />
  </div>
</template>

<script setup lang="ts">
import {useProductsTariffControlStore} from 'stores/Products/Tariff/useProductsTariffControlStore'
import {storeToRefs} from 'pinia'

const props = defineProps<{
  productId: string
}>()

const productsTariffControlStore = useProductsTariffControlStore(props.productId)()
const {
  productTariffUpdateData,
  intervalOptions,
} = storeToRefs(productsTariffControlStore)
const {
  addInterval,
  removeInterval,
} = productsTariffControlStore

</script>

<style lang="scss">
.ProductTariffIntervals {
  .topLine {
    border-bottom: 1px solid #C7DFF1;
    margin: 18px -18px;
  }
  .Intervals--actions {
    margin: 16px -16px 0 -16px;
    background-color: #D5EAFA;
    padding: 16px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    .BgBlueBtn {
      background-color: #C7DFF1;
      color: #1E88E5;
    }
    .BgGreyBtn {
      background-color: #C7DFF1;
      color: #78909C;
    }
  }
}
</style>
