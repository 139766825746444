import {usePutApi} from 'src/uses/Api/usePutApi'

export const useProductHidden = () => {
  const {
    put,
    response,
    status,
    loading,
    error,
    errors,
  } = usePutApi<unknown, {hidden: boolean}>(true)

  const changeHidden = async (hidden: boolean, id: string) => {
    await put(`v1/admin/product/${id}/visibility`, {hidden})
  }

  return {
    loading,
    response,
    status,
    error,
    errors,
    changeHidden,
  }
}
