<template>
  <div class="white-bg-field">
    <q-select
      :model-value="model"
      class="field-with-top-label"
      :options="options"
      label="Паспорт"
      option-label="title"
      outlined
      hide-bottom-space
      dense
      @update:model-value="onSelect"
    />
    <application-form-new-document-require-sign-parameter
      v-if="model"
      :key="JSON.stringify(model)"
      :document="model"
      @select:sign="model.file_sign = $event"
    />
  </div>
</template>

<script setup lang="ts">
import {computed, ref, watchEffect} from 'vue'
import {
  ApplicationFormDocument,
  ApplicationFormField,
  ApplicationFormFounder,
} from 'src/models/Applications/Form/ApplicationFormResponseData'
import {DocumentsCodesEnum} from 'src/models/DocumentsCodesEnum'
import ApplicationFormNewDocumentRequireSignParameter
  from 'src/components/Applications/Form/Documents/ApplicationFormNewDocumentRequireSignParameter.vue'
import {
  useCreateEmptyApplicationFormDocument
} from 'src/uses/Applications/Form/Documents/useCreateEmptyApplicationFormDocument'
import {FieldsCodesEnum} from 'src/models/FieldsCodesEnum'

const props = defineProps<{
  document: ApplicationFormDocument,
  documents: ApplicationFormDocument[],
  newDocuments: ApplicationFormDocument[],
  founders: ApplicationFormFounder[],
  headFields: ApplicationFormField[],
}>()

const emit = defineEmits<{
  (e: 'select-passport', data: ApplicationFormDocument): void,
}>()

const {create} = useCreateEmptyApplicationFormDocument()

const model = ref<ApplicationFormDocument>()

const allDocuments = computed<ApplicationFormDocument[]>(() => {
  const head = props.documents
    .find(d => d.code === DocumentsCodesEnum.PASSPORT_HEAD)
  const founders = props.documents
    .filter(d => d.code === DocumentsCodesEnum.PASSPORT_FOUNDER)
  const founderIds = founders
    .map(d => d.founder_id)

  const foundersWithoutPassports: ApplicationFormDocument[] = []

  props.founders
    .forEach(founder => {
      if (founderIds.includes(founder.id)) {
        return
      }

      const isUlField = founder.fields
        .find(f => f.code === FieldsCodesEnum.CLIENT_HF_UR_FL)

      if (isUlField && isUlField.value) {
        return
      }

      foundersWithoutPassports.push(
        {
          ...create('passports'),
          founder_id: founder.id,
          title: `Паспорт учредителя организации ${getName(founder.fields)}`,
          code: DocumentsCodesEnum.PASSPORT_FOUNDER,
        }
      )
    })

  return [
    {
      ...head,
      title: `Паспорт руководителя организации ${getName(props.headFields)}`
    },
    ...founders
      .map(founderDocument => {
        const founder = props.founders
          .find(founder => founder.id === founderDocument.founder_id)

        return {
          ...founderDocument,
          title: founder
            ? `Паспорт учредителя организации ${getName(founder.fields)}`
            : founderDocument.title,
        }
      }),
    ...foundersWithoutPassports,
  ] as ApplicationFormDocument[]
})

const options = computed<ApplicationFormDocument[]>(() => {
  return allDocuments.value
    .filter(document => !document.readonly)
    .filter(document => {
      return (document.code === DocumentsCodesEnum.PASSPORT_FOUNDER
        || document.code === DocumentsCodesEnum.PASSPORT_HEAD)
        && !isAlreadyLoaded(document)
    })
    .map(document => ({
      ...document,
    }))
})

const getName = (fields: ApplicationFormField[]) => {
  const nameField = fields
    .find(f => f.code === FieldsCodesEnum.CLIENT_HF_NAME)
  const surnameField = fields
    .find(f => f.code === FieldsCodesEnum.CLIENT_HF_SURNAME)
  const patronymicField = fields
    .find(f => f.code === FieldsCodesEnum.CLIENT_HF_PATRONYMIC)

  const nameParts = [
    ...[surnameField ? surnameField.value : []],
    ...[nameField ? nameField.value : []],
    ...[patronymicField ? patronymicField.value : []],
  ]

  return nameParts.join(' ')
}

const isAlreadyLoaded = (document: ApplicationFormDocument) => {
  if (props.newDocuments?.length === 0) {
    return false
  }

  return props.newDocuments?.find(doc => {
    return doc.code === document.code && doc.title === document.title
  })
}

const onSelect = (opt: ApplicationFormDocument) => {
  model.value = opt

  emit(
    'select-passport',
    model.value
  )
}

watchEffect(() => {
  model.value = props.document
})
</script>
