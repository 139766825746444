import {defineStore} from 'pinia'
import {ref} from 'vue'
import {usePostApi} from 'src/uses/Api/usePostApi'
import {useRoute} from 'vue-router'

export const useSignupConfirmStore = defineStore('signup-confirm', () => {
  const {
    post,
    response,
    status,
    loading,
    error,
    errors,
  } = usePostApi<never, undefined>()

  const success = ref(false)
  const route = useRoute()
  const hash = route.params.hash as string

  const doSignupConfirm = async () => {
    await post(`v1/signup/confirm/${hash || ''}`)
    if (status.value === 200 && response.value) {
      success.value = true
    }
  }

  return {
    loading,
    response,
    status,
    error,
    errors,
    success,
    doSignupConfirm
  }
})
