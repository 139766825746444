import {usePutApi} from 'src/uses/Api/usePutApi'
import {FieldUpdate} from 'src/models/Field/FieldUpdate'
import {ApplicationFormUpdateResponse} from 'src/models/Applications/Form/ApplicationFormUpdateResponse'

export const useUpdateFinanceField = (multiOrderId?: number, appId?: string) => {
  const {
    error,
    errors,
    put,
    status,
    response,
    loading,
  } = usePutApi<ApplicationFormUpdateResponse, {year: number, quarter: number, fields: FieldUpdate[]}>(true)

  const update = async (fields: FieldUpdate[], year: number, quarter: number) => {
    if (!multiOrderId && !appId) {
      return
    }

    if (multiOrderId) {
      await put(
        `v2/orders/forms/${multiOrderId}/finance`,
        {
          year,
          quarter,
          fields,
        }
      )

      return
    }

    await put(
      `v2/control-center/${appId}/finance`,
      {
        year,
        quarter,
        fields,
      }
    )
  }

  return {
    error,
    errors,
    put,
    status,
    response,
    loading,
    update,
  }
}
