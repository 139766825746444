<template class="profile-document-item--actions">
  <profile-document-delivery-modal
    v-if="!disableDelivery"
    :item="item"
  />
  <q-icon
    v-if="canSignEDS()"
    class="document-download-icon"
    name="svguse:icons.svg?2#app-pencil"
    color="blue-gray-3"
    @click="show"
  >
    <q-tooltip>
      Подписать КЭП
    </q-tooltip>
  </q-icon>
  <q-icon
    v-if="downloadFileUrl"
    class="document-download-icon"
    name="svguse:icons.svg?2#app-download"
    color="blue-gray-3"
    @click="download(downloadFileUrl)"
  >
    <q-tooltip>
      Скачать документ
    </q-tooltip>
  </q-icon>
  <template v-if="uploadScanAvailable(item.status)">
    <q-circular-progress
      v-if="props.loading"
      style="margin-left: 20px;"
      color="blue-gray-3"
      size="24px"
      indeterminate
    />
    <q-icon
      v-else
      class="document-download-icon"
      name="svguse:icons.svg?2#app-upload"
      color="blue-gray-3"
      @click="uploadClick"
    >
      <q-tooltip>
        Загрузить документ с подписью
      </q-tooltip>
    </q-icon>
    <q-file
      ref="fileRef"
      v-model="file"
      class="FilePicker"
      label="Standard"
      accept=".xls, .xlsx, .doc, .docx, .pdf, .jpeg, .jpg, .png, .txt, .sig, .zip, .rar, .xml"
      :max-file-size="26214400"
      @rejected="onRejected"
    />
  </template>
  <profile-document-sign-modal
    v-model="dialog"
    :item="item"
    @update:model-value="emit('refresh')"
  />
</template>

<script setup lang="ts">
import {computed, ref, watch} from 'vue'
import {useDialog} from 'src/uses/Dialogs/useDialog'
import {useFileDownload} from 'src/uses/File/useFileDownload'
import ProfileDocumentSignModal from 'src/components/Profile/Documents/ProfileDocumentSignModal.vue'
import ProfileDocumentDeliveryModal from 'src/components/Profile/Documents/ProfileDocumentDeliveryModal.vue'
import {Document} from 'src/models/Profile/Documents/Document'
import {DocumentStatusEnum} from 'src/models/Enums/DocumentStatusEnum'
import {DocumentTypeEnum} from 'src/models/Enums/DocumentTypeEnum'
import {QFile} from 'quasar'
import {useNotifyErrorsStore} from 'stores/useNotifyErrorsStore'

const props = defineProps<{
  item: Document
  loading: boolean
}>()

const emit = defineEmits<{
  (e: 'upload-scan', file: File): void,
  (e: 'refresh'): void,
}>()

const {
  addErrorMessage,
} = useNotifyErrorsStore()

const fileRef = ref<QFile>()
const file = ref<File>()

const item = computed(() => props.item)

const original = computed(() => item.value.attachments.find(f => f.type === 'original'))

const downloadFileUrl = computed(() => {
  if (!original.value) {
    return undefined
  }

  return `v1/document/download/${original.value.file_id}`
})
//const downloadSignFileUrl = `v1/document/${item.value.id}/sign`

const {
  download,
} = useFileDownload()

const {
  dialog,
  show,
} = useDialog()

const uploadScanAvailable = (status: DocumentStatusEnum) => {
  const docStatuses = [DocumentStatusEnum.NoSign, DocumentStatusEnum.Revision]

  return docStatuses.includes(status)
}

const canSignEDS = () => {
  return item.value.type !== DocumentTypeEnum.PersonalDataProcessing
}

const disableDelivery = () => {
  if (DocumentStatusEnum.Revision) {
    return true
  }

  if (DocumentStatusEnum.WaitOriginal) {
    return !!(item.value.delivery && item.value.delivery.name)
  }

  return false
}

const onRejected = (rejectedEntries: {failedPropValidation: string, file: File}[]) => {
  const maxSize = rejectedEntries
    .filter(e => e.failedPropValidation === 'max-file-size')

  if (maxSize.length) {
    addErrorMessage('Привышен максимально допустимый размер файла (25Mb).')
  }
}

const uploadClick = (event: Event) => {
  fileRef.value?.pickFiles(event)
}
watch(
  file,
  () => {
    if (!file.value) {
      return
    }

    emit('upload-scan', file.value)
  }
)
</script>

<style lang="scss">
.profile-document-item--actions {
  display: flex;
  flex-direction: row;
  .document-download-icon {
    padding: 3px;
    margin-left: 20px;
    &:hover {
      cursor: pointer;
      background: #F5F5F5;
      border-radius: 5px;
      padding: 3px;
      color: #1E88E5;
    }
  }
}
</style>
