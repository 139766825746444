import {defineStore} from 'pinia'
import {useGridFilters} from 'src/uses/Grid/useGridFilters'
import {NewsFilter} from 'src/models/News/NewsFilter'
import {topics as topicsDescriptions} from 'src/models/News/NewsDescriptions'
import {ref} from 'vue'

const defaultFilters: NewsFilter = {
  title: '',
  topic: '',
}

export const useNewsListFiltersStore = defineStore('news-list-filters', () => {
  const topics = ref(
    Object.entries(topicsDescriptions).map(([topic, title]) => {
      return {
        label: title,
        value: topic
      }
    })
  )

  const {
    filters,
    clearable,
    getFiltersQuery,
    clear,
  } = useGridFilters(defaultFilters)

  return {
    filters,
    clearable,
    getFiltersQuery,
    clear,
    topics
  }
})
