<template>
  <q-dialog
    :model-value="props.modelValue"
    @update:model-value="dialog => emit('update:modelValue', dialog)"
  >
    <q-card
      class="modal-blue-background"
      style="min-width: 420px;"
    >
      <q-card-section class="modal-title row items-center q-px-lg q-pt-lg q-pb-none">
        <div class="col">
          <strong>Подписать ЭЦП</strong>
        </div>
      </q-card-section>
      <q-card-section class="q-pa-lg">
        <sign-file
          v-if="fileForSign"
          ref="signFileComponent"
          :file-for-sign="fileForSign"
          @update:signed-file="handleSign"
        />
      </q-card-section>
      <q-card-actions
        align="right"
        class="modal-actions text-primary q-pa-lg"
        style="padding-right: 24px; padding-left: 24px;"
      >
        <q-btn
          v-close-popup
          :loading="loading"
          label="Отмена"
          class="q-pr-lg"
          color="white"
          style="padding: 17px 28px;"
          unelevated
          flat
          no-caps
          text-color="black"
          @click="abort()"
        />
        <q-btn
          :loading="loading"
          label="Подписать"
          color="primary"
          style="padding: 17px 28px;"
          unelevated
          no-caps
          @click="handleSubmit()"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
import {computed, onBeforeUnmount, ref, watch} from 'vue'
import {storeToRefs} from 'pinia'
import SignFile from 'src/components/Sign/SignFile.vue'
import {useProfileDocumentSignStore} from 'src/stores/Profile/Documents/useProfileDocumentSignStore'
import {Document} from 'src/models/Profile/Documents/Document'

const props = defineProps<{
  item: Document,
  modelValue: boolean,
}>()

const emit = defineEmits<{
  (e: 'update:modelValue', modelValue: boolean): void,
}>()

const item = computed(() => props.item)
const profileDocumentSignStore = useProfileDocumentSignStore()
const {
  loading,
  fileForSign,
  success,
} = storeToRefs(profileDocumentSignStore)

const {
  sign,
  getFileForSign,
} = profileDocumentSignStore

const abort = () => {
  emit('update:modelValue', false)
}

onBeforeUnmount(() => {
  profileDocumentSignStore.$reset()
  profileDocumentSignStore.$dispose()
})

const signFileComponent = ref<InstanceType<typeof SignFile>>()

const handleSubmit = async () => {
  if (!signFileComponent.value) {
    return
  }

  await signFileComponent.value.onSign()
}

const handleSign = async (signedFile: string, certName: string) => {
  await sign(item.value, signedFile, certName)
}

watch(
  props,
  async () => {
    if (props.modelValue) {
      await getFileForSign(item.value)
    }
  },
  {deep: true, immediate: true}
)

watch(
  success,
  async () => {
    if (success.value === true) {
      abort()
    }
  },
  {deep: true, immediate: true}
)

</script>
