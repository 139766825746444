<template>
  <div
    v-if="!loading"
    class="ApplicationKikControlCenterEditableFields"
  >
    <div
      v-if="kikSumField && !kikSumField.invisible"
      class="row"
    >
      <field-component
        :key="String(kikSumField.value)"
        :field="kikSumField"
        :banks="banks"
        class="col-12"
        ignore-width
        @update:value="onUpdate"
      />
    </div>
    <div
      v-if="kikMonthsField && !kikMonthsField.invisible"
      class="row"
    >
      <field-component
        :key="String(kikMonthsField.value)"
        :field="kikMonthsField"
        :banks="banks"
        class="col-12"
        ignore-width
        @update:value="onUpdate"
      />
    </div>
    <div
      v-if="kikSumApprovedField && !kikSumApprovedField.invisible"
      :class="{row: true, approvedFieldWrapper: kikSumApprovedField.readonly}"
    >
      <field-component
        :key="String(kikSumApprovedField.value)"
        :field="kikSumApprovedField"
        :banks="banks"
        class="col-12"
        ignore-width
        @update:value="onUpdate"
      />
    </div>
    <div
      v-if="kikSumIssuedField && !kikSumIssuedField.invisible"
      :class="{row: true, issuedFieldWrapper: kikSumIssuedField.readonly}"
    >
      <field-component
        :key="String(kikSumIssuedField.value)"
        :field="kikSumIssuedField"
        :banks="banks"
        class="col-12"
        ignore-width
        @update:value="onUpdate"
      />
    </div>
    <div
      v-if="kikMonthsApprovedField && !kikMonthsApprovedField.invisible"
      :class="{row: true, approvedFieldWrapper: kikMonthsApprovedField.readonly}"
    >
      <field-component
        :key="String(kikMonthsApprovedField.value)"
        :field="kikMonthsApprovedField"
        :banks="banks"
        class="col-12"
        ignore-width
        @update:value="onUpdate"
      />
    </div>
    <div
      v-if="kikMonthsIssuedField && !kikMonthsIssuedField.invisible"
      :class="{row: true, issuedFieldWrapper: kikMonthsIssuedField.readonly}"
    >
      <field-component
        :key="String(kikMonthsIssuedField.value)"
        :field="kikMonthsIssuedField"
        :banks="banks"
        class="col-12"
        ignore-width
        @update:value="onUpdate"
      />
    </div>
    <div
      v-if="kikTypeField && !kikTypeField.invisible"
      class="row"
    >
      <field-component
        :key="String(kikTypeField.value)"
        :field="kikTypeField"
        :banks="banks"
        class="col-12"
        ignore-width
        @update:value="onUpdate"
      />
    </div>
    <div class="KikTariff q-mt-md white-bg-field q-pa-md">
      <div class="row">
        <div class="col-4 q-pr-md">
          <q-input
            :model-value="tariffType"
            class="field-with-top-label"
            :disable="!bank || !bank.tariff_calculator_enabled"
            :loading="setTariffLoading"
            :debounce="1000"
            type="number"
            outlined
            label="Тариф, %"
            hide-bottom-space
            dense
            @update:model-value="updatePercentHandler"
          />
        </div>
        <div class="col-8">
          <q-input
            :model-value="tariff"
            class="field-with-top-label"
            :disable="!bank || !bank.tariff_calculator_enabled"
            :loading="setTariffLoading"
            label="Платеж, ₽"
            type="number"
            :debounce="1000"
            outlined
            hide-bottom-space
            dense
            @update:model-value="updateTariffHandler"
          />
        </div>
      </div>
      <div class="row no-wrap q-mt-md">
        <q-icon
          class="q-mr-sm"
          name="svguse:icons.svg?2#app-information-circle"
          size="sm"
        />
        <div>
          Ежемесячный платеж рассчитан приблизительно. Точная сумма будет известна после одобрения заявки
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  useApplicationControlCenterItemStore
} from 'src/stores/Applications/ControlCenter/useApplicationControlCenterItemStore'
import {storeToRefs} from 'pinia'
import FieldComponent from 'src/components/Fields/FieldComponent.vue'
import {FormElementValue} from 'src/models/Form'
import {ApplicationFormField} from 'src/models/Applications/Form/ApplicationFormResponseData'
import {useImportantFields} from 'src/uses/Applications/Form/useImportantFields'
import {useApplicationControlCenterStore} from 'stores/Applications/ControlCenter/useApplicationControlCenterStore'
import {computed} from 'vue'
import {useSetTariff} from 'src/uses/Applications/Tariff/useSetTariff'

const props = defineProps<{
  appId: string,
  number: string,
}>()

const applicationControlCenterItemStore = useApplicationControlCenterItemStore(props.appId)()
const {
  updateMainFields,
} = applicationControlCenterItemStore
const {
  loading,
  item,
  banks,
} = storeToRefs(applicationControlCenterItemStore)

const {
  kikSumField,
  kikMonthsField,
  kikTypeField,
  kikSumIssuedField,
  kikMonthsIssuedField,
  kikMonthsApprovedField,
  kikSumApprovedField,
} = useImportantFields(item)

const applicationControlCenterStore = useApplicationControlCenterStore(props.appId)()
const {
  loadingHeaderData,
  headerData,
} = storeToRefs(applicationControlCenterStore)

const {
  setTariff,
  loading: setTariffLoading,
  status: setTariffStatus,
} = useSetTariff()

const headerOrder = computed(() => {
  return getHeaderOrder()
})

const bank = computed(() => {
  if (!banks.value.length) {
    return undefined
  }

  return banks.value[0]
})

const tariff = computed(() => {
  if (!headerOrder.value) {
    return undefined
  }

  return headerOrder.value.tariff
})

const tariffType = computed(() => {
  if (!headerOrder.value) {
    return undefined
  }

  return headerOrder.value.tariff_type
})

const onUpdate = (
  value: FormElementValue,
  field: ApplicationFormField,
) => {
  updateMainFields([{
    value,
    code: field.code,
  }])
}

const getHeaderOrder = () => {
  if (loadingHeaderData.value || !headerData.value) {
    return undefined
  }

  return headerData.value.orders
    .find(order => order.id === props.appId)
}

const updatePercentHandler = async (percent: string | number | null) => {
  if (percent === null) {
    return
  }

  const percentValue = parseFloat(percent.toString())
  if (!percent
    || isNaN(percentValue)
    || percentValue <= 0
    || !tariff.value
    || !headerData.value
    || headerData.value.orders.length === 0
  ) {
    return
  }

  const order = getHeaderOrder()
  if (!order) {
    return
  }

  const oldPercent = order.tariff_type
  order.tariff_type = percentValue.toString()

  await setTariff(
    props.appId,
    {
      amount: parseFloat(tariff.value),
      percent: percentValue,
    }
  )

  if (setTariffStatus.value !== 200) {
    order.tariff_type = oldPercent
  }
}

const updateTariffHandler = async (value: string | number | null) => {
  if (value === null) {
    return
  }

  const tariffValue = parseFloat(value.toString())
  if (!value
    || isNaN(tariffValue)
    || tariffValue <= 0
    || !tariffType.value
    || !headerData.value
    || headerData.value.orders.length === 0
  ) {
    return
  }

  const order = getHeaderOrder()
  if (!order) {
    return
  }

  const oldTariff = order.tariff
  order.tariff = tariffValue.toString()

  await setTariff(
    props.appId,
    {
      amount: tariffValue,
      percent: parseFloat(tariffType.value),
    }
  )

  if (setTariffStatus.value !== 200) {
    order.tariff = oldTariff
  }
}
</script>

<style lang="scss">
body {
  .ApplicationKikControlCenterEditableFields {
    .KikTariff {
      background: #D5EAFA;
      border-radius: 6px;
      color: $blue-grey-5;
    }

    .approvedFieldWrapper {
      .q-field__native {
        color: $primary;
      }
    }
    .issuedFieldWrapper {
      .q-field__native {
        color: $green-14;
      }
    }
  }
}
</style>
