<template>
  <q-expansion-item
    class="DocumentsBlock"
    :model-value="true"
    :label="getDocumentGroupLabel(props.documentType)"
  >
    <template v-if="generatedDocuments">
      <div class="FormItemDocumentsGrid--table">
        <q-table
          :rows="docsByType"
          :columns="columns"
          :pagination="{rowsPerPage: 0}"
          separator="none"
          wrap-cells
          hide-bottom
        >
          <template #body-cell-title="props">
            <q-td style="max-width: 150px" :props="props">
              {{ getTitle(props.row) }}
            </q-td>
          </template>
          <template #body-cell-generated="props">
            <q-td :props="props">
              <q-toggle
                v-model="props.row.generated"
                class="q-mr-sm"
                @update:model-value="update(generatedDocuments)"
              />
            </q-td>
          </template>
        </q-table>
      </div>
    </template>
  </q-expansion-item>
</template>

<script setup lang="ts">
import {
  ApplicationFormDocumentGroupEnum,
} from 'src/models/Applications/Form/ApplicationFormResponseData'
import {useDocumentGroup} from 'src/uses/Applications/useDocumentGroup'
import {FormItemDocumentListItem} from 'src/models/FormEditor/FormItemDocumentListItem'
import {computed, ref} from 'vue'
import {storeToRefs} from 'pinia'
import {useFormItemGeneratedDocumentsStore} from 'stores/FormEditor/useFormItemGeneratedDocumentsStore'
import {QTableColumn} from 'src/models/Grid/GridColumnSettings'

const props = defineProps<{
  formId: string,
  documentType: ApplicationFormDocumentGroupEnum,
  search: string | null,
}>()

const columns = ref<QTableColumn[]>([
  {
    name: 'title',
    field: 'title',
    required: true,
    label: 'Наименование',
    align: 'left',
    classes: 'text-no-wrap full-width',
    sortable: false,
  },
  {
    name: 'generated',
    field: 'selected',
    required: true,
    label: 'Генерируется',
    align: 'right',
    classes: 'text-no-wrap',
    sortable: false,
  },
])

const {
  getDocumentGroupLabel,
} = useDocumentGroup()

const formItemGeneratedDocumentsStore = useFormItemGeneratedDocumentsStore(props.formId)()
const {update} = formItemGeneratedDocumentsStore
const {
  generatedDocuments,
} = storeToRefs(formItemGeneratedDocumentsStore)

const docsByType = computed(() => {
  console.log(props.search, props.search === '')
  const docs = !props.search || props.search === ''
    ? generatedDocuments.value
    : filteredDocs()

  return docs
    .filter(item => item.group === props.documentType)
})

const getTitle = (doc: FormItemDocumentListItem) => {
  return doc.title
}

const filteredDocs = () => {
  return generatedDocuments
    .value.filter(d => d.title.indexOf(props.search || '') !== -1)
}
</script>
