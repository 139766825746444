<template>
  <div
    v-if="props.headerData"
    :class="headerClass"
  >
    <application-control-center-info-banner
      v-if="props.productType === ProductEnum.EBG"
      :header-info="props.headerData"
      :documents="props.documents"
    />
    <application-kik-control-center-info-banner
      v-if="props.productType === ProductEnum.KIK"
      :header-info="props.headerData"
      :documents="props.documents"
    />
    <div class="q-px-lg q-pt-lg q-pb-sm">
      <div class="row q-mb-sm">
        <div class="q-pr-md mobile-hide">
          <q-btn
            class="back-btn text-blue-grey-4"
            icon="svguse:icons.svg?2#app-arrow-left"
            label="Назад"
            padding="8px 10px"
            unelevated
            no-caps
            @click="backClick"
          />
        </div>
        <div class="col">
          <application-controll-center-number
            :header-data="props.headerData"
            @changed="emit('changed')"
          />
        </div>
      </div>
      <div class="row q-pb-xs q-pt-md">
        <div class="col-sm col-xs-12">
          <div class="q-pr-sm float-left">
            <img
              :src="props.headerData?.bank?.logo"
              :alt="props.headerData?.bank?.title_short"
              style="max-width: 100px; max-height: 50px;"
            >
          </div>
          <div
            class="HeaderCompanyName q-pr-md float-left q-pt-md"
          >
            <client-link
              :title="props.headerData.client.title"
              :inn="props.headerData.client.inn"
            />
          </div>
          <div class="HeaderCompanyInn q-pr-md float-left q-pt-md">
            ИНН {{ props.headerData.client.inn }}
          </div>
        </div>
        <application-control-center-header-ebg-actions
          v-if="props.productType === ProductEnum.EBG"
          :header-data="props.headerData"
          :documents="props.documents"
          :stop-factors="props.stopFactors"
          :loading="props.loading"
        />
        <application-control-center-header-kik-actions
          v-if="props.productType === ProductEnum.KIK"
          :header-data="props.headerData"
          :documents="props.documents"
          :stop-factors="props.stopFactors"
          :loading="props.loading"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {computed} from 'vue'
import {ControlCenterHeader} from 'src/models/Applications/ControlCenter/ControlCenterHeader'
import ClientLink from 'src/components/ClientLink.vue'
import ApplicationControlCenterInfoBanner
  from 'src/components/Applications/ApplicationControlCenter/ApplicationControlCenterInfoBanner.vue'
import {ApplicationFormDocuments,} from 'src/models/Applications/Form/ApplicationFormResponseData'
import {SelectBankFactor} from 'src/models/Applications/New/SelectBank'
import {useRouter} from 'vue-router'
import ApplicationControllCenterNumber
  from 'src/components/Applications/ApplicationControlCenter/ApplicationControllCenterNumber.vue'
import {Platform} from 'quasar'
import {ProductEnum} from 'src/models/Applications/ProductEnum'
import ApplicationControlCenterHeaderEbgActions
  from 'components/Applications/ApplicationControlCenter/ApplicationControlCenterHeaderEbgActions.vue'
import ApplicationControlCenterHeaderKikActions
  from 'components/Applications/ApplicationControlCenter/ApplicationControlCenterHeaderKikActions.vue'
import ApplicationKikControlCenterInfoBanner
  from 'components/Applications/ApplicationControlCenter/ApplicationKikControlCenterInfoBanner.vue'

const props = defineProps<{
  loading: boolean,
  headerData: ControlCenterHeader,
  documents: ApplicationFormDocuments | null,
  stopFactors: SelectBankFactor[],
  productType: ProductEnum,
}>()

const emit = defineEmits<{
  (e: 'changed'): void,
}>()

const router = useRouter()

const headerClass = computed(() => {
  return Platform.is.mobile ? 'ApplicationControlCenterHeader--mobile' : ''
})

const backClick = () => {
  try {
    router.resolve(window.history.state.back)
    router.back()
  } catch (e) {
    const name = props.productType === ProductEnum.EBG
      ? 'applications-grid'
      : props.productType === ProductEnum.KIK
        ? 'applications-grid-kik'
        : 'applications-grid'

    router.push({name})
  }
}
</script>

<style lang="scss">
.back-btn {
  background-color: #F5F5F5;
  color: $grey-7;
}
</style>
