export enum ProductTariffParamsItemOperatorEnum {
  LT = 'lt',
  EQ = 'eq',
  GT = 'gt',
}

export enum ProductTariffParamsItemTypeEnum {
  SELECT = 'select',
  NUMBER = 'number',
  STRING = 'string',
}

export interface ProductTariffParamOptionsWithChild{
  code: string
  name: string,
  child: ProductTariffParamOptionsWithChild[]
}

export interface ProductTariffParamsItem {
  multiple: boolean
  options: Record<string, string> | ProductTariffParamOptionsWithChild | null
  relations: ProductTariffParamsItemOperatorEnum[]
  title: string
  type: ProductTariffParamsItemTypeEnum
}
