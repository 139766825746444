import {ApplicationMultiStatusEnum} from 'src/models/Applications/ApplicationMultiStatusEnum'

const statuses: Record<ApplicationMultiStatusEnum, string> = {
  [ApplicationMultiStatusEnum.need_attention]: 'Требует вашего внимания',
  [ApplicationMultiStatusEnum.in_work]: 'В работе',
  [ApplicationMultiStatusEnum.need_sign]: 'Требуется подпись',
  [ApplicationMultiStatusEnum.need_filling]: 'Требует заполнения',
  [ApplicationMultiStatusEnum.offer]: 'Выставлено предложение',
  [ApplicationMultiStatusEnum.released]: 'Выдана',
  [ApplicationMultiStatusEnum.rejected]: 'Отказ',
}

const blueStatuses = [
  ApplicationMultiStatusEnum.need_attention,
  ApplicationMultiStatusEnum.in_work,
]

const greyStatuses = [
  ApplicationMultiStatusEnum.need_filling,
]

const greenStatuses = [
  ApplicationMultiStatusEnum.released,
  ApplicationMultiStatusEnum.offer,
  ApplicationMultiStatusEnum.need_sign,
]

const redStatuses = [
  ApplicationMultiStatusEnum.rejected,
]

export const useMultiStatus = () => {
  const label = (status: ApplicationMultiStatusEnum) => statuses[status] || status

  const isBlue = (status: ApplicationMultiStatusEnum) => blueStatuses.includes(status)
  const isGrey = (status: ApplicationMultiStatusEnum) => greyStatuses.includes(status)
  const isGreen = (status: ApplicationMultiStatusEnum) => greenStatuses.includes(status)
  const isRed = (status: ApplicationMultiStatusEnum) => redStatuses.includes(status)

  const color = (status: ApplicationMultiStatusEnum) => {
    if (isRed(status)) {
      return 'red-12'
    }

    if (isBlue(status)) {
      return 'blue-7'
    }

    if (isGreen(status)) {
      return 'green-14'
    }

    if (isGrey(status)) {
      return 'blue-grey-4'
    }

    return ''
  }

  const bgColor = (status: ApplicationMultiStatusEnum) => {
    if (isRed(status)) {
      return 'red-1'
    }

    if (isBlue(status)) {
      return 'blue-1'
    }

    if (isGreen(status)) {
      return 'green-1'
    }

    if (isGrey(status)) {
      return 'grey-2'
    }

    return ''
  }

  return {
    label,
    color,
    bgColor,
  }
}
