<template>
  <template v-if="isAuthed">
    <div
      v-if="!$q.platform.is.mobile"
      class="row authed"
    >
      <profile-info />
    </div>
    <div v-if="$q.platform.is.mobile">
      <q-btn
        icon="svguse:icons.svg?2#app-support-head"
        color="blue-grey-4"
        flat
        round
        dense
        @click="show"
      />
      <slot name="default" />
      <q-dialog
        v-model="dialog"
        full-width
        position="top"
      >
        <q-card>
          <q-card-section>
            <div class="column">
              <profile-info />
            </div>
          </q-card-section>
        </q-card>
      </q-dialog>
    </div>
  </template>
</template>

<script setup lang="ts">
import {storeToRefs} from 'pinia'
import {useAuthStore} from 'src/stores/Login/useAuthStore'
import {useQuasar} from 'quasar'
import ProfileInfo from 'src/components/ProfileInfo.vue'
import {useDialog} from 'src/uses/Dialogs/useDialog'

const authStore = useAuthStore()
const {
  isAuthed,
} = storeToRefs(authStore)

const $q = useQuasar()

const {
  dialog,
  show,
} = useDialog()
</script>

<style lang="scss">
.authed {
  font-size: 14px;
  line-height: 35px;

  & > div {
    padding:  0 20px;
  }

  .support {
    border-right: 1px solid $grey-4;
  }
}
</style>
