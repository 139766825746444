<template>
  <q-dialog
    v-on:keyup.enter="emit('update:model-value', false)"
    :model-value="props.modelValue"
    :position="$q.platform.is.mobile ? 'bottom' : 'top'"
    full-width
    @update:model-value="emit('update:model-value', $event)"
  >
    <q-card>
      <q-card-section>
        <div class="row q-gutter-sm">
          <slot name="default" />
        </div>
      </q-card-section>
      <q-card-actions
        v-if="$q.platform.is.mobile"
        class="q-pa-md"
      >
        <q-btn
          v-close-popup
          class="col-12"
          style="margin-left: 0 !important;"
          label="Применить фильтры"
          color="primary"
          size="14px"
          padding="12px"
          unelevated
          no-caps
        />
      </q-card-actions>
      <q-card-actions
        v-else
        class="items-end justify-end"
      >
        <q-btn
          label="По умолчанию"
          color="grey-2"
          class="text-blue-grey-4"
          icon-right="svguse:icons.svg?2#app-book-open"
          size="14px"
          padding="12px"
          unelevated
          no-caps
          @click="setDefaultPins"
        />
        <q-btn
          label="Сбросить все фильтры"
          color="grey-2"
          class="text-blue-grey-4"
          icon-right="svguse:icons.svg?2#app-trash"
          size="14px"
          padding="12px"
          unelevated
          no-caps
          @click="clearPins"
        />
        <q-btn
          v-close-popup
          label="Ок"
          color="primary"
          size="14px"
          padding="12px 20px"
          unelevated
          no-caps
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
import {useQuasar} from 'quasar'

const emit = defineEmits<{
  (e: 'clear-pins'): void,
  (e: 'set-default-pins'): void,
  (e: 'update:model-value', value: boolean): void,
}>()

const props = defineProps<{
  modelValue: boolean
}>()

const $q = useQuasar()

const clearPins = () => {
  emit('clear-pins')
}

const setDefaultPins = () => {
  emit('set-default-pins')
}
</script>

<style lang="scss">
.filters-header {
  height: 32px;
}
</style>
