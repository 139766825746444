<template>
  <q-form class="q-pt-sm">
    <div v-if="product" class="row q-col-gutter-sm white-bg-field">
      <div class="col-3 q-mb-sm">
        <q-input
          v-model="productTariffUpdateData.min_sum"
          :loading="loading"
          class="field-with-top-label"
          label="Минимальный тариф (₽)"
          outlined
          hide-hint
          hide-bottom-space
          dense
        />
      </div>
      <div class="col-3">
        <q-toggle
          v-model="productTariffUpdateData.is_leap_year"
          label="Високосный год"
          class="q-mr-md"
          color="blue-7"
        />
      </div>
    </div>
    <product-tariff-control-intervals-list :product-id="props.product.id" />
    <product-tariff-control-conditions :product-id="props.product.id"/>
  </q-form>
</template>

<script setup lang="ts">
import {useProductsTariffControlStore} from 'stores/Products/Tariff/useProductsTariffControlStore'
import {storeToRefs} from 'pinia'
import ProductTariffControlIntervalsList from 'components/Products/Tariff/ProductTariffControlIntervalsList.vue'
import ProductTariffControlConditions from 'components/Products/Tariff/ProductTariffControlConditions.vue'
import {watchEffect} from 'vue'
import {ProductsListItem} from 'src/models/Products/ProductsListItem'

const props = defineProps<{
  product: ProductsListItem
}>()

const productsTariffControlStore = useProductsTariffControlStore(props.product.id)()
const {
  productTariffUpdateData,
  loading,
} = storeToRefs(productsTariffControlStore)

watchEffect(() => {
  productTariffUpdateData.value = {
    ...props.product.tariff,
    conditions: props.product.conditions,
    intervals: props.product.tariff.intervals || []
  }
})

</script>

<style lang="scss">
</style>
