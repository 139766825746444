<template>
  <application-new />
  <important-news />
</template>

<script setup lang="ts">
import ApplicationNew from 'src/components/Applications/ApplicationNew/ApplicationNew.vue'
import {useMetaStore} from 'src/stores/useMetaStore'
import ImportantNews from 'components/News/ImportantNews.vue'

useMetaStore().setTitle('Создать заявку')
</script>
