<template>
  <div>
    <plugin-version
      :info="systemInfo"
      :is-valid="isValidSystemInfo"
      :error="cryptoError || null"
      :plugin-error="pluginError"
    />

    <div
      v-if="isShowCertificateSelect()"
      class="white-bg-field"
    >
      <q-select
        v-model="cert"
        :options="certsForSelect"
        class="q-mt-md"
        map-options
        label="Выберите сертификат"
        outlined
        hide-hint
        hide-bottom-space
        dense
        emit-value
      />

      <div
        v-if="selectedCert"
        class="selected-cert-info q-mt-md"
      >
        <div v-if="ownerInfo?.fio"><span>ФИО:</span> {{ ownerInfo.fio }}</div>
        <div v-if="ownerInfo?.post"><span>Должность:</span> {{ ownerInfo.post }}</div>
        <div v-if="ownerInfo?.company"><span>Компания:</span> {{ ownerInfo.company }}</div>
        <div v-if="ownerInfo?.company"><span>ИНН:</span> {{ ownerInfo.inn }}</div>
        <div v-if="ownerInfo?.validTo"><span>Подпись истекает:</span> {{ ownerInfo.validTo }}</div>
      </div>

      <div
        v-if="certificateNoChange"
        class="auth-info error little"
      >
        <div>Сертификат не выбран</div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {computed, watch} from 'vue'
import {useCryptoPro} from 'src/uses/CryptoPro/useCryptoPro'
import PluginVersion from 'src/components/Sign/PluginVersion.vue'
import {createAttachedSignature} from 'crypto-pro'

const props = defineProps<{
  fileForSign: string,
}>()

const emit = defineEmits<{
  (e: 'update:signedFile', signedFile: string, certName: string): void,
}>()

const fileForSign = computed(() => props.fileForSign)
const {
  cert,
  systemInfo,
  isValidSystemInfo,
  certsForSelect,
  certificateNoChange,
  selectedCert,
  ownerInfo,
  pluginError,
  cryptoError,
  getPluginInfo,
  isShowCertificateSelect,
  addErrorMessage,
  base64ToArrayBuffer,
} = useCryptoPro()

const onSign = async () => {
  let sign = ''
  try {
    sign = await createAttachedSignature(cert.value, base64ToArrayBuffer(fileForSign.value))
    emit('update:signedFile', sign, ownerInfo.value ? ownerInfo.value.company : '')
  } catch (e) {
    addErrorMessage((e as Error).message)
  }
}

watch(
  props,
  async () => {
    if (props.fileForSign) {
      await getPluginInfo()
    }
  },
  {deep: true, immediate: true}
)

defineExpose({
  onSign,
})

</script>
<style lang="scss">
.selected-cert-info {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 5px;
  span {
    font-weight: bold;
  }
}
</style>
