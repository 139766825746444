import {computed, Ref, ref} from 'vue'
import {
  required,
} from 'src/utils/i18n-validators'
import useVuelidate from '@vuelidate/core'
import {usePostApi} from 'src/uses/Api/usePostApi'
import {useValidationRules} from 'src/uses/useValidationRules'
import {Contractor} from 'src/models/Contractors/Contractor'
import {useGetContractorItem} from 'src/uses/Contractors/useGetContractorItem'
import {FormHelper} from 'src/helpers/FormHelper'

export const useContractorsCreateUpdate = () => {
  const getContractorDataStore = useGetContractorItem()
  const {
    load,
    getContractorRequestData
  } = getContractorDataStore

  const {
    post,
    loading,
    status,
    errors,
  } = usePostApi<Contractor, FormData>()

  const {
    getRulesFromState,
  } = useValidationRules()

  const contractorData = ref<Contractor>({
    id: '',
    title: '',
    inn: '',
    manager_fio: '',
    address: '',
  })

  const itemId = ref<string>()
  const file = ref<File | null>(null)

  const rules = computed(() => {
    return {
      ...getRulesFromState(contractorData.value),
      inn: {
        required,
      },
      title: {
        required,
      },
      manager_fio: {
        required,
      },
      address: {
        required,
      },
      template: {
        required,
      },
    }
  })

  const validation = useVuelidate<Ref<Contractor>>(rules, contractorData)

  const removeFile = () => {
    contractorData.value.template = null
  }

  const create = async () => {
    if (!await validation.value.$validate()) {
      return
    }

    await post('v2/contractors', FormHelper.getFormDataFromObject(contractorData.value))
  }

  const update = async () => {
    if (!itemId.value || !await validation.value.$validate()) {
      return
    }

    const data = {
      ...contractorData.value
    }

    if (!data.template?.name) {
      delete data.template
    }

    await post(`v2/contractors/${itemId.value}`, FormHelper.getFormDataFromObject(data))
  }

  const loadContractorItem = async (id: string) => {
    await load(id)
    contractorData.value = {
      ...getContractorRequestData()
    }
  }

  const clearData = () => {
    contractorData.value = {
      id: '',
      title: '',
      inn: '',
      manager_fio: '',
      address: '',
      template: null,
    }
  }

  return {
    contractorData,
    validation,
    status,
    errors,
    loading,
    itemId,
    rules,
    file,
    create,
    update,
    removeFile,
    loadContractorItem,
    clearData,
  }
}
