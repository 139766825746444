import {usePutApi} from 'src/uses/Api/usePutApi'

export const useApplicationComment = () => {
  const {
    put,
    response,
    status,
    loading,
    error,
    errors,
  } = usePutApi<unknown, {comment: string}>(true)

  const update = async (
    id: string,
    comment: string,
  ) => {
    await put(
      `v1/order/${id}/additional-information/comment`,
      {
        comment
      }
    )
  }

  return {
    put,
    response,
    status,
    loading,
    error,
    errors,
    update
  }
}
