import {useFilterWithErrors} from 'src/uses/Applications/Form/fill/useFilterWithErrors'
import {computed} from 'vue'
import {ProductFields} from 'src/models/Applications/Form/ProductFields'
import {ApplicationFormBank} from 'src/models/Applications/Form/ApplicationFormResponseData'
import {Ref} from 'vue-demi'

export const useApplicationFormFillMain = (mainVisibleFields: Ref<ProductFields>, banks: Ref<ApplicationFormBank[]>) => {
  const {
    filterWithErrors,
    filterOnlyCommonOrDraftBanks,
  } = useFilterWithErrors(banks)

  const mainFields = computed(() => {
    if (!mainVisibleFields) {
      return {
        all: [],
        banks: [],
      }
    }

    return {
      all: mainVisibleFields.value.all.filter(filterOnlyCommonOrDraftBanks),
      banks: mainVisibleFields.value.banks
        .map(bank => {
          return {
            ...bank,
            fields: bank.fields.filter(filterOnlyCommonOrDraftBanks),
          }
        })
    }
  })

  const mainErrorFields = computed<ProductFields>(() => {
    return {
      all: mainFields.value.all.filter(filterWithErrors),
      banks: mainFields.value.banks
        .map(bank => {
          return {
            ...bank,
            fields: bank.fields.filter(filterWithErrors),
          }
        })
    }
  })

  const fillMainByBanks = computed(() => {
    if (!banks.value || !mainVisibleFields.value) {
      return []
    }

    return banks.value
      .map(bank => {
        const allWithFields = mainVisibleFields.value.banks
          .find(b => b.bank_id === bank.bank_id)

        const errorWithFields = mainErrorFields.value.banks
          .find(b => b.bank_id === bank.bank_id)

        return {
          ...bank,
          total: mainVisibleFields.value.all.length + (allWithFields ? allWithFields.fields.length : 0),
          error: mainErrorFields.value.all
            .filter(field => field.valid
              .find(valid => valid.errors.length && valid.id === bank.bank_id)
            )
            .length
            + (errorWithFields ? errorWithFields.fields.length : 0),
        }
      })
  })

  const countAllMainFields = computed(() => {
    if (!mainFields.value) {
      return 0
    }

    return mainFields.value.all.length
      + mainFields.value.banks
        .map(bank => bank.fields.length)
        .reduce((sum, length) => sum + length, 0)
  })

  const countErrorsMainFields = computed(() => {
    if (!mainErrorFields.value) {
      return 0
    }

    return mainErrorFields.value.all.length
      + mainErrorFields.value.banks
        .map(bank => bank.fields.length)
        .reduce((sum, length) => sum + length, 0)
  })

  return {
    countAllMainFields,
    countErrorsMainFields,
    mainErrorFields,
    fillMainByBanks,
  }
}
