<template>
  <div
    v-if="data"
    class="white-bg-field"
    style="margin-top: -24px;"
  >
    <info-banner
      text="Настроив кабинет, вы обеспечите привычную среду для сотрудников, а также повысите лояльность и доверие клиентов"
      caption="Вы можете брендировать кабинет клиента в соответствии с фирменным стилем вашей компании."
    />
    <div class="text-h5 q-my-lg">Настройка кабинета клиента</div>
    <div class="column q-col-gutter-y-xl">
      <div>
        <div class="text-h6 q-mb-md">Логотип компании</div>
        <div class="row q-col-gutter-x-md">
          <div class="col col-xs-12 col-md-grow q-mb-sm">
            <portal-sign-upload-with-preview
              :url="data.design_config.logo"
              class="fit"
              accept=".png, .svg"
              help="макс.2Mb"
              @file:selected="onLogoSelected"
              @change:src="data.design_config.logo = $event; noLogoImg = false"
            />
            <div
              v-if="hasError('logo')"
              class="q-field--error q-mb-md"
            >
              {{ getErrorMessage('logo') }}
            </div>
          </div>
          <div class="col col-xs-12 col-md-grow">
            <blue-info class="fit">
              Рекомендуем загружать горизонтально ориентированные изображения с прозрачным фоном
            </blue-info>
          </div>
        </div>
        <q-toggle
          v-model="noLogoImg"
          class="q-my-md"
          label="У компании отсутствует логотип"
        />
        <q-intersection
          v-if="noLogoImg"
          transition="scale"
          once
        >
          <div class="noLogoAlternative q-pa-md">
            <blue-info samll>
              Если временно отсутствует логотип, мы укажем название компании в выбранном вами фирменном цвете
            </blue-info>
            <div class="row q-mt-md q-col-gutter-x-md">
              <div class="col col-xs-12 col-md-grow">
                <q-input
                  v-model="data.design_config.logo_placeholder_text"
                  class="field-with-top-label"
                  label="Название компании"
                  :error="hasError('logo_placeholder_text')"
                  :error-message="getErrorMessage('logo_placeholder_text')"
                  outlined
                  hide-bottom-space
                  dense
                  @blur="clearError('logo_placeholder_text')"
                />
              </div>
              <div class="col col-xs-12 col-md-grow">
                <q-input
                  v-model="data.design_config.logo_placeholder_color"
                  class="field-with-top-label"
                  label="Цвет шрифта"
                  :rules="['anyColor']"
                  :error="hasError('logo_placeholder_color')"
                  :error-message="getErrorMessage('logo_placeholder_color')"
                  outlined
                  hide-bottom-space
                  dense
                  @blur="clearError('logo_placeholder_color')"
                >
                  <template #append>
                    <div class="row q-col-gutter-x-sm">
                      <div
                        class="colorPreview q-ml-md"
                        :style="`background-color: ${data.design_config.logo_placeholder_color || 'none'}`"
                      />
                      <q-icon
                        name="svguse:icons.svg?2#app-pipette"
                        class="cursor-pointer"
                      >
                        <q-popup-proxy cover>
                          <q-color v-model="data.design_config.logo_placeholder_color" />
                        </q-popup-proxy>
                      </q-icon>
                    </div>
                  </template>
                </q-input>
              </div>
            </div>
          </div>
        </q-intersection>
      </div>

      <div class="row q-col-gutter-x-md">
        <div class="col col-xs-12 col-md-grow q-mb-md">
          <div class="text-h6 q-mb-md">Фон при входе в кабинет</div>
          <portal-sign-upload-with-preview
            class="fit"
            :url="data.design_config.home_page_background_image"
            accept=".png, .svg, .jpeg, .jpg"
            help="рекомендуемый разм.1920x1080, макс.2Mb"
            @file:selected="onBgEnterSelected"
            @change:src="data.design_config.home_page_background_image = $event"
          />
          <div
            v-if="hasError('home_page_background_image')"
            class="q-field--error q-mb-md"
          >
            {{ getErrorMessage('home_page_background_image') }}
          </div>
        </div>
        <div class="col col-xs-12 col-md-grow q-mb-md">
          <div class="text-h6 q-mb-md">Фон кабинета</div>
          <portal-sign-upload-with-preview
            :url="data.design_config.other_page_background_image"
            class="fit"
            accept=".png, .svg, .jpeg, .jpg"
            help="рекомендуемый разм.1920x1080, макс.2Mb"
            @file:selected="onBgInnerSelected"
            @change:src="data.design_config.other_page_background_image = $event"
          />
          <div
            v-if="hasError('other_page_background_image')"
            class="q-field--error q-mb-md"
          >
            {{ getErrorMessage('other_page_background_image') }}
          </div>
        </div>
      </div>

      <div>
        <div class="text-h6 q-mb-md">Цвет фона</div>
        <div class="row q-col-gutter-x-md">
          <div class="col col-xs-12 col-md-grow q-mb-md">
            <q-input
              v-model="data.design_config.home_page_background_color"
              class="field-with-top-label"
              label="Фон всплывающих окон"
              :rules="['anyColor']"
              :error="hasError('home_page_background_color')"
              :error-message="getErrorMessage('home_page_background_color')"
              outlined
              hide-bottom-space
              dense
              @blur="clearError('home_page_background_color')"
            >
              <template #append>
                <div class="row q-col-gutter-x-sm">
                  <div
                    class="colorPreview q-ml-md"
                    :style="`background-color: ${data.design_config.home_page_background_color || 'none'}`"
                  />
                  <q-icon
                    name="svguse:icons.svg?2#app-pipette"
                    class="cursor-pointer"
                  >
                    <q-popup-proxy cover>
                      <q-color v-model="data.design_config.home_page_background_color" />
                    </q-popup-proxy>
                  </q-icon>
                </div>
              </template>
            </q-input>
          </div>
          <div class="col col-xs-12 col-md-grow q-mb-md">
            <q-input
              v-model="data.design_config.other_page_background_color"
              class="field-with-top-label"
              label="Фон информационного сообщения"
              :rules="['anyColor']"
              :error="hasError('other_page_background_color')"
              :error-message="getErrorMessage('other_page_background_color')"
              outlined
              hide-bottom-space
              dense
              @blur="clearError('other_page_background_color')"
            >
              <template #append>
                <div class="row q-col-gutter-x-sm">
                  <div
                    class="colorPreview q-ml-md"
                    :style="`background-color: ${data.design_config.other_page_background_color || 'none'}`"
                  />
                  <q-icon
                    name="svguse:icons.svg?2#app-pipette"
                    class="cursor-pointer"
                  >
                    <q-popup-proxy cover>
                      <q-color v-model="data.design_config.other_page_background_color" />
                    </q-popup-proxy>
                  </q-icon>
                </div>
              </template>
            </q-input>
          </div>
        </div>
      </div>

      <div>
        <div class="text-h6 q-mb-md">Основной акцентный цвет</div>
        <div class="row q-col-gutter-x-md">
          <div class="col col-xs-12 col-md-grow q-mb-md">
            <q-input
              v-model="data.design_config.btn_background_color"
              class="field-with-top-label"
              label="Цвет кнопок и переключателей"
              :rules="['anyColor']"
              :error="hasError('btn_background_color')"
              :error-message="getErrorMessage('btn_background_color')"
              outlined
              hide-bottom-space
              dense
              @blur="clearError('btn_background_color')"
            >
              <template #append>
                <div class="row q-col-gutter-x-sm">
                  <div
                    class="colorPreview  q-ml-md"
                    :style="`background-color: ${data.design_config.btn_background_color || 'none'}`"
                  />
                  <q-icon
                    name="svguse:icons.svg?2#app-pipette"
                    class="cursor-pointer"
                  >
                    <q-popup-proxy cover>
                      <q-color v-model="data.design_config.btn_background_color" />
                    </q-popup-proxy>
                  </q-icon>
                </div>
              </template>
            </q-input>
          </div>
          <div class="col col-xs-12 col-md-grow q-mb-md">
            <q-input
              v-model="data.design_config.btn_text_color"
              class="field-with-top-label"
              label="Цвет текста кнопок"
              :rules="['anyColor']"
              :error="hasError('btn_text_color')"
              :error-message="getErrorMessage('btn_text_color')"
              outlined
              hide-bottom-space
              dense
              @blur="clearError('btn_text_color')"
            >
              <template #append>
                <div class="row q-col-gutter-x-sm">
                  <div
                    class="colorPreview  q-ml-md"
                    :style="`background-color: ${data.design_config.btn_text_color || 'none'}`"
                  />
                  <q-icon
                    name="svguse:icons.svg?2#app-pipette"
                    class="cursor-pointer"
                  >
                    <q-popup-proxy cover>
                      <q-color v-model="data.design_config.btn_text_color" />
                    </q-popup-proxy>
                  </q-icon>
                </div>
              </template>
            </q-input>
          </div>
        </div>
      </div>

      <div>
        <div class="text-h6 q-mb-md">Дополнительный акцентный цвет</div>
        <div class="row q-col-gutter-x-md">
          <div class="col col-xs-12 col-md-grow q-mb-md">
            <q-input
              v-model="data.design_config.input_background_color"
              class="field-with-top-label"
              label="Цвет инпутов и выбранных строк"
              :rules="['anyColor']"
              :error="hasError('input_background_color')"
              :error-message="getErrorMessage('input_background_color')"
              outlined
              hide-bottom-space
              dense
              @blur="clearError('input_background_color')"
            >
              <template #append>
                <div class="row q-col-gutter-x-sm">
                  <div
                    class="colorPreview  q-ml-md"
                    :style="`background-color: ${data.design_config.input_background_color || 'none'}`"
                  />
                  <q-icon
                    name="svguse:icons.svg?2#app-pipette"
                    class="cursor-pointer"
                  >
                    <q-popup-proxy cover>
                      <q-color v-model="data.design_config.input_background_color" />
                    </q-popup-proxy>
                  </q-icon>
                </div>
              </template>
            </q-input>
          </div>
          <div class="col col-xs-12 col-md-grow q-mb-md">
            <q-input
              v-model="data.design_config.input_text_color"
              class="field-with-top-label"
              label="Цвет текста инпутов"
              :rules="['anyColor']"
              :error="hasError('input_text_color')"
              :error-message="getErrorMessage('input_text_color')"
              outlined
              hide-bottom-space
              dense
              @blur="clearError('input_text_color')"
            >
              <template #append>
                <div class="row q-col-gutter-x-sm">
                  <div
                    class="colorPreview q-ml-md"
                    :style="`background-color: ${data.design_config.input_text_color || 'none'}`"
                  />
                  <q-icon
                    name="svguse:icons.svg?2#app-pipette"
                    class="cursor-pointer"
                  >
                    <q-popup-proxy cover>
                      <q-color v-model="data.design_config.input_text_color" />
                    </q-popup-proxy>
                  </q-icon>
                </div>
              </template>
            </q-input>
          </div>
        </div>
      </div>

      <div>
        <div class="text-h6 q-mb-md">Дополнительная информация</div>
        <div class="row q-my-md q-col-gutter-x-md">
          <div class="col col-xs-12 col-md-grow q-mb-md">
            <q-input
              v-model="data.design_config.support_phone"
              v-mask="'+# (###) ###-##-##'"
              class="field-with-top-label"
              label="Телефон службы поддержки"
              :error="hasError('support_phone')"
              :error-message="getErrorMessage('support_phone')"
              outlined
              hide-bottom-space
              dense
              @blur="clearError('support_phone')"
            />
          </div>
          <div class="col col-xs-12 col-md-grow q-mb-md">
            <q-input
              v-model="data.design_config.support_email"
              class="field-with-top-label"
              label="E-mail службы поддержки"
              :rules="['email']"
              :error="hasError('support_email')"
              :error-message="getErrorMessage('support_email')"
              outlined
              hide-bottom-space
              dense
              @blur="clearError('email')"
            />
          </div>
        </div>
      </div>

      <div>
        <div class="PortalDocuments overflow-hidden">
          <div class="q-pa-md">
            <blue-info small>
              Укажите название и ссылки на документы с которыми должны ознакомиться и согласиться клиенты при входе в кабинет. Например, это могут быть политики конфиденциональности или согласия на обработку персональных данных.
            </blue-info>
            <div
              v-for="(doc, index) in data.design_config.documents"
              :key="index"
              class="row q-mt-md q-col-gutter-x-md"
            >
              <div class="col col-xs-12 col-md-grow q-mb-md">
                <q-input
                  v-model="doc.title"
                  class="field-with-top-label"
                  label="Название документа"
                  :error="hasError(`documents[${index}].title`)"
                  :error-message="getErrorMessage(`documents[${index}].title`)"
                  outlined
                  hide-bottom-space
                  dense
                  @blur="clearError(`documents[${index}].title`)"
                />
              </div>
              <div class="col col-xs-12 col-md-grow q-mb-md">
                <q-input
                  v-model="doc.url"
                  class="field-with-top-label"
                  label="Ссылка на документ"
                  :error="hasError(`documents[${index}].url`)"
                  :error-message="getErrorMessage(`documents[${index}].url`)"
                  outlined
                  hide-bottom-space
                  dense
                  @blur="clearError(`documents[${index}].url`)"
                />
              </div>
              <div
                v-if="!$q.platform.is.mobile"
                class="q-mt-lg"
              >
                <q-btn
                  icon="svguse:icons.svg?2#app-x"
                  class="text-blue-grey-5 q-mb-sm q-mt-md"
                  dense
                  unelevated
                  @click="deleteDocument(index)"
                />
              </div>
              <div
                v-if="$q.platform.is.mobile"
                class="col-xs-12 col-md-grow q-mb-md"
              >
                <q-btn
                  class="q-mb-sm full-width"
                  style="height: 50px;"
                  label="Удалить"
                  color="negative"
                  unelevated
                  @click="deleteDocument(index)"
                />
              </div>
            </div>
          </div>
          <q-btn
            label="Добавить поля"
            class="text-blue-grey-5 q-mt-md full-width"
            color="for-blue-grey-5"
            icon="svguse:icons.svg?2#app-plus-1"
            no-caps
            unelevated
            square
            @click="addDocument"
          />
        </div>
      </div>

      <div>
        <q-toggle
          v-model="data.design_config.show_gosoblako"
          class="q-my-md"
          label="Показывать текст &quot;Ведущий партнер GOSOBLAKO&quot;"
        />
      </div>
    </div>

    <div v-if="data.banner">
      <div class="text-h5 q-my-lg">Настройка рекламного блока</div>
      <div class="column q-col-gutter-y-xl q-mb-lg">
        <div>
          <div class="row">
            <div class="col-xs-12 col-md-4 q-mb-md">
              <q-toggle
                v-model="data.banner.enabled"
                class="q-my-md"
                label="Рекламный блок активен"
              />
            </div>
            <div class="col-xs-12 col-md-8 q-mb-md">
              <blue-info small>
                <div class="row">
                  <div class="col col-xs-12 col-md-grow q-mb-md">Вы можете настроить оформление рекламного блока, который увидят ваши клиенты в своем кабинете</div>
                  <ad-example-modal />
                </div>
              </blue-info>
            </div>
          </div>
        </div>

        <div v-if="data.banner.enabled">
          <div>
            <div class="text-h6 q-mb-md">Основная информация</div>
            <div class="row q-my-md q-col-gutter-x-md">
              <div class="col-12 q-mb-lg">
                <q-input
                  v-model="data.banner.title"
                  class="field-with-top-label"
                  label="Заголовок"
                  maxlength="50"
                  :error="bannerHasError('title')"
                  :error-message="getBannerErrorMessage('title')"
                  outlined
                  hide-bottom-space
                  dense
                  @blur="clearBannerError('title')"
                />
              </div>
              <div class="col-12">
                <q-input
                  v-model="data.banner.text"
                  class="field-with-top-label"
                  label="Текст"
                  maxlength="70"
                  :error="bannerHasError('text')"
                  :error-message="getBannerErrorMessage('text')"
                  outlined
                  hide-bottom-space
                  dense
                  @blur="clearBannerError('text')"
                />
              </div>
            </div>
          </div>

          <div class="row q-col-gutter-x-md q-mt-xl">
            <div class="col col-xs-12 col-md-grow q-mb-md">
              <div class="text-h6 q-mb-md">Иллюстрация</div>
              <portal-sign-upload-with-preview
                :url="data.banner.image"
                class="fit"
                accept=".png, .jpeg, .jpg"
                help="рекомендуемый разм.1920x1080, макс.2Mb"
                @file:selected="onBannerImgSelected"
                @change:src="data.banner.image = $event"
              />
            </div>
            <div class="col col-xs-12 col-md-grow q-mb-md">
              <q-input
                v-model="data.banner.background_color"
                class="field-with-top-label"
                label="Цвет фона"
                :rules="['anyColor']"
                outlined
                hide-bottom-space
                dense
              >
                <template #append>
                  <div class="row q-col-gutter-x-sm">
                    <div
                      class="colorPreview  q-ml-md"
                      :style="`background-color: ${data.banner.background_color || 'none'}`"
                    />
                    <q-icon
                      name="svguse:icons.svg?2#app-pipette"
                      class="cursor-pointer"
                    >
                      <q-popup-proxy cover>
                        <q-color v-model="data.banner.background_color" />
                      </q-popup-proxy>
                    </q-icon>
                  </div>
                </template>
              </q-input>
            </div>
          </div>

          <div class="q-mt-xl">
            <div class="text-h6 q-mb-md">Кнопка действия</div>
            <div class="row q-col-gutter-x-md">
              <div class="col col-xs-12 col-md-grow q-mb-md">
                <q-input
                  v-model="data.banner.btn_title"
                  class="field-with-top-label"
                  label="Текст кнопки"
                  :error="bannerHasError('btn_title')"
                  :error-message="getBannerErrorMessage('btn_title')"
                  outlined
                  hide-bottom-space
                  dense
                  @blur="clearBannerError('btn_title')"
                />
              </div>
              <div class="col col-xs-12 col-md-grow q-mb-md">
                <q-input
                  v-model="data.banner.btn_background_color"
                  class="field-with-top-label"
                  label="Цвет кнопки"
                  :rules="['anyColor']"
                  outlined
                  hide-bottom-space
                  dense
                >
                  <template #append>
                    <div class="row q-col-gutter-x-sm">
                      <div
                        class="colorPreview  q-ml-md"
                        :style="`background-color: ${data.banner.btn_background_color || 'none'}`"
                      />
                      <q-icon
                        name="svguse:icons.svg?2#app-pipette"
                        class="cursor-pointer"
                      >
                        <q-popup-proxy cover>
                          <q-color v-model="data.banner.btn_background_color" />
                        </q-popup-proxy>
                      </q-icon>
                    </div>
                  </template>
                </q-input>
              </div>
              <div class="col col-xs-12 col-md-grow q-mb-md">
                <q-input
                  v-model="data.banner.btn_text_color"
                  class="field-with-top-label"
                  label="Цвет текста"
                  :rules="['anyColor']"
                  outlined
                  hide-bottom-space
                  dense
                >
                  <template #append>
                    <div class="row q-col-gutter-x-sm">
                      <div
                        class="colorPreview  q-ml-md"
                        :style="`background-color: ${data.banner.btn_text_color || 'none'}`"
                      />
                      <q-icon
                        name="svguse:icons.svg?2#app-pipette"
                        class="cursor-pointer"
                      >
                        <q-popup-proxy cover>
                          <q-color v-model="data.banner.btn_text_color" />
                        </q-popup-proxy>
                      </q-icon>
                    </div>
                  </template>
                </q-input>
              </div>
            </div>
          </div>
          <div class="q-mt-lg">
            <div class="row q-col-gutter-x-md">
              <div class="col">
                <q-input
                  v-model="data.banner.btn_url"
                  class="field-with-top-label"
                  label="Сссылка для перехода"
                  :error="bannerHasError('btn_url')"
                  :error-message="getBannerErrorMessage('btn_url')"
                  outlined
                  hide-bottom-space
                  dense
                  @blur="clearBannerError('btn_url')"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="q-pt-xl">
      <div class="row">
        <div class="q-mb-sm col-xs-12 col-md-auto q-mb-md">
          <portal-sign-preview
            v-if="data"
            :data="data.design_config"
            :banner="data.banner"
          />
        </div>
        <q-space />
        <div class="col-xs-12 col-md-auto q-mb-md">
          <q-btn
            :loading="loading"
            label="Сохранить изменения"
            color="primary"
            :class="{'full-width': $q.platform.is.mobile}"
            icon-right="svguse:icons.svg?2#app-check"
            style="height: 50px;"
            unelevated
            no-caps
            @click="handleSubmit"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import InfoBanner from 'components/InfoBanner.vue'
import BlueInfo from 'components/BlueInfo.vue'
import {onBeforeMount, ref, computed, Ref} from 'vue'
import PortalSignUploadWithPreview from 'components/Profile/PortalSign/PortalSignUploadWithPreview.vue'
import {useAgentGetPortalData} from 'src/uses/Profiles/Agents/useAgentGetPortalData'
import {useValidationRules} from 'src/uses/useValidationRules'
import useVuelidate from '@vuelidate/core'
import {
  AgentPortalBanner,
  AgentPortalDataDesignConfig,
} from 'src/models/Profiles/Agents/AgentPortalData'
import {useValidation} from 'src/uses/useValidation'
import {useAgentPostPortalData} from 'src/uses/Profiles/Agents/useAgentPostPortalData'
import {Notify, useQuasar} from 'quasar'
import PortalSignPreview from 'components/Profile/PortalSign/PortalSignPreview.vue'
import AdExampleModal from 'components/Profile/PortalSign/AdExampleModal.vue'
import {urlValidator, required} from 'src/utils/i18n-validators'

const {
  getRulesFromState,
} = useValidationRules()

const {
  data,
  load,
} = useAgentGetPortalData()

const {
  errors,
  loading,
  status,
  save,
} = useAgentPostPortalData()

const $q = useQuasar()

const logoFile = ref<File>()
const noLogoImg = ref<boolean>(false)
const bgEnterFile = ref<File>()
const bgInnerFile = ref<File>()
const bannerImgFile = ref<File>()
const rules = computed(() => {
  return {
    ...getRulesFromState(data.value ? data.value?.design_config : {}),
  }
})

const designConfig = computed(() => {
  return data.value ? data.value?.design_config : undefined
})

const validation = useVuelidate<Ref<AgentPortalDataDesignConfig | undefined>>(
  rules,
  designConfig
)

const {
  hasError,
  getErrorMessage,
  clearError,
} = useValidation(validation, errors)

const bannerRules = computed(() => {
  return {
    btn_url: {urlValidator, required},
    title: {required},
    text: {required},
    btn_title: {required},
  }
})

const bannerData = computed(() => {
  return data.value ? data.value?.banner : undefined
})

const bannerValidation = useVuelidate<Ref<AgentPortalBanner | undefined>>(
  bannerRules,
  bannerData
)

const {
  hasError: bannerHasError,
  getErrorMessage: getBannerErrorMessage,
  clearError: clearBannerError,
} = useValidation(bannerValidation, errors)

const handleSubmit = async () => {
  if (!data.value) {
    return
  }

  if (data.value?.banner.enabled && !await bannerValidation.value.$validate()) {
    return
  }

  await save(
    data.value,
    logoFile.value,
    bgEnterFile.value,
    bgInnerFile.value,
    bannerImgFile.value,
    noLogoImg.value && !logoFile.value,
  )

  if (status.value === 200) {
    Notify.create({
      type: 'positive',
      message: 'Данные успешно сохранены',
      position: 'top',
      timeout: 2000,
      textColor: 'white',
    })
  }
}

const onLogoSelected = (file: File) => {
  clearError('logo')
  logoFile.value = file
}

const onBgEnterSelected = (file: File) => {
  clearError('home_page_background_image')
  bgEnterFile.value = file
}

const onBgInnerSelected = (file: File) => {
  clearError('other_page_background_image')
  bgInnerFile.value = file
}

const onBannerImgSelected = (file: File) => {
  bannerImgFile.value = file
}

const addDocument = () => {
  if (!data.value || !data.value.design_config) {
    return
  }

  data.value.design_config.documents.push({
    title: '',
    url: '',
  })
}

const deleteDocument = (index: number) => {
  if (!data.value || !data.value.design_config) {
    return
  }

  data.value.design_config.documents
    .splice(index, 1)
}

onBeforeMount(async () => {
  await load()
  if (!data.value || !data.value.design_config) {
    return
  }

  noLogoImg.value = !data.value.design_config.logo && !!data.value.design_config.logo_placeholder_text
})
</script>

<style lang="scss">
.noLogoAlternative {
  border-radius: 10px;
  border: 1px solid #C7DFF1;
}

.PortalDocuments {
  border-radius: 10px;
  border: 1px solid #C7DFF1;
}

.colorPreview {
  width: 25px;
  height: 25px;
  border: 1px solid #ccc;
  border-radius: 3px;
}
</style>
