<template>
  <div v-if="error || pluginError">
    <div class="auth-info error">
      <div v-if="pluginError">Плагин не загружен</div>
      <div v-if="error">{{ error }}</div>
    </div>
  </div>

  <div
    v-else
    class="sign-plugin-info"
  >
    <div
      v-if="!info"
      style="color:#1E88E5"
    >
      Попытка подключения плагина...
    </div>
    <div v-if="info">
      <div style="color:#1E88E5">Плагин загружен</div>
      <div class="version">Версия плагина: {{ info.cadesVersion }}</div>
      <div class="version">Версия КриптоПро: {{ info.cspVersion }}</div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {computed} from 'vue'
import {SystemInfo} from 'crypto-pro'

const props = defineProps<{
  info: SystemInfo | undefined,
  isValid: boolean,
  error: string | null | undefined,
  pluginError: boolean,
}>()

const info = computed(() => props.info)
const error = computed(() => props.error)
const pluginError = computed(() => props.pluginError)
</script>
<style lang="scss">
.sign-plugin-info {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 5px;
  .version {
    color: #9E9E9E;
  }
}
</style>
