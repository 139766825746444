<template>
  <span class="q-mr-md q-ml-sm ApplicationGridProductToggle">
    <q-btn-toggle
      v-model="productType"
      color="grey-2"
      text-color="grey-7"
      toggle-color="blue-1"
      toggle-text-color="primary"
      :disable="props.loading"
      :options="[
        {value: ProductEnum.EBG, slot: ProductEnum.EBG},
        {value: ProductEnum.KIK, slot: ProductEnum.KIK},
      ]"
      padding="10px 20px"
      unelevated
      no-caps
      dense
    >
      <template #EBG>
        <div class="row items-center no-wrap q-col-gutter-x-sm">
          <q-icon name="svguse:/icons.svg#ebg" />
          <div class="text-center text-no-wrap">
            {{ getProductLabel(ProductEnum.EBG) }}
          </div>
        </div>
      </template>
      <template #KIK>
        <div class="row items-center no-wrap q-col-gutter-x-sm">
          <q-icon name="svguse:/icons.svg#kik" />
          <div class="text-center text-no-wrap">
            {{ getProductLabel(ProductEnum.KIK) }}
          </div>
        </div>
      </template>
    </q-btn-toggle>
  </span>
</template>

<script setup lang="ts">
import {ProductEnum} from 'src/models/Applications/ProductEnum'
import {ref, watch} from 'vue'
import {useRoute, useRouter} from 'vue-router'
import {useProduct} from 'src/uses/Applications/useProduct'

const props = defineProps<{
  loading: boolean,
}>()

const route = useRoute()
const router = useRouter()

const {getProductLabel} = useProduct()

const productType = ref<ProductEnum>(route.name === 'applications-grid-kik' ? ProductEnum.KIK : ProductEnum.EBG)

watch(
  productType,
  () => {
    router.push({
      name: productType.value === ProductEnum.KIK ? 'applications-grid-kik' : 'applications-grid'
    })
  }
)
</script>

<style lang="scss">
.ApplicationGridProductToggle {
  .q-btn-group {
    .q-btn {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}
</style>
