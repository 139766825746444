<template>
  <q-btn
    v-if="isAdmin() || isJuniorAdmin()"
    size="sm"
    :color="!props.enabled ? 'blue-grey-4': 'primary'"
    icon="svguse:icons.svg?2#app-briefcase"
    round
    flat
    @click="emit('click')"
  >
    <q-tooltip>
      Партнерское подписание
    </q-tooltip>
  </q-btn>
</template>

<script setup lang="ts">
import {useAuthStore} from 'stores/Login/useAuthStore'

const props = defineProps<{
  enabled: boolean
}>()

const emit = defineEmits<{
  (e: 'click'): void,
}>()

const {
  isAdmin,
  isJuniorAdmin,
} = useAuthStore()
</script>

<style scoped lang="scss">

</style>
