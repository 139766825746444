<template>
  <q-btn
    v-if="control"
    class="fixed-bottom-right z-top"
    icon="svguse:icons.svg?2#app-support"
    style="margin-bottom: 80px; border-radius: 10px 0 0 10px !important;"
    color="blue-grey-10"
    padding="10px 0 10px 5px"
    stretch
    @click="onClick"
  />
</template>

<script setup lang="ts">
import {ref} from 'vue'

const control = ref<HTMLDivElement>()

const getControl = () => {
  const div = document.querySelector<HTMLDivElement>('.b24-widget-button-wrapper .b24-widget-button-inner-item')
  if (!div) {
    return
  }

  control.value = div
}

const onClick = () => {
  if (!control.value) {
    return
  }

  control.value.click()
}

const interval = setInterval(
  () => {
    if (control.value) {
      clearInterval(interval)
      return
    }

    getControl()
  },
  1000
)
</script>

<style lang="scss">
  .b24-widget-button-wrapper {
    z-index: -1 !important;
    opacity: 0 !important;
  }
</style>
