<template>
  <div class="col-sm col-xs-12 bannerMainContent">
    <div
      v-if="!headerInfo.filled"
      style="margin-top: 10px"
    >
      Для продолжения работы по заявке вам необходимо перейти в анкету и заполнить ее
    </div>
    <div v-if="headerInfo.filled">
      Анкета по данной заявке заполнена, вы можете отправить ее на проверку. <br> Если вам необходимо что-то исправить,
      <q-btn
        class="text-link-button"
        label="Перейдите в анкету"
        icon-right="svguse:icons.svg?2#app-arrow-right"
        size="xs"
        :to="{name: 'application-item', params: {formId: getFormId()}}"
      />
      для внесения правок в данные
    </div>
  </div>
  <div class="bannerInfoBtnWrap">
    <q-btn
      v-if="!headerInfo.filled"
      class="banner-btn"
      label="Доработать заявку"
      icon-right="svguse:icons.svg?2#app-pencil-alt"
      color="primary"
      unelevated
      no-caps
      :to="{name: 'application-item', params: {formId: getFormId()}}"
    />
    <q-btn
      v-if="headerInfo.filled"
      class="banner-btn"
      label="Отправить на проверку"
      icon-right="svguse:icons.svg?2#app-arrow-right"
      color="primary"
      unelevated
      no-caps
      :loading="loading"
      @click="sendGo(headerInfo.id)"
    />
  </div>
</template>

<script setup lang="ts">
import {computed} from 'vue'
import {ControlCenterHeader} from 'src/models/Applications/ControlCenter/ControlCenterHeader'
import {useApplicationSendGoStore} from 'src/stores/Applications/ControlCenter/ChangeStatus/useApplicationSendGoStore'

const props = defineProps<{ headerInfo: ControlCenterHeader }>()
const headerInfo = computed(() => props.headerInfo)

const {
  sendGo,
  loading,
} = useApplicationSendGoStore()

const getFormId = () => {
  return headerInfo.value.number.split('-')[0]
}
</script>
