import {usePutApi} from 'src/uses/Api/usePutApi'

export const useSavePortalEnabled = () => {
  const {
    put,
    response,
    status,
    loading,
    error,
    errors,
  } = usePutApi<unknown, {enabled: boolean, host: string}>()

  const save = async (id: string, enabled: boolean, host: string) => {
    await put(
      `v1/admin/sign-portals/${id}/enable`,
      {
        enabled,
        host,
      }
    )
  }

  return {
    loading,
    response,
    status,
    error,
    errors,
    save,
  }
}
