<template>
  <q-banner class="application-control-center-banner-info q-py-sm">
    <template #avatar>
      <div class="icon-banner-info">
        <q-icon
          name="svguse:icons.svg?2#app-exclamation-circle"
          size="sm"
          color="orange"
        />
      </div>
    </template>
    <div
      v-if="isBankEmployee()"
      class="row"
    >
      <change-kik-status-block :header-info="headerInfo" />
    </div>
    <new-status-block
      v-else-if="(isAgent() || isEmployee() || isHeadEmployee() || isOperator() || isAgentManager()) && props.headerInfo.status === ApplicationKikStatusEnum.need_sign"
      :header-info="props.headerInfo"
    />
    <div
      v-else-if="isAgent() || isEmployee() || isOperator() || isHeadEmployee() || isAgentManager()"
      class="row q-col-gutter-x-md"
    >
      <template v-if="props.headerInfo.status === ApplicationKikStatusEnum.in_bank && !props.headerInfo.new_request">
        <div class="col-sm col-xs-12 bannerMainContent">
          Заявка находится на рассмотрении в Банке. Вы получите уведомление о смене статуса
        </div>
      </template>
      <template v-else-if="props.headerInfo.status === ApplicationKikStatusEnum.in_bank && props.headerInfo.new_request">
        <div class="col-sm col-xs-12 bannerMainContent">
          Ознакомьтесь с данными и документами в заявке и при необходимости смените статус или напишите сообщение в чат
        </div>
        <div class="bannerInfoBtnWrap">
          <q-btn
            class="banner-btn"
            label="Запрос исполнен"
            icon-right="svguse:icons.svg?2#app-check"
            color="primary"
            :disable="loading"
            :loading="loading"
            unelevated
            no-caps
            @click="changeStatus(
              {
                id: props.headerInfo.id,
                applicationStatus: ApplicationKikSubStatusEnum.data_request_executed,
                initiator: ApplicationKikSubStatusEnum.data_request_executed
              },
            )"
          />
        </div>
      </template>
      <div
        v-else-if="props.headerInfo.status === ApplicationKikStatusEnum.approved && kikSumApprovedField && kikMonthsApprovedField"
        class="col-sm col-xs-12 bannerMainContent"
      >
        Клиенту одобрен кредит на сумму
        <money-component :model-value="kikSumApprovedField.value as FormElementPrimitiveValue || 0" />
        сроком в {{ kikMonthsApprovedField.value }} мес. Ознакомьтесь c подробной информацией в чате с Банком.
      </div>
      <div
        v-else-if="props.headerInfo.status === ApplicationKikStatusEnum.released && kikSumIssuedField && kikMonthsIssuedField"
        class="col-sm col-xs-12 bannerMainContent"
      >
        Кредит на сумму
        <money-component :model-value="kikSumIssuedField.value as FormElementPrimitiveValue || 0" />
        сроком в {{ kikMonthsIssuedField.value }} мес. выдан
      </div>
      <div
        v-else-if="props.headerInfo.status === ApplicationKikStatusEnum.bank_rejected"
        class="col-sm col-xs-12 bannerMainContent"
      >
        К сожалению по заявке получен отказ от банка. Причина отказа: "{{ props.headerInfo.status_comment }}"
      </div>
      <div
        v-else-if="props.headerInfo.status === ApplicationKikStatusEnum.client_rejected"
        class="col-sm col-xs-12 bannerMainContent"
      >
        Вы проставили отказ клиента по заявке. Если вы сделали это по ошибке - свяжитесь с нашей поддержкой
      </div>
    </div>
  </q-banner>
</template>

<script setup lang="ts">
import {ControlCenterHeader} from 'src/models/Applications/ControlCenter/ControlCenterHeader'
import {ApplicationFormDocuments} from 'src/models/Applications/Form/ApplicationFormResponseData'
import {useProfileStore} from 'src/stores/Login/useProfileStore'
import {ApplicationKikStatusEnum} from 'src/models/Applications/ApplicationKikStatusEnum'
import {storeToRefs} from 'pinia'
import {
  useApplicationKikControlCenterChangeStatusStore
} from 'stores/Applications/ControlCenter/ChangeStatus/useApplicationKikControlCenterChangeStatusStore'
import {ApplicationKikSubStatusEnum} from 'src/models/Applications/ApplicationKikSubStatusEnum'
import {useImportantFields} from 'src/uses/Applications/Form/useImportantFields'
import {
  useApplicationControlCenterItemStore
} from 'stores/Applications/ControlCenter/useApplicationControlCenterItemStore'
import MoneyComponent from 'components/MoneyComponent.vue'
import NewStatusBlock from 'components/Applications/ApplicationControlCenter/InfoAndActionBlocks/NewStatusBlock.vue'
import ChangeKikStatusBlock
  from 'components/Applications/ApplicationControlCenter/InfoAndActionBlocks/ChangeKikStatusBlock.vue'
import {FormElementPrimitiveValue} from 'src/models/Form'

const props = defineProps<{
  headerInfo: ControlCenterHeader,
  documents: ApplicationFormDocuments | null,
}>()

const {
  changeStatus,
} = useApplicationKikControlCenterChangeStatusStore(props.headerInfo.id)()
const {loading} = storeToRefs(useApplicationKikControlCenterChangeStatusStore(props.headerInfo.id)())

const {
  isAgent,
  isEmployee,
  isOperator,
  isAgentManager,
  isBankEmployee,
  isHeadEmployee,
} = useProfileStore()

const applicationControlCenterItemStore = useApplicationControlCenterItemStore(props.headerInfo.id)()
const {
  item
} = storeToRefs(applicationControlCenterItemStore)

const {
  kikSumIssuedField,
  kikMonthsIssuedField,
  kikMonthsApprovedField,
  kikSumApprovedField,
} = useImportantFields(item)
</script>

<style lang="scss">
.application-control-center-banner-info {
  background: #263238;
  color: #ffffff;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  padding: 20px 30px;

  .icon-banner-info {
    background: #37474F;
    border-radius: 5px;
    padding: 12px;
  }

  .banner-btn {
    padding: 10px 20px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
  }
}
</style>
