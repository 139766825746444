<template>
  <div class="row q-col-gutter-sm q-mt-md">
    <q-input
      ref="inputRef"
      v-model="formattedValue"
      :loading="calculateAmountLoading || calculatePercentLoading"
      class="field-with-top-label q-mb-sm col"
      label="Тариф ₽"
      debounce="700"
      outlined
      hide-bottom-space
      dense
      @blur="onBlur(); changeAmount()"
      @focus="onFocus"
    />
    <q-input
      :model-value="commissionPercent"
      :loading="calculateAmountLoading || calculatePercentLoading"
      class="field-with-top-label q-mb-sm col"
      label="Комиссия %"
      type="number"
      debounce="700"
      outlined
      hide-bottom-space
      dense
      @update:model-value="changePercent"
    />
  </div>
</template>

<script setup lang="ts">
import {useCalculateAmount} from 'src/uses/Applications/Tariff/useCalculateAmount'
import {useCalculatePercent} from 'src/uses/Applications/Tariff/useCalculatePercent'
import {ref, withDefaults, watchEffect, nextTick, computed} from 'vue'
import {useCurrencyInput} from 'src/uses/useCurrencyInput'

const props = withDefaults(
  defineProps<{
    id: string,
    commissionAmount: number | null,
    commissionPercent: number | null,
  }>(),
  {
    commissionAmount: 0,
    commissionPercent: 0,
  }
)

const emit = defineEmits<{
  (e: 'update:commission-amount', value: number): void,
  (e: 'update:commission-percent', value: number): void,
  (e: 'update:modelValue', value: number): void,
  (e: 'change', value: number): void,
}>()

const {
  calculate: calculateAmount,
  loading: calculateAmountLoading,
} = useCalculateAmount()

const {
  calculate: calculatePercent,
  loading: calculatePercentLoading,
} = useCalculatePercent()

const {
  numberValue,
  formattedValue,
  onFocus,
  onBlur,
} = useCurrencyInput()

const commissionPercent = ref<number>(0)

const commissionAmount = ref<number>(0)

const loading = computed(() => calculateAmountLoading.value || calculatePercentLoading.value)

const changeAmount = async () => {
  if (numberValue.value === undefined) {
    return
  }

  commissionAmount.value = numberValue.value || 0
  commissionPercent.value = !numberValue.value ? 0 : await calculatePercent(props.id, numberValue.value)

  emit('update:commission-amount', numberValue.value || 0)
  emit('update:commission-percent', commissionPercent.value)
}

const changePercent = async (input: string | number | null) => {
  const percent = input === null ? 0 : parseFloat(input.toString())
  if (props.commissionPercent === percent) {
    return
  }

  commissionPercent.value = percent
  commissionAmount.value = !percent ? 0 : await calculateAmount(props.id, percent)

  emit('update:commission-percent', percent)
  emit('update:commission-amount', commissionAmount.value)
}

defineExpose({
  loading
})

watchEffect(() => {
  commissionPercent.value = props.commissionPercent ? props.commissionPercent : 0
  commissionAmount.value = props.commissionAmount ? props.commissionAmount : 0
  nextTick(() => {
    if (commissionAmount.value !== numberValue.value) {
      numberValue.value = commissionAmount.value
      changeAmount()
    }
  })
})
</script>
