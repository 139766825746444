<template>
  <template v-if="props.list">
    <q-item
      v-if="props.item.can_send_to_release"
      v-ripple
      clickable
      @click="changeStatus(
        {
          id: props.item.id,
          applicationStatus: ApplicationSubStatusEnum.bg_project_bank_agreement,
          initiator: 'send_to_production'
        },
        props.item.sub_status
      )"
    >
      <q-item-section>
        Отправить на выпуск
      </q-item-section>
    </q-item>

    <q-item
      v-if="showToDraft(props.item)"
      v-ripple
      clickable
      @click="changeStatus(
        {
          id: props.item.id,
          applicationStatus: ApplicationSubStatusEnum.draft,
          initiator: 'align_risks'
        },
        props.item.sub_status
      )"
    >
      <q-item-section>
        Вернуть на статус Черновик
      </q-item-section>
    </q-item>

    <q-item
      v-if="showSendToCheck(props.item)"
      v-ripple
      clickable
      @click="send([props.item.id], props.item.multi_order_id)"
    >
      <q-item-section>
        Отправить на проверку
      </q-item-section>
    </q-item>

    <q-item
      v-if="showClientReject(props.item)"
      v-ripple
      clickable
      @click="changeStatus(
        {
          id: props.item.id,
          applicationStatus: ApplicationSubStatusEnum.client_rejected,
        },
        props.item.sub_status
      )"
    >
      <q-item-section>
        Отказ клиента
      </q-item-section>
    </q-item>
  </template>
</template>

<script setup lang="ts">
import {withDefaults} from 'vue'
import {ApplicationsGridItem} from 'src/models/Applications/ApplicationsGridItem'
import {useGridChangeStatusAction} from 'src/uses/Grid/useGridChangeStatusAction'
import {ApplicationSubStatusEnum} from 'src/models/Applications/ApplicationSubStatusEnum'
import {useSendToCheck} from 'src/stores/Applications/useSendToCheck'

const props = withDefaults(
  defineProps<{
    item: ApplicationsGridItem
    list?: boolean,
  }>(),
  {
    list: false,
  }
)

const {
  send,
} = useSendToCheck()

const {
  showToDraft,
  showClientReject,
  showSendToCheck,
  changeStatus,
} = useGridChangeStatusAction()
</script>
