<template>
  <div class="row q-mb-md q-col-gutter-md">
    <div class="col">
      <contractors-grid-filters
        :loading="loading"
        @update:filters="updateFilters"
      />
    </div>
    <div>
      <contractors-create-update-modal
        @success="updateFilters"
      />
    </div>
  </div>
  <div class="ClientsGrid--table">
    <q-table
      v-model:pagination="gridPagination"
      class="sticky-header-table"
      :rows="items"
      :columns="columns"
      :loading="loading"
      :rows-per-page-options="perPageOptions"
      separator="none"
      row-key="inn"
      hide-bottom
      wrap-cells
      @request="onRequest"
    >
      <template #body-cell-title="props">
        <q-td :props="props">
          {{ props.row.title }}
        </q-td>
      </template>
      <template #body-cell-inn="props">
        <q-td :props="props">
          {{ props.row.inn }}
        </q-td>
      </template>
      <template #body-cell-address="props">
        <q-td :props="props">
          {{ props.row.address }}
        </q-td>
      </template>
      <template #body-cell-manager_fio="props">
        <q-td :props="props">
          {{ props.row.manager_fio }}
        </q-td>
      </template>
      <template #body-cell-actions="props">
        <q-td
          :props="props"
          style="min-width: 100px"
        >
          <contractors-create-update-modal
            :item-id="props.row.id"
            @success="updateFilters"
          />
          <contractors-delete-modal
            :item-id="props.row.id"
            :name="props.row.title"
            @change="updateFilters"
          />
        </q-td>
      </template>
    </q-table>
    <pagination-component
      class="q-mb-md"
      :pagination="gridPagination"
      :per-page-options="perPageOptions"
      @request="onRequest"
      @refresh="load"
    />
  </div>
</template>

<script setup lang="ts">
import {storeToRefs} from 'pinia'
import {onBeforeMount, onBeforeUnmount} from 'vue'
import {useGridSettings} from 'src/uses/Grid/useGridSettings'
import PaginationComponent from 'src/components/PaginationComponent.vue'
import {RequestProp} from 'src/models/Grid/Pagination'
import {useContractorsGridStore} from 'stores/Contractors/useContractorsGridStore'
import {useContractorsGridFiltersStore} from 'stores/Contractors/useContractorsGridFiltersStore'
import {ContractorsGridColumns} from 'components/Contractors/ContractorsGridColumns'
import ContractorsCreateUpdateModal from 'components/Contractors/ContractorsCreateUpdateModal.vue'
import ContractorsGridFilters from 'components/Contractors/ContractorsGridFilters.vue'
import ContractorsDeleteModal from 'components/Contractors/ContractorsDeleteModal.vue'

const filtersStore = useContractorsGridFiltersStore()
const contractorsListStore = useContractorsGridStore()
const {
  items,
  gridPagination,
  perPageOptions,
  loading,
} = storeToRefs(contractorsListStore)

const {
  load,
  onRequestPagination,
  setPage,
} = contractorsListStore

const {columns} = useGridSettings('contractors-grid', ContractorsGridColumns)

onBeforeMount(async () => {
  await load()
})

onBeforeUnmount(() => {
  contractorsListStore.$reset()
  contractorsListStore.$dispose()
  filtersStore.$reset()
  filtersStore.$dispose()
})

const onRequest = ({pagination}: RequestProp) => {
  onRequestPagination({
    page: pagination.page,
    rowsPerPage: pagination.rowsPerPage,
    rowsNumber: 0,
    sortBy: pagination.sortBy,
    descending: pagination.descending,
  })
  load()
}

const updateFilters = () => {
  setPage(1)
  load()
}

</script>

<style lang="scss">
.ClientsGrid--table {
  margin: 0 -24px -24px;
  background: $blue-1;
  padding: 0 24px 24px;

  table {
    position: relative;

    tr {
      td {
        width: 150px;

        &:first-child {
          width: auto;
          white-space: normal;
        }
      }
    }
  }
}
</style>
