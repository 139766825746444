import {useFilterWithErrors} from 'src/uses/Applications/Form/fill/useFilterWithErrors'
import {computed} from 'vue'
import {
  ApplicationFormBank,
  ApplicationFormClient,
  ApplicationFormFounder
} from 'src/models/Applications/Form/ApplicationFormResponseData'
import {Ref} from 'vue-demi'

export const useApplicationFormFillClient = (client: Ref<ApplicationFormClient | null>, banks: Ref<ApplicationFormBank[]>) => {
  const {
    filterWithErrors,
    filterOnlyCommonOrDraftBanks,
  } = useFilterWithErrors(banks)

  const clientCommon = computed(() => {
    if (!client.value || !client.value.common) {
      return []
    }

    return client.value.common.fields
      .filter(f => f.invisible === false)
      .filter(filterOnlyCommonOrDraftBanks)
  })

  const clientHead = computed(() => {
    if (!client.value || !client.value.head) {
      return []
    }

    return client.value.head.fields
      .filter(f => f.invisible === false)
      .filter(filterOnlyCommonOrDraftBanks)
  })

  const clientFounders = computed(() => {
    if (!client.value || !client.value.founders) {
      return []
    }

    return client.value.founders
      .map(founder => {
        return {
          ...founder,
          fields: founder.fields
            .filter(f => f.invisible === false)
            .filter(filterOnlyCommonOrDraftBanks)
        }
      })
  })

  const clientCommonFieldsWithErrors = computed(() => {
    return clientCommon.value.filter(filterWithErrors)
  })

  const clientHeadFieldsWithErrors = computed(() => {
    return clientHead.value.filter(filterWithErrors)
  })

  const clientFoundersFieldsWithErrors = computed<ApplicationFormFounder[]>(() => {
    if (!client.value || !client.value.founders) {
      return []
    }

    return clientFounders.value
      .map(founder => {
        return {
          ...founder,
          fields: founder.fields
            .filter(filterWithErrors)
        }
      })
      .filter(founder => founder.fields.length)
  })

  const fillClientByBanks = computed(() => {
    return banks.value
      .map(bank => {
        const total = clientCommon.value
          .filter(f => !f.location.bank_id || f.location.bank_id === bank.bank_id)
          .length
          + clientHead.value
            .filter(f => !f.location.bank_id || f.location.bank_id === bank.bank_id)
            .length
          + clientFounders.value
            .map(f => {
              return {
                ...f,
                fields: f.fields.filter(f => !f.location.bank_id || f.location.bank_id === bank.bank_id),
              }
            })
            .map(f => f.fields.length)
            .reduce((sum, length) => sum + length, 0)

        const error = clientCommonFieldsWithErrors.value
          .filter(f => (!f.location.bank_id || f.location.bank_id === bank.bank_id)
            && f.valid
              .find(valid => valid.errors.length && valid.id === bank.bank_id)
          )
          .length
          + clientHeadFieldsWithErrors.value
            .filter(f => (!f.location.bank_id || f.location.bank_id === bank.bank_id)
              && f.valid
                .find(valid => valid.errors.length && valid.id === bank.bank_id)
            )
            .length
          + clientFoundersFieldsWithErrors.value
            .map(f => {
              return {
                ...f,
                fields: f.fields.filter(f => (!f.location.bank_id || f.location.bank_id === bank.bank_id)
                  && f.valid
                    .find(valid => valid.errors.length && valid.id === bank.bank_id)
                ),
              }
            })
            .map(f => f.fields.length)
            .reduce((sum, length) => sum + length, 0)

        return {
          ...bank,
          total,
          error,
        }
      })
  })

  const clientHasErrors = computed(() => {
    return clientCommonFieldsWithErrors.value.length
      || clientHeadFieldsWithErrors.value.length
      || clientFoundersFieldsWithErrors.value.length
  })

  const founderHasError = (id: string) => {
    return !!clientFoundersFieldsWithErrors.value
      .find(f => f.id === id)
  }

  const countAllClientFields = computed(() => {
    return clientCommon.value.length
      + clientHead.value.length
      + clientFounders.value.map(f => f.fields.length).reduce((sum, length) => sum + length, 0)
  })

  const countErrorsClientFields = computed(() => {
    return clientCommonFieldsWithErrors.value.length
      + clientHeadFieldsWithErrors.value.length
      + clientFoundersFieldsWithErrors.value.map(f => f.fields.length).reduce((sum, length) => sum + length, 0)
  })

  return {
    clientHasErrors,
    clientHeadFieldsWithErrors,
    clientCommonFieldsWithErrors,
    clientFoundersFieldsWithErrors,
    countAllClientFields,
    countErrorsClientFields,
    fillClientByBanks,
    founderHasError,
  }
}
