<template>
  <div
    v-if="isMainAdmin() && applicableStatus"
    class="q-py-sm q-px-md bg-white row"
  >
    <form-date
      v-model="releaseDate"
      class="field-with-top-label col"
      label="Дата выдачи БГ"
      :loading="loading"
      with-top-label
      @update:model-value="changeHandler"
    />
    <div
      v-if="modified"
      style="padding-top: 40px"
    >
      <q-btn
        class="q-ml-xs"
        icon="svguse:icons.svg?2#app-check"
        size="10px"
        color="green-14"
        :disable="loading"
        round
        unelevated
        flat
        @click="confirm"
      />
      <q-btn
        class="q-ml-xs"
        icon="svguse:icons.svg?2#app-x"
        size="10px"
        color="red-12"
        :disable="loading"
        round
        unelevated
        flat
        @click="cancel"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import {ApplicationsGridItem} from 'src/models/Applications/ApplicationsGridItem'
import {computed, ref, watch} from 'vue'
import {useProfileStore} from 'src/stores/Login/useProfileStore'
import {useReleaseDateUpdate} from 'src/uses/Applications/useReleaseDateUpdate'
import FormDate from 'components/FormElements/FormDate.vue'
import {ApplicationSubStatusEnum} from 'src/models/Applications/ApplicationSubStatusEnum'
import moment from 'moment/moment'

const props = defineProps<{
  item: ApplicationsGridItem,
}>()

const emit = defineEmits<{
  (e: 'update:modelValue', value: number): void,
  (e: 'changed'): void,
}>()

const {
  isMainAdmin,
} = useProfileStore()

const {
  status,
  loading,
  update,
} = useReleaseDateUpdate()

const releaseDate = ref<string>('')
const modified = ref(false)

const releaseDateFromProp = computed(() => props.item.release_date)

const applicableStatus = computed(() => {
  return [
    ApplicationSubStatusEnum.guarantee_released,
    ApplicationSubStatusEnum.guarantee_released_request,
    ApplicationSubStatusEnum.guarantee_released_request_sign,
  ].includes(props.item.sub_status)
})

const changeHandler = () => {
  modified.value = true
}

const confirm = async () => {
  await update(props.item.id, releaseDate.value)
  modified.value = false

  if (status.value === 200) {
    emit('changed')
  }
}

const cancel = () => {
  modified.value = false
  releaseDate.value = ''
}

watch(
  releaseDateFromProp,
  () => {
    if (!releaseDateFromProp.value) {
      return
    }

    releaseDate.value = moment(releaseDateFromProp.value, 'YYYY-MM-DD')
      .format('DD.MM.YYYY')
  },
  {
    immediate: true,
  }
)
</script>

<style scoped lang="scss">

</style>
