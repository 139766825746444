import {useGetApi} from 'src/uses/Api/useGetApi'
import {ref} from 'vue'
import {
  ApplicationBankEmployeesResponse
} from 'src/models/Applications/ApplicationBankEmployeesResponse'
import {ApplicationsGridItemBankEmployee} from 'src/models/Applications/ApplicationsGridItem'

export const useBankEmployeesLoad = () => {
  const {
    error,
    errors,
    get,
    status,
    response,
    loading,
  } = useGetApi<ApplicationBankEmployeesResponse>()

  const items = ref<ApplicationsGridItemBankEmployee[]>()
  const selected = ref<ApplicationsGridItemBankEmployee>()

  const getBankEmployeeByName = async (appId: string, query: string) => {
    await get(`v1/admin/order/${appId}/bank-employee/search?filter[name]=${query}`)

    if (status.value === 200 && response.value) {
      console.log(response.value.items)
      items.value = response.value.items
    }
  }

  return {
    error,
    errors,
    get,
    status,
    response,
    loading,
    items,
    selected,
    getBankEmployeeByName,
  }
}
