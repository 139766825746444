import {useGetApi} from 'src/uses/Api/useGetApi'
import {ApplicationsTariffDetailsResponseData} from 'src/models/Applications/Tariff/ApplicationsTariffDetailsResponseData'
import {ref} from 'vue'

export const useGetTariffDetails = () => {
  const {
    loading,
    get,
    status,
    response,
  } = useGetApi<ApplicationsTariffDetailsResponseData>()

  const tariffDetails = ref<ApplicationsTariffDetailsResponseData>()

  const load = async (id: string) => {
    await get(
      `v1/control-center/${id}/tariff`,
    )

    if (status.value === 200 && response && response.value) {
      tariffDetails.value = response.value
    }
  }

  return {
    loading,
    tariffDetails,
    load,
  }
}
