import {usePostApi} from 'src/uses/Api/usePostApi'
import {ListingsItemRecord} from 'src/models/Listings/ListingRecords/ListingsItemRecord'
import {usePutApi} from 'src/uses/Api/usePutApi'
import {useValidationRules} from 'src/uses/useValidationRules'
import {computed, Ref, ref} from 'vue'
import {required} from 'src/utils/i18n-validators'
import useVuelidate from '@vuelidate/core'

export const useListingsItemRecordCreate = () => {
  const {
    post,
    loading: postLoading,
    status: postStatus,
    errors: postErrors,
  } = usePostApi<ListingsItemRecord, ListingsItemRecord>()

  const {
    put,
    loading: putLoading,
    status: putStatus,
    errors: putErrors,
  } = usePutApi<ListingsItemRecord, ListingsItemRecord>()

  const {
    getRulesFromState,
  } = useValidationRules()

  const listingsItemRecordCreateUpdateData = ref<ListingsItemRecord>({
    id: '',
    key: '',
    value: '',
  })

  const rules = computed(() => {
    return {
      ...getRulesFromState(listingsItemRecordCreateUpdateData.value),
      key: { required },
      value: {},
    }
  })

  const validation = useVuelidate<Ref<ListingsItemRecord>>(rules, listingsItemRecordCreateUpdateData)

  const create = async (listingsId: string) => {
    if (!await validation.value.$validate()) {
      return
    }

    await post(`v2/listings/${listingsId}/records`, listingsItemRecordCreateUpdateData.value)
  }

  const update = async (listingsId: string) => {
    if (!listingsItemRecordCreateUpdateData.value.id || !await validation.value.$validate()) {
      return
    }

    await put(
      `v2/listings/${listingsId}/records/${listingsItemRecordCreateUpdateData.value.id}`,
      listingsItemRecordCreateUpdateData.value
    )
  }

  const errors = computed({
    get: () => {
      return listingsItemRecordCreateUpdateData.value.id
        ? putErrors.value
        : postErrors.value
    },
    set: (errors: Record<string, string> | undefined) => {
      if (listingsItemRecordCreateUpdateData.value.id) {
        putErrors.value = errors
        return
      }
      postErrors.value = errors
    }
  })

  const status = computed(() => {
    return listingsItemRecordCreateUpdateData.value.id
      ? putStatus.value
      : postStatus.value
  })

  const loading = computed(() => {
    return listingsItemRecordCreateUpdateData.value.id
      ? putLoading.value
      : postLoading.value
  })

  return {
    listingsItemRecordCreateUpdateData,
    validation,
    status,
    errors,
    loading,
    rules,
    create,
    update,
  }
}
