<template>
  <application-kik-grid-filters-dialog
    v-model="filerDialog"
    :loading="loading"
    :ignore-pins="ignorePins"
    @update:filters="updateFilters"
  />
  <div
    class="q-mb-sm"
    style="margin-left: -8px; margin-right: -8px;"
  >
    <application-grid-product-toggle :loading="loading" />
  </div>
  <div class="q-px-none">
    <div class="ApplicationKikGrid--mobile">
      <div class="row q-pb-lg q-pt-sm">
        <q-btn
          label="Все фильтры"
          color="blue-grey-0"
          class="text-blue-7 col"
          icon-right="svguse:icons.svg?2#app-adjustments"
          padding="10px"
          unelevated
          no-caps
          @click="filterDialogShow"
        />
        <q-btn
          v-if="clearable"
          color="blue-grey-0"
          class="text-blue-7 q-ml-xs"
          icon-right="svguse:icons.svg?2#app-trash"
          size="14px"
          padding="10px"
          unelevated
          no-caps
          @click="onClear"
        />
      </div>
      <application-kik-grid-statuses
        class="q-mb-sm"
        @update:filters="updateFilters"
      />
      <q-infinite-scroll
        ref="scroll"
        style="margin-bottom: 12px;"
        :offset="50"
        @load="onLoad"
      >
        <div
          v-for="item in items as ApplicationsGridKikItem[]"
          :key="item.id"
        >
          <div>
            <div class="ApplicationGrid--mobile--item q-pa-md rounded-borders bg-white q-mt-sm">
              <div class="row">
                <div>
                  <div class="text-grey-6">Дата, №</div>
                  <application-kik-grid-number-mobile
                    :item="item"
                    :onlyText="ApplicationKikSubStatusEnum.archive === item.sub_status"
                  />
                </div>
                <div class="col"></div>
                <div class="text-grey-6 q-mt-sm">
                  <div class="row no-wrap items-center">
                    <div class="text-right col">
                      <application-kik-grid-actions
                        :item="item"
                        @change="refreshById(item.id)"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="row no-wrap q-mt-sm">
                <div>
                  <div class="text-grey-6">Банк</div>
                  <div>
                    <img
                      :src="item.bank.logo"
                      :alt="item.bank.title_short"
                      style="max-width: 60px; max-height: 50px;"
                    >
                  </div>
                </div>
                <div class="col q-pl-sm">
                  <div class="text-grey-6">Статус</div>
                  <div class="row no-wrap">
                    <div class="q-pr-sm col">
                      <application-kik-grid-status :item="item" />
                    </div>
                    <application-grid-messages-action
                      :item="item as never as ApplicationsGridItem"
                      mobile
                      @model-closed="refreshById(item.id)"
                    />
                  </div>
                </div>
              </div>

              <div class="text-grey-6 q-mt-sm">Название/ИНН</div>
              <application-grid-inn
                :item="item"
                mobile
              />

              <div class="text-grey-6 q-mt-sm">
                Продукт
              </div>
              <div>
                {{ getCreditTypeLabel(item.credit_type) }}
              </div>

              <div class="text-grey-6 q-mt-sm">
                Сумма
                <kik-amount-help-tooltip />
              </div>
              <div class="row">
                <div class="row no-wrap">
                  <div v-if="item.credit_amount_requested">
                    <money-component :model-value="item.credit_amount_requested" />
                  </div>
                  <div
                    v-if="item.credit_amount_approved"
                    class="text-primary"
                  >
                    <money-component :model-value="item.credit_amount_approved" />
                  </div>
                  <div
                    v-if="item.credit_amount_issued"
                    class="text-green-14"
                  >
                    <money-component :model-value="item.credit_amount_issued" />
                  </div>
                </div>
              </div>

              <div class="text-grey-6 q-mt-sm">
                Срок действия
                <kik-months-help-tooltip />
              </div>
              <div class="row no-wrap">
                <div v-if="item.credit_months_requested">
                  {{ item.credit_months_requested }} мес.
                </div>
                <div
                  v-if="item.credit_months_approved"
                  class="text-primary"
                >
                  {{ item.credit_months_approved }} мес.
                </div>
                <div
                  v-if="item.credit_months_issued"
                  class="text-green-14"
                >
                  {{ item.credit_months_issued }} мес.
                </div>
              </div>

              <div class="text-grey-6 q-mt-sm">
                Ставка
              </div>
              <div>
                {{ item.tariff_percent }}%
              </div>
            </div>
          </div>
        </div>
        <template #loading>
          <div class="text-center">
            <q-circular-progress
              class="q-mt-sm"
              color="primary"
              size="md"
              indeterminate
            />
          </div>
        </template>
      </q-infinite-scroll>
    </div>
  </div>
</template>

<script setup lang="ts">
import ApplicationGridMessagesAction
  from 'src/components/Applications/ApplicationsGrid/Actions/ApplicationGridMessagesAction.vue'
import ApplicationGridInn from 'src/components/Applications/ApplicationsGrid/Columns/ApplicationGridInn.vue'
//import {useGridSettings} from 'src/uses/Grid/useGridSettings'
//import {ApplicationsGridKikColumns} from 'components/Applications/ApplicationsGrid/ApplicationsGridKikColumns'
import {ref, watch} from 'vue'
import {QInfiniteScroll} from 'quasar'
import {useApplicationsKikGrid} from 'components/Applications/ApplicationsGrid/Grid/useApplicationsKikGrid'
import ApplicationKikGridStatuses from 'components/Applications/ApplicationsGrid/ApplicationKikGridStatuses.vue'
import ApplicationKikGridFiltersDialog
  from 'components/Applications/ApplicationsGrid/ApplicationKikGridFiltersDialog.vue'
import ApplicationGridProductToggle
  from 'components/Applications/ApplicationsGrid/Grid/ApplicationGridProductToggle.vue'
import {ApplicationKikSubStatusEnum} from 'src/models/Applications/ApplicationKikSubStatusEnum'
import ApplicationKikGridNumberMobile
  from 'components/Applications/ApplicationsGrid/Columns/ApplicationKikGridNumberMobile.vue'
import ApplicationKikGridActions from 'components/Applications/ApplicationsGrid/Actions/ApplicationKikGridActions.vue'
import ApplicationKikGridStatus from 'components/Applications/ApplicationsGrid/Columns/ApplicationKikGridStatus.vue'
import KikAmountHelpTooltip from 'components/Applications/ApplicationsGrid/Grid/KikAmountHelpTooltip.vue'
import MoneyComponent from 'components/MoneyComponent.vue'
import {ApplicationsGridKikItem} from 'src/models/Applications/ApplicationsGridKikItem'
import {useCreditType} from 'src/uses/Applications/useCreditType'
import KikMonthsHelpTooltip from 'components/Applications/ApplicationsGrid/Grid/KikMonthsHelpTooltip.vue'
import {ApplicationsGridItem} from 'src/models/Applications/ApplicationsGridItem'

const {
  items,

  clearable,

  loading,
  ignorePins,

  noMorePages,

  filerDialog,
  filterDialogShow,

  updateFilters,
  onClear,

  refreshById,

  load,
  setNextPage
} = useApplicationsKikGrid()

const {
  getCreditTypeLabel,
} = useCreditType()

//const columns = ApplicationsGridKikColumns()
//const {isVisibleByRole} = useGridSettings('application-kik-grid', columns)

//const getColumnByName = (name: string) => columns.find(column => column.name === name)

const scroll = ref<QInfiniteScroll>()

const onLoad = async (index: number, done: (stop?: boolean) => void) => {
  if (loading.value) {
    return
  }
  setNextPage()
  await load()
  done()
}

watch(
  noMorePages,
  () => {
    if (!scroll.value) {
      return
    }

    if (noMorePages.value) {
      scroll.value.stop()

      return
    }

    scroll.value.resume()
  }
)
</script>

<style lang="scss" src="./ApplicationsKikGridMobile.scss"/>
