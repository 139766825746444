<template>
  <div
    v-if="financeVisible"
    class="ApplicationItemFinance"
  >
    <application-form-load-bo-modal
      :periods="financeVisible.periods"
      :multi-order-id="multiOrderId"
      @bo:loaded="startUpdateChangesProcess"
    />
    <q-tabs
      v-if="periods"
      ref="tabsRef"
      v-model="financePeriodTab"
      class="ApplicationItemFinanceTabs q-px-lg"
      style="margin: 0 -24px;"
      align="left"
      indicator-color="blue-7"
      inline-label
      no-caps
    >
      <application-form-finance-tab
        v-for="(period, key) of periods"
        :key="key"
        :period="period"
        :periods-with-errors="periodsWithErrors"
      />
    </q-tabs>

    <q-tab-panels
      v-model="financePeriodTab"
      class="bg-transparent"
      style="margin-left: -16px; margin-right: -16px;"
      animated
    >
      <template
        v-for="period in periods"
        :key="getId(period)"
      >
        <q-tab-panel
          v-if="getPeriodFields(period, financeVisible)"
          :name="getId(period)"
        >
          <finance-period
            :period="period"
            :fields="getPeriodFields(period, financeVisible)"
            @update:field="updateFinanceField($event, period)"
          />
        </q-tab-panel>
      </template>
    </q-tab-panels>
    <div
      class="text-right"
      style="margin-top: -16px;"
    >
      <q-btn
        class="q-mr-md"
        label="Наверх"
        icon-right="svguse:icons.svg?2#app-arrow-up"
        color="blue-7"
        padding="13px 25px"
        unelevated
        no-wrap
        no-caps
        @click="scrollTop"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import {useApplicationItemStore} from 'src/stores/Applications/Item/useApplicationItemStore'
import {storeToRefs} from 'pinia'
import {ref, watchEffect} from 'vue'
import {useScrollTo} from 'src/uses/useScrollTo'
import {QTabs} from 'quasar'
import {usePeriod} from 'src/uses/Applications/Form/Finance/usePeriod'
import FinancePeriod from 'src/components/Applications/Form/Finance/FinancePeriod.vue'
import ApplicationFormLoadBoModal from 'src/components/Applications/Form/Finance/ApplicationFormLoadBoModal.vue'
import ApplicationFormFinanceTab from 'src/components/Applications/Form/Finance/Tabs/ApplicationFormFinanceTab.vue'
import {ProductEnum} from 'src/models/Applications/ProductEnum'
import {useApplicationItemKikStore} from 'stores/Applications/Item/useApplicationItemKikStore'
import {useApplicationItemEbgStore} from 'stores/Applications/Item/useApplicationItemEbgStore'
import {useApplicationItemKikFillStore} from 'stores/Applications/Item/useApplicationItemKikFillStore'
import {useApplicationItemEbgFillStore} from 'stores/Applications/Item/useApplicationItemEbgFillStore'

const {
  scrollToInvisibleElement,
} = useScrollTo()

const inputProps = defineProps<{
  multiOrderId: number,
}>()

const applicationItemStore = useApplicationItemStore(inputProps.multiOrderId)()
const {
  productTab,
} = storeToRefs(applicationItemStore)

const applicationItemProductStore = productTab.value === ProductEnum.EBG
  ? useApplicationItemEbgStore(inputProps.multiOrderId)()
  : useApplicationItemKikStore(inputProps.multiOrderId)()

const {
  updateFinanceField,
} = applicationItemProductStore

const {
  startUpdateChangesProcess,
} = applicationItemStore

const {
  financeVisible,
  periods,
  financePeriodTab,
} = storeToRefs(applicationItemProductStore)

const fillStore = productTab.value === ProductEnum.EBG
  ? useApplicationItemEbgFillStore(inputProps.multiOrderId)()
  : useApplicationItemKikFillStore(inputProps.multiOrderId)()
const {
  periodsWithErrors,
} = storeToRefs(fillStore)

const {
  getId,
  getPeriodFields,
} = usePeriod()

const tabsRef = ref<QTabs>()

const scrollTop = () => {
  if (!tabsRef.value?.$el) {
    return
  }

  scrollToInvisibleElement(tabsRef.value?.$el)
}

watchEffect(() => {
  if (!financeVisible.value
    || !financeVisible.value.periods
    || financePeriodTab.value
    || !periods.value.length
  ) {
    return
  }

  financePeriodTab.value = getId(periods.value[0]) || null
})
</script>

<style lang="scss">
.ApplicationItemFinance {
  .ApplicationItemFinanceTabs {
    &:after {
      content: '';
      display: block;
      height: 1px;
      background: $blue-grey-2;
      position: absolute;
      width: 100%;
      bottom: 0;
      left: 0;
    }

    .q-tab__indicator {
      z-index: 2;
    }
  }
}
</style>
