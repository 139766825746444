import {defineStore} from 'pinia'
import {ref, watchEffect, watch, computed} from 'vue'
import {usePutApi} from 'src/uses/Api/usePutApi'
import {ProductTariffTypeEnum} from 'src/models/Enums/ProductTariffTypeEnum'
import {ProductTariffInterval, ProductTariffUpdateData} from 'src/models/Products/Tariff/ProductTariffUpdateData'
import {useProductsTariffParamsStore} from 'stores/Products/Tariff/useProductsTariffParamsStore'
import {ProductRateEnum} from 'src/models/Enums/ProductRateEnum'
import {ProductTariffParamsItemOperatorEnum, ProductTariffParamsItemTypeEnum} from 'src/models/Products/Tariff/ProductTariffParamsItem'
import {ProductOperationEnum} from 'src/models/Enums/ProductOperationEnum'
import {ProductCondition, ProductConditionPart} from 'src/models/Products/ProductCondition'

const productRateType: Record<ProductRateEnum, string> = {
  [ProductRateEnum.Amount]: '₽',
  [ProductRateEnum.Percent]: '%',
}

const productConditionOperation: Record<ProductOperationEnum, string> = {
  [ProductOperationEnum.Append]: 'Наценка',
  [ProductOperationEnum.Subtract]: 'Тариф',
  [ProductOperationEnum.SetMinimal]: 'Минимальный тариф',
}
export const useProductsTariffControlStore = (productId: string) => defineStore(`product-tariff-control-${productId}`, () => {
  const {
    loading,
    put,
    status: putStatus,
  } = usePutApi<ProductTariffUpdateData, ProductTariffUpdateData>(true)

  const {
    productTariffParams,
    paramComparisonOptions,
    paramValueTypesAndOptions,
    operatorOptions,
    conditionsParamsOptions,
    loadProductTariffParams,
  } = useProductsTariffParamsStore()

  const productTariffUpdateData = ref<ProductTariffUpdateData>({
    value: undefined,
    is_leap_year: false,
    intervals: [],
    min_sum: undefined,
    conditions: []
  })
  const type = ref<ProductTariffTypeEnum>(ProductTariffTypeEnum.PERCENT)
  const isFreeTariff = ref<boolean>(false)
  const intervalOptions = ref(
    Object.values(ProductRateEnum).map(key => {
      return {
        label: productRateType[key],
        value: key
      }
    })
  )

  const addInterval = () => {
    if (!productTariffUpdateData.value || !productTariffUpdateData.value.intervals) {
      return
    }

    productTariffUpdateData.value.intervals.push({
      sum_from: null,
      sum_to: null,
      days_from: null,
      days_to: null,
      type: ProductRateEnum.Amount,
      rate: null,
    })
  }

  const removeInterval = (key: number) => {
    if (!productTariffUpdateData.value || !productTariffUpdateData.value.intervals) {
      return
    }

    productTariffUpdateData.value.intervals.splice(key, 1)
  }

  const conditionOperationOptions = ref(
    Object.values(ProductOperationEnum).map(key => {
      return {
        label: productConditionOperation[key],
        value: key
      }
    })
  )

  const getInitParam = () => {
    return {
      operator: ProductTariffParamsItemOperatorEnum.EQ,
      param: 'advanceAmount',
      value: 0,
    }
  }

  const onChangeParam = (
    conditionKey: number,
    paramKey: number,
    value: string | string[]| number | null,
    paramType = ProductTariffParamsItemTypeEnum.STRING
  ) => {
    if (!productTariffUpdateData.value || !productTariffUpdateData.value?.conditions) {
      return
    }

    if (!productTariffUpdateData.value?.conditions[conditionKey]) {
      return
    }

    productTariffUpdateData.value?.conditions[conditionKey].comparison.parts.map((param, searchParamKey) => {
      if (searchParamKey === paramKey) {
        param.value = (paramType === ProductTariffParamsItemTypeEnum.NUMBER) ? Number(value) : value
      }
      return param
    })
  }

  const addCondition = () => {
    console.log(productTariffUpdateData.value.conditions)
    if (!productTariffUpdateData.value || !productTariffUpdateData.value.conditions) {
      return
    }

    productTariffUpdateData.value.conditions.push({
      comparison: {
        operator: 'and',
        parts: [getInitParam()],
      },
      operation: null,
      rateType: ProductRateEnum.Amount,
      rate: null
    })
  }

  const removeCondition = (key: number) => {
    if (!productTariffUpdateData.value || !productTariffUpdateData.value?.conditions) {
      return
    }

    productTariffUpdateData.value.conditions.splice(key, 1)
  }

  const addParam = (key: number) => {
    if (!productTariffUpdateData.value || !productTariffUpdateData.value?.conditions) {
      return
    }

    if (!productTariffUpdateData.value.conditions[key]) {
      return
    }

    productTariffUpdateData.value.conditions[key].comparison.parts.push(getInitParam())
  }

  const removeParam = (conditionKey: number, paramKey: number) => {
    if (!productTariffUpdateData.value || !productTariffUpdateData.value?.conditions) {
      return
    }

    productTariffUpdateData.value?.conditions[conditionKey].comparison.parts.splice(paramKey, 1)
  }

  const isValid = computed(() => {
    if (!productTariffUpdateData.value) {
      return false
    }

    if (!productTariffUpdateData.value.conditions) {
      return true
    }

    let isValidParams = true

    productTariffUpdateData.value.conditions.forEach((condition: ProductCondition) => {
      condition.comparison.parts.forEach((param: ProductConditionPart) => {
        if (param.value === 0 && param.operator) {
          return
        }
        if (!param.value || !param.operator) {
          isValidParams = false
        }
      })
    })

    return isValidParams
  })

  const save = async (id: string) => {
    if (!productTariffUpdateData.value) {
      return
    }
    let intervals: ProductTariffInterval[] = []
    if (productTariffUpdateData.value.intervals) {
      intervals = productTariffUpdateData.value.intervals.map(interval => {
        return {
          sum_from: Number(interval.sum_from),
          sum_to: Number(interval.sum_to),
          days_from: Number(interval.days_from),
          days_to: Number(interval.days_to),
          type: interval.type,
          rate: Number(interval.rate),
        }
      })
    }
    const data: ProductTariffUpdateData = {
      ...productTariffUpdateData.value,
      value: Number(productTariffUpdateData.value.value) || 0,
      min_sum: Number(productTariffUpdateData.value.min_sum) || undefined,
      intervals
    }
    await put(`v1/admin/product/${id}/tariff/${type.value.toLowerCase()}`, data)
  }

  watch(
    isFreeTariff,
    () => {
      if (isFreeTariff.value) {
        productTariffUpdateData.value.value = 0
      }
    },
    {deep: true}
  )

  watchEffect(() => {
    productTariffUpdateData.value.value = isFreeTariff.value ? undefined : productTariffUpdateData.value.value
  })

  return {
    type,
    loading,
    putStatus,
    productTariffUpdateData,
    isFreeTariff,
    save,

    addCondition,
    addParam,
    removeCondition,
    removeParam,
    onChangeParam,
    conditionOperationOptions,
    //isValid,

    intervalOptions,
    addInterval,
    removeInterval,

    productTariffParams,
    paramComparisonOptions,
    paramValueTypesAndOptions,
    operatorOptions,
    conditionsParamsOptions,
    isValid,
    loadProductTariffParams,
  }
})
