<template>
  <div class="row q-col-gutter-md q-mb-lg q-pa-none">
    <div class="col-sm-8 col-xs-12">
      <q-input
        v-model="filters.title"
        :loading="props.loading"
        class="NewsListFilters__title"
        style="min-width: 300px"
        label="Поиск по новостям"
        clearable
        outlined
        hide-hint
        hide-bottom-space
        dense
        @change="onSubmit"
        @clear="onSubmit"
      >
        <template #prepend>
          <q-icon name="svguse:icons.svg?2#app-search" />
        </template>
      </q-input>
    </div>
    <div class="col-sm-4 col-xs-12">
      <q-select
        v-model="filters.topic"
        :options="topics"
        :loading="loading"
        style="min-width: 200px"
        map-options
        outlined
        hide-hint
        hide-bottom-space
        dense
        emit-value
        @update:model-value="onSubmit"
      >
        <template #prepend>
          <q-icon name="svguse:icons.svg?2#app-filter" />
        </template>
      </q-select>
    </div>
  </div>
</template>

<script setup lang="ts">
import {storeToRefs} from 'pinia'
import {useNewsListFiltersStore} from 'src/stores/News/useNewsListFiltersStore'

const props = defineProps<{
  loading: boolean,
}>()

const emit = defineEmits<{
  (e: 'update:filters'): void,
}>()

const filtersStore = useNewsListFiltersStore()
const {
  filters,
  topics
} = storeToRefs(filtersStore)

const onSubmit = () => {
  emit('update:filters')
}
</script>

<style lang="scss">
.NewsListFilters__title {
  &.q-field {
    .q-field__label {
      top: 15px !important;
    }
  }
}
</style>
