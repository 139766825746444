import {NewsTopicEnum} from 'src/models/Enums/NewsTopicEnum'
import {NewsPublicationTypeEnum} from 'src/models/Enums/NewsPublicationTypeEnum'

export const topics: Record<NewsTopicEnum | '', string> = {
  [NewsTopicEnum.change_of_conditions]: 'Изменение условий',
  [NewsTopicEnum.products_and_banks]: 'Продукты и банки',
  [NewsTopicEnum.gosoblako_updates]: 'Обновление Gosoblako',
  [NewsTopicEnum.opinion_gosoblako]: 'Мнение Gosoblako',
  [NewsTopicEnum.promotions]: 'Промо акции',
  '': 'Все новости'
}

const topicsClasses: Record<NewsTopicEnum, string> = {
  [NewsTopicEnum.change_of_conditions]: 'blue-4',
  [NewsTopicEnum.products_and_banks]: 'green-4',
  [NewsTopicEnum.gosoblako_updates]: 'orange-5',
  [NewsTopicEnum.opinion_gosoblako]: 'primary',
  [NewsTopicEnum.promotions]: 'primary',
}

const publicationTypes: Record<NewsPublicationTypeEnum, string> = {
  [NewsPublicationTypeEnum.published]: 'Опубликовано',
  [NewsPublicationTypeEnum.draft]: 'Черновик',
}

export class NewsDescriptions {
  public static getNewsTopicDescription (topic: NewsTopicEnum | null): string {
    if (!topic) {
      return ''
    }
    return topics[topic] || topic
  }

  public static getNewsTopicColor (topic: NewsTopicEnum): string {
    return topicsClasses[topic] || topic
  }

  public static getNewsPublicationTypeDescription (publicationType: NewsPublicationTypeEnum): string {
    return publicationTypes[publicationType] || publicationType
  }
}
