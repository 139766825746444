<template>
  <div class="StopTooltip--head q-mb-xs">Стоп-факторы:</div>
  <template
    v-for="(stop, index) in props.modelValue"
    :key="index"
  >
    <div v-if="showStopFactor(props.modelValue, stop)">
      <q-icon
        name="svguse:icons.svg?2#app-x-circle-bold"
        size="24px"
        style="margin-top: -2px;"
        :color="getColor(stop.result)"
      />
      {{ stop.comment }}
    </div>
  </template>
</template>

<script setup lang="ts">
import {SelectBankFactor} from 'src/models/Applications/New/SelectBank'
import {useStopFactorResult} from 'src/uses/Applications/useStopFactorResult'

const props = defineProps<{
  modelValue: SelectBankFactor[],
}>()

const {
  showStopFactor,
  getColor,
} = useStopFactorResult()
</script>
