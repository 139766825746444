<template>
  <div class="q-mb-lg">
    <q-banner class="product-tariff-banner">
      <template #avatar>
        <div class="icon-product-tariff-banner">
          <q-icon
            name="svguse:icons.svg?2#app-exclamation-circle"
            size="sm"
            color="blue-grey-5"
          />
        </div>
      </template>
      <div>Тариф будет известен после одобрения заявки банком</div>
    </q-banner>
  </div>
</template>
<style lang="scss">
.product-tariff-banner {
  background: #D5EAFA !important;
  color: $blue-grey-5;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  padding: 10px 20px;
  border-radius: 6px;

  .icon-product-tariff-banner {
    background: #D5EAFA;
    border-radius: 5px;
    padding: 12px;
  }
}
</style>
