<template>
  <div class="row q-mb-md q-col-gutter-md">
    <div class="col">
      <banks-grid-filters
        :loading="loading"
        @update:filters="updateFilters"
      />
    </div>
    <div>
      <banks-range-modal
        :banks="items"
        @success="successSave"
      />
      <bank-create-modal @success="successSave"/>
    </div>
  </div>
  <div class="ClientsGrid--table">
    <q-table
      v-model:pagination="gridPagination"
      class="sticky-header-table"
      :rows="items"
      :columns="columns"
      :loading="loading"
      :rows-per-page-options="perPageOptions"
      separator="none"
      row-key="inn"
      hide-bottom
      wrap-cells
    >
      <template #body-cell-number="props">
        <q-td
          style="width: 70px"
          :props="props"
        >
          {{ props.pageIndex + 1 }}
        </q-td>
      </template>
      <template #body-cell-title="props">
        <q-td :props="props">
          <bank-create-modal
            :item-id="props.row.id"
            @success="successSave"
          />
          {{ props.row.title_short }}
        </q-td>
      </template>
      <template #body-cell-logo="props">
        <q-td :props="props">
          <img
            :src="props.row.logo"
            :alt="props.row.title_short"
            style="max-width: 100px; max-height: 50px;"
          >
        </q-td>
      </template>
      <template #body-cell-products="props">
        <q-td
          style="min-width: 280px"
          :props="props"
        >
          <bank-grid-products
            :bank="props.row"
            @change:product="successSave"
          />
        </q-td>
      </template>
      <template #body-cell-actions="props">
        <q-td :props="props">
          <q-toggle
            v-model="props.row.hidden"
            :false-value="true"
            :true-value="false"
            @update:model-value="changeHidden($event, props.row.id)"
          />
        </q-td>
      </template>
    </q-table>
  </div>
</template>

<script setup lang="ts">
import {storeToRefs} from 'pinia'
import {onBeforeMount, onBeforeUnmount} from 'vue'
import {useGridSettings} from 'src/uses/Grid/useGridSettings'
import BanksGridFilters from 'components/Banks/BanksGridFilters.vue'
import {useBanksGridStore} from 'stores/Banks/useBanksGridStore'
import {BanksGridColumns} from 'components/Banks/BanksGridColumns'
import BankCreateModal from 'components/Banks/BankCreateModal.vue'
import {useBankHidden} from 'src/uses/Bank/useBankHidden'
import {useBanksGridFiltersStore} from 'stores/Banks/useBanksGridFiltersStore'
import BankGridProducts from 'components/Banks/BankGridProducts.vue'
import BanksRangeModal from 'components/Banks/BanksRangeModal.vue'

const banksGridFiltersStore = useBanksGridFiltersStore()
const banksGridStore = useBanksGridStore()
const {
  items,
  gridPagination,
  perPageOptions,
  loading,
} = storeToRefs(banksGridStore)

const {
  load,
  setPage,
} = banksGridStore

const {
  changeHidden,
} = useBankHidden()

const {columns} = useGridSettings('banks-grid', BanksGridColumns)

onBeforeMount(async () => {
  await load()
})

onBeforeUnmount(() => {
  banksGridStore.$reset()
  banksGridStore.$dispose()
  banksGridFiltersStore.$reset()
  banksGridFiltersStore.$dispose()
})

const updateFilters = () => {
  setPage(1)
  load()
}

const successSave = () => {
  setPage(1)
  load()
}

</script>

<style lang="scss">
.ClientsGrid--table {
  margin: 0 -24px -24px;
  background: $blue-1;
  padding: 0 24px 24px;

  table {
    position: relative;

    tr {
      td {
        width: 150px;

        &:first-child {
          width: auto;
          white-space: normal;
        }
      }
    }
  }

  .createButton {
    .icon {
      background: white;
      border-radius: 6px;
      position: absolute;
      right: 10px;
      line-height: 0;
    }

    .q-btn {
      border-radius: 6px;
    }
  }
}
</style>
