<template>
  <q-input
    :key="String(props.field.value)"
    v-model="model"
    class="field-with-top-label"
    :hint="props.ignoreHint ? '' : (props.field.help || '')"
    :disable="props.field.readonly && !props.ignoreReadonly"
    :error="!!props.field.error || hasUnparsedParts"
    :error-message="props.field.error || 'Необходимо скорректировать разбивку адреса с помощью ручного ввода, нажав на карандаш.'"
    :loading="props.field.loading"
    :mask="props.field.mask ? props.field.mask.replaceAll('0', '#') : undefined"
    label-slot
    outlined
    hide-bottom-space
    dense
    @change="change"
  >
    <template #label>
      <slot name="labelPrepend" />
      {{ props.label || props.field.title }}
      <slot name="labelAppend" />
    </template>
    <template
      v-if="details"
    >
      <q-btn
        class="text-blue-7"
        style="height: 32px; margin: 10px 0 0 0;"
        icon="svguse:icons.svg?2#app-pencil-alt"
        padding="xs"
        :disable="props.field.loading || props.field.readonly"
        flat
        unelevated
        square
        @click="show"
      />
      <field-addres-details-modal
        v-model="dialog"
        :title="props.field.title"
        :details="details"
        :loading="!!props.field.loading"
        @update:address-details="emit('update:address-details', $event)"
      />
    </template>
  </q-input>
</template>

<script setup lang="ts">
import {computed, ref, watch, watchEffect} from 'vue'
import {
  AddressDetails,
  ApplicationFormField,
} from 'src/models/Applications/Form/ApplicationFormResponseData'
import {FormElementPrimitiveValue, FormElementValue} from 'src/models/Form'
import FieldAddresDetailsModal from 'src/components/Fields/FieldAddresDetailsModal.vue'
import {useDialog} from 'src/uses/Dialogs/useDialog'

const props = defineProps<{
  field: ApplicationFormField,
  label: string | boolean,
  ignoreReadonly: boolean,
  ignoreHint: boolean,
}>()

const emit = defineEmits<{
  (e: 'update:value', value: FormElementValue): void,
  (e: 'update:address-details', details: AddressDetails): void,
}>()

const {
  dialog,
  show,
  hide,
} = useDialog()

const model = ref<FormElementPrimitiveValue>(null)

const field = computed(() => props.field)

const details = computed(() => props.field.details)
const hasUnparsedParts = computed(() => details.value && !!details.value.unparsed_parts)

watchEffect(() => {
  model.value = props.field.value as FormElementPrimitiveValue
})

const change = () => {
  emit('update:value', model.value)
}

watch(
  field,
  () => {
    if (!field.value.loading && dialog.value) {
      hide()
    }
  },
  {
    deep: true,
  }
)
</script>
