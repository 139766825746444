<template>
  <q-expansion-item
    class="HelpBlock q-px-md q-mb-md"
    :label="helpBlock.title"
    default-opened
    expand-separator
  >
    <template v-if="helpBlock.pages && helpBlock.pages.length > 0">
      <div class="q-mb-md">
        <q-table
          :rows="helpBlock.pages"
          :columns="columns"
          :pagination="{rowsPerPage: 0}"
          separator="none"
          row-key="id"
          wrap-cells
          hide-bottom
          hide-header
        >
          <template #body-cell-title="props">
            <q-td style="width: 100%" :props="props">
              {{ props.row.title }}
            </q-td>
          </template>
          <template #body-cell-update="props">
            <q-td :props="props">
              Обновлено {{props.row.updated}}
            </q-td>
          </template>
          <template #body-cell-actions="props">
            <q-td style="max-width: 100px" :props="props">
              <settings-help-create-update-page-modal
                :block-id="helpBlock.id"
                :help-page="props.row"
                @success="change"
              />
              <settings-help-page-delete-modal
                :title="props.row.title"
                :item-id="props.row.id"
                @change="change"
              />
            </q-td>
          </template>
        </q-table>
      </div>
    </template>
    <div
      v-else
      class="bg-white text-center q-pa-md q-mb-md"
      style="border-radius: 6px"
    >
      В данном разделе пока нет ни одной страницы
    </div>
    <div class="row items-center justify-between HelpBlock--actions">
      <settings-help-create-update-page-modal
        :block-id="helpBlock.id"
        @success="change"
      />
      <div class="col text-right">
        <settings-help-create-update-block-modal
          :help-block="helpBlock"
          @success="change"
        />
        <settings-help-delete-block-modal
          :title="helpBlock.title"
          :item-id="helpBlock.id"
          @change="change"
        />
      </div>
    </div>
  </q-expansion-item>
</template>

<script setup lang="ts">
import {computed, ref} from 'vue'
import {HelpBlock} from 'src/models/Help/HelpBlock'
import SettingsHelpCreateUpdatePageModal from 'components/Settings/Help/SettingsHelpCreateUpdatePageModal.vue'
import SettingsHelpPageDeleteModal from 'components/Settings/Help/SettingsHelpPageDeleteModal.vue'
import SettingsHelpDeleteBlockModal from 'components/Settings/Help/SettingsHelpDeleteBlockModal.vue'
import SettingsHelpCreateUpdateBlockModal from 'components/Settings/Help/SettingsHelpCreateUpdateBlockModal.vue'
import {QTableColumn} from 'src/models/Grid/GridColumnSettings'

const props = defineProps<{
  helpBlock: HelpBlock,
}>()

const emit = defineEmits<{
  (e: 'change'): void,
}>()

const helpBlock = computed(() => props.helpBlock)
const change = () => {
  emit('change')
}

const columns = ref<QTableColumn[]>([
  {
    name: 'title',
    field: 'title',
    required: true,
    label: 'Наименование',
    align: 'left',
    classes: 'text-no-wrap',
    sortable: false,
  },
  {
    name: 'update',
    field: 'update',
    required: true,
    label: 'Дата обновления',
    align: 'left',
    classes: 'text-no-wrap',
    sortable: false,
  },
  {
    name: 'actions',
    field: 'actions',
    required: true,
    label: '',
    align: 'left',
    classes: 'text-no-wrap',
    sortable: false,
  },
])

</script>

<style lang="scss">
.HelpBlock {
  border-radius: 6px;
  border: 1px #C7DFF1 solid;
  .HelpBlock--actions {
    margin: 0 -16px;
    background-color: #D5EAFA;
    padding: 16px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    .BgBlueBtn {
      background-color: #C7DFF1;
      color: #1E88E5;
      padding: 10px 13px;
    }
    .BgGreyBtn {
      background-color: #C7DFF1;
      color: #78909C;
      padding: 10px 13px;
    }
  }
  &:hover {
    background: transparent !important;
  }

  .q-focus-helper {
    background: transparent !important;
  }

  .q-item {
    padding-left: 0 !important;
    padding-right: 0 !important;

    font-weight: 700;
    font-size: 16px;
  }
}
</style>
