import {defineStore} from 'pinia'
import {useGetApi} from 'src/uses/Api/useGetApi'
import {ref} from 'vue'
import {useGridPagination} from 'src/uses/Grid/useGridPagination'
import {ProductsListResponseData} from 'src/models/Products/ProductsListResponseData'
import {ProductsListItem} from 'src/models/Products/ProductsListItem'
import {useProductsGridFiltersStore} from 'stores/Products/useProductsGridFiltersStore'

export const useProductsListStore = defineStore('products-list', () => {
  const ADMIN_PRODUCTS_LIST_URL = 'admin/product/list'

  const {
    loading,
    get,
    errors,
    error,
    status,
    response,
  } = useGetApi<ProductsListResponseData>()

  const {
    pagination,
    gridPagination,
    perPageOptions,
    getFromStorage,
    setPagination,
    onRequestPagination,
    setPage,
    setPerPage,
  } = useGridPagination('products-list')

  const filtersStore = useProductsGridFiltersStore()
  const {getFiltersQuery} = filtersStore

  const items = ref<ProductsListItem[]>([])

  const getTariffValue = (product: ProductsListItem) => {
    if (product.tariff && product.tariff.value) {
      return product.tariff?.value
    } else if (product.tariff && product.tariff.min_sum) {
      return product.tariff.min_sum
    } else if (product.tariff) {
      return product.tariff?.value
    }
    return ''
  }

  const load = async () => {
    await getFromStorage()

    await get(
      ADMIN_PRODUCTS_LIST_URL,
      {
        page: pagination.value?.page,
        perpage: pagination.value?.per_page,
        ...getFiltersQuery()
      }
    )

    if (status.value === 200 && response && response.value?.items) {
      items.value = response.value?.items
      setPagination(response.value?.pagination)
    }
  }

  return {
    loading,
    get,
    errors,
    error,
    items,
    pagination,
    gridPagination,
    perPageOptions,
    onRequestPagination,
    load,
    setPage,
    setPerPage,

    getTariffValue,
  }
})
