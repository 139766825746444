import {defineStore} from 'pinia'
import {computed, Ref, ref} from 'vue'
import {usePostApi} from 'src/uses/Api/usePostApi'
import {RegistrationRequestData} from 'src/models/User/Registration/RegistrationRequestData'
import {
  email,
  integer,
  maxLength,
  minLength,
  passwordValidator,
  phoneValidator,
  required
} from 'src/utils/i18n-validators'
import useVuelidate from '@vuelidate/core'
import {sameAs} from '@vuelidate/validators'
import {useValidationRules} from 'src/uses/useValidationRules'

export const useRegistrationStore = defineStore('registration', () => {
  const {
    post,
    response,
    status,
    loading,
    error,
    errors,
  } = usePostApi<never, RegistrationRequestData>()

  const {
    getRulesFromState,
  } = useValidationRules()

  const registrationRequestData = ref<RegistrationRequestData>({
    email: '',
    password: '',
    phone: '',
    inn: '',
    privacy_policy: false,
    sublicense_agreement: false,
  })

  const success = ref(false)

  const rules = computed(() => {
    return {
      ...getRulesFromState(registrationRequestData.value),
      inn: {
        required,
        integer,
        minLength: minLength(10),
        maxLength: maxLength(12),
      },
      email: {
        required,
        email,
      },
      phone: {
        required,
        phoneValidator,
      },
      password: {
        required,
        passwordValidator,
        minLength: minLength(8),
        maxLength: maxLength(14),
      },
      privacy_policy: {
        sameAs: sameAs(true),
      },
      sublicense_agreement: {
        sameAs: sameAs(true),
      },
      first_name: {},
      last_name: {},
      middle_name: {},
    }
  })

  const validation = useVuelidate<Ref<RegistrationRequestData>>(rules, registrationRequestData)

  const doRegistration = async () => {
    if (!await validation.value.$validate()) {
      return
    }

    await post('v1/signup', registrationRequestData.value)
    if (status.value === 201 && response.value) {
      success.value = true
    }
  }

  return {
    registrationRequestData,
    loading,
    response,
    status,
    error,
    errors,
    validation,
    success,
    doRegistration,
  }
})
