import {useGetApi} from 'src/uses/Api/useGetApi'
import {ApplicationNewAgent, ApplicationNewAgentsResponse} from 'src/models/Applications/ApplicationNewAgentsResponse'
import {ref} from 'vue'

export const useAgentLoad = () => {
  const {
    error,
    errors,
    get,
    status,
    response,
    loading,
  } = useGetApi<ApplicationNewAgentsResponse>()

  const items = ref<ApplicationNewAgent[]>()
  const selected = ref<ApplicationNewAgent>()

  const getAgentListByInnOrName = async (appId: string, query: string) => {
    await get(`v1/admin/order/${appId}/agent/search?filter[name]=${query}`)

    if (status.value === 200 && response.value) {
      items.value = response.value.items
    }
  }

  return {
    error,
    errors,
    get,
    status,
    response,
    loading,
    items,
    selected,
    getAgentListByInnOrName,
  }
}
