import {computed, ref, watch} from 'vue'
import {
  ApplicationFormDocumentGroupEnum,
  ApplicationFormDocument,
  ApplicationFormDocuments, ApplicationFormDocumentChatSourceEnum, ApplicationFormDocumentStatusEnum,
} from 'src/models/Applications/Form/ApplicationFormResponseData'
import {useApplicationFormUploadDocument} from 'src/uses/Applications/Form/Documents/useApplicationFormUploadDocument'
import {
  ApplicationFormUpdateResponse
} from 'src/models/Applications/Form/ApplicationFormUpdateResponse'
import {useApplicationFormDeleteDocument} from 'src/uses/Applications/Form/Documents/useApplicationFormDeleteDocument'
import {useApplicationFormDownloadDocument} from 'src/uses/Applications/Form/Documents/useApplicationFormDownloadDocument'
import {DocumentsCodesEnum} from 'src/models/DocumentsCodesEnum'
import {ChatTypeEnum} from 'src/models/ChatTypeEnum'
import {
  useCreateEmptyApplicationFormDocument
} from 'src/uses/Applications/Form/Documents/useCreateEmptyApplicationFormDocument'

export const useDocuments = (multiOrderId?: number, appId?: string) => {
  const {create: createEmptyDocument} = useCreateEmptyApplicationFormDocument()

  const documents = ref<ApplicationFormDocuments>({})
  const newDocuments = ref<ApplicationFormDocuments>({})
  const response = ref<ApplicationFormUpdateResponse>()
  const uploadsStack: Record<string, ReturnType<typeof useApplicationFormUploadDocument>> = {}

  const visibleDocuments = computed(() => {
    return {
      [ApplicationFormDocumentGroupEnum.passports]: (documents.value[ApplicationFormDocumentGroupEnum.passports] || []).filter(filterVisible),
      [ApplicationFormDocumentGroupEnum.financial]: (documents.value[ApplicationFormDocumentGroupEnum.financial] || []).filter(filterVisible),
      [ApplicationFormDocumentGroupEnum.additional]: (documents.value[ApplicationFormDocumentGroupEnum.additional] || []).filter(filterVisible),
      [ApplicationFormDocumentGroupEnum.deal]: (documents.value[ApplicationFormDocumentGroupEnum.deal] || []).filter(filterVisible),
      [ApplicationFormDocumentGroupEnum.offer]: (documents.value[ApplicationFormDocumentGroupEnum.offer] || []).filter(filterVisible),
      [ApplicationFormDocumentGroupEnum.client]: (documents.value[ApplicationFormDocumentGroupEnum.client] || []).filter(filterVisible),
    }
  })

  const canUploadNewDocumentsBySections = ref<Record<ApplicationFormDocumentGroupEnum, boolean>>({
    [ApplicationFormDocumentGroupEnum.financial]: false,
    [ApplicationFormDocumentGroupEnum.passports]: false,
    [ApplicationFormDocumentGroupEnum.client]: false,
    [ApplicationFormDocumentGroupEnum.additional]: false,
    [ApplicationFormDocumentGroupEnum.deal]: false,
    [ApplicationFormDocumentGroupEnum.offer]: false,
  })

  const dropZoneAvailability = ref<Record<ApplicationFormDocumentGroupEnum, boolean>>({
    [ApplicationFormDocumentGroupEnum.financial]: false,
    [ApplicationFormDocumentGroupEnum.passports]: false,
    [ApplicationFormDocumentGroupEnum.client]: false,
    [ApplicationFormDocumentGroupEnum.additional]: false,
    [ApplicationFormDocumentGroupEnum.deal]: false,
    [ApplicationFormDocumentGroupEnum.offer]: false,
  })

  const canUploadAllNewDocuments = computed(() => {
    return canUploadNewDocumentsBySections.value.financial
      && canUploadNewDocumentsBySections.value.client
      && canUploadNewDocumentsBySections.value.additional
      && canUploadNewDocumentsBySections.value.deal
      && canUploadNewDocumentsBySections.value.offer
      && canUploadNewDocumentsBySections.value.passports
  })

  const canAddNewAddedDocument = (type: ApplicationFormDocumentGroupEnum) => {
    return !!(documents.value[type] || [])
      .find(doc => doc.code === DocumentsCodesEnum.ADDED_DOCUMENT
        && doc.invisible
        && doc.index === null
        && !doc.readonly
      )
  }

  const calculateDropZoneAvailability = (type: ApplicationFormDocumentGroupEnum) => {
    const visibleEditableDocuments = (visibleDocuments.value[type] || [])
      .filter(doc => !doc.readonly)

    return canAddNewAddedDocument(type)
      || visibleEditableDocuments.length > (newDocuments.value[type] || []).length
  }

  const recalculateCanUploadDocumentsBySections = () => {
    canUploadNewDocumentsBySections.value = {
      [ApplicationFormDocumentGroupEnum.financial]: !newDocuments.value.financial || !newDocuments.value.financial.find(d => !canUploadNewDocument(d)),
      [ApplicationFormDocumentGroupEnum.passports]: !newDocuments.value.passports || !newDocuments.value.passports.find(d => !canUploadNewDocument(d)),
      [ApplicationFormDocumentGroupEnum.client]: !newDocuments.value.client || !newDocuments.value.client.find(d => !canUploadNewDocument(d)),
      [ApplicationFormDocumentGroupEnum.additional]: !newDocuments.value.additional || !newDocuments.value.additional.find(d => !canUploadNewDocument(d)),
      [ApplicationFormDocumentGroupEnum.deal]: !newDocuments.value.deal || !newDocuments.value.deal.find(d => !canUploadNewDocument(d)),
      [ApplicationFormDocumentGroupEnum.offer]: !newDocuments.value.offer || !newDocuments.value.offer.find(d => !canUploadNewDocument(d)),
    }
  }

  const recalculateDropZoneAvailability = () => {
    dropZoneAvailability.value = {
      [ApplicationFormDocumentGroupEnum.financial]: calculateDropZoneAvailability(ApplicationFormDocumentGroupEnum.financial),
      [ApplicationFormDocumentGroupEnum.passports]: calculateDropZoneAvailability(ApplicationFormDocumentGroupEnum.passports),
      [ApplicationFormDocumentGroupEnum.client]: calculateDropZoneAvailability(ApplicationFormDocumentGroupEnum.client),
      [ApplicationFormDocumentGroupEnum.additional]: calculateDropZoneAvailability(ApplicationFormDocumentGroupEnum.additional),
      [ApplicationFormDocumentGroupEnum.deal]: calculateDropZoneAvailability(ApplicationFormDocumentGroupEnum.deal),
      [ApplicationFormDocumentGroupEnum.offer]: calculateDropZoneAvailability(ApplicationFormDocumentGroupEnum.offer),
    }
  }

  const filterVisible = (document: ApplicationFormDocument) => {
    return !document.invisible
  }

  const cancelUpload = (document: ApplicationFormDocument) => {
    if (uploadsStack[getInnerDocumentId(document)]) {
      uploadsStack[getInnerDocumentId(document)].abort()
      delete uploadsStack[getInnerDocumentId(document)]
    }
  }

  const downloadDocument = async (
    document?: ApplicationFormDocument,
    type?: ApplicationFormDocumentGroupEnum,
  ) => {
    if (!type || !document) {
      return
    }
    const {
      download,
    } = useApplicationFormDownloadDocument(multiOrderId, appId)

    await download(document, type)
  }

  const downloadDocumentSign = async (
    document: ApplicationFormDocument,
    type: ApplicationFormDocumentGroupEnum,
  ) => {
    const {
      download,
    } = useApplicationFormDownloadDocument(multiOrderId, appId)

    await download(document, type, true)
  }

  const getInnerDocumentId = (document: ApplicationFormDocument): string => {
    const parts = [
      document.code,
      document.index || '_',
      document.period_quarter || ' ',
      document.period_year || ' ',
      document.founder_id || ' ',
      document.location.section_type,
    ]

    return parts.join('.')
  }

  const deleteDocument = async (document: ApplicationFormDocument) => {
    const {
      status,
      errors,
      deleteDocument,
      response: deleteResponse,
    } = useApplicationFormDeleteDocument(multiOrderId, appId)

    document.loading = true
    await deleteDocument(document)

    if (status.value !== 200 && errors.value) {
      response.value = undefined
      document.loading = false
      document.progress = undefined

      return
    }

    document.loading = false
    document.progress = undefined
    response.value = deleteResponse.value
  }

  const onNewDocumentUpload = async (
    document: ApplicationFormDocument,
    documentType: ApplicationFormDocumentGroupEnum,
    newIndex: number,
    statusChangeDisabled = false
  ) => {
    const newBlock = newDocuments.value[documentType]
    if (!newBlock || !newBlock[newIndex]) {
      return
    }

    if (!documents.value[documentType]) {
      documents.value[documentType] = []
    }
    const block = documents.value[documentType] as ApplicationFormDocument[]

    const Index = block
      .findIndex(doc => {
        if (doc.code === DocumentsCodesEnum.ADDED_DOCUMENT
          && doc.invisible
          && doc.index === null
        ) {
          return false
        }

        return getInnerDocumentId(doc) === getInnerDocumentId(document)
      })

    if (Index === -1) {
      //block.push(document)
    } else {
      document.file_status = block[Index].file_status
      document.file_date = block[Index].file_date
    }

    block[Index] = document
    newBlock[newIndex] = document

    await uploadDocument(document, statusChangeDisabled)
    removeNewDocument(document, documentType)
  }

  const removeNewDocument = (
    document: ApplicationFormDocument,
    documentType: ApplicationFormDocumentGroupEnum,
  ) => {
    const block = newDocuments.value[documentType]
    if (!block) {
      return
    }

    const index = block.findIndex(d => getInnerDocumentId(d) === getInnerDocumentId(document))
    if (index === -1) {
      return
    }

    removeNewDocumentByIndex(index, documentType)
  }

  const clearNewDocuments = () => {
    newDocuments.value = {}
  }

  const removeNewDocumentByIndex = (
    index: number,
    documentType: ApplicationFormDocumentGroupEnum,
  ) => {
    const block = newDocuments.value[documentType]
    if (!block) {
      return
    }

    const document = block
      .find((doc, i) => i === index)

    if (document) {
      cancelUpload(document)
    }

    newDocuments.value[documentType] = block
      .filter((doc, i) => i !== index)
  }

  const updateNewDocument = (
    document: ApplicationFormDocument,
    documentType: ApplicationFormDocumentGroupEnum,
    index: number,
    source?: ChatTypeEnum,
  ) => {
    const block = newDocuments.value[documentType]
    if (!block || !block[index]) {
      return
    }

    const documentSource = source === ChatTypeEnum.bank
      ? ApplicationFormDocumentChatSourceEnum.bank_chat
      : source === ChatTypeEnum.platform
        ? ApplicationFormDocumentChatSourceEnum.platform_chat
        : undefined

    newDocuments.value[documentType] = (newDocuments.value[documentType] || [])
      .map((doc, docIndex) => {
        if (docIndex === index) {
          return {
            ...doc,
            ...document,
            source: documentSource,
          }
        }

        return {
          ...doc,
        }
      })
  }

  const addNewDocument = (
    document: ApplicationFormDocument,
    documentType: ApplicationFormDocumentGroupEnum,
  ) => {
    const block = newDocuments.value[documentType]
    if (!block) {
      newDocuments.value[documentType] = [
        document,
      ]

      return
    }

    block.push(document)
  }

  const canUploadNewDocument = (documentData: ApplicationFormDocument) => {
    if (!documentData.code
      || !documentData.file
      || !documentData.title
    ) {
      return false
    }

    if (documentData.sign_file_required && !documentData.file_sign) {
      return false
    }

    if (documentData.code === DocumentsCodesEnum.PASSPORT_FOUNDER) {
      return !!documentData.founder_id
    }

    if (documentData.code === DocumentsCodesEnum.ACCOUNTING_REPORTS) {
      return !!documentData.period_quarter && !!documentData.period_year
    }

    return true
  }

  const uploadAllNewDocuments = async (statusChangeDisabled = false, queue = false) => {
    const docsData: {
      document: ApplicationFormDocument
      group: ApplicationFormDocumentGroupEnum
      index: number
    }[] = []
    Object.entries(newDocuments.value)
      .forEach(([group, documents]) => {
        documents
          .forEach((document, index) => {
            document.loading = true
            docsData.push({
              document,
              group: group as ApplicationFormDocumentGroupEnum,
              index
            })
          })
      })

    if (queue) {
      for (const data of docsData) {
        await onNewDocumentUpload(
          data.document,
          data.group,
          0,
          statusChangeDisabled
        )
      }

      return
    }

    const awaits: Promise<void>[] = []
    for (const data of docsData) {
      awaits.push(
        onNewDocumentUpload(
          data.document,
          data.group as ApplicationFormDocumentGroupEnum,
          data.index,
          statusChangeDisabled
        )
      )
    }
    for (const key in awaits) {
      await awaits[key]
    }
  }

  const afterUpload = (document: ApplicationFormDocument) => {
    document.loading = false
    document.progress = undefined
    document.highlighted = false
    if (document.file) {
      document.file = undefined
    } else {
      document.file_sign = undefined
    }
    delete uploadsStack[getInnerDocumentId(document)]
  }

  const uploadPassportByFounderId = async (file: File, founderId?: string) => {
    if (!documents.value.passports) {
      return
    }

    let document = !founderId
      ? documents.value.passports
        .find(document => document.code === DocumentsCodesEnum.PASSPORT_HEAD)
      : documents.value.passports
        .find(document => document.founder_id === founderId)

    if (!document && !founderId) {
      return
    }

    if (!document && founderId) {
      document = createEmptyDocument('passports')
      document.code = DocumentsCodesEnum.PASSPORT_FOUNDER
      document.founder_id = founderId
      document.file_status = ApplicationFormDocumentStatusEnum.new
    }

    if (!document) {
      return
    }

    document.file = file
    await uploadDocument(document)
  }

  const uploadDocument = async (document: ApplicationFormDocument, disableStatusChange = false) => {
    response.value = undefined
    const use = useApplicationFormUploadDocument(document, multiOrderId, appId)
    uploadsStack[getInnerDocumentId(document)] = use

    document.highlighted = false
    document.loading = true
    await use.upload(document, disableStatusChange)
    if (use.status.value !== 200) {
      response.value = undefined
      document.loading = false
      afterUpload(document)
      if (document.file_sign && document.file) {
        await deleteDocument(document)
        return
      }

      return
    }

    afterUpload(document)

    const fileSign = document.file_sign
    if (document.code === DocumentsCodesEnum.ADDED_DOCUMENT && document.index === null) {
      const responseDocument = use.response.value?.changes.documents
        .find(d => d.code === document.code)

      if (responseDocument) {
        document.index = responseDocument.index
      }
    }

    use.response.value?.changes.documents
      .find(d => d.code === document.code)
    response.value = use.response.value

    if (fileSign) {
      document.file_sign = fileSign
      await uploadDocument(document)
    }
  }

  watch(
    newDocuments,
    () => {
      recalculateDropZoneAvailability()
      recalculateCanUploadDocumentsBySections()
    },
    {
      deep: true,
      immediate: true,
    }
  )
  watch(
    documents,
    recalculateDropZoneAvailability,
    {
      deep: true,
      immediate: true,
    }
  )

  return {
    documents,
    visibleDocuments,
    response,
    newDocuments,
    dropZoneAvailability,
    canUploadNewDocumentsBySections,
    canUploadAllNewDocuments,
    uploadDocument,
    cancelUpload,
    deleteDocument,
    downloadDocument,
    downloadDocumentSign,
    addNewDocument,
    removeNewDocumentByIndex,
    updateNewDocument,
    uploadAllNewDocuments,
    clearNewDocuments,
    onNewDocumentUpload,
    uploadPassportByFounderId,
    canUploadNewDocument,
    canAddNewAddedDocument,
  }
}
