import {usePutApi} from 'src/uses/Api/usePutApi'
import {ApplicationFormUpdateResponse} from 'src/models/Applications/Form/ApplicationFormUpdateResponse'
import {AddressDetails, ApplicationFormField} from 'src/models/Applications/Form/ApplicationFormResponseData'

export const useAddressDetailsSave = (multiOrderId?: number, appId?: string,) => {
  const {
    error,
    errors,
    put,
    status,
    response,
    loading,
  } = usePutApi<
    ApplicationFormUpdateResponse,
    {
      section_type: string
      section_id?: string
      code: string
      address_details: AddressDetails
    }
  >()

  const update = async (field: ApplicationFormField, details: AddressDetails) => {
    if (!multiOrderId && !appId) {
      return
    }

    const data = {
      section_type: field.location.section_type,
      section_id: field.location.section_id,
      code: field.code,
      address_details: details,
    }

    field.loading = true

    if (multiOrderId) {
      await put(
        `v2/orders/forms/${multiOrderId}/address-field-details`,
        data
      )
      field.loading = false

      return
    }

    await put(
      `v2/control-center/${appId}/address-field-details`,
      data
    )
    field.loading = false
  }

  return {
    error,
    errors,
    put,
    status,
    response,
    loading,
    update,
  }
}
