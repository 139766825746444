import {defineStore} from 'pinia'
import {useGetApi} from 'src/uses/Api/useGetApi'
import {computed, ref, watch} from 'vue'
import {
  ApplicationFormFinanceField,
  ApplicationFormBank,
  ApplicationFormClient,
  ApplicationFormDocument,
  ApplicationFormField,
  ApplicationFormResponseData,
  ApplicationFormDocumentGroupEnum,
} from 'src/models/Applications/Form/ApplicationFormResponseData'
import {useMainFields} from 'src/uses/Applications/Form/useMainFields'
import {useOptions} from 'src/uses/Applications/Form/useOptions'
import {useFinanceFields} from 'src/uses/Applications/Form/Finance/useFinanceFields'
import {useClientCommonFields} from 'src/uses/Applications/Form/Client/useClientCommonFields'
import {useClientHeadFields} from 'src/uses/Applications/Form/Client/useClientHeadFields'
import {useClientFoundersFields} from 'src/uses/Applications/Form/Client/useClientFoundersFields'
import {useUpdateFieldsSuccess} from 'src/uses/Applications/Form/useUpdateFieldsSuccess'
import {useDocuments} from 'src/uses/Applications/Form/Documents/useDocuments'
import {ApplicationFormUpdateResponse} from 'src/models/Applications/Form/ApplicationFormUpdateResponse'
import {ApplicationControlCenterSectionsEnum} from 'src/models/Enums/ApplicationControlCenterSectionsEnum'
import {useAddFounder} from 'src/uses/Applications/ControlCenter/Client/useAddFounder'
import {useDeleteFounder} from 'src/uses/Applications/ControlCenter/Client/useDeleteFounder'
import moment from 'moment/moment'
import {useAddressDetailsSave} from 'src/uses/Applications/Form/useAddressDetailsSave'
import {useApplicationControlCenterStore} from 'src/stores/Applications/ControlCenter/useApplicationControlCenterStore'
import {ProductEnum} from 'src/models/Applications/ProductEnum'

export const useApplicationControlCenterItemStore = (appId: string) => defineStore(
  `application-control-center-item-${appId}`,
  () => {
    const client = ref<ApplicationFormClient | null>(null)
    const banks = ref<ApplicationFormBank[]>([])
    const openedFounderId = ref<string>()

    const tab = ref<ApplicationControlCenterSectionsEnum>(ApplicationControlCenterSectionsEnum.MAIN)
    const documentTab = ref<ApplicationFormDocumentGroupEnum>(ApplicationFormDocumentGroupEnum.client)

    const highlightField = (field: ApplicationFormFinanceField | ApplicationFormDocument | ApplicationFormField, on = true) => {
      field.highlighted = on
    }

    const {
      update: updateAddressDetails,
      response: updateAddressDetailsResponse,
    } = useAddressDetailsSave(undefined, appId)

    const {
      main,
      mainVisibleFields,
      updateMainFields,
      response: updateMainFieldsResponse,
    } = useMainFields(banks, undefined, appId)

    const {
      documents,
      visibleDocuments,
      newDocuments,
      dropZoneAvailability,
      canUploadNewDocumentsBySections,
      canUploadAllNewDocuments,
      uploadDocument,
      cancelUpload,
      deleteDocument,
      downloadDocument,
      downloadDocumentSign,
      addNewDocument,
      removeNewDocumentByIndex,
      clearNewDocuments,
      updateNewDocument,
      onNewDocumentUpload,
      uploadAllNewDocuments,
      uploadPassportByFounderId,
      canUploadNewDocument,
      canAddNewAddedDocument,
      response: uploadDocumentResponse,
    } = useDocuments(undefined, appId)

    const {
      clientCommonVisibleFields,
      updateClientCommonFields,
      response: updateClientCommonFieldsResponse,
    } = useClientCommonFields(client, banks, undefined, appId)

    const {
      clientHeadVisibleFields,
      updateClientHeadFields,
      response: updateClientHeadFieldsResponse,
    } = useClientHeadFields(client, banks, undefined, appId)

    const {
      clientFoundersVisibleFields,
      updateClientFounderFields,
      response: updateClientFounderFieldsResponse,
    } = useClientFoundersFields(client, banks, undefined, appId)

    const {
      finance,
      financeVisible,
      periods,
      tab: financePeriodTab,
      updateFinanceField,
      response: updateFinanceFieldsResponse,
    } = useFinanceFields(undefined, appId)

    const addFounderUse = useAddFounder()

    const {
      updateChangesProcess,
    } = useUpdateFieldsSuccess()

    const {
      options,
    } = useOptions()

    const ITEM_URL = 'v2/control-center/'

    const addFounder = async () => {
      const newFounder = await addFounderUse.addFounder(appId)
      if (!newFounder || !client.value) {
        return
      }

      if (!client.value.founders) {
        client.value.founders = []
      }

      client.value.founders.push(newFounder)
      openedFounderId.value = newFounder.id
    }

    const deleteFounder = async (founderId: string) => {
      const deleteFounderUse = useDeleteFounder()
      if (!client.value || !client.value.founders) {
        return
      }

      await deleteFounderUse.deleteFounder(appId, founderId)

      if (deleteFounderUse.status.value === 200) {
        client.value.founders = client.value.founders.filter(f => f.id !== founderId)
      }

      const deleteFounderResponse: ApplicationFormUpdateResponse | undefined = deleteFounderUse.response.value as never
      startUpdateChangesProcess(deleteFounderResponse)
    }

    const {
      loading,
      get,
      errors,
      error,
      status,
      response,
    } = useGetApi<ApplicationFormResponseData>()

    const item = ref<ApplicationFormResponseData>()

    const isEbg = computed(() => {
      if (!item.value) {
        return false
      }

      return !!item.value.products.EBG
    })

    const isKik = computed(() => {
      if (!item.value) {
        return false
      }

      return !!item.value.products.KIK
    })

    const productType = computed(() => {
      if (isEbg.value) {
        return ProductEnum.EBG
      }

      if (isKik.value) {
        return ProductEnum.KIK
      }

      return undefined
    })

    const load = async () => {
      await get(ITEM_URL + appId)

      if (status.value === 200 && response && response.value) {
        item.value = response.value
        options.value = response.value?.options || null
        if (isEbg.value) {
          documents.value = response.value?.products.EBG?.documents || {}
          finance.value = response.value?.products.EBG?.finance
          client.value = response.value?.products.EBG?.client || null
          banks.value = response.value?.products.EBG?.banks || []
          main.value = response.value?.products.EBG?.order || null
        } else {
          documents.value = response.value?.products.KIK?.documents || {}
          finance.value = response.value?.products.KIK?.finance
          client.value = response.value?.products.KIK?.client || null
          banks.value = response.value?.products.KIK?.banks || []
          main.value = response.value?.products.KIK?.order || null
        }
        /*if (main.value) {
          main.value.main.fields.forEach(f => highlightField(f))
        }*/
      }
    }

    const startUpdateChangesProcess = (response: ApplicationFormUpdateResponse | undefined) => {
      updateChangesProcess(
        response,
        main.value,
        client.value,
        finance.value,
        documents.value,
        banks.value,
      )
      if (item.value) {
        item.value.last_update = moment().format('YYYY-MM-DD HH:mm:ss')
      }

      if (response?.changes.banks.length) {
        const applicationControlCenterStore = useApplicationControlCenterStore(appId)()
        applicationControlCenterStore.init()
      }
    }

    watch(updateMainFieldsResponse, startUpdateChangesProcess)
    watch(updateClientCommonFieldsResponse, startUpdateChangesProcess)
    watch(updateClientHeadFieldsResponse, startUpdateChangesProcess)
    watch(updateClientFounderFieldsResponse, startUpdateChangesProcess)
    watch(updateFinanceFieldsResponse, startUpdateChangesProcess)
    watch(uploadDocumentResponse, startUpdateChangesProcess)
    watch(updateAddressDetailsResponse, startUpdateChangesProcess)

    return {
      loading,
      get,
      errors,
      error,
      load,
      item,
      options,
      banks,
      tab,
      documentTab,
      highlightField,
      isEbg,
      isKik,
      productType,

      main,
      mainVisibleFields,
      updateMainFields,

      finance,
      financeVisible,
      periods,
      financePeriodTab,
      updateFinanceField,

      clientCommonVisibleFields,
      updateClientCommonFields,

      clientHeadVisibleFields,
      updateClientHeadFields,

      client,
      clientFoundersVisibleFields,
      openedFounderId,
      updateClientFounderFields,
      addFounder,
      deleteFounder,

      addFounderLoading: addFounderUse.loading,

      documents,
      visibleDocuments,
      newDocuments,
      dropZoneAvailability,
      canUploadNewDocumentsBySections,
      canUploadAllNewDocuments,
      uploadDocument,
      cancelUpload,
      deleteDocument,
      downloadDocument,
      downloadDocumentSign,
      addNewDocument,
      removeNewDocumentByIndex,
      clearNewDocuments,
      onNewDocumentUpload,
      updateNewDocument,
      uploadAllNewDocuments,
      uploadPassportByFounderId,
      canUploadNewDocument,
      canAddNewAddedDocument,

      updateAddressDetails,

      startUpdateChangesProcess,
    }
  })
