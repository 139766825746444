import {usePutApi} from 'src/uses/Api/usePutApi'
import {ApplicationsGridItemAdditionalInformationReleaseDate} from 'src/models/Applications/ApplicationsGridItem'

export const useApplicationReleaseDate = () => {
  const {
    put,
    response,
    status,
    loading,
    error,
    errors,
  } = usePutApi<unknown, ApplicationsGridItemAdditionalInformationReleaseDate>(true)

  const update = async (
    id: string,
    releaseDate: ApplicationsGridItemAdditionalInformationReleaseDate,
  ) => {
    await put(
      `v1/order/${id}/additional-information/release-date`,
      releaseDate
    )
  }

  return {
    put,
    response,
    status,
    loading,
    error,
    errors,
    update
  }
}
