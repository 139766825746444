<template>
  <q-icon
    class="document-download-icon"
    name="svguse:icons.svg?2#app-truck"
    color="blue-gray-3"
    @click="open"
  />
  <q-dialog
    v-model="dialog"
  >
    <q-card
      class="modal-blue-background"
    >
      <q-card-section>
        <div class="text-h6">Укажите службу доставки и трек номер</div>
      </q-card-section>
      <q-card-section>
        <q-form>
          <div class="row q-col-gutter-md">
            <div class="col-12 white-bg-field">
              <q-input
                v-model="deliveryInfoUpdateData.name"
                class="field-with-top-label"
                :loading="loading"
                :error-message="getErrorMessage('name')"
                :error="hasError('name')"
                label="Служба доставки"
                outlined
                hide-hint
                hide-bottom-space
                dense
                @blur="validation.name.$validate()"
              />
            </div>
            <div class="col-12 white-bg-field">
              <q-input
                v-model="deliveryInfoUpdateData.number"
                class="field-with-top-label"
                :loading="loading"
                :error-message="getErrorMessage('number')"
                :error="hasError('number')"
                label="Трек номер"
                outlined
                hide-hint
                hide-bottom-space
                dense
                @blur="validation.number.$validate()"
                @focus="clearError('number')"
              />
            </div>
          </div>
        </q-form>
      </q-card-section>
      <q-card-actions
        align="right"
        class="text-primary"
      >
        <q-btn
          v-close-popup
          :loading="loading"
          label="Отмена"
          class="q-pr-md"
          color="white"
          text-color="black"
        />
        <q-btn
          :loading="loading"
          label="Сохранить"
          color="primary"
          @click="handleSubmit()"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
import {computed, onBeforeUnmount} from 'vue'
import {useDialog} from 'src/uses/Dialogs/useDialog'
import {storeToRefs} from 'pinia'
import {useDeliveryInfoUpdateStore} from 'src/stores/Profile/Documents/useDeliveryInfoUpdateStore'
import {Document} from 'src/models/Profile/Documents/Document'
import {useValidation} from 'src/uses/useValidation'

const props = defineProps<{
  item: Document
}>()
const item = computed(() => props.item)

const deliveryInfoUpdateStore = useDeliveryInfoUpdateStore()
const {
  deliveryInfoUpdateData,
  loading,
  validation,
  errors,
} = storeToRefs(deliveryInfoUpdateStore)

const {
  update,
} = deliveryInfoUpdateStore

const {dialog, show} = useDialog()

const open = async () => {
  show()
}
const {
  getErrorMessage,
  hasError,
  clearError
} = useValidation(validation, errors)

onBeforeUnmount(() => {
  deliveryInfoUpdateStore.$reset()
  deliveryInfoUpdateStore.$dispose()
})

const handleSubmit = async () => {
  await update(item.value.id)
}

</script>
<style lang="scss">
.DocumentDeliveryModal {
  margin: 0 -24px -24px;
  background: $blue-1;
  padding: 0 24px 24px;
}
</style>
