import {defineStore} from 'pinia'
import {ref} from 'vue'
import {usePutApi} from 'src/uses/Api/usePutApi'
import {ChangeStatusRequestData} from 'src/models/Status/ChangeStatusRequestData'
import {ChangeStatusPayload} from 'src/models/Status/ChangeStatusPayload'
import {ApplicationFormUpdateResponse} from 'src/models/Applications/Form/ApplicationFormUpdateResponse'
import {useKikClientRejectComment} from 'stores/Status/useKikClientRejectComment'
import {useRequestKik} from 'stores/Status/useRequestKik'
import {useToNewKik} from 'stores/Status/useToNewKik'
import {useBankRejectKik} from 'stores/Status/useBankRejectKik'

export const useStatusKikStore = defineStore('applications-kik-change-status', () => {
  const {
    put,
    response,
    status,
    loading,
    error,
    errors,
  } = usePutApi<ApplicationFormUpdateResponse, ChangeStatusRequestData>(true)

  const lastAffectedStatusPayload = ref<ChangeStatusPayload>()
  const lastStatusPayload = ref<ChangeStatusPayload>()

  const changeStatus = async (
    payload: ChangeStatusPayload,
  ) => {
    lastStatusPayload.value = payload

    if (await onClientRejectComment.needShowDialog()) {
      onClientRejectComment.showClientRejectCommentDialog()
      return
    }

    if (await request.needShowDialog()) {
      request.showRequestDialog()
      return
    }

    if (await toNew.needShowDialog()) {
      toNew.showNewDialog()
      return
    }

    if (await bankReject.needShowDialog()) {
      bankReject.showBankRejectDialog()
      return
    }

    await realChangeStatus()
  }

  const realChangeStatus = async () => {
    if (!lastStatusPayload.value) {
      return
    }

    await put(
      `v2/control-center/${lastStatusPayload.value.id}/status/${lastStatusPayload.value.applicationStatus}`,
      {
        initiator: lastStatusPayload.value.initiator || '',
        withoutMessage: lastStatusPayload.value.withoutMessage || false,
        ...(lastStatusPayload.value.comment ? {comment: lastStatusPayload.value.comment} : {}),
        ...(lastStatusPayload.value?.sign_url ? {sign_url: lastStatusPayload.value.sign_url} : {}),
      }
    )

    if (status.value === 200 && response && response.value) {
      lastAffectedStatusPayload.value = lastStatusPayload.value
      lastStatusPayload.value = undefined
    }
  }

  const onClientRejectComment = useKikClientRejectComment(lastStatusPayload, realChangeStatus)
  const request = useRequestKik(lastStatusPayload, realChangeStatus)
  const toNew = useToNewKik(lastStatusPayload, realChangeStatus)
  const bankReject = useBankRejectKik(lastStatusPayload, realChangeStatus)

  return {
    put,
    response,
    status,
    loading,
    error,
    errors,
    lastAffectedStatusPayload,
    lastStatusPayload,

    clientRejectCommentDialog: onClientRejectComment.clientRejectCommentDialog,
    showClientRejectCommentDialog: onClientRejectComment.showClientRejectCommentDialog,
    hideClientRejectCommentDialog: onClientRejectComment.hideClientRejectCommentDialog,
    clientRejectWithComment: onClientRejectComment.clientRejectWithComment,

    requestDialog: request.requestDialog,
    showRequestDialog: request.showRequestDialog,
    hideRequestDialog: request.hideRequestDialog,
    onSaveRequest: request.onSaveRequest,

    newDialog: toNew.newDialog,
    showNewDialog: toNew.showNewDialog,
    hideNewDialog: toNew.hideNewDialog,
    onSaveNew: toNew.onSaveNew,

    bankRejectDialog: bankReject.bankRejectDialog,
    showBankRejectDialog: bankReject.showBankRejectDialog,
    hideBankRejectDialog: bankReject.hideBankRejectDialog,
    onSaveBankReject: bankReject.onSaveBankReject,

    changeStatus,
  }
})
