import {
  ApplicationFormUpdateResponse,
} from 'src/models/Applications/Form/ApplicationFormUpdateResponse'
import {useDeleteApi} from 'src/uses/Api/useDeleteApi'

export const useDeleteFounder = () => {
  const {
    error,
    errors,
    deleteRequest,
    status,
    response,
    loading,
  } = useDeleteApi<ApplicationFormUpdateResponse>()

  const deleteFounder = async (founderId: string, multiOrderId: number) => {
    await deleteRequest(
      `v2/orders/forms/${multiOrderId}/founders/${founderId}`,
    )
  }

  return {
    deleteFounder,
    error,
    errors,
    status,
    response,
    loading,
  }
}
