<template>
  <q-item
    v-if="isShow(props.item)"
    :active="props.item.active"
    clickable
    v-ripple
    @click="navigate(props.item)"
  >
    <q-item-section avatar>
      <q-icon :name="props.item.icon" />
      <q-tooltip
        v-if="props.mini"
        self="center left"
        anchor="center right"
        :delay="100"
        :offset="[20, 0]"
      >
        {{ props.item.label }}
      </q-tooltip>
    </q-item-section>
    <q-item-section>
      {{ props.item.label }}
    </q-item-section>
  </q-item>
</template>

<script setup lang="ts">
import {MenuItem} from 'src/models/MenuItem'
import {useMenuStore} from 'stores/useMenuStore'

const props = defineProps<{
  item: MenuItem
  mini: boolean
}>()

const menuStore = useMenuStore()
const {
  navigate,
  isShow,
} = menuStore
</script>

<style scoped lang="scss">

</style>
