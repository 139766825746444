<template>
  <div class="FormItemFinancePeriods q-mt-md">
    <div
      v-for="(period, key) of periods"
      :key="key"
      class="PeriodBlock q-mb-lg"
    >
      <div class="row white-bg-field q-col-gutter-md q-pa-md">
        <q-select
          v-model="period.year"
          class="q-mb-sm col-4"
          :options="getYears()"
          label="Год"
          :error-message="getErrorMessage(`periods[${key}].year`)"
          :error="hasError(`periods[${key}].year`)"
          outlined
          hide-bottom-space
          dense
          @update:model-value="save"
          @focus="clearError(`periods[${key}].year`)"
        />
        <q-select
          v-if="period.year"
          v-model="period.quarter"
          class="q-mb-sm col-4"
          :options="getQuarters()"
          label="Квартал"
          :error-message="getErrorMessage(`periods[${key}].quarter`)"
          :error="hasError(`periods[${key}].quarter`)"
          outlined
          hide-bottom-space
          dense
          @update:model-value="save"
          @focus="clearError(`periods[${key}].quarter`)"
        />
        <form-select
          v-if="period.year && period.quarter && !!period.reports"
          class="col-4"
          :model-value="period.reports.map((r)=> r.id)"
          :options="reportsOptions"
          label="Формы"
          top-actions
          @update:model-value="updateReports(period.id, $event as string[])"
        />

        <div
          v-if="period.year && period.quarter && !!period.reports && period.reports.length > 0"
          class="col-12 q-gutter-y-md"
        >
          <template
            v-for="(report, reportKey) in period.reports"
            :key="report.id"
          >
            <form-select
              :model-value="report.fields.map(f => f.id)"
              :options="getFieldsOptions(report.id)"
              :label="`Строки в &quot;${report.title}&quot;`"
              :error-message="getErrorMessage(`periods[${key}].reports[${reportKey}].fields`)"
              :error="getErrorMessage(`periods[${key}].reports[${reportKey}].fields`)"
              top-actions
              @update:model-value="updateReportFields(period.id, report.id, $event as string[])"
              @focus="clearError(`periods[${key}].reports[${reportKey}].fields`)"
            />
          </template>
        </div>
        <q-input
          v-model="period.comment"
          class="col-12"
          label="Комментарий"
          outlined
          hide-bottom-space
          dense
          @change="save"
        />
        <div
          v-if="period.conditions && period.conditions.parts.length > 0"
          class="col-12 q-py-lg q-pt-none"
        >
          <div
            v-for="(condition, index) in period.conditions.parts"
            :key="index"
          >
            <div
              class="row q-mb-md q-pa-md"
              style="border-top: 1px dotted #adacac;"
            >
              <div class="col-12">
                <div class="text-bold q-mb-xs">Условие #{{ index + 1 }}</div>
                <q-form>
                  <div class="text-blue-grey-4">Параметр</div>
                  <div
                    v-for="(param, indexParam) in condition.parts"
                    :key="indexParam"
                  >
                    <div class="row items-center white-bg-field q-ma-none">
                      <q-select
                        v-if="item"
                        v-model="param.param"
                        :options="item.conditions_params"
                        class="col q-mb-sm"
                        option-label="title"
                        option-value="param"
                        emit-value
                        map-options
                        outlined
                        hide-bottom-space
                        dense
                        @update:model-value="onChangeParam(period.id, index, indexParam)"
                      />
                      <q-field
                        :model-value="param.operator"
                        class=" q-pt-none no-bg q-mb-sm text-no-wrap"
                        :error="false"
                        borderless
                        hide-bottom-space
                        dense
                      >
                        <q-btn-toggle
                          v-model="param.operator"
                          class="full-width"
                          toggle-color="primary"
                          color="white"
                          text-color="grey-9"
                          toggle-text-color="white"
                          :options="operatorOptions"
                          :disable="paramComparisonOptions[param.param] || false"
                          no-caps
                          spread
                          unelevated
                          @update:model-value="changeParamValueOrOperator(period.id)"
                        />
                      </q-field>
                      <q-input
                        v-if="paramValueTypesAndOptions[param.param].type === FormItemConditionsParamTypeEnum.NUMBER || paramValueTypesAndOptions[param.param].type === FormItemConditionsParamTypeEnum.STRING"
                        v-model="param.value as string | number | undefined"
                        class="col q-mb-sm"
                        type="number"
                        outlined
                        hide-bottom-space
                        dense
                        @change="changeParamValueOrOperator(period.id)"
                      />
                      <template v-if="paramValueTypesAndOptions[param.param].type === FormItemConditionsParamTypeEnum.SELECT">
                        <form-select
                          v-if="paramValueTypesAndOptions[param.param].multiple"
                          class="col q-mb-sm"
                          :model-value="param.value || []"
                          :options="paramValueTypesAndOptions[param.param].options"
                          label=""
                          top-actions
                          option-label="label"
                          option-value="value"
                          @update:model-value="changeMultiSelectParamValue(period.id, index, indexParam, $event)"
                        />
                        <q-select
                          v-else
                          v-model="param.value"
                          class="col q-mb-sm"
                          :options="paramValueTypesAndOptions[param.param].options"
                          option-label="label"
                          option-value="value"
                          emit-value
                          map-options
                          outlined
                          hide-bottom-space
                          dense
                          @update:model-value="changeParamValueOrOperator(period.id)"
                        />
                      </template>
                      <q-btn
                        size="md"
                        icon="svguse:icons.svg?2#app-trash"
                        class="q-ml-sm q-mb-sm"
                        flat
                        dense
                        no-wrap
                        no-caps
                        round
                        @click="removeParam(period.id, index, indexParam)"
                      />
                    </div>
                  </div>
                  <div
                    class="row q-mt-sm justify-between"
                    style="padding-right: 40px"
                  >
                    <q-btn
                      class="btnBgGray"
                      style="padding: 8px 17px;"
                      label="Добавить параметр"
                      icon-right="svguse:icons.svg?2#app-plus"
                      text-color="blue-gray-5"
                      unelevated
                      no-wrap
                      no-caps
                      @click="addParam(period.id, index)"
                    />
                    <q-btn
                      class="btnBgGray"
                      style="padding: 8px 17px;"
                      text-color="blue-gray-5"
                      label="Удалить условие"
                      unelevated
                      no-wrap
                      no-caps
                      @click="removeCondition(period.id, index)"
                    />
                  </div>
                </q-form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-between PeriodBlock--bottom q-pa-md">
        <q-btn
          label="Добавить условие"
          icon-right="svguse:icons.svg?2#app-plus"
          text-color="primary"
          class="PeriodBottom-btn"
          style="padding: 13px 16px;"
          unelevated
          no-caps
          @click="addCondition(period.id, key)"
        />
        <form-item-delete-period-dialog
          :form-id="props.formId"
          :period-id="period.id || ''"
          @change="deletePeriod(period.id, key)"
        />
      </div>
    </div>
    <q-btn
      v-if="periodsOff"
      label="Добавить период"
      color="primary"
      icon-right="svguse:icons.svg?2#app-plus"
      class="q-mt-md"
      style="padding: 13px 16px;"
      unelevated
      no-caps
      @click="addPeriod"
    />
  </div>
</template>

<script setup lang="ts">
import {useFormItemFinancialStore} from 'stores/FormEditor/useFormItemFinancialStore'
import {storeToRefs} from 'pinia'
import {onBeforeUnmount} from 'vue'
import FormSelect from 'components/FormElements/FormSelect.vue'
import FormItemDeletePeriodDialog from 'components/FormEditor/Finance/FormItemDeletePeriodDialog.vue'
import {
  FormItemConditionsParamTypeEnum
} from 'src/models/FormEditor/FormItemResponseData'
import {useValidation} from 'src/uses/useValidation'

const props = defineProps<{
  formId: string,
}>()

const formItemFinancialStore = useFormItemFinancialStore(props.formId)()
const {
  getQuarters,
  getYears,
  addPeriod,
  deletePeriod,
  getFieldsOptions,

  updateReports,
  updateReportFields,
  save,

  addCondition,
  removeCondition,
  addParam,
  removeParam,
  onChangeParam,
  changeMultiSelectParamValue,
  changeParamValueOrOperator,
} = formItemFinancialStore
const {
  periodsOff,
  periods,
  reportsOptions,
  item,
  operatorOptions,
  paramComparisonOptions,
  paramValueTypesAndOptions,
  validation,
  errors,
} = storeToRefs(formItemFinancialStore)

const {
  getErrorMessage,
  hasError,
  clearError,
} = useValidation(validation, errors)

onBeforeUnmount(() => {
  formItemFinancialStore.$reset()
  formItemFinancialStore.$dispose()
})

</script>

<style lang="scss">
.FormItemFinancePeriods {
  .PeriodBlock {
    border: 1px #C7DFF1 solid;
    border-radius: 6px;
  }
  .PeriodBlock--bottom {
    background-color: #D5EAFA;
    .PeriodBottom-btn {
      background-color: #C7DFF1;
    }
  }
  .btnBgGray {
    background-color: #C7DFF1 !important;
  }
}
</style>
