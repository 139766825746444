import {useGetApi} from 'src/uses/Api/useGetApi'
import {ListingsItem} from 'src/models/Listings/ListingsItem'
import {ref} from 'vue'

export const useListingsItem = () => {
  const {
    get,
    response,
    status,
    loading,
    error,
    errors,
  } = useGetApi<ListingsItem>()

  const listingsItem = ref<ListingsItem>({
    id: '',
    title: '',
    config: null,
    permissions: null,
  })

  const loadListingsItem = async (id: string) => {
    await get(`v2/listings/${id}`)
    if (status.value === 200 && response.value) {
      listingsItem.value = response.value
    }
  }

  const getListingsItem = () => {
    return listingsItem.value
  }

  return {
    listingsItem,
    loading,
    response,
    status,
    error,
    errors,
    loadListingsItem,
    getListingsItem
  }
}
