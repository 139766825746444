<template>
  <div v-if="creditNextToFocus">
    <q-intersection
      transition="scale"
      once
    >
      <div class="applicationNewNexFieldHelper row items-center text-weight-medium q-py-md">
        <div
          class="col-12 q-px-md cursor-pointer"
          @click="creditFocusNext"
        >
          <template v-if="creditNextToFocus === 'creditAmount'">
            Укажите cумму кредита
          </template>
          <template v-if="creditNextToFocus === 'creditMonths'">
            Укажите количество месяцев
          </template>
          <template v-if="creditNextToFocus === 'creditType'">
            Укажите тип
          </template>
          <template v-if="creditNextToFocus === 'hasStateContracts'">
            Укажите наличие контрактов
          </template>
        </div>
      </div>
    </q-intersection>
  </div>
</template>

<script setup lang="ts">

import {useApplicationsNew} from 'src/stores/Applications/ApplicationsNew/useApplicationsNew'
import {storeToRefs} from 'pinia'

const applicationsNewStore = useApplicationsNew()
const {
  creditFocusNext,
} = applicationsNewStore

const {
  creditNextToFocus,
} = storeToRefs(applicationsNewStore)
</script>

<style lang="scss">
.applicationNewNexFieldHelper {
  background: $green-1;
  color: $green-14;
  font-size: 14px;

  span {
    color: $grey-7;
  }
}
</style>
