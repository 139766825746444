import {AgentPortalData} from 'src/models/Profiles/Agents/AgentPortalData'
import {usePostApi} from 'src/uses/Api/usePostApi'
import {FormHelper} from 'src/helpers/FormHelper'

export const useAgentPostPortalData = () => {
  const {
    post,
    response,
    status,
    loading,
    error,
    errors,
  } = usePostApi<unknown, FormData>()

  const save = async (
    data: AgentPortalData,
    logoFile?: File,
    bgEnterFile?: File,
    bgInnerFile?: File,
    bannerImgFile?: File,
    deleteLogo = false,
  ) => {
    const bannerDataForm = Object.fromEntries(
      Object.entries(data.banner)
        .map(([key, value]) => ([
          `banner_${key}`,
          value === null ? '' : value
        ])).filter((item) => item[0] !== 'banner_image')
    )

    const documentTitles = Object.fromEntries(
      data.design_config.documents
        .map((doc, index) => ([
          `documents[${index}][title]`,
          doc.title
        ]))
    )
    const documentUrls = Object.fromEntries(
      data.design_config.documents
        .map((doc, index) => ([
          `documents[${index}][url]`,
          doc.url
        ]))
    )

    const dataForForm = Object.fromEntries(
      Object.entries(data.design_config)
        .filter((entry) => entry[1] !== null && entry[1] !== 'null')
        .filter(entry => !['documents', 'logo', 'home_page_background_image', 'other_page_background_image'].includes(entry[0]))
    )

    const formData = FormHelper.getFormDataFromObject({
      logo_delete: deleteLogo,
      ...dataForForm,
      ...bannerDataForm,
      ...(logoFile ? {logo: logoFile} : {}),
      ...(bgEnterFile ? {home_page_background_image: bgEnterFile} : {}),
      ...(bgInnerFile ? {other_page_background_image: bgInnerFile} : {}),
      ...documentTitles,
      ...documentUrls,
      ...(bannerImgFile ? {banner_image: bannerImgFile} : {}),
    })

    console.log(formData)

    await post('v1/agent/sign-portal/config', formData)
  }

  return {
    loading,
    response,
    status,
    error,
    errors,
    save
  }
}
