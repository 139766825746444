<template>
  <q-btn
    v-if="props.isChat"
    class="GridActions q-ml-sm"
    color="blue-grey-4"
    size="12px"
    icon="svguse:icons.svg?2#app-chat"
    padding="6px"
    flat
    unelevated
    rounded
    square
    @click="open"
  />
  <q-btn
    v-if="ActStatuses.isFormedStatus(item.status) && !props.isChat"
    class="GridActions q-ml-sm"
    color="red-4"
    size="12px"
    icon="svguse:icons.svg?2#app-exclamation-circle"
    padding="6px"
    flat
    unelevated
    rounded
    square
    @click="show"
  />
  <q-dialog
    v-model="dialog"
  >
    <q-card
      style="min-width: 700px"
      class="modal-blue-background"
    >
      <q-card-section>
        <div class="text-h6">Акт / {{ item.month }}.{{ item.year }}</div>
      </q-card-section>
      <q-card-section class="q-pb-none q-pt-none">
        <info-banner
          :text="getTextForBanner(item.status)"
          :caption="ActStatuses.isFormedStatus(item.status) ? '' : captionBannerText"
          icon="svguse:icons.svg?2#app-exclamation-circle"
        />
      </q-card-section>
      <q-card-section>
        <div
          v-if="chat"
          class="q-pa-md act-chat-room"
        >
          <div
            v-for="day in chat"
            :key="day.day"
            style="max-height: 300px; overflow-y: scroll"
          >
            <div class="chat-messages-day text-center q-pa-lg">
              <q-chip>{{ getFormatDate(day.day) }}</q-chip>
            </div>
            <div
              v-for="mess in getMessagesByDay(day)"
              :key="mess.date"
              class="q-ma-md"
            >
              <q-chat-message
                :name="mess.is_mine ? 'Я' : 'GOSOBLAKO'"
                :text="[mess.text]"
                :sent="mess.is_mine"
                :stamp="getMessageTime(mess)"
                size="5"
              />
            </div>
          </div>
        </div>
      </q-card-section>
      <q-card-section class="q-pl-lg q-pr-lg white-bg-field">
        <div v-if="ActStatuses.isFormedStatus(item.status)">
          <div class="text-caption q-mb-sm">Комментарий (необязательно)</div>
          <q-input
            v-model="message"
            filled
            clearable
            type="text"
            label="Введите комментарий"
          />
        </div>
        <div v-else>
          <div class="no-active-chat q-pa-md">
            <q-banner class="no-active-chat-banner-info">
              <template #avatar>
                <div class="icon-no-active-chat-info">
                  <q-icon
                    name="svguse:icons.svg?2#app-exclamation"
                    size="sm"
                    color="orange"
                  />
                </div>
              </template>
              <div>В это чат нельзя писать пока акт находится на проверке</div>
            </q-banner>
          </div>
        </div>
      </q-card-section>
      <q-card-actions
        v-if="ActStatuses.isFormedStatus(item.status)"
        align="right"
        class="modal-actions text-primary q-pa-lg"
      >
        <q-btn
          v-close-popup
          label="Отмена"
          class="q-pr-lg"
          style="padding: 17px 28px;"
          unelevated
          flat
          no-caps
          text-color="black"
        />
        <q-btn
          label="На согласование"
          color="primary"
          style="padding: 17px 28px;"
          unelevated
          icon-right="svguse:icons.svg?2#app-arrow-narrow-right"
          no-caps
          @click="handleSubmit()"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
import {useDialog} from 'src/uses/Dialogs/useDialog'
import {computed, onBeforeUnmount} from 'vue'
import {Act} from 'src/models/Profile/Acts/Act'
import InfoBanner from 'src/components/InfoBanner.vue'
import {useProfileActSendMessageStore} from 'src/stores/Profile/Acts/useProfileActSendMessageStore'
import {storeToRefs} from 'pinia'
import {useProfileActMessagesStore} from 'src/stores/Profile/Acts/useProfileActMessagesStore'
import {ActStatuses} from 'src/models/Profile/Acts/ActStatusDescriptions'
import {ActStatusEnum} from 'src/models/Enums/ActStatusEnum'

const props = defineProps<{
  item: Act,
  isChat: boolean,
}>()

const item = computed(() => props.item)
const {dialog, show} = useDialog()

const open = async () => {
  if (item.value) {
    await load(item.value)
  }
  show()
}

const captionBannerText = 'Как только наш администратор его согласует - вы получите уведомление на почту'

const getTextForBanner = (status: ActStatusEnum): string => {
  return ActStatuses.isFormedStatus(status)
    ? 'В поле ниже Вы можете оставить комментарий к акту'
    : 'Ваш акт находится на согласовании.'
}

const profileActSendMessageStore = useProfileActSendMessageStore()
const {
  message,
} = storeToRefs(profileActSendMessageStore)

const {
  sendMessage
} = profileActSendMessageStore

const profileActMessagesStore = useProfileActMessagesStore()
const {
  chat,
} = storeToRefs(profileActMessagesStore)

const {
  load,
  getMessagesByDay,
  getMessageTime,
  getFormatDate,
} = profileActMessagesStore

onBeforeUnmount(() => {
  profileActSendMessageStore.$reset()
  profileActSendMessageStore.$dispose()
})

const handleSubmit = async () => {
  await sendMessage(item.value)
}

</script>
<style lang="scss">
.act-chat-room {
  background-color: #ffffff;
  padding: 20px;
  margin: -20px;
  .chat-messages-day {
    .q-chip__content {
      font-weight: 400;
      font-size: 12px;
      line-height: 12px;
    }
  }

  .q-message-text {
    padding: 10px 15px 15px 15px;
  }

  .q-message-received {
    .q-message-name--received {
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 17px;
      color: #1E88E5;
      margin-bottom: 2px;
    }
    .q-message-name--received:before {
      display: inline-flex;
      content: '';
      background-image: url('../../../assets/chat_gosoblako_icon.svg');
      background-size: 14px 14px;
      height: 12px;
      width: 15px;
      margin-top: 1px;
    }
    .q-message-text--received {
      background: #E3F2FD;
      border-radius: 14px 14px 14px 0;
    }
    .q-message-text:last-child:before {
      content: none;
    }
    .q-message-stamp {
      color: #64B5F6;
    }
  }

  .q-message-sent {
    .q-message-name--sent {
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 12px;
      color: #424242;
      margin-bottom: 3px;
    }
    /*.q-message-name--sent:before {
      display: inline-flex;
      content: '';
      background-image: url('../../../assets/chat_gosoblako_icon.svg');
      background-size: 12px 12px;
      height: 12px;
      width: 12px;
    }*/
    .q-message-text--sent {
      background: #F5F5F5;
      border-radius: 14px 14px 0 14px;
    }
    .q-message-text:last-child:before {
      content: none;
    }
    .q-message-stamp {
      color: #90A4AE;
    }
  }

}
.no-active-chat {

  .no-active-chat-banner-info {
    background: #263238;
    color: #ffffff;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    margin: 0 -14px 0 -14px;
    padding: 10px 20px;
    border-radius: 5px;
    .icon-no-active-chat-banner-info-info {
      background: #37474F;
      border-radius: 6px;
      padding: 14px;
    }
    .icon-no-active-chat-info{
      margin-top: 5px;
    }
  }
}
</style>
