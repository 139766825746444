<template>
  <div
    v-if="items.length > 1"
    class="ItemBeneficiariesList q-mt-lg"
  >
    <div class="row q-pa-md items-center">
      <div
        class="text-h6 q-pr-lg"
        style="margin-top: -3px;"
      >
        Заказчики
      </div>
      <div class="q-pr-lg">
        Выбрано бенефициаров: {{ items.length }}
      </div>
      <div class="q-pr-lg">
        Сумма обеспечения:
        <money-component :model-value="sum" />
      </div>
      <div :class="{'col-md-grow col-xs-12 text-right': true, 'q-pt-md': $q.platform.is.mobile }">
        <q-btn
          v-if="!opened"
          :class="{'full-width': $q.platform.is.mobile}"
          label="Показать"
          color="primary"
          icon-right="svguse:icons.svg?2#app-sort-descending"
          padding="15px 30px"
          unelevated
          no-caps
          @click="opened = true"
        />
        <q-btn
          v-else
          :class="{'full-width': $q.platform.is.mobile}"
          label="Скрыть"
          color="blue-grey-1"
          class="text-grey-6"
          icon-right="svguse:icons.svg?2#app-sort-ascending"
          padding="15px 30px"
          unelevated
          no-caps
          @click="opened = false"
        />
      </div>
    </div>
    <div
      v-if="opened"
    >
      <div class="q-pa-md blue-bg-field">
        <q-input
          v-model="search"
          class="field-with-top-label"
          label="Поиск по ИНН или наименованию"
          outlined
          hide-bottom-space
          dense
          emit-value
          map-options
          options-dense
          hide-dropdown-icon
          clearable
        >
          <template #prepend>
            <q-icon name="svguse:icons.svg?2#app-search" />
          </template>
        </q-input>
      </div>
      <q-table
        class="full-width"
        :style="{'max-height': Platform.is.mobile ? 'auto' : '40vh'}"
        :rows="filteredItems"
        :columns="columns"
        :pagination="{rowsPerPage: 0}"
        separator="horizontal"
        row-key="inn"
        hide-pagination
        virtual-scroll
        flat
        :grid="Platform.is.mobile"
      >
        <template #body-cell="propsTd">
          <q-td
            :props="propsTd"
            class="text-wrap"
          >
            {{ propsTd.value }}
          </q-td>
        </template>
        <template #body-cell-name="propsTd">
          <q-td
            :props="propsTd"
            class="text-wrap name"
          >
            {{ propsTd.value.toLowerCase() }}
          </q-td>
        </template>
        <template #body-cell-amount="propsTd">
          <q-td
            :props="propsTd"
            class="text-wrap"
          >
            <money-component :model-value="propsTd.value || 0" />
          </q-td>
        </template>
        <template #item="props">
          <div class="col-lg-12">
            <div class="row BeneficiareListMobile--item">
              <div class="col items-start itemInfo">
                <div class="q-mb-md">
                  <div class="text-caption">ИНН</div>
                  {{ props.row.inn }}
                </div>
                <div class="q-mb-md">
                  <div class="text-caption">Наименование</div>
                  {{ props.row.name.toLowerCase() }}
                </div>
                <div class="q-mb-md">
                  <div class="text-caption">Юридечский адрес</div>
                  {{ props.row.address.toLowerCase() }}
                </div>
                <div class="q-mb-md">
                  <div class="text-caption">Сумма обеспечения</div>
                  <money-component :model-value="props.row.amount || 0" />
                </div>
              </div>
            </div>
          </div>
        </template>
      </q-table>
    </div>
  </div>
</template>

<script setup lang="ts">
import {computed, ref} from 'vue'
import MoneyComponent from 'src/components/MoneyComponent.vue'
import {ApplicationFormResponseData} from 'src/models/Applications/Form/ApplicationFormResponseData'
import {useSearch} from 'src/uses/useSearch'
import {Platform, useQuasar} from 'quasar'
import {QTableColumn} from 'src/models/Grid/GridColumnSettings'
import {ProductEnum} from 'src/models/Applications/ProductEnum'

const props = defineProps<{
  item: ApplicationFormResponseData,
  product: ProductEnum,
}>()

const $q = useQuasar()

const {
  filter,
} = useSearch()

const items = computed(() => {
  const product = props.item?.products[props.product]
  if (!product) {
    return []
  }

  if (!product.order.customers) {
    return []
  }

  return product.order.customers
    .map(row => {
      const inn = (row.fields.find(field => field.code === 'DEAL_CUSTOMERS_INN')?.value || '').toString()
      const name = (row.fields.find(field => field.code === 'DEAL_CUSTOMERS_NAME')?.value || '').toString()
      const amount = parseFloat((row.fields.find(field => field.code === 'DEAL_CUSTOMERS_GUARANTEE')?.value || '').toString())
      const address = (row.fields.find(field => field.code === 'DEAL_CUSTOMERS_ADDRESS_LEGAL')?.value || '').toString()

      return {
        inn,
        name,
        amount,
        address,
      }
    })
})

const opened = ref(false)
const columns = ref<QTableColumn[]>([
  {
    name: 'inn',
    field: 'inn',
    required: true,
    label: 'ИНН',
    align: 'left',
    classes: 'text-no-wrap',
  },
  {
    name: 'name',
    field: 'name',
    required: true,
    label: 'Наименование',
    align: 'left',
    classes: 'text-no-wrap name',
  },
  {
    name: 'address',
    field: 'address',
    required: true,
    label: 'Юридечский адрес',
    align: 'left',
    classes: 'text-no-wrap',
  },
  {
    name: 'amount',
    field: 'amount',
    required: true,
    label: 'Сумма обеспечения',
    align: 'left',
    classes: 'text-no-wrap',
  },
])

const search = ref<string>('')

const filteredItems = computed(() => {
  return items.value
    .filter(i => filter(
      search.value,
      [
        i.inn.toString(),
        i.name,
      ])
    )
})

const sum = computed(() => {
  return items.value.reduce((summ, i) => summ + i.amount || 0, 0)
})

</script>

<style lang="scss">
body {
  .ItemBeneficiariesList {
    border-radius: 6px;
    position: relative;
    background: white;

    thead {
      tr:first-child th {
        background: $grey-3 !important;
        top: 3px;
        position: sticky;
        z-index: 1;

        &:before {
          content: '';
          width: 100%;
          height: 4px;
          margin-top: -4px;
          background: #ffffff;
          position: absolute;
          z-index: 1;
          top: 0;
          left: 0;
        }
      }
    }

    .name {
      text-transform: capitalize;
    }

    .q-field {
      .q-field__label {
        top: -32px !important;
        left: -49px !important;
      }
    }

    .q-table__bottom {
      display: none;
    }

    .BeneficiareListMobile--item {
      border-bottom: 1px solid #EEEEEE;
      display: flex;
      align-items: flex-start;
      padding-top: 5px;
      .itemInfo {
        padding-top: 10px;
        padding-left: 16px;
        padding-right: 16px;
        font-size: 16px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0;
        text-align: left;
        color: #424242;
        .text-caption {
          font-size: 14px;
          font-weight: 400;
          line-height: 14px;
          letter-spacing: 0;
          text-align: left;
          color: #9E9E9E;
          margin-bottom: 3px;
        }
      }
    }
  }

  .ItemBeneficiariesListClosed {
    border: 1px solid $blue-grey-1;
    border-radius: 6px;
    color: $grey-7;
  }
}
</style>
