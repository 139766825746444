import {useDialog} from 'src/uses/Dialogs/useDialog'
import {useProfileStore} from 'src/stores/Login/useProfileStore'
import {Ref} from 'vue'
import {ChangeStatusPayload} from 'src/models/Status/ChangeStatusPayload'
import {ApplicationKikSubStatusEnum} from 'src/models/Applications/ApplicationKikSubStatusEnum'

export const useRequestKik = (payload: Ref<ChangeStatusPayload | undefined>, realChangeStatus: () => Promise<void>) => {
  const {
    dialog: requestDialog,
    show: showRequestDialog,
    hide: hideRequestDialog,
  } = useDialog()

  const {
    isBankEmployee,
  } = useProfileStore()

  const needShowDialog = async () => {
    return isBankEmployee()
      && payload.value
      && ApplicationKikSubStatusEnum.data_request === payload.value?.applicationStatus
  }

  const onSaveRequest = async (comment: string) => {
    if (!payload.value) {
      return
    }

    payload.value.comment = comment

    await realChangeStatus()
    hideRequestDialog()
  }

  return {
    requestDialog,
    showRequestDialog,
    hideRequestDialog,
    needShowDialog,
    onSaveRequest,
  }
}
