<template>
  <template v-if="data && !!profile">
    <div
      v-if="!profile.curator"
      class="support"
    >
      <a :href="`tel:+${data.phone}`">
        <q-icon
          v-if="!$q.platform.is.mobile"
          name="svguse:icons.svg?2#app-support-head"
          color="blue-grey-4"
          size="24px"
        />
        {{ data.phone }}
      </a>
      <a href="mailto:support@gosoblako.com">
        <span class="text-grey q-ml-sm">{{ data.email }}</span>
      </a>
    </div>
    <div
      v-if="profile.curator"
      class="support"
    >
      <a :href="`tel:+${isSimba ? data.phone: profile.curator.phone}`">
        <q-icon
          v-if="!$q.platform.is.mobile"
          name="svguse:icons.svg?@#app-support-head"
          color="blue-grey-4"
          class="q-mr-xs"
          size="24px"
        />
        <template v-if="isSimba">
          {{ data.phone }}
        </template>
        <template v-else-if="profile.curator.phone">
          {{ formatPhone(profile.curator.phone) }}
        </template>
      </a>
      <br v-if="$q.platform.is.mobile">
      <span :class="{'text-grey': true, 'q-ml-sm': !$q.platform.is.mobile}">
        <template v-if="isSimba">
          Куратор: Гарматина Татьяна
        </template>
        <template v-else>
          Куратор: {{ profile.curator.name }}
        </template>
      </span>
    </div>
    <div
      v-if="isIndividual"
      class="text-blue-7"
    >
      <strong>{{ profile.name.full }}</strong>
    </div>
    <div
      v-if="isPhysical || isCompany"
      class="text-blue-7"
    >
      <strong>{{ profile.title }}</strong>
    </div>
    <div
      v-if="isOperator() || isAgentManager()"
      class="text-blue-7"
    >
      <strong>{{ isOperator() ? 'Оператор' : 'Куратор' }}: {{ profile.name.full }}</strong>
    </div>
    <div
      v-if="isBankEmployee()"
      class="text-blue-7"
    >
      <strong>Сотрудник Банка: {{ profile.name.full }}</strong>
    </div>
  </template>
</template>

<script setup lang="ts">
import {usePhoneFormat} from 'src/uses/usePhoneFormat'
import {computed} from 'vue'
import {OpfEnum} from 'src/models/OpfEnum'
import {useAuthStore} from 'src/stores/Login/useAuthStore'
import {storeToRefs} from 'pinia'
import {useQuasar} from 'quasar'
import {useIntegratorStore} from 'stores/useIntegratorStore'

const authStore = useAuthStore()
const {
  isAuthed,
  profile,
} = storeToRefs(authStore)

const {
  isOperator,
  isAgentManager,
  isBankEmployee,
} = authStore

const {data} = storeToRefs(useIntegratorStore())

const {
  formatPhone
} = usePhoneFormat()

const $q = useQuasar()

const isCompany = computed(() => isAuthed.value && profile.value ? OpfEnum.COMPANY === profile.value.opf : false)
const isPhysical = computed(() => isAuthed.value && profile.value ? OpfEnum.PHYSICAL === profile.value.opf : false)
const isIndividual = computed(() => isAuthed.value && profile.value ? OpfEnum.INDIVIDUAL === profile.value.opf : false)

const isSimba = computed(() => {
  return document.location.hostname === 'lk.simba-bg.ru'
})
</script>

<style scoped lang="scss">
a {
  text-decoration: none !important;
  color: $grey-9;
}
</style>
