import {GridColumnEditableSettings} from 'src/models/Grid/GridColumnSettings'

export const UsersGridColumns: GridColumnEditableSettings[] = [
  {
    field: '',
    name: 'fio',
    required: true,
    label: 'ФИО',
    align: 'left',
    classes: 'text-no-wrap',
    visible: true,
    editable: true,
    order: 2,
  },
  {
    field: '',
    name: 'phone',
    required: true,
    label: 'Телефон',
    align: 'left',
    classes: 'text-no-wrap',
    visible: true,
    editable: true,
    order: 3,
  },
  {
    field: '',
    name: 'email',
    required: true,
    label: 'E-mail',
    align: 'left',
    visible: true,
    editable: true,
    order: 4,
    sortable: true,
  },
  {
    field: '',
    name: 'date',
    required: true,
    label: 'Дата регистрации',
    align: 'left',
    visible: true,
    editable: true,
    order: 5,
    sortable: true,
  },
  {
    field: '',
    name: 'status',
    required: true,
    label: 'Статус',
    align: 'left',
    visible: true,
    editable: true,
    order: 6,
    sortable: true,
  },
  {
    field: '',
    name: 'id-sign',
    required: true,
    label: 'id sign',
    align: 'left',
    visible: true,
    editable: true,
    order: 7,
    sortable: false,
  },
  {
    field: '',
    name: 'actions',
    label: '',
    required: true,
    align: 'center',
    visible: true,
    editable: false,
    order: 8,
  },
]
