<template>
  <form-group no-border>
    <q-toggle
      ref="inputRef"
      v-model="hasStateContracts"
      style="margin-left: -10px;"
      label="У компании есть не менее 2-х исполненных и 2-х приходящихся к исполнению в ближайшие 18 месяцев госконтрактов в рамках 44-ФЗ"
    />
  </form-group>
</template>

<script setup lang="ts">
import FormGroup from 'src/components/FormGroup.vue'
import {storeToRefs} from 'pinia'
import {ref, watch} from 'vue'
import {useApplicationsNew} from 'src/stores/Applications/ApplicationsNew/useApplicationsNew'
import {QInput} from 'quasar'
import {useScrollTo} from 'src/uses/useScrollTo'

const applicationsNewStore = useApplicationsNew()
const {
  hasStateContracts,
  creditLastFocused,
} = storeToRefs(applicationsNewStore)

const {
  scrollToInvisibleElement,
} = useScrollTo()

const inputRef = ref<QInput>()

watch(
  creditLastFocused,
  () => {
    if (creditLastFocused.value !== 'hasStateContracts' || !inputRef.value) {
      return
    }

    scrollToInvisibleElement(inputRef.value.$el)
    inputRef.value.focus()
    setTimeout(() => inputRef.value?.focus(), 500)
  }
)
</script>
