import {defineStore, storeToRefs} from 'pinia'
import {useGetApi} from 'src/uses/Api/useGetApi'
import {onBeforeMount, ref, watch} from 'vue'
import {useGridPagination} from 'src/uses/Grid/useGridPagination'
import {useProfileStore} from 'src/stores/Login/useProfileStore'
import {applicationGridDefaultFilters} from 'src/components/Applications/ApplicationsGrid/ApplicationGridDefaultFilters'
import {CloneHelper} from 'src/helpers/CloneHelper'
import {GridSorting} from 'src/models/Grid/Sorting'
import {useRoute} from 'vue-router'
import {useMetaStore} from 'src/stores/useMetaStore'
import {useQuasar} from 'quasar'
import {useApplicationsGridKikFiltersStore} from 'stores/Applications/Grid/useApplicationsGridKikFiltersStore'
import {useApplicationsGridKikSortingStore} from 'stores/Applications/Grid/useApplicationsGridKikSortStore'
import {ApplicationsGridKikItem} from 'src/models/Applications/ApplicationsGridKikItem'
import {useStatusKikStore} from 'stores/Status/useStatusKikStore'
import {ApplicationsGridKikResponseData} from 'src/models/Applications/ApplicationsGridKikResponseData'
import {useBankFilterStore} from 'stores/Banks/useBankFilterStore'

export const useApplicationsGridKikStore = defineStore('applications-grid-kik', () => {
  const banksStore = useBankFilterStore()
  const {load: loadBanks} = banksStore
  const {banks} = storeToRefs(banksStore)

  const profileStore = useProfileStore()
  const {profile} = storeToRefs(profileStore)

  const statusStore = useStatusKikStore()
  const {lastAffectedStatusPayload} = storeToRefs(statusStore)

  const route = useRoute()

  const {
    loading,
    get,
    errors,
    error,
    status,
    response,
  } = useGetApi<ApplicationsGridKikResponseData>()

  const {
    pagination,
    gridPagination,
    perPageOptions,
    getFromStorage,
    noMorePages,
    setPagination,
    onRequestPagination,
    setPage,
    setNextPage,
    setPerPage,
    setQueryToRouter: setPaginationQueryToRouter,
  } = useGridPagination('applications-kik', route.name === 'applications-grid-kik')

  const $q = useQuasar()
  const filtersStore = useApplicationsGridKikFiltersStore()
  const {filters} = storeToRefs(filtersStore)

  const sortingStore = useApplicationsGridKikSortingStore()

  const items = ref(<ApplicationsGridKikItem[]>[])

  const load = async () => {
    getFromStorage()
    await filtersStore.setQueryToRouter()
    await setPaginationQueryToRouter()
    await sortingStore.setQueryToRouter()

    const response = await realLoad(
      pagination.value?.page,
      pagination.value?.per_page,
      sortingStore.sorting,
      filtersStore.getFiltersQuery(),
    )

    if (!response) {
      return
    }

    if ($q.platform.is.mobile) {
      items.value = items.value.concat(response.items)
    } else {
      items.value = response.items
    }

    setPagination(response.pagination)
  }

  const realLoad = async (
    page: number,
    perPage: number,
    sorting: GridSorting,
    filters: Record<string, unknown>,
  ) => {
    if (!profile.value) {
      return
    }

    useMetaStore().setTitle('Сделки (КИК)')

    await get(
      'v2/orders/kiks/grid',
      {
        page,
        perpage: perPage,
        ...sorting,
        ...filters,
        'filter[new_request]': filters['filter[new_request]'] ? 1 : 0
      }
    )

    if (status.value === 200 && response && response.value?.items) {
      return response.value
    }

    return undefined
  }

  const updateItem = (item: ApplicationsGridKikItem) => {
    const index = items.value
      .findIndex(i => i.id === item.id)
    if (index === -1) {
      return
    }

    items.value[index] = {...item}
  }

  const refreshById = async (id: string) => {
    const itemIndex = items.value
      .findIndex(item => item.id === id)

    if (itemIndex === -1) {
      return
    }

    const response = await realLoad(
      1,
      1,
      sortingStore.sorting,
      {
        ...CloneHelper.clone(applicationGridDefaultFilters),
        'filter[number]': items.value[itemIndex].number.toString()
      }
    )

    if (!response) {
      return
    }

    const itemExists = items.value
      .findIndex(item => item.id === id)
      !== -1

    if (itemExists && response.items[0]) {
      items.value[itemIndex] = response.items[0]
    }
  }

  onBeforeMount(async () => {
    if (banks.value.length === 0) {
      await loadBanks()
    }
  })

  watch(
    filters,
    () => {
      if (!$q.platform.is.mobile) {
        return
      }

      items.value = []
    },
    {
      deep: true
    }
  )

  watch(
    lastAffectedStatusPayload,
    async () => {
      if (items.value.length === 0) {
        return
      }
      if (!lastAffectedStatusPayload.value) {
        return
      }

      await refreshById(lastAffectedStatusPayload.value.id)
    },
  )

  watch(
    route,
    async () => {
      if (route.name === 'applications-grid-kik' && Object.keys(route.query).length === 0) {
        await load()
      }
    }
  )

  return {
    loading,
    errors,
    error,
    items,
    pagination,
    gridPagination,
    perPageOptions,
    noMorePages,
    setPerPage,
    onRequestPagination,
    load,
    setPage,
    setNextPage,
    updateItem,
    refreshById,
  }
})
