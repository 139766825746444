import {useFilterWithErrors} from 'src/uses/Applications/Form/fill/useFilterWithErrors'
import {computed} from 'vue'
import {
  ApplicationFormBank,
  ApplicationFormFinance,
  ApplicationFormFinanceField,
} from 'src/models/Applications/Form/ApplicationFormResponseData'
import {Ref} from 'vue-demi'

export const useApplicationFormFillFinance = (financeVisible: Ref<ApplicationFormFinance | undefined>, banks: Ref<ApplicationFormBank[]>) => {
  const {
    filterWithErrors,
    filterOnlyCommonOrDraftBanks,
  } = useFilterWithErrors(banks)

  const fillFinanceByBanks = computed(() => {
    if (!banks || !financeVisible) {
      return []
    }

    return banks.value
      .map(bank => {
        const allFields = Object.values(financeFields.value || {})
          .reduce((all, fields) => {
            all.push(...fields)
            return all
          }, [])
          .filter(f => !f.location.bank_id || f.location.bank_id === bank.bank_id)

        const errorWithFields = Object.values(financeErrorFields.value)
          .reduce((all, fields) => {
            all.push(...fields)
            return all
          }, [])
          .filter(f => (!f.location.bank_id || f.location.bank_id === bank.bank_id)
            && f.valid.find(valid => valid.errors.length && valid.id === bank.bank_id)
          )

        return {
          ...bank,
          total: allFields.length,
          error: errorWithFields.length,
        }
      })
  })

  const financeFields = computed(() => {
    if (!financeVisible.value) {
      return {}
    }

    return Object.fromEntries(
      Object.entries(financeVisible.value.fields)
        .map(([key, fields]) => {
          return [
            key,
            fields.filter(filterOnlyCommonOrDraftBanks)
          ]
        })
    )
  })

  const financeErrorFields = computed<Record<number, ApplicationFormFinanceField[]>>(() => {
    return Object.fromEntries(
      Object.entries(financeFields.value)
        .map(([key, fields]) => {
          return [
            key,
            fields.filter(filterWithErrors)
          ]
        })
    )
  })

  const financeFieldsHasErrors = computed(() => {
    return periodsWithErrors.value.length > 0
  })

  const periodsWithErrors = computed<number[]>(() => {
    return Object.entries(financeErrorFields.value)
      .filter(e => e[1].length > 0)
      .map(e => parseInt(e[0]))
  })

  const countAllFinanceFields = computed(() => {
    return Object.values(financeFields.value)
      .map(f => f.length)
      .reduce((sum, length) => sum + length, 0)
  })

  const countErrorsFinanceFields = computed(() => {
    return Object.values(financeErrorFields.value)
      .map(f => f.length)
      .reduce((sum, length) => sum + length, 0)
  })

  return {
    financeFieldsHasErrors,
    financeErrorFields,
    periodsWithErrors,
    countAllFinanceFields,
    countErrorsFinanceFields,
    fillFinanceByBanks,
  }
}
