import {defineStore} from 'pinia'
import {useGridFilters} from 'src/uses/Grid/useGridFilters'
import {useGridFiltersPins} from 'src/uses/Grid/useGridFiltersPins'
import {
  applicationGridDefaultFilters,
  applicationGridDefaultPins,
} from 'src/components/Applications/ApplicationsGrid/ApplicationGridDefaultFilters'

export const useApplicationsGridFiltersStore = defineStore(
  'applications-grid-filters',
  () => {
    const {
      filters,
      isDefaultFilterValue,
      clearable,
      getFiltersQuery,
      clear,
      setQueryToRouter,
    } = useGridFilters(applicationGridDefaultFilters, true)

    const {
      pins,
      hasPins,
      isPinned,
    } = useGridFiltersPins('applications', Object.keys(applicationGridDefaultFilters), applicationGridDefaultPins)

    return {
      filters,
      clearable,
      getFiltersQuery,
      clear,
      pins,
      hasPins,
      isPinned,
      setQueryToRouter,
      isDefaultFilterValue,
    }
  })
