<template>
  <q-dialog
    :model-value="props.modelValue"
    @update:model-value="emit('update:model-value', $event)"
  >
    <q-card
      class="q-mt-md bg-blue-1"
      style="min-width: 400px; max-width: 600px; border-radius: 4px !important;"
    >
      <only-delivery-address-modal-card
        :id="props.headerInfo.id"
        :loading="props.loading"
        @save="onSaveDelivery"
      />
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
import OnlyDeliveryAddressModalCard
  from 'src/components/Applications/onReleaseSendModal/OnlyDeliveryAddressModalCard.vue'
import {withDefaults} from 'vue'
import {ControlCenterHeader} from 'src/models/Applications/ControlCenter/ControlCenterHeader'

const props = withDefaults(
  defineProps<{
    modelValue?: boolean,
    headerInfo: ControlCenterHeader,
    loading: boolean,
  }>(),
  {
    modelValue: false,
    loading: false,
  }
)

const emit = defineEmits<{
  (e: 'update:model-value', value: boolean): void,
}>()

const onSaveDelivery = () => {
  emit('update:model-value', false)
}
</script>
