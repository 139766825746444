<template>
  <q-btn
    style="margin-left: 2px;"
    size="14px"
    color="blue-grey-4"
    icon="svguse:icons.svg?2#app-chat"
    :padding="props.mobile ? '4px 0' : '4px 16px'"
    flat
    @click="showChat"
  >
    <q-badge
      v-if="props.item.chat && props.item.chat.new_messages.all > 0"
      color="red-12"
      style="top: auto; right: auto; margin: -12px -12px 0 0; border-color: white;"
      class="small-badge"
      floating
      rounded
    >
      {{ props.item.chat.new_messages.all }}
    </q-badge>
    <q-tooltip
      anchor="top middle"
      self="bottom middle"
    >Открыть чат</q-tooltip>
  </q-btn>
  <chat-history-standalone
    v-if="dialog"
    v-model="dialog"
    :app-id="props.item.id"
  />
</template>

<script setup lang="ts">
import {watch, withDefaults} from 'vue'
import {ApplicationsGridItem} from 'src/models/Applications/ApplicationsGridItem'
import {useDialog} from 'src/uses/Dialogs/useDialog'
import ChatHistoryStandalone from 'src/components/Chat/ChatHistoryStandalone.vue'
import {usePosthogStore} from 'stores/Posthog/usePosthogStore'

const props = withDefaults(
  defineProps<{
    item: ApplicationsGridItem,
    mobile?: boolean,
  }>(),
  {
    mobile: false,
  }
)

const emit = defineEmits<{
  (e: 'model-closed'): void,
}>()

const {sendEvent} = usePosthogStore()
const {dialog, show} = useDialog()

const showChat = () => {
  show()
  sendEvent('Open_chat_via_list_of_applications')
}

watch(
  dialog,
  () => {
    if (dialog.value) {
      return
    }

    emit('model-closed')
  }
)
</script>

<style>

</style>
