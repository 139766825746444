<template>
  <q-dialog
    :model-value="props.modelValue"
    :position="$q.platform.is.mobile ? 'bottom' : 'standard'"
    :persistent="!$q.platform.is.mobile"
    @update:model-value="emit('update:model-value', $event)"
  >
    <q-card
      class="q-mt-md bg-blue-1"
      style="min-width: 400px; max-width: 600px; border-radius: 6px !important;"
    >
      <q-card-section class="text-h6 rounded-borders q-px-lg q-pt-lg">
        {{ props.title }}
      </q-card-section>

      <q-card-section class="q-pt-none q-px-lg white-bg-field">
        <div
          v-if="addressDetails"
          class="row q-col-gutter-x-md"
        >
          <q-input
            v-model="addressDetails.country"
            class="field-with-top-label col-md-6 col-xs-12 q-mb-sm"
            label="Страна"
            :disable="loading"
            outlined
            hide-bottom-space
            dense
          />
          <q-input
            v-model="addressDetails.region"
            class="field-with-top-label col-md-6 col-xs-12 q-mb-sm"
            label="Регион"
            :disable="loading"
            outlined
            hide-bottom-space
            dense
          />
          <q-input
            v-model="addressDetails.post_code"
            class="field-with-top-label col-md-6 col-xs-12 q-mb-sm"
            label="Индекс"
            :disable="loading"
            outlined
            hide-bottom-space
            dense
          />
          <q-input
            v-model="addressDetails.reign_type"
            class="field-with-top-label col-md-6 col-xs-12 q-mb-sm"
            label="Район (тип)"
            :disable="loading"
            outlined
            hide-bottom-space
            dense
          />
          <q-input
            v-model="addressDetails.reign"
            class="field-with-top-label col-md-6 col-xs-12 q-mb-sm"
            label="Район (название)"
            :disable="loading"
            outlined
            hide-bottom-space
            dense
          />
          <q-input
            v-model="addressDetails.city_type"
            class="field-with-top-label col-md-6 col-xs-12 q-mb-sm"
            label="Город (тип)"
            :disable="loading"
            outlined
            hide-bottom-space
            dense
          />
          <q-input
            v-model="addressDetails.city"
            class="field-with-top-label col-md-6 col-xs-12 q-mb-sm"
            label="Город (название)"
            :disable="loading"
            outlined
            hide-bottom-space
            dense
          />
          <q-input
            v-model="addressDetails.settlement_type"
            class="field-with-top-label col-md-6 col-xs-12 q-mb-sm"
            label="Населенный пункт (тип)"
            :disable="loading"
            outlined
            hide-bottom-space
            dense
          />
          <q-input
            v-model="addressDetails.settlement"
            class="field-with-top-label col-md-6 col-xs-12 q-mb-sm"
            label="Населенный пункт (название)"
            :disable="loading"
            outlined
            hide-bottom-space
            dense
          />
          <q-input
            v-model="addressDetails.street_type"
            class="field-with-top-label col-md-6 col-xs-12 q-mb-sm"
            label="Улица (тип)"
            :disable="loading"
            outlined
            hide-bottom-space
            dense
          />
          <q-input
            v-model="addressDetails.street"
            class="field-with-top-label col-md-6 col-xs-12 q-mb-sm"
            label="Улица (название)"
            :disable="loading"
            outlined
            hide-bottom-space
            dense
          />
          <q-input
            v-model="addressDetails.house"
            class="field-with-top-label col-md-6 col-xs-12 q-mb-sm"
            label="Дом"
            :disable="loading"
            outlined
            hide-bottom-space
            dense
          />
          <q-input
            v-model="addressDetails.block"
            class="field-with-top-label col-md-6 col-xs-12 q-mb-sm"
            label="Корпус / Строение (номер)"
            :disable="loading"
            outlined
            hide-bottom-space
            dense
          />
          <q-input
            v-model="addressDetails.block_type"
            class="field-with-top-label col-md-6 col-xs-12 q-mb-sm"
            label="Корпус / Строение (тип)"
            :disable="loading"
            outlined
            hide-bottom-space
            dense
          />
          <q-input
            v-model="addressDetails.flat"
            class="field-with-top-label col-md-6 col-xs-12 q-mb-sm"
            label="Квартира"
            :disable="loading"
            outlined
            hide-bottom-space
            dense
          />
        </div>
      </q-card-section>
      <q-card-actions
        class="q-pa-none"
        style="background: #D5EAFA;"
      >
        <div :class="{'row justify-end q-py-md q-px-sm full-width': true, 'column reverse': $q.platform.is.mobile}">
          <q-btn
            class="col-xs-12 col-sm-auto q-mb-sm"
            size="16px"
            padding="sm lg"
            label="Отмена"
            :loading="props.loading"
            unelevated
            no-caps
            @click="cancel"
          />
          <q-btn
            class="col-xs-12 col-sm-auto q-mb-sm"
            padding="sm lg"
            size="16px"
            color="blue-7"
            label="Сохранить"
            :loading="props.loading"
            unelevated
            no-caps
            @click="save"
          />
        </div>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
import {ref, watchEffect} from 'vue'
import {AddressDetails} from 'src/models/Applications/Form/ApplicationFormResponseData'
import {CloneHelper} from 'src/helpers/CloneHelper'
import {useQuasar} from 'quasar'

const props = defineProps<{
  modelValue: boolean,
  title: string,
  details: AddressDetails,
  loading: boolean,
}>()

const emit = defineEmits<{
  (e: 'update:model-value', value: boolean): void,
  (e: 'update:address-details', details: AddressDetails): void,
}>()

const $q = useQuasar()

const addressDetails = ref<AddressDetails>()

const save = () => {
  if (!addressDetails.value) {
    return
  }

  emit('update:address-details', addressDetails.value)
}

const cancel = () => {
  emit('update:address-details', props.details)
}

watchEffect(() => {
  addressDetails.value = CloneHelper.clone(props.details)
})
</script>

<style scoped lang="scss">

</style>
