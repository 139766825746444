<template>
  <q-btn
    v-if="helpPage"
    color="primary"
    icon="svguse:icons.svg?2#app-pencil-alt"
    round
    flat
    @click="open"
  />
  <q-btn
    v-else
    label="Добавить страницу"
    class="BgBlueBtn"
    icon-right="svguse:icons.svg?2#app-plus"
    unelevated
    no-caps
    @click="open"
  />
  <q-dialog
    v-model="dialog"
    full-width
  >
    <q-card class="modal-blue-background">
      <q-card-section
        class="modal-title row items-center q-pb-none q-pl-lg"
      >
        <div class="col">{{ helpPageCreateUpdateData.id ? 'Редактирование' : 'Создание новой' }} страницы</div>
      </q-card-section>
      <q-card-section class="q-pl-lg q-pr-lg">
        <q-form>
          <div class="row q-col-gutter-lg white-bg-field">
            <div class="col-12 q-mb-sm">
              <q-input
                v-model="helpPageCreateUpdateData.title"
                class="field-with-top-label"
                :loading="loading"
                label="Название"
                outlined
                hide-hint
                hide-bottom-space
                dense
              />
            </div>
            <div class="col-12 q-mb-sm">
              <span>Текст статьи</span>
              <q-editor
                v-model="helpPageCreateUpdateData.body"
                min-height="10rem"
                flat
                toolbar-bg="blue-7"
                toolbar-text-color="white"
                toolbar-toggle-color="yellow-8"
                :toolbar="[
              ['bold', 'italic', 'underline', 'left','center','right','justify','underline','strike','undo','redo', 'link'],
              [{
                label: $q.lang.editor.formatting,
                icon: $q.iconSet.editor.formatting,
                list: 'no-icons',
                options: ['p', 'h3', 'h4', 'h5', 'h6', 'code']
              }]
            ]"
              />
            </div>
          </div>
        </q-form>
      </q-card-section>
      <q-card-actions
        align="right"
        class="modal-actions text-primary q-pa-lg"
      >
        <q-btn
          v-close-popup
          :loading="loading"
          label="Отмена"
          class="q-pr-lg"
          style="padding: 17px 28px;"
          unelevated
          flat
          no-caps
          text-color="black"
        />
        <q-btn
          :loading="loading"
          label="Сохранить"
          color="primary"
          style="padding: 17px 28px;"
          unelevated
          icon-right="svguse:icons.svg?2#app-check"
          no-caps
          :disable="!helpPageCreateUpdateData.title || !helpPageCreateUpdateData.body"
          @click="handleSubmit()"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
import {useDialog} from 'src/uses/Dialogs/useDialog'
import {useCreateUpdateHelpPage} from 'src/uses/Settings/Help/useCreateUpdateHelpPage'
import {HelpPage} from 'src/models/Help/HelpPage'
import {onBeforeMount} from 'vue'
import {withDefaults} from 'vue/dist/vue'

const props = withDefaults(
  defineProps<{
    helpPage?: HelpPage | null,
    blockId?: string
  }>(),
  {
    helpPage: undefined,
    blockId: undefined,
  }
)

const emit = defineEmits<{
  (e: 'success'): void,
}>()

const createUpdateHelpPage = useCreateUpdateHelpPage()
const {
  helpPageCreateUpdateData,
  status,
  loading,
  create,
  update,
} = createUpdateHelpPage

const {dialog, show, hide} = useDialog()

const open = async () => {
  show()
}
const success = () => {
  emit('success')
  hide()
}

const handleSubmit = async () => {
  if (!props.blockId) {
    return
  }
  if (helpPageCreateUpdateData.value.id) {
    await update()
  } else {
    await create(props.blockId)
  }

  if (status.value === 200 || status.value === 201) {
    success()
  }
}

onBeforeMount(() => {
  if (!props.helpPage) {
    return
  }

  helpPageCreateUpdateData.value = props.helpPage
})

</script>
