import {ContractorsGuard} from 'src/router/quards/ContractorsGuard'

export const contractorsRouter = {
  path: '/contractors',
  component: () => import(/* webpackChunkName: "MainLayout" */ '../layouts/MainLayout.vue'),
  children: [
    {
      path: '',
      name: 'contractors-list',
      component: () => import(/* webpackChunkName: "MainLayout" */ '../views/Contractors/ContractorsView.vue'),
    },
  ],
  meta: {
    guards: [
      ContractorsGuard,
    ],
  },
}
