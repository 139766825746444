<template>
  <q-dialog
    :model-value="statusStore.bankRejectDialog || statusKikStore.bankRejectDialog"
    @update:model-value="statusStore.bankRejectDialog ? statusStore.bankRejectDialog = $event : statusKikStore.bankRejectDialog = $event"
  >
    <q-card
      class="q-mt-md bg-blue-1"
      style="min-width: 400px; max-width: 600px; border-radius: 4px !important;"
    >
      <q-card-section class="text-h6 rounded-borders">
        Отказ Банка
      </q-card-section>
      <q-card-section class="white-bg-field">
        <q-input
          v-model="comment"
          class="field-with-top-label q-mb-xl"
          label="Укажите причину отказа по заявке"
          maxlength="300"
          counter
          outlined
          hide-bottom-space
          dense
        />
      </q-card-section>
      <q-card-actions
        class="row justify-end q-py-md"
        style="background: #D5EAFA;"
      >
        <q-btn
          v-close-popup
          size="16px"
          padding="sm lg"
          label="Отмена"
          unelevated
          no-caps
        />
        <q-btn
          padding="sm lg"
          size="16px"
          color="blue-7"
          label="Сохранить"
          :disable="!comment"
          unelevated
          no-caps
          @click="onClick"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
import {useStatusStore} from 'src/stores/Status/useStatusStore'
import {ref, watchEffect} from 'vue'
import {useStatusKikStore} from 'stores/Status/useStatusKikStore'

const statusStore = useStatusStore()
const statusKikStore = useStatusKikStore()

const comment = ref<string>()

const onClick = () => {
  if (!comment.value) {
    return
  }

  if (statusStore.bankRejectDialog) {
    statusStore.onSaveBankReject(comment.value)
  }

  if (statusKikStore.bankRejectDialog) {
    statusKikStore.onSaveBankReject(comment.value)
  }
}

watchEffect(
  () => {
    if (!statusStore.bankRejectDialog) {
      comment.value = undefined
    }
  }
)

watchEffect(
  () => {
    if (!statusKikStore.bankRejectDialog) {
      comment.value = undefined
    }
  }
)
</script>
