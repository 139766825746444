import {defineStore} from 'pinia'
import {useQuasar} from 'quasar'
import {useLocalStorage} from 'src/uses/useLocalStorage'
import {computed, ref, watch, watchEffect} from 'vue'

interface SmartAppBannerData {
  closed: boolean
  hasApp: boolean
}

export const useSmartAppBannerStore = defineStore(
  'smart-app-banner',
  () => {
    const {
      getFromStorage,
      setToStorage,
      data,
    } = useLocalStorage<SmartAppBannerData>('smart-app-banner')

    const $q = useQuasar()
    const fallbackLink = 'https://play.google.com/store/apps/details?id=com.gosoblako.lk.app'

    const smartAppBannerData = ref<SmartAppBannerData>({
      closed: false,
      hasApp: true,
    })

    const smartAppDialog = computed(() => {
      return !smartAppBannerData.value.closed
        && !smartAppBannerData.value.hasApp
    })

    const init = async () => {
      if (!$q.platform.is.mobile
        || !$q.platform.is.android
        || $q.platform.is.nativeMobile
      ) {
        return
      }

      getFromStorage()
      if (smartAppBannerData.value.closed) {
        return
      }

      smartAppBannerData.value.hasApp = true
      // eslint-disable-next-line
      // @ts-ignore
      if (typeof navigator.getInstalledRelatedApps === 'function') {
        // eslint-disable-next-line
        // @ts-ignore
        if ((await navigator.getInstalledRelatedApps() || []).length === 0) {
          smartAppBannerData.value.hasApp = false
        }
      }
    }

    const close = () => {
      smartAppBannerData.value.closed = true
    }

    const install = () => {
      window.location.replace(fallbackLink)
    }

    setTimeout(init, 5000)

    watchEffect(() => {
      if (!data.value) {
        return
      }

      smartAppBannerData.value = data.value
    })

    watch(
      smartAppBannerData,
      () => {
        setToStorage(smartAppBannerData.value)
      },
      {
        deep: true,
      }
    )

    return {
      smartAppDialog,
      smartAppBannerData,
      close,
      install,
    }
  }
)
