<template>
  <div class="col-sm col-xs-12 bannerMainContent">
    <div>
      Заявка готова для подписания ЭЦП. <br>
      Для дальнейшей работы отправьте
      <copy-to-clipboard
        :text="headerInfo.client?.sign_url"
      >
        <q-btn
          class="text-link-button"
          label="ссылку"
          icon="svguse:icons.svg?2#app-document-duplicate"
          size="xs"
        />
      </copy-to-clipboard>
      клиенту.
      <q-btn
        class="text-link-button"
        label="Памятка по подписанию заявки"
        icon-right="svguse:icons.svg?2#app-download-bold"
        size="xs"
        target="_blank"
        href="https://drive.google.com/file/d/1Tf9nubz9UM9Iwh3eAjW5THz5LLY8S2k9/view?usp=drive_link"
      />
    </div>
  </div>
  <div
    v-if="props.headerInfo.type === ProductEnum.EBG && (isOperator() || isAgentManager() || isAgent())"
    class="bannerInfoBtnWrap"
  >
    <q-btn
      class="banner-btn"
      label="Изменить данные"
      icon-right="svguse:icons.svg?2#app-pencil-alt"
      color="primary"
      :disable="loading"
      :loading="loading"
      unelevated
      no-caps
      @click="changeStatus(
        {
          id: headerInfo.id,
          applicationStatus: ApplicationSubStatusEnum.completion,
          initiator: 'completion'
        },
        headerInfo.sub_status as ApplicationSubStatusEnum
      )"
    >
      <q-tooltip>
        <div class="row">
          <div class="col q-mr-xs">
            <q-icon
              name="svguse:icons.svg?2#app-exclamation"
              size="xs"
              color="orange"
            />
          </div>
          <div>
            Внимание!<br>
            При изменении данных заявка будет повторно отправлена на проверку
          </div>
        </div>
      </q-tooltip>
    </q-btn>
  </div>
  <div
    v-if="isMainAdmin() && props.headerInfo.integration.enabled"
    class="bannerInfoBtnWrap"
  >
    <q-btn
      class="banner-btn"
      label="Отправить в банк"
      color="primary"
      style="padding: 13px 23px"
      :disable="loading"
      :loading="loading"
      unelevated
      no-caps
      @click="changeStatus(
        {
          id: headerInfo.id,
          applicationStatus: ApplicationSubStatusEnum.client_signed,
          initiator: ApplicationSubStatusEnum.client_signed
        },
        headerInfo.sub_status as ApplicationSubStatusEnum
      )"
    />
  </div>
</template>

<script setup lang="ts">
import {computed} from 'vue'
import {ControlCenterHeader} from 'src/models/Applications/ControlCenter/ControlCenterHeader'
import {
  useApplicationControlCenterChangeStatusStore
} from 'src/stores/Applications/ControlCenter/ChangeStatus/useApplicationControlCenterChangeStatusStore'
import {ApplicationSubStatusEnum} from 'src/models/Applications/ApplicationSubStatusEnum'
import {useProfileStore} from 'src/stores/Login/useProfileStore'
import CopyToClipboard from 'src/components/CopyToClipboard.vue'
import {storeToRefs} from 'pinia'
import {ProductEnum} from 'src/models/Applications/ProductEnum'

const props = defineProps<{
  headerInfo: ControlCenterHeader
}>()
const headerInfo = computed(() => props.headerInfo)

const {
  changeStatus,
} = useApplicationControlCenterChangeStatusStore(headerInfo.value?.id)()
const {loading} = storeToRefs(useApplicationControlCenterChangeStatusStore(props.headerInfo.id)())

const {
  isOperator,
  isAgentManager,
  isAgent,
  isMainAdmin,
} = useProfileStore()
</script>
