import {ProductEnum} from 'src/models/Applications/ProductEnum'

const reportTypes: Record<ProductEnum, string> = {
  [ProductEnum.EBG]: 'ЭБГ',
  [ProductEnum.KIK]: 'Кредит',
  [ProductEnum.RKO]: 'РКО',
}

export class ReportTypeDescriptions {
  public static getReportTypeDescription (product: ProductEnum | null): string {
    if (!product) {
      return ''
    }

    return reportTypes[product] || product
  }
}
