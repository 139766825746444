<template>
  <div>
    <date-item :model-value="item.date"/>
  </div>
  <div
    style="width: 125px;"
    class="ellipsis-2-lines"
  >
    Заявка:
    <template v-if="props.onlyText">
      {{ item.number }}
    </template>
    <router-link
      v-else
      :to="to"
      style="white-space: normal"
      @click="sendEvent('Open_control_center_via_applications_number')"
    >
      {{ item.number }}
      <q-tooltip
        :delay="1000"
        max-width="300px"
      >
        {{ item.number }}
      </q-tooltip>
    </router-link>
  </div>
</template>

<script setup lang="ts">
import DateItem from 'src/components/DateItem.vue'
import {computed} from 'vue'
import {ApplicationsGridKikItem} from 'src/models/Applications/ApplicationsGridKikItem'
import {useGridKikChangeStatusAction} from 'src/uses/Grid/useGridKikChangeStatusAction'
import {usePosthogStore} from 'stores/Posthog/usePosthogStore'

const props = withDefaults(
  defineProps<{
    item: ApplicationsGridKikItem
    onlyText?: boolean
  }>(),
  {
    onlyText: false,
  }
)

const gridActions = useGridKikChangeStatusAction()
const {
  isDraft,
  isSystemRejected,
} = gridActions
const {sendEvent} = usePosthogStore()

const item = computed(() => props.item)

const to = computed(() => {
  return isDraft(item.value) || isSystemRejected(item.value)
    ? {
        name: 'application-item',
        params: {
          formId: item.value.multi_order_id,
        }
      }
    : {
        name: 'application-control-center',
        params: {
          appId: item.value.id,
          formId: item.value.form_id,
        }
      }
})
</script>
