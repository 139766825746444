import {useGetApi} from 'src/uses/Api/useGetApi'
import {ref} from 'vue'
import {BankInfo} from 'src/models/Banks/BankInfo'

export const useGetBankInfo = () => {
  const {
    get,
    response,
    status,
    loading,
    error,
    errors,
  } = useGetApi<BankInfo>()

  const bankInfo = ref<BankInfo>({
    title_full: '',
    title_short: '',
  })

  const getBankInfoByInn = async (inn: string) => {
    await get(`admin/bank/inn/${inn}`)
    if (status.value === 200 && response.value) {
      bankInfo.value = response.value
    }
  }

  return {
    bankInfo,
    loading,
    response,
    status,
    error,
    errors,
    getBankInfoByInn,
  }
}
