<template>
  <q-select
    v-model="selected"
    class="ReportClientFilter"
    :options="items"
    :loading="loading"
    :option-label="optionLabel"
    :option-value="optionValue"
    label="Клиент"
    hide-dropdown-icon
    outlined
    hide-bottom-space
    emit-value
    use-input
    multiple
    dense
    label-slot
    clearable
    @filter="onFilter"
  >
    <template
      #before-options
    >
      <q-list class="bg-blue-grey-1">
        <q-item
          v-for="opt in selected || []"
          :key="opt.id"
          clickable
          v-ripple
          @click="remove(opt)"
        >
          <q-item-section side>
            <q-checkbox
              v-model="selected"
              :val="opt"
            />
          </q-item-section>
          <q-item-section>
            <q-item-label>
              <div class="row items-center InnOption">
                <div class="text-grey-9 q-mr-sm">{{ opt.inn }}</div>
                <div class="text-grey-6">{{ opt.title }}</div>
              </div>
            </q-item-label>
          </q-item-section>
        </q-item>
      </q-list>
    </template>
    <template
      #option="{ itemProps, opt, selected, toggleOption }"
    >
      <q-item
        v-if="!isSelected(opt)"
        v-bind="itemProps"
      >
        <q-item-section side>
          <q-checkbox
            :model-value="selected"
            @update:model-value="toggleOption(opt)"
          />
        </q-item-section>
        <q-item-section>
          <q-item-label>
            <div class="row items-center InnOption">
              <div class="text-grey-9 q-mr-sm">{{ opt.inn }}</div>
              <div class="text-grey-6">{{ opt.title }}</div>
            </div>
          </q-item-label>
        </q-item-section>
      </q-item>
    </template>
  </q-select>
</template>

<script setup lang="ts">
import {useGetApi} from 'src/uses/Api/useGetApi'
import {onBeforeMount, ref, watch} from 'vue'
import {QSelect} from 'quasar'
import {ReportClient} from 'src/models/Profile/Reports/ReportClient'

const emit = defineEmits<{
  (e: 'update:model-value', employees: string[]): void,
}>()

const {
  loading,
  get,
  status,
  response,
} = useGetApi<{items: ReportClient[]}>()

const items = ref<ReportClient[]>([])
const selected = ref<ReportClient[]>([])

const loadClients = async (query = '') => {
  await get(
    'v1/admin/reports/clients',
    {
      'filter[name]': query,
    })

  if (status.value === 200 && response.value) {
    items.value = response.value.items
  }
}

const optionValue = (opt: ReportClient) => {
  return opt
}

const optionLabel = (opt: ReportClient) => {
  return opt.title
}

const onFilter = (
  input: string,
  update: (fn: () => void) => void
) => {
  update(async () => {
    await loadClients(input)
  })
}

const isSelected = (opt: ReportClient) => {
  return !!(selected.value || [])
    .find(item => item.id === opt.id)
}

const remove = (opt: ReportClient) => {
  selected.value = selected.value
    .filter(item => item.id !== opt.id)
}

onBeforeMount(loadClients)

watch(
  selected,
  () => {
    emit(
      'update:model-value',
      (selected.value || [])
        .map(item => item.inn)
    )
  },
  {
    deep: true,
  }
)
</script>

<style lang="scss">
.ReportClientFilter {
  .q-field__native {
    & > span {
      max-width: 65%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}
</style>
