import {SuretyGuarantor} from 'src/models/Applications/SuretyResponse'
import {usePutApi} from 'src/uses/Api/usePutApi'

export const useSuretyUpdate = (id: string) => {
  const {
    put,
    status,
    response,
    loading,
    error,
  } = usePutApi<unknown, {guarantors: SuretyGuarantor[]}>(true)

  const prepareDocs = async (guarantors: SuretyGuarantor[]) => {
    await put(
      `v1/control-center/${id}/guarantors`,
      {
        guarantors,
      }
    )
  }

  return {
    status,
    response,
    loading,
    error,
    prepareDocs,
  }
}
