<template>
  <q-btn
    label="Удалить период"
    icon-right="svguse:icons.svg?2#app-trash"
    class="FormItemDeletePeriod--btn"
    style="padding: 13px 16px;"
    unelevated
    no-caps
    @click="show"
  />
  <q-dialog
    v-model="dialog"
  >
    <q-card
      style="min-width: 400px; width: 100vw; max-width: 600px;"
      class="bg-blue-1"
    >
      <q-card-section class="text-h6 rounded-borders">
        Удаление перода
      </q-card-section>
      <q-card-section>
        <info-banner
          text="Вы уверены?"
          caption="Данные невозможно будет восстановить"
          icon="svguse:icons.svg?2#app-exclamation"
          color="red"
        />
      </q-card-section>
      <q-card-actions
        class="modal-actions row justify-end q-py-md"
        style="background: #D5EAFA;"
      >
        <q-btn
          size="16px"
          padding="sm lg"
          label="Отмена"
          :disable="loading"
          style="padding: 17px 28px;"
          unelevated
          no-caps
          v-close-popup
        />
        <q-btn
          padding="sm lg"
          size="16px"
          color="red"
          label="Удалить"
          icon-right="svguse:icons.svg?2#app-trash"
          :loading="loading"
          style="padding: 17px 28px;"
          unelevated
          no-caps
          @click="deleteData"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
import {useDialog} from 'src/uses/Dialogs/useDialog'
import InfoBanner from 'components/InfoBanner.vue'
import {useFormItemFinancePeriodDelete} from 'src/uses/FormEditor/Finance/useFormItemFinancePeriodDelete'

const props = defineProps<{
  formId: string,
  periodId: string,
}>()

const emit = defineEmits<{
  (e: 'change'): void,
}>()

const {
  dialog,
  show,
  hide,
} = useDialog()

const formItemFinancePeriodDelete = useFormItemFinancePeriodDelete()
const {
  status,
  loading,
  deletePeriod
} = formItemFinancePeriodDelete

const deleteData = async () => {
  if (!props.periodId) {
    emit('change')
    hide()

    return
  }
  await deletePeriod(props.formId, props.periodId)
  if (status.value === 200) {
    emit('change')
    hide()
  }
}

</script>
<style lang="scss">
.FormItemDeletePeriod--btn {
  background-color: #C7DFF1;
  padding: 10px 20px;
}
</style>
