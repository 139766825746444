import {defineStore, storeToRefs} from 'pinia'
import {useTokenStore} from 'src/stores/Login/useTokenStore'
import {ref, watch} from 'vue'
import {useGetApi} from 'src/uses/Api/useGetApi'
import {UserProfile} from 'src/models/User/UserProfile'
import {UserRoleEnum} from 'src/models/User/UserRoleEnum'
import {useRememberRouteForGuestStore} from 'stores/useRememberRouteForGuestStore'
import {useYMStore} from 'stores/useYMStore'

export const useProfileStore = defineStore('profile', () => {
  const rememberRouteForGuestStore = useRememberRouteForGuestStore()
  const YMStore = useYMStore()
  const tokenStore = useTokenStore()
  const {tokenData} = storeToRefs(tokenStore)
  const profile = ref<UserProfile>()
  const {response, status, get, loading} = useGetApi<UserProfile>()

  const isAgent = () => {
    return profile.value?.role === UserRoleEnum.Agent
  }

  const isHeadEmployee = () => {
    return profile.value?.role === UserRoleEnum.HeadEmployee
  }

  const isEmployee = () => {
    return profile.value?.role === UserRoleEnum.Employee
  }

  const isAdmin = () => {
    const adminRoles = [UserRoleEnum.Admin, UserRoleEnum.MiddleAdmin]
    return adminRoles.includes(profile.value?.role as never)
  }

  const isMainAdmin = () => {
    return profile.value?.role === UserRoleEnum.Admin
  }

  const isJuniorAdmin = () => {
    return profile.value?.role === UserRoleEnum.JuniorAdmin
  }

  const isBankEmployee = () => {
    return profile.value?.role === UserRoleEnum.BankEmployee
  }

  const isOperator = () => {
    return profile.value?.role === UserRoleEnum.Operator
  }

  const isAgentManager = () => {
    return profile.value?.role === UserRoleEnum.AgentManager
  }

  const isAgentOrEmployees = () => {
    if (!profile.value?.role) {
      return false
    }

    const roles = [
      UserRoleEnum.Agent,
      UserRoleEnum.Employee,
      UserRoleEnum.HeadEmployee,
    ]

    return roles.includes(profile.value?.role)
  }

  watch(
    tokenData,
    async () => {
      if (!tokenData.value) {
        profile.value = undefined

        return
      }

      await get('v1/profile')
      if (status.value !== 200 || !response.value) {
        return
      }

      profile.value = response.value
      YMStore.setUser(profile.value)
      rememberRouteForGuestStore.redirect()
    },
    {deep: true}
  )

  return {
    profile,
    loading,
    isAgent,
    isAdmin,
    isOperator,
    isAgentManager,
    isHeadEmployee,
    isEmployee,
    isBankEmployee,
    isJuniorAdmin,
    isAgentOrEmployees,
    isMainAdmin,
  }
})
