<template>
  <q-dialog
    :model-value="newDialog"
    @update:model-value="statusStore.newDialog ? statusStore.newDialog = $event : statusKikStore.newDialog = $event"
  >
    <q-card
      class="q-mt-md bg-blue-1"
      style="min-width: 400px; max-width: 600px; width: 100%; border-radius: 4px !important;"
    >
      <q-card-section class="text-h6 rounded-borders">
        Подписание документов
      </q-card-section>
      <q-card-section
        v-if="showSignChoice"
        class="bg-blue-grey-10 text-white row items-center q-py-lg"
      >
        <div class="rounded-borders q-pa-sm bg-blue-grey-9">
          <q-icon
            name="svguse:icons.svg?2#app-information-circle"
            size="sm"
            color="orange-6"
          />
        </div>
        <div class="col q-pl-sm text-wgite">
          Необходимо выбрать где клиент будет подписывать документы, а также указать ссылку или проверить список
          документов
        </div>
      </q-card-section>
      <q-card-section class="white-bg-field">
        <div v-if="showSignChoice">
          <q-toggle
            v-model="innerSign"
            class="q-mb-md"
            label="Подписание на платформе"
          />
        </div>
        <template v-if="showDocs">
          <div
            v-if="showSignChoice"
            class="bg-blue-grey-10 text-white row items-center q-pa-md rounded-borders"
          >
            <q-icon
              name="svguse:icons.svg?2#app-information-circle"
              size="sm"
              color="orange-6"
            />
            <div class="col q-pl-sm text-wgite">
              Проверьте список документов, которые будут подписаны клиентом
            </div>
          </div>
          <application-not-signed-documents
            v-if="showDocs"
            class="q-mb-lg"
            :not-signed-documents="notSignedDocuments"
          />
        </template>
        <q-input
          v-if="showLink"
          v-model="link"
          class="field-with-top-label q-mb-xl"
          label="Проверьте корректность ссылки. При необходимости измените её"
          maxlength="300"
          :error="v$.link.$error || !!urlError"
          :error-message="v$.link.$errors[0] ? 'Вы ввели некорректную ссылку. Просьба проверить' : urlError"
          counter
          outlined
          hide-bottom-space
          dense
          @blur="v$.$validate()"
        />
      </q-card-section>
      <q-card-actions
        class="row justify-end q-py-md"
        style="background: #D5EAFA;"
      >
        <q-btn
          v-close-popup
          size="16px"
          padding="sm lg"
          label="Отмена"
          unelevated
          no-caps
        />
        <q-btn
          padding="sm lg"
          size="16px"
          color="blue-7"
          label="Сохранить"
          :disable="v$.$invalid"
          unelevated
          no-caps
          @click="onClick"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
import {useStatusStore} from 'src/stores/Status/useStatusStore'
import {computed, ref, watch, watchEffect} from 'vue'
import {urlValidator, required} from 'src/utils/i18n-validators'
import useVuelidate from '@vuelidate/core'
import {useHeaderDataFromStore} from 'src/uses/Applications/ControlCenter/useHeaderDataFromStore'
import {useStatusKikStore} from 'stores/Status/useStatusKikStore'
import ApplicationNotSignedDocuments from 'components/Applications/ApplicationNotSignedDocuments.vue'
import {ControlCenterHeaderNotSignedDocument} from 'src/models/Applications/ControlCenter/ControlCenterHeader'
import {
  useApplicationControlCenterItemStore
} from 'stores/Applications/ControlCenter/useApplicationControlCenterItemStore'
import {storeToRefs} from 'pinia'
import {
  ApplicationFormDocument,
  ApplicationFormDocuments
} from 'src/models/Applications/Form/ApplicationFormResponseData'

const {
  getHeaderData
} = useHeaderDataFromStore()

const statusStore = useStatusStore()
const statusKikStore = useStatusKikStore()

const notSignedDocuments = ref<ControlCenterHeaderNotSignedDocument[]>([])

const showLink = ref(false)
const showDocs = ref(false)
const showSignChoice = ref(false)
const innerSign = ref(false)

const link = ref<string>()

const rules = computed(() => {
  const rules = {
    link: {},
    innerSign: {},
  }

  if (showSignChoice.value) {
    rules.innerSign = {
      required,
    }
  }

  if (showLink.value) {
    rules.link = {
      urlValidator,
      required,
    }
  }

  return rules
})

const newDialog = computed(() => statusStore.newDialog || statusKikStore.newDialog)
const urlError = computed(() => {
  if (statusKikStore.newDialog && !!statusKikStore.errors && !!statusKikStore.errors.signUrl) {
    return statusKikStore.errors.signUrl
  }

  if (statusStore.newDialog && !!statusStore.errors && !!statusStore.errors.signUrl) {
    return statusStore.errors.signUrl
  }

  return undefined
})

const v$ = useVuelidate(rules, {link, innerSign})

const onClick = async () => {
  v$.value.$validate()

  if (v$.value.$invalid) {
    return
  }

  const linkValue = showLink.value ? link.value : undefined
  const innerSignValue = showSignChoice.value ? innerSign.value : undefined

  if (statusStore.newDialog) {
    await statusStore.onSaveNew(linkValue, innerSignValue)
    if (statusStore.status === 200) {
      statusStore.hideNewDialog()
      v$.value.$reset()
    }

    return
  }

  await statusKikStore.onSaveNew(linkValue, innerSignValue)
  if (statusKikStore.status === 200) {
    statusKikStore.hideNewDialog()
    v$.value.$reset()
  }
}

const generateNotSignedDocuments = (allVisibleDocuments: ApplicationFormDocuments) => {
  return Object.values(allVisibleDocuments)
    .reduce<ApplicationFormDocument[]>(
      (result, value) => {
        return [
          ...result,
          ...value,
        ]
      },
      []
    )
    .filter(doc => {
      return !doc.sign_file_name
        && doc.file_date
        && doc.file_name
        && doc.title
    })
    .map(doc => ({
      date: doc.file_date || '',
      title: doc.title || '',
      file_name: doc.file_name || '',
    }))
}

watchEffect(
  () => {
    if (!statusStore.newDialog) {
      link.value = undefined
    }
  }
)

watchEffect(
  () => {
    if (!statusKikStore.newDialog) {
      link.value = undefined
    }
  }
)

watch(
  innerSign,
  () => {
    if (innerSign.value) {
      showLink.value = false
      showDocs.value = true

      return
    }

    showLink.value = true
    showDocs.value = false
  }
)

watch(
  newDialog,
  async () => {
    if (!newDialog.value || (!statusStore.lastStatusPayload && !statusKikStore.lastStatusPayload)) {
      return
    }

    const id = statusStore.newDialog
      ? (statusStore.lastStatusPayload || {}).id
      : (statusKikStore.lastStatusPayload || {}).id

    if (!id) {
      return
    }

    const header = await getHeaderData(id)

    if (!header) {
      return
    }

    link.value = header.bank.sign_url || ''
    if (header.product.inner_signing_enabled) {
      innerSign.value = true
      showSignChoice.value = true
    } else {
      showLink.value = true
      showDocs.value = false
      showSignChoice.value = false
      innerSign.value = false
    }

    const applicationControlCenterItemStore = useApplicationControlCenterItemStore(header.id)()
    const {
      visibleDocuments,
      item,
    } = storeToRefs(applicationControlCenterItemStore)

    const storeWasInitiatedInDialog = !item.value

    if (storeWasInitiatedInDialog) {
      await applicationControlCenterItemStore.load()
    }

    notSignedDocuments.value = generateNotSignedDocuments(visibleDocuments.value || {})

    if (storeWasInitiatedInDialog) {
      applicationControlCenterItemStore.$dispose()
      applicationControlCenterItemStore.$reset()
    }
  }
)
</script>
