import {defineStore} from 'pinia'
import {useGetApi} from 'src/uses/Api/useGetApi'
import {ref} from 'vue'
import {ReportEmployee} from 'src/models/Profile/Reports/ReportEmployee'
import {ReportEmployeesResponse} from 'src/models/Profile/Reports/ReportEmployeesResponse'

export const useEmployeeFilterSelectStore = defineStore('report-employee-filter-select', () => {
  const {
    loading,
    get,
    status,
    response,
  } = useGetApi<ReportEmployeesResponse>()

  const employees = ref<ReportEmployee[]>([])

  const fullName = (employee: ReportEmployee): string => {
    return `${employee.name_last} ${employee.name_last}`
  }

  const load = async () => {
    await get(
      'v1/reports/employees',
    )

    if (status.value === 200 && response && response.value) {
      employees.value = response.value.items?.map(employee => {
        return {
          ...employee,
          fullName: fullName(employee)
        }
      })
    }
  }

  return {
    loading,
    employees,
    load,
  }
})
