<template>
  <div class="col-sm col-xs-12 bannerMainContent">
    Заявка находится на рассмотрении в Банке. Вы получите уведомление о смене статуса
    <div v-if="showWaitingRequiredMessage" >
      Фактический статус заявки - "Требуется подпись". Нажмите здесь
      <q-btn
        class="text-link-button"
        label="здесь"
        size="xs"
        :loading="loading"
        @click="changeStatus(
        {
          id: headerInfo.id,
          applicationStatus: ApplicationSubStatusEnum.new,
          initiator: ApplicationSubStatusEnum.new
        },
        headerInfo.sub_status as ApplicationSubStatusEnum
      )"
      />
      для смены статуса
    </div>
  </div>
</template>
<script setup lang="ts">
import {computed} from 'vue'
import {ControlCenterHeader} from 'src/models/Applications/ControlCenter/ControlCenterHeader'
import {
  useApplicationControlCenterChangeStatusStore
} from 'src/stores/Applications/ControlCenter/ChangeStatus/useApplicationControlCenterChangeStatusStore'
import {storeToRefs} from 'pinia'
import {ApplicationSubStatusEnum} from 'src/models/Applications/ApplicationSubStatusEnum'
import {useProfileStore} from 'stores/Login/useProfileStore'

const props = defineProps<{ headerInfo: ControlCenterHeader }>()
const headerInfo = computed(() => props.headerInfo)

const {
  changeStatus,
} = useApplicationControlCenterChangeStatusStore(headerInfo.value?.id)()
const {loading} = storeToRefs(useApplicationControlCenterChangeStatusStore(props.headerInfo.id)())

const {
  isAdmin,
  isAgentManager
} = useProfileStore()

const showWaitingRequiredMessage = computed(() => {
  if (!(headerInfo.value.sub_status === ApplicationSubStatusEnum.in_bank && !headerInfo.value.integration.enabled)) {
    return false
  }

  return headerInfo.value.waiting_required && (isAdmin() || isAgentManager())
})
</script>
