<template>
  <q-btn
    icon-right="svguse:icons.svg?2#app-plus-1"
    class="text-blue-grey-5"
    color="for-blue-grey-5"
    style="padding: 12px 28px;"
    label="Добавить документ"
    no-caps
    unelevated
    @click="open"
  />
  <q-dialog
    v-model="dialog"
  >
    <q-card
      class="modal-blue-background"
      style="width: 700px; max-width: 80vw;"
    >
      <q-card-section>
        <div class="text-h6">
          Добавить документ
        </div>
      </q-card-section>
      <q-card-section>
        <q-form class="white-bg-field">
          <div class="row">
            <div class="col-12 q-mb-md">
              <q-input
                v-model="docName"
                label="Название файла в системе"
                outlined
                hide-hint
                hide-bottom-space
                dense
              />
            </div>
          </div>
          <div class="col-12">
            <q-list
              v-if="doc"
              class="q-mt-sm q-mb-sm"
            >
              <q-item
                class="news-document-item q-mt-xs"
              >
                <q-item-section avatar>
                  <q-icon
                    name="svguse:icons.svg?2#app-article-bold"
                    color="blue-grey-3"
                  />
                </q-item-section>
                <q-item-section class="overflow-hidden">{{ doc.name }}</q-item-section>
                <q-item-section side>
                  <q-icon
                    class="document-download-icon cursor-pointer"
                    name="svguse:icons.svg?2#app-trash"
                    color="blue-gray-3"
                    @click="removeDocument()"
                  />
                </q-item-section>
              </q-item>
            </q-list>
            <file-select-component
              v-else
              class="fit"
              accept=".PDF, .DOC"
              :max-file-size="2097152"
              @file:selected="onSelected"
            />
          </div>
        </q-form>
      </q-card-section>
      <q-card-actions
        align="right"
        class="text-primary modal-actions"
      >
        <q-btn
          v-close-popup
          label="Отмена"
          class="q-pr-lg"
          style="padding: 12px 28px;"
          unelevated
          flat
          no-caps
          text-color="black"
        />
        <q-btn
          label="Прикрепить"
          color="primary"
          style="padding: 12px 28px;"
          unelevated
          no-caps
          @click="handleSubmit()"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
import {watch, ref} from 'vue'
import {useDialog} from 'src/uses/Dialogs/useDialog'
import FileSelectComponent from 'components/FileSelectComponent.vue'

const props = defineProps<{
  modelValue: File | null,
  documentName: string
}>()

const emit = defineEmits<{
  (e: 'update:model-value', file: File | null): void,
  (e: 'update:document-name', name: string): void,
  (e: 'change'): void,
}>()

const {dialog, show, hide} = useDialog()

const open = async () => {
  show()
}

const doc = ref<File | null>(null)
const docName = ref('')

watch(
  props,
  () => {
    doc.value = props.modelValue
    docName.value = props.documentName
  },
  {immediate: true, deep: true}
)

const onSelected = (payload: File) => {
  doc.value = payload
}

const removeDocument = () => {
  doc.value = null
}

const handleSubmit = async () => {
  emit('update:model-value', doc.value)
  emit('update:document-name', docName.value)
  emit('change')
  hide()
}

</script>
