import {useDeleteApi} from 'src/uses/Api/useDeleteApi'

export const useDeleteHelpPage = () => {
  const {
    deleteRequest,
    response,
    status,
    loading,
    error,
    errors,
  } = useDeleteApi<never>()

  const deletePage = async (pageId: string) => {
    await deleteRequest(`admin/help/pages/${pageId}`)
  }

  return {
    loading,
    response,
    status,
    error,
    errors,
    deletePage,
  }
}
