import {defineStore} from 'pinia'
import {useGridFilters} from 'src/uses/Grid/useGridFilters'
import {EmployeesGridFilters} from 'src/models/Profile/Employees/EmployeesGridFilters'
import {ref} from 'vue'
import {UserRoleEnum} from 'src/models/User/UserRoleEnum'
import {UserRoles} from 'src/models/User/UserRoleDescriptions'

const defaultFilters: EmployeesGridFilters = {
  name: null,
  role: null,
}

const rolesForSelect = ref([
  {
    value: UserRoleEnum.Employee,
    label: UserRoles.getUserRoleDescription(UserRoleEnum.Employee)
  },
  {
    value: UserRoleEnum.HeadEmployee,
    label: UserRoles.getUserRoleDescription(UserRoleEnum.HeadEmployee)
  }
])

export const useEmployeeGridFiltersStore = defineStore('profile-employee-grid-filters', () => {
  const {
    filters,
    isDefaultFilterValue,
    clearable,
    getFiltersQuery,
    clear,
  } = useGridFilters(defaultFilters)

  return {
    filters,
    isDefaultFilterValue,
    clearable,
    getFiltersQuery,
    clear,
    rolesForSelect,
  }
})
