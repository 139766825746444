<template>
  <q-banner class="application-control-center-banner-info q-py-sm">
    <template #avatar>
      <div class="icon-banner-info">
        <q-icon
          name="svguse:icons.svg?2#app-exclamation-circle"
          size="sm"
          color="orange"
        />
      </div>
    </template>
    <div v-if="!headerInfo.integration.enabled && isAdmin()" class="row">
      <change-status-block
        :header-info="headerInfo"
      />
    </div>
    <div class="row q-col-gutter-x-md">
      <surety-block
        v-if="headerInfo.need_guarantors"
        :header-info="headerInfo"
      />
      <change-status-block
        v-else-if="isBankEmployee()"
        :header-info="headerInfo"
      />
      <template v-else>
        <commission-approval-block
          v-if="headerInfo.sub_status === ApplicationSubStatusEnum.commission_approval"
          :header-info="headerInfo"
        />
        <draft-status-block
          v-if="headerInfo.status === ApplicationStatusEnum.draft"
          :header-info="headerInfo"
        />
        <checking-status-block
          v-if="headerInfo.sub_status === ApplicationSubStatusEnum.checking"
          :header-info="headerInfo"
        />
        <completition-status-block
          v-if="headerInfo.sub_status === ApplicationSubStatusEnum.completion"
          :header-info="headerInfo"
        />
        <new-status-block
          v-if="headerInfo.sub_status === ApplicationSubStatusEnum.new"
          :header-info="headerInfo"
        />
        <risk-and-request-executed-statuses-block
          v-if="isRiskAndRequestExecutedStatuses(headerInfo.sub_status as ApplicationSubStatusEnum)"
          :header-info="headerInfo"
        />
        <guarantee-released-status-block
          v-if="headerInfo.sub_status === ApplicationSubStatusEnum.guarantee_released"
          :header-info="headerInfo"
        />
        <data-request-status-block
          v-if="isDataRequestStatuses(headerInfo.sub_status as ApplicationSubStatusEnum)"
          :header-info="headerInfo"
        />
        <bg-project-preparation-status-block
          v-if="headerInfo.sub_status === ApplicationSubStatusEnum.bg_project_preparation"
        />
        <bg-project-bank-agreement-status-block
          v-if="headerInfo.sub_status === ApplicationSubStatusEnum.bg_project_bank_agreement"
        />
        <client-rejected-status-block
          v-if="headerInfo.sub_status === ApplicationSubStatusEnum.client_rejected"
          :header-info="headerInfo"
        />
        <bank-rejected-status-block
          v-if="headerInfo.sub_status === ApplicationSubStatusEnum.bank_rejected"
          :header-info="headerInfo"
        />
        <bg-project-customer-agreement-status-block
          v-if="headerInfo.sub_status === ApplicationSubStatusEnum.bg_project_customer_agreement"
          :header-info="headerInfo"
          :documents="documents"
        />
        <verification-statuses-block
          v-if="isVerificationStatuses(headerInfo.sub_status as ApplicationSubStatusEnum)"
          :header-info="headerInfo"
        />
        <release-waiting-statuses-block v-if="isReleaseWaitingStatuses(headerInfo.sub_status as ApplicationSubStatusEnum)"/>
        <sign-statuses-block
          v-if="isSignStatuses(headerInfo.sub_status as ApplicationSubStatusEnum)"
          :header-info="headerInfo"
        />
        <duplication-block
          v-if="headerInfo.duplication_enabled"
          :header-info="headerInfo"
        />
        <wating-required-block
          v-if="headerInfo.sub_status === ApplicationSubStatusEnum.waiting_required"
          :header-info="headerInfo"
        />
      </template>
    </div>
  </q-banner>
</template>

<script setup lang="ts">
import {computed} from 'vue'
import {ControlCenterHeader} from 'src/models/Applications/ControlCenter/ControlCenterHeader'
import {ApplicationStatusEnum} from 'src/models/Applications/ApplicationStatusEnum'
import DraftStatusBlock
  from 'src/components/Applications/ApplicationControlCenter/InfoAndActionBlocks/DraftStatusBlock.vue'
import CheckingStatusBlock
  from 'src/components/Applications/ApplicationControlCenter/InfoAndActionBlocks/CheckingStatusBlock.vue'
import {ApplicationSubStatusEnum} from 'src/models/Applications/ApplicationSubStatusEnum'
import CompletitionStatusBlock
  from 'src/components/Applications/ApplicationControlCenter/InfoAndActionBlocks/CompletitionStatusBlock.vue'
import NewStatusBlock
  from 'src/components/Applications/ApplicationControlCenter/InfoAndActionBlocks/NewStatusBlock.vue'
import {
  useApplicationControlCenterStatuses
} from 'src/uses/Applications/ControlCenter/useApplicationControlCenterStatuses'
import RiskAndRequestExecutedStatusesBlock
  from 'src/components/Applications/ApplicationControlCenter/InfoAndActionBlocks/RiskAndRequestExecutedStatusesBlock.vue'
import GuaranteeReleasedStatusBlock
  from 'src/components/Applications/ApplicationControlCenter/InfoAndActionBlocks/GuaranteeReleasedStatusBlock.vue'
import DataRequestStatusBlock
  from 'src/components/Applications/ApplicationControlCenter/InfoAndActionBlocks/DataRequestStatusBlock.vue'
import BgProjectPreparationStatusBlock
  from 'src/components/Applications/ApplicationControlCenter/InfoAndActionBlocks/BgProjectPreparationStatusBlock.vue'
import BgProjectBankAgreementStatusBlock
  from 'src/components/Applications/ApplicationControlCenter/InfoAndActionBlocks/BgProjectBankAgreementStatusBlock.vue'
import ClientRejectedStatusBlock
  from 'src/components/Applications/ApplicationControlCenter/InfoAndActionBlocks/ClientRejectedStatusBlock.vue'
import BankRejectedStatusBlock
  from 'src/components/Applications/ApplicationControlCenter/InfoAndActionBlocks/BankRejectedStatusBlock.vue'
import BgProjectCustomerAgreementStatusBlock
  from 'src/components/Applications/ApplicationControlCenter/InfoAndActionBlocks/BgProjectCustomerAgreementStatusBlock.vue'
import {ApplicationFormDocuments} from 'src/models/Applications/Form/ApplicationFormResponseData'
import VerificationStatusesBlock
  from 'src/components/Applications/ApplicationControlCenter/InfoAndActionBlocks/VerificationStatusesBlock.vue'
import ReleaseWaitingStatusesBlock
  from 'src/components/Applications/ApplicationControlCenter/InfoAndActionBlocks/ReleaseWaitingStatusesBlock.vue'
import SignStatusesBlock
  from 'src/components/Applications/ApplicationControlCenter/InfoAndActionBlocks/SignStatusesBlock.vue'
import {useProfileStore} from 'src/stores/Login/useProfileStore'
import SuretyBlock from 'src/components/Applications/ApplicationControlCenter/InfoAndActionBlocks/SuretyBlock.vue'
import ChangeStatusBlock
  from 'components/Applications/ApplicationControlCenter/InfoAndActionBlocks/ChangeStatusBlock.vue'
import CommissionApprovalBlock
  from 'components/Applications/ApplicationControlCenter/InfoAndActionBlocks/CommissionApprovalBlock.vue'
import DuplicationBlock from 'components/Applications/ApplicationControlCenter/InfoAndActionBlocks/DuplicationBlock.vue'
import WatingRequiredBlock
  from 'components/Applications/ApplicationControlCenter/InfoAndActionBlocks/WatingRequiredBlock.vue'

const props = defineProps<{
  headerInfo: ControlCenterHeader,
  documents: ApplicationFormDocuments | null,
}>()

const {
  isBankEmployee,
  isAdmin,
} = useProfileStore()

const headerInfo = computed(() => props.headerInfo)
const documents = computed(() => props.documents)

const {
  isRiskAndRequestExecutedStatuses,
  isDataRequestStatuses,
  isVerificationStatuses,
  isReleaseWaitingStatuses,
  isSignStatuses,
} = useApplicationControlCenterStatuses()
</script>

<style lang="scss">
.application-control-center-banner-info {
  background: #263238;
  color: #ffffff;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  padding: 20px 30px;

  .icon-banner-info {
    background: #37474F;
    border-radius: 5px;
    padding: 12px;
  }

  .banner-btn {
    padding: 10px 20px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
  }
}
</style>
