import {usePutApi} from 'src/uses/Api/usePutApi'
import {computed, ref} from 'vue'
import {EgrulSourceEnum} from 'src/models/Enums/EgrulSourceEnum'
import {RntSourceEnum} from 'src/models/Enums/RntSourceEnum'

export interface EgrulRequestData {
  inn: string
  source: EgrulSourceEnum | null
}

export interface RntRequestData {
  rnt: string
  lot: number
  source: RntSourceEnum | null
}

const egrulSourcesTitles: Record<EgrulSourceEnum, string> = {
  [EgrulSourceEnum.KONTUR]: 'Контур',
  [EgrulSourceEnum.TL]: 'TenderLand',
}

const rntSourcesTitles: Record<RntSourceEnum, string> = {
  [RntSourceEnum.SELDON]: 'Seldon',
  [RntSourceEnum.TL]: 'TenderLand',
}

export const useSettingsRequestData = () => {
  const {
    put,
    response,
    status,
    loading,
    error,
    errors,
  } = usePutApi<unknown, EgrulRequestData | RntRequestData>(true)

  const egrulRequestData = ref<EgrulRequestData>({
    inn: '',
    source: null,
  })

  const rntRequestData = ref<RntRequestData>({
    rnt: '',
    lot: 1,
    source: null,
  })

  const egruleSources = () => {
    return Object.values(EgrulSourceEnum).map(source => {
      return {
        label: egrulSourcesTitles[source],
        value: source
      }
    })
  }

  const rntSources = () => {
    return Object.values(RntSourceEnum).map(source => {
      return {
        label: rntSourcesTitles[source],
        value: source
      }
    })
  }

  const isValidEgrul = computed(() => {
    return egrulRequestData.value.source && egrulRequestData.value.inn
  })

  const isValidRnt = computed(() => {
    return rntRequestData.value.source && rntRequestData.value.rnt && rntRequestData.value.lot
  })

  const changeRntRequest = async () => {
    await put(
      `admin/cache/purchase/${rntRequestData.value.source}/${rntRequestData.value.rnt}/${rntRequestData.value.lot}`
    )
  }

  const changeEgrulRequest = async () => {
    await put(`admin/cache/egrul/${egrulRequestData.value.source}/${egrulRequestData.value.inn}`)
  }

  return {
    loading,
    response,
    status,
    error,
    errors,

    rntRequestData,
    egrulRequestData,
    isValidEgrul,
    isValidRnt,
    changeRntRequest,
    changeEgrulRequest,
    egruleSources,
    rntSources,
  }
}
