import {
  ApplicationFormResponseData
} from 'src/models/Applications/Form/ApplicationFormResponseData'
import {Ref, computed} from 'vue'
import {ProvisionTypeEnum} from 'src/models/Applications/ProvosionTypeEnum'
import {FieldsCodesEnum} from 'src/models/FieldsCodesEnum'
import {useGetFieldByCode} from 'src/uses/Applications/Form/useGetFieldByCode'
import moment from 'moment/moment'
import {LawTypeEnum} from 'src/models/Applications/LawTypeEnum'
import {CreditTypeEnum} from 'src/models/Applications/CreditTypeEnum'

export const useImportantFields = (item: Ref<ApplicationFormResponseData | undefined>) => {
  const {
    getFieldByCode,
  } = useGetFieldByCode(item)

  const provisionTypeField = computed(() => {
    return getFieldByCode(FieldsCodesEnum.DEAL_GUARANTEE_TYPE)
  })

  const provisionType = computed(() => {
    return Object.values(ProvisionTypeEnum)
      .find(e => e === (provisionTypeField.value || {})?.value || '')
  })

  const rntField = computed(() => {
    return getFieldByCode(FieldsCodesEnum.DEAL_RNT)
  })

  const lawField = computed(() => {
    return getFieldByCode(FieldsCodesEnum.DEAL_LAW)
  })

  const lotField = computed(() => {
    return getFieldByCode(FieldsCodesEnum.DEAL_LOT)
  })

  const ikzField = computed(() => {
    return getFieldByCode(FieldsCodesEnum.DEAL_PURCHASE_IDENTITY_CODE)
  })

  const isPurchaseClosedField = computed(() => {
    return getFieldByCode(FieldsCodesEnum.DEAL_IS_PURCHASE_CLOSED)
  })

  const bgSumField = computed(() => {
    return provisionType.value === ProvisionTypeEnum.Participation
      ? getFieldByCode(FieldsCodesEnum.DEAL_GUARANTEE_AMOUNT_TENDER)
      : getFieldByCode(FieldsCodesEnum.DEAL_GUARANTEE_AMOUNT)
  })

  const kikSumIssuedField = computed(() => {
    return getFieldByCode(FieldsCodesEnum.DEAL_CREDIT_AMOUNT_ISSUED)
  })

  const kikMonthsApprovedField = computed(() => {
    return getFieldByCode(FieldsCodesEnum.DEAL_CREDIT_MONTHS_APPROVED)
  })

  const kikSumApprovedField = computed(() => {
    return getFieldByCode(FieldsCodesEnum.DEAL_CREDIT_AMOUNT_APPROVED)
  })

  const kikMonthsIssuedField = computed(() => {
    return getFieldByCode(FieldsCodesEnum.DEAL_CREDIT_MONTHS_ISSUED)
  })

  const kikSumField = computed(() => {
    return getFieldByCode(FieldsCodesEnum.DEAL_CREDIT_AMOUNT)
  })

  const kikMonthsField = computed(() => {
    return getFieldByCode(FieldsCodesEnum.DEAL_CREDIT_MONTHS)
  })

  const kikTypeField = computed(() => {
    return getFieldByCode(FieldsCodesEnum.DEAL_CREDIT_TYPE)
  })

  const kikCreditLineField = computed(() => {
    return getFieldByCode(FieldsCodesEnum.DEAL_CREDIT_LINE)
  })

  const startDateField = computed(() => {
    return provisionType.value === ProvisionTypeEnum.Participation
      ? getFieldByCode(FieldsCodesEnum.DEAL_GUARANTEE_START_DATE_TENDER)
      : getFieldByCode(FieldsCodesEnum.DEAL_GUARANTEE_STARTDATE)
  })

  const endDateField = computed(() => {
    return provisionType.value === ProvisionTypeEnum.Participation
      ? getFieldByCode(FieldsCodesEnum.DEAL_GUARANTEE_FINISH_DATE_TENDER)
      : getFieldByCode(FieldsCodesEnum.DEAL_GUARANTEE_FINISHDATE)
  })

  const startFromReleaseDateField = computed(() => {
    return getFieldByCode(FieldsCodesEnum.DEAL_START_FROM_RELEASE_DATE)
  })

  const startDate = computed<moment.Moment | false>(() => {
    const value = startDateField.value ? startDateField.value.value : ''

    return value ? moment(value.toString(), 'DD.MM.YYYY') : false
  })

  const endDate = computed<moment.Moment | false>(() => {
    const value = endDateField.value ? endDateField.value.value : ''

    return value ? moment(value.toString(), 'DD.MM.YYYY') : false
  })

  const countDays = computed(() => {
    if (!startDate.value || !endDate.value) {
      return undefined
    }

    return endDate.value.diff(startDate.value, 'day') + 1
  })

  const issueFormField = computed(() => {
    return getFieldByCode(FieldsCodesEnum.DEAL_GUARANTEE_ISSUE_FORM)
  })

  const issueMailHardCopyField = computed(() => {
    return getFieldByCode(FieldsCodesEnum.DEAL_GUARANTEE_ISSUE_FORM_MAILHARDCOPY)
  })

  const bg1397Field = computed(() => {
    return getFieldByCode(FieldsCodesEnum.DEAL_BG1397)
  })

  const nmcField = computed(() => {
    return getFieldByCode(FieldsCodesEnum.DEAL_DECLINE)
  })

  const law = computed<LawTypeEnum | undefined>(() => {
    const value = lawField.value ? lawField.value.value : ''

    return Object.values(LawTypeEnum).find(e => e.toString() === value)
  })

  const kikType = computed<CreditTypeEnum | undefined>(() => {
    const value = kikTypeField.value ? kikTypeField.value.value : ''

    return Object.values(CreditTypeEnum).find(e => e.toString() === value)
  })

  return {
    provisionType,
    provisionTypeField,
    rntField,
    lotField,
    bgSumField,
    lawField,
    law,
    kikType,
    startDateField,
    endDateField,
    startDate,
    endDate,
    countDays,
    issueFormField,
    issueMailHardCopyField,
    bg1397Field,
    nmcField,
    startFromReleaseDateField,
    ikzField,
    isPurchaseClosedField,
    kikSumField,
    kikMonthsField,
    kikTypeField,
    kikCreditLineField,
    kikSumIssuedField,
    kikMonthsIssuedField,
    kikMonthsApprovedField,
    kikSumApprovedField,
  }
}
