<template>
  <q-dialog
    v-model="dialog"
    position="top"
    seamless
    no-refocus
    no-focus
  >
    <div
      class="bg-white q-py-sm q-px-md shadow-1"
      style="margin-top: 55px; max-width: 80vw"
    >
      <div>
        <slot name="default" />
      </div>
    </div>
  </q-dialog>
</template>

<script setup lang="ts">
import {onBeforeUnmount, onMounted, ref} from 'vue'

const props = defineProps<{
  top: number,
}>()

const dialog = ref(false)

const onScroll = () => {
  dialog.value = window.scrollY > props.top
}

onMounted(() => {
  window.addEventListener('scroll', onScroll)
})

onBeforeUnmount(() => {
  window.removeEventListener('scroll', onScroll)
})
</script>

<style scoped lang="scss">

</style>
