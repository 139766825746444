import {ref} from 'vue'
import {useGetApi} from 'src/uses/Api/useGetApi'
import {UserItem} from 'src/models/Profiles/Users/UserItem'
import {UserStatusEnum} from 'src/models/User/UserStatusEnum'

export const useUserItem = () => {
  const {
    get,
    response,
    status,
    loading,
    error,
    errors,
  } = useGetApi<UserItem>()

  const userResponseData = ref<UserItem>({
    id: '',
    first_name: '',
    middle_name: '',
    last_name: '',
    email: '',
    phone: '',
    date: '',
    role: '',
    status: '',
    activated: ''
  })

  const load = async (id: number) => {
    await get(`admin/user/${id}`)
    if (status.value === 200 && response.value) {
      userResponseData.value = response.value
    }
  }

  const getUserRequestData = () => {
    userResponseData.value.user_is_active = userResponseData.value?.status === UserStatusEnum.Active
    return userResponseData.value
  }

  return {
    userResponseData,
    loading,
    response,
    status,
    error,
    errors,
    load,
    getUserRequestData
  }
}
