<template>
  <q-layout
    v-if="data"
    view="hHh LpR lff"
    @resize="onResize"
  >
    <q-header class="bg-white text-grey-10">
      <q-toolbar>
        <q-btn
          icon="svguse:icons.svg?2#app-menu-bold"
          color="blue-grey-4"
          flat
          round
          dense
          @click="onMenu()"
        />
        <q-toolbar-title>
          <div class="row justify-center">
            <div :class="{col: $q.platform.is.mobile}">
              <router-link :to="{name: 'main'}">
                <img
                  :src="data.logo"
                  style="max-width: 151px; max-height: 24px; margin-top: 6px;"
                >
              </router-link>
            </div>
            <div
              v-if="!$q.platform.is.mobile"
              class="col q-px-md"
            />
            <div>
              <authed-or-enter>
                <q-btn
                  v-if="hasRightSidebar"
                  icon="svguse:icons.svg?2#app-chevron-double-left-bold"
                  color="blue-grey-4"
                  flat
                  round
                  dense
                  @click="rightDrawer = !rightDrawer;"
                />
              </authed-or-enter>
            </div>
          </div>
        </q-toolbar-title>
      </q-toolbar>
    </q-header>

    <q-drawer
      v-model="drawer"
      class="bg-blue-grey-10"
      :mini="miniState"
      :width="drawerWidth"
      :breakpoint="650"
      show-if-above
      no-swipe-open
      no-swipe-close
      no-swipe-backdrop
      @click="drawerClick"
    >
      <template #mini>
        <q-scroll-area class="fit">
          <new-application-button
            :mini="true"
            @click.stop.prevent
          />
          <left-menu :mini="true"/>
        </q-scroll-area>
      </template>

      <q-scroll-area class="fit">
        <new-application-button :mini="false"/>
        <left-menu :mini="false"/>
      </q-scroll-area>
    </q-drawer>

    <router-view name="rightSidebar"/>

    <q-page-container>
      <q-page
        class="MainLayoutPage"
        style="max-width: 1440px; margin: 0 auto;"
        :padding="false"
      >
        <pull-to-refresh-wrapper>
          <div :style="{'padding-bottom': $q.platform.is.mobile ? '70px' : 0}">
            <router-view :key="`${JSON.stringify($route.params)} ${$route.name as string}`"/>
          </div>
          <q-resize-observer
            :debounce="700"
            @resize="onContentResize"
          />
          <q-page-sticky
            style="z-index: 3;"
            position="bottom"
            expand
          >
            <div
              v-if="$q.platform.is.mobile"
              class="mobileBottomMenu bg-blue-grey-10 qpa-md full-width row no-wrap"
            >
              <router-link
                v-if="clientsLink"
                class="text-white col"
                :to="clientsLink.to"
              >
                <q-icon
                  :name="clientsLink.icon"
                  color="blue-grey-4"
                  size="md"
                />
                <div>{{ clientsLink.label }}</div>
              </router-link>
              <router-link
                v-if="applicationsLink"
                class="text-white col"
                :to="applicationsLink.to"
              >
                <q-icon
                  :name="applicationsLink.icon"
                  size="md"
                  color="blue-grey-4"
                />
                <div>{{ applicationsLink.label }}</div>
              </router-link>

              <q-btn
                :to="{name: 'applications-new'}"
                class="text-white"
                style="margin-bottom: 45px; margin-top: -10px; border-radius: 20px"
                color="blue-7"
                size="13px"
                padding="0"
                dense
                unelevated
              >
                <q-icon
                  name="svguse:icons.svg#app-plus-bold-1"
                  color="white"
                  size="30px"
                  style="padding: 10px;"
                />
              </q-btn>

              <router-link
                v-if="newsLink"
                class="text-white col"
                :to="newsLink.to"
              >
                <q-icon
                  :name="newsLink.icon"
                  size="md"
                  color="blue-grey-4"
                />
                <div>{{ newsLink.label }}</div>
              </router-link>
              <router-link
                v-if="helpLink"
                class="text-white col"
                :to="helpLink.to"
              >
                <q-icon
                  :name="helpLink.icon"
                  size="md"
                  color="blue-grey-4"
                />
                <div>{{ helpLink.label }}</div>
              </router-link>
            </div>
          </q-page-sticky>
        </pull-to-refresh-wrapper>
      </q-page>
    </q-page-container>
  </q-layout>
</template>

<script setup lang="ts">
import LeftMenu from 'components/Menu/LeftMenu.vue'
import NewApplicationButton from 'src/components/Applications/NewApplicationButton.vue'
import AuthedOrEnter from 'src/components/AuthedOrEnter.vue'
import {useLayoutStore} from 'src/stores/useLayoutStore'
import {storeToRefs} from 'pinia'
import {useMenuStore} from 'src/stores/useMenuStore'
import {computed/*, onMounted*/, onUnmounted} from 'vue'
import {useQuasar} from 'quasar'
import {useRoute} from 'vue-router'
import PullToRefreshWrapper from 'components/PullToRefreshWrapper.vue'
import {useIntegratorStore} from 'stores/useIntegratorStore'
//import {initSnow} from '../snow'

const menuStore = useMenuStore()
const {getMenuByName} = menuStore
const layoutStore = useLayoutStore()
const {
  miniState,
  drawer,
  drawerWidth,
  rightDrawer,
} = storeToRefs(layoutStore)
const {
  onResize,
  onContentResize,
  drawerClick,
  onMenu,
} = layoutStore

const {data} = storeToRefs(useIntegratorStore())

const $q = useQuasar()

const route = useRoute()

const matched = computed(() => {
  return route.matched.slice(-1)[0]
})

const hasRightSidebar = computed(() => {
  if (!matched.value || !matched.value.components) {
    return false
  }

  return Object.keys(matched.value.components).includes('rightSidebar')
})

const clientsLink = computed(() => getMenuByName('clients-list'))
const applicationsLink = computed(() => getMenuByName('applications'))
const newsLink = computed(() => getMenuByName('news'))
const helpLink = computed(() => getMenuByName('help-page'))

/*onMounted(() => {
  initSnow(3)
})*/

onUnmounted(() => {
  menuStore.$dispose()
  menuStore.$reset()
  layoutStore.$dispose()
  layoutStore.$reset()
})
</script>

<style lang="scss">
.MainLayoutPage {
  color: $grey-9;

  .mobileBottomMenu {
    a {
      text-align: center;
      padding: 8px 8px 30px;

      &:hover {
        text-decoration: none;
      }

      &.router-link-active {
        .q-icon {
          color: white !important;
        }
      }

      div {
        font-size: 10px;
      }
    }
  }
}
</style>
