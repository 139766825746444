import {useGetApi} from 'src/uses/Api/useGetApi'
import {BankItem} from 'src/models/Banks/BankItem'
import {computed, ref} from 'vue'
import {defineStore} from 'pinia'

export const useBankEmployeeBankListStore = defineStore('bank-employee-banks-list', () => {
  const {
    loading,
    get,
    status,
    response,
  } = useGetApi<BankItem[]>()

  const banks = ref<BankItem[]>([])
  const mappedBanks = computed(() => {
    return banks.value
      .map(bank => ({
        id: bank.id,
        title: bank.title_short,
      }))
  })

  const load = async () => {
    if (loading.value || banks.value.length) {
      return
    }

    await get(
      'admin/bank/list/all',
    )

    if (status.value === 200 && response && response.value) {
      banks.value = response.value
    }
  }

  return {
    loading,
    banks,
    mappedBanks,
    load,
  }
})
