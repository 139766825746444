<template>
  <form-group>
    <q-input
      ref="inputRef"
      v-model="formattedValue"
      class="field-with-top-label overflow-label-visible q-mb-sm"
      label="Сумма банковской гарантии"
      placeholder="0,00"
      debounce="700"
      :disable="isBlocked('bgSum')"
      outlined
      hide-bottom-space
      dense
      @blur="onBlur"
      @focus="onFocus"
    />
    <div style="min-height: 40px">
      <q-toggle
        v-model="nmcDiscount"
        :disable="isBlocked('nmcDiscount')"
        :label="`Снижение от НМЦ более ${law === LawTypeEnum.PP615 ? '20' : '25'}%`"
        class="q-mr-md"
        style="margin-left: -10px;"
        color="blue-7"
      />
    </div>
  </form-group>
</template>

<script setup lang="ts">
import FormGroup from 'src/components/FormGroup.vue'
import {storeToRefs} from 'pinia'
import {ref, watch, watchEffect} from 'vue'
import {useApplicationsNew} from 'src/stores/Applications/ApplicationsNew/useApplicationsNew'
import {QInput} from 'quasar'
import {LawTypeEnum} from 'src/models/Applications/LawTypeEnum'
import {useCurrencyInput} from 'src/uses/useCurrencyInput'

const applicationsNewStore = useApplicationsNew()
const {
  bgSum,
  law,
  nmcDiscount,
  lastFocused,
} = storeToRefs(applicationsNewStore)

const {
  numberValue,
  formattedValue,
  onBlur,
  onFocus,
} = useCurrencyInput()

const {
  isBlocked,
} = applicationsNewStore

const debounceTimeout = ref<number>()

const inputRef = ref<QInput>()

watch(
  lastFocused,
  () => {
    if (lastFocused.value !== 'bgSum' || !inputRef.value || !numberValue.value) {
      return
    }

    inputRef.value.focus()
    setTimeout(() => inputRef.value?.focus(), 500)
  }
)

watchEffect(
  () => {
    numberValue.value = bgSum.value
  }
)

watch(
  numberValue,
  () => {
    clearTimeout(debounceTimeout.value)
    debounceTimeout.value = setTimeout(
      () => {
        bgSum.value = numberValue.value || undefined
      },
      700
    ) as never as number
  }
)
</script>
