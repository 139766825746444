<template>
  <div class="q-pt-none ApplicationHeaderActions">
    <application-kik-control-center-additional-request-btn :header-info="props.headerData" />
    <q-btn
      v-if="showClientReject(props.headerData)"
      class="status-button float-left"
      label="Отказ клиента"
      icon-right="svguse:icons.svg?2#app-x-circle"
      :disable="loading"
      :loading="loading"
      unelevated
      no-caps
      @click="changeStatus(
        {
          id: props.headerData.id,
          applicationStatus: ApplicationKikSubStatusEnum.client_rejected,
          initiator: 'client_rejected'
        }
      )"
    />
    <div :class="classes">
      <div>{{ label(props.headerData.status as never as ApplicationKikStatusEnum) }}</div>
      <div class="flex justify-center">
        <q-icon
          v-if="isFilledDraft(props.headerData)"
          class="status-icon float-left"
          name="svguse:icons.svg?2#app-check-circle-bold"
          color="green"
        />
        <q-icon
          v-if="isShowOrangeIcon()"
          class="status-icon float-left"
          name="svguse:icons.svg?2#app-exclamation-circle-bold"
          color="orange"
        />
        <div class="date">{{ props.headerData.status_date }}</div>
        <q-tooltip
          v-if="props.stopFactors.length"
          class="bg-blue-grey-10 StopTooltip q-pa-md"
          :delay="700"
        >
          <stop-factors-result :model-value="props.stopFactors"/>
        </q-tooltip>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {computed} from 'vue'
import {ControlCenterHeader} from 'src/models/Applications/ControlCenter/ControlCenterHeader'
import {ApplicationFormDocuments,} from 'src/models/Applications/Form/ApplicationFormResponseData'
import {SelectBankFactor} from 'src/models/Applications/New/SelectBank'
import StopFactorsResult from 'src/components/Applications/StopFactorsResult.vue'
import {storeToRefs} from 'pinia'
import {
  useApplicationKikControlCenterChangeStatusStore
} from 'stores/Applications/ControlCenter/ChangeStatus/useApplicationKikControlCenterChangeStatusStore'
import {ApplicationKikSubStatusEnum} from 'src/models/Applications/ApplicationKikSubStatusEnum'
import {useKikStatus} from 'src/uses/Applications/useKikStatus'
import {ApplicationKikStatusEnum} from 'src/models/Applications/ApplicationKikStatusEnum'
import ApplicationKikControlCenterAdditionalRequestBtn
  from 'components/Applications/ApplicationControlCenter/ApplicationKikControlCenterAdditionalRequestBtn.vue'

const props = defineProps<{
  loading: boolean,
  headerData: ControlCenterHeader,
  documents: ApplicationFormDocuments | null,
  stopFactors: SelectBankFactor[],
}>()

const {
  showClientReject,
  changeStatus,
  isFilledDraft,
} = useApplicationKikControlCenterChangeStatusStore(props.headerData?.id)()
const {loading} = storeToRefs(useApplicationKikControlCenterChangeStatusStore(props.headerData?.id)())

const isShowOrangeIcon = () => {
  return props.headerData.new_request
}

const classes = computed(() => {
  return {
    ApplicationGridStatus: true,
    [`control-center-status control-center-status__without_btn float-left bg-${bgColor(props.headerData.sub_status as never as ApplicationKikSubStatusEnum)}`]: !showClientReject(props.headerData),
    [`text-${color(props.headerData.sub_status as never as ApplicationKikSubStatusEnum)}`]: true,
    [`control-center-status float-left bg-${bgColor(props.headerData.sub_status as never as ApplicationKikSubStatusEnum)}`]: showClientReject(props.headerData),
  }
})

const {
  label,
  bgColor,
  color,
} = useKikStatus()
</script>

<style lang="scss">
.q-btn.status-button {
  background-color: #ECEFF1;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  color: #90A4AE;
  height: 50px;

  &:hover {
    background-color: #FF5252;
    color: #FFFFFF;
  }
}

.q-btn.requestButton {
  background-color: #ECEFF1;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  color: #90A4AE;
  height: 50px;

  &:hover {
    background-color: $orange-7;
    color: #FFFFFF;
  }
}

.control-center-status {
  min-width: 185px;
  height: 50px;
  background-color: #E3F2FD;
  color: #1E88E5;
  padding: 10px 26px;
  text-align: center;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;

  &.control-center-status__without_btn {
    border-radius: 6px;
  }

  &.green-color-status {
    background-color: #E8F5E9;
    color: #00C853;
  }

  &.red-color-status {
    background-color: #FFEBEE;
    color: #FF5252;
  }

  &.grey-color-status {
    background-color: #F5F5F5;
    color: #90A4AE;
  }

  .status-icon {
    margin-top: 3px;
    margin-right: 3px;
  }

  .date {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    margin-top: 3px;
  }
}
</style>
