import {GridColumnEditableSettings} from 'src/models/Grid/GridColumnSettings'

export const EmployeesGridColumns: GridColumnEditableSettings[] = [
  {
    field: '',
    name: 'number',
    required: true,
    label: 'Номер',
    align: 'left',
    classes: 'text-no-wrap',
    visible: true,
    editable: false,
    order: 1,
  },
  {
    field: '',
    name: 'fio',
    required: true,
    label: 'ФИО',
    align: 'left',
    classes: 'text-no-wrap',
    visible: true,
    editable: true,
    order: 2,
    sortableColumn: true,
  },
  {
    field: '',
    name: 'email',
    required: true,
    label: 'E-mail',
    align: 'left',
    visible: true,
    editable: true,
    order: 3,
    sortableColumn: true,
  },
  {
    field: '',
    name: 'role',
    required: true,
    label: 'Роль',
    align: 'left',
    visible: true,
    editable: true,
    order: 4,
  },
  {
    field: '',
    name: 'date',
    required: true,
    label: 'Дата регистрации',
    align: 'left',
    visible: true,
    editable: true,
    order: 5,
    sortableColumn: true,
  },
  {
    field: '',
    name: 'status',
    label: 'Статус',
    required: true,
    align: 'center',
    visible: true,
    editable: true,
    order: 6,
  },
  {
    field: '',
    name: 'actions',
    label: '',
    required: true,
    align: 'center',
    visible: true,
    editable: false,
    order: 10,
  },
]
