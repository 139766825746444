import {defineStore} from 'pinia'
import {useLocalStorage} from 'src/uses/useLocalStorage'
import {computed, ref, watchEffect} from 'vue'

export enum EnvCodeEnum {
  VUE_APP_ENV_EDITABLE = 'VUE_APP_ENV_EDITABLE',
  VUE_APP_API_URL = 'VUE_APP_API_URL',
  VUE_APP_WEBSOCKET_URL = 'VUE_APP_WEBSOCKET_URL',
  VUE_APP_ZAKUPKI_URL = 'VUE_APP_ZAKUPKI_URL',
  VUE_APP_DADATA_URL = 'VUE_APP_DADATA_URL',
  VUE_APP_DADATA_KEY = 'VUE_APP_DADATA_KEY',
  VUE_APP_OLD_FRONT_HOST = 'VUE_APP_OLD_FRONT_HOST',
}

export const protectedEnv = [
  EnvCodeEnum.VUE_APP_ENV_EDITABLE,
  EnvCodeEnum.VUE_APP_WEBSOCKET_URL,
]

export const useEnvStore = defineStore(
  'env-store',
  () => {
    const {
      data,
      getFromStorage,
      setToStorage,
    } = useLocalStorage<Record<EnvCodeEnum, string>>('env')

    const env = ref<Record<EnvCodeEnum, string>>()

    const editable = computed(() => {
      if (!env.value) {
        return false
      }

      return getEnvValue(EnvCodeEnum.VUE_APP_ENV_EDITABLE) === 'true'
    })

    const getEnvValue = (code: EnvCodeEnum): undefined | string => {
      if (!env.value) {
        return undefined
      }

      return env.value[code]
    }

    const isEditable = (code: EnvCodeEnum) => {
      return env.value && editable.value && !protectedEnv.includes(code)
    }

    const setValue = (code: EnvCodeEnum, value: string, force = false) => {
      if (!env.value) {
        return
      }
      if (!force && !isEditable(code)) {
        return
      }

      env.value[code] = value
    }

    const setToDefault = () => {
      env.value = Object.fromEntries(
        Object.values(EnvCodeEnum)
          .map(code => {
            return [code, import.meta.env[code] as string]
          })
      ) as Record<EnvCodeEnum, string>
    }

    const init = () => {
      const envVersion = parseInt(localStorage.getItem('ENV_VERSION') || '0')
      if (envVersion < 3) {
        setToStorage(null)
      }
      localStorage.setItem('ENV_VERSION', '3')
      getFromStorage()
      env.value = Object.fromEntries(
        Object.values(EnvCodeEnum)
          .map(code => {
            const value = !protectedEnv.includes(code) && data.value && data.value[code]
              ? data.value[code]
              : import.meta.env[code] as string

            return [code, value]
          })
      ) as Record<EnvCodeEnum, string>
    }

    init()

    watchEffect(() => {
      if (!env.value) {
        return
      }

      env.value[EnvCodeEnum.VUE_APP_WEBSOCKET_URL] = env.value[EnvCodeEnum.VUE_APP_API_URL]
        .replace(/^.*:\/\//gi, 'wss://')

      setToStorage(env.value)
    })

    return {
      editable,
      env,
      getEnvValue,
      setValue,
      setToDefault,
      isEditable,
    }
  }
)
