<template>
  <q-dialog
    :model-value="smartAppDialog"
    position="bottom"
    backdrop-filter="grayscale(100%) blur(1px) saturate(150%)"
    full-width
    no-route-dismiss
    square
    seamless
  >
    <div style="border-radius: 0 !important">
      <div class="bg-black text-white q-pa-md row items-center">
        <div>
          <q-btn
            class="q-mr-sm"
            icon="svguse:icons.svg?2#app-x"
            size="md"
            dense
            unelevated
            @click="close"
          />
        </div>
        <img
          src="/icons/android-icon.webp"
          width="64"
          height="64"
        />
        <div class="col q-px-md">
          <strong>GOSOBLAKO</strong>
        </div>
        <div>
          <q-btn
            label="перейти"
            class="text-black"
            size="md"
            color="grey-1"
            unelevated
            rounded
            no-caps
            @click="install"
          />
        </div>
      </div>
    </div>
  </q-dialog>
</template>

<script setup lang="ts">
import {useSmartAppBannerStore} from 'stores/useSmartAppBannerStore'
import {storeToRefs} from 'pinia'

const smartAppBannerStore = useSmartAppBannerStore()
const {
  smartAppDialog,
} = storeToRefs(smartAppBannerStore)
const {
  close,
  install,
} = smartAppBannerStore
</script>

<style scoped lang="scss">

</style>
