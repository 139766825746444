import {AuthedGuard} from 'src/router/quards/AuthedGuard'

export const settingsRouter = {
  path: '/settings',
  component: () => import(/* webpackChunkName: "MainLayout" */ '../layouts/MainLayout.vue'),
  children: [
    {
      path: 'news',
      name: 'settings-news',
      component: () => import(/* webpackChunkName: "NewsView" */ '../views/Settings/NewsView.vue'),
      meta: {
        guards: [
          AuthedGuard(),
        ],
      },
    },
    {
      path: 'news/create',
      name: 'settings-news-create',
      component: () => import(/* webpackChunkName: "NewsCreateUpdateView" */ '../views/Settings/NewsCreateUpdateView.vue'),
      meta: {
        guards: [
          AuthedGuard(),
        ],
      },
    },
    {
      path: 'news/:newsId',
      name: 'settings-news-update',
      component: () => import(/* webpackChunkName: "NewsCreateUpdateView" */ '../views/Settings/NewsCreateUpdateView.vue'),
      meta: {
        guards: [
          AuthedGuard(),
        ],
      },
    },
    {
      path: 'reports',
      name: 'settings-reports',
      component: () => import(/* webpackChunkName: "ReportsView" */ '../views/Profile/ReportsView.vue'),
      meta: {
        guards: [
          AuthedGuard(),
        ],
      },
    },
    {
      path: 'help',
      name: 'settings-help',
      component: () => import(/* webpackChunkName: "NewsView" */ '../views/Settings/HelpView.vue'),
      meta: {
        guards: [
          AuthedGuard(),
        ],
      },
    },
    {
      path: 'request-data',
      name: 'settings-request-data',
      component: () => import(/* webpackChunkName: "NewsView" */ '../views/Settings/RequestDataView.vue'),
      meta: {
        guards: [
          AuthedGuard(),
        ],
      },
    },
  ]
}
