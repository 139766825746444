<template>
  <div class="col-sm col-xs-12 bannerMainContent">
    <div>
      Ознакомьтесь с дополнительным запросом в чате, при необходимости прикрепите <br>
      документы или измените параметры заявки
    </div>

    <div v-if="headerInfo.delivery_data_filling_enabled">
      Если вам нужно изменить адрес доставки гарантии нажмите
      <q-btn
        class="text-link-button"
        label="здесь"
        icon-right="svguse:icons.svg?2#app-arrow-right"
        size="xs"
        @click="showDialog()"
      />
    </div>
  </div>
  <div class="bannerInfoBtnWrap">
    <q-btn
      v-if="changeStatusPayload"
      class="banner-btn"
      label="Запрос исполнен"
      icon-right="svguse:icons.svg?2#app-check"
      color="primary"
      :disable="loading"
      :loading="loading"
      unelevated
      no-caps
      @click="changeStatus(changeStatusPayload, headerInfo.sub_status as ApplicationSubStatusEnum)"
    />
  </div>
  <delivery-address-dialog
    v-if="headerInfo.delivery_data_filling_enabled"
    :header-info="headerInfo"
    :model-value="dialog"
    @update:model-value="dialog = $event"
  />
</template>

<script setup lang="ts">
import {computed, ref} from 'vue'
import {ControlCenterHeader} from 'src/models/Applications/ControlCenter/ControlCenterHeader'
import {
  useApplicationControlCenterChangeStatusStore
} from 'src/stores/Applications/ControlCenter/ChangeStatus/useApplicationControlCenterChangeStatusStore'
import DeliveryAddressDialog from 'src/components/Applications/onReleaseSendModal/DeliveryAddressDialog.vue'
import {storeToRefs} from 'pinia'
import {ApplicationSubStatusEnum} from 'src/models/Applications/ApplicationSubStatusEnum'
import {ApplicationStatusEnum} from 'src/models/Applications/ApplicationStatusEnum'

const props = defineProps<{ headerInfo: ControlCenterHeader }>()
const headerInfo = computed(() => props.headerInfo)
const dialog = ref(false)
const showDialog = () => {
  dialog.value = true
}

const {
  requestFulfilledInitiator,
  requestFulfilledStatus,
  changeStatus,
} = useApplicationControlCenterChangeStatusStore(headerInfo.value?.id)()
const {loading} = storeToRefs(useApplicationControlCenterChangeStatusStore(props.headerInfo.id)())

const changeStatusPayload = computed(() => {
  const newStatus = requestFulfilledStatus(props.headerInfo.status as ApplicationStatusEnum, props.headerInfo.sub_status as ApplicationSubStatusEnum)
  if (!newStatus) {
    return undefined
  }

  return {
    id: props.headerInfo.id,
    applicationStatus: newStatus,
    initiator: requestFulfilledInitiator(props.headerInfo.sub_status as ApplicationSubStatusEnum)
  }
})
</script>
