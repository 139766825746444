<template>
  <div
    class="col-sm col-xs-12 bannerMainContent"
  >
    По заявке требуется подписание документов клиентом.<br>
    Для подписания отправьте
    <copy-to-clipboard
      :text="headerInfo.client?.sign_url || ''"
    >
      <q-btn
        class="text-link-button"
        label="ссылку"
        icon="svguse:icons.svg?2#app-document-duplicate"
        size="xs"
      />
    </copy-to-clipboard>
    клиенту.
  </div>
</template>

<script setup lang="ts">
import {computed} from 'vue'
import {ControlCenterHeader} from 'src/models/Applications/ControlCenter/ControlCenterHeader'
import CopyToClipboard from 'src/components/CopyToClipboard.vue'

const props = defineProps<{
  headerInfo: ControlCenterHeader,
}>()

const headerInfo = computed(() => props.headerInfo)
</script>
