<template>
  <q-layout
    class="LoginMobileLayout"
    view="hHh LpR lff"
  >
    <q-page-container>
      <q-page class="vertical-middle">
        <router-view />
      </q-page>
    </q-page-container>
  </q-layout>
</template>

<script setup lang="ts">

</script>
