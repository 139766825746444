<template>
  <div
    v-if="isBankEmployee() || (chatType === ChatTypeEnum.platform || canRequestCompletedToBankChat)"
    class="row items-end q-pa-md"
  >
    <div class="text-right">
      <chat-upload-documents-modal
        :id="headerData.id"
        :chat-type="props.chatType"
      />
    </div>
    <div class="col q-mx-xs relative-position">
      <div class="ChatSendMessageComponent--documents">
        <chat-new-documents
          :header-data="props.headerData"
          :chat-type="props.chatType"
        />
      </div>
      <q-input
        v-model="message"
        :loading="loading"
        class="full-width chat-message-input"
        :placeholder="needPlatformChatChangeStatus ? 'Введите сообщение. При отправке будет автоматически исполнен запрос' : 'Введите сообщение'"
        maxlength="1000"
        autogrow
        @keydown.enter.exact.prevent="onSendClick()"
        @keydown.shift.enter.exact.prevent="addEnterToMessage()"
        @keydown.ctrl.enter.exact.prevent="addEnterToMessage()"
      />
    </div>
    <div>
      <q-btn
        :loading="loading || inSendProcess"
        :disable="!message"
        class="send-message-button"
        flat
        round
        color="blue-grey-3"
        style="transform: rotate(90deg); margin-bottom: 5px"
        icon="svguse:icons.svg?2#app-paper-airplane-bold"
        @click="onSendClick()"
      >
        <q-tooltip v-if="needPlatformChatChangeStatus">
          <div class="row">
            <q-icon
              class="q-mt-sm q-mr-sm"
              name="svguse:icons.svg?2#app-exclamation"
              size="sm"
              color="orange"
            />
            <div class="col">
              Внимание!<br>
              При отправке сообщения будет исполнен запрос
            </div>
          </div>
        </q-tooltip>
        <q-tooltip v-if="needBankChatChangeStatus">
          <div class="row">
            <q-icon
              class="q-mt-sm q-mr-sm"
              name="svguse:icons.svg?2#app-exclamation"
              size="sm"
              color="orange"
            />
            <div
              v-if="props.headerData.sub_status === ApplicationSubStatusEnum.bg_project_customer_agreement"
              class="col"
            >
              Внимание!<br>
              При отправке сообщения заявка будет направлена в Банк<br>
              на согласование проекта
            </div>
            <div
              v-else
              class="col"
            >
              Внимание!<br>
              При отправке сообщения будет исполнен запрос
            </div>
          </div>
        </q-tooltip>
      </q-btn>
    </div>
  </div>
  <div v-else>
    <q-banner class="chat-warning-info">
      <template #avatar>
        <div class="icon-chat-warning-info">
          <q-icon
            name="svguse:icons.svg?2#app-exclamation"
            size="sm"
            color="orange"
          />
        </div>
      </template>
      <div>В этот чат можно писать только при поступлении дополнительного запроса от банка</div>
    </q-banner>
  </div>
</template>

<script setup lang="ts">
import {computed, ref} from 'vue'
import {ControlCenterHeader} from 'src/models/Applications/ControlCenter/ControlCenterHeader'
import {useControlCenterChatSendMessage} from 'src/uses/Chat/useControlCenterChatSendMessage'
import {ChatTypeEnum} from 'src/models/ChatTypeEnum'
import ChatUploadDocumentsModal from 'src/components/Chat/ChatUploadDocumentsModal.vue'
import ChatNewDocuments from 'src/components/Chat/ChatNewDocuments.vue'
import {
  useApplicationControlCenterItemStore
} from 'src/stores/Applications/ControlCenter/useApplicationControlCenterItemStore'
import {useProfileStore} from 'src/stores/Login/useProfileStore'
import {ApplicationSubStatusEnum} from 'src/models/Applications/ApplicationSubStatusEnum'
import {usePosthogStore} from 'stores/Posthog/usePosthogStore'
import {useRoute} from 'vue-router'

const props = defineProps<{
  headerData: ControlCenterHeader,
  chatType: ChatTypeEnum,
}>()

const {
  isBankEmployee,
} = useProfileStore()

const headerData = computed(() => props.headerData)
const chatType = computed(() => props.chatType)

const controlCenterChatSendMessage = useControlCenterChatSendMessage(headerData.value.id, props.chatType)
const {
  message,
  loading,
  canRequestCompletedToBankChat,
  needPlatformChatChangeStatus,
  needBankChatChangeStatus,
  sendMessages,
} = controlCenterChatSendMessage
const route = useRoute()

const applicationItemStore = useApplicationControlCenterItemStore(props.headerData.id)()
const {
  uploadAllNewDocuments,
} = applicationItemStore
const {sendEvent} = usePosthogStore()

const inSendProcess = ref(false)
const messageTextareaRows = ref(1)

const onSendClick = async () => {
  if (inSendProcess.value) {
    return
  }

  inSendProcess.value = true
  await uploadAllNewDocuments(true, true)
  await sendMessages()
  inSendProcess.value = false
  messageTextareaRows.value = 1

  const eventName = route.name === 'application-control-center'
    ? 'Send_message_via_control_center'
    : 'Send_message_via_list_of_applications'
  sendEvent(eventName)
}

const addEnterToMessage = () => {
  message.value = message.value + '\n'
}

</script>
<style lang="scss">
.ChatSendMessageComponent--documents {
  position: absolute;
  z-index: 1;
  width: 100%;
  bottom: 36px;
}

.chat-warning-info {
  background: #263238;
  color: #ffffff;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  padding: 8px 20px;
  .icon-chat-warning-info {
    border-radius: 5px;
    padding-top: 3px;
  }
}

.chat-message-input {
  border-radius: 6px;
  .q-field__control {
    border-radius: 6px;
    border: none;

    &:before {
      border-color: transparent !important;
    }
  }
}

.chat-message-textarea {
  border-radius: 6px;
  padding: 5px 15px;
  border: none;
  background-color: #ECEFF1;
  &:before {
    border-color: transparent !important;
  }
  textarea {
    padding: 10px 15px!important;
  }
}
.send-message-button {
  margin-bottom: 5px;
  .q-icon {
    font-size: 30px;
  }
}
</style>
