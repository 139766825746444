<template>
  <div>
    <q-linear-progress
      v-if="loading"
      indeterminate
    />
    <q-list
      v-else
      class="q-mt-sm"
    >
      <q-item
        v-for="document in items"
        :key="document.id"
        class="profile-document-item q-mt-xs bg-white q-pt-md q-pb-md"
      >
        <q-item-section avatar>
          <q-icon
            name="svguse:icons.svg?2#app-article-bold"
            color="blue-grey-3"
          />
        </q-item-section>
        <q-item-section>{{ document.title }}</q-item-section>
        <q-item-section
          class="inline-block q-pt-xs"
          :style="`color: ${DocumentStatuses.getDocumentStatusColor(document.status)}`"
        >
          {{ DocumentStatuses.getDocumentStatusDescription(document.status) }}
          <q-icon
            :name="DocumentStatuses.getDocumentStatusIcon(document.status)"
            size="xs"
          >
            <q-tooltip v-if="document.comment">{{ document.comment }}</q-tooltip>
          </q-icon>
        </q-item-section>
        <q-item-section
          class="profile-document-item--actions"
          side
        >
          <profile-document-actions
            :item="document"
            :loading="loading || scanLoading"
            @refresh="load"
            @upload-scan="onUploadScan(document, $event)"
          />
        </q-item-section>
      </q-item>
    </q-list>
  </div>
</template>

<script setup lang="ts">
import {storeToRefs} from 'pinia'
import {onBeforeMount, onBeforeUnmount} from 'vue'
import {useProfileDocumentsGridStore} from 'src/stores/Profile/Documents/useProfileDocumentsGridStore'
import {DocumentStatuses} from 'src/models/Profile/Documents/DocumentStatusDescriptions'
import ProfileDocumentActions from 'src/components/Profile/Documents/ProfileDocumentActions.vue'
import {useAgentProfileRequisitesUploadScan} from 'src/uses/Profile/Requisites/useAgentProfileRequisitesUploadScan'
import {Document} from 'src/models/Profile/Documents/Document'
const profileDocumentsGridStore = useProfileDocumentsGridStore()
const {
  items,
  loading,
} = storeToRefs(profileDocumentsGridStore)

const {
  load,
} = profileDocumentsGridStore

const {
  uploadScan,
  status,
  loading: scanLoading
} = useAgentProfileRequisitesUploadScan()

const onUploadScan = async (document: Document, file: File) => {
  await uploadScan(document.id, file)
  if (status.value === 200) {
    await load()
  }
}

onBeforeMount(async () => {
  await load()
})

onBeforeUnmount(() => {
  profileDocumentsGridStore.$reset()
  profileDocumentsGridStore.$dispose()
})

</script>
<style lang="scss">
.profile-document-item {
  border-radius: 5px;
}
</style>
