import {
  ApplicationFormFinance, ApplicationFormFinanceField,
  ApplicationFormFinancePeriod
} from 'src/models/Applications/Form/ApplicationFormResponseData'

export const usePeriod = () => {
  const getLabel = (period: ApplicationFormFinancePeriod) => {
    return `${period.quarter} кв. ${period.year}`
  }

  const getId = (period: ApplicationFormFinancePeriod) => {
    return parseInt(`${period.year}${period.quarter}`)
  }

  const getPeriodFields = (
    period: ApplicationFormFinancePeriod,
    finance: ApplicationFormFinance
  ): ApplicationFormFinanceField[] => {
    return getPeriodFieldsById(getId(period), finance)
  }

  const getPeriodFieldsById = (
    periodId: number,
    finance: ApplicationFormFinance
  ): ApplicationFormFinanceField[] => {
    return finance.fields[periodId] || []
  }

  const hasForm1 = (
    fields: ApplicationFormFinanceField[],
  ) => {
    return fields.find(f => f.location.section_id === '1')
  }

  const hasForm2 = (
    fields: ApplicationFormFinanceField[],
  ) => {
    return fields.find(f => f.location.section_id === '2')
  }

  return {
    getLabel,
    getId,
    getPeriodFields,
    getPeriodFieldsById,
    hasForm1,
    hasForm2,
  }
}
