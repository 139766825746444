import {BgFormEnum} from 'src/models/Applications/BgFormEnum'
import {useOptionsFromObject} from 'src/uses/useOptionsFromObject'

const bgForms: Record<BgFormEnum, string> = {
  [BgFormEnum.electronic]: 'Электронная',
  [BgFormEnum.paper]: 'Бумажная',
}

export const useBgForm = () => {
  const {getOptionsFromObject} = useOptionsFromObject()
  const options = getOptionsFromObject(bgForms)
  const getBgFormLabel = (bgForm: BgFormEnum): string => {
    return bgForms[bgForm] || ''
  }

  return {
    getBgFormLabel,
    bgForms,
    options,
  }
}
