<template>
  <q-btn
    size="sm"
    color="blue-grey-4"
    icon="svguse:icons.svg?2#app-trash"
    round
    flat
    @click="open"
  />
  <q-dialog
    v-model="dialog"
  >
    <q-card
      style="min-width: 500px"
      class="modal-blue-background"
    >
      <q-card-section
        class="modal-title row items-center q-pb-none q-pl-lg"
      >
        <div>Удаление страницы помощи</div>
      </q-card-section>
      <q-card-section>
        <info-banner
          text="Вы уверены?"
          caption="Удаленную страницу нельзя будет восстановить!"
          icon="svguse:icons.svg?2#app-exclamation"
          color="red"
        />
      </q-card-section>
      <q-card-section>
        <div class="row">
          <div class="col-12">
            Страница "{{ props.title }}" будет удалена
          </div>
        </div>
      </q-card-section>
      <q-card-actions
        align="right"
        class="modal-actions text-primary q-pa-lg"
      >
        <q-btn
          v-close-popup
          :loading="loading"
          label="Отмена"
          class="q-pr-lg"
          style="padding: 17px 28px;"
          unelevated
          flat
          no-caps
          text-color="black"
        />
        <q-btn
          :loading="loading"
          label="Удалить"
          color="red"
          style="padding: 17px 28px;"
          unelevated
          icon-right="svguse:icons.svg?2#app-trash"
          no-caps
          @click="handleSubmit()"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
import {computed} from 'vue'
import {useDialog} from 'src/uses/Dialogs/useDialog'
import InfoBanner from 'src/components/InfoBanner.vue'
import {useDeleteHelpPage} from 'src/uses/Settings/Help/useDeleteHelpPage'

const emit = defineEmits<{
  (e: 'change'): void,
}>()

const props = defineProps<{
  itemId: string,
  title: string,
}>()
const itemId = computed(() => props.itemId)
const {dialog, show, hide} = useDialog()

const deleteHelpPage = useDeleteHelpPage()

const {
  loading,
  status,
  deletePage,
} = deleteHelpPage

const open = async () => {
  show()
}
const handleSubmit = async () => {
  await deletePage(itemId.value)
  if (status.value !== 200) {
    return
  }
  hide()
  emit('change')
}
</script>
