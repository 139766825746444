<template>
  <router-link
    class="clientLink"
    :to="to"
  >
    {{ props.title }}
    <q-tooltip>{{ props.title }}</q-tooltip>
  </router-link>
</template>

<script setup lang="ts">
import {computed} from 'vue'

const props = defineProps<{
  inn: string,
  title: string,
}>()

const to = computed(() => ({
  name: 'applications-grid',
  query: {
    'filter[client]': props.inn,
  }
}))

</script>

<style lang="scss">
  .clientLink {
    text-overflow: ellipsis;
  }
</style>
