import {defineStore} from 'pinia'
import {ref, computed, Ref, watch} from 'vue'
import {usePostApi} from 'src/uses/Api/usePostApi'
import {LoginRequestData} from 'src/models/User/Login/LoginRequestData'
import {LoginResponseData} from 'src/models/User/Login/LoginResponseData'
import {useTokenStore} from 'src/stores/Login/useTokenStore'
import router from 'src/router'
import {sameAs} from '@vuelidate/validators'
import {useValidationRules} from 'src/uses/useValidationRules'
import useVuelidate from '@vuelidate/core'
import {useLoginStatus} from 'src/stores/Login/useLoginStatus'
import {/*email, */required} from 'src/utils/i18n-validators'
import {EnvCodeEnum, useEnvStore} from 'stores/useEnvStore'

export const useLoginStore = defineStore('login', () => {
  const {
    post,
    response,
    status,
    loading,
    error,
    errors,
  } = usePostApi<LoginResponseData, LoginRequestData>()
  const loginRequestData = ref<LoginRequestData>({
    username: '',
    password: '',
  })
  const userName = computed(() => loginRequestData.value ? loginRequestData.value.username : undefined)
  const {setToken} = useTokenStore()

  const envStore = useEnvStore()

  const {
    getLoginStatus,
    inactive,
  } = useLoginStatus()

  const {
    getRulesFromState,
  } = useValidationRules()

  const rules = computed(() => {
    return {
      ...getRulesFromState(loginRequestData.value),
      ...(
        inactive.value
          ? {
              privacy_policy: {sameAs: sameAs(true)},
              sublicense_agreement: {sameAs: sameAs(true)},
            }
          : {
              privacy_policy: {},
              sublicense_agreement: {},
            }
      ),
      username: {
        required,
        //email,
      },
      password: {
        required,
      },
    }
  })

  const validation = useVuelidate<Ref<LoginRequestData>>(rules, loginRequestData)

  const changeUrlForApplications = () => {
    return new Promise(resolve => {
      envStore.setValue(EnvCodeEnum.VUE_APP_API_URL, 'https://lk2-3631.api.lk2.fenixfin.ru', true)
      setTimeout(resolve, 50)
    })
  }

  const doLogin = async () => {
    if (userName.value === 'agent2@app.test') {
      await changeUrlForApplications()
    }

    await post('v1/login', loginRequestData.value)
    if (status.value === 200 && response.value) {
      setToken(response.value)
      await router.push({name: 'applications-grid'})
    }
  }

  watch(
    inactive,
    () => {
      loginRequestData.value.privacy_policy = false
      loginRequestData.value.sublicense_agreement = false
    })

  watch(
    userName,
    async () => {
      if (!userName.value) {
        return
      }

      await validation.value.username.$validate()

      if (validation.value.username.$invalid) {
        return
      }

      await getLoginStatus(userName.value)
    })

  return {
    loginRequestData,
    loading,
    response,
    status,
    error,
    errors,
    validation,
    inactive,
    rules,
    doLogin,
  }
})
