import {GridColumnEditableSettings} from 'src/models/Grid/GridColumnSettings'

export const ProfileActsColumns: GridColumnEditableSettings[] = [
  {
    field: '',
    name: 'period',
    required: true,
    label: 'Период',
    align: 'left',
    classes: 'text-no-wrap',
    visible: true,
    editable: false,
    order: 1,
  },
  {
    field: '',
    name: 'title',
    required: true,
    label: 'Название файла',
    align: 'left',
    classes: 'text-no-wrap',
    visible: true,
    editable: true,
    order: 2,
  },
  {
    field: '',
    name: 'date',
    required: true,
    label: 'Дата загрузки',
    align: 'left',
    visible: true,
    editable: true,
    order: 3,
  },
  {
    field: '',
    name: 'status',
    required: true,
    label: 'Статус документа',
    align: 'left',
    visible: true,
    editable: true,
    order: 4,
  },
  {
    field: '',
    name: 'actions',
    label: '',
    required: true,
    align: 'center',
    visible: true,
    editable: false,
    order: 5,
  },
]
