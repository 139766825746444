<template>
  <div
    v-if="isAuthed && !isAdmin() && !isJuniorAdmin() && !isBankEmployee()"
    class="newApplicationButton q-py-lg"
  >
    <div
      v-if="!props.mini"
      class="q-px-md"
    >
      <q-btn
        :to="{name: 'applications-new'}"
        class="full-width"
        style="padding: 10px 16px;"
        align="left"
        color="blue-7"
        size="14px"
        unelevated
        no-caps
      >
        <strong>Создать заявку</strong>
        <span class="icon">
          <q-icon
            name="svguse:icons.svg?2#app-plus-bold-1"
            color="blue-7"
            size="18px"
            style="padding: 5.5px;"
          />
        </span>
      </q-btn>
    </div>
    <div
      v-else
      class="q-px-sm"
    >
      <q-btn
        :to="{name: 'applications-new'}"
        class="text-white"
        style="margin-bottom: 15px; margin-left: 6px;"
        color="blue-7"
        size="13px"
        padding="0"
        dense
        unelevated
      >
        <q-icon
          name="svguse:icons.svg?2#app-plus-bold-1"
          color="white"
          size="18px"
          style="padding: 5.5px;"
        />
      </q-btn>
      <q-tooltip
        self="center left"
        anchor="center right"
        :delay="100"
        :offset="[5, 0]"
      >
        <div class="text-no-wrap">Создать заявку</div>
      </q-tooltip>
    </div>
  </div>
</template>

<script setup lang="ts">
import {useAuthStore} from 'src/stores/Login/useAuthStore'
import {storeToRefs} from 'pinia'

const props = defineProps<{
  mini: boolean,
}>()

const authStore = useAuthStore()
const {
  isAdmin,
  isBankEmployee,
  isJuniorAdmin,
} = authStore
const {
  isAuthed,
} = storeToRefs(authStore)
</script>

<style lang="scss">
.newApplicationButton {
  .icon {
    background: white;
    border-radius: 6px;
    position: absolute;
    right: 10px;
    line-height: 0;
  }

  .q-btn {
    border-radius: 6px;
  }
}
</style>
