import {defineStore} from 'pinia'
import {ref} from 'vue'
import {useGetApi} from 'src/uses/Api/useGetApi'
import {Employee} from 'src/models/Profile/Employees/Employee'

export const useGetEmployeeItemStore = defineStore('get-employee-item', () => {
  const {
    get,
    response,
    status,
    loading,
    error,
    errors,
  } = useGetApi<Employee>()

  const employeeResponseData = ref<Employee>({
    id: '',
    name: '',
    email: '',
    role: '',
    phone: '',
    password: '',
    password_confirm: '',
  })

  const load = async (id: string) => {
    await get(`v1/employees/${id}`)
    if (status.value === 200 && response.value) {
      employeeResponseData.value = response.value
    }
  }

  const getEmployeeRequestData = () => {
    return employeeResponseData.value
  }

  return {
    employeeResponseData,
    loading,
    response,
    status,
    error,
    errors,
    load,
    getEmployeeRequestData
  }
})
