<template>
  <q-btn
    v-if="$q.platform.is.mobile"
    label="Редактировать"
    class="mobileBtnEdit text-no-wrap"
    size="md"
    icon-right="svguse:icons.svg?2#app-pencil-alt"
    no-caps
    unelevated
    no-wrap
    @click="open"
  />
  <q-btn
    v-else
    size="sm"
    color="primary"
    icon="svguse:icons.svg?2#app-pencil-alt"
    round
    flat
    @click="open"
  />
  <q-dialog
    v-model="dialog"
    :maximized="$q.platform.is.mobile"
  >
    <q-card class="modal-blue-background">
      <q-card-section class="modal-title row items-center q-pb-none q-pl-lg">
        <div class="col">Редактирование сотрудника</div>
        <q-btn
          v-if="$q.platform.is.mobile"
          v-close-popup
          class="modal-close-icon"
          icon="svguse:icons.svg?2#svguse:icons.svg?2#app-arrow-left-bold"
          flat
          round
          dense
        />
      </q-card-section>
      <q-card-section>
        <info-banner
          text="Старший сотрудник будет видеть все заявки"
          caption="Сотрудник будет видеть только те заявки, которые создал самостоятельно"
          icon="svguse:icons.svg?2#app-exclamation-circle"
        />
      </q-card-section>
      <q-card-section>
        <div class="row q-col-gutter-lg white-bg-field">
          <q-form>
            <div class="row q-col-gutter-md">
              <div class="col-xs-12 col-sm-6 q-mb-sm">
                <q-input
                  v-model="employeeUpdateData.name"
                  class="field-with-top-label"
                  :loading="loading"
                  :error-message="getErrorMessage('name')"
                  :error="hasError('name')"
                  label="ФИО"
                  outlined
                  hide-hint
                  hide-bottom-space
                  dense
                  @blur="validation.name.$validate()"
                  @focus="clearError('name')"
                />
              </div>
              <div class="col-xs-12 col-sm-6 q-mb-sm">
                <q-input
                  v-model="employeeUpdateData.email"
                  class="field-with-top-label"
                  :loading="loading"
                  :error-message="getErrorMessage('email')"
                  :error="hasError('email')"
                  label="E-mail"
                  outlined
                  hide-hint
                  hide-bottom-space
                  dense
                  @blur="validation.email.$validate()"
                  @focus="clearError('email')"
                />
              </div>
              <div class="col-xs-12 col-sm-6 q-mb-sm">
                <q-input
                  v-model="employeeUpdateData.phone"
                  v-mask="'+7 (9##) ###-##-##'"
                  class="field-with-top-label"
                  :loading="loading"
                  :error-message="getErrorMessage('phone')"
                  :error="hasError('phone')"
                  type="tel"
                  label="Телефон"
                  unmasked-value
                  fill-mask="0"
                  outlined
                  hide-hint
                  hide-bottom-space
                  dense
                  @blur="validation.phone.$validate()"
                  @focus="clearError('phone')"
                />
              </div>
              <div class="col-xs-12 col-sm-6 q-mb-sm">
                <q-select
                  v-model="employeeUpdateData.role"
                  class="field-with-top-label"
                  :options="rolesForSelect"
                  :loading="loading"
                  style="min-width: 200px"
                  map-options
                  label="Роль"
                  outlined
                  hide-hint
                  hide-bottom-space
                  dense
                  emit-value
                />
              </div>
              <div class="col-xs-12 col-sm-6 q-mb-sm">
                <password-help
                  :model-value="showPasswordHelp"
                  :password="employeeUpdateData.password || ''"
                >
                  <q-input
                    v-model="employeeUpdateData.password"
                    class="field-with-top-label"
                    :loading="loading"
                    autocomplete="new-password"
                    type="password"
                    label="Пароль"
                    outlined
                    hide-hint
                    hide-bottom-space
                    dense
                  />
                </password-help>
              </div>
              <div class="col-xs-12 col-sm-6 q-mb-sm">
                <q-input
                  v-model="employeeUpdateData.password_confirm"
                  class="field-with-top-label"
                  :loading="loading"
                  label="Повторите пароль"
                  outlined
                  hide-hint
                  hide-bottom-space
                  dense
                />
              </div>
            </div>
          </q-form>
        </div>
      </q-card-section>
      <q-card-actions
        v-if="$q.platform.is.mobile"
        align="right"
        class="modal-actions text-primary q-pa-lg row"
      >
        <q-btn
          :loading="loading"
          label="Сохранить"
          class="col-12"
          color="primary"
          style="padding: 17px 28px;"
          unelevated
          no-caps
          @click="handleSubmit()"
        />
        <q-btn
          v-close-popup
          :loading="loading"
          label="Отмена"
          class="col-12"
          style="padding: 17px 28px;"
          unelevated
          flat
          no-caps
          text-color="black"
        />
      </q-card-actions>
      <q-card-actions
        v-else
        align="right"
        class="modal-actions text-primary q-pa-lg"
      >
        <q-btn
          v-close-popup
          :loading="loading"
          label="Отмена"
          class="q-pr-lg"
          style="padding: 17px 28px;"
          unelevated
          flat
          no-caps
          text-color="black"
        />
        <q-btn
          :loading="loading"
          label="Сохранить"
          color="primary"
          style="padding: 17px 28px;"
          unelevated
          no-caps
          @click="handleSubmit()"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
import {computed, onBeforeUnmount, ref} from 'vue'
import {useDialog} from 'src/uses/Dialogs/useDialog'
import {storeToRefs} from 'pinia'
import {useValidation} from 'src/uses/useValidation'
import PasswordHelp from 'src/components/PasswordHelp.vue'
import {EmployeeGridItem} from 'src/models/Profile/Employees/EmployeeGridItem'
import {useEmployeeUpdateStore} from 'src/stores/Profile/Employees/useEmployeeUpdateStore'
import InfoBanner from 'src/components/InfoBanner.vue'
import {Notify, useQuasar} from 'quasar'

const props = defineProps<{
  item: EmployeeGridItem
}>()
const item = computed(() => props.item)

const employeeUpdateStore = useEmployeeUpdateStore(props.item.id)()
const {
  employeeUpdateData,
  loading,
  rolesForSelect,
  validation,
  errors,
  status,
} = storeToRefs(employeeUpdateStore)

const $q = useQuasar()

const {
  update,
  loadEmployeeItem
} = employeeUpdateStore

const {dialog, show, hide} = useDialog()

const open = async () => {
  show()
  await loadEmployeeItem(item.value.id)
}

const {
  getErrorMessage,
  hasError,
  clearError
} = useValidation(validation, errors)

const showPasswordHelp = ref(false)

onBeforeUnmount(() => {
  employeeUpdateStore.$reset()
  employeeUpdateStore.$dispose()
})

const handleSubmit = async () => {
  await update(item.value.id)
  if (status.value === 200) {
    hide()
    Notify.create({
      type: 'positive',
      message: 'Сохранено',
      position: 'top',
      timeout: 2000,
      textColor: 'white',
    })
  }
}

</script>
<style lang="scss">
  .mobileBtnEdit {
    padding: 9px;
    font-size: 14px;
    width: 100%;
    color: #1E88E5;
    background-color: #eeeeee
  }
</style>
