<template>
  <q-layout
    v-if="data"
    class="LoginLayout"
    view="hHh lpR fFf"
  >
    <div class="blur" />
    <router-link
      class="ToMain"
      :to="{name: 'login'}"
    >
      <img
        :src="data.logo_auth"
        alt=""
      >
    </router-link>
    <div class="helpText">
      Личный кабинет
    </div>
    <q-page-container>
      <q-page class="vertical-middle">
        <div class="row window-height items-center">
          <div class="col" />
          <div class="bg-white LoginWrapper">
            <div
              class="row"
              style="flex-direction: column; min-height: 100vh;"
            >
              <div class="LoginInner col">
                <router-view name="header" />
                <router-view />
              </div>
              <div class="LoginFooter">
                Для корректной работы на портале, рекомендуется использовать компьютеры под ОС Windows 7, 8, 10.
                Рекомендованные браузеры - Яндекс.Браузер 17+, Google Chrome 50+.
              </div>
            </div>
          </div>
        </div>
      </q-page>
    </q-page-container>
  </q-layout>
</template>

<script setup lang="ts">
/*import {initSnow} from '../snow'
import {onMounted} from 'vue'

onMounted(() => {
  initSnow()
})*/

import {useIntegratorStore} from 'stores/useIntegratorStore'
import {storeToRefs} from 'pinia'

const {data} = storeToRefs(useIntegratorStore())
</script>

<style lang="scss">
@function basePadding() {
  @return clamp(16px, 4vw, 72px);
}
.LoginLayout {
  background: #000606 url('../assets/login-main.png') clamp(-400px, -50%, -700px) 50% no-repeat;
  //background: #000606 url('../assets/main-new-year-2.jpg') clamp(-400px, -50%, -700px) 50% no-repeat;
  background-size: cover;
  background-attachment: fixed;
  position: relative;

  .blur {
    backdrop-filter: blur(5px);
    width: 100vw;
    height: 100vh;
    z-index: 0;
    position: fixed;
    top: 0;
    left: 0;
  }

  .ToMain {
    position: fixed;
    z-index: 1;
    top: basePadding();
    left: basePadding();

    img {
      max-width: clamp(200px, 20vw,400px);
    }
  }

  .helpText {
    position: fixed;
    z-index: 1;
    bottom: basePadding();
    left: basePadding();
    font-size: clamp(32px, 4vw, 70px);
    color: white;
  }

  .LoginWrapper {
    width: clamp(400px, 50%, 700px);
    height: 100vh;
    overflow: auto;

    .LoginInner {
      padding: basePadding() basePadding() basePadding();
      position: relative;
      z-index: 3;
    }

    .LoginFooter {
      padding: 24px basePadding();
      background: $grey-1;
      font-size: 10px;
      color: $grey;
    }
  }
}
</style>
