<template>
  <div class="col-sm col-xs-12 bannerMainContent">
    <div>
      Заявка находится на проверке сотрудника GOSOBLAKO. Дождитесь уведомления об окончании проверки
    </div>
  </div>
  <div class="bannerInfoBtnWrap">
    <q-btn
      v-if="showDraftBtn"
      class="banner-btn"
      label="Вернуть на статус черновик"
      icon-right="svguse:icons.svg?2#app-x-circle"
      color="primary"
      unelevated
      no-caps
      :loading="loading"
      @click="changeStatus(
        {
          id: props.headerInfo.id,
          applicationStatus: ApplicationSubStatusEnum.draft,
          initiator: 'align_risks'
        },
        props.headerInfo.sub_status as ApplicationSubStatusEnum
      )"
    />
  </div>
</template>
<script setup lang="ts">
import {ControlCenterHeader} from 'src/models/Applications/ControlCenter/ControlCenterHeader'
import {
  useApplicationControlCenterChangeStatusStore
} from 'stores/Applications/ControlCenter/ChangeStatus/useApplicationControlCenterChangeStatusStore'
import {storeToRefs} from 'pinia'
import {ApplicationSubStatusEnum} from 'src/models/Applications/ApplicationSubStatusEnum'
import {useProfileStore} from 'stores/Login/useProfileStore'
import {computed} from 'vue'

const props = defineProps<{
  headerInfo: ControlCenterHeader,
}>()

const profileStore = useProfileStore()
const {isAdmin} = profileStore

const {
  changeStatus,
} = useApplicationControlCenterChangeStatusStore(props.headerInfo?.id)()
const {loading} = storeToRefs(useApplicationControlCenterChangeStatusStore(props.headerInfo?.id)())

const showDraftBtn = computed(() => {
  if (!isAdmin()) {
    return false
  }
  const statuses = [
    ApplicationSubStatusEnum.talend_loaded,
    ApplicationSubStatusEnum.go_loaded,
    ApplicationSubStatusEnum.forming_errors,
  ]

  return props.headerInfo.integration.enabled && statuses.includes(props.headerInfo.sub_status as ApplicationSubStatusEnum)
})
</script>
