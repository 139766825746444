export const useOptionsFromObject = () => {
  const getOptionsFromObject = (obj: Record<string | number, string>): { label: string, value: string | number }[] => {
    return Object.entries(obj)
      .map(e => ({
        label: e[1],
        value: e[0] || '',
      }))
  }

  return {
    getOptionsFromObject,
  }
}
