<template>
  <div>
    <div
      v-if="!editMode && src"
      class="row q-col-gutter-x-md items-center"
    >
      <div>
        <q-img
          class="rounded-borders relative-position overflow-hidden"
          style="border: 1px solid #A5C2D8"
          ratio="1"
          :src="src"
          width="60px"
          fit="cover"
        />
      </div>
      <div class="col">
        <q-btn
          label="Изменить"
          :class="{'text-blue-grey-5': true, 'full-width': $q.platform.is.mobile}"
          color="for-blue-grey-5"
          :style="$q.platform.is.mobile ? 'height: 60px' : ''"
          no-caps
          unelevated
          @click="editMode = true"
        />
      </div>
    </div>
    <file-select-component
      v-else
      class="fit"
      label="Загрузить изображение"
      icon-right="svguse:icons.svg?2#app-photograph"
      :accept="props.accept"
      :max-size="props.help"
      :max-file-size="2097152"
      @file:selected="onSelected"
    />
  </div>
</template>

<script setup lang="ts">
import FileSelectComponent from 'components/FileSelectComponent.vue'
import {computed, ref, watchEffect} from 'vue'
import {useQuasar} from 'quasar'

const props = defineProps<{
  accept: string
  help: string
  url: string | null
}>()

const emit = defineEmits<{
  (e: 'file:selected', file: File): void
  (e: 'change:src', src: string): void
}>()

const $q = useQuasar()

const editMode = ref(false)
const file = ref<File>()
const fileSrc = ref<string>()

const src = computed(() => {
  return fileSrc.value || props.url
})

const onSelected = (payload: File) => {
  file.value = payload
  editMode.value = false
  emit('file:selected', payload)
}

watchEffect(() => {
  if (!file.value) {
    return
  }
  const reader = new FileReader()
  reader.readAsDataURL(file.value)
  reader.addEventListener('load', (e) => {
    if (!e.target || !e.target.result) {
      return
    }
    fileSrc.value = e.target.result as string
  })
})

watchEffect(() => {
  if (!fileSrc.value) {
    return
  }

  emit('change:src', fileSrc.value)
})
</script>

<style scoped lang="scss">

</style>
