<template>
  <form-select
    :model-value="props.modelValue"
    :options="options"
    label="Сотрудник"
    :loading="employeesLoading || props.loading"
    top-actions
    @update:model-value="onChange"
  />
</template>

<script setup lang="ts">
import {storeToRefs} from 'pinia'
import {computed, onBeforeMount} from 'vue'
import FormSelect from 'src/components/FormElements/FormSelect.vue'
import {useEmployeeFilterStore} from 'stores/Employees/useEmployeeFilterStore'
import {FormElementValue} from 'src/models/Form'

const props = defineProps<{
  modelValue: string[],
  loading: boolean,
}>()

const emit = defineEmits<{
  (e: 'update:model-value', val: string[]): void,
  (e: 'change'): void,
}>()

const employeesStore = useEmployeeFilterStore()
const {load} = employeesStore
const {loading: employeesLoading, employees} = storeToRefs(employeesStore)

const options = computed(() => {
  return employees.value
    .map(employee => ({
      label: employee.name_full,
      value: employee.id,
    }))
})

const onChange = (val: FormElementValue) => {
  emit('update:model-value', val as string[])
  emit('change')
}

onBeforeMount(() => {
  if (employees.value.length === 0) {
    load()
  }
})
</script>
