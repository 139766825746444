export enum ProductStopFactorParamsItemOperatorEnum {
  LT = 'lt',
  EQ = 'eq',
  GT = 'gt',
}

export enum ProductStopFactorParamsItemTypeEnum {
  SELECT = 'select',
  NUMBER = 'number',
  STRING = 'string',
}

export interface ProductStopFactorParamOptionsWithChild{
  code: string
  name: string,
  child: ProductStopFactorParamOptionsWithChild[]
}

export interface ProductStopFactorParamsItem {
  multiple: boolean
  options: Record<string, string> | ProductStopFactorParamOptionsWithChild | null
  relations: ProductStopFactorParamsItemOperatorEnum[]
  title: string
  type: ProductStopFactorParamsItemTypeEnum
}
