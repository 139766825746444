import {UserRoleEnum} from 'src/models/User/UserRoleEnum'

const roles: Record<UserRoleEnum, string> = {
  [UserRoleEnum.Admin]: 'Старший Администратор',
  [UserRoleEnum.JuniorAdmin]: 'Младший Администратор',
  [UserRoleEnum.MiddleAdmin]: 'Администратор',
  [UserRoleEnum.Agent]: 'Агент',
  [UserRoleEnum.User]: 'Пользователь',
  [UserRoleEnum.HeadEmployee]: 'Старший сотрудник',
  [UserRoleEnum.Employee]: 'Сотрудник',
  [UserRoleEnum.HeadOperator]: 'Руководитель группы операторов',
  [UserRoleEnum.Operator]: 'Оператор',
  [UserRoleEnum.AgentManager]: 'Агентский менеджер',
  [UserRoleEnum.RegionalManager]: 'Региональный менеджер',
  [UserRoleEnum.BankEmployee]: 'Сотрудник банка',
}

export class UserRoles {
  public static getUserRoleDescription (role: UserRoleEnum | undefined): string {
    if (!role) {
      return ''
    }

    return roles[role] || role
  }
}
