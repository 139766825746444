<template>
  <q-dialog
    v-if="headerData"
    :model-value="props.modelValue"
    full-width
    full-height
    :position="$q.platform.is.mobile ? 'bottom' : 'standard'"
    @update:model-value="emit('update:model-value', $event)"
  >
    <q-card class="ChatHistoryStandAloneCard">
      <q-card-section
        class="q-pa-none"
        style="height: 100%"
      >
        <application-control-center-chat-history
          :header-data="headerData"
          :dialog="props.modelValue"
          :original="false"
          @update:dialog="emit('update:model-value', $event)"
        />
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
import ApplicationControlCenterChatHistory
  from 'src/components/Applications/ApplicationControlCenter/ApplicationControlCenterChatHistory.vue'
import {onBeforeMount, onBeforeUnmount, watchEffect} from 'vue'
import {useApplicationControlCenterStore} from 'src/stores/Applications/ControlCenter/useApplicationControlCenterStore'
import {storeToRefs} from 'pinia'
import {
  useApplicationControlCenterItemStore
} from 'src/stores/Applications/ControlCenter/useApplicationControlCenterItemStore'
import {useControlCenterChatStore} from 'src/stores/Applications/ControlCenter/useControlCenterChatStore'
import {useLoadingStore} from 'src/stores/useLoadingStore'
import {useQuasar} from 'quasar'

const props = defineProps<{
  appId: string,
  modelValue: boolean,
}>()

const emit = defineEmits<{
  (e: 'update:model-value', value: boolean): void,
}>()

const $q = useQuasar()

const loadingStore = useLoadingStore()

const applicationControlCenterStore = useApplicationControlCenterStore(props.appId)()
const {init} = applicationControlCenterStore
const {
  //loadingHeaderData,
  headerData,
} = storeToRefs(applicationControlCenterStore)

const applicationControlCenterItemStore = useApplicationControlCenterItemStore(props.appId)()
const {
  load,
} = applicationControlCenterItemStore

const controlCenterChatStore = useControlCenterChatStore(props.appId)()
const {
  init: chatInit,
} = controlCenterChatStore

onBeforeMount(async () => {
  loadingStore.showLoading()
  await init()
  await load()
})

onBeforeUnmount(() => {
  applicationControlCenterStore.$reset()
  applicationControlCenterStore.$dispose()

  applicationControlCenterItemStore.$reset()
  applicationControlCenterItemStore.$dispose()

  controlCenterChatStore.$reset()
  controlCenterChatStore.$dispose()
})

watchEffect(() => {
  if (!headerData.value) {
    return
  }

  loadingStore.hideLoading()
  chatInit(headerData.value)
})

/*watchEffect(() => {
  loadingHeaderData.value
})*/
</script>

<style lang="scss">
.q-dialog__inner {
  .ChatHistoryStandAloneCard {
    min-width: 400px;
    max-width: 800px !important;
    max-height: 650px !important;
    border-radius: 8px;
    @media only screen and (pointer: coarse) {
      max-height: none !important;
    }
  }
}
</style>
