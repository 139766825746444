import {usePostApi} from 'src/uses/Api/usePostApi'
import {useValidationRules} from 'src/uses/useValidationRules'
import {computed, Ref, ref} from 'vue'
import {required} from 'src/utils/i18n-validators'
import useVuelidate from '@vuelidate/core'
import {ListingsItem, ListingsItemCreateUpdate} from 'src/models/Listings/ListingsItem'
import {FormHelper} from 'src/helpers/FormHelper'

export const useListingsCreateUpdate = () => {
  const {
    post,
    loading,
    status,
    errors,
  } = usePostApi<FormData, FormData>()

  const {
    getRulesFromState,
  } = useValidationRules()

  const listingCreateData = ref<ListingsItemCreateUpdate>({
    id: '',
    title: '',
    stop_factors_enabled: false,
    calculator_enabled: false,
    tariff_enabled: false,
    read_enabled: false,
    edit_enabled: false,
    delete_enabled: false,
    file: undefined
  })

  const rules = computed(() => {
    return {
      ...getRulesFromState(listingCreateData.value),
      title: { required },
      stop_factors_enabled: { required },
      calculator_enabled: { required },
      tariff_enabled: { required },
      read_enabled: { required },
      edit_enabled: { required },
      delete_enabled: { required },
    }
  })

  const validation = useVuelidate<Ref<ListingsItemCreateUpdate>>(rules, listingCreateData)

  const create = async () => {
    if (!await validation.value.$validate()) {
      return
    }

    await post('v2/listings', FormHelper.getFormDataFromObject(listingCreateData.value))
  }

  const update = async () => {
    if (!listingCreateData.value.id || !await validation.value.$validate()) {
      return
    }

    await post(`v2/listings/${listingCreateData.value.id}`, FormHelper.getFormDataFromObject(listingCreateData.value))
  }

  const clearState = () => {
    listingCreateData.value = {
      id: '',
      title: '',
      stop_factors_enabled: false,
      calculator_enabled: false,
      tariff_enabled: false,
      read_enabled: false,
      edit_enabled: false,
      delete_enabled: false,
      file: undefined
    }
  }

  const initItem = (item: ListingsItem) => {
    listingCreateData.value = {
      id: item.id || '',
      title: item.title || '',
      stop_factors_enabled: item.config?.stop_factors_enabled || false,
      calculator_enabled: item.config?.calculator_enabled || false,
      tariff_enabled: item.config?.tariff_enabled || false,
      read_enabled: item.permissions?.read_enabled || false,
      edit_enabled: item.permissions?.edit_enabled || false,
      delete_enabled: item.permissions?.delete_enabled || false,
    }
  }

  return {
    listingCreateData,
    validation,
    status,
    errors,
    loading,
    rules,
    initItem,
    create,
    update,
    clearState,
  }
}
