<template>
  <q-btn
    v-if="editable && !dialog"
    class="fixed-top-left z-top q-mt-xl q-ml-xl"
    size="md"
    icon="svguse:icons.svg?2#app-cog"
    color="primary"
    dense
    unelevated
    rounded
    @click="show"
  />
  <q-dialog v-model="dialog">
    <q-card style="min-width: 400px; max-width: 800px; width: 60vw;">
      <q-card-section>
        <div class="text-h6">Окружение</div>
      </q-card-section>
      <q-card-section class="q-pt-none">
        <div
          v-for="code in EnvCodeEnum"
          :key="code"
          class="row"
        >
          <q-input
            :model-value="getValue(code)"
            class="field-with-top-label col q-mb-sm"
            :label="code"
            :disable="!isEditable(code)"
            outlined
            hide-bottom-space
            dense
            @update:model-value="onChange(code, $event as string)"
          >
            <template
              v-if="getChange(code)"
              #after
            >
              <q-icon
                color="primary"
                name="svguse:icons.svg?2#app-information-circle-bold"
              />
            </template>
          </q-input>
          <copy-to-clipboard
            v-if="getValue(code)"
            :text="getValue(code) || ''"
          >
            <q-btn
              class="q-ml-sm"
              style="margin-top: 42px;"
              icon="svguse:icons.svg?2#app-document-duplicate"
              size="xs"
              color="blue-grey-4"
              round
              flat
            />
          </copy-to-clipboard>
        </div>
      </q-card-section>
      <q-card-actions
        class="row justify-end q-py-md"
        style="background: #D5EAFA;"
      >
        <q-btn
          padding="sm lg"
          size="16px"
          color="black"
          label="По умолчанию"
          unelevated
          no-caps
          @click="setToDefault(); changes = []"
        />
        <q-btn
          padding="sm lg"
          size="16px"
          color="blue-7"
          label="Сохранить"
          :disable="changes.length === 0"
          unelevated
          no-caps
          @click="save(); changes = []"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
import {useDialog} from 'src/uses/Dialogs/useDialog'
import {EnvCodeEnum, useEnvStore} from 'src/stores/useEnvStore'
import {storeToRefs} from 'pinia'
import {ref} from 'vue'
import CopyToClipboard from 'src/components/CopyToClipboard.vue'

const {
  isEditable,
  getEnvValue,
  setValue,
  setToDefault,
} = useEnvStore()

const {
  editable,
} = storeToRefs(useEnvStore())

const {
  dialog,
  show,
} = useDialog()

const changes = ref<{code: EnvCodeEnum, value: string}[]>([])

const getChange = (code: EnvCodeEnum) => {
  return changes.value
    .find(change => change.code === code)
}

const getValue = (code: EnvCodeEnum) => {
  const change = getChange(code)

  return change
    ? change.value
    : getEnvValue(code)
}

const onChange = (code: EnvCodeEnum, value: string) => {
  const index = changes.value
    .findIndex(change => change.code === code)

  if (getEnvValue(code) === value && index === -1) {
    return
  }

  if (getEnvValue(code) === value && index !== -1) {
    changes.value.splice(index, 1)
    return
  }

  if (index === -1) {
    changes.value.push({code, value})
    return
  }

  changes.value[index] = {code, value}
}

const save = () => {
  changes.value
    .forEach(change => {
      setValue(change.code, change.value)
    })
}
</script>

<style scoped lang="scss">

</style>
