import {defineStore} from 'pinia'
import {computed, Ref, ref} from 'vue'
import {usePostApi} from 'src/uses/Api/usePostApi'
import {
  maxLength,
  minLength,
  passwordValidator,
  required
} from 'src/utils/i18n-validators'
import useVuelidate from '@vuelidate/core'
import {PasswordRecoveryContinueRequestData} from 'src/models/User/PasswordRecovery/passwordRecoveryContinueRequestData'
import {useRoute} from 'vue-router'
import {useValidationRules} from 'src/uses/useValidationRules'

export const usePasswordRecoveryContinueStore = defineStore('password-recovery-continue', () => {
  const {
    post,
    response,
    status,
    loading,
    error,
    errors,
  } = usePostApi<never, PasswordRecoveryContinueRequestData>()

  const {
    getRulesFromState,
  } = useValidationRules()

  const passwordRecoveryContinueRequestData = ref<PasswordRecoveryContinueRequestData>({
    password: '',
  })

  const success = ref(false)
  const route = useRoute()
  const token = route.params.token as string

  const rules = computed(() => {
    return {
      ...getRulesFromState(passwordRecoveryContinueRequestData.value),
      password: {
        required,
        passwordValidator,
        minLength: minLength(8),
        maxLength: maxLength(14),
      },
    }
  })

  const validation = useVuelidate<Ref<PasswordRecoveryContinueRequestData>>(rules, passwordRecoveryContinueRequestData)

  const doPasswordRecoveryContinue = async () => {
    if (!await validation.value.$validate()) {
      return
    }

    await post(`v1/password/${token || ''}`, passwordRecoveryContinueRequestData.value)
    if (status.value === 200 && response.value) {
      success.value = true
    }
  }

  return {
    passwordRecoveryContinueRequestData,
    loading,
    response,
    status,
    error,
    errors,
    validation,
    success,
    doPasswordRecoveryContinue,
  }
})
