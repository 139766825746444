import {defineStore} from 'pinia'
import {useGetApi} from 'src/uses/Api/useGetApi'
import {ref} from 'vue'
import {useGridPagination} from 'src/uses/Grid/useGridPagination'
import {Document} from 'src/models/Profile/Documents/Document'
import {useApplicationsGridFiltersStore} from 'stores/Applications/Grid/useApplicationsGridFiltersStore'

export const useProfileDocumentsGridStore = defineStore('profile-documents-grid', () => {
  const AGENT_PROFILE_DOCUMENTS_LIST_URL = 'v1/document/list'

  const {
    loading,
    get,
    errors,
    error,
    status,
    response,
  } = useGetApi<Document[]>()

  const {
    pagination,
    gridPagination,
    perPageOptions,
    getFromStorage,
    onRequestPagination,
    setPage,
  } = useGridPagination('profile-documents')

  const filtersStore = useApplicationsGridFiltersStore()
  const {getFiltersQuery} = filtersStore

  const items = ref(<Document[]>[])

  const beforeLoadTimeout = () => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(true)
      }, 1500)
    })
  }

  const load = async () => {
    loading.value = true
    await beforeLoadTimeout()
    await getFromStorage()
    await get(
      AGENT_PROFILE_DOCUMENTS_LIST_URL,
      {
        page: pagination.value?.page,
        perpage: pagination.value?.per_page,
        ...getFiltersQuery()
      }
    )

    if (status.value === 200 && response.value) {
      items.value = response.value
    }
  }

  return {
    loading,
    get,
    errors,
    error,
    items,
    pagination,
    gridPagination,
    perPageOptions,
    onRequestPagination,
    load,
    setPage,
  }
})
