import {defineStore, storeToRefs} from 'pinia'
import {useGetApi} from 'src/uses/Api/useGetApi'
import {ref} from 'vue'
import {useGridPagination} from 'src/uses/Grid/useGridPagination'
import {NewsListResponseData} from 'src/models/News/NewsListResponseData'
import {NewsItemPreview} from 'src/models/News/NewsItemPreview'
import {useNewsListFiltersStore} from 'src/stores/News/useNewsListFiltersStore'
import {useProfileStore} from 'src/stores/Login/useProfileStore'
import {UserRoleEnum} from 'src/models/User/UserRoleEnum'

export const useNewsListStore = defineStore('list-news', () => {
  const profileStore = useProfileStore()
  const {profile} = storeToRefs(profileStore)

  const AGENT_NEWS_LIST_URL = 'v1/news/list'
  const ADMIN_NEWS_LIST_URL = 'admin/news/list'

  const {
    loading,
    get,
    errors,
    error,
    status,
    response,
  } = useGetApi<NewsListResponseData>()

  const {
    pagination,
    gridPagination,
    perPageOptions,
    getFromStorage,
    setPagination,
    onRequestPagination,
    setPage,
    setPerPage,
  } = useGridPagination('news-list')

  const filtersStore = useNewsListFiltersStore()
  const {getFiltersQuery} = filtersStore

  const items = ref(<NewsItemPreview[]>[])

  const load = async () => {
    if (!profile.value) {
      return
    }
    await getFromStorage()

    const url = [
      UserRoleEnum.Admin,
    ].includes(profile.value?.role)
      ? ADMIN_NEWS_LIST_URL
      : AGENT_NEWS_LIST_URL

    await get(
      url,
      {
        page: pagination.value?.page,
        perpage: pagination.value?.per_page,
        ...getFiltersQuery()
      }
    )

    if (status.value === 200 && response && response.value?.items) {
      items.value = response.value?.items
      setPagination(response.value?.pagination)
    }
  }

  return {
    loading,
    get,
    errors,
    error,
    status,
    items,
    pagination,
    gridPagination,
    perPageOptions,
    onRequestPagination,
    load,
    setPage,
    setPerPage,
  }
})
