<template>
  <q-btn
    v-if="!props.itemId"
    color="primary"
    icon-right="svguse:icons.svg?2#app-plus-1"
    label="Добавить контрагента"
    style="padding: 13px 17px"
    no-caps
    @click="open"
  />
  <q-btn
    v-if="props.itemId"
    size="sm"
    color="blue-grey-4"
    icon="svguse:icons.svg?2#app-pencil-alt"
    round
    flat
    @click="open"
  />
  <q-dialog
    v-model="dialog"
  >
    <q-card
      class="q-mt-md bg-blue-1"
      style="min-width: 400px; max-width: 800px; border-radius: 4px !important;"
    >
      <q-card-section>
        <div class="modal-title q-pb-none">
          {{ itemId ? 'Редактировать контрагента' : 'Создать контрагента' }}
        </div>
      </q-card-section>
      <q-card-section
        class="bg-blue-grey-10 text-white row items-center q-py-lg"
      >
        <div class="rounded-borders q-pa-sm bg-blue-grey-9">
          <q-icon
            name="svguse:icons.svg?2#app-information-circle"
            size="sm"
            color="orange-6"
          />
        </div>
        <div class="col q-pl-sm text-wgite">
          Укажите данные контрагента и загрузите шаблон для формирования согласий ОПД
        </div>
      </q-card-section>
      <q-card-section class="white-bg-field">
        <q-form
          v-if="contractorData"
          style="max-height: 70vh; overflow: auto; margin: 0 -16px; padding: 0 16px;"
        >
          <div class="row q-col-gutter-md">
            <div class="col-6">
              <q-input
                v-model="contractorData.inn"
                class="field-with-top-label"
                :loading="loading"
                :error-message="getErrorMessage('inn')"
                :error="hasError('inn')"
                label="ИНН"
                outlined
                hide-hint
                hide-bottom-space
                dense
                @blur="validation.inn.$validate()"
                @focus="clearError('inn')"
              />
            </div>
            <div class="col-6">
              <q-input
                v-model="contractorData.title"
                class="field-with-top-label"
                :loading="loading"
                :error-message="getErrorMessage('title')"
                :error="hasError('title')"
                label="Название"
                outlined
                hide-hint
                hide-bottom-space
                dense
                @blur="validation.title.$validate()"
                @focus="clearError('title')"
              />
            </div>
            <div class="col-12">
              <q-input
                v-model="contractorData.manager_fio"
                class="field-with-top-label"
                :loading="loading"
                :error-message="getErrorMessage('manager_fio')"
                :error="hasError('manager_fio')"
                label="ФИО менеджера"
                outlined
                hide-hint
                hide-bottom-space
                dense
                @blur="validation.manager_fio.$validate()"
                @focus="clearError('manager_fio')"
              />
            </div>
            <div class="col-12">
              <q-input
                v-model="contractorData.address"
                class="field-with-top-label"
                :loading="loading"
                :error-message="getErrorMessage('address')"
                :error="hasError('address')"
                label="Адрес"
                outlined
                hide-hint
                hide-bottom-space
                dense
                @blur="validation.address.$validate()"
                @focus="clearError('address')"
              />
            </div>
            <div class="col-12">
              <q-list
                v-if="contractorData.template"
                class="q-mt-sm q-mb-sm"
              >
                <q-item
                  class="contractor-file-item q-mt-xs"
                >
                  <q-item-section avatar>
                    <q-icon
                      name="svguse:icons.svg?2#app-article-bold"
                      color="blue-grey-3"
                    />
                  </q-item-section>
                  <q-item-section class="overflow-hidden">Согласие ОПД {{ contractorData.title }}</q-item-section>
                  <q-item-section side>
                    <q-icon
                      class="document-download-icon cursor-pointer"
                      name="svguse:icons.svg?2#app-trash"
                      color="blue-gray-3"
                      @click="removeFile()"
                    />
                  </q-item-section>
                </q-item>
              </q-list>
              <file-select-component
                v-if="!contractorData.template"
                :input-text="'или перетащите файлы в эту область для загрузки'"
                accept="DOC,DOCX"
                @file:selected="onFileSelected"
              />
              <div
                v-if="hasError('template')"
                class="text-red"
              >
                {{ getErrorMessage('template') }}
              </div>
            </div>
          </div>
        </q-form>
      </q-card-section>
      <q-card-actions
        class="row justify-end q-py-md"
        style="background: #D5EAFA;"
      >
        <q-btn
          v-close-popup
          size="16px"
          padding="sm lg"
          label="Отмена"
          :disable="loading"
          unelevated
          no-caps
        />
        <q-btn
          padding="sm lg"
          size="16px"
          color="blue-7"
          label="Сохранить"
          :loading="loading"
          unelevated
          no-caps
          @click="handleSubmit"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
import {withDefaults} from 'vue'
import {useDialog} from 'src/uses/Dialogs/useDialog'
import {useValidation} from 'src/uses/useValidation'
import {useContractorsCreateUpdate} from 'src/uses/Contractors/useContractorsCreateUpdate'
import FileSelectComponent from 'components/FileSelectComponent.vue'

const props = withDefaults(
  defineProps<{
    itemId?: string
  }>(),
  {
    itemId: undefined
  }
)

const emit = defineEmits<{
  (e: 'success'): void,
}>()

const {
  contractorData,
  loading,
  validation,
  errors,
  itemId,
  status,
  create,
  update,
  removeFile,
  loadContractorItem,
  clearData,
} = useContractorsCreateUpdate()

const {dialog, show, hide} = useDialog()

const open = async () => {
  clearAllErrors()
  clearData()
  show()
  itemId.value = props.itemId
  if (itemId.value) {
    await loadContractorItem(itemId.value)
  }
}

const {
  getErrorMessage,
  hasError,
  clearError,
  clearAllErrors,
} = useValidation(validation, errors)

const success = () => {
  emit('success')
  status.value = undefined
  hide()
}

const onFileSelected = (fileData: File) => {
  contractorData.value.template = fileData
}

const handleSubmit = async () => {
  if (itemId.value) {
    await update()
  } else {
    await create()
  }

  if (status.value === 200 || status.value === 201) {
    success()
  }
}
</script>
<style lang="scss">
.contractor-file-item {
  background: #FFFFFF;
  border-radius: 6px;
}
</style>
