import {ref} from 'vue'

export const useScrollTo = () => {
  const scrollTopFix = 100
  const root = ref<Element>()

  const getData = (element: HTMLElement) => {
    const scrollTop = root.value ? root.value.scrollTop : window.scrollY
    const scrollLeft = root.value ? root.value.scrollLeft : window.scrollX
    const windowHeight = root.value ? root.value.clientHeight : window.innerHeight
    const windowWidth = root.value ? root.value.clientWidth : window.innerWidth
    const {
      bottom,
      height,
      left,
      right,
      top,
      width,
    } = element.getBoundingClientRect()

    if (root.value) {
      const rootRect = root.value.getBoundingClientRect()

      return {
        scrollTop,
        scrollLeft,
        windowHeight,
        windowWidth,
        viewportBottom: bottom - rootRect.top,
        viewportLeft: left - rootRect.left,
        viewportRight: right - rootRect.left,
        viewportTop: top - rootRect.top,
        width,
        height,
      }
    }

    return {
      scrollTop,
      scrollLeft,
      windowHeight,
      windowWidth,
      viewportBottom: bottom,
      viewportLeft: left,
      viewportRight: right,
      viewportTop: top,
      width,
      height,
    }
  }

  const canBeFullDisplayedByX = (element: HTMLElement) => {
    const {
      windowWidth,
      width,
    } = getData(element)

    return width <= windowWidth
  }

  const canBeFullDisplayedByY = (element: HTMLElement) => {
    const {
      windowHeight,
      height,
    } = getData(element)

    return height <= windowHeight
  }

  const alreadyDisplayedByX = (element: HTMLElement) => {
    if (!canBeFullDisplayedByX(element)) {
      return false
    }

    const {
      windowWidth,
      viewportLeft,
      viewportRight,
    } = getData(element)

    return viewportLeft >= 0
      && viewportRight <= windowWidth
  }

  const alreadyDisplayedByY = (element: HTMLElement) => {
    if (!canBeFullDisplayedByY(element)) {
      return false
    }

    const {
      windowHeight,
      viewportTop,
      viewportBottom,
    } = getData(element)

    return viewportTop >= 0
      && viewportBottom <= windowHeight - scrollTopFix
  }

  const alreadyDisplayed = (element: HTMLElement) => {
    if (!canBeFullDisplayedByX(element) || !canBeFullDisplayedByY(element)) {
      return false
    }

    return alreadyDisplayedByX(element) && alreadyDisplayedByY(element)
  }

  const getNewScrollTop = (element: HTMLElement) => {
    const {
      scrollTop,
      viewportTop,
    } = getData(element)

    if (alreadyDisplayedByY(element)) {
      return scrollTop
    }

    return scrollTop + viewportTop - scrollTopFix
  }

  const getNewScrollLeft = (element: HTMLElement) => {
    const {
      scrollLeft,
      viewportLeft,
    } = getData(element)

    if (alreadyDisplayedByX(element)) {
      return scrollLeft
    }

    return scrollLeft + viewportLeft
  }

  const scrollToInvisibleElement = (element: HTMLElement) => {
    if (!element || alreadyDisplayed(element)) {
      return
    }

    if (root.value) {
      root.value.scrollTo({
        top: getNewScrollTop(element),
        left: getNewScrollLeft(element),
        behavior: 'smooth',
      })

      return
    }

    window.scrollTo({
      top: getNewScrollTop(element),
      left: getNewScrollLeft(element),
      behavior: 'smooth',
    })
  }

  return {
    scrollToInvisibleElement,
    root,
  }
}
