<template>
  <q-chip
    :class="`text-bold q-pa-sm ${enabledEBG ? 'enabledProduct' : ''}`"
    :color="enabledEBG ? 'blue-2' : 'blue-grey-1'"
    :text-color="enabledEBG ?'blue-7' : 'blue-grey-4'"
    clickable
    @click="enabledEBG ? deleteProductHandle(ProductEnum.EBG) : addProductHandle(ProductEnum.EBG)"
  >
    ЭБГ
    <q-icon
      class="enabledIcon q-ml-xs"
      size="sm"
      :name="enabledEBG ? 'svguse:icons.svg?2#app-check-circle-bold' : 'svguse:icons.svg?2#app-plus-circle-bold'"
    />
    <q-icon
      class="deleteIcon q-ml-xs"
      size="sm"
      color="red"
      name="svguse:icons.svg?2#app-x-circle-bold"
    />
  </q-chip>
  <q-chip
    :class="`text-bold q-pa-sm ${enabledRKO ? 'enabledProduct' : ''}`"
    :color="enabledRKO ? 'blue-2' : 'blue-grey-1'"
    :text-color="enabledRKO ?'blue-7' : 'blue-grey-4'"
    clickable
    @click="enabledRKO ? deleteProductHandle(ProductEnum.RKO) : addProductHandle(ProductEnum.RKO)"
  >
    РКО
    <q-icon
      class="enabledIcon q-ml-xs"
      size="sm"
      :name="enabledRKO ? 'svguse:icons.svg?2#app-check-circle-bold' : 'svguse:icons.svg?2#app-plus-circle-bold'"
    />
    <q-icon
      class="deleteIcon q-ml-xs"
      size="sm"
      color="red"
      name="svguse:icons.svg?2#app-x-circle-bold"
    />
  </q-chip>
  <q-chip
    :class="`text-bold q-pa-sm ${enabledKIK ? 'enabledProduct' : ''}`"
    :color="enabledKIK ? 'blue-2' : 'blue-grey-1'"
    :text-color="enabledKIK ?'blue-7' : 'blue-grey-4'"
    clickable
    @click="enabledKIK ? deleteProductHandle(ProductEnum.KIK) : addProductHandle(ProductEnum.KIK)"
  >
    КИК
    <q-icon
      class="enabledIcon q-ml-xs"
      size="sm"
      :name="enabledKIK ? 'svguse:icons.svg?2#app-check-circle-bold' : 'svguse:icons.svg?2#app-plus-circle-bold'"
    />
    <q-icon
      class="deleteIcon q-ml-xs"
      size="sm"
      color="red"
      name="svguse:icons.svg?2#app-x-circle-bold"
    />
  </q-chip>
</template>

<script setup lang="ts">
import {computed} from 'vue'
import {BankItem} from 'src/models/Banks/BankItem'
import {ProductEnum} from 'src/models/Applications/ProductEnum'
import {useAddBankProduct} from 'src/uses/Bank/useAddBankProduct'
import {useDeleteBankProduct} from 'src/uses/Bank/useDeleteBankProduct'

const props = defineProps<{
  bank: BankItem,
}>()

const emit = defineEmits<{
  (e: 'change:product'): void,
}>()

const addBankProduct = useAddBankProduct()
const {
  status,
  addProduct,
} = addBankProduct

const deleteBankProduct = useDeleteBankProduct()
const {
  status: deleteStatus,
  deleteProduct,
} = deleteBankProduct

const enabledEBG = computed(() => {
  if (!props.bank?.products) {
    return false
  }

  return Object.values(props.bank.products).find(product => product.type === ProductEnum.EBG)
})

const enabledKIK = computed(() => {
  if (!props.bank.products) {
    return false
  }

  return Object.values(props.bank.products).find(product => product.type === ProductEnum.KIK)
})

const enabledRKO = computed(() => {
  if (!props.bank?.products) {
    return false
  }

  return Object.values(props.bank.products).find(product => product.type === ProductEnum.RKO)
})

const addProductHandle = async (product: ProductEnum) => {
  await addProduct(product, props.bank.id)
  if (status.value === 201) {
    emit('change:product')
  }
}

const deleteProductHandle = async (productType: ProductEnum) => {
  if (!props.bank?.products) {
    return false
  }
  const product = Object.values(props.bank.products).find(product => product.type === productType)

  if (!product?.id) {
    return false
  }
  await deleteProduct(product.id)
  if (deleteStatus.value === 200) {
    emit('change:product')
  }
}
</script>
<style lang="scss">
.enabledProduct{
  &:hover {
    .enabledIcon {
      display: none;
    }
    .deleteIcon {
      display: inline-block;
    }
  }
}
.deleteIcon {
  display: none;
}

</style>
