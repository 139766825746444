import {ref} from 'vue'
import {DocumentSignInfo} from 'src/models/Profile/Documents/Document'
import {useGetApi} from 'src/uses/Api/useGetApi'

export const useDocumentSignInfo = () => {
  const {
    error,
    errors,
    get,
    status,
    response,
    loading,
  } = useGetApi<DocumentSignInfo[]>()

  const docSignInfo = ref<DocumentSignInfo[]>([])

  const load = async (appId: string, docId: string) => {
    await get(`v1/control-center/${appId}/documents/${docId}/sign/info`)

    if (status.value === 200 && response.value) {
      docSignInfo.value = response.value
    }
  }

  return {
    error,
    errors,
    status,
    response,
    loading,
    docSignInfo,
    load,
  }
}
