<template>
  <q-tabs
    v-if="props.tabs.length > 1"
    :model-value="tab"
    class="productsItemTabs q-mx-md"
    align="left"
    active-color="white"
    active-bg-color="blue-7"
    inline-label
    stretch
    no-caps
    @update:model-value="onUpdate"
  >
    <q-tab
      v-if="hasEbg"
      :name="products.EBG"
      label="ЭБГ"
      icon="svguse:/icons.svg#ebg"
    >
      <q-icon
        v-if="ebgFilled"
        name="svguse:icons.svg?2#app-check"
        color="positive"
        size="xs"
      />
    </q-tab>
    <q-tab
      v-if="hasKik"
      :name="products.KIK"
      label="Кредит"
      icon="svguse:/icons.svg#kik"
    >
      <q-icon
        v-if="kikFilled"
        name="svguse:icons.svg?2#app-check"
        color="positive"
        size="xs"
      />
    </q-tab>
  </q-tabs>
</template>

<script setup lang="ts">
import {ProductEnum} from 'src/models/Applications/ProductEnum'
import {computed, ref, watch} from 'vue'

const props = defineProps<{
  modelValue: ProductEnum | null | undefined,
  tabs: ProductEnum[],
  ebgFilled: boolean,
  kikFilled: boolean,
}>()

const emit = defineEmits<{
  (e: 'update:model-value', value: ProductEnum): void,
}>()

const products = ProductEnum
const tab = ref<ProductEnum | null | undefined>(undefined)

const hasEbg = computed(() => props.tabs.find(t => t === ProductEnum.EBG))
const hasKik = computed(() => props.tabs.find(t => t === ProductEnum.EBG))

const onUpdate = (payload: ProductEnum) => {
  emit('update:model-value', payload)
}

watch(
  props,
  () => {
    tab.value = props.modelValue
  },
  {immediate: true, deep: true}
)
</script>

<style lang="scss">
.productsItemTabs {
  margin-bottom: 10px;

  .q-tab {
    background: $blue-grey-1;
    border-right: 1px solid $blue-grey-2;
    border-color: transparent !important;
    border-radius: 6px;
    margin-right: 2px;
    min-width: calc(50% - 2px);

    &.bg-blue-7 {
      background: $blue-1 !important;
      color: $blue-7 !important;
    }

    &.disabled {
      opacity: 1 !important;

      .q-tab__content {
        opacity: 0.6;
      }
    }

    .q-tab__content {
      height: 70px;
    }

    .q-tab__indicator {
      display: none;
    }
  }
}
</style>
