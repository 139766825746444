import {usePutApi} from 'src/uses/Api/usePutApi'
import {ProductContractorItem} from 'src/models/Products/ProductContractorItem'
import {ref} from 'vue'
import {useContractorsGridStore} from 'stores/Contractors/useContractorsGridStore'
import {storeToRefs} from 'pinia'
import {useProductContractorsList} from 'src/uses/Products/useProductContractorsList'

export interface ProductContractorsRequestData {
  contractors: ProductContractorItem[]
}

export const useUpdateProductContractorsList = () => {
  const {
    error,
    errors,
    put,
    status,
    response,
    loading,
  } = usePutApi<undefined, ProductContractorsRequestData>()

  const {
    load: loadProductContractors,
    contractors: productContractors,
  } = useProductContractorsList()

  const contractorsGridStore = useContractorsGridStore()
  const { load: loadContractorsList } = contractorsGridStore
  const { items: contractorsListForSelect } = storeToRefs(contractorsGridStore)

  const contractorsList = ref<ProductContractorItem[]>([])
  const selected = ref<ProductContractorItem>()

  const update = async (productId: string) => {
    const data = {
      contractors: contractorsList.value.map((item, index) => {
        return {
          id: item.id,
          title: item.title,
          number: index,
        }
      })
    }
    await put(
      `v2/products/${productId}/contractors`,
      data
    )
  }

  const init = async (productId: string) => {
    await loadProductContractors(productId)
    if (productContractors.value) {
      contractorsList.value = productContractors.value
    }

    await loadContractorsList()
  }

  const addContractor = () => {
    if (!selected.value) {
      return
    }
    contractorsList.value.push(selected.value)
    selected.value = undefined
  }

  const removeDocument = (contractor: ProductContractorItem) => {
    contractorsList.value = contractorsList.value.filter(c => c.id !== contractor.id)
  }

  return {
    error,
    errors,
    put,
    status,
    response,
    loading,
    update,
    init,
    addContractor,
    removeDocument,
    selected,
    contractorsList,

    contractorsListForSelect,
    loadContractorsList
  }
}
