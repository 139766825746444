import {useDialog} from 'src/uses/Dialogs/useDialog'
import {ref, Ref} from 'vue'
import {ChangeStatusPayload} from 'src/models/Status/ChangeStatusPayload'
import {ControlCenterHeader} from 'src/models/Applications/ControlCenter/ControlCenterHeader'
import {useHeaderDataFromStore} from 'src/uses/Applications/ControlCenter/useHeaderDataFromStore'
import {ApplicationSubStatusEnum} from 'src/models/Applications/ApplicationSubStatusEnum'

export const useOnReleaseSend = (payload: Ref<ChangeStatusPayload | undefined>, realChangeStatus: () => Promise<void>) => {
  const {
    dialog: onReleaseSendDialog,
    show: showOnReleaseSendDialog,
    hide: hideOnReleaseSendDialog,
  } = useDialog()

  const {
    getHeaderData
  } = useHeaderDataFromStore()

  const headerData = ref<ControlCenterHeader | undefined>()

  const needShowDialog = async () => {
    if (!payload.value) {
      return false
    }

    headerData.value = await getHeaderData(payload.value.id)
    if (!headerData.value) {
      return false
    }

    if (payload.value?.initiator !== 'send_to_production') {
      return false
    }

    if (headerData.value?.need_specify_guarantee_registry) {
      return true
    }

    return headerData.value?.sub_status === ApplicationSubStatusEnum.bg_project_customer_agreement
      && !headerData.value?.delivery_data_filled
      //&& headerData.value?.integration?.enabled
  }

  const isOnlyRegistry = () => {
    return headerData.value?.need_specify_guarantee_registry && headerData.value?.delivery_data_filled
  }

  const isOnlyDelivery = () => {
    return !headerData.value?.need_specify_guarantee_registry && !headerData.value?.delivery_data_filled
  }

  const isRegistryAndDelivery = () => {
    return headerData.value?.need_specify_guarantee_registry && !headerData.value?.delivery_data_filled
  }

  const onChangeReleaseStatus = async () => {
    if (!payload.value) {
      return
    }

    await realChangeStatus()
    hideOnReleaseSendDialog()
  }

  return {
    onReleaseSendDialog,
    showOnReleaseSendDialog,
    hideOnReleaseSendDialog,
    needShowDialog,
    onChangeReleaseStatus,
    isOnlyRegistry,
    isOnlyDelivery,
    isRegistryAndDelivery,
  }
}
