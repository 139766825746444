import {useGetApi} from 'src/uses/Api/useGetApi'
import {computed, ref} from 'vue'
import {
  ProductStopFactorParamOptionsWithChild,
  ProductStopFactorParamsItem,
  ProductStopFactorParamsItemOperatorEnum
} from 'src/models/Products/StopFactorParamsItem'

const operators: Record<ProductStopFactorParamsItemOperatorEnum, string> = {
  [ProductStopFactorParamsItemOperatorEnum.LT]: '<',
  [ProductStopFactorParamsItemOperatorEnum.EQ]: '=',
  [ProductStopFactorParamsItemOperatorEnum.GT]: '>',
}

export const useProductStopFactorsParams = () => {
  const {
    loading,
    get,
    errors,
    error,
    status,
    response,
  } = useGetApi<ProductStopFactorParamsItem[]>()

  const productStopFactorsParams = ref<ProductStopFactorParamsItem[]>()
  const operatorOptions = ref(
    Object.values(ProductStopFactorParamsItemOperatorEnum).map(key => {
      return {
        label: operators[key],
        value: key
      }
    })
  )

  const loadProductStopFactorsParams = async () => {
    await get('v1/admin/product/factor/params')

    if (status.value === 200 && response && response.value) {
      productStopFactorsParams.value = response.value
    }
  }

  const paramComparisonOptions = computed(() => {
    if (!productStopFactorsParams.value) {
      return {}
    }

    const params = JSON.parse(JSON.stringify(productStopFactorsParams.value))

    return Object.fromEntries(
      Object.keys(productStopFactorsParams.value).map((key) => {
        return [
          key,
          params[key].relations.length === 1
        ]
      })
    )
  })

  const paramValueTypesAndOptions = computed(() => {
    if (!productStopFactorsParams.value) {
      return {}
    }

    const params = JSON.parse(JSON.stringify(productStopFactorsParams.value))

    return Object.fromEntries(
      Object.keys(productStopFactorsParams.value).map((key) => {
        if (!Array.isArray(params[key].options)) {
          const options = []
          for (const value in params[key].options) {
            options.push({
              label: params[key].options[value],
              value,
            })
          }
          return [
            key,
            {
              type: params[key].type,
              multiple: params[key].multiple,
              options
            }
          ]
        } else {
          return [
            key,
            {
              type: params[key].type,
              multiple: params[key].multiple,
              options: params[key].options ? flatOptions(params[key].options) : null
            }
          ]
        }
      })
    )
  })

  const mapOptionsToKeyValue = (options: ProductStopFactorParamOptionsWithChild[]) => {
    return options
      .map(option => {
        if (option.code && option.name) {
          return {
            label: option.name,
            value: option.code,
          }
        }

        return option
      })
  }

  const flatOptions = (options: ProductStopFactorParamOptionsWithChild[]) => {
    return mapOptionsToKeyValue(getOptionsWithChild(options))
  }

  const getOptionsWithChild = (options: ProductStopFactorParamOptionsWithChild[]) => {
    let flatOptions: ProductStopFactorParamOptionsWithChild[] = []
    for (const option of options) {
      flatOptions.push(option)
      flatOptions = flatOptions.concat(getOptionsWithChild(option.child || []))
    }

    return flatOptions
  }

  const stopFactorsParamsOptions = computed(() => {
    if (!productStopFactorsParams.value) {
      return []
    }

    const params = JSON.parse(JSON.stringify(productStopFactorsParams.value))

    return Object.keys(productStopFactorsParams.value)
      .map((key) => {
        return {
          label: params[key].title,
          value: key,
        }
      })
  })

  return {
    loading,
    status,
    get,
    errors,
    error,
    productStopFactorsParams,
    paramValueTypesAndOptions,
    paramComparisonOptions,
    stopFactorsParamsOptions,
    operatorOptions,
    loadProductStopFactorsParams,
  }
}
