import {useRequestApi} from './useRequestApi'
import {DeleteReturnInterface} from 'src/models/Api/DeleteReturnInterface'

export const useDeleteApi: <T = never>(showError?: boolean) => DeleteReturnInterface<T> = <T>(showErrors = false) => {
  const {
    response,
    error,
    errors,
    status,
    loading,
    request,
    abort,
    setHeader,
    headers,
    progressEvent,
  } = useRequestApi<T>(showErrors)

  const deleteRequest = async (url: string, data = {}) => {
    const query = Object.entries<string>(data)
      .map(entry => {
        if (Array.isArray(entry[1])) {
          return entry[1].map((item) => `${entry[0]}[]=${item}`)
            .join('&')
        }
        return `${entry[0]}=${entry[1]}`
      })
    const queryString = query.length > 0 ? `?${query.join('&')}` : ''

    await request(`${url}${queryString}`, 'DELETE')
  }

  return {
    response,
    error,
    errors,
    status,
    loading,
    request,
    abort,
    setHeader,
    headers,
    progressEvent,
    deleteRequest,
  }
}
