import {useOptionsFromObject} from 'src/uses/useOptionsFromObject'
import {CreditTypeEnum} from 'src/models/Applications/CreditTypeEnum'

const types: Record<CreditTypeEnum, string> = {
  [CreditTypeEnum.contract]: 'Контрактный',
  [CreditTypeEnum.revolving]: 'Оборотный',
}

export const useCreditType = () => {
  const {getOptionsFromObject} = useOptionsFromObject()
  const options = getOptionsFromObject(types)
  const getCreditTypeLabel = (type: CreditTypeEnum): string => {
    return types[type] || ''
  }

  return {
    getCreditTypeLabel,
    options,
  }
}
