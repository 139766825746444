import {defineStore, storeToRefs} from 'pinia'
import {useApplicationFormFillMain} from 'src/uses/Applications/Form/fill/useApplicationFormFillMain'
import {useApplicationFormFillFinance} from 'src/uses/Applications/Form/fill/useApplicationFormFillFinance'
import {ApplicationItemSectionsEnum} from 'src/models/Enums/ApplicationItemSectionsEnum'
import {FillStatusEnum} from 'src/models/Enums/FillStatusEnum'
import {useApplicationFormFillClient} from 'src/uses/Applications/Form/fill/useApplicationFormFillClient'
import {useApplicationFormFillDocuments} from 'src/uses/Applications/Form/fill/useApplicationFormFillDocuments'
import {computed} from 'vue'
import {ApplicationSubStatusEnum} from 'src/models/Applications/ApplicationSubStatusEnum'
import {
  useApplicationControlCenterItemStore
} from 'src/stores/Applications/ControlCenter/useApplicationControlCenterItemStore'

export const useApplicationControlCenterItemFillStore = (appId: string) => defineStore(`application-control-center-item-fill-${appId}`, () => {
  const applicationControlCenterItemStore = useApplicationControlCenterItemStore(appId)()
  const {
    banks,
    mainVisibleFields,
    financeVisible,
    client,
    visibleDocuments,
  } = storeToRefs(applicationControlCenterItemStore)

  const {
    countAllMainFields,
    countErrorsMainFields,
    fillMainByBanks,
    mainErrorFields,
  } = useApplicationFormFillMain(mainVisibleFields, banks)

  const {
    financeFieldsHasErrors,
    periodsWithErrors,
    countAllFinanceFields,
    countErrorsFinanceFields,
    fillFinanceByBanks,
    financeErrorFields,
  } = useApplicationFormFillFinance(financeVisible, banks)

  const {
    clientHasErrors,
    clientHeadFieldsWithErrors,
    clientCommonFieldsWithErrors,
    clientFoundersFieldsWithErrors,
    countAllClientFields,
    countErrorsClientFields,
    fillClientByBanks,
    founderHasError,
  } = useApplicationFormFillClient(client, banks)

  const {
    documentsHasErrors,
    countAllDocumentsFields,
    countErrorsDocumentsFields,
    fillDocumentsByBanks,
    passportsDocumentsErrorsFields,
    financialDocumentsErrorsFields,
    additionalDocumentsErrorsFields,
  } = useApplicationFormFillDocuments(visibleDocuments, banks)

  const getSectionFillStatus = (name: ApplicationItemSectionsEnum): FillStatusEnum => {
    if (name === ApplicationItemSectionsEnum.MAIN) {
      return countErrorsMainFields.value > 0
        ? FillStatusEnum.IN_PROGRESS
        : FillStatusEnum.OK
    }

    if (name === ApplicationItemSectionsEnum.FINANCIAL) {
      return financeFieldsHasErrors.value ? FillStatusEnum.IN_PROGRESS : FillStatusEnum.OK
    }

    if (name === ApplicationItemSectionsEnum.CLIENT) {
      return clientHasErrors.value ? FillStatusEnum.IN_PROGRESS : FillStatusEnum.OK
    }

    if (name === ApplicationItemSectionsEnum.DOCUMENTS) {
      return documentsHasErrors.value ? FillStatusEnum.IN_PROGRESS : FillStatusEnum.OK
    }

    return FillStatusEnum.IN_PROGRESS
  }

  const fillPercent = computed(() => {
    const all = countAllMainFields.value
      + countAllFinanceFields.value
      + countAllClientFields.value
      + countAllDocumentsFields.value

    const errors = countErrorsMainFields.value
      + countErrorsFinanceFields.value
      + countErrorsClientFields.value
      + countErrorsDocumentsFields.value

    const filled = all - errors

    return Math.floor(filled / all * 100)
  })

  const fillByBanks = computed(() => {
    return banks.value.map(bank => {
      const mainBank = fillMainByBanks.value
        .find(b => b.bank_id === bank.bank_id)
      const financeBank = fillFinanceByBanks.value
        .find(b => b.bank_id === bank.bank_id)
      const documentsBank = fillDocumentsByBanks.value
        .find(b => b.bank_id === bank.bank_id)
      const clientBank = fillClientByBanks.value
        .find(b => b.bank_id === bank.bank_id)

      return {
        ...bank,
        total: (mainBank ? mainBank.total : 0)
          + (financeBank ? financeBank.total : 0)
          + (documentsBank ? documentsBank.total : 0)
          + (clientBank ? clientBank.total : 0),
        error: (mainBank ? mainBank.error : 0)
          + (financeBank ? financeBank.error : 0)
          + (documentsBank ? documentsBank.error : 0)
          + (clientBank ? clientBank.error : 0),
      }
    })
  })

  const atLeastOneDraftBankIsOk = computed(() => {
    return fillByBanks.value.find(b => b.error === 0 && b.sub_status === ApplicationSubStatusEnum.draft)
  })

  return {
    countAllMainFields,
    countErrorsMainFields,
    mainErrorFields,

    periodsWithErrors,
    countAllFinanceFields,
    countErrorsFinanceFields,
    financeErrorFields,

    clientHasErrors,
    clientHeadFieldsWithErrors,
    clientCommonFieldsWithErrors,
    clientFoundersFieldsWithErrors,
    countAllClientFields,
    countErrorsClientFields,
    founderHasError,

    documentsHasErrors,
    countAllDocumentsFields,
    countErrorsDocumentsFields,
    passportsDocumentsErrorsFields,
    financialDocumentsErrorsFields,
    additionalDocumentsErrorsFields,

    getSectionFillStatus,
    fillPercent,
    fillByBanks,

    atLeastOneDraftBankIsOk,
  }
})
