<template>
  <q-table
    class="full-width"
    style="max-height: 40vh;"
    :rows="props.notSignedDocuments"
    :columns="columns"
    :pagination="{rowsPerPage: 0}"
    separator="horizontal"
    hide-pagination
    virtual-scroll
    flat
  />
</template>

<script setup lang="ts">
import {ref} from 'vue'
import {ControlCenterHeaderNotSignedDocument} from 'src/models/Applications/ControlCenter/ControlCenterHeader'
import {QTableColumn} from 'src/models/Grid/GridColumnSettings'

const props = defineProps<{
  notSignedDocuments: ControlCenterHeaderNotSignedDocument[]
}>()

const columns = ref<QTableColumn[]>([
  {
    name: 'title',
    field: 'title',
    required: true,
    label: 'Название документа',
    align: 'left',
    classes: 'text-no-wrap',
  },
  {
    name: 'date',
    field: 'date',
    required: true,
    label: 'Дата загрузки',
    align: 'left',
    classes: 'text-no-wrap',
  }
])
</script>

<style scoped lang="scss">

</style>
