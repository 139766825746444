<template>
  <q-btn
    color="primary"
    icon-right="svguse:icons.svg?2#app-plus-1"
    label="Добавить сотрудника"
    class="q-ml-md full-width"
    style="padding: 13px 16px;"
    unelevated
    no-caps
    @click="open"
  />
  <q-dialog
    v-model="dialog"
    :maximized="$q.platform.is.mobile"
  >
    <q-card class="modal-blue-background">
      <q-card-section class="modal-title row items-center q-pb-none q-pl-lg">
        <div class="col">Создать учетную запись пользователя</div>
        <q-btn
          v-if="$q.platform.is.mobile"
          v-close-popup
          class="modal-close-icon"
          icon="svguse:icons.svg?2#svguse:icons.svg?2#app-arrow-left-bold"
          flat
          round
          dense
        />
      </q-card-section>
      <q-card-section>
        <info-banner
          text="Старший сотрудник будет видеть все заявки"
          caption="Сотрудник будет видеть только те заявки, которые создал самостоятельно"
          icon="svguse:icons.svg?2#app-exclamation-circle"
        />
      </q-card-section>
      <q-card-section>
        <div class="row q-col-gutter-lg white-bg-field">
          <q-form>
            <div class="row q-col-gutter-lg white-bg-field">
              <div class="col-xs-12 col-sm-6 q-mb-sm">
                <q-input
                  v-model="employeeCreateData.name"
                  class="field-with-top-label"
                  :loading="loading"
                  :error-message="getErrorMessage('name')"
                  :error="hasError('name')"
                  label="ФИО"
                  outlined
                  hide-hint
                  hide-bottom-space
                  dense
                  @blur="validation.name.$validate()"
                  @focus="clearError('name')"
                />
              </div>
              <div class="col-xs-12 col-sm-6 q-mb-sm">
                <q-input
                  v-model="employeeCreateData.email"
                  class="field-with-top-label"
                  :loading="loading"
                  :error-message="getErrorMessage('email')"
                  :error="hasError('email')"
                  label="E-mail"
                  outlined
                  hide-hint
                  hide-bottom-space
                  dense
                  @blur="validation.email.$validate()"
                  @focus="clearError('email')"
                />
              </div>
              <div class="col-xs-12 col-sm-6 q-mb-sm">
                <q-input
                  v-model="employeeCreateData.phone"
                  v-mask="'+7 (9##) ###-##-##'"
                  class="field-with-top-label"
                  :loading="loading"
                  :error-message="getErrorMessage('phone')"
                  :error="hasError('phone')"
                  type="tel"
                  label="Телефон"
                  unmasked-value
                  fill-mask="0"
                  outlined
                  hide-hint
                  hide-bottom-space
                  dense
                  @blur="validation.phone.$validate()"
                  @focus="clearError('phone')"
                />
              </div>
              <div class="col-xs-12 col-sm-6 q-mb-sm">
                <q-select
                  v-model="employeeCreateData.role"
                  class="field-with-top-label"
                  :options="rolesForSelect"
                  :loading="loading"
                  style="min-width: 200px"
                  map-options
                  label="Роль"
                  outlined
                  hide-hint
                  hide-bottom-space
                  dense
                  emit-value
                />
              </div>
              <div class="col-xs-12 col-sm-6 q-mb-sm">
                <password-help
                  :model-value="showPasswordHelp"
                  :password="employeeCreateData.password || ''"
                >
                  <q-input
                    v-model="employeeCreateData.password"
                    class="field-with-top-label"
                    :loading="loading"
                    :error-message="getErrorMessage('password')"
                    :error="hasError('password')"
                    autocomplete="new-password"
                    type="password"
                    label="Пароль"
                    outlined
                    hide-hint
                    hide-bottom-space
                    dense
                    @blur="validation.password.$validate(); showPasswordHelp = false"
                    @focus="showPasswordHelp = true; clearError('password')"
                  />
                </password-help>
              </div>
              <div class="col-xs-12 col-sm-6 q-mb-sm">
                <password-help
                  :model-value="showPasswordConfirmHelp"
                  :password="employeeCreateData.password_confirm || ''"
                >
                  <q-input
                    v-model="employeeCreateData.password_confirm"
                    class="field-with-top-label"
                    :loading="loading"
                    :error-message="getErrorMessage('password')"
                    :error="hasError('password')"
                    type="password"
                    label="Повторите пароль"
                    outlined
                    hide-hint
                    hide-bottom-space
                    dense
                    @blur="validation.password.$validate(); showPasswordConfirmHelp = false"
                    @focus="showPasswordConfirmHelp = true; clearError('password')"
                  />
                </password-help>
              </div>
            </div>
          </q-form>
        </div>
      </q-card-section>
      <q-card-actions
        v-if="$q.platform.is.mobile"
        align="right"
        class="modal-actions text-primary q-pa-lg row"
      >
        <q-btn
          :loading="loading"
          label="Сохранить"
          class="col-12"
          color="primary"
          style="padding: 17px 28px;"
          unelevated
          no-caps
          @click="handleSubmit()"
        />
        <q-btn
          v-close-popup
          :loading="loading"
          label="Отмена"
          class="col-12"
          style="padding: 17px 28px;"
          unelevated
          flat
          no-caps
          text-color="black"
        />
      </q-card-actions>
      <q-card-actions
        v-else
        align="right"
        class="modal-actions text-primary q-pa-lg"
      >
        <q-btn
          v-close-popup
          :loading="loading"
          label="Отмена"
          class="q-pr-lg"
          style="padding: 17px 28px;"
          unelevated
          flat
          no-caps
          text-color="black"
        />
        <q-btn
          :loading="loading"
          label="Сохранить"
          color="primary"
          style="padding: 17px 28px;"
          unelevated
          no-caps
          @click="handleSubmit()"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
import {onBeforeUnmount, ref} from 'vue'
import {useDialog} from 'src/uses/Dialogs/useDialog'
import {storeToRefs} from 'pinia'
import {useEmployeeCreateStore} from 'src/stores/Profile/Employees/useEmployeeCreateStore'
import {useValidation} from 'src/uses/useValidation'
import PasswordHelp from 'src/components/PasswordHelp.vue'
import InfoBanner from 'src/components/InfoBanner.vue'
import {Notify, useQuasar} from 'quasar'

const employeeCreateStore = useEmployeeCreateStore()
const {
  employeeCreateData,
  loading,
  rolesForSelect,
  validation,
  errors,
  status,
} = storeToRefs(employeeCreateStore)

const {
  save,
} = employeeCreateStore

const {dialog, show, hide} = useDialog()

const open = async () => {
  show()
}

const {
  getErrorMessage,
  hasError,
  clearError
} = useValidation(validation, errors)

const $q = useQuasar()

const showPasswordHelp = ref(false)
const showPasswordConfirmHelp = ref(false)

onBeforeUnmount(() => {
  employeeCreateStore.$reset()
  employeeCreateStore.$dispose()
})

const handleSubmit = async () => {
  await save()
  if (status.value === 201) {
    hide()
    Notify.create({
      type: 'positive',
      message: 'Сохранено',
      position: 'top',
      timeout: 2000,
      textColor: 'white',
    })
  }
}

</script>
