<template>
  <q-btn
    size="sm"
    color="blue-grey-4"
    icon="svguse:icons.svg?2#app-eye"
    round
    flat
    @click="open"
  />
  <q-dialog
    v-model="dialog"
  >
<!--    <div
      v-if="response"
      :style="{height:1200?(600-200)+'px':(800-400>800?800:700-400)+'px'}"
      style="margin-top: 5px;border: 1px solid #a0a0a0;overflow:auto; min-width: 300px">
      <div v-html="excel.SheetActiveTable"></div>
    </div>-->

    <div
      v-if="JsonSheet"
      :style="{height:1200?(600-200)+'px':(800-400>800?800:700-400)+'px'}"
      style="margin-top: 5px;border: 1px solid #a0a0a0;overflow:auto; min-width: 350px"
    >
      <table class="default-table">
        <template v-for="(js, key) in JsonSheet" :key="key">
          <thead v-if="key === 0">
          <tr>
            <th style="background-color: #e9e5e5"></th>
            <th
              v-for="td in js"
              :key="td"
              class="text-left"
            >
              {{ td }}
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-if="key !== 0">
            <td style="background-color: #e9e5e5">{{ key }}</td>
            <td> {{ js['A']}}</td>
            <td> {{ js['B']}}</td>
          </tr>
          </tbody>
        </template>
      </table>
    </div>
  </q-dialog>
</template>

<script setup lang="ts">
import {useDialog} from 'src/uses/Dialogs/useDialog'
import {WorkBook} from 'xlsx'
import * as XLSX from 'xlsx'
import {useListingsDownloadFile} from 'src/uses/Listings/useListingsDownloadFile'
import {useLoadingStore} from 'stores/useLoadingStore'

const props = defineProps<{
  id: string
}>()

const {
  downloadListingsFile,
  status,
  response,
} = useListingsDownloadFile()

export interface ExcelTable {
  workbook?: WorkBook | {Sheets: string[]}
  sheetNames: string[]
  sheetNameActive: string
  SheetActiveTable: string
}

const excel: ExcelTable = {
  // data
  workbook: {Sheets: []},
  // Table Name Collection
  sheetNames: [],
  // activation item
  sheetNameActive: '',
  // Current activation form
  SheetActiveTable: ''
}

let JsonSheet: never[] = []

const getSheetNameTable = (sheetName: string) => {
  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    let worksheet = excel.workbook.Sheets[sheetName]
    worksheet = XLSX.utils.sheet_to_json(worksheet, {header: 'A', rawNumbers: true})
    worksheet = XLSX.utils.json_to_sheet(worksheet)
    JsonSheet = XLSX.utils.sheet_to_json(worksheet, {header: 'A', rawNumbers: true})
    /*let htmlData = XLSX.utils.sheet_to_html(worksheet, {header: '', footer: ''})
    htmlData = htmlData === '' ? htmlData : htmlData.replace(/<table/, '<table class="default-table" border="1px solid #c1c1c1" cellpadding="0" cellspacing="0"')
    htmlData = htmlData === '' ? htmlData : htmlData.replace(/<tr/, '<tr style="background:#e9e5e5"')
    excel.SheetActiveTable = htmlData*/
  } catch (e) {
    excel.SheetActiveTable = '<h4 style="text-align: center">no content yet</h4>'
  }
}

const showDoc = async (id: string) => {
  try {
    await downloadListingsFile(id, true)
    if (status.value === 200) {
      if (!response.value) {
        return
      }
      const reader = new FileReader() //Created a FileReader object, which is used to read file content asynchronously
      //Convert blob to ArrayBuffer object through readAsArrayBuffer
      reader.readAsText(response.value, 'utf-8') //
      reader.onload = (event) => {
        if (!event.target) {
          return
        }
        const workbook = XLSX.read(event.target.result, {type: 'string'})
        const sheetNames = workbook.SheetNames // Worksheet Name Collection
        excel.workbook = workbook
        excel.sheetNames = sheetNames
        excel.sheetNameActive = sheetNames[0]
        getSheetNameTable(sheetNames[0])
      }
    } else if (status.value === 404) {
      console.log('error')
    } else {
      console.log('Read Server Document Exception')
    }
  } catch (e) {
    console.log(e)
  }
}

const {dialog, show} = useDialog()
const loadingStore = useLoadingStore()

const open = async () => {
  loadingStore.showLoading()
  await showDoc(props.id)
  loadingStore.hideLoading()
  show()
}
</script>
<style lang="scss">
table.default-table {
  /* -moz-border-radius: 5px;
  -webkit-border-radius:5px;
  border-radius:5px; */
  width: 100%;
  border: solid #c1c1c1;
  border-width: 1px 0px 0px 1px;
  font-size: 16px;
  border-collapse: collapse;
  border-spacing: 0;
  background: #fff;
}

table.default-table tbody tr {
  height: 20px;
  line-height: 20px;
  white-space: nowrap;
}

table.default-table tbody tr:hover {
  background-color: #eee;
}

table.default-table tbody tr th, table.default-table tbody tr td {
  padding: 3px 5px;
  text-align: left;
  /* border: 1px solid #ddd; */
  border: solid #c1c1c1;
  border-width: 0px 1px 1px 0px;
  white-space: nowrap;
}

table.default-table tbody tr th {
  font-weight: bold;
  text-align: center;
}

table.default-table tbody tr td a:hover {
  color: #0080c0;
}

table.default-table thead tr {
  background: #e9e5e5;
  border: 1px solid #c1c1c1;
  th {
    border: solid #c1c1c1;
    border-width: 1px 0px 0px 1px;
    font-size: 16px;
    border-collapse: collapse;
    border-spacing: 0;
    font-weight: normal;
    padding: 0 3px;
  }
}
</style>
