import {useOptionsFromObject} from 'src/uses/useOptionsFromObject'
import {DeliveryDataReceiverEnum} from 'src/models/Applications/DeliveryData'

const receivers: Record<DeliveryDataReceiverEnum, string> = {
  [DeliveryDataReceiverEnum.client]: 'Клиент',
  [DeliveryDataReceiverEnum.customer]: 'Заказчик',
  [DeliveryDataReceiverEnum.other]: 'Иное лицо',
}

export const useDeliveryReceiver = () => {
  const {getOptionsFromObject} = useOptionsFromObject()
  const options = getOptionsFromObject(receivers)
  const getReceiverLabel = (receiver: DeliveryDataReceiverEnum): string => {
    return receivers[receiver] || ''
  }

  return {
    getReceiverLabel,
    options,
  }
}
