<template>
  <q-icon
    name="svguse:icons.svg?2#app-pencil-bold"
    color="green-14"
    size="14px"
    style="margin-top: -2px"
    @mouseover="loadSignInfo()"
  >
    <q-tooltip v-if="appId">
      <div v-if="loading">
        Идет загрузка информации...
        <q-circular-progress
          color="white"
          size="12px"
          indeterminate
        />
      </div>
      <div
        v-else
        class="q-gutter-y-lg"
      >
        <template v-if="docSignInfo.length !== 0">
          <div
            v-for="info in docSignInfo"
            :key="info.inn"
          >
            <div
              v-if="info.valid"
              class="q-mb-sm text-green-14"
            >
              <q-icon
                name="svguse:icons.svg?2#app-pencil-bold"
                color="green-14"
                size="14px"
                style="margin-top: -2px"
              />
              Подпись действительна
            </div>
            <div
              v-if="!info.valid"
              class="q-mb-sm text-red-14"
            >
              Срок сертификата истёк
            </div>
            <div>
              ФИО: {{ info.fio }}
            </div>
            <div v-if="info.company">
              Компания: {{ info.company }}
            </div>
            <div v-if="info.inn_le">
              ИНН ЮЛ: {{ info.inn_le }}
            </div>
            <div v-if="info.position">
              Должность: {{ info.position }}
            </div>
            <div v-if="info.inn">
              ИНН: {{ info.inn }}
            </div>
            <div v-if="info.valid_period">
              Срок действия: {{ info.valid_period }}
            </div>
            <div v-if="info.sign_date">
              Дата подписания: {{ info.sign_date }}
            </div>
          </div>
        </template>
      </div>
    </q-tooltip>
  </q-icon>
</template>

<script setup lang="ts">
import {computed} from 'vue'
import {useDocumentSignInfo} from 'src/stores/Applications/useDocumentSignInfo'
import {useRoute} from 'vue-router'

const props = defineProps<{
  docId: string
}>()

const route = useRoute()
const appId = computed(() => {
  if (route.name !== 'application-control-center') {
    return undefined
  }

  return route.params.appId as string
})

const {
  docSignInfo,
  loading,
  load,
} = useDocumentSignInfo()

const loadSignInfo = async () => {
  if (!appId.value) {
    return
  }

  if (docSignInfo.value.length !== 0) {
    return
  }

  await load(appId.value, props.docId)
}
</script>
