import {computed, Ref, ref, watch} from 'vue'
import {Beneficiary, BeneficiaryCreateShort, BeneficiaryShort} from 'src/models/Applications/Beneficiary'
import {RntData} from 'src/models/Applications/New/RntData'
import {ProvisionTypeEnum} from 'src/models/Applications/ProvosionTypeEnum'

export const useApplicationNewBeneficiaries = (
  rntData: Ref<RntData | undefined>,
  provisionType: Ref<ProvisionTypeEnum>,
  oneBg: Ref<boolean>
) => {
  const selectedBeneficiaries = ref<Beneficiary[]>([])

  const beneficiaries = computed<Beneficiary[]>(() => rntData.value
    ? rntData.value?.beneficiaries || []
    : []
  )

  const selectedBeneficiariesSum = computed(() => {
    return selectedBeneficiaries.value
      .map(
        b => ProvisionTypeEnum.Execution === provisionType.value
          ? b.lot_contract_guarantee
          : ProvisionTypeEnum.Participation === provisionType.value
            ? b.lot_guarantee : 0
      )
      .reduce((a, b) => a + b, 0)
  })

  const enabledOneBgForAllBeneficiaries = computed(() => {
    return beneficiaries.value.length > 1
      && provisionType.value !== ProvisionTypeEnum.Participation
  })

  const beneficiariesForRequests = computed(() => {
    if (provisionType.value === ProvisionTypeEnum.Participation) {
      return []
    }

    if (oneBg.value) {
      return [...beneficiaries.value]
    }

    return beneficiaries.value.length === 1
      ? [beneficiaries.value[0]]
      : [...selectedBeneficiaries.value]
  })

  const beneficiariesForProducts = computed(() => {
    return mapForProducts(beneficiariesForRequests.value)
  })

  const beneficiariesForProductsCreate = computed(() => {
    return mapForProductsCreate(beneficiariesForRequests.value)
  })

  const mapForProducts = (b: Beneficiary[]): BeneficiaryShort[] => {
    return b.map((b) => {
      return {
        inn: b.inn,
        lot_contract_guarantee: b.lot_contract_guarantee,
        region: b.region,
      }
    })
  }

  const mapForProductsCreate = (b: Beneficiary[]): BeneficiaryCreateShort[] => {
    return b.map((b) => {
      return {
        inn: b.inn,
        lot_contract_guarantee: b.lot_contract_guarantee,
        region: b.region,
        ogrn: b.ogrn,
        kpp: b.kpp,
        fact_address: b.fact_address,
        ur_address: b.ur_address,
        name: b.name,
        phone: b.phone,
        email: b.email,
      }
    })
  }

  watch(
    beneficiaries,
    () => {
      selectedBeneficiaries.value = []
    }
  )

  return {
    selectedBeneficiaries,
    beneficiaries,
    selectedBeneficiariesSum,
    enabledOneBgForAllBeneficiaries,
    beneficiariesForProducts,
    beneficiariesForProductsCreate,
  }
}
