import {ApplicationFormUpdateBlockEnum} from 'src/models/Applications/Form/ApplicationFormUpdateResponse'
import {
  ApplicationFormDocument,
  ApplicationFormDocumentStatusEnum
} from 'src/models/Applications/Form/ApplicationFormResponseData'

export const useCreateEmptyApplicationFormDocument = () => {
  const create = (sectionType: string): ApplicationFormDocument => {
    return {
      code: '',
      index: null,
      period_year: null,
      period_quarter: null,
      founder_id: null,
      location: {
        block: ApplicationFormUpdateBlockEnum.documents,
        section_type: sectionType,
      },
      invisible: false,
      readonly: false,
      valid: [],
      description: null,
      file_date: null,
      file_name: null,
      file_path: null,
      file_status: ApplicationFormDocumentStatusEnum.new,
      founder_passport_number: null,
      founder_passport_series: null,
      sign_file_name: null,
      sign_file_path: null,
      title: '',
      highlighted: false,
      sign_file_required: false,
      file: undefined,
    }
  }

  return {
    create,
  }
}
