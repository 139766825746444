<template>
  <q-card-section class="q-pb-sm q-pt-lg q-px-lg">
    <div>
      <slot name="type">
        Руководитель
      </slot>
    </div>
    <div
      class="text-h6"
      style="margin-top: -5px;"
    >
      {{ fullName }}
    </div>
  </q-card-section>
</template>

<script setup lang="ts">
import {computed} from 'vue'
import {useClientName} from 'src/uses/Applications/useClientName'
import {ProductFields} from 'src/models/Applications/Form/ProductFields'

const props = defineProps<{
  fields: ProductFields,
}>()
const fields = computed(() => props.fields)
const {
  fullName,
} = useClientName(fields)
</script>

<style lang="scss">

</style>
