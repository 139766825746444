import {defineStore} from 'pinia'
import {useDeleteApi} from 'src/uses/Api/useDeleteApi'

export const useFormItemDataDeleteStore = defineStore('form-item-data-delete', () => {
  const {
    deleteRequest,
    response,
    status,
    loading,
    error,
    errors,
  } = useDeleteApi<never>()

  const deleteFields = async (formId: string, sectionId: string) => {
    await deleteRequest(`v2/forms/${formId}/sections/${sectionId}/fields`)
  }

  return {
    loading,
    response,
    status,
    error,
    errors,
    deleteFields,
  }
})
