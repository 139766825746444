<template>
  <div
    class="row items-center"
    style="max-width: 100%"
  >
    <div class="col text-left">
      <q-chip
        class="q-ml-none"
        :color="color(props.bank)"
        :icon="icon(props.bank)"
        text-color="white"
        size="12px"
      >
        <span class="ellipsis">
          {{ props.bank.title }}
          <q-tooltip
            v-if="props.stopFactors.length && props.bank.sub_status === ApplicationSubStatusEnum.system_rejected"
            class="bg-blue-grey-10 StopTooltip q-pa-md"
            :delay="700"
          >
            <stop-factors-result :model-value="props.stopFactors" />
            <div class="q-mt-sm">
              Скорректируйте данные в анкете для продолжения работы с этим Банком
            </div>
          </q-tooltip>
        </span>
      </q-chip>
    </div>
    <div
      v-if="props.bank.sub_status === ApplicationSubStatusEnum.draft"
      class="q-pl-sm"
    >
      ({{ props.filled }}/{{ props.total }})
    </div>
    <div
      v-else
      class="q-pl-sm"
    >
      {{ text(props.bank) }}
    </div>
  </div>
</template>

<script setup lang="ts">
import {ApplicationFormBank} from 'src/models/Applications/Form/ApplicationFormResponseData'
import {ApplicationSubStatusEnum} from 'src/models/Applications/ApplicationSubStatusEnum'
import {SelectBankFactor} from 'src/models/Applications/New/SelectBank'
import StopFactorsResult from 'src/components/Applications/StopFactorsResult.vue'

const props = defineProps<{
  bank: ApplicationFormBank,
  total?: number,
  filled?: number,
  stopFactors: SelectBankFactor[],
}>()

const redStatuses = [
  ApplicationSubStatusEnum.client_rejected,
  ApplicationSubStatusEnum.bank_rejected,
  ApplicationSubStatusEnum.system_rejected,
  ApplicationSubStatusEnum.archive,
]

const bankStatuses = [
  ApplicationSubStatusEnum.send_2_bank,
  ApplicationSubStatusEnum.in_bank,
  ApplicationSubStatusEnum.data_request,
  ApplicationSubStatusEnum.data_request_executed,
  ApplicationSubStatusEnum.risks_assessment,
  ApplicationSubStatusEnum.risks_request,
  ApplicationSubStatusEnum.risks_request_executed,
  ApplicationSubStatusEnum.bg_project_preparation,
  ApplicationSubStatusEnum.bg_project_customer_agreement,
  ApplicationSubStatusEnum.bg_project_bank_agreement,
  ApplicationSubStatusEnum.bg_project_bank_agreement,
  ApplicationSubStatusEnum.bg_project_bank_agreement_request,
  ApplicationSubStatusEnum.bg_project_bank_agreement,
  ApplicationSubStatusEnum.payment_waiting,
  ApplicationSubStatusEnum.deferred_conditions_execution_waiting,
  ApplicationSubStatusEnum.deferred_conditions_executed,
  ApplicationSubStatusEnum.paid,
]

const doneStatuses = [
  ApplicationSubStatusEnum.guarantee_released,
  ApplicationSubStatusEnum.guarantee_released_request,
]

const checkingStatuses = [
  ApplicationSubStatusEnum.checking,
  ApplicationSubStatusEnum.completion,
  ApplicationSubStatusEnum.new,
]

const signStatuses = [
  ApplicationSubStatusEnum.client_signed,
]

const icon = (bank: ApplicationFormBank): string | undefined => {
  if (redStatuses.includes(bank.sub_status)) {
    return 'svguse:icons.svg?2#app-information-circle-bold'
  }

  if (redStatuses.includes(bank.sub_status)
    || doneStatuses.includes(bank.sub_status)
    || checkingStatuses.includes(bank.sub_status)
    || signStatuses.includes(bank.sub_status)
  ) {
    return 'svguse:icons.svg?2#app-check'
  }

  return undefined
}

const color = (bank: ApplicationFormBank) => {
  if (bank.sub_status === ApplicationSubStatusEnum.draft) {
    return 'blue-grey-8'
  }

  if (redStatuses.includes(bank.sub_status)) {
    return 'red-12'
  }

  if (redStatuses.includes(bank.sub_status)
    || doneStatuses.includes(bank.sub_status)
    || checkingStatuses.includes(bank.sub_status)
    || signStatuses.includes(bank.sub_status)
  ) {
    return 'green-14'
  }

  return 'blue-grey-8'
}

const text = (bank: ApplicationFormBank) => {
  if (redStatuses.includes(bank.sub_status)) {
    return 'Отказ'
  }

  if (bankStatuses.includes(bank.sub_status)) {
    return 'В банке'
  }

  if (doneStatuses.includes(bank.sub_status)) {
    return 'Выпущена'
  }

  if (checkingStatuses.includes(bank.sub_status)) {
    return 'На проверке'
  }

  if (signStatuses.includes(bank.sub_status)
  ) {
    return 'Требуется подпись'
  }

  return ''
}
</script>
