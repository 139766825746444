<template>
  <div class="text-h6 q-mt-md">Выберите нужную заявку для размещения в другом банке</div>
  <div class="row q-col-gutter-md q-my-sm">
    <q-input
      v-model="filters.number"
      class="col-sm-12 col-md"
      :loading="loading"
      autocomplete="new-password"
      label="Поиск по номеру заявки"
      clearable
      outlined
      hide-hint
      hide-bottom-space
      dense
      @change="updateFilters"
      @clear="updateFilters"
    >
      <template #prepend>
        <q-icon name="svguse:icons.svg?2#app-search"/>
      </template>
    </q-input>
    <q-input
      v-model="filters.client"
      class="col-sm-12 col-md"
      :loading="loading"
      autocomplete="new-password"
      label="Поиск по ИНН или наименованию"
      clearable
      outlined
      hide-hint
      hide-bottom-space
      dense
      @change="updateFilters"
      @clear="updateFilters"
    >
      <template #prepend>
        <q-icon name="svguse:icons.svg?2#app-search" />
      </template>
    </q-input>
    <q-input
      v-model="filters.rnt"
      class="col-sm-12 col-md"
      :loading="loading"
      autocomplete="new-password"
      label="Поиск по РНТ"
      clearable
      outlined
      hide-hint
      hide-bottom-space
      dense
      @change="updateFilters"
      @clear="updateFilters"
    >
      <template #prepend>
        <q-icon name="svguse:icons.svg?2#app-search" />
      </template>
    </q-input>
  </div>
  <div class="q-px-none">
    <div v-if="!Platform.is.mobile" class="ApplicationsCloneGrid--table">
      <q-table
        v-model:pagination="gridPagination"
        v-model:selected="selectedOriginalItems"
        selection="single"
        class="sticky-header-table"
        :rows="items"
        :columns="filteredVisibleColumns"
        :loading="loading"
        :rows-per-page-options="perPageOptions"
        separator="none"
        row-key="id"
        hide-pagination
        hide-selected-banner
        wrap-cells
        @request="onRequest"
        @row-click="onRowClick"
      >
        <template #header-selection />
        <template #body-selection />
        <template
          v-if="!loading"
          #no-data
        >
          <template v-if="!isDefaultFilterValue">
            По вашему запросу ничего не найдено. Попробуйте изменить фильтры
          </template>
          <template v-else>
            У вас пока нет ни одной заявки
          </template>
        </template>
        <template #body-cell-number="props">
          <q-td :props="props">
            <application-grid-number
              :item="props.row"
              only-text
            />
          </q-td>
        </template>
        <template #body-cell-inn="props">
          <q-td :props="props">
            <application-grid-inn
              :item="props.row"
              only-text
            />
          </q-td>
        </template>
        <template #body-cell-rnt="props">
          <q-td :props="props">
            <application-grid-rnt
              :item="props.row"
              only-text
            />
          </q-td>
        </template>
        <template #body-cell-guarantee_total="props">
          <q-td :props="props">
            <application-grid-sum
              :item="props.row"
              only-text
            />
          </q-td>
        </template>
        <template #body-cell-bank="props">
          <q-td :props="props">
            <img
              :src="props.row.bank.logo"
              :alt="props.row.bank.title_short"
              style="max-width: 100px; max-height: 50px;"
            >
          </q-td>
        </template>
        <template #body-cell-period="props">
          <q-td :props="props">
            <application-grid-period :item="props.row" />
          </q-td>
        </template>
      </q-table>
    </div>
    <div v-else>
      <div
        v-for="item in items as ApplicationsGridItem[]"
        :key="item.id"
      >
        <div>
          <div
            class="ApplicationCloneGrid--mobile--item q-pa-md rounded-borders bg-white q-mt-sm"
            @click="changeGridItemForeClone(item)"
          >
            <div class="row flex justify-between">
              <div>
                <div class="text-grey-6">Дата, №</div>
                <application-grid-number-mobile
                  :item="item"
                  only-text
                />
              </div>
              <img
                :src="item.bank.logo"
                :alt="item.bank.title_short"
                style="max-width: 60px; max-height: 50px;"
              >
            </div>

            <div class="text-grey-6 q-mt-sm">Название/ИНН</div>
            <application-grid-inn
              :item="item"
              mobile
              only-text
            />

            <div class="text-grey-6 q-mt-sm">Закупка</div>
            <application-grid-rnt
              :item="item"
              mobile
              only-text
            />

            <div class="text-grey-6 q-mt-sm">Сумма гарантии</div>
            <application-grid-sum
              :item="item"
              mobile
              only-text
            />

            <div class="text-grey-6 q-mt-sm">Срок действия</div>
            <application-grid-period
              :item="item"
              mobile
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import ApplicationGridNumber from 'components/Applications/ApplicationsGrid/Columns/ApplicationGridNumber.vue'
import ApplicationGridInn from 'components/Applications/ApplicationsGrid/Columns/ApplicationGridInn.vue'
import ApplicationGridRnt from 'components/Applications/ApplicationsGrid/Columns/ApplicationGridRnt.vue'
import ApplicationGridPeriod from 'components/Applications/ApplicationsGrid/Columns/ApplicationGridPeriod.vue'
import ApplicationGridSum from 'components/Applications/ApplicationsGrid/Columns/ApplicationGridSum.vue'
import {useApplicationsGrid} from 'components/Applications/ApplicationsGrid/Grid/useApplicationsGrid'
import {computed, onBeforeMount} from 'vue'
import {storeToRefs} from 'pinia'
import {ApplicationsGridItem} from 'src/models/Applications/ApplicationsGridItem'
import {useApplicationsNew} from 'stores/Applications/ApplicationsNew/useApplicationsNew'
import {useApplicationsGridFiltersStore} from 'stores/Applications/Grid/useApplicationsGridFiltersStore'
import {Platform} from 'quasar'
import ApplicationGridNumberMobile
  from 'components/Applications/ApplicationsGrid/Columns/ApplicationGridNumberMobile.vue'

const {
  isDefaultFilterValue,
  items,
  gridPagination,
  perPageOptions,
  loading,
  columns,
  setPerPage,
  load,
  onRequest,
  updateFilters: realUpdateFilters,
} = useApplicationsGrid()

const filtersStore = useApplicationsGridFiltersStore()
const {filters} = storeToRefs(filtersStore)

const newStore = useApplicationsNew()
const {selectedOriginalItems} = storeToRefs(newStore)

const filteredVisibleColumns = computed(() => {
  const columnNames = [
    'number',
    'inn',
    'rnt',
    'guarantee_total',
    'bank',
    'period',
  ]
  return (columns.value || [])
    .filter(column => columnNames.includes(column.name))
})

const updateFilters = () => {
  selectedOriginalItems.value = []
  realUpdateFilters()
}

const onRowClick = (event: Event, item: ApplicationsGridItem) => {
  selectedOriginalItems.value = [item]
}

onBeforeMount(async () => {
  setPerPage(10)
  await load()
})

const changeGridItemForeClone = (item: ApplicationsGridItem) => {
  selectedOriginalItems.value[0] = item
}
</script>

<style lang="scss">
.ApplicationsCloneGrid--table {
  margin: 0 -24px -24px;
  background: white;
  padding: 0 24px 24px;

  .q-table {
    border-spacing: 0 1px !important;
    border-left: 1px solid $grey-3;
    border-right: 1px solid $grey-3;
    background: $grey-3;

    tr:hover {
      td {
        background: $blue-1;
      }
    }
  }

  .q-table--col-auto-width {
    display: none;
  }
}

.ApplicationCloneGrid--mobile--item {
  border-radius: 16px;
  border:1px solid #f8eeee;
}
</style>
