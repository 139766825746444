export enum ApplicationStatusEnum {
  all = 'all',
  active = 'active',
  favorites = 'favorites',
  draft = 'draft',
  verification = 'verification',
  on_check = 'on_check',
  need_sign = 'need_sign',
  abs = 'abs',
  risk_assessment = 'risk_assessment',
  approved = 'approved',
  project_bg_ready = 'project_bg_ready',
  agreement = 'agreement',
  on_release = 'on_release',
  released = 'released',
  bank_rejected = 'bank_rejected',
  client_rejected = 'client_rejected',
  system_rejected = 'system_rejected',
  bank_consideration = 'bank_consideration',
  approved_orders = 'approved_orders',
  guarantee_released_request = 'guarantee_released_request',
  bg_project_bank_agreement_request = 'bg_project_bank_agreement_request',
  release_expired = 'release_expired',
  release_today = 'release_today',
  release_without_date = 'release_without_date',
  waiting = 'waiting',
  in_bank = 'in_bank',
}
