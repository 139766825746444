<template>
  <q-dialog
    :model-value="props.modelValue"
    :persistent="props.document.loading"
    @update:model-value="hide()"
  >
    <q-card
      class="q-mt-md bg-blue-1"
      style="min-width: 400px; max-width: 600px; width: 100%; border-radius: 4px !important;"
    >
      <q-card-section class="text-h6 rounded-borders">
        Загрузка документа “{{ props.document.title }}”
      </q-card-section>
      <q-card-section class="bg-blue-grey-10 text-white row items-center q-py-md">
        <div class="rounded-borders q-pa-sm bg-blue-grey-9">
          <q-icon
            name="svguse:icons.svg?2#app-information-circle"
            size="sm"
            color="orange-6"
          />
        </div>
        <div class="col q-pl-md text-wgite">
          Необходимо загрузить два файла: документ<br>
          и открепленную подпись
        </div>
      </q-card-section>
      <q-card-section class="white-bg-field">
        <div class="q-mb-xs text-grey-7">
          Документ
        </div>
        <application-form-new-document-file
          v-if="original"
          :file="original"
          :progress="props.document.file ? props.document.progress : undefined"
          :loading="props.document.loading"
          @remove="original = undefined"
        />
        <file-select-component
          v-else
          class="q-mb-sm"
          :accept="accept"
          @file:selected="original = $event"
        />
        <div class="q-mb-xs q-mt-sm text-grey-7">
          Открепленная подпись
        </div>
        <application-form-new-document-file
          v-if="sign"
          :file="sign"
          :progress="props.document.file_sign && !props.document.file ? props.document.progress: undefined"
          :loading="props.document.loading"
          @remove="sign = undefined"
        />
        <file-select-component
          v-else
          class="q-mb-sm"
          accept=".sig, .sgn, .p7s"
          @file:selected="sign = $event"
        />
      </q-card-section>
      <q-card-actions
        class="row justify-end q-py-md a-px-md"
        style="background: #D5EAFA;"
      >
        <q-btn
          v-close-popup
          size="16px"
          padding="sm lg"
          label="Отмена"
          :disable="props.document.loading"
          unelevated
          no-caps
        />
        <q-btn
          padding="sm lg"
          size="16px"
          color="blue-7"
          label="Сохранить"
          unelevated
          no-caps
          :disable="!original || !sign"
          @click="upload"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
import {computed, ref, watchEffect} from 'vue'
import FileSelectComponent from 'src/components/FileSelectComponent.vue'
import ApplicationFormNewDocumentFile from 'src/components/Applications/Form/Documents/ApplicationFormNewDocumentFile.vue'
import {ApplicationFormDocument} from 'src/models/Applications/Form/ApplicationFormResponseData'
import {DocumentsCodesEnum} from 'src/models/DocumentsCodesEnum'

const props = defineProps<{
  modelValue: boolean
  document: ApplicationFormDocument
}>()

const emit = defineEmits<{
  (e: 'update:model-value', value: boolean): void,
  (e: 'file:selected-with-sign', original: File, sign: File): void,
}>()

const withSignWasFired = ref(false)
const original = ref<File>()
const sign = ref<File>()

const accept = computed(() => {
  if (props.document.code === DocumentsCodesEnum.POWER_OF_ATTORNEY_FOR_THE_RIGHT_TO_SIGN) {
    return '.xml'
  }

  return '.xls, .xlsx, .doc, .docx, .pdf, .jpeg, .jpg, .png, .txt, .zip, .rar, .xml'
})

const upload = () => {
  if (!original.value || !sign.value) {
    return
  }

  withSignWasFired.value = true
  emit(
    'file:selected-with-sign',
    original.value,
    sign.value,
  )
}

const hide = () => {
  withSignWasFired.value = false
  original.value = undefined
  sign.value = undefined
  emit('update:model-value', false)
}

watchEffect(() => {
  if (withSignWasFired.value
    && props.modelValue
    && !props.document.file
    && !props.document.file_sign
  ) {
    hide()
    emit('update:model-value', false)
  }
})
</script>
