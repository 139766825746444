<template>
  <div>
    <q-btn
      class="banner-btn"
      label="Отправить на выпуск"
      icon-right="svguse:icons.svg?2#app-pencil-alt"
      color="primary"
      :disable="loading"
      :loading="loading"
      unelevated
      no-caps
      @click="changeStatus(
        {
          id: props.headerData.id,
          applicationStatus: ApplicationSubStatusEnum.bg_project_bank_agreement,
          initiator: 'send_to_production'
        },
        props.headerData.sub_status as ApplicationSubStatusEnum
      )"
    />
  </div>
</template>

<script setup lang="ts">
import {ControlCenterHeader} from 'src/models/Applications/ControlCenter/ControlCenterHeader'
import {
  useApplicationControlCenterChangeStatusStore
} from 'src/stores/Applications/ControlCenter/ChangeStatus/useApplicationControlCenterChangeStatusStore'
import {ApplicationSubStatusEnum} from 'src/models/Applications/ApplicationSubStatusEnum'
import {storeToRefs} from 'pinia'
const props = defineProps<{
  headerData: ControlCenterHeader,
}>()

const {
  changeStatus,
} = useApplicationControlCenterChangeStatusStore(props.headerData?.id)()
const {loading} = storeToRefs(useApplicationControlCenterChangeStatusStore(props.headerData?.id)())

</script>
