import {useDeleteApi} from 'src/uses/Api/useDeleteApi'

export const useFormItemDeleteField = () => {
  const {
    deleteRequest,
    response,
    status,
    loading,
    error,
    errors,
  } = useDeleteApi<never>()

  const deleteField = async (formId: string, sectionId: string, fieldId: string) => {
    await deleteRequest(`v2/forms/${formId}/sections/${sectionId}/fields/${fieldId}`)
  }

  return {
    loading,
    response,
    status,
    error,
    errors,
    deleteField,
  }
}
