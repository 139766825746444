import {useRequestApi} from './useRequestApi'
import {PatchReturnInterface} from 'src/models/Api/PatchReturnInterface'

export const usePatchApi: <T = never, R = never>(showError?: boolean) => PatchReturnInterface<R, T> = <T, R>(showErrors = false) => {
  const {
    response,
    error,
    errors,
    status,
    loading,
    request,
    abort,
    setHeader,
    headers,
    progressEvent,
  } = useRequestApi<T>(showErrors)

  const patch = async (url: string, data?: R) => {
    await request<R>(url, 'PATCH', data)
  }

  return {
    response,
    error,
    errors,
    status,
    loading,
    request,
    abort,
    setHeader,
    headers,
    progressEvent,
    patch,
  }
}
