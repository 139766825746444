import {useTokenStore} from 'src/stores/Login/useTokenStore'
import {storeToRefs} from 'pinia'
import {watch} from 'vue'
import {useEnvStore} from 'stores/useEnvStore'

export const useCookieToken = () => {
  const {
    logout,
    setToken,
  } = useTokenStore()

  const {tokenData} = storeToRefs(useTokenStore())

  const {editable} = storeToRefs(useEnvStore())

  const getMainDomainPart = () => {
    if (editable.value) {
      return ''
    }

    const domain = document.location.host
      .split(':')[0]
      .split('.')
      .filter((i, index) => index > 0)
      .join('.')

    return `domain=${domain};`
  }

  const saveTokenToCookie = () => {
    if (!tokenData.value) {
      return
    }

    document.cookie = `authToken=${tokenData.value.token}; ${getMainDomainPart()} path=/`
    document.cookie = `authTokenRefresh=${tokenData.value.refresh_token}; ${getMainDomainPart()} path=/`
  }

  const getCookieToken = () => {
    const tokenCookie = document.cookie
      .split(';')
      .find(item => item.trim().startsWith('authToken='))

    return tokenCookie ? tokenCookie.split('=')[1] : undefined
  }

  const refreshTokenCookie = () => {
    const refreshTokenCookie = document.cookie
      .split(';')
      .find(item => item.trim().startsWith('authTokenRefresh='))

    return refreshTokenCookie ? refreshTokenCookie.split('=')[1] : undefined
  }

  const startCookieListener = () => {
    setInterval(() => updateFromCookie(), 5000)
  }

  const updateFromCookie = async () => {
    if (!getCookieToken() || !refreshTokenCookie()) {
      return
    }

    if (getCookieToken() === 'expired' || refreshTokenCookie() === 'expired') {
      if (!tokenData.value) {
        return
      }

      await logout()

      return
    }

    if (tokenData.value
      && getCookieToken() === tokenData.value.token
      && refreshTokenCookie() === tokenData.value.refresh_token
    ) {
      return
    }

    console.log('token data was updated from cookie')
    const needReload = !tokenData.value
    setToken({
      expired: 21600,
      token: getCookieToken() || '',
      refresh_token: refreshTokenCookie() || '',
    })

    if (needReload) {
      document.location.reload()
    }
  }

  updateFromCookie()
  setTimeout(startCookieListener, 0)

  watch(
    tokenData,
    () => {
      if (tokenData.value === null) {
        document.cookie = `authToken=expired; ${getMainDomainPart()} path=/`
        document.cookie = `authTokenRefresh=expired; ${getMainDomainPart()} path=/`
        return
      }

      saveTokenToCookie()
    },
    {
      deep: true,
    }
  )

  return {
    startCookieListener,
  }
}
