<template>
  <template v-if="!isDraft(props.item) && !isSystemRejected(props.item) && !isArchive(props.item)">
    <q-item
      v-if="props.list"
      v-ripple
      class="text-no-wrap"
      clickable
      @click="navigate"
    >
      <q-item-section>
        <q-item-label>
          <q-icon
            color="blue-grey-4"
            size="24px"
            name="svguse:icons.svg?2#app-cog"
          />
          Управление заявкой
        </q-item-label>
      </q-item-section>
    </q-item>
    <q-btn
      v-else
      class="text-blue-grey-4"
      icon="svguse:icons.svg?2#app-cog"
      padding="sm"
      unelevated
      square
      @click="navigate"
    >
      <q-tooltip
        anchor="top middle"
        self="bottom middle"
      >Перейти в центр управления заявкой</q-tooltip>
    </q-btn>
  </template>
</template>
<script lang="ts" setup>
import {withDefaults} from 'vue'
import {ApplicationsGridItem} from 'src/models/Applications/ApplicationsGridItem'
import {useRouter} from 'vue-router'
import {useGridChangeStatusAction} from 'src/uses/Grid/useGridChangeStatusAction'
import {usePosthogStore} from 'stores/Posthog/usePosthogStore'

const router = useRouter()

const props = withDefaults(
  defineProps<{
    item: ApplicationsGridItem
    list?: boolean
  }>(),
  {
    list: false,
  }
)

const gridActions = useGridChangeStatusAction()
const {
  isDraft,
  isSystemRejected,
  isArchive,
} = gridActions

const {sendEvent} = usePosthogStore()

const navigate = () => {
  sendEvent('Open_control_center_via_setting_icon')
  router.push({
    name: 'application-control-center',
    params: {
      appId: props.item.id,
      formId: props.item.multi_order_id
    },
  })
}
</script>
