<template>
  <div class="white-bg-field q-my-md">
    <q-input
      v-model="search"
      label="Поиск по наименованию документа"
      outlined
      hide-bottom-space
      dense
      emit-value
      map-options
      options-dense
      hide-dropdown-icon
      clearable
    >
      <template #prepend>
        <q-icon name="svguse:icons.svg?2#app-search" />
      </template>
    </q-input>
  </div>
  <form-item-documents-block
    v-for="documentType in documentTypes"
    :key="documentType"
    :form-id="props.formId"
    :document-type="documentType"
    :search="search"
  />
</template>

<script setup lang="ts">
import {FormItemDocument} from 'src/models/FormEditor/FormItemResponseData'
import FormItemDocumentsBlock from 'components/FormEditor/Documents/FormItemDocumentsBlock.vue'
import {ApplicationFormDocumentGroupEnum} from 'src/models/Applications/Form/ApplicationFormResponseData'
import {onBeforeMount, ref} from 'vue'
import {useFormItemDocumentsStore} from 'stores/FormEditor/useFormItemDocumentsStore'

const props = defineProps<{
  formId: string,
  documents: FormItemDocument[]
}>()

const formItemDocumentsStore = useFormItemDocumentsStore(props.formId)()
const {
  load,
} = formItemDocumentsStore

const documentTypes: ApplicationFormDocumentGroupEnum[] = [
  ApplicationFormDocumentGroupEnum.passports,
  ApplicationFormDocumentGroupEnum.financial,
  ApplicationFormDocumentGroupEnum.additional,
  ApplicationFormDocumentGroupEnum.offer,
  ApplicationFormDocumentGroupEnum.deal,
]
const search = ref<string>('')

onBeforeMount(async () => {
  await load()
})

</script>
