<template>
  <q-btn
    label="Удалить"
    class="BgGreyBtn q-ml-md"
    icon-right="svguse:icons.svg?2#app-trash"
    unelevated
    no-caps
    @click="open"
  />
  <q-dialog
    v-model="dialog"
  >
    <q-card
      style="min-width: 500px"
      class="modal-blue-background"
    >
      <q-card-section
        class="modal-title row items-center q-pb-none q-pl-lg"
      >
        <div>Удаление раздела помощи</div>
      </q-card-section>
      <q-card-section>
        <info-banner
          text="Вы уверены?"
          caption="Удаленный раздел нельзя будет восстановить!"
          icon="svguse:icons.svg?2#app-exclamation"
          color="red"
        />
      </q-card-section>
      <q-card-section>
        <div class="row">
          <div class="col-12">
            Раздел "{{ props.title }}" будет удален
          </div>
        </div>
      </q-card-section>
      <q-card-actions
        align="right"
        class="modal-actions text-primary q-pa-lg"
      >
        <q-btn
          v-close-popup
          :loading="loading"
          label="Отмена"
          class="q-pr-lg"
          style="padding: 17px 28px;"
          unelevated
          flat
          no-caps
          text-color="black"
        />
        <q-btn
          :loading="loading"
          label="Удалить"
          color="red"
          style="padding: 17px 28px;"
          unelevated
          icon-right="svguse:icons.svg?2#app-trash"
          no-caps
          @click="handleSubmit()"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
import {computed} from 'vue'
import {useDialog} from 'src/uses/Dialogs/useDialog'
import InfoBanner from 'src/components/InfoBanner.vue'
import {useDeleteHelpBlock} from 'src/uses/Settings/Help/useDeleteHelpBlock'

const emit = defineEmits<{
  (e: 'change'): void,
}>()

const props = withDefaults(
  defineProps<{
    itemId?: string,
    title: string,
  }>(),
  {
    itemId: undefined,
  })
const itemId = computed(() => props.itemId)
const {dialog, show, hide} = useDialog()

const deleteHelpBlock = useDeleteHelpBlock()

const {
  loading,
  status,
  deleteBlock,
} = deleteHelpBlock

const open = async () => {
  show()
}
const handleSubmit = async () => {
  if (!itemId.value) {
    return
  }
  await deleteBlock(itemId.value)
  if (status.value !== 200) {
    return
  }
  hide()
  emit('change')
}
</script>
