<template>
  <q-dialog
    v-model="sendDialog"
    position="top"
  >
    <q-card
      class="ApplicationItemSendModal q-mt-md"
      style="max-width: 900px; min-width: 400px; width: 100%; border-radius: 4px !important;"
    >
      <q-card-section class="ApplicationItemSendModal__head bg-blue-grey-10 text-white text-h6">
        Отправить заявку на проверку
      </q-card-section>
      <q-card-section class="ApplicationItemSendModal__help row items-center text-grey-7">
        <q-icon
          name="svguse:icons.svg?2#app-information-circle"
          size="sm"
        />
        <div class="col q-pl-sm">
          Выберите заявки, которые хотите отправить на проверку
        </div>
      </q-card-section>
      <q-card-section class="ApplicationItemSendModal__main q-pa-none">
        <q-table
          v-model:selected="selectedBanks"
          class="full-width"
          style="max-height: 40vh;"
          :rows="banksFiltered"
          :columns="columns"
          :pagination="{rowsPerPage: 0}"
          selection="multiple"
          separator="horizontal"
          row-key="id"
          hide-selected-banner
          hide-pagination
          virtual-scroll
          flat
        >
          <template #header-selection="">
          </template>
          <template #body-selection="{row}">
            <q-checkbox
              v-model="selectedBanks"
              :val="row"
              :checked-icon="row.required ? 'svguse:icons.svg?2#app-lock-closed' : ''"
              :disable="!availableToSelect(row) || row.required"
            >
              <q-tooltip
                v-if="row.required"
                class="bg-blue-grey-10 StopTooltip q-pa-md"
                :delay="700"
              >
                <div class="row">
                  <div class="col q-mr-xs">
                    <q-icon
                      name="svguse:icons.svg?2#app-exclamation-circle"
                      class="float-left"
                      size="15px"
                      color="orange"
                    />
                  </div>
                  <div>
                    Заявка в данный банк создается в приоритетном порядке <br>
                    основываясь на предварительном анализе клиента и 100% совпадении анкетных данных группы банков
                  </div>
                </div>
              </q-tooltip>
            </q-checkbox>
          </template>
          <template #body-cell-bank="propsTd">
            <q-td
              :props="propsTd"
              class="text-wrap"
            >
              <img
                :src="propsTd.row.url"
                :alt="propsTd.row.title"
                style="height: 64px;"
              >
            </q-td>
          </template>
          <template #body-cell-product="propsTd">
            <q-td
              :props="propsTd"
              class="text-wrap"
            >
              {{ getProductLabel(propsTd.row.product || ProductEnum.EBG) }}
            </q-td>
          </template>
          <template #body-cell-tariff="propsTd">
            <q-td
              :props="propsTd"
              class="text-wrap"
            >
              {{ propsTd.row.tariff_type || 'min' }}
              /
              <money-component
                v-if="!Number.isNaN(parseFloat(propsTd.row.tariff))"
                :model-value="propsTd.row.tariff"
              />
              <template v-else>
                {{ propsTd.row.tariff }}
              </template>
            </q-td>
          </template>
        </q-table>
      </q-card-section>
      <q-card-actions class="row justify-end q-py-md">
        <q-btn
          size="16px"
          padding="sm lg"
          label="Отмена"
          unelevated
          no-caps
          @click="sendDialogHide()"
        />
        <q-btn
          align="left"
          class="sendButton"
          color="green-14"
          style="padding: 8px 56px 8px 16px;"
          unelevated
          :disable="selectedBanks.length === 0"
          no-caps
          @click="onSend"
        >
          <strong>Отправить</strong>
          <span class="icon">
            <q-icon
              name="svguse:icons.svg?2#app-arrow-right-bold"
              color="green-14"
              size="12px"
              style="padding: 5.5px;"
            />
          </span>
        </q-btn>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
import {useRoute, useRouter} from 'vue-router'
import {computed, onBeforeUnmount} from 'vue'
import {storeToRefs} from 'pinia'
import {useApplicationItemSendStore} from 'src/stores/Applications/Item/useApplicationItemSendStore'
import {useProduct} from 'src/uses/Applications/useProduct'
import {ProductEnum} from 'src/models/Applications/ProductEnum'
import MoneyComponent from 'src/components/MoneyComponent.vue'
import {useLoadingStore} from 'src/stores/useLoadingStore'
import {QTableColumn} from 'src/models/Grid/GridColumnSettings'
import {useApplicationItemStore} from 'stores/Applications/Item/useApplicationItemStore'

const route = useRoute()
const router = useRouter()
const multiOrderId = computed(() => parseInt(route.params.formId as string))
const applicationItemSendStore = useApplicationItemSendStore(multiOrderId.value)()
const applicationItemStore = useApplicationItemStore(multiOrderId.value)()
const {load} = applicationItemStore

const {
  sendDialogHide,
  availableToSelect,
  send,
} = applicationItemSendStore

const {
  banks,
  selectedBanks,
  sendDialog,
  status,
  hasEbg,
  hasKik,
} = storeToRefs(applicationItemSendStore)

const loadingStore = useLoadingStore()

const {
  getProductLabel,
} = useProduct()

const banksFiltered = computed(() => {
  return banks.value
    .filter(availableToSelect)
})

const columns = computed<QTableColumn[]>(() => [
  {
    name: 'number',
    field: 'number',
    required: true,
    label: '№ заявки',
    align: 'left',
    classes: 'text-no-wrap',
  },
  {
    name: 'bank',
    field: 'bank',
    required: true,
    label: 'Банк',
    align: 'left',
    classes: 'text-no-wrap',
  },
  {
    name: 'product',
    field: 'product',
    required: true,
    label: 'Продукт',
    align: 'left',
    classes: 'text-no-wrap',
  },
  {
    name: 'tariff',
    field: 'tariff',
    required: true,
    label: 'Тариф %/₽',
    align: 'left',
    classes: 'text-no-wrap',
  },
])

const onSend = async () => {
  loadingStore.showLoading()
  await send()
  loadingStore.hideLoading()
  if (status.value !== 200) {
    await load()
    return
  }
  sendDialog.value = false
  if (banksFiltered.value.length === 0) {
    if (hasEbg.value) {
      await router.push({
        name: 'applications-grid'
      })

      return
    }

    if (hasKik.value && !hasEbg.value) {
      await router.push({
        name: 'applications-grid-kik'
      })
    }
  }
}

onBeforeUnmount(() => {
  applicationItemSendStore.$reset()
  applicationItemSendStore.$dispose()
})
</script>

<style lang="scss">
.ApplicationItemSendModal {
  .sendButton {
    .icon {
      background: white;
      border-radius: 3px;
      position: absolute;
      right: 8px;
      line-height: 0;
    }

    .q-btn {
      border-radius: 6px;
    }
  }

  .q-table__top, .q-table__bottom, thead th {
    background: $grey-3 !important;
  }
}
</style>
