import {defineStore} from 'pinia'
import {computed, Ref, ref} from 'vue'
import {required} from 'src/utils/i18n-validators'
import useVuelidate from '@vuelidate/core'
import {usePutApi} from 'src/uses/Api/usePutApi'
import {DeliveryInfo} from 'src/models/Profile/Documents/DeliveryInfo'
import {useValidationRules} from 'src/uses/useValidationRules'

export const useDeliveryInfoUpdateStore = defineStore('delivery-info-update', () => {
  const {
    put,
    response,
    status,
    loading,
    error,
    errors,
  } = usePutApi<DeliveryInfo, DeliveryInfo>()

  const {
    getRulesFromState,
  } = useValidationRules()

  const deliveryInfoUpdateData = ref<DeliveryInfo>({
    name: '',
    number: '',
  })

  const success = ref(false)

  const rules = computed(() => {
    return {
      ...getRulesFromState(deliveryInfoUpdateData.value),
      name: {
        required,
      },
      number: {
        required,
      },
    }
  })

  const validation = useVuelidate<Ref<DeliveryInfo>>(rules, deliveryInfoUpdateData)

  const update = async (id: string) => {
    if (!await validation.value.$validate()) {
      return
    }

    await put(`v1/document/change/${id}/delivery`, deliveryInfoUpdateData.value)
    if (status.value === 200 && response.value) {
      success.value = true
    }
  }

  return {
    deliveryInfoUpdateData,
    loading,
    response,
    status,
    error,
    errors,
    validation,
    success,
    update,
  }
})
