<template>
  <page-block-wrapper>
    <div class="profile-wrapper">
      <profile-requisites />
    </div>
  </page-block-wrapper>
</template>
<script setup lang="ts">
import ProfileRequisites from 'src/components/Profile/Requisites/ProfileRequisites.vue'
import PageBlockWrapper from 'src/components/PageBlockWrapper.vue'
import {useMetaStore} from 'src/stores/useMetaStore'

useMetaStore().setTitle('Реквизиты')
</script>
