import {
  ApplicationFormFieldValid
} from 'src/models/Applications/Form/ApplicationFormResponseData'

export const useValid = () => {
  const hasError = (valid: ApplicationFormFieldValid[], bankId: string | undefined = undefined): boolean => {
    if (bankId) {
      return !!valid.find(v => bankId === v.id && v.errors.length > 0)
    }

    return !!valid.find(v => v.errors.length > 0)
  }

  const getErrors = (
    valid: ApplicationFormFieldValid[],
    bankId: string
  ): string[] => {
    const byBank = valid.find(v => bankId === v.id)
    return byBank
      ? byBank.errors
      : []
  }

  return {
    hasError,
    getErrors,
  }
}
