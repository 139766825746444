<template>
  <q-breadcrumbs class="Breadcrumbs">
    <q-breadcrumbs-el
      :label="parentLabel"
      :to="parentTo ? parentTo : undefined"
    />
    <q-breadcrumbs-el :label="currentLabel" />
  </q-breadcrumbs>
</template>

<script setup lang="ts">
import {computed} from 'vue'
import {RouteLocationRaw} from 'vue-router'

const props = defineProps<{
  parentLabel: string,
  parentTo: RouteLocationRaw,
  currentLabel: string,
}>()

const parentLabel = computed(() => props.parentLabel)
const parentTo = computed(() => props.parentTo)
const currentLabel = computed(() => props.currentLabel)
</script>

<style lang="scss">
.Breadcrumbs {
  padding-bottom: 25px;
  padding-left: 10px;
}
</style>
