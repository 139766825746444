import {useImportantFields} from 'src/uses/Applications/Form/useImportantFields'
import {Ref} from 'vue/dist/vue'
import {
  ApplicationFormBank,
  ApplicationFormField,
  ApplicationFormResponseData
} from 'src/models/Applications/Form/ApplicationFormResponseData'
import {computed} from 'vue'
import {StopFactorResultEnum} from 'src/models/Applications/New/SelectBank'
import {ProductEnum} from 'src/models/Applications/ProductEnum'
import {FieldsCodesEnum} from 'src/models/FieldsCodesEnum'

export const useApplicationFormStopFactors = (item: Ref<ApplicationFormResponseData | undefined>, type?: ProductEnum) => {
  const {
    bgSumField,
    startDateField,
    endDateField,
    kikSumField,
    kikMonthsField,
    kikTypeField,
    kikCreditLineField,
  } = useImportantFields(item)

  const stopFactorResultsByBanks = computed(() => {
    if (!type || !item.value) {
      return undefined
    }

    const product = item.value.products[type]

    if (!product) {
      return undefined
    }

    return product.banks
      .map(bank => {
        const founderErrors: {comment: string, result: StopFactorResultEnum}[] = []
        const founderFieldsCodesToCHeck = [
          FieldsCodesEnum.CLIENT_HF_RESIDENT,
          FieldsCodesEnum.CLIENT_HF_BIRTHDAY,
        ]
        product.client.head.fields
          .filter(field => founderFieldsCodesToCHeck.includes(field.code as FieldsCodesEnum))
          .forEach(field => {
            founderErrors.push(...getBankErrors(field, bank))
          })
        if (product.client.founders && product.client.founders.length > 0) {
          product.client.founders
            .forEach(founder => {
              founder.fields
                .filter(field => founderFieldsCodesToCHeck.includes(field.code as FieldsCodesEnum))
                .forEach(field => {
                  founderErrors.push(...getBankErrors(field, bank))
                })
            })
        }

        return {
          id: bank.id,
          errors: [
            ...getBankErrors(bgSumField.value, bank),
            ...getBankErrors(startDateField.value, bank),
            ...getBankErrors(endDateField.value, bank),
            ...getBankErrors(kikSumField.value, bank),
            ...getBankErrors(kikMonthsField.value, bank),
            ...getBankErrors(kikTypeField.value, bank),
            ...getBankErrors(kikCreditLineField.value, bank),
            ...founderErrors,
          ]
            .filter(
              (value, index, source) => {
                return source.findIndex(i => i.comment === value.comment) === index
              })
        }
      })
  })

  const getBankErrors = (field: ApplicationFormField | undefined, bank: ApplicationFormBank) => {
    if (!field || !field.valid) {
      return []
    }

    const valid = field.valid
      .find(v => v.id === bank.bank_id)

    if (!valid) {
      return []
    }

    return valid.errors
      .filter(error => error !== 'Поле обязательно для заполнения')
      .map(error => ({
        comment: error,
        result: StopFactorResultEnum.error,
      }))
  }

  const getStopFactorsResultsById = (id: string) => {
    const stops = (stopFactorResultsByBanks.value || [])
      .find(b => b.id === id)

    return stops ? stops.errors : []
  }

  return {
    stopFactorResultsByBanks,
    getStopFactorsResultsById,
  }
}
