import {useGetApi} from 'src/uses/Api/useGetApi'
import {ref} from 'vue'
import {usePutApi} from 'src/uses/Api/usePutApi'
import {RequiredProductsResponseData} from 'src/models/Products/RequiredProductsResponseData'
import {RequiredProductItem} from 'src/models/Products/RequiredProductItem'
import {
  RequiredAgentProductItem,
  RequiredAgentProductsRequestData
} from 'src/models/Products/RequiredAgentProductsRequestData'

export const useRequiredProductsForAgent = () => {
  const {
    loading,
    get,
    errors,
    error,
    status,
    response,
  } = useGetApi<RequiredProductsResponseData>()

  const {
    loading: putLoading,
    put,
    status: putStatus,
  } = usePutApi<RequiredAgentProductsRequestData, RequiredAgentProductsRequestData>()

  const requiredProducts = ref<RequiredProductItem[]>([])

  const selectedBanks = ref<string[]>([])

  const loadRequiredProducts = async (id: string) => {
    await get(`v1/admin/agent/${id}/required-products`)

    if (status.value === 200 && response && response.value) {
      requiredProducts.value = response.value?.items
      setSelectedBanks()
    }
  }
  const setSelectedBanks = () => {
    requiredProducts.value.forEach((item: RequiredProductItem) => {
      if (item.required) {
        selectedBanks.value.push(item.id)
      }
    })
  }

  const save = async (id: string) => {
    const requestData: RequiredAgentProductItem[] = []
    requiredProducts.value.forEach((item: RequiredProductItem) => {
      requestData.push({
        id: item.id,
        required: selectedBanks.value.includes(item.id)
      })
    })

    await put(`v1/admin/agent/${id}/required-products`, {products: requestData})
  }

  return {
    loading,
    putLoading,
    putStatus,
    status,
    get,
    errors,
    error,
    requiredProducts,
    selectedBanks,
    loadRequiredProducts,
    save,
  }
}
