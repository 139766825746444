import {ref} from 'vue'
import {useGetApi} from 'src/uses/Api/useGetApi'
import {FormItemField} from 'src/models/FormEditor/FormItemResponseData'
import {FormItemFieldsListResponse} from 'src/models/FormEditor/FormItemFieldsListResponse'

export const useFormItemAddFieldsList = () => {
  const {
    loading,
    get,
    errors,
    status,
    response,
  } = useGetApi<FormItemFieldsListResponse>()

  const fields = ref<FormItemField[]>([])

  const load = async (sectionId: string, name: string) => {
    await get('v2/forms/fields', {
      'filter[sectionId]': sectionId,
      'filter[name]': name,
    })

    if (status.value === 200 && response.value?.items) {
      fields.value = response.value?.items
    }
  }

  return {
    load,
    fields,
    loading,
    errors,
  }
}
