<template>
  <q-btn
    v-if="!props.item"
    class="q-ml-md"
    style="padding: 12px 23px;"
    color="primary"
    icon-right="svguse:icons.svg?2#app-plus-1"
    label="Добавить запись"
    no-caps
    @click="open"
  />
  <q-btn
    v-if="props.item"
    size="sm"
    color="blue-grey-4"
    icon="svguse:icons.svg?2#app-pencil-alt"
    round
    flat
    @click="open"
  />
  <q-dialog
    v-model="dialog"
  >
    <q-card
      class="q-mt-md bg-blue-1"
      style="min-width: 400px; max-width: 500px; border-radius: 4px !important;"
    >
      <q-card-section>
        <div class="modal-title q-pb-none">
          {{ item?.id ? `Редактирование записи ${item.id}` : 'Добавление записи' }}
        </div>
      </q-card-section>
      <q-card-section class="white-bg-field">
        <q-form
          v-if="listingsItemRecordCreateUpdateData"
          style="max-height: 70vh; overflow: auto; margin: 0 -16px; padding: 0 16px;"
        >
          <div class="row q-col-gutter-md">
            <div class="col-12">
              <q-input
                v-model="listingsItemRecordCreateUpdateData.key"
                class="field-with-top-label"
                :loading="loading"
                :error-message="getErrorMessage('key')"
                :error="hasError('key')"
                label="Универсальное поле 1"
                outlined
                hide-hint
                hide-bottom-space
                dense
                @blur="validation.key.$validate()"
                @focus="clearError('key')"
              />
            </div>
            <div class="col-12">
              <q-input
                v-model="listingsItemRecordCreateUpdateData.value"
                class="field-with-top-label"
                :loading="loading"
                :error-message="getErrorMessage('value')"
                :error="hasError('value')"
                label="Универсальное поле 2"
                outlined
                hide-hint
                hide-bottom-space
                dense
                @blur="validation.value.$validate()"
                @focus="clearError('value')"
              />
            </div>
          </div>
        </q-form>
      </q-card-section>
      <q-card-actions
        class="row justify-end q-py-md"
        style="background: #D5EAFA;"
      >
        <q-btn
          v-close-popup
          size="16px"
          padding="sm lg"
          label="Отмена"
          :disable="loading"
          unelevated
          no-caps
        />
        <q-btn
          padding="sm lg"
          size="16px"
          color="blue-7"
          label="Сохранить"
          :loading="loading"
          unelevated
          no-caps
          @click="handleSubmit"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
import {withDefaults} from 'vue'
import {useDialog} from 'src/uses/Dialogs/useDialog'
import {useValidation} from 'src/uses/useValidation'
import {ListingsItemRecord} from 'src/models/Listings/ListingRecords/ListingsItemRecord'
import {useListingsItemRecordCreate} from 'src/uses/Listings/useListingsItemRecordCreate'

const props = withDefaults(
  defineProps<{
    item?: ListingsItemRecord,
    listingsId: string,
  }>(),
  {
    item: undefined,
    listingsId: undefined,
  }
)

const emit = defineEmits<{
  (e: 'success'): void,
}>()

const listingsItemRecordCreate = useListingsItemRecordCreate()
const {
  listingsItemRecordCreateUpdateData,
  loading,
  validation,
  errors,
  status,
  create,
  update,
} = listingsItemRecordCreate

const {dialog, show, hide} = useDialog()

const open = async () => {
  clearAllErrors()
  show()
  if (props.item) {
    listingsItemRecordCreateUpdateData.value = props.item
  } else {
    listingsItemRecordCreateUpdateData.value.key = ''
    listingsItemRecordCreateUpdateData.value.value = ''
  }
}

const {
  getErrorMessage,
  hasError,
  clearError,
  clearAllErrors,
} = useValidation(validation, errors)

const success = () => {
  emit('success')
  hide()
}

const handleSubmit = async () => {
  if (listingsItemRecordCreateUpdateData.value?.id) {
    await update(props.listingsId)
  } else {
    await create(props.listingsId)
  }

  if (status.value === 201 || status.value === 200) {
    success()
  }
}
</script>
<style lang="scss">
.form-title {
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0;
  text-align: left;
  color: #424242;
}
</style>
