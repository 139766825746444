import {defineStore, storeToRefs} from 'pinia'
import {useGetApi} from 'src/uses/Api/useGetApi'
import {ref} from 'vue'
import {useGridPagination} from 'src/uses/Grid/useGridPagination'
import {ListingsItemRecordsResponseData} from 'src/models/Listings/ListingRecords/ListingsItemRecordsResponseData'
import {ListingsItemRecord} from 'src/models/Listings/ListingRecords/ListingsItemRecord'
import {
  useListingsItemRecordsGridFiltersStore
} from 'stores/Listings/ListingsRecord/useListingsItemRecordsGridFiltersStore'
import {useListingsItemRecordsGridSortStore} from 'stores/Listings/ListingsRecord/useListingsItemRecordsGridSortStore'

export const useListingsItemRecordsGridStore = defineStore('listings-records-grid', () => {
  const {
    loading,
    get,
    errors,
    error,
    status,
    response,
  } = useGetApi<ListingsItemRecordsResponseData>()

  const {
    pagination,
    gridPagination,
    perPageOptions,
    getFromStorage,
    setPagination,
    onRequestPagination,
    setPage,
  } = useGridPagination('listing-records-grid')

  const listingsGridSortStore = useListingsItemRecordsGridSortStore()
  const {
    sorting,
  } = storeToRefs(listingsGridSortStore)

  const filtersStore = useListingsItemRecordsGridFiltersStore()
  const {getFiltersQuery} = filtersStore

  const items = ref<ListingsItemRecord[]>([])

  const load = async (id: string) => {
    await getFromStorage()
    await get(
      `v2/listings/${id}/records`,
      {
        page: pagination.value?.page,
        perpage: pagination.value?.per_page,
        ...sorting.value,
        ...getFiltersQuery()
      }
    )

    if (status.value === 200 && response && response.value?.items) {
      items.value = response.value?.items
      setPagination(response.value?.pagination)
    }
  }

  return {
    loading,
    get,
    errors,
    error,
    items,
    pagination,
    gridPagination,
    perPageOptions,
    onRequestPagination,
    load,
    setPage,
  }
})
