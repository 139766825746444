<template>
  <q-icon
    name="svguse:icons.svg?2#app-exclamation-circle-bold"
  >
    <q-tooltip>
      <div class="row">
        <q-icon
          class="q-mr-md"
          color="orange"
          name="svguse:icons.svg?2#app-exclamation-circle"
          size="sm"
        />
        <div>
          <div class="row items-center no-wrap q-col-gutter-x-xs q-mb-xs">
            <div
              class="bg-grey-7 rounded-borders"
              style="width: 14px; height: 14px"
            />
            <div>
              — запрашиваемая сумма
            </div>
          </div>
          <div class="row items-center no-wrap q-col-gutter-x-xs q-mb-xs">
            <div
              class="bg-primary rounded-borders"
              style="width: 14px; height: 14px"
            />
            <div>
              — одобренная сумма
            </div>
          </div>
          <div class="row items-center no-wrap q-col-gutter-x-xs">
            <div
              class="bg-green-14 rounded-borders"
              style="width: 14px; height: 14px"
            />
            <div>
              — выданная сумма
            </div>
          </div>
        </div>
      </div>
    </q-tooltip>
  </q-icon>
</template>

<script setup lang="ts">

</script>

<style scoped lang="scss">

</style>
