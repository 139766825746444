<template>
  <div>
    <div class="flex justify-between">
      <div class="news-title q-mb-md">Новости</div>
      <router-link
        :to="{name: 'news'}"
        style="line-height: 28px;"
      >
        Перейти ко всем новостям
      </router-link>
    </div>
    <div
      v-if="!loading"
      class="row q-col-gutter-sm"
    >
      <div
        v-for="(item, index) in items"
        :key="item.id"
        :class="index < countOfBig ? 'col-sm-6 col-xs-12' : 'col-sm-3 col-xs-6'"
      >
        <news-list-item
          :item="item"
          :is-big="index < countOfBig"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {storeToRefs} from 'pinia'
import {onBeforeMount, onBeforeUnmount, computed} from 'vue'
import {useNewsListStore} from 'src/stores/News/useNewsListStore'
import NewsListItem from 'src/components/News/NewsListItem.vue'
const newsListStore = useNewsListStore()

const props = defineProps<{
  count: number,
  countOfBig: number,
}>()
const count = computed(() => props.count)
const countOfBig = computed(() => props.countOfBig)
const {
  items,
  loading,

} = storeToRefs(newsListStore)

const {
  load,
  setPerPage,
} = newsListStore

onBeforeMount(async () => {
  setPerPage(count.value || 3)
  await load()
})

onBeforeUnmount(() => {
  newsListStore.$reset()
  newsListStore.$dispose()
})
</script>

<style lang="scss">
.news-title {
  font-weight: 700;
  font-size: 22px;
  line-height: 22px;
  color: #424242;
}
</style>
