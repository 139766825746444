import {defineStore} from 'pinia'
import {useGetApi} from 'src/uses/Api/useGetApi'
import {computed, ref} from 'vue'
import {HelpBlock} from 'src/models/Help/HelpBlock'

export const useHelpStore = defineStore('help-pages', () => {
  const {
    loading,
    get,
    errors,
    error,
    status,
    response,
  } = useGetApi<HelpBlock[]>()

  const helpBlocks = ref<HelpBlock[]>([])
  const search = ref<string>('')
  const filterBlock = ref<{value: string, label: string} | null>(null)
  const filteredBlocks = ref<HelpBlock[]>([])

  const blocksForSelect = computed(() => {
    return helpBlocks.value.map(block => {
      return {
        label: block.title,
        value: block.id,
      }
    })
  })

  const load = async () => {
    if (loading.value) {
      return
    }
    await get('help/blocks')

    if (status.value === 200 && response.value) {
      helpBlocks.value = response.value
      filteredBlocks.value = response.value
    }
  }

  const changeFilter = () => {
    filteredBlocks.value = helpBlocks.value
    const blockId = filterBlock.value?.value
    if (filterBlock.value) {
      filteredBlocks.value = filteredBlocks.value.filter(hp => hp.id === blockId)
    }

    if (search.value !== '' && search.value !== null) {
      filteredBlocks.value = filteredBlocks.value.map(b => {
        return {
          ...b,
          pages: b.pages?.filter(p => p.title.toLowerCase().indexOf(search.value) !== -1)
        }
      })
    }
  }

  const clearSearchFilter = () => {
    filteredBlocks.value = filteredBlocks.value.map(b => {
      const helpBlock = helpBlocks.value.find(block => block.id === b.id)
      return {
        ...b,
        pages: helpBlock?.pages || []
      }
    })
  }

  return {
    loading,
    get,
    errors,
    error,
    helpBlocks,
    blocksForSelect,
    search,
    filterBlock,
    filteredBlocks,
    load,

    changeFilter,
    clearSearchFilter,
  }
})
