import {defineStore} from 'pinia'
import {usePostApi} from 'src/uses/Api/usePostApi'
import {ref} from 'vue'

export interface SendGo {
  orders: string[],
}

export const useApplicationSendGoStore = defineStore('application-sendGo', () => {
  const {
    loading,
    post,
    status,
    response,
  } = usePostApi<SendGo, SendGo>()

  const success = ref<boolean>(false)
  const sendGo = async (id: string) => {
    const requestData = {
      orders: [id]
    }

    await post('v1/order/gosoblako/send', requestData)
    if (status.value === 200 && response.value) {
      success.value = true
    }
  }

  return {
    sendGo,
    success,
    loading
  }
})
