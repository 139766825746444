<template>
  <q-intersection
    transition="scale"
    once
  >
    <div
      ref="progressRef"
      class="row filledProgress text-weight-medium"
      @resize="onResize"
    >
      <div
        class="filled"
        :style="`flex-basis: ${props.modelValue}%;`"
      >
        <span v-if="filledText">Заполнено на {{ props.modelValue }} %</span>
      </div>
      <div class="empty col">
        <span v-if="!filledText">Заполнено на {{ props.modelValue }} %</span>
      </div>
      <q-resize-observer @resize="onResize" />
    </div>
  </q-intersection>
</template>

<script setup lang="ts">
import {computed, ref} from 'vue'

const props = defineProps<{
  modelValue: number,
}>()

const filledText = computed(() => {
  if (!progressRef.value) {
    return props.modelValue > 20
  }

  return width.value * props.modelValue / 100 >= 200
})

const progressRef = ref(null)
const width = ref<number>(0)

const onResize = () => {
  if (!progressRef.value) {
    return
  }

  const el = progressRef.value as Element
  width.value = el.clientWidth
}
</script>

<style lang="scss">
  .filledProgress {
    height: 32px;
    line-height: 32px;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 14px;
    background: #D5EAFA;

    .filled {
      text-align: right;
      color: white;
      background: $green-14;
      border-left: 1px solid $green-14;
    }

    .empty {
      text-align: left;
      color: $green-14;
    }

    span {
      padding: 0 10px;
    }
  }
</style>
