import {defineStore, storeToRefs} from 'pinia'
import {ControlCenterHeader} from 'src/models/Applications/ControlCenter/ControlCenterHeader'
import {
  useApplicationControlCenterItemStore
} from 'src/stores/Applications/ControlCenter/useApplicationControlCenterItemStore'
import {watch} from 'vue'
import {ChangeStatusPayload} from 'src/models/Status/ChangeStatusPayload'
import {useApplicationControlCenterStore} from 'stores/Applications/ControlCenter/useApplicationControlCenterStore'
import {useProfileStore} from 'stores/Login/useProfileStore'
import {useStatusKikStore} from 'stores/Status/useStatusKikStore'
import {ApplicationKikStatusEnum} from 'src/models/Applications/ApplicationKikStatusEnum'
import {useKikChangeStatusAction} from 'src/uses/Applications/useKikChangeStatusAction'
import {ApplicationKikSubStatusEnum} from 'src/models/Applications/ApplicationKikSubStatusEnum'

export const useApplicationKikControlCenterChangeStatusStore = (appId: string) => defineStore(
  `application-kik-control-center-change-status-${appId}`,
  () => {
    const {
      init,
    } = useApplicationControlCenterStore(appId)()

    const {
      changeStatus: applicationChangeStatus
    } = useStatusKikStore()

    const {
      loading,
      status,
      response,
      lastStatusPayload,
    } = storeToRefs(useStatusKikStore())

    const applicationControlCenterItemStore = useApplicationControlCenterItemStore(appId)()
    const {
      startUpdateChangesProcess,
    } = applicationControlCenterItemStore

    const changeStatusUse = useKikChangeStatusAction()

    const profileStore = useProfileStore()
    const {
      isAgentOrEmployees,
      isBankEmployee,
    } = profileStore

    const isFilledDraft = (info: ControlCenterHeader) => {
      return ApplicationKikStatusEnum.draft === info.sub_status as never as ApplicationKikStatusEnum
        && info.filled
    }

    const showClientReject = (info: ControlCenterHeader) => {
      if (isBankEmployee()) {
        return false
      }
      if (info.required && isAgentOrEmployees()) {
        return false
      }

      return changeStatusUse.showClientReject(info.sub_status as ApplicationKikSubStatusEnum)
    }

    const changeStatus = async (
      payload: ChangeStatusPayload,
    ) => {
      await init()
      await applicationChangeStatus(payload)
    }

    watch(
      lastStatusPayload,
      async (value, oldValue) => {
        if (!oldValue || value) {
          return
        }

        if (status.value !== 200 || !response.value) {
          return
        }

        startUpdateChangesProcess(response.value)
      },
      {
        deep: true,
        immediate: true,
      }
    )

    return {
      loading,
      showClientReject,
      changeStatus,
      isFilledDraft,
    }
  })
