<template>
  <div class="FormItemDocumentDescription row items-center">
    <q-btn
      icon="svguse:icons.svg?2#app-pencil-alt"
      color="blue-7"
      class="FormItemDocumentDescription--btn float-left"
      no-caps
      unelevated
      flat
      dense
      @click="showDialog"
    />
    <div
      class="col"
      style="text-overflow: ellipsis; overflow: hidden; max-width: 100%;"
    >
      {{ modelValue }}
    </div>
  </div>
  <q-dialog
    v-model="dialog"
  >
    <q-card
      style="min-width: 400px; width: 100vw; max-width: 600px;"
      class="bg-blue-1"
    >
      <q-card-section class="text-h6 rounded-borders">
        Описание для документа
      </q-card-section>
      <q-card-section class="q-pt-none white-bg-field">
        <q-input
          v-model="docDesc"
          class="field-with-top-label"
          label="Текст описания"
          outlined
          hide-bottom-space
          dense
          emit-value
          map-options
          options-dense
          hide-dropdown-icon
          clearable
        />
      </q-card-section>
      <q-card-actions
        class="row justify-end q-py-md"
        style="background: #D5EAFA;"
      >
        <q-btn
          size="16px"
          padding="sm lg"
          label="Отмена"
          :disable="loading"
          style="padding: 17px 28px;"
          unelevated
          no-caps
          v-close-popup
        />
        <q-btn
          padding="sm lg"
          size="16px"
          color="blue-7"
          label="Сохранить"
          icon-right="svguse:icons.svg?2#app-document-duplicate"
          :loading="loading"
          :disable="docDesc === ''"
          style="padding: 17px 28px;"
          unelevated
          no-caps
          @click="save"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
import {watchEffect, ref} from 'vue'
import {useDialog} from 'src/uses/Dialogs/useDialog'
import {useFormItemUpdateDocumentDescription} from 'src/uses/FormEditor/useFormItemUpdateDocumentDescription'

const props = defineProps<{
  modelValue: string | null,
  docId: string,
}>()

const emit = defineEmits<{
  (e: 'update:model-value', value: string): void,
}>()

const docDesc = ref<string>('')

const formItemUpdateDocumentDescription = useFormItemUpdateDocumentDescription()
const {
  status,
  loading,
  updateDocumentDescription
} = formItemUpdateDocumentDescription

const {
  dialog,
  show,
  hide,
} = useDialog()

const save = async () => {
  if (docDesc.value === '') {
    return
  }

  await updateDocumentDescription(props.docId, docDesc.value || '')
  if (status.value === 200) {
    emit('update:model-value', docDesc.value)
    hide()
  }
}

watchEffect(() => {
  docDesc.value = props.modelValue || ''
})

const showDialog = async () => {
  show()
}

</script>
<style lang="scss">
.FormItemDocumentDescription {
  height: 55px;
  .FormItemDocumentDescription--btn {
    display: none;
  }
  &:hover {
    .FormItemDocumentDescription--btn {
      display: flex;
    }
  }
}
</style>
