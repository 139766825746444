import {usePutApi} from 'src/uses/Api/usePutApi'

export const useReleaseDateUpdate = () => {
  const {
    error,
    errors,
    put,
    status,
    response,
    loading,
  } = usePutApi<unknown, {date: string}>()

  const update = async (appId: string, date: string) => {
    await put(
      `v2/control-center/${appId}/release-date`,
      {
        date,
      }
    )
  }

  return {
    error,
    errors,
    put,
    status,
    response,
    loading,
    update,
  }
}
