<template>
  <div
    :class="classes"
    style="background: #D5EAFA;"
  >
    <div class="row">
      <q-icon
        name="svguse:icons.svg?2#app-information-circle"
        size="sm"
      />
      <div class="col q-pl-md">
        {{ props.text }}
        <slot />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {computed, withDefaults} from 'vue'

const props = withDefaults(
  defineProps<{
    text?: string,
    small?: boolean,
  }>(),
  {
    small: false,
    text: ''
  }
)

const classes = computed(() => {
  return {
    BlueInfo: true,
    'text-blue-grey-5': true,
    'q-pa-lg': !props.small,
    'q-pa-md': props.small,
  }
})
</script>

<style lang="scss">
.BlueInfo {
  border-radius: 6px;
}
</style>
