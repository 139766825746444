import {useRequestApi} from './useRequestApi'
import {ResponseType} from 'axios'
import {GetReturnInterface} from 'src/models/Api/GetReturnInterface'
/*import {storeToRefs} from 'pinia'
import {useSoftReloadStore} from 'stores/useSoftReloadStore'*/
import {ref} from 'vue'

export const useGetApi: <T = never>(showError?: boolean, responseType?: ResponseType) => GetReturnInterface<T> = <T>(showErrors = false, responseType: ResponseType = 'json') => {
  const {
    response,
    error,
    errors,
    status,
    loading,
    request,
    headers,
    progressEvent,
    abort,
    setHeader,
  } = useRequestApi<T>(showErrors, responseType)

  /*const {
    lastSoftReloadStartMoment,
  } = storeToRefs(useSoftReloadStore())

  const {
    addLoading,
    removeLoading,
  } = useSoftReloadStore()*/

  const lastUrl = ref<string>()
  const lastFilters = ref()

  const get = async (url: string, filters = {}) => {
    lastUrl.value = url
    lastFilters.value = filters
    const query = Object.entries<string>(filters)
      .map(entry => {
        if (Array.isArray(entry[1])) {
          return entry[1].map((item) => `${entry[0]}[]=${item}`)
            .join('&')
        }
        return `${entry[0]}=${entry[1]}`
      })
    const queryString = query.length > 0 ? `?${query.join('&')}` : ''

    await request(`${url}${queryString}`, 'GET')
  }

  /*watch(
    lastSoftReloadStartMoment,
    async () => {
      if (loading.value || !lastUrl.value) {
        return
      }

      addLoading(lastUrl.value)
      await get(lastUrl.value, lastFilters.value || {})
      removeLoading(lastUrl.value)
    },
    {
      deep: true,
    }
  )*/

  return {
    response,
    error,
    errors,
    status,
    loading,
    headers,
    progressEvent,
    abort,
    setHeader,
    get,
  }
}
