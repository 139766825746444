import {computed, ref} from 'vue'
import {
  ApplicationFormFinance,
  ApplicationFormFinanceField, ApplicationFormFinancePeriod,
} from 'src/models/Applications/Form/ApplicationFormResponseData'
import {useUpdateFinanceField} from 'src/uses/Applications/Form/Finance/useUpdateFinanceField'
import {ApplicationFormUpdateResponse} from 'src/models/Applications/Form/ApplicationFormUpdateResponse'
import {usePeriod} from 'src/uses/Applications/Form/Finance/usePeriod'
import {useUpdateSectionFields} from 'src/uses/Applications/Form/useUpdateSectionFields'

export const useFinanceFields = (multiOrderId?: number, appId?: string) => {
  const finance = ref<ApplicationFormFinance>()
  const response = ref<ApplicationFormUpdateResponse>()

  const tab = ref<number | null>(null)

  const {
    getPeriodFields,
  } = usePeriod()

  const {
    updateValues,
    updateErrorProcess,
    updateSuccessProcess,
  } = useUpdateSectionFields()

  const financeVisible = computed<ApplicationFormFinance | undefined>(() => {
    if (!finance.value) {
      return undefined
    }

    return {
      ...finance.value,
      fields: Object.fromEntries(
        Object.entries(finance.value.fields)
          .map(e => {
            e[1] = e[1].filter(f => f.invisible === false)

            return e
          })
      )
    }
  })

  const periods = computed<ApplicationFormFinancePeriod[]>(() => {
    if (!financeVisible.value) {
      return []
    }

    return Object.values(financeVisible.value.periods).sort(
      (a, b) => a.year > b.year || (a.year === b.year && a.quarter > b.quarter) ? -1 : 1
    )
  })

  const updateFinanceField = async (
    data: ApplicationFormFinanceField,
    period: ApplicationFormFinancePeriod,
  ) => {
    if (!financeVisible.value) {
      return
    }

    const {
      status,
      errors,
      response: updateResponse,
      update,
    } = useUpdateFinanceField(multiOrderId, appId)

    const fields = getPeriodFields(
      period,
      financeVisible.value
    )

    const updateField = [{code: data.code, value: data.value}]

    updateValues(
      updateField,
      fields
    )
    await update(
      fields,
      period.year,
      period.quarter
    )

    if (status.value !== 200 && errors.value) {
      updateErrorProcess(updateField, fields, errors.value)
      response.value = undefined

      return
    }

    updateSuccessProcess(updateField, fields)
    response.value = updateResponse.value
  }

  return {
    finance,
    financeVisible,
    response,
    updateFinanceField,
    periods,
    tab,
  }
}
