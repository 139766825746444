export enum FieldsCodesEnum {
  DEAL_RNT = 'DEAL_RNT',
  DEAL_LOT = 'DEAL_LOT',
  DEAL_LAW = 'DEAL_LAW',
  DEAL_GUARANTEE_TYPE = 'DEAL_GUARANTEE_TYPE',
  DEAL_GUARANTEE_STARTDATE = 'DEAL_GUARANTEE_STARTDATE',
  DEAL_GUARANTEE_FINISHDATE = 'DEAL_GUARANTEE_FINISHDATE',
  DEAL_GUARANTEE_START_DATE_TENDER = 'DEAL_GUARANTEE_START_DATE_TENDER',
  DEAL_GUARANTEE_FINISH_DATE_TENDER = 'DEAL_GUARANTEE_FINISH_DATE_TENDER',
  DEAL_GUARANTEE_AMOUNT_TENDER = 'DEAL_GUARANTEE_AMOUNT_TENDER',
  DEAL_GUARANTEE_AMOUNT = 'DEAL_GUARANTEE_AMOUNT',
  DEAL_GUARANTEE_ISSUE_FORM = 'DEAL_GUARANTEE_ISSUE_FORM',
  DEAL_GUARANTEE_ISSUE_FORM_MAILHARDCOPY = 'DEAL_GUARANTEE_ISSUE_FORM_MAILHARDCOPY',
  DEAL_BG1397 = 'DEAL_BG1397',
  DEAL_DECLINE = 'DEAL_DECLINE',
  DEAL_START_FROM_RELEASE_DATE = 'DEAL_START_FROM_RELEASE_DATE',
  DEAL_PURCHASE_IDENTITY_CODE = 'DEAL_PURCHASE_IDENTITY_CODE',
  DEAL_IS_PURCHASE_CLOSED = 'DEAL_IS_PURCHASE_CLOSED',
  DEAL_CUSTOMER_CONTACT_PHONE = 'DEAL_CUSTOMER_CONTACT_PHONE',
  DEAL_OKPD_CODE = 'DEAL_OKPD_CODE',
  DEAL_OKPD_SUM = 'DEAL_OKPD_SUM',
  DEAL_OKPD_NAME = 'DEAL_OKPD_NAME',
  DEAL_CREDIT_AMOUNT = 'DEAL_CREDIT_AMOUNT',
  DEAL_CREDIT_MONTHS = 'DEAL_CREDIT_MONTHS',
  DEAL_CREDIT_TYPE = 'DEAL_CREDIT_TYPE',
  DEAL_CREDIT_AMOUNT_ISSUED = 'DEAL_CREDIT_AMOUNT_ISSUED',
  DEAL_CREDIT_MONTHS_ISSUED = 'DEAL_CREDIT_MONTHS_ISSUED',
  DEAL_CREDIT_AMOUNT_APPROVED = 'DEAL_CREDIT_AMOUNT_APPROVED',
  DEAL_CREDIT_MONTHS_APPROVED = 'DEAL_CREDIT_MONTHS_APPROVED',
  DEAL_CREDIT_LINE = 'DEAL_CREDIT_LINE',

  CLIENT_HF_NUMBER = 'CLIENT_HF_NUMBER',
  CLIENT_HF_DOC_PLACE = 'CLIENT_HF_DOC_PLACE',
  CLIENT_HF_CODE_PLACE = 'CLIENT_HF_CODE_PLACE',
  CLIENT_HF_BIRTHDAY = 'CLIENT_HF_BIRTHDAY',
  CLIENT_HF_BIRTHPLACE = 'CLIENT_HF_BIRTHPLACE',
  CLIENT_HF_GENDER = 'CLIENT_HF_GENDER',
  CLIENT_HF_DOC_DATE = 'CLIENT_HF_DOC_DATE',
  CLIENT_HF_NAME = 'CLIENT_HF_NAME',
  CLIENT_HF_DOC_NUM = 'CLIENT_HF_DOC_NUM',
  CLIENT_HF_PATRONYMIC = 'CLIENT_HF_PATRONYMIC',
  CLIENT_HF_DOC_SER = 'CLIENT_HF_DOC_SER',
  CLIENT_HF_SURNAME = 'CLIENT_HF_SURNAME',
  CLIENT_HF_UR_FL = 'CLIENT_HF_UR_FL',
  CLIENT_HF_UL_Name = 'CLIENT_HF_UL_Name',
  CLIENT_COMMON_MR_CONTACT_OFFICE_PHONE = 'CLIENT_COMMON_MR_CONTACT_OFFICE_PHONE',
  CLIENT_HF_RESIDENT = 'CLIENT_HF_RESIDENT',
}
