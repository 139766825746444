<template>
  <q-expansion-item
    v-if="needShow"
    class="DocumentsBlock"
    :model-value="true"
    :label="getDocumentGroupLabel(props.documentType)"
  >
    <template v-if="documents">
      <div class="FormItemDocumentsGrid--table">
        <q-table
          :rows="docsByType"
          :columns="columns"
          :pagination="{rowsPerPage: 0}"
          separator="none"
          row-key="id"
          wrap-cells
          hide-bottom
        >
          <template #body-cell-title="props">
            <q-td
              style="max-width: 200px; text-overflow: ellipsis; overflow: hidden"
              :props="props"
            >
              {{ getTitle(props.row) }}
              <q-tooltip
                :delay="500"
              >
                {{ getTitle(props.row) }}
              </q-tooltip>
            </q-td>
          </template>
          <template #body-cell-description="props">
            <q-td
              :props="props"
            >
              <div :style="`width: ${currentContentWidth - 700}px; overflow: hidden; position: relative;`">
                <q-tooltip
                  v-if="props.row.description"
                  :delay="500"
                >
                  {{ props.row.description }}
                </q-tooltip>
                <form-item-edit-document-description-dialog
                  v-model="props.row.description"
                  :doc-id="props.row.id"
                />
              </div>
            </q-td>
          </template>
          <template #body-cell-required="rowProps">
            <q-td
              :props="rowProps"
              auto-width
            >
              <q-toggle
                :model-value="!!rowProps.row.required"
                class="q-mr-sm"
                @update:model-value="updateDocumentSelectedRequired(false, rowProps.row, $event)"
              />
            </q-td>
          </template>
          <template #body-cell-client_document="props">
            <q-td
              :props="props"
              auto-width
            >
              <q-toggle
                v-model="props.row.client_document"
                class="q-mr-sm"
                @update:model-value="updateDocumentClientDocument(props.row.id, $event)"
              />
            </q-td>
          </template>
          <template #body-cell-enabled="rowProps">
            <q-td
              :props="rowProps"
              auto-width
            >
              <q-toggle
                :model-value="!!rowProps.row.selected"
                class="q-mr-sm"
                @update:model-value="updateDocumentSelectedRequired(true, rowProps.row, $event)"
              />
            </q-td>
          </template>
        </q-table>
      </div>
    </template>
  </q-expansion-item>
</template>

<script setup lang="ts">
import {
  ApplicationFormDocumentGroupEnum,
} from 'src/models/Applications/Form/ApplicationFormResponseData'
import {useDocumentGroup} from 'src/uses/Applications/useDocumentGroup'
import {FormItemDocumentListItem} from 'src/models/FormEditor/FormItemDocumentListItem'
import {computed, ref} from 'vue'
import {useFormItemDocumentsStore} from 'stores/FormEditor/useFormItemDocumentsStore'
import {storeToRefs} from 'pinia'
import FormItemEditDocumentDescriptionDialog
  from 'components/FormEditor/Documents/FormItemEditDocumentDescriptionDialog.vue'
import {QTableColumn} from 'src/models/Grid/GridColumnSettings'
import {DocumentsCodesEnum} from 'src/models/DocumentsCodesEnum'
import {useLayoutStore} from 'stores/useLayoutStore'

const props = defineProps<{
  formId: string,
  documentType: ApplicationFormDocumentGroupEnum,
  search: string,
}>()

const {currentContentWidth} = storeToRefs(useLayoutStore())

const columns = ref<QTableColumn[]>([
  {
    name: 'title',
    field: 'title',
    required: true,
    label: 'Наименование',
    align: 'left',
    classes: 'text-no-wrap',
    sortable: false,
  },
  {
    name: 'description',
    field: 'description',
    required: true,
    label: 'Описание',
    align: 'left',
    classes: 'text-no-wrap',
    sortable: false,
  },
  {
    name: 'required',
    field: 'required',
    required: true,
    label: 'Обязательный',
    align: 'left',
    classes: 'text-no-wrap',
    sortable: false,
  },
  {
    name: 'client_document',
    field: 'client_document',
    required: true,
    label: 'Клиентский',
    align: 'left',
    classes: 'text-no-wrap',
    sortable: false,
  },
  {
    name: 'enabled',
    field: 'selected',
    required: true,
    label: 'Включен',
    align: 'left',
    classes: 'text-no-wrap',
    sortable: false,
  },
])

const {
  getDocumentGroupLabel,
} = useDocumentGroup()

const formItemDocumentsStore = useFormItemDocumentsStore(props.formId)()
const {
  updateDocumentSelectedRequired,
  updateDocumentClientDocument,
} = formItemDocumentsStore
const {
  documents,
} = storeToRefs(formItemDocumentsStore)

const docsByType = computed(() => {
  const docs = !props.search
    ? documents.value
    : filteredDocs()

  return docs
    .filter(item => item.group === props.documentType)
})

const needShow = computed(() => {
  return documents.value
    .filter(item => item.group === props.documentType)
    .length !== 0
})

const getTitle = (doc: FormItemDocumentListItem) => {
  if (doc.group === ApplicationFormDocumentGroupEnum.financial) {
    return `${doc.title} ${doc.period[0]?.quarter} кв. ${doc.period[0]?.year}`
  }

  return doc.title
}

const filteredDocs = () => {
  return documents.value
    .filter(d => {
      const title = d.code === DocumentsCodesEnum.ACCOUNTING_REPORTS && d.period[0]
        ? `${d.title} ${d.period[0].quarter} кв. ${d.period[0].year}`
        : d.title

      return title
        .indexOf(props.search) !== -1
    })
}

</script>

<style lang="scss">
.DocumentsBlock {
  &:hover {
    background: transparent !important;
  }

  .q-focus-helper {
    background: transparent !important;
  }

  .q-item {
    padding-left: 0 !important;
    padding-right: 0 !important;

    font-weight: 700;
    font-size: 16px;
  }
}
</style>
