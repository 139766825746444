<template>
  <q-form>
    <div class="row q-col-gutter-lg white-bg-field">
      <div class="col-12 q-mb-sm">
        <q-select
          v-model="registryType"
          class="field-with-top-label"
          :options="registryTypeForSelect"
          :loading="props.loading"
          style="min-width: 200px"
          map-options
          label="Укажите реестр из выпадающего списка"
          outlined
          hide-hint
          hide-bottom-space
          dense
          emit-value
        />
      </div>
      <template v-if="registryType === 'open'">
        <div class="col-12 q-mb-sm">
          <span>Укажите РНТ и ИКЗ или выберите размещение как закупка у единственного поставщика</span>
        </div>
        <q-input
          v-if="rntField"
          v-model="rnt"
          :loading="props.loading"
          :disable="singleSupplier"
          class="field-with-top-label col q-mb-md"
          label="РНТ"
          mask="###################"
          outlined
          hide-bottom-space
          dense
        />
        <q-input
          v-if="ikzField"
          v-model="ikz"
          :disable="singleSupplier"
          :loading="props.loading"
          class="field-with-top-label col-12"
          label="ИКЗ"
          :mask="(ikzField.mask || '').replaceAll('0', '#') || undefined"
          outlined
          hide-bottom-space
          dense
        />
        <div class="col-12 q-mb-sm">
          <q-toggle
            v-model="singleSupplier"
            :loading="props.loading"
            label="Разместить как закупку у единственного поставщика"
          />
        </div>
      </template>
    </div>
  </q-form>
</template>

<script setup lang="ts">
import {storeToRefs} from 'pinia'
import {useRegistryFormStore} from 'src/stores/Applications/Forms/useRegistryFormStore'

const props = defineProps<{
  id: string,
  loading: boolean,
}>()

const registryForm = useRegistryFormStore(props.id)()
const {
  registryType,
  singleSupplier,
  registryTypeForSelect,
  rntField,
  ikzField,
  ikz,
  rnt,
} = storeToRefs(registryForm)

</script>

<style lang="scss">

</style>
