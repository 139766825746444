<template>
  <q-tabs
    :model-value="tab"
    class="productsTabs q-my-md"
    align="justify"
    active-color="white"
    active-bg-color="blue-7"
    inline-label
    stretch
    no-caps
    @update:model-value="onUpdate"
  >
    <q-tab
      :name="products.EBG"
      label="Заявка ЭБГ"
      icon="svguse:/icons.svg#ebg"
      :disable="!EBGEnabled"
    >
      <template v-if="productEBGData && productEBGData.errors.length">
        <q-tooltip>
          <div class="StopTooltip--head q-mb-xs">Стоп-факторы:</div>
          <template
            v-for="(error, index) in productEBGData.errors"
            :key="index"
          >
            <div>
              <q-icon
                name="svguse:icons.svg?2#app-x-circle-bold"
                size="24px"
                style="margin-top: -2px;"
                color="red"
              />
              {{ error }}
            </div>
          </template>
        </q-tooltip>
        <q-badge
          class="bg-transparent"
          floating
        >
          <q-icon
            name="svguse:icons.svg?2#app-exclamation-circle-bold"
            color="red"
            size="xs"
          />
        </q-badge>
      </template>
      <div
        class="col text-right"
        style="min-width: 72px;"
      >
        <q-btn
          v-if="hasEBG"
          style="margin-right: -18px"
          icon="svguse:icons.svg?2#app-trash"
          padding="lg"
          unelevated
          @click.capture.stop.prevent="removeProduct(ProductEnum.EBG)"
        >
          <q-tooltip>Удалить продукт</q-tooltip>
        </q-btn>
      </div>
    </q-tab>
    <!--<q-tab
      :name="products.RKO"
      label="Заявка PKO"
      icon="svguse:/icons.svg#rko"
      disable
    />-->
    <q-tab
      :name="products.KIK"
      label="Заявка Кредит"
      icon="svguse:/icons.svg#kik"
      :disable="!KIKEnabled"
    >
      <template v-if="productKIKData && productKIKData.errors.length">
        <q-tooltip>
          <div class="StopTooltip--head q-mb-xs">Стоп-факторы:</div>
          <template
            v-for="(error, index) in productKIKData.errors"
            :key="index"
          >
            <div>
              <q-icon
                name="svguse:icons.svg?2#app-x-circle-bold"
                size="24px"
                style="margin-top: -2px;"
                color="red"
              />
              {{ error }}
            </div>
          </template>
        </q-tooltip>
        <q-badge
          class="bg-transparent"
          floating
        >
          <q-icon
            name="svguse:icons.svg?2#app-exclamation-circle-bold"
            color="red"
            size="xs"
          />
        </q-badge>
      </template>
      <div
        class="col text-right"
        style="min-width: 72px;"
      >
        <q-btn
          v-if="hasKIK"
          style="margin-right: -18px"
          icon="svguse:icons.svg?2#app-trash"
          padding="lg"
          unelevated
          @click.capture.stop.prevent="removeProduct(ProductEnum.KIK)"
        >
          <q-tooltip>Удалить продукт</q-tooltip>
        </q-btn>
      </div>
    </q-tab>
  </q-tabs>
</template>

<script setup lang="ts">
import {ProductEnum} from 'src/models/Applications/ProductEnum'
import {computed, ref, watch} from 'vue'
import {ApplicationNewProductData} from 'src/models/Applications/New/ApplicationNewProductData'
import {useApplicationsNew} from 'stores/Applications/ApplicationsNew/useApplicationsNew'
import {storeToRefs} from 'pinia'

const props = defineProps<{
  modelValue: ProductEnum | null | undefined
  productData: ApplicationNewProductData[]
}>()

const emit = defineEmits<{
  (e: 'update:model-value', value: ProductEnum): void,
}>()

const applicationsNewStore = useApplicationsNew()
const {
  removeProduct,
} = applicationsNewStore
const {
  hasEBG,
  hasKIK,
} = storeToRefs(applicationsNewStore)

const products = ProductEnum
const tab = ref<ProductEnum | null | undefined>(undefined)

const productEBGData = computed(() => getProductData(ProductEnum.EBG))
const productKIKData = computed(() => getProductData(ProductEnum.KIK))
const EBGEnabled = computed(() => productEBGData.value && productEBGData.value.errors.length === 0)
const KIKEnabled = computed(() => productKIKData.value && productKIKData.value.errors.length === 0)

const onUpdate = (payload: ProductEnum) => {
  emit('update:model-value', payload)
}

const getProductData = (type: ProductEnum) => {
  return props.productData.find(p => p.type === type)
}

watch(
  props,
  () => {
    tab.value = props.modelValue
  },
  {immediate: true, deep: true}
)
</script>

<style lang="scss">
.productsTabs {
  border-radius: 6px;
  margin-bottom: 20px;

  .q-tab {
    background: $blue-grey-1;
    border-right: 2px solid white;

    &.bg-blue-7 {
      background: $blue-1  !important;
      color: $blue-7 !important;
    }

    &:last-child {
      border-right: none;
    }

    &.disabled {
      opacity: 1 !important;

      .q-tab__content {
        opacity: 0.6;
      }
    }

    .q-tab__content {
      height: 70px;
      min-width: 100%;
    }

    .q-tab__indicator {
      display: none;
    }
  }
}
</style>
