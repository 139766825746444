<template>
  <q-dialog
    v-model="bankRequestSignAndAgreementConfirmDialog"
  >
    <q-card
      v-if="lastStatusPayload"
      class="q-mt-md bg-blue-1"
      style="min-width: 400px; max-width: 800px; border-radius: 4px !important;"
    >
      <q-card-section class="bg-blue-grey-10 text-white row items-center q-py-lg">
        <div class="rounded-borders q-pa-sm bg-blue-grey-9">
          <q-icon
            name="svguse:icons.svg?2#app-information-circle"
            size="sm"
            color="orange-6"
          />
        </div>
        <div class="col q-pl-sm text-wgite">
          Мы заметили, что вы внесли корректировки в параметры заявки.
          Для корректного согласования правок необходимо убедиться,
          что данные в проекте БГ и в системе совпадают.
          <div class="q-mt-sm">При необходимости скорректируйте данные и обновите проект.</div>
        </div>
      </q-card-section>
      <q-stepper
        v-model="step"
        header-nav
        class="modal-stepper"
        color="primary"
        animated
      >
        <q-step
          :name="1"
          title="Подтвердите данные"
          icon="svguse:icons.svg?2#app-cog"
          :done="step > 1"
        >
          <q-card-section>
            <agreement-confirm-editable-fields
              v-if="lastStatusPayload"
              ref="agreementConfirmEditableFieldsRef"
              :app-id="lastStatusPayload.id"
            />
          </q-card-section>
        </q-step>
        <q-step
          :name="2"
          title="Подпишите документы"
          icon="svguse:icons.svg?2#app-cog"
          :done="step > 2"
        >
          <q-card-section>
            <bank-request-sign-form
              ref="bankRequestSignRef"
              :app-id="lastStatusPayload.id"
            />
          </q-card-section>
        </q-step>
      </q-stepper>
      <q-card-actions
        class="row justify-end q-py-md"
        style="background: #D5EAFA;"
      >
        <q-btn
          v-close-popup
          size="16px"
          padding="sm lg"
          label="Отмена"
          unelevated
          no-caps
        />
        <q-btn
          v-if="step === 1"
          :disable="loading"
          :loading="loading"
          padding="sm lg"
          size="16px"
          color="blue-7"
          label="Данные совпадают"
          unelevated
          no-caps
          @click="step = 2"
        />
        <q-btn
          v-if="step === 2 && bankRequestSignRef"
          :disable="needSign === undefined"
          padding="sm lg"
          size="16px"
          color="blue-7"
          label="Сохранить"
          unelevated
          no-caps
          @click="statusStore.onBankRequestSignAndAgreementConfirm(needSign)"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
import {useStatusStore} from 'src/stores/Status/useStatusStore'
import {storeToRefs} from 'pinia'
import AgreementConfirmEditableFields from 'src/components/Applications/AgreementConfirmEditableFields.vue'
import {computed, ref} from 'vue'
import BankRequestSignForm from 'src/components/Applications/BankRequestSignForm.vue'

const statusStore = useStatusStore()
const {
  bankRequestSignAndAgreementConfirmDialog,
  lastStatusPayload,
} = storeToRefs(statusStore)

const agreementConfirmEditableFieldsRef = ref<typeof AgreementConfirmEditableFields>()
const bankRequestSignRef = ref<typeof BankRequestSignForm>()

const needSign = computed(() => !bankRequestSignRef.value ? undefined : bankRequestSignRef.value.needSign)

const step = ref(1)

const loading = computed(() => {
  if (step.value === 1) {
    if (!agreementConfirmEditableFieldsRef.value) {
      return true
    }

    return agreementConfirmEditableFieldsRef.value.loading
  }

  return false
})
</script>

<style lang="scss">
.modal-stepper {
  &.q-stepper {
    background: $blue-1;
    .q-stepper__tab.col-grow {
      //flex: 0 0 ;
    }
    .q-stepper__step-inner {
      padding: 0;
    }
    .title {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 16px;
      padding-bottom: 30px;
      padding-top: 10px;
    }
    .q-stepper__header {
      max-width: 600px;
    }
  }
}
</style>
