<template>
  <div class="col-sm col-xs-12 bannerMainContent">
    Дальнейшее рассмотрение заявки возможно при оформлении поручительства
  </div>
  <div>
    <q-btn
      class="banner-btn q-ml-md"
      label="Оформить поручительство"
      icon-right="svguse:icons.svg?2#app-check-bold"
      color="primary"
      unelevated
      no-caps
      @click="show"
    />
  </div>
  <surety-dialog
    v-model="dialog"
    :app-id="props.headerInfo.id"
  />
</template>

<script setup lang="ts">
import SuretyDialog from 'src/components/Applications/ApplicationControlCenter/Surety/SuretyDialog.vue'
import {useDialog} from 'src/uses/Dialogs/useDialog'
import {ControlCenterHeader} from 'src/models/Applications/ControlCenter/ControlCenterHeader'

const props = defineProps<{
  headerInfo: ControlCenterHeader,
}>()

const {
  dialog,
  show,
} = useDialog()
</script>

<style scoped lang="scss">

</style>
