import {defineStore, storeToRefs} from 'pinia'
import {useLocalStorage} from 'src/uses/useLocalStorage'
import {computed, ref, watchEffect} from 'vue'
import {useDialog} from 'src/uses/Dialogs/useDialog'
import {useProfileStore} from 'stores/Login/useProfileStore'
import {UserStateEnum} from 'src/models/User/UserStateEnum'
import {useQuasar} from 'quasar'

export const useAgentEnterAwareStore = defineStore('agent-enter-aware', () => {
  const {
    data,
    getFromStorage,
    setToStorage,
  } = useLocalStorage<string>('agent-enter-aware-datetime')

  const {
    dialog,
  } = useDialog()

  const {profile} = storeToRefs(useProfileStore())

  const $q = useQuasar()

  const lastAwareDateTime = ref<Date>()

  const needShowDialog = computed(() => {
    if ($q.platform.is.mobile
      || !profile.value
      || !profile.value.agent_status
      || ![
        UserStateEnum.NeedDocuments,
        UserStateEnum.NeedRequisites,
      ].includes(profile.value.agent_status)
    ) {
      return false
    }

    if (!lastAwareDateTime.value) {
      return true
    }

    const now = new Date()
    const diff = now.getTime() - lastAwareDateTime.value.getTime()

    return (diff / 1000 / 60 / 60) > 24
  })

  const setAwareDateTime = () => {
    lastAwareDateTime.value = new Date()
    dialog.value = false
  }

  getFromStorage()

  watchEffect(() => {
    if (!data.value) {
      return
    }

    lastAwareDateTime.value = new Date(data.value)
  })

  watchEffect(() => {
    if (!lastAwareDateTime.value) {
      return
    }

    setToStorage(lastAwareDateTime.value.toString())
  })

  watchEffect(() => {
    if (!needShowDialog.value) {
      return
    }
    dialog.value = true
  })

  return {
    dialog,
    setAwareDateTime,
  }
})
