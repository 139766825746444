export enum ApplicationKikSubStatusEnum {
  draft = 'draft',
  new = 'new',

  client_signed = 'client_signed',
  send_2_bank = 'send_2_bank',

  in_bank = 'in_bank',
  data_request = 'data_request',
  data_request_executed = 'data_request_executed',

  approved = 'approved', //

  released = 'released', //

  client_rejected = 'client_rejected',

  bank_rejected = 'bank_rejected',

  system_rejected = 'system_rejected',
  archive = 'archive',
}
