<template>
  <div class="row q-mb-md q-col-gutter-md">
    <div class="col-8">
      <profile-employees-grid-filters
        :loading="loading"
        @update:filters="updateFilters"
      />
    </div>
    <div class="col" />
    <div>
      <profile-employee-create-modal />
    </div>
  </div>
  <div class="EmployeesGrid--table">
    <q-table
      v-model:pagination="gridPagination"
      class="sticky-header-table q-ma-sm"
      :rows="items"
      :columns="columns"
      :loading="loading"
      :rows-per-page-options="perPageOptions"
      row-key="index"
      wrap-cells
      hide-pagination
      @request="onRequest"
    >
      <template
        v-if="!loading"
        #no-data
      >
        <template v-if="filtersStore.isDefaultFilterValue">
          Вы пока не создали сотрудников, нажмите кнопку "Добавить сотрудника" чтобы перейти к создаю учетной записи
        </template>
        <template v-else>
          По вашему запросу ничего не найдено. Попробуйте изменить фильтры
        </template>
      </template>
      <template #header-cell="props">
        <q-th
          :props="props"
          class="grid-column-header"
        >
          <employees-grid-th
            :item="props.col"
            @update:sorting="updateSorting"
          />
        </q-th>
      </template>
      <template #header-cell-role="props">
        <q-th :props="props">
          Роль
          <q-icon
            name="svguse:icons.svg?2#app-exclamation-circle-bold"
            size="1.3em"
          >
            <q-tooltip
              anchor="bottom middle"
              self="top middle"
            >
              <div>Сотрудник - видит только свои заявки</div>
              <div>Старший сотрудник - видит все заявки вашего аккаунта</div>
            </q-tooltip>
          </q-icon>
        </q-th>
      </template>
      <template #body-cell-number="props">
        <q-td :props="props">
          {{ props.pageIndex + 1 }}
        </q-td>
      </template>
      <template #body-cell-fio="props">
        <q-td :props="props">
          {{ getFullName(props.row) }}
        </q-td>
      </template>
      <template #body-cell-email="props">
        <q-td :props="props">
          {{ props.row.email }}
        </q-td>
      </template>
      <template #body-cell-role="props">
        <q-td :props="props">
          {{ UserRoles.getUserRoleDescription(props.row.role) }}
        </q-td>
      </template>
      <template #body-cell-date="props">
        <q-td :props="props">
          {{ props.row.date }}
        </q-td>
      </template>
      <template #body-cell-status="props">
        <q-td :props="props">
          {{ UserStatuses.getUserStatusDescription(props.row.status) }}
        </q-td>
      </template>
      <template #body-cell-actions="props">
        <q-td :props="props">
          <profile-employee-update-modal :item="props.row" />
          <profile-employee-delete-modal :item-id="props.row.id" />
        </q-td>
      </template>
    </q-table>
    <pagination-component
      class="q-mb-md"
      :pagination="gridPagination"
      :per-page-options="perPageOptions"
      @request="onRequest"
      @refresh="load"
    />
  </div>
</template>

<script setup lang="ts">
import {storeToRefs} from 'pinia'
import {onBeforeMount, onBeforeUnmount} from 'vue'
import {useGridSettings} from 'src/uses/Grid/useGridSettings'
import {useEmployeeGridFiltersStore} from 'src/stores/Profile/Employees/useEmployeesGridFiltersStore'
import {useEmployeesGridStore} from 'src/stores/Profile/Employees/useEmployeesGridStore'
import {EmployeesGridColumns} from 'src/components/Profile/Employees/EmployeesGridColumns'
import ProfileEmployeeCreateModal from 'src/components/Profile/Employees/ProfileEmployeeCreateModal.vue'
import {UserRoles} from 'src/models/User/UserRoleDescriptions'
import {UserStatuses} from 'src/models/User/UserStatusDescriptions'
import ProfileEmployeeUpdateModal from 'src/components/Profile/Employees/ProfileEmployeeUpdateModal.vue'
import PaginationComponent from 'src/components/PaginationComponent.vue'
import ProfileEmployeeDeleteModal from 'src/components/Profile/Employees/ProfileEmployeeDeleteModal.vue'
import ProfileEmployeesGridFilters from 'src/components/Profile/Employees/ProfileEmployeesGridFilters.vue'
import EmployeesGridTh from 'src/components/Profile/Employees/EmployeesGridTh.vue'
import {GridPagination, RequestProp} from 'src/models/Grid/Pagination'

const filtersStore = useEmployeeGridFiltersStore()
const employeesGridStore = useEmployeesGridStore()
const {
  items,
  gridPagination,
  perPageOptions,
  loading,
} = storeToRefs(employeesGridStore)

const {
  load,
  onRequestPagination,
  setPage,
  getFullName
} = employeesGridStore

const {columns} = useGridSettings('profile-employees-grid', EmployeesGridColumns)

onBeforeMount(async () => {
  await load()
})

onBeforeUnmount(() => {
  employeesGridStore.$reset()
  employeesGridStore.$dispose()
  filtersStore.$reset()
  filtersStore.$dispose()
})

const onRequest = ({pagination}: RequestProp) => {
  onRequestPagination(pagination as GridPagination)
  load()
}

const updateFilters = () => {
  setPage(1)
  load()
}

const updateSorting = () => {
  load()
}

</script>
<style lang="scss">
.EmployeesGrid--table {
  margin: 0 -24px -24px;
  background: $blue-1;
  padding: 0 24px 24px;

  table {
    position: relative;

    tr {
      td:first-child {
        width: 50px;
        white-space: normal;
      }
    }
  }

  .createButton {
    .icon {
      background: white;
      border-radius: 6px;
      position: absolute;
      right: 10px;
      line-height: 0;
    }

    .q-btn {
      border-radius: 6px;
    }
  }
}
</style>
