<template>
  <q-select
    v-model="selected"
    class="field-with-top-label"
    :options="items"
    :loading="loading"
    option-label="name"
    option-value="id"
    label="Руководитель операторов"
    style="min-width: 200px"
    hide-dropdown-icon
    outlined
    hide-bottom-space
    use-input
    dense
    label-slot
    clearable
    @filter="onFilter"
  />
</template>

<script setup lang="ts">
import {useGetApi} from 'src/uses/Api/useGetApi'
import {onBeforeMount, ref, watch} from 'vue'
import {QSelect} from 'quasar'

interface HeadOperator {
  id: string
  name: string
}

const emit = defineEmits<{
  (e: 'update:model-value', employees: string | undefined): void,
}>()

const props = defineProps<{
  modelValue: string | undefined
  name: string
}>()

const {
  loading,
  get,
  status,
  response,
} = useGetApi<{items: HeadOperator[]}>()

const items = ref<HeadOperator[]>([])
const selected = ref<HeadOperator>()

const load = async (query = '') => {
  await get(
    'v1/admin/user/head-operators',
    {
      'filter[name]': query,
    })

  if (status.value === 200 && response.value) {
    items.value = response.value.items
  }
}

const onFilter = (
  input: string,
  update: (fn: () => void) => void
) => {
  update(async () => {
    await load(input)
  })
}

onBeforeMount(async () => {
  await load(props.name)
  selected.value = items.value
    .find(item => item.id === props.modelValue)
})

watch(
  selected,
  () => {
    emit('update:model-value', selected.value ? selected.value.id : undefined)
  },
  {
    deep: true,
  }
)
</script>
