<template>
  <q-intersection
    transition="scale"
    once
  >
    <div
      v-if="opened"
      :class="classes"
    >
      <div class="q-px-sm q-py-md blue-bg-field">
        <q-input
          v-model="search"
          class="field-with-top-label"
          label="Поиск по ИНН или наименованию"
          outlined
          hide-bottom-space
          dense
          emit-value
          map-options
          options-dense
          hide-dropdown-icon
          clearable
        >
          <template #prepend>
            <q-icon name="svguse:icons.svg?2#app-search" />
          </template>
        </q-input>
      </div>
      <q-table
        v-model:selected="selectedBeneficiaries"
        class="full-width"
        :style="`max-height: 40vh; padding-bottom: ${bottomHeight}px;`"
        :rows="filteredItems"
        :columns="columns"
        :pagination="{rowsPerPage: 0}"
        separator="horizontal"
        row-key="inn"
        selection="multiple"
        hide-pagination
        virtual-scroll
        flat
        :grid="Platform.is.mobile"
      >
        <template #header-selection="scope">
          <q-checkbox v-model="scope.selected" />
        </template>
        <template #body-selection="scope">
          <q-checkbox
            :model-value="scope.selected"
            @update:model-value="onCheckboxUpdate($event, scope)"
          />
        </template>
        <template #body-cell="propsTd">
          <q-td
            :props="propsTd"
            class="text-wrap"
          >
            {{ propsTd.value }}
          </q-td>
        </template>
        <template #body-cell-name="propsTd">
          <q-td
            :props="propsTd"
            class="text-wrap name"
          >
            {{ propsTd.value.toLowerCase() }}
          </q-td>
        </template>
        <template #body-cell-amount="propsTd">
          <q-td
            :props="propsTd"
            class="text-wrap"
          >
            <money-component :model-value="propsTd.value" />
          </q-td>
        </template>

        <!--<template v-slot:top="props">
          <div class="topChecker">
            <q-checkbox
              :model-value="props.selected"
              label="Выбрать все"
            />
          </div>
        </template>-->

        <template v-slot:item="props">
          <div class="col-lg-12">
            <div class="row BeneficiareListMobile--item">
              <q-checkbox
                :model-value="props.selected"
                @update:model-value="onCheckboxUpdate($event, props)"
              />
              <div class="col items-start itemInfo">
                <div class="q-mb-md">
                  <div class="text-caption">ИНН</div>
                  {{ props.row.inn }}
                </div>
                <div class="q-mb-md">
                  <div class="text-caption">Наименование</div>
                  {{ props.row.name.toLowerCase() }}
                </div>
                <div class="q-mb-md">
                  <div class="text-caption">Сумма обеспечения</div>
                  <money-component :model-value="props.row.lot_contract_guarantee" />
                </div>
              </div>
            </div>
          </div>
        </template>
      </q-table>
      <div
        v-if="selectedBeneficiaries.length"
        ref="bottomRef"
        class="BeneficiareList--bottom q-pa-md"
      >
        <div class="row items-center">
          <div class="q-py-md col-7 col-xs-12">
            <div class="row justify-between q-pr-sm">
              <div class="q-pr-md text-no-wrap">
                Выбрано бенефициаров: {{ selectedBeneficiaries.length }}
              </div>
              <div class="text-no-wrap">
                Сумма банковской гарантии:
                <money-component :model-value="selectedBeneficiariesSum" />
              </div>
            </div>
          </div>
          <div class="q-py-md col-5 col-xs-12">
            <div class="row actions items-center justify-end">
              <div
                v-if="!$q.platform.is.mobile"
                class="text-no-wrap q-pr-lg cursor-pointer"
                @click="selectedBeneficiaries = []"
              >
                Сбросить все
              </div>
              <div class="text-no-wrap">
                <q-btn
                  label="Сохранить"
                  class="saveBtn"
                  color="blue-7"
                  padding="10px lg"
                  unelevated
                  no-caps
                  @click="save"
                />
              </div>
              <div
                v-if="$q.platform.is.mobile"
                class="text-no-wrap q-pr-lg cursor-pointer cancelBtn"
                @click="selectedBeneficiaries = []"
              >
                Сбросить все
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-else
      class="BeneficiareListClosed q-py-md q-px-md q-mb-lg"
    >
      <div class="row items-center justify-between">
        <div class="text-no-wrap count">
          Выбрано бенефициаров: {{ selectedBeneficiaries.length }}
        </div>
        <div>
          <q-btn
            label="Показать"
            color="primary"
            icon-right="svguse:icons.svg?2#app-sort-descending"
            style="height: 50px;"
            padding="0 30px"
            unelevated
            no-caps
            @click="opened = true"
          />
        </div>
      </div>
    </div>
  </q-intersection>
</template>

<script setup lang="ts">
import {computed, ref, watch} from 'vue'
import {useLayoutStore} from 'src/stores/useLayoutStore'
import {storeToRefs} from 'pinia'
import MoneyComponent from 'src/components/MoneyComponent.vue'
import {useApplicationsNew} from 'src/stores/Applications/ApplicationsNew/useApplicationsNew'
import {useSearch} from 'src/uses/useSearch'
import {Platform} from 'quasar'
import {QTableColumn} from 'src/models/Grid/GridColumnSettings'

const layoutStore = useLayoutStore()
const {
  currentContentWidth,
} = storeToRefs(layoutStore)

const applicationsNewStore = useApplicationsNew()
const {
  beneficiaries: items,
  selectedBeneficiaries,
  selectedBeneficiariesSum,
  bgSum,
} = storeToRefs(applicationsNewStore)

const {filter} = useSearch()

const opened = ref(true)
const bottomRef = ref<null | Element>(null)
const bottomHeight = ref(0)
const columns = ref<QTableColumn[]>([
  {
    name: 'inn',
    field: 'inn',
    required: true,
    label: 'ИНН',
    align: 'left',
    classes: 'text-no-wrap',
  },
  {
    name: 'name',
    field: 'name',
    required: true,
    label: 'Наименование',
    align: 'left',
    classes: 'text-no-wrap',
  },
  {
    name: 'amount',
    field: 'lot_contract_guarantee',
    required: true,
    label: 'Сумма обеспечения',
    align: 'left',
    classes: 'text-no-wrap',
  },
])

const search = ref<string>('')
const classes = computed(() => {
  return {
    BeneficiareList: !Platform.is.mobile,
    BeneficiareListMobile: Platform.is.mobile,
    'q-mb-lg': true,
  }
})

const filteredItems = computed(() => {
  return items.value
    .filter(i => filter(
      search.value,
      [
        i.inn.toString(),
        i.name,
      ])
    )
})

const refreshBottomHeight = () => {
  bottomHeight.value = bottomRef.value ? bottomRef.value.clientHeight : 0
  setTimeout(() => {
    bottomHeight.value = bottomRef.value ? bottomRef.value.clientHeight : 0
  }, 500)
}

const save = () => {
  bgSum.value = selectedBeneficiariesSum.value
    ? selectedBeneficiariesSum.value
    : bgSum.value
  opened.value = false
}

const onCheckboxUpdate = (value: unknown, scope: {selected?: boolean}) => {
  const propertyDescriptor = Object.getOwnPropertyDescriptor(scope, 'selected')
  if (!propertyDescriptor || !propertyDescriptor.set) {
    return
  }

  propertyDescriptor.set(value)
}

watch(bottomRef, refreshBottomHeight)
watch(currentContentWidth, refreshBottomHeight)
</script>

<style lang="scss">
body {
  .BeneficiareList {
    background-color: #FFFFFF;
    border: 1px solid $green-14;
    border-radius: 6px;
    position: relative;
    /*.BeneficiareList--bottom {
      .actions {
        flex-direction: row-reverse;
      }
    }*/

    .q-checkbox__inner--falsy .q-checkbox__bg {
      background: #eceff1 !important;
      border-color: #cfd8dc!important;
    }
    thead {
      tr:first-child th {
        background: $grey-3 !important;
        top: 3px;
        position: sticky;
        z-index: 1;

        &:before {
          content: '';
          width: 100%;
          height: 4px;
          margin-top: -4px;
          background: #ffffff;
          position: absolute;
          z-index: 1;
          top: 0;
          left: 0;
        }
      }
    }

    .name {
      text-transform: capitalize;
    }

    .q-field {
      .q-field__label {
        top: -32px !important;
        left: -49px !important;
      }
    }

    .q-table__bottom {
      display: none;
    }

    .BeneficiareList--bottom {
      position: absolute;
      background: $blue-grey-10;
      color: white;
      margin: 0 -1px -1px -1px;
      border-radius: 0 0 6px 6px;
      bottom: 0;
      width: calc(100% + 2px);
    }
  }

  .BeneficiareListClosed {
    border: 1px solid $blue-grey-1;
    border-radius: 6px;
    color: $grey-9;
    background: white;
    font-size: 14px;

    .count {
      -webkit-text-stroke: 0.4px;
    }
  }

  .BeneficiareListMobile {
    border: 1px solid #EEEEEE;
    border-radius: 6px;
    margin-bottom: 15px;
    .BeneficiareList--bottom {
      background-color: #263238;
      color: #FFFFFF;
      padding: 0 20px;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      .actions {
        flex-direction: column;
        align-items: normal;
        .saveBtn {
          width: 100%;
          margin-bottom: 10px;
        }
        .cancelBtn {
          padding: 10px 20px;
          width: 100%;
          text-align: center;
        }
      }
    }
    .q-table__container {
      max-height: none!important;
      padding-bottom: 0!important;
    }
    .q-table__bottom{
      display: none;
    }
    .q-table__top {
      padding: 0!important;
      background-color: #EEEEEE;
    }
    .BeneficiareListMobile--item {
      border-bottom: 1px solid #EEEEEE;
      display: flex;
      align-items: flex-start;
      padding-top: 5px;
      .itemInfo {
        padding-top: 10px;
        padding-left: 10px;
        font-size: 16px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0;
        text-align: left;
        color: #424242;
        .text-caption {
          font-size: 14px;
          font-weight: 400;
          line-height: 14px;
          letter-spacing: 0;
          text-align: left;
          color: #9E9E9E;
          margin-bottom: 3px;
        }
      }
    }
  }
}
</style>
