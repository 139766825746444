import {defineStore} from 'pinia'
import {computed, Ref, ref} from 'vue'
import {email, required} from 'src/utils/i18n-validators'
import useVuelidate from '@vuelidate/core'
import {Employee} from 'src/models/Profile/Employees/Employee'
import {UserRoles} from 'src/models/User/UserRoleDescriptions'
import {UserRoleEnum} from 'src/models/User/UserRoleEnum'
import {usePutApi} from 'src/uses/Api/usePutApi'
import {useGetEmployeeItemStore} from 'src/stores/Profile/Employees/useGetEmployeeItemStore'
import {useValidationRules} from 'src/uses/useValidationRules'

export const useEmployeeUpdateStore = (id: string) => defineStore(`employee-update-${id}`, () => {
  const getEmployeeItemStore = useGetEmployeeItemStore()
  const {
    load,
    getEmployeeRequestData
  } = getEmployeeItemStore
  const {
    put,
    response,
    status,
    loading,
    error,
    errors,
  } = usePutApi<Employee, Employee>()

  const {
    getRulesFromState,
  } = useValidationRules()

  const employeeUpdateData = ref<Employee>({
    name: '',
    email: '',
    role: '',
    phone: '',
    password: '',
    password_confirm: '',
  })

  const successEmployeeUpdated = ref(false)

  const rolesForSelect = ref([
    {
      value: UserRoleEnum.Employee,
      label: UserRoles.getUserRoleDescription(UserRoleEnum.Employee)
    },
    {
      value: UserRoleEnum.HeadEmployee,
      label: UserRoles.getUserRoleDescription(UserRoleEnum.HeadEmployee)
    }
  ])

  const rules = computed(() => {
    return {
      ...getRulesFromState(employeeUpdateData.value),
      name: {
        required,
      },
      email: {
        required,
        email,
      },
      phone: {
        required,
      },
      role: {
        required,
      },
    }
  })

  const validation = useVuelidate<Ref<Employee>>(rules, employeeUpdateData)

  const update = async (id: string) => {
    if (!await validation.value.$validate()) {
      return
    }

    await put(`v1/employees/${id}`, employeeUpdateData.value)
    if (status.value === 200 && response.value) {
      successEmployeeUpdated.value = true
    }
  }

  const loadEmployeeItem = async (id: string) => {
    await load(id)
    employeeUpdateData.value = {
      password: '',
      password_confirm: '',
      ...getEmployeeRequestData()
    }
  }

  return {
    employeeUpdateData,
    loading,
    response,
    status,
    error,
    errors,
    validation,
    successEmployeeUpdated,
    rolesForSelect,
    update,
    loadEmployeeItem
  }
})
