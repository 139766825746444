<template>
  <div
    class="ApplicationItemSidebar--content--banks q-pa-sm"
  >
    <div class="banks-title q-pb-xs">
      Банки
    </div>
    <application-item-sidebar-bank
      v-for="bank in draftFilledBanks as ApplicationFormBank[]"
      :key="bank.bank_id"
      :bank="bank"
      :filled="getByBank(bank.bank_id).filled"
      :total="getByBank(bank.bank_id).total"
      :stop-factors="[]"
    />
    <slot name="button" />
    <application-item-sidebar-bank
      v-for="bank in otherBanks as ApplicationFormBank[]"
      :key="bank.bank_id"
      :bank="bank"
      :filled="getByBank(bank.bank_id).filled"
      :total="getByBank(bank.bank_id).total"
      :stop-factors="getStopFactorsResultsById(bank.id)"
    />
  </div>
</template>

<script setup lang="ts">
import {computed} from 'vue'
import {storeToRefs} from 'pinia'
import {useApplicationItemStore} from 'src/stores/Applications/Item/useApplicationItemStore'
import {ApplicationFormBank} from 'src/models/Applications/Form/ApplicationFormResponseData'
import ApplicationItemSidebarBank
  from 'src/components/Applications/ApplicationItem/Sidebar/ApplicationItemSidebarBank.vue'
import {ApplicationSubStatusEnum} from 'src/models/Applications/ApplicationSubStatusEnum'
import {useApplicationFormStopFactors} from 'src/uses/Applications/Form/useApplicationFormStopFactors'
import {useApplicationItemEbgFillStore} from 'stores/Applications/Item/useApplicationItemEbgFillStore'
import {ProductEnum} from 'src/models/Applications/ProductEnum'
import {useApplicationItemKikStore} from 'stores/Applications/Item/useApplicationItemKikStore'
import {useApplicationItemEbgStore} from 'stores/Applications/Item/useApplicationItemEbgStore'
import {useApplicationItemKikFillStore} from 'stores/Applications/Item/useApplicationItemKikFillStore'

const props = defineProps<{
  multiOrderId: number,
  type: ProductEnum,
}>()

const itemProductStore = useApplicationItemStore(props.multiOrderId)()
const itemStore = props.type === ProductEnum.EBG
  ? useApplicationItemEbgStore(props.multiOrderId)()
  : useApplicationItemKikStore(props.multiOrderId)()

const {
  banks,
} = storeToRefs(itemStore)

const {
  item,
} = storeToRefs(itemProductStore)

const fillStore = props.type === ProductEnum.EBG
  ? useApplicationItemEbgFillStore(props.multiOrderId)()
  : useApplicationItemKikFillStore(props.multiOrderId)()

const {
  fillByBanks,
} = storeToRefs(fillStore)

const {
  getStopFactorsResultsById,
} = useApplicationFormStopFactors(item, props.type)

const draftFilledBanks = computed(() => {
  return banks.value
    .filter(bank => bank.sub_status === ApplicationSubStatusEnum.draft)
    .filter(bank => {
      const byBank = getByBank(bank.bank_id)

      return byBank.filled === byBank.total
    })
})

const draftFilledBanksIds = computed(() => {
  return draftFilledBanks.value
    .map(bank => bank.id)
})

const otherBanks = computed(() => {
  return banks.value
    .filter(bank => !draftFilledBanksIds.value.includes(bank.id))
})

const getByBank = (bankId: string) => {
  const bank = fillByBanks.value
    .find(b => b.bank_id === bankId)

  if (!bank) {
    return {
      total: 0,
      filled: 0,
    }
  }

  const total = bank ? bank.total : 0
  const error = bank ? bank.error : 0
  const filled = total - error

  return {
    total,
    filled,
  }
}
</script>

<style lang="scss">
.ApplicationItemSidebar--content--banks {
  border-top: 1px solid $blue-grey-8;

  .banks-title {
    color: $blue-grey-4;
    font-size: 12px;
  }
}
</style>
