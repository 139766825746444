import { Filesystem, Directory } from '@capacitor/filesystem'
import { Share } from '@capacitor/share'

export const useNativeFile = () => {
  const getFilesystemAccess = async (): Promise<boolean> => {
    const status = await Filesystem.checkPermissions()
    const state = status.publicStorage

    if (state === 'granted') {
      return true
    } else if (state === 'denied') {
      return false
    } else {
      await Filesystem.requestPermissions()
    }

    return false
  }

  const inMemoryShareFile = async (file: Blob, fileName: string) => {
    const canDownload = await getFilesystemAccess()

    if (!canDownload) {
      return
    }

    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onloadend = async () => {
      const base64data = reader.result

      const fileWriteResult = await Filesystem.writeFile({
        path: fileName,
        data: base64data as string,
        directory: Directory.Cache
      })

      const canShare = await Share.canShare()

      if (!canShare.value) {
        return
      }

      await Share.share({
        title: fileName,
        url: fileWriteResult.uri,
      })
    }
  }

  return {
    inMemoryShareFile
  }
}
