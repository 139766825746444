import {defineStore} from 'pinia'
import {useGetApi} from 'src/uses/Api/useGetApi'
import {ref} from 'vue'
import {Act} from 'src/models/Profile/Acts/Act'
import {ActChat, ActChatMessage} from 'src/models/Profile/Acts/ActChat'
import moment from 'moment'

export const useProfileActMessagesStore = defineStore('profile-act-messages', () => {
  const AGENT_PROFILE_ACTS_MESSAGES_LIST_URL = 'v1/acts'

  const {
    loading,
    get,
    errors,
    error,
    status,
    response,
  } = useGetApi<ActChat[]>()

  const chat = ref<ActChat[]>()

  const load = async (act:Act) => {
    await get(`${AGENT_PROFILE_ACTS_MESSAGES_LIST_URL}/${act.id}/messages`)

    if (status.value === 200 && response.value) {
      chat.value = response.value
    }
  }

  const getMessagesByDay = (chat: ActChat) => {
    return chat.messages
  }

  const getMessageTime = (mess: ActChatMessage) => {
    return moment(mess.date).format('HH:mm')
  }

  const getFormatDate = (day: string) => {
    const monthList = [
      'января',
      'февраля',
      'марта',
      'апреля',
      'мая',
      'июня',
      'июля',
      'августа',
      'сентбря',
      'октября',
      'ноября',
      'декабря',
    ]
    const chatDay = moment(day).get('date')
    const month = moment(day).month()
    return `${chatDay} ${monthList[month]}`
  }

  return {
    loading,
    get,
    errors,
    error,
    chat,
    load,
    getMessagesByDay,
    getMessageTime,
    getFormatDate,
  }
})
