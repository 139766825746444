<template>
  <info-banner
    class="ReportsMobileGrid__info-banner"
    text="Для формирования отчета, ведите необходимые данные и нажмите кнопку “Сформировать отчет”"
    icon="svguse:icons.svg?2#app-exclamation-circle"
  />
  <reports-grid-filters-dialog
    v-model="filerDialog"
    :loading="loading"
    @update:filters="updateFilters"
  />
  <div class="q-px-none q-mt-md q-mx-md">
    <div class="row">
      <q-btn
        label="Все фильтры"
        color="grey-2"
        class="text-blue-grey-4 col"
        icon-right="svguse:icons.svg?2#app-adjustments"
        unelevated
        no-caps
        @click="filterDialogShow"
      />
      <q-btn
        v-if="clearable"
        color="grey-2"
        class="text-blue-grey-4 q-ml-xs"
        icon-right="svguse:icons.svg?2#app-trash"
        size="14px"
        padding="5px 7px"
        unelevated
        square
        no-caps
        @click="onClear"
      />
    </div>
  </div>
  <div class="row">
    <div class="col-12 q-pa-md">
      <q-btn
        color="primary"
        icon-right="svguse:icons.svg?2#app-document-text"
        label="Сформировать отчет"
        class="full-width q-ml-md"
        style="padding: 13px 24px;"
        unelevated
        no-caps
        @click="generate"
      />
    </div>
  </div>
  <div class="ReportsMobileGrid--table">
    <div class="text-h6">История</div>
    <q-infinite-scroll
      ref="scroll"
      style="margin-bottom: 12px"
      @load="onLoad"
    >
      <template
        v-if="!loading && items.length === 0"
      >
        <template v-if="filtersStore.isDefaultFilterValue">
          Вы пока не сформировали отчеты. Нажмите кнопку "Сформировать отчет", чтобы получить выгрузку по вашим заявкам
        </template>
        <template v-else>
          По вашему запросу ничего не найдено. Попробуйте изменить фильтры
        </template>
      </template>
      <div
        v-for="item in items as ReportsGridItem[]"
        :key="item.id"
      >
        <div>
          <div class="EmployeesMobileGrid--mobile--item q-px-md q-pt-md q-pb-sm rounded-borders bg-white q-mt-sm">
            <div class="q-mb-sm">
              <div class="item-title">Тип документа: </div>
              Отчет по продукту {{ ReportTypeDescriptions.getReportTypeDescription(item.filter.product_type) }}
            </div>
            <div class="q-mb-sm">
              <div class="item-title">Статус: </div>
              <span :class="statusClass(item.status)">
                {{ ReportStatusDescriptions.getReportStatusDescription(item.status) }}
              </span>
            </div>
            <div class="q-mb-sm">
              <div class="item-title">Дата генерации: </div>
              {{ item.created || '-------- ----' }}
            </div>
            <div class="q-mb-sm">
              <q-btn
                class="downloadBtn text-center q-mt-sm"
                size="md"
                label="Скачать"
                icon-right="svguse:icons.svg?2#app-download"
                unelevated
                no-caps
                @click="download(`v1/reports/${item.id}/download`, false)"
              />
            </div>
            <div>
              <report-filters-info-modal
                v-if="item"
                :item="item"
              />
            </div>
          </div>
        </div>
      </div>
    </q-infinite-scroll>
  </div>
</template>

<script setup lang="ts">
import {storeToRefs} from 'pinia'
import {onBeforeUnmount, ref, watch} from 'vue'
import ReportsGridFiltersDialog from 'components/Profile/Reports/ReportsGridFiltersDialog.vue'
import InfoBanner from 'components/InfoBanner.vue'
import {useReportsGridFiltersStore} from 'stores/Profile/Reports/useReportsGridFiltersStore'
import {useReportsGridStore} from 'stores/Profile/Reports/useReportsGridStore'
import {ReportsGridItem} from 'src/models/Profile/Reports/ReportsGridItem'
import {ReportStatusDescriptions} from 'src/models/Profile/Reports/ReportStatusDescriptions'
import {useFileDownload} from 'src/uses/File/useFileDownload'
import {ReportTypeDescriptions} from 'src/models/Profile/Reports/ReportTypeDescriptions'
import ReportFiltersInfoModal from 'components/Profile/Reports/ReportFiltersInfoModal.vue'
import {QInfiniteScroll} from 'quasar'

const filtersStore = useReportsGridFiltersStore()
const reportsGridStore = useReportsGridStore()
const {
  items,
  loading,
  filerDialog,
  clearable,
  noMorePages,
} = storeToRefs(reportsGridStore)

const {
  load,
  setPage,
  filterDialogShow,
  onClear,
  setNextPage,
  statusClass,
  generate,
} = reportsGridStore

const scroll = ref<QInfiniteScroll>()

onBeforeUnmount(() => {
  reportsGridStore.$reset()
  reportsGridStore.$dispose()
  filtersStore.$reset()
  filtersStore.$dispose()
})

const updateFilters = () => {
  setPage(1)
  load()
}

const {
  download
} = useFileDownload()

const onLoad = async (index: number, done: (stop?: boolean) => void) => {
  if (loading.value) {
    return
  }
  setNextPage()
  await load()
  done()
}

watch(
  noMorePages,
  () => {
    if (!scroll.value) {
      return
    }

    if (noMorePages.value) {
      scroll.value.stop()

      return
    }

    scroll.value.resume()
  }
)
</script>
<style lang="scss">
.ReportsMobileGrid--table {
  margin: 0 -24px -24px;
  background: $blue-1;
  padding: 16px 40px;
  .item-title {
    color: $blue-grey-4;
    font-size: 12px;
  }
  .downloadBtn {
    width: 100%;
    background-color: #EEEEEE;
    color: #1E88E5;
    font-weight: bold;
    padding: 10px 17px;
  }

  .createButton {
    text-align: center;
    .icon {
      background: white;
      border-radius: 6px;
      line-height: 0;
      margin-left: 10px;
    }

    .q-btn {
      border-radius: 6px;
    }
  }
}
.ReportsMobileGrid__info-banner {
  margin-top: -24px;
  margin-bottom: 10px;
}
</style>
