<template>
  <div
    v-if="guarantor"
    class="SuretyGuarantor q-pa-md g-my-sm"
  >
    <div class="row q-col-gutter-md items-center">
      <div class="col-sm col-xs-12">
        <q-toggle
          v-model="guarantor.is_selected"
          :disable="props.disable"
          :indeterminate-value="null"
        >
          <strong>{{ guarantor.fio }}</strong><br>
          Доля в капитале:
          <strong class="text-primary">{{ guarantor.share_percent }}%</strong>
        </q-toggle>
      </div>
      <div class="col-sm col-xs-12">
        <q-input
          v-model="guarantor.phone"
          class="field-with-top-label"
          label="Контактный телефон"
          mask="+# (###) ###-##-##"
          :disable="props.disable"
          :error="guarantor.is_selected && !guarantor.phone"
          unmasked-value
          label-slot
          outlined
          hide-bottom-space
          dense
        />
      </div>
    </div>
    <div
      v-if="guarantor.documents.length === 2"
      class="q-mt-md q-pa-md bg-white rounded-borders"
    >
      <div class="row q-col-gutter-md items-center">
        <div class="col SuretyGuarantor--documents">
          <div
            v-if="agreement"
            class="row items-center q-py-sm"
          >
            <div class="col">
              <strong>Заявление оферта</strong>
              <div class="text-grey-6">Добавлен {{ agreement.date }}</div>
            </div>
            <div>
              <span
                v-if="agreement.status === ApplicationFormDocumentStatusEnum.signed"
                class="q-ma-sm text-green-14"
              >Подписан</span>
              <download-button
                :signed="agreement.status === ApplicationFormDocumentStatusEnum.signed"
                @document:download="download(agreement)"
                @document:download-sign="downloadSign(agreement)"
              />
            </div>
          </div>
          <div
            v-if="anket"
            class="row items-center q-py-sm"
          >
            <div class="col">
              <strong>Анкета поручителя</strong>
              <div class="text-grey-6">Добавлен {{ anket.date }}</div>
            </div>
            <div>
              <span
                v-if="anket.status === ApplicationFormDocumentStatusEnum.signed"
                class="q-ma-sm text-green-14"
              >Подписан</span>
              <download-button
                :signed="anket.status === ApplicationFormDocumentStatusEnum.signed"
                @document:download="download(anket)"
                @document:download-sign="downloadSign(anket)"
              />
            </div>
          </div>
        </div>
        <div>
          <q-btn
            size="16px"
            label="ссылка для учредителя"
            icon="svguse:icons.svg?2#app-document-duplicate"
            color="grey-2"
            class="text-blue-grey-4"
            unelevated
            no-caps
            @click="copyToClipboard(guarantor.sign_url || '')"
          />
        </div>
      </div>
    </div>
    <orange-info
      v-else
      class="q-mt-md"
      text="Анкета Поручителя и Оферта появится здесь через несколько минут вместе со ссылкой для подписания"
      small
    />
  </div>
</template>

<script setup lang="ts">
import {SuretyDocument, SuretyGuarantor} from 'src/models/Applications/SuretyResponse'
import {ref, watchEffect, computed} from 'vue'
import {copyToClipboard} from 'quasar'
import {
  ApplicationFormDocument,
  ApplicationFormDocumentStatusEnum
} from 'src/models/Applications/Form/ApplicationFormResponseData'
import DownloadButton from 'src/components/DownloadButton.vue'
import {useApplicationFormDownloadDocument} from 'src/uses/Applications/Form/Documents/useApplicationFormDownloadDocument'
import OrangeInfo from 'src/components/OrangeInfo.vue'
import {
  useCreateEmptyApplicationFormDocument
} from 'src/uses/Applications/Form/Documents/useCreateEmptyApplicationFormDocument'

const props = defineProps<{
  modelValue: SuretyGuarantor,
  disable: boolean,
  appId: string,
}>()

const emit = defineEmits<{
  (e: 'update:model-value', value: SuretyGuarantor): void,
}>()

const {create} = useCreateEmptyApplicationFormDocument()

const {
  download: realDownload,
} = useApplicationFormDownloadDocument(undefined, props.appId)

const guarantor = ref<SuretyGuarantor>()

const agreement = computed(() => props.modelValue.documents.find(d => d.code === 'OFFER_FOR_CONCLUSION_GUARANTEE_AGREEMENT'))
const anket = computed(() => props.modelValue.documents.find(d => d.code === 'GUARANTOR_QUESTIONNAIRE'))

const convertDocumentToApplicationDocument = (document: SuretyDocument): ApplicationFormDocument => {
  const newDocument = create(document.type)
  newDocument.code = document.code
  newDocument.file_status = ApplicationFormDocumentStatusEnum.uploaded
  newDocument.founder_id = document.founder_id
  newDocument.title = document.title

  return newDocument
  /*return {
    location: {
      block: ApplicationFormUpdateBlockEnum.documents,
      section_type: document.type,
    },
    invisible: false,
    valid:  [],
    code: document.code,
    description: null,
    file_date: null,
    file_name: null,
    file_path: null,
    file_status: ApplicationFormDocumentStatusEnum.uploaded,
    founder_id: document.founder_id,
    founder_passport_number: null,
    founder_passport_series: null,
    index: null,
    period_quarter: null,
    period_year: null,
    sign_file_name: null,
    sign_file_path: null,
    title: document.title,
    highlighted: false,
    readonly: false,
    sign_file_required: false,
  }*/
}

const download = (document: SuretyDocument) => {
  realDownload(
    convertDocumentToApplicationDocument(document),
    document.type
  )
}

const downloadSign = (document: SuretyDocument) => {
  realDownload(
    convertDocumentToApplicationDocument(document),
    document.type,
    true
  )
}

watchEffect(() => {
  guarantor.value = props.modelValue
})

watchEffect(() => {
  if (!guarantor.value) {
    return
  }

  emit('update:model-value', guarantor.value)
})
</script>

<style scoped lang="scss">
.SuretyGuarantor {
  border: 1px solid #C7DFF1;
  border-radius: 10px;

  .SuretyGuarantor--documents {
    & > div:first-child {
      border-bottom: 1px solid $grey-4;
    }
  }
}
</style>
