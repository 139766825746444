<template>
  <notifications-bread-crumbs v-if="!Platform.is.mobile" />
  <div :class="classes">
    <div class="title">Настройки уведомлений</div>
    <div class="q-mt-md">
      <div class="NotificationsGridProductToggle">
        <q-tabs
          v-model="productType"
          class="q-px-lg"
          align="left"
          indicator-color="blue-7"
          inline-label
          no-caps
        >
          <q-tab
            :name="ProductEnum.EBG"
            :ripple="false"
          >
            {{ getProductLabel(ProductEnum.EBG) }}
          </q-tab>
          <q-tab
            :name="ProductEnum.KIK"
            :ripple="false"
          >
            {{ getProductLabel(ProductEnum.KIK) }}
          </q-tab>
        </q-tabs>
      </div>
    </div>
    <q-table
      v-model:pagination="gridPagination"
      hide-pagination
      :rows="subscriptions"
      :columns="columns"
      :loading="loading"
      :class="{'q-mx-md': $q.platform.is.mobile, 'isMobile': $q.platform.is.mobile}"
      separator="cell"
      row-key="name"
      wrap-cells
      flat
    >
      <template #body-cell-title="props">
        <q-td :props="props">
          <div style="white-space: pre-wrap;">{{ props.row.title }}</div>
        </q-td>
      </template>
      <template #body-cell-email="props">
        <q-td
          :props="props"
          style="width: 50px;"
        >
          <profile-notifications-toggle
            :key="props.row.code + props.row.product_type"
            :model-value="props.row"
            :channel="ChannelsEnum.email"
            @update:modal-value="value => items[getItemIndex(props.row.code, props.row.product_type)] = value"
          />
        </q-td>
      </template>
      <template #body-cell-push="props">
        <q-td
          :props="props"
          style="width: 50px;"
        >
          <profile-notifications-toggle
            :key="props.row.code + props.row.product_type"
            :model-value="props.row"
            :channel="ChannelsEnum.push"
            @update:modal-value="value => items[getItemIndex(props.row.code, props.row.product_type)] = value"
          />
        </q-td>
      </template>
      <template
        #bottom
        v-if="$q.platform.is.mobile"
      >
        <div class="row q-mt-md full-width">
          <q-btn
            :loading="loading"
            class="col-12"
            style="height: 62px;"
            label="Сохранить изменения"
            color="primary"
            icon-right="svguse:icons.svg?2#app-check"
            no-caps
            @click="saveNotifications"
          />
          <div class="col-12 q-mt-sm">
            <span
              v-if="success"
              class="text-green-7"
            >
              Данные успешно сохранены
            </span>
          </div>
        </div>
      </template>
    </q-table>
    <div
      v-if="!$q.platform.is.mobile"
      class="row q-mt-md items-center"
    >
      <div class="col text-right">
        <span
          v-if="success"
          class="text-green-7 q-mr-md"
        >
          Данные успешно сохранены
        </span>
      </div>
      <div class="mobileBtn">
        <q-btn
          :loading="loading"
          label="Сохранить изменения"
          style="padding: 13px 23px"
          color="primary"
          icon-right="svguse:icons.svg?2#app-check"
          no-caps
          @click="saveNotifications"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {storeToRefs} from 'pinia'
import {computed, onBeforeMount, onBeforeUnmount} from 'vue'
import {useGridSettings} from 'src/uses/Grid/useGridSettings'
import {useProfileNotificationsGridStore} from 'src/stores/Profile/Notifications/useProfileNotificationsGridStore'
import {ProfileNotificationsColumns} from 'src/components/Profile/Notifications/ProfileNotificationsColumns'
import {useProfileNotificationsUpdateStore} from 'src/stores/Profile/Notifications/useProfileNotificationsUpdateStore'
import NotificationsBreadCrumbs from 'src/components/Profile/Notifications/NotificationsBreadCrumbs.vue'
import {ChannelsEnum} from 'src/models/Profile/Notifications/Subscription'
import {Platform, QTabs, useQuasar} from 'quasar'
import ProfileNotificationsToggle from 'components/Profile/Notifications/ProfileNotificationsToggle.vue'
import {ProductEnum} from 'src/models/Applications/ProductEnum'
import {useProduct} from 'src/uses/Applications/useProduct'

const profileNotificationsGridStore = useProfileNotificationsGridStore()
const {
  subscriptions,
  items,
  loading,
  productType,
  gridPagination
} = storeToRefs(profileNotificationsGridStore)

const {
  load,
  getItemIndex,
} = profileNotificationsGridStore

const $q = useQuasar()

const profileNotificationsUpdateStore = useProfileNotificationsUpdateStore()
const {success} = storeToRefs(profileNotificationsUpdateStore)
const {save} = profileNotificationsUpdateStore

const {columns} = useGridSettings('profile-notifications-grid', ProfileNotificationsColumns)
const classes = computed(() => {
  return {
    'ProfileNotificationsGrid--table': true,
    ProfileNotificationsGrid__mobile: Platform.is.mobile
  }
})

onBeforeMount(async () => {
  await load()
})

const {getProductLabel} = useProduct()

onBeforeUnmount(() => {
  profileNotificationsGridStore.$reset()
  profileNotificationsGridStore.$dispose()
})

const saveNotifications = async () => {
  await save(items.value)
}

</script>
<style lang="scss">
.ProfileNotificationsGrid--table {
  margin: 0 -24px -24px;
  background: $blue-1;
  padding: 30px 24px 25px 24px;

  .q-table__container.isMobile {
    .q-table__bottom {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }

  .q-table__card .q-table {
    border-spacing: 0;
  }

  .q-table__card .q-table tr td:first-child, .q-table__card .q-table tr td:last-child {
    border-radius: 0;
  }

  .q-table thead tr {
    background-color: #FAFAFA;
  }

  .q-table td, .q-table th {
    border-left: none !important;
  }

  .title {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
    color: #424242;
    margin-bottom: 25px;
  }

  table {
    position: relative;
  }

  &.ProfileNotificationsGrid__mobile {
    margin-top: -24px !important;
    padding-top: 15px;
    .title {
      padding-left: 16px;
      line-height: 16px;
      font-size: 16px;
      margin-bottom: 13px;
    }
    .mobileBtn {
      width: 100%;
      padding: 0 13px 13px 13px;
      .q-btn {
        padding: 13px;
        width: 100%;
      }
    }
  }

  .NotificationsGridProductToggle {
    padding: 10px 0 0 0;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    //border-bottom: 1px solid $blue-grey-2;
    //box-shadow: 0 0 0 2px $blue-grey-2 inset;
    margin: 0 -24px 30px -24px;
    position: relative;

    &:before {
      content: '';
      display: block;
      background: $blue-grey-2;
      height: 1px;
      width: 100%;
      bottom: 0;
      position: absolute;
    }

    .q-btn-group {
      .q-btn {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }
}
</style>
