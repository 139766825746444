import {ref, watch} from 'vue'
import {useLoading} from '../useLoading'
import axios, {AxiosError, AxiosInstance, AxiosRequestHeaders, AxiosResponseHeaders, Method, ResponseType,} from 'axios'
import {useAuthStore} from 'src/stores/Login/useAuthStore'
import {useRefreshTokenStore} from 'src/stores/Login/useRefreshTokenStore'
import {BackendError, BackendErrorTypeEnum} from 'src/models/BackendError'
import {useNotifyErrorsStore} from 'src/stores/useNotifyErrorsStore'
import {EnvCodeEnum, useEnvStore} from 'src/stores/useEnvStore'
import {storeToRefs} from 'pinia'
import {useImpersonateStore} from 'src/stores/useImpersonateStore'
import {useSentryStore} from 'stores/Sentry/useSentryStore'

export const useRequestApi = <T = never>(showErrors = false, responseTypeString: ResponseType = 'json') => {
  const {
    getEnvValue,
  } = useEnvStore()
  const {
    env,
  } = storeToRefs(useEnvStore())
  const {
    getTransparent,
    getBaggage,
  } = useSentryStore()

  const impersonateStore = useImpersonateStore()

  const authStore = useAuthStore()
  const refreshTokenStore = useRefreshTokenStore()
  const {loading, startLoading, stopLoading} = useLoading()
  const response = ref<T>()
  const error = ref<string>()
  const errors = ref<Record<string, string>>()
  const status = ref<number>()
  const progressEvent = ref<ProgressEvent>()
  const headers = ref<AxiosResponseHeaders>()
  const additionalRequestHeaders = ref<AxiosRequestHeaders>({})

  const controller = ref<AbortController>()

  const abort = () => {
    if (!controller.value) {
      return
    }
    controller.value.abort()
    clear()
    loading.value = false
  }

  const createInstance = () => {
    abort()

    return axios.create({
      baseURL: `${getEnvValue(EnvCodeEnum.VUE_APP_API_URL)}/api/`,
      headers: {
        Accept: 'application/json',
        baggage: getBaggage() || '',
        'sentry-trace': getTransparent() || '',
        ...impersonateStore.impersonateEmail ? {'X-Switch-User': impersonateStore.impersonateEmail} : {},
      }
    })
  }

  const axiosInstance = ref<AxiosInstance>(createInstance())

  const useNotifyErrors = useNotifyErrorsStore()
  const {
    addErrorMessage
  } = useNotifyErrors

  const clear = () => {
    status.value = undefined
    response.value = undefined
    error.value = undefined
    errors.value = undefined
    progressEvent.value = undefined
    headers.value = undefined
  }

  const onUploadProgress = (event: ProgressEvent) => {
    progressEvent.value = event
  }

  const setHeader = (key: string, value: string) => {
    additionalRequestHeaders.value[key] = value
  }

  const request = async <R = never>(url: string, method: Method, data?: R) => {
    abort()
    controller.value = new AbortController()
    clear()
    startLoading()
    const requestHeaders: AxiosRequestHeaders = {}
    Object.entries(additionalRequestHeaders.value)
      .forEach(([key, value]) => {
        requestHeaders[key] = value
      })
    if (authStore.token && Object.values(requestHeaders).length === 0) {
      requestHeaders.authorization = `Bearer ${authStore.token}`
    }
    try {
      const {
        data: responseData,
        status: responseStatus,
        headers: responseHeaders
      } = await axiosInstance.value.request<T>({
        url,
        method,
        data,
        headers: requestHeaders,
        responseType: responseTypeString,
        signal: controller.value.signal,
        onUploadProgress,
      })
      headers.value = responseHeaders
      response.value = responseData
      status.value = responseStatus
      if (responseStatus === 401 && authStore.token) {
        await process401(url)

        return
      }
      stopLoading()
    } catch (e) {
      await processError(e as unknown as AxiosError)
    }
  }

  const process401 = async (requestUrl: string) => {
    if (requestUrl.includes('logout')) {
      return
    }
    const url = document.location.href
    await refreshTokenStore.doRefreshToken()
    document.location.href = url
  }

  const processError = async (e: Error | AxiosError) => {
    console.log(e)
    if (axios.isAxiosError(e)) {
      if (e.code === 'ERR_CANCELED') {
        return
      }

      if (e.response) {
        status.value = e.response.status
      }

      if (e.response?.status === 401 && authStore.token) {
        await process401(e.request.responseURL)

        return
      }

      let data = e.response?.data as BackendError
      if (e.config.responseType === 'blob') {
        data = JSON.parse(await (data as never as Blob).text()) as BackendError
      }

      if (data?.error_type === undefined) {
        error.value = data?.message || 'no error message'
      }

      if ((data?.error_type || '') === BackendErrorTypeEnum.validation
        && data?.errors
        && Object.keys(data.errors).length
      ) {
        errors.value = data.errors
        stopLoading()
        if (showErrors) {
          showNotifyValidationError(data.errors)
        }

        return
      }

      if ((data?.error_type || '') === BackendErrorTypeEnum.exception) {
        error.value = data.message
        addErrorMessage(error.value)
      }
      stopLoading()

      return
    }

    error.value = (e as Error).message || ''
  }
  stopLoading()

  const showNotifyValidationError = (errors: Record<string, string>) => {
    for (const error in errors) {
      const message = `Ошибка валидации! Поле ${error}: ${errors[error]}`
      addErrorMessage(message)
    }
  }

  watch(
    env,
    () => {
      if (!env.value) {
        return
      }
      axiosInstance.value = createInstance()
    },
    {
      deep: true,
    }
  )

  return {
    response,
    error,
    errors,
    status,
    loading,
    progressEvent,
    headers,
    request,
    setHeader,
    abort
  }
}
