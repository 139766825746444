import {defineStore} from 'pinia'
import {useGridFilters} from 'src/uses/Grid/useGridFilters'
import {ListingsItemRecordsGridFilter} from 'src/models/Listings/ListingRecords/ListingsItemRecordsGridFilter'

const defaultFilters: ListingsItemRecordsGridFilter = {
  key: '',
  value: '',
}

export const useListingsItemRecordsGridFiltersStore = defineStore('listings-item-records-grid-filters', () => {
  const {
    filters,
    clearable,
    getFiltersQuery,
    clear,
  } = useGridFilters(defaultFilters)

  return {
    filters,
    clearable,
    getFiltersQuery,
    clear,
  }
})
