<template>
  <form-group>
    <div class="row q-mb-sm">
      <div class="col q-pr-sm">
        <q-input
          ref="dateFromInput"
          v-model="dateFrom"
          :disable="fromIssueDate || isBlocked('dateFrom')"
          mask="##.##.####"
          placeholder="дд.мм.гггг"
          label="Срок действия банковской гарантии"
          :error="false"
          class="field-with-top-label overflow-label-visible"
          debounce="700"
          outlined
          hide-bottom-space
          dense
          @blur="onDateBlur"
        >
          <template #append>
            <q-icon
              class="cursor-pointer"
              name="svguse:icons.svg?2#app-calendar"
            />
            <q-popup-proxy
              v-if="!fromIssueDate && !isBlocked('dateFrom')"
              ref="dateFromProxy"
              transition-show="scale"
              transition-hide="scale"
            >
              <q-date
                v-model="dateFrom"
                :options="fromDateOptions"
                mask="DD.MM.YYYY"
                minimal
                @update:model-value="($refs['dateFromProxy'] as QPopupProxy).hide()"
              />
            </q-popup-proxy>
          </template>
        </q-input>
      </div>
      <div class="col">
        <q-input
          ref="dateToInput"
          v-model="dateTo"
          placeholder="дд.мм.гггг"
          label=""
          mask="##.##.####"
          :disable="isBlocked('dateTo')"
          :error="false"
          class="field-with-top-label overflow-label-visible"
          debounce="700"
          outlined
          hide-bottom-space
          dense
          @blur="onDateBlur"
        >
          <template #append>
            <q-icon
              class="cursor-pointer"
              name="svguse:icons.svg?2#app-calendar"
            />
            <q-popup-proxy
              v-if="!isBlocked('dateTo')"
              ref="dateToProxy"
              transition-show="scale"
              transition-hide="scale"
            >
              <q-date
                v-model="dateTo"
                :options="toDateOptions"
                mask="DD.MM.YYYY"
                minimal
                @update:model-value="($refs['dateToProxy'] as QPopupProxy).hide()"
              />
            </q-popup-proxy>
          </template>
        </q-input>
      </div>
    </div>
    <div
      class="row no-wrap"
      style="max-height: 41px;"
    >
      <div class="q-mr-md">
        <q-input
          v-model="countDays"
          :disable="isBlocked('countDays')"
          style="width: 100px; height: 44px;"
          label="Дней"
          :error="false"
          debounce="700"
          outlined
          hide-bottom-space
          dense
        />
      </div>
      <q-toggle
        v-model="fromIssueDate"
        :disable="isBlocked('fromIssueDate')"
        label="С даты выдачи"
        class="q-mr-md"
        style="margin-left: -10px;"
        color="blue-7"
      />
    </div>
  </form-group>
</template>

<script setup lang="ts">
import FormGroup from 'src/components/FormGroup.vue'
import {storeToRefs} from 'pinia'
import {useApplicationsNew} from 'src/stores/Applications/ApplicationsNew/useApplicationsNew'
import {ref, watch} from 'vue'
import {QInput, QPopupProxy} from 'quasar'
import {useScrollTo} from 'src/uses/useScrollTo'

const dateFromProxy = ref<QPopupProxy>()
const dateToProxy = ref<QPopupProxy>()
const dateFromInput = ref<QInput>()
const dateToInput = ref<QInput>()

const applicationsNewStore = useApplicationsNew()
const {
  dateFrom,
  dateTo,
  countDays,
  fromIssueDate,
  lastFocused,
} = storeToRefs(applicationsNewStore)

const {
  isBlocked,
  fromDateOptions,
  toDateOptions,
} = applicationsNewStore

const {
  scrollToInvisibleElement,
} = useScrollTo()

const onDateBlur = () => {
  const parts = dateTo.value.split('.')
  if ((parts[2] || '').length === 2) {
    parts[2] = `20${parts[2]}`
    dateTo.value = parts.join('.')
  }
}

watch(
  lastFocused,
  () => {
    if (lastFocused.value === 'dateFrom' && dateFromProxy.value && dateFromInput.value) {
      scrollToInvisibleElement(dateFromInput.value?.$el)
      setTimeout(() => dateFromProxy.value?.show(), 500)

      return
    }

    if (lastFocused.value === 'dateTo' && dateToProxy.value && dateToInput.value) {
      scrollToInvisibleElement(dateToInput.value?.$el)
      setTimeout(() => dateToProxy.value?.show(), 500)
    }
  }
)
</script>
