<template>
  <q-btn
    v-if="!props.itemId"
    color="primary"
    icon-right="svguse:icons.svg?2#app-plus-1"
    label="Добавить банк"
    class="q-ml-md"
    style="padding: 13px 16px;"
    unelevated
    no-caps
    @click="open"
  />
  <q-btn
    v-if="props.itemId"
    size="sm"
    color="blue-grey-4"
    icon="svguse:icons.svg?2#app-pencil-alt"
    round
    flat
    @click="open"
  />
  <q-dialog
    v-model="dialog"
  >
    <q-card class="modal-blue-background">
      <q-card-section
        class="modal-title row items-center q-pb-none q-pl-lg"
      >
        <div>{{ props.itemId ? 'Редактировать' : 'Добавить' }} банк</div>
      </q-card-section>
      <q-card-section class="q-pl-lg q-pr-lg">
        <q-form>
          <div class="row q-col-gutter-lg white-bg-field">
            <div class="col-12 q-mb-sm">
              <q-input
                v-model="bankCreateData.inn"
                class="field-with-top-label"
                :loading="loading"
                :error-message="getErrorMessage('inn')"
                :error="hasError('inn')"
                label="ИНН"
                outlined
                hide-hint
                hide-bottom-space
                dense
                @blur="validation.inn.$validate()"
                @focus="clearError('inn')"
                @change="onChangeInn"
              />
            </div>
            <div class="col-12 q-mb-sm">
              <q-input
                v-model="bankCreateData.title_full"
                class="field-with-top-label"
                :loading="loading"
                :error-message="getErrorMessage('title_full')"
                :error="hasError('title_full')"
                label="Полное наименование банка"
                outlined
                hide-hint
                hide-bottom-space
                dense
                @blur="validation.title_full.$validate()"
                @focus="clearError('title_full')"
              />
            </div>
            <div class="col-12 q-mb-sm">
              <q-input
                v-model="bankCreateData.title_short"
                class="field-with-top-label"
                :loading="loading"
                :error-message="getErrorMessage('title_short')"
                :error="hasError('title_short')"
                label="Наименование банка в системе"
                outlined
                hide-hint
                hide-bottom-space
                dense
                @blur="validation.title_short.$validate()"
                @focus="clearError('title_short')"
              />
            </div>
            <div class="col-12 q-mb-sm">
              <q-input
                v-model="bankCreateData.sign_url"
                class="field-with-top-label"
                :loading="loading"
                label="Ссылка на подписание заявок"
                outlined
                hide-hint
                hide-bottom-space
                dense
              />
            </div>
            <div class="col-12 q-mb-sm">
              <file-select-component
                :input-text="'или перетащите файлы в эту область для загрузки'"
                multiple
                @file:selected="onFileSelected"
              />
              <q-list class="q-mt-sm">
                <q-item
                  v-if="newLogo || bankCreateData.logo"
                  class="news-document-item q-mt-xs"
                >
                  <q-item-section avatar>
                    <q-icon
                      v-if="!bankCreateData.logo"
                      name="svguse:icons.svg?2#app-photograph"
                      color="blue-grey-3"
                    />
                    <q-img
                      v-if="bankCreateData.logo"
                      :src="bankCreateData.logo as string"
                      width="100px"
                    />
                  </q-item-section>
                  <q-item-section>
                    {{ getFileName() ?  getFileName() : bankCreateData.logo}}
                  </q-item-section>
                  <q-item-section side>
                    <q-icon
                      class="document-download-icon cursor-pointer"
                      name="svguse:icons.svg?2#app-x"
                      color="blue-gray-3"
                      @click="removeLogo()"
                    />
                  </q-item-section>
                </q-item>
              </q-list>
            </div>
          </div>
        </q-form>
      </q-card-section>
      <q-card-actions
        align="right"
        class="modal-actions text-primary q-pa-lg"
      >
        <q-btn
          v-close-popup
          :loading="loading"
          label="Отмена"
          class="q-pr-lg"
          style="padding: 17px 28px;"
          unelevated
          flat
          no-caps
          text-color="black"
        />
        <q-btn
          :loading="loading"
          label="Сохранить"
          color="primary"
          style="padding: 17px 28px;"
          unelevated
          icon-right="svguse:icons.svg?2#app-check"
          no-caps
          @click="handleSubmit()"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
import {withDefaults} from 'vue'
import {useDialog} from 'src/uses/Dialogs/useDialog'
import {useValidation} from 'src/uses/useValidation'
import {useBankCreateEdit} from 'src/uses/Bank/useBankCreateEdit'
import FileSelectComponent from 'components/FileSelectComponent.vue'

const props = withDefaults(
  defineProps<{
    itemId?: string
  }>(),
  {
    itemId: undefined
  }
)

const emit = defineEmits<{
  (e: 'success'): void,
}>()

const bankCreateEdit = useBankCreateEdit()
const {
  bankCreateData,
  loading,
  validation,
  errors,
  itemId,
  status,
  newLogo,
  getFileName,
  removeLogo,
  create,
  update,
  loadUserItem,
  onChangeInn,
} = bankCreateEdit

const {dialog, show, hide} = useDialog()

const open = async () => {
  clearAllErrors()
  show()
  itemId.value = props.itemId
  if (itemId.value) {
    await loadUserItem(itemId.value)
  }
}

const {
  getErrorMessage,
  hasError,
  clearError,
  clearAllErrors,
} = useValidation(validation, errors)

const success = () => {
  emit('success')
  hide()
}

const handleSubmit = async () => {
  if (itemId.value) {
    await update()
  } else {
    await create()
  }

  if (status.value === 200) {
    success()
  }
}

const onFileSelected = (file: File) => {
  newLogo.value = file
}

</script>
