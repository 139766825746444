import {defineStore} from 'pinia'
import {computed, Ref, ref} from 'vue'
import {email, maxLength, minLength, passwordValidator, required} from 'src/utils/i18n-validators'
import useVuelidate from '@vuelidate/core'
import {usePostApi} from 'src/uses/Api/usePostApi'
import {Employee} from 'src/models/Profile/Employees/Employee'
import {UserRoles} from 'src/models/User/UserRoleDescriptions'
import {UserRoleEnum} from 'src/models/User/UserRoleEnum'
import {useValidationRules} from 'src/uses/useValidationRules'

export const useEmployeeCreateStore = defineStore('employee-create', () => {
  const {
    post,
    response,
    status,
    loading,
    error,
    errors,
  } = usePostApi<Employee, Employee>()

  const {
    getRulesFromState,
  } = useValidationRules()

  const employeeCreateData = ref<Employee>({
    name: '',
    email: '',
    role: '',
    phone: '',
    password: '',
    password_confirm: '',
  })

  const createdSuccess = ref(false)

  const rolesForSelect = ref([
    {
      value: UserRoleEnum.Employee,
      label: UserRoles.getUserRoleDescription(UserRoleEnum.Employee)
    },
    {
      value: UserRoleEnum.HeadEmployee,
      label: UserRoles.getUserRoleDescription(UserRoleEnum.HeadEmployee)
    }
  ])

  const rules = computed(() => {
    return {
      ...getRulesFromState(employeeCreateData.value),
      name: {
        required,
      },
      email: {
        required,
        email,
      },
      phone: {
        required,
      },
      password: {
        required,
        passwordValidator,
        minLength: minLength(8),
        maxLength: maxLength(14),
      },
      password_confirm: {
        required,
      },
      role: {
        required,
      },
    }
  })

  const validation = useVuelidate<Ref<Employee>>(rules, employeeCreateData)

  const save = async () => {
    if (!await validation.value.$validate()) {
      return
    }

    await post('v1/employees', employeeCreateData.value)
    if (status.value === 200 && response.value) {
      createdSuccess.value = true
    }
  }

  return {
    employeeCreateData,
    loading,
    response,
    status,
    error,
    errors,
    validation,
    createdSuccess,
    rolesForSelect,
    save,
  }
})
