import {defineStore, storeToRefs} from 'pinia'
import {Centrifuge} from 'centrifuge'
import {ref, watch, watchEffect} from 'vue'
import {PublicationContext, State} from 'centrifuge/build/types'
import {EnvCodeEnum, useEnvStore} from 'src/stores/useEnvStore'
import {useTokenStore} from 'src/stores/Login/useTokenStore'
import {useProfileStore} from 'src/stores/Login/useProfileStore'

export const useWebSocket = defineStore(
  'web-socket',
  () => {
    const {
      getEnvValue,
    } = useEnvStore()
    const {
      env,
    } = storeToRefs(useEnvStore())

    const {
      token,
    } = storeToRefs(useTokenStore())

    const {
      profile,
    } = storeToRefs(useProfileStore())

    const client = ref<Centrifuge>()
    const message = ref<PublicationContext>()
    const lastUrl = ref<string>()

    const connect = (token: string) => {
      lastUrl.value = getEnvValue(EnvCodeEnum.VUE_APP_WEBSOCKET_URL)
      client.value = new Centrifuge(
        `${getEnvValue(EnvCodeEnum.VUE_APP_WEBSOCKET_URL)}/websocket/connection/websocket`,
        {
          data: {
            token
          }
        })
    }

    const disconnect = async () => {
      if (!client.value) {
        return
      }

      await client.value.disconnect()
      client.value = undefined
    }

    watch(
      token,
      () => {
        if (!token.value) {
          disconnect()

          return
        }

        connect(token.value)
      }, {
        immediate: true
      })

    watch(
      client,
      () => {
        if (!client.value) {
          return
        }

        if (client.value?.state !== State.Disconnected) {
          return
        }

        client.value.connect()

        client.value.on('connected', async function () {
          if (!client.value) {
            return
          }

          client.value.newSubscription(`personal:#${profile.value?.user_id}`)
            .on('publication', (ctx: PublicationContext) => {
              message.value = ctx
            })
        })

        client.value.on('disconnected', function (ctx) {
          console.log('disconnected', ctx)
        })
      },
      {
        immediate: true
      }
    )

    watchEffect(async () => {
      if (!env.value || !token.value) {
        return
      }

      if (lastUrl.value === getEnvValue(EnvCodeEnum.VUE_APP_WEBSOCKET_URL)) {
        return
      }

      await disconnect()
      connect(token.value)
    })

    return {
      message,
    }
  })
