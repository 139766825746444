<template>
  <template
    v-if="filterFieldsByBlock(props.fields, props.block.startsWith).length"
  >
    <finance-row
      v-for="field in filterFieldsByBlock(props.fields, props.block.startsWith)"
      :key="field.code + field.value"
      :field="field"
      @update:field="updateFinanceField($event)"
    />
  </template>
  <finance-row
    v-if="isHidden24Sum(props.fields, props.block.sum) && sumField"
    :key="sumField.code + sumField.value"
    :field="sumField"
    @update:field="updateFinanceField($event)"
  />
  <finance-sum
    v-if="!isHidden24Sum(props.fields, props.block.sum) && sumField"
    :key="sumField.code + sumField.value"
    :field="sumField"
  />
</template>

<script setup lang="ts">
import FinanceRow from 'components/Applications/Form/Finance/FinanceRow.vue'
import FinanceSum from 'components/Applications/Form/Finance/FinanceSum.vue'
import {useFinanceFieldsFilter} from 'src/uses/Applications/Form/Finance/useFinanceFieldsFilter'
import {
  ApplicationFormFinanceField,
  ApplicationFormFinancePeriod
} from 'src/models/Applications/Form/ApplicationFormResponseData'
import {computed} from 'vue'

const props = defineProps<{
  period: ApplicationFormFinancePeriod,
  fields: ApplicationFormFinanceField[],
  block: {
    startsWith: string
    sum: string
  },
}>()

const emit = defineEmits<{
  (e: 'update:field', field: ApplicationFormFinanceField): void,
}>()

const {
  filterFieldsByBlock,
  getFieldByCode,
} = useFinanceFieldsFilter()

const sumField = computed(() => {
  return getFieldByCode(props.fields, props.block.sum)
})

const updateFinanceField = (field: ApplicationFormFinanceField) => {
  emit(
    'update:field',
    field,
  )
}

const isHidden24Sum = (
  fields: ApplicationFormFinanceField[],
  code: string,
) => {
  if (code !== '2400') {
    return false
  }

  return fields.length === 2
}
</script>
