<template>
  <div class="column fit">
    <div>
      <q-tabs
        v-model="activeChat"
        dense
        align="left"
        breakpoint="0"
        narrow-indicator
        no-caps
        class="control-center-chats-tabs"
      >
        <q-tab
          v-if="!isBankEmployee()"
          :name="ChatTypeEnum.platform"
          label="с площадкой"
          :ripple="false"
        >
          <q-badge
            v-if="platformNewMessages > 0"
            :label="platformNewMessages"
            color="red-12"
            style="padding: 4px; font-size: 8px; height: 12px;"
            floating
            rounded
          />
        </q-tab>
        <q-tab
          :name="ChatTypeEnum.bank"
          label="с банком"
          :ripple="false"
        >
          <q-badge
            v-if="bankNewMessages > 0"
            :label="bankNewMessages"
            color="red-12"
            style="padding: 4px; font-size: 8px; height: 12px;"
            floating
            rounded
          />
        </q-tab>
      </q-tabs>
    </div>
    <div class="col">
      <q-tab-panels
        v-model="activeChat"
        class="bg-transparent fit"
        style="border-radius: 0 0 8px 8px;"
      >
        <q-tab-panel
          v-if="!isBankEmployee()"
          :name="ChatTypeEnum.platform"
          class="q-pa-none fit"
        >
          <chat-messages
            v-if="chat"
            :chat="chat"
            :app-id="props.headerData.id"
          >
            <chat-send-message-component
              :header-data="headerData"
              :chat-type="ChatTypeEnum.platform"
            />
          </chat-messages>
        </q-tab-panel>
        <q-tab-panel
          :name="ChatTypeEnum.bank"
          class="q-pa-none"
        >
          <chat-messages
            v-if="chatBank"
            :chat="chatBank"
            :app-id="props.headerData.id"
          >
            <chat-send-message-component
              :header-data="headerData"
              :chat-type="ChatTypeEnum.bank"
            />
          </chat-messages>
        </q-tab-panel>
      </q-tab-panels>
    </div>
  </div>
</template>

<script setup lang="ts">
import {ref, watch, watchEffect} from 'vue'
import {ControlCenterHeader} from 'src/models/Applications/ControlCenter/ControlCenterHeader'
import ChatMessages from 'src/components/Chat/ChatMessages.vue'
import {
  useControlCenterChatStore,
} from 'src/stores/Applications/ControlCenter/useControlCenterChatStore'
import {storeToRefs} from 'pinia'
import {ChatTypeEnum} from 'src/models/ChatTypeEnum'
import ChatSendMessageComponent from 'src/components/Chat/ChatSendMessageComponent.vue'
import {useProfileStore} from 'src/stores/Login/useProfileStore'

const props = defineProps<{
  headerData: ControlCenterHeader,
  original: boolean,
}>()

const controlCenterChatStore = useControlCenterChatStore(props.headerData.id)()
const {
  chat,
  chatBank,
  activeChat,
} = storeToRefs(controlCenterChatStore)

const {
  isBankEmployee,
} = useProfileStore()

const platformNewMessages = ref(0)
const bankNewMessages = ref(0)

watchEffect(() => {
  platformNewMessages.value = props.headerData.chats.platform.new_messages
  bankNewMessages.value = props.headerData.chats.bank.new_messages
})

watch(
  activeChat,
  () => {
    if (activeChat.value === ChatTypeEnum.platform) {
      platformNewMessages.value = 0
    }

    if (activeChat.value === ChatTypeEnum.bank) {
      bankNewMessages.value = 0
    }
  },
  {
    immediate: true,
  }
)
</script>
<style lang="scss">
.control-center-chats-tabs {
  background-color: #D5EAFA;
  font-style: normal;
  font-weight: 550;
  font-size: 14px;
  line-height: 14px;
  color: #424242;
  .q-focus-helper {
    border-radius: 5px;
    display: none;
  }
  .q-tab__label {
    padding: 3px 5px;
    margin-bottom: 2px;
    &:hover {
      cursor: pointer;
      border-radius: 3px;
      background-color: #C7DFF1;
    }
  }
  .q-tab__indicator {
    background-color: #1E88E5;
  }
}
</style>
