<template>
  <q-pull-to-refresh
    v-if="$q.platform.is.nativeMobile && $q.platform.is.android"
    @refresh="refresh"
  >
    <slot name="default" />
  </q-pull-to-refresh>
  <template v-else>
    <slot name="default" />
  </template>
</template>

<script setup lang="ts">
//import {useSoftReloadStore} from 'stores/useSoftReloadStore'
import {useQuasar} from 'quasar'

/*const {
  reload,
} = useSoftReloadStore()*/

const $q = useQuasar()

const refresh = async (done: () => void) => {
  document.location.reload()
  //await reload()
  done()
}
</script>

<style scoped lang="scss">

</style>
