<template>
  <news-item-dialog
    v-if="!loading && item"
    v-model="dialog"
    :item="item"
  />
</template>

<script setup lang="ts">
import {computed, onBeforeMount} from 'vue'
import {useDialog} from 'src/uses/Dialogs/useDialog'
import NewsItemDialog from 'src/components/News/NewsItemDialog.vue'
import {storeToRefs} from 'pinia'
import {useImportantNewsListStore} from 'stores/News/useImportantNewsListStore'

const newsListStore = useImportantNewsListStore()
const {
  items,
  loading,
  status,
} = storeToRefs(newsListStore)

const {
  load,
} = newsListStore

const {dialog, show} = useDialog()

const open = async () => {
  show()
}

const item = computed(() => items.value[0])

onBeforeMount(async () => {
  /*if (!profile.value || profile.value.news?.important === 0) {
    return
  }*/

  await load()
  if (status.value === 200 && items.value.length > 0) {
    await open()
  }
})
</script>

<style lang="scss">
.news-topic, .news-publication-date {
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #757575;
}

.news-picture {
  border-radius: 5px;
  background: rgb(30,137,231);
  background: radial-gradient(circle, rgba(30,137,231,1) 0%, rgba(111,188,255,1) 0%, rgba(29,134,227,1) 72%);
}

</style>
