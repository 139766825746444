<template>
  <q-breadcrumbs class="Breadcrumbs">
    <q-breadcrumbs-el label="Профиль" />
    <q-breadcrumbs-el label="Настройки уведомлений" />
  </q-breadcrumbs>
</template>

<style lang="scss">
.Breadcrumbs {
  padding-bottom: 25px;
  padding-left: 10px;
}
</style>
