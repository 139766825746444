<template>
  <q-btn
    size="sm"
    icon="svguse:icons.svg?2#app-pencil-alt"
    color="blue-grey-4"
    round
    flat
    @click="open"
  />
  <q-dialog
    v-if="props.product"
    v-model="dialog"
  >
    <q-card
      class="productTariff"
      style="min-width: 500px; max-width: 1000px;"
    >
      <q-card-section
        class="modal-title row items-center q-pb-none q-pl-lg bg-white"
      >
        <div class="q-pt-md q-pb-sm">Редактирование тарифов</div>
      </q-card-section>
      <q-card-section class="q-pa-none">
        <div class="q-mt-md">
          <q-tabs
            v-model="type"
            class="ApplicationItemTabs q-mx-lg"
            align="left"
            no-caps
            inline-label
          >
            <product-tariff-tab
              :name="ProductTariffTypeEnum.PERCENT"
              label="Единый тариф от суммы"
            />
            <product-tariff-tab
              :name="ProductTariffTypeEnum.FIXED"
              label="Единый тариф за лид"
            />
            <product-tariff-tab
              :name="ProductTariffTypeEnum.INTERVALS"
              label="Зависит от суммы"
            />
            <product-tariff-tab
              :name="ProductTariffTypeEnum.BANK"
              label="Расчет за счет банка"
            />
          </q-tabs>

          <div
            v-if="props.product"
            :class="`ApplicationItemTabsContent ApplicationItemTabsContent--in_progress modal-blue-background`"
          >
            <q-tab-panels
              v-model="type"
              class="bg-transparent q-pt-md"
              animated
            >
              <q-tab-panel
                :name="ProductTariffTypeEnum.PERCENT"
                class="q-pa-lg"
              >
                <product-tariff-control-percent
                  :product-id="product.id"
                />
              </q-tab-panel>

              <q-tab-panel
                :name="ProductTariffTypeEnum.FIXED"
                class="q-pa-lg"
              >
                <product-tariff-control-fixed
                  :product-id="props.product.id"
                />
              </q-tab-panel>

              <q-tab-panel
                :name="ProductTariffTypeEnum.INTERVALS"
                class="q-pa-lg"
              >
                <product-tariff-control-intervals
                  :product="props.product"
                />
              </q-tab-panel>

              <q-tab-panel
                :name="ProductTariffTypeEnum.BANK"
                class="q-pa-lg"
              >
                <product-tariff-control-bank
                  :product-id="props.product.id"
                />
              </q-tab-panel>
            </q-tab-panels>
          </div>
        </div>
      </q-card-section>
      <q-card-actions
        align="right"
        class="modal-actions text-primary q-pa-lg"
      >
        <q-btn
          v-close-popup
          label="Отмена"
          class="q-pr-lg"
          style="padding: 17px 28px;"
          unelevated
          flat
          no-caps
          text-color="black"
        />
        <q-btn
          label="Сохранить"
          color="primary"
          style="padding: 17px 28px;"
          unelevated
          icon-right="svguse:icons.svg?2#app-check"
          no-caps
          :disable="!isValid"
          @click="handleSubmit()"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
import {useDialog} from 'src/uses/Dialogs/useDialog'
import {ProductsListItem} from 'src/models/Products/ProductsListItem'
import {ProductTariffTypeEnum} from 'src/models/Enums/ProductTariffTypeEnum'
import ProductTariffTab from 'components/Products/Tariff/ProductTariffTab.vue'
import {useProductsTariffControlStore} from 'stores/Products/Tariff/useProductsTariffControlStore'
import {storeToRefs} from 'pinia'
import {onBeforeUnmount} from 'vue'
import ProductTariffControlPercent from 'components/Products/Tariff/ProductTariffControlPercent.vue'
import ProductTariffControlFixed from 'components/Products/Tariff/ProductTariffControlFixed.vue'
import ProductTariffControlIntervals from 'components/Products/Tariff/ProductTariffControlIntervals.vue'
import ProductTariffControlBank from 'components/Products/Tariff/ProductTariffControlBank.vue'
import {useProductsTariffParamsStore} from 'stores/Products/Tariff/useProductsTariffParamsStore'

const props = defineProps<{
  product: ProductsListItem
}>()

const emit = defineEmits<{
  (e: 'updated'): void
}>()

const productsTariffControlStore = useProductsTariffControlStore(props.product.id)()
const {
  save,
} = productsTariffControlStore
const {
  type,
  putStatus,
  productTariffUpdateData,
  isValid,
} = storeToRefs(productsTariffControlStore)

const productTariffParamsStore = useProductsTariffParamsStore()
const {
  loadProductTariffParams
} = productTariffParamsStore
const {
  productTariffParams
} = storeToRefs(productTariffParamsStore)

const {dialog, show, hide} = useDialog()

const open = async () => {
  const tariff = props.product.tariff
  type.value = props.product.tariff.type || ProductTariffTypeEnum.PERCENT
  productTariffUpdateData.value = {
    value: tariff.value || undefined,
    min_sum: tariff.min_sum,
    conditions: props.product.conditions || [],
    intervals: tariff.intervals || [],
    is_leap_year: tariff.is_leap_year || false
  }
  if (!productTariffParams.value) {
    await loadProductTariffParams()
  }
  show()
}

const handleSubmit = async () => {
  await save(props.product.id)
  emit('updated')
  if (putStatus.value === 200) {
    hide()
  }
}

onBeforeUnmount(() => {
  productsTariffControlStore.$reset()
  productsTariffControlStore.$dispose()
})

</script>
<style lang="scss">
.productTariff {
  .q-field.field-with-top-label {
    padding-top: 0!important;

  }
  .btnBgGray {
    background-color: #C7DFF1 !important;
  }
}
</style>
