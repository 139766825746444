<template>
  <q-form class="q-pt-sm">
    <div v-if="productId" class="row white-bg-field">
      <div class="col-3 q-mb-sm">
        <q-input
          v-model="productTariffUpdateData.value"
          :loading="loading"
          class="field-with-top-label"
          label="% от цены"
          max="100"
          type="number"
          outlined
          hide-hint
          hide-bottom-space
          dense
        />
      </div>
    </div>
  </q-form>
  <product-tariff-control-conditions :product-id="props.productId" />
</template>

<script setup lang="ts">
import {useProductsTariffControlStore} from 'stores/Products/Tariff/useProductsTariffControlStore'
import ProductTariffControlConditions from 'components/Products/Tariff/ProductTariffControlConditions.vue'
import {storeToRefs} from 'pinia'

const props = defineProps<{
  productId: string
}>()

const productsTariffControlStore = useProductsTariffControlStore(props.productId)()
const {
  productTariffUpdateData,
  loading,
} = storeToRefs(productsTariffControlStore)

</script>

<style lang="scss">
</style>
