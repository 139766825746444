import {Platform} from 'quasar'
import {AuthedGuard} from 'src/router/quards/AuthedGuard'
import {UserRoleEnum} from 'src/models/User/UserRoleEnum'

const importClientsGridview = () => {
  if (Platform.is.mobile) {
    return import(/* webpackChunkName: "ClientsView" */ '../views/Clients/ClientsMobileView.vue')
  }

  return import(/* webpackChunkName: "ClientsView" */ '../views/Clients/ClientsView.vue')
}

export const clientsRouter = {
  path: '/clients',
  component: () => import(/* webpackChunkName: "MainLayout" */ '../layouts/MainLayout.vue'),
  children: [
    {
      path: '',
      name: 'clients-list',
      component: () => importClientsGridview(),
    },
  ],
  meta: {
    guards: [
      AuthedGuard([
        UserRoleEnum.Agent,
        UserRoleEnum.HeadEmployee,
        UserRoleEnum.Operator,
        UserRoleEnum.AgentManager,
        UserRoleEnum.Admin,
        UserRoleEnum.JuniorAdmin,
        UserRoleEnum.MiddleAdmin,
      ]),
    ],
  },
}
