<template>
  <div class="row q-my-md">
    <div class="col">
      <profiles-users-grid-filters
        :loading="loading"
        @update:filters="updateGrid"
      />
    </div>
    <div
      v-if="isAdmin()"
      class="q-pl-md"
    >
      <profiles-user-create-modal :item-id="undefined" />
    </div>
  </div>
  <div class="ProfileUsersGrid--table">
    <q-table
      v-model:pagination="gridPagination"
      class="sticky-header-table"
      :rows="items"
      :columns="columns"
      :loading="loading"
      :rows-per-page-options="perPageOptions"
      separator="none"
      row-key="id"
      wrap-cells
      hide-bottom
      @request="onRequest"
    >
      <template #body-cell-fio="props">
        <q-td :props="props">
          {{ getFullName(props.row) }}
        </q-td>
      </template>
      <template #body-cell-phone="props">
        <q-td :props="props">
          {{ props.row.phone }}
        </q-td>
      </template>
      <template #body-cell-email="props">
        <q-td :props="props">
          {{ props.row.email }}
        </q-td>
      </template>
      <template #body-cell-date="props">
        <q-td :props="props">
          {{ props.row.date }}
        </q-td>
      </template>
      <template #body-cell-status="props">
        <q-td
          :props="props"
          :class="`text-${UserStatuses.getUserStatusColor(props.row.status)}`"
        >
          {{ UserStatuses.getUserStatusDescription(props.row.status) }}
        </q-td>
      </template>
      <template #body-cell-id-sign="props">
        <q-td :props="props">
          <copy-to-clipboard
            class="col"
            :text="props.row.id.toString()"
          >
            {{ props.row.id }}
          </copy-to-clipboard>
        </q-td>
      </template>
      <template #body-cell-actions="props">
        <q-td :props="props">
          <q-btn
            size="sm"
            color="primary"
            icon="svguse:icons.svg?2#app-users"
            :to="getImpersonateUrl(props.row)"
            target="_blank"
            round
            flat
          />
          <profiles-user-create-modal :item-id="props.row.id" />
          <profiles-user-delete-modal
            v-if="isAdmin()"
            :item-id="props.row.id"
            @change="updateGrid"
          />
        </q-td>
      </template>
    </q-table>
    <pagination-component
      class="q-mb-md"
      :pagination="gridPagination as GridPagination"
      :per-page-options="perPageOptions"
      @request="onRequest"
      @refresh="load"
    />
  </div>
</template>

<script setup lang="ts">
import {storeToRefs} from 'pinia'
import {onBeforeMount, onBeforeUnmount} from 'vue'
import {useGridSettings} from 'src/uses/Grid/useGridSettings'
import {UserStatuses} from 'src/models/User/UserStatusDescriptions'
import ProfilesUsersGridFilters from 'src/components/Profiles/Users/ProfilesUsersGridFilters.vue'
import {useUsersListStore} from 'src/stores/Profiles/Users/useUsersListStore'
import {useUsersListFiltersStore} from 'src/stores/Profiles/Users/useUsersListFiltersStore'
import {UsersGridColumns} from 'src/components/Profiles/Users/UsersGridColumns'
import ProfilesUserCreateModal from 'src/components/Profiles/Users/ProfilesUserCreateModal.vue'
import ProfilesUserDeleteModal from 'src/components/Profiles/Users/ProfilesUserDeleteModal.vue'
import {GridPagination, RequestProp} from 'src/models/Grid/Pagination'
import PaginationComponent from 'src/components/PaginationComponent.vue'
import {useProfileStore} from 'src/stores/Login/useProfileStore'
import {GridSortingEnum} from 'src/models/Grid/GridSortingEnum'
import {useUsersGridSortStore} from 'src/stores/Profiles/Users/useUsersGridSortStore'
import {useImpersonateStore} from 'src/stores/useImpersonateStore'
import CopyToClipboard from 'components/CopyToClipboard.vue'

const {
  isAdmin,
} = useProfileStore()

const filtersStore = useUsersListFiltersStore()
const usersListStore = useUsersListStore()
const {
  items,
  gridPagination,
  perPageOptions,
  loading,
} = storeToRefs(usersListStore)

const {
  load,
  onRequestPagination,
  setPage,
  getFullName
} = usersListStore

const usersGridSortStore = useUsersGridSortStore()
const {
  sorting,
} = storeToRefs(usersGridSortStore)

const {
  getImpersonateUrl,
} = useImpersonateStore()

const {columns} = useGridSettings('profiles-users-grid', UsersGridColumns)

const onRequest = ({pagination}: RequestProp) => {
  sorting.value.direction = pagination.descending ? GridSortingEnum.DESC : GridSortingEnum.ASC
  sorting.value.sort = pagination.sortBy || ''
  onRequestPagination(pagination as GridPagination)
  load()
}

const updateGrid = () => {
  setPage(1)
  load()
}

onBeforeMount(async () => {
  await load()
})

onBeforeUnmount(() => {
  usersListStore.$reset()
  usersListStore.$dispose()

  filtersStore.$reset()
  filtersStore.$dispose()

  usersGridSortStore.$reset()
  usersGridSortStore.$dispose()
})
</script>

<style lang="scss">
.ProfileUsersGrid--table {
  margin: 0 -24px -24px;
  background: $blue-1;
  padding: 0 24px 24px;

  .sticky-header-table {
    //max-height: calc(100vh - 400px);
    .q-table__top, .q-table__bottom, .q-table:first-child tr th {
      background-color: #E3F2FD
    }
    .q-table__progress {
      .q-linear-progress {
        position: static !important;
      }
    }
    thead tr th {
      position: sticky;
      z-index: 1;
      top: 3px;

      &:before {
        content: '';
        width: 100%;
        height: 4px;
        margin-top: -4px;
        background-color: #E3F2FD;
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
      }
    }
    &.q-table--loading thead tr:last-child th {
      top: 48px;
    }
    tbody {
      scroll-margin-top: 48px;
    }
  }
}
</style>
