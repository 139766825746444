import {defineStore} from 'pinia'
import {ref} from 'vue'

export const useLoadingStore = defineStore('loadingStore', () => {
  const loading = ref(false)
  const text = ref<string>()
  const switchTimeout = ref()

  const showLoading = (str = '') => {
    clearTimeout(switchTimeout.value)
    text.value = str
    loading.value = true
  }

  const hideLoading = () => {
    clearTimeout(switchTimeout.value)
    switchTimeout.value = setTimeout(() => {
      text.value = undefined
      loading.value = false
    }, 30)
  }

  return {
    loading,
    text,
    showLoading,
    hideLoading,
  }
})
