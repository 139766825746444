import {useDeleteApi} from 'src/uses/Api/useDeleteApi'

export const useFormItemFinancePeriodDelete = () => {
  const {
    deleteRequest,
    response,
    status,
    loading,
    error,
    errors,
  } = useDeleteApi<never>()

  const deletePeriod = async (formId: string, periodId: string) => {
    await deleteRequest(`v2/forms/${formId}/finance/periods/${periodId}`)
  }

  return {
    loading,
    response,
    status,
    error,
    errors,
    deletePeriod,
  }
}
