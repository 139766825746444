<template>
  <span :class="{FilterPin: true, MobileFilterPin: $q.platform.is.mobile}">
    <span class="row">
      <span class="col">
        <slot />
      </span>
      <span v-if="!props.noPin && !$q.platform.is.mobile">
        <q-btn
          style="transform: rotate(45deg); margin: -5px 0 0 2px"
          size="8px"
          :color="props.modelValue ? 'primary' : 'blue-grey-2'"
          padding="2px"
          icon="svguse:icons.svg?2#app-push-pin"
          round
          unelevated
          flat
          @click="onToggle"
        />
      </span>
    </span>
  </span>
</template>

<script setup lang="ts">
import {withDefaults} from 'vue'
import {useQuasar} from 'quasar'

const props = withDefaults(
  defineProps<{
    modelValue: boolean,
    noPin?: boolean
  }>(),
  {
    noPin: false,
  }
)

const emit = defineEmits<{
  (e: 'update:model-value', value: boolean): void,
}>()

const $q = useQuasar()

const onToggle = () => {
  emit('update:model-value', !props.modelValue)
}
</script>

<style lang="scss">
.FilterPin {
  width: 330px;

  &.MobileFilterPin {
    width: 100%;
  }
}
</style>
