import postHog from 'posthog-js'
export default {
  install (app) {
    app.config.globalProperties.$posthog = postHog.init(
      'phc_DWhlzFBYNTWfiK7g8XWyp9K6jklDm0RZYFMIBo9Di48',
      {
        api_host: 'https://eu.i.posthog.com',
        person_profiles: 'identified_only',
        capture_pageview: false,
        capture_pageleave: false,
      }
    )
  },
}
