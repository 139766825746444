import {AuthedGuard} from 'src/router/quards/AuthedGuard'

export const newsRouter = {
  path: '/news',
  component: () => import(/* webpackChunkName: "MainLayout" */ '../layouts/MainLayout.vue'),
  children: [
    {
      path: '',
      name: 'news',
      component: () => import(/* webpackChunkName: "NewsView" */ '../views/News/NewsView.vue'),
      meta: {
        guards: [
          AuthedGuard(),
        ],
      },
    },
  ]
}
