import {FormItemUpdateDocumentsData} from 'src/models/FormEditor/FormItemUpdateDocumentsData'
import {FormItemDocumentListItem} from 'src/models/FormEditor/FormItemDocumentListItem'
import {usePutApi} from 'src/uses/Api/usePutApi'

export const useFormItemUpdateDocuments = () => {
  const {
    put,
    response,
    status,
    loading,
    error,
    errors,
  } = usePutApi<FormItemDocumentListItem[], FormItemUpdateDocumentsData>()

  const updateDocs = async (
    formId: string,
    documents: FormItemDocumentListItem[],
  ) => {
    const filteredDocs = documents
      .filter(d => d.selected === true)

    const data = {
      id: formId,
      documents: filteredDocs.map(fd => {
        return {
          id: fd.id,
          required: !!fd.required,
        }
      }),
    }

    await put(`v2/forms/${formId}/documents`, data)

    return response.value
  }

  return {
    loading,
    response,
    status,
    error,
    errors,
    updateDocs,
  }
}
