import {defineStore, storeToRefs} from 'pinia'
import {useTokenStore} from 'src/stores/Login/useTokenStore'
import {computed} from 'vue'
import {useProfileStore} from 'src/stores/Login/useProfileStore'

export const useAuthStore = defineStore('auth', () => {
  const tokenStore = useTokenStore()
  const profileStore = useProfileStore()
  const {logout} = tokenStore
  const {token, tokenData} = storeToRefs(tokenStore)
  const {
    profile,
    loading,
  } = storeToRefs(profileStore)
  const {
    isAdmin,
    isJuniorAdmin,
    isBankEmployee,
    isAgent,
    isOperator,
    isAgentManager,
  } = profileStore
  const isAuthed = computed(() => !!token.value)

  const profileLoaded = () => {
    return new Promise(function (resolve) {
      if (!isAuthed.value) {
        resolve(true)
        return
      }

      if (!profile.value) {
        const profileInterval = setInterval(() => {
          if (!profile.value) {
            return
          }
          clearInterval(profileInterval)
          resolve(true)
        }, 100)
      } else {
        resolve(true)
      }
    })
  }

  return {
    token,
    tokenData,
    isAuthed,
    profile,
    loading,
    profileLoaded,
    logout,
    isAdmin,
    isJuniorAdmin,
    isAgent,
    isOperator,
    isAgentManager,
    isBankEmployee,
  }
})
