<template>
  <div class="SettingsRequestData">
    <span class="text-h6 q-mb-md">
      Запрос данных из сервисов
    </span>
    <q-form>
      <div class="row q-col-gutter-lg q-mt-md white-bg-field">
        <div class="col-12">
          <span class="text-bold">Запрос данных по РНТ</span>
        </div>
        <div class="col-3">
          <q-input
            v-model="rntRequestData.rnt"
            class="field-with-top-label"
            :loading="loading"
            label="РНТ"
            outlined
            hide-hint
            hide-bottom-space
            dense
          />
        </div>
        <div class="col-3">
          <q-input
            v-model="rntRequestData.lot"
            class="field-with-top-label"
            label="Лот"
            outlined
            hide-hint
            hide-bottom-space
            dense
          />
        </div>
        <div class="col-3">
          <q-select
            v-model="rntRequestData.source"
            class="field-with-top-label"
            :options="rntSources()"
            :loading="loading"
            map-options
            label="Источник"
            outlined
            hide-hint
            hide-bottom-space
            dense
            emit-value
          />
        </div>
        <div class="col-3">
          <q-btn
            :loading="loading"
            label="Обновить"
            color="primary"
            style="padding: 12px 28px; margin-top: 30px"
            unelevated
            icon-right="svguse:icons.svg?2#app-check"
            no-caps
            :disable="!isValidRnt"
            @click="handleSubmit('rnt')"
          />
        </div>
      </div>
      <div class="row q-col-gutter-lg q-mt-md white-bg-field q-mb-lg">
        <div class="col-12">
          <span class="text-bold">Запрос данных ЕГРЮЛ</span>
        </div>
        <div class="col-3">
          <q-input
            v-model="egrulRequestData.inn"
            class="field-with-top-label"
            :loading="loading"
            label="ИНН"
            outlined
            hide-hint
            hide-bottom-space
            dense
          />
        </div>
        <div class="col-3">
          <q-select
            v-model="egrulRequestData.source"
            class="field-with-top-label"
            :options="egruleSources()"
            :loading="loading"
            map-options
            label="Источник"
            outlined
            hide-hint
            hide-bottom-space
            dense
            emit-value
          />
        </div>
        <div class="col-3">
          <q-btn
            :loading="loading"
            label="Обновить"
            color="primary"
            style="padding: 12px 28px; margin-top: 30px"
            unelevated
            icon-right="svguse:icons.svg?2#app-check"
            no-caps
            :disable="!isValidEgrul"
            @click="handleSubmit('egrul')"
          />
        </div>
      </div>
    </q-form>
  </div>
</template>

<script setup lang="ts">
import {useSettingsRequestData} from 'src/uses/Settings/useSettingsRequestData'
import {Notify} from 'quasar'

const {
  loading,
  status,
  rntRequestData,
  egrulRequestData,
  isValidEgrul,
  isValidRnt,
  changeRntRequest,
  changeEgrulRequest,
  egruleSources,
  rntSources,
} = useSettingsRequestData()

const handleSubmit = async (service: string) => {
  service === 'egrul' ? await changeEgrulRequest() : await changeRntRequest()

  if (status.value === 200) {
    Notify.create({
      type: 'positive',
      message: 'Данные обновлены',
      position: 'top',
      timeout: 2000,
      textColor: 'white',
    })
  }
}

</script>
<style lang="scss">
.SettingsRequestData {
  margin: -24px;
  background: $blue-1;
  padding: 24px;
}
</style>
