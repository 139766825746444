import {ListingsGuard} from 'src/router/quards/ListingsGuard'

export const listingsRouter = {
  path: '/listings',
  component: () => import(/* webpackChunkName: "MainLayout" */ '../layouts/MainLayout.vue'),
  children: [
    {
      path: '',
      name: 'listings-grid',
      component: () => import(/* webpackChunkName: "ListingsView" */ '../views/Listings/ListingsView.vue'),
    },
    {
      path: '/listings/:listingId/records',
      name: 'listing-records',
      component: () => import(/* webpackChunkName: "ListingsRecordsView" */ '../views/Listings/ListingRecords/ListingRecordsView.vue'),
    },
  ],
  meta: {
    guards: [
      ListingsGuard,
    ],
  },
}
