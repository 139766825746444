import {useGetApi} from 'src/uses/Api/useGetApi'
import {computed, ref, watch} from 'vue'
import {usePutApi} from 'src/uses/Api/usePutApi'
import {Product} from 'src/models/Products/Product'
import {useProductStopFactorsParams} from 'src/uses/Products/useProductStopFactorsParams'
import {ProductStopFactorParamsItemOperatorEnum} from 'src/models/Products/StopFactorParamsItem'
import {FormElementValue} from 'src/models/Form'

export const useProductStopFactors = () => {
  const {
    loading,
    get,
    errors,
    error,
    status,
    response,
  } = useGetApi<Product>()

  const {
    loading: putLoading,
    put,
    status: putStatus,
  } = usePutApi<unknown, Product>()

  const {
    stopFactorsParamsOptions,
    paramComparisonOptions,
    paramValueTypesAndOptions,
    operatorOptions,
    loadProductStopFactorsParams
  } = useProductStopFactorsParams()

  const product = ref<Product>()

  const integrationEnabled = computed(() => {
    if (!product.value) {
      return false
    }

    return product.value.integration_enabled
  })

  const stopFactorsCount = computed(() => {
    if (!product.value) {
      return 0
    }

    return product.value?.stop_factors.length
  })

  const isStopFactorsValid = computed(() => {
    if (!product.value) {
      return false
    }

    if (product.value?.stop_factors.length === 0) {
      return false
    }

    let isValid = true
    product.value?.stop_factors.forEach((stopFactor) => {
      stopFactor.comparison.parts.forEach((param) => {
        if (!param.value) {
          isValid = false
        }
      })
    })

    return isValid
  })

  const onChangeParam = (stopFactorKey: number, paramKey: number, value: FormElementValue) => {
    if (!product.value || !product.value?.stop_factors) {
      return
    }

    if (!product.value?.stop_factors[stopFactorKey]) {
      return
    }

    product.value?.stop_factors[stopFactorKey].comparison.parts.map((param, searchParamKey) => {
      if (searchParamKey === paramKey) {
        param.value = value as string | string[] | null
      }
      return param
    })
  }

  const addStopFactor = () => {
    if (!product.value) {
      return
    }

    product.value.stop_factors.push({
      comment: '',
      comparison: {
        operator: 'and',
        parts: [getInitParam()],
        type: 'comparison'
      },
      result: 10
    })
  }

  const removeStopFactor = (key: number) => {
    if (!product.value || !product.value?.stop_factors) {
      return
    }

    product.value.stop_factors.splice(key, 1)
  }

  const addParam = (key: number) => {
    if (!product.value || !product.value?.stop_factors) {
      return
    }

    if (!product.value.stop_factors[key]) {
      return
    }

    product.value.stop_factors[key].comparison.parts.push(getInitParam())
  }

  const removeParam = (stopFactorKey: number, paramKey: number) => {
    if (!product.value || !product.value?.stop_factors) {
      return
    }

    product.value?.stop_factors[stopFactorKey].comparison.parts.splice(paramKey, 1)
  }

  const loadProduct = async (id: string) => {
    await get(`v1/admin/product/${id}`)

    if (status.value === 200 && response && response.value) {
      product.value = response.value
    }
  }

  const save = async (id: string) => {
    await put(`v1/admin/product/${id}`, product.value)
  }

  const getInitParam = () => {
    return {
      operator: ProductStopFactorParamsItemOperatorEnum.EQ,
      param: 'guaranteeTotal',
      value: 0
    }
  }

  watch(
    integrationEnabled,
    () => {
      if (!product.value) {
        return
      }

      if (integrationEnabled.value) {
        product.value.manual_generation_enabled = false
        product.value.inner_signing_enabled = true
      }
    }
  )

  return {
    loading,
    putLoading,
    putStatus,
    status,
    get,
    errors,
    error,
    product,
    loadProduct,
    save,

    isStopFactorsValid,
    stopFactorsCount,
    paramComparisonOptions,
    paramValueTypesAndOptions,
    stopFactorsParamsOptions,
    operatorOptions,
    loadProductStopFactorsParams,
    onChangeParam,
    addStopFactor,
    removeStopFactor,
    addParam,
    removeParam,
  }
}
