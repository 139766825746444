<template>
  <div
    v-if="props.item.additional_information.release_date.date"
    :class="classes"
    style="font-weight: 700;"
  >
    {{ props.item.additional_information.release_date.date }}
  </div>
</template>

<script setup lang="ts">
import {computed} from 'vue'
import {ApplicationsGridItem} from 'src/models/Applications/ApplicationsGridItem'

const props = defineProps<{
  item: ApplicationsGridItem
}>()

const classes = computed(() => {
  return {
    'text-green-14': props.item.additional_information.release_date.is_exact,
    'text-orange-6': !props.item.additional_information.release_date.is_exact,
  }
})
</script>
