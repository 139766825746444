import {defineStore} from 'pinia'
import {AgentCompany} from 'src/models/Profile/Requisites/AgentCompany'
import {useEditProfileRequisites} from 'src/stores/Profile/Requisites/useEditProfileRequisites'
import {OpfEnum} from 'src/models/OpfEnum'
import {email, integer, maxLength, minLength, phoneValidator, required} from 'src/utils/i18n-validators'

export const useProfileRequisitesCompanyStore =
  defineStore('profile-requisites-company', () => {
    const {
      agentData,
      loading,
      response,
      status,
      error,
      errors,
      validation,
      success,
      save,
    } = useEditProfileRequisites<AgentCompany>(
      OpfEnum.COMPANY,
      {
        inn: {
          required,
          integer,
          minLength: minLength(10),
          maxLength: maxLength(12),
        },
        contact_email: {
          required,
          email,
        },
        contact_phone: {
          required,
          phoneValidator,
        },
        paymentAccount: {},
      }
    )

    return {
      agentData,
      loading,
      response,
      status,
      error,
      errors,
      validation,
      success,
      save,
    }
  })
