<template>
  <applications-grid-filters-resume-dialog
    :top="controlHeight"
  >
    <div class="row items-end justify-center">
      <q-btn
        color="grey-2"
        class="text-blue-grey-4"
        icon-right="svguse:icons.svg?2#app-adjustments"
        padding="xs"
        unelevated
        no-caps
        @click="filterDialogShow"
      />
      <q-btn
        v-if="clearable"
        color="grey-2"
        class="text-blue-grey-4 q-ml-xs"
        icon-right="svguse:icons.svg?2#app-trash"
        size="14px"
        padding="5px 7px"
        unelevated
        square
        no-caps
        @click="onClear"
      />
      <div>
        <application-grid-filters-expansion
          :loading="loading"
          :ignore-pins="true"
          @update:filters="updateFilters"
        />
      </div>
      <q-btn
        class="q-ml-sm text-primary bg-grey-2"
        label="Наверх"
        padding="xs sm"
        unelevated
        no-wrap
        no-caps
        @click="scrollTop"
      >
        <q-icon
          name="svguse:icons.svg?2#app-arrow-up"
          color="primary"
          size="15px"
        />
      </q-btn>
    </div>
  </applications-grid-filters-resume-dialog>
  <application-grid-filters-dialog
    v-model="filerDialog"
    :loading="loading"
    :ignorePins="ignorePins"
    @update:filters="updateFilters"
  />
  <grid-column-settings-dialog
    v-model="columns"
    v-model:dialog="dialogGridColumnsSettings"
    :default="ApplicationsGridColumns()"
  />
  <div
    class="q-px-none"
    style="margin-top: -20px;"
  >
    <div ref="controlRef">
      <div class="row">
        <div class="col">
          <q-btn
            color="blue-grey-4"
            icon="svguse:icons.svg?2#app-refresh"
            class="q-mr-sm"
            flat
            square
            dense
            @click="load"
          />
          <application-grid-product-toggle :loading="loading" />
        </div>
        <application-grid-toggle :loading="loading" />
      </div>
      <div class="q-px-lg q-py-md ApplicationsGrid--control">
        <div class="q-gutter-y-sm">
          <div class="row no-wrap q-col-gutter-x-sm">
            <div v-if="filtersBtnNotInStatuses">
              <q-btn
                color="grey-2"
                class="text-blue-grey-4"
                icon-right="svguse:icons.svg?2#app-adjustments"
                padding="10px"
                unelevated
                no-caps
                flat
                @click="filterDialogShow"
              />
            </div>
            <div class="col white-bg-field">
              <application-grid-filters-expansion
                ref="filterExpansion"
                v-model="filerDialog"
                :class="{'q-mt-sm': true, 'q-mb-sm': filterExpansion && filterExpansion.isShowFilters}"
                :ignore-pins="ignorePins"
                :loading="loading"
                @update:filters="updateFilters"
              />
              <div
                v-if="hasPins && !ignorePins"
                class="row q-gutter-sm q-mb-md"
              >
                <application-grid-filters
                  :loading="loading"
                  :ignore-pins="ignorePins"
                  only-pinned
                  @update:filters="updateFilters"
                />
              </div>
            </div>
            <div v-if="filtersBtnNotInStatuses">
              <q-btn
                v-if="clearable"
                color="grey-2"
                class="text-blue-grey-4 q-ml-xs"
                icon-right="svguse:icons.svg?2#app-trash"
                padding="7px"
                unelevated
                no-caps
                flat
                @click="onClear"
              />
            </div>
          </div>
          <div class="row no-wrap q-col-gutter-x-sm">
            <div v-if="filtersBtnInStatuses">
              <q-btn
                color="grey-2"
                class="text-blue-grey-4"
                icon-right="svguse:icons.svg?2#app-adjustments"
                padding="7px"
                unelevated
                no-caps
                flat
                @click="filterDialogShow"
              />
            </div>
            <div class="col">
              <application-grid-statuses @update:filters="updateFilters" />
            </div>
            <div v-if="filtersBtnInStatuses">
              <q-btn
                v-if="clearable"
                color="grey-2"
                class="text-blue-grey-4 q-ml-xs"
                icon-right="svguse:icons.svg?2#app-trash"
                padding="7px"
                unelevated
                no-caps
                flat
                @click="onClear"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="ApplicationsGrid--table">
      <q-table
        v-model:pagination="gridPagination"
        class="sticky-header-table"
        :rows="items"
        :columns="filteredVisibleColumns"
        :loading="loading"
        :rows-per-page-options="perPageOptions"
        separator="none"
        row-key="name"
        hide-pagination
        wrap-cells
        @request="onRequest"
      >
        <template
          v-if="!loading"
          #no-data
        >
          <template v-if="!isDefaultFilterValue">
            По вашему запросу ничего не найдено. Попробуйте изменить фильтры
          </template>
          <template v-else>
            У вас пока нет ни одной заявки
          </template>
        </template>
        <template #header-cell="props">
          <q-th
            :props="props"
            class="grid-column-header"
          >
            <applications-grid-th
              :item="props.col"
              @update:sorting="updateSorting"
            />
          </q-th>
        </template>
        <template #header-cell-actions="props">
          <q-th
            :props="props"
            class="grid-column-header"
          >
            <q-btn
              color="primary"
              icon="svguse:icons.svg?2#app-cog"
              flat
              square
              dense
              @click="showGridColumnsSettings"
            />
          </q-th>
        </template>
        <template #body-cell-date="props">
          <q-td :props="props">
            <application-grid-number
              :item="props.row"
              :onlyText="ApplicationSubStatusEnum.archive === props.row.sub_status"
            />
          </q-td>
        </template>
        <template #body-cell-inn="props">
          <q-td :props="props">
            <application-grid-inn :item="props.row" />
          </q-td>
        </template>
        <template #body-cell-rnt="props">
          <q-td :props="props">
            <application-grid-rnt :item="props.row" />
          </q-td>
        </template>
        <template #body-cell-period="props">
          <q-td :props="props">
            <application-grid-period :item="props.row" />
          </q-td>
        </template>
        <template #body-cell-guarantee_total="props">
          <q-td :props="props">
            <application-grid-sum
              :item="props.row"
              @tariff-refreshed="refreshById(props.row.id as never)"
            />
          </q-td>
        </template>
        <template #body-cell-release_date="props">
          <q-td :props="props">
            <application-grid-release-date :item="props.row" />
          </q-td>
        </template>
        <template #body-cell-status="props">
          <q-td :props="props">
            <div class="row no-wrap">
              <application-grid-status
                class="col"
                :item="props.row"
              />
              <application-grid-messages-action
                :item="props.row"
                @model-closed="refreshById(props.row.id as never)"
              />
            </div>
          </q-td>
        </template>
        <template #body-cell-users="props">
          <q-td :props="props">
            <application-grid-users
              :operator="props.row.operator"
              :agent-manager="props.row.agent_manager"
              :agent="props.row.agent"
              :hide-personal-data="profile && !!profile.is_hide_personal_data"
            />
          </q-td>
        </template>
        <template #body-cell-docs="props">
          <q-td :props="props">
            <application-grid-docs :item="props.row" />
          </q-td>
        </template>
        <template #body-cell-employee="props">
          <q-td :props="props">
            {{ props.row.agent.employee?.name || '' }}
          </q-td>
        </template>
        <template #body-cell-actions="props">
          <q-td :props="props">
            <application-grid-actions
              :item="props.row"
              @change="refreshById(props.row.id as never)"
            />
          </q-td>
        </template>
        <template #body-cell-bank="props">
          <q-td :props="props">
            <img
              :src="props.row.bank.logo"
              :alt="props.row.bank.title_short"
              style="max-width: 100px; max-height: 50px;"
            >
          </q-td>
        </template>
      </q-table>
      <pagination-component
        class="q-mb-md"
        :pagination="gridPagination as GridPagination"
        :per-page-options="perPageOptions"
        :can-refresh="false"
        @request="onRequest"
        @refresh="load"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import {ApplicationsGridColumns} from 'src/components/Applications/ApplicationsGrid/ApplicationsGridColumns'
import ApplicationGridNumber from 'src/components/Applications/ApplicationsGrid/Columns/ApplicationGridNumber.vue'
import ApplicationGridInn from 'src/components/Applications/ApplicationsGrid/Columns/ApplicationGridInn.vue'
import ApplicationGridRnt from 'src/components/Applications/ApplicationsGrid/Columns/ApplicationGridRnt.vue'
import ApplicationGridPeriod from 'src/components/Applications/ApplicationsGrid/Columns/ApplicationGridPeriod.vue'
import ApplicationGridSum from 'src/components/Applications/ApplicationsGrid/Columns/ApplicationGridSum.vue'
import ApplicationGridStatus from 'src/components/Applications/ApplicationsGrid/Columns/ApplicationGridStatus.vue'
import ApplicationGridUsers from 'src/components/Applications/ApplicationsGrid/Columns/ApplicationGridUsers.vue'
import ApplicationGridFilters from 'src/components/Applications/ApplicationsGrid/ApplicationGridFilters.vue'
import ApplicationGridFiltersExpansion from 'src/components/Applications/ApplicationsGrid/ApplicationGridFiltersExpansion.vue'
import ApplicationGridMessagesAction
  from 'src/components/Applications/ApplicationsGrid/Actions/ApplicationGridMessagesAction.vue'
import ApplicationGridStatuses from 'src/components/Applications/ApplicationsGrid/ApplicationGridStatuses.vue'
import PaginationComponent from 'src/components/PaginationComponent.vue'
import ApplicationGridDocs from 'src/components/Applications/ApplicationsGrid/Columns/ApplicationGridDocs.vue'
import ApplicationGridActions from 'src/components/Applications/ApplicationsGrid/Actions/ApplicationGridActions.vue'
import GridColumnSettingsDialog from 'src/components/Grid/GridColumnSettingsDialog.vue'
import {GridPagination} from 'src/models/Grid/Pagination'
import ApplicationsGridTh from 'src/components/Applications/ApplicationsGrid/ApplicationsGridTh.vue'
import ApplicationGridReleaseDate
  from 'src/components/Applications/ApplicationsGrid/Columns/ApplicationGridReleaseDate.vue'
import ApplicationGridFiltersDialog from 'src/components/Applications/ApplicationsGrid/ApplicationGridFiltersDialog.vue'
import {useApplicationsGrid} from 'src/components/Applications/ApplicationsGrid/Grid/useApplicationsGrid'
import {computed, onBeforeMount, ref} from 'vue'
import {ApplicationSubStatusEnum} from 'src/models/Applications/ApplicationSubStatusEnum'
import ApplicationGridToggle from 'components/Applications/ApplicationsGrid/Grid/ApplicationGridToggle.vue'
import ApplicationsGridFiltersResumeDialog
  from 'components/Applications/ApplicationsGrid/Grid/ApplicationsGridFiltersResumeDialog.vue'
import ApplicationGridProductToggle
  from 'components/Applications/ApplicationsGrid/Grid/ApplicationGridProductToggle.vue'
import {storeToRefs} from 'pinia'
import {useProfileStore} from 'stores/Login/useProfileStore'

const {profile} = storeToRefs(useProfileStore())

const {
  dialogGridColumnsSettings,
  showGridColumnsSettings,

  isDefaultFilterValue,
  hasPins,
  ignorePins,
  clearable,

  items,
  gridPagination,
  perPageOptions,
  loading,

  load,
  refreshById,

  columns,

  filerDialog,
  filterDialogShow,

  controlRef,
  controlHeight,
  filteredVisibleColumns,

  onRequest,
  updateFilters,
  updateSorting,
  onClear,
} = useApplicationsGrid()

const filterExpansion = ref<typeof ApplicationGridFiltersExpansion>()

const filtersBtnInStatuses = computed(() => {
  return filterExpansion.value
    && !filterExpansion.value.isShowFilters
    && !(hasPins.value && !ignorePins.value)
})

const filtersBtnNotInStatuses = computed(() => {
  return filterExpansion.value
    && (
      filterExpansion.value.isShowFilters
      ||
      (hasPins.value && !ignorePins.value)
    )
})

const scrollTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  })
}

onBeforeMount(async () => {
  await load()
})
</script>

<style lang="scss" src="./ApplicationsGrid.scss" />
