import {UserListItem} from 'src/models/Profiles/Users/UserListItem'
import {RouteLocationNormalizedLoaded, useRouter} from 'vue-router'
import {defineStore} from 'pinia'
import {ref, watchEffect} from 'vue'

export const useImpersonateStore =
  defineStore('impersonate', () => {
    const router = useRouter()
    const impersonateEmail = ref<string>()
    const getImpersonateUrl = (user: UserListItem) => {
      if (!user.email) {
        return undefined
      }

      return {
        name: 'main',
        query: {
          impersonate: user.email,
        },
      }
    }

    const detectImpersonateInRoute = (to: RouteLocationNormalizedLoaded) => {
      return !!to.query.impersonate
    }

    const processImpersonate = (to: RouteLocationNormalizedLoaded) => {
      if (!detectImpersonateInRoute(to)) {
        return
      }

      impersonateEmail.value = to.query.impersonate as string
      document.location.href = router.resolve({name: 'main'}).href
      document.location.reload()
    }

    //eslint-disable-next-line
    const clearImpersonate = () => {
      window.sessionStorage.removeItem('impersonate')
    }

    watchEffect(() => {
      if (!impersonateEmail.value) {
        return
      }

      window.sessionStorage.setItem('impersonate', impersonateEmail.value)
    })

    impersonateEmail.value = window.sessionStorage.getItem('impersonate') || undefined

    return {
      getImpersonateUrl,
      detectImpersonateInRoute,
      processImpersonate,
      clearImpersonate,
      impersonateEmail,
    }
  })
