import {defineStore, storeToRefs} from 'pinia'
import {useGetApi} from 'src/uses/Api/useGetApi'
import {ref} from 'vue'
import {useGridPagination} from 'src/uses/Grid/useGridPagination'
import {AgentsListResponseData} from 'src/models/Profiles/Agents/AgentsListResponseData'
import {useAgentsListFiltersStore} from 'src/stores/Profiles/Agents/useAgentsListFiltersStore'
import {ProfilesAgentList} from 'src/models/Profiles/Agents/ProfilesAgentList'
import {useProfilesAgentsGridSortStore} from 'src/stores/Profiles/Agents/useProfilesAgentsGridSortStore'

export const useAgentsListStore = defineStore('profiles-agents-list', () => {
  const {
    loading,
    get,
    errors,
    error,
    status,
    response,
  } = useGetApi<AgentsListResponseData>()

  const selected = ref([])

  const {
    pagination,
    gridPagination,
    perPageOptions,
    getFromStorage,
    setPagination,
    onRequestPagination,
    setPage,
  } = useGridPagination('profiles-agents-list')

  const {
    sorting,
  } = storeToRefs(useProfilesAgentsGridSortStore())

  const filtersStore = useAgentsListFiltersStore()
  const {getFiltersQuery} = filtersStore

  const items = ref(<ProfilesAgentList[]>[])

  /*const getFullName = (item: ProfilesAgentList): string => {
    return `${item.name_first || ''} ${item.name_middle || ''} ${item.name_last || ''}`
  }*/

  const load = async () => {
    await getFromStorage()
    await get(
      'admin/agent/list',
      {
        page: pagination.value?.page,
        perpage: pagination.value?.per_page,
        ...sorting.value,
        ...getFiltersQuery()
      }
    )

    if (status.value === 200 && response && response.value?.items) {
      items.value = response.value?.items
      setPagination(response.value?.pagination)
    }
  }

  return {
    loading,
    get,
    errors,
    error,
    items,
    pagination,
    gridPagination,
    perPageOptions,
    selected,
    onRequestPagination,
    load,
    setPage,
  }
})
