import {computed, ref} from 'vue'
import {usePostApi} from 'src/uses/Api/usePostApi'
import {ChatTypeEnum} from 'src/models/ChatTypeEnum'
import {ApplicationSubStatusEnum} from 'src/models/Applications/ApplicationSubStatusEnum'
import {storeToRefs} from 'pinia'
import {useApplicationControlCenterStore} from 'src/stores/Applications/ControlCenter/useApplicationControlCenterStore'
import {useProfileStore} from 'src/stores/Login/useProfileStore'
import {
  useApplicationControlCenterChangeStatusStore
} from 'src/stores/Applications/ControlCenter/ChangeStatus/useApplicationControlCenterChangeStatusStore'
import {ApplicationStatusEnum} from 'src/models/Applications/ApplicationStatusEnum'

export interface SendMessage {
  text: string,
  chat: string,
}

export const useControlCenterChatSendMessage = (id: string, type: ChatTypeEnum) => {
  const AGENT_CONTROL_CENTER_SEND_MESSAGES_URL = 'v1/control-center'

  const applicationControlCenterStore = useApplicationControlCenterStore(id)()
  const {
    headerData,
  } = storeToRefs(applicationControlCenterStore)

  const {
    changeStatus,
    requestFulfilledInitiator,
    requestFulfilledStatus,
  } = useApplicationControlCenterChangeStatusStore(id)()

  const {
    isBankEmployee,
  } = useProfileStore()

  const {
    loading,
    post,
    status,
    response,
  } = usePostApi<SendMessage, SendMessage>()

  const message = ref<string>('')
  const success = ref<boolean>(false)

  const canRequestCompletedToBankChat = computed(() => {
    if (!headerData.value) {
      return false
    }

    if (headerData.value.need_guarantors) {
      return false
    }

    return [
      ApplicationSubStatusEnum.data_request,
      ApplicationSubStatusEnum.risks_request,
      ApplicationSubStatusEnum.bg_project_bank_agreement_request,
      ApplicationSubStatusEnum.deferred_conditions_execution_waiting,
      ApplicationSubStatusEnum.guarantee_released_request,
      ApplicationSubStatusEnum.bg_project_customer_agreement,
    ].includes(headerData.value.sub_status as ApplicationSubStatusEnum)
  })

  const needPlatformChatChangeStatus = computed(() => {
    if (!headerData.value) {
      return false
    }

    return type === ChatTypeEnum.platform
      && headerData.value.new_request === true
      && headerData.value.sub_status === ApplicationSubStatusEnum.completion
  })

  const needBankChatChangeStatus = computed(() => {
    return type === ChatTypeEnum.bank
      && !isBankEmployee()
      && canRequestCompletedToBankChat.value
  })

  const sendMessages = async () => {
    if (!message.value || !type || !headerData.value) {
      return
    }

    const chatMessage: SendMessage = {
      text: message.value,
      chat: type
    }
    await post(`${AGENT_CONTROL_CENTER_SEND_MESSAGES_URL}/${id}/messages`, chatMessage)

    if (status.value === 201 && response.value) {
      loading.value = true
      if (needPlatformChatChangeStatus.value) {
        await changeStatus(
          {
            id,
            applicationStatus: ApplicationSubStatusEnum.checking,
          },
          headerData.value.sub_status as ApplicationSubStatusEnum
        )
      }

      const newSubStatus = requestFulfilledStatus(
        headerData.value.status as ApplicationStatusEnum,
        headerData.value.sub_status as ApplicationSubStatusEnum
      )
      if (needBankChatChangeStatus.value && newSubStatus) {
        await changeStatus(
          {
            id,
            applicationStatus: newSubStatus,
            initiator: (
              ApplicationSubStatusEnum.bg_project_bank_agreement === newSubStatus
              && headerData.value.sub_status === ApplicationSubStatusEnum.bg_project_customer_agreement
            )
              ? 'align_risks'
              : requestFulfilledInitiator(headerData.value.sub_status as ApplicationSubStatusEnum),
            from_chat: true,
            comment: message.value,
            withoutMessage: true,
          },
          headerData.value.sub_status as ApplicationSubStatusEnum
        )
      }

      success.value = true
      message.value = ''
      loading.value = false
    }
  }

  return {
    loading,
    message,
    canRequestCompletedToBankChat,
    needPlatformChatChangeStatus,
    needBankChatChangeStatus,
    sendMessages,
  }
}
