import {DocumentStatusEnum} from 'src/models/Enums/DocumentStatusEnum'

const statuses: Record<DocumentStatusEnum, string> = {
  [DocumentStatusEnum.NoSign]: 'Не подписан',
  [DocumentStatusEnum.IsChecking]: 'Проверка документов',
  [DocumentStatusEnum.Revision]: 'На доработке',
  [DocumentStatusEnum.Signed]: 'Подписано',
  [DocumentStatusEnum.WaitOriginal]: 'Ожидается оригинал',
}

const statusesColors: Record<DocumentStatusEnum, string> = {
  [DocumentStatusEnum.NoSign]: 'red',
  [DocumentStatusEnum.IsChecking]: 'black',
  [DocumentStatusEnum.Revision]: 'black',
  [DocumentStatusEnum.Signed]: '#00C853',
  [DocumentStatusEnum.WaitOriginal]: 'red',
}

const statusesIcons: Record<DocumentStatusEnum, string> = {
  [DocumentStatusEnum.NoSign]: 'svguse:icons.svg?2#app-exclamation-circle-bold',
  [DocumentStatusEnum.IsChecking]: 'svguse:icons.svg?2#app-exclamation-circle-bold',
  [DocumentStatusEnum.Revision]: 'svguse:icons.svg?2#app-exclamation-circle-bold',
  [DocumentStatusEnum.Signed]: 'svguse:icons.svg?2#app-lock-closed',
  [DocumentStatusEnum.WaitOriginal]: 'svguse:icons.svg?2#app-exclamation-circle-bold',
}

export class DocumentStatuses {
  public static getDocumentStatusDescription (status: DocumentStatusEnum): string {
    return statuses[status] || status
  }

  public static getDocumentStatusColor (status: DocumentStatusEnum): string {
    return statusesColors[status] || 'black'
  }

  public static getDocumentStatusIcon (status: DocumentStatusEnum): string {
    return statusesIcons[status] || 'black'
  }
}
