<template>
  <div
    v-if="isAgentManager() || isAdmin()"
    class="q-py-sm q-px-md bg-white row"
  >
    <q-input
      ref="inputRef"
      v-model="email"
      class="field-with-top-label col"
      label="Почта для уведомлений по заявке"
      :disable="!(isAgentManager() || isAdmin()) || loading"
      :loading="loading"
      outlined
      hide-bottom-space
      dense
    />
    <div
      v-if="modified"
      style="padding-top: 40px"
    >
      <q-btn
        class="q-ml-xs"
        icon="svguse:icons.svg?2#app-check"
        size="10px"
        color="green-14"
        :disable="loading"
        round
        unelevated
        flat
        @click="confirm"
      />
      <q-btn
        class="q-ml-xs"
        icon="svguse:icons.svg?2#app-x"
        size="10px"
        color="red-12"
        :disable="loading"
        round
        unelevated
        flat
        @click="cancel"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import {ApplicationsGridItem} from 'src/models/Applications/ApplicationsGridItem'
import {watchEffect, computed, ref} from 'vue'
import {useProfileStore} from 'src/stores/Login/useProfileStore'
import {useNotificationEmailUpdate} from 'src/uses/Applications/useNotificationEmailUpdate'

const props = defineProps<{
  item: ApplicationsGridItem,
}>()

const emit = defineEmits<{
  (e: 'update:modelValue', value: number): void,
  (e: 'change'): void,
  (e: 'changed'): void,
}>()

const {
  isAgentManager,
  isAdmin,
} = useProfileStore()

const {
  status,
  loading,
  update,
} = useNotificationEmailUpdate()

const email = ref<string>('')

const modified = computed(() => {
  if (!props.item.additional_information) {
    return false
  }
  return email.value !== (props.item.additional_information.email || '')
})

const confirm = async () => {
  await update(props.item.id, email.value || '')

  if (status.value === 200) {
    emit('changed')
  }
}

const cancel = () => {
  email.value = props.item.additional_information?.email || ''
}

watchEffect(() => {
  email.value = props.item.additional_information?.email || ''
})
</script>

<style scoped lang="scss">

</style>
