import {defineStore} from 'pinia'
import {ref} from 'vue'
import {useDeleteApi} from 'src/uses/Api/useDeleteApi'

export const useListingDeleteStore = defineStore('listing-delete', () => {
  const {
    deleteRequest,
    response,
    status,
    loading,
    error,
    errors,
  } = useDeleteApi<never>()

  const success = ref(false)

  const deleteListings = async (id: string) => {
    await deleteRequest(`v2/listings/${id}`)
    if (status.value === 200 && response.value) {
      success.value = true
    }
  }

  return {
    loading,
    response,
    status,
    error,
    errors,
    success,
    deleteListings,
  }
})
