<template>
  <div class="key-value-list-item q-py-sm q-px-md">
    <div class="row">
      <div class="col-6">
        <slot name="key" />
      </div>
      <div class="col-6">
        <slot name="default" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.key-value-list-item {
  color: $grey-7;
  background: #ffffff;
  border-bottom: 1px solid $blue-grey-1;

  &:hover {
    //background: $grey-2;
  }
}
</style>
