import {defineStore, storeToRefs} from 'pinia'
import {useApplicationFormFillMain} from 'src/uses/Applications/Form/fill/useApplicationFormFillMain'
import {useApplicationFormFillFinance} from 'src/uses/Applications/Form/fill/useApplicationFormFillFinance'
import {ApplicationItemSectionsEnum} from 'src/models/Enums/ApplicationItemSectionsEnum'
import {FillStatusEnum} from 'src/models/Enums/FillStatusEnum'
import {useApplicationFormFillClient} from 'src/uses/Applications/Form/fill/useApplicationFormFillClient'
import {useApplicationFormFillDocuments} from 'src/uses/Applications/Form/fill/useApplicationFormFillDocuments'
import {computed, ref, watch} from 'vue'
import {ApplicationSubStatusEnum} from 'src/models/Applications/ApplicationSubStatusEnum'
import {useApplicationItemEbgStore} from 'stores/Applications/Item/useApplicationItemEbgStore'

export const useApplicationItemEbgFillStore = (multiOrderId: number) => defineStore(`application-item-ebg-fill-${multiOrderId}`, () => {
  const applicationItemStore = useApplicationItemEbgStore(multiOrderId)()
  const {
    banks,
    mainVisibleFields,
    financeVisible,
    client,
    visibleDocuments,
  } = storeToRefs(applicationItemStore)

  const {
    countAllMainFields,
    countErrorsMainFields,
    fillMainByBanks,
    mainErrorFields,
  } = useApplicationFormFillMain(mainVisibleFields, banks)

  const {
    financeFieldsHasErrors,
    periodsWithErrors,
    countAllFinanceFields,
    countErrorsFinanceFields,
    fillFinanceByBanks,
    financeErrorFields,
  } = useApplicationFormFillFinance(financeVisible, banks)

  const {
    clientHasErrors,
    clientHeadFieldsWithErrors,
    clientCommonFieldsWithErrors,
    clientFoundersFieldsWithErrors,
    countAllClientFields,
    countErrorsClientFields,
    fillClientByBanks,
    founderHasError,
  } = useApplicationFormFillClient(client, banks)

  const {
    documentsHasErrors,
    countAllDocumentsFields,
    countErrorsDocumentsFields,
    fillDocumentsByBanks,
    passportsDocumentsErrorsFields,
    financialDocumentsErrorsFields,
    additionalDocumentsErrorsFields,
  } = useApplicationFormFillDocuments(visibleDocuments, banks)

  const tabsFillingStatus = ref<Record<string, FillStatusEnum>>({
    main: FillStatusEnum.IN_PROGRESS,
    client: FillStatusEnum.IN_PROGRESS,
    financial: FillStatusEnum.IN_PROGRESS,
    documents: FillStatusEnum.IN_PROGRESS,
  })

  const awaitTimeout = () => {
    return new Promise((resolve) => setTimeout(resolve, 1))
  }

  const getSectionFillStatus = async (name: ApplicationItemSectionsEnum): Promise<FillStatusEnum> => {
    await awaitTimeout()
    if (name === ApplicationItemSectionsEnum.MAIN) {
      tabsFillingStatus.value.main = countErrorsMainFields.value > 0
        ? FillStatusEnum.IN_PROGRESS
        : FillStatusEnum.OK

      return tabsFillingStatus.value.main
    }

    if (name === ApplicationItemSectionsEnum.FINANCIAL) {
      tabsFillingStatus.value.financial = financeFieldsHasErrors.value ? FillStatusEnum.IN_PROGRESS : FillStatusEnum.OK

      return tabsFillingStatus.value.financial
    }

    if (name === ApplicationItemSectionsEnum.CLIENT) {
      tabsFillingStatus.value.client = clientHasErrors.value ? FillStatusEnum.IN_PROGRESS : FillStatusEnum.OK

      return tabsFillingStatus.value.client
    }

    if (name === ApplicationItemSectionsEnum.DOCUMENTS) {
      tabsFillingStatus.value.documents = documentsHasErrors.value ? FillStatusEnum.IN_PROGRESS : FillStatusEnum.OK

      return tabsFillingStatus.value.documents
    }

    return FillStatusEnum.IN_PROGRESS
  }

  const fillPercent = computed(() => {
    const all = countAllMainFields.value
      + countAllFinanceFields.value
      + countAllClientFields.value
      + countAllDocumentsFields.value

    const errors = countErrorsMainFields.value
      + countErrorsFinanceFields.value
      + countErrorsClientFields.value
      + countErrorsDocumentsFields.value

    const filled = all - errors

    return Math.floor(filled / all * 100)
  })

  const fillByBanks = computed(() => {
    return banks.value
      .map(bank => {
        const mainBank = fillMainByBanks.value
          .find(b => b.bank_id === bank.bank_id)
        const financeBank = fillFinanceByBanks.value
          .find(b => b.bank_id === bank.bank_id)
        const documentsBank = fillDocumentsByBanks.value
          .find(b => b.bank_id === bank.bank_id)
        const clientBank = fillClientByBanks.value
          .find(b => b.bank_id === bank.bank_id)

        return {
          ...bank,
          total: (mainBank ? mainBank.total : 0)
            + (financeBank ? financeBank.total : 0)
            + (documentsBank ? documentsBank.total : 0)
            + (clientBank ? clientBank.total : 0),
          error: (mainBank ? mainBank.error : 0)
            + (financeBank ? financeBank.error : 0)
            + (documentsBank ? documentsBank.error : 0)
            + (clientBank ? clientBank.error : 0),
        }
      })
  })

  const atLeastOneDraftBankIsOk = computed(() => {
    const draftBanks = fillByBanks.value
      .filter(b => b.sub_status === ApplicationSubStatusEnum.draft)
    const okBanks = draftBanks
      .filter(b => b.error === 0)

    return okBanks.length && okBanks.length !== draftBanks.length
  })

  const allDraftBankIsOk = computed(() => {
    const draftBanks = fillByBanks.value
      .filter(b => b.sub_status === ApplicationSubStatusEnum.draft)
    const okBanks = draftBanks
      .filter(b => b.error === 0)

    return okBanks.length && okBanks.length === draftBanks.length
  })

  watch(
    countErrorsMainFields,
    () => getSectionFillStatus(ApplicationItemSectionsEnum.MAIN),
    {immediate: true}
  )
  watch(
    countErrorsFinanceFields,
    () => getSectionFillStatus(ApplicationItemSectionsEnum.FINANCIAL),
    {immediate: true}
  )
  watch(
    countErrorsClientFields,
    () => getSectionFillStatus(ApplicationItemSectionsEnum.CLIENT),
    {immediate: true}
  )
  watch(
    countErrorsDocumentsFields,
    () => getSectionFillStatus(ApplicationItemSectionsEnum.DOCUMENTS),
    {immediate: true}
  )

  return {
    countAllMainFields,
    countErrorsMainFields,
    mainErrorFields,

    periodsWithErrors,
    countAllFinanceFields,
    countErrorsFinanceFields,
    financeErrorFields,

    clientHasErrors,
    clientHeadFieldsWithErrors,
    clientCommonFieldsWithErrors,
    clientFoundersFieldsWithErrors,
    countAllClientFields,
    countErrorsClientFields,
    founderHasError,

    documentsHasErrors,
    countAllDocumentsFields,
    countErrorsDocumentsFields,
    passportsDocumentsErrorsFields,
    financialDocumentsErrorsFields,
    additionalDocumentsErrorsFields,

    getSectionFillStatus,
    fillPercent,
    fillByBanks,

    atLeastOneDraftBankIsOk,
    allDraftBankIsOk,

    tabsFillingStatus,
  }
})
