import {GridColumnEditableSettings} from 'src/models/Grid/GridColumnSettings'
export const ListingsItemRecordsGridColumns: GridColumnEditableSettings[] = [
  {
    field: '',
    name: 'id',
    required: true,
    label: 'Номер',
    align: 'left',
    classes: 'text-no-wrap',
    visible: true,
    editable: true,
    order: 1,
    sortable: true,
  },
  {
    field: '',
    name: 'key',
    required: true,
    label: 'Ключ',
    align: 'left',
    classes: 'text-no-wrap',
    visible: true,
    editable: true,
    order: 2,
    sortable: true,
  },
  {
    field: '',
    name: 'value',
    required: true,
    label: 'Значение',
    align: 'left',
    classes: 'text-no-wrap',
    visible: true,
    editable: true,
    order: 3,
    sortable: true,
  },
  {
    field: '',
    name: 'actions',
    label: '',
    required: true,
    align: 'center',
    visible: true,
    editable: false,
    order: 4,
  },
]
