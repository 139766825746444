<template>
  <q-btn
    size="md"
    style="width: 100%;"
    color="blue-grey-4"
    icon="svguse:icons.svg?2#app-chevron-down"
    flat
    @click="open"
  />
  <q-dialog
    v-model="dialog"
    position="bottom"
  >
    <q-card class="reportFiltersInfoModal">
      <q-card-section >
        <div class="q-mb-sm">
          <div class="item-title q-mr-xs">Банки:</div>
          <template v-if="!props.item.filter.banks || props.item.filter.banks.length === 0">
            Все
          </template>
          <template v-else>
            <span
              v-for="bank in props.item.filter.banks"
              :key="JSON.stringify(bank)"
            >
              <img
                :alt="bank.title_short"
                :src="bank.logo as string"
                style="max-height: 26px;line-height: 12px"
                class="q-mr-xs"
              >
            </span>
          </template>
        </div>
        <div class="q-mb-sm">
          <div class="item-title q-mr-xs">Сотрудники:</div>
          <template v-if="!props.item.filter?.employees || props.item.filter.employees.length === 0">
            Все
          </template>
          <template v-else>
                <span
                  v-for="(employee, index) in props.item.filter.employees"
                  :key="employee.name_full"
                >
                {{ employee.name_full }}<i v-if="props.item.filter.employees.length > index + 1">, </i>
              </span>
          </template>
        </div>
        <div class="q-mb-sm">
          <div class="item-title q-mr-xs">Статусы:</div>
          <template v-if="!props.item.filter?.statuses || props.item.filter.statuses.length === 0">
            Все
          </template>
          <template v-else>
            <span
              v-for="(status) in props.item.filter.statuses"
              :key="status"
            >
              {{ label(status) }},
            </span>
          </template>
        </div>
        <div class="q-mb-sm">
          <div class="item-title q-mr-xs">Дата создания заявок с:</div>
          {{ props.item.filter?.date_from }}
        </div>
        <div>
          <div class="item-title q-mr-xs">Дата создания заявок по:</div>
          {{ props.item.filter?.date_to }}
        </div>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
import {useDialog} from 'src/uses/Dialogs/useDialog'
import {ReportsGridItem} from 'src/models/Profile/Reports/ReportsGridItem'
import {useStatus} from 'src/uses/Applications/useStatus'

const props = defineProps<{
  item: ReportsGridItem
}>()

const {
  label,
} = useStatus()

const {dialog, show} = useDialog()
const open = async () => {
  if (!props.item.filter) {
    return
  }
  show()
}
</script>
<style lang="scss">
.reportFiltersInfoModal {
  .item-title {
    color: #9E9E9E;
  }
}
</style>
