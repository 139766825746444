<template>
  <div :class="{ApplicationGridStatuses: true, row: true, 'no-wrap': $q.platform.is.mobile, 'overflow-auto': true, 'q-col-gutter-sm': true}">
    <span
      v-for="status in filterStatuses"
      :key="status"
      class="text-no-wrap"
    >
      <q-btn
        :color="background(status)"
        :class="`text-${isActive(status) || isHovered(status) ? color(status) : 'blue-grey-4'}`"
        padding="7px 10px"
        size="12px"
        unelevated
        no-caps
        @mouseover="onOver(status)"
        @mouseout="onOut"
        @click="onClick(status)"
      >
        <q-icon
          :name="icon(status)"
          size="18px"
          class="q-mr-xs"
        />
        {{ filterLabel(status) }}
      </q-btn>
    </span>
  </div>
</template>

<script setup lang="ts">
import {ref} from 'vue'
import {storeToRefs} from 'pinia'
import {useQuasar} from 'quasar'
import {ApplicationKikStatusEnum} from 'src/models/Applications/ApplicationKikStatusEnum'
import {useApplicationsGridKikFiltersStore} from 'stores/Applications/Grid/useApplicationsGridKikFiltersStore'
import {useFilterKikStatus} from 'src/uses/Applications/useFilterKikStatus'

const emit = defineEmits<{
  (e: 'update:filters'): void,
}>()

const {
  filterStatuses,
  filterLabel,
  color,
  bgColor,
  icon,
} = useFilterKikStatus()

const hoveredStatus = ref<ApplicationKikStatusEnum>()

const filtersStore = useApplicationsGridKikFiltersStore()
const {filters} = storeToRefs(filtersStore)

const $q = useQuasar()

const background = (status: ApplicationKikStatusEnum) => {
  if ($q.platform.is.mobile) {
    return isActive(status) || isHovered(status)
      ? 'white'
      : 'transparent'
  }

  return isActive(status) || isHovered(status) ? bgColor(status) : 'transparent'
}

const isActive = (status: ApplicationKikStatusEnum) => {
  return filters.value.status === status
    || (status === ApplicationKikStatusEnum.all && filters.value.status === null)
}

const isHovered = (status: ApplicationKikStatusEnum) => {
  return hoveredStatus.value === status
}

const onOver = (status: ApplicationKikStatusEnum) => {
  hoveredStatus.value = status
}

const onOut = () => {
  hoveredStatus.value = undefined
}

const onClick = (status: ApplicationKikStatusEnum) => {
  filters.value.status = status === ApplicationKikStatusEnum.all ? null : status
  emit('update:filters')
}
</script>

<style lang="scss">
.ApplicationGridStatuses {
  .q-btn {
    .q-focus-helper {
      display: none !important;
    }
  }

  .q-btn__content {
    flex-wrap: nowrap;
  }
}
</style>
