import {useGetApi} from 'src/uses/Api/useGetApi'
import {ref} from 'vue'
import {
  AgentPortalData,
} from 'src/models/Profiles/Agents/AgentPortalData'

export const useAgentGetPortalData = () => {
  const {
    get,
    response,
    status,
    loading,
    error,
    errors,
  } = useGetApi<AgentPortalData>()

  const data = ref<AgentPortalData>()

  const load = async () => {
    await get('v1/agent/sign-portal')
    if (status.value === 200 && response && response.value) {
      data.value = response.value
    }
  }

  return {
    data,
    loading,
    response,
    status,
    error,
    errors,
    load,
  }
}
