import {defineStore, storeToRefs} from 'pinia'
import {useBgForm} from 'src/uses/Applications/useBgForm'
import {ref, computed, Ref} from 'vue'
import DeliveryData, {DeliveryDataReceiverEnum} from 'src/models/Applications/DeliveryData'
import {required} from 'src/utils/i18n-validators'
import useVuelidate from '@vuelidate/core'
import {useHeaderDataFromStore} from 'src/uses/Applications/ControlCenter/useHeaderDataFromStore'
import {useDeliveryReceiver} from 'src/uses/Applications/useDeliveryReceiver'
import {usePostApi} from 'src/uses/Api/usePostApi'
import {useImportantFields} from 'src/uses/Applications/Form/useImportantFields'
import {
  useApplicationControlCenterItemStore
} from 'src/stores/Applications/ControlCenter/useApplicationControlCenterItemStore'

export const useDeliveryAddressFormStore = (appId: string) => {
  return defineStore(`application-delivery-address-form-${appId}`, () => {
    const applicationControlCenterItemStore = useApplicationControlCenterItemStore(appId)()
    const {
      updateMainFields,
      load,
    } = applicationControlCenterItemStore
    const {
      item,
      banks,
    } = storeToRefs(applicationControlCenterItemStore)

    const {
      getHeaderData
    } = useHeaderDataFromStore()

    const {
      issueFormField,
      issueMailHardCopyField,
    } = useImportantFields(item)

    const {
      options,
      getReceiverLabel
    } = useDeliveryReceiver()

    const {
      post,
      response,
      status,
      loading,
      error,
      errors,
    } = usePostApi<DeliveryData, DeliveryData>()

    const {
      options: bgForms,
    } = useBgForm()

    const deliveryAddressData = ref<DeliveryData>({
      address: '',
      name: '',
      phone: '',
      receiver: '',
      comment: '',
    })

    const success = ref(false)

    const receiverOptions = ref<{ label: string, value: string | number }[]>([])

    const rules = computed(() => {
      if (!(issueMailHardCopyField.value?.value as never as boolean)) {
        return {
          address: {},
          name: {},
          phone: {},
          receiver: {},
        }
      }

      return {
        address: {
          required,
        },
        name: {
          required,
        },
        phone: {
          required,
        },
        receiver: {
          required,
        },
      }
    })

    const validation = useVuelidate<Ref<DeliveryData>>(rules, deliveryAddressData)

    const sendDeliveryAddress = async () => {
      if (!await validation.value.$validate()) {
        return
      }

      await post(`v1/control-center/${appId}/delivery-data`, deliveryAddressData.value)
      if (status.value === 200 && response.value) {
        success.value = true
      }
    }

    const init = async () => {
      const headerData = await getHeaderData(appId)
      if (headerData?.client.delivery_data) {
        deliveryAddressData.value = {
          ...headerData?.client.delivery_data
        }
      }

      receiverOptions.value = (options || [])
        .filter(opt => {
          if (headerData?.bank.inn === '7744001497') {
            return opt.value !== DeliveryDataReceiverEnum.other
          }

          return true
        })

      if (!item.value) {
        await load()
      }
    }

    return {
      bgForms,
      deliveryAddressData,
      loading,
      error,
      errors,
      init,
      sendDeliveryAddress,
      receiverOptions,
      validation,
      success,
      getReceiverLabel,
      updateMainFields,
      item,
      banks,
      issueFormField,
      issueMailHardCopyField,
      rules,
    }
  })
}
