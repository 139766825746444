<template>
  <div class="row q-mt-md q-mb-sm">
    <div class="title">Платежные реквизиты</div>
  </div>
  <div
    v-if="agentData"
    class="row q-col-gutter-md"
  >
    <div class="col-4 col-xs-12 white-bg-field q-mb-md">
      <q-input
        v-model="agentData.bik"
        class="field-with-top-label"
        :loading="loading || bikLoading"
        :error-message="getErrorMessage('bik')"
        :error="hasError('bik')"
        :disable="props.disableRequisitesChanging || $q.platform.is.mobile"
        mask="#########"
        maxlength="9"
        counter
        label="БИК"
        outlined
        hide-bottom-space
        dense
        @blur="onBikBlur(); validation.bik.$validate()"
        @focus="clearError('bik')"
      />
    </div>
    <div class="col-4 col-xs-12 white-bg-field q-mb-md">
      <q-input
        v-model="agentData.payment_account"
        class="field-with-top-label"
        :loading="loading || bikLoading"
        :disable="props.disableRequisitesChanging || $q.platform.is.mobile"
        :error-message="getErrorMessage('paymentAccount')"
        :error="hasError('paymentAccount')"
        mask="####################"
        maxlength="20"
        counter
        label="Расчетный счет"
        outlined
        hide-bottom-space
        dense
        @blur="validation.paymentAccount.$validate()"
        @focus="clearError('paymentAccount')"
      />
    </div>
    <div class="col-4 col-xs-12 white-bg-field q-mb-md">
      <q-input
        v-model="agentData.correspondent_account"
        class="field-with-top-label"
        :loading="loading || bikLoading"
        :error-message="getErrorMessage('correspondent_account')"
        :error="hasError('correspondent_account')"
        :disable="props.disableRequisitesChanging || $q.platform.is.mobile"
        mask="####################"
        maxlength="20"
        counter
        label="Корр. счет"
        outlined
        hide-bottom-space
        dense
        @blur="validation.correspondent_account.$validate()"
        @focus="clearError('correspondent_account')"
      />
    </div>
    <div class="col-8 col-xs-12 white-bg-field q-mb-md">
      <q-input
        v-model="agentData.bank_title"
        class="field-with-top-label"
        :loading="loading"
        :disable="props.disableRequisitesChanging || $q.platform.is.mobile"
        :error-message="getErrorMessage('bank_title')"
        :error="hasError('bank_title')"
        label="Название банка"
        outlined
        hide-bottom-space
        dense
        @blur="validation.bank_title.$validate()"
        @focus="clearError('bank_title')"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import {useProfileRequisitesCompanyStore} from 'src/stores/Profile/Requisites/useProfileRequisitesCompanyStore'
import {useAgentCompanyEditStore} from 'src/stores/Profiles/Agents/useAgentCompanyEditStore'
import {storeToRefs} from 'pinia'
import {useValidation} from 'src/uses/useValidation'
import {useDadataBik} from 'src/uses/Dadata/useDadataBik'
import {useProfileRequisitesPhysicalStore} from 'src/stores/Profile/Requisites/useProfileRequisitesPhysicalStore'
import {useAgentPhysicalEditStore} from 'src/stores/Profiles/Agents/useAgentPhysicalEditStore'
import {useProfileRequisitesIndividualStore} from 'src/stores/Profile/Requisites/useProfileRequisitesIndividualStore'
import {useAgentIndividualEditStore} from 'src/stores/Profiles/Agents/useAgentIndividualEditStore'
import {useQuasar} from 'quasar'

const props = defineProps<{
  store: typeof useProfileRequisitesCompanyStore
    | typeof useAgentCompanyEditStore
    | typeof useProfileRequisitesPhysicalStore
    | typeof useAgentPhysicalEditStore
    | typeof useProfileRequisitesIndividualStore
    | typeof useAgentIndividualEditStore,
  disableRequisitesChanging: boolean,
}>()

const $q = useQuasar()

const requisitesCompanyStore = props.store()

const {
  agentData,
  validation,
  errors,
  loading,
} = storeToRefs(requisitesCompanyStore)

const {
  getErrorMessage,
  hasError,
  clearError
} = useValidation(validation, errors)

const {
  load,
  dadataBank,
  loading: bikLoading,
} = useDadataBik()

const onBikBlur = async () => {
  if (!agentData.value) {
    return false
  }

  await load(agentData.value.bik)
  if (dadataBank.value) {
    agentData.value.bik = dadataBank.value?.bic || ''
    agentData.value.correspondent_account = dadataBank.value?.correspondent_account || ''
    agentData.value.bank_title = dadataBank.value?.name.payment || ''
  }
}
</script>

<style scoped lang="scss">

</style>
