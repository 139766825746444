import {Ref, ref, watch} from 'vue'
import {useGetApi} from 'src/uses/Api/useGetApi'
import {ApplicationNewInnResponse} from 'src/models/Applications/ApplicationNewInnResponse'
import {useApplicationsNewInnClientData} from 'src/stores/Applications/ApplicationsNew/useApplicationsNewInnClientData'
import {ApplicationNewAgent} from 'src/models/Applications/ApplicationNewAgentsResponse'
import {useRoute} from 'vue-router'

export const useApplicationsNewInn = (agent: Ref<ApplicationNewAgent | undefined>) => {
  const {
    loading,
    get,
    status,
    response,
  } = useGetApi<ApplicationNewInnResponse[]>()

  const {
    clientData,
    productsData,
    loading: loadingClientData,
    load: loadClientData,
  } = useApplicationsNewInnClientData()

  const route = useRoute()

  const items = ref<ApplicationNewInnResponse[]>([])
  const selected = ref<ApplicationNewInnResponse | null>(null)

  const load = async (query = '') => {
    await get(
      'clients-inn',
      {
        query,
        agent_id: agent.value ? agent.value.id : null,
      })

    if (status.value === 200 && response.value) {
      items.value = response.value
    }
  }

  watch(
    selected,
    () => {
      if (!selected.value) {
        return
      }

      loadClientData(selected.value.inn.toString())
    }
  )

  watch(
    items,
    () => {
      if (!route.query?.inn || items.value.length === 0) {
        return
      }

      const founded = items.value
        .find(i => i.inn === route.query?.inn)

      if (!founded) {
        return
      }

      selected.value = founded
    }
  )

  return {
    items,
    loading,
    loadingClientData,
    load,
    selected,
    clientData,
    productsData,
  }
}
