import {ProductEnum} from 'src/models/Applications/ProductEnum'
import {ApplicationGridKikFilters} from 'src/models/Applications/ApplicationGridKikFilters'

export const applicationGridKikDefaultFilters: ApplicationGridKikFilters = {
  new_request: false,
  number: null,
  product: ProductEnum.KIK,
  status: null,
  client: null,
  banks: [],
  agent: null,
}

export const applicationGridKikDefaultPins: Record<string, boolean> = {
  new_request: true,
  number: true,
  product: true,
  status: true,
  client: true,
  banks: true,
  agent: false,
}
