<template>
  <page-block-wrapper>
    <div class="row top q-pa-md bg-grey-3">
      <div class="col-6">
        <div class="row q-col-gutter-sm">
          <div>
            <q-select
              v-model="size"
              :options="sizes"
              bg-color="white"
              outlined
              dense
            />
          </div>
          <div class="col">
            <q-input
              v-model="search"
              bg-color="white"
              outlined
              dense
              clearable
            />
          </div>
        </div>
      </div>
      <div
        v-if="active"
        class="col-6 q-pa-sm"
      >
        <span>
          name: <strong>{{ active }}</strong>
        </span>
        <copy-to-clipboard :text="`svguse:icons.svg?2#${active}`">
          <q-btn
            class="q-ml-sm"
            icon="svguse:icons.svg?2#app-document-duplicate"
            size="xs"
            color="positive"
            round
          />
        </copy-to-clipboard>
        <q-btn
          class="q-ml-sm"
          icon="svguse:icons.svg?2#app-x"
          size="xs"
          color="red"
          round
          @click="active = ''"
        />
      </div>
    </div>
    <div class="q-pa-md">
      <div :class="`q-gutter-md demo-size-${size}`">
        <span
          v-for="icon in filteredIcons"
          :key="icon"
          class="demo-icon"
        >
          <q-btn
            :class="{'text-black': active !== icon, 'text-white': active === icon}"
            :color="active === icon ? 'positive' : 'white'"
            square
            stack
            unelevated
            dense
            @click="onClick(icon)"
          >
            <q-icon
              :name="`svguse:icons.svg?2#${icon}`"
              :size="size"
            />
          </q-btn>
          <br>
          <span class="demo-icon-name ellipsis inline-block">
            {{ removeAppPrefix(icon) }}
          </span>
        </span>
      </div>
    </div>
  </page-block-wrapper>
</template>

<script setup lang="ts">
import {computed, ref} from 'vue'
import {useQuasar} from 'quasar'
import CopyToClipboard from 'src/components/CopyToClipboard.vue'
import PageBlockWrapper from 'src/components/PageBlockWrapper.vue'

const icons = ref<string[]>([])
const search = ref('')
const active = ref('')
const size = ref('')

const $q = useQuasar()
const sizes = Object.keys($q.screen.sizes)
size.value = sizes[sizes.length - 1] || ''

const filteredIcons = computed(() => {
  if (!search.value) {
    return icons.value
  }

  return icons.value.filter(i => i.toString().toLowerCase().includes(search.value.toLowerCase()))
})

const getIcons = () => {
  icons.value = []
  fetch('/icons.svg')
    .then(response => response.text())
    .then((data) => {
      const result = [...data.matchAll(/id="([-\w\d]+)"/gi)]
        .map(result => result[1])

      icons.value = filterUnique(
        [
          ...icons.value,
          ...result
        ])
        .sort()
    })
}

const filterUnique = (icons: string[]) => {
  return icons
    .filter((name, index) => icons.findIndex(icon => icon === name) === index)
}

const removeAppPrefix = (icon: string): string => {
  return icon.replace(/^app-/gi, '')
}

const onClick = (icon: string) => {
  active.value = icon
}

getIcons()
</script>

<style lang="scss" scoped>
.top {
  position: sticky;
  top: 0;
  z-index: 9;
}

.demo-icon {
  display: inline-block;
  text-align: center;
}

.demo-size {
  &-sm {
    .demo-icon-name {
      width: 76px;
      font-size: 10px;
    }
  }
  &-md {
    .demo-icon-name {
      width: 84px;
      font-size: 12px;
    }
  }
  &-lg {
    .demo-icon-name {
      width: 90px;
      font-size: 14px;
    }
  }
  &-xl {
    .demo-icon-name {
      width: 98px;
      font-size: 14px;
    }
  }
}
</style>
