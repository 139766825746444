import {usePutApi} from 'src/uses/Api/usePutApi'
import {SetApplicationTariffPayload} from 'src/models/Applications/Tariff/SetApplicationTariffPayload'

export const useSetTariff = () => {
  const {
    loading,
    put,
    status,
    response,
  } = usePutApi<{message: 'success'}, SetApplicationTariffPayload>()

  const setTariff = async (id: string, data: SetApplicationTariffPayload) => {
    await put(
      `v1/control-center/${id}/tariff`,
      data
    )
  }

  return {
    loading,
    status,
    response,
    setTariff,
  }
}
