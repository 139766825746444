import {AuthedGuard} from 'src/router/quards/AuthedGuard'
import {UserRoleEnum} from 'src/models/User/UserRoleEnum'

export const profilesRouter = {
  path: '/profiles',
  component: () => import(/* webpackChunkName: "MainLayout" */ '../layouts/MainLayout.vue'),
  children: [
    {
      path: 'users',
      name: 'profiles-users',
      component: () => import(/* webpackChunkName: "UsersView" */ '../views/Profiles/UsersView.vue'),
      meta: {
        guards: [
          AuthedGuard([
            UserRoleEnum.AgentManager,
            UserRoleEnum.Admin,
            UserRoleEnum.JuniorAdmin,
            UserRoleEnum.MiddleAdmin,
          ]),
        ],
      },
    },
    {
      path: 'agents',
      name: 'profiles-agents',
      component: () => import(/* webpackChunkName: "AgentsView" */ '../views/Profiles/AgentsView.vue'),
      meta: {
        guards: [
          AuthedGuard([
            UserRoleEnum.AgentManager,
            UserRoleEnum.Admin,
            UserRoleEnum.JuniorAdmin,
            UserRoleEnum.MiddleAdmin,
          ]),
        ],
      },
    },
    {
      path: 'acts',
      name: 'profiles-acts',
      component: () => import(/* webpackChunkName: "ActsView" */ '../views/Profiles/ActsView.vue'),
      meta: {
        guards: [
          AuthedGuard([
            UserRoleEnum.AgentManager,
            UserRoleEnum.Admin,
            UserRoleEnum.JuniorAdmin,
            UserRoleEnum.MiddleAdmin,
          ]),
        ],
      },
    },
  ]
}
