<template>
  <file-select-component
    class="q-mb-sm"
    :can-upload="props.ignoreUploadedCheck || canBeUpload(props.documents)"
    :input-text="props.inputText"
    multiple
    @file:selected="onFileSelected"
  />
</template>

<script setup lang="ts">
import {withDefaults} from 'vue'
import {
  ApplicationFormDocumentGroupEnum,
  ApplicationFormDocument,
  ApplicationFormDocumentStatusEnum,
} from 'src/models/Applications/Form/ApplicationFormResponseData'
import {
  ApplicationFormUpdateBlockEnum,
} from 'src/models/Applications/Form/ApplicationFormUpdateResponse'
import {DocumentsCodesEnum} from 'src/models/DocumentsCodesEnum'
import {useCanDocumentBeUpload} from 'src/uses/Applications/Form/Documents/useCanDocumentBeUpload'
import FileSelectComponent from 'src/components/FileSelectComponent.vue'

const props = withDefaults(
  defineProps<{
    documentType: ApplicationFormDocumentGroupEnum,
    documents: ApplicationFormDocument[],
    ignoreUploadedCheck?: boolean,
    inputText?: string,
  }>(),
  {
    ignoreUploadedCheck: false,
    inputText: 'или перетащите файлы в эту область для загрузки'
  }
)

const emit = defineEmits<{
  (e: 'file:selected', file: File, document: ApplicationFormDocument): void,
}>()

const {
  canBeUpload,
} = useCanDocumentBeUpload()

const createDocument = () => {
  const code = props.documentType === ApplicationFormDocumentGroupEnum.financial
    ? DocumentsCodesEnum.ACCOUNTING_REPORTS
    : props.documentType === ApplicationFormDocumentGroupEnum.additional
      ? DocumentsCodesEnum.ADDED_DOCUMENT
      : ''

  return {
    location: {
      block: ApplicationFormUpdateBlockEnum.documents,
      section_type: props.documentType.toString(),
    },
    invisible: false,
    valid: [],
    code,
    description: null,
    file_date: null,
    file_name: null,
    file_path: null,
    file_status: ApplicationFormDocumentStatusEnum.new,
    founder_id: null,
    founder_passport_number: null,
    founder_passport_series: null,
    index: null,
    period_quarter: null,
    period_year: null,
    sign_file_name: null,
    sign_file_path: null,
    title: '',
    loading: false,
    readonly: false,
    sign_file_required: false,
    highlighted: false,
  }
}

const onFileSelected = (file: File) => {
  emit(
    'file:selected',
    file,
    createDocument(),
  )
}
</script>
