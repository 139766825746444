import {usePutApi} from 'src/uses/Api/usePutApi'
import {FormItemGeneratedDocumentListItem} from 'src/models/FormEditor/FormItemGeneratedDocumentListItem'

export const useFormItemGeneratedDocumentsUpdate = (formId: string) => {
  const {
    put,
    response,
    status,
    loading,
    error,
    errors,
  } = usePutApi<never, {id: string, documents: {id: string, generated: boolean}[]}>()

  const update = async (generatedDocuments: FormItemGeneratedDocumentListItem[]) => {
    await put(
      `v2/forms/${formId}/generated-documents`,
      {
        id: formId,
        documents: generatedDocuments
          .map(doc => ({
            id: doc.id,
            generated: !!doc.generated,
          }))
      }
    )
  }

  return {
    loading,
    response,
    status,
    error,
    errors,
    update,
  }
}
