<template>
  <q-input
    :key="value"
    v-model="value"
    disable
    :mask="props.field.mask ? props.field.mask.replaceAll('0', '#') : undefined"
    label-slot
    outlined
    hide-bottom-space
    dense
  >
    <template #label>
      <slot name="labelPrepend" />
      {{ props.label || props.field.title }}
      <slot name="labelAppend" />
    </template>
  </q-input>
</template>

<script setup lang="ts">
import {ref} from 'vue'
import {FormItemField} from 'src/models/FormEditor/FormItemResponseData'

const props = defineProps<{
  field: FormItemField,
  label: string | boolean,
}>()

const value = ref<string>('')
</script>
