import {defineStore} from 'pinia'
import {useGetApi} from 'src/uses/Api/useGetApi'
import {ref} from 'vue'
import {useGridPagination} from 'src/uses/Grid/useGridPagination'
import {NewsListResponseData} from 'src/models/News/NewsListResponseData'
import {NewsItemPreview} from 'src/models/News/NewsItemPreview'

export const useImportantNewsListStore = defineStore('important-list-news', () => {
  const {
    loading,
    get,
    errors,
    error,
    status,
    response,
  } = useGetApi<NewsListResponseData>()

  const {
    pagination,
    gridPagination,
    perPageOptions,
    getFromStorage,
    setPagination,
    onRequestPagination,
    setPage,
    setPerPage,
  } = useGridPagination('important-news-list')

  const items = ref(<NewsItemPreview[]>[])

  const load = async () => {
    await getFromStorage()

    await get(
      'v1/news/list',
      {
        page: pagination.value?.page,
        perpage: 20,
        'filter[important]': true,
        sort: 'publication_date',
        direction: 'desc'
      }
    )

    if (status.value === 200 && response && response.value?.items) {
      items.value = response.value?.items
      setPagination(response.value?.pagination)
    }
  }

  return {
    loading,
    get,
    errors,
    error,
    status,
    items,
    pagination,
    gridPagination,
    perPageOptions,
    onRequestPagination,
    load,
    setPage,
    setPerPage,
  }
})
