import {defineStore, storeToRefs} from 'pinia'
import {ref, watch} from 'vue'
import {useAuthStore} from 'src/stores/Login/useAuthStore'
import {MenuItem} from 'src/models/MenuItem'
import {useRoute, useRouter} from 'vue-router'
import {UserRoleEnum} from 'src/models/User/UserRoleEnum'
import {useProfileStore} from 'src/stores/Login/useProfileStore'
import {useQuasar} from 'quasar'
import {useFormsStore} from 'stores/Forms/useFormsStore'
import {ProductEnum} from 'src/models/Applications/ProductEnum'
import {FormItem} from 'src/models/FormEditor/FormItem'

export const useMenuStore = defineStore('menu-store', () => {
  const {profile} = storeToRefs(useProfileStore())
  const menu = ref<MenuItem[]>([])

  const route = useRoute()
  const router = useRouter()

  const routeNames = ref<string[]>([])

  const $q = useQuasar()

  const authStore = useAuthStore()
  const {
    isAgent,
    isAgentManager,
  } = authStore
  const {
    isAuthed,
  } = storeToRefs(authStore)

  const formsStore = useFormsStore()
  const {
    loadForms
  } = formsStore
  const {
    forms,
    loading,
  } = storeToRefs(formsStore)

  const navigate = (item: MenuItem) => {
    routeNames.value = [
      item.to.name
    ]
    //item.active = true
    //refresh()
    //setTimeout(() => router.push(item.to), 100)
    router.push(item.to)
  }

  const closeAll = () => {
    menu.value.forEach(item => {
      (item.children || []).forEach(subItem => {
        if (!subItem.active) {
          return
        }
        subItem.active = false
      })
      if (!item.active) {
        return
      }
      item.active = false
    })
  }

  const refresh = () => {
    closeAll()
    const names = routeNames.value

    menu.value.forEach(item => {
      if (names.includes(item.menuToName)) {
        item.active = true
      }
      (item.children || []).forEach(subItem => {
        if (names.includes(subItem.menuToName)) {
          item.active = true
          subItem.active = true
        }
        if (route.fullPath.includes('form-editor') && subItem.menuToName.includes('form-editor')) {
          (subItem.children || []).forEach(subSubItem => {
            if (!subSubItem.to
              || !subSubItem.to.params
              || !subSubItem.to.params.formId
              || subSubItem.to.params.formId !== route.params.formId
            ) {
              return
            }

            item.active = true
            subItem.active = true
            subSubItem.active = true
          })
        }
      })
    })
  }

  const isShow = (item: MenuItem): boolean => {
    if (!profile.value
      || !profile.value.role
      || !item.roles
    ) {
      return true
    }

    return item.roles.includes(profile.value.role)
  }

  const regenerateMenu = () => {
    if (isAuthed.value) {
      const applications: MenuItem = {
        label: 'Заявки',
        to: {name: 'applications-grid'},
        menuToName: 'applications',
        icon: 'svguse:icons.svg?2#app-clipboard-list',
        active: false,
        children: undefined,
      }
      /*if (!isAdmin()) {
        applications.children = [
          {
            label: 'Новая',
            to: {name: 'applications-new'},
            menuToName: 'applications-new',
            icon: 'svguse:icons.svg?2#app-document-add-bold',
            active: false,
          },
          {
            label: 'Список',
            to: {name: 'applications-grid'},
            menuToName: 'applications-grid',
            icon: 'svguse:icons.svg?2#svguse:icons.svg?2#app-clipboard-list-bold',
            active: false,
          },
        ]
      }*/

      menu.value = [
        {
          label: 'Профиль',
          to: {name: isAgent() ? 'profile-requisites' : 'profile-Settings'},
          menuToName: 'profile',
          icon: 'svguse:icons.svg?2#app-user-circle',
          active: false,
          roles: [
            UserRoleEnum.Agent,
            UserRoleEnum.HeadEmployee,
            UserRoleEnum.Employee,
            UserRoleEnum.Operator,
            UserRoleEnum.AgentManager,
          ],
          children: [
            {
              label: 'Реквизиты',
              to: {name: 'profile-requisites'},
              menuToName: 'profile-requisites',
              icon: 'svguse:icons.svg?2#app-library-bold',
              active: false,
              roles: [UserRoleEnum.Agent],
            },
            {
              label: 'Сотрудники',
              to: {name: 'profile-employees'},
              menuToName: 'profile-employees',
              icon: 'svguse:icons.svg?2#app-users-bold',
              active: false,
              roles: [UserRoleEnum.Agent],
            },
            {
              label: 'Отчеты',
              to: {name: 'profile-reports'},
              menuToName: 'profile-reports',
              icon: 'svguse:icons.svg?2#app-clipboard-check-bold',
              active: false,
              roles: [
                UserRoleEnum.Agent,
                UserRoleEnum.HeadEmployee,
                UserRoleEnum.Employee,
              ],
            },
            {
              label: 'Настройки',
              to: {name: 'profile-Settings'},
              menuToName: 'profile-Settings',
              icon: 'svguse:icons.svg?2#app-cog-bold',
              active: false,
            },
            {
              label: 'Уведомления',
              to: {name: 'profile-notifications'},
              menuToName: 'profile-notifications',
              icon: 'svguse:icons.svg?2#app-bell-bold',
              active: false,
              roles: [UserRoleEnum.Agent],
            },
            ...(
              !$q.platform.is.mobile
                ? [
                    {
                      label: 'Акты',
                      to: {name: 'profile-acts'},
                      menuToName: 'profile-acts',
                      icon: 'svguse:icons.svg?2#svguse:icons.svg?2#app-clipboard-list-bold',
                      active: false,
                      roles: [UserRoleEnum.Agent],
                    },
                  ]
                : []
            ),
            ...(
              profile.value && profile.value.sign_portal && profile.value.sign_portal.enabled
                ? [
                    {
                      label: 'Кабинет клиента',
                      to: {name: 'profile-portal-sign'},
                      menuToName: 'profile-portal-sign',
                      icon: 'svguse:icons.svg?2#app-color-swatch-bold',
                      active: false,
                      roles: [UserRoleEnum.Agent],
                    }
                  ]
                : []
            ),
          ]
        },
        {
          label: 'Клиенты',
          to: {name: 'clients-list'},
          menuToName: 'clients-list',
          icon: 'svguse:icons.svg?2#app-user-group',
          active: false,
          roles: [
            UserRoleEnum.Agent,
            UserRoleEnum.HeadEmployee,
            UserRoleEnum.Operator,
            UserRoleEnum.Admin,
            UserRoleEnum.JuniorAdmin,
            UserRoleEnum.MiddleAdmin,
          ],
        },
        {
          label: isAgentManager() ? 'Мои агенты' : 'Профили',
          to: {name: 'profiles-users'},
          menuToName: 'profiles-users',
          icon: 'svguse:icons.svg?2#app-user-group',
          active: false,
          roles: [UserRoleEnum.Admin, UserRoleEnum.AgentManager],
          children: [
            {
              label: 'Пользователи',
              to: {name: 'profiles-users'},
              menuToName: 'profiles-users',
              icon: 'svguse:icons.svg?2#app-user-group',
              active: false,
            },
            {
              label: 'Агенты',
              to: {name: 'profiles-agents'},
              menuToName: 'profiles-agents',
              icon: 'svguse:icons.svg?2#app-user-group',
              active: false,
            },
          ],
        },
        applications,
        {
          label: 'Новости',
          to: {name: 'news'},
          menuToName: 'news',
          icon: 'svguse:icons.svg?2#app-newspaper',
          active: false,
          roles: [
            UserRoleEnum.Agent,
            UserRoleEnum.HeadEmployee,
            UserRoleEnum.Employee,
            UserRoleEnum.Operator,
            UserRoleEnum.AgentManager]
          ,
        },
        {
          label: 'Помощь',
          to: {name: 'help'},
          menuToName: 'help-page',
          icon: 'svguse:icons.svg?2#app-support',
          active: false,
          roles: [
            UserRoleEnum.Agent,
            UserRoleEnum.HeadEmployee,
            UserRoleEnum.Employee,
            UserRoleEnum.Operator,
            UserRoleEnum.AgentManager,
            UserRoleEnum.JuniorAdmin,
          ],
        },
        {
          label: 'Продукты',
          to: {name: 'products-list'},
          menuToName: 'products',
          icon: 'svguse:icons.svg?2#app-collection',
          active: false,
          roles: [
            UserRoleEnum.Admin,
          ],
        },
        {
          label: 'Банки',
          to: {name: 'banks-grid'},
          menuToName: 'banks-grid',
          icon: 'svguse:icons.svg?2#app-support',
          active: false,
          roles: [
            UserRoleEnum.Admin,
          ],
        },
        {
          label: 'Редактор анкеты',
          to: {name: 'form-editor'},
          menuToName: 'form-editor',
          icon: 'svguse:icons.svg?2#app-pencil-alt',
          active: false,
          roles: [UserRoleEnum.Admin,],
          children: formsMenuChildren() || []
        },
        {
          label: 'Контрагенты',
          to: {name: 'contractors-list'},
          menuToName: 'contractors-list',
          icon: 'svguse:icons.svg?2#app-users-bold',
          active: false,
          roles: [
            UserRoleEnum.Admin,
          ],
        },
        {
          label: 'Общие настройки',
          to: {name: 'settings-news'},
          menuToName: 'settings-news',
          icon: 'svguse:icons.svg?2#app-adjustments',
          active: false,
          roles: [UserRoleEnum.Admin],
          children: [
            {
              label: 'Новости',
              to: {name: 'settings-news'},
              menuToName: 'settings-news',
              icon: 'svguse:icons.svg?2#app-newspaper',
              active: false,
            },
            {
              label: 'Справочники',
              to: {name: 'listings-grid'},
              menuToName: 'listings-grid',
              icon: 'svguse:icons.svg?2#app-view-list-bold',
              active: false,
            },
            {
              label: 'Помощь',
              to: {name: 'settings-help'},
              menuToName: 'settings-help',
              icon: 'svguse:icons.svg?2#app-support',
              active: false,
            },
            {
              label: 'Отчеты',
              to: {name: 'settings-reports'},
              menuToName: 'settings-reports',
              icon: 'svguse:icons.svg?2#app-clipboard-check-bold',
              active: false,
            },
            {
              label: 'Запрос данных',
              to: {name: 'settings-request-data'},
              menuToName: 'settings-request-data',
              icon: 'svguse:icons.svg?2#app-clipboard-copy',
              active: false,
            },
          ],
        },
      ]

      return
    }

    menu.value = [
      {
        label: 'Войти',
        to: {name: 'login'},
        menuToName: 'login',
        icon: 'svguse:icons.svg?2#app-login',
        active: false,
      },
      {
        label: 'Восстановить пароль',
        to: {name: 'password-recovery'},
        menuToName: 'password-recovery',
        icon: 'svguse:icons.svg?2#app-key',
        active: false,
      },
      {
        label: 'Регистрация',
        to: {name: 'registration'},
        menuToName: 'registration',
        icon: 'svguse:icons.svg?2#app-plus-circle',
        active: false,
      },
    ]
  }

  const getMenuByName = (name: string): MenuItem | undefined => {
    if (!profile.value) {
      return undefined
    }

    return menu.value
      .find(item => item.menuToName === name && isShow(item))
  }

  const formsMenuChildren = (): MenuItem[] => {
    if (forms.value.length === 0) {
      return []
    }

    const EBG = forms.value.filter(item => item.product_type === ProductEnum.EBG)
    const KIK = forms.value.filter(item => item.product_type === ProductEnum.KIK)
    const RKO = forms.value.filter(item => item.product_type === ProductEnum.RKO)

    return [
      {
        label: 'ЭБГ',
        to: {name: 'forms'},
        menuToName: 'form-editor-ebg',
        icon: 'svguse:icons.svg?2#ebg',
        active: false,
        children: getMenuChildrenBankList(EBG)
      },
      {
        label: 'КИК',
        to: {name: 'forms'},
        menuToName: 'form-editor-kik',
        icon: 'svguse:icons.svg?2#kik',
        active: false,
        children: getMenuChildrenBankList(KIK)
      },
      {
        label: 'РКО',
        to: {name: 'forms'},
        menuToName: 'form-editor-rko',
        icon: 'svguse:icons.svg?2#rko',
        active: false,
        children: getMenuChildrenBankList(RKO)
      },
    ]
  }

  const getMenuChildrenBankList = (forms: FormItem[]): MenuItem[] => {
    return forms.map(item => {
      return {
        label: item.bank_title,
        to: {
          name: `form-item-${item.product_type.toLowerCase()}`,
          params: {
            formId: item.form_id,
          }
        },
        menuToName: `form-editor-${item.product_type.toLowerCase()}`,
        icon: 'svguse:icons.svg?2#app-library',
        active: false,
      }
    })
  }

  watch(
    isAuthed,
    () => {
      regenerateMenu()
    },
    {
      immediate: true,
    }
  )

  watch(
    route,
    async () => {
      routeNames.value = route.matched
        .map(match => match.name || null)
        .filter(name => !!name) as string[]

      if (route.fullPath.includes('form-editor')) {
        await loadForms()
      }

      setTimeout(refresh, 50)
    },
    {immediate: true, deep: true}
  )

  watch(
    forms,
    () => {
      regenerateMenu()
    },
    {immediate: true}
  )

  return {
    menu,
    loading,
    forms,
    getMenuByName,
    navigate,
    isShow,
    loadForms,
  }
})
