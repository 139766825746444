<template>
  <div class="q-mt-xl">
    <div class="text-h6 q-mb-md">
      Руководители и учредители
    </div>
    <form-item-copy-delete-fields
      :form-id="props.formId"
      :section-id="clientHead.id"
      :block-id="props.client.id"
      :can-copy="clientHead.fields.length === 0"
      @change="updateFields"
    />
    <div class="q-py-lg">
      <form-item-fields-block-component
        :fields="clientHead.fields"
        :section-id="clientHead.id"
        :form-id="props.formId"
        @change="updateFields"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import {FormItemClientBlock} from 'src/models/FormEditor/FormItemResponseData'
import FormItemCopyDeleteFields from 'components/FormEditor/FormItemCopyDeleteFields.vue'
import {computed} from 'vue'
import FormItemFieldsBlockComponent from 'components/FormEditor/FormItemFieldsBlockComponent.vue'

const emit = defineEmits<{
  (e: 'updateFields'): void,
}>()

const props = defineProps<{
  client: FormItemClientBlock,
  formId: string,
}>()

const clientHead = computed(() => props.client.client_head)

const updateFields = () => {
  emit('updateFields')
}

</script>
