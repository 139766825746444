import {UserStatusEnum} from 'src/models/User/UserStatusEnum'

const statuses: Record<UserStatusEnum, string> = {
  [UserStatusEnum.Active]: 'Активен',
  [UserStatusEnum.Wait]: 'Ожидание подтверждения',
  [UserStatusEnum.Deleted]: 'Удален',
  [UserStatusEnum.Blocked]: 'Заблокирован',
}

const colors: Record<UserStatusEnum, string> = {
  [UserStatusEnum.Active]: 'green-14',
  [UserStatusEnum.Wait]: 'orange-6',
  [UserStatusEnum.Deleted]: 'red-12',
  [UserStatusEnum.Blocked]: 'red-12',
}

export class UserStatuses {
  public static getUserStatusDescription (status: UserStatusEnum | undefined): string {
    if (!status) {
      return ''
    }

    return statuses[status] || status
  }

  public static getUserStatusColor (status: UserStatusEnum): string {
    return colors[status] || ''
  }
}
