import {usePostApi} from 'src/uses/Api/usePostApi'
import {AddBankProduct} from 'src/models/Banks/BankItem'
import {ProductEnum} from 'src/models/Applications/ProductEnum'

export const useAddBankProduct = () => {
  const {
    post,
    response,
    status,
    loading,
    error,
    errors,
  } = usePostApi<unknown, AddBankProduct>(true)

  const addProduct = async (product: ProductEnum, id: string) => {
    await post('admin/product', {
      bankId: id,
      type: product,
      integration_enabled: false
    })
  }

  return {
    loading,
    response,
    status,
    error,
    errors,
    addProduct,
  }
}
