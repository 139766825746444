import {usePostApi} from 'src/uses/Api/usePostApi'

export const useApplicationManualGenerateDocumentsStart = () => {
  const {
    post,
  } = usePostApi<unknown, undefined>(true)

  const generate = async (id : string) => {
    await post(`v2/control-center/${id}/documents/generate`)
  }

  return {
    generate,
  }
}
