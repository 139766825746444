import {ref} from 'vue'
import {useGetApi} from 'src/uses/Api/useGetApi'
import {AgentsHistoryResponseData} from 'src/models/Profiles/Agents/AgentsHistoryResponseData'
import {ProfilesAgentHistory} from 'src/models/Profiles/Agents/ProfilesAgentHistory'

export const useGetAgentHistory = () => {
  const {
    get,
    response,
    status,
    loading,
    error,
    errors,
  } = useGetApi<AgentsHistoryResponseData>()

  const items = ref(<ProfilesAgentHistory[]>[])

  const load = async (id: string, name: string) => {
    await get(`admin/organization/history/bankrequisites/list?filter[agent_id]=${id}`)
    if (status.value === 200 && response && response.value?.items) {
      items.value = response.value?.items.map(i => {
        return {
          ...i,
          agentName: name
        }
      })
    }
  }

  return {
    items,
    loading,
    response,
    status,
    error,
    errors,
    load,
  }
}
