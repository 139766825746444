import {ref} from 'vue'
import {useGetApi} from 'src/uses/Api/useGetApi'
import {OpfEnum} from 'src/models/OpfEnum'

export const useGetProfileRequisites = <AgentRequisitesResponseInterface = Record<string, unknown>>(opf: OpfEnum) => {
  const {
    get,
    response,
    status,
    loading,
    error,
    errors,
  } = useGetApi<AgentRequisitesResponseInterface>()

  const AgentRequisitesResponse = ref<AgentRequisitesResponseInterface>()

  const load = async () => {
    await get(`v1/profile/requisites/${opf.toLowerCase()}`)
    if (status.value === 200 && response.value) {
      AgentRequisitesResponse.value = response.value
    }
  }

  return {
    AgentRequisitesResponse,
    loading,
    response,
    status,
    error,
    errors,
    load,
  }
}
