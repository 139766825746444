<template>
  <span ref="fieldRef" />
  <div :class="classes">
    <div class="col-sm col-xs-12 ApplicationItemDocument__name relative-position">
      <div class="row items-center no-wrap">
        <q-icon
          name="svguse:icons.svg?2#app-document-text-bold"
          color="blue-grey-3"
          size="md"
        />
        <field-bank-valid
          :valid="props.document.valid"
          :banks="props.banks"
        />
        <div class="ellipsis">
          {{ props.document.title }}
        </div>
        <div
          v-if="props.document.description"
          class="q-pl-xs q-pr-sm"
        >
          <q-icon
            color="grey-6"
            name="svguse:icons.svg?2#app-information-circle-bold"
            size="sm"
            class="infoIcon"
          >
            <q-tooltip :delay="500">
              <div class="row">
                <div class="col-1">
                  <q-icon
                    class="q-mr-sm"
                    color="orange"
                    name="svguse:icons.svg?2#app-information-circle"
                    size="sm"
                  />
                </div>
                <div class="col-11 q-pl-sm">
                  {{ props.document.description }}
                </div>
              </div>
            </q-tooltip>
          </q-icon>
        </div>
      </div>
      <div
        v-if="props.document.progress"
        class="progress"
      >
        <q-linear-progress
          :value="props.document.progress.loaded / props.document.progress.total"
          color="green-14"
        />
      </div>
    </div>

    <div class="ApplicationItemDocument__info-actions row items-center justify-end">
      <div
        v-if="props.document.file_date && !props.document.progress"
        class="ApplicationItemDocument__loaded text-grey text-center"
      >
        Загружен <span>{{ props.document.file_date }}</span>
      </div>
      <div
        v-if="isSigned && !props.document.progress && props.document.id"
        class="ApplicationItemDocument__signed text-green-14 text-center"
      >
        Подписан
        <document-sign-info
          :doc-id="props.document.id"
        />
      </div>
      <div class="ApplicationItemDocument__actions row items-center q-col-gutter-x-sm justify-end">
        <template v-if="props.document.file_date && !props.document.progress">
          <template v-if="!props.document.loading">
            <div>
              <download-button
                :loading="props.document.loading"
                :signed="isSigned"
                @document:download="emit('document:download')"
                @document:download-sign="emit('document:download-sign')"
              />
            </div>
            <div class="ApplicationItemDocument__delete">
              <q-btn
                class="delete"
                dense
                unelevated
                :disable="props.document.loading || props.document.readonly"
                @click="emit('document:delete')"
              >
                <q-icon
                  name="svguse:icons.svg?2#app-trash"
                  color="blue-grey-4"
                />
              </q-btn>
            </div>
          </template>
          <q-circular-progress
            v-else
            color="blue-grey-4"
            class="q-mr-sm"
            size="26px"
            indeterminate
          />
        </template>
        <div v-if="!props.document.file_date || props.document.progress">
          <q-file
            ref="fileRef"
            v-model="file"
            class="FilePicker"
            label="Standard"
            accept=".xls, .xlsx, .doc, .docx, .pdf, .jpeg, .jpg, .png, .txt, .sig, .sgn, .p7s, .zip, .rar, .xml"
            :max-file-size="26214400"
            @rejected="onRejected"
          />
          <q-btn
            v-if="props.document.loading"
            label="Отмена"
            icon-right="svguse:icons.svg?2#app-x-circle"
            color="blue-grey-5"
            padding="8px 15px"
            :loading="fileSandedButNoResponse"
            :disable="fileSandedButNoResponse"
            unelevated
            no-wrap
            no-caps
            @click="cancel()"
          />
          <q-btn
            v-if="!props.document.readonly"
            label="Загрузить"
            icon-right="svguse:icons.svg?2#app-sort-ascending"
            color="blue-7"
            padding="8px 15px"
            unelevated
            no-wrap
            no-caps
            :disable="props.document.loading"
            @click="uploadClick($event)"
          />
        </div>
      </div>
    </div>
  </div>
  <application-form-document-with-sign-dialog
    v-model="dialog"
    :document="document"
    @file:selected-with-sign="onFileSelectedWithSign"
  />
</template>

<script setup lang="ts">
import {computed, ref, watch, watchEffect} from 'vue'
import {QFile, useQuasar} from 'quasar'
import {useScrollTo} from 'src/uses/useScrollTo'
import FieldBankValid from 'src/components/Fields/FieldBankValid.vue'
import {
  ApplicationFormBank,
  ApplicationFormDocument,
  ApplicationFormDocumentStatusEnum,
} from 'src/models/Applications/Form/ApplicationFormResponseData'
import DownloadButton from 'src/components/DownloadButton.vue'
import ApplicationFormDocumentWithSignDialog
  from 'src/components/Applications/Form/Documents/ApplicationFormDocumentWithSignDialog.vue'
import {useDialog} from 'src/uses/Dialogs/useDialog'
import {useNotifyErrorsStore} from 'src/stores/useNotifyErrorsStore'
import DocumentSignInfo from 'src/components/Applications/Form/Documents/DocumentSignInfo.vue'
import {useLayoutStore} from 'stores/useLayoutStore'
import {storeToRefs} from 'pinia'

const props = defineProps<{
  document: ApplicationFormDocument
  banks: ApplicationFormBank[]
}>()

const emit = defineEmits<{
  (e: 'file:selected', file: File): void,
  (e: 'document:delete'): void,
  (e: 'document:download'): void,
  (e: 'document:download-sign'): void,
  (e: 'cancel'): void,
  (e: 'file:selected-with-sign', original: File, sign: File): void,
}>()

const {
  dialog,
  show,
} = useDialog()

const {
  scrollToInvisibleElement,
} = useScrollTo()

const {
  addErrorMessage,
} = useNotifyErrorsStore()

const layoutStore = useLayoutStore()
const {rightDrawer, drawer} = storeToRefs(layoutStore)

const $q = useQuasar()

const fieldRef = ref<HTMLSpanElement>()
const file = ref<File>()
const fileRef = ref<QFile>()
const isSigned = computed(() => ApplicationFormDocumentStatusEnum.signed === props.document.file_status)
const fileSandedButNoResponse = computed(() => {
  return props.document.loading
    && props.document.progress
    && props.document.progress.loaded === props.document.progress.total
})

const classes = computed(() => {
  return {
    ApplicationItemDocument: true,
    ApplicationItemDocumentHighlighted: props.document.highlighted,
    row: true,
    'items-center': true,
    'bg-white': !props.document.highlighted,
    'q-px-md': true,
    'q-my-xs': true,
  }
})

const uploadClick = (event: Event) => {
  if (props.document.sign_file_required) {
    show()

    return
  }
  fileRef.value?.pickFiles(event)
}

const cancel = () => {
  file.value = undefined
  emit('cancel')
}

const onFileSelectedWithSign = (original: File, sign: File) => {
  emit('file:selected-with-sign', original, sign)
}

const onRejected = (rejectedEntries: {failedPropValidation: string, file: File}[]) => {
  const maxSize = rejectedEntries
    .filter(e => e.failedPropValidation === 'max-file-size')

  if (maxSize.length) {
    addErrorMessage('Привышен максимально допустимый размер файла (25Mb).')
  }
}

watch(
  file,
  () => {
    if (!file.value) {
      return
    }

    emit('file:selected', file.value)
  }
)

watchEffect(() => {
  if (!props.document.highlighted) {
    return
  }

  if ($q.platform.is.mobile) {
    rightDrawer.value = false
    drawer.value = false
  }

  setTimeout(() => {
    if (fieldRef.value) {
      scrollToInvisibleElement(fieldRef.value)
    }
  }, 300)
})
</script>

<style lang="scss">
.ApplicationItemDocument {
  border: 1px solid white;
  border-radius: 6px;
  min-height: 60px;
  .infoIcon {
    font-size: 18px !important;
  }

  &.ApplicationItemDocumentHighlighted {
    background: $orange-1;
    border-color: $orange-6;
  }

  .progress {
    position: absolute;
    width: calc(100% - 40px);
    margin: -5px 0 0 32px;
  }

  .ApplicationItemDocument__loaded {
    min-width: 220px;
    font-size: 12px;
  }

  .ApplicationItemDocument__signed {
    min-width: 120px;
    font-size: 12px;
  }

  .ApplicationItemDocument__actions {
    min-width: 120px;
  }

  .ApplicationItemDocument__delete {
    &:hover {
      .q-icon {
        color: $red-12 !important;
      }
    }
  }
}
</style>
