<template>
  <template v-if="isAgentManager()">
    <q-item
      v-ripple
      clickable
      @click="emit('show-dialog')"
    >
      <q-item-section>
        Коментарий
      </q-item-section>
    </q-item>
  </template>
</template>

<script setup lang="ts">
import {useProfileStore} from 'src/stores/Login/useProfileStore'

const emit = defineEmits<{
  (e: 'show-dialog'): void,
}>()

const {
  isAgentManager,
} = useProfileStore()
</script>
