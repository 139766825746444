import {useLocalStorage} from 'src/uses/useLocalStorage'
import {computed, onBeforeMount, ref, watch} from 'vue'

export const useGridFiltersPins = (hash: string, keys: string[], defaultPins: Record<string, boolean> = {}) => {
  const {
    getFromStorage,
    setToStorage,
    data,
  } = useLocalStorage<Record<string, boolean>>(`grid-filters-pins-${hash}`)

  const pins = ref<Record<string, boolean>>({})

  const hasPins = computed(() => {
    return Object.values(pins.value).find(p => p)
  })

  const generateFromKeys = (): Record<string, boolean> => {
    const generated: Record<string, boolean> = {}
    keys.forEach(key => {
      generated[key] = defaultPins[key] || false
    })

    return generated
  }

  const getPins = () => {
    getFromStorage()
    pins.value = {
      ...generateFromKeys(),
      ...(data.value || {}),
    }
  }

  const isPinned = (key: string) => {
    return (pins.value as Record<string, boolean>)[key]
  }

  onBeforeMount(() => {
    getPins()
  })

  watch(
    pins,
    () => {
      setToStorage(pins.value)
    },
    {deep: true}
  )

  return {
    pins,
    isPinned,
    hasPins,
  }
}
