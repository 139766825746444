import {computed, ref} from 'vue'
import {
  FormItemConditionComparisonOperatorEnum, FormItemConditionsParamOptionsWithChild,
  FormItemFinancePeriodCondition, FormItemFinancePeriodConditionsPart
} from 'src/models/FormEditor/FormItemResponseData'
import {storeToRefs} from 'pinia'
import {useFormItemStore} from 'stores/FormEditor/useFormItemStore'
import {
  ProductRequiredConditionsParamEnum,
} from 'src/models/Products/ProductRequiredControl'
import {LawTypeEnum} from 'src/models/Applications/LawTypeEnum'
import {ProductStopFactorParamOptionsWithChild} from 'src/models/Products/StopFactorParamsItem'
import {FormElementValue} from 'src/models/Form'

const operators: Record<FormItemConditionComparisonOperatorEnum, string> = {
  [FormItemConditionComparisonOperatorEnum.LT]: '<',
  [FormItemConditionComparisonOperatorEnum.EQ]: '=',
  [FormItemConditionComparisonOperatorEnum.GT]: '>',
}

export const useFormItemConditions = (formId: string, save: () => Promise<void>) => {
  const {
    item
  } = storeToRefs(useFormItemStore(formId)())
  const operatorOptions = ref(
    Object.values(FormItemConditionComparisonOperatorEnum).map(key => {
      return {
        label: operators[key],
        value: key
      }
    })
  )

  const conditionParams = computed(() => {
    if (!item.value) {
      return []
    }

    return item.value.conditions_params
  })

  const periods = computed(() => {
    if (!item.value || !item.value.finance_block) {
      return []
    }

    return item.value.finance_block.periods
  })

  const paramComparisonOptions = computed(() => {
    if (!item.value || !item.value.conditions_params) {
      return {}
    }

    return Object.fromEntries(
      item.value.conditions_params
        .map(param => {
          return [
            param.param,
            param.relations.length === 1
          ]
        })
    )
  })

  const mapOptionsToKeyValue = (options: ProductStopFactorParamOptionsWithChild[]) => {
    return options
      .map(option => {
        if (option.code && option.name) {
          return {
            label: option.name,
            value: option.code,
          }
        }

        return option
      })
  }

  const flatOptions = (options: FormItemConditionsParamOptionsWithChild[]) => {
    return mapOptionsToKeyValue(getOptionsWithChild(options))
  }

  const getOptionsWithChild = (options: FormItemConditionsParamOptionsWithChild[]) => {
    let flatOptions: FormItemConditionsParamOptionsWithChild[] = []
    for (const option of options) {
      flatOptions.push(option)
      flatOptions = flatOptions.concat(getOptionsWithChild(option.child || []))
    }

    return flatOptions
  }

  const paramValueTypesAndOptions = computed(() => {
    if (!item.value || !item.value.conditions_params) {
      return {}
    }

    return Object.fromEntries(
      item.value.conditions_params
        .map(param => {
          if (!Array.isArray(param.options)) {
            const options = []
            for (const value in param.options) {
              options.push({
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                label: param.options[value],
                value,
              })
            }
            return [
              param.param,
              {
                type: param.type,
                multiple: param.multiple,
                options
              }
            ]
          } else {
            return [
              param.param,
              {
                type: param.type,
                multiple: param.multiple,
                options: param.options ? flatOptions(param.options) : null
              }
            ]
          }
        })
    )
  })

  const isValid = (periodId?: string) => {
    if (!item.value || !item.value.finance_block || !item.value.finance_block.periods) {
      return false
    }

    const periodInd = periods.value.findIndex(period => period.id === periodId)

    if (periodInd === -1 || !item.value.finance_block.periods[periodInd].conditions) {
      return true
    }

    let isValidParams = true

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    item.value.finance_block.periods[periodInd].conditions.parts.forEach((condition: FormItemFinancePeriodCondition) => {
      condition.parts.forEach((param: FormItemFinancePeriodConditionsPart) => {
        if (param.value === null || !param.operator) {
          isValidParams = false
        }
      })
    })

    return isValidParams
  }

  const getPeriodIndexById = (periodId?: string) => {
    if (!item.value || !item.value?.finance_block) {
      return null
    }
    const periodInd = periods.value.findIndex(period => period.id === periodId)

    return periodInd === -1 ? null : periodInd
  }

  const addCondition = async (periodId: string | undefined, periodInd: number) => {
    console.log(periodId, periodInd)
    if (periodInd === null) {
      return
    }

    if (!item.value
      || !item.value.finance_block
      || item.value.finance_block.periods.length === 0
      || !item.value.finance_block.periods[periodInd]
    ) {
      return
    }

    if (!item.value.finance_block.periods[periodInd].conditions) {
      item.value.finance_block.periods[periodInd].conditions = {
        operator: 'or',
        parts: []
      }
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    item.value.finance_block.periods[periodInd].conditions.parts.push({
      operator: 'and',
      parts: [{
        operator: FormItemConditionComparisonOperatorEnum.EQ,
        param: ProductRequiredConditionsParamEnum.OBLIGATION_TYPE,
        value: [LawTypeEnum.FZ44]
      }]
    })

    if (periodId && isValid(periodId)) {
      await save()
    }
  }

  const removeCondition = async (periodId: string | undefined, key: number) => {
    const periodInd = getPeriodIndexById(periodId)
    if (periodInd === null) {
      return
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    item.value.finance_block.periods[periodInd].conditions.parts.splice(key, 1)

    if (isValid(periodId)) {
      await save()
    }
  }

  const addParam = (periodId: string | undefined, key: number) => {
    const periodInd = getPeriodIndexById(periodId)
    if (periodInd === null) {
      return
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    item.value.finance_block.periods[periodInd].conditions.parts.map((item, conditionKey) => {
      if (key === conditionKey) {
        item.parts.push({
          operator: FormItemConditionComparisonOperatorEnum.EQ,
          param: ProductRequiredConditionsParamEnum.OBLIGATION_TYPE,
          value: [LawTypeEnum.FZ44]
        })
      }
      return item
    })
  }

  const removeParam = async (periodId: string | undefined, conditionKey: number, paramKey: number) => {
    const periodInd = getPeriodIndexById(periodId)
    if (periodInd === null) {
      return
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    item.value.finance_block.periods[periodInd].conditions.parts.map((item, key) => {
      if (conditionKey === key) {
        item.parts.splice(paramKey, 1)
      }
      return item
    })

    if (isValid(periodId)) {
      await save()
    }
  }

  const onChangeParam = async (periodId: string | undefined, conditionKey: number, paramKey: number) => {
    const periodInd = getPeriodIndexById(periodId)
    if (periodInd === null) {
      return
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    item.value.finance_block.periods[periodInd].conditions.parts.map((item, key) => {
      if (conditionKey === key) {
        item.parts.map((param, searchParamKey) => {
          if (searchParamKey === paramKey) {
            param.value = null
            param.operator = FormItemConditionComparisonOperatorEnum.EQ
          }
          return param
        })
      }
      return item
    })

    if (isValid(periodId)) {
      await save()
    }
  }

  const changeMultiSelectParamValue = async (
    periodId: string | undefined,
    conditionKey: number,
    paramKey: number,
    value: FormElementValue
  ) => {
    const periodInd = getPeriodIndexById(periodId)
    if (periodInd === null) {
      return
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    item.value.finance_block.periods[periodInd].conditions.parts.map((item, key) => {
      if (conditionKey === key) {
        item.parts.map((param, searchParamKey) => {
          if (searchParamKey === paramKey) {
            param.value = value
          }
          return param
        })
      }
      return item
    })

    if (!isValid(periodId)) {
      return
    }
    await save()
  }

  const changeParamValueOrOperator = async (periodId?: string) => {
    if (!isValid(periodId)) {
      return
    }
    await save()
  }

  return {
    operatorOptions,
    paramComparisonOptions,
    conditionParams,
    paramValueTypesAndOptions,

    addCondition,
    removeCondition,
    addParam,
    removeParam,
    onChangeParam,
    changeMultiSelectParamValue,
    changeParamValueOrOperator,
  }
}
