<template>
  <q-intersection
    transition="scale"
    once
  >
    <div class="row items-center justify-around q-mt-sm q-col-gutter-sm">
      <div :style="$q.platform.is.mobile ? 'text-align: center; padding: 0 30px' : ''">
        Уже создавали заявку, но теперь хотите разместить ее в другом банке?
      </div>
      <div :class="{'full-width': $q.platform.is.mobile}">
        <q-btn
          class="text-blue-grey-4"
          size="16px"
          padding="sm lg"
          label="Разместить в другом банке"
          color="grey-2"
          no-caps
          unelevated
          :style="$q.platform.is.mobile ? 'width: 100%; padding: 12px 0': ''"
          @click="clickBtn"
        />
      </div>
    </div>
  </q-intersection>
</template>

<script setup lang="ts">
import {useApplicationsNew} from 'stores/Applications/ApplicationsNew/useApplicationsNew'
import {storeToRefs} from 'pinia'
import {ApplicationNewModeEnum} from 'src/models/Applications/New/ApplicationNewModeEnum'
import {usePosthogStore} from 'stores/Posthog/usePosthogStore'

const applicationsNewStore = useApplicationsNew()
const {
  mode
} = storeToRefs(applicationsNewStore)
const {sendEvent} = usePosthogStore()
const clickBtn = () => {
  mode.value = ApplicationNewModeEnum.select
  sendEvent('Post_application_to_another_bank')
}
</script>

<style scoped lang="scss">

</style>
