export class CloneHelper {
  static clone<T = never> (source: T): T {
    return JSON.parse(
      JSON.stringify(
        source,
        (k, v) => v === undefined ? null : v
      )
    )
  }
}
