import {ref} from 'vue'
import {useGetApi} from 'src/uses/Api/useGetApi'
import {ProductContractorItem} from 'src/models/Products/ProductContractorItem'

export const useProductContractorsList = () => {
  const {
    get,
    response,
    status,
    loading,
    error,
    errors,
  } = useGetApi<{contractors: ProductContractorItem[]}>()

  const contractors = ref<ProductContractorItem[]>([])

  const load = async (productId: string) => {
    await get(`v2/products/${productId}/contractors`)
    if (status.value === 200 && response.value) {
      contractors.value = response.value?.contractors || []
    }
  }

  return {
    contractors,
    loading,
    response,
    status,
    error,
    errors,
    load,
  }
}
