<template>
  <filters-expansion
    :clearable="clearable"
    :is-show-filters="isShowFilters"
    @clear-pins="onClearPins"
    @set-default-pins="onSetDefaultPins"
  >
    <template #header>
      <div v-if="filters.number && !isPinned('number')">
        <q-chip
          :label="`Номер ${filters.number}`"
          color="primary"
          text-color="white"
        />
      </div>
      <div v-if="filters.banks.length > 0 && !isPinned('banks')">
        <q-chip
          label="Банки"
          color="primary"
          text-color="white"
        >
          <div
            class="q-ml-sm"
            style="margin-top: 6px;"
          >
            <template
              v-for="bank in banks"
              :key="bank.id"
            >
              <img
                v-if="filters.banks.includes(bank.id)"
                :alt="bank.title_short"
                :src="bank.logo as string"
                style="max-height: 20px;line-height: 20px"
                class="q-mr-xs"
              >
            </template>
          </div>
        </q-chip>
      </div>
      <div v-if="filters.client && !isPinned('client')">
        <q-chip
          :label="`ИНН ${filters.client}`"
          color="primary"
          text-color="white"
        />
      </div>
      <div v-if="filters.new_request && !isPinned('new_request')">
        <q-chip
          label="С доп. запросом"
          color="primary"
          text-color="white"
        />
      </div>
    </template>

    <application-kik-grid-filters
      :loading="props.loading"
      :only-pinned="false"
      :ignore-pins="props.ignorePins"
      @update:filters="onSubmit"
    />
  </filters-expansion>
</template>

<script setup lang="ts">
import {computed} from 'vue'
import {storeToRefs} from 'pinia'
import FiltersExpansion from 'src/components/Grid/FiltersExpansion.vue'
import {useProfileStore} from 'src/stores/Login/useProfileStore'
import {useApplicationsGridKikFiltersStore} from 'stores/Applications/Grid/useApplicationsGridKikFiltersStore'
import {
  applicationGridKikDefaultPins
} from 'components/Applications/ApplicationsGrid/Grid/ApplicationGridKikDefaultFilters'
import ApplicationKikGridFilters from 'components/Applications/ApplicationsGrid/ApplicationKikGridFilters.vue'
import {useBankFilterStore} from 'stores/Banks/useBankFilterStore'

const props = defineProps<{
  loading: boolean
  ignorePins: boolean
}>()

const emit = defineEmits<{
  (e: 'update:filters'): void,
}>()

const bankFilterStore = useBankFilterStore()
const {banks} = storeToRefs(bankFilterStore)
const filtersStore = useApplicationsGridKikFiltersStore()
const {isPinned: isRealPined} = filtersStore
const {filters, clearable, pins} = storeToRefs(filtersStore)
const profileStore = useProfileStore()
const {isAdmin} = profileStore

const onSubmit = () => {
  emit('update:filters')
}

const isPinned = (key: string) => {
  return isRealPined(key) && !props.ignorePins
}

const onClearPins = () => {
  Object.keys(pins.value)
    .forEach(key => {
      pins.value[key] = false
    })
}

const isShowFilters = computed(() => {
  return (filters.value.number && !isPinned('number'))
    || (filters.value.banks.length > 0 && !isPinned('banks'))
    || (filters.value.client && !isPinned('client'))
    || (isAdmin() && filters.value.new_request === true && !isPinned('new_request'))
})

const onSetDefaultPins = () => {
  pins.value = JSON.parse(JSON.stringify(applicationGridKikDefaultPins))
}

defineExpose({
  isShowFilters,
})
</script>
