<template>
  <q-dialog
    :model-value="!!props.item && props.item.opf === OpfEnum.INDIVIDUAL"
    @update:model-value="emit('close')"
  >
    <q-card
      class="q-mt-md bg-blue-1"
      style="min-width: 400px; max-width: 1000px; border-radius: 4px !important;"
    >
      <q-card-section class="text-h5 rounded-borders q-pb-none">
        Редактировать учетную запись агента
      </q-card-section>
      <q-card-section>
        <div style="max-height: 70vh; overflow: auto; margin: 0 -16px; padding: 0 16px;">
          <profiles-agent-history
            v-if="props.item"
            :item-id="props.item.id"
            :agent-name="fullName"
          />
          <profiles-agent-edit-individual v-if="agentData" />
          <profiles-agent-edit-documents
            v-if="!!offer"
            v-model:offer="offer"
            @select-sig="offerSigFile = $event"
            @select-original="offerOriginalFile = $event"
          />
        </div>
      </q-card-section>
      <q-card-actions
        class="row justify-end q-py-md"
        style="background: #D5EAFA;"
      >
        <q-btn
          v-close-popup
          size="16px"
          padding="sm lg"
          label="Отмена"
          :disable="loading"
          unelevated
          no-caps
        />
        <q-btn
          padding="sm lg"
          size="16px"
          color="blue-7"
          label="Сохранить"
          :loading="loading"
          unelevated
          no-caps
          @click="handleSubmit"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
import {onBeforeUnmount, watchEffect} from 'vue'
import {storeToRefs} from 'pinia'
import {useAgentIndividualEditStore} from 'src/stores/Profiles/Agents/useAgentIndividualEditStore'
import ProfilesAgentEditIndividual from 'src/components/Profiles/Agents/Form/ProfilesAgentEditIndividual.vue'
import ProfilesAgentHistory from 'src/components/Profiles/Agents/ProfilesAgentHistory.vue'
import {ProfilesAgentList} from 'src/models/Profiles/Agents/ProfilesAgentList'
import {OpfEnum} from 'src/models/OpfEnum'
import ProfilesAgentEditDocuments from 'src/components/Profiles/Agents/ProfilesAgentEditDocuments.vue'

const props = defineProps<{
  item: ProfilesAgentList | undefined
}>()

const emit = defineEmits<{
  (e: 'close'): void,
}>()

const agentEditStore = useAgentIndividualEditStore()
const {
  fullName,
  agentData,
  loading,
  offer,
  offerOriginalFile,
  offerSigFile,
  status,
} = storeToRefs(agentEditStore)

const {
  update,
  loadAgent,
} = agentEditStore

const handleSubmit = async () => {
  await update()
  if (status.value === 200) {
    emit('close')
  }
}

watchEffect(async () => {
  if (!props.item || props.item.opf !== OpfEnum.INDIVIDUAL) {
    return
  }

  await loadAgent(props.item.id)
})

onBeforeUnmount(() => {
  agentEditStore.$reset()
  agentEditStore.$dispose()
})
</script>
