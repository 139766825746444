import {createRouter, createWebHashHistory, RouteRecordRaw} from 'vue-router'
import {loginRouter} from 'src/router/login'
import {applicationsRouter} from 'src/router/applications'
import {useAccess} from 'src/uses/useAccess'
import {useAuthStore} from 'src/stores/Login/useAuthStore'
import {profileRouter} from 'src/router/pofile'
import {newsRouter} from 'src/router/news'
import {helpRouter} from 'src/router/help'
import {profilesRouter} from 'src/router/profiles'
import {demoRouter} from 'src/router/demo'
import {settingsRouter} from 'src/router/settings'
import {clientsRouter} from 'src/router/clients'
import {productsRouter} from 'src/router/products'
import {formEditor} from 'src/router/formEditor'
import {listingsRouter} from 'src/router/listings'
import {AuthedGuard} from 'src/router/quards/AuthedGuard'
import {useImpersonateStore} from 'src/stores/useImpersonateStore'
import {useRememberRouteForGuestStore} from 'stores/useRememberRouteForGuestStore'
import {banksRouter} from 'src/router/banks'
import {useIntegratorStore} from 'stores/useIntegratorStore'
import {contractorsRouter} from 'src/router/contractors'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: () => import(/* webpackChunkName: "MainLayout" */ '../layouts/MainLayout.vue'),
    children: [
      {
        path: '/',
        name: 'main',
        component: () => import(/* webpackChunkName: "HomeView" */ '../views/HomeView.vue'),
        meta: {
          guards: [
            AuthedGuard(),
          ],
        },
      },
    ],
    meta: {
      guards: [
        AuthedGuard(),
      ],
    },
  },
  ...loginRouter,
  applicationsRouter,
  profileRouter,
  newsRouter,
  helpRouter,
  profilesRouter,
  helpRouter,
  demoRouter,
  settingsRouter,
  clientsRouter,
  banksRouter,
  productsRouter,
  ...formEditor,
  listingsRouter,
  contractorsRouter,
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach(async (to, from, next) => {
  const integratorStore = useIntegratorStore()
  if (!integratorStore.loaded) {
    await integratorStore.load()
  }
  const {
    detectImpersonateInRoute,
    processImpersonate,
  } = useImpersonateStore()
  if (detectImpersonateInRoute(to)) {
    processImpersonate(to)
  }
  const authStore = useAuthStore()
  await authStore.profileLoaded()

  const rememberRouteForGuestStore = useRememberRouteForGuestStore()
  rememberRouteForGuestStore.remember(to)

  const {canAccess, redirectTo} = useAccess(to, from)
  if (canAccess()) {
    next()
    return
  }

  await next(redirectTo())
})

export default router
