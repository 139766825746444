import {defineStore} from 'pinia'
import {useGridFilters} from 'src/uses/Grid/useGridFilters'
import {ListingsGridFilter} from 'src/models/Listings/ListingsGridFilter'

const defaultFilters: ListingsGridFilter = {
  title: '',
}

export const useListingsGridFiltersStore = defineStore('listings-grid-filters', () => {
  const {
    filters,
    clearable,
    getFiltersQuery,
    clear,
  } = useGridFilters(defaultFilters)

  return {
    filters,
    clearable,
    getFiltersQuery,
    clear,
  }
})
