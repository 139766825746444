<template>
  <q-form
    v-if="!successModal || props.hasBackBtn"
    @submit="handleSubmit"
  >
    <div
      :style="`padding-top: ${props.hasBackBtn ? 80 : 0}px;`"
      class="q-gutter-lg"
    >
      <orange-info
        text="На указанный адрес электронной почты будет отправлено письмо с дальнейшими инструкциями по изменению пароля "
        small
      />
      <q-input
        v-model="passwordRecoveryRequestData.email"
        class="field-with-top-label q-mt-xl"
        :loading="loading"
        :error-message="getErrorMessage('email') || error"
        :error="hasError('email') || !!error"
        autocomplete="new-password"
        label="E-mail или логин"
        outlined
        hide-hint
        clearable
        hide-bottom-space
        dense
        @focus="clearError('inn'); error = undefined"
      />
      <div class="q-mt-xl">
        <div>
          <q-btn
            :loading="loading"
            label="Отправить"
            color="primary"
            type="submit"
            class="full-width"
            style="height: 50px;"
            unelevated
            no-caps
          />
        </div>
        <div v-if="!props.hasBackBtn">
          <q-btn
            label="Отмена"
            class="full-width bg-white q-mt-lg"
            style="height: 50px;"
            unelevated
            no-caps
            @click="emit('close')"
          />
        </div>
        <div v-if="props.hasBackBtn">
          <q-btn
            label="Назад"
            :to="{name: 'login'}"
            class="full-width bg-white q-mt-lg"
            style="height: 50px;"
            unelevated
            no-caps
          />
        </div>
      </div>
    </div>
  </q-form>
  <password-recovery-success-message v-model="successModal" />
</template>

<script setup lang="ts">
import {usePasswordRecoveryStore} from 'src/stores/Login/usePasswordRecoveryStore'
import {storeToRefs} from 'pinia'
import {useValidation} from 'src/uses/useValidation'
import {onBeforeUnmount, ref, watch} from 'vue'
import OrangeInfo from 'src/components/OrangeInfo.vue'
import PasswordRecoverySuccessMessage from 'components/Login/PasswordRecoverySuccessMessage.vue'

const props = withDefaults(
  defineProps<{
    hasBackBtn?: boolean
  }>(),
  {
    hasBackBtn: true,
  }
)

const emit = defineEmits<{
  (e: 'close'): void
  (e: 'success'): void
}>()

const successModal = ref(false)
const passwordRecoveryStore = usePasswordRecoveryStore()

const {
  passwordRecoveryRequestData,
  loading,
  error,
  success,
  validation,
  errors,
} = storeToRefs(passwordRecoveryStore)

const {doPasswordRecovery} = passwordRecoveryStore

const {
  getErrorMessage,
  hasError,
  clearError
} = useValidation(validation, errors)

onBeforeUnmount(() => {
  passwordRecoveryStore.$reset()
  passwordRecoveryStore.$dispose()
})

const handleSubmit = async () => {
  await doPasswordRecovery()
}

watch(
  success,
  () => {
    if (!success.value) {
      return
    }

    emit('success')
    successModal.value = true
  }
)
</script>
