import {defineStore} from 'pinia'
import {ref} from 'vue'
import * as Sentry from '@sentry/vue'
import * as utils from '@sentry/utils'

export const useSentryStore = defineStore('sentry-store', () => {
  const baggage = ref<string>()
  const transparent = ref<string>()

  const getBaggage = () => {
    const baggageData = utils.dynamicSamplingContextToSentryBaggageHeader(Sentry.getCurrentHub().getScope().getTransaction()?.getDynamicSamplingContext())
    if (baggageData === undefined) {
      return baggage.value
    }

    baggage.value = baggageData

    return baggage.value
  }

  const getTransparent = () => {
    const transparentData = Sentry.getCurrentHub().getScope().getSpan()?.toTraceparent()
    if (transparentData === undefined) {
      return transparent.value
    }

    transparent.value = transparentData

    return transparent.value
  }

  return {
    getTransparent,
    getBaggage,
  }
})
