import {defineStore} from 'pinia'
import {useGetApi} from 'src/uses/Api/useGetApi'
import {ref} from 'vue'
import {BankItem} from 'src/models/Banks/BankItem'
import {ProductEnum} from 'src/models/Applications/ProductEnum'

export const useBankFilterStore = defineStore('banks-filter-list', () => {
  const {
    loading,
    get,
    status,
    response,
  } = useGetApi<BankItem[]>()

  const banks = ref<BankItem[]>([])

  const getOptionsByProduct = (productType?: ProductEnum) => {
    return banks.value
      .filter(bank => {
        if (!productType || !bank.product_types) {
          return true
        }

        return bank.product_types.includes(productType)
      })
      .map(bank => ({
        label: bank.title_short,
        value: bank.id,
      }))
  }

  const load = async () => {
    if (loading.value) {
      return
    }

    await get(
      'v1/bank/list/all',
    )

    if (status.value === 200 && response && response.value) {
      banks.value = response.value
    }
  }

  return {
    loading,
    banks,
    load,
    getOptionsByProduct,
  }
})
