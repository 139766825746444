import {GridColumnEditableSettings} from 'src/models/Grid/GridColumnSettings'
import {UserRoleEnum} from 'src/models/User/UserRoleEnum'

export const ListingsGridColumns: GridColumnEditableSettings[] = [
  {
    field: '',
    name: 'title',
    required: true,
    label: 'Наименование',
    align: 'left',
    classes: 'text-no-wrap',
    visible: true,
    editable: true,
    order: 1,
    sortable: true,
  },
  {
    field: '',
    name: 'count',
    required: true,
    label: 'Кол-во записей',
    align: 'left',
    classes: 'text-no-wrap',
    visible: true,
    editable: true,
    order: 2,
    sortable: true,
  },
  {
    field: '',
    name: 'updated',
    required: true,
    label: 'Дата обновления',
    align: 'left',
    classes: 'text-no-wrap',
    visible: true,
    editable: true,
    order: 3,
    sortable: false,
    roles: [UserRoleEnum.Admin],
  },
  {
    field: '',
    name: 'actions',
    label: '',
    required: true,
    align: 'center',
    visible: true,
    editable: false,
    order: 4,
  },
]
