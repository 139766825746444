import {computed, ref, Ref} from 'vue'
import {
  ApplicationFormBank,
  ApplicationFormClient,
} from 'src/models/Applications/Form/ApplicationFormResponseData'
import {useUpdateSectionFields} from 'src/uses/Applications/Form/useUpdateSectionFields'
import {FieldUpdate} from 'src/models/Field/FieldUpdate'
import {ApplicationFormUpdateResponse} from 'src/models/Applications/Form/ApplicationFormUpdateResponse'
import {useUpdateClientFounderField} from 'src/uses/Applications/Form/Client/useUpdateClientFounderField'
import {ProductFields} from 'src/models/Applications/Form/ProductFields'
import {FieldsCodesEnum} from 'src/models/FieldsCodesEnum'

export const useClientFoundersFields = (
  client: Ref<ApplicationFormClient | null>,
  banks: Ref<ApplicationFormBank[]>,
  multiOrderId?: number,
  appId?: string,
) => {
  const {
    updateValues,
    updateErrorProcess,
    updateSuccessProcess,
    filterVisible,
    getParentFieldsUpdate,
  } = useUpdateSectionFields()

  const response = ref<ApplicationFormUpdateResponse>()

  const clientFoundersVisibleFields = computed<ProductFields[]>(() => {
    if (!client.value || !client.value.founders) {
      return []
    }

    return (client.value.founders || [])
      .sort((a, b) => {
        const aNumberField = a.fields
          .find(field => field.code === FieldsCodesEnum.CLIENT_HF_NUMBER)
        const bNumberField = b.fields
          .find(field => field.code === FieldsCodesEnum.CLIENT_HF_NUMBER)

        if (!aNumberField || !bNumberField) {
          return 0
        }

        return parseInt(aNumberField.value as string) - parseInt(bNumberField.value as string)
      })
      .map(
        founder => filterVisible(
          founder.fields,
          banks.value
        )
      )
  })

  const updateClientFounderFields = async (fields: FieldUpdate[], founderId: string) => {
    if (!client.value
      || !client.value?.founders
    ) {
      return
    }

    const founderIndex = client.value.founders
      .findIndex(f => f.id === founderId)

    if (founderIndex === -1) {
      return
    }

    const founder = client.value.founders[founderIndex]

    if (clientFoundersVisibleFields.value[founderIndex]) {
      fields.push(...getParentFieldsUpdate(fields, clientFoundersVisibleFields.value[founderIndex]))
    }

    const {
      status,
      errors,
      response: updateResponse,
      update,
    } = useUpdateClientFounderField(multiOrderId, appId)

    updateValues(fields, founder.fields)
    await update(fields, founder.id)

    if (status.value !== 200 && errors.value) {
      updateErrorProcess(fields, founder.fields, errors.value)
      response.value = undefined

      return
    }

    updateSuccessProcess(fields, founder.fields)
    response.value = updateResponse.value
  }

  return {
    clientFoundersVisibleFields,
    response,
    updateClientFounderFields,
  }
}
