<template>
  <div class="q-pa-sm">
    <div :class="classes">
      <slot name="default" />
    </div>
  </div>
</template>

<script setup lang="ts">
import {Platform} from 'quasar'
import {computed} from 'vue'

const classes = computed(() => {
  return {
    'PageBlockWrapper q-pa-lg': true,
    PageBlockWrapper__mobile: Platform.is.mobile
  }
})
</script>
