<template>
  <form-group>
    <q-input
      ref="inputRef"
      v-model="formattedValue"
      class="field-with-top-label overflow-label-visible q-mb-sm"
      label="Сумма кредита, ₽"
      placeholder="0,00"
      debounce="700"
      outlined
      hide-bottom-space
      dense
      @blur="onBlur"
      @focus="onFocus"
    />
  </form-group>
</template>

<script setup lang="ts">
import FormGroup from 'src/components/FormGroup.vue'
import {storeToRefs} from 'pinia'
import {ref, watch, watchEffect} from 'vue'
import {useApplicationsNew} from 'src/stores/Applications/ApplicationsNew/useApplicationsNew'
import {QInput} from 'quasar'
import {useCurrencyInput} from 'src/uses/useCurrencyInput'
import {useScrollTo} from 'src/uses/useScrollTo'

const applicationsNewStore = useApplicationsNew()
const {
  creditAmount,
  creditLastFocused,
} = storeToRefs(applicationsNewStore)

const {
  numberValue,
  formattedValue,
  onBlur,
  onFocus,
} = useCurrencyInput()

const {
  scrollToInvisibleElement,
} = useScrollTo()

const debounceTimeout = ref<number>()

const inputRef = ref<QInput>()

watch(
  creditLastFocused,
  () => {
    if (creditLastFocused.value !== 'creditAmount' || !inputRef.value) {
      return
    }

    scrollToInvisibleElement(inputRef.value.$el)

    inputRef.value.focus()
    setTimeout(() => inputRef.value?.focus(), 500)
  }
)

watchEffect(
  () => {
    numberValue.value = creditAmount.value
  }
)

watch(
  numberValue,
  () => {
    clearTimeout(debounceTimeout.value)
    debounceTimeout.value = setTimeout(
      () => {
        creditAmount.value = numberValue.value || undefined
      },
      700
    ) as never as number
  }
)
</script>
