<template>
  <form-date
    :model-value="props.field.value as string"
    :label="props.label || props.field.title"
    :hint="props.ignoreHint ? undefined : (props.field.help || undefined)"
    :disable="props.field.readonly && !props.ignoreReadonly"
    :error="props.field.error"
    :loading="props.field.loading"
    :min-date="minDate"
    :max-date="maxDate"
    with-top-label
    @update:model-value="update($event)"
  >
    <template #labelPrepend>
      <slot name="labelPrepend" />
    </template>
    <template #labelAppend>
      <slot name="labelAppend" />
    </template>
  </form-date>
</template>

<script setup lang="ts">
import {computed} from 'vue'
import {
  ApplicationFormField, ApplicationFormFieldRuleEnum,
} from 'src/models/Applications/Form/ApplicationFormResponseData'
import {FormElementValue} from 'src/models/Form'
import FormDate from 'src/components/FormElements/FormDate.vue'

const props = defineProps<{
  field: ApplicationFormField,
  label: string | boolean,
  ignoreReadonly: boolean,
  ignoreHint: boolean,
}>()

const emit = defineEmits<{
  (e: 'update:value', value: FormElementValue): void,
}>()

const minDate = computed(() => {
  const rule = props.field.rules
    .find(rule => rule.name === ApplicationFormFieldRuleEnum.minDate)

  return rule ? rule.value : undefined
})

const maxDate = computed(() => {
  const rule = props.field.rules
    .find(rule => rule.name === ApplicationFormFieldRuleEnum.maxDate)

  return rule ? rule.value : undefined
})

const update = (data: FormElementValue) => {
  emit('update:value', data)
}
</script>
