<template>
  <div
    v-if="loadingStore.loading"
    class="LoadingComponent row fit items-center justify-center text-white"
  >
    <div class="text-center">
      <q-circular-progress
        class="q-mb-md"
        color="white"
        size="66px"
        indeterminate
      />
      <div
        v-if="loadingStore.text"
        style="max-width: 600px;"
      >
        <div
          v-for="row in loadingStore.text.split('\n')"
          :key="row"
        >
          {{ row }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {useLoadingStore} from 'src/stores/useLoadingStore'

const loadingStore = useLoadingStore()
</script>

<style scoped lang="scss">
.LoadingComponent {
  position: fixed;
  z-index: 999999999;
  background: rgba(0,0,0,0.7);
  top: 0;
  left: 0;
  user-select: none;
}
</style>
