import {useGetApi} from 'src/uses/Api/useGetApi'
import {
  CalculatorComparisonPart,
  CalculatorConditionalOptions,
  CalculatorOptions,
  CalculatorRelationsEnum
} from 'src/models/Products/Calculator/ProductCalculatorOptions'
import {computed, ref} from 'vue'
import {usePutApi} from 'src/uses/Api/usePutApi'
import {
  ProductRequiredConditionComparisonOperatorEnum,
  ProductRequiredConditionsParamEnum,
} from 'src/models/Products/ProductRequiredControl'

const operators: Record<CalculatorRelationsEnum, string> = {
  [CalculatorRelationsEnum.EQ]: '=',
  [CalculatorRelationsEnum.GT]: '<',
  [CalculatorRelationsEnum.LT]: '>',
}

export const useProductCalculatorOptions = () => {
  const {
    loading,
    get,
    errors,
    error,
    status,
    response,
  } = useGetApi<CalculatorOptions>()

  const {
    loading: putLoading,
    put,
    status: putStatus,
  } = usePutApi<{message: 'success'}, CalculatorOptions>(true)

  const calculatorOptions = ref<CalculatorOptions>()

  const operatorOptions = ref(
    Object.values(CalculatorRelationsEnum).map(key => {
      return {
        label: operators[key],
        value: key
      }
    })
  )

  const paramComparisonOptions = computed(() => {
    if (!calculatorOptions.value || !calculatorOptions.value.conditional_params) {
      return {}
    }

    return Object.fromEntries(
      calculatorOptions.value.conditional_params
        .map(param => {
          return [
            param.param,
            param.relations.length === 1
          ]
        })
    )
  })

  const paramValueTypesAndOptions = computed(() => {
    if (!calculatorOptions.value || !calculatorOptions.value.conditional_params) {
      return {}
    }

    //console.log(calculatorOptions.value.conditional_params)

    return Object.fromEntries(
      calculatorOptions.value.conditional_params
        .map(param => {
          const options = []
          for (const value in param.options) {
            options.push({
              label: param.options[value],
              value,
            })
          }
          return [
            param.param,
            {
              type: param.type,
              multiple: param.multiple,
              options
            }
          ]
        })
    )
  })

  const addCondition = () => {
    if (!calculatorOptions.value) {
      return
    }

    if (!calculatorOptions.value?.conditional_options) {
      calculatorOptions.value.conditional_options = []
    }

    calculatorOptions.value.conditional_options.push({
      comparison: {
        parts: [{
          operator: ProductRequiredConditionComparisonOperatorEnum.EQ,
          param: ProductRequiredConditionsParamEnum.GUARANTEE_TOTAL,
          value: 0
        }]
      },
      date_of_entry_into_force_enabled: false,
      issue_form_enabled: false,
      bank_commission_enabled: false,
      bank_commission_percent: 0,
      bank_commission_comment: '',
      agent_commission_enabled: false,
      agent_commission_percent: 0,
      agent_commission_comment: '',
      calculator_enabled: false
    })
  }

  const removeCondition = (key: number) => {
    if (!calculatorOptions.value || !calculatorOptions.value.conditional_options) {
      return
    }

    calculatorOptions.value.conditional_options.splice(key, 1)
  }

  const addParam = (key: number) => {
    if (!calculatorOptions.value || !calculatorOptions.value.conditional_options) {
      return
    }
    if (!calculatorOptions.value.conditional_options[key]) {
      return
    }

    calculatorOptions.value.conditional_options[key].comparison.parts.push({
      operator: ProductRequiredConditionComparisonOperatorEnum.EQ,
      param: ProductRequiredConditionsParamEnum.GUARANTEE_TOTAL,
      value: null
    })
  }

  const removeParam = (conditionKey: number, paramKey: number) => {
    if (!calculatorOptions.value || !calculatorOptions.value.conditional_options) {
      return
    }

    calculatorOptions.value.conditional_options[conditionKey].comparison.parts.splice(paramKey, 1)
  }

  const onChangeParam = (conditionKey: number, paramKey: number) => {
    if (!calculatorOptions.value || !calculatorOptions.value.conditional_options || !calculatorOptions.value.conditional_options) {
      return
    }

    calculatorOptions.value.conditional_options[conditionKey].comparison.parts.map((param, searchParamKey) => {
      if (searchParamKey === paramKey) {
        param.value = null
      }
      return param
    })
  }

  const isValid = computed(() => {
    if (!calculatorOptions.value) {
      return false
    }

    if (!calculatorOptions.value.conditional_options) {
      return true
    }

    let isValidParams = true

    if (calculatorOptions.value.bank_commission_enabled
      && !(calculatorOptions.value?.bank_commission_percent && calculatorOptions.value?.bank_commission_comment)
    ) {
      return false
    }
    if (calculatorOptions.value.agent_commission_enabled
      && !(calculatorOptions.value?.agent_commission_percent && calculatorOptions.value?.agent_commission_comment)
    ) {
      return false
    }

    calculatorOptions.value.conditional_options.forEach((condition: CalculatorConditionalOptions) => {
      condition.comparison.parts.forEach((param: CalculatorComparisonPart) => {
        if (!param.value || !param.operator) {
          isValidParams = false
        }
      })
      if (condition.bank_commission_enabled && !(condition.bank_commission_percent && condition.bank_commission_comment)) {
        isValidParams = false
      }
      if (condition.agent_commission_enabled && !(condition.agent_commission_percent && condition.agent_commission_comment)) {
        isValidParams = false
      }
    })

    return isValidParams
  })

  const loadCalculatorOptions = async (id: string) => {
    await get(`v1/admin/product/${id}/calculator-options`)

    if (status.value === 200 && response && response.value) {
      calculatorOptions.value = response.value
    }
  }

  const save = async (id: string) => {
    if (!calculatorOptions.value) {
      return
    }

    const data: CalculatorOptions = {
      ...calculatorOptions.value,
      agent_commission_percent: Number(calculatorOptions.value.agent_commission_percent),
      bank_commission_percent: Number(calculatorOptions.value.bank_commission_percent)
    }

    if (calculatorOptions.value?.conditional_options) {
      data.conditional_options = calculatorOptions.value?.conditional_options.map(option => {
        return {
          ...option,
          agent_commission_percent: Number(option.agent_commission_percent),
          bank_commission_percent: Number(option.bank_commission_percent)
        }
      })
    }
    await put(
      `v1/admin/product/${id}/calculator-options`,
      data
    )
  }

  return {
    loading,
    status,
    errors,
    error,
    calculatorOptions,
    loadCalculatorOptions,

    save,
    putLoading,
    putStatus,

    addCondition,
    addParam,
    removeCondition,
    removeParam,
    onChangeParam,
    paramComparisonOptions,
    paramValueTypesAndOptions,
    operatorOptions,
    isValid,
  }
}
