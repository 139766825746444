import {defineStore} from 'pinia'
import {UserProfile} from 'src/models/User/UserProfile'
import {ref} from 'vue'

declare const ym: (id: number, method: string, params?: unknown) => void

export const useYMStore = defineStore(
  'YM',
  () => {
    const id = 95290970

    const orderNumbers = ref<string[]>([])

    const setYm = (method: string, value: unknown) => {
      if (typeof ym !== 'function') {
        return
      }

      ym(id, method, value)
    }

    const setUser = (profile: UserProfile) => {
      setYm('setUserID', profile.user_id.toString())
      setParams({
        user_role: profile.role,
        user_name: profile.name.full,
        agent_inn: profile.inn,
        UserID: profile.user_id,
        orders: orderNumbers.value,
      })
    }

    const setParams = (params: object) => {
      setYm('userParams', params)
    }

    const setOrderNumber = (number: string) => {
      orderNumbers.value.push(number)
      setParams({
        orders: orderNumbers.value,
      })
    }

    return {
      orderNumbers,
      setYm,
      setUser,
      setParams,
      setOrderNumber,
    }
  }
)
