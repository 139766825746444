<template>
  <q-card
    :class="{'news-big-card': isBig, 'news-small-card': !isBig, 'cursor-pointer': true}"
    flat
    @click="open"
  >
    <div style="position: relative">
      <q-img
        :ratio="isBig ? 16/7.846 : 1"
        :src="item.picture || ''"
        class="news-picture"
      >
        <div
          v-if="!item.picture"
          style="background: none"
          class="absolute-full text-subtitle2 flex flex-center"
        >
          <div
            class="flex flex-center"
            :style="isBig ? 'width: 55%' : 'width: 70%'"
          >
            <q-img
              :src="`images/${item.topic}_icon.png`"
              class="q-mt-md"
            />
          </div>
        </div>
      </q-img>
    </div>
    <q-card-section class="q-pa-none q-pb-md q-pt-sm">
      <div class="news-topic q-mt-sm q-mb-sm">{{ NewsDescriptions.getNewsTopicDescription(item.topic) }}</div>
      <div
        :class="isBig ? 'news-title-big ellipsis-2-lines' : 'news-title-small ellipsis-3-lines'"
      >
        {{ item.title }}
      </div>
      <news-item-dialog
        v-if="item?.id"
        v-model="dialog"
        :item="item"
      />
      <div class="news-publication-date text-gray q-mt-md">{{ item.publication_date }}</div>
    </q-card-section>
  </q-card>
</template>

<script setup lang="ts">
import {computed} from 'vue'
import {NewsDescriptions} from 'src/models/News/NewsDescriptions'
import {NewsItemPreview} from 'src/models/News/NewsItemPreview'
import {useDialog} from 'src/uses/Dialogs/useDialog'
import NewsItemDialog from 'src/components/News/NewsItemDialog.vue'

const props = defineProps<{
  item: NewsItemPreview,
  isBig: boolean,
}>()
const item = computed(() => props.item)
const isBig = computed(() => props.isBig)

const {dialog, show} = useDialog()

const open = async () => {
  show()
}
</script>

<style lang="scss">
.news-topic, .news-publication-date {
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #757575;
}

.news-big-card {
}

.news-small-card {
}

.news-picture {
  border-radius: 5px;
  background: rgb(30,137,231);
  background: radial-gradient(circle, rgba(30,137,231,1) 0%, rgba(111,188,255,1) 0%, rgba(29,134,227,1) 72%);
}

</style>
