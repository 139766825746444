import {usePostApi} from 'src/uses/Api/usePostApi'
import {usePutApi} from 'src/uses/Api/usePutApi'
import {computed, ref} from 'vue'
import {HelpBlock} from 'src/models/Help/HelpBlock'

export const useCreateUpdateHelpBlock = () => {
  const {
    post,
    loading: postLoading,
    status: postStatus,
    errors: postErrors,
  } = usePostApi<HelpBlock, HelpBlock>()

  const {
    put,
    loading: putLoading,
    status: putStatus,
    errors: putErrors,
  } = usePutApi<HelpBlock, HelpBlock>()

  const helpBlockCreateUpdateData = ref<HelpBlock>({
    title: '',
  })

  const status = computed(() => {
    return helpBlockCreateUpdateData.value.id
      ? putStatus.value
      : postStatus.value
  })

  const errors = computed({
    get: () => {
      return helpBlockCreateUpdateData.value.id
        ? putErrors.value
        : postErrors.value
    },
    set: (errors: Record<string, string> | undefined) => {
      if (helpBlockCreateUpdateData.value.id) {
        putErrors.value = errors
        return
      }
      postErrors.value = errors
    }
  })

  const loading = computed(() => {
    return helpBlockCreateUpdateData.value.id
      ? putLoading.value
      : postLoading.value
  })

  const create = async () => {
    await post('admin/help/blocks', helpBlockCreateUpdateData.value)
  }

  const update = async () => {
    if (!helpBlockCreateUpdateData.value.id) {
      return
    }

    await put(`admin/help/blocks/${helpBlockCreateUpdateData.value.id}`, helpBlockCreateUpdateData.value)
  }

  return {
    helpBlockCreateUpdateData,
    status,
    putStatus,
    postStatus,
    putErrors,
    postErrors,
    errors,
    loading,
    create,
    update,
  }
}
