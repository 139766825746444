import {createI18n} from 'vue-i18n'
import en from 'src/locales/en'
import ru from 'src/locales/ru'

export default createI18n({
  locale: 'ru',
  fallbackLocale: 'en',
  globalInjection: true,
  messages: {
    en,
    ru,
  }
})
