<template>
  <page-block-wrapper>
    <div class="profile-wrapper">
      <reports-grid />
    </div>
  </page-block-wrapper>
</template>
<script setup lang="ts">
import ReportsGrid from 'src/components/Profile/Reports/ReportsGrid.vue'
import PageBlockWrapper from 'src/components/PageBlockWrapper.vue'
import {useMetaStore} from 'src/stores/useMetaStore'

useMetaStore().setTitle('Отчеты')
</script>
