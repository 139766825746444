import {defineStore, storeToRefs} from 'pinia'
import {useGetApi} from 'src/uses/Api/useGetApi'
import {ref} from 'vue'
import {useGridPagination} from 'src/uses/Grid/useGridPagination'
import {ClientsListResponseData} from 'src/models/Clients/ClientsListResponseData'
import {useClientsListFiltersStore} from 'src/stores/Clients/useClientsListFiltersStore'
import {ClientsListItem} from 'src/models/Clients/ClientsListItem'
import {useClientsGridSortStore} from 'src/stores/Clients/useClientsGridSortStore'
import {useDialog} from 'src/uses/Dialogs/useDialog'
import {useQuasar} from 'quasar'
import {useProfileStore} from 'stores/Login/useProfileStore'

export const useClientsListStore = defineStore('clients-list', () => {
  const {
    loading,
    get,
    errors,
    error,
    status,
    response,
  } = useGetApi<ClientsListResponseData>()

  const {
    pagination,
    gridPagination,
    perPageOptions,
    getFromStorage,
    noMorePages,
    setPagination,
    onRequestPagination,
    setPage,
    setNextPage,
  } = useGridPagination('clients-list')

  const $q = useQuasar()

  const {
    isAdmin,
    isJuniorAdmin,
  } = useProfileStore()

  const clientsGridSortStore = useClientsGridSortStore()
  const {
    sorting,
  } = storeToRefs(clientsGridSortStore)

  const filtersStore = useClientsListFiltersStore()
  const {
    getFiltersQuery,
    clear
  } = filtersStore
  const {clearable} = storeToRefs(filtersStore)

  const items = ref<ClientsListItem[]>([])

  const {
    dialog: filerDialog,
    show: filterDialogShow
  } = useDialog()

  const updateFilters = () => {
    setPage(1)
    load()
  }

  const onClear = async () => {
    await clear()
    updateFilters()
  }

  const load = async () => {
    const url = isAdmin() || isJuniorAdmin()
      ? 'v1/admin/client/list'
      : 'v1/client/list'
    await getFromStorage()
    await get(
      url,
      {
        page: pagination.value?.page,
        perpage: pagination.value?.per_page,
        ...sorting.value,
        ...getFiltersQuery()
      }
    )

    if (status.value === 200 && response && response.value?.items) {
      if ($q.platform.is.mobile && pagination.value?.page !== 1) {
        items.value = items.value.concat(response.value.items)
      } else {
        items.value = response.value.items
      }
      setPagination(response.value?.pagination)
    }
  }

  return {
    loading,
    get,
    errors,
    error,
    items,
    pagination,
    gridPagination,
    noMorePages,
    perPageOptions,
    onRequestPagination,
    load,
    setPage,
    setNextPage,

    filerDialog,
    filterDialogShow,
    clearable,
    onClear
  }
})
