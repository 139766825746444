import moment from 'moment/moment'
import {Chat, ChatMessage} from 'src/models/Chat'

export const useChat = () => {
  const getMessagesByDay = (chat: Chat) => {
    return chat.messages
  }

  const getMessageTime = (mess: ChatMessage) => {
    return moment(mess.date).format('HH:mm')
  }

  const getFormatDate = (day: string) => {
    const monthList = [
      'января',
      'февраля',
      'марта',
      'апреля',
      'мая',
      'июня',
      'июля',
      'августа',
      'сентбря',
      'октября',
      'ноября',
      'декабря',
    ]
    const chatDay = moment(day).get('date')
    const month = moment(day).month()
    return `${chatDay} ${monthList[month]}`
  }

  const prepareMessage = (msg: string) => {
    return msg
      .replaceAll('\n', '<br>')
      .replaceAll(
        /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/ig,
        '<a href="$1" target="_blank">$1</a>'
      )
  }

  const getIconByAuthorType = (type: string) => {
    if (['Администратор', 'Система', 'external'].includes(type)) {
      return 'svguse:icons.svg?2#app-cloud-bold'
    }

    if (['Агент', 'Агентский менеджер', 'Оператор'].includes(type)) {
      return 'svguse:icons.svg?2#app-user-circle-bold'
    }

    if (['Банк'].includes(type)) {
      return 'svguse:icons.svg?2#app-library-bold'
    }

    return ''
  }

  return {
    getMessagesByDay,
    getMessageTime,
    getFormatDate,
    prepareMessage,
    getIconByAuthorType,
  }
}
