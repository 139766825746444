<template>
  <q-expansion-item
    v-if="isShow(props.item)"
    :model-value="props.item.active"
    :icon="props.item.icon"
    :label="props.item.label"
    :loading="item.menuToName === 'forms-list' && loadingForms"
    :duration="50"
    :group="`somegroup${props.group}`"
    color="blue-grey-4"
    @click.capture="onClickExpandable(props.item)"
  >
    <template #header>
      <q-item-section avatar>
        <q-icon
          :name="props.item.icon"
          color="blue-grey-4"
        />
        <q-menu
          v-if="canShowSubMenu"
          v-model="menu"
          anchor="top right"
          self="top left"
          :offset="[25, 0]"
          no-parent-event
        >
          <q-list
            class="menuList ignoreLastChild bg-blue-grey-10"
            style="min-width: 200px;"
          >
            <template
              v-for="(subItem, Index) in props.item.children || []"
              :key="Index"
            >
              <menu-item
                v-if="!subItem.children"
                :item="subItem"
                :mini="props.mini"
              />
              <menu-expansion-item
                v-else
                :item="subItem"
                :mini="props.mini"
                :group="props.group + 1"
              />
            </template>
          </q-list>
        </q-menu>
        <q-tooltip
          v-if="props.mini"
          self="center left"
          anchor="center right"
          :delay="100"
          :offset="[20, 0]"
        >
          {{ props.item.label }}
        </q-tooltip>
      </q-item-section>
      <q-item-section>
        {{ props.item.label }}
      </q-item-section>
    </template>
    <template
      v-for="(subItem, Index) in props.item.children || []"
      :key="Index"
    >
      <menu-item
        v-if="!subItem.children"
        :item="subItem"
        :mini="props.mini"
      />
      <menu-expansion-item
        v-else
        :item="subItem"
        :mini="props.mini"
        :group="props.group + 1"
      />
    </template>
  </q-expansion-item>
</template>

<script setup lang="ts">
import {MenuItem as MenuItemModel} from 'src/models/MenuItem'
import {useMenuStore} from 'stores/useMenuStore'
import {useAuthStore} from 'stores/Login/useAuthStore'
import {storeToRefs} from 'pinia'
import MenuItem from 'components/Menu/MenuItem.vue'
import {computed, ref} from 'vue'

const props = withDefaults(
  defineProps<{
    item: MenuItemModel
    mini: boolean
    group?: number
  }>(),
  {
    group: 1,
  }
)

const menuStore = useMenuStore()
const {
  isShow,
  loadForms,
} = menuStore
const {
  forms,
  loading: loadingForms,
} = storeToRefs(menuStore)

const authStore = useAuthStore()
const {
  isAdmin,
} = authStore

const menu = ref(false)

const canShowSubMenu = computed(() => {
  return props.mini && props.group === 1
})

const onClickExpandable = async (item: MenuItemModel) => {
  menu.value = !menu.value
  if (isAdmin() && item.menuToName === 'form-editor' && forms.value.length === 0) {
    await loadForms()
  }
  /*if (item.menuToName === 'form-editor' || item.menuToName === 'forms') {
    return
  }*/
  /*if (item.children && !props.mini) {
    return
  }

  navigate(item)*/
}
</script>

<style scoped lang="scss">

</style>
