<template>
  <page-block-wrapper>
    <div class="clients-mobile">
      <clients-grid-mobile />
    </div>
  </page-block-wrapper>
</template>
<script setup lang="ts">
import PageBlockWrapper from 'src/components/PageBlockWrapper.vue'
import {useMetaStore} from 'src/stores/useMetaStore'
import ClientsGridMobile from 'components/Clients/ClientsGridMobile.vue'

useMetaStore().setTitle('Клиенты')
</script>
