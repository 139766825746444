<template>
  <profile-employees-grid-filters-dialog
    v-model="filerDialog"
    :loading="loading"
    @update:filters="updateFilters"
  />
  <div class="q-px-none q-mt-sm">
    <div class="row q-mx-md">
      <q-btn
        label="Все фильтры"
        color="grey-2"
        class="text-blue-grey-4 col q-mt-sm"
        icon-right="svguse:icons.svg?2#app-adjustments"
        unelevated
        no-caps
        @click="filterDialogShow"
      />
      <q-btn
        v-if="clearable"
        color="grey-2"
        class="text-blue-grey-4 q-ml-xs"
        icon-right="svguse:icons.svg?2#app-trash"
        size="14px"
        padding="5px 7px"
        unelevated
        square
        no-caps
        @click="onClear"
      />
    </div>
    <div class="row q-ma-md">
      <div class="col-12">
        <profile-employee-create-modal />
      </div>
    </div>
    <div class="ProfileEmployeesGrid--mobile q-pa-md">
      <div
        v-if="items.length === 0"
        class="ProfileEmployeesGrid--mobile__item q-pa-md"
      >
        <div v-if="filtersStore.isDefaultFilterValue">
          Вы пока не создали сотрудников, нажмите кнопку "Добавить сотрудника" чтобы перейти к создаю учетной записи
        </div>
        <div v-else>
          По вашему запросу ничего не найдено. Попробуйте изменить фильтры
        </div>
      </div>
      <q-infinite-scroll
        ref="scroll"
        style="margin-bottom: 12px"
        @load="onLoad"
      >
        <div class="row">
          <div
            v-for="item in items as EmployeeGridItem[]"
            :key="item.id"
            class="ProfileEmployeesGrid--mobile__item col-12 q-pa-md q-mb-md"
          >
            <div class="q-mb-sm">
              <div class="ProfileEmployeesGrid--title">ФИО</div>
              {{ getFullName(item) }}
            </div>
            <div class="q-mb-sm">
              <div class="ProfileEmployeesGrid--title">E-mail</div>
              {{ item.email }}
            </div>
            <div class="q-mb-sm">
              <div class="ProfileEmployeesGrid--title">Роль</div>
              {{ UserRoles.getUserRoleDescription(item.role) }}
            </div>
            <div class="q-mb-sm">
              <div class="ProfileEmployeesGrid--title">Дата регистрации</div>
              {{ item.date }}
            </div>
            <div class="q-mb-md">
              <div class="ProfileEmployeesGrid--title">Статус</div>
              {{ UserStatuses.getUserStatusDescription(item.status) }}
            </div>
            <div class="row q-gutter-sm no-wrap">
              <profile-employee-update-modal
                v-if="item"
                :item="item"
              />
              <profile-employee-delete-modal
                v-if="item"
                :item-id="item.id"
              />
            </div>
          </div>
        </div>
        <template #loading>
          <div class="text-center">
            <q-circular-progress
              class="q-mt-md"
              color="primary"
              size="md"
              indeterminate
            />
          </div>
        </template>
      </q-infinite-scroll>
    </div>
  </div>
</template>

<script setup lang="ts">
import {storeToRefs} from 'pinia'
import {onBeforeUnmount, ref, watch} from 'vue'
import ProfileEmployeesGridFiltersDialog from 'components/Profile/Employees/ProfileEmployeesGridFiltersDialog.vue'
import {EmployeeGridItem} from 'src/models/Profile/Employees/EmployeeGridItem'
import {useEmployeeGridFiltersStore} from 'stores/Profile/Employees/useEmployeesGridFiltersStore'
import {useEmployeesGridStore} from 'stores/Profile/Employees/useEmployeesGridStore'
import ProfileEmployeeCreateModal from 'components/Profile/Employees/ProfileEmployeeCreateModal.vue'
import {UserRoles} from 'src/models/User/UserRoleDescriptions'
import {UserStatuses} from 'src/models/User/UserStatusDescriptions'
import ProfileEmployeeUpdateModal from 'components/Profile/Employees/ProfileEmployeeUpdateModal.vue'
import ProfileEmployeeDeleteModal from 'components/Profile/Employees/ProfileEmployeeDeleteModal.vue'
import {QInfiniteScroll} from 'quasar'

const filtersStore = useEmployeeGridFiltersStore()
const employeesListStore = useEmployeesGridStore()
const {
  items,
  loading,
  filerDialog,
  clearable,
  noMorePages,
} = storeToRefs(employeesListStore)

const {
  load,
  setPage,
  setNextPage,
  filterDialogShow,
  onClear,
  getFullName,
} = employeesListStore

const scroll = ref<QInfiniteScroll>()

onBeforeUnmount(() => {
  employeesListStore.$reset()
  employeesListStore.$dispose()
  filtersStore.$reset()
  filtersStore.$dispose()
})

const onLoad = async (index: number, done: (stop?: boolean) => void) => {
  if (loading.value) {
    return
  }
  setNextPage()
  await load()
  done()
}

const updateFilters = () => {
  setPage(1)
  load()
}

watch(
  noMorePages,
  () => {
    if (!scroll.value) {
      return
    }

    if (noMorePages.value) {
      scroll.value.stop()

      return
    }

    scroll.value.resume()
  }
)
</script>

<style lang="scss">
.ProfileEmployeesGrid--mobile {
  background: $blue-1;

  .ProfileEmployeesGrid--mobile__item {
    background-color: #FFFFFF;
    border-radius: 16px;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0;
    text-align: left;
    color: #424242;

    .ProfileEmployeesGrid--title {
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0;
      text-align: left;

      color: #9E9E9E;
    }
  }

  .createButton {
    text-align: center;
    .icon {
      background: white;
      border-radius: 6px;
      line-height: 0;
      margin-left: 10px;
    }

    .q-btn {
      border-radius: 6px;
    }
  }
}
</style>
