import {ref} from 'vue'
import {useGetApi} from 'src/uses/Api/useGetApi'
import {Contractor} from 'src/models/Contractors/Contractor'

export const useGetContractorItem = () => {
  const {
    get,
    response,
    status,
    loading,
    error,
    errors,
  } = useGetApi<Contractor>()

  const contractorsResponseData = ref<Contractor>({
    id: '',
    inn: '',
    title: '',
    manager_fio: '',
    address: '',
  })

  const load = async (id: string) => {
    await get(`v2/contractors/${id}`)
    if (status.value === 200 && response.value) {
      contractorsResponseData.value = response.value
    }
  }

  const getContractorRequestData = () => {
    return contractorsResponseData.value
  }

  return {
    contractorsResponseData,
    loading,
    response,
    status,
    error,
    errors,
    load,
    getContractorRequestData
  }
}
