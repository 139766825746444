import {usePutApi} from 'src/uses/Api/usePutApi'

export const useChangeNumber = () => {
  const {
    loading,
    put,
    status,
    response,
  } = usePutApi<unknown, {number: string}>()

  const save = async (id: string, number: string) => {
    await put(
      `v1/control-center/${id}/number`,
      {
        number,
      }
    )
  }

  return {
    save,
    loading,
    status,
    response,
  }
}
