import {AuthedGuard} from 'src/router/quards/AuthedGuard'
import {UserRoleEnum} from 'src/models/User/UserRoleEnum'

export const formEditor = [
  {
    path: '/form-editor/EBG',
    name: 'form-editor-ebg',
    component: () => import('../layouts/MainLayout.vue'),
    children: [
      {
        path: ':formId',
        name: 'form-item-ebg',
        component: () => import('../views/FormEditor/FormEditor.vue'),
        meta: {
          guards: [
            AuthedGuard([
              UserRoleEnum.Admin,
              UserRoleEnum.JuniorAdmin,
              UserRoleEnum.MiddleAdmin,
            ]),
          ],
        },
      },
    ]
  },
  {
    path: '/form-editor/KIK',
    name: 'form-editor-kik',
    component: () => import('../layouts/MainLayout.vue'),
    children: [
      {
        path: ':formId',
        name: 'form-item-kik',
        component: () => import('../views/FormEditor/FormEditor.vue'),
        meta: {
          guards: [
            AuthedGuard([
              UserRoleEnum.Admin,
              UserRoleEnum.JuniorAdmin,
              UserRoleEnum.MiddleAdmin,
            ]),
          ],
        },
      },
    ]
  },
  {
    path: '/form-editor/RKO',
    name: 'form-editor-rko',
    component: () => import('../layouts/MainLayout.vue'),
    children: [
      {
        path: ':formId',
        name: 'form-item-rko',
        component: () => import('../views/FormEditor/FormEditor.vue'),
        meta: {
          guards: [
            AuthedGuard([
              UserRoleEnum.Admin,
              UserRoleEnum.JuniorAdmin,
              UserRoleEnum.MiddleAdmin,
            ]),
          ],
        },
      },
    ]
  },
]
