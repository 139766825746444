import {UserStateEnum} from 'src/models/User/UserStateEnum'

export const useProfileRequisites = () => {
  const showAlertMessage = (state: UserStateEnum) => {
    return [UserStateEnum.NeedRequisites, UserStateEnum.NeedDocuments].includes(state)
  }

  return {
    showAlertMessage
  }
}
