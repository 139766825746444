<template>
  <div class="bg-blue-1">
    <div class="row q-mb-md">
      <div class="col q-mr-md">
        <div class="row q-col-gutter-x-md">
          <div class="col-7 white-bg-field">
            <q-input
              v-model="search"
              label="Поиск по наименованию"
              outlined
              hide-bottom-space
              dense
              emit-value
              map-options
              options-dense
              hide-dropdown-icon
              clearable
              @clear="clearSearchFilter"
              @keyup="changeFilter"
            >
              <template #prepend>
                <q-icon name="svguse:icons.svg?2#app-search" />
              </template>
            </q-input>
          </div>
          <div class="col white-bg-field">
            <q-select
              v-model="filterBlock"
              :options="blocksForSelect"
              label="Раздел"
              outlined
              hide-hint
              hide-bottom-space
              dense
              @update:model-value="changeFilter"
            />
          </div>
        </div>
      </div>
      <settings-help-create-update-block-modal
        @success="updateList"
      />
    </div>
    <settings-help-block
      v-for="(helpBlock, index) in filteredBlocks"
      :key="index"
      :help-block="helpBlock"
      @change="updateList"
    />
  </div>
</template>

<script setup lang="ts">
import {onBeforeMount} from 'vue'
import {storeToRefs} from 'pinia'
import SettingsHelpBlock from 'components/Settings/Help/SettingsHelpBlock.vue'
import {useHelpStore} from 'stores/Help/useHelpStore'
import SettingsHelpCreateUpdateBlockModal from 'components/Settings/Help/SettingsHelpCreateUpdateBlockModal.vue'

const helpStore = useHelpStore()
const {
  load,
  changeFilter,
  clearSearchFilter,
} = helpStore
const {
  search,
  filterBlock,
  blocksForSelect,
  filteredBlocks,
} = storeToRefs(helpStore)

const updateList = async () => {
  await load()
}

onBeforeMount(async () => {
  await load()
})

</script>
