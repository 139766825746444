import {ref} from 'vue'

export const useBlock = () => {
  const blocked = ref<string[]>([])

  const isBlocked = (name: string) => {
    return blocked.value.includes(name)
  }

  const block = (name: string) => {
    //console.log('block ' + name)
    blocked.value.push(name)
    blocked.value = blocked.value.filter(
      (value, index, self) => self.indexOf(value) === index
    )
  }

  const clearAllBlocks = () => {
    blocked.value = []
  }

  const unblock = (name: string) => {
    //console.log('unblock ' + name)
    blocked.value = blocked.value.filter(val => val !== name)
  }

  return {
    blocked,
    isBlocked,
    block,
    unblock,
    clearAllBlocks,
  }
}
