import {GridColumnEditableSettings} from 'src/models/Grid/GridColumnSettings'
import {UserRoleEnum} from 'src/models/User/UserRoleEnum'

export const ClientsGridColumns: GridColumnEditableSettings[] = [
  {
    field: '',
    name: 'title',
    required: true,
    label: 'Наименования / имя',
    align: 'left',
    classes: 'text-no-wrap',
    visible: true,
    editable: true,
    order: 1,
    sortable: true,
  },
  {
    field: '',
    name: 'inn',
    required: true,
    label: 'ИНН',
    align: 'left',
    classes: 'text-no-wrap',
    visible: true,
    editable: true,
    order: 2,
    sortable: true,
  },
  {
    name: 'region',
    field: '',
    required: true,
    label: 'Регион',
    align: 'left',
    visible: true,
    editable: true,
    order: 3,
    sortable: true,
    roles: [
      UserRoleEnum.Agent,
      UserRoleEnum.Admin,
      UserRoleEnum.MiddleAdmin,
      UserRoleEnum.JuniorAdmin,
    ],
  },
  {
    field: '',
    name: 'timezone',
    required: true,
    label: 'Время клиента',
    align: 'left',
    visible: true,
    editable: true,
    order: 5,
    sortable: true,
    roles: [
      UserRoleEnum.Agent,
      UserRoleEnum.Admin,
      UserRoleEnum.MiddleAdmin,
      UserRoleEnum.JuniorAdmin,
    ],
  },
  {
    name: 'agent',
    field: '',
    required: true,
    label: 'Название агента',
    align: 'left',
    visible: true,
    editable: true,
    order: 4,
    sortable: false,
    roles: [
      UserRoleEnum.Operator,
      UserRoleEnum.Admin,
      UserRoleEnum.MiddleAdmin,
      UserRoleEnum.JuniorAdmin,
    ],
  },
  {
    field: '',
    name: 'agentCode',
    required: true,
    label: 'Код агента',
    align: 'left',
    classes: 'text-no-wrap',
    visible: true,
    editable: true,
    order: 6,
    sortable: false,
    roles: [
      UserRoleEnum.Admin,
      UserRoleEnum.MiddleAdmin,
      UserRoleEnum.JuniorAdmin,
    ],
  },
  {
    field: '',
    name: 'actions',
    label: '',
    required: true,
    align: 'center',
    visible: true,
    editable: false,
    order: 7,
    roles: [
      UserRoleEnum.Agent,
      UserRoleEnum.HeadEmployee,
      UserRoleEnum.Operator,
      UserRoleEnum.AgentManager,
    ],
  },
]
