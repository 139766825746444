import {
  ApplicationFormFinanceField,
  ApplicationFormBank,
  ApplicationFormDocument,
  ApplicationFormField
} from 'src/models/Applications/Form/ApplicationFormResponseData'

export enum ApplicationFormUpdateBlockEnum {
  order = 'order',
  client = 'client',
  finance = 'finance',
  documents = 'documents',
}

export interface ApplicationFormUpdateChanges {
  fields: ApplicationFormField[]
  documents: ApplicationFormDocument[]
  finance: {
    fields: ApplicationFormFinanceField[]
  }
  banks: ApplicationFormBank[]
}

export interface ApplicationFormCreateFounderResponse {
  changes: ApplicationFormUpdateChanges,
  founder_id: string,
}

export interface ApplicationFormUpdateResponse {
  changes: ApplicationFormUpdateChanges,
}
