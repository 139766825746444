import {defineStore, storeToRefs} from 'pinia'
import {useGetApi} from 'src/uses/Api/useGetApi'
import {ref} from 'vue'
import {useGridPagination} from 'src/uses/Grid/useGridPagination'
import {UsersListResponseData} from 'src/models/Profiles/Users/UsersListResponseData'
import {useUsersListFiltersStore} from 'src/stores/Profiles/Users/useUsersListFiltersStore'
import {UserListItem} from 'src/models/Profiles/Users/UserListItem'
import {useUsersGridSortStore} from 'src/stores/Profiles/Users/useUsersGridSortStore'

export const useUsersListStore = defineStore('profiles-users-list', () => {
  const {
    loading,
    get,
    errors,
    error,
    status,
    response,
  } = useGetApi<UsersListResponseData>()

  const usersGridSortStore = useUsersGridSortStore()
  const {
    sorting,
  } = storeToRefs(usersGridSortStore)

  const {
    pagination,
    gridPagination,
    perPageOptions,
    getFromStorage,
    setPagination,
    onRequestPagination,
    setPage,
  } = useGridPagination('profiles-users-list')

  const filtersStore = useUsersListFiltersStore()
  const {getFiltersQuery} = filtersStore

  const items = ref<UserListItem[]>([])

  const getFullName = (item: UserListItem): string => {
    return `${item.name_first || ''} ${item.name_middle || ''} ${item.name_last || ''}`
  }

  const load = async () => {
    await getFromStorage()
    await get(
      'admin/user/list',
      {
        page: pagination.value?.page,
        perpage: pagination.value?.per_page,
        ...getFiltersQuery(),
        ...sorting.value,
      }
    )

    if (status.value === 200 && response && response.value?.items) {
      items.value = response.value?.items
      setPagination(response.value?.pagination)
    }
  }

  return {
    loading,
    get,
    errors,
    error,
    items,
    pagination,
    gridPagination,
    perPageOptions,
    onRequestPagination,
    load,
    setPage,
    getFullName,
  }
})
