import {usePostApi} from 'src/uses/Api/usePostApi'
import {ApplicationCreatePayload} from 'src/models/Applications/New/ApplicationCreatePayload'
import {ApplicationCreateResponse} from 'src/models/Applications/New/ApplicationCreateResponse'

export const useApplicationsNewSend = () => {
  const {
    loading,
    post,
    status,
    response,
    errors,
  } = usePostApi<ApplicationCreateResponse, ApplicationCreatePayload>()

  const create = async (payload: ApplicationCreatePayload) => {
    await post('v1/order/create', payload)
  }

  return {
    errors,
    response,
    status,
    loading,
    create,
  }
}
