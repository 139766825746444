import {EnvCodeEnum, useEnvStore} from 'src/stores/useEnvStore'

export const useRnt = () => {
  const {getEnvValue} = useEnvStore()
  const isRntLink = (rnt: string, rntLink: string | null): boolean => {
    return isRntNumber(rnt) || !!rntLink
  }

  const isRntNumber = (rnt: string) => {
    return !isNaN(Number(rnt))
  }

  const getRntLink = (rnt: string, rntLink: string | null) => {
    return (rntLink === null)
      ? `${getEnvValue(EnvCodeEnum.VUE_APP_ZAKUPKI_URL)}${rnt}`
      : rntLink
  }

  return {
    isRntLink,
    isRntNumber,
    getRntLink,
  }
}
