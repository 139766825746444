import {ApplicationKikStatusEnum} from 'src/models/Applications/ApplicationKikStatusEnum'
import {ApplicationKikSubStatusEnum} from 'src/models/Applications/ApplicationKikSubStatusEnum'
import {ApplicationStatusEnum} from 'src/models/Applications/ApplicationStatusEnum'

const statuses: Record<ApplicationKikStatusEnum, string> = {
  [ApplicationKikStatusEnum.all]: 'Все',
  [ApplicationKikStatusEnum.draft]: 'Черновик',
  [ApplicationKikStatusEnum.in_bank]: 'Отправлено в банк',
  [ApplicationKikStatusEnum.approved]: 'Одобрена',
  [ApplicationKikStatusEnum.released]: 'Выдана',
  [ApplicationKikStatusEnum.bank_rejected]: 'Отказ банка',
  [ApplicationKikStatusEnum.client_rejected]: 'Отказ клиента',
  [ApplicationKikStatusEnum.system_rejected]: 'Отказ системы',
  [ApplicationKikStatusEnum.need_sign]: 'Требуется подпись',
  [ApplicationKikStatusEnum.favorites]: 'Избранное',
}

const icons: Record<ApplicationKikStatusEnum, string> = {
  [ApplicationKikStatusEnum.all]: 'app-collection-bold',
  [ApplicationKikStatusEnum.draft]: 'app-clipboard-list-bold',
  [ApplicationKikStatusEnum.in_bank]: 'app-collection-bold',
  [ApplicationKikStatusEnum.approved]: 'app-collection-bold',
  [ApplicationKikStatusEnum.released]: 'app-collection-bold',
  [ApplicationKikStatusEnum.bank_rejected]: 'app-collection-bold',
  [ApplicationKikStatusEnum.client_rejected]: 'app-collection-bold',
  [ApplicationKikStatusEnum.system_rejected]: 'app-collection-bold',
  [ApplicationStatusEnum.need_sign]: 'app-collection-bold',
  [ApplicationKikStatusEnum.favorites]: 'app-collection-bold',
}

const redStatuses: ApplicationKikSubStatusEnum[] = [
  ApplicationKikSubStatusEnum.bank_rejected,
  ApplicationKikSubStatusEnum.client_rejected,
  ApplicationKikSubStatusEnum.system_rejected,
  ApplicationKikSubStatusEnum.archive,
]

const blueStatuses: ApplicationKikSubStatusEnum[] = [
  ApplicationKikSubStatusEnum.client_signed,
  ApplicationKikSubStatusEnum.send_2_bank,
  ApplicationKikSubStatusEnum.in_bank,
  ApplicationKikSubStatusEnum.data_request,
  ApplicationKikSubStatusEnum.data_request_executed,
]

const greenStatuses: ApplicationKikSubStatusEnum[] = [
]

const greyStatuses: ApplicationKikSubStatusEnum[] = [
  ApplicationKikSubStatusEnum.draft,
]

const orangeStatuses: ApplicationKikSubStatusEnum[] = [
]

export const useKikStatus = () => {
  const label = (status: ApplicationKikStatusEnum) => statuses[status] || status

  const icon = (status: ApplicationKikStatusEnum) => icons[status] || status

  const isDraft = (status: ApplicationKikSubStatusEnum) => status === ApplicationKikSubStatusEnum.draft
  const isClientRejected = (status: ApplicationKikSubStatusEnum) => status === ApplicationKikSubStatusEnum.client_rejected

  const isRed = (status: ApplicationKikSubStatusEnum) => redStatuses.includes(status)
  const isBlue = (status: ApplicationKikSubStatusEnum) => blueStatuses.includes(status)
  const isGreen = (status: ApplicationKikSubStatusEnum) => greenStatuses.includes(status)
  const isGrey = (status: ApplicationKikSubStatusEnum) => greyStatuses.includes(status)
  const isOrange = (status: ApplicationKikSubStatusEnum) => orangeStatuses.includes(status)

  const color = (status: ApplicationKikSubStatusEnum) => {
    if (isRed(status)) {
      return 'red-12'
    }

    if (isOrange(status)) {
      return 'orange-6'
    }

    if (isBlue(status)) {
      return 'blue-7'
    }

    if (isGreen(status)) {
      return 'green-14'
    }

    if (isGrey(status)) {
      return 'blue-grey-4'
    }

    return 'green-14'
  }

  const bgColor = (status: ApplicationKikSubStatusEnum) => {
    if (isRed(status)) {
      return 'red-1'
    }

    if (isOrange(status)) {
      return 'orange-1'
    }

    if (isBlue(status)) {
      return 'blue-1'
    }

    if (isGreen(status)) {
      return 'green-1'
    }

    if (isGrey(status)) {
      return 'grey-4'
    }

    return 'green-1'
  }

  return {
    label,
    isDraft,
    isClientRejected,
    isRed,
    isBlue,
    isGreen,
    isGrey,
    isOrange,
    color,
    bgColor,
    icon,
    statuses,
  }
}
