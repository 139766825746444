import {FormHelper} from 'src/helpers/FormHelper'
import {
  ApplicationFormDocumentGroupEnum,
  ApplicationFormDocument,
} from 'src/models/Applications/Form/ApplicationFormResponseData'
import {useGetApi} from 'src/uses/Api/useGetApi'
import {useFile} from 'src/uses/useFile'

export const useApplicationFormDownloadDocument = (multiOrderId?: number, appId?: string) => {
  const {
    error,
    errors,
    get,
    status,
    response,
    loading,
    headers,
  } = useGetApi<Blob>(true, 'blob')

  const {
    getFilenameFromHttpResponseBlob,
    downloadFile,
  } = useFile()

  const prepareDocumentDataQueryString = (document: ApplicationFormDocument) => {
    const data = {
      code: document.code,
      index: document.index,
      year: document.period_year,
      quarter: document.period_quarter,
      founder_id: document.founder_id,
      type: document.location.section_type,
      principal_id: document.principal_id,
      partner_id: document.partner_id,
      //title: document.title,
    }

    return encodeURI(
      Object.entries(data)
        .filter(e => !!e[1])
        .map(e => `${e[0]}=${e[1]}`)
        .join('&')
    )
  }

  const download = async (
    document: ApplicationFormDocument,
    type: ApplicationFormDocumentGroupEnum,
    signed = false,
  ) => {
    if (!multiOrderId && !appId) {
      return
    }

    document.loading = true
    const basePath = multiOrderId
      ? `v2/orders/forms/${multiOrderId}/documents/`
      : `v2/control-center/${appId}/documents/`

    await get(
      `${basePath}${signed ? 'sign' : 'file'}?${prepareDocumentDataQueryString(document)}`,
      FormHelper.getFormDataFromObject({
        id: multiOrderId,
        file: document,
      })
    )

    if (status.value === 200 && response.value) {
      const fileName = getFilenameFromHttpResponseBlob(response.value, headers.value, true) || 'file'
      downloadFile(response.value, fileName)
    }

    document.loading = false
  }

  return {
    error,
    errors,
    status,
    response,
    loading,
    download,
  }
}
