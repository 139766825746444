<template>
  <div
    v-if="props.isShowFilters"
    style="font-size: 0.8rem; align-items: end"
    class="row"
  >
    <slot name="header" />
    <div />
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  isShowFilters: boolean
}>()
</script>

<style lang="scss">
</style>
