<template>
  <q-btn
    size="sm"
    color="blue-grey-4"
    icon="svguse:icons.svg?2#app-users-bold"
    round
    flat
    @click="open"
  />
  <q-dialog
    v-model="dialog"
  >
    <q-card
      class="q-mt-md bg-blue-1"
      style="min-width: 400px; max-width: 600px; border-radius: 4px !important;"
    >
      <q-card-section>
        <div class="modal-title rounded-borders q-pb-none">
          Контрагенты
        </div>
      </q-card-section>
      <q-card-section
        class="bg-blue-grey-10 text-white row items-center q-py-lg"
      >
        <div class="rounded-borders q-pa-sm bg-blue-grey-9">
          <q-icon
            name="svguse:icons.svg?2#app-information-circle"
            size="sm"
            color="orange-6"
          />
        </div>
        <div class="col q-pl-sm text-wgite">
          Добавьте одного или несколько контрагентов для формирований согласий на ОПД, которые будут подписывать клиенты
          в заявках на данный продукт
        </div>
      </q-card-section>
      <q-card-section class="white-bg-field">
        <q-form>
          <div class="row">
            <q-select
              v-model="selected"
              class="field-with-top-label ProductContractors q-mb-md col"
              :options="contractorsListForSelect"
              :loading="loading"
              option-label="title"
              option-value="id"
              hide-dropdown-icon
              outlined
              hide-bottom-space
              hide-selected
              use-input
              fill-input
              dense
              label-slot
              clearable
              @filter="onFilter"
            >
              <template #prepend>
                <q-icon name="svguse:icons.svg?2#app-search" />
              </template>
              <template #option="scope">
                <q-item
                  v-bind="scope.itemProps"
                  class="q-py-sm q-px-md text-no-wrap ellipsis"
                >
                  <div class="row items-center InnOption">
                    <div class="text-grey-9 q-mr-sm">{{ scope.opt.inn }}</div>
                    <div class="text-grey-6">{{ scope.opt.title }}</div>
                  </div>
                </q-item>
              </template>
            </q-select>
            <q-btn
              style="max-height: 48px; margin-top: 32px"
              class="q-ml-md"
              color="primary"
              icon-right="svguse:icons.svg?2#app-plus-1"
              label="Добавить запись"
              no-caps
              :disable="!selected"
              @click="addContractor"
            />
          </div>
        </q-form>
      </q-card-section>
      <q-card-section>
        <span class="text-bold">Контрагенты</span>
        <q-list class="q-mt-sm q-mb-sm">
          <q-item
            v-for="contractor in contractorsList"
            :key="contractor.id"
            class="contractor-item q-mt-xs"
          >
            <q-item-section class="overflow-hidden">{{ contractor.title }}</q-item-section>
            <q-item-section side>
              <q-icon
                class="document-download-icon cursor-pointer"
                name="svguse:icons.svg?2#app-trash"
                color="blue-gray-3"
                @click="removeDocument(contractor)"
              />
            </q-item-section>
          </q-item>
        </q-list>
      </q-card-section>
      <q-card-actions
        class="row justify-end q-py-md"
        style="background: #D5EAFA;"
      >
        <q-btn
          v-close-popup
          size="16px"
          padding="sm lg"
          label="Отмена"
          :disable="loading"
          unelevated
          no-caps
        />
        <q-btn
          padding="sm lg"
          size="16px"
          color="blue-7"
          label="Сохранить"
          :loading="loading"
          unelevated
          no-caps
          :disable="contractorsList.length === 0"
          @click="handleSubmit"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
import {useDialog} from 'src/uses/Dialogs/useDialog'
import {ProductsListItem} from 'src/models/Products/ProductsListItem'
import {useUpdateProductContractorsList} from 'src/uses/Products/useUpdateProductContractorsList'
import {useContractorsGridFiltersStore} from 'stores/Contractors/useContractorsGridFiltersStore'
import {storeToRefs} from 'pinia'

const props = defineProps<{
  product: ProductsListItem
}>()

const emit = defineEmits<{
  (e: 'success'): void,
}>()

const {
  contractorsListForSelect,
  loadContractorsList,
  status,
  loading,
  update,
  init,
  selected,
  contractorsList,
  addContractor,
  removeDocument,
} = useUpdateProductContractorsList()
const filtersStore = useContractorsGridFiltersStore()
const {filters} = storeToRefs(filtersStore)

const {dialog, show, hide} = useDialog()

const open = async () => {
  show()
  await init(props.product.id)
}

const success = () => {
  emit('success')
  hide()
}

const onFilter = (
  input: string,
  update: (fn: () => void) => void
) => {
  update(async () => {
    filters.value.query = input
    await loadContractorsList()
  })
}

const handleSubmit = async () => {
  await update(props.product.id)
  if (status.value === 200 || status.value === 201) {
    success()
  }
}
</script>
<style lang="scss">
body {
  .q-field {
    &.ProductContractors {
      padding-top: 32px !important;
      padding-bottom: 2px;

      .q-field__label {
        top: -32px !important;
        left: -49px !important;
        font-size: 16px !important;
        color: $grey-9;
      }

      input {
        margin-top: 2px;
      }

      input::-webkit-input-placeholder {
        opacity: 1 !important;
        color: $grey-8 !important;
      }
    }
  }
  .contractor-item {
    border-radius: 6px;
    border: 1px dotted #accadc;
    &:hover {
      background-color: #D5EAFA;
    }
  }
}
</style>
