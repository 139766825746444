import {ProductEnum} from 'src/models/Applications/ProductEnum'

export enum ChannelsEnum {
  email = 'email',
  push = 'push',
  sms = 'sms',
}

export interface Channels {
  [ChannelsEnum.email]: boolean
  [ChannelsEnum.push]: boolean
  [ChannelsEnum.sms]: boolean
}

export interface Subscription {
  code: string
  title: string
  channels: Channels,
  product_type: ProductEnum
}

export interface SubscriptionUpdateItem {
  code: string
  channels: Channels
}
