<template>
  <div v-if="!loading">
    <div class="row">
      <div class="col q-mr-sm">
        <field-component
          v-if="rntField && !rntField.invisible"
          :key="String(rntField.value)"
          :field="rntField"
          :banks="banks"
          ignore-width
          @update:value="onUpdate"
        />
      </div>
      <div
        v-if="lotField /*&& !lotField.invisible*/"
        :key="String(lotField.value)"
        style="width: 90px;"
      >
        <field-component
          :field="lotField"
          :banks="banks"
          ignore-width
          @update:value="onUpdate"
        />
      </div>
    </div>
    <div
      v-if="bgSumField && !bgSumField.invisible"
      class="row"
    >
      <field-component
        :key="String(bgSumField.value)"
        :field="bgSumField"
        :banks="banks"
        class="col-12"
        ignore-width
        @update:value="onUpdate"
      />
      <field-component
        v-if="nmcField && !nmcField.invisible"
        :key="String(nmcField.value)"
        :field="nmcField"
        :banks="banks"
        class="col-12"
        :style="!$q.platform.is.mobile ? 'margin-top: -45px;' : ''"
        ignore-width
        @update:value="onUpdate"
      />
    </div>
    <div v-if="$q.platform.is.mobile">
      <div class="row q-mb-sm">
        <div
          v-if="startDateField && !startDateField.invisible"
          :key="String(startDateField.value)"
          class="col q-mr-sm"
        >
          <field-component
            :field="startDateField"
            :banks="banks"
            label="Срок действия банковской гарантии"
            ignore-width
            @update:value="onUpdate"
          />
        </div>
        <div
          v-if="endDateField && !endDateField.invisible"
          :key="String(endDateField.value)"
          class="col q-mr-sm"
        >
          <field-component
            :field="endDateField"
            :banks="banks"
            label=" "
            ignore-width
            @update:value="onUpdate"
          />
        </div>
      </div>
      <div class="row q-mb-sm">
        <div
          v-if="endDateField && startDateField"
          class="white-bg-field"
          style="width: 90px;"
        >
          <q-input
            :model-value="countDays"
            class="field-with-top-label"
            disable
            label="Дней"
            outlined
            hide-bottom-space
            dense
          />
        </div>
        <div
          v-if="startFromReleaseDateField && !startFromReleaseDateField.invisible"
          :key="String(startFromReleaseDateField.value)"
          class="col q-mr-sm"
        >
          <field-component
            :field="startFromReleaseDateField"
            :banks="banks"
            style="margin-top: 25px;"
            ignore-width
            @update:value="onUpdate"
          />
        </div>
      </div>
    </div>
    <div v-else>
      <div class="row">
        <div
          v-if="startDateField && !startDateField.invisible"
          :key="String(startDateField.value)"
          class="col q-mr-sm"
        >
          <field-component
            :field="startDateField"
            :banks="banks"
            label="Срок действия банковской гарантии"
            ignore-width
            @update:value="onUpdate"
          />
        </div>
        <div
          v-if="endDateField && !endDateField.invisible"
          :key="String(endDateField.value)"
          class="col q-mr-sm"
        >
          <field-component
            :field="endDateField"
            :banks="banks"
            label=" "
            ignore-width
            @update:value="onUpdate"
          />
        </div>
        <div
          v-if="endDateField && startDateField"
          class="white-bg-field"
          style="width: 90px;"
        >
          <q-input
            :model-value="countDays"
            class="field-with-top-label"
            disable
            label="Дней"
            outlined
            hide-bottom-space
            dense
          />
        </div>
      </div>
      <div class="row">
        <div
          v-if="startFromReleaseDateField && !startFromReleaseDateField.invisible"
          :key="String(startFromReleaseDateField.value)"
          class="col q-mr-sm"
        >
          <field-component
            :field="startFromReleaseDateField"
            :banks="banks"
            :style="!$q.platform.is.mobile ? 'margin-top: -30px;' : ''"
            ignore-width
            @update:value="onUpdate"
          />
        </div>
      </div>
    </div>
    <div :class="{'row wrap': true, 'q-mt-md': !startFromReleaseDateField || startFromReleaseDateField.invisible}">
      <field-component
        v-if="issueFormField && !issueFormField.invisible"
        :key="String(issueFormField.value)"
        :field="issueFormField"
        :banks="banks"
        class="col-12"
        ignore-width
        @update:value="onUpdate"
      />
      <field-component
        v-if="issueMailHardCopyField && !issueMailHardCopyField.invisible"
        :key="String(issueMailHardCopyField.value)"
        :field="issueMailHardCopyField"
        :banks="banks"
        class="col-sm-6 col-xs-12"
        :style="!$q.platform.is.mobile ? 'margin-top: -25px;' : ''"
        ignore-width
        @update:value="onUpdate"
      />
      <field-component
        v-if="bg1397Field && !bg1397Field.invisible"
        :key="String(bg1397Field.value)"
        :field="bg1397Field"
        :banks="banks"
        class="col-sm-6 col-xs-12"
        :style="$q.platform.is.mobile ? '' : 'margin-top: -25px'"
        ignore-width
        ignore-hint
        @update:value="onUpdate"
      />
    </div>
    <div
      v-if="bank"
      :class="{'row q-gutter-sm white-bg-field': true, 'q-mt-md': !bg1397Field || bg1397Field.invisible}"
    >
      <q-input
        ref="inputRef"
        v-model="formattedValue"
        class="field-with-top-label col-md-4 col-xs"
        disable
        label="Тариф ₽"
        outlined
        hide-bottom-space
        dense
        @blur="onBlur"
        @focus="onFocus"
      />
      <q-input
        :model-value="bank.commission_percent || 'min'"
        class="field-with-top-label"
        style="width: 70px;"
        disable
        label="% год"
        outlined
        hide-bottom-space
        dense
      />
      <div
        class="col-sm col-xs-12"
        style="padding-top: 30px;"
      >
        <application-tariff
          :id="props.appId"
          :number="props.number"
          @tariff-refreshed="onTariffRefreshed"
        >
          <template #default="{onShowClick}">
            <q-btn
              v-if="bank.tariff_calculator_enabled"
              label="Изменить тариф"
              color="primary"
              padding="12px 20px"
              icon-right="svguse:icons.svg?2#app-pencil-alt"
              no-caps
              no-wrap
              unelevated
              @click="onShowClick"
            />
          </template>
        </application-tariff>
        <q-btn
          v-if="bank.tariff_calculator_enabled"
          class="q-ml-xs"
          icon="svguse:icons.svg?2#app-refresh"
          size="14px"
          color="blue-grey-4"
          unelevated
          dense
          flat
          @click="refreshTariff"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {computed, nextTick, watchEffect} from 'vue'
import {
  useApplicationControlCenterItemStore
} from 'src/stores/Applications/ControlCenter/useApplicationControlCenterItemStore'
import {storeToRefs} from 'pinia'
import FieldComponent from 'src/components/Fields/FieldComponent.vue'
import {FormElementValue} from 'src/models/Form'
import {ApplicationFormField} from 'src/models/Applications/Form/ApplicationFormResponseData'
import {useImportantFields} from 'src/uses/Applications/Form/useImportantFields'
import ApplicationTariff from 'src/components/Applications/Tariff/ApplicationTariff.vue'
import moment from 'moment'
import {useGetTariffDetails} from 'src/uses/Applications/Tariff/useGetTariffDetails'
import {useQuasar} from 'quasar'
import {useCurrencyInput} from 'src/uses/useCurrencyInput'

const props = defineProps<{
  appId: string,
  number: string,
}>()

const applicationControlCenterItemStore = useApplicationControlCenterItemStore(props.appId)()
const {
  updateMainFields,
  startUpdateChangesProcess,
} = applicationControlCenterItemStore
const {
  loading,
  item,
  banks,
} = storeToRefs(applicationControlCenterItemStore)

const {
  rntField,
  lotField,
  bgSumField,
  startDateField,
  endDateField,
  countDays,
  issueFormField,
  issueMailHardCopyField,
  bg1397Field,
  nmcField,
  startFromReleaseDateField,
} = useImportantFields(item)

const {
  load: loadTariffDetails,
  tariffDetails,
} = useGetTariffDetails()

const {
  formattedValue,
  numberValue,
  onBlur,
  onFocus
} = useCurrencyInput()

const $q = useQuasar()

const bank = computed(() => {
  if (!banks.value.length) {
    return undefined
  }

  return banks.value[0]
})

const onUpdate = (
  value: FormElementValue,
  field: ApplicationFormField,
) => {
  updateMainFields([{
    value,
    code: field.code,
  }])
}

const refreshTariff = async () => {
  if (!startDateField.value) {
    return
  }

  await updateMainFields([{
    value: moment().format('DD.MM.YYYY'),
    code: startDateField.value?.code,
  }])

  onTariffRefreshed()
}

const onTariffRefreshed = async () => {
  if (!bank.value) {
    return
  }

  await loadTariffDetails(bank.value.id)

  if (!tariffDetails.value) {
    return
  }

  startUpdateChangesProcess({
    changes: {
      fields: [],
      documents: [],
      finance: {
        fields: [],
      },
      banks: [
        {
          ...bank.value,
          commission_percent: tariffDetails.value.commission_percent,
          commission_amount: tariffDetails.value.commission_amount,
        }
      ],
    }
  })
}

watchEffect(() => {
  if (!bank.value) {
    return
  }

  nextTick(() => {
    if (!bank.value) {
      return
    }

    numberValue.value = bank.value.commission_amount || 0
  })
})
</script>

<style lang="scss">

</style>
