import {ref} from 'vue'
import {useGetApi} from 'src/uses/Api/useGetApi'
import {
  ApplicationNewEmployee,
  ApplicationNewEmployeeResponse
} from 'src/models/Applications/ApplicationNewEmployeeResponse'
import {UserRoleEnum} from 'src/models/User/UserRoleEnum'

export const useApplicationsNewEmployee = () => {
  const {
    loading,
    get,
    status,
    response,
  } = useGetApi<ApplicationNewEmployeeResponse>()

  const items = ref<ApplicationNewEmployee[]>([])
  const selected = ref<ApplicationNewEmployee>()

  const loadEmployees = async (agentId: string) => {
    await get(`v1/product/selection/agents/${agentId}/employees`,)

    if (status.value === 200 && response.value) {
      items.value = response.value.items

      const agent = items.value
        .find(item => item.role === UserRoleEnum.Agent)
      if (!selected.value && agent) {
        selected.value = agent
      }
    }
  }

  return {
    items,
    loading,
    loadEmployees,
    selected,
  }
}
