import {ref, watch} from 'vue'

import moment from 'moment'
import {useNotifyErrorsStore} from 'src/stores/useNotifyErrorsStore'
import {CertificateOwnerInfo} from 'src/models/CryptoPro/CertificateOwnerInfo'
import {
  Certificate,
  createAttachedSignature,
  getCertificate,
  getSystemInfo,
  getUserCertificates,
  isValidSystemSetup,
  SystemInfo
} from 'crypto-pro'

export interface CertSelectOption {
  label: string,
  value: string,
}
export const useCryptoPro = () => {
  const useNotifyErrors = useNotifyErrorsStore()
  const {
    addErrorMessage
  } = useNotifyErrors

  const systemInfo = ref<SystemInfo>()
  const isValidSystemInfo = ref(true)
  const certificates = ref<Certificate[]>([])
  const certsForSelect = ref<CertSelectOption[]>()
  const certificateNoChange = ref(false)
  const cert = ref<string>('')
  const selectedCert = ref<Certificate>()
  const ownerInfo = ref<CertificateOwnerInfo>()
  const sublicenseAgreement = ref(false)
  const privacyPolicy = ref(false)
  const pluginError = ref(false)
  const cryptoError = ref<string | undefined>()

  const getPluginInfo = async () => {
    try {
      systemInfo.value = await getSystemInfo()
      isValidSystemInfo.value = await isValidSystemSetup()
      await getCertificates()
    } catch (e) {
      pluginError.value = true
    }
  }

  const getCertificates = async () => {
    try {
      certificates.value = await getUserCertificates()
    } catch (e) {
      cryptoError.value = (e as Error).message
    }
  }

  const getCertsForSelect = () => {
    if (!certificates.value) {
      return []
    }

    certsForSelect.value = certificates.value.map(item => {
      let name = item.name
      const validDate = moment(item.validTo).format('DD.MM.YYYY')
      if (name[0] === '"') {
        name = name.substring(1)
        name = name.replace('"""', '"').replace('""', '"')
      }

      return {
        label: `${name} годен до ${validDate}`,
        value: item.thumbprint
      }
    })
  }

  const isDisabledEnterButton = () => {
    return !(sublicenseAgreement.value && privacyPolicy.value && selectedCert.value)
  }

  const isShowCertificateSelect = () => {
    return systemInfo.value && isValidSystemInfo.value && !pluginError.value && !cryptoError.value
  }

  const base64ToArrayBuffer = (base64: string) => {
    const binaryString = atob(base64)
    const bytes = new Uint8Array(binaryString.length)
    for (let i = 0; i < binaryString.length; i++) {
      bytes[i] = binaryString.charCodeAt(i)
    }

    return bytes.buffer
  }

  const setOwnerInfo = async () => {
    const info = await selectedCert.value?.getOwnerInfo()
    if (info) {
      const innUl = info.find(item => item.title === 'ИНН ЮЛ')

      const middleAndFirstName = info.find(item => item.title === 'Имя Отчество')
      const lastName = info.find(item => item.title === 'Фамилия')
      const post = info.find(item => item.title === 'Должность')
      const company = info.find(item => item.title === 'Компания')
      const inn = innUl || info.find(item => item.title === 'ИНН')
      const validTo = (selectedCert.value?.validTo)
        ? moment(selectedCert.value?.validTo).format('DD.MM.YYYY')
        : ''

      ownerInfo.value = {
        fio: lastName?.description ? `${lastName?.description} ${middleAndFirstName?.description}` : '',
        company: company?.description || '',
        post: post?.description || '',
        validTo: validTo || '',
        inn: inn?.description || '',
      }
    }
  }

  watch(
    certificates,
    () => {
      if (certificates.value.length > 0) {
        getCertsForSelect()
      }
    },
    {deep: true, immediate: true}
  )

  watch(
    cert,
    async () => {
      if (cert.value) {
        certificateNoChange.value = false
        selectedCert.value = await getCertificate(cert.value)
        await setOwnerInfo()
      }
    },
    {deep: true, immediate: true}
  )

  return {
    cert,
    systemInfo,
    isValidSystemInfo,
    certsForSelect,
    certificateNoChange,
    selectedCert,
    ownerInfo,
    sublicenseAgreement,
    privacyPolicy,
    pluginError,
    cryptoError,
    base64ToArrayBuffer,
    getPluginInfo,
    getCertificates,
    isDisabledEnterButton,
    isShowCertificateSelect,
    createAttachedSignature,
    addErrorMessage
  }
}
