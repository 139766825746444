export enum ProductRequiredConditionsParamTypeEnum {
  SELECT = 'select',
  NUMBER = 'number',
}

export enum ProductRequiredConditionComparisonOperatorEnum {
  LT = 'lt',
  EQ = 'eq',
  GT = 'gt',
}

export enum ProductRequiredConditionsParamEnum {
  OBLIGATION_TYPE = 'obligationType',
  PROVISION_TYPE = 'provisionType',
  GUARANTEE_TOTAL = 'guaranteeTotal',
  GUARANTEE_DAYS = 'guaranteeDays',
  PURCHASE_CLOSED = 'purchaseClosed',
  PURCHASE_GOZ = 'purchaseGoz',
  COMMERCIAL_PURCHASE = 'commercialPurchase',
}

export interface ProductRequiredConditionsParam {
  multiple: boolean
  options: Record<string, string> | null
  param: ProductRequiredConditionsParamEnum
  relations: ProductRequiredConditionComparisonOperatorEnum[]
  title: string
  type: ProductRequiredConditionsParamTypeEnum
}

export interface ProductRequiredConditionsPart {
  operator: ProductRequiredConditionComparisonOperatorEnum
  param: ProductRequiredConditionsParamEnum,
  value: string | number | string[] | null
}
export interface ProductRequiredCondition {
  operator: 'and'
  parts: ProductRequiredConditionsPart[]
}

export interface ProductRequiredConditions {
  operator: 'or'
  parts: ProductRequiredCondition[]
}

export interface ProductRequiredControl {
  conditions?: ProductRequiredConditions | null
  conditions_params?: ProductRequiredConditionsParam[]
  required: boolean
}
