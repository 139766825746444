import {defineStore} from 'pinia'
import {useGridFilters} from 'src/uses/Grid/useGridFilters'
import {ActsGridFilters} from 'src/models/Profile/Acts/ActsGridFilters'
import {ActStatusEnum} from 'src/models/Enums/ActStatusEnum'
import {ActStatuses} from 'src/models/Profile/Acts/ActStatusDescriptions'
import {ref} from 'vue'

const defaultFilters: ActsGridFilters = {
  status: '',
}

export const useProfileActsGridFilters = defineStore('profile-acts-grid-filters', () => {
  const {
    filters,
    clearable,
    getFiltersQuery,
    clear,
  } = useGridFilters(defaultFilters)

  const statuses = ref()
  const years = ref()
  const months = ref()

  const generateStatuses = () => {
    statuses.value = Object.values(ActStatusEnum).map(p => {
      return {
        title: ActStatuses.getActStatusDescription(p),
        id: p
      }
    })
  }

  const generateYears = () => {
    const year = new Date().getFullYear()
    const startYear = year - 2
    const yearsOptions = []
    for (let i = 2; i > -1; i--) {
      yearsOptions.push({
        id: startYear + i,
        title: startYear + i,
      })
    }
    years.value = yearsOptions
  }

  const generateMonths = () => {
    const monthList = [
      'Январь',
      'Февраль',
      'Март',
      'Апрель',
      'Май',
      'Июнь',
      'Июль',
      'Август',
      'Сентбрь',
      'Октябрь',
      'Ноябрь',
      'Декабрь',
    ]

    const monthOptions = []

    for (let i = 1; i < monthList.length + 1; i++) {
      monthOptions.push({
        id: i,
        title: monthList[i]
      })
    }
    /*monthList.map((m, index) => {
      monthOptions.push({
        id: index,
        title: m
      })
    })*/

    /*monthList.forEach((m, index) => {
      months.value.push({
        id: index,
        title: m
      })
    })*/

    months.value = monthOptions
  }

  return {
    filters,
    clearable,
    getFiltersQuery,
    clear,
    statuses,
    years,
    months,
    generateStatuses,
    generateYears,
    generateMonths,
  }
})
