export const useForms = () => {
  const form1ActiveBlocks = [
    {
      title: 'I. Внеоборотные активы',
      startsWith: '11',
      sum: '1100',
    },
    {
      title: 'II. Оборотные активы',
      startsWith: '12',
      sum: '1200',
    },
  ]
  const form1ActiveSumCode = '1600'

  const form1PassiveBlocks = [
    {
      title: 'III. Капитал и резервы',
      startsWith: '13',
      sum: '1300',
    },
    {
      title: 'IV. Долгосрочные обязательства',
      startsWith: '14',
      sum: '1400',
    },
    {
      title: 'V. Краткосрочные обязательства',
      startsWith: '15',
      sum: '1500',
    },
  ]
  const form1PassiveSumCode = '1700'

  const form2Blocks = [
    {
      startsWith: '21',
      sum: '2100',
    },
    {
      startsWith: '22',
      sum: '2200',
    },
    {
      startsWith: '23',
      sum: '2300',
    },
    {
      startsWith: '24',
      sum: '2400',
    },
    {
      startsWith: '25',
      sum: '2500',
    },
  ]

  return {
    form1ActiveBlocks,
    form1ActiveSumCode,
    form1PassiveBlocks,
    form1PassiveSumCode,
    form2Blocks,
  }
}
