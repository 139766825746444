import {computed, Ref, ref} from 'vue'
import {FieldUpdate} from 'src/models/Field/FieldUpdate'
import {
  ApplicationFormBank,
  ApplicationFormOrder
} from 'src/models/Applications/Form/ApplicationFormResponseData'
import {useUpdateMainField} from 'src/uses/Applications/Form/useUpdateMainField'
import {ApplicationFormUpdateResponse} from 'src/models/Applications/Form/ApplicationFormUpdateResponse'
import {useUpdateSectionFields} from 'src/uses/Applications/Form/useUpdateSectionFields'
import {ProductFields} from 'src/models/Applications/Form/ProductFields'
import {FieldsCodesEnum} from 'src/models/FieldsCodesEnum'

export const useMainFields = (
  banks: Ref<ApplicationFormBank[]>,
  multiOrderId?: number,
  appId?: string,
) => {
  const main = ref<ApplicationFormOrder | null>(null)
  const response = ref<ApplicationFormUpdateResponse>()

  const okpdFieldsToUpdate = ref<FieldUpdate[]>()

  const {
    updateValues,
    updateErrorProcess,
    updateSuccessProcess,
    filterVisible,
    getParentFieldsUpdate,
  } = useUpdateSectionFields()

  const okpdFieldCodes = [
    FieldsCodesEnum.DEAL_OKPD_CODE,
    FieldsCodesEnum.DEAL_OKPD_NAME,
    FieldsCodesEnum.DEAL_OKPD_SUM,
  ]

  let okpdUpdateTimeout: number | undefined

  const mainVisibleFields = computed<ProductFields>(() => {
    return filterVisible(main.value ? main.value.main.fields : [], banks.value)
  })

  const updateOkpdFields = async () => {
    if (!okpdFieldsToUpdate.value) {
      return
    }
    await realUpdateUpdateMainFields(okpdFieldsToUpdate.value)
    okpdFieldsToUpdate.value = undefined
  }

  const updateMainFields = async (fields: FieldUpdate[]) => {
    if (!main.value) {
      return
    }

    fields.push(...getParentFieldsUpdate(fields, mainVisibleFields.value))
    updateValues(fields, main.value.main.fields)
    if (okpdFieldCodes.find(code => fields.find(field => field.code === code))) {
      const okpdToAddCodes = okpdFieldCodes
        .filter(code => !fields.find(field => field.code === code))

      if (okpdToAddCodes.length && main.value && main.value.main) {
        fields.push(
          ...main.value.main.fields
            .filter(field => okpdToAddCodes.includes(field.code as FieldsCodesEnum))
            .map(field => ({
              value: field.value,
              code: field.code,
            }))
        )
      }

      okpdFieldsToUpdate.value = [
        ...(okpdFieldsToUpdate.value || [])
          .filter(({code}) => !fields.map(field => field.code).includes(code)),
        ...fields
      ]

      clearTimeout(okpdUpdateTimeout)
      okpdUpdateTimeout = setTimeout(
        () => {
          updateOkpdFields()
        },
        100
      ) as never as number

      return
    }

    await realUpdateUpdateMainFields(fields)
  }

  const realUpdateUpdateMainFields = async (fields: FieldUpdate[]) => {
    if (!main.value) {
      return
    }

    const {
      status,
      errors,
      response: updateResponse,
      update,
    } = useUpdateMainField()
    await update(fields, multiOrderId, appId)

    if (status.value !== 200 && errors.value) {
      updateErrorProcess(fields, main.value.main.fields, errors.value)
      response.value = undefined

      return
    }

    updateSuccessProcess(fields, main.value.main.fields)
    response.value = updateResponse.value
  }

  return {
    main,
    mainVisibleFields,
    response,
    updateMainFields,
  }
}
