<template>
  <q-toggle
    :model-value="enabled"
    class="q-mr-sm"
    style="margin-left: -10px;"
    @update:model-value="onChange"
  />
</template>

<script setup lang="ts">
import {computed} from 'vue'
import {useListingsItemRecordEnabledUpdate} from 'src/uses/Listings/useListingsItemRecordEnabledUpdate'

const props = defineProps<{
  modelValue: boolean,
  listingsId: string,
  recordId: number,
}>()

const emit = defineEmits<{
  (e: 'update:model-value', value: boolean): void
}>()

const enabled = computed(() => props.modelValue)

const {
  updateEnabled,
  status,
} = useListingsItemRecordEnabledUpdate()

const onChange = async (enabled: boolean) => {
  await updateEnabled(props.listingsId, props.recordId, enabled)
  if (status.value === 400) {
    enabled = !enabled
  }
  emit('update:model-value', enabled)
}

</script>
