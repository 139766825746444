<template>
  <applications-grid-filters-resume-dialog
    :top="controlHeight"
  >
    <div class="row items-end justify-center">
      <q-btn
        color="grey-2"
        class="text-blue-grey-4"
        icon-right="svguse:icons.svg?2#app-adjustments"
        padding="xs"
        unelevated
        no-caps
        @click="filterDialogShow"
      />
      <q-btn
        v-if="clearable"
        color="grey-2"
        class="text-blue-grey-4 q-ml-xs"
        icon-right="svguse:icons.svg?2#app-trash"
        size="14px"
        padding="5px 7px"
        unelevated
        square
        no-caps
        @click="onClear"
      />
      <div>
        <application-kik-grid-filters-expansion
          :loading="loading"
          :ignore-pins="true"
          @update:filters="updateFilters"
        />
      </div>
      <q-btn
        class="q-ml-sm text-primary bg-grey-2"
        label="Наверх"
        padding="xs sm"
        unelevated
        no-wrap
        no-caps
        @click="scrollTop"
      >
        <q-icon
          name="svguse:icons.svg?2#app-arrow-up"
          color="primary"
          size="15px"
        />
      </q-btn>
    </div>
  </applications-grid-filters-resume-dialog>
  <application-kik-grid-filters-dialog
    v-model="filerDialog"
    :loading="loading"
    :ignorePins="ignorePins"
    @update:filters="updateFilters"
  />
  <grid-column-settings-dialog
    v-model="columns"
    v-model:dialog="dialogGridColumnsSettings"
    :default="ApplicationsGridKikColumns()"
  />
  <div
    class="q-px-none"
    style="margin-top: -20px;"
  >
    <div ref="controlRef">
      <q-btn
        color="blue-grey-4"
        icon="svguse:icons.svg?2#app-refresh"
        class="q-mr-sm"
        flat
        square
        dense
        @click="load"
      />
      <application-grid-product-toggle :loading="loading" />
      <div class="q-px-lg q-py-md ApplicationsGrid--control">
        <div class="q-gutter-y-sm">
          <div class="row no-wrap q-col-gutter-x-sm">
            <div v-if="filtersBtnNotInStatuses">
              <q-btn
                color="grey-2"
                class="text-blue-grey-4"
                icon-right="svguse:icons.svg?2#app-adjustments"
                padding="10px"
                unelevated
                no-caps
                flat
                @click="filterDialogShow"
              />
            </div>
            <div class="col white-bg-field">
              <application-kik-grid-filters-expansion
                ref="filterExpansion"
                v-model="filerDialog"
                :class="{'q-mt-sm': true, 'q-mb-sm': filterExpansion && filterExpansion.isShowFilters}"
                :ignore-pins="ignorePins"
                :loading="loading"
                @update:filters="updateFilters"
              />
              <div
                v-if="hasPins && !ignorePins"
                class="row q-gutter-sm q-mb-md"
              >
                <application-kik-grid-filters
                  :loading="loading"
                  :ignore-pins="ignorePins"
                  only-pinned
                  @update:filters="updateFilters"
                />
              </div>
            </div>
            <div v-if="filtersBtnNotInStatuses">
              <q-btn
                v-if="clearable"
                color="grey-2"
                class="text-blue-grey-4 q-ml-xs"
                icon-right="svguse:icons.svg?2#app-trash"
                padding="7px"
                unelevated
                no-caps
                flat
                @click="onClear"
              />
            </div>
          </div>
          <div class="row no-wrap q-col-gutter-x-sm">
            <div v-if="filtersBtnInStatuses">
              <q-btn
                color="grey-2"
                class="text-blue-grey-4"
                icon-right="svguse:icons.svg?2#app-adjustments"
                padding="7px"
                unelevated
                no-caps
                flat
                @click="filterDialogShow"
              />
            </div>
            <div class="col">
              <application-kik-grid-statuses @update:filters="updateFilters" />
            </div>
            <div v-if="filtersBtnInStatuses">
              <q-btn
                v-if="clearable"
                color="grey-2"
                class="text-blue-grey-4 q-ml-xs"
                icon-right="svguse:icons.svg?2#app-trash"
                padding="7px"
                unelevated
                no-caps
                flat
                @click="onClear"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="ApplicationsGrid--table">
      <q-table
        v-model:pagination="gridPagination"
        class="sticky-header-table"
        :rows="items"
        :columns="filteredVisibleColumns"
        :loading="loading"
        :rows-per-page-options="perPageOptions"
        separator="none"
        row-key="name"
        hide-pagination
        wrap-cells
        @request="onRequest"
      >
        <template
          v-if="!loading"
          #no-data
        >
          <template v-if="!isDefaultFilterValue">
            По вашему запросу ничего не найдено. Попробуйте изменить фильтры
          </template>
          <template v-else>
            У вас пока нет ни одной заявки
          </template>
        </template>
        <template #header-cell="props">
          <q-th
            :props="props"
            class="grid-column-header"
          >
            <applications-kik-grid-th
              :item="props.col"
              @update:sorting="updateSorting"
            />
          </q-th>
        </template>
        <template #header-cell-actions="props">
          <q-th
            :props="props"
            class="grid-column-header"
          >
            <q-btn
              color="primary"
              icon="svguse:icons.svg?2#app-cog"
              flat
              square
              dense
              @click="showGridColumnsSettings"
            />
          </q-th>
        </template>
        <template #header-cell-credit_amount="props">
          <q-th
            :props="props"
            class="grid-column-header"
          >
            <div class="text-no-wrap">
              {{ props.col.label }}
              <kik-amount-help-tooltip />
            </div>
          </q-th>
        </template>
        <template #header-cell-credit_months="props">
          <q-th
            :props="props"
            class="grid-column-header"
          >
            <div class="text-no-wrap">
              {{ props.col.label }}
              <kik-months-help-tooltip />
            </div>
          </q-th>
        </template>
        <template #body-cell-date="props">
          <q-td :props="props">
            <application-kik-grid-number
              :item="props.row"
              :onlyText="ApplicationKikSubStatusEnum.archive === props.row.sub_status"
            />
          </q-td>
        </template>
        <template #body-cell-inn="props">
          <q-td :props="props">
            <application-grid-inn :item="props.row" />
          </q-td>
        </template>
        <template #body-cell-credit_type="props">
          <q-td :props="props">
            <div class="row no-wrap">
              {{ getCreditTypeLabel(props.row.credit_type) }}
            </div>
          </q-td>
        </template>
        <template #body-cell-credit_amount="props">
          <q-td :props="props">
            <div class="column no-wrap">
              <div v-if="props.row.credit_amount_requested">
                <money-component :model-value="props.row.credit_amount_requested" />
              </div>
              <div
                v-if="props.row.credit_amount_approved"
                class="text-primary"
              >
                <money-component :model-value="props.row.credit_amount_approved" />
              </div>
              <div
                v-if="props.row.credit_amount_issued"
                class="text-green-14"
              >
                <money-component :model-value="props.row.credit_amount_issued" />
              </div>
            </div>
          </q-td>
        </template>
        <template #body-cell-credit_months="props">
          <q-td :props="props">
            <div class="column no-wrap">
              <div v-if="props.row.credit_months_requested">
                {{ props.row.credit_months_requested }} мес.
              </div>
              <div
                v-if="props.row.credit_months_approved"
                class="text-primary"
              >
                {{ props.row.credit_months_approved }} мес.
              </div>
              <div
                v-if="props.row.credit_months_issued"
                class="text-green-14"
              >
                {{ props.row.credit_months_issued }} мес.
              </div>
            </div>
          </q-td>
        </template>
        <template #body-cell-tariff_percent="props">
          <q-td :props="props">
            <div
              v-if="props.row.tariff_percent"
              class="row no-wrap"
            >
              {{ props.row.tariff_percent }}%
            </div>
          </q-td>
        </template>
        <template #body-cell-status="props">
          <q-td :props="props">
            <div class="row no-wrap">
              <application-kik-grid-status
                class="col"
                :item="props.row"
              />
              <application-grid-messages-action
                :item="props.row"
                @model-closed="refreshById(props.row.id)"
              />
            </div>
          </q-td>
        </template>
        <template #body-cell-users="props">
          <q-td :props="props">
            <application-grid-users
              :operator="props.row.operator"
              :agent-manager="props.row.agent_manager"
              :agent="props.row.agent"
            />
          </q-td>
        </template>
        <template #body-cell-actions="props">
          <q-td :props="props">
            <application-kik-grid-actions
              :item="props.row"
              @change="refreshById(props.row.id)"
            />
          </q-td>
        </template>
        <template #body-cell-bank="props">
          <q-td :props="props">
            <img
              :src="props.row.bank.logo"
              :alt="props.row.bank.title_short"
              style="max-width: 100px; max-height: 50px;"
            >
          </q-td>
        </template>
      </q-table>
      <pagination-component
        :pagination="gridPagination as GridPagination"
        :per-page-options="perPageOptions"
        :can-refresh="false"
        @request="onRequest"
        @refresh="load"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import ApplicationGridInn from 'src/components/Applications/ApplicationsGrid/Columns/ApplicationGridInn.vue'
import ApplicationGridMessagesAction
  from 'src/components/Applications/ApplicationsGrid/Actions/ApplicationGridMessagesAction.vue'
import PaginationComponent from 'src/components/PaginationComponent.vue'
import GridColumnSettingsDialog from 'src/components/Grid/GridColumnSettingsDialog.vue'
import {GridPagination} from 'src/models/Grid/Pagination'
import {computed, onBeforeMount, ref} from 'vue'
import ApplicationsGridFiltersResumeDialog
  from 'components/Applications/ApplicationsGrid/Grid/ApplicationsGridFiltersResumeDialog.vue'
import ApplicationGridProductToggle
  from 'components/Applications/ApplicationsGrid/Grid/ApplicationGridProductToggle.vue'
import {useApplicationsKikGrid} from 'components/Applications/ApplicationsGrid/Grid/useApplicationsKikGrid'
import ApplicationKikGridFiltersExpansion
  from 'components/Applications/ApplicationsGrid/ApplicationKikGridFiltersExpansion.vue'
import ApplicationKikGridFilters from 'components/Applications/ApplicationsGrid/ApplicationKikGridFilters.vue'
import ApplicationKikGridFiltersDialog
  from 'components/Applications/ApplicationsGrid/ApplicationKikGridFiltersDialog.vue'
import {ApplicationsGridKikColumns} from 'components/Applications/ApplicationsGrid/ApplicationsGridKikColumns'
import ApplicationKikGridStatuses from 'components/Applications/ApplicationsGrid/ApplicationKikGridStatuses.vue'
import {ApplicationKikSubStatusEnum} from 'src/models/Applications/ApplicationKikSubStatusEnum'
import ApplicationKikGridNumber from 'components/Applications/ApplicationsGrid/Columns/ApplicationKikGridNumber.vue'
import ApplicationKikGridStatus from 'components/Applications/ApplicationsGrid/Columns/ApplicationKikGridStatus.vue'
import {useCreditType} from 'src/uses/Applications/useCreditType'
import MoneyComponent from 'components/MoneyComponent.vue'
import ApplicationKikGridActions from 'components/Applications/ApplicationsGrid/Actions/ApplicationKikGridActions.vue'
import ApplicationsKikGridTh from 'components/Applications/ApplicationsGrid/ApplicationsKikGridTh.vue'
import KikAmountHelpTooltip from 'components/Applications/ApplicationsGrid/Grid/KikAmountHelpTooltip.vue'
import KikMonthsHelpTooltip from 'components/Applications/ApplicationsGrid/Grid/KikMonthsHelpTooltip.vue'
import ApplicationGridFiltersExpansion
  from 'components/Applications/ApplicationsGrid/ApplicationGridFiltersExpansion.vue'
import ApplicationGridUsers from 'components/Applications/ApplicationsGrid/Columns/ApplicationGridUsers.vue'

const {
  dialogGridColumnsSettings,
  showGridColumnsSettings,

  isDefaultFilterValue,
  hasPins,
  ignorePins,
  clearable,

  items,
  gridPagination,
  perPageOptions,
  loading,

  load,
  refreshById,

  columns,

  filerDialog,
  filterDialogShow,

  controlRef,
  controlHeight,
  filteredVisibleColumns,

  onRequest,
  updateFilters,
  updateSorting,
  onClear,
} = useApplicationsKikGrid()

const filterExpansion = ref<typeof ApplicationGridFiltersExpansion>()

const filtersBtnInStatuses = computed(() => {
  return filterExpansion.value
    && !filterExpansion.value.isShowFilters
    && !(hasPins.value && !ignorePins.value)
})

const filtersBtnNotInStatuses = computed(() => {
  return filterExpansion.value
    && (
      filterExpansion.value.isShowFilters
      ||
      (hasPins.value && !ignorePins.value)
    )
})

const {
  getCreditTypeLabel,
} = useCreditType()

const scrollTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  })
}

onBeforeMount(async () => {
  await load()
})
</script>

<style lang="scss" src="./ApplicationsGrid.scss" />
