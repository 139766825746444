<template>
  <div :class="classes">
    <slot name="default" />
  </div>
</template>

<script setup lang="ts">
import {withDefaults, computed} from 'vue'
const props = withDefaults(defineProps<{ noBorder?: boolean }>(), {
  noBorder: false,
})

const classes = computed(() => {
  return {
    formGroup: true,
    'q-pa-md': true,
    bordered: !props.noBorder,
  }
})
</script>

<style lang="scss">
.formGroup {
  border-radius: 6px;
  border: 1px solid transparent;
  overflow: hidden;
  position: relative;

  &.bordered {
    border-color: $blue-grey-1;
  }
}
</style>
