<template>
  <div class="row no-wrap">
    <div>
      <date-item :model-value="item.date"/>
    </div>
    <div
      style="width: 125px;"
      class="ellipsis col q-pl-sm"
    >
      <div>
        {{ props.item.id }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import DateItem from 'src/components/DateItem.vue'
import {ApplicationsMultiGridItem} from 'src/models/Applications/ApplicationsMultiGridItem'

const props = defineProps<{
  item: ApplicationsMultiGridItem
}>()
</script>
