import {useOptionsFromObject} from 'src/uses/useOptionsFromObject'
import {DocumentStatusEnum} from 'src/models/Enums/DocumentStatusEnum'

const statuses: Record<DocumentStatusEnum, string> = {
  [DocumentStatusEnum.Signed]: 'подписано',
  [DocumentStatusEnum.NoSign]: 'не подписан',
  [DocumentStatusEnum.IsChecking]: 'проверка документов',
  [DocumentStatusEnum.Revision]: 'на доработке',
  [DocumentStatusEnum.WaitOriginal]: 'ожидается оригинал',
}

export const useAgentDocumentStatus = () => {
  const {getOptionsFromObject} = useOptionsFromObject()
  const options = getOptionsFromObject(statuses)
  const getStatusLabel = (status: DocumentStatusEnum): string => {
    return statuses[status] || ''
  }

  return {
    getStatusLabel,
    options,
  }
}
