<template>
  <div
    :class="classes"
    @click="clickOnStatus"
  >
    <strong class="text-no-wrap ellipsis full-width block">{{ label(item.status) }}</strong>
    <div class="row items-center no-wrap">
      <div class="col" />
      <span
        v-if="showIcon"
        class="ApplicationGridStatus--request"
      >
        <q-icon
          style="margin-right: 2px; margin-top: -3px"
          :color="isOrange(item.sub_status) || newRequest ? 'orange' : 'green-14'"
          size="16px"
          :name="iconName"
        />
      </span>
      <date-item
        v-if="statusDate"
        :model-value="statusDate"
        class="block"
      />
      <div class="col" />
    </div>
    <q-tooltip
      v-if="showTooltip"
      :delay="700"
      :offset="[0, 10]"
    >
      <div v-if="statusComment && isRed(subStatus)">
        {{ statusComment }}
      </div>
      <div v-if="newRequest">
        По заявке есть дополнительный запрос. Перейдите в центр управления заявкой.
      </div>
      <div v-if="filled && isDraft(subStatus)">
        Заявка готова к отправке на проверку.
      </div>
      <stop-factors-result
        v-if="props.item.stop_factor_results.length"
        :model-value="props.item.stop_factor_results"
      />
    </q-tooltip>
  </div>
</template>

<script setup lang="ts">
import {computed} from 'vue'
import DateItem from 'src/components/DateItem.vue'
import StopFactorsResult from 'src/components/Applications/StopFactorsResult.vue'
import {useKikStatus} from 'src/uses/Applications/useKikStatus'
import {ApplicationsGridKikItem} from 'src/models/Applications/ApplicationsGridKikItem'
import {useQuasar} from 'quasar'
import {usePosthogStore} from 'stores/Posthog/usePosthogStore'

const props = defineProps<{
  item: ApplicationsGridKikItem
}>()

const {
  isDraft,
  isRed,
  isOrange,
  color,
  bgColor,
  label,
} = useKikStatus()
const {sendEvent} = usePosthogStore()

const $q = useQuasar()

const subStatus = computed(() => props.item.sub_status)
const newRequest = computed(() => props.item.new_request)
//сonst newNotice = computed(() => props.item.new_notice)
const filled = computed(() => props.item.filled)
const statusComment = computed(() => props.item.status_comment)
const statusDate = computed(() => props.item.status_date)

const showTooltip = computed(() => {
  return (statusComment.value && isRed(subStatus.value))
    || newRequest.value
    || (filled.value && isDraft(subStatus.value))
    || props.item.stop_factor_results.length
})

const showIcon = computed(() => {
  return newRequest.value
    || (isDraft(subStatus.value) && filled.value)
})

const iconName = computed(() => {
  if (isDraft(props.item.sub_status)) {
    return 'svguse:icons.svg?2#app-check-circle-bold'
  }

  return 'svguse:icons.svg?2#app-exclamation-circle-bold'
})

const classes = computed(() => {
  return {
    ApplicationGridStatus: true,
    isMobile: $q.platform.is.mobile,
    'q-py-sm': true,
    'q-px-md': true,
    'text-center': true,
    [`bg-${bgColor(props.item.sub_status)}`]: !isDraft(props.item.sub_status),
    [`text-${color(props.item.sub_status)}`]: true,
    'bg-grey-2': isDraft(props.item.sub_status)
  }
})

const clickOnStatus = () => {
  sendEvent('Click_on_status_name_in_list_of_applications')
}
</script>

<style lang="scss">
.ApplicationGridStatus {
  border-radius: 6px;
  min-width: 190px !important;
  max-width: 190px !important;
  width: 190px !important;

  &.isMobile {
    min-width: auto !important;
    max-width: none !important;
    width: auto !important;
  }
}
</style>
