<template>
  <div>
    <date-item :model-value="props.item.date" />
  </div>
  <div
    style="width: 125px;"
    class="ellipsis-2-lines"
  >
    Заявка:
    {{ props.item.id }}
  </div>
</template>

<script setup lang="ts">
import DateItem from 'src/components/DateItem.vue'
import {ApplicationsMultiGridItem} from 'src/models/Applications/ApplicationsMultiGridItem'

const props = withDefaults(
  defineProps<{
    item: ApplicationsMultiGridItem
    onlyText?: boolean
  }>(),
  {
    onlyText: false,
  }
)
</script>
