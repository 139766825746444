import {
  ApplicationFormFinanceField,
  ApplicationFormBank,
  ApplicationFormDocument,
  ApplicationFormField,
} from 'src/models/Applications/Form/ApplicationFormResponseData'
import {Ref} from 'vue'
import {ApplicationSubStatusEnum} from 'src/models/Applications/ApplicationSubStatusEnum'

export const useFilterWithErrors = (banks: Ref<ApplicationFormBank[]>) => {
  const filterWithErrors = (field: ApplicationFormField | ApplicationFormFinanceField | ApplicationFormDocument) => {
    return field.error
      || field.valid
        .reduce(
          (result, bank) => result || !!bank.errors.length,
          false
        )
  }

  const filterOnlyCommonOrDraftBanks = (field: ApplicationFormField | ApplicationFormFinanceField | ApplicationFormDocument) => {
    const bank = banks.value
      .find(b => b.bank_id === field.location.bank_id)

    return !bank
      || bank.sub_status === ApplicationSubStatusEnum.draft
  }

  return {
    filterWithErrors,
    filterOnlyCommonOrDraftBanks,
  }
}
