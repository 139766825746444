<template>
  <application-form-founder-modal
    v-model="dialog"
    :is-ok="props.isOk"
    :fields="fields"
    :is-head="props.isHead"
    :founder-id="props.founderId"
    :banks="props.banks"
    @update:field-value="onUpdateFieldValue"
    @update:address-details="updateAddressDetails"
  >
    <template #type>
      <div class="q-mb-xs">
        <slot name="type">
          Руководитель
        </slot>
      </div>
    </template>
  </application-form-founder-modal>
  <div class="ApplicationItemFounder q-py-sm q-px-md">
    <div class="row items-center">
      <div class="col col-md col-xs-12 ApplicationItemFounder__fio">
        {{ fullName }}
        <q-icon
          v-if="props.isOk"
          name="svguse:icons.svg?2#app-check"
          color="green-14"
          size="sm"
        />
      </div>
      <div class="ApplicationItemFounder__type col col-md-0">
        <slot name="type">
          Руководитель
        </slot>
      </div>
      <div>
        <q-btn
          dense
          unelevated
          :disable="props.loading"
          :loading="props.loading"
          @click="show"
        >
          <q-icon
            name="svguse:icons.svg?2#app-pencil-alt"
            color="blue-7"
          />
        </q-btn>
        <q-btn
          v-if="!props.isHead"
          class="delete"
          dense
          unelevated
          :disable="loading || props.isHead"
          :loading="loading"
          @click="deleteHandler"
        >
          <q-icon
            name="svguse:icons.svg?2#app-trash"
            color="blue-grey-4"
          />
        </q-btn>
        <div
          v-else
          style="width: 63px;"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {computed, watch, watchEffect, withDefaults} from 'vue'
import {useClientName} from 'src/uses/Applications/useClientName'
import {useDialog} from 'src/uses/Dialogs/useDialog'
import {ProductFields} from 'src/models/Applications/Form/ProductFields'
import {
  AddressDetails,
  ApplicationFormBank, ApplicationFormField,
} from 'src/models/Applications/Form/ApplicationFormResponseData'
import {FieldUpdate} from 'src/models/Field/FieldUpdate'
import ApplicationFormFounderModal from 'src/components/Applications/Form/Client/ApplicationFormFounderModal.vue'

const props = withDefaults(
  defineProps<{
    fields: ProductFields,
    isHead?: boolean,
    founderId: string,
    isOk: boolean,
    loading: boolean,
    openedFounderId?: string,
    banks: ApplicationFormBank[],
  }>(),
  {
    isHead: true,
    openedFounderId: undefined,
  }
)

const emit = defineEmits<{
  (e: 'delete', id: string): void,
  (e: 'update:openedFounderId', value?: string): void,
  (
    e: 'update:fieldValue',
    data: FieldUpdate[],
    founderId: string,
    file?: File,
  ): void,
  (e: 'update:address-details', field: ApplicationFormField, details: AddressDetails): void,
  (e: 'passport-recognized', file: File): void,
}>()

const {
  dialog,
  show: realShow,
  hide,
} = useDialog()

const fields = computed<ProductFields>(() => props.fields)

const onUpdateFieldValue = (
  data: FieldUpdate[],
  founderId: string,
  file?: File
) => {
  emit('update:fieldValue', data, founderId, file)
}

const deleteHandler = () => {
  if (!props.founderId || props.isHead) {
    return
  }

  emit('delete', props.founderId)
}

const show = () => {
  if (props.isHead) {
    realShow()
    return
  }

  emit('update:openedFounderId', props.founderId)
}

const {
  fullName,
} = useClientName(fields)

const updateAddressDetails = (field: ApplicationFormField, details: AddressDetails) => {
  emit('update:address-details', field, details)
}

watch(
  dialog,
  () => {
    if (props.openedFounderId && dialog.value === false) {
      emit('update:openedFounderId', undefined)
    }
  }
)

watchEffect(() => {
  if (props.openedFounderId === props.founderId) {
    realShow()

    return
  }

  hide()
})
</script>

<style lang="scss">
.ApplicationItemFounder {
  background: $grey-2;
  margin: 3px 0 0;
  border-radius: 6px;

  &:hover {
    background: $grey-3;
  }

  .ApplicationItemFounder__fio {
    font-weight: 700;
  }

  .ApplicationItemFounder__type {
    min-width: 200px;
    font-size: 12px;
  }

  .delete {
    &:hover {
      .q-icon {
        color: $red-12 !important;
      }
    }
  }
}
</style>
