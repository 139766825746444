import {computed, Ref, ref} from 'vue'
import {CreditTypeEnum} from 'src/models/Applications/CreditTypeEnum'

export const useApplicationsNewCreditForm = () => {
  const creditAmount = ref<number>()
  const creditMonths = ref<number>()
  const creditType = ref<CreditTypeEnum>(CreditTypeEnum.contract)
  const hasStateContracts = ref(true)

  const setDefault = () => {
    creditAmount.value = undefined
    creditMonths.value = undefined
    hasStateContracts.value = true
    creditType.value = CreditTypeEnum.contract
  }

  setDefault()

  const fieldsSet = [
    'creditAmount',
    'creditMonths',
    'creditType',
    'hasStateContracts',
  ]

  const lastFocused = ref('')

  const data = computed<Record<string, Ref>>(() => ({
    creditAmount,
    creditMonths,
    creditType,
    hasStateContracts,
  }))

  const requiredFields = computed(() => {
    return fieldsSet
  })

  const nextToFocus = computed(() => {
    if (!emptyRequiredFields.value.length) {
      return null
    }

    return emptyRequiredFields.value[0]
  })

  const percentage = computed(() => {
    const total = requiredFields.value
    const empty = emptyRequiredFields.value

    return Math.ceil((total.length - empty.length) / total.length * 100)
  })

  const emptyRequiredFields = computed(() => {
    return requiredFields.value
      .filter(
        f => {
          if (!data.value || !data.value[f]) {
            return true
          }

          if (data.value[f].value === undefined
            || data.value[f].value === null
            || data.value[f].value === ''
          ) {
            return true
          }

          if (f === 'creditAmount') {
            return data.value[f].value === 0
          }

          return false
        }
      )
  })

  const focusNext = () => {
    const fieldName = nextToFocus.value

    if (!fieldName || !data.value || !data.value[fieldName]) {
      return
    }

    lastFocused.value = fieldName
    setTimeout(() => {
      lastFocused.value = ''
    }, 0)
  }

  return {
    creditAmount,
    creditMonths,
    creditType,
    hasStateContracts,
    percentage,
    data,
    lastFocused,
    emptyRequiredFields,
    requiredFields,
    nextToFocus,
    setDefault,
    focusNext,
  }
}
