import {defineStore} from 'pinia'
import {useGridSorting} from 'src/uses/Grid/useGridSorting'
import {GridSortingEnum} from 'src/models/Grid/GridSortingEnum'
import {useRoute} from 'vue-router'

export const useApplicationsGridKikSortingStore
  = defineStore('applications-grid-kik-sorting', () => {
    const route = useRoute()
    const {
      sorting,
      setQueryToRouter,
    } = useGridSorting(
      {
        sort: 'updated',
        direction: GridSortingEnum.DESC
      },
      route.name === 'applications-grid-kik'
    )

    return {
      sorting,
      setQueryToRouter,
    }
  })
