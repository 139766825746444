<template>
  <q-input
    :key="String(props.field.value)"
    v-model="formattedValue"
    class="field-with-top-label"
    :hint="props.ignoreHint ? '' : (props.field.help || '')"
    :disable="props.field.readonly && !props.ignoreReadonly"
    :error="!!props.field.error"
    :error-message="props.field.error"
    :loading="props.field.loading"
    label-slot
    outlined
    hide-bottom-space
    dense
    @blur="onBlur(); emit('update:value', numberValue || null)"
    @focus="onFocus"
  >
    <template #label>
      <slot name="labelPrepend" />
      {{ props.label || props.field.title }}
      <slot name="labelAppend" />
    </template>
  </q-input>
</template>

<script setup lang="ts">
import {computed, nextTick, watchEffect} from 'vue'
import {
  ApplicationFormField,
} from 'src/models/Applications/Form/ApplicationFormResponseData'
import {FormElementValue} from 'src/models/Form'
import {useCurrencyInput} from 'src/uses/useCurrencyInput'

const props = defineProps<{
  field: ApplicationFormField,
  label: string | boolean,
  ignoreReadonly: boolean,
  ignoreHint: boolean,
}>()

const emit = defineEmits<{
  (e: 'update:value', value: FormElementValue): void,
  (e: 'change', value: number): void,
}>()

const {
  numberValue,
  formattedValue,
  onBlur,
  onFocus,
} = useCurrencyInput()

const value = computed<number>(() => {
  return Number.isNaN(props.field.value)
    ? 0
    : props.field.value as number
})

watchEffect(() => {
  nextTick(() => {
    numberValue.value = value.value
  })
})
</script>
