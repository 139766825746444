<template>
  <loading-component />
  <env-edit-component />
  <!--
    @TODO перенести все диалоги по статусам
      либо в соответствующие view либо в MainLayout
      т к в случае отсутствия аутентификации нет кейса когда необходимо показывать данные диалоги
  -->
  <bank-request-sign-dialog />
  <agreement-confirm-dialog />
  <bank-request-sign-and-agreement-confirm-dialog />
  <bank-request-sign-and-on-release-send-dialog />
  <client-reject-comment-dialog />
  <kik-client-reject-comment-dialog />
  <request-dialog />
  <to-new-dialog />
  <bank-reject-dialog />
  <change-release-guarantee-status-dialog />
  <on-release-send-dialog />
  <router-view />
  <support-chat />
  <agent-enter-aware-dialog />
  <smart-app-banner />
</template>

<script lang="ts" setup>
import {useQuasar} from 'quasar'
import LoadingComponent from 'src/components/LoadingComponent.vue'
import BankRequestSignDialog from 'src/components/Applications/BankRequestSignDialog.vue'
import {useEnvStore} from 'src/stores/useEnvStore'
import EnvEditComponent from 'src/components/EnvEditComponent.vue'
import {useImpersonateStore} from 'src/stores/useImpersonateStore'
import {nextTick} from 'vue'
import {useCookieToken} from 'src/stores/Login/useCookieToken'
import OnReleaseSendDialog from 'src/components/Applications/onReleaseSendModal/OnReleaseSendDialog.vue'
import ClientRejectCommentDialog from 'src/components/Applications/ClientRejectCommentDialog.vue'
import RequestDialog from 'src/components/Applications/RequestDialog.vue'
import ToNewDialog from 'src/components/Applications/ToNewDialog.vue'
import BankRejectDialog from 'src/components/Applications/BankRejectDialog.vue'
import {useMetaStore} from 'src/stores/useMetaStore'
import SupportChat from 'src/components/SupportChat.vue'
import AgreementConfirmDialog from 'src/components/Applications/AgreementConfirmDialog.vue'
import BankRequestSignAndAgreementConfirmDialog
  from 'src/components/Applications/BankRequestSignAndAgreementConfirmDialog.vue'
import { usePushNotifications } from 'src/uses/Native/usePushNotifications'
import { useAppUpdate } from 'src/uses/Native/useAppUpdate'
import { useAndroidListeners } from 'src/uses/Native/useAndroidListeners'
import BankRequestSignAndOnReleaseSendDialog from 'components/Applications/BankRequestSignAndOnReleaseSendDialog.vue'
import KikClientRejectCommentDialog from 'components/Applications/KikClientRejectCommentDialog.vue'
import AgentEnterAwareDialog from 'components/AgentEnterAwareDialog.vue'
import ChangeReleaseGuaranteeStatusDialog
  from 'components/Applications/ApplicationControlCenter/InfoAndActionBlocks/ChangeReleaseGuaranteeStatusDialog.vue'
import {useAppUrlOpen} from 'src/uses/Native/useAppUrlOpen'
import SmartAppBanner from 'components/SmartAppBanner.vue'

const $q = useQuasar()
useImpersonateStore()
useEnvStore()
const {performImmediateUpdate} = useAppUpdate()
const appUrlOpen = useAppUrlOpen()

$q.iconMapFn = (iconName) => {
  if (iconName === 'cancel') {
    return {
      cls: 'app-x',
    }
  }
  if (iconName === 'event') {
    return {
      cls: 'app-calendar',
    }
  }
  if (iconName === 'arrow_drop_down') {
    return {
      cls: 'app-chevron-down',
    }
  }
  if (iconName === 'keyboard_arrow_down') {
    return {
      cls: 'app-chevron-down',
    }
  }
  if (iconName === 'check_circle') {
    return {
      cls: 'app-check-circle-bold',
    }
  }
  if (iconName === 'warning') {
    return {
      cls: 'app-exclamation-bold',
    }
  }
  if (iconName === 'chevron_right') {
    return {
      cls: 'app-chevron-right',
    }
  }
  if (iconName === 'chevron_left') {
    return {
      cls: 'app-chevron-left',
    }
  }
  if (iconName === 'edit') {
    return {
      cls: 'app-pencil',
    }
  }
  if (iconName === 'error') {
    return {
      cls: 'svguse:icons.svg?2#app-exclamation-circle-bold',
    }
  }
  if (iconName.startsWith('app-')) {
    return {
      cls: iconName,
    }
  }
}

useMetaStore().reset()

if ($q.platform.is.nativeMobileWrapper) {
  performImmediateUpdate()
  usePushNotifications()
  appUrlOpen.addListeners()
}
if ($q.platform.is.android) {
  useAndroidListeners()
}

nextTick(() => {
  useCookieToken()
})
</script>

<style lang="scss" src="./styles/app.scss" />
