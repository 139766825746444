import {usePostApi} from 'src/uses/Api/usePostApi'
import {FmsUnit, FmsUnitItem, FmsUnitRequest} from 'src/models/Dadata/FmsUnit'
import {ref} from 'vue'
import {EnvCodeEnum, useEnvStore} from 'src/stores/useEnvStore'

export const useDadataFmsUnit = () => {
  const {
    error,
    errors,
    status,
    post,
    response,
    loading,
    setHeader,
  } = usePostApi<FmsUnit, FmsUnitRequest>()

  const {getEnvValue} = useEnvStore()

  const items = ref<FmsUnitItem[]>([])

  const load = async (input: string) => {
    if (!input) {
      return
    }

    setHeader('Authorization', `Token ${getEnvValue(EnvCodeEnum.VUE_APP_DADATA_KEY)}`)
    const url = `${getEnvValue(EnvCodeEnum.VUE_APP_DADATA_URL)}/suggestions/api/4_1/rs/suggest/fms_unit`
    await post(url, {query: input})

    if (status.value !== 200 || !response.value?.suggestions) {
      items.value = []
      return
    }

    items.value = response.value.suggestions
  }

  return {
    error,
    errors,
    status,
    response,
    loading,
    items,
    load,
  }
}
