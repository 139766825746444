import {defineStore} from 'pinia'
import {useGridSorting} from 'src/uses/Grid/useGridSorting'
import {GridSortingEnum} from 'src/models/Grid/GridSortingEnum'
import {useRoute} from 'vue-router'

export const useApplicationsGridSortingStore
  = defineStore('applications-grid-sorting', () => {
    const route = useRoute()
    const {
      sorting,
      setQueryToRouter,
    } = useGridSorting({sort: 'updated', direction: GridSortingEnum.DESC}, route.name === 'applications-grid')

    return {
      sorting,
      setQueryToRouter,
    }
  })
