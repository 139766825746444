<template>
  <page-block-wrapper>
    <div class="profiles-agents">
      <profiles-agents-grid />
    </div>
  </page-block-wrapper>
</template>
<script setup lang="ts">
import ProfilesAgentsGrid from 'src/components/Profiles/Agents/ProfilesAgentsGrid.vue'
import PageBlockWrapper from 'src/components/PageBlockWrapper.vue'
import {useMetaStore} from 'src/stores/useMetaStore'

useMetaStore().setTitle('Агенты')
</script>
