<template>
  <q-dialog
    :model-value="!!props.item && (isAdmin() || isJuniorAdmin())"
    @update:model-value="emit('close')"
  >
    <q-card
      class="q-mt-md bg-blue-1"
      style="min-width: 400px; max-width: 600px; border-radius: 4px !important;"
    >
      <q-card-section class="text-h6 rounded-borders">
        Управление партнерским подписанием
      </q-card-section>
      <q-card-section class="bg-blue-grey-10 text-white row items-center q-py-lg">
        <div class="rounded-borders q-pa-sm bg-blue-grey-9">
          <q-icon
            name="svguse:icons.svg?2#app-information-circle"
            size="sm"
            color="orange-6"
          />
        </div>
        <div class="col q-pl-sm text-wgite">
          При активации партнерского подписания обязательно укажите адрес в поле "Host"
        </div>
      </q-card-section>
      <q-card-section class="white-bg-field">
        <div class="column q-col-gutter-y-md">
          <div>
            <q-toggle
              v-model="enabled"
              label="Партнерское подписание"
              :loading="loading"
            />
          </div>
          <div>
            <q-input
              v-model="host"
              class="field-with-top-label"
              label="Host"
              :loading="loading"
              :disable="!enabled"
              :error="v$.host.$error"
              :error-message="v$.host.$errors[0] ? 'Указан некорректный host' : ''"
              outlined
              hide-hint
              hide-bottom-space
              dense
            />
          </div>
        </div>

      </q-card-section>
      <q-card-actions
        class="row justify-end q-py-md"
        style="background: #D5EAFA;"
      >
        <q-btn
          v-close-popup
          size="16px"
          padding="sm lg"
          label="Отмена"
          unelevated
          no-caps
        />
        <q-btn
          padding="sm lg"
          size="16px"
          color="blue-7"
          label="Сохранить"
          :disable="enabled && !host"
          :loading="loading || saveLoading"
          unelevated
          no-caps
          @click="onSave"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
import {ProfilesAgentList} from 'src/models/Profiles/Agents/ProfilesAgentList'
import {useAuthStore} from 'stores/Login/useAuthStore'
import {useGetPortalData} from 'src/uses/Profiles/Agents/useGetPortalData'
import {computed, ref, watch, watchEffect} from 'vue'
import {useSavePortalEnabled} from 'src/uses/Profiles/Agents/useSavePortalEnabled'
import {urlValidator} from 'src/utils/i18n-validators'
import useVuelidate from '@vuelidate/core'

const props = defineProps<{
  item: ProfilesAgentList | undefined
}>()

const emit = defineEmits<{
  (e: 'close'): void,
}>()

const {
  isAdmin,
  isJuniorAdmin,
} = useAuthStore()

const {
  loading,
  data,
  load,
} = useGetPortalData()

const {
  loading: saveLoading,
  status,
  save,
} = useSavePortalEnabled()

const item = computed(() => props.item)
const enabled = ref(false)
const host = ref('')

const rules = computed(() => {
  if (enabled.value) {
    return {
      host: {urlValidator}
    }
  }

  return {
    host: {}
  }
})

const v$ = useVuelidate(rules, {host})

const onSave = async () => {
  v$.value.$validate()
  if (!item.value || v$.value.$invalid) {
    return
  }

  await save(item.value.id, enabled.value, host.value)

  if (status.value === 200) {
    emit('close')
  }
}

watchEffect(() => {
  if (!data.value) {
    return
  }

  enabled.value = data.value.enabled
  host.value = data.value.host
})

watch(
  item,
  () => {
    if (!item.value) {
      enabled.value = false
      host.value = ''

      return
    }

    load(item.value.id)
  },
  {
    deep: true,
    immediate: true,
  }
)
</script>

<style lang="scss">

</style>
