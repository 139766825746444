<template>
  <q-dialog
    v-model="bankRequestSignDialog"
  >
    <q-card
      v-if="lastStatusPayload"
      class="BankRequestSignModal q-mt-md bg-blue-1"
      style="min-width: 400px; max-width: 600px; border-radius: 4px !important;"
    >
      <q-card-section class="text-h6 rounded-borders">
        Подписание документов
      </q-card-section>
      <q-card-section class="bg-blue-grey-10 text-white row items-center q-py-lg">
        <div class="rounded-borders q-pa-sm bg-blue-grey-9">
          <q-icon
            name="svguse:icons.svg?2#app-information-circle"
            size="sm"
            color="orange-6"
          />
        </div>
        <div class="col q-pl-sm text-wgite">
          Мы заметили, что вы загрузили в заявку неподписанные документы
        </div>
      </q-card-section>
      <q-card-section class="q-pt-none white-bg-field">
        <bank-request-sign-form
          ref="bankRequestSignRef"
          :app-id="lastStatusPayload.id"
        />
      </q-card-section>
      <q-card-actions
        class="row justify-end q-py-md"
        style="background: #D5EAFA;"
      >
        <q-btn
          v-close-popup
          size="16px"
          padding="sm lg"
          label="Отмена"
          unelevated
          no-caps
        />
        <q-btn
          :disable="!bankRequestSignRef || bankRequestSignRef.needSign === undefined || loading"
          :loading="loading"
          padding="sm lg"
          size="16px"
          color="blue-7"
          label="Сохранить"
          unelevated
          no-caps
          @click="statusStore.onSaveBankRequestSign(!!bankRequestSignRef && !!bankRequestSignRef.needSign)"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
import {useStatusStore} from 'src/stores/Status/useStatusStore'
import {storeToRefs} from 'pinia'
import {ref} from 'vue'
import BankRequestSignForm from 'src/components/Applications/BankRequestSignForm.vue'

const statusStore = useStatusStore()
const {
  bankRequestSignDialog,
  lastStatusPayload,
  loading,
} = storeToRefs(statusStore)

const bankRequestSignRef = ref<typeof BankRequestSignForm>()
</script>

<style lang="scss">
.BankRequestSignModal {
  .sendButton {
    .icon {
      background: white;
      border-radius: 3px;
      position: absolute;
      right: 8px;
      line-height: 0;
    }

    .q-btn {
      border-radius: 6px;
    }
  }

  .q-table__top, .q-table__bottom, thead th {
    background: $grey-3 !important;
  }
}
</style>
