<template>
  <q-field
    ref="bgFormField"
    :key="props.field.value"
    :model-value="model"
    :disable="props.field.readonly && !props.ignoreReadonly"
    class="field-with-top-label no-bg btn-group-white-bg"
    :error="!!props.field.error"
    :error-message="props.field.error"
    :loading="props.field.loading"
    label-slot
    borderless
    hide-bottom-space
    dense
  >
    <template #label>
      <slot name="labelPrepend" />
      {{ props.label || props.field.title }}
      <slot name="labelAppend" />
    </template>
    <q-btn-toggle
      :model-value="model"
      :disable="props.field.readonly && !props.ignoreReadonly"
      class="full-width"
      toggle-color="primary"
      color="blue-grey-1"
      text-color="grey-9"
      toggle-text-color="white"
      :options="options"
      no-caps
      spread
      unelevated
      @update:model-value="change"
    />
  </q-field>
</template>

<script setup lang="ts">
import {ref, watchEffect, computed} from 'vue'
import {
  ApplicationFormField,
} from 'src/models/Applications/Form/ApplicationFormResponseData'
import {FormElementValue} from 'src/models/Form'
import {QField} from 'quasar'

const props = defineProps<{
  field: ApplicationFormField,
  label: string | boolean,
  ignoreReadonly: boolean,
  ignoreHint: boolean,
}>()

const emit = defineEmits<{
  (e: 'update:value', value: FormElementValue): void,
}>()

const model = ref<FormElementValue>(null)

const options = computed(() => {
  if (!props.field.choices) {
    return []
  }

  const options = []
  for (const value in props.field.choices) {
    options.push({
      label: props.field.choices[value],
      value,
    })
  }

  return options
})

watchEffect(() => {
  model.value = props.field.value
})

const change = (value: FormElementValue) => {
  model.value = value
  emit('update:value', model.value)
}
</script>
