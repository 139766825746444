<template>
  <page-block-wrapper>
    <div class="profile-wrapper">
      <profile-employees-grid />
    </div>
  </page-block-wrapper>
</template>
<script setup lang="ts">
import ProfileEmployeesGrid from 'src/components/Profile/Employees/ProfileEmployeesGrid.vue'
import PageBlockWrapper from 'src/components/PageBlockWrapper.vue'
import {useMetaStore} from 'src/stores/useMetaStore'

useMetaStore().setTitle('Сотрудники')
</script>
