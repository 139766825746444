import {ControlCenterHeader} from 'src/models/Applications/ControlCenter/ControlCenterHeader'
import {useGetApi} from 'src/uses/Api/useGetApi'
import {ref} from 'vue'
import {UserRoleEnum} from 'src/models/User/UserRoleEnum'
import {useProfileStore} from 'src/stores/Login/useProfileStore'
import {storeToRefs} from 'pinia'

export const useHeaderData = () => {
  const {
    error,
    errors,
    get,
    status,
    response,
    loading,
  } = useGetApi<ControlCenterHeader>()

  const profileStore = useProfileStore()
  const {profile} = storeToRefs(profileStore)

  const headerData = ref<ControlCenterHeader>()

  const AGENT_ORDERS_CONTROL_CENTER_URL = 'v1/control-center'
  const ADMIN_ORDERS_CONTROL_CENTER_URL = 'v1/admin/control-center'

  const loadHeaderData = async (appId: string) => {
    if (!profile.value) {
      return
    }

    const url = [
      UserRoleEnum.Admin,
      UserRoleEnum.JuniorAdmin,
      UserRoleEnum.MiddleAdmin,
    ].includes(profile.value.role)
      ? ADMIN_ORDERS_CONTROL_CENTER_URL
      : AGENT_ORDERS_CONTROL_CENTER_URL

    await get(`${url}/${appId}/header`)

    if (status.value === 200 && response && response.value) {
      headerData.value = response.value
    }
  }

  return {
    error,
    errors,
    get,
    status,
    response,
    loading,
    headerData,
    loadHeaderData,
  }
}
