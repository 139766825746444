import {storeToRefs} from 'pinia'
import {computed} from 'vue'
import {ApplicationItemSectionsEnum} from 'src/models/Enums/ApplicationItemSectionsEnum'
import {ProductFields} from 'src/models/Applications/Form/ProductFields'
import {useApplicationItemEbgStore} from 'stores/Applications/Item/useApplicationItemEbgStore'
import {useApplicationItemEbgFillStore} from 'stores/Applications/Item/useApplicationItemEbgFillStore'
import {useApplicationItemKikFillStore} from 'stores/Applications/Item/useApplicationItemKikFillStore'
import {ProductEnum} from 'src/models/Applications/ProductEnum'
import {useApplicationItemKikStore} from 'stores/Applications/Item/useApplicationItemKikStore'
import {useApplicationItemStore} from 'stores/Applications/Item/useApplicationItemStore'

export const useFillNext = (multiOrderId: number) => {
  const fillEbgStore = useApplicationItemEbgFillStore(multiOrderId)()
  const fillKikStore = useApplicationItemKikFillStore(multiOrderId)()
  const applicationItemStore = useApplicationItemStore(multiOrderId)()

  const countErrorFields = computed(() => {
    return fillEbgStore.countErrorsMainFields
      + fillEbgStore.countErrorsFinanceFields
      + fillEbgStore.countErrorsClientFields
      + fillEbgStore.countErrorsDocumentsFields

      + fillKikStore.countErrorsMainFields
      + fillKikStore.countErrorsFinanceFields
      + fillKikStore.countErrorsClientFields
      + fillKikStore.countErrorsDocumentsFields
  })

  const filterNoLoadingFields = (data: ProductFields) => {
    return {
      all: data.all
        .filter(f => !f.loading),
      banks: data.banks
        .map(b => {
          return {
            ...b,
            fields: b.fields
              .filter(f => !f.loading),
          }
        })
    }
  }

  const changeProductTabIfNeeded = (product: ProductEnum) => {
    if (applicationItemStore.productTab === product) {
      return
    }

    if (applicationItemStore.productTab === ProductEnum.EBG) {
      applicationItemStore.productTab = ProductEnum.KIK
      return
    }

    if (applicationItemStore.productTab === ProductEnum.KIK) {
      applicationItemStore.productTab = ProductEnum.EBG
    }
  }

  const showNextFieldByProduct = (product: ProductEnum) => {
    const fillStore = product === ProductEnum.EBG
      ? useApplicationItemEbgFillStore(multiOrderId)()
      : useApplicationItemKikFillStore(multiOrderId)()

    const {
      countErrorsMainFields,
      countErrorsFinanceFields,
      countErrorsClientFields,
      countErrorsDocumentsFields,
      mainErrorFields,
      financeErrorFields,
      clientHeadFieldsWithErrors,
      clientCommonFieldsWithErrors,
      clientFoundersFieldsWithErrors,
      passportsDocumentsErrorsFields,
      financialDocumentsErrorsFields,
      additionalDocumentsErrorsFields,
    } = storeToRefs(fillStore)

    const applicationItemProductStore = product === ProductEnum.EBG
      ? useApplicationItemEbgStore(multiOrderId)()
      : useApplicationItemKikStore(multiOrderId)()
    const {
      highlightField,
    } = applicationItemProductStore
    const {
      tab,
      financePeriodTab,
      openedFounderId,
    } = storeToRefs(applicationItemProductStore)

    if (countErrorFields.value === 0) {
      return false
    }

    const main = filterNoLoadingFields(mainErrorFields.value)
    if (countErrorsMainFields.value) {
      const bank = main.banks
        .find(b => b.fields.length)
      const field = main.all[0]
        || (bank ? bank.fields[0] : undefined)

      if (field) {
        changeProductTabIfNeeded(product)
        tab.value = ApplicationItemSectionsEnum.MAIN
        highlightField(field, false)
        highlightField(field)

        return true
      }
    }

    if (countErrorsFinanceFields.value) {
      const errorPeriod = Object.fromEntries(
        Object.entries(financeErrorFields.value)
          .filter((e) => {
            return e[1].filter(f => !f.loading).length > 0
          })
      )
      if (errorPeriod) {
        const periodNumber = Object.keys(errorPeriod)[0]
        financePeriodTab.value = periodNumber
          ? parseInt(periodNumber)
          : null

        const periodFields = Object.values(errorPeriod)[0] || []
        const field = periodFields[0]

        changeProductTabIfNeeded(product)
        tab.value = ApplicationItemSectionsEnum.FINANCIAL
        highlightField(field, false)
        highlightField(field)

        return true
      }
    }

    const commonClient = clientCommonFieldsWithErrors.value
      .filter(f => !f.loading)
    if (countErrorsClientFields.value && commonClient.length
    ) {
      changeProductTabIfNeeded(product)
      tab.value = ApplicationItemSectionsEnum.CLIENT
      highlightField(commonClient[0], false)
      highlightField(commonClient[0])

      return true
    }

    const clientHead = clientHeadFieldsWithErrors.value
      .filter(f => !f.loading)
    if (countErrorsClientFields.value && clientHead.length
    ) {
      changeProductTabIfNeeded(product)
      tab.value = ApplicationItemSectionsEnum.CLIENT
      openedFounderId.value = 'head'
      highlightField(clientHead[0], false)
      highlightField(clientHead[0])

      return true
    }

    const clientFounders = clientFoundersFieldsWithErrors.value
      .filter(founder => founder.fields.find(f => !f.loading))
      .map(founder => {
        return {
          ...founder,
          fields: founder.fields
            .filter(f => !f.loading),
        }
      })
    if (countErrorsClientFields.value && clientFounders.length) {
      const founder = clientFounders[0]
      if (!founder.fields[0]) {
        return false
      }

      changeProductTabIfNeeded(product)
      tab.value = ApplicationItemSectionsEnum.CLIENT
      openedFounderId.value = founder.id
      highlightField(founder.fields[0], false)
      highlightField(founder.fields[0])

      return true
    }

    const passportDocuments = passportsDocumentsErrorsFields.value
      .filter(d => !d.loading)
    if (countErrorsDocumentsFields.value && passportDocuments.length) {
      changeProductTabIfNeeded(product)
      tab.value = ApplicationItemSectionsEnum.DOCUMENTS
      highlightField(passportDocuments[0], false)
      highlightField(passportDocuments[0])

      return true
    }

    const financialDocuments = financialDocumentsErrorsFields.value
      .filter(d => !d.loading)
    if (countErrorsDocumentsFields.value && financialDocuments.length) {
      changeProductTabIfNeeded(product)
      tab.value = ApplicationItemSectionsEnum.DOCUMENTS
      highlightField(financialDocuments[0], false)
      highlightField(financialDocuments[0])

      return true
    }

    const additionalDocuments = additionalDocumentsErrorsFields.value
      .filter(d => !d.loading)
    if (countErrorsDocumentsFields.value && additionalDocuments.length) {
      changeProductTabIfNeeded(product)
      tab.value = ApplicationItemSectionsEnum.DOCUMENTS
      highlightField(additionalDocuments[0], false)
      highlightField(additionalDocuments[0])

      return true
    }

    return false
  }

  const showNextField = () => {
    if (!showNextFieldByProduct(ProductEnum.EBG)) {
      showNextFieldByProduct(ProductEnum.KIK)
    }
  }

  return {
    countErrorFields,
    showNextField,
  }
}
