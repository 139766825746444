import {computed, onBeforeMount, ref, UnwrapRef} from 'vue'
import {LocationQueryRaw, useRoute, useRouter} from 'vue-router'

export const useGridFilters = <T = Record<string, number | string | boolean | string[] | number[] | null>>(filtersInitValue: T, syncToRouter = false) => {
  const filters = ref<T>({...filtersInitValue})
  const isDefaultFilterValue = computed(() => {
    return JSON.stringify(filters.value) === JSON.stringify(filtersInitValue)
  })

  const router = useRouter()
  const route = useRoute()

  const getFiltersQuery = () => {
    return Object.fromEntries(
      Object.entries(filters.value as ArrayLike<T>)
        .filter(filter => filter[1] !== undefined
          && filter[1] !== null
          && filter[1] !== ''
          && (!Array.isArray(filter[1]) || filter[1].length !== 0)
        )
        .map(filter => [`filter[${filter[0]}]`, filter[1]])
    )
  }

  const clear = () => {
    filters.value = {...filtersInitValue} as UnwrapRef<T>
  }

  const getNotFilterQuery = () => {
    return Object.fromEntries(
      Object.entries(route.query)
        .filter(([key]) => !key.startsWith('filter'))
    )
  }

  const setQueryToRouter = async () => {
    if (!syncToRouter) {
      return
    }

    await router.replace({
      ...route,
      query: {
        ...getNotFilterQuery(),
        ...getFiltersQuery(),
      } as LocationQueryRaw
    })
  }

  const clearable = computed(() => {
    for (const key in filtersInitValue) {
      if (Array.isArray(filtersInitValue[key])) {
        if ((filtersInitValue[key] as never as never[]).length !== ((filters.value as T)[key] as never as never[]).length) {
          return true
        } else {
          continue
        }
      }

      /* eslint-disable eqeqeq */
      if (filtersInitValue[key] != (filters.value as T)[key]) {
        if ((filtersInitValue[key] as never as string) === null
          && (filters.value as T)[key] as never as string === ''
        ) {
          continue
        }

        return true
      }
    }

    return false
  })

  const hasFiltersInRoute = () => {
    if (!syncToRouter) {
      return false
    }

    for (const queryKey in route.query) {
      if (queryKey.includes('filter')) {
        return true
      }
    }

    return false
  }

  onBeforeMount(() => {
    if (hasFiltersInRoute()) {
      clear()
      const newFilters: T = {
        ...filtersInitValue
      }
      for (const queryKey in route.query) {
        if (!queryKey.includes('filter')) {
          continue
        }

        const key = queryKey.replace(/filter\[(.+)]/gi, '$1') as keyof T
        if (!key) {
          continue
        }

        if (key === 'banks' && !Array.isArray(route.query[queryKey])) {
          route.query[queryKey] = [route.query[queryKey] as string]
        }

        newFilters[key] = (
          route.query[queryKey] === 'true'
            ? true
            : route.query[queryKey] === 'false'
              ? false
              : route.query[queryKey]
        ) as never as T[keyof T]
      }
      filters.value = {
        ...newFilters
      } as UnwrapRef<T>
    }
  })

  return {
    filters,
    clearable,
    isDefaultFilterValue,
    getFiltersQuery,
    clear,
    setQueryToRouter,
  }
}
