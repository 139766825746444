import {ApplicationFormField} from 'src/models/Applications/Form/ApplicationFormResponseData'
import {DocumentGroupEnum} from 'src/models/Enums/DocumentGroupEnum'
import {FormItemGeneratedDocumentListItem} from 'src/models/FormEditor/FormItemGeneratedDocumentListItem'
import {FormElementValue} from 'src/models/Form'

export enum ApplicationFormDocumentGroupEnum {
  additional = 'additional',
  financial = 'financial',
  passports = 'passports',
  deal = 'deal',
  offer = 'offer',
  client = 'client',
}

export enum FormItemConditionsParamTypeEnum {
  SELECT = 'select',
  NUMBER = 'number',
  STRING = 'string',
}

export enum FormItemConditionComparisonOperatorEnum {
  LT = 'lt',
  EQ = 'eq',
  GT = 'gt',
}

export interface FormItemConditionsParamOptionsWithChild {
  code: string
  name: string,
  child: FormItemConditionsParamOptionsWithChild[]
}

export interface FormItemConditionsParam {
  multiple: boolean
  options: Record<string, string> | FormItemConditionsParamOptionsWithChild | null
  param: string
  relations: FormItemConditionComparisonOperatorEnum[]
  title: string
  type: FormItemConditionsParamTypeEnum
}

export interface FormItemFinancePeriodConditionsPart {
  operator: FormItemConditionComparisonOperatorEnum
  param: string,
  value: FormElementValue
}
export interface FormItemFinancePeriodCondition {
  operator: 'and'
  parts: FormItemFinancePeriodConditionsPart[]
}

export interface FormItemFinancePeriodConditions {
  operator: 'or'
  parts: FormItemFinancePeriodCondition[]
}

export interface FormItemField extends ApplicationFormField{
}

export interface FormFinanceBlockReportField {
  id: string
  title: string
  code?: string
}

export interface FormFinanceBlockReport {
  id: string
  title: string
  fields: FormFinanceBlockReportField[]
}

export interface FormFinanceBlockPeriod {
  id?: string
  year?: number | null
  quarter?: number | string | null
  comment?: string
  reports?: FormFinanceBlockReport[]
  conditions?: FormItemFinancePeriodConditions
}

export interface FormFinanceBlock {
  allow_short_reporting: boolean
  periods: FormFinanceBlockPeriod[]
}

export interface ApplicationFormFounder {
  fields: ApplicationFormField[]
  id: string
}

export interface FormItemDocument {
  id: string
  code: string
  title: string
  description: string
  group: DocumentGroupEnum
  period: FormFinanceBlockPeriod[]
  required?: boolean
  selected?: boolean
  client_document?: boolean
}

export interface FormItemClientBlock {
  id: string
  client_common: {
    id: string
    fields: FormItemField[]
  }
  client_head: {
    id: string
    fields:FormItemField[]
  }
}

export interface FormItemOrderBlock {
  id: string
  order_main: {
    id: string
    fields: FormItemField[]
  }
}

export interface FormItemResponseData {
  id: string
  client_block: FormItemClientBlock
  document_block: FormItemDocument[]
  finance_block: FormFinanceBlock
  generated_document_block: FormItemGeneratedDocumentListItem[] | null
  order_block: FormItemOrderBlock
  conditions_params?: FormItemConditionsParam[]
}
