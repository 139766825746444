export const useSearch = () => {
  const filter = (search: string, compareWith: string[]) => {
    if (!search) {
      return true
    }

    const values = search
      .replaceAll(/^\s+/gi, '')
      .replaceAll(/\s+$/gi, '')
      .toLowerCase()
      .split(' ')

    if (!values.length) {
      return true
    }

    return compareWith
      .find(f => {
        let findCount = 0
        for (const value of values) {
          if (f.toLowerCase().includes(value)) {
            findCount++
          }
        }

        return findCount === values.length
      })
  }

  return {
    filter,
  }
}
