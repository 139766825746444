import {usePostApi} from 'src/uses/Api/usePostApi'

export const useDuplication = () => {
  const {
    post,
    status,
    response,
    loading,
  } = usePostApi<{message: string, multi_order_id: string}>(true)

  const duplicate = async (id: string) => {
    await post(`v2/orders/${id}/duplication/create`)
  }

  return {
    loading,
    status,
    response,
    duplicate,
  }
}
