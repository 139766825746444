<template>
  <tr>
    <td>{{ props.field.title }}</td>
    <td>{{ props.field.code }}</td>
    <td :class="classes">
      <span ref="fieldRef" />
      <q-field
        :model-value="props.field.value"
        :bg-color="bgColor"
        :color="color"
        borderless
        disable
        hide-bottom-space
        dense
      >
        <template #control="{ id, floatingLabel, emitValue }">
          <input
            v-show="floatingLabel"
            :id="id"
            v-model="formattedValue"
            :key="JSON.stringify(props.field)"
            class="q-field__input text-right"
            disabled
            @blur="e => {emitValue((e.target as HTMLInputElement).value); onBlur()}"
            @focus="onFocus"
          >
        </template>
      </q-field>
    </td>
  </tr>
</template>

<script setup lang="ts">
import {computed, nextTick, ref, watch, watchEffect} from 'vue'
import {ApplicationFormFinanceField} from 'src/models/Applications/Form/ApplicationFormResponseData'
import {useValid} from 'src/uses/Applications/Form/useValid'
import {useScrollTo} from 'src/uses/useScrollTo'
import {useCurrencyInput} from 'src/uses/useCurrencyInput'
import {useLayoutStore} from 'stores/useLayoutStore'
import {storeToRefs} from 'pinia'
import {useQuasar} from 'quasar'

const props = defineProps<{
  field: ApplicationFormFinanceField,
}>()

const {
  scrollToInvisibleElement,
} = useScrollTo()

const {
  formattedValue,
  numberValue,
  onFocus,
  onBlur,
} = useCurrencyInput(true)

const layoutStore = useLayoutStore()
const {rightDrawer, drawer} = storeToRefs(layoutStore)

const $q = useQuasar()

const fieldRef = ref<HTMLSpanElement>()

const classes = computed(() => {
  return {
    'white-bg-field': !props.field.highlighted,
    'warning-bg-field': props.field.highlighted,
  }
})

const bgColor = computed(() => {
  if (props.field.highlighted) {
    return undefined
  }

  return hasError(props.field.valid) || props.field.error
    ? 'red-1'
    : 'green-1'
})

const color = computed(() => {
  if (props.field.highlighted) {
    return undefined
  }

  return props.field.error
    ? 'red-13'
    : 'green-10'
})

const {hasError} = useValid()

watchEffect(() => {
  if (!props.field.highlighted) {
    return
  }

  if ($q.platform.is.mobile) {
    rightDrawer.value = false
    drawer.value = false
  }

  setTimeout(() => {
    if (fieldRef.value) {
      scrollToInvisibleElement(fieldRef.value)
    }
  }, 300)
})

watch(
  props.field,
  () => {
    nextTick(() => {
      numberValue.value = props.field.value === 0 || props.field.value
        ? props.field.value
        : undefined
    })
  },
  {
    deep: true,
    immediate: true,
  },
)
</script>
