<template>
  <div style="margin-top: -24px; margin-left: -24px; margin-right: -24px; margin-bottom: -24px;">
    <template v-if="headerData">
      <!--<application-last-modified-info
        v-if="item && item.last_update"
        :model-value="item.last_update"
      />-->
      <application-control-center-header
        v-if="productType"
        :header-data="headerData"
        :documents="documents"
        :loading="loadingHeaderData && loadingItem"
        :stop-factors="getStopFactorsResultsById(headerData.id)"
        :product-type="productType"
        @changed="init"
      />
      <div class="q-mt-md">
        <q-tabs
          v-model="tab"
          class="ApplicationControlCenterTabs q-mx-lg"
          align="justify"
          no-caps
          inline-label
        >
          <application-control-center-tab
            :name="ApplicationControlCenterSectionsEnum.MAIN"
            :loading="loadingHeaderData"
            label="Центр управления"
          />
          <application-control-center-tab
            :name="ApplicationControlCenterSectionsEnum.PARAMETERS"
            :loading="loadingItem"
            label="Параметры заявки"
          />
          <application-control-center-tab
            v-if="periods.length > 0"
            :name="ApplicationControlCenterSectionsEnum.FINANCIAL"
            :loading="loadingItem"
            label="Финансовый блок"
          />
          <application-control-center-tab
            :name="ApplicationControlCenterSectionsEnum.CLIENT"
            :loading="loadingItem"
            label="Данные по клиенту"
          />
          <application-control-center-tab
            :name="ApplicationControlCenterSectionsEnum.DOCUMENTS"
            :loading="loadingItem"
            label="Документы"
          />
        </q-tabs>
        <div
          class="ApplicationControlCenterTabsContent"
        >
          <q-tab-panels
            v-model="tab"
            class="bg-transparent"
            animated
          >
            <q-tab-panel
              :name="ApplicationControlCenterSectionsEnum.MAIN"
              class="ApplicationControlCenterMain q-pa-lg"
            >
              <application-control-center-main :header-data="headerData" />
            </q-tab-panel>
            <q-tab-panel
              :name="ApplicationControlCenterSectionsEnum.PARAMETERS"
              class="ApplicationControlCenterParameters q-pa-lg"
            >
              <application-control-center-main-fields
                :fields="mainVisibleFields"
                :app-id="appId"
              />
              <application-form-beneficiaries-list
                v-if="item && productType"
                :item="item"
                :product="productType"
              />
            </q-tab-panel>

            <q-tab-panel
              v-if="periods.length > 0"
              :name="ApplicationControlCenterSectionsEnum.FINANCIAL"
              class="ApplicationControlCenterFinance q-pa-lg"
            >
              <application-control-center-finance :app-id="appId" />
            </q-tab-panel>

            <q-tab-panel
              :name="ApplicationControlCenterSectionsEnum.CLIENT"
              class="ApplicationControlCenterClientData q-pa-lg"
            >
              <application-control-center-client-common-fields
                :fields="clientCommonVisibleFields"
                :app-id="appId"
              />
              <application-control-center-item-head-and-founders
                :head-fields="clientHeadVisibleFields"
                :founders-fields="clientFoundersVisibleFields"
                :app-id="appId"
              />
            </q-tab-panel>

            <q-tab-panel
              :name="ApplicationItemSectionsEnum.DOCUMENTS"
              class="ApplicationControlCenterDocuments q-pa-lg"
            >
              <application-control-center-item-documents :app-id="appId" />
            </q-tab-panel>
          </q-tab-panels>
        </div>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import {useRoute} from 'vue-router'
import {computed, onBeforeMount, onBeforeUnmount, watchEffect} from 'vue'
import {storeToRefs} from 'pinia'
import {useApplicationControlCenterStore} from 'src/stores/Applications/ControlCenter/useApplicationControlCenterStore'
import ApplicationControlCenterHeader
  from 'src/components/Applications/ApplicationControlCenter/ApplicationControlCenterHeader.vue'
import {ApplicationItemSectionsEnum} from 'src/models/Enums/ApplicationItemSectionsEnum'
import ApplicationControlCenterTab
  from 'src/components/Applications/ApplicationControlCenter/Tabs/ApplicationControlCenterTab.vue'
import {ApplicationControlCenterSectionsEnum} from 'src/models/Enums/ApplicationControlCenterSectionsEnum'
import ApplicationControlCenterMain
  from 'src/components/Applications/ApplicationControlCenter/ApplicationControlCenterMain.vue'
import {
  useApplicationControlCenterItemStore
} from 'src/stores/Applications/ControlCenter/useApplicationControlCenterItemStore'
import ApplicationControlCenterMainFields
  from 'src/components/Applications/ApplicationControlCenter/Form/ApplicationControlCenterMainFields.vue'
import ApplicationControlCenterFinance
  from 'src/components/Applications/ApplicationControlCenter/Form/ApplicationControlCenterFinance.vue'
import ApplicationControlCenterClientCommonFields
  from 'src/components/Applications/ApplicationControlCenter/Form/Client/ApplicationControlCenterClientCommonFields.vue'
import ApplicationControlCenterItemHeadAndFounders
  from 'src/components/Applications/ApplicationControlCenter/Form/Client/ApplicationControlCenterItemHeadAndFounders.vue'
import ApplicationControlCenterItemDocuments
  from 'src/components/Applications/ApplicationControlCenter/Form/ApplicationControlCenterItemDocuments.vue'
//import ApplicationLastModifiedInfo from 'src/components/Applications/ApplicationLastModifiedInfo.vue'
import {useControlCenterChatStore} from 'src/stores/Applications/ControlCenter/useControlCenterChatStore'
import ApplicationFormBeneficiariesList from 'src/components/Applications/Form/ApplicationFormBeneficiariesList.vue'
import {useApplicationFormStopFactors} from 'src/uses/Applications/Form/useApplicationFormStopFactors'
import {useLoadingStore} from 'src/stores/useLoadingStore'
import {
  useApplicationControlCenterChangeStatusStore
} from 'src/stores/Applications/ControlCenter/ChangeStatus/useApplicationControlCenterChangeStatusStore'
import {useYMStore} from 'stores/useYMStore'
import {ApplicationFormDocumentGroupEnum} from 'src/models/Applications/Form/ApplicationFormResponseData'

const route = useRoute()
const appId = computed(() => route.params.appId as string)

const YMStore = useYMStore()

const applicationControlCenterStore = useApplicationControlCenterStore(appId.value)()
const {init} = applicationControlCenterStore
const {
  loadingHeaderData,
  headerData,
} = storeToRefs(applicationControlCenterStore)

const changeStatusStore = useApplicationControlCenterChangeStatusStore(appId.value)()

const applicationControlCenterItemStore = useApplicationControlCenterItemStore(appId.value)()
const {
  load,
} = applicationControlCenterItemStore
const {
  tab,
  documentTab,
  productType,
  loading: loadingItem,
  mainVisibleFields,
  item,
  clientCommonVisibleFields,
  clientHeadVisibleFields,
  clientFoundersVisibleFields,
  documents,
  periods,
} = storeToRefs(applicationControlCenterItemStore)

const controlCenterChatStore = useControlCenterChatStore(appId.value)()
const {
  init: chatInit,
} = controlCenterChatStore

const {
  getStopFactorsResultsById,
} = useApplicationFormStopFactors(item, productType.value)

const loadingStore = useLoadingStore()

onBeforeMount(async () => {
  loadingStore.showLoading('Пожалуйста подождите, центр управления заявкой открывается.\nЭто займет несколько секунд...')
  await init()
  await load()
  loadingStore.hideLoading()

  if (headerData.value && headerData.value.number) {
    YMStore.setOrderNumber(headerData.value.number)
  }

  const tabs = Object.values<string>(ApplicationControlCenterSectionsEnum)
  if (route.query.tab && tabs.includes(route.query.tab as string)) {
    tab.value = route.query.tab as ApplicationControlCenterSectionsEnum
  }
  const documentTabs = Object.values<string>(ApplicationFormDocumentGroupEnum)
  if (route.query.documentTab && documentTabs.includes(route.query.documentTab as string)) {
    documentTab.value = route.query.documentTab as ApplicationFormDocumentGroupEnum
  }
})

onBeforeUnmount(() => {
  applicationControlCenterStore.$reset()
  applicationControlCenterStore.$dispose()

  applicationControlCenterItemStore.$reset()
  applicationControlCenterItemStore.$dispose()

  controlCenterChatStore.$reset()
  controlCenterChatStore.$dispose()

  changeStatusStore.$reset()
  changeStatusStore.$dispose()
})

watchEffect(() => {
  if (!headerData.value) {
    return
  }

  chatInit(headerData.value)
})

</script>

<style lang="scss">
.ApplicationItemTabs {

}

.ApplicationControlCenterTabsContent {
  background: $blue-1;
  &.ApplicationItemTabsContent--ok {
    background: $green-1;
  }
  &.ApplicationControlCenterTabsContent--in_progress {
    background: $blue-1;
  }
  &.ApplicationControlCenterTabsContent--error {
    background: $blue-1;
  }
}
</style>
