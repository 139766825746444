export enum DocumentsCodesEnum {
  ACCOUNTING_REPORTS = 'ACCOUNTING_REPORTS',
  TAX_RETURN = 'TAX_RETURN',
  ACCOUNT_CARD_51_FOR_LAST_FULL_YEAR = 'ACCOUNT_CARD_51_FOR_LAST_FULL_YEAR',
  ACCOUNT_CARD_51_FOR_YEAR = 'ACCOUNT_CARD_51_FOR_YEAR',
  PASSPORT_HEAD = 'PASSPORT_HEAD',
  PASSPORT_FOUNDER = 'PASSPORT_FOUNDER',
  ADDED_DOCUMENT = 'ADDED_DOCUMENT',
  BG_FORM = 'BG_FORM',
  INVOICE = 'INVOICE',
  BG_SCAN = 'BG_SCAN',
  EXTRACT_FROM_BG_REGISTRY = 'EXTRACT_FROM_BG_REGISTRY',
  CUSTOMER_FORM = 'CUSTOMER_FORM',
  POWER_OF_ATTORNEY_FOR_THE_RIGHT_TO_SIGN = 'POWER_OF_ATTORNEY_FOR_THE_RIGHT_TO_SIGN',
}
