import {defineStore} from 'pinia'
import {usePostApi} from 'src/uses/Api/usePostApi'

export const useFormItemDataCopyStore = defineStore('form-item-data-copy', () => {
  const {
    post,
    response,
    status,
    loading,
    error,
    errors,
  } = usePostApi<never, never>()

  const copy = async (formId: string, blockId: string, sectionId: string,) => {
    await post(`v2/forms/import/fields/from/form/${formId}/to/block/${blockId}/section/${sectionId}`)
  }

  return {
    loading,
    response,
    status,
    error,
    errors,
    copy,
  }
})
