<template>
  <q-form v-if="agentData">
    <div class="row q-mb-sm">
      <div class="title">Основные реквизиты</div>
    </div>
    <div class="row q-col-gutter-md">
      <div class="col-4 col-xs-12 white-bg-field q-mb-md">
        <q-input
          v-model="agentData.inn"
          class="field-with-top-label"
          :loading="loading"
          :error-message="getErrorMessage('inn')"
          :error="hasError('inn')"
          :disable="$q.platform.is.mobile"
          autocomplete="new-password"
          label="ИНН"
          outlined
          hide-bottom-space
          dense
          @blur="validation.inn.$validate()"
          @focus="clearError('inn')"
        />
      </div>
      <div class="col-8 col-xs-12 white-bg-field q-mb-md">
        <q-input
          v-model="agentData.title"
          class="field-with-top-label"
          :loading="loading"
          :error-message="getErrorMessage('title')"
          :error="hasError('title')"
          :disable="$q.platform.is.mobile"
          label="Название"
          outlined
          hide-bottom-space
          dense
          @blur="validation.title.$validate()"
          @focus="clearError('title')"
        />
      </div>
      <div class="col-4 col-xs-12 white-bg-field q-mb-md">
        <q-input
          v-model="agentData.ceo_position"
          class="field-with-top-label"
          :loading="loading"
          :error-message="getErrorMessage('ceo_position')"
          :error="hasError('ceo_position')"
          :disable="$q.platform.is.mobile"
          label="Должность руководителя"
          outlined
          hide-bottom-space
          dense
          @blur="validation.ceo_position.$validate()"
          @focus="clearError('ceo_position')"
        />
      </div>
      <div class="col-8 col-xs-12 white-bg-field q-mb-md">
        <q-input
          v-model="agentData.ceo_name"
          class="field-with-top-label"
          :loading="loading"
          :error-message="getErrorMessage('ceo_name')"
          :error="hasError('ceo_name')"
          :disable="$q.platform.is.mobile"
          label="ФИО руководителя"
          outlined
          hide-bottom-space
          dense
          @blur="validation.ceo_name.$validate()"
          @focus="clearError('ceo_name')"
        />
      </div>
      <div class="col-4 col-xs-12 white-bg-field q-mb-md">
        <q-input
          v-model="agentData.based_on"
          class="field-with-top-label"
          :loading="loading"
          :error-message="getErrorMessage('based_on')"
          :error="hasError('based_on')"
          :disable="$q.platform.is.mobile"
          label="Действует на основании"
          outlined
          hide-bottom-space
          dense
          @blur="validation.based_on.$validate()"
          @focus="clearError('based_on')"
        />
      </div>
      <div class="col-4 col-xs-12 white-bg-field q-mb-md">
        <q-input
          v-model="agentData.contact_email"
          class="field-with-top-label"
          :loading="loading"
          autocomplete="new-password"
          label="E-mail"
          :error-message="getErrorMessage('contact_email')"
          :error="hasError('contact_email')"
          :disable="$q.platform.is.mobile"
          outlined
          hide-bottom-space
          dense
          @blur="validation.contact_email.$validate()"
          @focus="clearError('contact_email')"
        />
      </div>
      <div class="col-4 col-xs-12 white-bg-field q-mb-md">
        <q-input
          v-model="agentData.contact_phone"
          class="field-with-top-label"
          :loading="loading"
          :error-message="getErrorMessage('contact_phone')"
          :error="hasError('contact_phone')"
          :disable="$q.platform.is.mobile"
          type="tel"
          label="Телефон"
          mask="+# (###) ### - ## - ##"
          hint="+# (###) ### - ## - ##"
          unmasked-value
          fill-mask
          outlined
          hide-bottom-space
          dense
          @blur="validation.contact_phone.$validate()"
          @focus="clearError('contact_phone')"
        />
      </div>
      <div class="col-8 col-xs-12 white-bg-field q-mb-md">
        <q-input
          v-model="agentData.fact_address"
          class="field-with-top-label"
          :loading="loading"
          :error-message="getErrorMessage('fact_address')"
          :error="hasError('fact_address')"
          :disable="$q.platform.is.mobile"
          label="Фактический адрес"
          outlined
          hide-bottom-space
          dense
          @blur="validation.fact_address.$validate()"
          @focus="clearError('fact_address')"
        />
      </div>
      <div class="col white-bg-field q-mb-md">
        <q-field
          :model-value="agentData.tax_system"
          class="field-with-top-label no-bg q-mb-sm text-no-wrap"
          label="Система налогообложения"
          :error-message="getErrorMessage('tax_system')"
          :error="hasError('tax_system')"
          :disable="$q.platform.is.mobile"
          borderless
          hide-bottom-space
          dense
        >
          <q-btn-toggle
            v-model="agentData.tax_system"
            class="full-width"
            toggle-color="primary"
            color="white"
            text-color="grey-9"
            toggle-text-color="white"
            :disable="$q.platform.is.mobile"
            :options="[
              {label: 'УСН', value: 'SYSTEM_USN'},
              {label: 'ОСН', value: 'SYSTEM_OSN'}
            ]"
            spread
            unelevated
            @change="validation.tax_system.$validate()"
          />
        </q-field>
      </div>
    </div>
    <payment-requisites
      :store="props.store"
      :disable-requisites-changing="props.disableRequisitesChanging"
    />
  </q-form>
</template>

<script setup lang="ts">
import {storeToRefs} from 'pinia'
import {useValidation} from 'src/uses/useValidation'
import {useProfileRequisitesCompanyStore} from 'src/stores/Profile/Requisites/useProfileRequisitesCompanyStore'
import {useAgentCompanyEditStore} from 'src/stores/Profiles/Agents/useAgentCompanyEditStore'
import PaymentRequisites from 'src/components/Requisites/PaymentRequisites.vue'
import {useQuasar} from 'quasar'

const props = defineProps<{
  store: typeof useProfileRequisitesCompanyStore | typeof useAgentCompanyEditStore,
  disableRequisitesChanging: boolean,
}>()

const requisitesCompanyStore = props.store()

const $q = useQuasar()

const {
  agentData,
  validation,
  errors,
  loading,
} = storeToRefs(requisitesCompanyStore)

const {
  getErrorMessage,
  hasError,
  clearError
} = useValidation(validation, errors)

</script>
