<template>
  <q-btn
    v-if="$q.platform.is.mobile"
    label="Удалить"
    class="mobileBtnDelete"
    icon-right="svguse:icons.svg?2#app-pencil-alt"
    no-wrap
    no-caps
    unelevated
    @click="open"
  />
  <q-btn
    v-else
    size="sm"
    color="blue-grey-4"
    icon="svguse:icons.svg?2#app-trash"
    round
    flat
    @click="open"
  />
  <q-dialog
    v-model="dialog"
    :position="$q.platform.is.mobile ? 'bottom' : 'standard'"
    :full-width="$q.platform.is.mobile"
  >
    <q-card
      style="min-width: 300px"
      class="modal-blue-background"
    >
      <q-card-section
        class="modal-title row items-center q-pb-none q-pl-lg"
      >
        <div>Удаление сотрудника</div>
        <q-space />
        <q-btn
          v-close-popup
          class="modal-close-icon"
          icon="svguse:icons.svg?2#app-x"
          flat
          round
          dense
        />
      </q-card-section>
      <q-card-section>
        <info-banner
          text="Вы уверены?"
          caption="Выберите сотрудника которому перейдут заявки"
          icon="svguse:icons.svg?2#app-exclamation"
          color="red"
        />
      </q-card-section>
      <q-card-section>
        <q-form>
          <div class="row q-col-gutter-md">
            <div class="col-12 white-bg-field">
              <q-select
                :model-value="successor"
                :options="successors"
                :loading="successorsLoading"
                style="min-width: 200px"
                class="white field-with-top-label"
                option-value="id"
                option-label="name"
                label="Сотрудник"
                outlined
                hide-hint
                hide-bottom-space
                dense
                @update:model-value="onChange"
              />
            </div>
          </div>
        </q-form>
      </q-card-section>
      <q-card-actions
        v-if="$q.platform.is.mobile"
        align="right"
        class="modal-actions text-primary q-pa-lg row"
      >
        <q-btn
          :loading="loading"
          label="Удалить"
          color="red"
          class="col-12"
          style="padding: 17px 28px;"
          unelevated
          icon-right="svguse:icons.svg?2#app-trash"
          no-caps
          @click="handleSubmit()"
        />
        <q-btn
          v-close-popup
          :loading="loading"
          label="Отмена"
          class="col-12"
          style="padding: 17px 28px;"
          unelevated
          flat
          no-caps
          text-color="black"
        />
      </q-card-actions>
      <q-card-actions
        v-else
        align="right"
        class="modal-actions text-primary q-pa-lg"
      >
        <q-btn
          v-close-popup
          :loading="loading"
          label="Отмена"
          class="q-pr-lg"
          style="padding: 17px 28px;"
          unelevated
          flat
          no-caps
          text-color="black"
        />
        <q-btn
          :loading="loading"
          label="Удалить"
          color="red"
          style="padding: 17px 28px;"
          unelevated
          icon-right="svguse:icons.svg?2#app-trash"
          no-caps
          @click="handleSubmit()"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
import {computed, onBeforeUnmount, watch} from 'vue'
import {useDialog} from 'src/uses/Dialogs/useDialog'
import {storeToRefs} from 'pinia'
import {useEmployeeDeleteStore} from 'src/stores/Profile/Employees/useEmployeeDeleteStore'
import InfoBanner from 'src/components/InfoBanner.vue'
import {useSuccessorsListStore} from 'src/stores/Profile/Employees/useSuccessorsListStore'
import {SuccessorItem} from 'src/models/Profile/Employees/SuccessorItem'
import {useQuasar} from 'quasar'

const emit = defineEmits<{
  (e: 'change'): void,
}>()

const props = defineProps<{
  itemId: string
}>()
const itemId = computed(() => props.itemId)
const {dialog, show, hide} = useDialog()

const deleteEmployeeStore = useEmployeeDeleteStore()

const {
  success,
  loading,
  successor,
} = storeToRefs(deleteEmployeeStore)

const {
  deleteEmployee
} = deleteEmployeeStore

const $q = useQuasar()

const successorsListStore = useSuccessorsListStore('delete-employee-modal')()
const {load} = successorsListStore
const {loading: successorsLoading, successors} = storeToRefs(successorsListStore)

const open = async () => {
  show()
  if (!successors || successors?.value?.length === 0) {
    await load(itemId.value)
  }
}

onBeforeUnmount(() => {
  successorsListStore.$dispose()
})

const handleSubmit = async () => {
  await deleteEmployee(itemId.value)
  hide()
}

const onChange = (val: SuccessorItem) => {
  successor.value = val
}

watch(
  success,
  () => {
    if (success.value) {
      emit('change')
    }
  },
  {
    deep: true,
  },
)
</script>

<style lang="scss">
  .mobileBtnDelete {
    padding: 9px;
    font-size: 14px;
    width: 100%;
    color: #FF5252;
    background-color: #eeeeee
  }
</style>
