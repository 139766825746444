<template>
  <div class="col-12">
    <span class="q-mr-lg">Выбрано агентов: {{ agents.length }}</span>
    <q-btn
      color="primary"
      label="Прикрепить агентского менеджера"
      no-caps
      @click="open"
    />
  </div>
  <q-dialog
    v-model="dialog"
  >
    <q-card>
      <q-card-section>
        <div class="text-h6">Перекрепить агентского менеджера</div>
      </q-card-section>
      <q-card-section>
        <q-form>
          <div class="row q-col-gutter-md">
            <div class="col-12">
              <q-select
                :model-value="agentManager"
                :options="agentManagers"
                :loading="agentManagersLoading"
                style="min-width: 200px"
                option-value="id"
                option-label="name"
                label="Агентский менеджер"
                outlined
                hide-hint
                hide-bottom-space
                dense
                @update:model-value="onChange"
              />
            </div>
          </div>
        </q-form>
      </q-card-section>
      <q-card-actions
        align="right"
        class="text-primary"
      >
        <q-btn
          v-close-popup
          :loading="loading"
          label="Отмена"
          class="q-pr-md"
          color="white"
          text-color="black"
        />
        <q-btn
          :loading="loading"
          label="Сохранить"
          color="primary"
          @click="handleSubmit()"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
import {computed, onBeforeUnmount, watch} from 'vue'
import {useDialog} from 'src/uses/Dialogs/useDialog'
import {storeToRefs} from 'pinia'
import {ProfilesAgentList} from 'src/models/Profiles/Agents/ProfilesAgentList'
import {useAgentManagerListStore} from 'src/stores/AgentManagers/useAgentManagerListStore'
import {useAttachAgentToAgentManagerStore} from 'src/stores/Profiles/Agents/useAttachAgentToAgentManagerStore'
import {AgentManagerListItem} from 'src/models/AgentManagers/AgentManagerListItem'

const emit = defineEmits<{
  (e: 'change'): void,
}>()

const props = defineProps<{
  agents: ProfilesAgentList[],
  loading: boolean,
}>()

const agents = computed(() => props.agents)

const agentManagersStore = useAgentManagerListStore('attach-manager-modal')()
const {load} = agentManagersStore
const {loading: agentManagersLoading, agentManagers} = storeToRefs(agentManagersStore)

const attachAgentToAgentManagerStore = useAttachAgentToAgentManagerStore()
const {
  agentManager,
  loading,
  success,
} = storeToRefs(attachAgentToAgentManagerStore)

const {
  attach,
} = attachAgentToAgentManagerStore

const {dialog, show, hide} = useDialog()

const open = async () => {
  show()
  if (!agentManagers || agentManagers?.value?.length === 0) {
    load()
  }
}

onBeforeUnmount(() => {
  attachAgentToAgentManagerStore.$reset()
  attachAgentToAgentManagerStore.$dispose()
  agentManagersStore.$dispose()
})

watch(
  success,
  () => {
    if (success.value) {
      hide()
      emit('change')
    }
  },
  {
    deep: true,
  },
)

const handleSubmit = async () => {
  const agentsIds = agents.value.map(agent => agent.id)
  await attach(agentsIds)
}

const onChange = (val: AgentManagerListItem) => {
  agentManager.value = val
}

</script>
