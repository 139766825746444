<template>
  <q-intersection
    transition="scale"
    once
  >
    <q-select
      v-model="selected"
      class="field-with-top-label ApplicationNewAgents q-mb-md"
      :options="items"
      :loading="loading"
      :option-label="optionLabel"
      :option-value="optionValue"
      label="Агент"
      hide-dropdown-icon
      outlined
      hide-bottom-space
      hide-selected
      use-input
      fill-input
      emit-value
      dense
      label-slot
      clearable
      @filter="onFilter"
    >
      <template #prepend>
        <q-icon name="svguse:icons.svg?2#app-search" />
      </template>
      <template #label>
        <div class="text-weight-medium">
          Агент
        </div>
      </template>
      <template #option="scope">
        <q-item
          v-bind="scope.itemProps"
          class="q-py-sm q-px-md text-no-wrap ellipsis"
        >
          <div class="row items-center InnOption">
            <div class="text-grey-9 q-mr-sm">{{ scope.opt.inn }}</div>
            <div class="text-grey-6">{{ scope.opt.title }}</div>
          </div>
        </q-item>
      </template>
    </q-select>
  </q-intersection>
</template>

<script setup lang="ts">
import {onBeforeMount} from 'vue'
import {storeToRefs} from 'pinia'
import {useApplicationsNew} from 'src/stores/Applications/ApplicationsNew/useApplicationsNew'
import {QSelect} from 'quasar'
import {ApplicationNewAgent} from 'src/models/Applications/ApplicationNewAgentsResponse'
import {useRoute} from 'vue-router'
const applicationsNewStore = useApplicationsNew()
const {
  loadAgents: load,
} = applicationsNewStore

const {
  selectedAgent: selected,
  agents: items,
  agentsLoading: loading,
} = storeToRefs(applicationsNewStore)

const route = useRoute()

const optionValue = (opt: ApplicationNewAgent) => {
  return opt
}

const optionLabel = (opt: ApplicationNewAgent) => {
  return opt.title
}

const onFilter = (
  input: string,
  update: (fn: () => void) => void
) => {
  update(async () => {
    await load(input)
  })
}

onBeforeMount(() => {
  let query = ''
  if (route.query?.agent_inn) {
    query = route.query?.agent_inn as string
  }

  load(query)
})
</script>

<style lang="scss">
body {
  .q-field {
    &.ApplicationNewAgents {
      padding-top: 32px !important;
      padding-bottom: 2px;

      .q-field__label {
        top: -32px !important;
        left: -49px !important;
        font-size: 16px !important;
        color: $grey-9;
      }

      input {
        margin-top: 2px;
      }

      input::-webkit-input-placeholder {
        opacity: 1 !important;
        color: $grey-8 !important;
      }
    }
  }
}
</style>
