import {defineStore, storeToRefs} from 'pinia'
import {useFormItemStore} from 'stores/FormEditor/useFormItemStore'
import {useFormItemGetDocumentsStore} from 'stores/FormEditor/useFormItemGetDocumentsStore'
import {useFormItemUpdateDocuments} from 'src/uses/FormEditor/useFormItemUpdateDocuments'
import {useFormItemUpdateClientDocument} from 'src/uses/FormEditor/useFormItemUpdateClientDocument'
import {FormItemDocumentListItem} from 'src/models/FormEditor/FormItemDocumentListItem'

export const useFormItemDocumentsStore = (formId: string) => defineStore(`form-item-documents-${formId}`, () => {
  const formItemGetDocumentsStore = useFormItemGetDocumentsStore(formId)()
  const {
    loadDocs,
  } = formItemGetDocumentsStore
  const {
    documents,
    status: loadDocumentsStatus
  } = storeToRefs(formItemGetDocumentsStore)

  const {
    documents: formDocuments
  } = storeToRefs(useFormItemStore(formId)())

  const {
    updateDocs
  } = useFormItemUpdateDocuments()
  const {
    updateClientDocument
  } = useFormItemUpdateClientDocument()

  const load = async () => {
    await loadDocs()

    if (loadDocumentsStatus.value === 200) {
      initDocs()
    }
  }

  const initDocs = () => {
    documents.value = documents.value.map((doc) => {
      return {
        ...doc,
        selected: documentSelected(doc.id),
        required: documentRequired(doc.id),
        client_document: documentClientDocument(doc.id),
      }
    })
  }

  const documentSelected = (id: string) => {
    return !!(formDocuments.value || []).find(d => d.id === id)
  }

  const documentRequired = (id: string) => {
    return !!(formDocuments.value || []).find(d => d.id === id && d.required === true)
  }

  const documentClientDocument = (id: string) => {
    return !!(formDocuments.value || []).find(d => d.id === id && d.client_document === true)
  }

  const findDocumentIndex = (document: FormItemDocumentListItem) => {
    return documents.value
      .findIndex(d => d.id === document.id && d.group === document.group)
  }

  const updateDocumentSelectedRequired = async (
    selectedMode: boolean,
    document: FormItemDocumentListItem,
    value: boolean
  ) => {
    const documentIndex = findDocumentIndex(document)
    if (documentIndex === -1) {
      return
    }

    if (selectedMode) {
      documents.value[documentIndex].selected = value
      if (!value) {
        documents.value[documentIndex].required = value
        documents.value[documentIndex].client_document = value
      }
    } else {
      documents.value[documentIndex].required = value
      if (value) {
        documents.value[documentIndex].selected = value
      }
    }

    const result = (await updateDocs(formId, documents.value)) || []
    result
      .forEach(document => {
        const documentIndex = findDocumentIndex(document)
        if (documentIndex === -1) {
          return
        }

        documents.value[documentIndex] = {
          ...documents.value[documentIndex],
          ...document,
        }
      })
  }

  const updateDocumentClientDocument = async (id: string, state: boolean) => {
    await updateClientDocument(id, state)
  }

  return {
    documents,
    load,
    loadDocs,
    initDocs,
    updateDocumentSelectedRequired,
    updateDocumentClientDocument,
  }
})
