<template>
  <q-dialog
    :model-value="props.modelValue"
    :position="$q.platform.is.mobile ? 'bottom' : 'top'"
    :full-width="$q.platform.is.mobile"
    @update:model-value="emit('update:model-value', $event)"
  >
    <q-card
      style="width: 700px;"
    >
      <q-card-section class="row items-center q-pb-sm q-pt-sm">
        <div class="news-topic">{{ NewsDescriptions.getNewsTopicDescription(newsItem.topic) }}</div>
        <q-space />
        <q-btn
          v-close-popup
          icon="svguse:icons.svg?2#app-x"
          flat
          round
          dense
          color="blue-grey-3"
        />
      </q-card-section>
      <q-separator />
      <q-card-section>
        <q-img
          v-if="newsItem.picture"
          class="rounded-borders"
          :src="newsItem.picture as string"
        />
        <div
          class="text-h6 q-mt-md q-mb-md"
          style="font-weight: bold"
        >
          {{ newsItem.title }}
        </div>
        <div v-html="newsItem.body" />
        <div class="news-publication-date q-mt-sm">{{ newsItem.publication_date }}</div>
      </q-card-section>
      <q-card-section class="q-pa-none q-ma-none">
        <div
          v-if="documents && documents.length > 0"
          class="news-documents-list bg-grey-2 q-pa-md"
        >
          <span>Прикрепленные документы:</span>
          <q-list class="q-mt-sm">
            <q-item
              v-for="document in documents"
              :key="document.id"
              class="news-document-item q-mt-xs"
            >
              <q-item-section avatar>
                <q-icon
                  name="svguse:icons.svg?2#app-article-bold"
                  color="blue-grey-3"
                />
              </q-item-section>
              <q-item-section class="ellipsis">{{ document.title }}</q-item-section>
              <q-item-section side>
                <q-icon
                  class="document-download-icon"
                  name="svguse:icons.svg?2#app-download"
                  color="blue-gray-3"
                  @click="downloadByUrl(document.file as string, document.name)"
                />
              </q-item-section>
            </q-item>
          </q-list>
        </div>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
import {computed, watchEffect} from 'vue'
import {useNewsItem} from 'src/stores/News/useNewsItemStore'
import {NewsDescriptions} from 'src/models/News/NewsDescriptions'
import {NewsItem, NewsItemDocuments} from 'src/models/News/NewsItem'
import {withDefaults} from 'vue/dist/vue'
import {NewsItemPreview} from 'src/models/News/NewsItemPreview'
import {useQuasar} from 'quasar'

const props = withDefaults(
  defineProps<{
    item: NewsItemPreview,
    modelValue: boolean,
    preview?: boolean,
    documents: NewsItemDocuments[] | undefined
  }>(),
  {
    preview: false,
    documents: undefined
  }
)

const emit = defineEmits<{
  (e: 'update:model-value', value: boolean): void
}>()

const item = computed(() => props.item)
const documents = computed(() => props.preview ? props.documents : item.value.documents)
const {
  newsItem,
  downloadByUrl,
  load,
} = useNewsItem()

const $q = useQuasar()

watchEffect(async () => {
  if (!props.modelValue || !item.value) {
    return
  }

  if (props.preview) {
    newsItem.value = props.item as NewsItem

    return
  }

  if (!item.value.id) {
    return
  }

  await load(item.value.id)
})
</script>

<style lang="scss">
.news-document-item {
  border: 1px solid #CFD8DC;
  border-radius: 5px;
  .document-download-icon {
    padding: 3px;
    &:hover {
      cursor: pointer;
      background: #CFD8DC;
      border-radius: 5px;
      padding: 3px;
    }
  }
}

.news-modal-wrap {

  height: 100%;
  & > div {
    overflow: auto;
    height: 100%;
  }
}

.news-title-big, .news-title-small{
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.news-title-big {
  font-weight: 550;
  font-size: 20px;
  line-height: 24px;
}

.news-title-small {
  font-weight: 550;
  font-size: 16px;
  line-height: 20px;
}

.news-topic, .news-publication-date {
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #757575;
}
</style>
