<template>
  <span>
    {{ moneyString }}
  </span>
</template>

<script setup lang="ts">
import {computed} from 'vue'

const props = defineProps<{
  modelValue: string | number | undefined | null
}>()

const moneyString = computed(() => {
  const value = !props.modelValue
    ? 0
    : parseFloat(props.modelValue.toString())

  return new Intl.NumberFormat(
    'ru',
    {
      style: 'currency',
      currency: 'RUB'
    }
  ).format(value)
})
</script>
