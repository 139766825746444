import {defineStore, storeToRefs} from 'pinia'
import {useGetApi} from 'src/uses/Api/useGetApi'
import {ref} from 'vue'
import {ApplicationHistory} from 'src/models/Applications/ControlCenter/ApplicationHistory'
import {useProfileStore} from 'src/stores/Login/useProfileStore'
import {UserRoleEnum} from 'src/models/User/UserRoleEnum'

export const useControlCenterHistoryStore = (id: string) => defineStore('control-center-history', () => {
  const profileStore = useProfileStore()
  const {profile} = storeToRefs(profileStore)

  const AGENT_ORDERS_CONTROL_CENTER_URL = 'v1/control-center'
  const ADMIN_ORDERS_CONTROL_CENTER_URL = 'v1/admin/control-center'

  const {
    loading,
    get,
    errors,
    error,
    status,
    response,
  } = useGetApi<ApplicationHistory[]>()

  const history = ref<ApplicationHistory[]>()

  const loadHistory = async () => {
    if (!profile.value) {
      return
    }

    const url = [
      UserRoleEnum.Admin,
      UserRoleEnum.JuniorAdmin,
      UserRoleEnum.MiddleAdmin,
    ].includes(profile.value.role)
      ? ADMIN_ORDERS_CONTROL_CENTER_URL
      : AGENT_ORDERS_CONTROL_CENTER_URL
    await get(`${url}/${id}/history`)

    if (status.value === 200 && response.value) {
      history.value = response.value
    }
  }

  return {
    loading,
    get,
    errors,
    error,
    history,
    loadHistory,
  }
})
