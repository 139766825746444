import {computed, Ref, ref, watch} from 'vue'
import {
  email,
  maxLength,
  minLength,
  nameUpperLowerCaseValidator,
  passwordValidator,
  phoneValidator,
  required,
} from 'src/utils/i18n-validators'
import useVuelidate from '@vuelidate/core'
import {usePostApi} from 'src/uses/Api/usePostApi'
import {UserItem} from 'src/models/Profiles/Users/UserItem'
import {UserRoleEnum} from 'src/models/User/UserRoleEnum'
import {UserRoles} from 'src/models/User/UserRoleDescriptions'
import {usePutApi} from 'src/uses/Api/usePutApi'
import {OpfEnum} from 'src/models/OpfEnum'
import {Opf} from 'src/models/OpfDescriptions'
import {useValidationRules} from 'src/uses/useValidationRules'
import {useUserItem} from 'src/uses/Profile/Users/useUserItem'

export const useUserCreateUpdate = () => {
  const {
    load,
    getUserRequestData
  } = useUserItem()

  const {
    post,
    loading: postLoading,
    status: postStatus,
    errors: postErrors,
  } = usePostApi<UserItem, UserItem>()

  const {
    put,
    loading: putLoading,
    status: putStatus,
    errors: putErrors,
  } = usePutApi<UserItem, UserItem>()

  const {
    getRulesFromState,
  } = useValidationRules()

  const blockedRoles = computed(() => {
    return [
      UserRoleEnum.User,
      UserRoleEnum.Agent,
      UserRoleEnum.HeadEmployee,
      UserRoleEnum.Employee
    ]
  })

  const isBlockedRole = computed(() => blockedRoles.value.includes(userCreateData.value.role as UserRoleEnum))

  const rolesForSelect = ref(
    Object.values(UserRoleEnum).map(role => {
      return {
        label: UserRoles.getUserRoleDescription(role),
        value: role
      }
    }).filter(item => {
      return !blockedRoles.value
        .includes(item.value)
    })
  )

  const blockedRolesForSelect = ref(
    Object.values(UserRoleEnum).map(role => {
      return {
        label: UserRoles.getUserRoleDescription(role),
        value: role
      }
    }).filter(item => {
      return blockedRoles.value
        .includes(item.value)
    })
  )

  const opfForSelect = ref(
    Object.values(OpfEnum).map(opf => {
      return {
        label: Opf.getOpfDescription(opf),
        value: opf
      }
    })
  )

  const userCreateData = ref<UserItem>({
    id: '',
    first_name: '',
    last_name: '',
    middle_name: '',
    email: '',
    phone: '',
    date: '',
    role: '',
    status: '',
    activated: '',
    password: '',
    password_confirm: '',
    user_is_active: false,
    bank_employee: {
      banks: [],
      is_hide_personal_data: true,
    }
  })

  const createRole = computed(() => userCreateData.value.role)

  const itemId = ref<number>()

  const computedCreateData = computed(() => {
    return {
      ...userCreateData.value,
      ...(
        userCreateData.value.operator
          ? {
              is_hide_personal_data: userCreateData.value.operator.is_hide_personal_data,
              head_operator_id: userCreateData.value.operator.head_id,
            }
          : {}
      ),
      ...(
        userCreateData.value.bank_employee
          ? {
              banks: userCreateData.value.bank_employee.banks
                .map(bank => bank.id),
              is_hide_personal_data: userCreateData.value.bank_employee.is_hide_personal_data
            }
          : {}
      ),
      is_default: userCreateData.value.agent_manager ? userCreateData.value.agent_manager.is_default : false
    }
  })

  const rules = computed(() => {
    return {
      ...getRulesFromState(userCreateData.value),
      first_name: {
        required,
        nameUpperLowerCaseValidator,
      },
      last_name: {
        required,
        nameUpperLowerCaseValidator,
      },
      email: {
        required,
        email,
      },
      phone: {
        required,
        phoneValidator,
      },
      password: !itemId.value
        ? {
            required,
            passwordValidator,
            minLength: minLength(8),
            maxLength: maxLength(14),
          }
        : {},
      password_confirm: !itemId.value
        ? {
            required,
            passwordValidator,
            minLength: minLength(8),
            maxLength: maxLength(14),
          }
        : {},
      role: {
        required,
      },
    }
  })

  const validation = useVuelidate<Ref<UserItem>>(rules, userCreateData)

  const status = computed(() => {
    return itemId.value
      ? putStatus.value
      : postStatus.value
  })

  const errors = computed({
    get: () => {
      return itemId.value
        ? putErrors.value
        : postErrors.value
    },
    set: (errors: Record<string, string> | undefined) => {
      if (itemId.value) {
        putErrors.value = errors
        return
      }
      postErrors.value = errors
    }
  })

  const loading = computed(() => {
    return itemId.value
      ? putLoading.value
      : postLoading.value
  })

  const create = async () => {
    if (!await validation.value.$validate()) {
      return
    }

    await post('admin/user', computedCreateData.value)
  }

  const update = async () => {
    if (!itemId.value || !await validation.value.$validate()) {
      return
    }

    await put(`admin/user/${itemId.value}`, computedCreateData.value)
  }

  const loadUserItem = async (id: number) => {
    await load(id)
    userCreateData.value = {
      password: '',
      password_confirm: '',
      ...getUserRequestData()
    }
  }

  watch(
    createRole,
    () => {
      if (userCreateData.value.role === UserRoleEnum.BankEmployee && !userCreateData.value.bank_employee) {
        userCreateData.value.bank_employee = {
          banks: [],
          is_hide_personal_data: false,
        }
      }

      if (userCreateData.value.role === UserRoleEnum.Operator && !userCreateData.value.operator) {
        userCreateData.value.operator = {
          head_id: '',
          head_name: '',
          is_hide_personal_data: false,
        }
      }

      if (userCreateData.value.role === UserRoleEnum.AgentManager && !userCreateData.value.agent_manager) {
        userCreateData.value.agent_manager = {
          is_default: false,
        }
      }
    }
  )

  return {
    userCreateData,
    validation,
    status,
    putStatus,
    postStatus,
    putErrors,
    postErrors,
    errors,
    loading,
    rolesForSelect,
    blockedRolesForSelect,
    isBlockedRole,
    opfForSelect,
    itemId,
    rules,
    blockedRoles,
    create,
    update,
    loadUserItem,
  }
}
