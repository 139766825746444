import {useGetApi} from 'src/uses/Api/useGetApi'
import {computed, ref} from 'vue'
import {
  ProductRequiredCondition,
  ProductRequiredConditionComparisonOperatorEnum,
  ProductRequiredConditionsParamEnum, ProductRequiredConditionsPart,
  ProductRequiredControl
} from 'src/models/Products/ProductRequiredControl'
import {usePutApi} from 'src/uses/Api/usePutApi'
import {LawTypeEnum} from 'src/models/Applications/LawTypeEnum'

const operators: Record<ProductRequiredConditionComparisonOperatorEnum, string> = {
  [ProductRequiredConditionComparisonOperatorEnum.EQ]: '=',
  [ProductRequiredConditionComparisonOperatorEnum.GT]: '>',
  [ProductRequiredConditionComparisonOperatorEnum.LT]: '<',
}

export const useProductsRequiredControl = () => {
  const {
    loading,
    get,
    errors,
    error,
    status,
    response,
  } = useGetApi<ProductRequiredControl>()

  const {
    loading: putLoading,
    put,
    status: putStatus,
  } = usePutApi<ProductRequiredControl, ProductRequiredControl>()

  const requiredControlData = ref<ProductRequiredControl>({
    required: false
  })

  const operatorOptions = ref(
    Object.values(ProductRequiredConditionComparisonOperatorEnum).map(key => {
      return {
        label: operators[key],
        value: key
      }
    })
  )

  const conditionsCount = computed(() => {
    if (!requiredControlData.value || !requiredControlData.value.conditions?.parts) {
      return 0
    }

    return requiredControlData.value.conditions?.parts.length
  })

  const loadRequiredControl = async (id: string) => {
    await get(`v1/admin/product/${id}/required-control`)

    if (status.value === 200 && response && response.value) {
      requiredControlData.value = response.value
    }
  }

  const paramComparisonOptions = computed(() => {
    if (!requiredControlData.value || !requiredControlData.value.conditions_params) {
      return {}
    }

    return Object.fromEntries(
      requiredControlData.value.conditions_params
        .map(param => {
          return [
            param.param,
            param.relations.length === 1
          ]
        })
    )
  })

  const paramValueTypesAndOptions = computed(() => {
    if (!requiredControlData.value || !requiredControlData.value.conditions_params) {
      return {}
    }

    return Object.fromEntries(
      requiredControlData.value.conditions_params
        .map(param => {
          const options = []
          for (const value in param.options) {
            options.push({
              label: param.options[value],
              value,
            })
          }
          return [
            param.param,
            {
              type: param.type,
              multiple: param.multiple,
              options
            }
          ]
        })
    )
  })

  const isValid = computed(() => {
    if (!requiredControlData.value) {
      return false
    }

    if (!requiredControlData.value.conditions) {
      return true
    }

    let isValidParams = true

    requiredControlData.value.conditions.parts.forEach((condition: ProductRequiredCondition) => {
      condition.parts.forEach((param: ProductRequiredConditionsPart) => {
        if (!param.value || !param.operator) {
          isValidParams = false
        }
      })
    })

    return isValidParams
  })

  const save = async (id: string) => {
    await put(`v1/admin/product/${id}/required-control`, requiredControlData.value)
  }

  const addCondition = () => {
    if (!requiredControlData.value) {
      return
    }

    if (!requiredControlData.value.conditions) {
      requiredControlData.value.conditions = {
        operator: 'or',
        parts: []
      }
    }

    requiredControlData.value.conditions.parts.push({
      operator: 'and',
      parts: [{
        operator: ProductRequiredConditionComparisonOperatorEnum.EQ,
        param: ProductRequiredConditionsParamEnum.OBLIGATION_TYPE,
        value: [LawTypeEnum.FZ44]
      }]
    })
  }

  const removeCondition = (key: number) => {
    if (!requiredControlData.value || !requiredControlData.value.conditions) {
      return
    }

    requiredControlData.value.conditions.parts.splice(key, 1)
  }

  const addParam = (key: number) => {
    if (!requiredControlData.value || !requiredControlData.value.conditions) {
      return
    }
    if (!requiredControlData.value.conditions.parts[key]) {
      return
    }

    requiredControlData.value.conditions.parts.map((item, conditionKey) => {
      if (key === conditionKey) {
        item.parts.push({
          operator: ProductRequiredConditionComparisonOperatorEnum.EQ,
          param: ProductRequiredConditionsParamEnum.OBLIGATION_TYPE,
          value: [LawTypeEnum.FZ44]
        })
      }
      return item
    })
  }

  const removeParam = (conditionKey: number, paramKey: number) => {
    if (!requiredControlData.value || !requiredControlData.value.conditions) {
      return
    }

    requiredControlData.value.conditions.parts.map((item, key) => {
      if (conditionKey === key) {
        item.parts.splice(paramKey, 1)
      }
      return item
    })
  }

  const onChangeParam = (conditionKey: number, paramKey: number) => {
    if (!requiredControlData.value || !requiredControlData.value.conditions) {
      return
    }

    requiredControlData.value.conditions.parts.map((item, key) => {
      if (conditionKey === key) {
        item.parts.map((param, searchParamKey) => {
          if (searchParamKey === paramKey) {
            param.value = null
          }
          return param
        })
      }
      return item
    })
  }

  return {
    loading,
    putLoading,
    putStatus,
    status,
    get,
    errors,
    error,
    requiredControlData,
    loadRequiredControl,
    save,

    addCondition,
    addParam,
    removeCondition,
    removeParam,
    onChangeParam,
    isValid,
    paramComparisonOptions,
    paramValueTypesAndOptions,
    conditionsCount,
    operatorOptions,
  }
}
