<template>
  <q-tab
    :name="props.name"
    :class="classes()"
  >
    {{ props.label }}
    <q-icon
      v-if="isOk()"
      name="svguse:icons.svg?2#app-check"
      size="sm"
    />
  </q-tab>
</template>

<script setup lang="ts">
import {ApplicationItemSectionsEnum} from 'src/models/Enums/ApplicationItemSectionsEnum'
import {FillStatusEnum} from 'src/models/Enums/FillStatusEnum'

const props = defineProps<{
  name: ApplicationItemSectionsEnum,
  label: string,
  multiOrderId: number,
  status: FillStatusEnum,
}>()

const classes = () => {
  return {
    [`ApplicationItemTab--${props.status}`]: true,
    ApplicationItemTab: true,
  }
}

const isOk = () => {
  return props.status === FillStatusEnum.OK
}

</script>

<style lang="scss">

.ApplicationItemTab {
  border-radius: 6px 6px 0 0;
  margin-right: 1px;
  height: 60px;
  padding: 0 25px;
  opacity: 1;

  .ApplicationItemTab--count {
    color: $blue-grey-3;
  }

  .q-tab__indicator {
    display: none;
  }

  font-weight: 700;

  &.ApplicationItemTab--in_progress {
    background: $grey-2;
    color: $grey-9;

    &.q-tab--active {
      background: $blue-1;
    }
  }

  &.ApplicationItemTab--error {
    background: $grey-2;
    color: $orange;

    &.q-tab--active {
      background: $blue-1;
    }
  }

  &.ApplicationItemTab--ok {
    background: $grey-2;
    color: $green-14;

    &.q-tab--active {
      background: $green-1;
    }
  }
}
</style>
