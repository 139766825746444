import {FieldUpdate} from 'src/models/Field/FieldUpdate'
import {
  ApplicationFormFinanceField,
  ApplicationFormBank,
  ApplicationFormField
} from 'src/models/Applications/Form/ApplicationFormResponseData'
import {ProductFields} from 'src/models/Applications/Form/ProductFields'

export const useUpdateSectionFields = () => {
  const updateValues = (
    update: FieldUpdate[] | ApplicationFormFinanceField[],
    fields: ApplicationFormField[] | ApplicationFormFinanceField[],
  ) => {
    setLoading(update, fields, true)
    fields
      .forEach(f => {
        f.highlighted = false
        const fieldUpdate = update.find(uf => uf.code === f.code)
        if (!fieldUpdate) {
          return
        }

        f.value = fieldUpdate.value
      })
  }

  const setLoading = (
    update: FieldUpdate[] | ApplicationFormFinanceField[],
    fields: ApplicationFormField[] | ApplicationFormFinanceField[],
    loading: boolean
  ) => {
    fields
      .forEach(f => {
        const fieldUpdate = update.find(uf => uf.code === f.code)
        if (!fieldUpdate) {
          return
        }

        f.highlighted = false
        f.loading = loading
      })
  }

  const updateErrorProcess = (
    update: FieldUpdate[] | ApplicationFormFinanceField[],
    fields: ApplicationFormField[] | ApplicationFormFinanceField[],
    errors: Record<string, string>,
  ) => {
    const errorsArray = Object.entries(errors)

    fields
      .forEach(f => {
        const error = errorsArray.find(e => e[0] === f.code)
        if (!error) {
          return
        }

        f.error = error[1]
      })

    setLoading(update, fields, false)
  }

  const updateSuccessProcess = (
    update: FieldUpdate[] | ApplicationFormFinanceField[],
    fields: ApplicationFormField[] | ApplicationFormFinanceField[],
  ) => {
    const updateCodes = update.map(val => val.code)

    fields
      .forEach(f => {
        if (!updateCodes.includes(f.code)) {
          return
        }

        f.error = undefined
      })
    setLoading(update, fields, false)
  }

  const filterVisible = (
    fields: ApplicationFormField[],
    banks: ApplicationFormBank[]
  ): ProductFields => {
    const visible = !fields
      ? []
      : fields
        .filter(f => !f.invisible)

    const byBanks = banks
      .map(bank => ({
        ...bank,
        fields: visible.filter(f => f.location.bank_id === bank.bank_id)
      }))
      .filter(bank => bank.fields.length > 0)

    return {
      all: visible.filter(f => !f.location.bank_id),
      banks: byBanks,
    }
  }

  const getParentFieldsUpdateFromFields = (update: FieldUpdate[], fields: ApplicationFormField[]): FieldUpdate[] => {
    const updateCodes = update.map(f => f.code)
    const parentFieldsCodes = fields
      .filter(f => updateCodes.includes(f.code) && f.parent_field_code)
      .map(f => f.parent_field_code)

    const relatedFieldsWithErrorCodes = fields
      .filter(field => field.parent_field_code
        && updateCodes.includes(field.parent_field_code)
        && field.error
      )
      .map(f => f.code)

    return fields
      .filter(f => parentFieldsCodes.includes(f.code)
        || relatedFieldsWithErrorCodes.includes(f.code)
      )
      .map(f => ({
        code: f.code,
        value: f.value,
      }))
  }

  const getParentFieldsUpdate = (update: FieldUpdate[], product: ProductFields) => {
    const fieldsToAdd: FieldUpdate[] = []
    fieldsToAdd.push(...getParentFieldsUpdateFromFields(update, product.all))
    product.banks
      .forEach(b => {
        fieldsToAdd.push(...getParentFieldsUpdateFromFields(update, b.fields))
      })

    return fieldsToAdd
  }

  return {
    setLoading,
    updateValues,
    updateErrorProcess,
    updateSuccessProcess,
    filterVisible,
    getParentFieldsUpdate,
  }
}
