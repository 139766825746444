import {defineStore} from 'pinia'
import {ref} from 'vue'
import {usePutApi} from 'src/uses/Api/usePutApi'
import {Subscription, SubscriptionUpdateItem} from 'src/models/Profile/Notifications/Subscription'

export const useProfileNotificationsUpdateStore = defineStore('profile-profile-notifications', () => {
  const {
    put,
    response,
    status,
    loading,
    error,
    errors,
  } = usePutApi<never, {subscriptions: SubscriptionUpdateItem[]}>()

  const success = ref(false)

  const save = async (notifications: Subscription[]) => {
    success.value = false

    const notificationsUpdate = notifications.map((item) => {
      return {
        code: item.code,
        channels: item.channels
      }
    })

    await put('v2/profile/subscriptions', {subscriptions: notificationsUpdate})
    if (status.value === 200 && response.value) {
      success.value = true
    }
  }

  return {
    loading,
    response,
    status,
    error,
    errors,
    success,
    save,
  }
})
