import {useRequestApi} from './useRequestApi'
import {PutReturnInterface} from 'src/models/Api/PutReturnInterface'

export const usePutApi: <T = never, R = never>(showError?: boolean) => PutReturnInterface<R, T> = <T, R>(showErrors = false) => {
  const {
    response,
    error,
    errors,
    status,
    loading,
    request,
    headers,
    abort,
    setHeader,
    progressEvent,
  } = useRequestApi<T>(showErrors)

  const put = async (url: string, data?: R) => {
    await request<R>(url, 'PUT', data)
  }

  return {
    response,
    error,
    errors,
    status,
    loading,
    headers,
    abort,
    setHeader,
    progressEvent,
    put,
  }
}
