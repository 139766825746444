import {useProfileStore} from 'stores/Login/useProfileStore'
import {useStatusKikStore} from 'stores/Status/useStatusKikStore'
import {useKikChangeStatusAction} from 'src/uses/Applications/useKikChangeStatusAction'
import {ApplicationsGridKikItem} from 'src/models/Applications/ApplicationsGridKikItem'

export const useGridKikChangeStatusAction = () => {
  const statusStore = useStatusKikStore()
  const {changeStatus} = statusStore

  const changeStatusUse = useKikChangeStatusAction()

  const profileStore = useProfileStore()
  const {isAgentOrEmployees} = profileStore

  const isDraft = (item: ApplicationsGridKikItem) => {
    return changeStatusUse.isDraft(item.sub_status)
  }

  const isBankRejected = (item: ApplicationsGridKikItem) => {
    return changeStatusUse.isBankRejected(item.sub_status)
  }

  const isSystemRejected = (item: ApplicationsGridKikItem) => {
    return changeStatusUse.isSystemRejected(item.sub_status)
  }

  const isArchive = (item: ApplicationsGridKikItem) => {
    return changeStatusUse.isArchive(item.sub_status)
  }

  const isClientRejected = (item: ApplicationsGridKikItem) => {
    return changeStatusUse.isClientRejected(item.sub_status)
  }

  const showClientReject = (item: ApplicationsGridKikItem) => {
    if (item.required && isAgentOrEmployees()) {
      return false
    }

    return changeStatusUse.showClientReject(item.sub_status)
  }

  const showSendToCheck = (item: ApplicationsGridKikItem) => {
    return changeStatusUse.showSendToCheck(item.sub_status, item.filled)
  }

  const showGridStatusChange = (item: ApplicationsGridKikItem) => {
    return changeStatusUse.showStatusChange(
      item.sub_status,
      item.filled,
    )
  }

  return {
    isDraft,
    isBankRejected,
    isSystemRejected,
    isArchive,
    isClientRejected,
    changeStatus,
    showClientReject,
    showSendToCheck,
    showGridStatusChange,
  }
}
