import {AxiosResponseHeaders} from 'axios'
import {Capacitor} from '@capacitor/core'
import {useNativeFile} from 'src/uses/useNativeFile'

export const useFile = () => {
  const {inMemoryShareFile} = useNativeFile()
  const getFilenameFromHttpResponseBlob = (
    response: Blob | undefined,
    headers: AxiosResponseHeaders | undefined,
    useUtf = false
  ) => {
    if (!(headers && response)) {
      return ''
    }
    const headerContent = headers['content-disposition']
    if (!headerContent) {
      return ''
    }

    if (useUtf) {
      const filename = headerContent
        .split('; ')[2]
        .replace('filename*=utf-8\'\'', '')
        .replace(/"/g, '') || ''

      return decodeURI(filename)
    }

    return headerContent
      .split(';')[1]
      .replace('filename=', '')
      .replace(/ /g, '')
      .replace(/"/g, '') || ''
  }

  const downloadFile = async (file: Blob | undefined, fileName: string) => {
    if (!file) {
      return
    }

    //TODO const $q = useQuasar() - research why $q is undefined when downloadFile from application form
    if (Capacitor.isNativePlatform()) {
      await inMemoryShareFile(file, fileName)
      return
    }

    const url = window.URL.createObjectURL(file)
    downloadByUrl(url, fileName)
  }

  const downloadByUrl = (url: string, fileName: string) => {
    const link = document.createElement('a')
    link.href = url
    link.download = fileName
    link.target = '_blank'
    link.click()
    window.URL.revokeObjectURL(url)
  }

  const base64ToSigFile = (base64: string, filename: string): File => {
    const contentType = 'application/pkcs7-signature'
    const byteNumbers = new Array(base64.length)

    for (let i = 0; i < base64.length; i++) {
      byteNumbers[i] = base64.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)
    const blob = new Blob([byteArray], { type: contentType })

    return new File([blob], filename, {type: contentType, lastModified: Date.now()})
  }

  return {
    downloadByUrl,
    getFilenameFromHttpResponseBlob,
    downloadFile,
    base64ToSigFile,
  }
}
