<template>
  <template v-if="isOperator() || isAgentManager()">
    <q-item
      v-if="props.list"
      v-ripple
      :disable="loading"
      class="text-no-wrap"
      clickable
      @click="onClick"
    >
      <q-item-section>
        <q-item-label>
          <q-icon
            :color="props.item.favorite ? 'red-12' : 'blue-grey-4'"
            size="24px"
            name="svguse:icons.svg?2#app-heart"
          />
          Избранное
        </q-item-label>
      </q-item-section>
    </q-item>
    <q-btn
      v-else
      :class="classes"
      :disable="loading"
      icon="svguse:icons.svg?2#app-heart"
      padding="sm"
      flat
      unelevated
      square
      @click="onClick"
    />
  </template>
</template>

<script setup lang="ts">
import {computed, withDefaults} from 'vue'
import {ApplicationsGridItem} from 'src/models/Applications/ApplicationsGridItem'
import {useApplicationFavorite} from 'src/uses/Applications/useApplicationFavorite'
import {useProfileStore} from 'src/stores/Login/useProfileStore'

const props = withDefaults(
  defineProps<{
    item: ApplicationsGridItem,
    list?: boolean,
  }>(),
  {
    list: false,
  }
)

const emit = defineEmits<{
  (e: 'updated'): void
}>()

const {
  favorite,
  loading,
  status,
} = useApplicationFavorite()

const {
  isOperator,
  isAgentManager,
} = useProfileStore()

const classes = computed(() => {
  return {
    'text-blue-grey-4': !props.item.favorite,
    'text-red-12': props.item.favorite,
  }
})

const onClick = async () => {
  await favorite(props.item.id, !props.item.favorite)
  if (status.value !== 200) {
    return
  }

  emit('updated')
}
</script>
