import {defineStore} from 'pinia'
import {useGetApi} from 'src/uses/Api/useGetApi'
import {ref} from 'vue'
import {SuccessorsListResponseData} from 'src/models/Profile/Employees/SuccessorsListResponseData'
import {SuccessorItem} from 'src/models/Profile/Employees/SuccessorItem'

export const useSuccessorsListStore = (hash: string) => defineStore(`successors-list-${hash}`, () => {
  const {
    loading,
    get,
    status,
    response,
  } = useGetApi<SuccessorsListResponseData>()

  const successors = ref<SuccessorItem[]>([])

  const load = async (id: string) => {
    await get(
      `v1/employees/${id}/successors`,
    )

    if (status.value === 200 && response && response.value) {
      successors.value = response.value?.items.map(successor => {
        return {
          id: successor.id,
          name: `${successor.name_first} ${successor.name_last}`
        }
      })
    }
  }

  return {
    loading,
    successors,
    load,
  }
})
