import {defineStore, storeToRefs} from 'pinia'
import {useGetApi} from 'src/uses/Api/useGetApi'
import {computed, ref, watch} from 'vue'
import {
  ApplicationFormResponseData
} from 'src/models/Applications/Form/ApplicationFormResponseData'
import {useOptions} from 'src/uses/Applications/Form/useOptions'
import {useAddFounder} from 'src/uses/Applications/Form/Client/useAddFounder'
import {useDeleteFounder} from 'src/uses/Applications/Form/Client/useDeleteFounder'
import {ApplicationFormUpdateResponse} from 'src/models/Applications/Form/ApplicationFormUpdateResponse'
import moment from 'moment'
import {useLoadingStore} from 'src/stores/useLoadingStore'
import {useApplicationItemKikStore} from 'stores/Applications/Item/useApplicationItemKikStore'
import {useApplicationItemEbgStore} from 'stores/Applications/Item/useApplicationItemEbgStore'
import {ProductEnum} from 'src/models/Applications/ProductEnum'

export const useApplicationItemStore = (multiOrderId: number) => defineStore(`application-item-${multiOrderId}`, () => {
  const kikStore = useApplicationItemKikStore(multiOrderId)()
  const {
    updateMainFieldsResponse: kikUpdateMainFieldsResponse,
    updateClientCommonFieldsResponse: kikUpdateClientCommonFieldsResponse,
    updateClientHeadFieldsResponse: kikUpdateClientHeadFieldsResponse,
    updateClientFounderFieldsResponse: kikUpdateClientFounderFieldsResponse,
    updateFinanceFieldsResponse: kikUpdateFinanceFieldsResponse,
    uploadDocumentResponse: kikUploadDocumentResponse,
    updateAddressDetailsResponse: kikUpdateAddressDetailsResponse,
  } = storeToRefs(kikStore)

  const ebgStore = useApplicationItemEbgStore(multiOrderId)()
  const {
    updateMainFieldsResponse: ebgUpdateMainFieldsResponse,
    updateClientCommonFieldsResponse: ebgUpdateClientCommonFieldsResponse,
    updateClientHeadFieldsResponse: ebgUpdateClientHeadFieldsResponse,
    updateClientFounderFieldsResponse: ebgUpdateClientFounderFieldsResponse,
    updateFinanceFieldsResponse: ebgUpdateFinanceFieldsResponse,
    uploadDocumentResponse: ebgUploadDocumentResponse,
    updateAddressDetailsResponse: ebgUpdateAddressDetailsResponse,
  } = storeToRefs(ebgStore)

  const loadingStore = useLoadingStore()

  const addFounderUse = useAddFounder()

  const productTab = ref<ProductEnum>()

  const {
    options,
  } = useOptions()

  const ITEM_URL = 'v2/orders/forms/'

  const addFounder = async (multiOrderId: number) => {
    const newFounder = await addFounderUse.addFounder(multiOrderId)
    if (!newFounder) {
      return
    }

    if (hasEbg.value && ebgStore.client) {
      if (!ebgStore.client.founders) {
        ebgStore.client.founders = []
      }

      ebgStore.client.founders.push(newFounder)
      ebgStore.openedFounderId = newFounder.id
    }

    if (hasKik.value && kikStore.client) {
      if (!kikStore.client.founders) {
        kikStore.client.founders = []
      }

      kikStore.client.founders.push(newFounder)
      kikStore.openedFounderId = newFounder.id
    }
  }

  const deleteFounder = async (founderId: string) => {
    const deleteFounderUse = useDeleteFounder()

    await deleteFounderUse.deleteFounder(founderId, multiOrderId)

    if (deleteFounderUse.status.value !== 200 || !deleteFounderUse.response.value) {
      return
    }

    const deleteFounderResponse: ApplicationFormUpdateResponse | undefined = deleteFounderUse.response.value as never
    if (hasEbg.value && ebgStore.client) {
      ebgStore.client.founders = ebgStore.client.founders
        .filter(f => f.id !== founderId)
    }
    if (hasKik.value && kikStore.client) {
      kikStore.client.founders = kikStore.client.founders
        .filter(f => f.id !== founderId)
    }
    startUpdateChangesProcess(deleteFounderResponse)
  }

  const {
    loading,
    get,
    errors,
    error,
    status,
    response,
  } = useGetApi<ApplicationFormResponseData>()

  const item = ref<ApplicationFormResponseData>()

  const hasKik = computed(() => !!item.value && !!item.value.products.KIK)
  const hasEbg = computed(() => !!item.value && !!item.value.products.EBG)

  const productTabs = computed(() => {
    if (!item.value) {
      return []
    }

    return [
      ...(item.value.products.EBG ? [ProductEnum.EBG] : []),
      ...(item.value.products.KIK ? [ProductEnum.KIK] : []),
    ]
  })

  const load = async () => {
    loadingStore.showLoading('Пожалуйста подождите, Анкета загружается.\nЭто займет несколько секунд...')
    await get(ITEM_URL + multiOrderId)
    loadingStore.hideLoading()

    if (status.value === 200 && response && response.value) {
      item.value = response.value
      options.value = response.value?.options || null
      if (response.value.products.EBG) {
        ebgStore.main = response.value.products.EBG?.order || null
        ebgStore.client = response.value.products.EBG?.client || null
        ebgStore.banks = (response.value.products.EBG?.banks || [])
          .map(bank => ({
            ...bank,
            product: ProductEnum.EBG,
          }))
        ebgStore.documents = response.value.products.EBG?.documents || {}
        ebgStore.finance = response.value.products.EBG?.finance
      }
      if (response.value.products.KIK) {
        kikStore.main = response.value.products.KIK?.order || null
        kikStore.client = response.value.products.KIK?.client || null
        kikStore.banks = (response.value.products.KIK?.banks || [])
          .map(bank => ({
            ...bank,
            product: ProductEnum.KIK,
          }))
        kikStore.documents = response.value.products.KIK?.documents || {}
        kikStore.finance = response.value.products.KIK?.finance
      }
      /*if (main.value) {
        main.value.main.fields.forEach(f => highlightField(f))
      }*/
    }
  }

  const startUpdateChangesProcess = (response: ApplicationFormUpdateResponse | undefined) => {
    if (hasEbg.value) {
      ebgStore.startUpdateChangesProcess(response)
    }
    if (hasKik.value) {
      kikStore.startUpdateChangesProcess(response)
    }
    if (item.value) {
      item.value.last_update = moment().format('YYYY-MM-DD HH:mm:ss')
    }
  }

  watch(ebgUpdateMainFieldsResponse, startUpdateChangesProcess)
  watch(ebgUpdateClientCommonFieldsResponse, startUpdateChangesProcess)
  watch(ebgUpdateClientHeadFieldsResponse, startUpdateChangesProcess)
  watch(ebgUpdateClientFounderFieldsResponse, startUpdateChangesProcess)
  watch(ebgUpdateFinanceFieldsResponse, startUpdateChangesProcess)
  watch(ebgUploadDocumentResponse, startUpdateChangesProcess)
  watch(ebgUpdateAddressDetailsResponse, startUpdateChangesProcess)

  watch(kikUpdateMainFieldsResponse, startUpdateChangesProcess)
  watch(kikUpdateClientCommonFieldsResponse, startUpdateChangesProcess)
  watch(kikUpdateClientHeadFieldsResponse, startUpdateChangesProcess)
  watch(kikUpdateClientFounderFieldsResponse, startUpdateChangesProcess)
  watch(kikUpdateFinanceFieldsResponse, startUpdateChangesProcess)
  watch(kikUploadDocumentResponse, startUpdateChangesProcess)
  watch(kikUpdateAddressDetailsResponse, startUpdateChangesProcess)

  watch(
    productTabs,
    () => {
      if (!productTabs.value.length) {
        return
      }

      productTab.value = productTabs.value[0]
    },
    {
      deep: true,
      immediate: true,
    }
  )

  return {
    loading,
    errors,
    error,
    item,
    options,
    productTab,
    productTabs,
    hasEbg,
    hasKik,

    get,
    load,

    addFounder,
    deleteFounder,

    addFounderLoading: addFounderUse.loading,

    startUpdateChangesProcess,
  }
})
