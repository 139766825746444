import {ApplicationStatusEnum} from 'src/models/Applications/ApplicationStatusEnum'
import {computed} from 'vue'
import {useProfileStore} from 'src/stores/Login/useProfileStore'

const filterStatusesDesc: Record<ApplicationStatusEnum, string> = {
  [ApplicationStatusEnum.all]: 'Все',
  [ApplicationStatusEnum.active]: 'Активные',
  [ApplicationStatusEnum.favorites]: 'Избранное',
  [ApplicationStatusEnum.draft]: 'Черновики',
  [ApplicationStatusEnum.verification]: 'Проверка Gosoblako',
  [ApplicationStatusEnum.on_check]: 'На проверке',
  [ApplicationStatusEnum.need_sign]: 'Требуют подпись',
  [ApplicationStatusEnum.abs]: 'Отправлено в банк',
  [ApplicationStatusEnum.risk_assessment]: 'Оценка рисками',
  [ApplicationStatusEnum.approved]: 'Выставлено предложение',
  [ApplicationStatusEnum.project_bg_ready]: 'Выставлено предложение',
  [ApplicationStatusEnum.agreement]: 'На согласовании с банком',
  [ApplicationStatusEnum.on_release]: 'Ожидают выпуска',
  [ApplicationStatusEnum.released]: 'Выданные гарантии',
  [ApplicationStatusEnum.bank_rejected]: 'Отказ банка',
  [ApplicationStatusEnum.client_rejected]: 'Отказ клиента',
  [ApplicationStatusEnum.system_rejected]: 'Отказ системы',
  [ApplicationStatusEnum.bank_consideration]: 'На рассмотрении в Банке',
  [ApplicationStatusEnum.approved_orders]: 'Одобренные',
  [ApplicationStatusEnum.guarantee_released_request]: 'Выставлено предложение',
  [ApplicationStatusEnum.bg_project_bank_agreement_request]: 'Выставлено предложение',
  [ApplicationStatusEnum.release_today]: 'Выпуск сегодня',
  [ApplicationStatusEnum.release_expired]: 'Выпуск просрочен',
  [ApplicationStatusEnum.release_without_date]: 'Выпуск без даты',
  [ApplicationStatusEnum.waiting]: '',
  [ApplicationStatusEnum.in_bank]: 'Отправлено в банк',
}

const icons: Record<ApplicationStatusEnum, string> = {
  [ApplicationStatusEnum.all]: 'svguse:icons.svg?2#app-collection-bold',
  [ApplicationStatusEnum.active]: 'svguse:icons.svg?2#app-collection-bold',
  [ApplicationStatusEnum.favorites]: 'svguse:icons.svg?2#app-heart-bold',
  [ApplicationStatusEnum.draft]: 'svguse:icons.svg?2#app-clipboard-list-bold',
  [ApplicationStatusEnum.verification]: 'svguse:icons.svg?2#app-Check-GO-bold',
  [ApplicationStatusEnum.on_check]: 'svguse:icons.svg?2#app-collection-bold',
  [ApplicationStatusEnum.need_sign]: 'svguse:icons.svg?2#app-need-signature-bold',
  [ApplicationStatusEnum.abs]: 'svguse:icons.svg?2#app-collection-bold',
  [ApplicationStatusEnum.risk_assessment]: 'svguse:icons.svg?2#app-collection-bold',
  [ApplicationStatusEnum.approved]: 'svguse:icons.svg?2#app-collection-bold',
  [ApplicationStatusEnum.project_bg_ready]: 'svguse:icons.svg?2#app-collection-bold',
  [ApplicationStatusEnum.agreement]: 'svguse:icons.svg?2#app-approval-bank-bold',
  [ApplicationStatusEnum.on_release]: 'svguse:icons.svg?2#app-waiting-release-bold',
  [ApplicationStatusEnum.released]: 'svguse:icons.svg?2#app-warranty-issued-bold',
  [ApplicationStatusEnum.bank_rejected]: 'svguse:icons.svg?2#app-bank-refusal-bold',
  [ApplicationStatusEnum.client_rejected]: 'svguse:icons.svg?2#app-x-circle-bold',
  [ApplicationStatusEnum.system_rejected]: 'svguse:icons.svg?2#app-minus-circle-bold',
  [ApplicationStatusEnum.bank_consideration]: 'svguse:icons.svg?2#app-sent-bank-bold',
  [ApplicationStatusEnum.approved_orders]: 'svguse:icons.svg?2#app-exposed-bold',
  [ApplicationStatusEnum.guarantee_released_request]: 'svguse:icons.svg?2#app-collection-bold',
  [ApplicationStatusEnum.bg_project_bank_agreement_request]: 'svguse:icons.svg?2#app-collection-bold',
  [ApplicationStatusEnum.release_today]: 'svguse:icons.svg?2#app-release-today-bold',
  [ApplicationStatusEnum.release_expired]: 'svguse:icons.svg?2#app-release-overdue-bold',
  [ApplicationStatusEnum.release_without_date]: 'svguse:icons.svg?2#app-release-without-date-bold',
  [ApplicationStatusEnum.waiting]: 'svguse:icons.svg?2#app-release-without-date-bold',
  [ApplicationStatusEnum.in_bank]: 'svguse:icons.svg?2#app-collection-bold',
}

const redStatuses: ApplicationStatusEnum[] = [
  ApplicationStatusEnum.bank_rejected,
  ApplicationStatusEnum.client_rejected,
  ApplicationStatusEnum.system_rejected,
]

const blueStatuses: ApplicationStatusEnum[] = [
  ApplicationStatusEnum.abs,
  ApplicationStatusEnum.risk_assessment,
  ApplicationStatusEnum.agreement,
  ApplicationStatusEnum.on_release,
  ApplicationStatusEnum.bank_consideration,
]

const greenStatuses: ApplicationStatusEnum[] = [
  ApplicationStatusEnum.released,
  ApplicationStatusEnum.project_bg_ready,
  ApplicationStatusEnum.need_sign,
  ApplicationStatusEnum.verification,
  ApplicationStatusEnum.on_check,
  ApplicationStatusEnum.approved,
]

const greyStatuses: ApplicationStatusEnum[] = [
  ApplicationStatusEnum.draft,
  ApplicationStatusEnum.all,
]

export const useFilterStatus = () => {
  const {
    isOperator,
    isAgentManager,
    isAdmin,
  } = useProfileStore()

  const filterLabel = (status: ApplicationStatusEnum) => filterStatusesDesc[status] || status

  const icon = (status: ApplicationStatusEnum) => icons[status] || status

  const isDraft = (status: ApplicationStatusEnum) => status === ApplicationStatusEnum.draft

  const isRed = (status: ApplicationStatusEnum) => redStatuses.includes(status)
  const isBlue = (status: ApplicationStatusEnum) => blueStatuses.includes(status)
  const isGreen = (status: ApplicationStatusEnum) => greenStatuses.includes(status)
  const isGrey = (status: ApplicationStatusEnum) => greyStatuses.includes(status)

  const color = (status: ApplicationStatusEnum) => {
    if (isRed(status)) {
      return 'red-12'
    }

    if (isBlue(status)) {
      return 'blue-7'
    }

    if (isGreen(status)) {
      return 'green-14'
    }

    if (isGrey(status)) {
      return 'blue-grey-4'
    }

    return 'green-14'
  }

  const bgColor = (status: ApplicationStatusEnum) => {
    if (isRed(status)) {
      return 'white'//'red-1'
    }

    if (isBlue(status)) {
      return 'white'//'blue-1'
    }

    if (isGreen(status)) {
      return 'white'//'green-1'
    }

    if (isGrey(status)) {
      return 'white'//'grey-4'
    }

    return 'white'//'green-1'
  }

  const filterStatuses = computed(() => {
    return [
      ApplicationStatusEnum.all,
      ApplicationStatusEnum.active,
      ApplicationStatusEnum.draft,
      ApplicationStatusEnum.verification,
      ApplicationStatusEnum.need_sign,
      ApplicationStatusEnum.bank_consideration,
      ApplicationStatusEnum.approved_orders,
      ApplicationStatusEnum.agreement,
      ApplicationStatusEnum.on_release,
      ApplicationStatusEnum.released,
      ApplicationStatusEnum.bank_rejected,
      ApplicationStatusEnum.client_rejected,
      ApplicationStatusEnum.system_rejected,
      ...(isOperator() || isAgentManager()) ? [ApplicationStatusEnum.favorites] : [],
      ...(isAgentManager() || isAdmin()) ? [
        ApplicationStatusEnum.release_today,
        ApplicationStatusEnum.release_expired,
        ApplicationStatusEnum.release_without_date,
      ] : [],
      /*ApplicationStatusEnum.on_check,
      ApplicationStatusEnum.abs,
      ApplicationStatusEnum.risk_assessment,
      ApplicationStatusEnum.approved_orders,
      ApplicationStatusEnum.approved,
      ApplicationStatusEnum.project_bg_ready,*/
    ]
  })

  return {
    filterLabel,
    isDraft,
    isRed,
    isBlue,
    isGreen,
    isGrey,
    color,
    bgColor,
    icon,
    filterStatuses,
  }
}
