import {useGetApi} from 'src/uses/Api/useGetApi'
import {useFile} from 'src/uses/useFile'

export const useListingsDownloadFile = () => {
  const {
    error,
    errors,
    get,
    status,
    response,
    loading,
    headers,
  } = useGetApi<Blob>(true, 'blob')

  const {
    getFilenameFromHttpResponseBlob,
    downloadFile,
  } = useFile()

  const downloadListingsFile = async (id: string, preview = false) => {
    await get(`v2/listings/${id}/download`)

    if (status.value === 200 && response.value && !preview) {
      const fileName = getFilenameFromHttpResponseBlob(response.value, headers.value, true) || 'file'
      downloadFile(response.value, fileName)
    }
  }

  return {
    loading,
    errors,
    error,
    status,
    response,
    downloadListingsFile,
  }
}
