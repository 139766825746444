<template>
  <form-group>
    <q-field
      ref="typeField"
      :model-value="creditType"
      class="field-with-top-label no-bg btn-group-white-bg q-mb-sm text-no-wrap"
      label="Тип кредита"
      :error="false"
      borderless
      hide-bottom-space
      dense
    >
      <q-btn-toggle
        v-model="creditType"
        class="full-width"
        toggle-color="primary"
        color="blue-grey-1"
        text-color="grey-9"
        toggle-text-color="white"
        :options="types"
        spread
        unelevated
        no-caps
      />
    </q-field>
  </form-group>
</template>

<script setup lang="ts">
import FormGroup from 'src/components/FormGroup.vue'
import {storeToRefs} from 'pinia'
import {useApplicationsNew} from 'src/stores/Applications/ApplicationsNew/useApplicationsNew'
import {ref, watch} from 'vue'
import {QField} from 'quasar'
import {useScrollTo} from 'src/uses/useScrollTo'
import {useCreditType} from 'src/uses/Applications/useCreditType'

const typeField = ref<QField>()

const {
  options: types,
} = useCreditType()

const applicationsNewStore = useApplicationsNew()
const {
  creditType,
  creditLastFocused,
} = storeToRefs(applicationsNewStore)

const {
  scrollToInvisibleElement,
} = useScrollTo()

watch(
  creditLastFocused,
  () => {
    if (creditLastFocused.value !== 'law' || !typeField.value) {
      return
    }
    scrollToInvisibleElement(typeField.value?.$el)
    typeField.value.focus()
  }
)
</script>

<style scoped>

</style>
