import {BankItem} from 'src/models/Banks/BankItem'
import {usePutApi} from 'src/uses/Api/usePutApi'

export const useBankRange = () => {
  const {
    put,
    response,
    status,
    loading,
    error,
    errors,
  } = usePutApi<unknown, {banks: BankItem[]}>(true)

  const range = async (banksSortData: BankItem[]) => {
    await put('admin/bank/sort', {banks: banksSortData})
  }

  return {
    loading,
    response,
    status,
    error,
    errors,
    range,
  }
}
