import {ref} from 'vue'
import {useGetApi} from 'src/uses/Api/useGetApi'
import {OpfEnum} from 'src/models/OpfEnum'

export const useGetAgent = <AgentRequisitesResponse = Record<string, unknown>>(opf: OpfEnum) => {
  const {
    get,
    response,
    status,
    loading,
    error,
    errors,
  } = useGetApi<AgentRequisitesResponse>()

  const agentResponseData = ref<AgentRequisitesResponse>()

  const loadAgentCompany = async (id: string) => {
    await get(`admin/agent/${id}/${opf.toString().toLowerCase()}`)
    if (status.value === 200 && response.value) {
      agentResponseData.value = response.value
    }
  }

  return {
    agentResponseData,
    loading,
    response,
    status,
    error,
    errors,
    loadAgentCompany,
  }
}
