import {defineStore, storeToRefs} from 'pinia'
import {ControlCenterHeader} from 'src/models/Applications/ControlCenter/ControlCenterHeader'
import {ApplicationSubStatusEnum} from 'src/models/Applications/ApplicationSubStatusEnum'
import {ApplicationStatusEnum} from 'src/models/Applications/ApplicationStatusEnum'
import {useRequestFulfilledStatus} from 'src/uses/Applications/ControlCenter/useRequestFulfilledStatus'
import {useStatusStore} from 'src/stores/Status/useStatusStore'
import {useChangeStatusAction} from 'src/uses/Applications/useChangeStatusAction'
import {
  useApplicationControlCenterItemStore
} from 'src/stores/Applications/ControlCenter/useApplicationControlCenterItemStore'
import {watch} from 'vue'
import {ChangeStatusPayload} from 'src/models/Status/ChangeStatusPayload'
import {useApplicationControlCenterStore} from 'stores/Applications/ControlCenter/useApplicationControlCenterStore'
import {useProfileStore} from 'stores/Login/useProfileStore'

export const useApplicationControlCenterChangeStatusStore = (appId: string) => defineStore(
  `application-control-center-change-status-${appId}`,
  () => {
    const {
      init,
    } = useApplicationControlCenterStore(appId)()

    const {
      changeStatus: applicationChangeStatus
    } = useStatusStore()

    const {
      loading,
      status,
      response,
      lastStatusPayload,
    } = storeToRefs(useStatusStore())

    const applicationControlCenterItemStore = useApplicationControlCenterItemStore(appId)()
    const {
      startUpdateChangesProcess,
    } = applicationControlCenterItemStore

    const changeStatusUse = useChangeStatusAction()

    const {
      getStatus,
      getInitiator
    } = useRequestFulfilledStatus()

    const profileStore = useProfileStore()
    const {isAgentOrEmployees} = profileStore

    const isFilledDraft = (info: ControlCenterHeader) => {
      return ApplicationSubStatusEnum.draft === info.sub_status && info.filled
    }

    const isCompletion = (info: ControlCenterHeader) => {
      return ApplicationSubStatusEnum.completion === info.sub_status
    }

    const showClientReject = (info: ControlCenterHeader) => {
      if (info.required && isAgentOrEmployees()) {
        return false
      }

      return changeStatusUse.showClientReject(info.sub_status as ApplicationSubStatusEnum, info.integration.enabled)
    }

    const showToDraft = (info: ControlCenterHeader) => {
      return changeStatusUse.showToDraft(info.sub_status as ApplicationSubStatusEnum, info.sent_date)
    }

    const requestFulfilledStatus = (status: ApplicationStatusEnum, subStatus: ApplicationSubStatusEnum | null = null) => {
      let requestStatus = getStatus(status)

      if (subStatus) {
        if (subStatus === ApplicationSubStatusEnum.bg_project_customer_agreement) {
          requestStatus = ApplicationSubStatusEnum.bg_project_bank_agreement
        }

        if (subStatus === ApplicationSubStatusEnum.bg_project_bank_agreement_request) {
          requestStatus = ApplicationSubStatusEnum.bg_project_bank_agreement
        }

        if (subStatus === ApplicationSubStatusEnum.guarantee_released_request) {
          requestStatus = ApplicationSubStatusEnum.guarantee_released
        }
      }

      return requestStatus
    }

    const requestFulfilledInitiator = (subStatus: ApplicationSubStatusEnum) => {
      return getInitiator(subStatus)
    }

    const getSendRequestApplicationStatus = (info: ControlCenterHeader) => {
      return changeStatusUse.getSendRequestApplicationStatus(info.sub_status as ApplicationSubStatusEnum, info.status as ApplicationStatusEnum)
    }

    const changeStatus = async (
      payload: ChangeStatusPayload,
      currentSubStatus: ApplicationSubStatusEnum,
    ) => {
      await init()
      await applicationChangeStatus(payload, currentSubStatus)
    }

    watch(
      lastStatusPayload,
      async (value, oldValue) => {
        if (!oldValue || value) {
          return
        }

        if (status.value !== 200 || !response.value) {
          return
        }

        startUpdateChangesProcess(response.value)
      },
      {
        deep: true,
        immediate: true,
      }
    )

    return {
      loading,
      showClientReject,
      changeStatus,
      isFilledDraft,
      isCompletion,
      requestFulfilledInitiator,
      requestFulfilledStatus,
      getSendRequestApplicationStatus,
      showToDraft,
    }
  })
