<template>
  <div style="margin-left: -24px; margin-right: -24px; margin-bottom: -24px;">
    <template v-if="!loading">
      <application-last-modified-info
        v-if="item && item.last_update"
        :model-value="item.last_update"
      />
      <application-item-header
        v-if="item"
        :application-item="item"
        :loading="loading"
      />

      <application-item-products-tabs
        v-model="productTab"
        :tabs="productTabs"
        :ebg-filled="fillEbgPercent === 100"
        :kik-filled="fillKikPercent === 100"
      />

      <q-tab-panels
        v-model="productTab"
        class="bg-transparent"
        animated
      >
        <q-tab-panel
          :name="ProductEnum.EBG"
          style="padding: 0;"
        >
          <div class="q-mx-lg">
            <filled-progres :model-value="fillEbgPercent" />
          </div>
          <div class="q-mt-md">
            <q-tabs
              v-model="ebgTab"
              class="ApplicationItemTabs q-mx-lg"
              align="justify"
              no-caps
              inline-label
            >
              <application-item-tab
                :name="ApplicationItemSectionsEnum.MAIN"
                :multi-order-id="multiOrderId"
                :status="tabsFillingEbgStatus.main"
                label="Параметры заявки"
              />
              <application-item-tab
                v-if="ebgPeriods.length > 0"
                :name="ApplicationItemSectionsEnum.FINANCIAL"
                :multi-order-id="multiOrderId"
                :status="tabsFillingEbgStatus.financial"
                label="Финансовый блок"
              />
              <application-item-tab
                :name="ApplicationItemSectionsEnum.CLIENT"
                :multi-order-id="multiOrderId"
                :status="tabsFillingEbgStatus.client"
                label="Данные по клиенту"
              />
              <application-item-tab
                :name="ApplicationItemSectionsEnum.DOCUMENTS"
                :multi-order-id="multiOrderId"
                :status="tabsFillingEbgStatus.documents"
                label="Документы"
              />
            </q-tabs>
            <div
              v-if="item"
              :class="`ApplicationItemTabsContent ApplicationItemTabsContent--${tabsFillingEbgStatus[ebgTab]}`"
            >
              <q-tab-panels
                v-model="ebgTab"
                class="bg-transparent"
                animated
              >
                <q-tab-panel
                  :name="ApplicationItemSectionsEnum.MAIN"
                  class="q-pa-lg"
                >
                  <application-main-fields
                    :fields="ebgMainVisibleFields"
                    :multi-order-id="multiOrderId"
                  />
                  <application-form-beneficiaries-list
                    v-if="item"
                    :item="item"
                    :product="ProductEnum.EBG"
                  />
                </q-tab-panel>

                <q-tab-panel
                  v-if="ebgPeriods.length > 0"
                  :name="ApplicationItemSectionsEnum.FINANCIAL"
                  class="q-pa-lg"
                >
                  <application-item-finance :multi-order-id="multiOrderId" />
                </q-tab-panel>

                <q-tab-panel
                  :name="ApplicationItemSectionsEnum.CLIENT"
                  class="q-pa-lg"
                >
                  <application-client-common-fields
                    :fields="ebgClientCommonVisibleFields"
                    :multi-order-id="multiOrderId"
                  />
                  <application-item-head-and-founders
                    :head-fields="ebgClientHeadVisibleFields"
                    :founders-fields="ebgClientFoundersVisibleFields"
                    :multi-order-id="multiOrderId"
                  />
                </q-tab-panel>

                <q-tab-panel
                  :name="ApplicationItemSectionsEnum.DOCUMENTS"
                  class="q-pa-lg"
                >
                  <application-item-documents :multi-order-id="multiOrderId" />
                </q-tab-panel>
              </q-tab-panels>
            </div>
          </div>
        </q-tab-panel>
        <q-tab-panel
          :name="ProductEnum.KIK"
          style="padding: 0;"
        >
          <div class="q-mx-lg">
            <filled-progres :model-value="fillKikPercent" />
          </div>
          <div class="q-mt-md">
            <q-tabs
              v-model="kikTab"
              class="ApplicationItemTabs q-mx-lg"
              align="justify"
              no-caps
              inline-label
            >
              <application-item-tab
                :name="ApplicationItemSectionsEnum.MAIN"
                :multi-order-id="multiOrderId"
                :status="tabsFillingKikStatus.main"
                label="Параметры заявки"
              />
              <application-item-tab
                v-if="kikPeriods.length > 0"
                :name="ApplicationItemSectionsEnum.FINANCIAL"
                :multi-order-id="multiOrderId"
                :status="tabsFillingKikStatus.financial"
                label="Финансовый блок"
              />
              <application-item-tab
                :name="ApplicationItemSectionsEnum.CLIENT"
                :multi-order-id="multiOrderId"
                :status="tabsFillingKikStatus.client"
                label="Данные по клиенту"
              />
              <application-item-tab
                :name="ApplicationItemSectionsEnum.DOCUMENTS"
                :multi-order-id="multiOrderId"
                :status="tabsFillingKikStatus.documents"
                label="Документы"
              />
            </q-tabs>
            <div
              v-if="item"
              :class="`ApplicationItemTabsContent ApplicationItemTabsContent--${tabsFillingKikStatus[kikTab]}`"
            >
              <q-tab-panels
                v-model="kikTab"
                class="bg-transparent"
                animated
              >
                <q-tab-panel
                  :name="ApplicationItemSectionsEnum.MAIN"
                  class="q-pa-lg"
                >
                  <application-main-fields
                    :fields="kikMainVisibleFields"
                    :multi-order-id="multiOrderId"
                  />
                  <application-form-beneficiaries-list
                    v-if="item"
                    :item="item"
                    :product="ProductEnum.KIK"
                  />
                </q-tab-panel>

                <q-tab-panel
                  v-if="kikPeriods.length > 0"
                  :name="ApplicationItemSectionsEnum.FINANCIAL"
                  class="q-pa-lg"
                >
                  <application-item-finance :multi-order-id="multiOrderId" />
                </q-tab-panel>

                <q-tab-panel
                  :name="ApplicationItemSectionsEnum.CLIENT"
                  class="q-pa-lg"
                >
                  <application-client-common-fields
                    :fields="kikClientCommonVisibleFields"
                    :multi-order-id="multiOrderId"
                  />
                  <application-item-head-and-founders
                    :head-fields="kikClientHeadVisibleFields"
                    :founders-fields="kikClientFoundersVisibleFields"
                    :multi-order-id="multiOrderId"
                  />
                </q-tab-panel>

                <q-tab-panel
                  :name="ApplicationItemSectionsEnum.DOCUMENTS"
                  class="q-pa-lg"
                >
                  <application-item-documents :multi-order-id="multiOrderId" />
                </q-tab-panel>
              </q-tab-panels>
            </div>
          </div>
        </q-tab-panel>
      </q-tab-panels>
    </template>
    <mobile-send-to-check-btn
      v-if="productTab"
      :multi-order-id="multiOrderId"
      :product="productTab"
    />
  </div>
</template>

<script setup lang="ts">
import {useRoute} from 'vue-router'
import {computed, onBeforeMount, onBeforeUnmount} from 'vue'
import {storeToRefs} from 'pinia'
import {useApplicationItemStore} from 'src/stores/Applications/Item/useApplicationItemStore'
import ApplicationItemHeader from 'src/components/Applications/ApplicationItem/ApplicationItemHeader.vue'
import ApplicationMainFields from 'src/components/Applications/ApplicationItem/ApplicationMainFields.vue'
import ApplicationItemFinance from 'src/components/Applications/ApplicationItem/ApplicationItemFinance.vue'
import ApplicationItemTab from 'src/components/Applications/ApplicationItem/ApplicationItemTab.vue'
import {ApplicationItemSectionsEnum} from 'src/models/Enums/ApplicationItemSectionsEnum'
import ApplicationClientCommonFields
  from 'src/components/Applications/ApplicationItem/ApplicationClientCommonFields.vue'
import ApplicationItemHeadAndFounders
  from 'src/components/Applications/ApplicationItem/ApplicationItemHeadAndFounders.vue'
import ApplicationLastModifiedInfo from 'src/components/Applications/ApplicationLastModifiedInfo.vue'
import ApplicationFormBeneficiariesList from 'src/components/Applications/Form/ApplicationFormBeneficiariesList.vue'
import ApplicationItemDocuments
  from 'src/components/Applications/ApplicationItem/Documents/ApplicationItemDocuments.vue'
import {useMetaStore} from 'src/stores/useMetaStore'
import MobileSendToCheckBtn from 'components/Applications/ApplicationItem/MobileSendToCheckBtn.vue'
import {useApplicationItemEbgStore} from 'stores/Applications/Item/useApplicationItemEbgStore'
import FilledProgres from 'components/FilledProgres.vue'
import {useApplicationItemKikStore} from 'stores/Applications/Item/useApplicationItemKikStore'
import {ProductEnum} from 'src/models/Applications/ProductEnum'
import {useApplicationItemEbgFillStore} from 'stores/Applications/Item/useApplicationItemEbgFillStore'
import {useApplicationItemKikFillStore} from 'stores/Applications/Item/useApplicationItemKikFillStore'
import ApplicationItemProductsTabs from 'components/Applications/ApplicationItem/ApplicationItemProductsTabs.vue'

const route = useRoute()

const multiOrderId = computed(() => parseInt(route.params.formId as string))

const fillEbgStore = useApplicationItemEbgFillStore(multiOrderId.value)()
const {
  fillPercent: fillEbgPercent,
  tabsFillingStatus: tabsFillingEbgStatus
} = storeToRefs(fillEbgStore)

const fillKikStore = useApplicationItemKikFillStore(multiOrderId.value)()
const {
  fillPercent: fillKikPercent,
  tabsFillingStatus: tabsFillingKikStatus,
} = storeToRefs(fillKikStore)

const applicationItemStore = useApplicationItemStore(multiOrderId.value)()
const {load} = applicationItemStore
const {
  item,
  loading,
  productTab,
  productTabs,
} = storeToRefs(applicationItemStore)

const applicationItemKikStore = useApplicationItemKikStore(multiOrderId.value)()
const {
  mainVisibleFields: kikMainVisibleFields,
  clientCommonVisibleFields: kikClientCommonVisibleFields,
  clientHeadVisibleFields: kikClientHeadVisibleFields,
  clientFoundersVisibleFields: kikClientFoundersVisibleFields,
  tab: kikTab,
  periods: kikPeriods,
} = storeToRefs(applicationItemKikStore)

const applicationItemEbgStore = useApplicationItemEbgStore(multiOrderId.value)()
const {
  mainVisibleFields: ebgMainVisibleFields,
  clientCommonVisibleFields: ebgClientCommonVisibleFields,
  clientHeadVisibleFields: ebgClientHeadVisibleFields,
  clientFoundersVisibleFields: ebgClientFoundersVisibleFields,
  tab: ebgTab,
  periods: ebgPeriods,
} = storeToRefs(applicationItemEbgStore)

useMetaStore().setTitle(`Анкета ${multiOrderId.value}`)

onBeforeMount(async () => {
  await load()
})

onBeforeUnmount(() => {
  applicationItemStore.$reset()
  applicationItemStore.$dispose()

  applicationItemEbgStore.$reset()
  applicationItemEbgStore.$dispose()

  applicationItemKikStore.$reset()
  applicationItemKikStore.$dispose()

  fillEbgStore.$reset()
  fillEbgStore.$dispose()

  fillKikStore.$reset()
  fillKikStore.$dispose()
})

</script>

<style lang="scss">
.ApplicationItem {
  margin-left: -24px; margin-right: -24px; margin-bottom: -24px;
}

.ApplicationItemTabs {

}

.ApplicationItemTabsContent {
  &.ApplicationItemTabsContent--ok {
    background: $green-1;
  }
  &.ApplicationItemTabsContent--in_progress {
    background: $blue-1;
  }
  &.ApplicationItemTabsContent--error {
    background: $blue-1;
  }
}
</style>
