import {useGetApi} from 'src/uses/Api/useGetApi'
import {ref} from 'vue'
import {BankItem} from 'src/models/Banks/BankItem'

export const useGetBankItem = () => {
  const {
    get,
    response,
    status,
    loading,
    error,
    errors,
  } = useGetApi<BankItem>()

  const bankResponseData = ref<BankItem>({
    id: '',
    hidden: false,
    inn: '',
    logo: '',
    sort: 0,
    title_full: '',
    title_short: '',
    product_types: [],
  })

  const load = async (id: string) => {
    await get(`admin/bank/${id}`)
    if (status.value === 200 && response.value) {
      bankResponseData.value = response.value
    }
  }

  const getBankRequestData = () => {
    return bankResponseData.value
  }

  return {
    bankResponseData,
    loading,
    response,
    status,
    error,
    errors,
    load,
    getBankRequestData
  }
}
