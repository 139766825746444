import {defineStore} from 'pinia'
import {useGetApi} from 'src/uses/Api/useGetApi'
import {ref} from 'vue'
import {FormFinanceBlockReport} from 'src/models/FormEditor/FormItemResponseData'

export const useFormItemFinancialReportsStore = defineStore('form-item-financial-reports', () => {
  const {
    loading,
    get,
    errors,
    error,
    status,
    response,
  } = useGetApi<FormFinanceBlockReport[]>()

  const reports = ref<FormFinanceBlockReport[]>([])

  const loadReports = async () => {
    await get('v2/forms/reports')
    if (status.value === 200 && response && response.value) {
      reports.value = response.value
    }
  }

  return {
    loading,
    response,
    status,
    error,
    errors,
    reports,
    loadReports,
  }
})
