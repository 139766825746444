<template>
  <div class="row q-col-gutter-md">
    <div class="col-4">
      <q-input
        v-model="filters.name"
        :loading="props.loading"
        label="Поиск по имени или ИНН"
        clearable
        outlined
        hide-hint
        hide-bottom-space
        dense
        @change="onSubmit"
        @clear="onSubmit"
      >
        <template #prepend>
          <q-icon name="svguse:icons.svg?2#app-search" />
        </template>
      </q-input>
    </div>
    <div class="col-4">
      <q-select
        v-model="filters.offer_status"
        :options="offerStatuses"
        :loading="loading"
        map-options
        label="Статус документа"
        outlined
        hide-hint
        hide-bottom-space
        dense
        emit-value
        @update:model-value="onSubmit"
      />
    </div>
    <div class="col-4">
      <q-select
        v-model="filters.status"
        :options="statuses"
        :loading="loading"
        map-options
        label="Статусы"
        outlined
        hide-hint
        hide-bottom-space
        dense
        emit-value
        @update:model-value="onSubmit"
      />
    </div>
    <div
      v-if="isAdmin()"
      class="col"
    >
      <agent-manager-filter
        v-model="filters.managerId"
        :loading="loading"
        @change="onSubmit"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import {onBeforeMount} from 'vue'
import {storeToRefs} from 'pinia'
import {useAgentsListFiltersStore} from 'src/stores/Profiles/Agents/useAgentsListFiltersStore'
import AgentManagerFilter from 'src/components/Profiles/Agents/Filters/AgentManagerFilter.vue'
import {useProfileStore} from 'src/stores/Login/useProfileStore'
import {UserStateEnum} from 'src/models/User/UserStateEnum'
import {DocumentStatusEnum} from 'src/models/Enums/DocumentStatusEnum'
const props = defineProps<{
  loading: boolean,
}>()

const emit = defineEmits<{
  (e: 'update:filters'): void,
}>()

const {
  isAdmin,
} = useProfileStore()

const filtersStore = useAgentsListFiltersStore()
const {
  filters,
  statuses,
  offerStatuses
} = storeToRefs(filtersStore)

const getStatuses = () => {
  statuses.value.unshift({
    label: 'Все статусы',
    value: '' as UserStateEnum
  })

  return statuses.value
}

const getOfferStatuses = () => {
  offerStatuses.value.unshift({
    label: 'Все статусы',
    value: '' as DocumentStatusEnum
  })

  return offerStatuses.value
}

onBeforeMount(() => {
  statuses.value = getStatuses()
  offerStatuses.value = getOfferStatuses()
})

const onSubmit = () => {
  emit('update:filters')
}
</script>
