<template>
  <div
    v-if="periods.length"
    class="row items-center justify-between q-mb-md"
  >
    <div class="GetBO q-px-xl q-py-lg">
      <q-btn
        class="full-width"
        label="Заполнить фин. блок автоматически"
        icon-right="svguse:icons.svg?2#app-sort-ascending"
        color="blue-7"
        padding="13px"
        unelevated
        no-wrap
        no-caps
        @click="show"
      />
      <div class="text-grey-7 q-mt-sm">Найдем отчетность на оф. сайте налоговой и заполним данные</div>
    </div>
  </div>
  <q-dialog
    v-model="dialog"
    :persistent="loading"
  >
    <q-card
      class="bg-blue-1"
      style="max-width: 600px;  width: calc(90vw);"
    >
      <q-card-section class="row items-center q-py-lg q-px-lg">
        <div class="text-h6">Запросить файл отчетности</div>
      </q-card-section>
      <q-card-section class="q-pa-none">
        <div class="row bg-blue-grey-10 q-pa-lg">
          <div class="rounded-borders q-pa-sm bg-orange">
            <q-icon
              name="svguse:icons.svg?2#app-information-circle"
              size="sm"
              color="white"
            />
          </div>
          <div class="col text-white text-bold q-pl-lg">
            Внимание! Если выбранные периоды уже были заполнены ранее, они будут обновлены
          </div>
        </div>
      </q-card-section>
      <q-card-section class="q-pa-lg blue-grey-bg-field">
        <div class="q-mb-sm">Выберите периоды БО, которые требуется найти и заполнить:</div>
        <div class="selectAll q-pb-xs q-mb-xs">
          <q-checkbox
            :model-value="selected.length === periods.length"
            style="margin-left: -10px;"
            color="blue-7"
            :val="true"
            @click="selected = [...periods]"
          >
            <span class="text-grey-7">Отметить все</span>
          </q-checkbox>
        </div>
        <div
          v-for="period of periods"
          :key="getId(period)"
          style="margin-left: -10px;"
        >
          <q-checkbox
            v-model="selected"
            color="blue-7"
            :val="period"
          >
            <span class="text-grey-7">{{ getLabel(period) }}</span>
          </q-checkbox>
        </div>
      </q-card-section>
      <q-card-actions
        class="row justify-end"
        style="background: #D5EAFA; padding: 24px;"
      >
        <div class="col" />
        <q-btn
          size="16px"
          padding="md lg"
          label="Отмена"
          :disable="loading"
          unelevated
          no-caps
          @click="abort"
        />
        <q-btn
          padding="md lg"
          size="16px"
          color="blue-7"
          label="Запросить"
          :loading="loading"
          :disable="loading || selected.length === 0"
          unelevated
          no-caps
          @click="save"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
import {computed, withDefaults, ref, watchEffect} from 'vue'
import {
  ApplicationFormFinancePeriod,
} from 'src/models/Applications/Form/ApplicationFormResponseData'
import {usePeriod} from 'src/uses/Applications/Form/Finance/usePeriod'
import {useDialog} from 'src/uses/Dialogs/useDialog'
import {useLoadBO} from 'src/uses/Applications/Form/useLoadBO'
import {ApplicationFormUpdateResponse} from 'src/models/Applications/Form/ApplicationFormUpdateResponse'
import {useNotifyErrorsStore} from 'src/stores/useNotifyErrorsStore'

const props = withDefaults(
  defineProps<{
    periods: Record<number, ApplicationFormFinancePeriod>,
    multiOrderId?: number,
    appId?: string,
  }>(),
  {
    multiOrderId: undefined,
    appId: undefined,
  }
)

const emit = defineEmits<{
  (e: 'bo:loaded', response: ApplicationFormUpdateResponse): void,
}>()

const {
  show,
  hide,
  dialog,
} = useDialog()

const {
  getId,
  getLabel,
} = usePeriod()

const {
  loading,
  status,
  response,
  loadBO,
} = useLoadBO()

const useNotifyErrors = useNotifyErrorsStore()
const {
  addErrorMessage
} = useNotifyErrors

const periods = computed(() => Object.values(props.periods).filter(p => p.quarter === 4))

const selected = ref<ApplicationFormFinancePeriod[]>([])
const isEmptyLoadedBO = ref(false)

const abort = () => {
  selected.value = []
  hide()
}

const save = async () => {
  isEmptyLoadedBO.value = false

  await loadBO(
    selected.value.map(p => p.year),
    props.multiOrderId,
    props.appId,
  )

  if (status.value !== 200 || !response.value) {
    return
  }

  isEmptyLoadedBO.value = emptyLoadedBO(response.value)

  emit('bo:loaded', response.value)

  selected.value = []
  hide()
}

const emptyLoadedBO = (response: ApplicationFormUpdateResponse) => {
  const finance = response.changes?.finance
  if (!response.changes?.finance) {
    return true
  }

  return finance.fields.length === 0
}

watchEffect(() => {
  if (!isEmptyLoadedBO.value) {
    return
  }
  isEmptyLoadedBO.value = false

  addErrorMessage('По данному клиенту нет данных на сайте налоговой. Пожалуйста, заполните фин. блок вручную')
})
</script>

<style lang="scss" scoped>
.GetBO {
  border: 1px solid $blue-grey-2;
  border-radius: 6px;

  > div {
    font-size: 12px;
  }
}

.GetBOHelp {
  background: $blue-grey-2;
  border-radius: 6px;
}

.selectAll {
  border-bottom: 1px solid $blue-grey-2;
}
</style>
