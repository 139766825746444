<template>
  <div class="q-py-sm q-px-md bg-white row">
    <q-select
      v-model="selected"
      class="col ApplicationAgents field-with-top-label q-mb-md"
      :options="items"
      :loading="loading || updateLoading"
      :option-label="optionLabel"
      :option-value="optionValue"
      label="Наименование"
      hide-dropdown-icon
      outlined
      hide-bottom-space
      hide-selected
      use-input
      fill-input
      emit-value
      dense
      label-slot
      clearable
      @filter="onFilter"
    >
      <template #prepend>
        <q-icon name="svguse:icons.svg?2#app-search" />
      </template>
      <template #label>
        <div class="text-weight-medium">
          Наименование
        </div>
      </template>
      <template #option="scope">
        <q-item
          v-bind="scope.itemProps"
          class="q-py-sm q-px-md text-no-wrap ellipsis"
        >
          <div class="row items-center InnOption">
            <div class="text-grey-9 q-mr-sm">{{ scope.opt.inn }}</div>
            <div class="text-grey-6">{{ scope.opt.title }}</div>
          </div>
        </q-item>
      </template>
    </q-select>
    <div
      v-if="modified"
      style="padding-top: 40px"
    >
      <q-btn
        class="q-ml-xs"
        icon="svguse:icons.svg?2#app-check"
        size="10px"
        color="green-14"
        :disable="updateLoading"
        round
        unelevated
        flat
        @click="confirm"
      />
      <q-btn
        class="q-ml-xs"
        icon="svguse:icons.svg?2#app-x"
        size="10px"
        color="red-12"
        :disable="updateLoading"
        round
        unelevated
        flat
        @click="cancel"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import {computed, nextTick, watchEffect, ref} from 'vue'
import {QSelect} from 'quasar'
import {ApplicationNewAgent} from 'src/models/Applications/ApplicationNewAgentsResponse'
import {useAgentLoad} from 'src/uses/Applications/useAgentLoad'
import {ApplicationsGridItem} from 'src/models/Applications/ApplicationsGridItem'
import {useAgentUpdate} from 'src/uses/Applications/useAgentUpdate'

const props = defineProps<{
  item: ApplicationsGridItem,
}>()

const emit = defineEmits<{
  (e: 'update:modelValue', value: number): void,
  (e: 'change'): void,
  (e: 'changed'): void,
}>()

const agentLoad = useAgentLoad()
const {
  getAgentListByInnOrName,
  selected,
  items,
  loading,
} = agentLoad

const {
  status,
  loading: updateLoading,
  update,
} = useAgentUpdate()

const optionValue = (opt: ApplicationNewAgent) => {
  return opt
}

const optionLabel = (opt: ApplicationNewAgent) => {
  return opt.title
}

const onFilter = (
  input: string,
  update: (fn: () => void) => void
) => {
  if (!input) {
    update(() => true)
    return
  }

  update(async () => {
    await getAgentListByInnOrName(props.item.id, input)
  })
}

const agentId = ref()

const modified = computed(() => {
  return agentId.value !== props.item.agent.id
})

const confirm = async () => {
  await update(props.item.id, agentId.value || 0)

  if (status.value === 200) {
    emit('changed')
  }
}

const cancel = () => {
  agentId.value = props.item.agent.id
  selected.value = props.item.agent
}

nextTick(() => {
  agentId.value = props.item.agent.id
})

watchEffect(() => {
  agentId.value = props.item.agent.id
})

watchEffect(() => {
  selected.value = props.item.agent
})

watchEffect(() => {
  if (!selected.value) {
    return
  }
  agentId.value = selected.value.id
})

</script>

<style lang="scss">
body {
  .q-field {
    &.ApplicationAgents {
      padding-top: 32px !important;
      padding-bottom: 2px;

      .q-field__label {
        top: -32px !important;
        left: -49px !important;
      }

      input {
        margin-top: 2px;
      }

      input::-webkit-input-placeholder {
        opacity: 1 !important;
        color: $grey-8 !important;
      }
    }
  }
}
</style>
