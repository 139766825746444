import {defineStore} from 'pinia'
import {useGetApi} from 'src/uses/Api/useGetApi'
import {ref} from 'vue'
import {useGridPagination} from 'src/uses/Grid/useGridPagination'
import {Act} from 'src/models/Profile/Acts/Act'
import {ActGridResponseData} from 'src/models/Profile/Acts/ActGridResponseData'
import {useProfileActsGridFilters} from 'src/stores/Profile/Acts/useProfileActsGridFiltersStore'

export const useProfileActsGridStore = defineStore('profile-acts-grid', () => {
  const AGENT_PROFILE_ACTS_LIST_URL = 'v1/acts'

  const {
    loading,
    get,
    errors,
    error,
    status,
    response,
  } = useGetApi<ActGridResponseData>()

  const {
    pagination,
    gridPagination,
    perPageOptions,
    getFromStorage,
    onRequestPagination,
    setPage,
  } = useGridPagination('profile-acts')

  const filtersStore = useProfileActsGridFilters()
  const {getFiltersQuery} = filtersStore

  const items = ref(<Act[]>[])

  const load = async () => {
    await getFromStorage()
    await get(
      AGENT_PROFILE_ACTS_LIST_URL,
      {
        page: pagination.value?.page,
        perpage: pagination.value?.per_page,
        ...getFiltersQuery()
      }
    )

    if (status.value === 200 && response.value?.items) {
      items.value = response.value.items
      pagination.value = response.value?.pagination
    }
  }

  return {
    loading,
    get,
    errors,
    error,
    items,
    pagination,
    gridPagination,
    perPageOptions,
    onRequestPagination,
    load,
    setPage,
  }
})
