<template>
  <div style="margin-left: -24px; margin-right: -24px; margin-bottom: -24px;">
    <template v-if="item">
      <div class="q-mt-md">
        <q-tabs
          v-model="tab"
          class="ApplicationItemTabs q-mx-lg"
          align="left"
          no-caps
          inline-label
        >
          <form-item-tab
            :name="ApplicationItemSectionsEnum.MAIN"
            label="Параметры заявки"
          />
          <form-item-tab
            :name="ApplicationItemSectionsEnum.FINANCIAL"
            label="Финансовый блок"
          />
          <form-item-tab
            :name="ApplicationItemSectionsEnum.CLIENT"
            label="Данные по клиенту"
          />
          <form-item-tab
            :name="ApplicationItemSectionsEnum.DOCUMENTS"
            label="Документы"
          />
          <form-item-tab
            name="GENERATED_DOCUMENTS"
            label="Генерируемые документы"
          />
        </q-tabs>

        <div
          v-if="item"
          :class="`ApplicationItemTabsContent ApplicationItemTabsContent--in_progress`"
        >
          <q-tab-panels
            v-model="tab"
            class="bg-transparent"
            animated
          >
            <q-tab-panel
              :name="ApplicationItemSectionsEnum.MAIN"
              class="q-pa-lg"
            >
              <form-item-main-fields
                :form-id="item.id"
                :order="item.order_block"
                @update-fields="updateFields"
              />
            </q-tab-panel>

            <q-tab-panel
              :name="ApplicationItemSectionsEnum.FINANCIAL"
              class="q-pa-lg"
            >
              <form-item-financial-fields
                :form-id="item.id"
              />
            </q-tab-panel>

            <q-tab-panel
              :name="ApplicationItemSectionsEnum.CLIENT"
              class="q-pa-lg"
            >
              <form-item-client-common-fields
                :form-id="item.id"
                :client="item.client_block"
                @update-fields="updateFields"
              />
              <form-item-client-head-fields
                :form-id="item.id"
                :client="item.client_block"
                @update-fields="updateFields"
              />
            </q-tab-panel>

            <q-tab-panel
              :name="ApplicationItemSectionsEnum.DOCUMENTS"
              class="q-pa-lg"
            >
              <form-item-documents
                :form-id="item.id"
                :documents="item.document_block"
              />
            </q-tab-panel>

            <q-tab-panel
              name="GENERATED_DOCUMENTS"
              class="q-pa-lg"
            >
              <form-item-generated-documents :form-id="item.id" />
            </q-tab-panel>
          </q-tab-panels>
        </div>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import {useRoute} from 'vue-router'
import {computed, onBeforeMount, onBeforeUnmount} from 'vue'
import {storeToRefs} from 'pinia'
import {ApplicationItemSectionsEnum} from 'src/models/Enums/ApplicationItemSectionsEnum'
import {useMetaStore} from 'src/stores/useMetaStore'
import {useFormItemStore} from 'stores/FormEditor/useFormItemStore'
import FormItemTab from 'components/FormEditor/FormItemTab.vue'
import FormItemMainFields from 'components/FormEditor/FormItemMainFields.vue'
import FormItemClientCommonFields from 'components/FormEditor/FormItemClientCommonFields.vue'
import FormItemClientHeadFields from 'components/FormEditor/FormItemClientHeadFields.vue'
import FormItemDocuments from 'components/FormEditor/Documents/FormItemDocuments.vue'
import FormItemGeneratedDocuments from 'components/FormEditor/Documents/FormItemGeneratedDocuments.vue'
import FormItemFinancialFields from 'components/FormEditor/Finance/FormItemFinancialFields.vue'

const route = useRoute()

const formId = computed(() => route.params.formId as string)

const formItemStore = useFormItemStore(formId.value)()
const {load} = formItemStore
const {
  item,
  tab,
} = storeToRefs(formItemStore)

useMetaStore().setTitle('Редактор анкеты')

const updateFields = async () => {
  await load()
}

onBeforeMount(async () => {
  await load()
})

onBeforeUnmount(() => {
  formItemStore.$reset()
  formItemStore.$dispose()
})

</script>

<style lang="scss">
.ApplicationItemTabs {

}

.ApplicationItemTabsContent {
  &.ApplicationItemTabsContent--ok {
    background: $green-1;
  }
  &.ApplicationItemTabsContent--in_progress {
    background: $blue-1;
  }
  &.ApplicationItemTabsContent--error {
    background: $blue-1;
  }
}
</style>
