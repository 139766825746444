<template>
  <q-dialog
    :model-value="props.modelValue"
    :position="$q.platform.is.mobile ? 'bottom' : 'standard'"
    @before-show="showAnimationInProgress = true"
    @show="afterShow"
    @update:model-value="dialog => emit('update:modelValue', dialog)"
  >
    <q-card
      :class="{ApplicationItemFounderModal: true, green: props.isOk}"
      style="max-width: 1000px; min-width: 400px;  width: calc(90vw);"
    >
      <application-form-founder-modal-head :fields="fields">
        <template #type><slot name="type" /></template>
      </application-form-founder-modal-head>
      <q-card-section
        class="ApplicationItemFounderModal__main q-px-lg q-pb-none"
        :style="`background: ${props.isOk} ? '$blue-1' : '$green-1' };`"
      >
        <q-scroll-area
          ref="scrollRoot"
          class="fit"
          style="margin-right: -16px; width: auto !important;"
        >
          <div class="q-pr-md q-pb-md">
            <application-form-founder-passport-recognize @recognized:data="onDataRecognized" />
            <fields-block-component
              v-if="scrollRootElement"
              :fields="props.fields"
              :banks="props.banks"
              :scroll-root="scrollRootElement"
              :show-animation-in-progress="showAnimationInProgress"
              @update:value="updateValue"
              @update:address-details="updateAddressDetails"
            />
          </div>
        </q-scroll-area>
      </q-card-section>
      <q-card-actions class="row justify-end q-py-md">
        <q-btn
          size="16px"
          padding="sm lg"
          label="Закрыть"
          unelevated
          no-caps
          @click="close()"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
import {computed, ref, withDefaults, nextTick} from 'vue'
import {
  AddressDetails,
  ApplicationFormBank,
  ApplicationFormField
} from 'src/models/Applications/Form/ApplicationFormResponseData'
import {FormElementValue} from 'src/models/Form'
import {ProductFields} from 'src/models/Applications/Form/ProductFields'
import FieldsBlockComponent from 'src/components/Applications/Form/FieldsBlockComponent.vue'
import {PassportRecognizeResponse} from 'src/models/PassportRecognizeResponse'
import {FieldsCodesEnum} from 'src/models/FieldsCodesEnum'
import {QScrollArea, useQuasar} from 'quasar'
import {FieldUpdate} from 'src/models/Field/FieldUpdate'
import ApplicationFormFounderModalHead from 'src/components/Applications/Form/Client/ApplicationFormFounderModalHead.vue'
import ApplicationFormFounderPassportRecognize
  from 'src/components/Applications/Form/Client/ApplicationFormFounderPassportRecognize.vue'

const props = withDefaults(
  defineProps<{
    fields: ProductFields,
    modelValue: boolean,
    isHead: boolean,
    founderId: string,
    isOk: boolean,
    banks: ApplicationFormBank[],
  }>(),
  {
    isHead: true,
  }
)

const emit = defineEmits<{
  (e: 'update:modelValue', modelValue: boolean): void,
  (
    e: 'update:fieldValue',
    data: FieldUpdate[],
    founderId: string,
    file?: File,
  ): void,
  (e: 'update:address-details', field: ApplicationFormField, details: AddressDetails): void,
}>()

const $q = useQuasar()

const scrollRoot = ref<QScrollArea>()

const showAnimationInProgress = ref(false)

const fields = computed(() => props.fields)

const scrollRootElement = computed(() => {
  if (!scrollRoot.value) {
    return null
  }

  return scrollRoot.value.getScrollTarget()
})

const close = () => {
  emit('update:modelValue', false)
}

const getFieldByCode = (code: string) => {
  /* eslint-disable eqeqeq */
  let field = fields.value.all.find(f => f.code == code)
  if (field) {
    return field
  }

  fields.value.banks
    .forEach(b => {
      /* eslint-disable eqeqeq */
      const bankField = b.fields.find(f => f.code == code)
      field = bankField || field
    })

  return field
}

const onDataRecognized = (data: PassportRecognizeResponse, file: File) => {
  const valuesToPatch: FormElementValue[] = []
  const fieldsToPatch: ApplicationFormField[] = []

  const recognizeKeysToCodesMapping = {
    authority: FieldsCodesEnum.CLIENT_HF_DOC_PLACE,
    authority_code: FieldsCodesEnum.CLIENT_HF_CODE_PLACE,
    birthday: FieldsCodesEnum.CLIENT_HF_BIRTHDAY,
    birthplace: FieldsCodesEnum.CLIENT_HF_BIRTHPLACE,
    gender: FieldsCodesEnum.CLIENT_HF_GENDER,
    issue_date: FieldsCodesEnum.CLIENT_HF_DOC_DATE,
    name: FieldsCodesEnum.CLIENT_HF_NAME,
    number: FieldsCodesEnum.CLIENT_HF_DOC_NUM,
    patronymic: FieldsCodesEnum.CLIENT_HF_PATRONYMIC,
    series: FieldsCodesEnum.CLIENT_HF_DOC_SER,
    surname: FieldsCodesEnum.CLIENT_HF_SURNAME,
  }

  const codesToIgnoreWhenFilled = [
    FieldsCodesEnum.CLIENT_HF_BIRTHDAY,
    FieldsCodesEnum.CLIENT_HF_BIRTHPLACE,
    FieldsCodesEnum.CLIENT_HF_NAME,
    FieldsCodesEnum.CLIENT_HF_SURNAME,
    FieldsCodesEnum.CLIENT_HF_PATRONYMIC,
    FieldsCodesEnum.CLIENT_HF_GENDER,
  ]

  Object.entries(recognizeKeysToCodesMapping)
    .forEach(map => {
      const field = getFieldByCode(map[1])
      const value = (data as never as Record<string, never>)[map[0]]
      if (!field
        || !value
        || (
          codesToIgnoreWhenFilled.includes(field.code as FieldsCodesEnum)
          && field.value
        )
      ) {
        return
      }

      valuesToPatch.push(value)
      fieldsToPatch.push(field)
    })

  updateValue(valuesToPatch, fieldsToPatch, file)
}

const updateValue = (
  value: FormElementValue | FormElementValue[],
  field: ApplicationFormField | ApplicationFormField[],
  file?: File,
) => {
  const data: FieldUpdate[] = []

  if (Array.isArray(value) && Array.isArray(field)) {
    if (value.length !== field.length) {
      return
    }
    field.forEach((f, index) => {
      data.push({
        code: f.code,
        value: value[index],
      })
    })
  } else {
    data.push({
      code: (field as ApplicationFormField).code,
      value: value as FormElementValue,
    })
  }
  emit('update:fieldValue', data, props.founderId, file)
}

const updateAddressDetails = (field: ApplicationFormField, details: AddressDetails) => {
  emit('update:address-details', field, details)
}

const afterShow = () => {
  nextTick(() => {
    setTimeout(() => showAnimationInProgress.value = false, 100)
  })
}
</script>

<style lang="scss">
.ApplicationItemFounderModal {
  background: $blue-1;

  &.green {
    background: $green-1;

    .q-card__actions {
      background: $green-2;
    }
  }

  .q-card__actions {
    background: #D5EAFA;
  }

  .ApplicationItemFounderModal__main {
    height: calc(90vh - 160px);
  }
}
</style>
