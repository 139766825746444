<template>
  <application-form-new-document
    v-for="(document, index) in newDocuments"
    :key="index"
    ref="formNewDocumentRefs"
    :document="document"
    :document-type="documentType"
    :documents="existingDocuments"
    :new-documents="newDocuments"
    :founders="props.founders"
    :head-fields="props.headFields"
    :can-add-new-added-document="props.canAddNewAddedDocument"
    no-actions
    @remove="emit('new-documents:remove', index)"
    @update="emit('new-document:update', $event, documentType, index)"
  />
  <application-form-upload
    v-if="props.dropZoneAvailable"
    :document-type="props.documentType"
    :documents="existingDocuments"
    ignore-uploaded-check
    @file:selected="onNewDocumentFileSelected"
  />
</template>

<script setup lang="ts">
import {computed} from 'vue'
import {
  ApplicationFormDocumentGroupEnum,
  ApplicationFormDocument,
  ApplicationFormDocuments,
  ApplicationFormFounder,
  ApplicationFormField,
} from 'src/models/Applications/Form/ApplicationFormResponseData'
import ApplicationFormNewDocument from 'src/components/Applications/Form/Documents/ApplicationFormNewDocument.vue'
import ApplicationFormUpload from 'src/components/Applications/Form/Documents/ApplicationFormUpload.vue'

const props = defineProps<{
  documents: ApplicationFormDocuments
  newDocuments: ApplicationFormDocuments
  documentType: ApplicationFormDocumentGroupEnum
  founders: ApplicationFormFounder[]
  headFields: ApplicationFormField[]
  dropZoneAvailable: boolean
  canAddNewAddedDocument: boolean
}>()

const emit = defineEmits<{
  (
    e: 'file:selected-new',
    file: File,
    document: ApplicationFormDocument,
    documentType: ApplicationFormDocumentGroupEnum,
  ): void,
  (e: 'new-documents:remove', index: number): void,
  (
    e: 'new-document:update',
    document: ApplicationFormDocument,
    documentType: ApplicationFormDocumentGroupEnum,
    index: number
  ): void,
}>()

const existingDocuments = computed<ApplicationFormDocument[]>(() => {
  const documents = props.documents[props.documentType]
  if (!documents) {
    return []
  }

  return documents
})

const newDocuments = computed<ApplicationFormDocument[]>(() => {
  const documents = props.newDocuments[props.documentType]
  if (!documents) {
    return []
  }

  return documents
})

const onNewDocumentFileSelected = (file: File, document: ApplicationFormDocument) => {
  emit('file:selected-new', file, document, props.documentType)
}
</script>

<style lang="scss">
.DocumentsBlock {
  &:hover {
    background: transparent !important;
  }

  .q-focus-helper {
    background: transparent !important;
  }

  .q-item {
    padding-left: 0 !important;
    padding-right: 0 !important;

    font-weight: 700;
    font-size: 16px;
  }
}
</style>
