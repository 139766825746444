<template>
  <clients-grid-filters-dialog
    v-model="filerDialog"
    :loading="loading"
    @update:filters="updateFilters"
  />
  <div class="q-px-none">
    <div class="row q-pb-md">
      <q-btn
        label="Все фильтры"
        color="grey-2"
        class="text-blue-grey-4 col"
        icon-right="svguse:icons.svg?2#app-adjustments"
        unelevated
        no-caps
        @click="filterDialogShow"
      />
      <q-btn
        v-if="clearable"
        color="grey-2"
        class="text-blue-grey-4 q-ml-xs"
        icon-right="svguse:icons.svg?2#app-trash"
        size="14px"
        padding="5px 7px"
        unelevated
        square
        no-caps
        @click="onClear"
      />
    </div>
    <div class="ClientsGrid--mobile">
      <q-infinite-scroll
        ref="scroll"
        style="margin-bottom: 12px"
        @load="onLoad"
      >
        <div
          v-for="item in items as ClientsListItem[]"
          :key="item.id"
          class="q-mb-md"
        >
          <div>
            <div class="ClientsGrid--mobile--item q-pa-md rounded-borders bg-white">
              <div class="q-mb-sm">
                <div class="text-row-title">Краткое наименование: </div>
                <router-link
                  :to="{
                name: 'applications-grid',
                query: { 'filter[client]': item.inn}
              }"
                >
                  {{ item.title }}
                </router-link>
              </div>
              <div class="q-mb-sm">
                <div class="text-row-title">ИНН: </div>
                {{ item.inn }}
              </div>
              <div
                v-if="item.region?.name"
                class="q-mb-sm"
              >
                <div class="text-row-title">Регион: </div>
                {{ item.region?.name }}
              </div>
              <div
                v-if="isOperator()"
                class="q-mb-sm"
              >
                <div class="text-row-title">Агент: </div>
                {{ item.agent.name }}
              </div>
              <div v-if="item.localtime">
                <div class="text-row-title">Время клиента: </div>
                {{ item.localtime }} {{ item.timezone }}
              </div>
              <div class="createButton">
                <q-btn
                  :to="{
                name: 'applications-new',
                query: {
                  inn: item.inn,
                  agent_id: isOperator() ? item.agent.id : undefined,
                  agent_inn: isOperator() ? item.agent.inn : undefined,
                }
              }"
                  class="full-width q-mt-sm"
                  color="primary"
                  size="12px"
                  style="padding: 10px 36px 10px 16px;"
                  unelevated
                  no-caps
                >
                  <strong>Создать заявку</strong>
                  <span class="icon">
                <q-icon
                  name="svguse:icons.svg?2#app-plus-1"
                  color="primary"
                  size="12px"
                  style="padding: 5.5px;"
                />
              </span>
                </q-btn>
              </div>
            </div>
          </div>
        </div>
        <template #loading>
          <div class="text-center">
            <q-circular-progress
              class="q-mt-sm"
              color="primary"
              size="md"
              indeterminate
            />
          </div>
        </template>
      </q-infinite-scroll>
    </div>
  </div>
</template>

<script setup lang="ts">
import {storeToRefs} from 'pinia'
import {onBeforeUnmount, ref, watch} from 'vue'
import {useClientsListFiltersStore} from 'src/stores/Clients/useClientsListFiltersStore'
import {useClientsListStore} from 'src/stores/Clients/useClientsListStore'
import {useProfileStore} from 'src/stores/Login/useProfileStore'
import {ClientsListItem} from 'src/models/Clients/ClientsListItem'
import ClientsGridFiltersDialog from 'components/Clients/ClientsGridFiltersDialog.vue'
import {QInfiniteScroll} from 'quasar'

const filtersStore = useClientsListFiltersStore()
const clientsListStore = useClientsListStore()
const {
  items,
  loading,
  filerDialog,
  clearable,
  noMorePages,
} = storeToRefs(clientsListStore)

const {
  load,
  setPage,
  setNextPage,
  filterDialogShow,
  onClear,
} = clientsListStore

const {
  isOperator,
} = useProfileStore()

const scroll = ref<QInfiniteScroll>()

const onLoad = async (index: number, done: (stop?: boolean) => void) => {
  if (loading.value) {
    return
  }
  setNextPage()
  await load()
  done()
}

const updateFilters = () => {
  setPage(1)
  load()
}

onBeforeUnmount(() => {
  clientsListStore.$reset()
  clientsListStore.$dispose()
  filtersStore.$reset()
  filtersStore.$dispose()
})

watch(
  noMorePages,
  () => {
    if (!scroll.value) {
      return
    }

    if (noMorePages.value) {
      scroll.value.stop()

      return
    }

    scroll.value.resume()
  }
)
</script>

<style lang="scss">
.ClientsGrid--mobile {
  background: $blue-1;
  padding: 20px;
  margin: 0 -10px -10px -10px;
  .ClientsGrid--mobile--item {
    border-radius: 8px;
    .text-row-title {
      color: #9E9E9E;
      font-size: 13px;
      font-weight: 400;
      line-height: 15.23px;
      text-align: left;

    }
  }
  table {
    position: relative;

    tr {
      td {
        width: 150px;

        &:first-child {
          width: auto;
          white-space: normal;
        }
      }
    }
  }

  .createButton {
    .icon {
      background: white;
      border-radius: 6px;
      line-height: 0;
      margin-left: 10px;
    }

    .q-btn {
      border-radius: 6px;
    }
  }
}
</style>
