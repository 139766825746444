import {
  ApplicationFormUpdateBlockEnum,
  ApplicationFormUpdateResponse
} from 'src/models/Applications/Form/ApplicationFormUpdateResponse'
import {
  ApplicationFormDocumentGroupEnum,
  ApplicationFormFinance,
  ApplicationFormFinanceField, ApplicationFormBank,
  ApplicationFormClient,
  ApplicationFormDocument,
  ApplicationFormDocuments,
  ApplicationFormField,
  ApplicationFormOrder
} from 'src/models/Applications/Form/ApplicationFormResponseData'
import {usePeriod} from 'src/uses/Applications/Form/Finance/usePeriod'

export const useUpdateFieldsSuccess = () => {
  const {
    getPeriodFieldsById,
  } = usePeriod()

  const tryProcess = (
    change: ApplicationFormField | ApplicationFormFinanceField,
    fields: ApplicationFormField[] | ApplicationFormFinanceField[]
  ) => {
    const fieldIndex = fields.findIndex(f => f.code === change.code)
    if (fieldIndex === -1) {
      return
    }
    //change.value = '31.03.2023'
    fields[fieldIndex] = {
      ...fields[fieldIndex],
      ...change,
      location: fields[fieldIndex].location,
      loading: false,
    }
  }

  const tryProcessMain = (
    change: ApplicationFormField,
    main: ApplicationFormOrder | null
  ) => {
    if (change.location.block !== ApplicationFormUpdateBlockEnum.order
      || change.location.section_type !== 'main'
      || !main
    ) {
      return
    }

    tryProcess(change, main.main.fields)
  }

  const tryProcessCustomers = (
    change: ApplicationFormField,
    main: ApplicationFormOrder | null
  ) => {
    if (change.location.block !== ApplicationFormUpdateBlockEnum.order
      || change.location.section_type !== 'customers'
      || !main
      || !main.customers
    ) {
      return
    }

    const customer = main.customers
      .find(customer => customer.id === parseInt(change.location.section_id || '0'))

    if (!customer) {
      return
    }

    tryProcess(change, customer.fields)
  }

  const tryProcessClientCommon = (
    change: ApplicationFormField,
    client: ApplicationFormClient | null
  ) => {
    if (change.location.block !== ApplicationFormUpdateBlockEnum.client
      || change.location.section_type !== 'common'
      || !client
    ) {
      return
    }

    tryProcess(change, client.common.fields)
  }

  const tryProcessClientHead = (
    change: ApplicationFormField,
    client: ApplicationFormClient | null
  ) => {
    if (change.location.block !== ApplicationFormUpdateBlockEnum.client
      || change.location.section_type !== 'head'
      || !client
    ) {
      return
    }

    tryProcess(change, client.head.fields)
  }

  const tryProcessClientFounder = (
    change: ApplicationFormField,
    client: ApplicationFormClient | null
  ) => {
    if (change.location.block !== ApplicationFormUpdateBlockEnum.client
      || change.location.section_type !== 'founders'
      || !change.location.section_id
      || !client
    ) {
      return
    }

    const founder = client.founders
      .find(f => f.id === change.location.section_id)

    if (!founder) {
      return
    }

    tryProcess(change, founder.fields)
  }

  const tryProcessDocuments = (
    change: ApplicationFormDocument,
    documents: ApplicationFormDocuments,
    type: ApplicationFormDocumentGroupEnum,
    banks: ApplicationFormBank[],
  ) => {
    const documentFields = documents[type] || []
    if (change.location.block !== ApplicationFormUpdateBlockEnum.documents
      || change.location.section_type !== type
    ) {
      return
    }

    const changeBankIds = change.valid.map(v => v.id)
    const bankIds = banks.map(v => v.bank_id)
    const bankExistsInProduct = changeBankIds.find(bankId => bankIds.includes(bankId))

    const fieldIndex = documentFields
      .findIndex(d => {
        return d.code === change.code
          && (
            ![
              ApplicationFormDocumentGroupEnum.additional,
              ApplicationFormDocumentGroupEnum.deal,
              ApplicationFormDocumentGroupEnum.offer,
            ].includes(type)
            || change.code !== 'ADDED_DOCUMENT'
            || d.index === change.index
          )
          && d.founder_id === change.founder_id
          && d.period_year === change.period_year
          && d.period_quarter === change.period_quarter
      })

    if (fieldIndex === -1) {
      if (bankExistsInProduct) {
        documentFields.push(change)
      }

      return
    }

    if (!change.file_status) {
      if (!bankExistsInProduct) {
        documentFields.splice(fieldIndex, 1)
        return
      }
    }

    //change.value = '31.03.2023'
    documentFields[fieldIndex] = {
      ...documentFields[fieldIndex],
      ...change,
    }
  }

  const tryProcessFinance = (
    change: ApplicationFormFinanceField,
    finance: ApplicationFormFinance | undefined,
  ) => {
    if (change.location.block !== ApplicationFormUpdateBlockEnum.finance
      || !finance
      || !finance.fields
    ) {
      return
    }

    const fields = getPeriodFieldsById(
      parseInt(change.location.section_type),
      finance
    )

    if (fields.length === 0) {
      return
    }

    tryProcess(change, fields)
  }

  const tryProcessBanks = (
    change: ApplicationFormBank,
    banks: ApplicationFormBank[]
  ) => {
    const bankIndex = banks.findIndex(b => b.id === change.id)
    if (bankIndex === -1) {
      return
    }

    banks[bankIndex] = {
      ...banks[bankIndex],
      ...change,
    }
  }

  const updateChangesProcess = (
    response: ApplicationFormUpdateResponse | undefined,
    main: ApplicationFormOrder | null,
    client: ApplicationFormClient | null,
    finance: ApplicationFormFinance | undefined,
    documents: ApplicationFormDocuments,
    banks: ApplicationFormBank[],
  ) => {
    if (!response || !response.changes) {
      return
    }

    Object.values(ApplicationFormDocumentGroupEnum)
      .forEach(type => {
        response.changes.documents
          .forEach(change => {
            tryProcessDocuments(change, documents, type, banks)
          })
      })

    response.changes.fields
      .forEach(change => {
        tryProcessMain(change, main)
        tryProcessCustomers(change, main)
        tryProcessClientCommon(change, client)
        tryProcessClientHead(change, client)
        tryProcessClientFounder(change, client)
      })

    response.changes.finance.fields
      .forEach(change => {
        tryProcessFinance(change, finance)
      })

    response.changes.banks
      .forEach(change => {
        tryProcessBanks(change, banks)
      })
  }

  return {
    updateChangesProcess,
  }
}
