export const usePhoneFormat = () => {
  const formatPhone = (input: string) => {
    if (!input) {
      return ''
    }

    return input
      .replace(/[^\d]/, '')
      .replace(
        /(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/,
        '+$1 ($2) $3-$4-$5'
      )
  }

  return {
    formatPhone,
  }
}
