import {ref} from 'vue'
import {IntegratorData} from 'src/models/IntegratorData'
import {useGetApi} from 'src/uses/Api/useGetApi'

export const useGetIntegratorData = () => {
  const {
    loading,
    get,
    status,
    response,
  } = useGetApi<IntegratorData>()

  const data = ref<IntegratorData>()

  const load = async () => {
    //const host = 'simba-bg.ru'
    const host = document.location.hostname
    await get(
      `v2/integrators/hosts/${host}`,
    )

    if (status.value === 200 && response && response.value) {
      data.value = response.value
    }
  }

  return {
    loading,
    data,
    status,
    load,
  }
}
