<template>
  <q-input
    :key="String(props.field.value)"
    :model-value="model as FormElementPrimitiveValue"
    class="field-with-top-label"
    :hint="props.ignoreHint ? '' : (props.field.help || '')"
    :disable="props.field.readonly && !props.ignoreReadonly"
    :error="!!props.field.error"
    :error-message="props.field.error"
    :loading="props.field.loading"
    type="number"
    label-slot
    outlined
    hide-bottom-space
    dense
    @update:model-value="onInput"
    @change="change"
  >
    <template #label>
      <slot name="labelPrepend" />
      {{ props.label || props.field.title }}
      <slot name="labelAppend" />
    </template>
  </q-input>
</template>

<script setup lang="ts">
import {ref, watchEffect} from 'vue'
import {
  ApplicationFormField,
} from 'src/models/Applications/Form/ApplicationFormResponseData'
import {FormElementPrimitiveValue, FormElementValue} from 'src/models/Form'

const props = defineProps<{
  field: ApplicationFormField,
  label: string | boolean,
  ignoreReadonly: boolean,
  ignoreHint: boolean,
}>()

const emit = defineEmits<{
  (e: 'update:value', value: FormElementValue): void,
}>()

const model = ref<FormElementValue>(null)

watchEffect(() => {
  model.value = props.field.value
})

const change = () => {
  emit('update:value', model.value)
}

const onInput = (value: FormElementValue) => {
  if (typeof value !== 'string') {
    return
  }
  const newValue = value.replaceAll(/\D/gi, '')

  model.value = newValue ? parseInt(newValue) : null
}
</script>
