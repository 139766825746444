<template>
  <div :class="getClass(props.modelValue.factor_result)">
    {{ getLabel(props.modelValue.factor_result) }}
    <template v-if="!isGreen(props.modelValue.factor_result)">
      <q-icon
        name="svguse:icons.svg?2#app-exclamation-circle-bold"
        size="16px"
        style="margin-top: -2px;"
        :color="getColor(props.modelValue.factor_result)"
      />
      <q-tooltip class="bg-blue-grey-10 StopTooltip q-pa-md">
        <stop-factors-result :model-value="props.modelValue.factors" />
      </q-tooltip>
    </template>
  </div>
</template>

<script setup lang="ts">
import {SelectBank} from 'src/models/Applications/New/SelectBank'
import {useStopFactorResult} from 'src/uses/Applications/useStopFactorResult'
import StopFactorsResult from 'src/components/Applications/StopFactorsResult.vue'

const props = defineProps<{
  modelValue: SelectBank,
}>()

const {
  getLabel,
  isGreen,
  getClass,
  getColor,
} = useStopFactorResult()
</script>
