<template>
  <q-dialog
    :model-value="statusStore.requestDialog || statusKikStore.requestDialog"
    @update:model-value="statusStore.requestDialog ? statusStore.requestDialog = $event : statusKikStore.requestDialog = $event"
  >
    <q-card
      class="q-mt-md bg-blue-1 RequestDialog"
      style="min-width: 400px; max-width: 600px; border-radius: 4px !important;"
    >
      <q-card-section class="text-h6 rounded-borders">
        Комментарий
      </q-card-section>
      <q-card-section class="white-bg-field">
        <q-input
          v-model="comment"
          class="field-with-top-label q-mb-xl CommentInput"
          label="Укажите, что необходимо доработать в заявке"
          counter
          outlined
          autogrow
          hide-bottom-space
        />
      </q-card-section>
      <q-card-actions
        class="row justify-end q-py-md"
        style="background: #D5EAFA;"
      >
        <q-btn
          v-close-popup
          size="16px"
          padding="sm lg"
          label="Отмена"
          unelevated
          no-caps
        />
        <q-btn
          padding="sm lg"
          size="16px"
          color="blue-7"
          label="Сохранить"
          :disable="!comment"
          unelevated
          no-caps
          @click="onClick"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
import {useStatusStore} from 'src/stores/Status/useStatusStore'
import {ref, watchEffect} from 'vue'
import {useStatusKikStore} from 'stores/Status/useStatusKikStore'

const statusStore = useStatusStore()
const statusKikStore = useStatusKikStore()

const comment = ref<string>()

const onClick = () => {
  if (!comment.value) {
    return
  }

  if (statusStore.requestDialog) {
    statusStore.onSaveRequest(comment.value)
  }

  if (statusKikStore.requestDialog) {
    statusKikStore.onSaveRequest(comment.value)
  }
}

watchEffect(
  () => {
    if (!statusStore.requestDialog) {
      comment.value = undefined
    }
  }
)
watchEffect(
  () => {
    if (!statusKikStore.requestDialog) {
      comment.value = undefined
    }
  }
)
</script>
<style lang="scss">
.RequestDialog {
  .q-field.field-with-top-label .q-field__native{
    padding: 10px 0!important;
  }
}
</style>
