import {usePostApi} from 'src/uses/Api/usePostApi'
import {ApplicationClonePayload} from 'src/models/Applications/New/ApplicationClonePayload'

export const useApplicationNewSendClone = () => {
  const {
    loading,
    post,
    status,
    response,
    errors,
  } = usePostApi<{multi_order_id: string}, ApplicationClonePayload>()

  const clone = async (payload: ApplicationClonePayload, id: string) => {
    await post(`v2/orders/${id}/selection/create`, payload)
  }

  return {
    errors,
    response,
    status,
    loading,
    clone,
  }
}
