<template>
  <div class="row q-col-gutter-md">
    <form-item-field-component
      v-for="(field, index) in fields"
      :key="index"
      :field="field"
      :form-id="props.formId"
      :section-id="props.sectionId"
      @change="changeField"
      @add-before-fields="addBeforeFields"
      @delete-new-field="deleteNewField"
    />
  </div>
  <div class="q-py-lg">
    <q-btn
      v-if="!isShowAddFieldsGrid"
      label="Добавить поля"
      icon-right="svguse:icons.svg?2#app-plus"
      color="blue-7"
      padding="13px 20px"
      unelevated
      no-wrap
      no-caps
      @click="showAddFieldGrid"
    />
    <form-item-add-fields
      v-model="isShowAddFieldsGrid"
      :section-id="props.sectionId"
      :form-id="props.formId"
      :before-field-id="beforeFieldId"
      @add-fields="addFields"
      @change="onChange"
    />
  </div>
</template>

<script setup lang="ts">
import {ref, watchEffect} from 'vue'
import FormItemFieldComponent from 'components/FormEditor/FormItemFieldComponent.vue'
import {ApplicationFormField} from 'src/models/Applications/Form/ApplicationFormResponseData'
import {FormItemField} from 'src/models/FormEditor/FormItemResponseData'
import FormItemAddFields from 'components/FormEditor/FormItemAddFields.vue'

const emit = defineEmits<{
  (e: 'change'): void,
  (e: 'addBeforeFields', id: string): void,
}>()

const props = defineProps<{
  fields: ApplicationFormField[],
  formId: string,
  sectionId: string,
}>()

const fields = ref<FormItemField[]>([])
const beforeFieldId = ref<string>()
const isShowAddFieldsGrid = ref<boolean>(false)

const addNewFields = (fieldsToAdd: FormItemField[]) => {
  const newFields = fieldsToAdd.map(item => {
    return {
      ...item,
      is_new_field: true
    }
  })
  if (!beforeFieldId.value) {
    fields.value.push(...newFields)
    return
  }
  const beforeFieldIndex = fields.value.findIndex(f => f.id === beforeFieldId.value)
  if (beforeFieldIndex === -1) {
    return
  }

  fields.value.splice(beforeFieldIndex, 0, ...newFields)
}

const onChange = () => {
  isShowAddFieldsGrid.value = false
  emit('change')
}

const addFields = (newFields: FormItemField[]) => {
  fields.value = [...props.fields]
  addNewFields(newFields)
}

const showAddFieldGrid = () => {
  isShowAddFieldsGrid.value = true
}

const addBeforeFields = (id: string) => {
  beforeFieldId.value = id
  isShowAddFieldsGrid.value = true
}

const deleteNewField = (id: string) => {
  const fieldIndex = fields.value.findIndex(f => f.id === id)
  if (fieldIndex !== -1) {
    fields.value.splice(fieldIndex, 1)
  }
}

watchEffect(() => {
  fields.value = [...props.fields]
})

const changeField = () => {
  emit('change')
}
</script>
