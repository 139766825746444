import {defineStore} from 'pinia'
import {ref} from 'vue'
import {useDeleteApi} from 'src/uses/Api/useDeleteApi'
import {SuccessorItem} from 'src/models/Profile/Employees/SuccessorItem'

export const useEmployeeDeleteStore = defineStore('employee-delete', () => {
  const {
    deleteRequest,
    response,
    status,
    loading,
    error,
    errors,
  } = useDeleteApi<never>()

  const success = ref(false)
  const successor = ref<SuccessorItem>({
    id: '',
    name: '',
  })

  const deleteEmployee = async (id: string) => {
    await deleteRequest(`v1/employees/${id}`, {successor_id: successor.value?.id})
    if (status.value === 200 && response.value) {
      success.value = true
    }
  }

  return {
    loading,
    response,
    status,
    error,
    errors,
    success,
    successor,
    deleteEmployee,
  }
})
