import {useGetApi} from 'src/uses/Api/useGetApi'
import {ref} from 'vue'
import {NewsItem} from 'src/models/News/NewsItem'
import {NewsStatusEnum} from 'src/models/Enums/NewsStatusEnum'
import {NewsTopicEnum} from 'src/models/Enums/NewsTopicEnum'

export const useNewsItem = () => {
  const {
    loading,
    get,
    errors,
    error,
    status,
    response,
  } = useGetApi<NewsItem>()

  const newsItem = ref<NewsItem>({
    id: '',
    title: '',
    body: '',
    created: '',
    publication_date: '',
    publication_time: '',
    publication_type: NewsStatusEnum.draft,
    status: NewsStatusEnum.draft,
    topic: NewsTopicEnum.change_of_conditions,
    picture: '',
    documents: [],
    delete: false,
  })

  const load = async (id: string) => {
    await get(`v1/news/${id}`)

    if (status.value === 200 && response && response.value) {
      newsItem.value = response.value
    }
  }

  const downloadByUrl = (url: string, fileName: string) => {
    const link = document.createElement('a')
    link.href = url
    link.download = fileName
    link.target = '_blank'
    link.click()
    window.URL.revokeObjectURL(url)
  }

  return {
    loading,
    get,
    errors,
    error,
    newsItem,
    load,
    downloadByUrl,
  }
}
