import {defineStore} from 'pinia'
import {ref} from 'vue'
import {usePostApi} from 'src/uses/Api/usePostApi'
import {useGetApi} from 'src/uses/Api/useGetApi'
import {FormHelper} from 'src/helpers/FormHelper'
import {useFile} from 'src/uses/useFile'
import {Document} from 'src/models/Profile/Documents/Document'
import {AttachmentsTypeEnum} from 'src/models/Enums/AttachmentsTypeEnum'
import {DocumentStatusEnum} from 'src/models/Enums/DocumentStatusEnum'

export const useProfileDocumentSignStore = defineStore('profile-document-sign', () => {
  const fileUse = useFile()
  const {
    base64ToSigFile
  } = fileUse

  const {
    post,
    response,
    status,
    loading,
    error,
    errors,
  } = usePostApi<FormData, FormData>()

  const {
    get,
    status: getStatus,
    response: getResponse,
  } = useGetApi<{base64: string} | undefined>()

  const success = ref(false)
  const fileForSign = ref<string | undefined>('')

  const sign = async (document: Document, signedFile: string, certName: string) => {
    certName = certName.replace(' ', '_')
    const filename = `${document.type}_${certName}.pdf.sig`
    const data = FormHelper.getFormDataFromObject({
      file: base64ToSigFile(signedFile, filename)
    })
    await post(`v1/document/upload/${document.id}/sign`, data)
    if (status.value === 200 && response.value) {
      success.value = true
    }
  }

  const getFileForSign = async (document: Document) => {
    if (document && document.status === DocumentStatusEnum.WaitOriginal) {
      return
    }

    const attach = document.attachments?.find(a => a.type === AttachmentsTypeEnum.Original)

    if (!attach) {
      return
    }
    await get(
      `v1/document/download/${attach.file_id}/base64`,
    )

    if (getStatus.value === 200 && getResponse) {
      fileForSign.value = getResponse.value?.base64
    }
  }

  return {
    loading,
    response,
    status,
    error,
    errors,
    success,
    fileForSign,
    getFileForSign,
    sign,
  }
})
