<template>
  <q-btn
    size="sm"
    color="blue-grey-4"
    icon="svguse:icons.svg?2#app-pencil-alt"
    round
    flat
    @click="emit('click')"
  />
</template>

<script setup lang="ts">
const emit = defineEmits<{
  (e: 'click'): void,
}>()
</script>

<style scoped lang="scss">

</style>
