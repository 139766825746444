export default interface DeliveryData {
  address: string
  name: string
  phone: string
  receiver: string
  comment: string
}

export enum DeliveryDataReceiverEnum {
  client = 'client',
  customer = 'customer',
  other = 'other',
}
