<template>
  <div :class="{'row q-col-gutter-sm': props.mobile}">
    <div>
      <money-component :model-value="item.guarantee_total" />
    </div>
    <div :class="{col: props.mobile}">
      <div class="row no-wrap">
        <div class="col">
          <money-component :model-value="item.tariff" />
          /
          {{ item.tariff_percent }} %
        </div>
        <div
          v-if="!props.onlyText"
          style="position: relative; min-width: 35px;"
        >
          <div style="top: -7px; position: absolute;">
            <application-tariff
              :id="item.id"
              :number="item.number"
              @tariff-refreshed="emit('tariffRefreshed')"
            >
              <template #default="{onShowClick}">
                <q-btn
                  v-if="props.item.tariff_calculator_enabled"
                  class="q-ml-xs"
                  icon="svguse:icons.svg?2#app-pencil-alt"
                  :style="$q.platform.is.mobile ? 'margin-top: -3px;' : ''"
                  :size="!props.mobile ? '10px' : '14px'"
                  color="blue-grey-4"
                  round
                  unelevated
                  flat
                  @click="onShowClick"
                >
                  <q-tooltip
                    anchor="top middle"
                    self="bottom middle"
                  >
                    Изменить тариф
                  </q-tooltip>
                </q-btn>
              </template>
            </application-tariff>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="item.payment">
    Оплата:
    <money-component
      :class="item.payment !== Number(item.tariff) ? `text-red` : `text-green`"
      :model-value="item.payment"
    />
  </div>
</template>

<script setup lang="ts">
import {computed, withDefaults} from 'vue'
import {ApplicationsGridItem} from 'src/models/Applications/ApplicationsGridItem'
import MoneyComponent from 'src/components/MoneyComponent.vue'
import ApplicationTariff from 'src/components/Applications/Tariff/ApplicationTariff.vue'
import {useQuasar} from 'quasar'

const props = withDefaults(
  defineProps<{
    item: ApplicationsGridItem
    mobile?: boolean
    onlyText?: boolean
  }>(),
  {
    mobile: false,
    onlyText: false,
  }
)

const emit = defineEmits<{
  (e: 'tariffRefreshed'): void,
}>()

const $q = useQuasar()

const item = computed(() => props.item)
</script>
