import {usePutApi} from 'src/uses/Api/usePutApi'

export const useAgentUpdate = () => {
  const {
    error,
    errors,
    put,
    status,
    response,
    loading,
  } = usePutApi<unknown, {agent_id: string}>()

  const update = async (appId: string, agentId: string) => {
    await put(
      `v1/admin/order/${appId}/agent/attach`,
      {
        agent_id: agentId
      }
    )
  }

  return {
    error,
    errors,
    put,
    status,
    response,
    loading,
    update,
  }
}
