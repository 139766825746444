export const useValidationRules = () => {
  const getRulesFromState = <T = Record<string, never>>(state: T) => {
    return Object.fromEntries(
      Object.entries(state as ArrayLike<T>)
        .map(e => [e[0], {}])
    )
  }

  return {
    getRulesFromState,
  }
}
