<template>
  <q-item
    v-if="props.list"
    v-ripple
    class="text-no-wrap"
    clickable
    @click="showWrapper"
  >
    <q-item-section>
      <q-item-label>
        <q-icon
          color="blue-grey-4"
          size="24px"
          name="svguse:icons.svg?2#app-information-circle"
        />
        Информация
      </q-item-label>
    </q-item-section>
  </q-item>
  <q-btn
    v-else
    class="text-blue-grey-4"
    icon="svguse:icons.svg?2#app-information-circle"
    padding="sm"
    flat
    unelevated
    square
    @click="showWrapper"
  >
    <q-tooltip
      anchor="top middle"
      self="bottom middle"
    >Информация по заявке</q-tooltip>
  </q-btn>
</template>

<script setup lang="ts">
import {withDefaults} from 'vue'

const emit = defineEmits<{
  (e: 'show-dialog'): void,
}>()

const props = withDefaults(
  defineProps<{
    list?: boolean,
  }>(),
  {
    list: false,
  }
)

const showWrapper = () => {
  emit('show-dialog')
}
</script>

<style lang="scss">
  .ApplicationGridInfo--ListWrapper {
    border-radius: 6px;
    overflow: hidden;
    position: relative;
  }
</style>
