<template>
  <q-btn
    size="sm"
    icon="svguse:icons.svg?2#app-calculator"
    color="blue-grey-4"
    round
    flat
    @click="open"
  />
  <q-dialog
    v-if="props.product && calculatorOptions"
    v-model="dialog"
  >
    <q-card
      class="productCalculatorOptions"
      style="min-width: 600px; max-width: 1000px;"
    >
      <q-card-section
        class="modal-title row items-center q-pb-none q-pl-lg bg-white"
      >
        <div class="q-pt-md q-pb-sm">Настройки калькулятора</div>
      </q-card-section>
      <q-card-section class="q-pa-none q-pa-md white-bg-field">
        <div class="row">
          <div class="col-12">
            <q-toggle
              v-model="calculatorOptions.enabled"
              label="Калькулятор включен"
              class="q-mr-md"
              color="blue-7"
            />
          </div>
        </div>
        <div
          v-if="calculatorOptions.enabled"
          class="row productCalculatorOptions--wrapper"
        >
          <div class="col-12">
            <q-toggle
              v-model="calculatorOptions.date_of_entry_into_force_enabled"
              label="Управление датой вступления в силу"
              class="q-mr-md"
              color="blue-7"
            />
          </div>
          <div class="col-12">
            <q-toggle
              v-model="calculatorOptions.issue_form_enabled"
              label="Управление формой выдачи"
              class="q-mr-md"
              color="blue-7"
            />
          </div>
          <div class="col-12">
            <q-toggle
              v-model="calculatorOptions.commission_approval_enabled"
              label="Перевод статуса на согласование комиссии"
              class="q-mr-md"
              color="blue-7"
            />
          </div>
          <div class="col-12">
            <q-toggle
              v-model="calculatorOptions.external_discount_enabled"
              label="Принимать скидки от интегратора"
              class="q-mr-md"
              color="blue-7"
            />
          </div>
          <div class="col-12 commissionBlock">
            <q-toggle
              v-model="calculatorOptions.bank_commission_enabled"
              label="Падение за счет банка"
              class="q-mr-md"
              color="blue-7"
            />
            <div
              v-if="calculatorOptions.bank_commission_enabled"
              class="row q-col-gutter-md q-mt-md q-pa-md"
            >
              <div class="col" style="max-width: 200px">
                <q-input
                  v-model="calculatorOptions.bank_commission_percent"
                  type="number"
                  label="Падение %"
                  class="field-with-top-label"
                  outlined
                  hide-hint
                  hide-bottom-space
                  dense
                />
              </div>
              <div class="col">
                <q-input
                  v-model="calculatorOptions.bank_commission_comment"
                  class="field-with-top-label"
                  label="Комментарий"
                  outlined
                  hide-hint
                  hide-bottom-space
                  dense
                />
              </div>
            </div>
          </div>
          <div class="col-12 commissionBlock">
            <q-toggle
              v-model="calculatorOptions.agent_commission_enabled"
              label="Падение за счет агента"
              class="q-mr-md"
              color="blue-7"
            />
            <div
              v-if="calculatorOptions.agent_commission_enabled"
              class="row q-col-gutter-md q-mt-md q-pa-md"
            >
              <div class="col" style="max-width: 200px">
                <q-input
                  v-model="calculatorOptions.agent_commission_percent"
                  type="number"
                  label="Падение %"
                  class="field-with-top-label"
                  outlined
                  hide-hint
                  hide-bottom-space
                  dense
                />
              </div>
              <div class="col">
                <q-input
                  v-model="calculatorOptions.agent_commission_comment"
                  class="field-with-top-label"
                  label="Комментарий"
                  outlined
                  hide-hint
                  hide-bottom-space
                  dense
                />
              </div>
            </div>
          </div>
        </div>
      </q-card-section>
      <q-card-section
        class="q-pa-none q-pa-md white-bg-field conditionsBlock"
      >

        <div
          v-if="calculatorOptions.conditional_options"
          class="row q-pa-md"
        >
          <div
            v-if="calculatorOptions && calculatorOptions.conditional_options && calculatorOptions.conditional_options.length > 0"
            class="col-12 q-pt-none">
            <div v-for="(condition, index) in calculatorOptions.conditional_options" :key="index">
              <div class="row q-mb-md q-pa-md"
                   style="border-radius: 6px; border: 1px dotted #adacac;"
              >
                <div class="col-12">
                  <div class="text-bold q-mb-xs">Условие #{{ index + 1 }}</div>
                  <q-form>
                    <div
                      v-for="(param , indexParam) in condition.comparison.parts"
                      :key="indexParam"
                      class="q-py-xs"
                    >
                      <div class="text-blue-grey-4 q-mb-sm">Параметр</div>
                      <div class="row q-gutter-sm white-bg-field">
                        <q-select
                          v-model="param.param"
                          :options="calculatorOptions.conditional_params"
                          class="col field-with-top-label q-mb-sm"
                          option-label="title"
                          option-value="param"
                          emit-value
                          map-options
                          outlined
                          hide-bottom-space
                          dense
                          @update:model-value="onChangeParam(index, indexParam)"
                        />
                        <q-field
                          :model-value="param.operator"
                          class="field-with-top-label q-pt-none no-bg q-mb-sm text-no-wrap"
                          :error="false"
                          borderless
                          hide-bottom-space
                          dense
                        >
                          <q-btn-toggle
                            v-model="param.operator"
                            class="full-width"
                            toggle-color="primary"
                            color="white"
                            text-color="grey-9"
                            toggle-text-color="white"
                            :options="operatorOptions"
                            :disable="paramComparisonOptions[param.param] || false"
                            no-caps
                            spread
                            unelevated
                          />
                        </q-field>
                        <q-input
                          v-model="param.value as number"
                          class="col field-with-top-label q-mb-sm"
                          type="number"
                          v-if="paramValueTypesAndOptions[param.param].type === CalculatorParamTypesEnum.NUMBER"
                          outlined
                          hide-bottom-space
                          dense
                        />
                        <q-select
                          v-if="paramValueTypesAndOptions[param.param].type === CalculatorParamTypesEnum.SELECT"
                          v-model="param.value"
                          class="col field-with-top-label q-mb-sm"
                          :multiple="paramValueTypesAndOptions[param.param].multiple"
                          :use-chips="paramValueTypesAndOptions[param.param].multiple"
                          :options="paramValueTypesAndOptions[param.param].options"
                          option-label="label"
                          option-value="value"
                          emit-value
                          map-options
                          outlined
                          hide-bottom-space
                          dense
                        />
                        <q-input
                          v-if="paramValueTypesAndOptions[param.param].type === CalculatorParamTypesEnum.DATETIME"
                          :model-value="param.value as string"
                          class="field-with-top-label"
                          label="Дата публикации"
                          outlined
                          hide-bottom-space
                          dense
                          readonly
                        >
                          <template #append>
                            <q-icon name="svguse:icons.svg?2#app-calendar" />
                          </template>
                          <q-popup-proxy
                            ref="pubDate"
                            cover
                            transition-show="scale"
                            transition-hide="scale"
                          >
                            <div class="row">
                              <div class="col-6">
                                <q-date
                                  v-model="param.value"
                                  mask="DD.MM.YYYY HH:mm"
                                />
                              </div>
                              <div class="col-6">
                                <q-time
                                  v-model="param.value as string"
                                  mask="YYYY-MM-DD HH:mm"
                                />
                              </div>
                              <div class="col-12">
                                <q-btn
                                  label="Закрыть"
                                  v-close-popup
                                  color="primary" flat
                                />
                              </div>
                            </div>
                          </q-popup-proxy>
                        </q-input>
                        <q-btn
                          size="md"
                          icon="svguse:icons.svg?2#app-trash"
                          flat
                          round
                          @click="removeParam(index, indexParam)"
                        />
                      </div>
                    </div>
                    <div>
                      <q-btn
                        class="btnBgGray q-mt-sm"
                        label="Добавить параметр"
                        icon-right="svguse:icons.svg?2#app-plus"
                        text-color="blue-gray-5"
                        unelevated
                        no-wrap
                        no-caps
                        @click="addParam(index)"
                      />
                    </div>
                  </q-form>
                </div>

                <div class="row q-mt-md">
                  <div class="col-12">
                    <q-toggle
                      v-model="condition.calculator_enabled"
                      label="Калькулятор включен"
                      class="q-mr-md"
                      color="blue-7"
                    />
                  </div>
                </div>
                <div
                  v-if="condition.calculator_enabled"
                  class="row"
                >
                  <div class="col-12">
                    <q-toggle
                      v-model="condition.date_of_entry_into_force_enabled"
                      label="Управление датой вступления в силу"
                      class="q-mr-md"
                      color="blue-7"
                    />
                  </div>
                  <div class="col-12">
                    <q-toggle
                      v-model="condition.issue_form_enabled"
                      label="Управление формой выдачи"
                      class="q-mr-md"
                      color="blue-7"
                    />
                  </div>
                  <div class="col-12 commissionBlock">
                    <q-toggle
                      v-model="condition.bank_commission_enabled"
                      label="Падение за счет банка"
                      class="q-mr-md"
                      color="blue-7"
                    />
                    <div
                      v-if="condition.bank_commission_enabled"
                      class="row q-col-gutter-md q-mt-md q-pa-md"
                    >
                      <div class="col" style="max-width: 200px">
                        <q-input
                          v-model="condition.bank_commission_percent"
                          type="number"
                          label="Падение %"
                          class="field-with-top-label"
                          outlined
                          hide-hint
                          hide-bottom-space
                          dense
                        />
                      </div>
                      <div class="col">
                        <q-input
                          v-model="condition.bank_commission_comment"
                          class="field-with-top-label"
                          label="Комментарий"
                          outlined
                          hide-hint
                          hide-bottom-space
                          dense
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-12 commissionBlock">
                    <q-toggle
                      v-model="condition.agent_commission_enabled"
                      label="Падение за счет агента"
                      class="q-mr-md"
                      color="blue-7"
                    />
                    <div
                      v-if="condition.agent_commission_enabled"
                      class="row q-col-gutter-md q-mt-md q-pa-md"
                    >
                      <div class="col" style="max-width: 200px">
                        <q-input
                          v-model="condition.agent_commission_percent"
                          type="number"
                          label="Падение %"
                          class="field-with-top-label"
                          outlined
                          hide-hint
                          hide-bottom-space
                          dense
                        />
                      </div>
                      <div class="col">
                        <q-input
                          v-model="condition.agent_commission_comment"
                          class="field-with-top-label"
                          label="Комментарий"
                          outlined
                          hide-hint
                          hide-bottom-space
                          dense
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 q-py-md text-right">
                  <q-btn
                    class="btnBgGray"
                    style="padding: 12px 23px;"
                    text-color="blue-gray-5"
                    label="Удалить условие"
                    unelevated
                    no-wrap
                    no-caps
                    @click="removeCondition(index)"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <q-btn
              label="Добавить условие"
              icon-right="svguse:icons.svg?2#app-plus"
              class="btnBgGray textBlue"
              style="padding: 12px 23px;"
              unelevated
              no-wrap
              no-caps
              @click="addCondition()"
            />
          </div>
        </div>
      </q-card-section>
      <q-card-actions
        align="right"
        class="modal-actions text-primary q-pa-lg"
      >
        <q-btn
          v-close-popup
          label="Отмена"
          class="q-pr-lg"
          style="padding: 17px 28px;"
          unelevated
          flat
          no-caps
          :loading="putLoading"
          text-color="black"
        />
        <q-btn
          label="Сохранить"
          color="primary"
          style="padding: 17px 28px;"
          unelevated
          icon-right="svguse:icons.svg?2#app-check"
          no-caps
          :loading="putLoading"
          :disable="!isValid"
          @click="handleSubmit()"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
import {useDialog} from 'src/uses/Dialogs/useDialog'
import {ProductsListItem} from 'src/models/Products/ProductsListItem'
import {useProductCalculatorOptions} from 'src/uses/Products/Calculator/useProductCalculatorOptions'
import {CalculatorParamTypesEnum} from 'src/models/Products/Calculator/ProductCalculatorOptions'

const props = defineProps<{
  product: ProductsListItem
}>()

const emit = defineEmits<{
  (e: 'updated'): void
}>()

const productsTariffControlStore = useProductCalculatorOptions()
const {
  loadCalculatorOptions,
  save,
  calculatorOptions,
  putStatus,
  putLoading,

  addCondition,
  addParam,
  removeCondition,
  removeParam,
  onChangeParam,
  paramComparisonOptions,
  paramValueTypesAndOptions,
  operatorOptions,
  isValid,
} = productsTariffControlStore

const {dialog, show, hide} = useDialog()

const open = async () => {
  await loadCalculatorOptions(props.product.id)
  show()
}

const handleSubmit = async () => {
  await save(props.product.id)
  emit('updated')
  if (putStatus.value === 200) {
    hide()
  }
}

</script>
<style lang="scss">
.productCalculatorOptions {
  .productCalculatorOptions--wrapper {
    background-color: #E3F2FD;
    margin: 0 -24px -24px -24px;
    padding: 24px;
  }
  .q-field.field-with-top-label {
    padding-top: 0!important;

  }
  .btnBgGray {
    background-color: #C7DFF1 !important;
  }
  .commissionBlock {
    border-radius: 6px;
    &:hover {
      background-color: #D5EAFA;
    }
  }
  .conditionsBlock {
    background-color: #E3F2FD;
  }
  .textBlue {
    color: #1E88E5;
  }
}
</style>
