<template>
  <div v-if="!!error">
    <q-intersection
      transition="scale"
    >
      <div class="errorHelper row items-center text-weight-medium q-py-md">
        <div class="col-12 q-px-md">
          {{ props.error }}
        </div>
      </div>
    </q-intersection>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  error: string,
}>()
</script>

<style lang="scss">
.errorHelper {
  background: $red-1;
  color: $red-12;
  font-size: 14px;
}
</style>
