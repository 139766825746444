<template>
  <q-card-section class="text-h6 rounded-borders q-px-lg q-mb-none">
    Адрес отправки БГ для заявки №{{ number }}
  </q-card-section>
  <q-card-section class="q-px-none q-pt-none">
    <delivery-address-modal-form
      :id="props.id"
      :is-only-delivery-modal="true"
      :can-change-issue-bg-form="props.canChangeIssueBgForm"
    />
  </q-card-section>
  <q-card-actions
    align="right"
    class="modal-actions text-primary q-pa-lg"
  >
    <q-btn
      label="Сохранить"
      color="primary"
      style="padding: 17px 28px;"
      unelevated
      no-caps
      :loading="deliveryAddressForm.loading || props.loading"
      :disable="deliveryAddressForm.validation.$invalid"
      @click="!!issueMailHardCopyField && issueMailHardCopyField.value ? deliveryAddressForm.sendDeliveryAddress() : onSave()"
    />
  </q-card-actions>
</template>

<script setup lang="ts">
import DeliveryAddressModalForm from 'src/components/Applications/onReleaseSendModal/DeliveryAddressModalForm.vue'
import {useDeliveryAddressFormStore} from 'src/stores/Applications/Forms/useDeliveryAddressFormStore'
import {onBeforeMount, watchEffect, ref} from 'vue'
import {useHeaderDataFromStore} from 'src/uses/Applications/ControlCenter/useHeaderDataFromStore'
import {
  useApplicationControlCenterItemStore
} from 'src/stores/Applications/ControlCenter/useApplicationControlCenterItemStore'
import {storeToRefs} from 'pinia'
import {useImportantFields} from 'src/uses/Applications/Form/useImportantFields'

const props = defineProps<{
  id: string,
  canChangeIssueBgForm?: boolean
  loading: boolean
}>()
const emit = defineEmits<{
  (e: 'save'): void,
}>()

const deliveryAddressForm = useDeliveryAddressFormStore(props.id)()
watchEffect(() => {
  if (deliveryAddressForm.success) {
    onSave()
  }
})

const onSave = () => {
  emit('save')
}

const applicationControlCenterItemStore = useApplicationControlCenterItemStore(props.id)()
const {
  item,
} = storeToRefs(applicationControlCenterItemStore)
const {
  issueMailHardCopyField,
} = useImportantFields(item)

const {
  getHeaderData
} = useHeaderDataFromStore()

const number = ref<string | undefined>('')

onBeforeMount(async () => {
  if (props.id) {
    const data = await getHeaderData(props.id)
    number.value = data?.number
  }
})

</script>

<style lang="scss">

</style>
