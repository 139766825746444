<template>
  <q-dialog
    v-model="statusStore.guaranteeReleasedDialog"
  >
    <q-card
      class="q-mt-md bg-blue-1"
      style="min-width: 500px; max-width: 600px; border-radius: 6px !important;"
    >
      <q-card-section class="text-h6 rounded-borders">
        Изменение статуса
      </q-card-section>
      <q-card-section class=" q-mb-lg">
        <q-banner class="guarantee-release-banner-info">
          <template #avatar>
            <div class="icon-guarantee-release-banner-info">
              <q-icon
                name="svguse:icons.svg?2#app-exclamation"
                size="sm"
                color="red"
              />
            </div>
          </template>
          <div>Внимание! При переводе заявки в статус "Выпущена" все связанные заявки будут закрыты системой. <br> Вы уверены, что
            хотите продолжить?</div>
        </q-banner>
      </q-card-section>
      <q-card-actions
        class="row justify-end q-py-md"
        style="background: #D5EAFA;"
      >
        <q-btn
          v-close-popup
          size="16px"
          padding="sm lg"
          label="Отмена"
          unelevated
          no-caps
        />
        <q-btn
          padding="sm lg"
          size="16px"
          color="blue-7"
          label="Продолжить"
          unelevated
          no-caps
          @click="statusStore.onSaveGuaranteeReleased()"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
import {useStatusStore} from 'src/stores/Status/useStatusStore'

const statusStore = useStatusStore()
</script>
<style lang="scss">
.guarantee-release-banner-info {
  background: #f35757 !important;
  color: #ffffff;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  margin: 0 -24px 0 -24px;
  padding: 20px 30px;

  .icon-guarantee-release-banner-info {
    background-color: white!important;
    border-radius: 5px;
    padding: 12px;
  }
}
</style>
