<template>
  <filters-dialog
    :model-value="props.modelValue"
    @update:model-value="emit('update:model-value', $event)"
  >
    <reports-grid-filters
      :loading="loading"
      :only-pinned="false"
      @update:filters="emit('update:filters')"
    />
  </filters-dialog>
</template>

<script setup lang="ts">
import FiltersDialog from 'src/components/Grid/FiltersDialog.vue'
import ReportsGridFilters from 'components/Profile/Reports/ReportsGridFilters.vue'

const props = defineProps<{
  loading: boolean
  modelValue: boolean
}>()

const emit = defineEmits<{
  (e: 'update:filters'): void,
  (e: 'update:model-value', value: boolean): void,
}>()
</script>
