<template>
  <div class="col-sm col-xs-12 bannerMainContent">
    <div
      v-if="props.headerInfo.sub_status === ApplicationSubStatusEnum.commission_approval"
      style="margin-top: 10px"
    >
      Сделка отправлена в банк на согласование комиссии
    </div>
  </div>
</template>

<script setup lang="ts">
import {ControlCenterHeader} from 'src/models/Applications/ControlCenter/ControlCenterHeader'
import {ApplicationSubStatusEnum} from 'src/models/Applications/ApplicationSubStatusEnum'

const props = defineProps<{ headerInfo: ControlCenterHeader }>()

</script>
