import {defineStore} from 'pinia'
import {useGetApi} from 'src/uses/Api/useGetApi'
import {ref, computed} from 'vue'
import {
  ProductTariffParamOptionsWithChild,
  ProductTariffParamsItem, ProductTariffParamsItemOperatorEnum,
} from 'src/models/Products/Tariff/ProductTariffParamsItem'

const operators: Record<ProductTariffParamsItemOperatorEnum, string> = {
  [ProductTariffParamsItemOperatorEnum.LT]: '<',
  [ProductTariffParamsItemOperatorEnum.EQ]: '=',
  [ProductTariffParamsItemOperatorEnum.GT]: '>',
}

export const useProductsTariffParamsStore = defineStore('product-tariff-params', () => {
  const {
    loading,
    get,
    errors,
    error,
    status,
    response,
  } = useGetApi<ProductTariffParamsItem[]>()

  const productTariffParams = ref<ProductTariffParamsItem[]>()
  const operatorOptions = ref(
    Object.values(ProductTariffParamsItemOperatorEnum).map(key => {
      return {
        label: operators[key],
        value: key
      }
    })
  )

  const loadProductTariffParams = async () => {
    await get('v1/admin/product/factor/params')

    if (status.value === 200 && response && response.value) {
      console.log(response.value)
      productTariffParams.value = response.value
      console.log(productTariffParams.value)
    }
  }

  const paramComparisonOptions = computed(() => {
    if (!productTariffParams.value) {
      return {}
    }

    const params = JSON.parse(JSON.stringify(productTariffParams.value))

    return Object.fromEntries(
      Object.keys(productTariffParams.value).map((key) => {
        return [
          key,
          params[key].relations.length === 1
        ]
      })
    )
  })

  const paramValueTypesAndOptions = computed(() => {
    if (!productTariffParams.value) {
      return {}
    }

    const params = JSON.parse(JSON.stringify(productTariffParams.value))

    return Object.fromEntries(
      Object.keys(productTariffParams.value).map((key) => {
        if (!Array.isArray(params[key].options)) {
          const options = []
          for (const value in params[key].options) {
            options.push({
              label: params[key].options[value],
              value,
            })
          }
          return [
            key,
            {
              type: params[key].type,
              multiple: params[key].multiple,
              options
            }
          ]
        } else {
          return [
            key,
            {
              type: params[key].type,
              multiple: params[key].multiple,
              options: params[key].options ? flatOptions(params[key].options) : null
            }
          ]
        }
      })
    )
  })

  const mapOptionsToKeyValue = (options: ProductTariffParamOptionsWithChild[]) => {
    return options
      .map(option => {
        if (option.code && option.name) {
          return {
            label: option.name,
            value: option.code,
          }
        }

        return option
      })
  }

  const flatOptions = (options: ProductTariffParamOptionsWithChild[]) => {
    return mapOptionsToKeyValue(getOptionsWithChild(options))
  }

  const getOptionsWithChild = (options: ProductTariffParamOptionsWithChild[]) => {
    let flatOptions: ProductTariffParamOptionsWithChild[] = []
    for (const option of options) {
      flatOptions.push(option)
      flatOptions = flatOptions.concat(getOptionsWithChild(option.child || []))
    }

    return flatOptions
  }

  const conditionsParamsOptions = computed(() => {
    if (!productTariffParams.value) {
      return []
    }

    const params = JSON.parse(JSON.stringify(productTariffParams.value))

    return Object.keys(productTariffParams.value).map((key) => {
      return {
        label: params[key].title,
        value: key,
      }
    })
  })

  return {
    loading,
    status,
    get,
    errors,
    error,
    productTariffParams,
    paramValueTypesAndOptions,
    paramComparisonOptions,
    conditionsParamsOptions,
    operatorOptions,
    loadProductTariffParams,
  }
})
