<template>
  <application-form-documents-block
    v-for="documentType in documentTypes"
    :key="documentType"
    :documents="visibleDocuments"
    :new-documents="newDocuments"
    :document-type="documentType"
    :can-upload-all-new-documents="canUploadAllNewDocuments"
    :banks="banks"
    :founders="client && client.founders ? client.founders : []"
    :head-fields="client && client.head ? client.head.fields : []"
    :dropzone-available="dropZoneAvailability[documentType]"
    :fill-status="getSectionFillStatus(ApplicationItemSectionsEnum.DOCUMENTS)"
    :can-add-new-added-document="canAddNewAddedDocument(documentType)"
    @cancel="onCancel"
    @file:selected-with-sign="onFileSelectedWithSign"
    @file:selected="onFileSelected"
    @file:selected-new="onFileSelectedNew"
    @document:delete="deleteDocument($event)"
    @document:download="downloadDocument($event, documentType)"
    @document:download-sign="downloadDocumentSign($event, documentType)"
    @new-documents:remove="removeNewDocumentByIndex($event, documentType)"
    @new-documents:upload="onNewDocumentUploadHandler"
    @new-document:update="updateNewDocument"
    @upload-all-new-documents="uploadAllNewDocuments(false, true)"
  />
</template>

<script setup lang="ts">
import {storeToRefs} from 'pinia'
import {
  ApplicationFormDocument,
  ApplicationFormDocumentGroupEnum,
} from 'src/models/Applications/Form/ApplicationFormResponseData'
import ApplicationFormDocumentsBlock from 'src/components/Applications/Form/Documents/ApplicationFormDocumentsBlock.vue'
import {useApplicationItemStore} from 'stores/Applications/Item/useApplicationItemStore'
import {ProductEnum} from 'src/models/Applications/ProductEnum'
import {useApplicationItemKikStore} from 'stores/Applications/Item/useApplicationItemKikStore'
import {useApplicationItemEbgStore} from 'stores/Applications/Item/useApplicationItemEbgStore'
import {ApplicationItemSectionsEnum} from 'src/models/Enums/ApplicationItemSectionsEnum'
import {useApplicationItemKikFillStore} from 'stores/Applications/Item/useApplicationItemKikFillStore'
import {useApplicationItemEbgFillStore} from 'stores/Applications/Item/useApplicationItemEbgFillStore'

const inputProps = defineProps<{
  multiOrderId: number,
}>()

const {
  productTab,
} = storeToRefs(useApplicationItemStore(inputProps.multiOrderId)())

const {
  getSectionFillStatus
} = productTab.value === ProductEnum.EBG
  ? useApplicationItemEbgFillStore(inputProps.multiOrderId)()
  : useApplicationItemKikFillStore(inputProps.multiOrderId)()

const applicationItemProductStore = productTab.value === ProductEnum.EBG
  ? useApplicationItemEbgStore(inputProps.multiOrderId)()
  : useApplicationItemKikStore(inputProps.multiOrderId)()
const {
  uploadDocument,
  cancelUpload,
  deleteDocument,
  downloadDocument,
  downloadDocumentSign,
  addNewDocument,
  removeNewDocumentByIndex,
  onNewDocumentUpload,
  updateNewDocument,
  uploadAllNewDocuments,
  canAddNewAddedDocument,
} = applicationItemProductStore
const {
  visibleDocuments,
  newDocuments,
  dropZoneAvailability,
  canUploadAllNewDocuments,
  banks,
  client,
} = storeToRefs(applicationItemProductStore)

const documentTypes: ApplicationFormDocumentGroupEnum[] = [
  ApplicationFormDocumentGroupEnum.passports,
  ApplicationFormDocumentGroupEnum.financial,
  ApplicationFormDocumentGroupEnum.additional,
]

const onFileSelected = (file: File, document: ApplicationFormDocument) => {
  document.file = file
  uploadDocument(document)
}

const onFileSelectedWithSign = (original: File, sign: File, document: ApplicationFormDocument) => {
  document.file_sign = sign
  document.file = original
  uploadDocument(document)
}

const onFileSelectedNew = (
  file: File,
  document: ApplicationFormDocument,
  documentType: ApplicationFormDocumentGroupEnum,
) => {
  document.file = file
  addNewDocument(document, documentType)
}

const onCancel = (document: ApplicationFormDocument) => {
  cancelUpload(document)
}

const onNewDocumentUploadHandler = (
  document: ApplicationFormDocument,
  documentType: ApplicationFormDocumentGroupEnum,
  index: number
) => {
  onNewDocumentUpload(document, documentType, index)
}
</script>
