<template>
  <q-field
    :key="String(props.field.value)"
    :model-value="model"
    :hint="props.ignoreHint ? '' : (props.field.help || '')"
    :disable="props.field.readonly && !props.ignoreReadonly"
    :class="classes"
    :error="!!props.field.error"
    :error-message="props.field.error"
    :loading="props.field.loading"
    for="NOT_EXISTING_ID"
    hide-bottom-space
    borderless
    dense
  >
    <q-toggle
      v-model="model"
      :disable="props.field.readonly && !props.ignoreReadonly"
      :indeterminate-value="null"
      :size="size"
    >
      <template #default>
        <slot name="labelPrepend" />
        {{ props.label || props.field.title }}
        <slot name="labelAppend" />
      </template>
    </q-toggle>
  </q-field>
</template>

<script setup lang="ts">
import {computed, ref, watch, watchEffect} from 'vue'
import {ApplicationFormField} from 'src/models/Applications/Form/ApplicationFormResponseData'
import {FormElementValue} from 'src/models/Form'
import {QToggle, useQuasar} from 'quasar'

const props = defineProps<{
  field: ApplicationFormField,
  label: string | boolean,
  ignoreReadonly: boolean,
  ignoreHint: boolean,
}>()

const emit = defineEmits<{
  (e: 'update:value', value: FormElementValue): void,
}>()

const $q = useQuasar()

const model = ref<FormElementValue>(null)

const size = computed(() => {
  if ($q.platform.is.mobile) {
    return 'xl'
  }

  return 'md'
})

const classes = computed(() => {
  return {
    'no-bg field-wrapper field-with-top-label': true,
    noPaddingTop: $q.platform.is.mobile,
  }
})

watchEffect(() => {
  model.value = props.field.value
})

watch(
  model,
  () => {
    emit('update:value', model.value)
  }
)
</script>
