<template>
  <q-input
    :key="String(props.field.value)"
    v-model="model"
    v-mask="mask"
    class="field-with-top-label"
    :hint="props.ignoreHint ? '' : (props.field.help || '')"
    :disable="props.field.readonly && !props.ignoreReadonly"
    :error="!!props.field.error"
    :error-message="props.field.error"
    :loading="props.field.loading"
    label-slot
    outlined
    hide-bottom-space
    dense
    @change="change"
    @keyup="keyup"
  >
    <template #label>
      <slot name="labelPrepend" />
      {{ props.field.title }}
      <slot name="labelAppend" />
    </template>
  </q-input>
</template>

<script setup lang="ts">
import {computed, ref, watchEffect} from 'vue'
import {
  ApplicationFormField,
} from 'src/models/Applications/Form/ApplicationFormResponseData'
import {FormElementPrimitiveValue, FormElementValue} from 'src/models/Form'
import {FieldsCodesEnum} from 'src/models/FieldsCodesEnum'

const props = defineProps<{
  field: ApplicationFormField,
  label: string | boolean,
  ignoreReadonly: boolean,
  ignoreHint: boolean,
}>()

const emit = defineEmits<{
  (e: 'update:value', value: FormElementValue): void,
}>()

const model = ref<FormElementPrimitiveValue>(null)

watchEffect(() => {
  model.value = props.field.value as FormElementPrimitiveValue
})

const mask = computed(() => {
  const FIELDS_WITHOUT_9_IN_MASK = [
    FieldsCodesEnum.CLIENT_COMMON_MR_CONTACT_OFFICE_PHONE,
  ]
  if (FIELDS_WITHOUT_9_IN_MASK.includes(props.field.code as FieldsCodesEnum)) {
    return '7##########'
  }

  if (props.field.code === FieldsCodesEnum.DEAL_CUSTOMER_CONTACT_PHONE) {
    return '###########'
  }

  return '+7 (9##) ###-##-##'
})

const change = () => {
  if (!model.value) {
    emit('update:value', '')

    return
  }

  emit('update:value', (model.value as string).replace(/[^+\d]/gi, ''))
}

const keyup = () => {
  if (props.field.code !== FieldsCodesEnum.DEAL_CUSTOMER_CONTACT_PHONE) {
    return
  }

  if (!model.value) {
    return
  }

  const phone = model.value.toString()
  if (phone.length < 11 && !(phone[0] === '7' || phone[0] === '8')) {
    model.value = '7' + phone
  }
}
</script>
