import {useOptionsFromObject} from 'src/uses/useOptionsFromObject'
import {ApplicationFormDocumentGroupEnum} from 'src/models/Applications/Form/ApplicationFormResponseData'

const groups: Record<ApplicationFormDocumentGroupEnum, string> = {
  [ApplicationFormDocumentGroupEnum.passports]: 'Паспорта',
  [ApplicationFormDocumentGroupEnum.financial]: 'Финансовая отчетность',
  [ApplicationFormDocumentGroupEnum.additional]: 'Дополнительные документы',
  [ApplicationFormDocumentGroupEnum.deal]: 'Сделка',
  [ApplicationFormDocumentGroupEnum.offer]: 'Предложение',
  [ApplicationFormDocumentGroupEnum.client]: 'Клиент',
}

const groupsAdd: Record<ApplicationFormDocumentGroupEnum, string> = {
  [ApplicationFormDocumentGroupEnum.passports]: 'Добавление Паспорта',
  [ApplicationFormDocumentGroupEnum.financial]: 'Добавление файла отчетности',
  [ApplicationFormDocumentGroupEnum.additional]: 'Добавление дополнительного документа',
  [ApplicationFormDocumentGroupEnum.deal]: '',
  [ApplicationFormDocumentGroupEnum.offer]: '',
  [ApplicationFormDocumentGroupEnum.client]: '',
}

export const useDocumentGroup = () => {
  const {getOptionsFromObject} = useOptionsFromObject()
  const options = getOptionsFromObject(groups)
  const getDocumentGroupLabel = (group: ApplicationFormDocumentGroupEnum): string => {
    return groups[group] || ''
  }

  const getDocumentGroupAddLabel = (group: ApplicationFormDocumentGroupEnum): string => {
    return groupsAdd[group] || ''
  }

  return {
    getDocumentGroupLabel,
    getDocumentGroupAddLabel,
    options,
  }
}
