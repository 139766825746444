<template>
  <div
    :key="JSON.stringify(props.field.value)"
    :class="classes"
  >
    <div class="row">
      <span ref="fieldRef" />
      <copy-to-clipboard
        class="col"
        :text="valueText"
        :disable="!props.field.readonly"
      >
        <field-select
          v-if="isDropdown"
          :field="props.field"
          :label="props.label"
          :ignore-readonly="props.ignoreReadonly"
          :ignore-hint="props.ignoreHint"
          class="col"
          @update:value="updateValue"
          @update:target-value="updateTargetValue"
          @update:address-details="updateAddressDetails"
        >
          <template #labelPrepend>
            <field-bank-valid
              :valid="props.field.valid"
              :banks="banks"
            />
          </template>
        </field-select>
        <field-boolean
          v-else-if="isBoolean"
          :field="props.field"
          :label="props.label"
          :ignore-readonly="props.ignoreReadonly"
          :ignore-hint="props.ignoreHint"
          class="col"
          @update:value="updateValue"
          @update:target-value="updateTargetValue"
          @update:address-details="updateAddressDetails"
        >
          <template #labelPrepend>
            <field-bank-valid
              :valid="props.field.valid"
              :banks="banks"
            />
          </template>
        </field-boolean>
        <field-date
          v-else-if="isDate || isPeriod"
          :field="props.field"
          :label="props.label"
          :ignore-readonly="props.ignoreReadonly"
          :ignore-hint="props.ignoreHint"
          class="col"
          @update:value="updateValue"
          @update:target-value="updateTargetValue"
          @update:address-details="updateAddressDetails"
        >
          <template #labelPrepend>
            <field-bank-valid
              :valid="props.field.valid"
              :banks="banks"
            />
          </template>
        </field-date>
        <field-amount
          v-else-if="isAmount"
          :field="props.field"
          :label="props.label"
          :ignore-readonly="props.ignoreReadonly"
          :ignore-hint="props.ignoreHint"
          class="col"
          @update:value="updateValue"
          @update:target-value="updateTargetValue"
          @update:address-details="updateAddressDetails"
        >
          <template #labelPrepend>
            <field-bank-valid
              :valid="props.field.valid"
              :banks="banks"
            />
          </template>
        </field-amount>
        <field-count
          v-else-if="isCount"
          :field="props.field"
          :label="props.label"
          :ignore-readonly="props.ignoreReadonly"
          :ignore-hint="props.ignoreHint"
          class="col"
          @update:value="updateValue"
          @update:target-value="updateTargetValue"
          @update:address-details="updateAddressDetails"
        >
          <template #labelPrepend>
            <field-bank-valid
              :valid="props.field.valid"
              :banks="banks"
            />
          </template>
        </field-count>
        <field-phone
          v-else-if="isPhone"
          :field="props.field"
          :label="props.label"
          :ignore-readonly="props.ignoreReadonly"
          :ignore-hint="props.ignoreHint"
          class="col"
          @update:value="updateValue"
          @update:target-value="updateTargetValue"
          @update:address-details="updateAddressDetails"
        >
          <template #labelPrepend>
            <field-bank-valid
              :valid="props.field.valid"
              :banks="banks"
            />
          </template>
        </field-phone>
        <field-email
          v-else-if="isEmail"
          :field="props.field"
          :label="props.label"
          :ignore-readonly="props.ignoreReadonly"
          :ignore-hint="props.ignoreHint"
          class="col"
          @update:value="updateValue"
          @update:target-value="updateTargetValue"
          @update:address-details="updateAddressDetails"
        >
          <template #labelPrepend>
            <field-bank-valid
              :valid="props.field.valid"
              :banks="banks"
            />
          </template>
        </field-email>
        <field-dadata-fms-unit
          v-else-if="isDadataFmsUnit"
          :field="props.field"
          :label="props.label"
          :ignore-readonly="props.ignoreReadonly"
          :ignore-hint="props.ignoreHint"
          class="col"
          @update:value="updateValue"
          @update:target-value="updateTargetValue"
          @update:address-details="updateAddressDetails"
        >
          <template #labelPrepend>
            <field-bank-valid
              :valid="props.field.valid"
              :banks="banks"
            />
          </template>
        </field-dadata-fms-unit>
        <field-dadata-okpd
          v-else-if="isDadataOkpd"
          :field="props.field"
          :label="props.label"
          :ignore-readonly="props.ignoreReadonly"
          :ignore-hint="props.ignoreHint"
          class="col"
          @update:value="updateValue"
          @update:target-value="updateTargetValue"
          @update:address-details="updateAddressDetails"
        >
          <template #labelPrepend>
            <field-bank-valid
              :valid="props.field.valid"
              :banks="banks"
            />
          </template>
        </field-dadata-okpd>
        <field-btngroup
          v-else-if="isBtngroup"
          :field="props.field"
          :label="props.label"
          :ignore-readonly="props.ignoreReadonly"
          :ignore-hint="props.ignoreHint"
          class="col"
          @update:value="updateValue"
          @update:target-value="updateTargetValue"
          @update:address-details="updateAddressDetails"
        >
          <template #labelPrepend>
            <field-bank-valid
              :valid="props.field.valid"
              :banks="banks"
            />
          </template>
        </field-btngroup>
        <field-address
          v-else-if="isAddress"
          :field="props.field"
          :label="props.label"
          :ignore-readonly="props.ignoreReadonly"
          :ignore-hint="props.ignoreHint"
          class="col"
          @update:value="updateValue"
          @update:target-value="updateTargetValue"
          @update:address-details="updateAddressDetails"
        >
          <template #labelPrepend>
            <field-bank-valid
              :valid="props.field.valid"
              :banks="banks"
            />
          </template>
        </field-address>
        <field-string
          v-else
          :field="props.field"
          :label="props.label"
          :ignore-readonly="props.ignoreReadonly"
          :ignore-hint="props.ignoreHint"
          class="col"
          @update:value="updateValue"
          @update:target-value="updateTargetValue"
          @update:address-details="updateAddressDetails"
        >
          <template #labelPrepend>
            <field-bank-valid
              :valid="props.field.valid"
              :banks="banks"
            />
          </template>
        </field-string>
      </copy-to-clipboard>
    </div>
  </div>
</template>

<script setup lang="ts">
import {withDefaults, computed, ref, watchEffect, watch} from 'vue'
import {
  AddressDetails,
  ApplicationFormBank,
  ApplicationFormField,
  ApplicationFormFieldTypeEnum,
} from 'src/models/Applications/Form/ApplicationFormResponseData'
import FieldSelect from 'src/components/Fields/FieldSelect.vue'
import FieldString from 'src/components/Fields/FieldString.vue'
import FieldBoolean from 'src/components/Fields/FieldBoolean.vue'
import FieldDate from 'src/components/Fields/FieldDate.vue'
import FieldAmount from 'src/components/Fields/FieldAmount.vue'
import FieldPhone from 'src/components/Fields/FieldPhone.vue'
import FieldEmail from 'src/components/Fields/FieldEmail.vue'
import FieldCount from 'src/components/Fields/FieldCount.vue'
import {useQuasar} from 'quasar'
import {FormElementValue} from 'src/models/Form'
import FieldBankValid from 'src/components/Fields/FieldBankValid.vue'
import {useScrollTo} from 'src/uses/useScrollTo'
import FieldDadataFmsUnit from 'src/components/Fields/FieldDadataFmsUnit.vue'
import FieldBtngroup from 'src/components/Fields/FieldBtngroup.vue'
import FieldAddress from 'src/components/Fields/FieldAddress.vue'
import {useLayoutStore} from 'stores/useLayoutStore'
import {storeToRefs} from 'pinia'
import CopyToClipboard from 'src/components/CopyToClipboard.vue'
import FieldDadataOkpd from 'components/Fields/FieldDadataOkpd.vue'

const props = withDefaults(
  defineProps<{
    field: ApplicationFormField,
    banks: ApplicationFormBank[],
    scrollRoot?: Element | null,
    showAnimationInProgress?: boolean,
    ignoreWidth?: boolean,
    ignoreReadonly?: boolean,
    ignoreHint?: boolean,
    label?: string | boolean,
  }>(),
  {
    scrollRoot: null,
    showAnimationInProgress: false,
    ignoreWidth: false,
    ignoreReadonly: false,
    ignoreHint: false,
    label: false,
  }
)

const emit = defineEmits<{
  (
    e: 'update:value',
    value: FormElementValue,
    field: ApplicationFormField,
  ): void,
  (
    e: 'update:target-value',
    value: FormElementValue,
    field: ApplicationFormField,
  ): void,
  (
    e: 'update:address-details',
    field: ApplicationFormField,
    details: AddressDetails,
  ): void,
}>()
const layoutStore = useLayoutStore()
const {rightDrawer, drawer} = storeToRefs(layoutStore)
const fieldRef = ref<HTMLSpanElement>()

const $q = useQuasar()

const {
  scrollToInvisibleElement,
  root,
} = useScrollTo()

const isDropdown = computed(() => props.field.type === ApplicationFormFieldTypeEnum.dropdown)
const isDadataFmsUnit = computed(() => {
  return props.field.service?.code === 'fms_unit'
    && props.field.type === ApplicationFormFieldTypeEnum.string
})
const isDadataOkpd = computed(() => {
  return props.field.service?.code === 'okpd2'
    && props.field.type === ApplicationFormFieldTypeEnum.string
})
//const isString = computed(() => props.field.type === ApplicationFormFieldTypeEnum.string)
const isBoolean = computed(() => props.field.type === ApplicationFormFieldTypeEnum.boolean)
const isAddress = computed(() => props.field.type === ApplicationFormFieldTypeEnum.address)
const isDate = computed(() => props.field.type === ApplicationFormFieldTypeEnum.date)
const isPeriod = computed(() => props.field.type === ApplicationFormFieldTypeEnum.period)
const isAmount = computed(() => props.field.type === ApplicationFormFieldTypeEnum.amount)
const isPhone = computed(() => props.field.type === ApplicationFormFieldTypeEnum.phone)
const isEmail = computed(() => props.field.type === ApplicationFormFieldTypeEnum.email)
const isBtngroup = computed(() => props.field.type === ApplicationFormFieldTypeEnum.btngroup)
const isCount = computed(() => props.field.type === ApplicationFormFieldTypeEnum.count)

const highlighted = computed(() => props.field.highlighted)

const prepareSingleValue = (value: string | number | boolean | null) => {
  if (value === undefined || value === null) {
    return ''
  }

  if (typeof props.field.value === 'boolean') {
    return props.field.value ? 'Да' : 'Нет'
  }

  if (!props.field.choices) {
    return typeof value.toString === 'function'
      ? value.toString()
      : value as string
  }

  return props.field.choices[value as string] || value.toString()
}

const valueText = computed(() => {
  if (Array.isArray(props.field.value)) {
    return props.field.value
      .map(prepareSingleValue)
      .join(', ')
  }

  return prepareSingleValue(props.field.value)
})

const classes = computed(() => {
  const baseMultiplier = $q.screen.xs
    ? 6 : $q.screen.sm
      ? 6 : $q.screen.md
        ? 4 : $q.screen.lg
          ? 4 : $q.screen.xl
            ? 4 : 4

  let size = props.field.length * baseMultiplier
  size = size > 12 ? 12 : size

  if (props.ignoreWidth) {
    size = 12
  }

  if ($q.platform.is.mobile) {
    size = 12
  }

  return {
    [`col-${size}`]: !props.ignoreWidth,
    'white-bg-field': !props.field.highlighted,
    'warning-bg-field': props.field.highlighted,
  }
})

const updateValue = (value: FormElementValue) => {
  emit('update:value', value, props.field)
}

const updateAddressDetails = (details: AddressDetails) => {
  emit('update:address-details', props.field, details)
}

const updateTargetValue = (value: FormElementValue) => {
  emit('update:target-value', value, props.field)
}

watchEffect(() => {
  if (!props.scrollRoot) {
    return
  }

  root.value = props.scrollRoot
})

watch(
  highlighted,
  () => {
    if (!highlighted.value) {
      return
    }

    if (!highlighted.value) {
      return
    }

    if ($q.platform.is.mobile) {
      rightDrawer.value = false
      drawer.value = false
    }

    setTimeout(() => {
      if (fieldRef.value && !root.value) {
        scrollToInvisibleElement(fieldRef.value)
      }

      if (fieldRef.value && root.value && !props.showAnimationInProgress) {
        scrollToInvisibleElement(fieldRef.value)
      }
    }, 300)
  },
  {
    immediate: true,
  }
)
</script>
