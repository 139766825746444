import {ref} from 'vue'
import {ProductFields} from 'src/models/Applications/Form/ProductFields'
import {FieldsCodesEnum} from 'src/models/FieldsCodesEnum'
import {useSearch} from 'src/uses/useSearch'

export const useClientHeadAndFounders = () => {
  const {
    filter,
  } = useSearch()

  const search = ref('')

  const showFounder = (founder: ProductFields) => {
    const fields = founder.all
      .filter(f => {
        return [
          FieldsCodesEnum.CLIENT_HF_NAME,
          FieldsCodesEnum.CLIENT_HF_SURNAME,
          FieldsCodesEnum.CLIENT_HF_PATRONYMIC,
          FieldsCodesEnum.CLIENT_HF_UL_Name,
        ].includes(f.code as FieldsCodesEnum)
      })

    const name = (
      fields
        .find(f => f.code === FieldsCodesEnum.CLIENT_HF_NAME) || {}
    )
      .value || ''
    const surname = (
      fields
        .find(f => f.code === FieldsCodesEnum.CLIENT_HF_SURNAME) || {}
    ).value || ''
    const patronymic = (
      fields
        .find(f => f.code === FieldsCodesEnum.CLIENT_HF_PATRONYMIC) || {}
    ).value || ''
    const ulName = (
      fields
        .find(f => f.code === FieldsCodesEnum.CLIENT_HF_UL_Name) || {}
    ) || ''

    const compareWith = []
    if (ulName) {
      compareWith.push(ulName.toString())
    }

    if (surname || patronymic || name) {
      compareWith.push(
        `${name.toString()} ${surname.toString()} ${patronymic.toString()}`
      )
    }

    return filter(search.value, compareWith)
  }

  return {
    search,
    showFounder,
  }
}
