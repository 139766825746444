import {FormFinanceBlockPeriod} from 'src/models/FormEditor/FormItemResponseData'
import {ref} from 'vue'

export const useFormItemFinancePeriod = () => {
  const period = ref<FormFinanceBlockPeriod>()

  const getYears = () => {
    const year = new Date().getFullYear()
    const startYear = year - 3
    const years = []
    for (let i = 3; i > -1; i--) {
      years.push(startYear + i)
    }

    return years
  }

  const getQuarters = () => {
    return [1, 2, 3, 4]
  }

  return {
    period,
    getYears,
    getQuarters,
  }
}
