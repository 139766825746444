import {ApplicationsGridItem} from 'src/models/Applications/ApplicationsGridItem'
import {useStatusStore} from 'src/stores/Status/useStatusStore'
import {useChangeStatusAction} from 'src/uses/Applications/useChangeStatusAction'
import {useProfileStore} from 'stores/Login/useProfileStore'

export const useGridChangeStatusAction = () => {
  const statusStore = useStatusStore()
  const {changeStatus} = statusStore

  const changeStatusUse = useChangeStatusAction()

  const profileStore = useProfileStore()
  const {isAgentOrEmployees} = profileStore

  const isDraft = (item: ApplicationsGridItem) => {
    return changeStatusUse.isDraft(item.sub_status)
  }

  const isBankRejected = (item: ApplicationsGridItem) => {
    return changeStatusUse.isBankRejected(item.sub_status)
  }

  const isSystemRejected = (item: ApplicationsGridItem) => {
    return changeStatusUse.isSystemRejected(item.sub_status)
  }

  const isArchive = (item: ApplicationsGridItem) => {
    return changeStatusUse.isArchive(item.sub_status)
  }

  const isProjectBgReady = (item: ApplicationsGridItem) => {
    return changeStatusUse.isProjectBgReady(item.sub_status)
  }

  const isReleased = (item: ApplicationsGridItem) => {
    return changeStatusUse.isReleased(item.sub_status)
  }

  const isClientRejected = (item: ApplicationsGridItem) => {
    return changeStatusUse.isClientRejected(item.sub_status)
  }

  const showClientReject = (item: ApplicationsGridItem) => {
    if (item.required && isAgentOrEmployees()) {
      return false
    }

    return changeStatusUse.showClientReject(item.sub_status, item.integration.enabled)
  }

  const showCompletion = (item: ApplicationsGridItem) => {
    return changeStatusUse.showCompletion(item.sub_status)
  }

  const showSendToProduction = (item: ApplicationsGridItem) => {
    return changeStatusUse.showSendToProduction(item.sub_status)
  }

  const showToBgProjectBankAgreement = (item: ApplicationsGridItem) => {
    return changeStatusUse.showToBgProjectBankAgreement(item.sub_status)
  }

  const showToDraft = (item: ApplicationsGridItem) => {
    return changeStatusUse.showToDraft(item.sub_status, item.sent_date)
  }

  const showSendToCheck = (item: ApplicationsGridItem) => {
    return changeStatusUse.showSendToCheck(item.sub_status, item.filled)
  }

  const showGridStatusChange = (item: ApplicationsGridItem) => {
    return changeStatusUse.showStatusChange(
      item.sub_status,
      item.sent_date,
      item.filled,
      item.integration.enabled
    )
  }

  return {
    isDraft,
    isBankRejected,
    isSystemRejected,
    isArchive,
    isClientRejected,
    isProjectBgReady,
    isReleased,
    showCompletion,
    changeStatus,
    showToDraft,
    showToBgProjectBankAgreement,
    showSendToProduction,
    showClientReject,
    showSendToCheck,
    showGridStatusChange,
  }
}
